import React, { useState } from 'react';
import { Button, DoubleCheckDialogHeadlessUI } from '@autosquare/common';
import AutoRegisterTitle from '@components/IDE/AutoRegister/AutoRegisterTitle';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { UpdateTestScenarioData } from '@customTypes/testScenario/type';
import { AutoRegisterInfoData } from '@customTypes/ide/scheduler/scheduler';
import IdeAutoRegisterList from '@components/IdeAutoRegisterList/IdeAutoRegisterList';
import clsx from 'clsx';
import IdeAutoRegisterScenarioModal from '@components/IdeAutoRegisterCreateEditModal/IdeAutoRegisterScenarioModal';
import IdeAutoRegisterScenarioEditModal from '@components/IdeAutoRegisterCreateEditModal/IdeAutoRegisterScenarioEditModal';

type Props = {
  autoRegistersList: AutoRegisterInfoData[];
  setAutoRegistersList: React.Dispatch<
    React.SetStateAction<AutoRegisterInfoData[]>
  >;
};

const InputAutoRegister = ({
  autoRegistersList,
  setAutoRegistersList,
}: Props) => {
  const { control, resetField } = useFormContext<UpdateTestScenarioData>();
  const { fields, append, remove, update } = useFieldArray<
    UpdateTestScenarioData,
    'autoRegisters'
  >({
    control,
    name: 'autoRegisters',
  });

  const [isAddClicked, setIsAddClicked] = useState(false);
  const [index, setIndex] = useState<number>(null);

  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [openCaution, setOpenCaution] = useState(false);

  const deleteButtonHandler = () => {
    const removeList = autoRegistersList.filter((_, idx) => idx !== index);
    setAutoRegistersList(removeList);
    remove(index);
    setOpenCaution(false);
  };

  return (
    <div className="col-span-3 flex flex-col items-start gap-y-2">
      <AutoRegisterTitle position="bottom-7 -right-28" />
      <div className="flex items-center gap-x-3">
        {autoRegistersList?.length > 0 && (
          <IdeAutoRegisterList
            autoDataRegisterList={autoRegistersList}
            setIndex={setIndex}
            setIsEditModalOpened={setIsEditModalOpened}
            setOpenCaution={setOpenCaution}
          />
        )}
        <Button
          type="button"
          variant="imageOriginal"
          buttonSize="none"
          className={clsx(
            'flex items-center justify-center',
            autoRegistersList?.length === 0 && 'p-2',
          )}
          onClick={() => {
            resetField('autoRegisters');
            append({ platformName: '' });
            setIsAddClicked(true);
          }}
        >
          <PlusIcon className="size-6" />
          <p className="text-sm font-normal text-gray-900">Add</p>
        </Button>
        {isAddClicked && (
          <IdeAutoRegisterScenarioModal
            isAddClicked={isAddClicked}
            setIsAddClicked={setIsAddClicked}
            autoRegistersList={autoRegistersList}
            setAutoRegistersList={setAutoRegistersList}
            fields={fields}
            update={update}
            remove={remove}
          />
        )}
      </div>
      <DoubleCheckDialogHeadlessUI
        isOpened={openCaution}
        setIsOpened={setOpenCaution}
        type={'caution'}
        title={'Delete Platform'}
        subTitle={
          '삭제하시면 해당 플랫폼에 테스트 결과 자동 등록이 불가능 합니다.'
        }
        buttonChildren={
          <>
            <Button
              type="button"
              variant={'caution'}
              onClick={deleteButtonHandler}
            >
              Delete
            </Button>
            <Button
              type="button"
              variant={'secondary'}
              onClick={() => setOpenCaution(false)}
            >
              Cancel
            </Button>
          </>
        }
      />
      {isEditModalOpened && (
        <IdeAutoRegisterScenarioEditModal
          index={index}
          update={update}
          selectedData={autoRegistersList?.[index]}
          autoRegistersList={autoRegistersList}
          setAutoRegisterList={setAutoRegistersList}
          isEditModalOpened={isEditModalOpened}
          setIsEditModalOpened={setIsEditModalOpened}
        />
      )}
    </div>
  );
};

export default InputAutoRegister;
