import React from 'react';
import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';
import DefinitionList from '@components/shared/DefinitionList';
import {
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { useMobileTestStepBySaveTextQuery } from '@lib/api/ide/mobile/mobileTestStep';
import { useSpeedMobileTestStepBySaveTextQuery } from '@lib/api/ide/speedMobile/speedMobileTestStep';
import { dataTypeList, findDataType } from '@utils/index';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { findDescriptionFromIdx } from '@utils/static/findDescriptionFromIdx';
import {
  Controller,
  UseFieldArrayReplace,
  useFormContext,
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

type Props = {
  replace: UseFieldArrayReplace<UpdateTestStepData>;
  mobileTestStepData: MobileTestStepData;
};

const EditTypeAndReferenceValue = ({ replace, mobileTestStepData }: Props) => {
  const {
    control,
    resetField,
    setValue,
    unregister,
    formState: { errors },
  } = useFormContext<UpdateTestStepData>();
  const uiLocation = isWordInLocationPathname('ui-test');
  const mobileTestStepBySaveTextQuery = uiLocation
    ? useMobileTestStepBySaveTextQuery()
    : useSpeedMobileTestStepBySaveTextQuery();

  const deviceOs = mobileTestStepData.mobileOs;
  const referenceValue = mobileTestStepData.optionsDto.compareIdx;

  const referenceValueList =
    mobileTestStepBySaveTextQuery.data as MobileTestStepData[];

  const filteredReferenceValueList = referenceValueList.filter(
    (item) => item.mobileOs === deviceOs,
  );

  return (
    <ErrorBoundaryWrapper>
      <DefinitionList
        term={'Condition*'}
        description={
          <Controller
            control={control}
            name={'optionsDto.dataType'}
            render={({ field }) => (
              <ListBoxHeadlessInController
                value={field.value}
                onChange={field.onChange}
                lists={dataTypeList}
                valueToSave={'serverName'}
                valueToShow={'name'}
                buttonValue={findDataType(field.value)}
              />
            )}
          />
        }
      />
      <DefinitionList
        term={'Reference Value*'}
        description={
          <Controller
            control={control}
            name={'optionsDto.compareIdx'}
            rules={{
              validate: (value) =>
                findDescriptionFromIdx(
                  Number(value),
                  filteredReferenceValueList,
                ) === undefined
                  ? '기준 값을 선택해 주세요.'
                  : true,
            }}
            render={({ field }) => (
              <>
                <ListBoxHeadlessInController
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                    if (
                      Number(value) !== Number(referenceValue) ||
                      referenceValue === undefined
                    ) {
                      unregister('optionsDto.operatorList');
                      replace({ inputValue: null, operator: null });
                    } else {
                      resetField('optionsDto.operatorList');
                      setValue(
                        'optionsDto.operatorList',
                        mobileTestStepData.optionsDto.operatorList,
                      );
                    }
                  }}
                  lists={filteredReferenceValueList}
                  valueToSave={'idx'}
                  valueToShow={'description'}
                  savedType={'string'}
                  buttonValue={
                    findDescriptionFromIdx(
                      Number(field.value),
                      filteredReferenceValueList,
                    ) || 'None'
                  }
                />
                <ErrorMessage
                  errors={errors}
                  name={'optionsDto.compareIdx'}
                  render={({ message }) => (
                    <p className="standard-error-message">{message}</p>
                  )}
                />
              </>
            )}
          />
        }
      />
    </ErrorBoundaryWrapper>
  );
};

export default EditTypeAndReferenceValue;
