import { CreateTestDataAutomation } from '@customTypes/testCase/type';

export const enhanceAutomationDataWithIdx = (
  data: CreateTestDataAutomation,
  caseIdx: string,
  projectIdx: string,
): CreateTestDataAutomation => {
  return {
    ...data,
    caseIdx: Number(caseIdx),
    projectIdx: Number(projectIdx),
  };
};
