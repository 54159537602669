import React, { useState } from 'react';
import InstalledAppTableHeader from './InstalledAppTable/InstalledAppTableHeader';
import InstalledAppTableBody from './InstalledAppTable/InstalledAppTableBody';
import { TableBodyContainer } from '@autosquare/common';

const InstalledAppTable = () => {
  const [headerLength, setHeaderLength] = useState(0);

  return (
    <div className="overflow-x-auto">
      <table className="w-full divide-y divide-gray-200">
        <InstalledAppTableHeader setHeaderLength={setHeaderLength} />
        <TableBodyContainer>
          <InstalledAppTableBody headerLength={headerLength} />
        </TableBodyContainer>
      </table>
    </div>
  );
};

export default InstalledAppTable;
