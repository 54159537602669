import { API } from '@api/endpoints';
import {
  AdminProjectListDetail,
  CreateAdminProjectData,
  MessengerAlarmType,
  MessengerPlatformRequiredInfo,
  ProjectListDetail,
  UpdateAdminProjectDetailData,
} from '@customTypes/index';
import { axiosInstance } from '../..';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { adminKeys } from '@queries/ide/adminKeys';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useParentPath } from '@hooks/index';
import { projectKeys } from '@queries/dashboard/project/projectKeys';

// GET
// 어드민 프로젝트 전체 목록 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Admin/getAllProjectList
const getAllAdminProjectList = async (): Promise<AdminProjectListDetail[]> => {
  const response = await axiosInstance.get(API.GET_ADMIN_ALL_PROJECT_LIST);
  return response.data;
};
export const useAllAdminProjectListQuery = () =>
  useQuery<AdminProjectListDetail[], ServerResponseErrorDetails>({
    queryKey: adminKeys.projectLists(),
    queryFn: () => getAllAdminProjectList(),
  });

// 어드민 프로젝트 상세 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Admin/getProjectDetail_1
const getAdminProjectDetail = async (
  idx: string,
): Promise<ProjectListDetail> => {
  const response = await axiosInstance.get(API.GET_ADMIN_PROJECT + idx);
  return response.data;
};
export const useAdminProjectDetailQuery = (idx: string) => {
  return useQuery<ProjectListDetail, ServerResponseErrorDetails>({
    queryKey: adminKeys.projectDetail(idx),
    queryFn: () => getAdminProjectDetail(idx),
  });
};

// 어드민 사용가능한 알림 리스트 확인
// https://dev.t-square.co.kr/swagger-ui/index.html#/Admin/getMessengerList
const getAdminAvailableMessengerList = async (): Promise<
  MessengerPlatformRequiredInfo[]
> => {
  const response = await axiosInstance.get(API.ADMIN_MESSENGER);
  return response.data;
};

export const useAdminAvailableMessengerListQuery = () => {
  return useQuery<MessengerPlatformRequiredInfo[], ServerResponseErrorDetails>({
    queryKey: adminKeys.messengers(),
    queryFn: () => getAdminAvailableMessengerList(),
  });
};

// POST
// 어드민 프로젝트 생성
// https://dev.t-square.co.kr/swagger-ui/index.html#/Admin/createProject
export const createAdminProject = async (
  data: CreateAdminProjectData,
): Promise<unknown> => {
  const response = await axiosInstance.post(API.CREATE_ADMIN_PROJECT, data);
  return response.data;
};
export const useCreateAdminProjectMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const projectListPage = useParentPath();

  return useMutation<
    unknown,
    ServerResponseErrorDetails,
    CreateAdminProjectData
  >({
    mutationFn: (data) => createAdminProject(data),
    onSuccess: () => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(adminKeys.all);
      navigate(projectListPage);
    },
  });
};

// 어드민 메신저 알림 테스트용 발신
// https://dev.t-square.co.kr/swagger-ui/index.html#/Admin/sendTestAlarm
const sendTestMessengerAlarm = async (
  data: MessengerAlarmType,
): Promise<unknown> => {
  const response = await axiosInstance.post(
    `${API.ADMIN_MESSENGER}/test`,
    data,
  );
  return response.data;
};

export const useSendTestMessengerAlarmMutation = () => {
  return useMutation<unknown, Error, MessengerAlarmType>({
    mutationFn: (data) => sendTestMessengerAlarm(data),
  });
};

// PUT
// 어드민 프로젝트 수정
// https://dev.t-square.co.kr/swagger-ui/index.html#/Admin/updateProject_1
const updateAdminProjectDetail = async (
  idx: string,
  data: UpdateAdminProjectDetailData,
): Promise<unknown> => {
  const response = await axiosInstance.put(
    API.UPDATE_ADMIN_PROJECT + idx,
    data,
  );
  return response.data;
};
export const useUpdateAdminProjectDetailMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const adminProjectListPage = useParentPath();

  return useMutation<
    unknown,
    ServerResponseErrorDetails,
    UpdateAdminProjectDetailData
  >({
    mutationFn: (data) => updateAdminProjectDetail(idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(adminKeys.all);
      navigate(adminProjectListPage);
    },
  });
};

// DELETE
// 어드민 프로젝트 삭제
// https://dev.t-square.co.kr/swagger-ui/index.html#/Admin/deleteProject
const deleteAdminProject = async (idx: string): Promise<boolean> => {
  const response = await axiosInstance.delete(`${API.ADMIN_PROJECT}/${idx}`);
  return response.data;
};
export const useDeleteAdminProjectMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const projectListPage = useParentPath();

  return useMutation<
    boolean,
    ServerResponseErrorDetails,
    UpdateAdminProjectDetailData
  >({
    mutationFn: () => deleteAdminProject(idx),
    onSuccess: () => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(adminKeys.projectLists());
      navigate(projectListPage);
    },
  });
};
