import {
  ArrowSpinner,
  Button,
  DoubleCheckDialogHeadlessUI,
} from '@autosquare/common';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';
import RemoteTestExecutionAlarmModal from '@components/TestExecution/shared/RemoteTestExecutionAlarmModal';

import TestExecutionContainer from '@components/TestExecution/TestExecutionContainer';
import { DeleteWebSpeedTestCaseInfo } from '@customTypes/ide/speedTest/Web/testCase/type';
import { useRemoteWebTestExecutionMutation } from '@lib/api/ide/remote/remote';

import { useDeleteWebSpeedTestCaseMutation } from '@lib/api/ide/speedWeb/webSpeedTestCase';

import React, { useState } from 'react';

interface Props {
  isTestExecutionOpened: boolean;
  setIsTestExecutionOpened: React.Dispatch<React.SetStateAction<boolean>>;
  testCaseIdx: number;
  isOpenedCautionDialog: boolean;
  setIsOpenedCautionDialog: React.Dispatch<React.SetStateAction<boolean>>;
  closeDialog: () => void;
  selectedArray: number[];
  resetSelectedArray: () => void;
}

const ExecutionContainerAndErrorDialog = ({
  isTestExecutionOpened,
  setIsTestExecutionOpened,
  testCaseIdx,
  isOpenedCautionDialog,
  setIsOpenedCautionDialog,
  closeDialog,
  selectedArray,
  resetSelectedArray,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExecutionErrorModalOpen, setIsExecutionErrorModalOpen] =
    useState(false);
  const deleteWebSpeedTestCaseMutation = useDeleteWebSpeedTestCaseMutation();

  const data: DeleteWebSpeedTestCaseInfo = { idxList: selectedArray };

  const remoteWebSpeedCaseExecutionMutation =
    useRemoteWebTestExecutionMutation('case');

  return (
    <>
      {isTestExecutionOpened && (
        <TestExecutionContainer
          setState={setIsTestExecutionOpened}
          idx={testCaseIdx}
          testType={'case'}
          deviceType={'web'}
          testLocation={'speed-test'}
          setIsModalOpen={setIsModalOpen}
          remoteWebExecutionMutation={remoteWebSpeedCaseExecutionMutation}
          setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
        />
      )}
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedCautionDialog}
        setIsOpened={setIsOpenedCautionDialog}
        type="caution"
        title={
          deleteWebSpeedTestCaseMutation.isError ? 'Error' : 'Delete Test Case'
        }
        subTitle={
          deleteWebSpeedTestCaseMutation.isError
            ? deleteWebSpeedTestCaseMutation.error.message
            : '해당 테스트 케이스를 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {!deleteWebSpeedTestCaseMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  deleteWebSpeedTestCaseMutation.mutate(data, {
                    onSuccess: () => {
                      closeDialog();
                      resetSelectedArray();
                    },
                  })
                }
              >
                Delete
              </Button>
            )}
            <Button type="button" variant="secondary" onClick={closeDialog}>
              Cancel
            </Button>
          </>
        }
      />
      {remoteWebSpeedCaseExecutionMutation.isLoading && <ArrowSpinner />}
      <RemoteTestExecutionAlarmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isExecutionErrorModalOpen={isExecutionErrorModalOpen}
        setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
      />
      <RemoteConnectionAlarmModal />
    </>
  );
};

export default ExecutionContainerAndErrorDialog;
