import { ErrorBoundaryWrapper, TooltipTop } from '@autosquare/common';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

import clsx from 'clsx';
import React, { ReactNode } from 'react';

type Props = {
  title: string | ReactNode;
  children: ReactNode;
  isTitleCenter?: boolean;
  tooltipClass?: string;
  tooltipWord?: string;
};

const SchedulerGridContainer = ({
  title,
  children,
  tooltipClass,
  tooltipWord,
  isTitleCenter = false,
}: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div className="grid grid-cols-5 gap-4">
        {tooltipWord ? (
          <div className="flex items-center gap-2.5">
            <p
              className={clsx(
                'text-sm font-medium leading-6 text-gray-900',
                isTitleCenter && 'pt-2',
              )}
            >
              {title}
            </p>
            <div className="group relative">
              <QuestionMarkCircleIcon
                className="size-5 cursor-help text-red-500"
                strokeWidth={2}
              />
              <TooltipTop
                positionClass={clsx(
                  'invisible group-hover:visible',
                  tooltipClass,
                )}
              >
                {tooltipWord}
              </TooltipTop>
            </div>
          </div>
        ) : (
          <div
            className={clsx(
              'text-sm font-medium leading-6 text-gray-900',
              isTitleCenter && 'pt-2',
            )}
          >
            {title}
          </div>
        )}
        <div className="col-span-4">{children}</div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default SchedulerGridContainer;
