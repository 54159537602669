import { TableBodyBasic } from '@autosquare/common';
import Tooltip from '@components/shared/Tooltip/Tooltip';
import { InstalledAppList } from '@customTypes/index';
import { truncateText } from '@utils/helper/truncateText';
import React from 'react';

type Props = {
  installedAppVersion: InstalledAppList;
  idx: number;
};

const InstalledAppTableBodyRow = ({ installedAppVersion, idx }: Props) => {
  const initialPackageName = installedAppVersion.packageName;
  const packageName = truncateText(initialPackageName);

  return (
    <tr>
      <TableBodyBasic>{idx + 1}</TableBodyBasic>
      <TableBodyBasic>{installedAppVersion.appName}</TableBodyBasic>
      <TableBodyBasic showTooltipOnHover={initialPackageName.length > 30}>
        {packageName}
        <Tooltip positionClass="invisible group-hover:visible left-2 bottom-10">
          {initialPackageName}
        </Tooltip>
      </TableBodyBasic>
      <TableBodyBasic>{installedAppVersion.appVersion}</TableBodyBasic>
      <TableBodyBasic>{installedAppVersion.buildVersion}</TableBodyBasic>
      <TableBodyBasic>{installedAppVersion.osType}</TableBodyBasic>
    </tr>
  );
};

export default InstalledAppTableBodyRow;
