import React from 'react';
import {
  ErrorBoundaryWrapper,
  PagingHeadingsInAutosquare,
} from '@autosquare/common';
import SearchForm from '@components/SearchForm/SearchForm';
import { useLocation, useParams } from 'react-router-dom';
import SchedulerListTable from './components/SchedulerListTable';
import { useFilteredSchedulerListQuery } from '@lib/api/ide/scheduler/scheduler';
import getFirstGenerationDateToDateType from '@utils/static/getFirstGenerationDateToDateType';

const SchedulerList = () => {
  const { projectIdx } = useParams();
  const { pathname } = useLocation();

  const filteredSchedulerListQuery = useFilteredSchedulerListQuery();
  const schedulerLists = filteredSchedulerListQuery.data;
  const initialCreatedAt = schedulerLists?.[0]?.initialCreatedAt;
  const formattedCreatedAt = getFirstGenerationDateToDateType(initialCreatedAt);

  const breadcrumbs = [
    { title: 'UI Test', href: `/ide/projects/${projectIdx}/ui-test/overview` },
    { title: 'Scheduler', href: pathname },
    { title: 'List', href: pathname },
  ];

  const pageHeadingButtons = [{ title: 'Create scheduler', href: 'create' }];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Scheduler List'}
        pageHeadingButtons={pageHeadingButtons}
      />
      <SearchForm initialCreatedAt={formattedCreatedAt}>
        <SearchForm.SchedulerTestType />
        <SearchForm.SchedulerStatus />
      </SearchForm>
      <SchedulerListTable />
    </ErrorBoundaryWrapper>
  );
};

export default SchedulerList;
