import { ErrorBoundaryWrapper } from '@autosquare/common';
import TestStepDetailBasicDefinition from '@components/shared/TestStepDetail/TestStepDetailBasicDefinition';
import { WebTestStepData } from '@customTypes/testStep/type';
import { useWebTestStepByCommandQuery } from '@lib/api/ide/web/webTestStep';
import {
  findDataType,
  findOperatorLabel,
  findWebTestStepDescriptionFromIdx,
} from '@utils/index';
import React from 'react';

type Props = {
  webTestStepData: WebTestStepData;
};

const DataValidationDetailBasic = ({ webTestStepData }: Props) => {
  const { data: webTestStepBySaveTextList } = useWebTestStepByCommandQuery();

  const formattedOperatorValues = webTestStepData.optionsDto.operatorList.map(
    (operator) =>
      `${findOperatorLabel(operator.operator)}${operator.inputValue}`,
  );
  const operatorSummary = formattedOperatorValues.join('\n');

  return (
    <ErrorBoundaryWrapper>
      <TestStepDetailBasicDefinition
        term={'Condition'}
        description={findDataType(webTestStepData.optionsDto.dataType)}
      />
      <TestStepDetailBasicDefinition
        term={'Reference Value'}
        description={
          findWebTestStepDescriptionFromIdx(
            Number(webTestStepData.optionsDto.compareIdx),
            webTestStepBySaveTextList,
          ) || 'None'
        }
      />
      <TestStepDetailBasicDefinition
        term={'Input Data'}
        description={operatorSummary}
      />
    </ErrorBoundaryWrapper>
  );
};

export default DataValidationDetailBasic;
