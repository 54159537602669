import { RootState } from '@app/store';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import { UpdateSpeedSchedulerData } from '@customTypes/ide/speedScheduler/speedScheduler';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

const SpeedTestSchedulerDetailRepeat = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<UpdateSpeedSchedulerData>();

  const isRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer
        title="Repeat"
        tooltipClass={clsx('-left-14 bottom-7 text-xs')}
        tooltipWord={
          '속도 측정을 몇 번 반복할지 횟수를 입력해 주세요. (처음과 마지막 속도 측정을 제외한 나머지 값들의 평균이 결과로 저장 됩니다.)'
        }
      >
        <input
          type="number"
          className={clsx(
            'input-base hide-input-number-arrow',
            !isRunning && 'bg-gray-100',
          )}
          {...register('repeatNum', { valueAsNumber: true })}
          disabled={!isRunning}
        />
        <ErrorMessage
          errors={errors}
          name={'repeatNum'}
          render={({ message }) => (
            <p className="standard-error-message">{message}</p>
          )}
        />
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default SpeedTestSchedulerDetailRepeat;
