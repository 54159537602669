import { DialogButton } from '@components/Dialog';
import { DialogButtonPanels } from '@components/Dialog/DialogComponents';
import { CommonDialog } from '@components/Dialog';
import React from 'react';
import {
  TestDataAutomationInfo,
  UpdateTestDataAutomation,
} from '@customTypes/testCase/type';
import { UseMutationResult } from '@tanstack/react-query';
import { ServerResponseErrorDetails } from '@customTypes/type';

type UpdateDataAutomationErrorDialogProps = {
  isUpdateErrorModalOpen: boolean;
  setIsUpdateErrorModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateAutomationMutation: UseMutationResult<
    TestDataAutomationInfo,
    ServerResponseErrorDetails,
    UpdateTestDataAutomation
  >;
};

const UpdateDataAutomationErrorDialog = ({
  isUpdateErrorModalOpen,
  setIsUpdateErrorModalOpen,
  updateAutomationMutation,
}: UpdateDataAutomationErrorDialogProps) => {
  return (
    <CommonDialog
      isOpen={isUpdateErrorModalOpen}
      setIsOpen={setIsUpdateErrorModalOpen}
      iconType="caution"
      title="Error"
      subTitle={
        updateAutomationMutation.isError &&
        updateAutomationMutation.error.message
      }
    >
      <DialogButtonPanels>
        <DialogButton
          type="button"
          variant="secondary"
          onClick={() => setIsUpdateErrorModalOpen(false)}
        >
          Cancel
        </DialogButton>
      </DialogButtonPanels>
    </CommonDialog>
  );
};

export default UpdateDataAutomationErrorDialog;
