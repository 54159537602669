import { RootState } from '@app/store';
import {
  ErrorBoundaryWrapper,
  PageHeadingButtons,
  PagingHeadings,
  RoleAuth,
} from '@autosquare/common';
import { useAuthUserInfo, useParentPath } from '@hooks/index';
import { useProjectMemberDetailQuery } from '@lib/api/dashboard/queryHooks';
import { setIsEdited } from '@store/index';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
  setIsRemoved: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProjectMembersPagingHeadings = ({ setIsRemoved }: Props) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const dispatch = useDispatch();
  const isEdited = useSelector(
    (state: RootState) => state.projectMemberDetail.isEdited,
  );

  const { data, isLoading, isFetching } = useProjectMemberDetailQuery();

  const {
    userProjectRole,
    userId: loginUserId,
    userAuthRole,
  } = useAuthUserInfo();

  const isAdminMemberInProject =
    userProjectRole === 0 || userAuthRole === RoleAuth.Admin;

  const memberDetailUserId = data?.userId;

  const memberListPage = useParentPath();
  const currentPage = pathname + search;

  const breadcrumbs = isEdited
    ? [
        { title: 'Project Settings', href: memberListPage },
        { title: 'Members', href: memberListPage },
        { title: 'Detail', href: currentPage },
        { title: 'Edit', href: currentPage },
      ]
    : [
        { title: 'Project Settings', href: memberListPage },
        { title: 'Members', href: memberListPage },
        { title: 'Detail', href: currentPage },
      ];

  const cancelButton: PageHeadingButtons[] = [
    {
      title: 'Cancel',
      onClick: () => navigate(memberListPage),
      type: 'button',
      variant: 'secondary',
    },
  ];

  const pageHeadingButtons: PageHeadingButtons[] =
    isLoading || isFetching
      ? null
      : isEdited
        ? [
            {
              title: 'Cancel',
              onClick: () => dispatch(setIsEdited(false)),
              type: 'button',
              variant: 'secondary',
            },
            {
              title: 'Save',
              type: 'submit',
              variant: 'primary',
              form: 'edit-member-form',
              id: 'edit-submit-button',
            },
          ]
        : [
            ...cancelButton,
            {
              title: 'Edit',
              type: 'button',
              variant: 'primary',
              onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                dispatch(setIsEdited(true));
              },
              id: 'edit-button',
            },
            {
              title: 'Remove',
              onClick: () => setIsRemoved(true),
              type: 'button',
              variant: 'caution',
            },
          ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={isEdited ? 'Edit Member' : 'Member Detail'}
        pageHeadingButtons={
          loginUserId === memberDetailUserId
            ? cancelButton
            : isAdminMemberInProject
              ? pageHeadingButtons
              : cancelButton
        }
      />
    </ErrorBoundaryWrapper>
  );
};

export default ProjectMembersPagingHeadings;
