import {
  ErrorBoundaryWrapper,
  SpeedTestResultTableDataCell,
  SpeedTestResultTableHeader,
  Time,
} from '@autosquare/common';
import { WebSpeedTestResultData } from '@customTypes/ide/speedTest/Web/testResult/type';
import { TestResultStatus } from '@customTypes/index';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

type Props = {
  resultData: WebSpeedTestResultData;
};

const WebSpeedTestResultsOverviewTable = ({ resultData }: Props) => {
  const navigate = useNavigate();
  const { projectIdx } = useParams();
  const { pathname, search } = useLocation();

  const resultWithUUID = resultData?.list?.map((item) => ({
    ...item,
    index: crypto.randomUUID(),
  }));

  const isDashboard =
    !isWordInLocationPathname('speed-test') &&
    !isWordInLocationPathname('dashboard');

  const navigateUrl = (
    resultIdx: number,
    groupIdx: number,
    channel: string,
    dateTime: string,
  ) => {
    return isDashboard
      ? `/projects/${projectIdx}/web/speed/test-result/detail?result=${resultIdx}&group=${groupIdx}&order-by=decs&channel=${channel}&start=${dayjs(dateTime).subtract(14, 'day').format('YYYY-MM-DD')}&end=${dayjs(dateTime).format('YYYY-MM-DD')}`
      : `/ide/projects/${projectIdx}/speed-test/web/test-result/detail?result=${resultIdx}&group=${groupIdx}&order-by=desc&channel=${channel}&start=${dayjs(dateTime).subtract(14, 'day').format('YYYY-MM-DD')}&end=${dayjs(dateTime).format('YYYY-MM-DD')}`;
  };

  return (
    <ErrorBoundaryWrapper>
      <div className="flex flex-col gap-4">
        {resultWithUUID?.map((webSpeedTestResult) => (
          <div
            key={webSpeedTestResult.index}
            className="flex w-full flex-col gap-3.5"
          >
            <div className="flex w-full items-center justify-between leading-tight">
              <h2 className="text-sm font-semibold leading-tight text-black">
                {webSpeedTestResult.menu} Loading Time 비교측정 결과
              </h2>
              <button
                type="button"
                className={clsx(
                  'flex items-center justify-center',
                  webSpeedTestResult.services[0].result_enum === 'RUNNING'
                    ? 'text-gray-400'
                    : 'text-gray-900',
                )}
                disabled={
                  webSpeedTestResult.services[0].result_enum === 'RUNNING'
                }
                onClick={() => {
                  localStorage.setItem('entryPoint', pathname + search);
                  navigate(
                    navigateUrl(
                      webSpeedTestResult.services[0].idx,
                      webSpeedTestResult.group_idx,
                      webSpeedTestResult.services[0].channel,
                      webSpeedTestResult.date_time,
                    ),
                    {
                      state: {
                        status: webSpeedTestResult.services[0].result_enum,
                        page: 1,
                      },
                    },
                  );
                }}
              >
                <div className="text-center text-xs font-semibold leading-tight">
                  Detail
                </div>
                <ChevronRightIcon className="size-5" />
              </button>
            </div>
            <div className="gray-xy-scrollbar w-full overflow-x-auto">
              <table className="min-w-full table-auto">
                <thead className="text-sm font-semibold leading-tight text-gray-900">
                  <tr className="border-b border-solid border-gray-200 bg-gray-50">
                    <SpeedTestResultTableHeader width="w-40">
                      Type
                    </SpeedTestResultTableHeader>
                    <SpeedTestResultTableHeader width="w-40">
                      DateTime
                    </SpeedTestResultTableHeader>
                    {webSpeedTestResult.services.map((service) => (
                      <SpeedTestResultTableHeader
                        key={service.service_group_idx}
                      >
                        {service.service}
                      </SpeedTestResultTableHeader>
                    ))}
                  </tr>
                </thead>
                <tbody className="text-sm font-normal leading-tight text-gray-900">
                  <tr className="border-b border-solid border-gray-400">
                    <SpeedTestResultTableDataCell>
                      {webSpeedTestResult.services[0].channel}
                    </SpeedTestResultTableDataCell>
                    <SpeedTestResultTableDataCell>
                      <p>{webSpeedTestResult.date_time.split(' ')[0]}</p>
                      <div className="flex items-center gap-1 text-sm font-normal leading-tight text-gray-500">
                        <Time />
                        {webSpeedTestResult.date_time.split(' ')[1]}
                      </div>
                    </SpeedTestResultTableDataCell>
                    {webSpeedTestResult.services.map((service) => (
                      <SpeedTestResultTableDataCell
                        key={service.service_group_idx}
                        isOverThreeSeconds={
                          service.result_enum === TestResultStatus.Pass &&
                          service.load_speed_average > 3
                        }
                      >
                        {service.result_enum === TestResultStatus.Pass
                          ? service.load_speed_average
                          : service.result_enum === TestResultStatus.NA
                            ? 'N/A'
                            : TestResultStatus.Running
                              ? 'Running'
                              : TestResultStatus.Fail && 'Fail'}
                      </SpeedTestResultTableDataCell>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default WebSpeedTestResultsOverviewTable;
