import React from 'react';

const OverviewSkeleton = () => {
  // 스피드 테스트 오버뷰 작업 끝내면 해당 컴포넌트 삭제
  return (
    <>
      <div className="mt-8 flow-root">
        <div className="pb-3">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-semibold leading-6 text-gray-900">
              Mobile
            </p>
            <svg
              className="size-5 shrink-0 text-gray-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>
            <p className="truncate text-sm text-gray-500">
              최근 테스트 결과 목록
            </p>
          </div>
        </div>
        <div className="w-full animate-pulse">
          {Array.from({ length: 6 }, (_, i) => (
            <div
              className="my-6 h-4 rounded odd:bg-gray-200 even:bg-gray-300"
              key={i}
            />
          ))}
        </div>
        <div className="py-3">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-semibold leading-6 text-gray-900">Web</p>
            <svg
              className="size-5 shrink-0 text-gray-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>
            <p className="truncate text-sm text-gray-500">
              최근 테스트 결과 목록
            </p>
          </div>
        </div>
        <div className="w-full animate-pulse">
          {Array.from({ length: 6 }, (_, i) => (
            <div
              className="my-6 h-4 rounded odd:bg-gray-200 even:bg-gray-300"
              key={i}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default OverviewSkeleton;
