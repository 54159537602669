import { useState } from 'react';

const useTextareaResize = () => {
  const [userHeight, setUserHeight] = useState<string | null>(null);

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserHeight(`${e.target.scrollHeight}px`);
    if (!userHeight) {
      e.target.style.height = 'auto';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
  };

  return { userHeight, handleInput };
};

export default useTextareaResize;
