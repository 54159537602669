import {
  TableBodyBasic,
  TableBodyGray,
  TableContainer,
  TableHeader,
  TableHeaderContainer,
  TableNoList,
} from '@autosquare/common';

import React, { useRef, useEffect } from 'react';

import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';

interface Props {
  selectedTestCase: number[];
  setSelectedTestCase: React.Dispatch<React.SetStateAction<number[]>>;
}

const MobileTestCaseExportTable = ({
  selectedTestCase,
  setSelectedTestCase,
}: Props) => {
  const mobileTestCaseQuery = useMobileTestCaseListQuery();
  const mobileTestCaseList = mobileTestCaseQuery.data;

  const selectAllTestCase = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    if (target.checked) {
      const allIdx = mobileTestCaseList.map((item) => item.idx);
      setSelectedTestCase(allIdx);
    } else {
      setSelectedTestCase([]);
    }
  };

  const selectTestCase = (
    idx: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const target = e.target as HTMLInputElement;
    if (target.checked) {
      setSelectedTestCase([...selectedTestCase, idx]);
    } else {
      setSelectedTestCase(selectedTestCase.filter((item) => item !== idx));
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const isIndeterminate =
      selectedTestCase.length > 0 &&
      selectedTestCase.length < mobileTestCaseList?.length;
    inputRef.current.indeterminate = isIndeterminate;
  }, [selectedTestCase]);

  const tableHeaders = [
    <input
      type="checkbox"
      className="flex h-4 w-4 items-center justify-center rounded border border-solid border-gray-300 text-indigo-600 focus:ring-indigo-600"
      ref={inputRef}
      checked={selectedTestCase.length > 0}
      onChange={(e) => selectAllTestCase(e)}
    />,
    'Title',
    'Description',
    'Test Step Count',
    'Priority',
    'Updated at',
  ];

  return (
    <TableContainer>
      <TableHeaderContainer>
        {tableHeaders.map((header, index) => (
          <TableHeader key={index}>{header}</TableHeader>
        ))}
      </TableHeaderContainer>
      <tbody className="divide-y divide-gray-200">
        {mobileTestCaseList?.length === 0 ? (
          <TableNoList colSpan={tableHeaders?.length}>
            해당 프로젝트에 생성된 테스트 케이스가 존재하지 않습니다.
          </TableNoList>
        ) : (
          mobileTestCaseList?.map((mobileTestCase) => (
            <tr
              key={mobileTestCase.idx}
              className={`${
                selectedTestCase.includes(mobileTestCase.idx) &&
                'relative bg-gray-50'
              }`}
            >
              <td className="table-data-basic relative">
                {selectedTestCase.includes(mobileTestCase.idx) && (
                  <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                )}
                <input
                  type="checkbox"
                  className="flex h-4 w-4 items-center justify-center rounded border border-solid border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  checked={selectedTestCase.includes(mobileTestCase.idx)}
                  onChange={(e) => selectTestCase(mobileTestCase.idx, e)}
                />
              </td>
              <TableBodyBasic>{mobileTestCase.title}</TableBodyBasic>
              <td className="table-data-cell-gray max-w-[17rem] break-words">
                {mobileTestCase.description}
              </td>
              <TableBodyGray>
                {`AOS: ${mobileTestCase.steps.aosStepCount} / iOS: ${mobileTestCase.steps.iosStepCount}`}
              </TableBodyGray>
              <TableBodyGray>{mobileTestCase.priority}</TableBodyGray>
              <TableBodyGray>{mobileTestCase.updatedAt}</TableBodyGray>
            </tr>
          ))
        )}
      </tbody>
    </TableContainer>
  );
};

export default MobileTestCaseExportTable;
