import {
  Breadcrumbs,
  ErrorBoundaryWrapper,
  PageHeadingButtons,
  PagingHeadingsNavigate,
} from '@autosquare/common';

import React from 'react';
import { useLocation } from 'react-router-dom';

import TestCaseImportStep from './components/TestCaseImportStep';
import TestCaseImportUploadFile from './components/TestCaseImportUploadFile';
import TestCaseImportShowingResults from './components/TestCaseImportShowingResults';
import TestCaseImportCompleteResults from './components/TestCaseImportCompleteResults';
import { useParentPath } from '@hooks/index';
import {
  TestCaseImportProvider,
  useTestCaseImport,
} from '@contexts/ide/TestCaseImportContext';

const MobileTestCaseImportContent = () => {
  const { pathname } = useLocation();
  const {
    stepNumber,
    setStepNumber,
    clearAll,
    isDatFile,
    isCsvFile,
    isImportFormDataLoading,
  } = useTestCaseImport();

  const projectPath = useParentPath(3);
  const overviewPath = `${projectPath}/overview`;
  const testCasePath = useParentPath();

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'UI Test', href: overviewPath },
    { title: 'Mobile', href: testCasePath },
    { title: 'Test Case', href: testCasePath },
    { title: 'Import', href: pathname },
  ];

  const pageHeadingButtons: PageHeadingButtons[] =
    stepNumber === 2
      ? [
          {
            title: 'Clear',
            variant: 'caution',
            type: 'button',
            onClick: clearAll,
          },
          {
            title: 'Import',
            variant: 'primary',
            type: isDatFile ? 'button' : 'submit',
            form: isCsvFile ? 'import-csv-file' : undefined,
            disabled: isImportFormDataLoading,
            onClick: () => {
              if (isDatFile) {
                setStepNumber(3);
              }
            },
          },
        ]
      : null;

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Case Import'}
        pageHeadingButtons={pageHeadingButtons}
        titleDescription="Export된 테스트 케이스 또는 CSV 파일을 Import하여 테스트 케이스 목록을 생성할 수 있습니다."
      />
      <div className="h-[calc(100vh-10rem)] px-4 sm:px-6 lg:px-8">
        <div className="mt-8 h-full">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <TestCaseImportStep stepNumber={stepNumber} />
              {stepNumber === 1 ? (
                <TestCaseImportUploadFile />
              ) : stepNumber === 2 ? (
                <TestCaseImportShowingResults />
              ) : (
                <TestCaseImportCompleteResults />
              )}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

const MobileTestCaseImport = () => {
  return (
    <TestCaseImportProvider>
      <MobileTestCaseImportContent />
    </TestCaseImportProvider>
  );
};

export default MobileTestCaseImport;
