import { ErrorBoundaryWrapper } from '@autosquare/common';
import React, { ReactElement } from 'react';

type Props = {
  term: string;
  description: string | ReactElement | boolean;
};

const DefinitionList = ({ term, description }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div className="border-b border-gray-200 sm:flex sm:items-center sm:px-6 sm:py-4">
        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
          {term}
        </dt>
        <dd className="mt-1 break-all text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0 sm:w-full">
          {description}
        </dd>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default DefinitionList;
