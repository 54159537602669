import { Checkbox, DateInTable, TableBodyBasic } from '@autosquare/common';
import { DeviceListInProject } from '@customTypes/index';
import React from 'react';
import DeviceStatusInProject from './DeviceStatusInProject';
import DeviceInfoCell from './DeviceInfoCell';

interface AdminDeviceRowProps {
  device: DeviceListInProject;
  selectedArray: number[];
  selectArray: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  idx: number;
  isActivate: boolean;
}

const AdminDeviceRow = ({
  device,
  selectedArray,
  selectArray,
  idx,
  isActivate,
}: AdminDeviceRowProps) => {
  return (
    <>
      <TableBodyBasic>
        <Checkbox
          checked={selectedArray.includes(device.idx)}
          onChange={(e) => selectArray(device.idx, e)}
          disabled={!isActivate}
        />
      </TableBodyBasic>
      <TableBodyBasic disabled={!isActivate}>{idx + 1}</TableBodyBasic>
      <DeviceInfoCell
        model={device.deviceModel}
        name={device.deviceName}
        isActivate={isActivate}
      />
      <DeviceInfoCell
        model={device.deviceOs}
        name={device.deviceType}
        isActivate={isActivate}
      />
      <TableBodyBasic>
        <DateInTable dateTime={device.registeredAt} disabled={!isActivate} />
      </TableBodyBasic>
      <TableBodyBasic>
        <DeviceStatusInProject device={device} disabled={!isActivate} />
      </TableBodyBasic>
    </>
  );
};

export default AdminDeviceRow;
