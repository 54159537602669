import React from 'react';
import {
  DefaultSkeleton,
  ErrorBoundaryWrapper,
  ErrorMessage,
  Pagination,
  PagingHeadingsInAutosquare,
  SpeedTestResultTableSkeleton,
} from '@autosquare/common';
import SearchForm from '@components/SearchForm/SearchForm';
import { useSearchSpeedTestMobileResultQuery } from '@lib/api/ide/speedMobile/speedMobileTestResult';
import { useLocation, useSearchParams } from 'react-router-dom';
import IdeMobileSpeedTestResultTable from './components/IdeMobileSpeedTestResultTable';
import getFirstGenerationDateToDateType from '@utils/static/getFirstGenerationDateToDateType';

const IdeMobileSpeedTestResult = () => {
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));
  const size = Number(searchParams.get('size'));

  const searchSpeedTestMobileResultQuery =
    useSearchSpeedTestMobileResultQuery();
  const speedMobileTestResultData = searchSpeedTestMobileResultQuery.data;
  const total = speedMobileTestResultData?.totalElements;

  const initialCreatedAt = speedMobileTestResultData?.initialCreatedAt;
  const formattedCreatedAt = getFirstGenerationDateToDateType(initialCreatedAt);

  const currentPage = pathname + search;

  const breadcrumbs = [
    {
      title: 'Speed Test',
      href: `${pathname.split('/').slice(0, -2).join('/')}/overview`,
    },
    { title: 'Mobile', href: currentPage },
    { title: 'Test Result', href: currentPage },
    { title: 'List', href: currentPage },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Result List'}
      />
      <SearchForm
        calendarSubTitle="Date"
        isFunctionalTest={false}
        initialCreatedAt={formattedCreatedAt}
      >
        <SearchForm.ResultTestType isFunctionalTest={false} />
      </SearchForm>
      {searchSpeedTestMobileResultQuery.isLoading ? (
        <SpeedTestResultTableSkeleton />
      ) : searchSpeedTestMobileResultQuery.isError ? (
        <ErrorMessage>
          {searchSpeedTestMobileResultQuery.error.message}
        </ErrorMessage>
      ) : (
        <IdeMobileSpeedTestResultTable resultData={speedMobileTestResultData} />
      )}
      {searchSpeedTestMobileResultQuery.isLoading ? (
        <DefaultSkeleton number={1} height={'h-4'} outMargin={'my-4'} />
      ) : (
        total > 5 && (
          <Pagination
            page={page}
            total={total}
            size={size}
            isDashboard={false}
          />
        )
      )}
    </ErrorBoundaryWrapper>
  );
};

export default IdeMobileSpeedTestResult;
