import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  DefaultSkeleton,
  ErrorBoundaryWrapper,
  Pagination,
  PagingHeadingsNavigate,
  ResultsToolbar,
} from '@autosquare/common';

import { useWebTestResultSearchListQuery } from '@lib/api/ide/web/webTestResult';

import WebTestResultTable from './components/WebTestResultTable';
import SearchForm from '@components/SearchForm/SearchForm';
import getFirstGenerationDateToDateType from '@utils/static/getFirstGenerationDateToDateType';

const IdeWebTestResult = () => {
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));
  const size = Number(searchParams.get('size'));

  const webTestResultSearchListQuery = useWebTestResultSearchListQuery();
  const webTestResultSearchList = webTestResultSearchListQuery.data;

  const initialCreatedAt = webTestResultSearchList?.initialCreatedAt;
  const formattedCreatedAt = getFirstGenerationDateToDateType(initialCreatedAt);

  const currentUrl = pathname + search;

  const breadcrumbs = [
    {
      title: 'UI Test',
      href: `${pathname.split('/').slice(0, -2).join('/')}/overview`,
    },
    { title: 'Web', href: currentUrl },
    { title: 'Test Result', href: currentUrl },
    { title: 'List', href: currentUrl },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Result List'}
      />
      <SearchForm calendarSubTitle="Date" initialCreatedAt={formattedCreatedAt}>
        <SearchForm.ResultTestType />
        <SearchForm.Browser />
        <SearchForm.ExecutionResult />
      </SearchForm>
      <ResultsToolbar totalResults={webTestResultSearchList?.totalElements} />
      <WebTestResultTable />
      {webTestResultSearchListQuery.isLoading ? (
        <DefaultSkeleton number={1} height={'h-4'} outMargin={'my-4'} />
      ) : (
        webTestResultSearchList?.content.length !== 0 && (
          <Pagination
            page={page}
            total={webTestResultSearchList?.totalElements}
            size={size}
            isDashboard={false}
          />
        )
      )}
    </ErrorBoundaryWrapper>
  );
};

export default IdeWebTestResult;
