import React from 'react';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import DetailInfo from './ProjectSettingsDetailNormal/DetailInfo';
import DetailFeatures from '../../shared/DetailFeatures';
import DetailMessenger from '../../shared/DetailMessenger';
import DetailAutoRegister from '../../shared/DetailAutoRegister';

const ProjectSettingsDetailNormal = () => {
  return (
    <ErrorBoundaryWrapper>
      <DetailInfo />
      <DetailFeatures />
      <DetailMessenger />
      <DetailAutoRegister />
    </ErrorBoundaryWrapper>
  );
};

export default ProjectSettingsDetailNormal;
