import { LocationCategory } from '@lib/api/dashboard/overview/overview';
import clsx from 'clsx';
import React from 'react';

type Props = {
  testLocationTabList: {
    title: LocationCategory;
    content: { id: number; post: React.JSX.Element }[];
  }[];
  categorySelectedIndex: number;
};

const TestCategoryContents = ({
  testLocationTabList,
  categorySelectedIndex,
}: Props) => {
  return (
    <div className="mt-3 w-full">
      {testLocationTabList.map(({ title, content }, index) => (
        <div
          key={title}
          role="tabpanel"
          id={`panel-${index}`}
          className={clsx(categorySelectedIndex !== index && 'hidden')}
        >
          <ul>
            {content.map((item) => (
              <li key={item.id}>{item.post}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default TestCategoryContents;
