import { ErrorBoundaryWrapper } from '@autosquare/common';
import { AdminUserEmail, AdminUserEmailSchema } from '@customTypes/index';
import { PlusIcon } from '@heroicons/react/20/solid';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import SuccessToSendEmail from './AddSystemUsersInput/SuccessToSendEmail';

type Props = {
  userEmailList: AdminUserEmail[];
  setUserEmailList: React.Dispatch<React.SetStateAction<AdminUserEmail[]>>;
  isSendInvite: boolean;
};

const AddSystemUsersInput = ({
  userEmailList,
  setUserEmailList,
  isSendInvite,
}: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<AdminUserEmail>({
    resolver: zodResolver(AdminUserEmailSchema),
  });

  const onSubmit: SubmitHandler<AdminUserEmail> = (data) => {
    if (
      userEmailList.some(
        (user) => user.inviteUserEmail === data.inviteUserEmail,
      )
    ) {
      setError('inviteUserEmail', { message: 'Email already exists' });
    } else {
      setUserEmailList((prev) => [...prev, data]);
      reset();
      clearErrors('inviteUserEmail');
    }
  };

  return (
    <ErrorBoundaryWrapper>
      <form className="py-6" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="flex">
          <input
            type="email"
            className="input-base rounded-r-none disabled:bg-gray-100"
            placeholder="Enter an email"
            {...register('inviteUserEmail')}
            disabled={isSendInvite}
          />
          <button
            type="submit"
            className="flex h-9 items-center justify-start rounded-r-lg bg-gray-300 p-2 hover:bg-gray-500 disabled:hover:bg-gray-300"
            disabled={isSendInvite}
          >
            <PlusIcon className="size-5 text-white" />
          </button>
        </div>
        <ErrorMessage
          errors={errors}
          name="inviteUserEmail"
          render={({ message }) => (
            <p className="error-message pt-3">{message}</p>
          )}
        />
      </form>
      {isSendInvite && <SuccessToSendEmail />}
    </ErrorBoundaryWrapper>
  );
};

export default AddSystemUsersInput;
