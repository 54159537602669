import { ErrorBoundaryWrapper } from '@autosquare/common';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';
import {
  RemoteControl,
  RemoteControlInfo,
  RemoteStatus,
} from '@customTypes/index';
import { PencilSquareIcon } from '@heroicons/react/20/solid';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { ErrorMessage } from '@hookform/error-message';
import { useUpdateAliasRemoteControlMutation } from '@lib/api/ide/remote/remote';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

type Props = {
  remoteIDE: RemoteControlInfo;
  index: number;
  setRemoteControlInfo: React.Dispatch<React.SetStateAction<RemoteControl>>;
  validIndex: number;
};

const RemoteIDE = ({
  remoteIDE,
  index,
  setRemoteControlInfo,
  validIndex,
}: Props) => {
  const [isAliasEdited, setIsAliasEdited] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { status, ...restData } = remoteIDE;

  const handleRadioChange = () => {
    setRemoteControlInfo(restData);
  };

  useEffect(() => {
    if (index === validIndex) {
      setRemoteControlInfo(restData);
    }
  }, [index, remoteIDE]);

  // 원격 PC 별칭 수정 로직
  const updateAliasRemoteControlMutation =
    useUpdateAliasRemoteControlMutation();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<RemoteControl>({
    defaultValues: {
      idx: remoteIDE.idx,
      userId: remoteIDE.userId,
      alias: remoteIDE.alias,
    },
  });

  const onSubmit: SubmitHandler<RemoteControl> = (data) => {
    updateAliasRemoteControlMutation.mutate(data, {
      onSuccess: () => {
        setIsAliasEdited(false);
      },
    });
  };

  return (
    <ErrorBoundaryWrapper>
      <li
        key={remoteIDE.idx}
        className="flex items-center justify-between gap-3"
      >
        <div className="flex items-center gap-x-3">
          <input
            type="radio"
            className="input-radio-congress-blue disabled:text-gray-300"
            defaultChecked={index === validIndex}
            name="remoteIDEInfo"
            id={`remoteIDEInfo-${remoteIDE.idx}`}
            onChange={handleRadioChange}
            disabled={
              remoteIDE.status === RemoteStatus.DISCONNECTED ||
              (remoteIDE.status === RemoteStatus.CONNECTED &&
                validIndex !== index)
            }
          />
          {!isAliasEdited && (
            <>
              <label
                className={clsx(
                  'text-sm font-normal leading-normal',
                  remoteIDE.status === RemoteStatus.WAITING ||
                    (remoteIDE.status === RemoteStatus.CONNECTED &&
                      index === validIndex)
                    ? 'text-gray-900'
                    : 'text-gray-300',
                )}
                htmlFor={`remoteIDEInfo-${remoteIDE.idx}`}
              >
                {remoteIDE.alias}
              </label>
              {remoteIDE.status === RemoteStatus.CONNECTED &&
                validIndex === index && (
                  <div className="size-3 rounded-full bg-green-400" />
                )}
            </>
          )}
        </div>
        {isAliasEdited ? (
          <ErrorBoundaryWrapper>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex w-full items-center gap-3"
            >
              <input
                type="text"
                className="input-base w-full"
                {...register('alias', {
                  required: '1자 이상 20자 이하로 입력해 주세요.',
                  minLength: {
                    value: 1,
                    message: '1자 이상 20자 이하로 입력해 주세요.',
                  },
                  maxLength: {
                    value: 20,
                    message: '1자 이상 20자 이하로 입력해 주세요.',
                  },
                })}
              />
              <button
                type="submit"
                disabled={updateAliasRemoteControlMutation.isLoading}
              >
                <CheckIcon className="text-congress-blue size-5" />
              </button>
              <button
                type="button"
                onClick={() => {
                  reset();
                  updateAliasRemoteControlMutation.reset();
                  setIsAliasEdited(false);
                }}
              >
                <XMarkIcon className="size-5" />
              </button>
            </form>
          </ErrorBoundaryWrapper>
        ) : (
          <ErrorBoundaryWrapper>
            <button
              type="button"
              onClick={() => setIsAliasEdited(true)}
              className="disabled:text-gray-300"
              disabled={
                remoteIDE.status === RemoteStatus.DISCONNECTED ||
                (remoteIDE.status === RemoteStatus.CONNECTED &&
                  index !== validIndex)
              }
            >
              <PencilSquareIcon className="size-5" />
            </button>
          </ErrorBoundaryWrapper>
        )}
      </li>
      {updateAliasRemoteControlMutation.isError ? (
        <p className="error-message">
          {updateAliasRemoteControlMutation.error.message}
        </p>
      ) : (
        <ErrorMessage
          errors={errors}
          name={'alias'}
          render={({ message }) => <p className="error-message">{message}</p>}
        />
      )}
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default RemoteIDE;
