import React from 'react';
import clsx from 'clsx';
import { CreateWebTestStepData } from '@customTypes/testStep/type';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';
import { WebCommandOptionServerName } from '@utils/static/webCommandOptions';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import { preventEnterHandler } from '@utils/helper/preventEnterHandler';

const CompareTextInput = () => {
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext<CreateWebTestStepData>();

  const isSecret =
    watch('optionsDto.secret') && JSON.parse(watch('optionsDto.secret'));
  const isTextEncrypt =
    watch('optionsDto.textEncrypt') &&
    JSON.parse(watch('optionsDto.textEncrypt'));
  const command = watch('command');

  const isSecretOption =
    command === WebCommandOptionServerName.ConfirmText && isSecret;
  const isTextEncryptOption =
    command === WebCommandOptionServerName.InputText && isTextEncrypt;
  return (
    <ErrorBoundaryWrapper>
      <input
        className={clsx(
          'input-base',
          (isSecretOption || isTextEncryptOption) && 'password-input',
        )}
        placeholder={
          command === WebCommandOptionServerName.ConfirmText
            ? '비교할 텍스트를 직접 입력해 주세요.'
            : '텍스트를 입력해 주세요.'
        }
        {...register('optionsDto.compareText', {
          required: '텍스트를 입력해 주세요.',
        })}
        onKeyDown={(e) =>
          preventEnterHandler(e, isSecretOption || isTextEncryptOption)
        }
      />
      <ErrorMessage
        errors={errors}
        name="optionsDto.compareText"
        render={({ message }) => (
          <p className="error-message !mt-2">{message}</p>
        )}
      />
    </ErrorBoundaryWrapper>
  );
};

export default CompareTextInput;
