import { ErrorBoundaryWrapper } from '@autosquare/common';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import {
  SpeedSchedulerTypeName,
  SpeedSchedulerTypeServerName,
} from '@customTypes/ide/speedScheduler/speedScheduler';
import { useSpeedSchedulerDetailQuery } from '@lib/api/ide/speedScheduler/speedScheduler';
import React from 'react';

const SpeedTestSchedulerDetailTestType = () => {
  const { data: speedSchedulerDetail } = useSpeedSchedulerDetailQuery();

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer title="Test Type">
        {speedSchedulerDetail.type === SpeedSchedulerTypeServerName.Mobile
          ? SpeedSchedulerTypeName.Mobile
          : SpeedSchedulerTypeName.Web}
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default SpeedTestSchedulerDetailTestType;
