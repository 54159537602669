import React from 'react';

import DetailDraggable from './DetailDnd/DetailDraggable';

const DetailDnd = () => {
  return (
    <div className="flex w-full text-sm">
      <DetailDraggable />
    </div>
  );
};

export default DetailDnd;
