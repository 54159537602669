import {
  CloseButton,
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import React, { Fragment, useState } from 'react';

import TestStepList from './TestStepList';
import SpeedTestStepList from './SpeedTestStepList';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TestStepListDialog = ({ isOpen, setIsOpen }: Props) => {
  const [isTooltipOpened, setIsTooltipOpened] = useState(false);

  const isUiTestPath = isWordInLocationPathname('ui-test');

  return (
    <ErrorBoundaryWrapper>
      <Transition show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          onClose={() => setIsOpen(false)}
          static
          className="relative z-50 transition"
        >
          <TransitionChild
            as={Fragment}
            enter="transform transition ease-in-out duration-500"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <DialogPanel className="pointer-events-auto fixed right-0 top-[3.25rem] flex h-[calc(100%-4rem)] w-[30%] items-center justify-center p-4">
                  <div className="absolute -left-2 top-4 flex gap-1">
                    {isTooltipOpened && (
                      <div className="absolute left-[-10.25rem] flex items-center justify-center rounded-md bg-gray-900 px-3 py-1.5 text-white">
                        Test Step List 닫힘
                      </div>
                    )}
                    <CloseButton
                      type="button"
                      className="relative flex h-9 w-7 items-center justify-center rounded-l-md bg-gray-900 text-white"
                      onMouseEnter={() => setIsTooltipOpened(true)}
                      onMouseLeave={() => setIsTooltipOpened(false)}
                      onClick={() => setIsOpen(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
                        />
                      </svg>
                    </CloseButton>
                  </div>
                  {isUiTestPath ? <TestStepList /> : <SpeedTestStepList />}
                </DialogPanel>
              </div>
            </div>
          </TransitionChild>
        </Dialog>
      </Transition>
    </ErrorBoundaryWrapper>
  );
};

export default TestStepListDialog;
