import React from 'react';
import { AutoRegisterInfoData } from '@customTypes/ide/scheduler/scheduler';
import { AutoRegisterPlatformServername } from '@utils/static/autoRegister/autoRegisterTypeList';
import IdeScenarioJiraInputContents from './AutoRegisterScenarioPlatformInpuList/IdeScenarioJiraInputContents';
import IdeScenarioConfluenceInputContents from './AutoRegisterScenarioPlatformInpuList/IdeScenarioConfluenceInputContents';

type Props = {
  autoIndex: number;
  platform: string;
  autoRegisters: AutoRegisterInfoData;
};

const AutoRegisterScenarioPlatformInputList = ({
  autoIndex,
  platform,
  autoRegisters,
}: Props) => {
  return (
    <div className="flex flex-col space-y-4">
      {platform === AutoRegisterPlatformServername.Jira && (
        <IdeScenarioJiraInputContents
          autoIndex={autoIndex}
          autoRegisters={autoRegisters}
        />
      )}
      {platform === AutoRegisterPlatformServername.Confluence && (
        <IdeScenarioConfluenceInputContents
          autoIndex={autoIndex}
          autoRegisters={autoRegisters}
        />
      )}
    </div>
  );
};

export default AutoRegisterScenarioPlatformInputList;
