import React from 'react';
import clsx from 'clsx';
import ResultRatePie from '@components/Overview/ResultRatePie';
import SpeedEnvironmentBar from '@components/Overview/SpeedEnvironmentBar';
import TestBreakdownGroup from '@components/Overview/TestBreakdownGroup';
import OverviewSubTitle from '@components/shared/OverviewSubTitle';
import SlidingTabWithTestResult from 'src/pages/IDE/SpeedTest/SpeedTestOverview/components/SlidingTabWithTestResult';
import { checkIsProjectFeatureIncluded } from '@utils/static/checkProjectFeature';
import { ProjectListDetailFeaturesFeature } from '@customTypes/dashboard/project/type';
import { useSelector } from 'react-redux';
import { ProjectListDetailFeaturesCategory } from '@customTypes/dashboard/project/type';
import { RootState } from '@app/store';
import { OverviewResultRateReport } from '@customTypes/ide/overview/type';
import SpeedTestResultTableSection from 'src/pages/IDE/SpeedTest/SpeedTestOverview/components/SpeedTestResultTableSection';
import { MobileSpeedTestResultData } from '@customTypes/ide/speedTest/Mobile/testResult/type';
import { WebSpeedTestResultData } from '@customTypes/ide/speedTest/Web/testResult/type';
import { TestPlatformType } from '@lib/api/dashboard/overview/overview';
type Props = {
  reportData: OverviewResultRateReport;
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  mobileSpeedResult: MobileSpeedTestResultData;
  webSpeedResult: WebSpeedTestResultData;
};

const SpeedTestTabOverview = ({
  reportData,
  selectedIndex,
  setSelectedIndex,
  mobileSpeedResult,
  webSpeedResult,
}: Props) => {
  const mobileTotal = mobileSpeedResult?.totalElements;
  const webTotal = webSpeedResult?.totalCount;
  const projectList = useSelector(
    (state: RootState) => state.projectList.projectList,
  );
  const isMobileTestSpeedEnabled = checkIsProjectFeatureIncluded(
    projectList,
    ProjectListDetailFeaturesCategory.Mobile,
    ProjectListDetailFeaturesFeature.Speed,
  );
  const isWebTestSpeedEnabled = checkIsProjectFeatureIncluded(
    projectList,
    ProjectListDetailFeaturesCategory.Web,
    ProjectListDetailFeaturesFeature.Speed,
  );

  const isBothSpeedEnabled = isMobileTestSpeedEnabled && isWebTestSpeedEnabled;

  const mobileReportData = reportData?.mobileResultReports;
  const webReportData = reportData?.webResultReports;
  const mobileTabContents = {
    title: TestPlatformType.MOBILE,
    content: [
      {
        id: 1,
        post: (
          <SpeedTestResultTableSection
            total={mobileTotal}
            resultData={mobileSpeedResult}
          />
        ),
      },
    ],
  };
  const webTabContents = {
    title: TestPlatformType.WEB,
    content: [
      {
        id: 1,
        post: (
          <SpeedTestResultTableSection
            total={webTotal}
            resultData={webSpeedResult}
          />
        ),
      },
    ],
  };
  const tabList = isBothSpeedEnabled
    ? [mobileTabContents, webTabContents]
    : isMobileTestSpeedEnabled
      ? [mobileTabContents]
      : [webTabContents];

  return (
    <div className="flow-root">
      <OverviewSubTitle
        subTitle={'Result Rate'}
        description="프로젝트 테스트 결과 비율"
      />
      <div className="grid grid-cols-2 gap-x-7">
        {isMobileTestSpeedEnabled && (
          <ResultRatePie data={mobileReportData} header="Mobile" />
        )}
        {isWebTestSpeedEnabled && (
          <ResultRatePie data={webReportData} header="Web" />
        )}
        <div className={clsx(isBothSpeedEnabled && 'col-span-2')}>
          <OverviewSubTitle
            subTitle={'Environment'}
            description="테스트 환경"
          />
          <div
            className={clsx(isBothSpeedEnabled && 'grid grid-cols-2 gap-x-3')}
          >
            {isMobileTestSpeedEnabled && (
              <SpeedEnvironmentBar
                data={mobileReportData?.envRate}
                envType={'mobile'}
              />
            )}
            {isWebTestSpeedEnabled && (
              <SpeedEnvironmentBar
                data={webReportData?.envRate}
                envType={'web'}
              />
            )}
          </div>
          <div className="mt-8 flow-root">
            <OverviewSubTitle
              subTitle={'Test Breakdown'}
              description="프로젝트 내 스케줄러, 시나리오, 케이스 개수"
            />
            <TestBreakdownGroup
              data={reportData}
              isMobileTestEnabled={isMobileTestSpeedEnabled}
              isWebTestEnabled={isWebTestSpeedEnabled}
            />
          </div>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <OverviewSubTitle
          subTitle={'Recent Test Results'}
          description="최근 테스트 결과 목록"
        />
      </div>
      <SlidingTabWithTestResult
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        tabList={tabList}
      />
    </div>
  );
};

export default SpeedTestTabOverview;
