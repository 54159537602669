import {
  ArrowSpinner,
  BackToListIcon,
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
  PageHeadingButtons,
  PagingHeadingsNavigate,
  TestResultDetailMenu,
} from '@autosquare/common';
import { useMobileSpeedTestResultDeleteMutation } from '@lib/api/dashboard/mobile/mobileSpeedResult';
import React, { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

type Props = {
  exportChartToPng: () => Promise<void>;
};

const MobileSpeedTestResultHeader = ({ exportChartToPng }: Props) => {
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const mobileSpeedTestResultDeleteMutation =
    useMobileSpeedTestResultDeleteMutation();

  const resultIdx = searchParams.get('result');
  const resultStatus = searchParams.get('status');
  const isResultNA = resultStatus === 'NA';
  const backToListPage = localStorage.getItem('entryPoint');
  const resultListPage = pathname.split('/').slice(0, -1).join('/');

  const breadcrumbs = [
    {
      title: 'Mobile',
      href: resultListPage,
    },
    {
      title: 'Speed Test Result',
      href: resultListPage,
    },
    { title: 'Detail', href: pathname + search },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: (
        <div className="flex items-center justify-between gap-1.5 text-sm font-semibold leading-5 text-gray-900">
          <BackToListIcon />
          Back to list
        </div>
      ),
      onClick: () => navigate(backToListPage ?? resultListPage),
      type: 'button',
      variant: 'secondary',
    },
  ];

  const testResultDetailMenu: TestResultDetailMenu[] = [
    {
      title: 'Export',
      onClick: () => {
        exportChartToPng();
      },
      disabled: isResultNA,
    },
    {
      title: 'Delete',
      onClick: () => {
        if (mobileSpeedTestResultDeleteMutation.isError) {
          mobileSpeedTestResultDeleteMutation.reset();
        }
        setIsModalOpen(true);
      },
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Speed Test Result Detail'}
        pageHeadingButtons={pageHeadingButtons}
        testResultDetailMenu={testResultDetailMenu}
      />
      <DoubleCheckDialogHeadlessUI
        isOpened={isModalOpen}
        setIsOpened={setIsModalOpen}
        type={'caution'}
        title={
          mobileSpeedTestResultDeleteMutation.isError
            ? 'Error'
            : 'Delete Test Result'
        }
        subTitle={
          mobileSpeedTestResultDeleteMutation.isError
            ? mobileSpeedTestResultDeleteMutation.error.message
            : '해당 테스트 결과를 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {!mobileSpeedTestResultDeleteMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  mobileSpeedTestResultDeleteMutation.mutate(
                    { idxList: [Number(resultIdx)] },
                    {
                      onError: () => {
                        setIsModalOpen(true);
                      },
                    },
                  )
                }
                disabled={mobileSpeedTestResultDeleteMutation.isLoading}
              >
                Delete
              </Button>
            )}
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              Cancel
            </Button>
          </>
        }
      />
      {mobileSpeedTestResultDeleteMutation.isLoading && <ArrowSpinner />}
    </ErrorBoundaryWrapper>
  );
};

export default MobileSpeedTestResultHeader;
