import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';

import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';

import { RootState } from '@app/store';

import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionName,
  mobileCommandOptions,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import {
  useMobileScreenshotQuery,
  useMobileSourceQuery,
  useRemoteMobileScreenshotQuery,
  useRemoteMobileSourceQuery,
} from '@lib/api/ide/mobile/mobileDevice';
import { ErrorMessage } from '@hookform/error-message';
import { Mode } from '@customTypes/ide/remote/type';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';
import { getRemoteState } from '@utils/static/getConfiguration';

const TestStepCommand = () => {
  const { configuration } = getRemoteState();
  const mobileScreenshotQuery =
    configuration === Mode.Standard
      ? useMobileScreenshotQuery()
      : useRemoteMobileScreenshotQuery();

  const mobileSourceQuery =
    configuration === Mode.Standard
      ? useMobileSourceQuery()
      : useRemoteMobileSourceQuery();

  const deviceInfo = useSelector((state: RootState) => state.device.deviceInfo);

  // AOS, IOS에 따른 명령어 옵션 리스트 분리
  const commandOptionsList = mobileCommandOptions.slice(1);

  const iOSMobileCommandOptions = commandOptionsList.filter(
    (mobileCommandOption) =>
      mobileCommandOption.name !== MobileCommandOptionName.ResetApp,
  );

  const {
    control,
    watch,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext<CreateTestStepData>();

  const command = watch('command');

  useEffect(() => {
    setValue('optionsDto.optionType', command);
    setValue('optionsDto.stepIdxList', undefined);
    setValue('optionsDto.secret', undefined);
    setValue('optionsDto.maskingOptions.enabled', 'false');
    setValue('optionsDto.maskingOptions.type', undefined);
    setValue('optionsDto.maskingOptions.valueList', undefined);
    setValue('optionsDto.repeatLimit', undefined);
  }, [setValue, command]);

  return (
    <ErrorBoundaryWrapper>
      <div>
        <Controller
          control={control}
          name="command"
          rules={{ required: 'Command를 입력해주세요.' }}
          render={({ field }) => (
            <ListBoxHeadlessInController
              value={field.value}
              buttonValue={
                findMobileCommandNameFromServerName(field.value) ||
                MobileCommandOptionName.Command
              }
              onChange={(value) => {
                field.onChange(value);
                resetField('value');
                if (
                  command === MobileCommandOptionServerName.Loop ||
                  command === MobileCommandOptionServerName.IF
                ) {
                  resetField('optionsDto.conditionCommand');
                  resetField('optionsDto.conditionValue');
                }
              }}
              lists={
                deviceInfo.os === DeviceInfoOs.Aos
                  ? commandOptionsList
                  : iOSMobileCommandOptions
              }
              valueToSave={'serverName'}
              valueToShow={'name'}
              disabled={
                mobileScreenshotQuery.isLoading || mobileSourceQuery.isLoading
              }
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={'command'}
          render={({ message }) => (
            <p className="error-message pt-2">{message}</p>
          )}
        />
      </div>
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default TestStepCommand;
