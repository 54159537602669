import { ErrorBoundaryWrapper } from '@autosquare/common';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';

import React from 'react';
import { GrPowerReset } from 'react-icons/gr';

const DefaultValue = () => {
  return (
    <ErrorBoundaryWrapper>
      <div className="flex">
        <div className="relative w-full cursor-default rounded-l-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
          <span className="block truncate">Value</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="size-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </div>
        <button
          type="button"
          className="flex size-9 items-center justify-center rounded-r-md border-y border-r border-solid border-gray-300 px-2 py-1 text-gray-900 shadow-sm"
          disabled
        >
          <GrPowerReset style={{ color: '#9CA3AF' }} />
        </button>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default DefaultValue;
