import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { axiosInstance } from '..';

import { API } from '@api/endpoints';

import {
  OverviewResultRateReport,
  OverviewResults,
} from '@customTypes/ide/overview/type';
import { ServerResponseErrorDetails } from '@customTypes/type';

import { overviewKeys } from '@queries/dashboard/overviewKeys';
import { useParams } from 'react-router-dom';

export enum LocationCategory {
  UI = 'UI Test',
  SPEED = 'Speed Test',
}

export enum TestPlatformType {
  MOBILE = 'Mobile',
  WEB = 'Web',
}

// GET
const fetchFunctionalTestOverview = async (
  projectIdx: string,
): Promise<OverviewResults> => {
  const response = await axiosInstance.get<OverviewResults>(
    `${API.OVERVIEW}?projectIdx=${projectIdx}`,
  );
  return response.data;
};
export const useFunctionalTestOverviewQuery = (
  options?: UseQueryOptions<OverviewResults>,
) => {
  const { projectIdx } = useParams();

  return useQuery<OverviewResults, ServerResponseErrorDetails>({
    queryKey: overviewKeys.list(projectIdx),
    queryFn: () => fetchFunctionalTestOverview(projectIdx),
    ...options,
  });
};

const getResultRate = async (
  projectIdx: string,
): Promise<OverviewResultRateReport> => {
  const response = await axiosInstance.get(
    `${API.UI_TEST_REPORT_OVERVIEW}${projectIdx}`,
  );
  return response.data;
};

export const useGetResultRateQuery = (location: LocationCategory) => {
  const { projectIdx } = useParams();

  return useQuery<OverviewResultRateReport, Error, OverviewResultRateReport>({
    queryKey: overviewKeys.report(projectIdx, LocationCategory.UI),
    queryFn: () => getResultRate(projectIdx),
    enabled: location === LocationCategory.UI,
  });
};

const getSpeedTestResultRate = async (
  projectIdx: string,
): Promise<OverviewResultRateReport> => {
  const response = await axiosInstance.get(
    `${API.SPEED_TEST_REPORT_OVERVIEW}${projectIdx}`,
  );
  return response.data;
};

export const useGetSpeedTestResultRateQuery = (location: LocationCategory) => {
  const { projectIdx } = useParams();
  return useQuery<OverviewResultRateReport, Error, OverviewResultRateReport>({
    queryKey: overviewKeys.report(projectIdx, LocationCategory.SPEED),
    queryFn: () => getSpeedTestResultRate(projectIdx),
    enabled: location === LocationCategory.SPEED,
  });
};
