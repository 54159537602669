import React, { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';

import {
  CompareBy,
  CompareRange,
  CreateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

import CompareByRadio from './ConfirmAndInputText/CompareByRadio';
import CompareByContents from './ConfirmAndInputText/CompareByContents';
import EqualAndContainRadio from './ConfirmAndInputText/EqualAndContainRadio';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const ConfirmAndInputText = () => {
  const { watch, resetField, setValue } = useFormContext<CreateTestStepData>();

  const command = watch('command');

  useEffect(() => {
    return () => {
      resetField('optionsDto.compareBy');
      resetField('optionsDto.compareText');
      resetField('optionsDto.compareIdx');
      resetField('optionsDto.compareRange');
      resetField('optionsDto.textReplaceOptions');
      setValue('optionsDto.textReplace', undefined);
      setValue('optionsDto.autoColumn', undefined);
      setValue('optionsDto.targetData', undefined);
      setValue('optionsDto.secret', undefined);
      setValue('optionsDto.textEncrypt', undefined);
    };
  }, [resetField, setValue]);

  useEffect(() => {
    if (command === MobileCommandOptionServerName.ConfirmText) {
      setValue('optionsDto.compareBy', CompareBy.Id);
      setValue('optionsDto.compareRange', CompareRange.Equal);
      setValue('optionsDto.secret', 'false');
    }

    if (command === MobileCommandOptionServerName.InputText) {
      setValue('optionsDto.compareBy', CompareBy.Id);
      setValue('optionsDto.textReplace', undefined);
      resetField('optionsDto.textReplaceOptions');
      resetField('optionsDto.compareRange');
      resetField('optionsDto.repeatLimit');
    }
  }, [resetField, command, setValue]);

  return (
    <ErrorBoundaryWrapper>
      <CompareByRadio />
      <CompareByContents />
      <EqualAndContainRadio />
    </ErrorBoundaryWrapper>
  );
};

export default ConfirmAndInputText;
