import { UpdateProjectDetailSetting } from '@customTypes/index';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { EmailList, ErrorBoundaryWrapper } from '@autosquare/common';
import { handleEmailListOnKeyDownInProjectDetail } from '@utils/static/dashboard/handleEmailListOnKeyDown';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { ErrorMessage } from '@hookform/error-message';
import { useDetailProjectAdmin } from '@hooks/index';
import { handleSelectMember } from '@utils/index';

const DetailProjectAdmin = () => {
  const {
    formState: { errors },
  } = useFormContext<UpdateProjectDetailSetting>();

  const {
    projectAdminList,
    setProjectAdminList,
    setProjectAdminInput,
    isOpenedMemberList,
    setIsOpenedMemberList,
    selectedIndex,
    setSelectedIndex,
    temporarySavedEmailAddress,
    setTemporarySavedEmailAddress,
    inputRef,
    wrapperRef,
    ulRef,
    filteredPeople,
  } = useDetailProjectAdmin();

  const emailListState = {
    temporarySavedList: temporarySavedEmailAddress,
    projectAdminList,
    selectedIndex,
    filteredPeople,
  };

  const emailListActions = {
    setIsOpenedMemberList,
    setProjectAdminList,
    setProjectAdminInput,
    setSelectedIndex,
    setTemporarySavedEmailAddress,
  };

  const emailListRefs = {
    inputRef,
  };

  return (
    <ErrorBoundaryWrapper>
      <div className="mt-2">
        <ErrorBoundaryWrapper>
          <div ref={wrapperRef}>
            <div className="relative">
              <input
                className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => setProjectAdminInput(e.target.value)}
                placeholder="Select user"
                ref={inputRef}
                onKeyDown={(e) =>
                  handleEmailListOnKeyDownInProjectDetail(
                    e,
                    emailListState,
                    emailListActions,
                    emailListRefs,
                  )
                }
                onClick={() => setIsOpenedMemberList(true)}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                onClick={() => setIsOpenedMemberList(!isOpenedMemberList)}
                onKeyDown={(e) =>
                  handleEmailListOnKeyDownInProjectDetail(
                    e,
                    emailListState,
                    emailListActions,
                    emailListRefs,
                  )
                }
              >
                <ChevronUpDownIcon
                  className="size-5 text-gray-400"
                  aria-hidden="true"
                />
              </button>
              {filteredPeople?.length > 0 && isOpenedMemberList && (
                <ul
                  className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm"
                  ref={ulRef}
                >
                  {filteredPeople?.map((person, index) => (
                    <li key={person.idx}>
                      <button
                        type="button"
                        className={clsx(
                          'group relative w-full cursor-default select-none py-2 pl-3 pr-9 hover:bg-indigo-600',
                          index === selectedIndex
                            ? 'bg-indigo-600 text-white'
                            : 'text-gray-900',
                        )}
                        onClick={() =>
                          handleSelectMember(
                            person,
                            projectAdminList,
                            setProjectAdminList,
                            setProjectAdminInput,
                            inputRef,
                            setTemporarySavedEmailAddress,
                            setIsOpenedMemberList,
                          )
                        }
                      >
                        <div className="flex">
                          <span className={`truncate group-hover:text-gray-50`}>
                            {person.name}
                          </span>
                          <span
                            className={clsx(
                              'ml-2 truncate text-gray-500 group-hover:text-indigo-200',
                              index === selectedIndex
                                ? 'text-indigo-200'
                                : 'text-gray-500',
                            )}
                          >
                            {person.userId}
                          </span>
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </ErrorBoundaryWrapper>
        <EmailList state={projectAdminList} setState={setProjectAdminList} />
      </div>
      <ErrorMessage
        errors={errors}
        name="projectAdminList"
        render={({ message }) => <p className="error-message">{message}</p>}
      />
    </ErrorBoundaryWrapper>
  );
};

export default DetailProjectAdmin;
