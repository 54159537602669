import React, { useState } from 'react';
import clsx from 'clsx';
import { Button, ResultDialogHeadlessUI } from '@autosquare/common';
import { ResetAndConfirmPassword } from '@autosquare/common/src/utils/type/resetPassword/type';
import { useResetPassword } from '@lib/api/dashboard/forgotAndResetPassword/forgotAndResetPassword';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import PasswordForm from './components/PasswordForm';

const ResetPassword = () => {
  const [isSuccessfulChanged, setIsSuccessfulChanged] = useState(false);
  const [isFailedChanged, setIsFailedChanged] = useState(false);

  const methods = useForm<ResetAndConfirmPassword>();

  const password = methods.watch('password');
  const confirmPassword = methods.watch('confirmPassword');

  const resetPassword = useResetPassword();

  const isWindows = navigator.userAgent.includes('Windows');

  const onSubmit: SubmitHandler<ResetAndConfirmPassword> = (
    data: ResetAndConfirmPassword,
  ) => {
    const userId = sessionStorage.getItem('userId');
    if (password !== confirmPassword) {
      methods.setError('confirmPassword', {
        type: 'manual',
        message: '입력한 비밀번호와 일치하지 않습니다.',
      });
      return;
    }
    resetPassword.mutate(
      { ...data, userId },
      {
        onSuccess: () => {
          setIsSuccessfulChanged(true);
          sessionStorage.removeItem('userId');
        },
        onError: () => setIsFailedChanged(true),
      },
    );
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="mx-auto grid w-96 grid-rows-1">
            <div className="px-3 py-6">
              <h2 className="text-lg font-semibold">비밀번호 재설정</h2>
              <hr className="my-2 border-t border-solid border-congress-blue" />
              <p className="text-sm">
                8 ~16자의 영문 대 소문자, 숫자, 기호를 사용하여
                <br />
                새로운 비밀번호를 입력해 주세요.
              </p>
            </div>
            <div className="flex flex-col items-center justify-center">
              <PasswordForm />
              <Button
                type="submit"
                buttonSize="xs"
                className={clsx('mt-5 py-2', isWindows ? 'w-36' : 'w-32')}
                disabled={
                  password?.length === undefined ||
                  password?.length === 0 ||
                  confirmPassword?.length === undefined ||
                  confirmPassword?.length === 0
                }
              >
                비밀번호 변경하기
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
      {isFailedChanged && (
        <ResultDialogHeadlessUI
          isOpen={isFailedChanged}
          setIsOpen={setIsFailedChanged}
          title={'Error'}
        />
      )}
      {isSuccessfulChanged && (
        <ResultDialogHeadlessUI
          isOpen={isSuccessfulChanged}
          setIsOpen={setIsSuccessfulChanged}
          title={'Success'}
        />
      )}
    </>
  );
};

export default ResetPassword;
