import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  CommonDialog,
  DialogButton,
  DialogButtonPanels,
} from '@components/Dialog';
import { useRemoveMemberMutation } from '@lib/api/dashboard/queryHooks';
import React, { useState } from 'react';

type Props = {
  isRemoved: boolean;
  setIsRemoved: React.Dispatch<React.SetStateAction<boolean>>;
};

const RemoveDialog = ({ isRemoved, setIsRemoved }: Props) => {
  const [isRemoveErrorModalOpen, setIsRemoveErrorModalOpen] = useState(false);

  const removeMemberMutation = useRemoveMemberMutation();

  const closeDialog = () => {
    setIsRemoved(false);
  };

  return (
    <ErrorBoundaryWrapper>
      {/* Remove 버튼 클릭 시 나타나는 Dialog */}
      <CommonDialog
        isOpen={isRemoved}
        setIsOpen={setIsRemoved}
        iconType={'caution'}
        title={'Remove Project Member'}
        subTitle={'해당 사용자를 프로젝트에서 삭제하시겠습니까?'}
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant="caution"
            onClick={() => {
              removeMemberMutation.mutate(undefined, {
                onError: () => {
                  setIsRemoveErrorModalOpen(true);
                },
                onSettled: () => {
                  closeDialog();
                },
              });
            }}
          >
            Delete
          </DialogButton>
          <DialogButton type="button" variant="secondary" onClick={closeDialog}>
            Cancel
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
      {/* 삭제 시 발생하는 Error와 관련된 Dialog */}
      <CommonDialog
        isOpen={isRemoveErrorModalOpen}
        setIsOpen={setIsRemoveErrorModalOpen}
        iconType={'caution'}
        title={'Failure'}
        subTitle={removeMemberMutation.error?.message}
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant="secondary"
            onClick={() => setIsRemoveErrorModalOpen(false)}
          >
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default RemoveDialog;
