import React from 'react';
import { Button, ScenarioListDetailData } from '@autosquare/common';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { TestCaseDetailPickTitleAndIdx } from '@customTypes/index';
import { SelectedItem } from '@autosquare/common/src/hooks/checkbox/useCheckboxWithDnd';

type Props<T extends TestCaseDetailPickTitleAndIdx | ScenarioListDetailData> = {
  selectedArray: SelectedItem[];
  items: T[];
  copyItems: T[];
  setCopyItems: React.Dispatch<React.SetStateAction<T[]>>;
  resetSelectedArray: () => void;
  disabled?: boolean;
};

const TransferItemButton = <
  T extends TestCaseDetailPickTitleAndIdx | ScenarioListDetailData,
>({
  selectedArray,
  items,
  copyItems,
  setCopyItems,
  resetSelectedArray,
  disabled = false,
}: Props<T>) => {
  const transferItemHandler = () => {
    const selectedItem = selectedArray.map((selected) =>
      items.find(({ dndIndex }) => dndIndex === selected.dndIndex),
    );

    const maxIndex =
      copyItems.length > 0
        ? copyItems.reduce(
            (max, item) => (item.dndIndex > max ? item.dndIndex : max),
            0,
          )
        : 0;
    const transferableItem = selectedItem.map((item, index) => ({
      ...item,
      dndIndex: maxIndex + index + 1,
    }));
    setCopyItems((prev) => [...prev, ...transferableItem]);
    resetSelectedArray();
  };
  return (
    <div className="flex items-center justify-center">
      <Button
        type="button"
        onClick={transferItemHandler}
        disabled={!selectedArray.length || disabled}
        className="border disabled:border-gray-200"
      >
        <ChevronRightIcon className="size-4" strokeWidth="3" />
      </Button>
    </div>
  );
};

export default TransferItemButton;
