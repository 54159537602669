import React from 'react';
import clsx from 'clsx';
import { TrashIcon } from '@heroicons/react/20/solid';
import { Bars2Icon } from '@heroicons/react/24/outline';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

type Props = {
  onClickHandler: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  isDragging: boolean;
  listeners: SyntheticListenerMap;
  disabled?: boolean;
};

const RightItemSection = ({
  onClickHandler,
  isDragging,
  listeners,
  disabled = false,
}: Props) => {
  return (
    <div
      className={clsx(
        'flex items-center gap-x-3',
        disabled && 'cursor-default',
      )}
    >
      <TrashIcon
        className={clsx(
          !disabled && 'invisible size-5 text-gray-700 group-hover:visible',
        )}
        onClick={onClickHandler}
      />
      <div className="rounded-md p-1.5">
        <Bars2Icon
          className={clsx(
            'size-5',
            disabled
              ? 'cursor-default'
              : isDragging
                ? 'cursor-grabbing hover:bg-gray-200'
                : 'cursor-grab',
          )}
          {...listeners}
        />
      </div>
    </div>
  );
};

export default RightItemSection;
