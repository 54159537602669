import { ErrorBoundaryWrapper, LoadingSpinner } from '@autosquare/common';
import { useTestCaseImport } from '@contexts/ide/TestCaseImportContext';
import {
  ImportCsvTestCaseRequest,
  ImportCsvTestCaseRequestSchema,
  ImportedFileCsvData,
  PriorityNameEnum,
} from '@customTypes/index';
import { zodResolver } from '@hookform/resolvers/zod';
import { useImportCsvTestCaseCsvMutation } from '@lib/api/ide/queryHooks';
import { PlatFormTypeServerName } from '@utils/static/platformTypeList';
import React, { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import SetDefaultValues from './CsvResult/SetDefaultValues';
import DataMapping from './CsvResult/DataMapping';
import {
  CommonDialog,
  DialogButton,
  DialogButtonPanels,
} from '@components/Dialog';

type Props = {
  fileData: ImportedFileCsvData[];
};

const CsvResult = ({ fileData }: Props) => {
  const { setStepNumber, setImportFormCsvData, setIsImportFormDataLoading } =
    useTestCaseImport();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const methods = useForm<ImportCsvTestCaseRequest>({
    defaultValues: {
      platformType: PlatFormTypeServerName.MobileApp,
      priority: PriorityNameEnum.Medium,
    },
    resolver: zodResolver(ImportCsvTestCaseRequestSchema),
    mode: 'onChange',
  });

  const importCsvTestCaseMutation = useImportCsvTestCaseCsvMutation();

  const onSubmit: SubmitHandler<ImportCsvTestCaseRequest> = (data) => {
    setIsImportFormDataLoading(true);
    setImportFormCsvData(data);

    const transformedData = {
      ...data,
      tcId: { [data.tcId]: fileData[data.tcId] },
      title: { [data.title]: fileData[data.title] },
      description: data.description
        ? { [data.description]: fileData[data.description] }
        : undefined,
    };

    importCsvTestCaseMutation.mutate(transformedData, {
      onSuccess: () => {
        setStepNumber(3);
      },
      onError: () => {
        setIsErrorDialogOpen(true);
      },
      onSettled: () => {
        setIsImportFormDataLoading(false);
      },
    });
  };

  const csvHeaderList = Object.keys(fileData);

  const csvHeaderListWithNo = csvHeaderList.map((header, index) => ({
    idx: index + 1,
    header: header,
  }));

  return (
    <ErrorBoundaryWrapper>
      <FormProvider {...methods}>
        <form
          id="import-csv-file"
          onSubmit={methods.handleSubmit(onSubmit)}
          className="mt-10 "
        >
          <SetDefaultValues />
          <DataMapping csvHeaderListWithNo={csvHeaderListWithNo} />
        </form>
      </FormProvider>
      <CommonDialog
        isOpen={isErrorDialogOpen}
        setIsOpen={setIsErrorDialogOpen}
        iconType={'caution'}
        title={'Error'}
        subTitle={importCsvTestCaseMutation.error?.message}
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant="secondary"
            onClick={() => {
              setIsErrorDialogOpen(false);
            }}
          >
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
      {importCsvTestCaseMutation.isLoading && (
        <LoadingSpinner loadingTitle="Loading..." />
      )}
    </ErrorBoundaryWrapper>
  );
};

export default CsvResult;
