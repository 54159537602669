import { ErrorBoundaryWrapper } from '@autosquare/common';
import React from 'react';
import ProjectDevicesListHeader from './components/ProjectDevicesListHeader';
import ProjectDevicesListSection from './components/ProjectDevicesListSection';
import DeviceAuthorizationInfo from './components/DeviceAuthorizationInfo';

const ProjectDevicesList = () => {
  return (
    <ErrorBoundaryWrapper>
      <ProjectDevicesListHeader />
      <DeviceAuthorizationInfo />
      <ProjectDevicesListSection />
    </ErrorBoundaryWrapper>
  );
};

export default ProjectDevicesList;
