import { useCallback } from 'react';
import dayjs from 'dayjs';
import { getRoundedTime, formatDateTime, isSameDate } from '@utils/index';

export const useReservationTime = () => {
  const now = dayjs();
  const minStartTime = getRoundedTime(now);

  const dateOnChange = useCallback(
    (date: Date, onChange: (value: string) => void) => {
      onChange(formatDateTime(date));
    },
    [],
  );

  const checkIsToday = useCallback(
    (startTime: string) => {
      return isSameDate(minStartTime.toDate(), startTime);
    },
    [minStartTime],
  );

  const checkIsSameDay = useCallback((startTime: string, endTime: string) => {
    return isSameDate(startTime, endTime);
  }, []);

  return {
    minStartTime,
    dateOnChange,
    checkIsToday,
    checkIsSameDay,
  };
};
