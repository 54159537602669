import { Button, ErrorBoundaryWrapper } from '@autosquare/common';
import { CommonDialog } from '@components/Dialog';
import { RESET_DELAY_MS } from '@constants/index';
import {
  DeviceApprovalInfo,
  RequestStatus,
  UpdateDeviceApproval,
  UpdateDeviceApprovalSchema,
} from '@customTypes/index';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUpdateAdminDeviceApprovalMutation } from '@lib/api/dashboard/queryHooks';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

type Props = { deviceApproval: DeviceApprovalInfo };

const ApprovalActionButtons = ({ deviceApproval }: Props) => {
  const [isRejectDialog, setIsRejectDialog] = useState(false);
  const [isErrorDialog, setIsErrorDialog] = useState(false);

  const updateAdminDeviceApprovalMutation =
    useUpdateAdminDeviceApprovalMutation();

  const acceptSubmit = () => {
    updateAdminDeviceApprovalMutation.mutate(
      {
        idx: deviceApproval.idx,
        requestStatus: RequestStatus.Approved,
      },
      {
        onError: () => {
          setIsErrorDialog(true);
        },
      },
    );
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UpdateDeviceApproval>({
    resolver: zodResolver(UpdateDeviceApprovalSchema),
    defaultValues: {
      idx: deviceApproval.idx,
      requestStatus: RequestStatus.Rejected,
    },
  });

  const onSubmit: SubmitHandler<UpdateDeviceApproval> = (data) => {
    updateAdminDeviceApprovalMutation.mutate(data, {
      onError: () => {
        setIsErrorDialog(true);
      },
    });
  };

  const onCloseRejectDialog = () => {
    reset();
    setIsRejectDialog(false);
  };

  const dialogErrorMessage = () => {
    if (updateAdminDeviceApprovalMutation.isError) {
      if (updateAdminDeviceApprovalMutation.error.message.includes('등록된')) {
        return 'Already Registered';
      }
      if (updateAdminDeviceApprovalMutation.error.message.includes('삭제된')) {
        return 'Already Deleted';
      }
    }
    return 'Error';
  };

  const onCloseErrorDialog = () => {
    setTimeout(() => {
      updateAdminDeviceApprovalMutation.reset();
    }, RESET_DELAY_MS);
    setIsErrorDialog(false);
  };

  return (
    <ErrorBoundaryWrapper>
      <div className="flex w-fit gap-2.5">
        <Button
          type="button"
          variant="caution"
          onClick={() => setIsRejectDialog(true)}
          disabled={updateAdminDeviceApprovalMutation.isLoading}
        >
          Reject
        </Button>
        <Button
          type="button"
          onClick={acceptSubmit}
          disabled={updateAdminDeviceApprovalMutation.isLoading}
        >
          Accept
        </Button>
      </div>
      <CommonDialog
        isOpen={isRejectDialog}
        setIsOpen={setIsRejectDialog}
        iconType={'caution'}
        title={'Reject'}
        subTitle={'승인 거절 사유를 입력하실 수 있습니다.'}
        onClose={onCloseRejectDialog}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <CommonDialog.DialogPanels>
            <input className="input-base" {...register('reason')} />
            <ErrorMessage
              errors={errors}
              name="reason"
              render={({ message }) => (
                <p className="error-message">{message}</p>
              )}
            />
          </CommonDialog.DialogPanels>
          <CommonDialog.DialogButtonPanels>
            <CommonDialog.DialogButton
              type="submit"
              disabled={updateAdminDeviceApprovalMutation.isLoading}
            >
              OK
            </CommonDialog.DialogButton>
            <CommonDialog.DialogButton
              type="button"
              onClick={onCloseRejectDialog}
              variant="secondary"
            >
              Cancel
            </CommonDialog.DialogButton>
          </CommonDialog.DialogButtonPanels>
        </form>
      </CommonDialog>
      <CommonDialog
        isOpen={isErrorDialog}
        setIsOpen={setIsErrorDialog}
        iconType={'caution'}
        title={dialogErrorMessage()}
        subTitle={
          updateAdminDeviceApprovalMutation.isError &&
          updateAdminDeviceApprovalMutation.error.message
        }
        onClose={onCloseErrorDialog}
      >
        <CommonDialog.DialogButtonPanels>
          <CommonDialog.DialogButton type="button" onClick={onCloseErrorDialog}>
            Close
          </CommonDialog.DialogButton>
        </CommonDialog.DialogButtonPanels>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default ApprovalActionButtons;
