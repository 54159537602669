import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { SaveTextFrom } from '@customTypes/ide/mobileTestStep/SaveTextFrom';

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import ExtractTextFormat from './SaveText/ExtractTextFormat';
import From from './SaveText/From';
import SmsList from './SaveText/SmsList';
import SecretBox from '@components/TestStep/SecretBox';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const SaveText = () => {
  const { watch, control, setValue } = useFormContext<CreateTestStepData>();

  const saveTextFrom = watch('optionsDto.from');

  useEffect(() => {
    setValue('optionsDto.secret', 'false');
    setValue('optionsDto.from', SaveTextFrom.Element);
    return () => setValue('optionsDto.secret', undefined);
  }, []);

  return (
    <ErrorBoundaryWrapper>
      <SecretBox control={control} />
      <From />
      {saveTextFrom === SaveTextFrom.Sms && <SmsList />}
      <ExtractTextFormat />
    </ErrorBoundaryWrapper>
  );
};

export default SaveText;
