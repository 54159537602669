import React from 'react';
import PropertyDetail from './PropertyDetail';
import {
  MobileTestStepData,
  MobileTestStepDataOptionsDto,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { findDataTypeFromServerName } from '@customTypes/ide/mobileTestStep/dataValidation/dataValidation';
import { findDescriptionFromIdx } from '@utils/static/findDescriptionFromIdx';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { useMobileTestStepBySaveTextQuery } from '@lib/api/ide/mobile/mobileTestStep';
import { useSpeedMobileTestStepBySaveTextQuery } from '@lib/api/ide/speedMobile/speedMobileTestStep';
import { findOperatorLabel } from '@utils/index';
import { ErrorBoundaryWrapper } from '@autosquare/common';
type Props = {
  info: MobileTestStepDataOptionsDto;
};

const DataValidationList = ({ info }: Props) => {
  const uiLocation = isWordInLocationPathname('ui-test');
  const mobileTestStepBySaveTextQuery = uiLocation
    ? useMobileTestStepBySaveTextQuery()
    : useSpeedMobileTestStepBySaveTextQuery();
  const referenceValueList =
    mobileTestStepBySaveTextQuery.data as MobileTestStepData[];
  const condition = findDataTypeFromServerName(info.dataType);
  const referenceValue =
    findDescriptionFromIdx(Number(info.compareIdx), referenceValueList) ||
    'None';

  const inputData = info.operatorList
    .map((item) => `${findOperatorLabel(item.operator)}${item.inputValue}`)
    .join(', ');

  return (
    <ErrorBoundaryWrapper>
      <PropertyDetail term={'Condition'} description={condition} />
      <PropertyDetail term={'Reference Value'} description={referenceValue} />
      <PropertyDetail term={'Input Data'} description={inputData} />
    </ErrorBoundaryWrapper>
  );
};

export default DataValidationList;
