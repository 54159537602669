import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  ProjectListDetailFeaturesCategory,
  ProjectListDetailFeaturesFeature,
} from '@customTypes/index';
import { FaCheck } from 'react-icons/fa';
import { useProjectSettingDetailQuery } from '@lib/api/dashboard/queryHooks';
import React from 'react';

const DetailFeatures = () => {
  const { data: projectSettingDetail } = useProjectSettingDetailQuery();

  const features = projectSettingDetail.features;

  // Features에서 현재 활성화 되어있는지 아닌지 확인하는 함수
  const checkFeaturesActivate = (
    feature: ProjectListDetailFeaturesFeature,
    category: ProjectListDetailFeaturesCategory,
  ) =>
    features.find(
      (value) => value?.category === category && value?.feature === feature,
    )?.activate;

  const featuresValueList = [
    {
      idx: 1,
      fieldsetName: 'Mobile',
      fields: [
        {
          id: 'mobile-functional',
          labelName: '기능 테스트',
          defaultChecked: checkFeaturesActivate(
            ProjectListDetailFeaturesFeature.Functional,
            ProjectListDetailFeaturesCategory.Mobile,
          ),
        },
        {
          id: 'mobile-speed',
          labelName: '속도 테스트',
          defaultChecked: checkFeaturesActivate(
            ProjectListDetailFeaturesFeature.Speed,
            ProjectListDetailFeaturesCategory.Mobile,
          ),
        },
      ],
    },
    {
      idx: 2,
      fieldsetName: 'Web',
      fields: [
        {
          id: 'web-functional',
          labelName: '기능 테스트',
          defaultChecked: checkFeaturesActivate(
            ProjectListDetailFeaturesFeature.Functional,
            ProjectListDetailFeaturesCategory.Web,
          ),
        },
        {
          id: 'web-speed',
          labelName: '속도 테스트',
          defaultChecked: checkFeaturesActivate(
            ProjectListDetailFeaturesFeature.Speed,
            ProjectListDetailFeaturesCategory.Web,
          ),
        },
      ],
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <section className="pt-6">
        <h2 className="border-b border-solid border-gray-200 pb-5">Features</h2>
        {featuresValueList.map((featuresValue) => (
          <div
            className="grid grid-cols-3 items-center py-4 text-sm font-normal leading-normal text-gray-900"
            key={featuresValue.idx}
          >
            <div className="font-medium">{featuresValue.fieldsetName}</div>
            <div className="col-span-2 flex items-center gap-6">
              {featuresValue.fields.map((field) => (
                <div key={field.id} className="flex items-center gap-3">
                  <p className="w-4">
                    {field.defaultChecked && (
                      <FaCheck className="size-4 text-congress-blue" />
                    )}
                  </p>
                  <p>{field.labelName}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </section>
    </ErrorBoundaryWrapper>
  );
};

export default DetailFeatures;
