import React from 'react';
import {
  ErrorBoundaryWrapper,
  ErrorMessage,
  MainHeader,
} from '@autosquare/common';
import { LocationCategory } from '@lib/api/dashboard/overview/overview';
import OverviewGraphSkeleton from '@components/Skeleton/OverviewGraphSkeleton';
import UITestTabOverview from './components/UITestTabOverview';
import SpeedTestTabOverview from './components/SpeedTestTabOverview';
import TestCategoryTab from './components/TestCategoryTab';
import TestCategoryContents from './components/TestCategoryContents';
import { useCommonDashboardOverview } from '@hooks/overview/useCommonDashboardOverview';

const CommonDashboardOverview = () => {
  const {
    mobileFunctionalResults,
    webFunctionalResults,
    reportData,
    selectedIndex,
    setSelectedIndex,
    categorySelectedIndex,
    setCategorySelectedIndex,
    selectedTestCategory,
    setSelectedTestCategory,
    mobileSpeedResultQuery,
    webSpeedResultQuery,
    resultRateQuery,
    functionalTestOverviewQuery,
    mobileSpeedResult,
    webSpeedResult,
    isDataFetching,
    isBothTestEnabled,
    isUITestEnabled,
    isInitialLoading,
  } = useCommonDashboardOverview();
  const uiTestTab = {
    title: LocationCategory.UI,
    content: [
      {
        id: 1,
        post: (
          <UITestTabOverview
            mobileFunctionalResults={mobileFunctionalResults}
            webFunctionalResults={webFunctionalResults}
            reportData={reportData}
          />
        ),
      },
    ],
  };
  const speedTestTab = {
    title: LocationCategory.SPEED,
    content: [
      {
        id: 1,
        post: (
          <SpeedTestTabOverview
            reportData={reportData}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            mobileSpeedResult={mobileSpeedResult}
            webSpeedResult={webSpeedResult}
          />
        ),
      },
    ],
  };
  const testLocationTabList = isBothTestEnabled
    ? [uiTestTab, speedTestTab]
    : isUITestEnabled
      ? [uiTestTab]
      : [speedTestTab];

  return (
    <ErrorBoundaryWrapper>
      <MainHeader
        firstHeader={'Overview'}
        headerTitle={'Overview'}
        rightElement={
          isInitialLoading ? (
            <div className="flex h-11 w-48 animate-pulse items-center justify-end rounded-md bg-gray-200" />
          ) : (
            <TestCategoryTab
              testLocationTabList={testLocationTabList}
              categorySelectedIndex={categorySelectedIndex}
              setCategorySelectedIndex={setCategorySelectedIndex}
              selectedTestCategory={selectedTestCategory}
              setSelectedTestCategory={setSelectedTestCategory}
              setSelectedIndex={setSelectedIndex}
            />
          )
        }
      />
      {isInitialLoading || isDataFetching ? (
        <OverviewGraphSkeleton />
      ) : functionalTestOverviewQuery.isError ? (
        <ErrorMessage>{functionalTestOverviewQuery.error.message}</ErrorMessage>
      ) : mobileSpeedResultQuery.isError ? (
        <ErrorMessage>{mobileSpeedResultQuery.error.message}</ErrorMessage>
      ) : webSpeedResultQuery.isError ? (
        <ErrorMessage>{webSpeedResultQuery.error.message}</ErrorMessage>
      ) : resultRateQuery.isError ? (
        <ErrorMessage>{resultRateQuery.error.message}</ErrorMessage>
      ) : (
        <ErrorBoundaryWrapper>
          <TestCategoryContents
            testLocationTabList={testLocationTabList}
            categorySelectedIndex={categorySelectedIndex}
          />
        </ErrorBoundaryWrapper>
      )}
    </ErrorBoundaryWrapper>
  );
};

export default CommonDashboardOverview;
