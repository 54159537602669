import { PlatFormTypeServerName } from '@autosquare/common';
import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { TestResultStatus } from '@customTypes/testResult/type';

export enum SpeedMobileTestTypeCategory {
  Monitoring = 'Monitoring',
  Scenario = 'Scenario',
  Case = 'Case',
}

export enum SpeedMobileTestTypeServerName {
  Monitoring = 'Speed_Monitoring',
  Scenario = 'Scenario_speed',
  Case_App = 'Unit_app_speed',
  Case_Web = 'Unit_web_speed',
}
export enum SpeedMobileTestResultDisplayType {
  Speed_Monitoring = SpeedMobileTestTypeCategory.Monitoring,
  Scenario_speed = SpeedMobileTestTypeCategory.Scenario,
  Unit_app_speed = SpeedMobileTestTypeCategory.Case,
  Unit_web_speed = SpeedMobileTestTypeCategory.Case,
}

export type ServiceSpeedData = {
  name: string;
  value: number;
  result: TestResultStatus;
};

export type EnvironmentData = {
  deviceName: string;
  os: DeviceInfoOs;
};

export type ContentsData = {
  resultIdx: number;
  testType: SpeedMobileTestTypeServerName;
  environment: EnvironmentData;
  group: string;
  groupIdx: number;
  dateTime: string;
  loadSpeedData: ServiceSpeedData[];
  resultEnum: TestResultStatus;
  tester: string;
};

export type MobileSpeedTestResultData = {
  first: boolean;
  last: boolean;
  currentPage: number;
  currentSize: number;
  totalPages: number;
  totalElements: number;
  content: ContentsData[];
  initialCreatedAt?: string;
};

export type ExecutionDetailStep = {
  stepIdx: number;
  command: string;
  description: string;
  result: TestResultStatus;
  failReason: string | null;
};

export type ExecutionDetail = {
  title: string;
  result: string;
  stepList: ExecutionDetailStep[];
  failReason: string | null;
};

export type RepeatResultsData = {
  repeat: number;
  dateTime: string;
  loadTime: number;
  domTime: number;
  videoPath: string;
};

export type MobileSpeedTestResultTableDetailData = {
  idx: number;
  serviceName: string;
  version: string;
  averageTime: number;
  executionDetail?: ExecutionDetail;
  repeatResults: RepeatResultsData[];
  logPath: string;
  platform: PlatFormTypeServerName;
  url?: string;
};

export type LoadSpeedData = {
  value: number;
  detailIdx: number;
  result: TestResultStatus;
};
export type LoadSpeedDataContents = {
  name: string;
  data: LoadSpeedData[];
};
export type MobileSpeedTestResultDetailData = {
  idx: number;
  group: string;
  tester: string;
  dateDuration: string;
  os: DeviceInfoOs;
  device: string;
  title: string;
  testType: string;
  dateList: string[];
  loadSpeedData: LoadSpeedDataContents[];
  failReason: string;
};
