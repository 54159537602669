import React from 'react';

const ProjectApplicationSkeleton = () => {
  return (
    <div className="space-y-5 border-y border-gray-200 pb-8 pt-4">
      {Array.from({ length: 6 }, (_, i) => i).map((x) => (
        <div key={x} className="grid grid-cols-8 gap-4">
          <div className="h-7 w-24 animate-pulse rounded-md bg-gray-300" />
          <div className="h-7 w-36 animate-pulse rounded-md bg-gray-300" />
        </div>
      ))}
    </div>
  );
};

export default ProjectApplicationSkeleton;
