import axios from 'axios';
import * as Sentry from '@sentry/react';

import { store } from '@app/store';
import {
  getAccessToken,
  getAutosquareMode,
  getIdeAccessToken,
  getRemoteAccessToken,
} from '@store/api/accessTokenSlice';
import { decodeJwtToken } from '@autosquare/common';
import { getPreviousLink } from '@store/data/previousLinkSlice';

import {
  getErrorMessage,
  openErrorModal,
} from '@store/ide/remote/remoteConnectionStatusSlice';
import { Mode } from '@customTypes/ide/remote/type';
import { deleteRemoteInfo } from '@store/ide/remote/remoteControlSlice';

export const axiosInstance = axios.create({
  baseURL: `${location.protocol}//${location.port === '3000' || location.port === '3002' ? location.hostname : location.host}`,
  timeout: 60 * 1000,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = store.getState().accessTokenValue.ideAccessToken;
    if (accessToken) {
      config.headers.Authorization = accessToken;
      const userId = decodeJwtToken(accessToken)?.sub;
      Sentry.setUser({ id: userId });
    }

    const remoteAccessToken =
      store.getState().accessTokenValue.remoteAccessToken;
    if (remoteAccessToken) {
      config.headers['Remote-Access'] = remoteAccessToken;
    } else {
      delete config.headers['Remote-Access'];
    }
    const remotePCInfo = store.getState().remoteControl.remoteInfo;
    const mode = store.getState().accessTokenValue.autosquareMode;

    const url = localStorage.getItem('applicationUrl');
    const remoteExitUrl = config.url.includes(`${url}/remote/exit`);
    const remoteConfiguration =
      localStorage.getItem('configuration') === Mode.Remote;
    if (remoteConfiguration && remotePCInfo && !remoteExitUrl) {
      config.headers['Autosquare-Mode'] = mode;
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    store.dispatch(getIdeAccessToken(response.headers.authorization));
    store.dispatch(getRemoteAccessToken(response.headers['remote-access']));
    store.dispatch(getAutosquareMode(response.headers['autosquare-mode']));

    return response.data;
  },

  (error) => {
    const { config } = error;
    if (config?.customFlag) {
      if (
        (error.response &&
          error.response?.data?.errorDetails?.errorCode === '03R000001') ||
        (error.status === 400 &&
          error.response?.data?.errorDetails?.message?.include(
            '원격 접속이',
          )) ||
        error.code === 'ERR_NETWORK'
      ) {
        const errorMessage = error.response?.data?.errorDetails?.message;
        store.dispatch(openErrorModal());
        store.dispatch(getErrorMessage(errorMessage));
        store.dispatch(deleteRemoteInfo());
      }
      if (error.status === 405) {
        window.location.href = `/ide/not-found`;
        return;
      }

      return Promise.reject(error.response?.data?.errorDetails);
    }

    if (
      (error.response && error.response?.status >= 500) ||
      (error.isAxiosError && !error.response)
    ) {
      Sentry.captureException(error);
    }

    if (error.response?.status === 404 || error.response?.status === 405) {
      window.location.href = `/ide/not-found`;
    }

    if (error.response?.status === 401) {
      window.location.href = `/ide/invalid-token`;
    }

    if (error.response?.status === 500) {
      window.location.href = `/ide/internal-server-error`;
    }

    return Promise.reject(
      error.response?.data?.errorDetails
        ? error.response?.data?.errorDetails
        : error.response?.data?.detail
          ? { message: error.response?.data?.detail }
          : {
              message:
                '알 수 없는 에러가 발생했습니다.\n에러가 지속된다면 관리자에게 문의 부탁드립니다.',
            },
    );
  },
);

export const axiosLocalInstance = axios.create({
  baseURL: `${location.protocol}//${location.port === '3000' || location.port === '3002' ? location.hostname : location.host}`,
  timeout: 3 * 60 * 1000,
  withCredentials: true,
});

axiosLocalInstance.interceptors.request.use(
  (config) => {
    const accessToken = store.getState().accessTokenValue.ideAccessToken;
    if (accessToken) {
      config.headers.Authorization = accessToken;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  },
);

axiosLocalInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },

  (error) => {
    if (
      (error.response && error.response?.status >= 500) ||
      (error.isAxiosError && !error.response)
    ) {
      Sentry.captureException(error);
    }

    if (error.response?.status === 401) {
      window.location.href = `/ide/invalid-token`;
    }

    return Promise.reject(
      error.response?.data?.errorDetails
        ? error.response?.data?.errorDetails
        : error.message
          ? { message: error.message }
          : {
              message:
                '알 수 없는 에러가 발생했습니다.\n에러가 지속된다면 관리자에게 문의 부탁드립니다.',
            },
    );
  },
);

export const axiosRawResponseInstance = axios.create({
  baseURL: `${location.protocol}//${location.port === '3000' || location.port === '3002' ? location.hostname : location.host}`,
  timeout: 60000,
  withCredentials: true,
});

axiosRawResponseInstance.interceptors.request.use(
  (config) => {
    const accessToken = store.getState().accessTokenValue.ideAccessToken;
    if (accessToken) {
      config.headers.Authorization = accessToken;
      const userId = decodeJwtToken(accessToken)?.sub;
      Sentry.setUser({ id: userId });
    } else {
      delete config.headers.Authorization;
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  },
);

axiosRawResponseInstance.interceptors.response.use(
  (response) => {
    store.dispatch(getAccessToken(response.headers.authorization));
    return response;
  },

  async (error) => {
    const { response } = error;

    if (response.status === 401) {
      store.dispatch(
        getPreviousLink(
          window.location.pathname +
            window.location.search +
            window.location.hash,
        ),
      );
      window.location.href = '/invalid-token';
      return;
    }

    if (response && response.status >= 500) {
      Sentry.captureException(error);
      window.location.href = '/internal-server-error';
      return;
    }

    if (response.status === 404) {
      throw Error('404');
    }
    if (error.code === 'ERR_NETWORK' || response.status === 405) {
      window.location.href = `/ide/not-found`;
      return;
    }
    if (error.isAxiosError && !response) {
      Sentry.captureException(error);
      return;
    }

    // Blob 형태의 에러 응답 처리
    if (
      response &&
      response.data instanceof Blob &&
      response.headers['content-type'] === 'application/json'
    ) {
      try {
        const errorText = await response.data.text();
        const errorJson = JSON.parse(errorText);
        return Promise.reject(errorJson.errorDetails ?? errorJson);
      } catch (e) {
        return Promise.reject({
          status: response.status,
          message: '알 수 없는 오류가 발생했습니다.',
        });
      }
    }

    return Promise.reject(
      response?.data?.errorDetails ?? {
        message: '파일 처리 중 문제가 발생했습니다.\n관리자에게 문의해주세요.',
      },
    );
  },
);
