import { ErrorBoundaryWrapper, MailReceiver } from '@autosquare/common';
import { ProjectMember } from '@customTypes/dashboard/project/type';
import {
  CreateSchedulerData,
  UpdateSchedulerData,
} from '@customTypes/ide/scheduler/scheduler';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useSpeedSchedulerEmailAlarmOnKeyDown } from '@hooks/speedScheduler/useSpeedSchedulerEmailAlarmOnKeyDown';
import clsx from 'clsx';
import React from 'react';
import { UseFormSetValue } from 'react-hook-form';

type Props = {
  wrapperRef: React.MutableRefObject<HTMLDivElement>;
  onChangeEmailAddress: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  setIsOpenedMemberList: React.Dispatch<React.SetStateAction<boolean>>;
  inputRef: React.MutableRefObject<HTMLInputElement>;
  emailAddress: string;
  temporarySavedEmailAddress: MailReceiver;
  setEmailAddress: React.Dispatch<React.SetStateAction<string>>;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  setEmailErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  filteredPeople: ProjectMember[];
  setTemporarySavedEmailAddress: React.Dispatch<
    React.SetStateAction<MailReceiver>
  >;
  selectedIndex: number;
  inputFocus: () => void;
  isOpenedMemberList: boolean;
  ulRef: React.MutableRefObject<HTMLUListElement>;
  onSelectMember: (member: ProjectMember) => void;
  mailReceivers: MailReceiver[];
  setValue: UseFormSetValue<CreateSchedulerData | UpdateSchedulerData>;
  disabled?: boolean;
};

const EmailAlarmCombobox = ({
  wrapperRef,
  onChangeEmailAddress,
  setIsOpenedMemberList,
  inputRef,
  emailAddress,
  temporarySavedEmailAddress,
  setEmailAddress,
  setSelectedIndex,
  setEmailErrorMessage,
  filteredPeople,
  setTemporarySavedEmailAddress,
  selectedIndex,
  inputFocus,
  isOpenedMemberList,
  ulRef,
  onSelectMember,
  mailReceivers,
  setValue,
  disabled = false,
}: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div ref={wrapperRef}>
        <div className="relative">
          <div className="relative">
            <input
              type="text"
              className={clsx(
                'w-full rounded-md border-0 py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
                disabled ? 'bg-gray-100' : 'bg-white',
              )}
              onChange={onChangeEmailAddress}
              onKeyDown={(e) =>
                useSpeedSchedulerEmailAlarmOnKeyDown({
                  e: e,
                  setIsOpenedMemberList: setIsOpenedMemberList,
                  inputRef: inputRef,
                  emailAddress: emailAddress,
                  temporarySavedEmailAddress: temporarySavedEmailAddress,
                  setEmailAddress: setEmailAddress,
                  setSelectedIndex: setSelectedIndex,
                  setEmailErrorMessage: setEmailErrorMessage,
                  filteredPeople: filteredPeople,
                  setTemporarySavedEmailAddress: setTemporarySavedEmailAddress,
                  selectedIndex: selectedIndex,
                  mailReceivers: mailReceivers,
                  setValue: setValue,
                })
              }
              placeholder="Enter an email address or select project member"
              onFocus={inputFocus}
              ref={inputRef}
              disabled={disabled}
            />
            <button
              type="button"
              className="absolute right-2 top-2"
              onClick={() => setIsOpenedMemberList(!isOpenedMemberList)}
              onKeyDown={(e) =>
                useSpeedSchedulerEmailAlarmOnKeyDown({
                  e: e,
                  setIsOpenedMemberList: setIsOpenedMemberList,
                  inputRef: inputRef,
                  emailAddress: emailAddress,
                  temporarySavedEmailAddress: temporarySavedEmailAddress,
                  setEmailAddress: setEmailAddress,
                  setSelectedIndex: setSelectedIndex,
                  setEmailErrorMessage: setEmailErrorMessage,
                  filteredPeople: filteredPeople,
                  setTemporarySavedEmailAddress: setTemporarySavedEmailAddress,
                  selectedIndex: selectedIndex,
                  mailReceivers: mailReceivers,
                  setValue: setValue,
                })
              }
              disabled={disabled}
            >
              <ChevronUpDownIcon
                className="size-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
          </div>
          {filteredPeople?.length > 0 && isOpenedMemberList && (
            <ul
              className="absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm"
              ref={ulRef}
            >
              {filteredPeople?.map((person, index) => (
                <li
                  key={person?.userId}
                  className={clsx(
                    'group relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-indigo-600 hover:text-white',
                    index === selectedIndex
                      ? 'bg-indigo-600 text-white'
                      : 'text-gray-900',
                  )}
                  onClick={() => onSelectMember(person)}
                >
                  <div className="flex">
                    <span className={`truncate`}>{person?.name}</span>
                    <span
                      className={clsx(
                        'ml-2 truncate text-gray-500 group-hover:text-indigo-200',
                        index === selectedIndex
                          ? 'text-indigo-200'
                          : 'text-gray-500',
                      )}
                    >
                      {person?.userId}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default EmailAlarmCombobox;
