import { z } from 'zod';
import { SupportedBrowsers } from '../browser/browser';
import dayjs from 'dayjs';

export interface CreateSessionIdResponse {
  sessionId: string;
  value: unknown;
}

export enum DeviceInfoOs {
  Aos = 'aos',
  Ios = 'ios',
}

export enum DeviceInfoOsFullName {
  Aos = 'Android',
  Ios = 'iOS',
}

export enum ConnectionType {
  USB = 'usb',
  Wireless = 'wireless',
  TCP = 'tcp',
}

export const DeviceInfoSchema = z.object({
  udid: z.string().optional(),
  model: z.string().optional(),
  os: z.nativeEnum(DeviceInfoOs).optional(),
  serialNumber: z.string().optional(),
  connectionType: z.nativeEnum(ConnectionType).optional(),
  enabled: z.boolean().optional(),
  installedBrowserList: z.array(z.nativeEnum(SupportedBrowsers)).optional(),
  browser: z.string().nullable().optional(),
});

export interface DeviceInfo {
  udid: string;
  model: string;
  os: DeviceInfoOs;
  serialNumber: string;
  connectionType: ConnectionType;
  enabled?: boolean;
  enabledReason?: string;
  installedBrowserList?: SupportedBrowsers[];
  browser?: string;
}

export interface WifiConnectRequest {
  pairingIpAddressAndPort: string;
  deviceIpAddressAndPort: string;
  pairingCode: string;
}

export interface TcpConnectionRequest {
  serialNumber: string;
  ipAddress: string;
}

export enum TestExecutionTestType {
  Manual = 'manual',
  Monitoring = 'monitoring',
  Unit = 'unit',
  Command = 'command',
  Unit_speed = 'unit_speed',
  Command_speed = 'command_speed',
}

export interface DistributionTestExecutionTestInfo {
  testInfo: DistributionTestExecutionTestData[];
  saveVideo?: boolean;
  saveScreenshot?: boolean;
  saveLog?: boolean;
}

export interface DistributionTestExecutionTestData
  extends BaseDistributionTestExecutionTestData {
  scheduleIdx?: number;
  repeatCount?: number;
}

export interface BaseDistributionTestExecutionTestData {
  testType: TestExecutionTestType;
  idx: number;
  device: DeviceInfo;
  projectIdx: number;
}

export interface WdaStatusResponse {
  projectIdx: number;
  projectName: string;
  status: boolean | null;
  filePath?: string | null;
}

export enum DeviceReservationStatus {
  InUse = 'In Use',
  Reserved = 'Reserved',
  Available = 'Available',
  Unavailable = 'Unavailable',
}

export const ReservationDeviceInfoSchema = z.object({
  deviceIdx: z.number(),
  deviceModel: z.string(),
  deviceName: z.string(),
  deviceOs: z.nativeEnum(DeviceInfoOsFullName),
});

export type ReservationDeviceInfo = z.infer<typeof ReservationDeviceInfoSchema>;

export const ReservationInfoSchema = z.object({
  reservationIdx: z.number(),
  reservationStatus: z.nativeEnum(DeviceReservationStatus),
  reservationStartDate: z.string().date(),
  reservationEndDate: z.string().date(),
  reservationStartTime: z.string().datetime(),
  reservationEndTime: z.string().datetime(),
  userIdx: z.number(),
  userName: z.string(),
});

export type ReservationInfo = z.infer<typeof ReservationInfoSchema>;

export const ReservationDeviceInfoListSchema =
  ReservationDeviceInfoSchema.extend(ReservationInfoSchema.shape);

export const ReservationDeviceListSchema = z.object({
  reservationInfo: z.array(ReservationDeviceInfoListSchema),
  authorizationEnabled: z.boolean(),
});

export type ReservationDeviceList = z.infer<typeof ReservationDeviceListSchema>;

export const ReservationDeviceDetailSchema = z.object({
  deviceInfo: ReservationDeviceInfoSchema,
  reservationInfo: z.array(ReservationDeviceInfoListSchema),
});

export type ReservationDeviceDetail = z.infer<
  typeof ReservationDeviceDetailSchema
>;

export const ReservationDeviceAvailableListSchema = z.object({
  projectIdx: z.number(),
  startDate: z.string().date(),
  endDate: z.string().date(),
  startTime: z.string().time(),
  endTime: z.string().time(),
});

export type ReservationDeviceAvailableList = z.infer<
  typeof ReservationDeviceAvailableListSchema
>;

export const ReservationDeviceSchema =
  ReservationDeviceAvailableListSchema.extend({
    deviceIdx: z
      .array(z.number())
      .min(1, { message: '디바이스를 선택해 주세요.' }),
  });

export type ReservationDevice = z.infer<typeof ReservationDeviceSchema>;

export const ReservationDeviceFormSchema = ReservationDeviceSchema.pick({
  projectIdx: true,
  deviceIdx: true,
}).extend({
  startTime: z
    .string({ message: 'Start Time을 선택해 주세요.' })
    .refine((val) => dayjs(val, 'YYYY-MM-DD HH:mm', true).isValid(), {
      message: 'Start Time을 선택해 주세요.',
    }),
  endTime: z
    .string({ message: 'End Time을 선택해 주세요.' })
    .refine((val) => dayjs(val, 'YYYY-MM-DD HH:mm', true).isValid(), {
      message: 'End Time을 선택해 주세요.',
    }),
});

export type ReservationDeviceForm = z.infer<typeof ReservationDeviceFormSchema>;

export const UpdateReservationDeviceSchema = ReservationDeviceSchema.extend({
  reservationIdx: z.number(),
  deviceIdx: z.number(),
});

export type UpdateReservationDevice = z.infer<
  typeof UpdateReservationDeviceSchema
>;

export const UpdateReservationDeviceFormSchema =
  ReservationDeviceFormSchema.omit({ deviceIdx: true }).extend({
    reservationIdx: z.number(),
    deviceIdx: z.number(),
  });

export type UpdateReservationDeviceForm = z.infer<
  typeof UpdateReservationDeviceFormSchema
>;

export const CancelReservationDeviceSchema = z.object({
  reservationIdxList: z.array(z.number()),
});

export type CancelReservationDevice = z.infer<
  typeof CancelReservationDeviceSchema
>;
