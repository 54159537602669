import React from 'react';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import {
  TestResultDetailInquiryByCase,
  TestResultLabel,
} from '@customTypes/testResult/type';
import { findMobileCommandNameFromServerName } from '@utils/static/mobileCommandOption';
import { findWebCommandNameFromServerName } from '@utils/static/webCommandOptions';
import clsx from 'clsx';

type Props = {
  testResultDetailInquiryByCase: TestResultDetailInquiryByCase[];
};

const TestStepResultDetail = ({ testResultDetailInquiryByCase }: Props) => {
  return (
    <div className="px-4">
      <div className="mt-6 border-b border-gray-200 pb-3">
        <p className="text-sm font-semibold leading-6 text-gray-900">
          Test Step Result({testResultDetailInquiryByCase?.length})
        </p>
      </div>
      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <>
              <ul role="list" className="space-y-6">
                {testResultDetailInquiryByCase?.map(
                  (testResultDetailInquiry, index) => (
                    <li
                      key={testResultDetailInquiry.seq}
                      className="relative flex gap-x-4"
                    >
                      {testResultDetailInquiry.result === 'Fail' ||
                      (testResultDetailInquiry.result === 'N/A' &&
                        testResultDetailInquiry.failReason !== '-') ? (
                        <>
                          <Disclosure defaultOpen={true}>
                            <div className="w-full">
                              <div className="bg-white">
                                <DisclosureButton className="group w-full">
                                  <div className="flex items-center justify-between space-x-3 px-0">
                                    <div className="flex min-w-0 flex-col gap-2">
                                      <div className="relative flex items-start gap-x-3">
                                        <div
                                          className={clsx(
                                            'absolute left-0 top-0 flex w-6 justify-center',
                                            index ===
                                              testResultDetailInquiryByCase?.length -
                                                1
                                              ? 'h-6'
                                              : '-bottom-6',
                                          )}
                                        >
                                          <div className="w-px bg-gray-200" />
                                        </div>
                                        <div className="relative flex size-6 flex-none items-center justify-center bg-white">
                                          <div
                                            className={clsx(
                                              'size-1.5 rounded-full bg-green-500 ring-1 ring-gray-300',
                                              testResultDetailInquiry.result ===
                                                TestResultLabel.Fail
                                                ? 'bg-red-500'
                                                : 'bg-gray-500',
                                            )}
                                          />
                                        </div>
                                        <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                          {isWordInLocationPathname('mobile')
                                            ? findMobileCommandNameFromServerName(
                                                testResultDetailInquiry.command,
                                              )
                                            : findWebCommandNameFromServerName(
                                                testResultDetailInquiry.command,
                                              )}
                                          {' - '}
                                          <span className="font-medium text-gray-900">
                                            {
                                              testResultDetailInquiry.description
                                            }
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="justify-items-end">
                                      <div className="grid grid-cols-1">
                                        <ChevronRightIcon
                                          className="size-5 shrink-0 text-gray-400 group-data-[open]:rotate-90 group-data-[open]:text-gray-500"
                                          aria-hidden="true"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </DisclosureButton>
                                <DisclosurePanel>
                                  <div className="mt-4 pl-10">
                                    <p className="text-sm">
                                      {testResultDetailInquiry.result === 'Fail'
                                        ? `Fail Reason: ${testResultDetailInquiry.failReason}`
                                        : testResultDetailInquiry.result ===
                                            'N/A' &&
                                          testResultDetailInquiry.failReason !==
                                            '-' &&
                                          `N/A Reason: ${testResultDetailInquiry.failReason}`}
                                    </p>
                                  </div>
                                </DisclosurePanel>
                              </div>
                            </div>
                          </Disclosure>
                        </>
                      ) : (
                        <div className="flex items-center justify-between space-x-3 px-0">
                          <div className="flex min-w-0 flex-col gap-2">
                            <div className="relative flex items-start gap-x-3">
                              <div
                                className={clsx(
                                  'absolute left-0 top-0 flex w-6 justify-center',
                                  index ===
                                    testResultDetailInquiryByCase?.length - 1
                                    ? 'h-6'
                                    : '-bottom-6',
                                )}
                              >
                                <div className="w-px bg-gray-200" />
                              </div>
                              <div className="relative flex size-6 flex-none items-center justify-center bg-white">
                                <div
                                  className={clsx(
                                    'size-1.5 rounded-full ring-1 ring-gray-300',
                                    testResultDetailInquiry.result === 'Pass'
                                      ? 'bg-green-500'
                                      : 'bg-gray-500',
                                  )}
                                />
                              </div>
                              <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                {isWordInLocationPathname('mobile')
                                  ? findMobileCommandNameFromServerName(
                                      testResultDetailInquiry.command,
                                    )
                                  : findWebCommandNameFromServerName(
                                      testResultDetailInquiry.command,
                                    )}
                                {' - '}
                                <span className="font-medium text-gray-900">
                                  {testResultDetailInquiry.description}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                  ),
                )}
              </ul>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestStepResultDetail;
