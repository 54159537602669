import {
  ArrowSpinner,
  ErrorBoundaryWrapper,
  Execution,
  StartAndFinishMark,
} from '@autosquare/common';

import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ChevronUpIcon } from '@heroicons/react/20/solid';

import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';

import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import clsx from 'clsx';
import { getCommandDisplayText } from '@utils/index';
import TestStepListProperty from '@components/MobileTestStep/shared/TestStepListProperty';
import { stepDisabledExecution } from '@utils/static/mobileTestStep/stepDisabledExecution';
import { useMobileSpeedTestStepExecutionMutation } from '@lib/api/ide/speedMobile/speedMobileExecution';
import {
  CommonDialog,
  DialogButton,
  DialogButtonPanels,
} from '@components/Dialog';

interface Props {
  list: MobileTestStepData;
  index: number;
  lists: MobileTestStepData[];
  startStepIdx: number;
  endStepIdx: number;
  setIsExecutionStarted: React.Dispatch<React.SetStateAction<boolean>>;
}

const SpeedTestStepDragList = ({
  list,
  index,
  lists,
  startStepIdx,
  endStepIdx,
  setIsExecutionStarted,
}: Props) => {
  const [isOpenedDetail, setIsOpenedDetails] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const openModal = () => {
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const toggledDetails = () => {
    setIsOpenedDetails(!isOpenedDetail);
  };

  const saveTextIndex =
    lists
      ?.map((item) => item.idx)
      .indexOf(parseInt(list.optionsDto.compareIdx)) + 1;

  const mobileSpeedTestStepExecutionMutation =
    useMobileSpeedTestStepExecutionMutation();

  const executeMobileTestStep = () => {
    mobileSpeedTestStepExecutionMutation.mutate(list.idx, {
      onSuccess: () => {
        setIsExecutionStarted(true);
      },
    });
    closeModal();
  };

  const clickExecutionIcon = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    openModal();
  };

  const ifPassAction =
    list.command === MobileCommandOptionServerName.IF
      ? list.value.split(',')[0]
      : undefined;
  const ifFailAction =
    list.command === MobileCommandOptionServerName.IF
      ? list.value.split(',')[1]
      : undefined;

  const { disabledExecution } = stepDisabledExecution(
    list,
    ifPassAction,
    ifFailAction,
  );

  return (
    <ErrorBoundaryWrapper>
      <Draggable draggableId={list.idx.toString()} index={index}>
        {(provided) => (
          <li
            className="flex w-full flex-col"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onDragStart={() => setIsOpenedDetails(false)}
          >
            <div className="flex items-center justify-center">
              {Number(
                provided.dragHandleProps['data-rbd-drag-handle-draggable-id'],
              ) === startStepIdx && (
                <div className="w-1">
                  <StartAndFinishMark size="medium" />
                </div>
              )}
              {Number(
                provided.dragHandleProps['data-rbd-drag-handle-draggable-id'],
              ) === endStepIdx && (
                <div className="w-1">
                  <StartAndFinishMark size="medium" />
                </div>
              )}
              <div
                className={clsx(
                  'flex w-full justify-between border border-gray-200 bg-gray-50 p-3 text-left text-xs font-medium text-gray-900',
                  { 'border-b-0': isOpenedDetail },
                )}
                onClick={toggledDetails}
              >
                <div className="flex w-3/4 flex-row items-center gap-2">
                  <Execution
                    size={5}
                    onClick={clickExecutionIcon}
                    disabled={
                      disabledExecution ||
                      mobileSpeedTestStepExecutionMutation.isLoading
                    }
                    testType={'step'}
                  />
                  <div className="w-[43.607rem] truncate">
                    <p className="truncate text-xs font-medium leading-none">
                      {list.description},{' '}
                      {findMobileCommandNameFromServerName(list.command)},{' '}
                      {getCommandDisplayText(list)}
                    </p>
                  </div>
                </div>
                <ChevronUpIcon
                  className={clsx(
                    'size-5 cursor-pointer text-gray-900',
                    !isOpenedDetail && 'rotate-180',
                  )}
                />
              </div>
            </div>
            {isOpenedDetail && (
              <TestStepListProperty list={list} saveTextIndex={saveTextIndex} />
            )}
          </li>
        )}
      </Draggable>
      <CommonDialog
        isOpen={isModalOpened}
        setIsOpen={setIsModalOpened}
        iconType={'caution'}
        title={'Execute Step Test'}
        subTitle={
          '선택한 스텝에 대한 테스트를 진행하시겠습니까?\n(테스트 결과는 저장되지 않으며,\n테스트 실행 완료 후 현재 스크린샷과 객체 정보가 자동 갱신됩니다.)'
        }
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant="primary"
            onClick={executeMobileTestStep}
          >
            Execute
          </DialogButton>
          <DialogButton type="button" variant="secondary" onClick={closeModal}>
            Cancel
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
      {mobileSpeedTestStepExecutionMutation.isLoading && <ArrowSpinner />}
    </ErrorBoundaryWrapper>
  );
};

export default SpeedTestStepDragList;
