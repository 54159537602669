import { ScenarioListDetailData } from '@autosquare/common';
import { getTestScenarioIndexArray } from '@store/ide/numberArraySlice';

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Card from '@components/ScenarioCardSection/Card';
import { useScenarioDndHandler } from '@hooks/useScenarioDndHandler';
import { SelectedItem } from '@autosquare/common/src/hooks/checkbox/useCheckboxWithDnd';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  MeasuringStrategy,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

interface Props {
  copyTestScenarios: ScenarioListDetailData[];
  setCopyTestScenarios: React.Dispatch<
    React.SetStateAction<ScenarioListDetailData[]>
  >;
  selectedArray: SelectedItem[];
  selectArray: (
    idx: number,
    dndIndex: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  setSelectedArray: React.Dispatch<React.SetStateAction<SelectedItem[]>>;
}

const Paste = ({
  copyTestScenarios,
  setCopyTestScenarios,
  selectedArray,
  selectArray,
  setSelectedArray,
}: Props) => {
  const dispatch = useDispatch();

  const {
    sensors,
    onDragEnd,
    onDragStart,
    items,
    onRemove,
    activeId,
    overlayCopyTestCase,
  } = useScenarioDndHandler(copyTestScenarios, setCopyTestScenarios);

  useEffect(() => {
    dispatch(
      getTestScenarioIndexArray(
        [...copyTestScenarios].map((list) => list?.idx),
      ),
    );
  }, [copyTestScenarios]);

  return (
    <div className="gray-scrollbar max-h-[calc(16.375rem)]">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        modifiers={[restrictToVerticalAxis]}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {copyTestScenarios?.map((copyTestScenario) => (
            <Card
              key={copyTestScenario.dndIndex}
              copyItem={copyTestScenario}
              selectArray={selectArray}
              selectedArray={selectedArray}
              onRemove={onRemove}
              setSelectedArray={setSelectedArray}
            />
          ))}
        </SortableContext>
        <DragOverlay
          wrapperElement="ul"
          style={{
            boxShadow: activeId && '0 10px 10px -5px',
          }}
        >
          {activeId ? (
            <div style={{ transform: 'scale(1.04)' }}>
              <Card
                copyItem={overlayCopyTestCase}
                selectArray={selectArray}
                selectedArray={selectedArray}
                onRemove={onRemove}
                setSelectedArray={setSelectedArray}
              />
            </div>
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
};

export default Paste;
