import React, { useState } from 'react';
import {
  CreatePlusButton,
  ErrorBoundaryWrapper,
  GroupManagement,
  PageHeadingButtons,
  PagingHeadingsInAutosquare,
} from '@autosquare/common';
import { useLocation, useNavigate } from 'react-router-dom';
import MobileSpeedTestCaseList from './components/MobileSpeedTestCaseList';
import GroupProvider from '@contexts/ide/GroupProvider';
import GroupContainer from '@components/shared/TestCaseGroup/GroupContainer';
import SearchForm from '@components/SearchForm/SearchForm';
import { useSearchMobileSpeedTestCaseListQuery } from '@lib/api/ide/speedMobile/speedMobileTestCase';
import getFirstGenerationDateToDateType from '@utils/static/getFirstGenerationDateToDateType';

const MobileSpeedTestCase = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { data: searchMobileSpeedTestCaseList } =
    useSearchMobileSpeedTestCaseListQuery();
  const initialCreatedAt = searchMobileSpeedTestCaseList?.[0]?.initialCreatedAt;
  const formattedCreatedAt = getFirstGenerationDateToDateType(initialCreatedAt);

  const [isGroupOpened, setIsGroupOpened] = useState(false);

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      icon: <CreatePlusButton />,
      variant: 'imageBasic',
      onClick: () => {
        navigate('create');
      },
      description: 'Create Case',
    },
    {
      icon: <GroupManagement />,
      variant: 'imageBasic',
      onClick: () => {
        setIsGroupOpened(true);
      },
      description: 'Group Management',
    },
  ];

  const breadcrumbs = [
    {
      title: 'Speed Test',
      href: `${pathname.split('/').slice(0, -2).join('/')}/overview`,
    },
    { title: 'Mobile', href: pathname },
    { title: 'Test Case', href: pathname },
    { title: 'List', href: pathname },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Case List'}
        pageHeadingButtons={pageHeadingButtons}
      />
      <SearchForm initialCreatedAt={formattedCreatedAt}>
        <SearchForm.Platform />
        <SearchForm.Priority />
      </SearchForm>
      <GroupProvider>
        <MobileSpeedTestCaseList />
        {isGroupOpened && <GroupContainer closeModal={setIsGroupOpened} />}
      </GroupProvider>
    </ErrorBoundaryWrapper>
  );
};

export default MobileSpeedTestCase;
