import { ErrorBoundaryWrapper } from '@autosquare/common';
import { useWebSpeedTestCaseDetailQuery } from '@lib/api/ide/speedWeb/webSpeedTestCase';
import { findFillColorFromName } from '@utils/static/priorityLists';
import React from 'react';

const WebSpeedTestCaseInfoDefault = () => {
  const webSpeedTestCaseDetailQuery = useWebSpeedTestCaseDetailQuery();
  const webSpeedTestCaseDetailData = webSpeedTestCaseDetailQuery.data;

  const fillColor = findFillColorFromName(webSpeedTestCaseDetailData?.priority);

  const descriptionList = [
    {
      term: 'Priority',
      description: (
        <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
          <svg
            className={`size-1.5 ${fillColor}`}
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx={3} cy={3} r={3} />
          </svg>
          {webSpeedTestCaseDetailData?.priority}
        </span>
      ),
    },
    {
      term: 'Test Group',
      description: webSpeedTestCaseDetailData?.caseGroupInfo?.name || '-',
    },
    {
      term: 'Service Group',
      description: webSpeedTestCaseDetailData?.serviceGroupInfo?.name || '-',
    },
    { term: 'Creator', description: webSpeedTestCaseDetailData?.creator },
  ];

  const bottomDescriptionLists = [
    { term: 'Title', description: webSpeedTestCaseDetailData?.title },
    {
      term: 'Description',
      description: webSpeedTestCaseDetailData?.description,
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="mt-1">
        <dl className="grid grid-cols-1 border-t border-gray-100 py-6 sm:grid-cols-4">
          {descriptionList.map((description) => (
            <div key={description.term}>
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {description.term}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                {description.description}
              </dd>
            </div>
          ))}
        </dl>
        <dl className="grid grid-cols-1 border-t border-gray-100 py-6 sm:grid-cols-3">
          {bottomDescriptionLists.map((description) => (
            <div key={description.term}>
              <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-1">
                {description.term}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-2">
                {description.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default WebSpeedTestCaseInfoDefault;
