import React, { useEffect, useState } from 'react';
import { DeviceInfo, DeviceInfoOs } from '@customTypes/ide/device/device';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/store';
import { useParams } from 'react-router-dom';
import {
  ErrorBoundaryWrapper,
  PagingHeadingButtonOnClick,
} from '@autosquare/common';
import { getSelectedDevices } from '@store/ide/device/selectedDevicesSlice';
import { hasDownloadedData } from '@store/ide/settings/settingsSlice';
import RepeatInfo from './SpeedMobileTestExecution/RepeatInfo';
import SpeedExecutionInfo from './SpeedMobileTestExecution/SpeedExecutionInfo';
import SpeedSelectedDevices from './SpeedMobileTestExecution/SpeedSelectedDevices';
import { useFormContext } from 'react-hook-form';
import { SpeedMobileCaseExecution } from '@customTypes/speedTestExecution/speedTestExecution';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';
import TestResultSaveOptions from '../../TestResultSaveOptions/TestResultSaveOptions';
import { UseMutationResult } from '@tanstack/react-query';
import { ExecutionTestData } from '@customTypes/testExecution/type';
import { useTestExecutionContext } from '@contexts/ide/TestExecutionProvider';
import { getRemoteState } from '@utils/static/getConfiguration';
import { useCheckWdaStatusQuery } from '@lib/api/ide/mobile/mobileDevice';

type Props = {
  idx: number;
  setIsReadyToNavigate: React.Dispatch<React.SetStateAction<boolean>>;
  testType: TestTypeCategory;
  divWidth: number;
  mobileOS?: DeviceInfoOs;
  setIsSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsWirelessDeviceModal: React.Dispatch<React.SetStateAction<boolean>>;
  setConnectionType: React.Dispatch<React.SetStateAction<'wifi' | 'port'>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  remoteMobileExecutionMutation?: UseMutationResult<
    unknown,
    Error,
    ExecutionTestData,
    unknown
  >;
  closeModalExecution: () => void;
  setIsExecutionErrorModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SpeedMobileTestExecution = ({
  idx,
  setIsReadyToNavigate,
  testType,
  divWidth,
  mobileOS,
  setIsSettingsOpen,
  setIsWirelessDeviceModal,
  setConnectionType,
  setIsModalOpen,
  remoteMobileExecutionMutation,
  closeModalExecution,
  setIsExecutionErrorModalOpen,
}: Props) => {
  const { projectIdx } = useParams();

  const [selectedDevices, setSelectedDevices] = useState<DeviceInfo[]>([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isSelectedDevices, setIsSelectedDevices] = useState(false);
  const [isXcodeApp, setIsXcodeApp] = useState(false);
  const [isWdaFile, setIsWdaFile] = useState(false);
  const [needIosSetting, setNeedIosSetting] = useState(false);
  const [selectedBrowser, setSelectedBrowser] = useState<string>(null);

  const { watch } = useFormContext<SpeedMobileCaseExecution>();

  const checkedDeviceHandler = (value: DeviceInfo, isChecked: boolean) => {
    const updatedValue = {
      ...value,
      browser: selectedBrowser ? 'chrome' : 'safari',
    };
    if (isChecked) {
      setSelectedDevices([...selectedDevices, updatedValue]);
    }
    const isIncluded = selectedDevices.some(
      (device) => device.udid === value.udid,
    );
    if (!isChecked && isIncluded) {
      setSelectedDevices([]);
    }
  };

  const checkHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: DeviceInfo,
  ) => {
    setIsChecked(!isChecked);
    checkedDeviceHandler(value, e.target.checked);
    setIsSelectedDevices(false);
  };

  const dispatch = useDispatch();
  const isDownloadedData = useSelector(
    (state: RootState) => state.settings.isDownloadedData,
  );

  useEffect(() => {
    dispatch(getSelectedDevices(selectedDevices));
  }, [selectedDevices]);

  const repeatCount = Number(watch('repeatCount'));

  const defaultTestData = {
    infos: selectedDevices,
    projectIdx: Number(projectIdx),
  };

  const { shouldSaveVideo, shouldSaveScreenshot, shouldSaveLogFile } =
    useTestExecutionContext();

  const testStepData = { ...defaultTestData, stepIdx: idx };

  const testCaseData = {
    ...defaultTestData,
    caseIdx: idx,
    saveVideo: shouldSaveVideo,
    saveScreenshot: shouldSaveScreenshot,
    saveLog: shouldSaveLogFile,
  };

  const testScenarioData = {
    ...defaultTestData,
    scenarioIdx: idx,
    saveVideo: shouldSaveVideo,
    saveScreenshot: shouldSaveScreenshot,
    saveLog: shouldSaveLogFile,
  };

  const testData =
    testType === 'step'
      ? testStepData
      : testType === 'case'
        ? testCaseData
        : testScenarioData;

  const { remoteConfiguration, standardConfiguration } = getRemoteState();

  const checkWdaStatusQuery = useCheckWdaStatusQuery();
  const remotePCWdaStatusData = checkWdaStatusQuery.data;
  const remotePCWdaStatus = remotePCWdaStatusData?.filter(
    (data) => data.projectIdx === Number(projectIdx),
  )?.[0]?.status;

  const checkCondition = (os: string) => {
    if (standardConfiguration) {
      return os === DeviceInfoOs.Ios && (!isXcodeApp || !isWdaFile);
    }
    return os === DeviceInfoOs.Ios && remotePCWdaStatus === null;
  };

  const executionTestOnClick = () => {
    if (
      selectedDevices?.length === 0 ||
      repeatCount === 0 ||
      repeatCount < 3 ||
      repeatCount > 50
    ) {
      setIsSelectedDevices(true);
      return;
    }
    if (selectedDevices.some(({ os }) => checkCondition(os))) {
      setNeedIosSetting(true);
      return;
    }
    setIsSelectedDevices(false);

    if (remoteConfiguration) {
      closeModalExecution();
      remoteMobileExecutionMutation.mutate(testData, {
        onSuccess: () => {
          setIsModalOpen(true);
        },
        onError: () => {
          setIsExecutionErrorModalOpen(true);
        },
      });
      return;
    }
    setIsReadyToNavigate(true);
  };

  useEffect(() => {
    const getXcodeLocation = async () => {
      const xcodeLocation: string | undefined =
        await window.electron.invoke('checkXcodeLocation');
      setIsXcodeApp(xcodeLocation?.toLowerCase().includes('xcode.app'));
    };
    const checkWdaFile = async () => {
      const existWdaFile: boolean = await window.electron.invoke(
        'existWdaFile',
        projectIdx,
      );
      setIsWdaFile(existWdaFile);
    };

    if (standardConfiguration) {
      getXcodeLocation();
      checkWdaFile();

      if (isDownloadedData) {
        getXcodeLocation();
        checkWdaFile();
        dispatch(hasDownloadedData(false));
      }
    }
  }, [isDownloadedData, standardConfiguration]);

  const breadcrumbs = [
    { title: 'Speed Test' },
    { title: 'Mobile' },
    {
      title:
        testType === 'scenario'
          ? 'Test Scenario'
          : testType === 'case'
            ? 'Test Case'
            : 'Test Step',
    },
    { title: 'Execution' },
  ];

  const pageHeadingButtons = [
    { title: 'Execution test', onClick: executionTestOnClick },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingButtonOnClick
        breadcrumbs={breadcrumbs}
        pageTitle={
          testType === 'scenario'
            ? 'Test Scenario Execution'
            : testType === 'case'
              ? 'Test Case Execution'
              : 'Test Step Execution'
        }
        pageHeadingButtons={pageHeadingButtons}
        count={selectedDevices?.length}
      />
      <SpeedExecutionInfo idx={idx} testType={testType} />
      <TestResultSaveOptions>
        <TestResultSaveOptions.Video />
        <TestResultSaveOptions.LogFile />
      </TestResultSaveOptions>
      <RepeatInfo isSelectedDevices={isSelectedDevices} />
      <SpeedSelectedDevices
        selectedDevices={selectedDevices}
        setSelectedDevices={setSelectedDevices}
        checkHandler={checkHandler}
        isSelectedDevices={isSelectedDevices}
        setIsSelectedDevices={setIsSelectedDevices}
        divWidth={divWidth}
        testType={testType}
        mobileOS={mobileOS}
        idx={idx}
        needIosSetting={needIosSetting}
        setNeedIosSetting={setNeedIosSetting}
        setIsSettingsOpen={setIsSettingsOpen}
        setSelectedBrowser={setSelectedBrowser}
        setIsWirelessDeviceModal={setIsWirelessDeviceModal}
        setConnectionType={setConnectionType}
      />
    </ErrorBoundaryWrapper>
  );
};

export default SpeedMobileTestExecution;
