import { regexLibrary } from '@autosquare/common';
import { z } from 'zod';

export interface UserInfoByDecodeKey {
  userId: string;
}

export interface SignUpData {
  key: string;
  userId: string;
  name: string;
  password: string;
}

export const SignupRequestBaseSchema = z.object({
  key: z.string(),
  userId: z.string().email(),
  name: z
    .string()
    .min(1, {
      message: '1~50자 한글 또는 영어 이름을 입력해 주세요.',
    })
    .max(50, {
      message: '1~50자 한글 또는 영어 이름을 입력해 주세요.',
    })
    .regex(regexLibrary.signup.userName, {
      message: '1~50자 한글 또는 영어 이름을 입력해 주세요.',
    }),
  password: z.string().regex(regexLibrary.password, {
    message: '8~16자의 영문 대소문자, 숫자, 기호를 사용해 주세요.',
  }),
  confirmPassword: z.string(),
  jobTitle: z
    .string()
    .min(1, { message: '1~50자로 입력해 주세요.' })
    .max(50, { message: '1~50자로 입력해 주세요.' }),
  organization: z.preprocess(
    (val) => (typeof val === 'string' && val === '' ? undefined : val),
    z.string().max(50, { message: '1~50자로 입력해 주세요.' }).optional(),
  ),
  department: z.preprocess(
    (val) => (typeof val === 'string' && val === '' ? undefined : val),
    z.string().max(50, { message: '1~50자로 입력해 주세요.' }).optional(),
  ),
});

export const SignupRequestSchema = SignupRequestBaseSchema.refine(
  (data) => data.password === data.confirmPassword,
  {
    message: '비밀번호가 일치하지 않습니다.',
    path: ['confirmPassword'],
  },
);

export type SignupRequest = z.infer<typeof SignupRequestSchema>;
