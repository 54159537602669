import React from 'react';
import clsx from 'clsx';
import OverviewNoData from '@components/shared/OverviewNoData';
import OverviewSubTitle from '@components/shared/OverviewSubTitle';
import { OverviewResultRateReport } from '@customTypes/ide/overview/type';
import { ComputerDesktopIcon } from '@heroicons/react/20/solid';
import { DevicePhoneMobileIcon } from '@heroicons/react/24/solid';
import { breakdownGroupData } from '@utils/static/overview/breakdownGroupData';
import BreakdownItem from './TestBreakdownGroup/BreakdownItem';

type Props = {
  data: OverviewResultRateReport;
  isMobileTestEnabled: boolean;
  isWebTestEnabled: boolean;
};

const TestBreakdownGroup = ({
  data,
  isMobileTestEnabled,
  isWebTestEnabled,
}: Props) => {
  const { mergedTotalBreakdown } = breakdownGroupData(
    data,
    isMobileTestEnabled,
    isWebTestEnabled,
  );

  return (
    <div
      className={clsx(
        'grid w-full space-y-2.5',
        isMobileTestEnabled && isWebTestEnabled
          ? 'grid-cols-3 gap-x-4 2xl:gap-12'
          : 'grid-cols-1',
      )}
    >
      {mergedTotalBreakdown.map((item) => (
        <div
          key={item.type}
          className={clsx('grid w-full grid-cols-1 gap-x-2.5 space-y-2.5')}
        >
          <OverviewSubTitle
            key={item.type}
            subTitle={item.type}
            description={`${item.total}개`}
            isSubCategory
          />
          {item.total === 0 ? (
            <OverviewNoData />
          ) : (
            <div
              className={clsx(
                'grid w-full space-y-2',
                isMobileTestEnabled && isWebTestEnabled
                  ? 'grid-rows-2'
                  : 'grid-rows-1',
              )}
            >
              {isMobileTestEnabled && (
                <BreakdownItem
                  icon={<DevicePhoneMobileIcon className="size-6" />}
                  type={item.type}
                  count={item.count.mobile}
                  deactivate={item.deactivate?.mobile}
                  activate={item.activate?.mobile}
                  priority={item.priority?.mobile}
                  total={item.total}
                  isMobileTestEnabled={isMobileTestEnabled}
                />
              )}
              {isWebTestEnabled && (
                <BreakdownItem
                  icon={<ComputerDesktopIcon className="size-6" />}
                  type={item.type}
                  count={item.count.web}
                  deactivate={item.deactivate?.web}
                  activate={item.activate?.web}
                  priority={item.priority?.web}
                  total={item.total}
                  isWebTestEnabled={isWebTestEnabled}
                />
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TestBreakdownGroup;
