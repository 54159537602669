import {
  DisplayDataType,
  Operator,
  OperatorSymbol,
  ServerFormatDataType,
} from '@customTypes/index';

export const dataTypeList = [
  {
    idx: 1,
    name: DisplayDataType.Number,
    serverName: ServerFormatDataType.Number,
  },
];

export const findDataType = (serverName: ServerFormatDataType) =>
  dataTypeList.find((dataType) => dataType.serverName === serverName)?.name;

export const operatorList = [
  { idx: 1, label: OperatorSymbol.Plus, serverName: Operator.Plus },
  { idx: 2, label: OperatorSymbol.Minus, serverName: Operator.Minus },
];

export const findOperatorLabel = (serverName: Operator) =>
  operatorList.find((operator) => operator.serverName === serverName)?.label;
