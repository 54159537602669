import React from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  DefaultSkeleton,
  ErrorBoundaryWrapper,
  ErrorMessage,
  Pagination,
  PagingHeadingsInAutosquare,
  SpeedTestResultTableSkeleton,
} from '@autosquare/common';
import { useSearchMobileSpeedTestResultQuery } from '@lib/api/dashboard/mobile/mobileSpeedResult';
import SearchForm from '@components/SearchForm/SearchForm';
import MobileSpeedTestResultTable from '@components/SpeedTest/MobileSpeedTestResult/MobileSpeedTestResultTable';
import getFirstGenerationDateToDateType from '@utils/static/getFirstGenerationDateToDateType';

const MobileSpeedTestResult = () => {
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));
  const size = Number(searchParams.get('size'));

  const searchMobileSpeedTestResultQuery =
    useSearchMobileSpeedTestResultQuery();

  const searchMobileSpeedTestResult = searchMobileSpeedTestResultQuery.data;

  const initialCreatedAt = searchMobileSpeedTestResult?.initialCreatedAt;
  const formattedCreatedAt = getFirstGenerationDateToDateType(initialCreatedAt);

  const currentUrl = location.pathname + location.search;
  const total = searchMobileSpeedTestResult?.totalElements;
  const breadcrumbs = [
    { title: 'Mobile', href: currentUrl },
    { title: 'Speed Test Result', href: currentUrl },
    { title: 'List', href: currentUrl },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Speed Test Result List'}
      />
      <SearchForm
        calendarSubTitle="Date"
        isFunctionalTest={false}
        initialCreatedAt={formattedCreatedAt}
      >
        <SearchForm.ResultTestType isFunctionalTest={false} />
      </SearchForm>
      {searchMobileSpeedTestResultQuery.isLoading ||
      searchMobileSpeedTestResultQuery.isFetching ? (
        <SpeedTestResultTableSkeleton />
      ) : searchMobileSpeedTestResultQuery.isError ? (
        <ErrorMessage>
          {searchMobileSpeedTestResultQuery.error.message}
        </ErrorMessage>
      ) : (
        <MobileSpeedTestResultTable resultData={searchMobileSpeedTestResult} />
      )}
      {searchMobileSpeedTestResultQuery.isLoading ? (
        <DefaultSkeleton number={1} height={'h-4'} outMargin={'my-4'} />
      ) : (
        total > 0 && (
          <Pagination
            page={page}
            total={total}
            size={size}
            isDashboard={false}
          />
        )
      )}
    </ErrorBoundaryWrapper>
  );
};

export default MobileSpeedTestResult;
