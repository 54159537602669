import { ErrorBoundaryWrapper } from '@autosquare/common';
import React, { useState } from 'react';
import MemberInformation from './components/MemberInformation';
import RemoveDialog from './components/RemoveDialog';
import ProjectMembersPagingHeadings from './components/ProjectMembersPagingHeadings';

const ProjectMemberDetail = () => {
  const [isRemoved, setIsRemoved] = useState(false);

  return (
    <ErrorBoundaryWrapper>
      <ProjectMembersPagingHeadings setIsRemoved={setIsRemoved} />
      <MemberInformation />
      <RemoveDialog isRemoved={isRemoved} setIsRemoved={setIsRemoved} />
    </ErrorBoundaryWrapper>
  );
};

export default ProjectMemberDetail;
