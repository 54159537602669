import { ErrorBoundaryWrapper } from '@autosquare/common';
import React from 'react';
import ReserverDeviceDetailHeader from './components/ReserverDeviceDetailHeader';
import ReservationInformation from './components/ReservationInformation';
import ReservationTime from './components/ReservationTime';

const ReservedDeviceDetail = () => {
  return (
    <ErrorBoundaryWrapper>
      <ReserverDeviceDetailHeader />
      <ReservationInformation />
      <ReservationTime />
    </ErrorBoundaryWrapper>
  );
};

export default ReservedDeviceDetail;
