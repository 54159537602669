import {
  ErrorBoundaryWrapper,
  SectionTitleHeaderDescription,
  SectionTitleHeader,
  SectionTitleHeaderContainer,
  TableContainer,
  SectionTitleContainer,
} from '@autosquare/common';
import React, { useState } from 'react';
import ReservationDeviceTableHeader from './ReservationDeviceAuthorization/ReservationDeviceTableHeader';
import ReservationDeviceAuthorizationTableBody from './ReservationDeviceAuthorization/ReservationDeviceAuthorizationTableBody';

const ReservationDeviceAuthorization = () => {
  const [headerLength, setHeaderLength] = useState(0);

  return (
    <ErrorBoundaryWrapper>
      <SectionTitleContainer>
        <SectionTitleHeaderContainer>
          <SectionTitleHeader>Device Information</SectionTitleHeader>
          <SectionTitleHeaderDescription>
            등록된 디바이스의 정보 및 사용 상태를 확인할 수 있습니다.
          </SectionTitleHeaderDescription>
        </SectionTitleHeaderContainer>
      </SectionTitleContainer>
      <TableContainer>
        <ReservationDeviceTableHeader setHeaderLength={setHeaderLength} />
        <ReservationDeviceAuthorizationTableBody headerLength={headerLength} />
      </TableContainer>
    </ErrorBoundaryWrapper>
  );
};

export default ReservationDeviceAuthorization;
