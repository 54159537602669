import {
  Breadcrumbs,
  ErrorBoundaryWrapper,
  ErrorMessage,
  PagingHeadings,
} from '@autosquare/common';
import React from 'react';
import SettingsEmailForm from '@components/SettingsAndHelpModal/components/Settings/Alarm/SettingsEmailForm';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';
import { useEmailAlarmSettingQuery } from '@lib/api/ide/setting/settings';

type Props = {
  closeModal: () => void;
};
const Alarm = ({ closeModal }: Props) => {
  const accessToken = useSelector(
    (state: RootState) => state.accessTokenValue.ideAccessToken,
  );
  const { isLoading, isError, error } = useEmailAlarmSettingQuery({
    enabled: accessToken !== null,
  });

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'Settings' },
    { title: 'Alarm' },
  ];
  return (
    <ErrorBoundaryWrapper>
      <main className="gray-scrollbar w-full py-5 pr-10">
        <PagingHeadings
          breadcrumbs={breadcrumbs}
          pageTitle={'Alarm Settings'}
        />
        {isLoading ? (
          <div className="pt-4">Loading...</div>
        ) : isError ? (
          <ErrorMessage>{error.message}</ErrorMessage>
        ) : (
          <SettingsEmailForm closeModal={closeModal} />
        )}
      </main>
    </ErrorBoundaryWrapper>
  );
};

export default Alarm;
