import React, { useEffect } from 'react';
import { CommonDialog } from '@components/Dialog';
import {
  Controller,
  UseFieldArrayUpdate,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { AutoRegisterInfoData } from '@customTypes/ide/scheduler/scheduler';
import { ListBoxHeadlessInController } from '@autosquare/common';
import {
  AutoRegisterPlatformServername,
  findAutoRegisterPlatformName,
} from '@utils/static/autoRegister/autoRegisterTypeList';
import { hasDuplicateTestResults } from '@utils/static/autoRegister/hasDuplicateJiraMapping';
import {
  CreateTestScenarioData,
  UpdateTestScenarioData,
} from '@customTypes/testScenario/type';
import AutoRegisterScenarioEditInputList from './IdeAutoRegisterScenarioEditModal/AutoRegisterScenarioEditInputList';
import { useGetAutoRegistersInfoQuery } from '@lib/api/ide/autoRegisters/autoRegisters';

type Props = {
  index: number;
  update: UseFieldArrayUpdate<
    CreateTestScenarioData | UpdateTestScenarioData,
    'autoRegisters'
  >;
  selectedData: AutoRegisterInfoData;
  autoRegistersList: AutoRegisterInfoData[];
  setAutoRegisterList: React.Dispatch<
    React.SetStateAction<AutoRegisterInfoData[]>
  >;
  isEditModalOpened: boolean;
  setIsEditModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

const IdeAutoRegisterScenarioEditModal = ({
  index,
  update,
  selectedData,
  autoRegistersList,
  setAutoRegisterList,
  isEditModalOpened,
  setIsEditModalOpened,
}: Props) => {
  const {
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateTestScenarioData | UpdateTestScenarioData>();

  const { data: autoRegisterInfo } = useGetAutoRegistersInfoQuery();

  const currentPlatform = autoRegistersList?.[index]?.platformName;
  const filteredPlatformList = autoRegisterInfo
    ?.map((info) => ({
      serverName: info.platform,
      name: findAutoRegisterPlatformName(info.platform),
    }))
    .filter(
      (item) =>
        !autoRegistersList.some(
          (data) => data.platformName === item.serverName,
        ),
    );

  const autoRegisterNewData = useWatch({
    control,
    name: `autoRegisters.${index}`,
  });
  const platform = autoRegisterNewData?.platformName;
  const mapping = autoRegisterNewData?.jira?.mapping?.every(
    (item) => item.issueType && item.testResult,
  );

  const requestUrl = autoRegisterNewData?.confluence?.requestUrl;
  const testResults = autoRegisterNewData?.confluence?.testResults;
  const cancelButtonHandler = () => {
    update(index, selectedData);
    clearErrors('autoRegisters');
    setIsEditModalOpened(false);
  };

  const saveButtonHandler = () => {
    if (platform === AutoRegisterPlatformServername.Jira) {
      if (!mapping) {
        setError(`autoRegisters.${index}.jira.mapping`, {
          type: 'required',
          message: '필수 선택 항목입니다.',
        });
      }

      const result = hasDuplicateTestResults(
        autoRegisterNewData?.jira?.mapping,
      );
      if (result) {
        setError(`autoRegisters.${index}.jira.mapping`, {
          type: 'duplication',
          message: '결과 한개당 하나의 이슈만 등록 가능합니다.',
        });
      }
    }

    if (platform === AutoRegisterPlatformServername.Confluence) {
      if (!requestUrl) {
        setError(`autoRegisters.${index}.confluence.requestUrl`, {
          message: '필수 입력 항목입니다.',
        });
      }
      if (testResults.length === 0) {
        setError(`autoRegisters.${index}.confluence.testResults`, {
          message: '필수 선택 항목입니다.',
        });
      }
    }
    const newDataList = autoRegistersList.map((data, idx) =>
      idx === index ? autoRegisterNewData : data,
    );

    const noErrors = errors.autoRegisters === undefined;
    if (noErrors) {
      setAutoRegisterList(newDataList);
      setIsEditModalOpened(false);
    }
  };

  useEffect(() => {
    if (autoRegistersList[index]) {
      update(index, autoRegistersList[index]);
      setValue(`autoRegisters.${index}.platformName`, currentPlatform);
    }
  }, []);

  return (
    <>
      <CommonDialog
        isOpen={isEditModalOpened}
        setIsOpen={setIsEditModalOpened}
        iconType={'edit'}
        title={'Edit Platform'}
        subTitle={''}
        onClose={cancelButtonHandler}
      >
        <div className="mt-5 space-y-4">
          <div>
            <Controller
              control={control}
              name={`autoRegisters.${index}.platformName`}
              render={({ field }) => (
                <ListBoxHeadlessInController
                  value={field.value}
                  onChange={(value) => {
                    if (
                      value !== selectedData.platformName &&
                      value === AutoRegisterPlatformServername.Jira
                    ) {
                      update(index, {
                        platformName: value,
                        jira: {
                          projectKey: '',
                          mapping: [
                            { testResult: '', issueType: '', priority: '' },
                          ],
                        },
                        confluence: undefined,
                      });
                    }
                    if (
                      value !== selectedData.platformName &&
                      value === AutoRegisterPlatformServername.Confluence
                    ) {
                      update(index, {
                        platformName: value,
                        confluence: {
                          requestUrl: '',
                          testResults: ['Pass', 'Fail', 'N/A'],
                        },
                        jira: undefined,
                      });
                    }
                    field.onChange(value);
                  }}
                  lists={filteredPlatformList}
                  valueToSave={'serverName'}
                  valueToShow={'name'}
                  buttonValue={findAutoRegisterPlatformName(field.value)}
                  placeholder="Select a Platform from the list"
                />
              )}
            />
          </div>
          <AutoRegisterScenarioEditInputList
            autoIndex={index}
            selectedAutoRegisterData={selectedData}
            autoRegistersData={autoRegistersList}
          />
          <CommonDialog.DialogButtonPanels>
            <CommonDialog.DialogButton
              variant="primary"
              type="button"
              onClick={saveButtonHandler}
            >
              Save
            </CommonDialog.DialogButton>
            <CommonDialog.DialogButton
              variant="secondary"
              type="button"
              onClick={cancelButtonHandler}
            >
              Cancel
            </CommonDialog.DialogButton>
          </CommonDialog.DialogButtonPanels>
        </div>
      </CommonDialog>
    </>
  );
};

export default IdeAutoRegisterScenarioEditModal;
