import {
  BasicAlert,
  DoubleCheckDialogHeadlessUI,
  Button,
  ArrowSpinner,
  ErrorBoundaryWrapper,
} from '@autosquare/common';
import React, { useState } from 'react';
import { useDeleteTestStepMutation } from '@lib/api/ide/mobile/mobileTestStep';
import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';
import { ClickId } from '@utils/static/clickOptions';
import {
  CompareBy,
  MaskingOptionsType,
  MobileTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { SaveTextFrom } from '@customTypes/ide/mobileTestStep/SaveTextFrom';
import PropertyDetail from './TestStepListProperty/PropertyDetail';
import PropertyValue from './TestStepListProperty/PropertyValue';
import LoopTestStepList from './TestStepListProperty/LoopTestStepList';
import IfTestStepList from './TestStepListProperty/IfTestStepList';
import TextReplaceOptions from './TestStepListProperty/TextReplaceOptions';
import SaveTextList from './TestStepListProperty/SaveTextList';
import UnLockPatternList from './TestStepListProperty/UnLockPatternList';
import DataValidationList from './TestStepListProperty/DataValidationList';
import { useDeleteSpeedMobileTestStep } from '@lib/api/ide/speedMobile/speedMobileTestStep';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { TrashIcon } from '@heroicons/react/20/solid';

type Props = {
  list: MobileTestStepData;
  saveTextIndex: number;
};

const TestStepListProperty = ({ list, saveTextIndex }: Props) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const openModal = () => {
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const { command, value, description, required, idx, optionsDto, mobileOs } =
    list;

  const deleteTestStepMutation = useDeleteTestStepMutation();
  const deleteSpeedMobileTestStepMutation = useDeleteSpeedMobileTestStep();

  const isUiTestLocation = isWordInLocationPathname('ui-test');

  return (
    <ErrorBoundaryWrapper>
      <div className="text-sm text-gray-500">
        <div className="border border-gray-200">
          <dl className="divide-y divide-gray-100">
            <PropertyDetail
              term={'Command'}
              description={findMobileCommandNameFromServerName(command)}
            />
            <PropertyDetail term={'Description'} description={description} />
            {list.command !== MobileCommandOptionServerName.Loop &&
              list.command !== MobileCommandOptionServerName.IF &&
              list.command !== MobileCommandOptionServerName.UnlockPattern &&
              !(
                list.command === MobileCommandOptionServerName.SaveText &&
                list.optionsDto.from === SaveTextFrom.Sms
              ) && (
                <PropertyValue
                  commandText={command}
                  valueText={value}
                  setIsCopied={setIsCopied}
                  optionsDto={optionsDto}
                  saveTextIndex={saveTextIndex}
                />
              )}
            {(list.command === MobileCommandOptionServerName.Click &&
              list.optionsDto.clickBy === ClickId.Default) ||
            list.command === MobileCommandOptionServerName.ConfirmText ||
            (list.command === MobileCommandOptionServerName.InputText &&
              list.optionsDto.compareBy === CompareBy.Text) ? (
              <>
                <PropertyDetail
                  term={'Secret'}
                  description={
                    list.command === MobileCommandOptionServerName.InputText
                      ? optionsDto.textEncrypt === 'true'
                        ? 'Y'
                        : 'N'
                      : optionsDto.secret === 'true'
                        ? 'Y'
                        : 'N'
                  }
                />
                {list.command !== MobileCommandOptionServerName.InputText && (
                  <TextReplaceOptions list={list} />
                )}
              </>
            ) : list.command === MobileCommandOptionServerName.ScrollSwipe ? (
              <PropertyDetail
                term={'Repeat'}
                description={optionsDto.repeatLimit}
              />
            ) : list.command === MobileCommandOptionServerName.SaveText ? (
              <SaveTextList list={list} />
            ) : list.command ===
              MobileCommandOptionServerName.DataValidation ? (
              <DataValidationList info={list.optionsDto} />
            ) : (
              list.command === MobileCommandOptionServerName.UnlockPattern && (
                <UnLockPatternList list={list} />
              )
            )}
            {list.command !== MobileCommandOptionServerName.Loop &&
              list.command !== MobileCommandOptionServerName.IF &&
              list.command !== MobileCommandOptionServerName.Wait && (
                <>
                  <PropertyDetail
                    term={'Masking Options'}
                    description={
                      optionsDto.maskingOptions?.enabled === 'true'
                        ? `Y / ${optionsDto.maskingOptions?.type === MaskingOptionsType.Element ? '직접 선택' : optionsDto.maskingOptions?.type === MaskingOptionsType.Coordinate ? '영역 선택' : '전체 화면'}`
                        : 'N'
                    }
                  />
                  <PropertyDetail
                    term={'Required'}
                    description={required ? 'Y' : 'N'}
                  />
                </>
              )}
            {list.command === MobileCommandOptionServerName.Loop ? (
              <LoopTestStepList list={list} setIsCopied={setIsCopied} />
            ) : list.command === MobileCommandOptionServerName.IF ? (
              <IfTestStepList list={list} setIsCopied={setIsCopied} />
            ) : (
              list.command === MobileCommandOptionServerName.Wait && (
                <PropertyDetail
                  term={'Masking Options'}
                  description={
                    optionsDto.maskingOptions?.enabled === 'true'
                      ? `Y / ${optionsDto.maskingOptions?.type === MaskingOptionsType.Element ? '직접 선택' : optionsDto.maskingOptions?.type === MaskingOptionsType.Coordinate ? '영역 선택' : '전체 화면'}`
                      : 'N'
                  }
                />
              )
            )}
            {!(
              list.command === MobileCommandOptionServerName.Loop &&
              list.value === MobileCommandOptionServerName.Wait
            ) &&
              list.command !== MobileCommandOptionServerName.Wait && (
                <PropertyDetail
                  term={'WaitTime'}
                  description={optionsDto.waitingTime}
                />
              )}
            <div className="flex items-center justify-end bg-white px-3 py-2">
              <button type="button" onClick={openModal}>
                <TrashIcon className="size-5 text-gray-700" />
              </button>
            </div>
          </dl>
        </div>
      </div>
      <DoubleCheckDialogHeadlessUI
        isOpened={isModalOpened}
        setIsOpened={setIsModalOpened}
        type={'caution'}
        title={
          deleteTestStepMutation.isError ||
          deleteSpeedMobileTestStepMutation.isError
            ? 'Error'
            : 'Delete Test Step'
        }
        subTitle={
          deleteTestStepMutation.isError
            ? deleteTestStepMutation.error.message
            : deleteSpeedMobileTestStepMutation.isError
              ? deleteSpeedMobileTestStepMutation.error.message
              : '해당 테스트 스텝을 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {!deleteTestStepMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() => {
                  if (isUiTestLocation) {
                    deleteTestStepMutation.mutate([idx], {
                      onError: () => {
                        openModal();
                      },
                    });
                  } else {
                    deleteSpeedMobileTestStepMutation.mutate(
                      { stepIdxList: [idx], mobileOs: mobileOs },
                      {
                        onError: () => {
                          openModal();
                        },
                      },
                    );
                  }
                }}
              >
                Delete
              </Button>
            )}
            <Button type="button" variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
          </>
        }
      />
      {isCopied && <BasicAlert phrase="클립보드에 복사되었습니다." />}
      {(deleteTestStepMutation.isLoading ||
        deleteSpeedMobileTestStepMutation.isLoading) && <ArrowSpinner />}
    </ErrorBoundaryWrapper>
  );
};

export default TestStepListProperty;
