import React, { useState } from 'react';
import {
  Breadcrumbs,
  Button,
  ErrorBoundaryWrapper,
  PagingHeadings,
} from '@autosquare/common';
import SubTitleHeaderSection from '@components/shared/SubTitleHeader/SubTitleHeaderSection';
import { useParentPath } from '@hooks/useParentPath';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import UploadFileSection from './components/UploadFileSection';
import FileInformationSection from './components/FileInformationSection';
import {
  AddAppInfo,
  AddAppInfoRequestSchema,
} from '@customTypes/dashboard/project/application/type';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAddAppInfoMutation } from '@lib/api/dashboard/project/project-settings/application/application';
import { ErrorDialog } from '@components/Dialog';

const AddProjectApplication = () => {
  const [isAddAppErrorOpen, setIsAddAppErrorOpen] = useState(false);
  const { pathname } = useLocation();
  const parentPath = useParentPath();
  const navigate = useNavigate();

  const methods = useForm<AddAppInfo>({
    resolver: zodResolver(AddAppInfoRequestSchema),
  });

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'Project Settings', href: pathname },
    { title: 'Application', href: parentPath },
    { title: 'Add', href: pathname },
  ];

  const addAppInfoMutation = useAddAppInfoMutation();
  const onSubmit: SubmitHandler<AddAppInfo> = (data) => {
    delete data.file;
    addAppInfoMutation.mutate(data, {
      onSuccess: () => navigate(parentPath),
      onError: () => setIsAddAppErrorOpen(true),
    });
  };
  const isButtonDisabled = addAppInfoMutation.isLoading;
  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings breadcrumbs={breadcrumbs} pageTitle={'Add Application'} />
      <SubTitleHeaderSection
        header="Upload File"
        description="300MB 이하의 ipa 또는 apk 파일만 업로드할 수 있습니다."
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <UploadFileSection />
          <SubTitleHeaderSection
            header="Information"
            description="업로드한 애플리케이션의 정보를 확인할 수 있습니다."
          />
          <FileInformationSection />
          <div className="flex items-center justify-end gap-x-3">
            <Button
              type="button"
              variant="secondary"
              onClick={() => navigate(parentPath)}
              disabled={isButtonDisabled}
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary" disabled={isButtonDisabled}>
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
      <ErrorDialog
        isOpen={isAddAppErrorOpen}
        setIsOpen={setIsAddAppErrorOpen}
        subTitle={addAppInfoMutation.error && addAppInfoMutation.error.message}
      />
    </ErrorBoundaryWrapper>
  );
};

export default AddProjectApplication;
