import {
  TestResultDetails,
  TestResultExportCaseResultList,
} from '@customTypes/index';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export const useExportOptionsTestResult = (resultDetail: TestResultDetails) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const { watch, setValue } = useFormContext<TestResultExportCaseResultList>();

  const caseResultList = watch('caseResultList');

  const handleParentChange = (
    parentIdx: number,
    checked: boolean,
    stepIdxList: number[],
  ) => {
    if (checked) {
      // 체크박스 선택 시 항목 추가
      const existingItemIndex = caseResultList.findIndex(
        (item) => item.caseResultIdx === parentIdx,
      );

      if (existingItemIndex === -1) {
        // 항목이 없으면 추가
        setValue('caseResultList', [
          ...caseResultList,
          {
            caseResultIdx: parentIdx,
            stepResultIdxList: stepIdxList,
          },
        ]);
      } else {
        // 항목이 이미 존재하는 경우 업데이트
        setValue(
          `caseResultList.${existingItemIndex}.stepResultIdxList`,
          stepIdxList,
        );
      }
    } else {
      // 체크박스 해제 시 항목 삭제
      setValue(
        'caseResultList',
        caseResultList.filter((item) => item.caseResultIdx !== parentIdx),
      );
    }
  };

  const currentItem = caseResultList.find(
    (item) => item.caseResultIdx === resultDetail.idx,
  );

  return { isPanelOpen, setIsPanelOpen, handleParentChange, currentItem };
};
