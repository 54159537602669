import React from 'react';
import SignUpFormFields from './SignUpFormFields';

const ProfileFields = () => {
  return (
    <>
      <SignUpFormFields title={'Job Title*'} name={'jobTitle'} type={'text'} />
      <SignUpFormFields
        title={'Organization'}
        name={'organization'}
        type={'text'}
      />
      <SignUpFormFields
        title={'Department'}
        name={'department'}
        type={'text'}
      />
    </>
  );
};

export default ProfileFields;
