import { ListBoxHeadlessInController } from '@autosquare/common';
import {
  CreateTestScenarioData,
  UpdateTestScenarioData,
} from '@customTypes/testScenario/type';
import {
  AutoRegisterPlatformName,
  AutoRegisterPlatformServername,
  findAutoRegisterPlatformName,
} from '@utils/static/autoRegister/autoRegisterTypeList';
import React from 'react';
import {
  Controller,
  FieldArrayWithId,
  UseFieldArrayUpdate,
  useFormContext,
} from 'react-hook-form';

type Props = {
  update: UseFieldArrayUpdate<
    CreateTestScenarioData | UpdateTestScenarioData,
    'autoRegisters'
  >;
  index: number;
  fields: FieldArrayWithId<
    CreateTestScenarioData | UpdateTestScenarioData,
    'autoRegisters'
  >[];
  filteredDataList: {
    name: AutoRegisterPlatformName;
    serverName: AutoRegisterPlatformServername;
  }[];
};

const AutoRegisterScenarioPlatformNameController = ({
  update,
  index,
  fields,
  filteredDataList,
}: Props) => {
  const { control } = useFormContext<
    CreateTestScenarioData | UpdateTestScenarioData
  >();
  return (
    <Controller
      control={control}
      name={`autoRegisters.${index}.platformName`}
      render={({ field }) => (
        <ListBoxHeadlessInController
          value={field.value}
          onChange={(value) => {
            if (value === AutoRegisterPlatformServername.Confluence) {
              update(fields.length - 1, {
                platformName: value,
                confluence: {
                  requestUrl: '',
                  testResults: ['Pass', 'Fail', 'N/A'],
                },
                jira: undefined,
              });
            } else if (value === AutoRegisterPlatformServername.Jira) {
              update(fields.length - 1, {
                platformName: value,
                jira: {
                  projectKey: '',
                  mapping: [{ testResult: '', issueType: '', priority: '' }],
                },
                confluence: undefined,
              });
            }
            field.onChange(value);
          }}
          lists={filteredDataList}
          valueToSave={'serverName'}
          valueToShow={'name'}
          buttonValue={findAutoRegisterPlatformName(field.value)}
          placeholder="Select a Platform from the list"
        />
      )}
    />
  );
};

export default AutoRegisterScenarioPlatformNameController;
