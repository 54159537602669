import {
  ArrowSpinner,
  Button,
  ErrorBoundaryWrapper,
  TableSkeleton,
} from '@autosquare/common';

import React, { useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import TestStepListTable from './TestStepList/TestStepListTable';

import TestExecutionContainer from '@components/TestExecution/TestExecutionContainer';

import { DeviceInfoOs } from '@customTypes/ide/device/device';

import { useMobileTestStepByCaseQuery } from '@lib/api/ide/mobile/mobileTestStep';
import TestDataAutomationButton from '@components/TestAutomationSettings/mobile/TestDataAutomationButton';
import { useRemoteMobileTestExecutionMutation } from '@lib/api/ide/remote/remote';
import RemoteTestExecutionAlarmModal from '@components/TestExecution/shared/RemoteTestExecutionAlarmModal';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';

const TestStepList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const [isTestExecutionOpened, setIsTestExecutionOpened] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExecutionErrorModalOpen, setIsExecutionErrorModalOpen] =
    useState(false);

  const remoteMobileExecutionMutation =
    useRemoteMobileTestExecutionMutation('step');

  const mobileTestStepInTestCaseQuery = useMobileTestStepByCaseQuery();
  const mobileTestStepList = mobileTestStepInTestCaseQuery.data;

  const testStepList = [
    { idx: 1, title: DeviceInfoOs.Aos },
    { idx: 2, title: DeviceInfoOs.Ios },
  ];

  return (
    <ErrorBoundaryWrapper>
      <TestDataAutomationButton />
      <section className="px-4 pt-6 sm:px-0">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h2 className="text-base font-semibold leading-6 text-gray-900">
              Test Step List
            </h2>
          </div>
          <div className="relative flex gap-4">
            <div className="group">
              <Button
                disabled={
                  mobileTestStepList?.aos.length === 0 &&
                  mobileTestStepList?.ios.length === 0
                }
                type="button"
                onClick={() => setIsTestExecutionOpened(true)}
                variant="secondary"
              >
                Execute
              </Button>
              <div
                className={`absolute bottom-full mb-2 min-w-max rounded bg-black px-2.5 py-1 text-xs text-white ${
                  mobileTestStepList?.aos.length === 0 &&
                  mobileTestStepList?.ios.length === 0
                    ? 'right-2/3 hidden group-hover:block group-hover:opacity-85'
                    : 'hidden'
                }`}
              >
                해당 케이스를 실행할 수 없습니다.
              </div>
            </div>
            <Button
              type="button"
              onClick={() =>
                navigate({
                  pathname: `/ide/projects/${projectIdx}/ui-test/mobile/test-case/device-list`,
                  search: location.search,
                })
              }
            >
              Add test step
            </Button>
          </div>
        </div>
        <div className="grid grid-flow-col grid-cols-2 gap-4">
          {mobileTestStepInTestCaseQuery.isLoading ? (
            <>
              <TableSkeleton />
              <TableSkeleton />
            </>
          ) : (
            testStepList?.map((stepList) => (
              <TestStepListTable key={stepList.idx} title={stepList.title} />
            ))
          )}
        </div>
      </section>
      {isTestExecutionOpened && (
        <TestExecutionContainer
          setState={setIsTestExecutionOpened}
          idx={Number(idx)}
          testType={'case'}
          deviceType={'mobile'}
          setIsModalOpen={setIsModalOpen}
          remoteMobileExecutionMutation={remoteMobileExecutionMutation}
          setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
        />
      )}
      {remoteMobileExecutionMutation.isLoading && <ArrowSpinner />}
      <RemoteTestExecutionAlarmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isExecutionErrorModalOpen={isExecutionErrorModalOpen}
        setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
      />
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default TestStepList;
