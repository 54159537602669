import { UpdateProjectDetailSetting } from '@customTypes/index';
import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  id: keyof UpdateProjectDetailSetting;
  label: string;
  isPreventEnterKey?: boolean;
  disabled?: boolean;
};

const DetailInputFields = ({
  id,
  label,
  isPreventEnterKey = false,
  disabled = false,
}: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<UpdateProjectDetailSetting>();

  const preventEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <p className="font-medium">{label}</p>
      <input
        {...register(id)}
        className="input-base disabled:bg-gray-50"
        disabled={disabled}
        onKeyDown={isPreventEnterKey ? preventEnterKey : undefined}
      />
      <ErrorMessage
        errors={errors}
        name={id}
        render={({ message }) => (
          <p className="standard-error-message -mt-2">{message}</p>
        )}
      />
    </div>
  );
};

export default DetailInputFields;
