import { createSlice } from '@reduxjs/toolkit';

export type RemoteConnectionStatus = {
  isErrorModalOpen: boolean;
  errorMessage: string;
  isConnecting: boolean;
};

const initialState: RemoteConnectionStatus = {
  isErrorModalOpen: false,
  errorMessage: '',
  isConnecting: false,
};

export const remoteConnectionStatusSlice = createSlice({
  name: 'remote connection status',
  initialState,
  reducers: {
    openErrorModal: (state) => {
      state.isErrorModalOpen = true;
    },
    closeErrorModal: (state) => {
      state.isErrorModalOpen = false;
    },
    getErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    startConnecting: (state) => {
      state.isConnecting = true;
    },
    completedConnecting: (state) => {
      state.isConnecting = false;
    },
  },
});

export const {
  openErrorModal,
  closeErrorModal,
  getErrorMessage,
  startConnecting,
  completedConnecting,
} = remoteConnectionStatusSlice.actions;

export default remoteConnectionStatusSlice.reducer;
