import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { axiosInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';

import { projectKeys } from '@queries/ide/projectKeys';
import {
  ProjectListDetail,
  ProjectMember,
} from '@customTypes/dashboard/project/type';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';
import { getRemoteState } from '@utils/static/getConfiguration';

// GET
const getProjectList = async (): Promise<ProjectListDetail[]> => {
  const response = await axiosInstance.get<ProjectListDetail[]>(
    IDEAPI.PROJECT_LIST,
  );
  return response.data;
};
export const useProjectListQuery = (
  options?: UseQueryOptions<ProjectListDetail[]>,
) => {
  const { remoteConfiguration } = getRemoteState();
  const remotePCInfo = useSelector(
    (state: RootState) => state.remoteControl.remoteInfo,
  );
  const defaultKey = ['project', 'projectList'];
  const key =
    remoteConfiguration && remotePCInfo !== undefined
      ? [...defaultKey, remotePCInfo.alias]
      : defaultKey;
  return useQuery<ProjectListDetail[], Error, ProjectListDetail[]>({
    queryKey: key,
    queryFn: getProjectList,
    ...options,
  });
};

const getProjectDetail = async (
  projectIdx: string,
): Promise<ProjectListDetail> => {
  const response = await axiosInstance.get<ProjectListDetail>(
    IDEAPI.PROJECT_DETAIL + projectIdx,
  );
  return response.data;
};
export const useProjectDetailQuery = (
  options?: UseQueryOptions<ProjectListDetail>,
) => {
  const { projectIdx } = useParams();

  return useQuery<ProjectListDetail, Error>({
    queryKey: projectKeys.detail(projectIdx),
    queryFn: () => getProjectDetail(projectIdx),
    ...options,
  });
};

const projectMemberList = async (
  projectIdx: string,
): Promise<ProjectMember[]> => {
  const response = await axiosInstance.get<ProjectMember[]>(
    IDEAPI.getProjectMembers(projectIdx),
  );
  return response.data;
};
export const useProjectMemberListQuery = () => {
  const { projectIdx } = useParams();

  return useQuery<ProjectMember[], Error>({
    queryKey: projectKeys.groupMemberList(projectIdx),
    queryFn: () => projectMemberList(projectIdx),
  });
};
