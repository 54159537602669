import {
  CommonDialog,
  DialogButton,
  DialogButtonPanels,
} from '@components/Dialog';
import { ReservationInfo } from '@customTypes/index';
import { useDeleteDeviceReservationMutation } from '@lib/api/ide/queryHooks';
import React, { useState } from 'react';

type Props = {
  reservationInfo: ReservationInfo;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

const ReservationTimeTableBodyRow = ({ reservationInfo, setIsEdit }: Props) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteErrorModalOpen, setIsDeleteErrorModalOpen] = useState(false);

  const deleteDeviceReservationMutation = useDeleteDeviceReservationMutation();

  const deleteOnClick = () => {
    deleteDeviceReservationMutation.mutate(
      { reservationIdxList: [reservationInfo.reservationIdx] },
      {
        onError: () => {
          setIsDeleteErrorModalOpen(true);
        },
        onSettled: () => {
          setIsDeleteModalOpen(false);
        },
      },
    );
  };

  return (
    <>
      <div key={reservationInfo.reservationIdx} className="table-row">
        <div className="table-cell whitespace-pre-wrap border-t border-gray-200 px-3 py-4 text-sm text-gray-900">{`${reservationInfo.reservationStartDate} ${reservationInfo.reservationStartTime}`}</div>
        <div className="table-cell whitespace-pre-wrap border-t border-gray-200 px-3 py-4 text-sm text-gray-900">{`${reservationInfo.reservationEndDate} ${reservationInfo.reservationEndTime}`}</div>
        <div className="table-cell whitespace-pre-wrap border-t border-gray-200 px-3 py-4 text-sm text-gray-900">
          {reservationInfo.userName}
        </div>
        <div className="table-cell whitespace-pre-wrap border-t border-gray-200 px-3 py-4 text-sm text-gray-900">
          <div className="flex justify-end gap-4 text-sm font-semibold leading-tight">
            <button
              type="button"
              className="text-gray-900 enabled:hover:text-opacity-80"
              onClick={() => setIsEdit(true)}
            >
              Edit
            </button>
            <button
              type="button"
              className="text-red-500 enabled:hover:text-red-300"
              onClick={() => setIsDeleteModalOpen(true)}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      <CommonDialog
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        iconType={'caution'}
        title={'Cancel Reservation'}
        subTitle={'디바이스 예약을 취소하시겠습니까?'}
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant="caution"
            onClick={deleteOnClick}
            disabled={deleteDeviceReservationMutation.isLoading}
          >
            {deleteDeviceReservationMutation.isLoading
              ? 'Deleting...'
              : 'Delete'}
          </DialogButton>
          <DialogButton
            type="button"
            variant="secondary"
            onClick={() => setIsDeleteModalOpen(false)}
          >
            Cancel
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
      <CommonDialog
        isOpen={isDeleteErrorModalOpen}
        setIsOpen={setIsDeleteErrorModalOpen}
        iconType={'caution'}
        title={'Failure'}
        subTitle={deleteDeviceReservationMutation.error?.message}
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant="secondary"
            onClick={() => setIsDeleteErrorModalOpen(false)}
          >
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
    </>
  );
};

export default ReservationTimeTableBodyRow;
