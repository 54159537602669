import {
  ErrorBoundaryWrapper,
  ErrorMessage,
  ImportTestStepTableSkeleton,
} from '@autosquare/common';
import ImportTestStepTable from '@components/TestStep/ImportTestStepTable';
import TestStepDetailBasicDefinition from '@components/shared/TestStepDetail/TestStepDetailBasicDefinition';
import { WebTestStepData } from '@customTypes/testStep/type';
import { useWebTestStepListQuery } from '@lib/api/ide/web/webTestStep';
import { WebCommandOptionServerName } from '@utils/static/webCommandOptions';
import React, { ReactElement } from 'react';

type Props = {
  webTestStepData: WebTestStepData;
  testStepDetailListValue: string | ReactElement;
};

const TestStepDetailBasicValue = ({
  webTestStepData,
  testStepDetailListValue,
}: Props) => {
  const testStepHeader = ['#', 'Command', 'Description'];

  const stepListQuery = useWebTestStepListQuery(webTestStepData.value, {
    enabled:
      webTestStepData?.command === WebCommandOptionServerName.ImportTestStep,
  });
  const stepList = stepListQuery.data;
  const selectedImportTestStep =
    webTestStepData.optionsDto.stepIdxList?.split(',');

  const selectedImportTestStepList = selectedImportTestStep?.flatMap(
    (selected) => stepList?.filter((step) => step.idx === +selected),
  );

  return (
    <TestStepDetailBasicDefinition
      term={'Value'}
      description={
        webTestStepData?.command ===
        WebCommandOptionServerName.ImportTestStep ? (
          <ErrorBoundaryWrapper>
            {stepListQuery.isLoading || stepListQuery.isFetching ? (
              <ErrorBoundaryWrapper>
                <div className="mb-6 h-6 w-64 animate-pulse rounded-md bg-gray-300"></div>
                <ImportTestStepTableSkeleton
                  hasCheckBox={false}
                  hasBorder={true}
                />
              </ErrorBoundaryWrapper>
            ) : stepListQuery.isError ? (
              <ErrorMessage>{stepListQuery.error.message}</ErrorMessage>
            ) : (
              <ErrorBoundaryWrapper>
                <div className="mb-6">{testStepDetailListValue}</div>
                <ImportTestStepTable
                  headers={testStepHeader}
                  rows={selectedImportTestStepList}
                  style="border border-gray-200 rounded-lg"
                  columnWidths={['w-14', 'w-3/6', 'w-3/6']}
                  hasCheckBox={false}
                />
              </ErrorBoundaryWrapper>
            )}
          </ErrorBoundaryWrapper>
        ) : (
          testStepDetailListValue
        )
      }
    />
  );
};

export default TestStepDetailBasicValue;
