import React, { useEffect } from 'react';
import {
  CompareBy,
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { ErrorMessage } from '@hookform/error-message';
import { preventEnterHandler } from '@utils/helper/preventEnterHandler';
interface Props {
  mobileTestStep: MobileTestStepData;
}

const EditInputTextSecretAndTextEncrypt = ({ mobileTestStep }: Props) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<UpdateTestStepData>();
  const compareBy = watch('optionsDto.compareBy');
  const isTextEncrypt = watch('optionsDto.textEncrypt') === 'true';
  const isSavedEncryptValue =
    compareBy === mobileTestStep.optionsDto.compareBy &&
    mobileTestStep.optionsDto.textEncrypt === 'true';

  const isSecret = watch('optionsDto.secret') === 'true';
  const isSavedSecretValue =
    compareBy === mobileTestStep.optionsDto.compareBy &&
    mobileTestStep.optionsDto?.secret === 'true';

  const isEncryptSecretOnEdit =
    (isSecret && isSavedSecretValue) || (isTextEncrypt && isSavedEncryptValue);

  useEffect(() => {
    if (
      mobileTestStep.optionsDto.compareBy === CompareBy.Text &&
      mobileTestStep.optionsDto.textEncrypt === 'true'
    ) {
      setValue('optionsDto.compareText', null);
    }
  }, []);

  return (
    <>
      <input
        type={'text'}
        className={clsx('input-base', {
          'password-input': isTextEncrypt || isSecret,
        })}
        placeholder={
          isEncryptSecretOnEdit
            ? '암호화된 입력 값은 표시되지 않습니다.'
            : '텍스트를 입력해 주세요.'
        }
        {...register('optionsDto.compareText', {
          required: {
            value: !isEncryptSecretOnEdit,
            message: 'Value값을 입력해 주세요.',
          },
        })}
        onKeyDown={(e) => preventEnterHandler(e, isTextEncrypt)}
      />
      <ErrorMessage
        name={'optionsDto.compareText'}
        errors={errors}
        render={({ message }) => (
          <p className="standard-error-message pt-0">{message}</p>
        )}
      />
    </>
  );
};

export default EditInputTextSecretAndTextEncrypt;
