import { DeviceApprovalRequest } from '@customTypes/index';
import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  label: string;
  name: keyof DeviceApprovalRequest;
  children: React.ReactNode;
};

const AddDeviceFormFields = ({ label, name, children }: Props) => {
  const {
    formState: { errors },
  } = useFormContext<DeviceApprovalRequest>();

  return (
    <div className="flex flex-col gap-y-2">
      <label className="text-sm font-medium leading-normal text-gray-900">
        {label}
      </label>
      {children}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <p className="standard-error-message py-0">{message}</p>
        )}
      />
    </div>
  );
};

export default AddDeviceFormFields;
