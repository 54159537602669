import { IDEAPI } from '@api/ideEndpoints';
import {
  CreateWebSpeedTestScenarioData,
  DeleteWebSpeedTestScenarioData,
  UpdateWebSpeedTestScenarioData,
  WebSpeedTestScenarioData,
} from '@customTypes/ide/speedTest/Mobile/testScenario/type';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { webSpeedTestScenarioKeys } from '@queries/ide/speedWeb/webSpeedTestScenarioKeys';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { axiosInstance } from '..';

const speedTestScenarioApi = IDEAPI.WEB_SPEED_TEST_SCENARIO;

//GET
const getSpeedWebTestScenarioList = async (
  projectIdx: string,
): Promise<WebSpeedTestScenarioData[]> => {
  const response = await axiosInstance.get<WebSpeedTestScenarioData[]>(
    `${speedTestScenarioApi}?projectIdx=${projectIdx}`,
  );
  return response.data;
};

export const useSpeedWebTestScenarioListQuery = (
  options?: UseQueryOptions<WebSpeedTestScenarioData[], Error>,
) => {
  const { projectIdx } = useParams();

  return useQuery<
    WebSpeedTestScenarioData[],
    Error,
    WebSpeedTestScenarioData[]
  >({
    queryKey: webSpeedTestScenarioKeys.list(projectIdx),
    queryFn: () => getSpeedWebTestScenarioList(projectIdx),
    ...options,
  });
};

const getSpeedWebTestScenarioDetail = async (idx: string) => {
  const response = await axiosInstance.get<WebSpeedTestScenarioData>(
    `${speedTestScenarioApi}/${idx}`,
  );
  return response.data;
};
export const useSpeedWebTestScenarioDetailQuery = (
  scenarioIndex?: number | undefined,
  options?: UseQueryOptions<
    WebSpeedTestScenarioData,
    Error,
    WebSpeedTestScenarioData
  >,
) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  return useQuery<WebSpeedTestScenarioData, Error, WebSpeedTestScenarioData>({
    queryKey: webSpeedTestScenarioKeys.detail(
      scenarioIndex ? scenarioIndex.toString() : idx,
    ),
    queryFn: () =>
      getSpeedWebTestScenarioDetail(
        scenarioIndex ? scenarioIndex.toString() : idx,
      ),
    ...options,
  });
};

const fetchWebSpeedTestScenarioBySearch = async (
  search: string,
): Promise<WebSpeedTestScenarioData[]> => {
  const response = await axiosInstance.get<WebSpeedTestScenarioData[]>(
    `${IDEAPI.WEB_SPEED_TEST_SCENARIO}/search${search}`,
  );
  return response.data;
};
export const useSearchWebSpeedTestScenarioListQuery = () => {
  const { search } = useLocation();
  return useQuery<WebSpeedTestScenarioData[], Error>({
    queryKey: webSpeedTestScenarioKeys.search(search),
    queryFn: () => fetchWebSpeedTestScenarioBySearch(search),
    enabled: search !== '',
  });
};

//POST
export const createSpeedWebTestScenario = async (
  projectIdx: string,
  data: CreateWebSpeedTestScenarioData,
): Promise<WebSpeedTestScenarioData> => {
  const response = await axiosInstance.post<WebSpeedTestScenarioData>(
    `${speedTestScenarioApi}?projectIdx=${projectIdx}`,
    data,
  );
  return response.data;
};

export const useCreateSpeedWebTestScenarioMutation = () => {
  const queryClient = useQueryClient();
  const { projectIdx } = useParams();

  return useMutation<
    WebSpeedTestScenarioData,
    ServerResponseErrorDetails,
    WebSpeedTestScenarioData
  >({
    mutationFn: (data) => createSpeedWebTestScenario(projectIdx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(webSpeedTestScenarioKeys.all);
    },
  });
};

//PUT
export const updateSpeedWebTestScenario = async (
  idx: string,
  data: UpdateWebSpeedTestScenarioData,
): Promise<WebSpeedTestScenarioData> => {
  const response = await axiosInstance.put<WebSpeedTestScenarioData>(
    `${speedTestScenarioApi}/${idx}`,
    data,
  );
  return response.data;
};

export const useUpdateSpeedWebTestScenarioMutation = (scenarioIdx?: string) => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  return useMutation<
    WebSpeedTestScenarioData,
    ServerResponseErrorDetails,
    UpdateWebSpeedTestScenarioData
  >({
    mutationFn: (data) =>
      updateSpeedWebTestScenario(scenarioIdx ? scenarioIdx : idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(webSpeedTestScenarioKeys.all);
    },
  });
};

//DELETE
export const deleteSpeedWebTestScenarioMutation = async (
  data: DeleteWebSpeedTestScenarioData,
): Promise<string> => {
  const response = await axiosInstance.delete<string>(speedTestScenarioApi, {
    data: data,
  });
  return response.data;
};

export const useDeleteSpeedWebTestScenarioMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { projectIdx } = useParams();

  return useMutation<
    string,
    ServerResponseErrorDetails,
    DeleteWebSpeedTestScenarioData
  >({
    mutationFn: (data) => deleteSpeedWebTestScenarioMutation(data),
    onSuccess: () => {
      navigate(`/ide/projects/${projectIdx}/speed-test/web/test-scenario`);
      queryClient.invalidateQueries(webSpeedTestScenarioKeys.lists());
      queryClient.invalidateQueries(webSpeedTestScenarioKeys.searches());
    },
  });
};
