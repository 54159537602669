import { findTextReplaceAddNameByServerName } from '@utils/static/mobileTestStep/textReplace/textReplaceAddList';
import { findTextReplaceFromNameByServerName } from '@utils/static/mobileTestStep/textReplace/textReplaceFromList';

import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React from 'react';

import PropertyDetail from './PropertyDetail';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Props = {
  list: MobileTestStepData;
};

const TextReplaceOptions = ({ list }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <PropertyDetail
        term={'Text Replace'}
        description={
          list.value?.includes('@text') ||
          list.value?.includes('@label') ||
          list.optionsDto.secret === 'true' ||
          (list.command === MobileCommandOptionServerName.ConfirmText &&
            list.optionsDto.textReplace)
            ? list.optionsDto.textReplace === 'true'
              ? 'Y'
              : 'N'
            : '-'
        }
      />
      {list.optionsDto.textReplace === 'true' && (
        <ErrorBoundaryWrapper>
          <PropertyDetail
            term={'From'}
            description={findTextReplaceFromNameByServerName(
              list.optionsDto.textReplaceOptions.from,
            )}
          />
          <PropertyDetail
            term={'Add'}
            description={`${findTextReplaceAddNameByServerName(list.optionsDto.textReplaceOptions.addKey)} / ${list.optionsDto.textReplaceOptions.addValue}`}
          />
          <PropertyDetail
            term={'Date Fromat'}
            description={list.optionsDto.textReplaceOptions.dateFormat}
          />
        </ErrorBoundaryWrapper>
      )}
    </ErrorBoundaryWrapper>
  );
};

export default TextReplaceOptions;
