import { BrowserInfoSchema } from '../browser/browser';
import { DeviceInfoSchema } from '../device/device';

import { z } from 'zod';
import { DeleteIndexList } from '@customTypes/type';
import { MailReceiverSchema } from '@autosquare/common';

export enum SpeedSchedulerTypeName {
  Mobile = 'Mobile',
  Web = 'Web',
}

export enum SpeedSchedulerTypeServerName {
  Mobile = 'mobile',
  Web = 'web',
}

export const MessengerReceiverSchema = z.object({
  platformName: z.string(),
  recipientList: z.string(),
});

export const BaseSpeedSchedulerSchema = z.object({
  title: z.string(),
  type: z.nativeEnum(SpeedSchedulerTypeServerName),
  startTime: z.string(),
  autoRegister: z.boolean(),
  mailReceivers: z.array(MailReceiverSchema),
  messengerReceivers: z.array(MessengerReceiverSchema),
  saveVideo: z.boolean().optional(),
  saveLog: z.boolean().optional(),
  deviceReboot: z.boolean(),
});

export const SpeedSchedulerDetailSchema = BaseSpeedSchedulerSchema.extend({
  projectIdx: z.number(),
  idx: z.number(),
  scenarioIdxList: z.array(z.number()),
  environment: z.string(),
  cycle: z.string(),
  running: z.boolean(),
  creator: z.string(),
  ownerUuid: z.string(),
  testCategory: z.string(),
  repeatNum: z.number(),
  createdAt: z.string(),
  initialCreatedAt: z.string().optional(),
});

export const BaseLocalSpeedSchedulerSchema = z.object({
  title: z
    .string()
    .min(1, { message: 'Title을 입력해 주세요.' })
    .max(50, { message: '50자 이하 입력해 주세요.' }),
  scenarioIdxList: z.array(z.number()),
  startTime: z.string(),
  autoRegister: z.boolean(),
  mailReceivers: z.array(MailReceiverSchema).optional(),
  messengerReceivers: z.array(MessengerReceiverSchema),
  repeatNum: z
    .number({
      required_error: '3 이상 50 이하 숫자를 입력해 주세요.',
      invalid_type_error: '3 이상 50 이하 숫자를 입력해 주세요.',
    })
    .min(3, { message: '3 이상 50 이하 숫자를 입력해 주세요.' })
    .max(50, { message: '3 이상 50 이하 숫자를 입력해 주세요.' }),
  saveVideo: z.boolean().optional(),
  saveLog: z.boolean().optional(),
  deviceReboot: z.boolean(),
});

export const CreateSpeedSchedulerSchema = BaseLocalSpeedSchedulerSchema.extend({
  type: z.nativeEnum(SpeedSchedulerTypeName),
  projectIdx: z.number(),
  deviceInfoDto: DeviceInfoSchema.optional(),
  browserInfoDto: BrowserInfoSchema.optional(),
  cronCycle: z.string(),
});

const InfosSchema = DeviceInfoSchema.merge(BrowserInfoSchema).optional();

const RecipientData = z.object({
  recipientId: z.string(),
  recipientInfo: z.string().optional(),
});

export const UpdateSpeedSchedulerSchema = BaseLocalSpeedSchedulerSchema.extend({
  scheduleIdx: z.number(),
  infos: InfosSchema,
  cronCycle: z.string(),
});

export type SpeedSchedulerDetailData = z.infer<
  typeof SpeedSchedulerDetailSchema
>;
export type CreateSpeedSchedulerData = z.infer<
  typeof CreateSpeedSchedulerSchema
>;
export type UpdateSpeedSchedulerData = z.infer<
  typeof UpdateSpeedSchedulerSchema
>;
export type DeleteSpeedSchedulerData = DeleteIndexList;
export type MessengerReceiver = z.infer<typeof MessengerReceiverSchema>;
export type RecipientData = z.infer<typeof RecipientData>;
