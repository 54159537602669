import { DateInTable, TableBodyBasic } from '@autosquare/common';
import { DeviceListInProject } from '@customTypes/index';
import React from 'react';
import DeviceInfoCell from './DeviceInfoCell';

interface MemberDeviceRowProps {
  device: DeviceListInProject;
  idx: number;
  isActivate: boolean;
}

const MemberDeviceRow = ({ device, idx, isActivate }: MemberDeviceRowProps) => {
  return (
    <>
      <TableBodyBasic disabled={!isActivate}>{idx + 1}</TableBodyBasic>
      <DeviceInfoCell
        model={device.deviceModel}
        name={device.deviceName}
        isActivate={isActivate}
      />
      <DeviceInfoCell
        model={device.deviceOs}
        name={device.deviceType}
        isActivate={isActivate}
      />
      <TableBodyBasic>
        <DateInTable dateTime={device.registeredAt} disabled={!isActivate} />
      </TableBodyBasic>
      <TableBodyBasic disabled={!isActivate}>
        {device.enabled ? 'ON' : 'OFF'}
      </TableBodyBasic>
    </>
  );
};

export default MemberDeviceRow;
