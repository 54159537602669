import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useMobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';

import PointOrRectangleInput from '@components/shared/PointOrRectangleInput';

import {
  CreateTestStepData,
  CreateTestStepDataOptionsDto,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type ValueKeys =
  | keyof CreateTestStepData
  | `optionsDto.${keyof CreateTestStepDataOptionsDto}`;

type Props = {
  value?: ValueKeys;
};

const ScrollSwipe = ({ value = 'value' }: Props) => {
  const {
    startX,
    startY,
    endX,
    endY,
    onChangeStartX,
    onChangeStartY,
    onChangeEndX,
    onChangeEndY,
    imageWidth,
    imageHeight,
  } = useMobileTestStepContext();

  const {
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateTestStepData>();

  useEffect(() => {
    setValue(value, `${startX},${startY},${endX},${endY}`);

    return () => {
      setValue(value, '');
    };
  }, [startX, startY, endX, endY, setValue]);

  useEffect(() => {
    if (
      startX > imageWidth ||
      startY > imageHeight ||
      endX > imageWidth ||
      endY > imageHeight
    ) {
      setError(value, {
        type: 'max',
        message: `X는 0이상 ${imageWidth}이하, Y는 0이상 ${imageHeight} 이하로 작성해 주세요.`,
      });
    } else if (startX < 0 || startY < 0 || endX < 0 || endY < 0) {
      setError(value, {
        type: 'min',
        message: `X는 0이상 ${imageWidth}이하, Y는 0이상 ${imageHeight} 이하로 작성해 주세요.`,
      });
    } else if (!startX || !startY || !endX || !endY) {
      setError(value, {
        type: 'required',
        message: `X는 0이상 ${imageWidth}이하, Y는 0이상 ${imageHeight} 이하로 작성해 주세요.`,
      });
    } else {
      clearErrors(value);
    }
  }, [
    startX,
    startY,
    endX,
    endY,
    imageWidth,
    imageHeight,
    setError,
    clearErrors,
  ]);

  useEffect(() => {
    return () => {
      clearErrors(value);
    };
  }, []);

  const scrollSwipeProperties = [
    {
      labelTitle: 'startX',
      value: startX,
      onChange: onChangeStartX,
    },
    {
      labelTitle: 'startY',
      value: startY,
      onChange: onChangeStartY,
    },
    {
      labelTitle: 'endX',
      value: endX,
      onChange: onChangeEndX,
    },
    {
      labelTitle: 'endY',
      value: endY,
      onChange: onChangeEndY,
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PointOrRectangleInput
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        properties={scrollSwipeProperties}
        isHiddenInputArrow={true}
      />
      <ErrorMessage
        errors={errors}
        name={value}
        render={({ message }) => (
          <p className="error-message -mt-2">{message}</p>
        )}
      />
    </ErrorBoundaryWrapper>
  );
};

export default ScrollSwipe;
