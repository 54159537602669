import { RootState } from '@app/store';
import { TooltipTop } from '@autosquare/common';
import PointOrRectangleInput from '@components/shared/PointOrRectangleInput';
import { useMobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { ErrorMessage } from '@hookform/error-message';
import { getCroppedImage } from '@utils/imageUtils';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

const CompareImageCoordinate = () => {
  const {
    startX,
    startY,
    endX,
    endY,
    onChangeStartX,
    onChangeStartY,
    onChangeEndX,
    onChangeEndY,
    imageWidth,
    imageHeight,
    isComparingRectHidden,
    setIsComparingRectHidden,
  } = useMobileTestStepContext();

  const {
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateTestStepData>();

  const screenshot = useSelector(
    (state: RootState) => state.mobileScreenshotSource.screenshotSource,
  );

  useEffect(() => {
    setValue('optionsDto.conditionValue', undefined);
    return () => {
      setValue('optionsDto.conditionValue', undefined);
      setValue('optionsDto.conditionOptions', undefined);
      setIsComparingRectHidden(true);
    };
  }, [setValue]);

  useEffect(() => {
    setValue(
      'optionsDto.conditionValue',
      getCroppedImage(
        startX,
        startY,
        Math.abs(endX - startX),
        Math.abs(endY - startY),
        screenshot,
      ).split(',')[1],
    );
    setValue(
      'optionsDto.conditionOptions.cropCoordinate',
      `${startX},${startY},${endX},${endY}`,
    );
  }, [startX, startY, endX, endY, setValue]);

  useEffect(() => {
    if (
      startX > imageWidth ||
      startY > imageHeight ||
      endX > imageWidth ||
      endY > imageHeight
    ) {
      setError('optionsDto.conditionOptions.cropCoordinate', {
        type: 'max',
        message: `X는 0이상 ${imageWidth}이하, Y는 0이상 ${imageHeight} 이하로 작성해 주세요.`,
      });
    } else if (startX < 0 || startY < 0 || endX < 0 || endY < 0) {
      setError('optionsDto.conditionOptions.cropCoordinate', {
        type: 'min',
        message: `X는 0이상 ${imageWidth}이하, Y는 0이상 ${imageHeight} 이하로 작성해 주세요.`,
      });
    } else if (!startX || !startY || !endX || !endY) {
      setError('optionsDto.conditionOptions.cropCoordinate', {
        type: 'required',
        message: `X는 0이상 ${imageWidth}이하, Y는 0이상 ${imageHeight} 이하로 작성해 주세요.`,
      });
    } else {
      clearErrors('optionsDto.conditionOptions.cropCoordinate');
    }
  }, [
    startX,
    startY,
    endX,
    endY,
    imageWidth,
    imageHeight,
    setError,
    clearErrors,
  ]);

  useEffect(() => {
    return () => {
      clearErrors('optionsDto.conditionOptions.cropCoordinate');
    };
  }, []);

  const rectProperties = [
    {
      labelTitle: 'startX',
      maxSize: imageWidth,
      value: startX,
      onChange: onChangeStartX,
    },
    {
      labelTitle: 'startY',
      maxSize: imageHeight,
      value: startY,
      onChange: onChangeStartY,
    },
    {
      labelTitle: 'endX',
      maxSize: imageWidth,
      value: endX,
      onChange: onChangeEndX,
    },
    {
      labelTitle: 'endY',
      maxSize: imageHeight,
      value: endY,
      onChange: onChangeEndY,
    },
  ];

  return (
    <>
      <PointOrRectangleInput
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        properties={rectProperties}
        isHiddenInputArrow={true}
      />
      <ErrorMessage
        errors={errors}
        name={'optionsDto.conditionOptions.cropCoordinate'}
        render={({ message }) => (
          <p className="error-message -mt-2">{message}</p>
        )}
      />
      <div className="flex items-center gap-1">
        <p className="text-sm font-medium leading-tight text-gray-900">
          Compare Image Box
        </p>
        <div className="flex items-center gap-2.5">
          <div className="group relative">
            <QuestionMarkCircleIcon className="size-5 cursor-help text-red-500" />
            <TooltipTop
              positionClass={'invisible group-hover:visible -left-36 bottom-6'}
            >
              스크린샷 화면에서 Compare Image Box를 숨길 수 있습니다.
            </TooltipTop>
          </div>
          <button
            type="button"
            onClick={() => setIsComparingRectHidden(!isComparingRectHidden)}
          >
            {isComparingRectHidden ? (
              <EyeIcon className="size-5" />
            ) : (
              <EyeSlashIcon className="size-5" />
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default CompareImageCoordinate;
