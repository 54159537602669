import { onChangeWebExecutionRepeatData } from '@store/ide/browser/selectedWebExecutionDataSlice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

type Props = {
  repeat: string;
  numberRepeat: number;
  onChangeRepeat: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isSelectedBrowser: boolean;
};

const Repeat = ({
  repeat,
  numberRepeat,
  onChangeRepeat,
  isSelectedBrowser,
}: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onChangeWebExecutionRepeatData({ repeat: numberRepeat }));
  }, [numberRepeat]);

  const errorMessage =
    !numberRepeat || numberRepeat < 3
      ? 'Repeat 값을 최소 3부터 입력해 주세요.'
      : numberRepeat > 50 && 'Repeat 값은 최대 50까지 입력 가능합니다.';

  return (
    <section className="text-base font-normal text-gray-900">
      <h3 className="font-semibold leading-7">Repeat</h3>
      <p className="text-sm font-medium">
        속도 측정을 몇 번 반복할지 횟수를 입력해 주세요. (처음과 마지막 속도
        측정을 제외한 나머지 값들의 평균이 결과로 저장 됩니다.)
      </p>
      <input
        type="text"
        className="input-base hide-input-number-arrow mt-3"
        value={repeat}
        onChange={onChangeRepeat}
        inputMode="numeric"
      />
      {isSelectedBrowser && (
        <p className="standard-error-message">{errorMessage}</p>
      )}
    </section>
  );
};

export default Repeat;
