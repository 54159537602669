import React, { useState } from 'react';
import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';
import { CommonDialog } from '@components/Dialog';
import {
  AutoRegisterType,
  CreateAdminProjectData,
  UpdateAdminProjectDetailData,
} from '@customTypes/dashboard/admin/type';
import { ErrorMessage } from '@hookform/error-message';
import {
  autoRegisterPlatformList,
  AutoRegisterPlatformServername,
  confluenceInput,
  findAutoRegisterPlatformName,
  jiraInput,
} from '@utils/static/autoRegister/autoRegisterTypeList';
import { Controller, useFormContext } from 'react-hook-form';
import AutoRegisterInputList from './AutoRegisterModal/AutoRegisterInputList';
import { dynamicDefaultInputList } from '@utils/static/autoRegister/autoRegisterInputList';

type Props = {
  isAddClicked: boolean;
  setIsAddClicked: React.Dispatch<React.SetStateAction<boolean>>;
  autoRegisterDataList: AutoRegisterType[];
  setAutoRegisterDataList: React.Dispatch<
    React.SetStateAction<AutoRegisterType[]>
  >;
};

const AutoRegisterModal = ({
  isAddClicked,
  setIsAddClicked,
  autoRegisterDataList,
  setAutoRegisterDataList,
}: Props) => {
  const {
    control,
    watch,
    trigger,
    setValue,
    resetField,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateAdminProjectData | UpdateAdminProjectDetailData>();
  const [isSavedButtonClicked, setIsSavedButtonClicked] = useState(false);

  const platform = watch('platform');
  const filteredDataList = autoRegisterPlatformList.filter(
    (item) =>
      !autoRegisterDataList.some((data) => data.platform === item.serverName),
  );

  const { autoRegisterInputList } = dynamicDefaultInputList(platform);

  const validateHandling = (result: boolean) => {
    if (result) {
      const data = {
        ...watch('platformInput'),
        activate: true,
        platform: platform,
      };
      setAutoRegisterDataList((prev) => [...prev, data]);
      setValue('platform', platform);
      setIsAddClicked(false);
      setIsSavedButtonClicked(false);
    }
  };

  const saveButtonHandler = async () => {
    setIsSavedButtonClicked(true);
    if (!platform) {
      const result = await trigger('platform', { shouldFocus: true });
      validateHandling(result);
      return;
    }
    if (platform === AutoRegisterPlatformServername.Jira) {
      const result = await trigger(jiraInput, { shouldFocus: true });
      validateHandling(result);
      return;
    }
    if (platform === AutoRegisterPlatformServername.Confluence) {
      const result = await trigger(confluenceInput, {
        shouldFocus: true,
      });
      validateHandling(result);
    }
  };

  const cancelButtonHandler = () => {
    setIsAddClicked(false);
    setValue('platform', undefined);
  };

  return (
    <ErrorBoundaryWrapper>
      <CommonDialog
        isOpen={isAddClicked}
        setIsOpen={setIsAddClicked}
        iconType={'add'}
        title={'Add Platform'}
        subTitle={''}
      >
        <div className="mt-5 space-y-4">
          <div>
            <Controller
              control={control}
              name="platform"
              rules={{ required: '필수 선택 항목입니다' }}
              render={({ field }) => (
                <ListBoxHeadlessInController
                  value={field.value}
                  onChange={(value) => {
                    resetField('platformInput.requestUrl');
                    resetField('platformInput.email');
                    resetField('platformInput.apiToken');
                    field.onChange(value);
                    if (errors.platform) {
                      clearErrors('platform');
                      setIsSavedButtonClicked(false);
                    }
                  }}
                  lists={filteredDataList}
                  valueToSave={'serverName'}
                  valueToShow={'name'}
                  buttonValue={findAutoRegisterPlatformName(field.value)}
                  placeholder="Select a Platform"
                />
              )}
            />
            <ErrorMessage
              name="platform"
              errors={errors}
              render={({ message }) => (
                <p className="error-message mt-2">{message}</p>
              )}
            />
          </div>
          <div className="flex flex-col space-y-4">
            <AutoRegisterInputList
              autoRegisterInputList={autoRegisterInputList}
              isSavedButtonClicked={isSavedButtonClicked}
            />
          </div>
          <CommonDialog.DialogButtonPanels>
            <CommonDialog.DialogButton
              variant="primary"
              type="button"
              onClick={saveButtonHandler}
            >
              Save
            </CommonDialog.DialogButton>
            <CommonDialog.DialogButton
              variant="secondary"
              type="button"
              onClick={cancelButtonHandler}
            >
              Cancel
            </CommonDialog.DialogButton>
          </CommonDialog.DialogButtonPanels>
        </div>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default AutoRegisterModal;
