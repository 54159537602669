import { ErrorBoundaryWrapper } from '@autosquare/common';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import TestResultSaveOptionsCheckbox from '@components/Scheduler/TestResultSaveOptionsCheckbox';
import { CreateSpeedSchedulerData } from '@customTypes/ide/speedScheduler/speedScheduler';

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const CreateSpeedSchedulerTestResultSaveOptions = () => {
  const { register, setValue } = useFormContext<CreateSpeedSchedulerData>();

  useEffect(() => {
    setValue('saveVideo', true);
    setValue('saveLog', true);
    return () => {
      setValue('saveVideo', undefined);
      setValue('saveLog', undefined);
    };
  }, [setValue]);

  const checkboxList = [
    { title: 'Video', register: register('saveVideo') },
    { title: 'Log File', register: register('saveLog') },
  ];

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer
        title="Test Result Save Options"
        isTitleCenter={true}
      >
        <div className="grid grid-cols-2">
          {checkboxList.map((checkbox) => (
            <TestResultSaveOptionsCheckbox
              key={checkbox.title}
              title={checkbox.title}
              register={checkbox.register}
            />
          ))}
        </div>
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default CreateSpeedSchedulerTestResultSaveOptions;
