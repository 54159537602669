import {
  ErrorMessage,
  ScenarioListDetailData,
  TableNoList,
  TableSkeleton,
} from '@autosquare/common';
import { UseQueryResult } from '@tanstack/react-query';
import React from 'react';
type Props = {
  query: UseQueryResult<ScenarioListDetailData[], Error>;
  colSpan: number;
};
const RenderLoadingOrError = ({ query, colSpan }: Props) => {
  return (
    <>
      {query.isLoading || query.isFetching ? (
        <TableNoList colSpan={colSpan}>
          <TableSkeleton />
        </TableNoList>
      ) : (
        query.isError && (
          <TableNoList colSpan={colSpan}>
            <ErrorMessage>{query.error.message}</ErrorMessage>
          </TableNoList>
        )
      )}
    </>
  );
};

export default RenderLoadingOrError;
