import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  UpdateWebTestStepData,
  UpdateWebTestStepDataKeys,
} from '@customTypes/testStep/type';

import { ErrorMessage } from '@hookform/error-message';
import { preventEnterHandler } from '@utils/helper/preventEnterHandler';
import clsx from 'clsx';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  term: string;
  name: UpdateWebTestStepDataKeys;
  isSavedSecretValue?: boolean;
  message?: string;
  secretOption?: boolean;
}

const WebTestStepTextareaByReactHookForm = ({
  term,
  name,
  isSavedSecretValue,
  message = 'Value 값을 입력해 주세요.',
  secretOption = false,
}: Props) => {
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext<UpdateWebTestStepData>();

  const isSecret = watch('optionsDto.secret') === 'true';

  const { ref, ...rest } = register(name, {
    required: {
      value: !(isSecret && isSavedSecretValue),
      message: message,
    },
  });

  const placeholder =
    isSecret && isSavedSecretValue
      ? '암호화된 값은 표시되지 않습니다.'
      : secretOption
        ? '텍스트를 입력해 주세요.'
        : undefined;

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (textareaRef.current) {
      setHeight(textareaRef.current.scrollHeight);
    }
  }, [name]);

  useImperativeHandle(ref, () => textareaRef.current);

  return (
    <ErrorBoundaryWrapper>
      <div
        className={`items-center border-b border-gray-200 sm:flex sm:px-6 sm:py-4`}
      >
        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
          {term}
        </dt>
        <dd className="mt-1 w-full text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
          <div className="w-full">
            <label htmlFor={name} className="sr-only">
              {term}
            </label>
            <textarea
              name={name}
              id={name}
              style={{ height }}
              className={clsx(
                'block w-full overflow-y-auto rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 scrollbar-thin placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
                isSecret && 'password-input',
              )}
              ref={textareaRef}
              placeholder={placeholder}
              onKeyDown={(e) => preventEnterHandler(e, isSecret)}
              {...rest}
            />
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <p className="standard-error-message">{message}</p>
              )}
            />
          </div>
        </dd>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default WebTestStepTextareaByReactHookForm;
