import React from 'react';
import EditMessengerRequestUrl from './EditMessengerFields/EditMessengerRequestUrl';
import EditMessengerOfficeToken from './EditMessengerFields/EditMessengerOfficeToken';
import EditMessengerRecipientList from './EditMessengerFields/EditMessengerRecipientList';
import { ProjectListDetail } from '@customTypes/dashboard/project/type';

type Props = {
  adminProjectData: ProjectListDetail;
  changePlatformCount: number;
};

const EditMessenger01 = ({ adminProjectData, changePlatformCount }: Props) => {
  return (
    <>
      <EditMessengerRequestUrl />
      <EditMessengerOfficeToken />
      <EditMessengerRecipientList
        adminProjectData={adminProjectData}
        changePlatformCount={changePlatformCount}
      />
    </>
  );
};

export default EditMessenger01;
