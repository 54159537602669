import React from 'react';

import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import LoopCondition from './EditLoop/LoopCondition';
import LoopAction from './EditLoop/LoopAction';
import TestStepEditMaskingOptions from '@components/MobileTestCaseDetail/shared/TestStepEditMaskingOptions';
import { ErrorBoundaryWrapper } from '@autosquare/common';

interface Props {
  mobileTestStep: MobileTestStepData;
}

const EditLoop = ({ mobileTestStep }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <LoopCondition />
      <LoopAction mobileTestStep={mobileTestStep} />
      <TestStepEditMaskingOptions />
    </ErrorBoundaryWrapper>
  );
};

export default EditLoop;
