import { useMutation } from '@tanstack/react-query';

import { axiosLocalInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';

import {
  UseExecuteWebTestMutation,
  UseExecuteWebTestScenarioMutation,
  WebSpeedTestCaseExecutionData,
  WebSpeedTestExecutionType,
  WebSpeedTestScenarioExecutionData,
  WebTestCaseExecutionData,
  WebTestScenarioExecutionData,
} from '@customTypes/ide/browser/browserExecution';
import { remoteConfig } from '@utils/static/remoteConfig';

// POST
// UI Test
const executeWebTestCase = async (
  cookie: string,
  data: WebTestCaseExecutionData,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    IDEAPI.RUN_WEB_TEST_CASE,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useExecuteWebTestCaseMutation = ({
  cookie,
  data,
}: UseExecuteWebTestMutation) => {
  return useMutation<unknown, Error>({
    mutationFn: () => executeWebTestCase(cookie, data),
  });
};

const executeWebTestScenario = async (
  cookie: string,
  data: WebTestScenarioExecutionData,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post(
    IDEAPI.RUN_WEB_TEST_SCENARIO,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useExecuteWebTestScenarioMutation = ({
  cookie,
  data,
}: UseExecuteWebTestScenarioMutation) => {
  return useMutation<unknown, Error>({
    mutationFn: () => executeWebTestScenario(cookie, data),
  });
};

// Web Speed Test
const executeWebSpeedTestCase = async (
  data: WebSpeedTestCaseExecutionData,
  cookie: string,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    `${IDEAPI.EXECUTE_WEB_SPEED_TEST}${WebSpeedTestExecutionType.SpeedCase}`,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useExecuteWebSpeedTestCaseMutation = (cookie: string) => {
  return useMutation<unknown, Error, WebSpeedTestCaseExecutionData>({
    mutationFn: (data) => executeWebSpeedTestCase(data, cookie),
  });
};

const executeWebSpeedTestScenario = async (
  cookie: string,
  data: WebSpeedTestScenarioExecutionData,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    `${IDEAPI.EXECUTE_WEB_SPEED_TEST}${WebSpeedTestExecutionType.SpeedScenario}`,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useExecuteWebSpeedTestScenarioMutation = (cookie: string) => {
  return useMutation<unknown, Error, WebSpeedTestScenarioExecutionData>({
    mutationFn: (data) => executeWebSpeedTestScenario(cookie, data),
  });
};

//원격 웹 테스트 실행
//https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Execute/runTest
const executeRemoteWebSpeedTestCase = async (
  data: WebSpeedTestCaseExecutionData,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    `${IDEAPI.REMOTE}/connect/execute/web/run?type=${WebSpeedTestExecutionType.SpeedCase}`,
    data,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteExecuteWebSpeedTestCaseMutation = () => {
  return useMutation<unknown, Error, WebSpeedTestCaseExecutionData>({
    mutationFn: (data) => executeRemoteWebSpeedTestCase(data),
  });
};

const executeRemoteWebSpeedTestScenario = async (
  data: WebSpeedTestScenarioExecutionData,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    `${IDEAPI.REMOTE}/connect/execute/web/run?type=${WebSpeedTestExecutionType.SpeedScenario}`,
    data,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteExecuteWebSpeedTestScenarioMutation = () => {
  return useMutation<unknown, Error, WebSpeedTestScenarioExecutionData>({
    mutationFn: (data) => executeRemoteWebSpeedTestScenario(data),
  });
};

// Stop Execution
const stopWebTestExecution = async (cookie: string): Promise<unknown> => {
  const response = await axiosLocalInstance.post(
    IDEAPI.STOP_WEB_RUNNING_TEST,
    undefined,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useStopWebTestExecutionMutation = (cookie: string) => {
  return useMutation<unknown, Error>({
    mutationFn: () => stopWebTestExecution(cookie),
  });
};

//원격 웹 테스트 중단
//https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Execute/stopWebTest
const stopRemoteWebTestExecution = async (): Promise<unknown> => {
  const response = await axiosLocalInstance.post(
    `${IDEAPI.REMOTE}/connect/execute/web/stop`,
    undefined,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteStopWebTestExecutionMutation = () =>
  useMutation<unknown, Error>({
    mutationFn: () => stopRemoteWebTestExecution(),
  });
