import { SpeedMobileCaseExecution } from '@customTypes/speedTestExecution/speedTestExecution';
import { onOnlyNumberChangeHandler } from '@utils/static/mobileTestStep/onOnlyNumberChangeHandler';
import React from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  isSelectedDevices: boolean;
};

const RepeatInfo = ({ isSelectedDevices }: Props) => {
  const { register, setValue, watch } =
    useFormContext<SpeedMobileCaseExecution>();
  const repeatCount = Number(watch('repeatCount'));
  const errorMessage =
    repeatCount === 0 || repeatCount < 3
      ? 'Repeat 값을 최소 3부터 입력해 주세요.'
      : repeatCount > 50
        ? 'Repeat 값은 최대 50까지 입력 가능합니다.'
        : null;
  return (
    <div className="flex flex-col gap-y-2">
      <label htmlFor="device-repeat" className="font-semibold text-gray-900">
        Repeat
      </label>
      <p className="text-sm text-gray-900">
        속도 측정을 몇 번 반복할지 횟수를 입력해 주세요. &#40;처음과 마지막 속도
        측정을 제외한 나머지 값들의 평균이 결과로 저장 됩니다.&#41;
      </p>
      <input
        type="text"
        id="device-repeat"
        className="input-base w-full"
        defaultValue={12}
        {...register('repeatCount', {
          onChange: (e) =>
            onOnlyNumberChangeHandler(e, 'repeatCount', setValue),
        })}
      />
      {isSelectedDevices && (
        <div className="standard-error-message">{errorMessage}</div>
      )}
    </div>
  );
};

export default RepeatInfo;
