import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import React from 'react';

import { Tab, TabGroup, TabList } from '@headlessui/react';

import MobileResultsDetail from './MediaAndLogHandler/MobileResultsDetail';
import WebResultDetail from './MediaAndLogHandler/WebResultDetail';
import clsx from 'clsx';

type Props = {
  imagePaths: string[];
  videoPath: string;
  logPath: string;
};

const MediaAndLogHandler = ({ videoPath, logPath, imagePaths }: Props) => {
  const tabs = isWordInLocationPathname('mobile')
    ? [
        { name: 'Video', isHidden: videoPath === null },
        { name: 'Screenshot', isHidden: imagePaths[0] === null },
        { name: 'Log File', isHidden: logPath === null },
      ]
    : [{ name: 'Screenshot', isHidden: imagePaths[0] === null }];

  const isAllHidden = isWordInLocationPathname('mobile')
    ? videoPath === null && imagePaths[0] === null && logPath === null
    : imagePaths[0] === null;

  return (
    <div className={clsx('px-4', isAllHidden && 'hidden')}>
      <TabGroup>
        <TabList className="-mb-px flex space-x-8 border-b border-gray-200">
          {tabs.map((tab) => (
            <Tab
              key={tab.name}
              className={clsx(
                'whitespace-nowrap border-b-2 border-transparent px-1 py-5 text-sm font-medium text-gray-500 data-[hover]:border-gray-300 data-[selected]:border-indigo-500 data-[hover]:text-gray-700 data-[selected]:text-indigo-600',
                tab.isHidden && 'hidden',
              )}
              disabled={tab.isHidden}
            >
              {tab.name}
            </Tab>
          ))}
        </TabList>
        {isWordInLocationPathname('mobile') ? (
          <MobileResultsDetail
            imagePaths={imagePaths}
            videoPath={videoPath}
            logPath={logPath}
          />
        ) : (
          <WebResultDetail imagePaths={imagePaths} />
        )}
      </TabGroup>
    </div>
  );
};

export default MediaAndLogHandler;
