import React from 'react';
import {
  DefaultSkeleton,
  ErrorBoundaryWrapper,
  ErrorMessage,
  Pagination,
  PagingHeadingsInAutosquare,
  SpeedTestResultTableSkeleton,
} from '@autosquare/common';
import { useSearchIdeWebSpeedTestResultListQuery } from '@lib/api/ide/speedWeb/webSpeedTestResult';
import { useLocation, useSearchParams } from 'react-router-dom';
import WebSpeedTestResultList from '@components/WebSpeedTestResultList/WebSpeedTestResultList';
import SearchForm from '@components/SearchForm/SearchForm';
import NoSpeedTestResultMessage from '@components/Search/NoSpeedTestResultMessage';
import getFirstGenerationDateToDateType from '@utils/static/getFirstGenerationDateToDateType';

const IdeWebSpeedTestResult = () => {
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));
  const size = Number(searchParams.get('size'));

  const searchIdeWebSpeedTestResultListQuery =
    useSearchIdeWebSpeedTestResultListQuery();
  const searchWebSpeedTestResultList =
    searchIdeWebSpeedTestResultListQuery.data;

  const initialCreatedAt = searchWebSpeedTestResultList?.initialCreatedAt;
  const formattedCreatedAt = getFirstGenerationDateToDateType(initialCreatedAt);

  const currentUrl = pathname + search;

  const breadcrumbs = [
    {
      title: 'Speed Test',
      href: `${pathname.split('/').slice(0, -2).join('/')}/overview`,
    },
    { title: 'Web', href: currentUrl },
    { title: 'Test Result', href: currentUrl },
    { title: 'List', href: currentUrl },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Result List'}
      />
      <SearchForm
        calendarSubTitle="Date"
        isFunctionalTest={false}
        initialCreatedAt={formattedCreatedAt}
      >
        <SearchForm.ResultTestType isFunctionalTest={false} />
      </SearchForm>
      {searchIdeWebSpeedTestResultListQuery.isLoading ? (
        <SpeedTestResultTableSkeleton />
      ) : searchIdeWebSpeedTestResultListQuery.isError ? (
        <ErrorMessage>
          {searchIdeWebSpeedTestResultListQuery.error.message}
        </ErrorMessage>
      ) : searchWebSpeedTestResultList?.totalCount === 0 ? (
        <NoSpeedTestResultMessage />
      ) : (
        <WebSpeedTestResultList
          webSpeedTestResultList={searchWebSpeedTestResultList}
        />
      )}
      {searchIdeWebSpeedTestResultListQuery.isLoading ? (
        <DefaultSkeleton number={1} height={'h-4'} outMargin={'my-4'} />
      ) : (
        searchWebSpeedTestResultList?.totalCount > 5 && (
          <Pagination
            page={page}
            total={searchWebSpeedTestResultList?.totalCount}
            size={size}
            isDashboard={false}
          />
        )
      )}
    </ErrorBoundaryWrapper>
  );
};

export default IdeWebSpeedTestResult;
