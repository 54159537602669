import { ErrorBoundaryWrapper } from '@autosquare/common';
import { ClickId } from '@utils/static/clickOptions';
import clsx from 'clsx';

import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

type Props = {
  id: ClickId;
  name: string;
  defaultChecked: boolean;
  labelTitle: string;
  register: UseFormRegisterReturn;
  onClick?: () => void;
  disabled?: boolean;
};

const CheckboxHookForm = ({
  id,
  name,
  defaultChecked,
  labelTitle,
  register,
  onClick,
  disabled = false,
}: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div key={id} className="flex items-center">
        <input
          id={id}
          name={name}
          type="radio"
          defaultChecked={defaultChecked}
          value={id}
          onClick={onClick}
          className="input-radio-congress-blue disalbed:opacity-70 disabled:cursor-default"
          disabled={disabled}
          {...register}
        />
        <label
          htmlFor={id}
          className={clsx(
            'ml-3 block text-sm font-medium leading-6 text-gray-900',
            disabled ? 'cursor-default' : 'cursor-pointer',
          )}
        >
          {labelTitle}
        </label>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default CheckboxHookForm;
