export const mobileDeviceKeys = {
  all: ['mobileDevice'] as const,
  lists: () => [...mobileDeviceKeys.all, 'list'] as const,
  list: (projectIdx: string) =>
    [...mobileDeviceKeys.lists(), projectIdx] as const,
  screenshot: () => [...mobileDeviceKeys.all, 'screenshot'] as const,
  source: () => [...mobileDeviceKeys.all, 'source'] as const,
  appList: () => [...mobileDeviceKeys.all, 'appList'] as const,
  smsList: () => [...mobileDeviceKeys.all, 'smsList'] as const,
  wdaStatus: () => [...mobileDeviceKeys.all, 'wdaStatus'] as const,
};
