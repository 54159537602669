import React, { Fragment } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { RecipientData } from '@customTypes/ide/speedScheduler/speedScheduler';
import { ErrorBoundaryWrapper } from '@autosquare/common';

interface Props {
  state: RecipientData;
  setState: React.Dispatch<React.SetStateAction<RecipientData>>;
  list: RecipientData[];
  disabled?: boolean;
}

export const MessengerAlarmHeadlessListbox = ({
  state,
  setState,
  list,
  disabled = false,
}: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <Listbox value={state} onChange={setState}>
        {({ open }) => (
          <div className="relative">
            <ListboxButton
              className={clsx(
                'relative w-full cursor-default rounded-md py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6',
                disabled ? 'bg-gray-100' : 'bg-white',
              )}
              disabled={disabled}
            >
              <span className="block truncate text-gray-500">
                Select a recipient from the list
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="size-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm">
                {list?.length === 0 ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    Not Founded
                  </div>
                ) : (
                  list?.map((value, index) => (
                    <ListboxOption
                      key={index}
                      className={clsx(
                        'group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white',
                      )}
                      value={value}
                    >
                      <div className="flex">
                        <span className={`truncate`}>{value.recipientId}</span>
                        <span
                          className={clsx(
                            'ml-2 truncate text-gray-500 group-hover:text-indigo-200 group-data-[focus]:text-indigo-200',
                          )}
                        >
                          {value.recipientInfo}
                        </span>
                      </div>
                    </ListboxOption>
                  ))
                )}
              </ListboxOptions>
            </Transition>
          </div>
        )}
      </Listbox>
    </ErrorBoundaryWrapper>
  );
};
