import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, ErrorBoundaryWrapper } from '@autosquare/common';
import {
  useSignUpMutation,
  useUserInfoByDecodeKeyQuery,
} from '@lib/api/dashboard/user/users';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { SignupRequest, SignupRequestSchema } from '@customTypes/index';
import { zodResolver } from '@hookform/resolvers/zod';
import EmailFields from './SignUpFormFields.tsx/EmailFields';
import NameFields from './SignUpFormFields.tsx/NameFields';
import PasswordFields from './SignUpFormFields.tsx/PasswordFields';
import ProfileFields from './SignUpFormFields.tsx/ProfileFields';
import { CommonDialog, DialogButtonPanels } from '@components/Dialog';

const SignUpForm = () => {
  const [searchParams] = useSearchParams();
  const decodeKey = searchParams.get('key');

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  // decodeKey 값으로 이메일 주소 확인
  const { data: decodeKeyData } = useUserInfoByDecodeKeyQuery(decodeKey);

  const methods = useForm<SignupRequest>({
    defaultValues: {
      key: decodeKey,
      userId: decodeKeyData.userId,
    },
    resolver: zodResolver(SignupRequestSchema),
  });

  const signUpMutation = useSignUpMutation();

  const onSubmit: SubmitHandler<SignupRequest> = (data) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { confirmPassword, ...serverData } = data;

    signUpMutation.mutate(serverData, {
      onError: () => {
        setIsErrorDialogOpen(true);
      },
    });
  };

  // watch로 필수 값 실시간 감시
  const { name, password, confirmPassword, jobTitle } = methods.watch();

  // 필수 필드가 모두 충족되는지 확인
  const isSubmitDisabled = !(
    name &&
    password &&
    confirmPassword &&
    jobTitle &&
    !methods.formState.errors.name &&
    !methods.formState.errors.password &&
    !methods.formState.errors.confirmPassword &&
    !methods.formState.errors.jobTitle
  );

  return (
    <ErrorBoundaryWrapper>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="space-y-3 sm:mx-auto sm:w-full sm:max-w-sm"
        >
          <EmailFields />
          <NameFields />
          <PasswordFields />
          <ProfileFields />
          <Button
            type="submit"
            variant="primary"
            className="w-full"
            disabled={signUpMutation.isLoading || isSubmitDisabled}
          >
            Sign up
          </Button>
          {signUpMutation.isError && (
            <p className="error-message">{signUpMutation.error.message}</p>
          )}
        </form>
      </FormProvider>
      {/* 회원가입 실패 Dialog */}
      <CommonDialog
        isOpen={isErrorDialogOpen}
        setIsOpen={setIsErrorDialogOpen}
        iconType="caution"
        title="Failure"
        subTitle={signUpMutation.error?.message}
      >
        <DialogButtonPanels>
          <Button
            type="button"
            variant="secondary"
            onClick={() => {
              setIsErrorDialogOpen(false);
            }}
          >
            Close
          </Button>
        </DialogButtonPanels>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default SignUpForm;
