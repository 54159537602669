import { ErrorBoundaryWrapper } from '@autosquare/common';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import { CreateSpeedSchedulerData } from '@customTypes/ide/speedScheduler/speedScheduler';
import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const CreateSpeedTestSchedulerTitle = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CreateSpeedSchedulerData>();

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer title="Title" isTitleCenter={true}>
        <input
          className="input-base"
          placeholder="Enter scheduler title"
          {...register('title')}
        />
        <ErrorMessage
          errors={errors}
          name="title"
          render={({ message }) => (
            <p className="standard-error-message">{message}</p>
          )}
        />
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default CreateSpeedTestSchedulerTitle;
