import { ErrorBoundaryWrapper } from '@autosquare/common';
import { CreateWebTestStepData } from '@customTypes/testStep/type';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const WebTestStepMaskingOptionsFullScreen = () => {
  const { setValue } = useFormContext<CreateWebTestStepData>();

  useEffect(() => {
    setValue('optionsDto.maskingOptions.valueList', undefined);
  }, []);

  return (
    <ErrorBoundaryWrapper>
      <div className="inline-flex h-9 w-full items-center justify-start rounded-md py-1.5 pl-3 pr-2">
        <div className="w-full text-center text-sm font-normal leading-normal text-gray-900">
          브라우저 화면 전체가 마스킹 처리 되어 저장됩니다.
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default WebTestStepMaskingOptionsFullScreen;
