import React from 'react';

import { ErrorBoundaryWrapper } from '@autosquare/common';

import AdminUserListPageHeading from './components/AdminUserListPageHeading';
import AdminUserListTable from './components/AdminUserListTable';

const AdminUsersList = () => {
  return (
    <ErrorBoundaryWrapper>
      <AdminUserListPageHeading />
      <AdminUserListTable />
    </ErrorBoundaryWrapper>
  );
};

export default AdminUsersList;
