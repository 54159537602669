import React, { useEffect, useState } from 'react';
import SelectReservationTime from './ReserveDeviceForm/SelectReservationTime';
import AvailableDevices from './ReserveDeviceForm/AvailableDevices';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button, ErrorBoundaryWrapper } from '@autosquare/common';
import { useSaveDeviceReservationMutation } from '@lib/api/ide/queryHooks';
import {
  ReservationDevice,
  ReservationDeviceForm,
  ReservationDeviceFormSchema,
} from '@customTypes/index';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useParentPath, useReservationTime } from '@hooks/index';
import ReserveDeviceDialog from './ReserveDeviceForm/ReserveDeviceDialog';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';

const ReserveDeviceForm = () => {
  const { projectIdx } = useParams();
  const navigate = useNavigate();
  const deviceListPath = useParentPath();

  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const { minStartTime } = useReservationTime();

  const methods = useForm<ReservationDeviceForm>({
    defaultValues: {
      projectIdx: Number(projectIdx),
      startTime: minStartTime.format('YYYY-MM-DD HH:mm'),
      deviceIdx: [],
    },
    resolver: zodResolver(ReservationDeviceFormSchema),
  });

  const deviceIdxList = methods.watch('deviceIdx');

  const saveDeviceReservationMutation = useSaveDeviceReservationMutation();

  const onSubmit: SubmitHandler<ReservationDeviceForm> = (data) => {
    const updatedData: ReservationDevice = {
      ...data,
      startDate: dayjs(data.startTime).format('YYYY-MM-DD'),
      endDate: dayjs(data.endTime).format('YYYY-MM-DD'),
      startTime: dayjs(data.startTime).format('HH:mm'),
      endTime: dayjs(data.endTime).format('HH:mm'),
    };

    saveDeviceReservationMutation.mutate(updatedData, {
      onSuccess: () => {
        setIsSuccessDialogOpen(true);
      },
      onError: () => {
        setIsErrorDialogOpen(true);
      },
    });
  };

  useEffect(() => {
    if (deviceIdxList.length > 0) {
      methods.clearErrors('deviceIdx');
    }
  }, [deviceIdxList, methods.clearErrors]);

  return (
    <ErrorBoundaryWrapper>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SelectReservationTime />
          <AvailableDevices />
          <div
            className={clsx(
              'mt-4 flex gap-2 border-t border-gray-200 pt-8',
              methods.formState.errors?.deviceIdx
                ? 'justify-between'
                : 'justify-end',
            )}
          >
            <ErrorMessage
              errors={methods.formState.errors}
              name="deviceIdx"
              render={({ message }) => (
                <>
                  <div />
                  <p className="error-message">{message}</p>
                </>
              )}
            />
            <div className="flex items-center gap-2">
              <Button
                type="button"
                variant="secondary"
                onClick={() => navigate(deviceListPath)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={saveDeviceReservationMutation.isLoading}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
      <ReserveDeviceDialog
        isSuccessDialogOpen={isSuccessDialogOpen}
        setIsSuccessDialogOpen={setIsSuccessDialogOpen}
        isErrorDialogOpen={isErrorDialogOpen}
        setIsErrorDialogOpen={setIsErrorDialogOpen}
        errorMessage={saveDeviceReservationMutation.error?.message}
      />
    </ErrorBoundaryWrapper>
  );
};

export default ReserveDeviceForm;
