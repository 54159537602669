import { useState } from 'react';

export const useDeleteDialog = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const openModal = () => setIsModalOpened(true);
  const closeModal = () => setIsModalOpened(false);

  return { isModalOpened, openModal, closeModal, setIsModalOpened };
};
