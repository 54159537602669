import React, { useEffect, useState } from 'react';

import { useDeviceListQuery } from '@lib/api/ide/mobile/mobileDevice';

import DeviceListBox from '@components/Scheduler/DeviceListBox';

import { DeviceInfo } from '@customTypes/ide/device/device';
import {
  CreateSchedulerData,
  SchedulerTypeServerName,
} from '@customTypes/ide/scheduler/scheduler';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import { Mode } from '@customTypes/ide/remote/type';
import { useRemoteDeviceListQuery } from '@lib/api/ide/remote/remote';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';
import { getRemoteState } from '@utils/static/getConfiguration';

const CreateSchedulerDevice = () => {
  const {
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateSchedulerData>();

  const testType = watch('type');

  const { configuration } = getRemoteState();
  const [deviceInfoDto, setDeviceInfoDto] = useState<DeviceInfo>(undefined);
  const deviceListQuery =
    configuration === Mode.Standard
      ? useDeviceListQuery({
          enabled: testType === SchedulerTypeServerName.Mobile,
        })
      : useRemoteDeviceListQuery({
          enabled: testType === SchedulerTypeServerName.Mobile,
        });

  const handleChange = (selectedDevice: DeviceInfo) => {
    setDeviceInfoDto(selectedDevice);
    setValue('deviceInfoDto', selectedDevice);
  };

  useEffect(() => {
    if (testType === SchedulerTypeServerName.Web) {
      setValue('deviceInfoDto', undefined);
      setDeviceInfoDto(undefined);
    }
  }, [testType]);

  useEffect(() => {
    if (deviceInfoDto) {
      clearErrors('deviceInfoDto');
    }
  }, [deviceInfoDto]);

  return (
    <ErrorBoundaryWrapper>
      {testType === SchedulerTypeServerName.Mobile && (
        <SchedulerGridContainer title={'Device'}>
          <div className="mt-2 flex flex-col items-start gap-x-4 sm:col-span-2 sm:mt-0">
            <DeviceListBox
              deviceInfoDto={deviceInfoDto}
              handleChange={handleChange}
              deviceListQuery={deviceListQuery}
            />
            <ErrorMessage
              name="deviceInfoDto"
              errors={errors}
              render={({ message }) => (
                <p className="standard-error-message">{message}</p>
              )}
            />
          </div>
        </SchedulerGridContainer>
      )}
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default CreateSchedulerDevice;
