import React, { createContext, useContext, useMemo, useState } from 'react';
import { ElectronFile } from '@autosquare/common';
import {
  ImportedFileData,
  ImportedFileCsvData,
  ImportCsvTestCaseRequest,
} from '@customTypes/index';

type StepNumber = 1 | 2 | 3;

interface TestCaseImportContextType {
  stepNumber: StepNumber;
  setStepNumber: (step: StepNumber) => void;
  fileData: ImportedFileData[] | ImportedFileCsvData[];
  setFileData: React.Dispatch<
    React.SetStateAction<ImportedFileData[] | ImportedFileCsvData[]>
  >;
  fileInfo: ElectronFile | undefined;
  setFileInfo: React.Dispatch<React.SetStateAction<ElectronFile | undefined>>;
  clearAll: () => void;
  isDatFile: boolean;
  isCsvFile: boolean;
  isDatData: (
    data: ImportedFileData[] | ImportedFileCsvData[],
  ) => data is ImportedFileData[];
  isCsvData: (
    data: ImportedFileData[] | ImportedFileCsvData[],
  ) => data is ImportedFileCsvData[];
  importFormCsvData: ImportCsvTestCaseRequest | undefined;
  setImportFormCsvData: React.Dispatch<
    React.SetStateAction<ImportCsvTestCaseRequest | undefined>
  >;
  isImportFormDataLoading: boolean;
  setIsImportFormDataLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const TestCaseImportContext = createContext<
  TestCaseImportContextType | undefined
>(undefined);

export const TestCaseImportProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [stepNumber, setStepNumber] = useState<StepNumber>(1);
  const [fileData, setFileData] = useState<
    ImportedFileData[] | ImportedFileCsvData[]
  >([]);
  const [fileInfo, setFileInfo] = useState<ElectronFile | undefined>(undefined);
  const [importFormCsvData, setImportFormCsvData] = useState<
    ImportCsvTestCaseRequest | undefined
  >(undefined);
  const [isImportFormDataLoading, setIsImportFormDataLoading] = useState(false);

  const clearAll = () => {
    setStepNumber(1);
    setFileData([]);
    setFileInfo(undefined);
  };

  const fileName = fileInfo?.name;
  const isDatFile: boolean = /\.dat$/.test(fileName);
  const isCsvFile: boolean = /\.csv$/.test(fileName);

  const isDatData = (
    data: ImportedFileData[] | ImportedFileCsvData[],
  ): data is ImportedFileData[] => {
    return data.length > 0 && 'testCaseData' in data[0];
  };

  const isCsvData = (
    data: ImportedFileData[] | ImportedFileCsvData[],
  ): data is ImportedFileCsvData[] => {
    return typeof data === 'object';
  };

  const value = useMemo(() => {
    return {
      stepNumber,
      setStepNumber,
      fileData,
      setFileData,
      fileInfo,
      setFileInfo,
      clearAll,
      isDatFile,
      isCsvFile,
      isDatData,
      isCsvData,
      importFormCsvData,
      setImportFormCsvData,
      isImportFormDataLoading,
      setIsImportFormDataLoading,
    };
  }, [
    stepNumber,
    fileData,
    fileInfo,
    isDatFile,
    isCsvFile,
    isDatData,
    isCsvData,
    importFormCsvData,
    isImportFormDataLoading,
  ]);

  return (
    <TestCaseImportContext.Provider value={value}>
      {children}
    </TestCaseImportContext.Provider>
  );
};

export const useTestCaseImport = () => {
  const context = useContext(TestCaseImportContext);
  if (context === undefined) {
    throw new Error(
      'useTestCaseImport must be used within a TestCaseImportProvider',
    );
  }
  return context;
};
