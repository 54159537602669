import {
  Breadcrumbs,
  ErrorBoundaryWrapper,
  PagingHeadingsNavigate,
} from '@autosquare/common';
import { useParentPath } from '@hooks/index';
import React from 'react';
import { useLocation } from 'react-router-dom';

const ReserveDeviceHeader = () => {
  const { pathname, search } = useLocation();

  const deviceListPath = useParentPath();

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'Devices', href: deviceListPath },
    { title: 'List', href: deviceListPath },
    { title: 'Reservation', href: pathname + search },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Reservation'}
      />
    </ErrorBoundaryWrapper>
  );
};

export default ReserveDeviceHeader;
