import {
  Button,
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';

import React, { useState } from 'react';
import { findMemberName, memberRole } from '@utils/static/memberRole';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  AddMemberData,
  AddMemberDataSchema,
  UserRole,
} from '@customTypes/dashboard/project/type';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from '@hookform/error-message';
import { useNavigate } from 'react-router-dom';
import { useAddProjectMemberMutation } from '@lib/api/dashboard/queryHooks';
import {
  CommonDialog,
  DialogButton,
  DialogButtonPanels,
} from '@components/Dialog';

type Props = {
  memberListPage: string;
};

const AddProjectMemberForm = ({ memberListPage }: Props) => {
  const navigate = useNavigate();

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AddMemberData>({
    defaultValues: {
      addUserRole: UserRole.Member,
    },
    resolver: zodResolver(AddMemberDataSchema),
  });

  const addMemberMutation = useAddProjectMemberMutation();

  const onSubmit: SubmitHandler<AddMemberData> = (data) => {
    addMemberMutation.mutate(data, {
      onError: () => {
        setIsErrorDialogOpen(true);
      },
    });
  };

  const memberInformationList = [
    {
      idx: 1,
      title: 'Role',
      description: (
        <Controller
          control={control}
          name="addUserRole"
          render={({ field }) => (
            <ListBoxHeadlessInController
              value={field.value}
              buttonValue={findMemberName(field.value)}
              onChange={field.onChange}
              lists={memberRole}
              valueToSave={'serverName'}
              valueToShow={'name'}
            />
          )}
        />
      ),
    },
    {
      idx: 2,
      title: 'Email Address',
      description: (
        <input
          className="input-base"
          placeholder="가입된 사용자만 프로젝트에 추가할 수 있습니다."
          type="email"
          {...register('addUserId')}
        />
      ),
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mt-8 flow-root"
        noValidate
      >
        <div className="space-y-12 sm:space-y-16">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Member Information
            </h2>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
              프로젝트 멤버에 대한 정보를 입력 해주세요.
            </p>
            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              {memberInformationList.map((memberInformation) => (
                <div
                  key={memberInformation.idx}
                  className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6"
                >
                  <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                    {memberInformation.title}
                  </label>
                  <div className="col-span-1 mt-2 sm:mt-0">
                    {memberInformation.description}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-between gap-x-6">
          <div />
          <ErrorMessage
            errors={errors}
            name="addUserId"
            render={({ message }) => <p className="error-message">{message}</p>}
          />
          <div className="flex items-center gap-3">
            <Button
              type="button"
              variant="secondary"
              onClick={() => navigate(memberListPage)}
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </div>
        </div>
      </form>
      {/* 멤버 추가 실패 Dialog */}
      <CommonDialog
        isOpen={isErrorDialogOpen}
        setIsOpen={() => setIsErrorDialogOpen(false)}
        iconType={'caution'}
        title={'Failure'}
        subTitle={addMemberMutation.error?.message}
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant={'secondary'}
            onClick={() => setIsErrorDialogOpen(false)}
          >
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default AddProjectMemberForm;
