import React from 'react';
import { Button, ErrorBoundaryWrapper, ErrorMessage } from '@autosquare/common';
import { Cog8ToothIcon } from '@heroicons/react/20/solid';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetTestCaseDataAutomation } from '@lib/api/ide/mobile/mobileTestAutomation';
import clsx from 'clsx';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { useGetSpeedTestCaseDataAutomation } from '@lib/api/ide/speedMobile/mobileTestAutomation';

const TestDataAutomationButton = () => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');
  const navigate = useNavigate();

  const uiLocation = isWordInLocationPathname('ui-test');
  const useGetTestCaseDataAutomationQuery = uiLocation
    ? useGetTestCaseDataAutomation()
    : useGetSpeedTestCaseDataAutomation();

  const testAutomationData = useGetTestCaseDataAutomationQuery.data;
  const isTestAutomationData = testAutomationData !== null;

  const detailDescription = [
    { title: 'Title', value: testAutomationData?.title },
    { title: 'Description', value: testAutomationData?.description },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div
        className={clsx(
          'flex items-center justify-start',
          isTestAutomationData && 'border-b border-solid border-gray-100 pb-6',
        )}
      >
        <h2 className="text-base font-semibold leading-normal text-gray-900">
          Automation
        </h2>
        <Button
          variant={'imageOriginal'}
          onClick={() => navigate(`automation?idx=${idx}`)}
        >
          <Cog8ToothIcon className="size-5" />
        </Button>
      </div>
      {useGetTestCaseDataAutomationQuery.isLoading ||
      useGetTestCaseDataAutomationQuery.isFetching ? (
        <div className="grid grid-cols-2 py-2.5 text-sm leading-normal">
          {Array.from({ length: 2 }).map((_, i) => (
            <div key={i} className="flex flex-col gap-y-2">
              <div className="h-4 w-20 animate-pulse rounded-md bg-gray-100"></div>
              <div className="h-6 w-1/2 animate-pulse rounded-md bg-gray-100"></div>
            </div>
          ))}
        </div>
      ) : useGetTestCaseDataAutomationQuery.isError ? (
        <ErrorMessage>
          {useGetTestCaseDataAutomationQuery.error.message}
        </ErrorMessage>
      ) : (
        isTestAutomationData && (
          <div className="grid grid-cols-2 py-2.5 text-sm leading-normal">
            {detailDescription.map((item) => (
              <div key={item.title} className="flex flex-col gap-y-2">
                <p className="font-medium text-gray-900">{item.title}</p>
                <p className="font-normal text-gray-700">{item.value}</p>
              </div>
            ))}
          </div>
        )
      )}
    </ErrorBoundaryWrapper>
  );
};

export default TestDataAutomationButton;
