import {
  Breadcrumbs,
  ErrorBoundaryWrapper,
  PageHeadingButtons,
  PagingHeadingsNavigate,
} from '@autosquare/common';
import { useParentPath } from '@hooks/index';
import React from 'react';
import { useLocation } from 'react-router-dom';

const CreateMobileTestCasePagingHeading = () => {
  const { pathname } = useLocation();

  const projectPathName = useParentPath(3);
  const overviewPathName = `${projectPathName}/overview`;
  const testCasePathName = useParentPath();

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'UI Test', href: overviewPathName },
    { title: 'Mobile', href: testCasePathName },
    { title: 'Test Case', href: testCasePathName },
    { title: 'Create', href: pathname },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    { title: 'Save', variant: 'primary', type: 'submit' },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Case Create'}
        pageHeadingButtons={pageHeadingButtons}
      />
    </ErrorBoundaryWrapper>
  );
};

export default CreateMobileTestCasePagingHeading;
