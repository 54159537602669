import {
  Checkbox,
  Detail,
  ErrorMessage,
  Execution,
  TableBodyBasic,
  TableHeader,
  TableNoList,
  TableSkeleton,
  TableWrapper,
  Time,
  UseCheckboxAttribute,
} from '@autosquare/common';
import NoListResultMessage from '@components/Search/NoListResultMessage';
import { TestCaseDetailData } from '@customTypes/testCase/type';
import { UseQueryResult } from '@tanstack/react-query';
import { findPlatformTypeNameFromServerName } from '@utils/static/platformTypeList';
import { timePattern } from '@utils/static/timePattern';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Props extends UseCheckboxAttribute {
  filteredMobileTestCaseListQuery: UseQueryResult<TestCaseDetailData[], Error>;
  setTestCaseIdx: React.Dispatch<React.SetStateAction<number>>;
  setIsTestExecutionOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileTestCaseListUI = ({
  selectArray,
  selectedArray,
  selectAllArray,
  filteredMobileTestCaseListQuery,
  openDialog,
  setTestCaseIdx,
  setIsTestExecutionOpened,
}: Props) => {
  const navigate = useNavigate();

  const filteredMobileTestCaseList = filteredMobileTestCaseListQuery.data;

  const tableHeaderListWithoutIdx = [
    {
      title: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={(e) => selectAllArray(e)}
          disabled={
            filteredMobileTestCaseListQuery.isLoading ||
            filteredMobileTestCaseListQuery.isFetching ||
            filteredMobileTestCaseListQuery.isError
          }
        />
      ),
    },
    {
      title: selectedArray.length > 0 ? <div className="w-4" /> : 'TC-ID',
    },
    {
      title: selectedArray.length > 0 ? <div className="w-14" /> : 'Platform',
    },
    { title: 'Test Group' },
    { title: 'Title' },
    { title: 'Step Count' },
    { title: 'Priority' },
    { title: 'Creator' },
    { title: 'Created at', isSortable: true },
    { title: '' },
    { title: '' },
  ];

  const tableHeaderList = tableHeaderListWithoutIdx.map((header, idx) => ({
    ...header,
    idx,
  }));

  return (
    <TableWrapper selectedArray={selectedArray} openDialog={openDialog}>
      <thead>
        <tr>
          {tableHeaderList.map((tableHeader) => (
            <TableHeader
              key={tableHeader.idx}
              isSortable={tableHeader.isSortable}
            >
              {tableHeader.title}
            </TableHeader>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {filteredMobileTestCaseListQuery.isLoading ||
        filteredMobileTestCaseListQuery.isFetching ? (
          <TableNoList colSpan={tableHeaderList.length}>
            <TableSkeleton />
          </TableNoList>
        ) : filteredMobileTestCaseListQuery.isError ? (
          <TableNoList colSpan={tableHeaderList.length}>
            <ErrorMessage>
              {filteredMobileTestCaseListQuery.error.message}
            </ErrorMessage>
          </TableNoList>
        ) : filteredMobileTestCaseList?.length === 0 ? (
          <NoListResultMessage
            colSpan={tableHeaderList.length}
            testType="case"
          />
        ) : (
          filteredMobileTestCaseList?.map((mobileTestCase) => (
            <tr key={mobileTestCase.idx}>
              <TableBodyBasic>
                <Checkbox
                  checked={selectedArray.includes(mobileTestCase.idx)}
                  onChange={(e) => selectArray(mobileTestCase.idx, e)}
                />
              </TableBodyBasic>
              <TableBodyBasic>{mobileTestCase.tcId}</TableBodyBasic>
              <TableBodyBasic>
                {mobileTestCase.platformType === null
                  ? '-'
                  : findPlatformTypeNameFromServerName(
                      mobileTestCase.platformType,
                    )
                      .replace(/mobile/i, '')
                      .trim()}
              </TableBodyBasic>
              <TableBodyBasic>
                {mobileTestCase.caseGroupInfo?.name ?? '-'}
              </TableBodyBasic>
              <td className="table-data-basic max-w-[14.75rem]">
                <div className="font-medium text-gray-900">
                  {mobileTestCase.title}
                </div>
                <div className={`mt-1 w-full break-words text-gray-500`}>
                  {mobileTestCase.description}
                </div>
              </td>
              <TableBodyBasic>
                <p>AOS: {mobileTestCase.steps.aosStepCount}</p>
                <p>iOS: {mobileTestCase.steps.iosStepCount}</p>
              </TableBodyBasic>
              <TableBodyBasic>{mobileTestCase.priority}</TableBodyBasic>
              <TableBodyBasic>{mobileTestCase.creator}</TableBodyBasic>
              <TableBodyBasic>
                <div>{mobileTestCase.createdAt?.split(' ')[0] ?? '-'}</div>
                <div
                  className={`mt-1 flex items-center justify-start gap-2 text-gray-500`}
                >
                  {mobileTestCase.createdAt && <Time />}
                  {RegExp(timePattern).exec(mobileTestCase.createdAt)[0]}
                </div>
              </TableBodyBasic>
              <TableBodyBasic>
                <Execution
                  testType="case"
                  tooltipLocation={'right'}
                  disabled={
                    mobileTestCase.steps.aosStepCount === 0 &&
                    mobileTestCase.steps.iosStepCount === 0
                  }
                  onClick={() => {
                    if (
                      mobileTestCase.steps.aosStepCount > 0 ||
                      mobileTestCase.steps.iosStepCount > 0
                    ) {
                      setTestCaseIdx(mobileTestCase.idx);
                      setIsTestExecutionOpened(true);
                    }
                  }}
                />
              </TableBodyBasic>
              <TableBodyBasic>
                <Detail
                  onClick={() =>
                    navigate({
                      pathname: 'detail',
                      search: `idx=${mobileTestCase.idx}`,
                    })
                  }
                />
              </TableBodyBasic>
            </tr>
          ))
        )}
      </tbody>
    </TableWrapper>
  );
};

export default MobileTestCaseListUI;
