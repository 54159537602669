import { WebCommandOptionServerName } from '@utils/static/webCommandOptions';

import WebTestStepDetailConfirmInputTextarea from '@components/IDE/WebTestStepDetail/WebTestStepDetailConfirmInputTextarea';
import WebTestStepConfirmInputRadio from '@components/IDE/WebTestStepDetail/WebTestStepConfirmInputRadio';

import { useWebTestStepByCommandQuery } from '@lib/api/ide/web/webTestStep';

import {
  UpdateWebTestStepData,
  WebTestStepData,
} from '@customTypes/testStep/type';

import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import TestStepSelectSaveText from '../TestStepSelectSaveText/TestStepSelectSaveText';
import { CompareBy } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import clsx from 'clsx';
import AutomationContents from './AutomationContents';
import { useGetWebTestCaseDataAutomation } from '@lib/api/ide/web/webTestAutomation';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import { preventEnterHandler } from '@utils/helper/preventEnterHandler';

interface Props {
  webTestStep: WebTestStepData;
}

const TestStepConfirmOrInputText = ({ webTestStep }: Props) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<UpdateWebTestStepData>();

  const webTestStepBySaveTextQuery = useWebTestStepByCommandQuery();
  const saveTextLists = webTestStepBySaveTextQuery.data;

  const isCompareById = webTestStep.optionsDto.compareBy === CompareBy.Id;
  const isCompareByText = webTestStep.optionsDto.compareBy === CompareBy.Text;
  const isCompareByAutomation =
    webTestStep.optionsDto.compareBy === CompareBy.Automation;

  const command = watch('command');
  const compareBy = watch('optionsDto.compareBy');
  const isSecret = watch('optionsDto.secret') === 'true';
  const isSavedSecretValue =
    compareBy === webTestStep.optionsDto.compareBy &&
    webTestStep.optionsDto.secret === 'true';

  const isTextEncrypt = watch('optionsDto.textEncrypt') === 'true';
  const isSavedEncryptValue =
    compareBy === webTestStep.optionsDto.compareBy &&
    webTestStep.optionsDto.textEncrypt === 'true';

  const isEncryptSecretOnEdit =
    (isSecret && isSavedSecretValue) || (isTextEncrypt && isSavedEncryptValue);

  const { data: automationData } = useGetWebTestCaseDataAutomation();
  const targetData = automationData?.targetData;

  const radioOptions = [
    {
      id: 'id',
      title: '스텝 선택',
      defaultChecked: isCompareById,
      onClick: () => {
        if (isCompareById) {
          setValue('optionsDto.compareIdx', webTestStep.optionsDto.compareIdx);
        }
        if (isCompareByText) {
          setValue('optionsDto.compareIdx', undefined);
        }
        if (isCompareByAutomation) {
          setValue('optionsDto.autoColumn', undefined);
          setValue('optionsDto.targetData', undefined);
        }
        setValue('optionsDto.compareText', undefined);
      },
    },
    {
      id: 'text',
      title: '직접 입력',
      defaultChecked: isCompareByText,
      onClick: () => {
        if (isCompareById) {
          setValue('optionsDto.compareText', '');
        }
        if (isCompareByText) {
          setValue(
            'optionsDto.compareText',
            webTestStep.optionsDto.compareText,
          );
        }
        if (isCompareByAutomation) {
          setValue('optionsDto.autoColumn', undefined);
          setValue('optionsDto.targetData', undefined);
        }
        setValue('optionsDto.compareIdx', undefined);
      },
    },
  ];
  const automationOption = {
    id: 'automation',
    title: 'Automation',
    defaultChecked: isCompareByAutomation,
    onClick: () => {
      if (!isCompareByAutomation) {
        setValue('optionsDto.compareText', undefined);
        setValue('optionsDto.textEncrypt', undefined);
        setValue('optionsDto.secret', undefined);
        setValue('optionsDto.targetData', targetData);
        setValue('optionsDto.compareIdx', undefined);
      }
    },
  };

  const filteredRadioOptions =
    command === WebCommandOptionServerName.InputText
      ? [...radioOptions, automationOption]
      : radioOptions;

  const equalAndContainRadioOptions = [
    {
      title: '동일',
      id: 'equal',
      defaultChecked: webTestStep.optionsDto.compareRange === 'equal',
    },
    {
      title: '포함',
      id: 'contain',
      defaultChecked: webTestStep.optionsDto.compareRange === 'contain',
    },
  ];

  return (
    <div className="items-center border-b border-gray-200 sm:flex sm:px-6 sm:py-4">
      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
        Value*
      </dt>
      <dd className="mt-1 grid w-full grid-rows-1 gap-2 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
        <WebTestStepDetailConfirmInputTextarea
          name={'value'}
          isSavedSecretValue={webTestStep.optionsDto.secret === 'true'}
        />
        <div className="space-y-4 sm:flex sm:items-center sm:justify-between sm:space-x-10 sm:space-y-0">
          <WebTestStepConfirmInputRadio
            name={'optionsDto.compareBy'}
            radioOptions={filteredRadioOptions}
          />
        </div>
        {compareBy === CompareBy.Id && saveTextLists?.length === 0 ? (
          <div className="flex flex-col items-center justify-center border border-solid border-border-line p-3 text-sm">
            <p className="mb-2">선택 가능한 테스트 스텝이 없습니다.</p>
            <p>Save Text Step을 먼저 추가해주세요.</p>
          </div>
        ) : compareBy === CompareBy.Id && saveTextLists?.length !== 0 ? (
          <TestStepSelectSaveText saveTextLists={saveTextLists} />
        ) : compareBy === CompareBy.Text ? (
          <ErrorBoundaryWrapper>
            <input
              type={'text'}
              className={clsx(
                'input-base',
                (isSecret || isTextEncrypt) && 'password-input',
              )}
              placeholder={
                isEncryptSecretOnEdit
                  ? '암호화된 입력 값은 표시되지 않습니다.'
                  : webTestStep.command ===
                      WebCommandOptionServerName.ConfirmText
                    ? '비교할 텍스트를 직접 입력해 주세요.'
                    : '텍스트를 입력해 주세요.'
              }
              {...register('optionsDto.compareText', {
                required: {
                  value: !isEncryptSecretOnEdit,
                  message: '텍스트를 입력해 주세요.',
                },
              })}
              onKeyDown={(e) =>
                preventEnterHandler(e, isSecret || isTextEncrypt)
              }
            />
            <ErrorMessage
              name={'optionsDto.compareText'}
              errors={errors}
              render={({ message }) => (
                <p className="standard-error-message py-0">{message}</p>
              )}
            />
          </ErrorBoundaryWrapper>
        ) : (
          compareBy === CompareBy.Automation && <AutomationContents />
        )}
        {webTestStep.command === WebCommandOptionServerName.ConfirmText && (
          <WebTestStepConfirmInputRadio
            name={'optionsDto.compareRange'}
            radioOptions={equalAndContainRadioOptions}
          />
        )}
      </dd>
    </div>
  );
};

export default TestStepConfirmOrInputText;
