import React from 'react';
import MessengerRequestUrl from './MessengerFields/MessengerRequestUrl';
import MessengerServerCode from './MessengerFields/MessengerServerCode';
import MessengerSenderId from './MessengerFields/MessengerSenderId';
import MessengerRecipientList from './MessengerFields/MessengerRecipientList';

const Messenger10to12 = () => {
  return (
    <>
      <MessengerRequestUrl />
      <MessengerServerCode />
      <MessengerSenderId />
      <MessengerRecipientList />
    </>
  );
};

export default Messenger10to12;
