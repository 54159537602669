import {
  SpeedSchedulerTypeServerName,
  UpdateSpeedSchedulerData,
  UpdateSpeedSchedulerSchema,
} from '@customTypes/ide/speedScheduler/speedScheduler';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useRemoteUpdateSpeedSchedulerMutation,
  useRemoteUpdateSpeedSchedulerStatusMutation,
  useSpeedSchedulerDetailQuery,
  useUpdateSpeedSchedulerMutation,
  useUpdateSpeedSchedulerStatusMutation,
} from '@lib/api/ide/speedScheduler/speedScheduler';
import SpeedTestSchedulerDetailStatus from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailStatus';
import SpeedTestSchedulerDetailTestType from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailTestType';
import SpeedTestSchedulerDetailTitle from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailTitle';
import SpeedTestSchedulerDetailScenario from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailScenario';
import SpeedTestSchedulerDetailDevice from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailDevice';
import SpeedTestSchedulerDetailBrowser from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailBrowser';
import SpeedTestSchedulerDetailRepeat from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailRepeat';
import SpeedTestSchedulerDetailSchedule from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailSchedule';
import SpeedTestSchedulerDetailEmailAlarm from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailEmailAlarm';
import { Button, ErrorBoundaryWrapper, ErrorMessage } from '@autosquare/common';
import { useDispatch, useSelector } from 'react-redux';
import { hasSchedulerRunning } from '@store/ide/scheduler/schedulerSlice';
import { RootState } from '@app/store';
import SpeedTestSchedulerDetailMessengerAlarm from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailMessengerAlarm';
import { useSpeedSchedulerMessengerAlarm } from '@hooks/speedScheduler/useSpeedSchedulerMessengerAlarm';
import SpeedTestSchedulerDetailTestResultSaveOptions from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailTestResultSaveOptions';
import { Mode } from '@customTypes/ide/remote/type';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';
import { getRemoteState } from '@utils/static/getConfiguration';
import SpeedTestSchedulerDetailDeviceReboot from './SpeedTestSchedulerDetailForm/SpeedTestSchedulerDetailDeviceReboot';

const SpeedTestSchedulerDetailForm = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const speedSchedulerDetailQuery = useSpeedSchedulerDetailQuery();
  const speedSchedulerDetail = speedSchedulerDetailQuery.data;

  const methods = useForm<UpdateSpeedSchedulerData>({
    defaultValues: {
      scheduleIdx: speedSchedulerDetail.idx,
      title: speedSchedulerDetail.title,
      scenarioIdxList: speedSchedulerDetail.scenarioIdxList,
      startTime: speedSchedulerDetail.startTime,
      cronCycle: speedSchedulerDetail.cycle,
      mailReceivers: speedSchedulerDetail.mailReceivers,
      repeatNum: speedSchedulerDetail.repeatNum,
      autoRegister: true,
      messengerReceivers: speedSchedulerDetail.messengerReceivers ?? [],
      saveVideo:
        speedSchedulerDetail.type === SpeedSchedulerTypeServerName.Mobile
          ? speedSchedulerDetail.saveVideo
          : undefined,
      saveLog:
        speedSchedulerDetail.type === SpeedSchedulerTypeServerName.Mobile
          ? speedSchedulerDetail.saveLog
          : undefined,
      deviceReboot: speedSchedulerDetail.deviceReboot,
    },
    resolver: zodResolver(UpdateSpeedSchedulerSchema),
  });
  const { standardConfiguration, configuration } = getRemoteState();
  const updateSpeedSchedulerMutation = standardConfiguration
    ? useUpdateSpeedSchedulerMutation()
    : useRemoteUpdateSpeedSchedulerMutation();
  const updateSpeedSchedulerStatusMutation =
    configuration === Mode.Standard
      ? useUpdateSpeedSchedulerStatusMutation()
      : useRemoteUpdateSpeedSchedulerStatusMutation();

  const { isMessengerActivate } = useSpeedSchedulerMessengerAlarm();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hasSchedulerRunning(speedSchedulerDetail.running));
  }, []);

  const isRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );

  const onSubmit: SubmitHandler<UpdateSpeedSchedulerData> = (data) => {
    if (isRunning && data.scenarioIdxList.length === 0) {
      methods.setError('scenarioIdxList', {
        type: 'required',
        message: '시나리오를 선택해 주세요.',
      });
    } else {
      updateSpeedSchedulerStatusMutation.mutate(
        { idx: speedSchedulerDetail.idx, isRunning: isRunning },
        {
          onSuccess: () => {
            if (isRunning) {
              updateSpeedSchedulerMutation.mutate(data);
            } else {
              navigate(pathname.split('/').slice(0, -1).join('/'));
            }
          },
        },
      );
    }
  };

  return (
    <ErrorBoundaryWrapper>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="mt-4 flow-root"
        >
          <div className="mt-4 flex flex-col gap-y-4 space-y-4 border-b border-gray-900/10 pb-12">
            <SpeedTestSchedulerDetailStatus />
            <SpeedTestSchedulerDetailTestType />
            <SpeedTestSchedulerDetailTitle />
            <SpeedTestSchedulerDetailScenario />
            <SpeedTestSchedulerDetailDevice />
            <SpeedTestSchedulerDetailBrowser />
            <SpeedTestSchedulerDetailRepeat />
            <SpeedTestSchedulerDetailSchedule />
            {speedSchedulerDetail.type ===
              SpeedSchedulerTypeServerName.Mobile && (
              <SpeedTestSchedulerDetailTestResultSaveOptions />
            )}
            <SpeedTestSchedulerDetailDeviceReboot />
            <SpeedTestSchedulerDetailEmailAlarm />
            {isMessengerActivate && <SpeedTestSchedulerDetailMessengerAlarm />}
          </div>
          <div className="flex items-center justify-end gap-6 border-t border-solid border-gray-200 py-4">
            {updateSpeedSchedulerMutation.isError ? (
              <ErrorMessage>
                {updateSpeedSchedulerMutation.error.message}
              </ErrorMessage>
            ) : (
              updateSpeedSchedulerStatusMutation.isError && (
                <ErrorMessage>
                  {updateSpeedSchedulerStatusMutation.error.message}
                </ErrorMessage>
              )
            )}
            <Button
              type="button"
              variant="secondary"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={
                updateSpeedSchedulerMutation.isLoading ||
                updateSpeedSchedulerStatusMutation.isLoading
              }
            >
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default SpeedTestSchedulerDetailForm;
