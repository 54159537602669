import React from 'react';
import DefinitionTextAreaWithSecretOption from '@components/shared/TestStepDetail/DefinitionTextAreaWithSecretOption';
import DefinitionList from '@components/shared/DefinitionList';
import SecretEditBox from '@components/TestStep/SecretEditBox';
import { useFormContext } from 'react-hook-form';
import { UpdateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Props = {
  isSavedSecretValue: boolean;
};

const ClickDefaultOptionEdit = ({ isSavedSecretValue }: Props) => {
  const { control } = useFormContext<UpdateTestStepData>();
  return (
    <ErrorBoundaryWrapper>
      <DefinitionTextAreaWithSecretOption
        term={'Value*'}
        name={'value'}
        isSavedSecretValue={isSavedSecretValue}
      />
      <DefinitionList
        term="Secret"
        description={<SecretEditBox control={control} />}
      />
    </ErrorBoundaryWrapper>
  );
};

export default ClickDefaultOptionEdit;
