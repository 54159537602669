import { ListBoxHeadlessInController } from '@autosquare/common';

import {
  findTextReplaceAddNameByServerName,
  textReplaceAddList,
} from '@utils/static/mobileTestStep/textReplace/textReplaceAddList';

import DefinitionList from '@components/shared/DefinitionList';

import { UpdateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { AddServerName } from '@customTypes/ide/mobileTestStep/textReplace/textReplace';

import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

type Props = {
  addKey?: AddServerName;
  addValue?: number;
};

const Add = ({ addKey = AddServerName.Day, addValue = 0 }: Props) => {
  const {
    control,
    register,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext<UpdateTestStepData>();
  useEffect(() => {
    if (addKey) {
      setValue('optionsDto.textReplaceOptions.addKey', addKey);
    } else {
      setValue('optionsDto.textReplaceOptions.addKey', AddServerName.Day);
    }
  }, [addKey, setValue, resetField]);

  useEffect(() => {
    if (addValue) {
      setValue('optionsDto.textReplaceOptions.addValue', addValue);
    } else {
      setValue('optionsDto.textReplaceOptions.addValue', 0);
    }
  }, [addValue, setValue, resetField]);

  return (
    <DefinitionList
      term={'Add*'}
      description={
        <div className="grid grid-cols-7 gap-x-6">
          <div className="col-span-2">
            <Controller
              control={control}
              name="optionsDto.textReplaceOptions.addKey"
              render={({ field }) => (
                <ListBoxHeadlessInController
                  value={field.value || 'Select'}
                  buttonValue={
                    findTextReplaceAddNameByServerName(field.value) || 'Select'
                  }
                  onChange={field.onChange}
                  lists={textReplaceAddList}
                  valueToSave={'serverName'}
                  valueToShow={'name'}
                />
              )}
            />
          </div>
          <div className="col-span-5">
            <input
              type="number"
              className="input-base"
              {...register('optionsDto.textReplaceOptions.addValue', {
                required: '최소 0 이상 최대 50 이하 값을 입력해 주세요.',
                min: {
                  value: 0,
                  message: '최소 0 이상 최대 50 이하 값을 입력해 주세요.',
                },
                max: {
                  value: 50,
                  message: '최소 0 이상 최대 50 이하 값을 입력해 주세요.',
                },
              })}
            />
          </div>
          <ErrorMessage
            name="optionsDto.textReplaceOptions.addValue"
            errors={errors}
            render={({ message }) => (
              <p className="standard-error-message col-span-5">{message}</p>
            )}
          />
        </div>
      }
    />
  );
};

export default Add;
