import {
  ErrorBoundaryWrapper,
  PagingHeadingsInAutosquare,
} from '@autosquare/common';
import React from 'react';
import { useLocation } from 'react-router-dom';

import CreateSchedulerForm from './components/CreateSchedulerForm';
import AddWirelessDeviceButton from '@components/AddWirelessDeviceButton/AddWirelessDeviceButton';
import { useParentPath } from '@hooks/index';

const CreateScheduler = () => {
  const { pathname, search } = useLocation();

  const uiTestPage = useParentPath(2);
  const schedulerListPage = useParentPath();

  const breadcrumbs = [
    { title: 'UI Test', href: `${uiTestPage}/overview` },
    { title: 'Scheduler', href: schedulerListPage },
    { title: 'Create', href: pathname + search },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="flex items-center justify-between">
        <PagingHeadingsInAutosquare
          breadcrumbs={breadcrumbs}
          pageTitle={'Create Scheduler'}
        />
        <div className="mt-8">
          <AddWirelessDeviceButton />
        </div>
      </div>
      <CreateSchedulerForm />
    </ErrorBoundaryWrapper>
  );
};

export default CreateScheduler;
