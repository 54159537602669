import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { UpdateSchedulerData } from '@customTypes/ide/scheduler/scheduler';
import { MessengerAlarmHeadlessListbox } from '@components/Scheduler/MessengerAlarmHeadlessListbox';
import { useSchedulerMessengerAlarm } from '@hooks/scheduler/useSchedulerMessengerAlarm';
import SchedulerDetailMessengerList from './SchedulerDeailMessengerAlarm/SchedulerDetailMessengerList';
import { RecipientData } from '@customTypes/ide/speedScheduler/speedScheduler';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';

interface Props {
  isRunning: boolean;
}

const SchedulerDetailMessengerAlarm = ({ isRunning }: Props) => {
  const [inputValue, setInputValue] = useState<RecipientData>({
    recipientId: '',
    recipientInfo: '',
  });
  const { setValue, watch } = useFormContext<UpdateSchedulerData>();
  const messengerReceiver = watch('messengerReceivers');

  const {
    filteredMessengerReceivers,
    filteredList,
    platformName,
    deleteTagItem,
  } = useSchedulerMessengerAlarm(messengerReceiver, setValue);

  useEffect(() => {
    const savedRecipient = messengerReceiver?.[0]?.recipientList ?? '';
    const addedRecipient = `${inputValue?.recipientId}${inputValue?.recipientInfo ? '/' : ''}${inputValue?.recipientInfo ?? ''}`;
    const updatedRecipient = savedRecipient
      ? `${savedRecipient},${addedRecipient}`
      : addedRecipient;
    if (addedRecipient) {
      setValue('messengerReceivers', [
        {
          platformName: platformName,
          recipientList: updatedRecipient,
        },
      ]);
    }
  }, [inputValue]);

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer title={'Messenger Alarm'}>
        <div className="mt-2 sm:col-span-2 sm:mt-0">
          <MessengerAlarmHeadlessListbox
            state={inputValue}
            setState={setInputValue}
            list={filteredList}
            disabled={!isRunning}
          />
          <SchedulerDetailMessengerList
            isRunning={isRunning}
            messengerList={filteredMessengerReceivers}
            deleteTagItem={deleteTagItem}
          />
        </div>
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default SchedulerDetailMessengerAlarm;
