import React, { BaseSyntheticEvent } from 'react';
import { CreateAdminProjectData } from '@customTypes/dashboard/admin/type';
import { ErrorMessage } from '@hookform/error-message';
import { Path, useFormContext, ValidationRule } from 'react-hook-form';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { TooltipTop } from '@autosquare/common';

type Props = {
  autoRegisterInputList: {
    labelId: string;
    label: string;
    id: string;
    explanation: string;
    name: Path<CreateAdminProjectData>;
    pattern?: ValidationRule<RegExp>;
  }[];
  isSavedButtonClicked: boolean;
};

const AutoRegisterInputList = ({
  autoRegisterInputList,
  isSavedButtonClicked,
}: Props) => {
  const {
    watch,
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateAdminProjectData>();
  const platform = watch('platform');

  return (
    <>
      {platform && (
        <div className="flex flex-col space-y-4">
          {autoRegisterInputList.map((input) => (
            <React.Fragment key={input.id}>
              <div className="flex gap-x-2.5">
                <label
                  className="text-sm font-medium leading-tight text-gray-900"
                  htmlFor={input.labelId}
                >
                  {input.label}
                </label>
                {input.labelId.includes('token') && (
                  <div className="group relative">
                    <QuestionMarkCircleIcon className="size-5 cursor-help text-red-500" />
                    <TooltipTop positionClass="icon-description -left-24 bottom-6 group-hover:visible invisible">
                      API Token이 만료될 경우, 테스트 결과의 자동 등록이
                      중단됩니다.
                    </TooltipTop>
                  </div>
                )}
                <p
                  className="text-sm font-medium leading-tight text-gray-500"
                  id={input.id}
                >
                  {input.explanation}
                </p>
              </div>
              <div>
                <input
                  className="input-base"
                  id={input.labelId}
                  aria-describedby={input.id}
                  {...register(input.name, {
                    required: '필수 입력 항목입니다.',
                    pattern: input.pattern,
                    onChange: (value: BaseSyntheticEvent) => {
                      if (value.target.value) clearErrors(`${input.name}`);
                      else if (isSavedButtonClicked && !value.target.value) {
                        setError(`${input.name}`, {
                          message: '필수 입력 항목입니다.',
                        });
                      }
                    },
                  })}
                />
                <ErrorMessage
                  name={input.name}
                  errors={errors}
                  render={({ message }) => (
                    <p className="error-message mt-2">{message}</p>
                  )}
                />
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
};

export default AutoRegisterInputList;
