import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ScenarioDraggableSection from './CreateSpeedTestSchedulerScenario/ScenarioDraggableSection';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const CreateSpeedTestSchedulerScenario = () => {
  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer title="Scenario" isTitleCenter={true}>
        <DndProvider backend={HTML5Backend}>
          <ScenarioDraggableSection />
        </DndProvider>
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default CreateSpeedTestSchedulerScenario;
