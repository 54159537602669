import { useTitle } from '@autosquare/common';

import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';

import { useGetRefreshToken } from '@hooks/electron/useGetRefreshToken';

const IdeRoot = () => {
  useTitle('Autosquare');

  const { cookie } = useGetRefreshToken();

  return (
    <>
      <ScrollRestoration getKey={(location) => location.key} />
      <Outlet />
    </>
  );
};

export default IdeRoot;
