import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';

import 'react-datepicker/dist/react-datepicker.css';
import { useFormContext } from 'react-hook-form';
import { CreateSpeedSchedulerData } from '@customTypes/ide/speedScheduler/speedScheduler';
import dayjs from 'dayjs';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const CreateSpeedSchedulerStartDate = () => {
  registerLocale('ko', ko);

  const { setValue } = useFormContext<CreateSpeedSchedulerData>();

  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);

  const minDate = new Date();
  minDate.setSeconds(0, 0);

  const toggleCalendar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setValue(
      'startTime',
      dayjs(startDate).second(0).format('YYYY-MM-DD HH:mm:ss'),
    );
  }, [setValue, startDate]);

  return (
    <ErrorBoundaryWrapper>
      <div className="mt-2 sm:col-span-5 sm:mt-0">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Start Time
        </label>
        <div className="mt-2 flex rounded-md shadow-sm">
          <div className="relative flex grow items-stretch focus-within:z-10">
            <label className="flex size-full items-center justify-between focus:border-none">
              <div className="w-full" onClick={() => setIsOpen(true)}>
                <DatePicker
                  selected={startDate}
                  timeInputLabel="Time : "
                  showPopperArrow={false}
                  showTimeInput
                  minDate={minDate}
                  dateFormat="yyyy-MM-dd HH:mm"
                  locale="ko"
                  className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(date: Date | null) => {
                    date.setSeconds(0);
                    setStartDate(date);
                  }}
                  onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                  required
                  readOnly
                  disabled={isOpen}
                  open={isOpen}
                  onClickOutside={() => setIsOpen(false)}
                />
              </div>
              <div
                onClick={toggleCalendar}
                className="relative -ml-px inline-flex cursor-pointer items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="size-5"
                >
                  <path d="M5.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H6a.75.75 0 01-.75-.75V12zM6 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H6zM7.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H8a.75.75 0 01-.75-.75V12zM8 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H8zM9.25 10a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H10a.75.75 0 01-.75-.75V10zM10 11.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V12a.75.75 0 00-.75-.75H10zM9.25 14a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H10a.75.75 0 01-.75-.75V14zM12 9.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V10a.75.75 0 00-.75-.75H12zM11.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H12a.75.75 0 01-.75-.75V12zM12 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H12zM13.25 10a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H14a.75.75 0 01-.75-.75V10zM14 11.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V12a.75.75 0 00-.75-.75H14z" />
                  <path
                    fillRule="evenodd"
                    d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </label>
          </div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default CreateSpeedSchedulerStartDate;
