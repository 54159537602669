import {
  SectionTitle,
  SectionMainTitleContainer,
  SectionTitleDescription,
  SectionTitleMain,
  SectionTitleRight,
} from '@autosquare/common';
import React from 'react';

const CreateMobileTestHeader = () => {
  return (
    <div className="pt-8">
      <SectionTitle>
        <SectionMainTitleContainer>
          <SectionTitleMain>Test Case Information</SectionTitleMain>
          <SectionTitleRight type="caution">* 필수 입력 사항</SectionTitleRight>
        </SectionMainTitleContainer>
        <SectionTitleDescription>
          테스트 케이스 정보를 입력해주세요.
        </SectionTitleDescription>
      </SectionTitle>
    </div>
  );
};

export default CreateMobileTestHeader;
