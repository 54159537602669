import React from 'react';
import dayjs from 'dayjs';
import {
  Checkbox,
  Detail,
  TableBodyBasic,
  TooltipTop,
} from '@autosquare/common';
import { AppInfoList } from '@customTypes/dashboard/project/application/type';
import { ClockIcon } from '@heroicons/react/24/outline';
import { truncateText } from '@utils/helper/truncateText';
import { useNavigate } from 'react-router-dom';
import { useAuthUserInfo } from '@hooks/index';

type Props = {
  info: AppInfoList;
  index: number;
  selectedArray: number[];
  selectArray: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ApplicationTableBody = ({
  info,
  index,
  selectedArray,
  selectArray,
}: Props) => {
  const { isAdmin } = useAuthUserInfo();
  const registeredDate = dayjs(info.updatedAt).format('YYYY-MM-DD');
  const registeredTime = dayjs(info.updatedAt).format('HH:mm');
  const positionClass =
    'icon-description bottom-12 left-1 invisible group-hover:visible';
  const navigate = useNavigate();

  return (
    <tr>
      {isAdmin && (
        <TableBodyBasic>
          <Checkbox
            checked={selectedArray.includes(info.idx)}
            onChange={(e) => selectArray(info.idx, e)}
          />
        </TableBodyBasic>
      )}
      <TableBodyBasic>{index + 1}</TableBodyBasic>
      <TableBodyBasic
        showTooltipOnHover={
          info.appName.length > 30 || info.description?.length > 30
        }
      >
        <div>{truncateText(info.appName)}</div>
        <div className="text-xs font-normal leading-normal text-gray-500">
          {truncateText(info.description)}
        </div>
        <TooltipTop positionClass={positionClass}>
          <div>{info.appName}</div>
          <div>{info.description}</div>
        </TooltipTop>
      </TableBodyBasic>
      <TableBodyBasic showTooltipOnHover={info.packageName.length > 30}>
        {truncateText(info.packageName)}
        <TooltipTop positionClass={positionClass}>
          <div>{info.packageName}</div>
        </TooltipTop>
      </TableBodyBasic>
      <TableBodyBasic>v{info.appVersion}</TableBodyBasic>
      <TableBodyBasic>{info.buildVersion}</TableBodyBasic>
      <TableBodyBasic>{info.osType}</TableBodyBasic>
      <TableBodyBasic>
        <div className="whitespace-nowrap">{registeredDate}</div>
        <div className="flex items-center gap-x-1 text-gray-500">
          <ClockIcon className="size-3.5" />
          <div>{registeredTime}</div>
        </div>
      </TableBodyBasic>
      <TableBodyBasic showTooltipOnHover={info.registerUser.length > 30}>
        <div className="whitespace-nowrap">
          {truncateText(info.registerUser)}
        </div>
        <TooltipTop positionClass={positionClass}>
          <div>{info.registerUser}</div>
        </TooltipTop>
      </TableBodyBasic>
      <TableBodyBasic>
        <Detail
          onClick={() =>
            navigate({ pathname: 'detail', search: `idx=${info.idx}` })
          }
        />
      </TableBodyBasic>
    </tr>
  );
};

export default ApplicationTableBody;
