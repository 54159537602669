import { Checkbox } from '@autosquare/common';
import { SelectedItem } from '@autosquare/common/src/hooks/checkbox/useCheckboxWithDnd';
import { TestCaseDetailPickTitleAndIdx } from '@customTypes/testCase/type';

import React from 'react';

interface Props {
  testCase: TestCaseDetailPickTitleAndIdx;
  selectArray: (
    idx: number,
    dndIndex: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  selectedArray: SelectedItem[];
}

const ListItemCard = ({ testCase, selectArray, selectedArray }: Props) => {
  const selectedDnd = selectedArray.map(({ dndIndex }) => dndIndex);
  return (
    <div className="m-2 flex items-center border border-solid bg-gray-50 px-3 py-2">
      <Checkbox
        checked={selectedDnd.includes(testCase?.dndIndex)}
        onChange={(e) => {
          selectArray(testCase?.idx, testCase?.dndIndex, e);
        }}
      />
      <div className="ml-7">{testCase?.title}</div>
    </div>
  );
};

export default ListItemCard;
