import {
  Breadcrumbs,
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
  PagingHeadingsNavigate,
} from '@autosquare/common';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useProjectDetailQuery } from '@lib/api/ide/project/project';
import BrowserView from './components/BrowserView';

const WebTestStep = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const [isPreloadFileMissing, setIsPreloadFileMissing] = useState(false);
  const [downloadErrorMessage, setDownloadErrorMessage] = useState<string>('');
  const [isDownloadError, setIsDownloadError] = useState(false);

  const projectDetailQuery = useProjectDetailQuery();
  const projectName = projectDetailQuery.data?.name;

  useEffect(() => {
    window.electron.send('checkPreloadFile');

    window.electron.on('noPreloadFile', () => {
      setIsPreloadFileMissing(true);
    });

    window.electron.on('preloadDownloadError', (message: string) => {
      setDownloadErrorMessage(message);
      setIsDownloadError(true);
    });

    return () => window.electron.send('closeAddTestStepAndList');
  }, []);

  const webTestCaseTitle = sessionStorage.getItem('testCaseTitle');
  const webTestCaseDetailPage = `${pathname.split('/').slice(0, -1).join('/')}/test-case/detail`;

  const breadcrumbs: Breadcrumbs[] = [
    {
      title: projectName,
      href: `/ide/projects/list`,
    },
    {
      title: 'UI Test',
      href: `${pathname.split('/').slice(0, -2).join('/')}/overview`,
    },
    {
      title: 'Web',
      href: `${pathname.split('/').slice(0, -1).join('/')}/test-case`,
    },
    {
      title: webTestCaseTitle,
      href: `${webTestCaseDetailPage}${search}`,
    },
    { title: 'Test Step', href: pathname + search },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="mx-4 flex items-center justify-between py-4">
        <PagingHeadingsNavigate breadcrumbs={breadcrumbs} pageTitle={null} />
        <Button
          type="button"
          onClick={() =>
            navigate({ pathname: webTestCaseDetailPage, search: search })
          }
          variant="primary"
        >
          Close
        </Button>
      </div>
      <div className="flex h-full justify-between">
        <BrowserView />
      </div>
      <DoubleCheckDialogHeadlessUI
        isOpened={isPreloadFileMissing}
        setIsOpened={setIsPreloadFileMissing}
        type={'caution'}
        title={'Error'}
        subTitle={
          '현재 화면을 구성하기 위한 파일이 존재하지 않습니다.\n파일 다운로드를 위해 Download 버튼을 클릭해주세요.'
        }
        buttonChildren={
          <Button
            type="button"
            variant="primary"
            onClick={() => {
              window.electron.send('preloadDownloaded');
              setIsPreloadFileMissing(false);
            }}
          >
            Download
          </Button>
        }
      />
      <DoubleCheckDialogHeadlessUI
        isOpened={isDownloadError}
        setIsOpened={setIsDownloadError}
        type={'caution'}
        title={'Error'}
        subTitle={`Error : ${downloadErrorMessage}\n에러가 지속된다면 관리자에게 문의해주세요.`}
        buttonChildren={
          <Button
            type="button"
            onClick={() => {
              setIsDownloadError(false);
            }}
          >
            Cancel
          </Button>
        }
      />
    </ErrorBoundaryWrapper>
  );
};

export default WebTestStep;
