import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';

import React, { useContext, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useTestStepValueList } from '@hooks/testStep/useTestStepValueList';

import { MobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';

import Repeat from '@components/TestStep/Repeat';
import AddTestStepConditionHeader from '@components/TestStep/AddTestStepConditionHeader';
import TestStepValueListbox from '@components/IDE/TestStepValueListbox';

import {
  mobileCommandOptions,
  findMobileCommandNameFromServerName,
  MobileCommandOptionName,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ErrorMessage } from '@hookform/error-message';

const LoopCondition = () => {
  const { isListboxOpened, setIsListboxOpened } = useContext(
    MobileTestStepContext,
  );

  const {
    watch,
    control,
    resetField,
    setValue,
    formState: { errors },
  } = useFormContext<CreateTestStepData>();

  const command = watch('command');
  const value = watch('value');
  const conditionCommand = watch('optionsDto.conditionCommand');
  const conditionValue = watch('optionsDto.conditionValue');
  const maskingOptionsEnabled =
    watch('optionsDto.maskingOptions.enabled') === 'true';

  const { totalValues, bounds, objWithFullXpathMap } = useTestStepValueList({
    command: command,
  });

  useEffect(() => {
    return () => {
      resetField('optionsDto.conditionCommand');
      resetField('optionsDto.repeatLimit');
    };
  }, [resetField]);

  useEffect(() => {
    setValue('optionsDto.conditionOptions.elementOptions.bounds', bounds);
    return () => setValue('optionsDto.conditionOptions', undefined);
  }, [setValue, conditionValue]);

  useEffect(() => {
    setValue('optionsDto.fullXpath', undefined);
    if (conditionValue !== undefined) {
      setValue('optionsDto.fullXpath', objWithFullXpathMap.get(conditionValue));
    }
    return () => setValue('optionsDto.fullXpath', undefined);
  }, [conditionValue, conditionCommand]);

  return (
    <ErrorBoundaryWrapper>
      <AddTestStepConditionHeader
        header={'Condition'}
        description={'지정된 조건이 만족하는 동안 반복 실행됩니다.'}
      />
      <Controller
        control={control}
        defaultValue={undefined}
        name={'optionsDto.conditionCommand'}
        rules={{ required: 'Conditon Command를 선택해주세요.' }}
        render={({ field }) => (
          <ListBoxHeadlessInController
            value={field.value}
            buttonValue={
              findMobileCommandNameFromServerName(field.value) || 'Command'
            }
            onChange={field.onChange}
            lists={mobileCommandOptions.filter(
              (mobileCommand) =>
                mobileCommand.name ===
                  MobileCommandOptionName.VerifyNoElement ||
                mobileCommand.name === MobileCommandOptionName.FindElement,
            )}
            valueToSave={'serverName'}
            valueToShow={'name'}
            disabled={maskingOptionsEnabled}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={'optionsDto.conditionCommand'}
        render={({ message }) => (
          <p className="error-message -mt-2">{message}</p>
        )}
      />
      <Controller
        control={control}
        name={'optionsDto.conditionValue'}
        rules={{ required: 'Condition Value를 선택해주세요.' }}
        render={({ field }) => (
          <TestStepValueListbox
            valueText={field.value || 'Value'}
            setValueText={field.onChange}
            totalValues={totalValues}
            isReset={
              value === MobileCommandOptionServerName.Click ? true : false
            }
            isListboxOpened={isListboxOpened}
            setIsListboxOpened={setIsListboxOpened}
            resetOnClick={() => {
              setValue('optionsDto.conditionCommand', undefined);
              setValue('optionsDto.conditionValue', undefined);
            }}
            disabled={
              conditionCommand === undefined ||
              (value === MobileCommandOptionServerName.Click &&
                field.value !== undefined) ||
              maskingOptionsEnabled
            }
            resetDisabled={maskingOptionsEnabled}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={'optionsDto.conditionValue'}
        render={({ message }) => (
          <p className="error-message -mt-2">{message}</p>
        )}
      />
      <Repeat keyName={'optionsDto.repeatLimit'} defaultValue={5} max={15} />
    </ErrorBoundaryWrapper>
  );
};

export default LoopCondition;
