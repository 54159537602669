export type DataTypeList = {
  idx: number;
  name: DisplayDataType;
  serverName: ServerFormatDataType;
};

export enum DisplayDataType {
  Number = 'Number',
}

export enum ServerFormatDataType {
  Number = 'number',
}

export const dataTypeList: DataTypeList[] = [
  {
    idx: 0,
    name: DisplayDataType.Number,
    serverName: ServerFormatDataType.Number,
  },
];

export enum OperatorSymbol {
  Plus = '+',
  Minus = '-',
}

export enum Operator {
  Plus = 'plus',
  Minus = 'minus',
}

export type OperatorList = {
  operator: Operator;
  inputValue: string;
};

export const findDataTypeFromServerName = (serverName: ServerFormatDataType) =>
  dataTypeList.find((dataType) => dataType.serverName === serverName)?.name;
