import {
  Checkbox,
  TableBodyBasic,
  TableRowBackgroundGray,
} from '@autosquare/common';
import {
  ConnectionType,
  DeviceInfoOs,
  DeviceInfoOsFullName,
} from '@customTypes/ide/device/device';
import {
  SpeedSchedulerDetailData,
  SpeedSchedulerTypeName,
  SpeedSchedulerTypeServerName,
} from '@customTypes/ide/speedScheduler/speedScheduler';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import {
  DocumentMagnifyingGlassIcon,
  WifiIcon,
} from '@heroicons/react/24/outline';
import { UseMutationResult } from '@tanstack/react-query';
import { parseCronCycle } from '@utils/static/parseCronCycle';
import { formatDays } from '@utils/static/selectedDays';
import dayjs from 'dayjs';
import React from 'react';
import { LiaUsb } from 'react-icons/lia';
import { useNavigate } from 'react-router-dom';

type Props = {
  idx: number;
  speedScheduler: SpeedSchedulerDetailData;
  selectedArray: number[];
  selectArray: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  updateSpeedSchedulerStatusMutation: UseMutationResult<
    SpeedSchedulerDetailData,
    Error,
    { idx: number; isRunning: boolean }
  >;
  setIsErrorModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

const SpeedTestSchedulerTableBody = ({
  idx,
  speedScheduler,
  selectedArray,
  selectArray,
  updateSpeedSchedulerStatusMutation,
  setIsErrorModalOpened,
}: Props) => {
  const navigate = useNavigate();

  return (
    <TableRowBackgroundGray
      isInvalid={!speedScheduler.running}
      key={speedScheduler.idx}
    >
      <TableBodyBasic>
        <Checkbox
          checked={selectedArray.includes(speedScheduler.idx)}
          onChange={(e) => selectArray(speedScheduler.idx, e)}
        />
      </TableBodyBasic>
      <TableBodyBasic>{idx + 1}</TableBodyBasic>
      <TableBodyBasic>
        {speedScheduler.type === SpeedSchedulerTypeServerName.Mobile
          ? SpeedSchedulerTypeName.Mobile
          : SpeedSchedulerTypeName.Web}
      </TableBodyBasic>
      <TableBodyBasic>
        {speedScheduler.type === SpeedSchedulerTypeServerName.Mobile ? (
          <div className="flex items-start gap-2">
            {speedScheduler.environment.split('/')[3] === ConnectionType.USB ? (
              <LiaUsb size={24} />
            ) : (
              <WifiIcon className="size-5" />
            )}
            <div>
              <p>{speedScheduler.environment.split('/')[1]}</p>
              <p className="text-xs text-gray-500">
                {speedScheduler.environment.split('/')[2] === DeviceInfoOs.Aos
                  ? DeviceInfoOsFullName.Aos
                  : DeviceInfoOsFullName.Ios}
              </p>
            </div>
          </div>
        ) : (
          speedScheduler.environment.split('/')[0][0].toUpperCase() +
          speedScheduler.environment.split('/')[0].slice(1)
        )}
      </TableBodyBasic>
      <TableBodyBasic>{speedScheduler.title}</TableBodyBasic>
      <TableBodyBasic>
        <p>{speedScheduler.scenarioIdxList.length}</p>
      </TableBodyBasic>
      <TableBodyBasic>
        <div className="flex flex-col">
          <span>
            {`Created at: ${
              speedScheduler.createdAt
                ? dayjs(speedScheduler.createdAt).format('YYYY-MM-DD HH:mm:ss')
                : '-'
            }`}
          </span>
          <span>After: {speedScheduler.startTime.slice(0, -4)}</span>
          <span>
            {`On: ${formatDays(parseCronCycle(speedScheduler.cycle).onPart)}`}
          </span>
          <span>
            {`Run: ${parseCronCycle(speedScheduler.cycle).timeInterval}`}
          </span>
        </div>
      </TableBodyBasic>
      <TableBodyBasic>
        <button
          type="button"
          className="rounded-md hover:text-congress-blue"
          aria-label="update status"
          onClick={() =>
            updateSpeedSchedulerStatusMutation.mutate(
              { idx: speedScheduler.idx, isRunning: !speedScheduler.running },
              { onError: () => setIsErrorModalOpened(true) },
            )
          }
          disabled={updateSpeedSchedulerStatusMutation.isLoading}
        >
          {speedScheduler.running ? (
            <EyeIcon className="size-5" aria-label="eye" />
          ) : (
            <EyeSlashIcon className="size-5" aria-label="eye slash" />
          )}
        </button>
      </TableBodyBasic>
      <TableBodyBasic>
        <button
          type="button"
          className="size-6 rounded-md hover:text-congress-blue"
          aria-label="navigate detail"
          onClick={() => navigate(`detail?idx=${speedScheduler.idx}`)}
        >
          <DocumentMagnifyingGlassIcon className="size-full" />
        </button>
      </TableBodyBasic>
    </TableRowBackgroundGray>
  );
};

export default SpeedTestSchedulerTableBody;
