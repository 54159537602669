import {
  ErrorBoundaryWrapper,
  ErrorMessage,
  TableSkeleton,
} from '@autosquare/common';
import { useProjectDeviceDetailQuery } from '@lib/api/dashboard/queryHooks';
import React from 'react';
import ProjectDeviceDetailForm from './ProjectDeviceDetailMain/ProjectDeviceDetailForm';
import { useAuthUserInfo } from '@hooks/index';
import NormalUserDeviceDetail from './ProjectDeviceDetailMain/NormalUserDeviceDetail';

const ProjectDeviceDetailMain = () => {
  const { isAdmin, isMember } = useAuthUserInfo();

  const { isLoading, isFetching, isError, error } =
    useProjectDeviceDetailQuery();

  if (isLoading || isFetching) {
    return <TableSkeleton />;
  }

  if (isError) {
    return <ErrorMessage>{error.message}</ErrorMessage>;
  }

  if (isAdmin) {
    return (
      <ErrorBoundaryWrapper>
        <ProjectDeviceDetailForm />
      </ErrorBoundaryWrapper>
    );
  }

  if (isMember) {
    return (
      <ErrorBoundaryWrapper>
        <NormalUserDeviceDetail />
      </ErrorBoundaryWrapper>
    );
  }
};

export default ProjectDeviceDetailMain;
