import { DeviceInfoOsFullName } from '@customTypes/index';
import { createQueryKeys } from '@queries/createQueryKeys';

export const projectDeviceKeys = {
  ...createQueryKeys('projectDevice'),
  enabledInstallDeviceLists: () =>
    [...projectDeviceKeys.all, 'enabledInstallDeviceList'] as const,
  enabledInstallDeviceList: (
    projectIdx: string,
    packageName: string,
    type: DeviceInfoOsFullName,
  ) =>
    [
      ...projectDeviceKeys.enabledInstallDeviceLists(),
      projectIdx,
      packageName,
      type,
    ] as const,
  installedAppVersionLists: () =>
    [...projectDeviceKeys.all, 'installedAppVersionList'] as const,
  installedAppVersionList: (projectIdx: string, deviceIdx: string) =>
    [
      ...projectDeviceKeys.installedAppVersionLists(),
      projectIdx,
      deviceIdx,
    ] as const,
};
