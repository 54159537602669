import React from 'react';
import TestCaseInfoTop from './TestCaseInfoForm/TestCaseInfoTop';
import TestCaseInfoBottom from './TestCaseInfoForm/TestCaseInfoBottom';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const TestCaseInfoForm = () => {
  return (
    <ErrorBoundaryWrapper>
      <div className="mt-1">
        <TestCaseInfoTop />
        <TestCaseInfoBottom />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default TestCaseInfoForm;
