import { ErrorBoundaryWrapper } from '@autosquare/common';
import React from 'react';
import ProjectAddDevicePagingHeader from './components/ProjectAddDevicePagingHeader';
import ProjectDeviceInfoForm from './components/ProjectDeviceInfoForm';
import { useAuthUserInfo } from '@hooks/index';
import { AccessDenied } from '@components/index';

const ProjectAddDevice = () => {
  const { isAdmin } = useAuthUserInfo();

  return (
    <ErrorBoundaryWrapper>
      <ProjectAddDevicePagingHeader />
      {isAdmin ? (
        <ErrorBoundaryWrapper>
          <div className="py-4">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Device Information
            </h2>
            <p className="py-1 text-sm font-normal leading-normal text-gray-600">
              현재 프로젝트에 등록할 디바이스의 정보를 입력 후 시스템 어드민에게
              승인 요청할 수 있습니다.
            </p>
          </div>
          <ProjectDeviceInfoForm />
        </ErrorBoundaryWrapper>
      ) : (
        <AccessDenied />
      )}
    </ErrorBoundaryWrapper>
  );
};

export default ProjectAddDevice;
