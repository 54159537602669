import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  TestResultDetails,
  TestResultLabel,
} from '@customTypes/testResult/type';
import clsx from 'clsx';

import React from 'react';

type Props = {
  resultDetails: TestResultDetails[];
  handleClick: (item: string) => void;
  activeSection: string;
};

const TestCaseResultListSidebar = ({
  resultDetails,
  handleClick,
  activeSection,
}: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <aside className="fixed inset-y-16 right-0 hidden w-96 overflow-y-auto overflow-x-hidden border-l border-gray-200 px-4 py-1 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full sm:px-6 lg:px-8 xl:block">
        <div className="mb-4 border-b border-gray-200 bg-white py-5">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Test Case Result List
          </h3>
        </div>
        <div className="flow-root">
          <ErrorBoundaryWrapper>
            <ul role="list" className="space-y-6">
              {resultDetails?.map((resultDetail, index) => (
                <li key={resultDetail.seq} className="relative flex gap-x-4">
                  <div
                    className={clsx(
                      'absolute left-0 top-0 flex w-6 justify-center',
                      index === resultDetails?.length - 1 ? 'h-6' : '-bottom-6',
                    )}
                  >
                    <div className="w-px bg-gray-200" />
                  </div>
                  <div className="relative flex size-6 flex-none items-center justify-center bg-white">
                    <div
                      className={clsx(
                        'size-1.5 rounded-full ring-1 ring-gray-300',
                        resultDetail.result === TestResultLabel.Pass
                          ? 'bg-green-500'
                          : resultDetail.result === TestResultLabel.Fail
                            ? 'bg-red-500'
                            : 'bg-gray-500',
                      )}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => handleClick(resultDetail.title)}
                    className={clsx(
                      'flex-auto py-0.5 text-start text-xs leading-5 text-gray-900 hover:font-semibold',
                      activeSection === resultDetail.title
                        ? 'font-bold'
                        : 'font-medium',
                    )}
                  >
                    {resultDetail.title}
                  </button>
                </li>
              ))}
            </ul>
          </ErrorBoundaryWrapper>
        </div>
      </aside>
    </ErrorBoundaryWrapper>
  );
};

export default TestCaseResultListSidebar;
