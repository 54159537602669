import { useMobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';
import React, { useState } from 'react';
import LandScapeTestStepListButton from './LandScapeTestStepListButton';
import TestStepListDialog from './TestStepListContainer/TestStepListDialog';
import TestStepList from './TestStepListContainer/TestStepList';
import SpeedTestStepList from './TestStepListContainer/SpeedTestStepList';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const TestStepListContainer = () => {
  const { isLandscapeMode } = useMobileTestStepContext();
  const [isTooltipOpened, setIsTooltipOpened] = useState(false);
  const [isTestStepListOpened, setIsTestStepListOpened] = useState(false);

  const isUiTestPath = isWordInLocationPathname('ui-test');
  const isSpeedTestPath = isWordInLocationPathname('speed-test');

  if (isLandscapeMode) {
    return (
      <ErrorBoundaryWrapper>
        <LandScapeTestStepListButton
          isTestStepListOpened={isTestStepListOpened}
          setIsTestStepListOpened={setIsTestStepListOpened}
          isTooltipOpened={isTooltipOpened}
          setIsTooltipOpened={setIsTooltipOpened}
        />
        <TestStepListDialog
          isOpen={isTestStepListOpened}
          setIsOpen={setIsTestStepListOpened}
        />
      </ErrorBoundaryWrapper>
    );
  }

  if (isUiTestPath) {
    return <TestStepList />;
  }

  if (isSpeedTestPath) {
    return <SpeedTestStepList />;
  }

  return null;
};

export default TestStepListContainer;
