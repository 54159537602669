import {
  ErrorBoundaryWrapper,
  HeadingLevelTwoWithUnderline,
  TableData,
} from '@autosquare/common';

import React from 'react';

import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import { TestResult } from '@customTypes/testResult/type';
import {
  DeviceInfoOs,
  DeviceInfoOsFullName,
} from '@customTypes/ide/device/device';

type Props = { testResult: TestResult };

const Information = ({ testResult }: Props) => {
  const tableHeaders = isWordInLocationPathname('mobile')
    ? ['Tester', 'DateTime', 'OS', 'Device', 'Title', 'App Version', 'Type']
    : ['Tester', 'DateTime', 'Browser', 'Title', 'Type'];

  return (
    <ErrorBoundaryWrapper>
      <section aria-labelledby="information">
        <HeadingLevelTwoWithUnderline>Information</HeadingLevelTwoWithUnderline>
        <div className="-mx-4 w-full">
          <table className="mx-4 min-w-full divide-y divide-gray-200 py-4">
            <thead className="bg-gray-50">
              <tr>
                {tableHeaders?.map((tableHeader, idx) => (
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold"
                    key={idx}
                  >
                    {tableHeader}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isWordInLocationPathname('mobile') ? (
                <tr>
                  <TableData padding="sm">{testResult.tester}</TableData>
                  <TableData padding="sm">
                    {testResult.startTime} ~ {testResult.endTime.slice(11)}
                  </TableData>
                  <TableData padding="sm">
                    {testResult.mobileOs === DeviceInfoOs.Aos
                      ? DeviceInfoOsFullName.Aos
                      : DeviceInfoOsFullName.Ios}
                  </TableData>
                  <TableData padding="sm">{testResult.device}</TableData>
                  <TableData padding="sm">{testResult.scenarioName}</TableData>
                  <TableData padding="sm">
                    {testResult.appVersion === null
                      ? '-'
                      : testResult.appVersion}
                  </TableData>
                  <TableData padding="sm">{testResult.type}</TableData>
                </tr>
              ) : (
                <tr>
                  <TableData padding="sm">{testResult.tester}</TableData>
                  <TableData padding="sm">
                    {testResult.startTime} ~ {testResult.endTime.slice(11)}
                  </TableData>
                  <TableData padding="sm">
                    <p>
                      {testResult.browserName[0].toUpperCase() +
                        testResult.browserName.slice(1)}
                    </p>
                    <p>{testResult.browserVersion}</p>
                  </TableData>
                  <TableData padding="sm">{testResult.scenarioName}</TableData>
                  <TableData padding="sm">{testResult.type}</TableData>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
    </ErrorBoundaryWrapper>
  );
};

export default Information;
