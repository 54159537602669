import {
  ErrorBoundaryWrapper,
  SectionTitleContainer,
  SectionTitleHeader,
  SectionTitleHeaderContainer,
} from '@autosquare/common';
import React from 'react';
import ReservationTimeTable from './ReservationTime/ReservationTimeTable';

const ReservationTime = () => {
  return (
    <ErrorBoundaryWrapper>
      <SectionTitleContainer>
        <SectionTitleHeaderContainer>
          <SectionTitleHeader>Reservation Time</SectionTitleHeader>
        </SectionTitleHeaderContainer>
      </SectionTitleContainer>
      <div className="mt-6">
        <ReservationTimeTable />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default ReservationTime;
