import clsx from 'clsx';
import React, { Fragment } from 'react';

type Props = {
  subTitle: string;
  description?: string;
  isSubCategory?: boolean;
};

const OverviewSubTitle = ({
  subTitle,
  description,
  isSubCategory = false,
}: Props) => {
  const subTitleArr = [{ title: subTitle }];

  return (
    <div
      className={clsx(
        'flex items-center space-x-2 pb-3',
        isSubCategory && ' border-b border-gray-300 pb-2',
      )}
    >
      {subTitleArr.map((item) => (
        <Fragment key={item.title}>
          <p
            className={clsx(
              'text-sm leading-6 text-gray-900',
              isSubCategory ? 'font-medium' : 'font-semibold ',
            )}
          >
            {item.title}
          </p>
          <svg
            className="size-5 shrink-0 text-gray-500"
            fill="currentColor"
            viewBox="0 0 20 20"
            aria-hidden="true"
          >
            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
          </svg>
        </Fragment>
      ))}
      <p className="truncate text-sm text-gray-500">{description}</p>
    </div>
  );
};

export default OverviewSubTitle;
