import { ListBoxHeadlessInController } from '@autosquare/common';

import {
  dateFormatDayList,
  dateFormatMonthList,
  dateFormatSeparatorList,
  dateFormatYearList,
  findDateFormatDayNameByServerName,
  findDateFormatMonthNameByServerName,
  findDateFormatSeparatorNameByServerName,
  findDateFormatYearNameByServerName,
} from '@utils/static/mobileTestStep/textReplace/dateFormatList';

import DefinitionList from '@components/shared/DefinitionList';

import {
  TextReplaceOptions,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import {
  DayServerName,
  MonthServerName,
  SeparatorServerName,
  YearServerName,
} from '@customTypes/ide/mobileTestStep/textReplace/textReplace';

import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

type Props = {
  textReplaceOptions: TextReplaceOptions;
};

const DateFormat = ({ textReplaceOptions }: Props) => {
  const {
    control,
    watch,
    setValue,
    clearErrors,
    setError,
    resetField,
    formState: { errors },
  } = useFormContext<UpdateTestStepData>();

  const [year, setYear] = useState<YearServerName>(YearServerName.None);
  const [month, setMonth] = useState<MonthServerName>(MonthServerName.None);
  const [day, setDay] = useState<DayServerName>(DayServerName.None);

  const parseDateString = (dateString: string) => {
    const regex = /(Y+|M+|D+)/g;
    const matches = dateString?.match(regex);

    let year = '';
    let month = '';
    let day = '';

    matches?.forEach((match) => {
      if (match.includes('Y')) {
        year = match;
      } else if (match.includes('M')) {
        month = match;
      } else if (match.includes('D')) {
        day = match;
      }
    });

    return { year, month, day };
  };

  const parseDate = parseDateString(textReplaceOptions?.dateFormat);
  useEffect(() => {
    if (parseDate?.year) {
      setYear(parseDate?.year as YearServerName);
    }
  }, [parseDate?.year]);

  useEffect(() => {
    if (parseDate?.month) {
      setMonth(parseDate?.month as MonthServerName);
    }
  }, [parseDate?.month]);

  useEffect(() => {
    if (parseDate?.day) {
      setDay(parseDate?.day as DayServerName);
    }
  }, [parseDate?.day]);

  const separator = watch('optionsDto.textReplaceOptions.separator');
  const separatorName = findDateFormatSeparatorNameByServerName(separator);

  useEffect(() => {
    if (textReplaceOptions?.separator) {
      setValue(
        'optionsDto.textReplaceOptions.separator',
        textReplaceOptions?.separator,
      );
    } else {
      setValue(
        'optionsDto.textReplaceOptions.separator',
        SeparatorServerName.None,
      );
    }
  }, [setValue, resetField, textReplaceOptions?.separator]);

  useEffect(() => {
    const dateFormat =
      separator === SeparatorServerName.None
        ? `${year}${month}${day}`
        : separator === SeparatorServerName.Space
          ? `${year}${(year && month) || (year && day) ? ' ' : ''}${month}${month && day ? ' ' : ''}${day}`
          : `${year}${(year && month) || (year && day) ? separatorName : ''}${month}${month && day ? separatorName : ''}${day}`;

    setValue('optionsDto.textReplaceOptions.dateFormat', dateFormat);

    if (
      year !== YearServerName.None ||
      month !== MonthServerName.None ||
      day !== DayServerName.None
    ) {
      clearErrors('optionsDto.textReplaceOptions.dateFormat');
    } else {
      setError('optionsDto.textReplaceOptions.dateFormat', {
        type: 'manual',
        message: 'Date Format의 년, 월, 일 중 1가지 이상 선택해 주세요.',
      });
    }

    return () => {
      clearErrors('optionsDto.textReplaceOptions.dateFormat');
    };
  }, [setValue, setError, clearErrors, year, month, day, separator]);

  const dateFormatList = [
    {
      idx: 1,
      listbox: (
        <ListBoxHeadlessInController
          value={year}
          buttonValue={findDateFormatYearNameByServerName(year)}
          onChange={setYear}
          lists={dateFormatYearList}
          valueToSave={'serverName'}
          valueToShow={'name'}
        />
      ),
    },
    {
      idx: 2,
      listbox: (
        <ListBoxHeadlessInController
          value={month}
          buttonValue={findDateFormatMonthNameByServerName(month)}
          onChange={setMonth}
          lists={dateFormatMonthList}
          valueToSave={'serverName'}
          valueToShow={'name'}
        />
      ),
    },
    {
      idx: 3,
      listbox: (
        <ListBoxHeadlessInController
          value={day}
          buttonValue={findDateFormatDayNameByServerName(day)}
          onChange={setDay}
          lists={dateFormatDayList}
          valueToSave={'serverName'}
          valueToShow={'name'}
        />
      ),
    },
    {
      idx: 4,
      listbox: (
        <Controller
          control={control}
          name="optionsDto.textReplaceOptions.separator"
          render={({ field }) => (
            <ListBoxHeadlessInController
              value={field.value}
              buttonValue={findDateFormatSeparatorNameByServerName(
                field.value || SeparatorServerName.None,
              )}
              onChange={field.onChange}
              lists={dateFormatSeparatorList}
              valueToSave={'serverName'}
              valueToShow={'name'}
            />
          )}
        />
      ),
    },
  ];

  return (
    <DefinitionList
      term={'Date Format*'}
      description={
        <div className="grid grid-cols-4 items-center gap-2">
          {dateFormatList.map((dateFormat) => (
            <div className="col-span-1" key={dateFormat.idx}>
              {dateFormat.listbox}
            </div>
          ))}
          <ErrorMessage
            name="optionsDto.textReplaceOptions.dateFormat"
            errors={errors}
            render={({ message }) => (
              <p className="standard-error-message col-span-4">{message}</p>
            )}
          />
        </div>
      }
    />
  );
};

export default DateFormat;
