import React from 'react';
import { MessengerAlarmType } from '@customTypes/dashboard/admin/type';
import { ErrorBoundaryWrapper } from '@autosquare/common';
type Props = {
  platformTitle: string;
  messengerData: MessengerAlarmType;
};
const Messenger01Detail = ({ platformTitle, messengerData }: Props) => {
  const recipientList = messengerData.recipientList?.map((item) =>
    item.replace(/\//g, ' / '),
  );

  const detailList = [
    { title: 'Platform', content: platformTitle },
    { title: 'Request URL', content: messengerData?.requestUrl },
    { title: 'Office Token', content: messengerData?.officeToken },
  ];

  return (
    <ErrorBoundaryWrapper>
      <dl className="grid grid-cols-2 gap-y-6 py-6 text-sm leading-normal text-gray-900">
        {detailList.map((detail) => (
          <React.Fragment key={detail.title}>
            <dt className="col-span-1 font-medium">{detail.title}</dt>
            <dd className="col-span-1 font-medium leading-normal">
              {detail.content}
            </dd>
          </React.Fragment>
        ))}
      </dl>
      <div className="text-sm leading-normal text-gray-900">
        <div className="pb-5 font-medium">Recipient List</div>
        <div className="flex flex-col items-start justify-center gap-y-3 rounded-md border border-gray-300 p-3 text-sm font-medium leading-tight text-black">
          {recipientList?.map((item, index) => (
            <div className="flex gap-x-3 py-1.5" key={item}>
              <div className="px-3">{index + 1}</div>
              <div>{item}</div>
            </div>
          ))}
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default Messenger01Detail;
