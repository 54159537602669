import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  UpdateWebTestStepData,
  UpdateWebTestStepDataKeys,
} from '@customTypes/testStep/type';
import React from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  name: UpdateWebTestStepDataKeys;
  radioOptions: {
    title: string;
    id: string;
    defaultChecked: boolean;
    onClick?: () => void;
  }[];
};

const WebTestStepConfirmInputRadio = ({ name, radioOptions }: Props) => {
  const { register } = useFormContext<UpdateWebTestStepData>();

  return (
    <ErrorBoundaryWrapper>
      <fieldset>
        <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
          {radioOptions.map((radioOption) => (
            <div key={radioOption.id} className="flex items-center">
              <input
                id={radioOption.id}
                name={radioOption.id}
                type="radio"
                defaultChecked={radioOption.defaultChecked}
                onClick={radioOption.onClick}
                value={radioOption.id}
                className="input-radio-congress-blue"
                {...register(name)}
              />
              <label
                htmlFor={radioOption.id}
                className="ml-3 block cursor-pointer text-sm font-medium leading-6 text-gray-900"
              >
                {radioOption.title}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </ErrorBoundaryWrapper>
  );
};

export default WebTestStepConfirmInputRadio;
