import React from 'react';
import { useLocation } from 'react-router-dom';
import ProjectMembersTable from './components/ProjectMembersTable';

import {
  ErrorMessage,
  PageHeadingButtons,
  PagingHeadings,
  TableSkeleton,
  ErrorBoundaryWrapper,
  RoleAuth,
} from '@autosquare/common';
import { useAuthUserInfo } from '@hooks/index';
import { useProjectMemberListQuery } from '@lib/api/dashboard/queryHooks';

const ProjectMembersList = () => {
  const { pathname } = useLocation();

  const {
    data: members,
    isError,
    error,
    isLoading,
    isFetching,
  } = useProjectMemberListQuery();

  const { userProjectRole, userAuthRole } = useAuthUserInfo();

  const breadcrumbs = [
    { title: 'Project Settings', href: pathname },
    { title: 'Members', href: pathname },
    { title: 'List', href: pathname },
  ];

  const pageHeadingButtons = (): PageHeadingButtons[] => {
    if (isLoading || isFetching) {
      return;
    }
    if (userProjectRole === 0 || userAuthRole === RoleAuth.Admin) {
      return [
        {
          title: 'Add',
          href: `add-member`,
          type: 'button',
          variant: 'primary',
        },
      ];
    }
    return;
  };

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={'Member List'}
        pageHeadingButtons={pageHeadingButtons()}
      />
      {isLoading || isFetching ? (
        <TableSkeleton />
      ) : isError ? (
        <ErrorMessage>{error.message}</ErrorMessage>
      ) : (
        <ProjectMembersTable members={members} />
      )}
    </ErrorBoundaryWrapper>
  );
};

export default ProjectMembersList;
