import {
  UseCheckboxAttribute,
  Detail,
  Execution,
  TableBodyBasic,
  TableHeader,
  Checkbox,
  TableWrapper,
  Time,
  ScenarioListDetailData,
  ErrorBoundaryWrapper,
  ArrowSpinner,
} from '@autosquare/common';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TestExecutionContainer from '@components/TestExecution/TestExecutionContainer';
import { timePattern } from '@utils/static/timePattern';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import NoListResultMessage from '@components/Search/NoListResultMessage';
import RenderLoadingOrError from '@components/shared/RenderLoadingOrError';
import { UseQueryResult } from '@tanstack/react-query';
import {
  useRemoteMobileTestExecutionMutation,
  useRemoteWebTestExecutionMutation,
} from '@lib/api/ide/remote/remote';
import RemoteTestExecutionAlarmModal from '@components/TestExecution/shared/RemoteTestExecutionAlarmModal';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';

interface Props extends UseCheckboxAttribute {
  mobileListQuery: UseQueryResult<ScenarioListDetailData[], Error>;
  webListQuery: UseQueryResult<ScenarioListDetailData[], Error>;
}

const ScenarioListTable = ({
  selectAllArray,
  selectArray,
  selectedArray,
  openDialog,
  mobileListQuery,
  webListQuery,
}: Props) => {
  const navigate = useNavigate();
  const mobileLocation = isWordInLocationPathname('mobile');

  const [isTestExecutionOpened, setIsTestExecutionOpened] = useState(false);
  const [testScenarioIdx, setTestScenarioIdx] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExecutionErrorModalOpen, setIsExecutionErrorModalOpen] =
    useState(false);

  const filteredTestScenarioList = mobileLocation
    ? mobileListQuery.data
    : webListQuery.data;

  const disabledOption = mobileLocation
    ? mobileListQuery.isLoading ||
      mobileListQuery.isFetching ||
      mobileListQuery.isError
    : webListQuery.isLoading || webListQuery.isFetching || webListQuery.isError;

  const remoteMobileScenarioExecutionMutation =
    useRemoteMobileTestExecutionMutation('scenario');
  const remoteWebScenarioExecutionMutation =
    useRemoteWebTestExecutionMutation('scenario');

  const tableHeaderList = [
    {
      idx: 1,
      title: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={(e) => selectAllArray(e)}
          key="selected-checkbox"
          disabled={disabledOption}
        />
      ),
    },
    {
      idx: 2,
      title: selectedArray.length > 0 ? <div className="size-5" /> : 'No',
    },
    {
      idx: 3,
      title: selectedArray.length > 0 ? <div className="size-5" /> : 'Title',
    },
    { idx: 4, title: 'Test Case Count' },
    { idx: 5, title: 'Creator' },
    { idx: 6, title: 'Created at', isSortable: true },
    { idx: 7, title: '' },
    { idx: 8, title: '' },
  ];

  return (
    <ErrorBoundaryWrapper>
      <TableWrapper selectedArray={selectedArray} openDialog={openDialog}>
        <thead>
          <tr>
            {tableHeaderList.map((tableHeader) => (
              <TableHeader
                key={tableHeader.idx}
                isSortable={tableHeader.isSortable}
              >
                {tableHeader.title}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {isWordInLocationPathname('mobile') && (
            <RenderLoadingOrError
              query={mobileListQuery}
              colSpan={tableHeaderList.length}
            />
          )}
          {isWordInLocationPathname('web') && (
            <RenderLoadingOrError
              query={webListQuery}
              colSpan={tableHeaderList.length}
            />
          )}

          {filteredTestScenarioList?.length === 0 ? (
            <NoListResultMessage
              colSpan={tableHeaderList.length}
              testType={'scenario'}
            />
          ) : (
            filteredTestScenarioList?.map((scenario, idx) => (
              <tr key={scenario.idx}>
                <TableBodyBasic>
                  <Checkbox
                    checked={selectedArray.includes(scenario.idx)}
                    onChange={(e) => selectArray(scenario.idx, e)}
                  />
                </TableBodyBasic>
                <TableBodyBasic>{idx + 1}</TableBodyBasic>
                <TableBodyBasic>
                  <div className="font-medium text-gray-900">
                    {scenario.title}
                  </div>
                  <div className="mt-1 text-gray-500">
                    {scenario.description}
                  </div>
                </TableBodyBasic>
                <TableBodyBasic>{scenario.caseCount}</TableBodyBasic>
                <TableBodyBasic>{scenario.creator}</TableBodyBasic>
                <TableBodyBasic>
                  <div>{scenario.createdAt?.split(' ')[0] ?? '-'}</div>
                  <div
                    className={`mt-1 flex items-center justify-start gap-2 text-gray-500`}
                  >
                    {scenario.createdAt && <Time />}
                    {scenario.createdAt?.match(timePattern)[0]}
                  </div>
                </TableBodyBasic>
                <TableBodyBasic>
                  <Execution
                    testType="scenario"
                    tooltipLocation="right"
                    disabled={scenario.caseCount === 0}
                    onClick={() => {
                      if (scenario.caseCount > 0) {
                        setTestScenarioIdx(scenario.idx);
                        setIsTestExecutionOpened(true);
                      }
                    }}
                  />
                </TableBodyBasic>
                <TableBodyBasic>
                  <Detail
                    onClick={() =>
                      navigate({
                        pathname: 'detail',
                        search: `idx=${scenario.idx}`,
                      })
                    }
                  />
                </TableBodyBasic>
              </tr>
            ))
          )}
        </tbody>
      </TableWrapper>
      {isTestExecutionOpened && (
        <TestExecutionContainer
          setState={setIsTestExecutionOpened}
          idx={testScenarioIdx}
          testType={'scenario'}
          deviceType={mobileLocation ? 'mobile' : 'web'}
          setIsModalOpen={setIsModalOpen}
          remoteMobileExecutionMutation={remoteMobileScenarioExecutionMutation}
          remoteWebExecutionMutation={remoteWebScenarioExecutionMutation}
          setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
        />
      )}
      {(remoteMobileScenarioExecutionMutation.isLoading ||
        remoteWebScenarioExecutionMutation.isLoading) && <ArrowSpinner />}
      <RemoteTestExecutionAlarmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isExecutionErrorModalOpen={isExecutionErrorModalOpen}
        setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
      />
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default ScenarioListTable;
