import {
  Dispatch,
  KeyboardEvent,
  MutableRefObject,
  SetStateAction,
} from 'react';

import { AdminUserData } from '@customTypes/dashboard/admin/type';
import { ProjectMemberList } from '@customTypes/dashboard/project/type';
import { MailReceiver } from '@autosquare/common';

type EmailListState = {
  temporarySavedList: MailReceiver;
  projectAdminList: MailReceiver[];
  selectedIndex: number;
  filteredPeople: AdminUserData[];
};

type ProjectDetailEmailListState = {
  temporarySavedList: MailReceiver;
  projectAdminList: MailReceiver[];
  selectedIndex: number;
  filteredPeople: ProjectMemberList[];
};

type EmailListActions = {
  setIsOpenedMemberList: Dispatch<SetStateAction<boolean>>;
  setProjectAdminList: Dispatch<SetStateAction<MailReceiver[]>>;
  setProjectAdminInput: Dispatch<SetStateAction<string>>;
  setSelectedIndex: Dispatch<SetStateAction<number>>;
  setTemporarySavedEmailAddress: Dispatch<SetStateAction<MailReceiver>>;
};

type EmailListRefs = {
  inputRef: MutableRefObject<HTMLInputElement | null>;
};

export const handleEmailListOnKeyDown = (
  e: KeyboardEvent<HTMLInputElement> | KeyboardEvent<HTMLButtonElement>,
  state: EmailListState,
  actions: EmailListActions,
  refs: EmailListRefs,
) => {
  const {
    temporarySavedList,
    projectAdminList,
    selectedIndex,
    filteredPeople,
  } = state;

  const {
    setIsOpenedMemberList,
    setProjectAdminList,
    setProjectAdminInput,
    setSelectedIndex,
    setTemporarySavedEmailAddress,
  } = actions;

  const { inputRef } = refs;

  let newSelectedIndex: number;

  if (/[ㄱ-ㅎ가-힣a-zA-Z0-9]/g.test(e.key)) {
    setIsOpenedMemberList(true);
  }

  if (e.key === 'Enter') {
    e.preventDefault();
    if (temporarySavedList?.email !== '' && temporarySavedList?.name !== '') {
      setProjectAdminList([
        ...projectAdminList,
        {
          email: temporarySavedList?.email,
          name: temporarySavedList?.name,
        },
      ]);
      setProjectAdminInput('');
      if (inputRef.current) inputRef.current.value = '';
      setSelectedIndex(-1);
      setIsOpenedMemberList(false);
      setTemporarySavedEmailAddress({ email: '', name: '' });
    }
  }

  if (e.key === 'Escape') {
    e.preventDefault();
    setIsOpenedMemberList(false);
    setProjectAdminInput('');
    if (inputRef.current) inputRef.current.value = '';
    setSelectedIndex(-1);
    setTemporarySavedEmailAddress({ email: '', name: '' });
  }

  const arrowKeyBasic = () => {
    e.preventDefault();
    setIsOpenedMemberList(true);
    setSelectedIndex(newSelectedIndex);
    if (inputRef.current) {
      inputRef.current.value =
        filteredPeople[newSelectedIndex]?.email ?? inputRef.current.value;
    }
    setTemporarySavedEmailAddress({
      email: filteredPeople[newSelectedIndex]?.email ?? '',
      name: filteredPeople[newSelectedIndex]?.name ?? '',
    });
  };

  if (e.key === 'ArrowUp') {
    newSelectedIndex =
      selectedIndex > 0 ? selectedIndex - 1 : filteredPeople?.length - 1;
    arrowKeyBasic();
  }

  if (e.key === 'ArrowDown') {
    newSelectedIndex =
      selectedIndex < filteredPeople?.length - 1 ? selectedIndex + 1 : 0;
    arrowKeyBasic();
  }
};

// project settings 내 디테일에서 사용하는 함수
export const handleEmailListOnKeyDownInProjectDetail = (
  e: KeyboardEvent<HTMLInputElement> | KeyboardEvent<HTMLButtonElement>,
  state: ProjectDetailEmailListState,
  actions: EmailListActions,
  refs: EmailListRefs,
) => {
  const {
    temporarySavedList,
    projectAdminList,
    selectedIndex,
    filteredPeople,
  } = state;

  const {
    setIsOpenedMemberList,
    setProjectAdminList,
    setProjectAdminInput,
    setSelectedIndex,
    setTemporarySavedEmailAddress,
  } = actions;

  const { inputRef } = refs;

  let newSelectedIndex: number;

  if (/[ㄱ-ㅎ가-힣a-zA-Z0-9]/g.test(e.key)) {
    setIsOpenedMemberList(true);
  }

  if (e.key === 'Enter') {
    e.preventDefault();
    if (temporarySavedList?.email !== '' && temporarySavedList?.name !== '') {
      setProjectAdminList([
        ...projectAdminList,
        {
          email: temporarySavedList?.email,
          name: temporarySavedList?.name,
        },
      ]);
      setProjectAdminInput('');
      if (inputRef.current) inputRef.current.value = '';
      setSelectedIndex(-1);
      setIsOpenedMemberList(false);
      setTemporarySavedEmailAddress({ email: '', name: '' });
    }
  }

  if (e.key === 'Escape') {
    e.preventDefault();
    setIsOpenedMemberList(false);
    setProjectAdminInput('');
    if (inputRef.current) inputRef.current.value = '';
    setSelectedIndex(-1);
    setTemporarySavedEmailAddress({ email: '', name: '' });
  }

  const arrowKeyBasic = () => {
    e.preventDefault();
    setIsOpenedMemberList(true);
    setSelectedIndex(newSelectedIndex);
    if (inputRef.current) {
      inputRef.current.value =
        filteredPeople[newSelectedIndex]?.userId ?? inputRef.current.value;
    }
    setTemporarySavedEmailAddress({
      email: filteredPeople[newSelectedIndex]?.userId ?? '',
      name: filteredPeople[newSelectedIndex]?.name ?? '',
    });
  };

  if (e.key === 'ArrowUp') {
    newSelectedIndex =
      selectedIndex > 0 ? selectedIndex - 1 : filteredPeople?.length - 1;
    arrowKeyBasic();
  }

  if (e.key === 'ArrowDown') {
    newSelectedIndex =
      selectedIndex < filteredPeople?.length - 1 ? selectedIndex + 1 : 0;
    arrowKeyBasic();
  }
};
