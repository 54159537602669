import { ErrorBoundaryWrapper } from '@autosquare/common';
import { useProjectSettingDetailQuery } from '@lib/api/dashboard/queryHooks';
import { findAutoRegisterPlatformName } from '@utils/static/autoRegister/autoRegisterTypeList';
import React from 'react';

const DetailAutoRegister = () => {
  const { data: projectSettingDetail } = useProjectSettingDetailQuery();

  const registers = projectSettingDetail.registers;

  if (registers.length === 0) {
    return;
  }

  return (
    <ErrorBoundaryWrapper>
      <section className="pt-5">
        <h2 className="border-b border-solid border-gray-200 pb-5">
          AutoRegister
        </h2>
        <div className="grid grid-cols-4 py-4">
          <p className="font-medium">Platform</p>
          <div className="flex flex-wrap items-center gap-4">
            {registers.map((register) => (
              <p
                key={register.platform}
                className="rounded-md bg-gray-100 px-3 py-1.5"
              >
                {findAutoRegisterPlatformName(register.platform)}
              </p>
            ))}
          </div>
        </div>
      </section>
    </ErrorBoundaryWrapper>
  );
};

export default DetailAutoRegister;
