import { ErrorBoundaryWrapper } from '@autosquare/common';
import React from 'react';
import SelectApplicationOsListbox from './SelectApplication/SelectApplicationOsListbox';
import SelectApplicationPackageName from './SelectApplication/SelectApplicationPackageName';
import SelectApplicationAppVersion from './SelectApplication/SelectApplicationAppVersion';
import SelectApplicationBuildVersion from './SelectApplication/SelectApplicationBuildVersion';
import SelectApplicationHeader from './SelectApplication/SelectApplicationHeader';

const SelectApplication = () => {
  return (
    <>
      <SelectApplicationHeader />
      <ErrorBoundaryWrapper>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-4">
          <SelectApplicationOsListbox />
          <SelectApplicationPackageName />
          <SelectApplicationAppVersion />
          <SelectApplicationBuildVersion />
        </div>
      </ErrorBoundaryWrapper>
    </>
  );
};

export default SelectApplication;
