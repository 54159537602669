import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import {
  CreateSpeedSchedulerData,
  SpeedSchedulerTypeName,
} from '@customTypes/ide/speedScheduler/speedScheduler';

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import { useProjectDetailQuery } from '@lib/api/ide/project/project';
import { checkIsProjectFeatureIncluded } from '@utils/static/checkProjectFeature';
import {
  ProjectListDetailFeaturesCategory,
  ProjectListDetailFeaturesFeature,
} from '@customTypes/index';
import clsx from 'clsx';
const CreateSpeedTestSchedulerTestType = () => {
  const { data: projectDetail } = useProjectDetailQuery();

  const { register, setValue } = useFormContext<CreateSpeedSchedulerData>();

  // mobile 기능 활성화 여부
  const isMobileSettings = checkIsProjectFeatureIncluded(
    projectDetail,
    ProjectListDetailFeaturesCategory.Mobile,
    ProjectListDetailFeaturesFeature.Speed,
  );

  // web 기능 활성화 여부
  const isWebSettings = checkIsProjectFeatureIncluded(
    projectDetail,
    ProjectListDetailFeaturesCategory.Web,
    ProjectListDetailFeaturesFeature.Speed,
  );

  // 기능에 따른 테스트 타입 초기 설정 설정
  useEffect(() => {
    if (isMobileSettings) {
      setValue('type', SpeedSchedulerTypeName.Mobile);
    }
    if (!isMobileSettings && isWebSettings) {
      setValue('type', SpeedSchedulerTypeName.Web);
    }
  }, [isMobileSettings, isWebSettings, setValue]);

  const testTypeList = [
    { name: SpeedSchedulerTypeName.Mobile, disabled: !isMobileSettings },
    { name: SpeedSchedulerTypeName.Web, disabled: !isWebSettings },
  ];

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer title="Test Type">
        <fieldset className="flex items-center gap-10">
          {testTypeList.map((testType) => (
            <label
              key={testType.name}
              className={clsx(
                'flex items-center gap-3',
                testType.disabled
                  ? 'cursor-default text-gray-300'
                  : 'cursor-pointer text-gray-900',
              )}
            >
              <input
                type="radio"
                value={testType.name}
                className="input-radio-congress-blue disabled:bg-gray-100"
                {...register('type')}
                disabled={testType.disabled}
              />
              {testType.name}
            </label>
          ))}
        </fieldset>
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default CreateSpeedTestSchedulerTestType;
