import { Button, ErrorBoundaryWrapper } from '@autosquare/common';
import { CreateTestDataAutomation } from '@customTypes/testCase/type';
import { useCreateSpeedTestDataAutomationMutation } from '@lib/api/ide/speedMobile/mobileTestAutomation';
import React, { useState } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useCreateTestDataAutomationForm } from '@hooks/index';
import RegisterInformation from '@components/TestAutomationSettings/common/RegisterInformation';
import RegisterTestData from '@components/TestAutomationSettings/common/RegisterTestData';
import AutomationOrderBy from '@components/TestAutomationSettings/common/AutomationOrderBy';
import { DialogButton } from '@components/Dialog/DialogComponents';
import { DialogButtonPanels } from '@components/Dialog/DialogComponents';
import { CommonDialog } from '@components/Dialog';

type Props = {
  testCaseDetailPage: string;
};

const AutomationRegisterComponent = ({ testCaseDetailPage }: Props) => {
  const navigate = useNavigate();

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const methods = useCreateTestDataAutomationForm();

  const createTestDataAutomation = useCreateSpeedTestDataAutomationMutation();

  const onSubmit: SubmitHandler<CreateTestDataAutomation> = (data) => {
    const updatedData = { ...data, filePath: data.file.path };
    delete updatedData.file;

    createTestDataAutomation.mutate(updatedData, {
      onError: () => {
        setIsErrorModalOpen(true);
      },
    });
  };

  return (
    <ErrorBoundaryWrapper>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="mt-8 space-y-2 pb-5 sm:flex-col sm:items-center sm:justify-center"
        >
          <RegisterInformation />
          <div className="flex flex-col gap-y-6 pt-4">
            <RegisterTestData />
            <AutomationOrderBy />
            <div className="flex items-center justify-end gap-x-2">
              <Button
                type="button"
                variant="secondary"
                onClick={() => navigate(testCaseDetailPage)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={createTestDataAutomation.isLoading}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
      <CommonDialog
        isOpen={isErrorModalOpen}
        setIsOpen={setIsErrorModalOpen}
        iconType="caution"
        title="Error"
        subTitle={
          createTestDataAutomation.isError &&
          createTestDataAutomation.error.message
        }
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            onClick={() => setIsErrorModalOpen(false)}
            variant="secondary"
          >
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default AutomationRegisterComponent;
