import React from 'react';
import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';
import { UpdateWebTestStepData } from '@customTypes/testStep/type';
import { ErrorMessage } from '@hookform/error-message';
import { useGetWebTestCaseDataAutomation } from '@lib/api/ide/web/webTestAutomation';
import { Controller, useFormContext } from 'react-hook-form';

const AutomationContents = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<UpdateWebTestStepData>();
  const { data: automationData } = useGetWebTestCaseDataAutomation();
  const rows = automationData?.table?.sheets?.[0].rows;
  const columnList = Object.keys(rows?.[0] || false)?.map((item) => {
    return { title: item || '-' };
  });

  const findColumnFormData = (value: string) =>
    columnList.find((column) => column?.title === value)?.title;

  return (
    <ErrorBoundaryWrapper>
      <Controller
        control={control}
        name="optionsDto.autoColumn"
        rules={{
          validate: (value) => {
            if (findColumnFormData(value) === undefined || value === '-') {
              return 'Test Data를 선택해 주세요.';
            }
          },
        }}
        render={({ field }) => (
          <ListBoxHeadlessInController
            value={field.value}
            buttonValue={
              field.value && findColumnFormData(field.value) === undefined
                ? 'None'
                : field.value
            }
            onChange={field.onChange}
            lists={columnList}
            valueToSave={'title'}
            valueToShow={'title'}
            placeholder={'Select Test Data'}
            noListMessage={'저장된 Test Data가 없습니다.'}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name="optionsDto.autoColumn"
        render={({ message }) => (
          <p className="standard-error-message -mt-2">{message}</p>
        )}
      />
    </ErrorBoundaryWrapper>
  );
};

export default AutomationContents;
