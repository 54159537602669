import { useState } from 'react';
import {
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

export const useScenarioDndHandler = <
  T extends { dndIndex?: number; idx?: number },
>(
  copyTestCases: T[],
  setCopyTestCases: React.Dispatch<React.SetStateAction<T[]>>,
) => {
  const [activeId, setActiveId] = useState<number>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const items = copyTestCases.map(({ dndIndex }) => dndIndex);

  const onDragEnd = (event) => {
    setActiveId(null);
    const { active, over } = event;

    if (active.id !== over?.id) {
      setCopyTestCases((items) => {
        const oldIndex = items.findIndex((item) => item.dndIndex === active.id);
        const newIndex = items.findIndex((item) => item.dndIndex === over?.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const onRemove = (id: number) => {
    setCopyTestCases((prevItems) =>
      prevItems.filter(({ dndIndex }) => dndIndex !== id),
    );
  };

  const onDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const overlayCopyTestCase = copyTestCases.find(
    (item) => item.dndIndex === activeId,
  );
  const testCaseIdxArray = copyTestCases.map(({ idx }) => idx);

  return {
    sensors,
    items,
    onDragEnd,
    onRemove,
    onDragStart,
    activeId,
    overlayCopyTestCase,
    testCaseIdxArray,
  };
};
