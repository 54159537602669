import { ErrorBoundaryWrapper } from '@autosquare/common';
import { CommonDialog } from '@components/Dialog';
import { MagnifyingGlassPlusIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';

type Props = {
  description: string;
};

const ApprovalDescription = ({ description }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <ErrorBoundaryWrapper>
      <button type="button" onClick={() => setIsDialogOpen(true)}>
        <MagnifyingGlassPlusIcon className="size-6" />
      </button>
      <CommonDialog
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        iconType={'checked'}
        title={'Confirm Approval Request '}
        subTitle={'승인 요청 사유를 확인할 수 있습니다.'}
      >
        <CommonDialog.DialogPanels>
          <p className="gray-scrollbar max-h-24 rounded-md border border-solid border-gray-300 px-3 py-1.5 text-sm font-normal leading-tight tracking-tight text-black">
            {description || '-'}
          </p>
        </CommonDialog.DialogPanels>
        <CommonDialog.DialogButtonPanels>
          <CommonDialog.DialogButton
            type="button"
            onClick={() => setIsDialogOpen(false)}
          >
            Close
          </CommonDialog.DialogButton>
        </CommonDialog.DialogButtonPanels>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default ApprovalDescription;
