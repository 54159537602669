import { ErrorBoundaryWrapper } from '@autosquare/common';
import DefinitionList from '@components/shared/DefinitionList';
import SwitchInController from '@components/shared/HeadlessUI/Switch/SwitchInController';
import { UpdateWebTestStepData } from '@customTypes/testStep/type';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const WebTestStepEditMaskingOptions = () => {
  const { control } = useFormContext<UpdateWebTestStepData>();

  return (
    <ErrorBoundaryWrapper>
      <DefinitionList
        term={'Masking Options'}
        description={
          <Controller
            control={control}
            name={'optionsDto.maskingOptions.enabled'}
            render={({ field }) => (
              <SwitchInController
                checked={field.value === true}
                onChange={field.onChange}
                size="sm"
                disabled={true}
              />
            )}
          />
        }
      />
    </ErrorBoundaryWrapper>
  );
};

export default WebTestStepEditMaskingOptions;
