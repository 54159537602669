import React, { Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  TableBodyBasic,
  TableBodyBasicNoData,
  TableHeader,
  TableRow,
} from '@autosquare/common';

import {
  MobileTestResultProperties,
  TestResultLabel,
} from '@customTypes/testResult/type';
import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

type Props = {
  tableHeaders: string[];
  mobileResults: MobileTestResultProperties[];
};

const MobileUITestResults = ({ tableHeaders, mobileResults }: Props) => {
  const { projectIdx } = useParams();
  const navigate = useNavigate();

  const subTitle = [{ title: 'Mobile' }];

  const detailPagePath = isWordInLocationPathname('ide')
    ? `/ide/projects/${projectIdx}/ui-test/mobile/test-result/detail`
    : `/projects/${projectIdx}/mobile/test-result/detail`;
  return (
    <div className="mt-8 flow-root">
      <div className="pb-3">
        <div className="flex items-center space-x-2">
          {subTitle.map((item) => (
            <Fragment key={item.title}>
              <p className="text-sm font-semibold leading-6 text-gray-900">
                {item.title}
              </p>
              <svg
                className="size-5 shrink-0 text-gray-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
            </Fragment>
          ))}
          <p className="truncate text-sm text-gray-500">
            최근 테스트 결과 목록
          </p>
        </div>
      </div>
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            {tableHeaders?.map((header) => (
              <TableHeader key={header}>{header}</TableHeader>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white">
          {mobileResults?.length === 0 ? (
            <TableBodyBasicNoData colSpan={tableHeaders?.length}>
              현재 조회되는 데이터가 없습니다.
            </TableBodyBasicNoData>
          ) : (
            mobileResults?.map((mobileResult, index) => (
              <TableRow key={mobileResult.idx}>
                <TableBodyBasic>{index + 1}</TableBodyBasic>
                <TableBodyBasic>{mobileResult.scenarioName}</TableBodyBasic>
                <TableBodyBasic>{mobileResult.startTime}</TableBodyBasic>
                <TableBodyBasic>{mobileResult.result}</TableBodyBasic>
                <TableBodyBasic>
                  <button
                    type="button"
                    className={clsx(
                      'hover:text-congress-blue rounded-md shadow-sm',
                      mobileResult.result === TestResultLabel.Running &&
                        'invisible',
                    )}
                    onClick={() =>
                      navigate({
                        pathname: detailPagePath,
                        search: `idx=${mobileResult.idx}`,
                      })
                    }
                  >
                    <DocumentMagnifyingGlassIcon className="size-6" />
                  </button>
                </TableBodyBasic>
              </TableRow>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default MobileUITestResults;
