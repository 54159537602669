import React from 'react';
import DeviceDetailFormFields from './DeviceDetailFormFields';
import { Controller, useFormContext } from 'react-hook-form';
import { DeviceType, UpdateProjectDeviceDetail } from '@customTypes/index';
import { ListBoxHeadlessInController } from '@autosquare/common';

const DeviceDetailType = () => {
  const { control, watch } = useFormContext<UpdateProjectDeviceDetail>();

  const isEnabled = watch('enabled');

  return (
    <DeviceDetailFormFields title={'Device Type'} name={'deviceType'}>
      <Controller
        control={control}
        name="deviceType"
        render={({ field }) => (
          <ListBoxHeadlessInController
            value={field.value || ''}
            buttonValue={field.value}
            onChange={field.onChange}
            lists={[{ title: DeviceType.Mobile }, { title: DeviceType.Tablet }]}
            valueToSave={'title'}
            valueToShow={'title'}
            placeholder={'Select Type'}
            disabled={!isEnabled}
            isGrayBackground={!isEnabled}
          />
        )}
      />
    </DeviceDetailFormFields>
  );
};

export default DeviceDetailType;
