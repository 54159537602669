import React from 'react';
import { useSearchParams } from 'react-router-dom';

type TestType = 'result';

type Props = {
  testType?: TestType;
};

const NoSpeedTestResultMessage = ({ testType = 'result' }: Props) => {
  const [searchParams] = useSearchParams();

  const requiredParams = ['page', 'size', 'projectIdx', 'order'];

  const hasExtraParams = Array.from(searchParams.keys()).some(
    (key) => !requiredParams.includes(key),
  );

  const noResultMessage: Record<TestType, string> = {
    result: '진행된 테스트 결과가 없습니다.',
  };

  return (
    <p className="pt-4 text-center text-sm text-gray-900">
      {hasExtraParams ? '검색된 결과가 없습니다.' : noResultMessage[testType]}
    </p>
  );
};

export default NoSpeedTestResultMessage;
