import { UpdateAdminProjectDetailData } from '@customTypes/dashboard/admin/type';
import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const EditMessengerSenderId = () => {
  const {
    register,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<UpdateAdminProjectDetailData>();

  const senderField = watch('messengers.0.sender');
  useEffect(() => {
    if (!senderField) {
      setError('messengers.0.sender', {
        message: '필수 입력 항목입니다.',
      });
      return;
    }
    clearErrors('messengers.0.sender');
  }, [senderField, setError, clearErrors]);

  return (
    <div className="mt-6 sm:space-y-0 sm:pb-0">
      <div className="flex items-center gap-x-3 py-5">
        <label className="text-sm font-medium leading-6 text-gray-900">
          Sender ID*
        </label>
        <p className="text-sm font-medium leading-tight text-gray-500">
          알림을 발신하는 대상자의 데이터를 입력해 주세요.
        </p>
      </div>
      <div className="mt-2">
        <input
          type="text"
          name={'senderId'}
          id={'senderId'}
          className="input-base"
          {...register('messengers.0.sender', {
            required: '필수 입력 항목입니다.',
          })}
        />
        <ErrorMessage
          errors={errors}
          name="messengers.0.sender"
          render={({ message }) => (
            <p className="error-message py-1.5">{message}</p>
          )}
        />
      </div>
    </div>
  );
};

export default EditMessengerSenderId;
