import { ErrorBoundaryWrapper } from '@autosquare/common';
import AllTestScenarioListInDraggableElement from '@components/Scheduler/AllTestScenarioListInDraggableElement';
import ScenarioDraggableElementContainer from '@components/Scheduler/ScenarioDraggableElementContainer';
import SchedulerTestScenarioListContainer from '@components/Scheduler/SchedulerTestScenarioListContainer';
import {
  CreateSpeedSchedulerData,
  SpeedSchedulerTypeName,
} from '@customTypes/ide/speedScheduler/speedScheduler';
import { ErrorMessage } from '@hookform/error-message';
import { useSpeedTestSchedulerDraggableSpace } from '@hooks/speedTestScheduler/useSpeedTestSchedulerDraggableSpace';

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import SchedulerTestScenarioList from './ScenarioDraggableSection/SchedulerTestScenarioList';

const ScenarioDraggableSection = () => {
  const {
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateSpeedSchedulerData>();

  const testType = watch('type');
  const scenarioIdxList = watch('scenarioIdxList');

  const {
    copiedTestScenarioList,
    setCopiedTestScenarioList,
    searchWord,
    onChangeSearchWord,
    setSearchWord,
    changeCardList,
    searchResultTestScenarioList,
    speedMobileTestScenarioList,
    speedWebTestScenarioList,
    isLoading,
  } = useSpeedTestSchedulerDraggableSpace('create', testType);

  useEffect(() => {
    if (scenarioIdxList?.length > 0) {
      clearErrors('scenarioIdxList');
    }
  }, [clearErrors, scenarioIdxList]);

  return (
    <ErrorBoundaryWrapper>
      <ScenarioDraggableElementContainer>
        <AllTestScenarioListInDraggableElement
          isLoading={isLoading}
          speedTestScenarioList={
            testType === SpeedSchedulerTypeName.Mobile
              ? speedMobileTestScenarioList
              : speedWebTestScenarioList
          }
          searchResultTestScenarioList={searchResultTestScenarioList}
          copiedTestScenarioList={copiedTestScenarioList}
          changeCardList={changeCardList}
          searchWord={searchWord}
          onChangeSearchWord={onChangeSearchWord}
          setSearchWord={setSearchWord}
        />
        <SchedulerTestScenarioListContainer>
          <SchedulerTestScenarioList
            copiedTestScenarioList={copiedTestScenarioList}
            setCopiedTestScenarioList={setCopiedTestScenarioList}
            changeCardList={changeCardList}
          />
        </SchedulerTestScenarioListContainer>
        <ErrorMessage
          errors={errors}
          name="scenarioIdxList"
          render={({ message }) => (
            <p className="standard-error-message">{message}</p>
          )}
        />
      </ScenarioDraggableElementContainer>
    </ErrorBoundaryWrapper>
  );
};

export default ScenarioDraggableSection;
