import { ElectronFile } from '@autosquare/common';
import { DeviceInfoOsFullName } from '@customTypes/ide/device/device';
import { number, z } from 'zod';

export const BaseAppInfoSchema = z.object({
  projectIdx: z.number(),
  appName: z.string(),
  packageName: z.string(),
  osType: z.nativeEnum(DeviceInfoOsFullName),
  appVersion: z.string(),
  buildVersion: z.string(),
});

export const AppInfoResponseListSchema = BaseAppInfoSchema.extend({
  idx: z.number(),
  updatedAt: z.string(),
  registerUser: z.string(),
  description: z.string(),
});
export type AppInfoList = z.infer<typeof AppInfoResponseListSchema>;

export const AppInfoIdxListSchema = z.object({
  projectIdx: z.string(),
  idxList: z.array(number()),
});
export type AppInfoIdxList = z.infer<typeof AppInfoIdxListSchema>;

const descriptionBase = z
  .string()
  .max(50, '1~50자 입력해 주세요.')
  .transform((v) => v.trim())
  .optional();

export const EditableFieldsSchema = z.object({
  description: descriptionBase,
});
export type AppInfoEditableFields = z.infer<typeof EditableFieldsSchema>;

export const UploadAppResponseSchema = BaseAppInfoSchema.extend({
  checksum: z.string(),
});
export type UploadAppFileInfo = z.infer<typeof UploadAppResponseSchema>;

export const AddAppInfoRequestSchema = BaseAppInfoSchema.extend({
  description: descriptionBase,
  checksum: z.string(),
  file: z
    .custom<ElectronFile>(
      (file): file is File => file instanceof File,
      '300MB 이하의 ipa 또는 apk 파일을 업로드해 주세요.',
    )
    .refine(
      (file) =>
        file instanceof File &&
        (file.name.endsWith('.ipa') || file.name.endsWith('.apk')) &&
        file.size <= 300 * 1024 * 1024,
      '300MB 이하의 ipa 또는 apk 파일을 업로드해 주세요.',
    ),
});
export type AddAppInfo = z.infer<typeof AddAppInfoRequestSchema>;

export const DeleteTempFileSchema = BaseAppInfoSchema.omit({
  appName: true,
});
export type DeleteTempFile = z.infer<typeof DeleteTempFileSchema>;
