import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';

import {
  webCommandOptions,
  findWebCommandNameFromServerName,
  WebCommandOptionName,
} from '@utils/static/webCommandOptions';

import { CreateWebTestStepData } from '@customTypes/testStep/type';

import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

const WebTestStepCommandLine = () => {
  const {
    control,
    watch,
    setValue,
    resetField,
    formState: { errors },
    clearErrors,
  } = useFormContext<CreateWebTestStepData>();
  const [isReload, setIsReload] = useState(false);

  const command = watch('command');
  const isMaskingOptionsEnabled = watch('optionsDto.maskingOptions.enabled');

  useEffect(() => {
    window.electron.on<boolean>('browserViewReload', (reload) => {
      setIsReload(reload);
    });
  }, []);

  useEffect(() => {
    window.electron.send('webCommandOptionName', {
      command: findWebCommandNameFromServerName(command),
      isMaskingEnabled: isMaskingOptionsEnabled,
    });

    clearErrors('optionsDto.stepIdxList');
  }, [command, isMaskingOptionsEnabled]);

  useEffect(() => {
    if (isReload) {
      setValue('command', undefined);
      setValue('optionsDto.optionType', undefined);
      setIsReload(false);
    }
  }, [isReload]);

  useEffect(() => {
    setValue('optionsDto.optionType', command);
    setValue('optionsDto.maskingOptions.enabled', false);
    setValue('optionsDto.maskingOptions.type', undefined);
    setValue('optionsDto.maskingOptions.valueList', undefined);
    setValue('value', '');
  }, [setValue, command]);

  return (
    <ErrorBoundaryWrapper>
      <Controller
        control={control}
        name="command"
        rules={{ required: 'Command를 입력해 주세요.' }}
        render={({ field }) => (
          <ListBoxHeadlessInController
            value={field.value}
            buttonValue={
              findWebCommandNameFromServerName(field.value) ||
              WebCommandOptionName.Command
            }
            onChange={(value) => {
              field.onChange(value);
              resetField('value');
            }}
            lists={webCommandOptions.slice(1)}
            valueToSave={'serverName'}
            valueToShow={'name'}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name="command"
        render={({ message }) => (
          <p className="error-message !mt-2">{message}</p>
        )}
      />
    </ErrorBoundaryWrapper>
  );
};

export default WebTestStepCommandLine;
