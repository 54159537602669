import React from 'react';
import { UseMutationResult } from '@tanstack/react-query';
import { DeviceApprovalRequest, DeviceListInProject } from '@customTypes/index';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import { CommonDialog } from '@components/Dialog';
import { useNavigate } from 'react-router-dom';
import { useParentPath } from '@hooks/useParentPath';
import { RESET_DELAY_MS } from '@constants/index';

type Props = {
  registerDeviceRequestMutation: UseMutationResult<
    DeviceListInProject,
    ServerResponseErrorDetails,
    DeviceApprovalRequest
  >;
  isSuccessDialogOpen: boolean;
  setIsSuccessDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isErrorDialogOpen: boolean;
  setIsErrorDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProjectDeviceInfoDialog = ({
  registerDeviceRequestMutation,
  isSuccessDialogOpen,
  setIsSuccessDialogOpen,
  isErrorDialogOpen,
  setIsErrorDialogOpen,
}: Props) => {
  const navigate = useNavigate();
  const deviceList = useParentPath();

  const successOnClose = () => {
    navigate(deviceList);
  };

  const errorOnClose = () => {
    setIsErrorDialogOpen(false);
    setTimeout(() => {
      registerDeviceRequestMutation.reset();
    }, RESET_DELAY_MS);
  };

  return (
    <ErrorBoundaryWrapper>
      <CommonDialog
        isOpen={isSuccessDialogOpen}
        setIsOpen={setIsSuccessDialogOpen}
        iconType={'checked'}
        title={'Approval Success'}
        subTitle={'디바이스 승인 요청이 완료되었습니다.'}
        onClose={successOnClose}
      >
        <CommonDialog.DialogButtonPanels>
          <CommonDialog.DialogButton type="button" onClick={successOnClose}>
            Close
          </CommonDialog.DialogButton>
        </CommonDialog.DialogButtonPanels>
      </CommonDialog>
      <CommonDialog
        isOpen={isErrorDialogOpen}
        setIsOpen={setIsErrorDialogOpen}
        iconType={'caution'}
        title={
          registerDeviceRequestMutation.isError &&
          registerDeviceRequestMutation.error.message.includes(
            '승인 대기중인 디바이스',
          )
            ? 'Approval Already Pending'
            : 'Approval Failure'
        }
        subTitle={
          registerDeviceRequestMutation.isError &&
          registerDeviceRequestMutation.error.message
        }
        onClose={errorOnClose}
      >
        <CommonDialog.DialogButtonPanels>
          <CommonDialog.DialogButton type="button" onClick={errorOnClose}>
            Close
          </CommonDialog.DialogButton>
        </CommonDialog.DialogButtonPanels>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default ProjectDeviceInfoDialog;
