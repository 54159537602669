import { createSlice } from '@reduxjs/toolkit';

export type RemoteSettingsModal = {
  isRemoteSettingOpen: boolean;
};

const initialState: RemoteSettingsModal = {
  isRemoteSettingOpen: false,
};

export const remoteSettingsModalSlice = createSlice({
  name: 'remote settings modal',
  initialState,
  reducers: {
    showModal: (state) => {
      state.isRemoteSettingOpen = true;
    },
    closeModal: (state) => {
      state.isRemoteSettingOpen = false;
    },
  },
});

export const { showModal, closeModal } = remoteSettingsModalSlice.actions;

export default remoteSettingsModalSlice.reducer;
