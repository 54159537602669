import axios from 'axios';
import * as Sentry from '@sentry/react';

import { store } from '@app/store';

import {
  getAccessToken,
  getRemoteAccessToken,
} from '@store/api/accessTokenSlice';
import { getPreviousLink } from '@store/data/previousLinkSlice';
import { decodeJwtToken } from '@autosquare/common';

export const axiosInstance = axios.create({
  baseURL: `${location.protocol}//${location.port === '3000' || location.port === '3002' ? location.hostname : location.host}`,
  timeout: 60000,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = store.getState().accessTokenValue.accessTokenValue;
    if (accessToken) {
      config.headers.Authorization = accessToken;
      const userId = decodeJwtToken(accessToken)?.sub;
      Sentry.setUser({ id: userId });
    } else {
      delete config.headers.Authorization;
    }

    const remoteAccessToken =
      store.getState().accessTokenValue.remoteAccessToken;
    if (remoteAccessToken) {
      config.headers['Remote-Access'] = remoteAccessToken;
    } else {
      delete config.headers['Remote-Access'];
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    store.dispatch(getAccessToken(response.headers.authorization));
    store.dispatch(getRemoteAccessToken(response.headers['remote-access']));
    return response.data;
  },

  (error) => {
    const { config } = error;
    if (config?.customFlag) {
      return Promise.reject(error.response?.data?.errorDetails);
    }

    if (error.response.status === 401) {
      store.dispatch(
        getPreviousLink(
          window.location.pathname +
            window.location.search +
            window.location.hash,
        ),
      );
      window.location.href = '/invalid-token';
    } else if (error.response && error.response.status >= 500) {
      if (error.config?.skipServerErrorRedirect) {
        // skipServerErrorRedirect 플래그가 설정된 경우 이동하지 않음
        return Promise.reject(error.response.data);
      }

      Sentry.captureException(error);
      window.location.href = '/internal-server-error';
    } else if (
      error.code === 'ERR_NETWORK' ||
      error.response.status === 404 ||
      error.response.status === 405
    ) {
      window.location.href = `/not-found`;
    } else if (error.isAxiosError && !error.response) {
      Sentry.captureException(error);
    }
    return Promise.reject(
      error.response?.data?.errorDetails ?? {
        message:
          '알 수 없는 에러가 발생했습니다.\n에러가 지속된다면 관리자에게 문의 부탁드립니다.',
      },
    );
  },
);

export const axiosRawResponseInstance = axios.create({
  baseURL: `${location.protocol}//${location.port === '3000' || location.port === '3002' ? location.hostname : location.host}`,
  timeout: 60000,
  withCredentials: true,
});

axiosRawResponseInstance.interceptors.request.use(
  (config) => {
    const accessToken = store.getState().accessTokenValue.accessTokenValue;
    if (accessToken) {
      config.headers.Authorization = accessToken;
      const userId = decodeJwtToken(accessToken)?.sub;
      Sentry.setUser({ id: userId });
    } else {
      delete config.headers.Authorization;
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  },
);

axiosRawResponseInstance.interceptors.response.use(
  (response) => {
    store.dispatch(getAccessToken(response.headers.authorization));

    return response;
  },

  async (error) => {
    const { response } = error;

    if (response?.status === 401) {
      store.dispatch(
        getPreviousLink(
          window.location.pathname +
            window.location.search +
            window.location.hash,
        ),
      );
      window.location.href = '/invalid-token';
      return;
    }

    if (response && response.status >= 500) {
      Sentry.captureException(error);
      window.location.href = '/internal-server-error';

      return;
    }

    if (response?.status === 404) {
      throw Error('404');
    }

    if (error.code === 'ERR_NETWORK' || response?.status === 405) {
      window.location.href = `/not-found`;

      return;
    }

    if (error.isAxiosError && !response) {
      Sentry.captureException(error);
      return;
    }

    // Blob 형태의 에러 응답 처리
    if (
      response &&
      response.data instanceof Blob &&
      (response.headers['content-type'] === 'application/json' ||
        response.headers['content-type'] === 'application/json;charset=UTF-8')
    ) {
      try {
        const errorText = await response.data.text();
        const errorJson = JSON.parse(errorText);
        return Promise.reject(errorJson.errorDetails ?? errorJson);
      } catch (e) {
        return Promise.reject({
          status: response.status,
          message: '알 수 없는 오류가 발생했습니다.',
        });
      }
    }

    return Promise.reject({
      message: '파일 처리 중 문제가 발생했습니다.\n관리자에게 문의해주세요.',
    });
  },
);
