import { Checkbox, TableBodyBasic } from '@autosquare/common';
import { EnableInstallDevice } from '@customTypes/index';
import clsx from 'clsx';
import React from 'react';

type Props = {
  device: EnableInstallDevice;
  idx: number;
  selectArray: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedArray: number[];
};

const SelectDeviceTableBodyRow = ({
  device,
  idx,
  selectArray,
  selectedArray,
}: Props) => {
  return (
    <tr className={clsx(device.enable ? 'bg-white' : 'bg-gray-100')}>
      <TableBodyBasic>
        <Checkbox
          checked={selectedArray.includes(device.idx)}
          onChange={(e) => selectArray(device.idx, e)}
          disabled={!device.enable}
        />
      </TableBodyBasic>
      <TableBodyBasic>{idx + 1}</TableBodyBasic>
      <TableBodyBasic isPaddingZero={!!device.deviceName}>
        <div className="flex flex-col">
          <p>{device.deviceModel}</p>
          {device.deviceName && (
            <p className="text-xs leading-normal text-gray-500">
              {device.deviceName}
            </p>
          )}
        </div>
      </TableBodyBasic>
      <TableBodyBasic isPaddingZero={!!device.deviceType}>
        <div className="flex flex-col">
          <p>{device.deviceOs}</p>
          {device.deviceType && (
            <p className="text-xs leading-normal text-gray-500">
              {device.deviceType}
            </p>
          )}
        </div>
      </TableBodyBasic>
      <TableBodyBasic>{device.remoteAlias}</TableBodyBasic>
      <TableBodyBasic>{device.appVersion}</TableBodyBasic>
      <TableBodyBasic>{device.buildVersion}</TableBodyBasic>
      <TableBodyBasic
        textColor={device.enable ? 'blue' : 'black'}
        isBold={device.enable}
      >
        {device.enable ? 'Y' : 'N'}
      </TableBodyBasic>
    </tr>
  );
};

export default SelectDeviceTableBodyRow;
