import { TooltipTop } from '@autosquare/common';
import { BreakdownPriority } from '@customTypes/ide/overview/type';
import { priorityColors } from '@utils/static/overview/breakdownGroupData';
import clsx from 'clsx';
import React from 'react';

type Props = {
  priority: BreakdownPriority[];
  total: number;
  count: number;
};

const CaseItem = ({ priority, total, count }: Props) => {
  return (
    <div className="relative flex w-full items-center rounded-lg">
      {priority
        ?.filter((item) => item.count > 0)
        .map((category, index, arr) => (
          <div
            key={category.priority}
            className={clsx(
              'group relative h-6',
              arr.length === 1
                ? 'rounded-lg'
                : index === 0
                  ? 'rounded-l-lg'
                  : index === arr.length - 1 && 'rounded-r-lg',
              priorityColors[category.priority],
            )}
            style={{
              width: `${(category.count / total) * 100}%`,
            }}
          >
            <TooltipTop positionClass="invisible bottom-1/2 group-hover:visible -translate-x-1/2 -translate-y-1/2 left-1/2">
              {`${category.priority} ${category.count}`}
            </TooltipTop>
          </div>
        ))}
      <div className="pl-3 text-xs font-normal text-black">{count}</div>
    </div>
  );
};

export default CaseItem;
