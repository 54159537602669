import React from 'react';
import AddDeviceFormFields from './AddDeviceFormFields';
import { Controller } from 'react-hook-form';
import { ListBoxHeadlessInController } from '@autosquare/common';
import {
  DeviceApprovalRequest,
  DeviceInfoOsFullName,
} from '@customTypes/index';
import { useFormContext } from 'react-hook-form';

const AddDeviceOs = () => {
  const { control } = useFormContext<DeviceApprovalRequest>();

  return (
    <AddDeviceFormFields label="Device OS*" name="deviceOs">
      <Controller
        control={control}
        name="deviceOs"
        render={({ field }) => (
          <ListBoxHeadlessInController
            value={field.value || ''}
            buttonValue={field.value}
            onChange={field.onChange}
            lists={[
              { title: DeviceInfoOsFullName.Aos },
              { title: DeviceInfoOsFullName.Ios },
            ]}
            valueToSave={'title'}
            valueToShow={'title'}
            placeholder={'Select OS'}
          />
        )}
      />
    </AddDeviceFormFields>
  );
};

export default AddDeviceOs;
