import { Button, ErrorBoundaryWrapper } from '@autosquare/common';
import {
  CommonDialog,
  DialogButtonPanels,
  ErrorDialog,
} from '@components/Dialog';
import React from 'react';

type Props = {
  isSuccessModalOpen: boolean;
  setIsSuccessModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isErrorModalOpen: boolean;
  setIsErrorModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProjectSettingsDetailModal = ({
  isSuccessModalOpen,
  setIsSuccessModalOpen,
  isErrorModalOpen,
  setIsErrorModalOpen,
}: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <CommonDialog
        isOpen={isSuccessModalOpen}
        setIsOpen={setIsSuccessModalOpen}
        iconType={'checked'}
        title={'Success'}
        subTitle={'프로젝트 정보 저장이 완료되었습니다.'}
      >
        <DialogButtonPanels>
          <Button
            variant="primary"
            onClick={() => setIsSuccessModalOpen(false)}
          >
            Close
          </Button>
        </DialogButtonPanels>
      </CommonDialog>

      <ErrorDialog
        isOpen={isErrorModalOpen}
        setIsOpen={setIsErrorModalOpen}
        subTitle={'프로젝트 정보 저장이 실패되었습니다.'}
      />
    </ErrorBoundaryWrapper>
  );
};

export default ProjectSettingsDetailModal;
