import { DeviceInfoOs } from '@customTypes/ide/device/device';
import {
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import From from './ClickTextReplace/From';
import Add from './ClickTextReplace/Add';
import DateFormat from './ClickTextReplace/DateFormat';
import SwitchInController from '@components/shared/HeadlessUI/Switch/SwitchInController';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Props = {
  mobileTestStepData: MobileTestStepData;
};

const ClickTextReplace = ({ mobileTestStepData }: Props) => {
  const { control, watch, setValue } = useFormContext<UpdateTestStepData>();

  const value = watch('value');
  const textReplace = watch('optionsDto.textReplace');
  const isTextReplaceFromServer =
    mobileTestStepData.optionsDto.textReplace === 'true';

  const isTextReplaceDisabled =
    mobileTestStepData.mobileOs === DeviceInfoOs.Aos
      ? !value?.includes('@text')
      : !value?.includes('@label');

  const isSecret = watch('optionsDto.secret');

  useEffect(() => {
    if (isTextReplaceDisabled && isSecret === 'false') {
      setValue('optionsDto.textReplace', 'false');
    }
  }, [value, setValue, isSecret]);

  useEffect(() => {
    if (textReplace === 'false') {
      setValue('optionsDto.textReplaceOptions', undefined);
    }
  }, [textReplace, setValue]);

  return (
    <ErrorBoundaryWrapper>
      <div
        className={`items-center border-b border-gray-200 sm:flex sm:px-6 sm:py-4`}
      >
        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
          Text Replace
        </dt>
        <dd className="mt-1 w-full text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
          <div className="w-full">
            <Controller
              control={control}
              name={'optionsDto.textReplace'}
              defaultValue={mobileTestStepData.optionsDto.textReplace}
              render={({ field }) => (
                <SwitchInController
                  checked={field.value === 'true'}
                  onChange={(checked: boolean) =>
                    field.onChange(checked ? 'true' : 'false')
                  }
                  size="sm"
                  disabled={isTextReplaceDisabled}
                />
              )}
            />
          </div>
        </dd>
      </div>
      {textReplace === 'true' && (
        <ErrorBoundaryWrapper>
          <From
            from={
              isTextReplaceFromServer &&
              mobileTestStepData.optionsDto.textReplaceOptions?.from
            }
          />
          <Add
            addKey={
              isTextReplaceFromServer &&
              mobileTestStepData.optionsDto.textReplaceOptions?.addKey
            }
            addValue={
              isTextReplaceFromServer &&
              mobileTestStepData.optionsDto.textReplaceOptions?.addValue
            }
          />
          <DateFormat
            textReplaceOptions={
              isTextReplaceFromServer &&
              mobileTestStepData.optionsDto.textReplaceOptions
            }
          />
        </ErrorBoundaryWrapper>
      )}
    </ErrorBoundaryWrapper>
  );
};

export default ClickTextReplace;
