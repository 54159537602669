import {
  CopyWebTestStep,
  UpdateWebTestStepData,
  WebTestStepData,
} from '@customTypes/testStep/type';

import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { axiosInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';
import { webTestStepKeys } from '@queries/ide/web/webTestStepKeys';
import { CreateWebTestStepData } from '@customTypes/testStep/type';
import { useSearchParams } from 'react-router-dom';
import { WebCommandOptionServerName } from '@utils/static/webCommandOptions';

interface DeleteWebTestStepMutation {
  caseIdx: string;
  idxList?: number[];
}

// GET
const getTestStepList = async (idx: string): Promise<WebTestStepData[]> => {
  const response = await axiosInstance.get<WebTestStepData[]>(
    IDEAPI.GET_ALL_TEST_STEP + idx,
  );
  return response.data;
};
export const useWebTestStepListQuery = (
  idx?: string,
  options?: UseQueryOptions<WebTestStepData[], Error>,
) => {
  const [searchParams] = useSearchParams();
  const webTestCaseIdx = idx ? idx : searchParams.get('idx');
  return useQuery<WebTestStepData[], Error>({
    queryKey: webTestStepKeys.case(webTestCaseIdx),
    queryFn: () => getTestStepList(webTestCaseIdx),
    ...options,
  });
};

const getWebTestStepByCommand = async (
  idx: number,
  command: string,
): Promise<WebTestStepData[]> => {
  const response = await axiosInstance.get<WebTestStepData[]>(
    `${IDEAPI.getWebTestStepByCommand(idx, command)}`,
  );
  return response.data;
};
export const useWebTestStepByCommandQuery = (
  webCommandOptions?: WebCommandOptionServerName,
  options?: UseQueryOptions<WebTestStepData[], Error>,
) => {
  const [searchParams] = useSearchParams();
  const idx = Number(searchParams.get('idx'));
  const command = webCommandOptions ?? WebCommandOptionServerName.SaveText;

  return useQuery<WebTestStepData[], Error>({
    queryKey: webTestStepKeys.command(idx, command),
    queryFn: () => getWebTestStepByCommand(idx, command),
    ...options,
  });
};

const getWebTestStep = async (idx: number): Promise<WebTestStepData> => {
  const response = await axiosInstance.get<WebTestStepData>(
    IDEAPI.GET_WEB_TEST_STEP + idx,
  );
  return response.data;
};

export const useGetWebTestStepQuery = (
  idx: number,
  options?: UseQueryOptions<WebTestStepData, Error>,
) => {
  return useQuery<WebTestStepData, Error>({
    queryKey: webTestStepKeys.list(idx?.toString()),
    queryFn: () => getWebTestStep(idx),
    ...options,
  });
};

// POST
const createWebTestStep = async (
  data: CreateWebTestStepData,
): Promise<unknown> => {
  const response = await axiosInstance.post(IDEAPI.CREATE_WEB_TEST_STEP, data);
  return response.data;
};
export const useCreateWebTestStepMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, CreateWebTestStepData>({
    mutationFn: (data) => createWebTestStep(data),
    onSuccess: () => {
      queryClient.invalidateQueries(webTestStepKeys.cases());
    },
  });
};

const copyWebTestStep = async (
  data: CopyWebTestStep,
): Promise<WebTestStepData> => {
  const response = await axiosInstance.post<WebTestStepData>(
    `${IDEAPI.WEB_TEST_STEP}/copy`,
    data,
  );
  return response.data;
};
export const useCopyWebTestStepMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<WebTestStepData, Error, CopyWebTestStep>({
    mutationFn: (data) => copyWebTestStep(data),
    onSuccess: () => {
      queryClient.invalidateQueries(webTestStepKeys.all);
    },
  });
};

//PUT
const modifyWebTestStepDetail = async (
  idx: number,
  data: UpdateWebTestStepData,
): Promise<unknown> => {
  const response = await axiosInstance.put<unknown>(
    IDEAPI.UPDATE_WEB_TEST_STEP_DETAIL + idx,
    data,
  );
  return response.data;
};

export const useModifyWebTestStepMutation = (idx: number) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, UpdateWebTestStepData>({
    mutationFn: (data) => modifyWebTestStepDetail(idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(webTestStepKeys.all);
    },
  });
};

// DELETE
const deleteWebTestStep = async (
  caseIdx: string,
  idxList: number[],
): Promise<unknown> => {
  const response = await axiosInstance.delete<unknown>(
    IDEAPI.DELETE_TEST_STEP,
    {
      data: {
        caseIdx: caseIdx,
        idxList: idxList,
      },
    },
  );
  return response.data;
};
export const useDeleteWebTestStepMutation = ({
  caseIdx,
  idxList,
}: DeleteWebTestStepMutation) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error>({
    mutationFn: () => deleteWebTestStep(caseIdx, idxList),
    onSuccess: () => {
      queryClient.invalidateQueries(webTestStepKeys.cases());
    },
  });
};
