import {
  ArrowPathIcon,
  MagnifyingGlassIcon,
  MinusIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import dayjs from 'dayjs';

import 'react-datepicker/dist/react-datepicker.css';

import { Button } from '@autosquare/common';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
  UseFormRegisterReturn,
} from 'react-hook-form';
import {
  SearchbarFieldNames,
  SearchbarParams,
  SearchbarParamsSchema,
  SortOrder,
  SpeedTestResultType,
  StatusEnum,
  TestTypeEnum,
} from '@customTypes/search/type';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { PriorityEnum, PriorityNameEnum } from '@customTypes/testCase/type';
import CalendarIconButton from './components/CalendarIconButton';
import {
  TestResultLabel,
  TestResultStatus,
  TestResultType,
} from '@customTypes/testResult/type';
import { ErrorMessage } from '@hookform/error-message';
import './SearchForm.css';
import CalendarCustomHeader from '@components/Calendar/CalendarCustomHeader';
import { PlatFormTypeServerName } from '@utils/static/platformTypeList';
import { SupportedBrowserList } from '@customTypes/ide/browser/browser';

import {
  DeviceInfoOs,
  DeviceInfoOsFullName,
} from '@customTypes/ide/device/device';

registerLocale('ko', ko);

type SearchFormProps = {
  children?: ReactNode;
  isFunctionalTest?: boolean;
  calendarSubTitle?: 'Created at' | 'Date';
  initialCreatedAt: Date;
};

const SearchForm = ({
  children,
  initialCreatedAt,
  isFunctionalTest = true,
  calendarSubTitle = 'Created at',
}: SearchFormProps) => {
  const { projectIdx } = useParams();
  const { pathname, search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const order =
    (searchParams.get('order') as SortOrder) ??
    (pathname.includes('test-result') ? SortOrder.Desc : SortOrder.Asc);

  const size = searchParams.get('size');

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const startDateRef = useRef<HTMLButtonElement>(null);
  const endDateRef = useRef<HTMLButtonElement>(null);

  const getSearchParams = (field: SearchbarFieldNames) =>
    searchParams.get(field)?.split(',') || undefined;

  const methods = useForm<SearchbarParams>({
    defaultValues: {
      projectIdx: projectIdx,
      value: searchParams.get('value') || undefined,
      priority: getSearchParams('priority') as PriorityEnum[],
      platform: getSearchParams('platform') as PlatFormTypeServerName[],
      status: getSearchParams('status') as StatusEnum[],
      testType: getSearchParams('testType') as TestTypeEnum[],
      type: getSearchParams('type') as TestResultType[],
      browser: getSearchParams('browser') as SupportedBrowserList[],
      result: getSearchParams('result') as TestResultStatus[],
      os: getSearchParams('os') as DeviceInfoOs[],
    },
    resolver: zodResolver(SearchbarParamsSchema),
  });

  const allFields: SearchbarFieldNames[] = [
    'value',
    'platform',
    'priority',
    'testType',
    'type',
    'status',
    'browser',
    'result',
    'os',
  ];

  // allFields에 작성한 key값들을 watch 하는 로직
  const watchedFields = allFields.reduce(
    (acc, field) => {
      acc[field] = methods.watch(field);
      return acc;
    },
    {} as Record<SearchbarFieldNames, unknown>,
  );

  // 초기화
  const resetOnClick = () => {
    if (methods.formState.errors.value) {
      methods.clearErrors('value');
    }

    setStartDate(undefined);
    setEndDate(undefined);

    allFields.forEach((field) => methods.setValue(field, ''));

    setSearchParams({
      projectIdx: projectIdx,
      order: order,
      size: size,
      page: String(1),
    });
  };

  // 초기 query 추가 로직
  useEffect(() => {
    if (!search) {
      setSearchParams({
        projectIdx: projectIdx,
        order: order,
        size: isFunctionalTest ? String(10) : String(5),
        page: String(1),
      });
    }
  }, [search]);

  // 날짜 업데이트
  const formattedStartDate = startDate
    ? dayjs(startDate).format('YYYY-MM-DD')
    : undefined;
  const formattedEndDate = endDate
    ? dayjs(endDate).format('YYYY-MM-DD')
    : undefined;

  useEffect(() => {
    methods.setValue('startDate', formattedStartDate);
  }, [methods.setValue, formattedStartDate]);

  useEffect(() => {
    methods.setValue('endDate', formattedEndDate);
  }, [methods.setValue, formattedEndDate]);

  // SearchParams의 날짜로 startDate, endDate 업데이트
  const searchParamsStartDate = searchParams.get('startDate');
  const searchParamsEndDate = searchParams.get('endDate');

  useEffect(() => {
    setStartDate(
      searchParamsStartDate ? new Date(searchParamsStartDate) : undefined,
    );
    setEndDate(searchParamsEndDate ? new Date(searchParamsEndDate) : undefined);
  }, []);

  // 배열을 string으로 변경하는 로직
  const arrayToArrayJoin = (data: string | string[]) => {
    if (Array.isArray(data)) {
      return data.join(',');
    }
    if (Array.isArray(data) && data.length === 0) {
      return undefined;
    }
    return data;
  };

  const onSubmit: SubmitHandler<SearchbarParams> = (data) => {
    if (
      !data.value &&
      !data.startDate &&
      !data.endDate &&
      !data.platform &&
      !data.priority &&
      !data.testType &&
      !data.type &&
      !data.status &&
      !data.browser &&
      !data.result &&
      !data.os
    ) {
      methods.setError('value', {
        type: 'required',
        message: '검색어 혹은 필터를 입력해 주세요.',
      });
    } else {
      data.page = 1;
      data.size = size;
      data.order = order;

      data.priority = arrayToArrayJoin(data.priority);
      data.platform = arrayToArrayJoin(data.platform);
      data.status = arrayToArrayJoin(data.status);
      data.testType = arrayToArrayJoin(data.testType);
      data.type = arrayToArrayJoin(data.type);
      data.browser = arrayToArrayJoin(data.browser);
      data.result = arrayToArrayJoin(data.result);
      data.os = arrayToArrayJoin(data.os);

      const filteredParams = Object.entries(data).reduce(
        (acc, [key, value]) => {
          if (value !== undefined && value !== '') {
            acc[key] = String(value);
          }
          return acc;
        },
        {} as Record<string, string>,
      );

      setSearchParams(filteredParams);
    }
  };

  // checkbox가 boolean일 때 undefined로 변환해주는 로직
  allFields.forEach((field) => {
    useEffect(() => {
      const value = watchedFields[field];
      if (typeof value === 'boolean') {
        methods.setValue(field, undefined);
      }
    }, [watchedFields[field]]);
  });

  // 에러 메시지 초기화
  useEffect(() => {
    methods.clearErrors('value');
  }, [
    isFilterVisible,
    startDate,
    endDate,
    watchedFields.value,
    watchedFields.priority,
    watchedFields.platform,
    watchedFields.status,
    watchedFields.testType,
    watchedFields.type,
    watchedFields.browser,
    watchedFields.result,
    watchedFields.os,
  ]);

  return (
    <FormProvider {...methods}>
      <form className="py-4" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex gap-2">
          <div className="flex w-full">
            <input
              className="h-9 w-full rounded-l-lg border border-solid border-gray-300 px-3 py-1.5 text-sm font-normal leading-normal ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600"
              placeholder="Search"
              {...methods.register('value', {
                onChange: (e) => {
                  const value = e.target.value;
                  // 입력 시작 시 공백 입력 불가
                  methods.setValue('value', value.replace(/^\s+/g, ''));
                },
              })}
            />
            <button
              type="button"
              className="flex h-9 items-center justify-start rounded-r-lg bg-gray-300 p-2 hover:bg-gray-500"
              onClick={() => setIsFilterVisible(!isFilterVisible)}
            >
              {isFilterVisible ? (
                <MinusIcon className="size-5 text-white" />
              ) : (
                <PlusIcon className="size-5 text-white" />
              )}
            </button>
          </div>
          {!isFilterVisible && (
            <>
              <Button type="submit" variant="search">
                <MagnifyingGlassIcon className="size-5 font-semibold" />
              </Button>
              <Button type="button" variant="secondary" onClick={resetOnClick}>
                <ArrowPathIcon className="size-5" />
              </Button>
            </>
          )}
        </div>
        <ErrorMessage
          errors={methods.formState.errors}
          name="value"
          render={({ message }) => (
            <p className="error-message pt-4">{message}</p>
          )}
        />
        {isFilterVisible && (
          <div className="space-y-4 px-8 py-4 text-sm font-medium leading-tight text-gray-900">
            <div className="flex items-center gap-10">
              <p className="w-20">{calendarSubTitle}</p>
              <div className="flex items-center">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  startDate={startDate}
                  endDate={endDate}
                  minDate={initialCreatedAt}
                  maxDate={endDate || new Date()}
                  dateFormat="yyyy-MM-dd"
                  showPopperArrow={false}
                  customInput={
                    <CalendarIconButton
                      ref={startDateRef}
                      placeholderText={'Start Date'}
                    />
                  }
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <CalendarCustomHeader
                      date={date}
                      changeYear={changeYear}
                      changeMonth={changeMonth}
                      decreaseMonth={decreaseMonth}
                      increaseMonth={increaseMonth}
                      prevMonthButtonDisabled={prevMonthButtonDisabled}
                      nextMonthButtonDisabled={nextMonthButtonDisabled}
                    />
                  )}
                  selectsStart
                />
                <span className="mx-4">-</span>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText={'End date'}
                  minDate={startDate || initialCreatedAt}
                  maxDate={new Date()}
                  showPopperArrow={false}
                  dateFormat="yyyy-MM-dd"
                  customInput={
                    <CalendarIconButton
                      ref={endDateRef}
                      placeholderText={'End Date'}
                    />
                  }
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <CalendarCustomHeader
                      date={date}
                      changeYear={changeYear}
                      changeMonth={changeMonth}
                      decreaseMonth={decreaseMonth}
                      increaseMonth={increaseMonth}
                      prevMonthButtonDisabled={prevMonthButtonDisabled}
                      nextMonthButtonDisabled={nextMonthButtonDisabled}
                    />
                  )}
                  selectsEnd
                />
              </div>
            </div>
            {children}
            <div className="flex items-center justify-center gap-2.5">
              <Button type="button" variant="secondary" onClick={resetOnClick}>
                검색 초기화
              </Button>
              <Button type="submit" variant="search">
                검색
              </Button>
            </div>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

const Platform = () => {
  const { register } = useFormContext<SearchbarParams>();
  const platformList = [
    { title: 'App', id: PlatFormTypeServerName.MobileApp },
    { title: 'Web', id: PlatFormTypeServerName.MobileWeb },
  ];

  return (
    <SearchFormCheckbox
      labelTitle={'Platform'}
      checkboxList={platformList}
      register={register('platform')}
    />
  );
};

const Priority = () => {
  const { register } = useFormContext<SearchbarParams>();
  const priorityList = [
    {
      title: PriorityNameEnum.High,
      id: PriorityEnum.High,
    },
    {
      title: PriorityNameEnum.Medium,
      id: PriorityEnum.Medium,
    },
    {
      title: PriorityNameEnum.Low,
      id: PriorityEnum.Low,
    },
  ];
  return (
    <SearchFormCheckbox
      labelTitle={'Priority'}
      checkboxList={priorityList}
      register={register('priority')}
    />
  );
};

const ResultTestType = ({
  isFunctionalTest = true,
}: {
  isFunctionalTest?: boolean;
}) => {
  const { register } = useFormContext<SearchbarParams>();

  const typeList = isFunctionalTest
    ? [
        { title: TestResultType.Monitoring, id: TestResultType.Monitoring },
        { title: TestResultType.Manual, id: TestResultType.Manual },
        { title: TestResultType.Unit, id: TestResultType.Unit },
      ]
    : [
        {
          title: SpeedTestResultType.Monitoring,
          id: SpeedTestResultType.Monitoring,
        },
        {
          title: SpeedTestResultType.Scenario,
          id: SpeedTestResultType.Scenario,
        },
        { title: SpeedTestResultType.Case, id: SpeedTestResultType.Case },
      ];

  return (
    <SearchFormCheckbox
      labelTitle={'Type'}
      checkboxList={typeList}
      register={register('type')}
    />
  );
};

const SchedulerStatus = () => {
  const { register } = useFormContext<SearchbarParams>();
  const statusList = [
    { title: 'Running', id: StatusEnum.On },
    { title: 'Not Running', id: StatusEnum.Off },
  ];

  return (
    <SearchFormCheckbox
      labelTitle={'Status'}
      checkboxList={statusList}
      register={register('status')}
    />
  );
};

const SchedulerTestType = () => {
  const { register } = useFormContext<SearchbarParams>();

  const testTypeList = [
    { title: 'Mobile', id: TestTypeEnum.Mobile },
    { title: 'Web', id: TestTypeEnum.Web },
  ];

  return (
    <SearchFormCheckbox
      labelTitle={'Test Type'}
      checkboxList={testTypeList}
      register={register('testType')}
    />
  );
};

const Browser = () => {
  const { register } = useFormContext<SearchbarParams>();
  const browserList = [
    { title: SupportedBrowserList.Chrome, id: SupportedBrowserList.Chrome },
    { title: SupportedBrowserList.Safari, id: SupportedBrowserList.Safari },
    { title: SupportedBrowserList.Edge, id: SupportedBrowserList.Edge },
  ];
  return (
    <SearchFormCheckbox
      labelTitle={'Browser'}
      checkboxList={browserList}
      register={register('browser')}
    />
  );
};

const ExecutionResult = () => {
  const { register } = useFormContext<SearchbarParams>();
  const resultList = [
    { title: TestResultLabel.Pass, id: TestResultStatus.Pass },
    { title: TestResultLabel.Fail, id: TestResultStatus.Fail },
  ];

  return (
    <SearchFormCheckbox
      labelTitle={'Result'}
      checkboxList={resultList}
      register={register('result')}
    />
  );
};

const MobileDeviceOs = () => {
  const { register } = useFormContext<SearchbarParams>();

  const mobileDeviceOsList = [
    { title: DeviceInfoOsFullName.Aos, id: DeviceInfoOs.Aos },
    { title: DeviceInfoOsFullName.Ios, id: DeviceInfoOs.Ios },
  ];

  return (
    <SearchFormCheckbox
      labelTitle={'OS'}
      checkboxList={mobileDeviceOsList}
      register={register('os')}
    />
  );
};

type SearchFormCheckboxProps = {
  labelTitle: string;
  checkboxList: { title: string; id: string }[];
  register: UseFormRegisterReturn;
};

export const SearchFormCheckbox = ({
  labelTitle,
  checkboxList,
  register,
}: SearchFormCheckboxProps) => {
  return (
    <div className="flex items-center gap-10">
      <p className="w-20">{labelTitle}</p>
      {checkboxList.map((item) => (
        <label key={item.id} className="flex items-center gap-3">
          <input
            type="checkbox"
            className="input-checkbox-congress-blue"
            id={item.id}
            value={item.id}
            {...register}
          />
          {item.title}
        </label>
      ))}
    </div>
  );
};

SearchForm.Platform = Platform;
SearchForm.Priority = Priority;
SearchForm.ResultTestType = ResultTestType;
SearchForm.Browser = Browser;
SearchForm.ExecutionResult = ExecutionResult;
SearchForm.SchedulerStatus = SchedulerStatus;
SearchForm.SchedulerTestType = SchedulerTestType;
SearchForm.MobileDeviceOs = MobileDeviceOs;

export default SearchForm;
