import { Button, ErrorBoundaryWrapper } from '@autosquare/common';
import { useCreateWebTestStepMutation } from '@lib/api/ide/web/webTestStep';
import { WebCommandOptionServerName } from '@utils/static/webCommandOptions';
import TestStepLoop from '@components/TestStep/TestStepLoop';
import { CreateWebTestStepData } from '@customTypes/testStep/type';
import React, { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import WebTestStepCommandLine from './WebAddTestStep/WebTestStepCommandLine';
import WebTestStepValueLine from './WebAddTestStep/WebTestStepValueLine';
import ConfirmAndInputText from './WebAddTestStep/ConfirmAndInputText';
import KeyboardEventList from './WebAddTestStep/KeyboardEventList';
import WaitTime from './WebAddTestStep/WaitTime';
import TestStepRequired from './WebAddTestStep/TestStepRequired';
import TestStepDescription from './WebAddTestStep/TestStepDescription';
import IfCommand from './WebAddTestStep/IfCommand';
import ScrollAndSwipe from './WebAddTestStep/ScrollAndSwipe';
import ImportTestStepCommandList from './WebAddTestStep/ImportTestStepCommandList';
import DefaultValue from '@components/IDE/DefaultValue';
import DataValidation from './WebAddTestStep/DataValidation';
import WebSecretBox from '@components/TestStep/WebSecretBox';
import WebTestStepMaskingOptions from '@components/WebTestStep/WebTestStepMaskingOptions';
import { isValueListObject } from '@utils/index';
import {
  MaskingOptionsType,
  MaskingOptionsValueListObject,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ErrorDialog } from '@components/Dialog';

const WebAddTestStep = () => {
  const [searchParams] = useSearchParams();
  const caseIdx = Number(searchParams.get('idx'));

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const methods = useForm<CreateWebTestStepData>({
    defaultValues: {
      caseIdx: caseIdx,
    },
  });

  const command = methods.watch('command');

  const needToDefaultValues = () => {
    methods.setValue('optionsDto.waitingTime', '2');
    methods.setValue('required', true);
  };

  useEffect(() => {
    needToDefaultValues();
  }, [methods.setValue]);

  const createWebTestStepMutation = useCreateWebTestStepMutation();

  const removeXpathPrefix = (value: string): string => {
    const xpath = 'xpath=';

    return value?.startsWith(xpath) ? value.slice(xpath.length) : value;
  };

  const onSubmit: SubmitHandler<CreateWebTestStepData> = (data) => {
    data.value = removeXpathPrefix(data.value.toString());

    const fieldsToProcess = [
      'targetElement',
      'conditionValue',
      'passActionValue',
      'failActionValue',
    ];

    fieldsToProcess.forEach((field) => {
      if (field in data.optionsDto) {
        data.optionsDto[field] = removeXpathPrefix(
          data.optionsDto[field]?.toString(),
        );
      }
    });

    const valueList = data.optionsDto.maskingOptions.valueList;

    if (
      data.optionsDto.maskingOptions.enabled &&
      data.optionsDto.maskingOptions.type === MaskingOptionsType.Element &&
      valueList &&
      isValueListObject(valueList[0])
    ) {
      data.optionsDto.maskingOptions.valueList = (
        valueList as MaskingOptionsValueListObject[]
      ).map((item) => removeXpathPrefix(item.value));
    }

    createWebTestStepMutation.mutate(data, {
      onSuccess: () => {
        methods.reset();
        needToDefaultValues();
      },
      onError: () => {
        setIsErrorDialogOpen(true);
      },
    });
  };

  return (
    <ErrorBoundaryWrapper>
      <div className="h-full w-1/2">
        <FormProvider {...methods}>
          <form
            className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <div className="gray-scrollbar h-0 flex-1 rounded-none">
              <div className="bg-congress-blue p-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <div className="text-base font-semibold leading-6 text-white">
                    Add Test Step
                  </div>
                </div>
              </div>
              <div className="flex flex-1 flex-col justify-between">
                <div className="divide-y divide-gray-200 px-4 sm:px-6">
                  <div className="space-y-6 pb-5 pt-6 text-sm">
                    <WebTestStepCommandLine />
                    <TestStepDescription />
                    {command === undefined ? (
                      <DefaultValue />
                    ) : command === WebCommandOptionServerName.KeyBoardEvent ? (
                      <KeyboardEventList />
                    ) : command ===
                      WebCommandOptionServerName.ScrollAndSwipe ? (
                      <ScrollAndSwipe />
                    ) : command ===
                      WebCommandOptionServerName.ImportTestStep ? (
                      <ImportTestStepCommandList />
                    ) : (
                      command !== WebCommandOptionServerName.IF && (
                        <WebTestStepValueLine />
                      )
                    )}
                    {command === WebCommandOptionServerName.ConfirmText ||
                    command === WebCommandOptionServerName.InputText ? (
                      <ConfirmAndInputText />
                    ) : command ===
                      WebCommandOptionServerName.ScrollAndSwipe ? (
                      <TestStepLoop />
                    ) : (
                      command === WebCommandOptionServerName.DataValidation && (
                        <DataValidation />
                      )
                    )}
                    {command === WebCommandOptionServerName.IF ? (
                      <IfCommand />
                    ) : (
                      <>
                        {(command === WebCommandOptionServerName.Click ||
                          command === WebCommandOptionServerName.SaveText) && (
                          <WebSecretBox control={methods.control} />
                        )}
                        <WebTestStepMaskingOptions />
                        <TestStepRequired />
                      </>
                    )}
                    <WaitTime />
                    {command === WebCommandOptionServerName.IF && (
                      <WebTestStepMaskingOptions />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex shrink-0 items-center justify-end p-4">
              <Button
                type="submit"
                variant="primary"
                disabled={createWebTestStepMutation.isLoading}
              >
                Add
              </Button>
            </div>
          </form>
        </FormProvider>
        <ErrorDialog
          isOpen={isErrorDialogOpen}
          setIsOpen={setIsErrorDialogOpen}
          subTitle={createWebTestStepMutation.error?.message}
        />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default WebAddTestStep;
