import { ErrorBoundaryWrapper } from '@autosquare/common';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import { CreateSchedulerData } from '@customTypes/ide/scheduler/scheduler';
import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const CreateSchedulerTitle = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CreateSchedulerData>();
  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer title={'Title'}>
        <div className="mt-2 sm:col-span-2 sm:mt-0">
          <input
            id="title"
            className="input-base"
            placeholder="Enter scheduler title"
            {...register('title')}
            required
          />
          <ErrorMessage
            errors={errors}
            name="title"
            render={({ message }) => (
              <p className="standard-error-message">{message}</p>
            )}
          />
        </div>
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default CreateSchedulerTitle;
