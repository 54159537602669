import React from 'react';
import clsx from 'clsx';
import { SelectedItem } from '@autosquare/common/src/hooks/checkbox/useCheckboxWithDnd';
import { TestCaseDetailPickTitleAndIdx } from '@customTypes/testCase/type';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import { useScenarioDndCard } from '@hooks/useScenarioDndCard';
import { useSortableCard } from '@hooks/useSortableCard';
import LeftItemSection from '@components/ScenarioCardSection/LeftItemSection';
import RightItemSection from '@components/ScenarioCardSection/RightItemSection';

type Props = {
  copyTestCase: TestCaseDetailPickTitleAndIdx;
  selectedArray: SelectedItem[];
  selectArray: (
    idx: number,
    dndIndex: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  onRemove: (id: number) => void;
  setSelectedArray: React.Dispatch<React.SetStateAction<SelectedItem[]>>;
};

const DetailCard = ({
  copyTestCase,
  selectedArray,
  selectArray,
  onRemove,
  setSelectedArray,
}: Props) => {
  const id = copyTestCase.dndIndex;
  const { attributes, setNodeRef, sortableStyle, listeners, isDragging } =
    useSortableCard(id);

  const { isChecked, onCheckboxChange, onTrashIconHandler } =
    useScenarioDndCard(
      copyTestCase,
      selectedArray,
      selectArray,
      onRemove,
      setSelectedArray,
      id,
    );

  return (
    <ErrorBoundaryWrapper>
      <div
        ref={setNodeRef}
        className={clsx(
          'bg-input-bg group m-2 flex items-center justify-between border border-solid px-2 py-0.5',
        )}
        style={sortableStyle}
        {...attributes}
      >
        <LeftItemSection
          isChecked={isChecked}
          onChange={onCheckboxChange}
          listeners={listeners}
          title={copyTestCase?.title}
        />
        <RightItemSection
          onClickHandler={onTrashIconHandler}
          isDragging={isDragging}
          listeners={listeners}
        />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default DetailCard;
