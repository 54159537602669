import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { axiosInstance, axiosLocalInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';
import { mobileDeviceKeys } from '@queries/ide/mobile/mobileDeviceKeys';
import { getSessionId } from '@store/ide/device/sessionIdSlice';

import {
  CreateSessionIdResponse,
  DeviceInfo,
  TcpConnectionRequest,
  WdaStatusResponse,
  WifiConnectRequest,
} from '@customTypes/ide/device/device';
import { InstallMandatoryApp } from '@customTypes/setting/type';
import { ServerErrorData } from '@customTypes/type';
import { MobileScreenshotPageSource } from '@customTypes/testStep/type';
import { SmsData } from '@customTypes/ide/device/sms';
import { useParams } from 'react-router-dom';
import { Mode } from '@customTypes/ide/remote/type';
import { RootState } from '@app/store';
import { remoteConfig } from '@utils/static/remoteConfig';
import { getRemoteState } from '@utils/static/getConfiguration';

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  customFlag?: boolean;
}

const remoteDeviceSessionUrl = `${IDEAPI.REMOTE}/connect/device/mobile/session`;

// GET
// 연결된 디바이스 목록 조회
// http://localhost:8081/swagger-ui/index.html#/Device/getDeviceList
const deviceList = async (projectIdx: string): Promise<DeviceInfo[]> => {
  const response = await axiosLocalInstance.get<DeviceInfo[]>(
    `${IDEAPI.GET_DEVICE_LIST}?projectIdx=${projectIdx}`,
  );
  return response.data;
};
export const useDeviceListQuery = (
  options?: UseQueryOptions<DeviceInfo[], Error>,
) => {
  const { projectIdx } = useParams();
  const { configuration } = getRemoteState();
  return useQuery<DeviceInfo[], Error>({
    queryKey: mobileDeviceKeys.list(projectIdx),
    queryFn: () => deviceList(projectIdx),
    enabled: configuration === Mode.Standard,
    ...options,
  });
};

const mobileScreenshot = async (): Promise<string> => {
  const response = await axiosLocalInstance.get<string>(IDEAPI.TAKE_SCREENSHOT);
  return response.data;
};
export const useMobileScreenshotQuery = () => {
  const { configuration } = getRemoteState();
  return useQuery<string, Error>({
    queryKey: mobileDeviceKeys.screenshot(),
    queryFn: () => mobileScreenshot(),
    enabled: configuration === Mode.Standard,
  });
};

//원격 페이지 스크린샷 가져오기
//https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Device/getScreenshot
const remoteMobileScreenshot = async (): Promise<string> => {
  const response = await axiosInstance.get<string>(
    `${remoteDeviceSessionUrl}/screenshot`,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteMobileScreenshotQuery = () => {
  const { configuration } = getRemoteState();
  return useQuery<string, Error>({
    queryKey: mobileDeviceKeys.screenshot(),
    queryFn: () => remoteMobileScreenshot(),
    enabled: configuration === Mode.Remote,
  });
};

const mobileSource = async (): Promise<MobileScreenshotPageSource> => {
  const response = await axiosLocalInstance.get<MobileScreenshotPageSource>(
    IDEAPI.GET_PAGE_SOURCE,
  );
  return response.data;
};
export const useMobileSourceQuery = () => {
  const { configuration } = getRemoteState();
  return useQuery<MobileScreenshotPageSource, Error>({
    queryKey: mobileDeviceKeys.source(),
    queryFn: () => mobileSource(),
    enabled: configuration === Mode.Standard,
  });
};

//원격 페이지 소스 조회
//https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Device/getPageSource
const remoteMobileSource = async (): Promise<MobileScreenshotPageSource> => {
  const response = await axiosInstance.get<MobileScreenshotPageSource>(
    `${remoteDeviceSessionUrl}/source`,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteMobileSourceQuery = () => {
  const { configuration } = getRemoteState();
  return useQuery<MobileScreenshotPageSource, Error>({
    queryKey: mobileDeviceKeys.source(),
    queryFn: () => remoteMobileSource(),
    enabled: configuration === Mode.Remote,
  });
};

const mobileAppList = async (): Promise<string[]> => {
  const response = await axiosLocalInstance.get<string[]>(
    IDEAPI.GET_MOBILE_APP_LIST,
  );
  return response.data;
};
export const useMobileAppListQuery = () => {
  const { configuration } = getRemoteState();
  return useQuery<string[], Error>({
    queryKey: mobileDeviceKeys.appList(),
    queryFn: () => mobileAppList(),
    enabled: configuration === Mode.Standard,
  });
};

//원격 디바이스 내 설치된 앱 목록 조회
//https://dev.t-square.co.kr/swagger-ui/index.html#/v1.0.3/getPackageList
const remoteMobileAppList = async (): Promise<string[]> => {
  const response = await axiosInstance.get<string[]>(
    `${remoteDeviceSessionUrl}/appList`,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteMobileAppListQuery = () => {
  const { configuration } = getRemoteState();
  return useQuery<string[], Error>({
    queryKey: mobileDeviceKeys.appList(),
    queryFn: () => remoteMobileAppList(),
    enabled: configuration === Mode.Remote,
  });
};

const getSmsList = async (): Promise<SmsData[]> => {
  const response = await axiosLocalInstance.get<SmsData[]>(IDEAPI.GET_SMS_LIST);
  return response.data;
};
export const useMobileSmsListQuery = (
  options?: UseQueryOptions<SmsData[], Error>,
) => {
  const { configuration } = getRemoteState();
  return useQuery<SmsData[], Error>({
    queryKey: mobileDeviceKeys.smsList(),
    queryFn: () => getSmsList(),
    enabled: configuration === Mode.Standard,
    ...options,
  });
};

//원격 디바이스 내 SMS 리스트 조회
//https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Device/getSmsList
const getRemoteSmsList = async (): Promise<SmsData[]> => {
  const response = await axiosInstance.get<SmsData[]>(
    `${remoteDeviceSessionUrl}/sms`,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteMobileSmsListQuery = (
  options?: UseQueryOptions<SmsData[], Error>,
) => {
  const { configuration } = getRemoteState();
  return useQuery<SmsData[], Error>({
    queryKey: mobileDeviceKeys.smsList(),
    queryFn: () => getRemoteSmsList(),
    enabled: configuration === Mode.Remote,
    ...options,
  });
};

// post
const createSessionId = async (
  projectIdx: string,
  cookie: string,
  deviceInfo: DeviceInfo,
): Promise<CreateSessionIdResponse> => {
  const response = await axios.post(
    IDEAPI.CREATE_SESSION_ID + projectIdx,
    deviceInfo,
    { headers: { 'Token-Value': cookie }, timeout: 5 * 60 * 1000 },
  );
  return response.data?.data;
};
export const useCreateSessionIdMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { projectIdx } = useParams();
  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  return useMutation<
    CreateSessionIdResponse,
    AxiosError<ServerErrorData>,
    DeviceInfo
  >({
    mutationFn: (data) => createSessionId(projectIdx, cookie, data),
    onSuccess: (response) => {
      dispatch(getSessionId(response.sessionId));
      queryClient.invalidateQueries(mobileDeviceKeys.all);
    },
  });
};

//원격 디바이스 선택
//https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Device/selectDevice
const remoteCreateSessionId = async (
  projectIdx: string,
  cookie: string,
  deviceInfo: DeviceInfo,
): Promise<CreateSessionIdResponse> => {
  const response = await axiosInstance.post(
    `${remoteDeviceSessionUrl}/create?project=${projectIdx}`,
    deviceInfo,
    {
      customFlag: true,
      headers: { 'Token-Value': cookie, 'Content-Type': 'application/json' },
      timeout: 5 * 60 * 1000,
    } as CustomAxiosRequestConfig,
  );
  return response.data;
};
export const useRemoteCreateSessionIdMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { projectIdx } = useParams();
  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  return useMutation<
    CreateSessionIdResponse,
    AxiosError<ServerErrorData>,
    DeviceInfo
  >({
    mutationFn: (data) => remoteCreateSessionId(projectIdx, cookie, data),
    onSuccess: (response) => {
      dispatch(getSessionId(response.sessionId));
      queryClient.invalidateQueries(mobileDeviceKeys.all);
    },
  });
};

const mobileDeleteDriver = async (): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    IDEAPI.DELETE_MOBILE_DRIVE,
  );
  return response.data;
};
export const useMobileDeleteDriverMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error>({
    mutationFn: () => mobileDeleteDriver(),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileDeviceKeys.all);
    },
  });
};

//원격 세션 삭제
//https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Device/deleteSession
const remoteMobileDeleteDriver = async (): Promise<unknown> => {
  const response = await axiosInstance.post<unknown>(
    `${remoteDeviceSessionUrl}/delete`,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteMobileDeleteDriverMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error>({
    mutationFn: () => remoteMobileDeleteDriver(),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileDeviceKeys.all);
    },
  });
};

const installMandatoryApp = async (
  cookie: string,
  data: InstallMandatoryApp,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    IDEAPI.INSTALL_MANDATORY_APP_IN_IOS,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useInstallMandatoryAppMutation = (cookie: string) => {
  return useMutation<unknown, Error, InstallMandatoryApp>({
    mutationFn: (data) => installMandatoryApp(cookie, data),
  });
};

const settingWifiWirelessDevice = async (
  data: WifiConnectRequest,
): Promise<WifiConnectRequest> => {
  const response = await axiosLocalInstance.post<WifiConnectRequest>(
    `${IDEAPI.SETTING_WIRELESS_DEVICE}/wifi`,
    data,
  );
  return response.data;
};
export const useSettingWifiWirelessDeviceMutation = () => {
  return useMutation<WifiConnectRequest, Error, WifiConnectRequest>({
    mutationFn: (data) => settingWifiWirelessDevice(data),
  });
};

//원격 Wifi 무선 디바이스 설정
//https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Device/connectWifiWireless
const remoteSettingWifiWirelessDevice = async (
  data: WifiConnectRequest,
): Promise<WifiConnectRequest> => {
  const response = await axiosInstance.post<WifiConnectRequest>(
    `${IDEAPI.REMOTE}/connect/device/mobile/wireless/wifi`,
    data,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteSettingWifiWirelessDeviceMutation = () => {
  return useMutation<WifiConnectRequest, Error, WifiConnectRequest>({
    mutationFn: (data) => remoteSettingWifiWirelessDevice(data),
  });
};

const settingTcpWirelessDevice = async (
  data: TcpConnectionRequest,
): Promise<TcpConnectionRequest> => {
  const response = await axiosLocalInstance.post<TcpConnectionRequest>(
    `${IDEAPI.SETTING_WIRELESS_DEVICE}/tcp`,
    data,
  );
  return response.data;
};
export const useSettingTcpWirelessDeviceMutation = () => {
  return useMutation<TcpConnectionRequest, Error, TcpConnectionRequest>({
    mutationFn: (data) => settingTcpWirelessDevice(data),
  });
};

//원격 TCP 무선 디바이스 설정
//https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Device/connectTcpWireless
const remoteSettingTcpWirelessDevice = async (
  data: TcpConnectionRequest,
): Promise<TcpConnectionRequest> => {
  const response = await axiosInstance.post<TcpConnectionRequest>(
    `${IDEAPI.REMOTE}/connect/device/mobile/wireless/tcp`,
    data,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteSettingTcpWirelessDeviceMutation = () => {
  return useMutation<TcpConnectionRequest, Error, TcpConnectionRequest>({
    mutationFn: (data) => remoteSettingTcpWirelessDevice(data),
  });
};

//원격지 PC WDA 체크
//https://dev.t-square.co.kr/swagger-ui/index.html#/v1.0.7/checkWda%E2%82%A9Status
const checkWdaStatus = async (): Promise<WdaStatusResponse[]> => {
  const response = await axiosInstance.get<WdaStatusResponse[]>(
    `${IDEAPI.REMOTE}/connect/wda/status`,
    remoteConfig,
  );
  return response.data;
};

export const useCheckWdaStatusQuery = () => {
  const { configuration } = getRemoteState();
  return useQuery<WdaStatusResponse[], Error>({
    queryKey: mobileDeviceKeys.wdaStatus(),
    queryFn: () => checkWdaStatus(),
    enabled: configuration === Mode.Remote,
  });
};

//원격지 PC WDA 다운로드
//https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Schedule/downloadDerivedData
const downloadDerivedData = async (projectIdx: number): Promise<boolean> => {
  const response = await axiosInstance.post<boolean>(
    `${IDEAPI.REMOTE}/connect/wda/download?project=${projectIdx}`,
    remoteConfig,
  );
  return response.data;
};

export const useRemoteDownloadWdaDataMutation = () => {
  return useMutation<boolean, Error, number>({
    mutationFn: (projectIdx: number) => downloadDerivedData(projectIdx),
  });
};

//원격지 PC WDA 업로드
const uploadDerivedData = async (
  projectIdx: number,
  file: File,
): Promise<unknown> => {
  const response = await axiosInstance.post<unknown>(
    `${IDEAPI.REMOTE_WDA_UPLOAD}/${projectIdx}`,
    { file: file },
    {
      customFlag: true,
      headers: { 'Content-Type': 'multipart/form-data' },
    } as CustomAxiosRequestConfig,
  );
  return response.data;
};

export const useRemoteUploadWdaDataMutation = () => {
  return useMutation<unknown, Error, { projectIdx: number; file: File }>({
    mutationFn: (data) => uploadDerivedData(data.projectIdx, data.file),
  });
};
