import React from 'react';
import AdminProjectListTable from './components/AdminProjectListTable';
import {
  ErrorBoundaryWrapper,
  ErrorMessage,
  PageHeadingButtons,
  PagingHeadings,
  TableSkeleton,
} from '@autosquare/common';
import { useLocation } from 'react-router-dom';
import { useAllAdminProjectListQuery } from '@lib/api/dashboard/queryHooks';

const AdminProjectList = () => {
  const { pathname } = useLocation();

  const {
    data: adminProjectList,
    isLoading,
    isFetching,
    isError,
    error,
  } = useAllAdminProjectListQuery();

  const breadcrumbs = [
    { title: 'Administration', href: pathname },
    { title: 'Projects', href: pathname },
    { title: 'List', href: pathname },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: 'Create Project',
      href: 'create',
      type: 'button',
      variant: 'primary',
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={'Project List'}
        pageHeadingButtons={pageHeadingButtons}
      />
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto scrollbar-thin sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {isLoading || isFetching ? (
              <TableSkeleton />
            ) : isError ? (
              <ErrorMessage>{error.message}</ErrorMessage>
            ) : (
              <AdminProjectListTable adminProjectList={adminProjectList} />
            )}
          </div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default AdminProjectList;
