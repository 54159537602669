import React from 'react';
import { Button, Checkbox, ScenarioListDetailData } from '@autosquare/common';
import { TestCaseDetailPickTitleAndIdx } from '@customTypes/testCase/type';
import { useIndeterminateCheckbox } from '@hooks/index';
import { SelectedItem } from '@autosquare/common/src/hooks/checkbox/useCheckboxWithDnd';

type Props = {
  selectedArray: SelectedItem[];
  selectAllArray: (e: React.ChangeEvent<HTMLInputElement>) => void;
  total: number;
  setCopyTestCases?: React.Dispatch<
    React.SetStateAction<
      TestCaseDetailPickTitleAndIdx[] | ScenarioListDetailData[]
    >
  >;
  resetSelectedArray?: () => void;
  disabled?: boolean;
  hasDeleteOption?: boolean;
};

const SelectionHeaderWithCheckbox = ({
  selectedArray,
  selectAllArray,
  total,
  setCopyTestCases,
  resetSelectedArray,
  disabled = false,
  hasDeleteOption = false,
}: Props) => {
  const selectedDndArray = selectedArray.map(({ dndIndex }) => dndIndex);

  const onDeleteButton = () => {
    setCopyTestCases((prev) =>
      prev.filter(({ dndIndex }) => !selectedDndArray.includes(dndIndex)),
    );
    resetSelectedArray();
  };
  const checkboxRef = useIndeterminateCheckbox(selectedArray.length, total);
  return (
    <div className="flex h-12 items-center justify-between border-b border-gray-200 px-4 py-2">
      <div className="flex h-full items-center gap-x-3">
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={(e) => selectAllArray(e)}
          ref={checkboxRef}
          disabled={disabled}
        />
        {hasDeleteOption && selectedArray.length > 0 && (
          <Button
            type="button"
            variant="secondary"
            buttonSize="sm"
            className="m-3"
            onClick={onDeleteButton}
          >
            Delete
          </Button>
        )}
      </div>
      <div className="text-sm font-normal leading-tight text-gray-900">
        Selected: {selectedArray.length} / Total: {total}
      </div>
    </div>
  );
};

export default SelectionHeaderWithCheckbox;
