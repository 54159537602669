import {
  ErrorBoundaryWrapper,
  TableBodyBasic,
  TableBodyGray,
  TableHeader,
} from '@autosquare/common';
import { ImportedFileData } from '@customTypes/index';
import React from 'react';

type Props = {
  fileData: ImportedFileData[];
};

const DatResult = ({ fileData }: Props) => {
  const tableHeader = [
    'No',
    'Title',
    'Description',
    'Test Step Count',
    'Priority',
    'Status',
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="space-y-12">
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="col-span-full">
            <div>
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{fileData?.length}</span>{' '}
                results
              </p>
            </div>
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {tableHeader.map((header) => (
                    <TableHeader isFirstPaddingZero={true} key={header}>
                      {header}
                    </TableHeader>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {fileData?.map((file, index) => (
                  <tr key={file.testCaseData.uniqueKey}>
                    <TableBodyBasic isFirstPaddingZero={true}>
                      {index + 1}
                    </TableBodyBasic>
                    <TableBodyGray>{file.testCaseData.title}</TableBodyGray>
                    <td className="table-data-cell-gray max-w-xs break-words">
                      {file.testCaseData.description}
                    </td>
                    <TableBodyGray>
                      AOS: {file.testCaseData.aosStepCount} / iOS:{' '}
                      {file.testCaseData.iosStepCount}
                    </TableBodyGray>
                    <TableBodyGray>
                      {file.testCaseData.priority[0] +
                        file.testCaseData.priority.slice(1).toLowerCase()}
                    </TableBodyGray>
                    <TableBodyGray>{file.status}</TableBodyGray>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default DatResult;
