import { ErrorBoundaryWrapper } from '@autosquare/common';
import React from 'react';
import ProjectSettingsDetailPagingHeadings from './components/ProjectSettingsDetailPagingHeadings';
import ProjectSettingsDetailInformation from './components/ProjectSettingsDetailInformation';
import TitleHeader from './components/TitleHeader';

const ProjectSettingsDetail = () => {
  return (
    <ErrorBoundaryWrapper>
      <ProjectSettingsDetailPagingHeadings />
      <div className="py-4">
        <TitleHeader />
        <ProjectSettingsDetailInformation />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default ProjectSettingsDetail;
