import { ErrorBoundaryWrapper, Button } from '@autosquare/common';
import {
  CommonDialog,
  DialogButtonPanels,
  ErrorDialog,
} from '@components/Dialog';
import { DeviceAdministrationInfo } from '@customTypes/index';
import { useUpdateAdminDeviceStatusMutation } from '@lib/api/dashboard/queryHooks';
import React, { useCallback, useState } from 'react';

type Props = {
  adminDevice: DeviceAdministrationInfo;
  isDeviceEnabled: boolean;
};

const DeviceStatus = ({ adminDevice, isDeviceEnabled }: Props) => {
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [isDisabledDialogOpen, setIsDisabledDialogOpen] = useState(false);
  const updateAdminDeviceStatusMutation = useUpdateAdminDeviceStatusMutation();

  const updateDeviceStatus = useCallback(
    (deviceStatus: boolean) => {
      updateAdminDeviceStatusMutation.mutate(
        { idx: adminDevice.idx, enabled: deviceStatus },
        {
          onError: () => {
            setIsErrorDialogOpen(true);
          },
          onSettled: () => {
            setIsDisabledDialogOpen(false);
          },
        },
      );
    },
    [updateAdminDeviceStatusMutation],
  );

  return (
    <ErrorBoundaryWrapper>
      {adminDevice.deviceEnabled ? (
        <Button
          variant="toggleOn"
          onClick={() => {
            setIsDisabledDialogOpen(true);
          }}
          buttonSize="none"
          disabled={!isDeviceEnabled}
        >
          ON
        </Button>
      ) : (
        <Button
          variant="toggleOff"
          onClick={() => {
            updateDeviceStatus(true);
          }}
          disabled={
            !isDeviceEnabled || updateAdminDeviceStatusMutation.isLoading
          }
          buttonSize="none"
        >
          OFF
        </Button>
      )}

      {/* 디바이스 비활성화 시 Dialog */}
      <CommonDialog
        isOpen={isDisabledDialogOpen}
        setIsOpen={setIsDisabledDialogOpen}
        iconType={'caution'}
        title={'Device Disable'}
        subTitle={
          '해당 디바이스를 비활성화하면 IDE에서 사용할 수 없습니다.\n비활성화하시겠습니까?\n(스케줄러에 설정된 경우, 테스트 결과는 N/A로 처리됩니다.)'
        }
      >
        <DialogButtonPanels>
          <Button
            type="button"
            variant="primary"
            onClick={() => {
              updateDeviceStatus(false);
            }}
            disabled={updateAdminDeviceStatusMutation.isLoading}
          >
            OK
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={() => setIsDisabledDialogOpen(false)}
          >
            Cancel
          </Button>
        </DialogButtonPanels>
      </CommonDialog>

      {/* 디바이스 상태 변경 시 에러 메시지 띄우기 */}
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        setIsOpen={setIsErrorDialogOpen}
        subTitle={updateAdminDeviceStatusMutation.error?.message}
      />
    </ErrorBoundaryWrapper>
  );
};

export default DeviceStatus;
