import React from 'react';
import {
  Breadcrumbs,
  ErrorBoundaryWrapper,
  ErrorMessage,
  PagingHeadingsInAutosquare,
} from '@autosquare/common';
import AutomationSettingsSkeleton from '@components/shared/Skeleton/AutomationSettingsSkeleton';
import { useLocation } from 'react-router-dom';
import WebAutomationDisplayDataComponent from './components/WebAutomationDisplayDataComponent';
import WebAutomationRegisterComponent from './components/WebAutomationRegisterComponent';
import { useGetWebTestCaseDataAutomation } from '@lib/api/ide/web/webTestAutomation';
import { useParentPath } from '@hooks/index';

const WebTestAutomationSettings = () => {
  const { pathname, search } = useLocation();

  const overviewPage = `${useParentPath(4)}/overview`;
  const testCasePage = useParentPath(2);
  const testCaseDetailPage = `${testCasePage}/detail${search}`;
  const testCaseAutomationPage = pathname + search;

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'UI Test', href: overviewPage },
    { title: 'Web', href: testCasePage },
    { title: 'Test Case', href: testCasePage },
    { title: 'Detail', href: testCaseDetailPage },
    { title: 'Automation Settings', href: testCaseAutomationPage },
  ];

  const useGetTestCaseDataAutomationQuery = useGetWebTestCaseDataAutomation();
  const testAutomationData = useGetTestCaseDataAutomationQuery.data;
  const isTestAutomationData = testAutomationData !== null;

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Automation Settings'}
      />
      {useGetTestCaseDataAutomationQuery.isLoading ||
      useGetTestCaseDataAutomationQuery.isFetching ? (
        <AutomationSettingsSkeleton />
      ) : useGetTestCaseDataAutomationQuery.isError ? (
        <ErrorMessage>
          {useGetTestCaseDataAutomationQuery.error.message}
        </ErrorMessage>
      ) : isTestAutomationData ? (
        <WebAutomationDisplayDataComponent
          testAutomationData={testAutomationData}
          testCaseDetailPage={testCaseDetailPage}
        />
      ) : (
        <WebAutomationRegisterComponent
          testCaseDetailPage={testCaseDetailPage}
        />
      )}
    </ErrorBoundaryWrapper>
  );
};

export default WebTestAutomationSettings;
