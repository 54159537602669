import React from 'react';
import {
  SectionMainTitleContainer,
  SectionTitleContainer,
  SectionTitleHeader,
  SectionTitleHeaderDescription,
} from '@autosquare/common';

type Props = {
  header: string;
  description?: string;
};

const SubTitleHeaderSection = ({ header, description }: Props) => {
  return (
    <SectionTitleContainer>
      <SectionMainTitleContainer>
        <SectionTitleHeader>{header}</SectionTitleHeader>
        {description && (
          <SectionTitleHeaderDescription>
            {description}
          </SectionTitleHeaderDescription>
        )}
      </SectionMainTitleContainer>
    </SectionTitleContainer>
  );
};

export default SubTitleHeaderSection;
