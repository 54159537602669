import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

import { SidebarList } from '@customTypes/type';
import clsx from 'clsx';

type Props = {
  sidebarList: SidebarList;
};

const ProjectSidebarSubList = ({ sidebarList }: Props) => {
  const location = useLocation();

  const [isOpened, setIsOpened] = useState(true);

  useEffect(() => {
    if (location.pathname.includes(sidebarList.location)) {
      setIsOpened(true);
    }
  }, [location.pathname]);

  return (
    <div>
      <button
        className={clsx(
          'flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700',
          location.pathname.includes(sidebarList.hrefBasic)
            ? 'bg-gray-50'
            : 'hover:bg-gray-50',
        )}
        onClick={() => setIsOpened(!isOpened)}
      >
        <ChevronRightIcon
          className={clsx(
            'size-5 shrink-0',
            isOpened ? 'rotate-90 text-gray-500' : 'text-gray-400',
          )}
          aria-hidden="true"
        />
        {sidebarList.name}
      </button>
      {isOpened && (
        <ul className="mt-1 px-2">
          {sidebarList.children.map((item) => (
            <li key={item.name}>
              <Link
                to={item.href}
                onClick={(e) => {
                  if (item.hrefBasic === location.pathname) {
                    e.preventDefault();
                  }
                }}
              >
                <div
                  className={clsx(
                    'block cursor-pointer rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700',
                    location.pathname.includes(item.hrefBasic)
                      ? 'bg-gray-50'
                      : 'hover:bg-gray-50',
                  )}
                >
                  {item.name}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ProjectSidebarSubList;
