import { ListBoxHeadlessInController } from '@autosquare/common';
import { InstallAppRequest } from '@customTypes/index';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  label: string;
  name: Exclude<keyof InstallAppRequest, 'projectIdx' | 'deviceIdxList'>;
  lists: unknown[];
  valueToSave: string;
  valueToShow: string;
  disabled?: boolean;
};

const SelectApplicationListbox = ({
  label,
  name,
  lists,
  valueToSave,
  valueToShow,
  disabled = false,
}: Props) => {
  const { control } = useFormContext<InstallAppRequest>();

  return (
    <div className="flex flex-col gap-2">
      <label className="text-sm font-medium leading-normal text-gray-900">
        {label}
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <ListBoxHeadlessInController
            value={field.value}
            buttonValue={field.value}
            onChange={field.onChange}
            lists={lists}
            valueToSave={valueToSave}
            valueToShow={valueToShow}
            disabled={disabled}
            isGrayBackground={disabled}
            placeholder="Select"
          />
        )}
      />
    </div>
  );
};

export default SelectApplicationListbox;
