import { CreateAdminProjectData } from '@customTypes/dashboard/admin/type';
import { Path } from 'react-hook-form';

export type AutoRegisterPlatformList = {
  idx: number;
  name: AutoRegisterPlatformName;
  serverName: AutoRegisterPlatformServername;
};

export enum AutoRegisterPlatformName {
  Jira = 'Jira',
  Confluence = 'Confluence',
}

export enum AutoRegisterPlatformServername {
  Jira = 'jira',
  Confluence = 'confluence',
}

export const autoRegisterPlatformList: AutoRegisterPlatformList[] = [
  {
    idx: 0,
    name: AutoRegisterPlatformName.Jira,
    serverName: AutoRegisterPlatformServername.Jira,
  },
  {
    idx: 1,
    name: AutoRegisterPlatformName.Confluence,
    serverName: AutoRegisterPlatformServername.Confluence,
  },
];

export const findAutoRegisterPlatformName = (
  value: AutoRegisterPlatformServername | string,
) => autoRegisterPlatformList.find((item) => item.serverName === value)?.name;

export const jiraInput: Path<CreateAdminProjectData>[] = [
  'platformInput.requestUrl',
  'platformInput.email',
  'platformInput.apiToken',
];

export const confluenceInput: Path<CreateAdminProjectData>[] = [
  'platformInput.email',
  'platformInput.apiToken',
];

export const JiraTestResultList = [
  { idx: 0, serverName: 'Fail' },
  { idx: 1, serverName: 'N/A' },
];

export const ConfluenceTestResultList = [
  { label: 'Pass', id: 'pass' },
  { label: 'Fail', id: 'fail' },
  { label: 'N/A', id: 'na' },
];
