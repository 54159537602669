import {
  ErrorBoundaryWrapper,
  ErrorMessage,
  TableBodyContainer,
  TableHeader,
  TableNoList,
  TableSkeleton,
} from '@autosquare/common';
import { useAdminDeviceApprovalListQuery } from '@lib/api/dashboard/queryHooks';
import React from 'react';
import AdminRequestTableBody from './AdminRequestTable/AdminRequestTableBody';

const AdminRequestTable = () => {
  const adminDeviceApprovalListQuery = useAdminDeviceApprovalListQuery();
  const adminDeviceApprovalList = adminDeviceApprovalListQuery.data;

  const tableHeaderList = [
    { idx: 1, title: 'No' },
    { idx: 2, title: 'Type' },
    { idx: 3, title: 'Requester' },
    { idx: 4, title: 'Device Name' },
    { idx: 5, title: 'OS' },
    { idx: 6, title: 'Project' },
    { idx: 7, title: 'DateTime' },
    { idx: 8, title: 'Description' },
    { idx: 9, title: '' },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="my-3 min-w-full">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              {tableHeaderList.map((header) => (
                <TableHeader key={header.idx}>{header.title}</TableHeader>
              ))}
            </tr>
          </thead>
          <TableBodyContainer>
            {adminDeviceApprovalListQuery.isLoading ? (
              <TableNoList colSpan={tableHeaderList.length}>
                <TableSkeleton />
              </TableNoList>
            ) : adminDeviceApprovalListQuery.isError ? (
              <TableNoList colSpan={tableHeaderList.length}>
                <ErrorMessage margin="my-0">
                  {adminDeviceApprovalListQuery.error.message}
                </ErrorMessage>
              </TableNoList>
            ) : adminDeviceApprovalList.length === 0 ? (
              <TableNoList colSpan={tableHeaderList.length}>
                요청된 내용이 없습니다.
              </TableNoList>
            ) : (
              adminDeviceApprovalList.map((deviceApproval, idx) => (
                <AdminRequestTableBody
                  key={deviceApproval.idx}
                  deviceApproval={deviceApproval}
                  idx={idx}
                />
              ))
            )}
          </TableBodyContainer>
        </table>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default AdminRequestTable;
