import {
  Button,
  Checkbox,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
  ErrorMessage,
  TableHeader,
  TableNoList,
  TableWrapper,
  useCheckbox,
} from '@autosquare/common';

import React from 'react';

import SchedulerListTableBody from './SchedulerListTableBody';
import {
  useDeleteSchedulerMutation,
  useFilteredSchedulerListQuery,
  useRemoteDeleteSchedulerMutation,
} from '@lib/api/ide/scheduler/scheduler';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';
import SchedulerListTableBodySkeleton from '@components/Scheduler/SchedulerListTableBodySkeleton';
import NoListResultMessage from '@components/Search/NoListResultMessage';
import { Mode } from '@customTypes/ide/remote/type';
import { getRemoteState } from '@utils/static/getConfiguration';

const SchedulerListTable = () => {
  const filteredSchedulerListQuery = useFilteredSchedulerListQuery();
  const schedulerLists = filteredSchedulerListQuery.data;
  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(schedulerLists, 'idx');

  const headerList = [
    {
      idx: 1,
      title: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={selectAllArray}
          key="selected-checkbox"
        />
      ),
    },
    {
      idx: 2,
      title: selectedArray.length > 0 ? <div className="size-5" /> : 'No',
    },
    {
      idx: 3,
      title:
        selectedArray.length > 0 ? (
          <div className="h-5 w-[4.125rem]" />
        ) : (
          'Test Type'
        ),
    },
    { idx: 4, title: 'Environment' },
    { idx: 5, title: 'Title' },
    { idx: 6, title: 'Scenario Count' },
    { idx: 7, title: 'Schedule', isSortable: true },
    { idx: 8, title: '' },
    { idx: 9, title: '' },
  ];

  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );
  const { configuration } = getRemoteState();
  const deleteSchedulerMutation =
    configuration === Mode.Standard
      ? useDeleteSchedulerMutation(cookie)
      : useRemoteDeleteSchedulerMutation();

  return (
    <ErrorBoundaryWrapper>
      <TableWrapper selectedArray={selectedArray} openDialog={openDialog}>
        <thead>
          <tr>
            {headerList?.map((header) => (
              <TableHeader
                key={header.idx}
                isFirstPaddingZero={false}
                isSortable={header.isSortable}
              >
                {header.title}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white">
          {filteredSchedulerListQuery.isLoading ? (
            <SchedulerListTableBodySkeleton
              tableHeaderLength={headerList.length}
            />
          ) : filteredSchedulerListQuery.isError ? (
            <TableNoList colSpan={headerList.length}>
              <ErrorMessage>
                {filteredSchedulerListQuery.error.message}
              </ErrorMessage>
            </TableNoList>
          ) : schedulerLists?.length === 0 ? (
            <NoListResultMessage
              colSpan={headerList.length}
              testType="scheduler"
            />
          ) : (
            schedulerLists?.map((schedulerList, idx) => (
              <SchedulerListTableBody
                key={schedulerList?.idx}
                idx={idx}
                schedulerList={schedulerList}
                selectArray={selectArray}
                selectedArray={selectedArray}
              />
            ))
          )}
        </tbody>
      </TableWrapper>
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedCautionDialog}
        setIsOpened={setIsOpenedCautionDialog}
        type="caution"
        title={deleteSchedulerMutation.isError ? 'Error' : 'Delete Scheduler'}
        subTitle={
          deleteSchedulerMutation.isError
            ? deleteSchedulerMutation.error.message
            : '해당 스케쥴러를 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {!deleteSchedulerMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  deleteSchedulerMutation.mutate(
                    { idxList: selectedArray },
                    {
                      onSuccess: () => {
                        closeDialog();
                        resetSelectedArray();
                      },
                      onError: () => {
                        openDialog();
                      },
                    },
                  )
                }
              >
                Delete
              </Button>
            )}
            <Button type="button" variant="secondary" onClick={closeDialog}>
              Cancel
            </Button>
          </>
        }
      />
    </ErrorBoundaryWrapper>
  );
};

export default SchedulerListTable;
