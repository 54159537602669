import React from 'react';

import NoChildrenSidebarList from './NoChildrenSidebarList';
import ProjectSidebarSubList from './ProjectSidebarSubList';

import { SidebarList } from '@customTypes/type';
import { ProjectListDetail } from '@customTypes/dashboard/project/type';

type Props = {
  sidebarList: SidebarList[];
  projectList: ProjectListDetail;
  setIsToggledSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProjectSidebarMonitorSize = ({
  sidebarList,
  projectList,
  setIsToggledSidebar,
}: Props) => {
  return (
    <>
      <div className="flex h-16 shrink-0 items-center">
        <span className="text-sm font-semibold">{projectList?.name}</span>
      </div>
      <div className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {sidebarList?.map((sidebar) => (
                <li key={sidebar?.name}>
                  {!sidebar?.children ? (
                    <NoChildrenSidebarList
                      sidebarList={sidebar}
                      setIsToggledSidebar={setIsToggledSidebar}
                    />
                  ) : (
                    <ProjectSidebarSubList sidebarList={sidebar} />
                  )}
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ProjectSidebarMonitorSize;
