import {
  Button,
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
  regexLibrary,
} from '@autosquare/common';
import DefinitionList from '@components/shared/DefinitionList';
import { UpdateWebTestStepData } from '@customTypes/testStep/type';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { findOperatorLabel, operatorList } from '@utils/index';
import React, { useEffect, useState } from 'react';
import {
  Controller,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form';

type Props = {
  fields: FieldArrayWithId<UpdateWebTestStepData, 'optionsDto.operatorList'>[];
  append: UseFieldArrayAppend<UpdateWebTestStepData>;
  remove: UseFieldArrayRemove;
};

const EditDataValidationInputData = ({ fields, append, remove }: Props) => {
  const [isAppendable, setIsAppendable] = useState(true);

  const {
    control,
    watch,
    register,
    formState: { errors, isSubmitted },
  } = useFormContext<UpdateWebTestStepData>();

  const optionsDtoOperatorList = watch('optionsDto.operatorList');

  const isOperatorTruthy = optionsDtoOperatorList?.every(
    (operator) => !!operator.operator,
  );
  const isInputValueTruthy = optionsDtoOperatorList?.every(
    (operator) =>
      !!operator.inputValue &&
      regexLibrary.numericWithOptionalDecimals12Digits.test(
        operator.inputValue,
      ),
  );

  const plusButtonOnClick = () => {
    if (isOperatorTruthy && isInputValueTruthy) {
      append({ operator: undefined, inputValue: undefined });
    } else {
      setIsAppendable(false);
    }
  };

  useEffect(() => {
    if (isOperatorTruthy && isInputValueTruthy) {
      setIsAppendable(true);
    }
  }, [isInputValueTruthy, isOperatorTruthy]);

  const isOperatorError =
    errors &&
    errors?.optionsDto?.operatorList?.some((operator) => operator?.operator);

  const isInputValueError =
    errors &&
    errors?.optionsDto?.operatorList?.some((operator) => operator?.inputValue);

  return (
    <ErrorBoundaryWrapper>
      <DefinitionList
        term={'Input Data*'}
        description={
          <div className="space-y-4">
            <div>
              <div className="grid grid-cols-2">
                <p className="px-2">Operators</p>
                <p className="-ml-4 px-2">Input Value</p>
              </div>
              <ul className="space-y-4">
                {fields.map((item, index) => (
                  <li key={item.id}>
                    <div className="flex w-full gap-4">
                      <div className="grid w-[calc(100%-3.5rem)] grid-cols-2 gap-4">
                        <div className="flex w-full flex-col">
                          <ErrorBoundaryWrapper>
                            <Controller
                              control={control}
                              name={`optionsDto.operatorList.${index}.operator`}
                              rules={{ required: '연산 기호를 선택해 주세요.' }}
                              render={({ field }) => (
                                <ListBoxHeadlessInController
                                  value={field.value}
                                  buttonValue={findOperatorLabel(field.value)}
                                  placeholder={'Select'}
                                  onChange={field.onChange}
                                  lists={operatorList}
                                  valueToSave={'serverName'}
                                  valueToShow={'label'}
                                />
                              )}
                            />
                          </ErrorBoundaryWrapper>
                        </div>
                        <div className="flex w-full flex-col">
                          <ErrorBoundaryWrapper>
                            <input
                              type="number"
                              step={'any'}
                              className="input-base hide-input-number-arrow"
                              placeholder="변경 값을 입력하세요."
                              {...register(
                                `optionsDto.operatorList.${index}.inputValue`,
                                {
                                  required:
                                    '최대 12자리의 숫자와 소수점 이하 12자리까지 입력 가능합니다.\n숫자(0-9)와 온점(.)만 허용됩니다.',
                                  pattern: {
                                    value:
                                      regexLibrary.numericWithOptionalDecimals12Digits,
                                    message:
                                      '최대 12자리의 숫자와 소수점 이하 12자리까지 입력 가능합니다.\n숫자(0-9)와 온점(.)만 허용됩니다.',
                                  },
                                },
                              )}
                            />
                          </ErrorBoundaryWrapper>
                        </div>
                      </div>
                      <div className="flex items-end justify-center">
                        <ErrorBoundaryWrapper>
                          <Button
                            type="button"
                            variant="secondary"
                            buttonSize="xs"
                            isSquare
                            onClick={() => {
                              fields.length > 1 && remove(index);
                            }}
                            disabled={fields.length === 1}
                          >
                            <MinusIcon className="size-6" />
                          </Button>
                        </ErrorBoundaryWrapper>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <ErrorBoundaryWrapper>
              <button
                type="button"
                className="flex-center h-9 w-full rounded border border-dashed border-gray-300 px-2 py-1"
                onClick={plusButtonOnClick}
              >
                <PlusIcon className="size-6 text-gray-900" />
              </button>
              {(!isAppendable || isSubmitted) &&
              (!isOperatorTruthy || isOperatorError) ? (
                <p className="error-message pt-2">연산 기호를 선택해 주세요.</p>
              ) : (
                (!isAppendable || isSubmitted) &&
                (!isInputValueTruthy || isInputValueError) && (
                  <p className="error-message py-2">
                    최대 12자리의 숫자와 소수점 이하 12자리까지 입력 가능합니다.
                    <br />
                    숫자(0-9)와 온점(.)만 허용됩니다.
                  </p>
                )
              )}
            </ErrorBoundaryWrapper>
          </div>
        }
      />
    </ErrorBoundaryWrapper>
  );
};

export default EditDataValidationInputData;
