import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';

import { CopyRight } from '@autosquare/common';

const MiddleAlignRoot = () => {
  return (
    <>
      <ScrollRestoration getKey={(location) => location.key} />
      <main className="m-auto flex h-auto min-h-[calc(100vh-10rem)] flex-col items-center justify-center text-sm">
        <Outlet />
      </main>
      <CopyRight />
    </>
  );
};

export default MiddleAlignRoot;
