import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  CommonDialog,
  DialogButton,
  DialogButtonPanels,
} from '@components/Dialog';
import { useParentPath } from '@hooks/index';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  isSuccessDialogOpen: boolean;
  setIsSuccessDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isErrorDialogOpen: boolean;
  setIsErrorDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  errorMessage: string;
};
const ReserveDeviceDialog = ({
  isSuccessDialogOpen,
  setIsSuccessDialogOpen,
  isErrorDialogOpen,
  setIsErrorDialogOpen,
  errorMessage,
}: Props) => {
  const navigate = useNavigate();
  const reservationDeviceListPath = useParentPath();

  const successOnClose = () => {
    setIsSuccessDialogOpen(false);
    navigate(reservationDeviceListPath);
  };

  return (
    <ErrorBoundaryWrapper>
      {/* Success Dialog */}
      <CommonDialog
        isOpen={isSuccessDialogOpen}
        setIsOpen={setIsSuccessDialogOpen}
        iconType={'checked'}
        title={'Success'}
        subTitle={'디바이스 예약이 완료되었습니다.'}
        onClose={successOnClose}
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant="primary"
            onClick={successOnClose}
          >
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
      {/* Error Dialog */}
      <CommonDialog
        isOpen={isErrorDialogOpen}
        setIsOpen={setIsErrorDialogOpen}
        iconType={'caution'}
        title={'Failure'}
        subTitle={errorMessage}
        onClose={successOnClose}
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant="secondary"
            onClick={() => setIsErrorDialogOpen(false)}
          >
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default ReserveDeviceDialog;
