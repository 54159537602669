import { RootState } from '@app/store';
import {
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorMessage,
  PagingHeadingsNavigate,
  useScrollEvent,
} from '@autosquare/common';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';
import { useTestExecutionContext } from '@contexts/ide/TestExecutionProvider';
import {
  WebSpeedTestCaseExecutionData,
  WebSpeedTestScenarioExecutionData,
} from '@customTypes/ide/browser/browserExecution';
import { useGetWebSocketMessage } from '@hooks/electron/useGetWebSocketMessage';
import {
  useExecuteWebSpeedTestCaseMutation,
  useExecuteWebSpeedTestScenarioMutation,
  useRemoteExecuteWebSpeedTestCaseMutation,
  useRemoteStopWebTestExecutionMutation,
  useStopWebTestExecutionMutation,
} from '@lib/api/ide/web/webExecution';

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import TestExecutionDetailLoading from './TestExecutionDetailLoading';
import { Mode } from '@customTypes/ide/remote/type';
import { getRemoteState } from '@utils/static/getConfiguration';

type Props = { testType: TestTypeCategory; closeModalExecution: () => void };

const SpeedWebTestExecutionDetail = ({
  testType,
  closeModalExecution,
}: Props) => {
  const { isModalOpened, setIsModalOpened, openModal, closeModal } =
    useTestExecutionContext();

  const webExecutionData = useSelector(
    (state: RootState) => state.webExecutionData.selectedWebExecutionData,
  );
  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  const { webSocketMessages, isTestRunning, setIsTestRunning } =
    useGetWebSocketMessage();
  const { scrollRef } = useScrollEvent(webSocketMessages);
  const { configuration } = getRemoteState();
  const executeWebSpeedTestMutation =
    configuration === Mode.Standard
      ? useExecuteWebSpeedTestCaseMutation(cookie)
      : useRemoteExecuteWebSpeedTestCaseMutation();

  const stopWebTestExecutionMutation =
    configuration === Mode.Standard
      ? useStopWebTestExecutionMutation(cookie)
      : useRemoteStopWebTestExecutionMutation();

  const executeWebSpeedTestScenarioMutation =
    useExecuteWebSpeedTestScenarioMutation(cookie);

  useEffect(() => {
    if (cookie !== null) {
      if (testType === 'case') {
        executeWebSpeedTestMutation.mutate(
          webExecutionData as WebSpeedTestCaseExecutionData,
        );
      }
      if (testType === 'scenario') {
        executeWebSpeedTestScenarioMutation.mutate(
          webExecutionData as WebSpeedTestScenarioExecutionData,
        );
      }
    }
  }, [cookie]);

  const breadcrumbs = [
    { title: 'Speed Test' },
    { title: 'Web' },
    {
      title:
        testType === 'scenario'
          ? 'Test Scenario'
          : testType === 'case' && 'Test Case',
    },
    { title: 'Execution' },
    { title: 'Detail' },
  ];

  return (
    <>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Execution Detail'}
      />
      <div className="h-[calc(100%-7rem)]">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Execution Log
        </h2>
        <div
          className="optimize-legibility flex h-[calc(100%-3rem)] flex-col gap-2 overflow-auto overflow-y-scroll rounded-md border border-gray-200 bg-gray-50/80 px-4 py-2 will-change-scroll scrollbar-thin scrollbar-track-rounded-full scrollbar-thumb-rounded-full"
          ref={scrollRef}
        >
          {executeWebSpeedTestMutation.isError ? (
            <ErrorMessage margin="my-0">
              {executeWebSpeedTestMutation.error.message}
            </ErrorMessage>
          ) : (
            webSocketMessages?.map((webSocketMessage, idx) => (
              <p key={idx} className="text-sm">
                {webSocketMessage}
              </p>
            ))
          )}
        </div>
        {stopWebTestExecutionMutation.isLoading ? (
          <TestExecutionDetailLoading />
        ) : stopWebTestExecutionMutation.isError ? (
          <DoubleCheckDialogHeadlessUI
            isOpened={isModalOpened}
            setIsOpened={setIsModalOpened}
            type={'caution'}
            title={'Error'}
            subTitle={stopWebTestExecutionMutation.error.message}
            buttonChildren={
              <Button type="button" variant="secondary" onClick={closeModal}>
                Cancel
              </Button>
            }
          />
        ) : (
          isTestRunning && (
            <DoubleCheckDialogHeadlessUI
              isOpened={isModalOpened}
              setIsOpened={setIsModalOpened}
              type={'caution'}
              title={'Stop Test'}
              subTitle={
                '테스트를 강제 종료하시겠습니까?\n(테스트 결과는 N/A 처리됩니다.)'
              }
              buttonChildren={
                <>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    variant="caution"
                    onClick={() => {
                      stopWebTestExecutionMutation.mutate(undefined, {
                        onSuccess: () => {
                          closeModalExecution();
                        },
                        onError: () => {
                          setIsTestRunning(false);
                          openModal();
                        },
                      });
                      closeModal();
                    }}
                  >
                    Stop
                  </Button>
                </>
              }
            />
          )
        )}
      </div>
    </>
  );
};

export default SpeedWebTestExecutionDetail;
