import React from 'react';
import { UseMutationResult } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { LiaUsb } from 'react-icons/lia';

import { getDeviceInfo } from '@store/ide/device/selectedDevicesSlice';
import {
  ConnectionType,
  CreateSessionIdResponse,
  DeviceInfo,
  DeviceInfoOs,
} from '@customTypes/ide/device/device';
import { ServerErrorData } from '@customTypes/type';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { getRemoteState } from '@utils/static/getConfiguration';
import { openErrorModal } from '@store/ide/remote/remoteConnectionStatusSlice';

type Props = {
  deviceList: DeviceInfo;
  mutation: UseMutationResult<
    CreateSessionIdResponse,
    AxiosError<ServerErrorData>,
    DeviceInfo,
    unknown
  >;
  setNeedIosSetting: React.Dispatch<React.SetStateAction<boolean>>;
  setDeviceValue: React.Dispatch<React.SetStateAction<DeviceInfo>>;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  remotePCWdaStatus: boolean;
};

const MobileDeviceList = ({
  deviceList,
  mutation,
  setNeedIosSetting,
  setDeviceValue,
  setIsAuthenticated,
  remotePCWdaStatus,
}: Props) => {
  const navigate = useNavigate();
  const { projectIdx } = useParams();
  const { search } = useLocation();
  const location = isWordInLocationPathname('ui-test')
    ? 'ui-test'
    : 'speed-test';

  const dispatch = useDispatch();

  const { standardConfiguration, remoteConfiguration } = getRemoteState();

  const remoteNeedIosSettingCondition = remotePCWdaStatus === null;

  const onClick = () => {
    if (deviceList.enabled === false) {
      setIsAuthenticated(true);
      return;
    }

    if (
      deviceList.os === DeviceInfoOs.Ios &&
      (standardConfiguration ||
        (remoteConfiguration && remoteNeedIosSettingCondition))
    ) {
      setDeviceValue(deviceList);
      setNeedIosSetting(true);
    } else {
      mutation.mutate(deviceList, {
        onSuccess: () => {
          navigate({
            pathname: `/ide/projects/${projectIdx}/${location}/mobile/test-step`,
            search: `${search}&udid=${deviceList.udid}`,
          });
          dispatch(getDeviceInfo(deviceList));
        },
        onError: () => {
          dispatch(openErrorModal());
        },
      });
    }
  };

  return (
    <li>
      <button
        type="button"
        className={clsx(
          'group relative flex w-full cursor-pointer items-center justify-center space-x-3 rounded-md border border-gray-200 p-4 text-start',
          deviceList.enabled ? 'hover:bg-gray-50' : 'bg-gray-200',
        )}
        onClick={onClick}
      >
        <div className="shrink-0">
          {deviceList.connectionType === null ||
          deviceList.connectionType === ConnectionType.USB ? (
            <LiaUsb size="1.5rem" />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.288 15.038a5.25 5.25 0 0 1 7.424 0M5.106 11.856c3.807-3.808 9.98-3.808 13.788 0M1.924 8.674c5.565-5.565 14.587-5.565 20.152 0M12.53 18.22l-.53.53-.53-.53a.75.75 0 0 1 1.06 0Z"
              />
            </svg>
          )}
        </div>
        <div className={'min-w-0 flex-1'}>
          <div className="text-sm font-medium text-gray-900">
            <span className="absolute" aria-hidden="true" />
            {deviceList.model ?? '-'}
          </div>
          <p className="truncate text-sm text-gray-500">
            {deviceList.serialNumber}
          </p>
        </div>
        <div className="shrink-0 self-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className={clsx('size-5 text-gray-400', {
              'group-hover:text-gray-500': deviceList.enabled,
            })}
          >
            <path
              fillRule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </button>
    </li>
  );
};

export default MobileDeviceList;
