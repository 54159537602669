import { CommonDialog } from '@components/Dialog';
import {
  AutoRegisterInfoData,
  CreateSchedulerData,
  UpdateSchedulerData,
} from '@customTypes/ide/scheduler/scheduler';
import { ErrorMessage } from '@hookform/error-message';
import {
  AutoRegisterPlatformServername,
  findAutoRegisterPlatformName,
} from '@utils/static/autoRegister/autoRegisterTypeList';
import React, { useEffect } from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import AutoRegisterPlatformInputList from './IdeAutoRegisterModal/AutoRegisterPlatformInputList';
import { useGetAutoRegistersInfoQuery } from '@lib/api/ide/autoRegisters/autoRegisters';
import { hasDuplicateTestResults } from '@utils/static/autoRegister/hasDuplicateJiraMapping';
import AutoRegisterPlatformNameController from './IdeAutoRegisterModal/AutoRegisterPlatformNameController';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Props = {
  isAddClicked: boolean;
  setIsAddClicked: React.Dispatch<React.SetStateAction<boolean>>;
  autoRegistersList: AutoRegisterInfoData[];
  setAutoRegistersList: React.Dispatch<
    React.SetStateAction<AutoRegisterInfoData[]>
  >;
  fields: FieldArrayWithId<
    CreateSchedulerData | UpdateSchedulerData,
    'autoRegisters'
  >[];
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<
    CreateSchedulerData | UpdateSchedulerData,
    'autoRegisters'
  >;
};

const IdeAutoRegisterModal = ({
  isAddClicked,
  setIsAddClicked,
  autoRegistersList,
  setAutoRegistersList,
  fields,
  remove,
  update,
}: Props) => {
  const {
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateSchedulerData | UpdateSchedulerData>();

  const { data: autoRegisterInfo } = useGetAutoRegistersInfoQuery();

  const autoIndex = fields.length;

  const filteredDataList = autoRegisterInfo
    ?.map((info) => {
      const { platform } = info;
      return {
        serverName: platform,
        name: findAutoRegisterPlatformName(platform),
      };
    })
    .filter(
      (item) =>
        !autoRegistersList.some(
          (data) => data.platformName === item.serverName,
        ),
    );

  const autoRegisters = useWatch({
    control,
    name: `autoRegisters.${autoIndex - 1}`,
  });
  const platform = autoRegisters.platformName;
  const projectKey = autoRegisters?.jira?.projectKey;
  const jiraMappingData = autoRegisters?.jira?.mapping;

  const mapping = jiraMappingData?.every(
    (item) => item.issueType && item.testResult,
  );

  const requestUrl = autoRegisters?.confluence?.requestUrl;
  const testResult = autoRegisters?.confluence?.testResults;

  const saveButtonHandler = () => {
    const currentIndex = fields.length - 1; // 항상 최신 인덱스 참조
    const currentField = fields[currentIndex];
    if (!platform) {
      setError(`autoRegisters.${autoIndex - 1}.platformName`, {
        type: 'required',
        message: '필수 선택 항목입니다.',
      });
      return;
    }

    if (platform === AutoRegisterPlatformServername.Jira) {
      if (!projectKey) {
        setError(`autoRegisters.${autoIndex - 1}.jira.projectKey`, {
          type: 'required',
          message: '필수 선택 항목입니다.',
        });
      }

      if (!mapping) {
        setError(`autoRegisters.${autoIndex - 1}.jira.mapping`, {
          type: 'required',
          message: '필수 선택 항목입니다.',
        });
      }

      const result = hasDuplicateTestResults(jiraMappingData);
      if (result) {
        setError(`autoRegisters.${autoIndex - 1}.jira.mapping`, {
          type: 'duplication',
          message: '결과 한개당 하나의 이슈만 등록 가능합니다.',
        });
      }
    }

    if (platform === AutoRegisterPlatformServername.Confluence) {
      if (!requestUrl) {
        setError(`autoRegisters.${autoIndex - 1}.confluence.requestUrl`, {
          message: '필수 입력 항목입니다.',
        });
      }

      if (testResult.length === 0) {
        setError(`autoRegisters.${autoIndex - 1}.confluence.testResults`, {
          message: '필수 선택 항목입니다.',
        });
      }
    }
    const noErrors = errors.autoRegisters === undefined;
    if (noErrors) {
      update(currentIndex, { ...currentField });
      setAutoRegistersList((prev) => [...prev, { ...fields[currentIndex] }]);
      setIsAddClicked(false);
      clearErrors('autoRegisters');
    }
  };

  const cancelButtonHandler = () => {
    setIsAddClicked(false);
    remove(autoIndex - 1);
    clearErrors('autoRegisters');
  };
  useEffect(() => {
    if (!platform) return;
    if (platform === AutoRegisterPlatformServername.Confluence) {
      update(autoIndex - 1, {
        platformName: platform,
        confluence: {
          requestUrl: '',
          testResults: [],
        },
        jira: undefined,
      });
    } else if (platform === AutoRegisterPlatformServername.Jira) {
      update(autoIndex - 1, {
        platformName: platform,
        jira: {
          projectKey: '',
          mapping: [{ testResult: '', issueType: '', priority: '' }],
        },
        confluence: undefined,
      });
    }
  }, [platform, update, autoIndex]);

  return (
    <ErrorBoundaryWrapper>
      <CommonDialog
        isOpen={isAddClicked}
        setIsOpen={setIsAddClicked}
        iconType={'add'}
        title={'Add Platform'}
        subTitle={''}
        onClose={cancelButtonHandler}
      >
        <div className="mt-5 space-y-4">
          {fields.map((autoModalField, index) => (
            <React.Fragment key={autoModalField.id}>
              <div>
                <AutoRegisterPlatformNameController
                  fields={fields}
                  update={update}
                  index={index}
                  filteredDataList={filteredDataList}
                />
                <ErrorMessage
                  name={`autoRegisters.${index}.platformName`}
                  errors={errors}
                  render={({ message }) => (
                    <p className="error-message mt-2">{message}</p>
                  )}
                />
              </div>
              <AutoRegisterPlatformInputList
                autoIndex={index}
                platform={platform}
                autoRegisters={autoRegisters}
              />
            </React.Fragment>
          ))}
          <CommonDialog.DialogButtonPanels>
            <CommonDialog.DialogButton
              variant="primary"
              type="button"
              onClick={saveButtonHandler}
            >
              Save
            </CommonDialog.DialogButton>
            <CommonDialog.DialogButton
              variant="secondary"
              type="button"
              onClick={cancelButtonHandler}
            >
              Cancel
            </CommonDialog.DialogButton>
          </CommonDialog.DialogButtonPanels>
        </div>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default IdeAutoRegisterModal;
