import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { z } from 'zod';

export interface DeviceSetting {
  ios_team_id: string | null;
}

export interface UpdateEmailSetting {
  dashboard_server_url?: string;
  smtp_port: string;
  smtp_server: string;
  smtp_username: string;
}

export interface UpdateSettings {
  key: string;
  value: string;
}

export const EmailSettingSchema = z.object({
  dashboard_server_url: z.string().url().optional(),
  smtp_port: z.number({ message: 'Port 번호를 입력해 주세요.' }),
  smtp_server: z.string().min(1, { message: 'Server 주소를 입력해 주세요.' }),
  smtp_username: z
    .string()
    .min(1, { message: 'Username을 입력해 주세요.' })
    .email({ message: '이메일 형식으로 입력해 주세요.' }),
});

export const UpdateSettingEmailSchema = EmailSettingSchema.pick({
  smtp_port: true,
  smtp_server: true,
  smtp_username: true,
}).extend({
  smtp_password: z.string().optional(),
});

export type EmailSetting = z.infer<typeof EmailSettingSchema>;
export type UpdateSettingEmail = z.infer<typeof UpdateSettingEmailSchema>;

export interface InstallMandatoryApp {
  udid: string;
  app_path: string;
  team_id: string;
}

export interface DerivedData {
  projectIdx: number;
  projectName: string;
  filePath: string;
  checksum: string;
  uuid: string;
}

export interface StatusDerivedData extends DerivedData {
  status: boolean;
}

export interface UploadDerivedDataVariables {
  projectIdx: number;
  filePath: string;
}
export interface Configuration {
  configuration: 'standard' | 'remote';
}
export interface ApplicationUrl extends Configuration {
  applicationUrl: string;
}

export type FormProperty = {
  label: string;
  id: keyof UpdateSettingEmail;
  type: React.HTMLInputTypeAttribute;
  register: UseFormRegisterReturn;
  placeholder?: string;
};
