import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

interface CalendarCustomInputProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  value?: string | number | readonly string[] | undefined;
  placeholderText: string;
  width?: 'w-full' | 'min-w-40';
}
const CalendarIconButton = forwardRef<
  HTMLButtonElement,
  CalendarCustomInputProps
>(({ onClick, value, placeholderText, width = 'min-w-40' }, ref) => {
  return (
    <button
      onClick={onClick}
      ref={ref}
      type="button"
      className={clsx(
        'flex h-10 items-center justify-between rounded-md border border-solid border-gray-300 px-3 py-2 text-sm font-normal leading-tight',
        value ? 'text-gray-900' : 'text-gray-400',
        width,
      )}
    >
      {value || placeholderText}
      <CalendarDaysIcon className="size-6 text-gray-900" />
    </button>
  );
});

CalendarIconButton.displayName = 'CalendarIconButton';

export default CalendarIconButton;
