import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import accessTokenReducer from '@store/api/accessTokenSlice';
import userInfoReducer from '@store/data/userInfoSlice';
import previousLinkReducer from '@store/data/previousLinkSlice';
import projectListReducer from '@store/data/projectListSlice';
import projectMemberDetailReducer from '@store/dashboard/project/projectMemberDetailSlice';

// IDE
import settingsReducer from '@store/ide/settings/settingsSlice';
import sessionIdReducer from '@store/ide/device/sessionIdSlice';
import selectedDevicesReducer from '@store/ide/device/selectedDevicesSlice';
import selectedBrowserReducer from '@store/ide/browser/selectedBrowserSlice';
import numberArrayReducer from '@store/ide/numberArraySlice';
import schedulerReducer from '@store/ide/scheduler/schedulerSlice';
import mobileScreenshotSourceReducer from '@store/ide/mobileTestStep/mobileScreenshotSourceSlice';
import pageSourcesReducer from '@store/ide/mobileTestStep/pageSourcesSlice';
import webTestStepInfoReducer from '@store/ide/webTestStep/webTestStepInfoSlice';
import refreshTokenReducer from '@store/api/refreshTokenSlice';
import mobileTestStepDetailReducer from '@store/ide/mobileTestStepDetail/mobileTestStepDetailSlice';
import webTestStepDetailReducer from '@store/ide/webTestStepDetail/webTestStepDetailSlice';
import createGroupReducer from '@store/ide/speedTestCase/CreateGroupSlice';
import unlockPatternReducer from '@store/ide/mobileTestStep/unlockPatternSlice';
import screenReducer from '@store/ide/mobileTestStep/screenSlice';
import uuidReducer from '@store/ide/mobileTestStep/uuidSlice';
import distributionExecutionReducer from '@store/ide/device/distributionExecutionSlice';
import selectedWebExecutionDataReducer from '@store/ide/browser/selectedWebExecutionDataSlice';
import remoteControlReducer from '@store/ide/remote/remoteControlSlice';
import remoteConnectionStatusReducer from '@store/ide/remote/remoteConnectionStatusSlice';
import remoteSettingsModalReducer from '@store/ide/remote/remoteSettingsModalSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const userInfoConfig = {
  key: 'userInfo',
  storage,
};

const previousLinkConfig = {
  key: 'previousLink',
  storage,
};

const projectListConfig = {
  key: 'projectList',
  storage,
};

const remoteControlConfig = {
  key: 'remoteControl',
  storage,
};

const persistedReducer = persistReducer(persistConfig, accessTokenReducer);

const persistedUserInfoReducer = persistReducer(
  userInfoConfig,
  userInfoReducer,
);
const persistedPreviousLinkReducer = persistReducer(
  previousLinkConfig,
  previousLinkReducer,
);
const persistedProjectListReducer = persistReducer(
  projectListConfig,
  projectListReducer,
);
const persistedRemoteControlReducer = persistReducer(
  remoteControlConfig,
  remoteControlReducer,
);

// settings option
const settingsPersistConfig = {
  key: 'settings',
  storage,
};

const persistedSettingsReducer = persistReducer(
  settingsPersistConfig,
  settingsReducer,
);

// device
const sessionIdPersistConfig = {
  key: 'sessionId',
  storage,
};

const persistedSessionIdReducer = persistReducer(
  sessionIdPersistConfig,
  sessionIdReducer,
);

const devicePersistConfig = {
  key: 'device',
  storage,
};

const persistedDeviceReducer = persistReducer(
  devicePersistConfig,
  selectedDevicesReducer,
);

const browserPersistConfig = {
  key: 'browser',
  storage,
};

const persistedBrowserReducer = persistReducer(
  browserPersistConfig,
  selectedBrowserReducer,
);

const mobileScreenshotSourcePersistConfig = {
  key: 'mobile',
  storage,
};

const persistedMobileScreenshotSourceReducer = persistReducer(
  mobileScreenshotSourcePersistConfig,
  mobileScreenshotSourceReducer,
);

const webTestStepInfoPersistConfig = {
  key: 'webTestStepInfo',
  storage,
};

const persistedWebTestStepInfoReducer = persistReducer(
  webTestStepInfoPersistConfig,
  webTestStepInfoReducer,
);

export const store = configureStore({
  reducer: {
    accessTokenValue: persistedReducer,
    userInfo: persistedUserInfoReducer,
    previousLink: persistedPreviousLinkReducer,
    projectList: persistedProjectListReducer,
    remoteControl: persistedRemoteControlReducer,
    sessionId: persistedSessionIdReducer,
    device: persistedDeviceReducer,
    browser: persistedBrowserReducer,
    mobileScreenshotSource: persistedMobileScreenshotSourceReducer,
    pageSources: pageSourcesReducer,
    webTestStepInfo: persistedWebTestStepInfoReducer,
    settings: persistedSettingsReducer,
    mobileTestStepDetail: mobileTestStepDetailReducer,
    webTestStepDetail: webTestStepDetailReducer,
    unlockPattern: unlockPatternReducer,
    screen: screenReducer,
    uuid: uuidReducer,
    distributionExecution: distributionExecutionReducer,
    webExecutionData: selectedWebExecutionDataReducer,
    projectMemberDetail: projectMemberDetailReducer,

    numberArray: numberArrayReducer,
    scheduler: schedulerReducer,
    refreshToken: refreshTokenReducer,
    createGroup: createGroupReducer,
    remoteConnection: remoteConnectionStatusReducer,
    remoteSettingsModal: remoteSettingsModalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
