import React, { useEffect } from 'react';
import clsx from 'clsx';
import { PlusIcon } from '@heroicons/react/20/solid';
import { Button } from '@autosquare/common';
import {
  AutoRegisterType,
  CreateAdminProjectData,
} from '@customTypes/dashboard/admin/type';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';
import AutoRegisterList from '@components/AutoRegisterList/AutoRegisterList';

type Props = {
  isToggleOn: boolean;
  autoRegisterDataList: AutoRegisterType[];
  setIsEditModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCaution: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAddClicked: React.Dispatch<React.SetStateAction<boolean>>;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
};

const AutoRegisterAddPlatform = ({
  isToggleOn,
  autoRegisterDataList,
  setIsEditModalOpened,
  setOpenCaution,
  setIsAddClicked,
  setIndex,
}: Props) => {
  const {
    formState: { errors },
    clearErrors,
  } = useFormContext<CreateAdminProjectData>();

  useEffect(() => {
    if (errors.registers && autoRegisterDataList.length > 0) {
      clearErrors('registers');
    }
  }, [clearErrors, autoRegisterDataList, errors.registers]);
  return (
    <div
      className={clsx(
        'transition duration-700 ease-in-out',
        isToggleOn ? 'opacity-100' : 'max-h-0 opacity-0',
      )}
    >
      <div
        className={clsx(
          'flex w-full items-center gap-x-4 sm:space-y-0 sm:pb-0',
          errors.registers ? '' : 'pb-12',
        )}
      >
        <div className="text-sm font-medium leading-normal text-gray-900">
          Platform*
        </div>
        {autoRegisterDataList.length > 0 && (
          <AutoRegisterList
            autoDataRegisterList={autoRegisterDataList}
            setIndex={setIndex}
            setIsEditModalOpened={setIsEditModalOpened}
            setOpenCaution={setOpenCaution}
          />
        )}
        <Button
          type="button"
          variant="imageOriginal"
          className="flex items-center justify-center"
          onClick={() => setIsAddClicked(true)}
        >
          <PlusIcon className="size-6" />
          <p className="text-sm font-normal text-gray-900">Add</p>
        </Button>
      </div>
      <ErrorMessage
        name="registers"
        errors={errors}
        render={({ message }) => (
          <p className="standard-error-message ml-24">{message}</p>
        )}
      />
    </div>
  );
};

export default AutoRegisterAddPlatform;
