import { ErrorBoundaryWrapper, PagingHeadings } from '@autosquare/common';
import React from 'react';
import { useLocation } from 'react-router-dom';
import AdminRequestList from './components/AdminRequestTable';

const AdminDeviceApproval = () => {
  const { pathname } = useLocation();

  const adminDeviceListPage = pathname.split('/').slice(0, -1).join('/');

  const breadcrumbs = [
    { title: 'Administration', href: adminDeviceListPage },
    { title: 'Devices', href: adminDeviceListPage },
    { title: 'List', href: adminDeviceListPage },
    { title: 'Approval', href: pathname },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={'Approval Requests'}
      />
      <h2 className="py-1 text-sm font-normal leading-normal text-gray-600">
        Project Admin이 요청한 디바이스 등록 및 삭제 요청을 검토하고 승인할 수
        있습니다.
      </h2>
      <AdminRequestList />
    </ErrorBoundaryWrapper>
  );
};

export default AdminDeviceApproval;
