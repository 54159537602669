import { Button } from '@autosquare/common';
import {
  CommonDialog,
  DialogButtonPanels,
  ErrorDialog,
} from '@components/Dialog';
import { useDeleteAdminDeviceMutation } from '@lib/api/dashboard/queryHooks';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

type Props = {
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const DeleteDialog = ({ isDeleteModalOpen, setIsDeleteModalOpen }: Props) => {
  const [searchParams] = useSearchParams();
  const idx = Number(searchParams.get('idx'));

  const [isDeleteErrorModalOpen, setIsDeleteErrorModalOpen] = useState(false);

  const deleteAdminDeviceMutation = useDeleteAdminDeviceMutation();

  const deleteOnClick = () => {
    deleteAdminDeviceMutation.mutate([idx], {
      onError: () => {
        setIsDeleteErrorModalOpen(true);
      },
      onSettled: () => {
        setIsDeleteModalOpen(false);
      },
    });
  };

  return (
    <>
      {/* 삭제 모달 */}
      <CommonDialog
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        iconType={'caution'}
        title={'Delete Device'}
        subTitle={'해당 디바이스를 삭제하시겠습니까?'}
      >
        <DialogButtonPanels>
          <Button
            type="button"
            variant="caution"
            onClick={deleteOnClick}
            disabled={deleteAdminDeviceMutation.isLoading}
          >
            Delete
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={() => setIsDeleteModalOpen(false)}
          >
            Cancel
          </Button>
        </DialogButtonPanels>
      </CommonDialog>

      {/* 삭제 실패 Dialog */}
      <ErrorDialog
        isOpen={isDeleteErrorModalOpen}
        setIsOpen={setIsDeleteErrorModalOpen}
        subTitle={deleteAdminDeviceMutation.error?.message}
      />
    </>
  );
};

export default DeleteDialog;
