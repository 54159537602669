import {
  Checkbox,
  Detail,
  ErrorMessage,
  Execution,
  TableBodyBasic,
  TableBodyContainer,
  TableHeader,
  TableHeaderContainer,
  TableNoList,
  TableWrapper,
  Time,
  useCheckbox,
} from '@autosquare/common';
import NoListResultMessage from '@components/Search/NoListResultMessage';
import TableSkeleton from '@components/Skeleton/TableSkeleton';

import { useSearchWebSpeedTestCaseListQuery } from '@lib/api/ide/speedWeb/webSpeedTestCase';

import { timePattern } from '@utils/static/timePattern';
import dayjs from 'dayjs';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ExecutionContainerAndErrorDialog from './WebSpeedTestCaseList/ExecutionContainerAndErrorDialog';

const WebSpeedTestCaseList = () => {
  const navigate = useNavigate();

  const [isTestExecutionOpened, setIsTestExecutionOpened] = useState(false);
  const [testCaseIdx, setTestCaseIdx] = useState<number>(0);

  const searchWebSpeedTestCaseListQuery = useSearchWebSpeedTestCaseListQuery();
  const searchWebSpeedTestCaseList = searchWebSpeedTestCaseListQuery.data;

  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(searchWebSpeedTestCaseList, 'idx');

  const headerList = [
    {
      idx: 1,
      title: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={selectAllArray}
          key="selected-checkbox"
        />
      ),
    },
    {
      idx: 2,
      title: selectedArray.length > 0 ? <div className="w-5" /> : 'No',
    },
    {
      idx: 3,
      title:
        selectedArray.length > 0 ? (
          <div className="w-[4.625rem]" />
        ) : (
          'Test Group'
        ),
    },
    { idx: 4, title: 'Service Group' },
    { idx: 5, title: 'Title' },
    { idx: 6, title: 'Priority' },
    { idx: 7, title: 'Creator' },
    { idx: 8, title: 'Created at', isSortable: true },
    { idx: 9, title: '' },
    { idx: 10, title: '' },
  ];

  return (
    <>
      <TableWrapper selectedArray={selectedArray} openDialog={openDialog}>
        <TableHeaderContainer>
          {headerList.map((header) => (
            <TableHeader key={header.idx} isSortable={header.isSortable}>
              {header.title}
            </TableHeader>
          ))}
        </TableHeaderContainer>
        <TableBodyContainer>
          {searchWebSpeedTestCaseListQuery.isLoading ||
          searchWebSpeedTestCaseListQuery.isFetching ? (
            <TableSkeleton dataLength={headerList.length} />
          ) : searchWebSpeedTestCaseListQuery.isError ? (
            <TableNoList colSpan={headerList.length}>
              <ErrorMessage>
                {searchWebSpeedTestCaseListQuery.error.message}
              </ErrorMessage>
            </TableNoList>
          ) : searchWebSpeedTestCaseList?.length === 0 ? (
            <NoListResultMessage
              colSpan={headerList.length}
              testType={'case'}
            />
          ) : (
            searchWebSpeedTestCaseList?.map((webSpeedTestCase, index) => (
              <tr key={webSpeedTestCase.idx}>
                <TableBodyBasic>
                  <Checkbox
                    checked={selectedArray.includes(webSpeedTestCase.idx)}
                    onChange={(e) => selectArray(webSpeedTestCase.idx, e)}
                  />
                </TableBodyBasic>
                <TableBodyBasic>{index + 1}</TableBodyBasic>
                <TableBodyBasic>
                  {webSpeedTestCase.caseGroupInfo?.name || '-'}
                </TableBodyBasic>
                <TableBodyBasic>
                  {webSpeedTestCase.serviceGroupInfo?.name || '-'}
                </TableBodyBasic>
                <td className="table-data-basic max-w-xs">
                  <div className="font-medium text-gray-900">
                    {webSpeedTestCase.title}
                  </div>
                  <div className="mt-1 w-full break-words text-gray-500">
                    {webSpeedTestCase.description}
                  </div>
                </td>
                <TableBodyBasic>{webSpeedTestCase.priority}</TableBodyBasic>
                <TableBodyBasic>{webSpeedTestCase.creator}</TableBodyBasic>
                <TableBodyBasic>
                  <div>
                    {dayjs(webSpeedTestCase?.createdAt).format('YYYY-MM-DD') ??
                      '-'}
                  </div>
                  <div
                    className={`mt-1 flex items-center justify-start gap-2 text-gray-500`}
                  >
                    {webSpeedTestCase.updatedAt && <Time />}
                    {RegExp(timePattern).exec(webSpeedTestCase.updatedAt)[0]}
                  </div>
                </TableBodyBasic>
                <TableBodyBasic>
                  <Execution
                    testType="case"
                    disabled={!webSpeedTestCase.hasStep}
                    tooltipLocation={'right'}
                    onClick={() => {
                      if (webSpeedTestCase.hasStep) {
                        setTestCaseIdx(webSpeedTestCase.idx);
                        setIsTestExecutionOpened(true);
                      }
                    }}
                  />
                </TableBodyBasic>
                <TableBodyBasic>
                  <Detail
                    onClick={() =>
                      navigate({
                        pathname: `detail`,
                        search: `idx=${webSpeedTestCase.idx}`,
                      })
                    }
                  />
                </TableBodyBasic>
              </tr>
            ))
          )}
        </TableBodyContainer>
      </TableWrapper>
      <ExecutionContainerAndErrorDialog
        isTestExecutionOpened={isTestExecutionOpened}
        setIsTestExecutionOpened={setIsTestExecutionOpened}
        testCaseIdx={testCaseIdx}
        isOpenedCautionDialog={isOpenedCautionDialog}
        setIsOpenedCautionDialog={setIsOpenedCautionDialog}
        closeDialog={closeDialog}
        selectedArray={selectedArray}
        resetSelectedArray={resetSelectedArray}
      />
    </>
  );
};

export default WebSpeedTestCaseList;
