import React, { useState } from 'react';
import clsx from 'clsx';
import {
  findWebCommandNameFromServerName,
  WebCommandOptionServerName,
} from '@utils/static/webCommandOptions';

import { WebTestStepData } from '@customTypes/testStep/type';

import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Draggable } from 'react-beautiful-dnd';

import WebTestStepListDetails from './WebTestStepListCard/WebTestStepListDetails';
import { getWebAddTestStepListValue } from '@utils/static/webTestStep/addTestStepListValue';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Props = {
  index: number;
  allWebTestStepList: WebTestStepData;
};

const WebTestStepListCard = ({ index, allWebTestStepList }: Props) => {
  const [isOpenedDetail, setIsOpenedDetails] = useState(false);

  const toggledDetails = () => {
    setIsOpenedDetails(!isOpenedDetail);
  };

  const value = getWebAddTestStepListValue(allWebTestStepList);

  return (
    <ErrorBoundaryWrapper>
      <Draggable draggableId={String(allWebTestStepList.idx)} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onDragStart={() => setIsOpenedDetails(false)}
          >
            <div className="mb-2 flex w-full flex-row">
              <div className="flex w-full flex-col">
                <div
                  className={clsx(
                    'flex w-full justify-between border border-gray-200 bg-gray-50 p-3 text-left text-xs font-medium text-gray-900',
                    { 'border-b-0': isOpenedDetail },
                  )}
                  onClick={toggledDetails}
                >
                  <div className="flex w-[calc(100%-1.25rem)] flex-row items-center">
                    <div className="border-r border-r-gray-200 pr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="size-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6 4.75A.75.75 0 016.75 4h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 4.75zM6 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 10zm0 5.25a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75a.75.75 0 01-.75-.75zM1.99 4.75a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1v-.01zM1.99 15.25a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1v-.01zM1.99 10a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1V10z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="w-full">
                      <div className="ml-3 w-[calc(100%-2rem)] truncate">
                        {`${
                          allWebTestStepList.description
                        }, ${findWebCommandNameFromServerName(
                          allWebTestStepList.command,
                        )}${allWebTestStepList.command === WebCommandOptionServerName.IF ? '' : `, ${value}`}`}
                      </div>
                    </div>
                  </div>
                  <div className="size-5">
                    <ChevronDownIcon
                      className={clsx('size-5 cursor-pointer text-gray-900', {
                        'rotate-180': isOpenedDetail,
                      })}
                    />
                  </div>
                </div>
                {isOpenedDetail && (
                  <WebTestStepListDetails
                    allWebTestStepList={allWebTestStepList}
                    value={value}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </Draggable>
    </ErrorBoundaryWrapper>
  );
};

export default WebTestStepListCard;
