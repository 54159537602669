import { useWebTestStepListQuery } from '@lib/api/ide/web/webTestStep';
import { findTitleFromIdx } from '../findTitleFromIdx';
import {
  findKeyboardEventNameFromServerName,
  KeyboardEventServerName,
} from '../keyboardEventList';
import { WebCommandOptionServerName } from '../webCommandOptions';
import { useWebTestCaseListQuery } from '@lib/api/ide/web/webTestCase';
import { WebTestStepData } from '@customTypes/testStep/type';
import { findBrowserKeyNameFromServerName } from '../browserKeyList';
import { CompareBy } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { useRemoteWebTestCaseListQuery } from '@lib/api/dashboard/web/webTestCase';
import { useRemoteWebTestStepListQuery } from '@lib/api/dashboard/web/webTestStep';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { useGetWebTestCaseDataAutomation } from '@lib/api/ide/web/webTestAutomation';

export const getWebTestStepListValue = (testStepList: WebTestStepData) => {
  const isIde = isWordInLocationPathname('ide/');
  const isRemote = isWordInLocationPathname('remote/');

  const { data: ideWebTestStepList } = useWebTestStepListQuery(undefined, {
    enabled: isIde,
  });
  const { data: ideWebTestCaseList } = useWebTestCaseListQuery({
    enabled: isIde,
  });

  const { data: remoteWebTestStepList } = useRemoteWebTestStepListQuery(
    undefined,
    { enabled: isRemote },
  );
  const { data: remoteWebTestCaseList } = useRemoteWebTestCaseListQuery({
    enabled: isRemote,
  });

  const webTestStepList = isIde
    ? ideWebTestStepList
    : isRemote && remoteWebTestStepList;

  const webTestCaseList = isIde
    ? ideWebTestCaseList
    : isRemote && remoteWebTestCaseList;

  const importTestStepTitle = findTitleFromIdx(
    Number(testStepList.value),
    webTestCaseList,
  );
  const importTestStepCount =
    testStepList.optionsDto.stepIdxList?.split(',').length;

  const targetTextNum =
    webTestStepList
      .map((item) => item.idx)
      .indexOf(Number(testStepList.optionsDto.compareIdx)) + 1;
  const isSecret = testStepList.optionsDto.secret === 'true';
  const isTextEncrypt = testStepList.optionsDto.textEncrypt === 'true';

  // input text 에서 Data Automation 유무 확인 로직
  const { data: webTestCaseDataAutomation } = useGetWebTestCaseDataAutomation({
    enabled: testStepList.command === WebCommandOptionServerName.InputText,
  });

  switch (testStepList.command) {
    case WebCommandOptionServerName.Click:
    case WebCommandOptionServerName.SaveText:
      if (isSecret) {
        return '******';
      } else {
        return testStepList.value;
      }

    case WebCommandOptionServerName.DataValidation:
    case WebCommandOptionServerName.GetUrl:
      return testStepList.value;

    case WebCommandOptionServerName.ImportTestStep:
      return `${importTestStepTitle} / ${importTestStepCount}`;

    case WebCommandOptionServerName.ImportTestCase:
      return findTitleFromIdx(Number(testStepList.value), webTestCaseList);

    case WebCommandOptionServerName.KeyBoardEvent:
      return findKeyboardEventNameFromServerName(
        testStepList.value as KeyboardEventServerName,
      );

    case WebCommandOptionServerName.BrowserKey:
      return findBrowserKeyNameFromServerName(testStepList.value);

    case WebCommandOptionServerName.IF:
      return testStepList.optionsDto.conditionValue;

    case WebCommandOptionServerName.ScrollAndSwipe:
      return `[${testStepList.value}]
  Repeat=${testStepList.optionsDto.loop}`;

    case WebCommandOptionServerName.InputText:
      if (testStepList.optionsDto.compareBy === CompareBy.Automation) {
        return `${testStepList.value}/${webTestCaseDataAutomation?.idx ? 'Automation' : 'None'}`;
      }
      return testStepList.value;

    default:
      if (testStepList.optionsDto.compareBy === 'id') {
        if (isSecret) {
          return `******\ntarget text=#${targetTextNum}`;
        } else {
          return `${testStepList.value}\ntarget text=#${targetTextNum}`;
        }
      } else if (testStepList.optionsDto.compareBy === 'text') {
        if (isSecret) {
          return `******\ntarget text="**********"`;
        }
        if (isTextEncrypt) {
          return `${testStepList.value}\ntarget text="**********"`;
        }
        return `${testStepList.value}\ntarget text="${testStepList.optionsDto.compareText}"`;
      } else {
        return testStepList.value;
      }
  }
};
