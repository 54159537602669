import {
  ErrorBoundaryWrapper,
  SectionTitleContainer,
  SectionTitleHeader,
  SectionTitleHeaderContainer,
  SectionTitleHeaderDescription,
  TableContainer,
  useCheckbox,
} from '@autosquare/common';
import {
  ReservationDeviceForm,
  ReservationDeviceInfo,
} from '@customTypes/index';
import { SlashIcon } from '@heroicons/react/24/outline';
import { useGetAvailableDeviceListMutation } from '@lib/api/ide/queryHooks';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import AvailableDevicesTableHeader from './AvailableDevices/AvailableDevicesTableHeader';
import AvailableDevicesTableBody from './AvailableDevices/AvailableDevicesTableBody';
import {
  CommonDialog,
  DialogButton,
  DialogButtonPanels,
} from '@components/Dialog';

const AvailableDevices = () => {
  const [availableDeviceList, setAvailableDeviceList] = useState<
    ReservationDeviceInfo[]
  >([]);
  const [headerLength, setHeaderLength] = useState<number>(0);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const { selectAllArray, selectArray, selectedArray } = useCheckbox(
    availableDeviceList,
    'deviceIdx',
  );

  const { watch, setValue } = useFormContext<ReservationDeviceForm>();

  const projectIdx = watch('projectIdx');
  const startTime = watch('startTime');
  const endTime = watch('endTime');
  const deviceIdxList = watch('deviceIdx');

  const getAvailableDeviceListMutation = useGetAvailableDeviceListMutation();

  useEffect(() => {
    if (!projectIdx || !startTime || !endTime) return;

    const availableData = {
      projectIdx,
      startDate: dayjs(startTime).format('YYYY-MM-DD'),
      endDate: dayjs(endTime).format('YYYY-MM-DD'),
      startTime: dayjs(startTime).format('HH:mm'),
      endTime: dayjs(endTime).format('HH:mm'),
    };

    getAvailableDeviceListMutation.mutate(availableData, {
      onSuccess: (response) => {
        setAvailableDeviceList(response);
      },
      onError: () => {
        setIsErrorDialogOpen(true);
      },
    });
  }, [projectIdx, endTime, startTime]);

  useEffect(() => {
    setValue('deviceIdx', selectedArray);
  }, [selectedArray, setValue]);

  return (
    <ErrorBoundaryWrapper>
      <section>
        <SectionTitleContainer>
          <SectionTitleHeaderContainer>
            <SectionTitleHeader>Available Devices</SectionTitleHeader>
            <SectionTitleHeaderDescription>
              선택한 날짜와 시간에 사용할 디바이스를 목록에서 선택해 주세요.
            </SectionTitleHeaderDescription>
          </SectionTitleHeaderContainer>
          <div className="flex items-center gap-2">
            <p>{`Selected: ${deviceIdxList.length}`}</p>
            <SlashIcon className="size-5 text-gray-500" />
            <p>{`Total: ${availableDeviceList.length}`}</p>
          </div>
        </SectionTitleContainer>
        <TableContainer>
          <AvailableDevicesTableHeader
            availableDeviceList={availableDeviceList}
            setHeaderLength={setHeaderLength}
            selectedArray={selectedArray}
            selectAllArray={selectAllArray}
          />
          <AvailableDevicesTableBody
            headerLength={headerLength}
            availableDeviceList={availableDeviceList}
            selectedArray={selectedArray}
            selectArray={selectArray}
          />
        </TableContainer>
      </section>
      <CommonDialog
        isOpen={isErrorDialogOpen}
        setIsOpen={setIsErrorDialogOpen}
        iconType={'caution'}
        title={'Failure'}
        subTitle={getAvailableDeviceListMutation.error?.message}
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant="secondary"
            onClick={() => setIsErrorDialogOpen(false)}
          >
            Cancel
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default AvailableDevices;
