import {
  CommonDialog,
  DialogButton,
  DialogButtonPanels,
} from '@components/Dialog';
import { useGetWebSocketMessage } from '@hooks/electron/useGetWebSocketMessage';
import React from 'react';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsExecutionStarted: React.Dispatch<React.SetStateAction<boolean>>;
};

const SpeedTestStepExecutionResultDialog = ({
  isModalOpen,
  setIsModalOpen,
  setIsExecutionStarted,
}: Props) => {
  const { testStepResult } = useGetWebSocketMessage();

  const onClose = () => {
    setIsModalOpen(false);
    setIsExecutionStarted(false);
  };

  if (testStepResult?.result === 'Pass' && !testStepResult?.load_speed) {
    return (
      <CommonDialog
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        iconType={'checked'}
        title={'Execute Success'}
        subTitle={'테스트 결과: Pass'}
        onClose={onClose}
      >
        <DialogButtonPanels>
          <DialogButton type="button" variant="primary" onClick={onClose}>
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
    );
  }

  if (testStepResult?.result === 'Fail' && testStepResult?.fail_reason) {
    return (
      <CommonDialog
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        iconType={'caution'}
        title={'Execute Failure'}
        subTitle={`테스트 결과: Fail\n실패 사유: ${testStepResult?.fail_reason}`}
        onClose={onClose}
      >
        <DialogButtonPanels>
          <DialogButton type="button" variant="primary" onClick={onClose}>
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
    );
  }

  if (testStepResult?.result === 'N/A' && testStepResult?.fail_reason) {
    return (
      <CommonDialog
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        iconType={'yellow caution'}
        title={'Execute Error'}
        subTitle={`테스트 결과: N/A\n실패 사유: ${testStepResult?.fail_reason}`}
        onClose={onClose}
      >
        <DialogButtonPanels>
          <DialogButton type="button" variant="primary" onClick={onClose}>
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
    );
  }

  return null;
};

export default SpeedTestStepExecutionResultDialog;
