import {
  Breadcrumbs,
  ErrorBoundaryWrapper,
  ErrorMessage,
  PagingHeadingsNavigate,
} from '@autosquare/common';

import React from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { useWebTestCaseDetailQuery } from '@lib/api/ide/web/webTestCase';

import WebTestCaseDetailInfoProvider from '@contexts/ide/WebTestCaseDetailInfoProvider';

import WebTestStepList from './components/WebTestStepList';
import WebTestCaseInfo from './components/WebTestCaseInfo';

const WebTestCaseDetail = () => {
  const { pathname, search } = useLocation();
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const webTestCaseDetailQuery = useWebTestCaseDetailQuery(idx);

  const breadcrumbs: Breadcrumbs[] = [
    {
      title: 'UI Test',
      href: `${pathname.split('/').slice(0, -3).join('/')}/overview`,
    },
    { title: 'Web', href: `/ide/projects/${projectIdx}/ui-test/web/test-case` },
    {
      title: 'Test Case',
      href: `/ide/projects/${projectIdx}/ui-test/web/test-case`,
    },
    { title: 'Detail', href: pathname + search },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Case Detail'}
      />
      {webTestCaseDetailQuery.isError ? (
        <ErrorMessage>{webTestCaseDetailQuery.error.message}</ErrorMessage>
      ) : (
        <ErrorBoundaryWrapper>
          <WebTestCaseDetailInfoProvider>
            <WebTestCaseInfo />
          </WebTestCaseDetailInfoProvider>
          <WebTestStepList />
        </ErrorBoundaryWrapper>
      )}
    </ErrorBoundaryWrapper>
  );
};

export default WebTestCaseDetail;
