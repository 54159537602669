import React from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';

import { RootState } from '@app/store';

import { useUpdateTestStepMutation } from '@lib/api/ide/mobile/mobileTestStep';

import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';
import { ClickId } from '@utils/static/clickOptions';

import DefinitionList from '@components/shared/DefinitionList';
import DefinitionInputOrTextAreaByReactHookForm from '@components/shared/TestStepDetail/DefinitionInputOrTextAreaByReactHookForm';
import TestStepDetailEditButton from '@components/shared/TestStepDetail/TestStepDetailEditButton';
import DefinitionInputByReactHookForm from '@components/shared/TestStepDetail/DefinitionInputByReactHookForm';

import {
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { SaveTextFrom } from '@customTypes/ide/mobileTestStep/SaveTextFrom';

import TestStepValue from './TestStepDetailEdit/TestStepValue';
import EditLoop from './TestStepDetailEdit/EditLoop';
import EditIfCommand from './TestStepDetailEdit/EditIfCommand';
import ClickTextReplace from './TestStepDetailEdit/ClickTextReplace';
import EditSaveText from './TestStepDetailEdit/EditSaveText';
import UnlockPatternEdit from './TestStepDetailEdit/UnlockPatternEdit';
import useTestStepEditFormHandler from '@hooks/testStep/useTestStepEditFormHandler';
import { useUpdateSpeedMobileTestStep } from '@lib/api/ide/speedMobile/speedMobileTestStep';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import EditDataValidation from './TestStepDetailEdit/EditDataValidation';
import TestStepEditMaskingOptions from '../../shared/TestStepEditMaskingOptions';
import { ErrorBoundaryWrapper } from '@autosquare/common';

interface Props {
  setIsEdited: React.Dispatch<React.SetStateAction<boolean>>;
  mobileTestStepData: MobileTestStepData | MobileTestStepData;
  isEdited: boolean;
}

const TestStepDetailEdit = ({ setIsEdited, mobileTestStepData }: Props) => {
  const testStepIdx = useSelector(
    (state: RootState) => state.mobileTestStepDetail.testStepIdx,
  );
  const isUiTestLocation = isWordInLocationPathname('ui-test');

  const updateTestStepMutation = isUiTestLocation
    ? useUpdateTestStepMutation(testStepIdx)
    : useUpdateSpeedMobileTestStep(testStepIdx);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { idx, ...restMobileTestStepData } = mobileTestStepData;
  const methods = useForm<UpdateTestStepData>({
    defaultValues: restMobileTestStepData,
  });

  const { onSubmit, onError } = useTestStepEditFormHandler(
    methods,
    updateTestStepMutation,
    setIsEdited,
  );

  return (
    <ErrorBoundaryWrapper>
      <FormProvider {...methods}>
        <form noValidate onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <TestStepDetailEditButton
            setIsEdited={setIsEdited}
            updateTestStepMutation={updateTestStepMutation}
          />
          <div className="divide-y divide-gray-200">
            <div className="items-center px-4 py-5 sm:p-0">
              <dl className="space-y-8 sm:space-y-0">
                <DefinitionList
                  term={
                    mobileTestStepData.mobileOs === DeviceInfoOs.Aos
                      ? 'App Package Name'
                      : 'App Bundle Identifier'
                  }
                  description={mobileTestStepData.packageName}
                />
                <DefinitionInputByReactHookForm
                  term={'App Version*'}
                  name={'appVersion'}
                  type={'input'}
                  register={methods.register('appVersion', {
                    required: 'App Version을 입력해주세요.',
                  })}
                />
                <DefinitionList
                  term={'Command'}
                  description={findMobileCommandNameFromServerName(
                    mobileTestStepData.command,
                  )}
                />
                <DefinitionInputByReactHookForm
                  term={'Description*'}
                  name={'description'}
                  type={'text'}
                  isBorderNeeded={
                    mobileTestStepData.command !==
                    MobileCommandOptionServerName.ScrollSwipe
                  }
                  register={methods.register('description', {
                    required:
                      'Description은 1자에서 200자 이내로 입력해 주세요.',
                    maxLength: {
                      value: 200,
                      message:
                        'Description은 1자에서 200자 이내로 입력해 주세요.',
                    },
                  })}
                />
                {mobileTestStepData.command !==
                  MobileCommandOptionServerName.Loop &&
                  mobileTestStepData.command !==
                    MobileCommandOptionServerName.IF &&
                  mobileTestStepData?.command !==
                    MobileCommandOptionServerName.UnlockPattern &&
                  !(
                    mobileTestStepData?.command ===
                      MobileCommandOptionServerName.SaveText &&
                    mobileTestStepData.optionsDto?.from === SaveTextFrom.Sms
                  ) && <TestStepValue mobileTestStep={mobileTestStepData} />}
                {mobileTestStepData.command ===
                  MobileCommandOptionServerName.UnlockPattern && (
                  <UnlockPatternEdit />
                )}
                {mobileTestStepData.command ===
                  MobileCommandOptionServerName.Click &&
                mobileTestStepData.optionsDto.clickBy === ClickId.Default ? (
                  <ClickTextReplace mobileTestStepData={mobileTestStepData} />
                ) : mobileTestStepData.command ===
                  MobileCommandOptionServerName.DataValidation ? (
                  <EditDataValidation mobileTestStepData={mobileTestStepData} />
                ) : (
                  mobileTestStepData.command ===
                    MobileCommandOptionServerName.SaveText && (
                    <EditSaveText mobileTestStepData={mobileTestStepData} />
                  )
                )}
                {mobileTestStepData.command ===
                MobileCommandOptionServerName.ScrollSwipe ? (
                  <>
                    <DefinitionInputByReactHookForm
                      term={'Repeat*'}
                      name={'optionsDto.repeatLimit'}
                      type={'number'}
                      min={1}
                      max={99}
                      register={methods.register('optionsDto.repeatLimit', {
                        required: 'Repeat 값을 최소 1부터 입력해 주세요.',
                        min: {
                          value: 1,
                          message: 'Repeat 값을 최소 1부터 입력해 주세요.',
                        },
                        max: {
                          value: 99,
                          message: 'Repeat 값은 최대 99까지 입력 가능합니다.',
                        },
                      })}
                    />
                    <TestStepEditMaskingOptions />
                  </>
                ) : (
                  mobileTestStepData.command !==
                    MobileCommandOptionServerName.Loop &&
                  mobileTestStepData.command !==
                    MobileCommandOptionServerName.IF &&
                  mobileTestStepData.command !==
                    MobileCommandOptionServerName.Wait && (
                    <>
                      <TestStepEditMaskingOptions />
                      <DefinitionInputOrTextAreaByReactHookForm
                        term={'Required'}
                        name={'required'}
                        type={'checkbox'}
                      />
                    </>
                  )
                )}
                {mobileTestStepData.command ===
                MobileCommandOptionServerName.Loop ? (
                  <EditLoop mobileTestStep={mobileTestStepData} />
                ) : (
                  mobileTestStepData.command ===
                    MobileCommandOptionServerName.IF && (
                    <EditIfCommand mobileTestStepData={mobileTestStepData} />
                  )
                )}
                {mobileTestStepData.command !==
                  MobileCommandOptionServerName.Wait &&
                  (mobileTestStepData.command !==
                    MobileCommandOptionServerName.Loop ||
                    methods.watch('value') !==
                      MobileCommandOptionServerName.Wait) && (
                    <DefinitionInputByReactHookForm
                      term={'WaitTime*'}
                      name={'optionsDto.waitingTime'}
                      type={'number'}
                      register={methods.register('optionsDto.waitingTime', {
                        valueAsNumber: true,
                        min: {
                          value: 0,
                          message: '최소 0이상 숫자만 입력 가능합니다.',
                        },
                        max: {
                          value: 60,
                          message: '최대 60이하 숫자만 입력 가능합니다.',
                        },
                        required: 'WaitTime을 최소 0부터 입력해 주세요.',
                      })}
                    />
                  )}
              </dl>
            </div>
          </div>
        </form>
      </FormProvider>
    </ErrorBoundaryWrapper>
  );
};

export default TestStepDetailEdit;
