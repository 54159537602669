import {
  SectionTitleContainer,
  SectionTitleHeader,
  SectionTitleHeaderContainer,
  SectionTitleHeaderDescription,
} from '@autosquare/common';
import { useInstalledAppVersionListQuery } from '@lib/api/dashboard/queryHooks';
import clsx from 'clsx';
import React from 'react';

const InstalledAppHeader = () => {
  const {
    data: installedAppVersionList,
    isSuccess,
    isLoading,
  } = useInstalledAppVersionListQuery();

  const isConnected = installedAppVersionList?.connected;

  return (
    <SectionTitleContainer>
      <SectionTitleHeaderContainer>
        <SectionTitleHeader>App Information</SectionTitleHeader>
        <SectionTitleHeaderDescription>
          디바이스에 설치된 애플리케이션의 정보를 확인할 수 있습니다.
        </SectionTitleHeaderDescription>
      </SectionTitleHeaderContainer>
      {isLoading ? (
        <div className="flex animate-pulse items-center gap-2 rounded-md bg-gray-200 px-3 py-2 text-sm font-semibold leading-tight text-gray-200">
          Loading...
        </div>
      ) : (
        isSuccess && (
          <div
            className={clsx(
              'flex items-center gap-2 rounded-md px-3 py-2 text-sm font-semibold leading-tight',
              isConnected
                ? 'bg-blue-200 text-blue-900'
                : 'bg-red-100 text-red-500',
            )}
          >
            {isConnected ? 'Connected' : 'Disconnected'}
          </div>
        )
      )}
    </SectionTitleContainer>
  );
};

export default InstalledAppHeader;
