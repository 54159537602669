import React, { useEffect } from 'react';
import {
  CreateAdminProjectData,
  MessengerPlatformRequiredInfo,
} from '@customTypes/dashboard/admin/type';
import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

type Props = {
  messengerList: MessengerPlatformRequiredInfo[];
};

const MessengerPlatform = ({ messengerList }: Props) => {
  const {
    control,
    watch,
    resetField,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateAdminProjectData>();

  //To do: teams 추가, slack 추가될 경우 수정 예정
  const filteredMessengerList = messengerList?.filter(
    (item) => item.platformCode !== 'MSGR02' && item.platformCode !== 'MSGR99',
  );
  const platform = watch('messengers.0.platform');

  useEffect(() => {
    if (platform === undefined) {
      setError('messengers.0.platform', {
        message: '필수 선택 항목입니다.',
      });
      return;
    }
    clearErrors('messengers.0.platform');
  }, [platform, clearErrors, setError]);

  return (
    <ErrorBoundaryWrapper>
      <div className="flex items-center gap-4 ">
        <label className="w-40 text-sm font-medium leading-6 text-gray-900">
          Platform*
        </label>
        <Controller
          control={control}
          name="messengers.0.platform"
          rules={{ required: '필수 선택 항목입니다.' }}
          render={({ field }) => (
            <ListBoxHeadlessInController
              value={field.value}
              buttonValue={
                messengerList?.find((item) => item.serverName === field.value)
                  ?.platformTitle
              }
              onChange={(value) => {
                field.onChange(value);
                if (platform !== value) {
                  resetField('messengers.0.requestUrl');
                  resetField('messengers.0.officeToken');
                  resetField('messengers.0.srvCode');
                  resetField('messengers.0.sender');
                  resetField('recipientId');
                  resetField('recipientInfo');
                  setValue('messengers.0.recipientList', []);
                }
              }}
              lists={filteredMessengerList}
              valueToShow={'platformTitle'}
              valueToSave={'serverName'}
              placeholder={'Select Messenger'}
              size="w-48"
            />
          )}
        />
      </div>
      <ErrorMessage
        errors={errors}
        name="messengers.0.platform"
        render={({ message }) => (
          <p className="standard-error-message ml-44">{message}</p>
        )}
      />
    </ErrorBoundaryWrapper>
  );
};

export default MessengerPlatform;
