import React from 'react';

import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import IfCondition from './EditIfCommand/IfCondition';
import IfAction from './EditIfCommand/IfAction';
import TestStepEditMaskingOptions from '@components/MobileTestCaseDetail/shared/TestStepEditMaskingOptions';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Props = {
  mobileTestStepData: MobileTestStepData;
};

const EditIfCommand = ({ mobileTestStepData }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <IfCondition mobileTestStepData={mobileTestStepData} />
      <IfAction mobileTestStepData={mobileTestStepData} />
      <TestStepEditMaskingOptions />
    </ErrorBoundaryWrapper>
  );
};

export default EditIfCommand;
