import React, { useEffect } from 'react';

import { findFillColorFromName } from '@utils/static/priorityLists';

import { WebTestCaseDetailData } from '@customTypes/testCase/type';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Props = {
  webTestCaseDetailData: WebTestCaseDetailData;
};

const WebTestCaseInfoDefault = ({ webTestCaseDetailData }: Props) => {
  const fillColor = findFillColorFromName(webTestCaseDetailData?.priority);

  useEffect(() => {
    sessionStorage.setItem('testCaseTitle', webTestCaseDetailData?.title);
  }, []);

  const descriptionList = [
    {
      term: 'Priority',
      description: (
        <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
          <svg
            className={`size-1.5 ${fillColor}`}
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx={3} cy={3} r={3} />
          </svg>
          {webTestCaseDetailData?.priority}
        </span>
      ),
    },
    {
      term: 'Test Group',
      description: webTestCaseDetailData?.caseGroupInfo?.name || '-',
    },
    { term: 'Creator', description: webTestCaseDetailData?.creator },
  ];

  const bottomDescriptionLists = [
    { term: 'Title', description: webTestCaseDetailData?.title },
    { term: 'Description', description: webTestCaseDetailData?.description },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="mt-1">
        <dl className="grid grid-cols-1 border-t border-gray-100 py-6 sm:grid-cols-3">
          {descriptionList.map((description) => (
            <div key={description.term}>
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {description.term}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                {description.description}
              </dd>
            </div>
          ))}
        </dl>
        <dl className="grid grid-cols-1 border-t border-gray-100 py-6 sm:grid-cols-3">
          {bottomDescriptionLists.map((description) => (
            <div key={description.term}>
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {description.term}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                {description.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default WebTestCaseInfoDefault;
