import { CalendarIcon } from '@autosquare/common';
import React, { forwardRef } from 'react';
interface CalendarCustomInputProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  value?: string | number | readonly string[] | undefined;
}

const CalendarCustomInput = forwardRef<
  HTMLButtonElement,
  CalendarCustomInputProps
>(({ onClick, value }, ref) => {
  return (
    <button
      className="flex min-w-40 items-center justify-center gap-x-3 rounded-md border border-gray-300 p-2 text-sm font-normal leading-tight"
      ref={ref}
      onClick={onClick}
      type="button"
    >
      {value}
      <CalendarIcon />
    </button>
  );
});

CalendarCustomInput.displayName = 'CalendarCustomInput';
export default CalendarCustomInput;
