import React from 'react';
import SignUpFormFields from './SignUpFormFields';

const EmailFields = () => {
  return (
    <SignUpFormFields
      title={'Email Address*'}
      name={'userId'}
      type={'text'}
      disabled={true}
    />
  );
};

export default EmailFields;
