import React, { useEffect } from 'react';
import SelectApplicationListbox from './SelectApplicationListbox';
import { useFormContext } from 'react-hook-form';
import { InstallAppRequest } from '@customTypes/index';
import { useGetProjectAppListQuery } from '@lib/api/dashboard/queryHooks';

const SelectApplicationAppVersion = () => {
  const { watch, setValue } = useFormContext<InstallAppRequest>();

  const osType = watch('osType');
  const packageName = watch('packageName');
  const appVersion = watch('appVersion');

  const { data: projectAppList } = useGetProjectAppListQuery();

  // packageName 과 osType 을 기준으로 필터링
  const filteredAppList = projectAppList?.filter(
    (app) => app.osType === osType && app.packageName === packageName,
  );

  // appVersion 이 변경되면 buildVersion 을 초기화
  useEffect(() => {
    setValue('buildVersion', undefined);
  }, [appVersion, setValue]);

  return (
    <SelectApplicationListbox
      label={'App Version*'}
      name={'appVersion'}
      lists={filteredAppList}
      valueToSave={'appVersion'}
      valueToShow={'appVersion'}
      disabled={!packageName}
    />
  );
};

export default SelectApplicationAppVersion;
