import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import SignUpHeader from './components/SignUpHeader';
import SignUpFooter from './components/SignUpFooter';
import SignUpFormContainer from './components/SignUpFormContainer';

const SignUp = () => {
  const [searchParams] = useSearchParams();
  const decodeKey = searchParams.get('key');

  if (!decodeKey) {
    return <Navigate to="/signup-error" />;
  }

  return (
    <ErrorBoundaryWrapper>
      <div className="mt-12 h-auto min-h-full w-96">
        <SignUpHeader />
        <div className="pt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="border-congress-blue mb-3 border-b border-solid pb-2 text-end">
            <p className="text-xs text-red-500">*필수 입력 사항</p>
          </div>

          <SignUpFormContainer />
        </div>
        <SignUpFooter />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default SignUp;
