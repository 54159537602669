import {
  RoleAuth,
  SectionTitleMain,
  SectionTitleRight,
} from '@autosquare/common';
import { SectionMainTitleContainer } from '@autosquare/common';
import { SectionTitle } from '@autosquare/common';
import { useAuthUserInfo } from '@hooks/index';
import React from 'react';

const TitleHeader = () => {
  const { userProjectRole, userAuthRole } = useAuthUserInfo();

  return (
    <SectionTitle withBorder>
      <SectionMainTitleContainer>
        <SectionTitleMain>Project Information</SectionTitleMain>
        {(userProjectRole === 0 || userAuthRole === RoleAuth.Admin) && (
          <SectionTitleRight type="caution">*필수 입력 사항</SectionTitleRight>
        )}
      </SectionMainTitleContainer>
    </SectionTitle>
  );
};

export default TitleHeader;
