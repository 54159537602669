import { CreateWebTestStepData } from '@customTypes/testStep/type';

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const TestStepLoop = () => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<CreateWebTestStepData>();

  useEffect(() => {
    setValue('optionsDto.loop', 1);
    return () => {
      setValue('optionsDto.loop', undefined);
    };
  }, [setValue]);

  return (
    <ErrorBoundaryWrapper>
      <div className="grid grid-cols-3 items-start gap-4">
        <label
          htmlFor="loop"
          className="block pt-1 text-sm font-medium leading-6 text-gray-900"
        >
          Loop
        </label>
        <div className="col-span-2 mt-0">
          <input
            type="number"
            name="loop"
            id="loop"
            className="input-base"
            required
            {...register('optionsDto.loop', {
              required: 'Loop 값을 입력해주세요.',
              min: { value: 1, message: '1 이상 60 이하 입력해 주세요.' },
              max: { value: 60, message: '1 이상 60 이하 입력해 주세요.' },
            })}
          />
        </div>
        <ErrorMessage
          errors={errors}
          name="optionsDto.loop"
          render={({ message }) => (
            <p className="standard-error-message col-span-3">{message}</p>
          )}
        />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default TestStepLoop;
