import {
  Button,
  Checkbox,
  ErrorBoundaryWrapper,
  TableBodyBasic,
  TableBodyContainer,
  TableNoList,
} from '@autosquare/common';
import {
  ReservationDeviceForm,
  ReservationDeviceInfo,
} from '@customTypes/index';
import clsx from 'clsx';
import React from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  headerLength: number;
  availableDeviceList: ReservationDeviceInfo[];
  selectedArray: number[];
  selectArray: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
};

const AvailableDevicesTableBody = ({
  headerLength,
  availableDeviceList,
  selectedArray,
  selectArray,
}: Props) => {
  const { watch, setValue } = useFormContext<ReservationDeviceForm>();

  const deviceIdxList = watch('deviceIdx');

  return (
    <ErrorBoundaryWrapper>
      <TableBodyContainer>
        {availableDeviceList.length === 0 ? (
          <TableNoList colSpan={headerLength}>
            예약할 날짜 및 시간을 먼저 선택해 주세요.
          </TableNoList>
        ) : (
          availableDeviceList.map((device, idx) => (
            <tr key={device.deviceIdx}>
              <TableBodyBasic>{idx + 1}</TableBodyBasic>
              <TableBodyBasic>{device.deviceModel}</TableBodyBasic>
              <TableBodyBasic>{device.deviceName || '-'}</TableBodyBasic>
              <TableBodyBasic>{device.deviceOs}</TableBodyBasic>
              <TableBodyBasic>
                <div className="flex justify-end">
                  <Checkbox
                    checked={selectedArray.includes(device.deviceIdx)}
                    onChange={(e) => selectArray(device.deviceIdx, e)}
                  />
                </div>
              </TableBodyBasic>
            </tr>
          ))
        )}
      </TableBodyContainer>
    </ErrorBoundaryWrapper>
  );
};

export default AvailableDevicesTableBody;
