import { UpdateAdminProjectDetailData } from '@customTypes/dashboard/admin/type';
import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const EditMessengerServerCode = () => {
  const {
    register,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<UpdateAdminProjectDetailData>();
  const srvCodeField = watch('messengers.0.srvCode');
  useEffect(() => {
    if (!srvCodeField) {
      setError('messengers.0.srvCode', {
        message: '필수 입력 항목입니다.',
      });
      return;
    }
    clearErrors('messengers.0.srvCode');
  }, [srvCodeField, setError, clearErrors]);

  return (
    <div className="mt-6 sm:space-y-0 sm:pb-0">
      <div className="flex items-center gap-x-3 py-5">
        <label className="text-sm font-medium leading-6 text-gray-900">
          SrvCode*
        </label>
        <p className="text-sm font-medium leading-tight text-gray-500">
          알림 받을 메신저의 고유 코드를 입력해 주세요.
        </p>
      </div>
      <div className="mt-2">
        <input
          type="text"
          name={'svcCode'}
          id={'svcCode'}
          className="input-base"
          {...register('messengers.0.srvCode', {
            required: '필수 입력 항목입니다.',
          })}
        />
        <ErrorMessage
          errors={errors}
          name="messengers.0.srvCode"
          render={({ message }) => (
            <p className="error-message py-1.5">{message}</p>
          )}
        />
      </div>
    </div>
  );
};

export default EditMessengerServerCode;
