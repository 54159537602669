import {
  ArrowSpinner,
  Breadcrumbs,
  ErrorBoundaryWrapper,
  PageHeadingButtons,
  PagingHeadingsNavigate,
} from '@autosquare/common';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getSelectedBrowser } from '@store/ide/browser/selectedBrowserSlice';

import WebExecutionInfo from './WebTestExecution/WebExecutionInfo';
import SelectBrowser from './WebTestExecution/SelectBrowser';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';
import { BrowserInfo } from '@customTypes/ide/browser/browser';
import TestResultSaveOptions from '../../TestResultSaveOptions/TestResultSaveOptions';
import {
  WebTestCaseExecutionData,
  WebTestExecutionData,
} from '@customTypes/ide/browser/browserExecution';
import { UseMutationResult } from '@tanstack/react-query';
import { useTestExecutionContext } from '@contexts/ide/TestExecutionProvider';
import { useParams } from 'react-router-dom';
import RemoteConnectionAlarmModal from '../shared/RemoteConnectionAlarmModal';
import { getRemoteState } from '@utils/static/getConfiguration';

type Props = {
  idx: number;
  setIsReadyToNavigate: React.Dispatch<React.SetStateAction<boolean>>;
  testType: TestTypeCategory;
  divWidth: number;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  remoteWebExecutionMutation: UseMutationResult<
    unknown,
    Error,
    WebTestExecutionData,
    unknown
  >;
  closeModalExecution: () => void;
  setIsExecutionErrorModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const WebTestExecution = ({
  idx,
  setIsReadyToNavigate,
  testType,
  divWidth,
  setIsModalOpen,
  remoteWebExecutionMutation,
  closeModalExecution,
  setIsExecutionErrorModalOpen,
}: Props) => {
  const [selectedBrowser, setSelectedBrowser] = useState<BrowserInfo[]>([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isSelectedBrowser, setIsSelectedBrowser] = useState(false);

  const { projectIdx } = useParams();
  const { shouldSaveScreenshot } = useTestExecutionContext();
  const testCaseData: WebTestCaseExecutionData = {
    infos: selectedBrowser,
    projectIdx: Number(projectIdx),
    caseIdx: idx,
    saveScreenshot: shouldSaveScreenshot,
  };

  const testScenarioData = {
    scenarioIdx: idx,
    projectIdx: Number(projectIdx),
    infos: selectedBrowser,
    saveScreenshot: shouldSaveScreenshot,
  };

  const testData = testType === 'case' ? testCaseData : testScenarioData;

  const checkedBrowserHandler = (value: BrowserInfo, isChecked: boolean) => {
    if (isChecked) {
      setSelectedBrowser([...selectedBrowser, value]);
    }
    if (!isChecked && selectedBrowser.includes(value)) {
      setSelectedBrowser(
        selectedBrowser.filter((selectedDevice) => selectedDevice !== value),
      );
    }
  };

  const checkHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: BrowserInfo,
  ) => {
    setIsChecked(!isChecked);
    checkedBrowserHandler(value, e.target.checked);
    setIsSelectedBrowser(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSelectedBrowser(selectedBrowser));
  }, [selectedBrowser]);

  const { remoteConfiguration } = getRemoteState();
  const executionTestOnClick = () => {
    if (selectedBrowser?.length === 0) {
      setIsSelectedBrowser(true);
    } else {
      setIsSelectedBrowser(false);
      if (remoteConfiguration) {
        closeModalExecution();
        remoteWebExecutionMutation.mutate(testData, {
          onSuccess: () => {
            setIsModalOpen(true);
          },
          onError: () => {
            setIsExecutionErrorModalOpen(true);
          },
        });
        return;
      }
      setIsReadyToNavigate(true);
    }
  };

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'UI Test' },
    { title: 'Web' },
    {
      title:
        testType === 'step'
          ? 'Test Step'
          : testType === 'case'
            ? 'Test Case'
            : 'Test Scenario',
    },
    { title: 'Execution' },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: 'Execution Test',
      type: 'button',
      variant: 'primary',
      onClick: executionTestOnClick,
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="flex flex-col gap-8">
        <PagingHeadingsNavigate
          breadcrumbs={breadcrumbs}
          pageTitle={
            testType === 'step'
              ? 'Test Step Execution'
              : testType === 'case'
                ? 'Test Case Execution'
                : 'Test Scenario Execution'
          }
          pageHeadingButtons={pageHeadingButtons}
        />
        <WebExecutionInfo idx={idx} testType={testType} />
        <TestResultSaveOptions>
          <TestResultSaveOptions.Screenshot />
        </TestResultSaveOptions>
        <SelectBrowser
          selectedBrowser={selectedBrowser}
          setSelectedBrowser={setSelectedBrowser}
          checkHandler={checkHandler}
          isSelectedBrowser={isSelectedBrowser}
          setIsSelectedBrowser={setIsSelectedBrowser}
          divWidth={divWidth}
        />
      </div>
      {remoteWebExecutionMutation.isLoading && <ArrowSpinner />}
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default WebTestExecution;
