import { ErrorBoundaryWrapper } from '@autosquare/common';
import { RepeatResultsData } from '@customTypes/ide/speedTest/Mobile/testResult/type';
import clsx from 'clsx';
import React from 'react';

type Props = {
  result: RepeatResultsData;
  isWeb: boolean;
};

const SpeedRepeatResultTableRow = ({ result, isWeb }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div
        className={clsx(
          'grid h-[3.75rem] items-center border-b border-gray-300',
          isWeb ? 'grid-cols-4' : 'grid-cols-3',
        )}
      >
        <div className="px-6 text-sm text-gray-900">{result.repeat}</div>
        <div className="break-words pl-6 text-sm text-gray-500">
          {result.dateTime}
        </div>
        {isWeb && (
          <div className="px-6 text-sm text-gray-500">{result.domTime}</div>
        )}
        <div className="px-6 text-sm text-gray-500">{result.loadTime}</div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default SpeedRepeatResultTableRow;
