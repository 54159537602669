import React from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { RootState } from '@app/store';

import { useModifyWebTestStepMutation } from '@lib/api/ide/web/webTestStep';

import {
  findWebCommandNameFromServerName,
  WebCommandOptionServerName,
} from '@utils/static/webCommandOptions';

import WebTestStepInputByReactHookForm from '@components/IDE/WebTestStepDetail/WebTestStepInputByReactHookForm';
import WebTestStepTextareaByReactHookForm from '@components/IDE/WebTestStepDetail/WebTestStepTextareaByReactHookForm';
import DefinitionList from '@components/shared/DefinitionList';
import TestStepDetailEditButton from '@components/shared/TestStepDetail/TestStepDetailEditButton';

import {
  UpdateWebTestStepData,
  WebTestStepData,
} from '@customTypes/testStep/type';

import TestStepValue from './TestStepDetailEdit/TestStepValue/TestStepValue';
import DefinitionInputOrTextAreaByReactHookForm from './TestStepDetailEdit/DefinitionInputOrTextAreaByReactHookForm';
import EditIfCommand from './TestStepDetailEdit/EditIfCommand';
import EditDataValidation from './TestStepDetailEdit/EditDataValidation';
import WebSecretEditBox from '@components/TestStep/WebSecretEditBox';
import { CompareBy } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import WebTestStepEditMaskingOptions from '@components/WebTestStep/WebTestStepEditMaskingOptions';
import { ErrorBoundaryWrapper } from '@autosquare/common';

interface Props {
  setIsEdited: React.Dispatch<React.SetStateAction<boolean>>;
  webTestStepData: WebTestStepData;
}

const TestStepDetailEdit = ({ setIsEdited, webTestStepData }: Props) => {
  const testStepIdx = useSelector(
    (state: RootState) => state.webTestStepDetail.testStepIdx,
  );

  const [searchParams] = useSearchParams();
  const caseIdx = Number(searchParams.get('idx'));

  const modifyTestStepMutation = useModifyWebTestStepMutation(testStepIdx);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { idx, ...restWebTestStepData } = webTestStepData;

  const methods = useForm<UpdateWebTestStepData>({
    defaultValues: { caseIdx: caseIdx, ...restWebTestStepData },
  });
  const compareBy = methods.watch('optionsDto.compareBy');

  const onSubmit: SubmitHandler<UpdateWebTestStepData> = (data) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { startX, startY, endX, endY, ...restData } = data;
    modifyTestStepMutation.mutate(restData, {
      onSuccess: () => {
        setIsEdited(false);
        methods.reset();
      },
    });
  };
  return (
    <ErrorBoundaryWrapper>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <TestStepDetailEditButton
            setIsEdited={setIsEdited}
            updateTestStepMutation={modifyTestStepMutation}
          />
          <div className="divide-y divide-gray-200">
            <div className="items-center px-4 py-5 sm:p-0">
              <dl className="space-y-8 sm:space-y-0">
                <DefinitionList
                  term={'Command'}
                  description={findWebCommandNameFromServerName(
                    webTestStepData.command,
                  )}
                />
                <WebTestStepInputByReactHookForm
                  term={'Description*'}
                  name={'description'}
                  type={'text'}
                  register={methods.register('description', {
                    required:
                      'Description은 1자에서 200자 이내로 입력해 주세요.',
                    maxLength: {
                      value: 200,
                      message:
                        'Description은 1자에서 200자 이내로 입력해 주세요.',
                    },
                  })}
                />
                {webTestStepData.command !== WebCommandOptionServerName.IF && (
                  <TestStepValue webTestStep={webTestStepData} />
                )}
                {(webTestStepData.command ===
                  WebCommandOptionServerName.Click ||
                  (webTestStepData.command ===
                    WebCommandOptionServerName.InputText &&
                    CompareBy.Text === compareBy) ||
                  webTestStepData.command ===
                    WebCommandOptionServerName.SaveText ||
                  webTestStepData.command ===
                    WebCommandOptionServerName.ConfirmText) && (
                  <DefinitionList
                    term={'Secret'}
                    description={
                      <WebSecretEditBox
                        control={methods.control}
                        name={
                          webTestStepData.command ===
                          WebCommandOptionServerName.InputText
                            ? 'optionsDto.textEncrypt'
                            : 'optionsDto.secret'
                        }
                      />
                    }
                  />
                )}
                {webTestStepData.command ===
                WebCommandOptionServerName.KeyBoardEvent ? (
                  <WebTestStepTextareaByReactHookForm
                    term={'TargetElement*'}
                    name={'optionsDto.targetElement'}
                    message={'TargetElement를 입력해 주세요.'}
                  />
                ) : webTestStepData.command ===
                  WebCommandOptionServerName.ScrollAndSwipe ? (
                  <WebTestStepInputByReactHookForm
                    term={'Loop*'}
                    name={'optionsDto.loop'}
                    type={'number'}
                    register={methods.register('optionsDto.loop', {
                      required: 'Loop를 입력해 주세요.',
                      min: { value: 1, message: '1 이상 입력해 주세요.' },
                    })}
                  />
                ) : (
                  webTestStepData.command ===
                    WebCommandOptionServerName.DataValidation && (
                    <EditDataValidation webTestStepData={webTestStepData} />
                  )
                )}
                {webTestStepData.command === WebCommandOptionServerName.IF ? (
                  <EditIfCommand webTestStepData={webTestStepData} />
                ) : (
                  <ErrorBoundaryWrapper>
                    <WebTestStepEditMaskingOptions />
                    <DefinitionInputOrTextAreaByReactHookForm
                      term={'Required'}
                      name={'required'}
                      type={'checkbox'}
                    />
                  </ErrorBoundaryWrapper>
                )}
                <WebTestStepInputByReactHookForm
                  term={'WaitTime*'}
                  name={'optionsDto.waitingTime'}
                  type={'number'}
                  register={methods.register('optionsDto.waitingTime', {
                    required: 'Wait Time을 입력해 주세요.',
                    min: { value: 0, message: '0 이상 60 이하 입력해 주세요.' },
                    max: {
                      value: 60,
                      message: '0 이상 60 이하 입력해 주세요.',
                    },
                  })}
                />
                {webTestStepData.command === WebCommandOptionServerName.IF && (
                  <WebTestStepEditMaskingOptions />
                )}
              </dl>
            </div>
          </div>
        </form>
      </FormProvider>
    </ErrorBoundaryWrapper>
  );
};

export default TestStepDetailEdit;
