import { ErrorBoundaryWrapper } from '@autosquare/common';
import TestStepDetailBasicDefinition from '@components/shared/TestStepDetail/TestStepDetailBasicDefinition';
import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { findMobileCommandNameFromServerName } from '@utils/static/mobileCommandOption';
import React from 'react';

type Props = {
  mobileTestStepData: MobileTestStepData;
};

const DefaultDefinition = ({ mobileTestStepData }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <TestStepDetailBasicDefinition
        term={
          mobileTestStepData?.mobileOs === DeviceInfoOs.Aos
            ? 'App Package Name'
            : 'App Bundle Identifier'
        }
        description={mobileTestStepData?.packageName}
      />
      <TestStepDetailBasicDefinition
        term={'App Version'}
        description={mobileTestStepData?.appVersion}
      />
      <TestStepDetailBasicDefinition
        term={'Command'}
        description={findMobileCommandNameFromServerName(
          mobileTestStepData?.command,
        )}
      />
      <TestStepDetailBasicDefinition
        term={'Description'}
        description={mobileTestStepData?.description}
      />
    </ErrorBoundaryWrapper>
  );
};

export default DefaultDefinition;
