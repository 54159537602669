import { ErrorMessage, SidebarSkeleton } from '@autosquare/common';

import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';

import { useProjectDetailQuery } from '@lib/api/ide/project/project';
import { useUserInfoQuery } from '@lib/api/ide/user/user';
import { checkIsProjectFeatureIncluded } from '@utils/static/checkProjectFeature';

import {
  ProjectListDetailFeaturesCategory,
  ProjectListDetailFeaturesFeature,
} from '@customTypes/dashboard/project/type';

import SidebarList from './Sidebar/SidebarList';
import SidebarTitle from './Sidebar/SidebarTitle';

type LocationObject = {
  dashboard: string;
  devices: string;
  'ui-test': string;
  'speed-test': string;
};

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectIdx } = useParams();
  const useInfoQuery = useUserInfoQuery();
  const userInfo = useInfoQuery.data;

  const projectDetailQuery = useProjectDetailQuery();
  const projectDetail = projectDetailQuery.data;

  const locationObject: LocationObject = {
    dashboard: `/ide/projects/${projectIdx}/dashboard/`,
    devices: `/ide/projects/${projectIdx}/devices`,
    'ui-test': `/ide/projects/${projectIdx}/ui-test/`,
    'speed-test': `/ide/projects/${projectIdx}/speed-test/`,
  };
  const locationKey = location.pathname.split('/')[4] as keyof LocationObject;

  const commonURL = locationObject[locationKey];

  const sidebarLists = [
    {
      name: 'Overview',
      href: `${commonURL}overview`,
    },
    {
      name: 'Scheduler',
      href: `${commonURL}scheduler`,
      base: `${commonURL}scheduler`,
    },
    {
      name: 'Mobile',
      location: 'mobile',
      children: [
        {
          name: 'Test Scenario',
          href: `${commonURL}mobile/test-scenario`,
          base: `${commonURL}mobile/test-scenario`,
        },
        {
          name: 'Test Case',
          href: `${commonURL}mobile/test-case`,
          base: `${commonURL}mobile/test-case`,
        },
        {
          name: 'Test Result',
          href: `${commonURL}mobile/test-result`,
          base: `${commonURL}mobile/test-result`,
        },
      ],
    },
    {
      name: 'Web',
      location: 'web',
      children: [
        {
          name: 'Test Scenario',
          href: `${commonURL}web/test-scenario`,
          base: `${commonURL}web/test-scenario`,
        },
        {
          name: 'Test Case',
          href: `${commonURL}web/test-case`,
          base: `${commonURL}web/test-case`,
        },
        {
          name: 'Test Result',
          href: `${commonURL}web/test-result`,
          base: `${commonURL}web/test-result`,
        },
      ],
    },
  ];

  const sidebarDeviceList = [
    {
      name: 'Devices',
      location: 'devices',
      children: [
        {
          name: 'Device List',
          href: `${commonURL}`,
          base: `${commonURL}`,
        },
      ],
    },
  ];

  const filteredSidebarLists =
    locationKey === 'devices'
      ? sidebarDeviceList
      : sidebarLists.filter((item) => {
          if (locationKey === 'dashboard') {
            return item.name === 'Overview';
          }

          if (locationKey === 'ui-test') {
            if (item.location === 'mobile') {
              return checkIsProjectFeatureIncluded(
                projectDetail,
                ProjectListDetailFeaturesCategory.Mobile,
                ProjectListDetailFeaturesFeature.Functional,
              );
            }
            if (item.location === 'web') {
              return checkIsProjectFeatureIncluded(
                projectDetail,
                ProjectListDetailFeaturesCategory.Web,
                ProjectListDetailFeaturesFeature.Functional,
              );
            }
          }

          if (locationKey === 'speed-test') {
            if (item.location === 'mobile') {
              return checkIsProjectFeatureIncluded(
                projectDetail,
                ProjectListDetailFeaturesCategory.Mobile,
                ProjectListDetailFeaturesFeature.Speed,
              );
            }
            if (item.location === 'web') {
              return checkIsProjectFeatureIncluded(
                projectDetail,
                ProjectListDetailFeaturesCategory.Web,
                ProjectListDetailFeaturesFeature.Speed,
              );
            }
          }

          return true;
        });

  const userMenu = [
    {
      name: userInfo?.name,
      location: 'username',
      children: [
        {
          name: 'Logout',
          href: '/ide/login',
          base: '/ide/login',
        },
      ],
    },
  ];

  return (
    <aside className="lg:fixed lg:z-50 lg:ml-20 lg:flex lg:w-64 lg:flex-col">
      {useInfoQuery.isLoading || projectDetailQuery.isLoading ? (
        <SidebarSkeleton />
      ) : useInfoQuery.isError ? (
        <ErrorMessage>{useInfoQuery.error.message}</ErrorMessage>
      ) : projectDetailQuery.isError ? (
        <ErrorMessage>{projectDetailQuery.error.message}</ErrorMessage>
      ) : (
        <div className="flex h-screen w-60 grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
          <SidebarTitle projectName={projectDetail?.name} />
          <div className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {filteredSidebarLists?.map((item) => (
                    <li key={item.name}>
                      {!item.children ? (
                        <button
                          type="button"
                          className={clsx(
                            'flex w-full cursor-pointer justify-start rounded-md py-2 pl-10 pr-2 text-sm font-semibold leading-6 text-gray-700',
                            location.pathname.includes(item.href)
                              ? 'bg-gray-50'
                              : 'hover:bg-gray-50',
                          )}
                          onClick={() => {
                            if (item.name.includes('Overview')) {
                              navigate(item.href);
                              projectDetailQuery.refetch();
                              return;
                            }
                            navigate(item.href);
                          }}
                        >
                          {item.name}
                        </button>
                      ) : (
                        <SidebarList item={item} />
                      )}
                    </li>
                  ))}
                </ul>
              </li>
              <ul>
                {userMenu.map((item) => (
                  <li key={item.name}>
                    <SidebarList item={item} />
                  </li>
                ))}
              </ul>
            </ul>
          </div>
        </div>
      )}
    </aside>
  );
};

export default Sidebar;
