import React, { useEffect, useState } from 'react';
import {
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';
import {
  MessengerPlatformRequiredInfo,
  UpdateAdminProjectDetailData,
} from '@customTypes/dashboard/admin/type';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import useEditMessengerPlatformHandler from '@hooks/admin/useEditMessengerPlatformHandler';

type Props = {
  messengerList: MessengerPlatformRequiredInfo[];
  defaultPlatform: string;
  changePlatformCount: number;
  setChangePlatformCount: React.Dispatch<React.SetStateAction<number>>;
};

const EditMessengerPlatform = ({
  messengerList,
  defaultPlatform,
  changePlatformCount,
  setChangePlatformCount,
}: Props) => {
  const {
    control,
    watch,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useFormContext<UpdateAdminProjectDetailData>();

  const platform = watch('messengers.0.platform');

  const [isOpenedCautionDialog, setIsOpenedCautionDialog] = useState(false);
  //To do: teams 추가, slack 추가될 경우 수정 예정
  const filteredMessengerList = messengerList?.filter(
    (item) => item.platformCode !== 'MSGR02' && item.platformCode !== 'MSGR99',
  );

  const { platformChangeHandler, okButtonHandler, cancelButtonHandler } =
    useEditMessengerPlatformHandler(
      filteredMessengerList,
      defaultPlatform,
      changePlatformCount,
      setIsOpenedCautionDialog,
      setChangePlatformCount,
      setValue,
    );

  useEffect(() => {
    if (!platform) {
      setError('messengers.0.platform', {
        message: '필수 선택 항목입니다.',
      });
      return;
    }
    clearErrors('messengers.0.platform');
  }, [platform, setError, clearErrors]);

  return (
    <ErrorBoundaryWrapper>
      <div className="flex items-center gap-4">
        <label className="w-40 text-sm font-medium leading-6 text-gray-900">
          Platform*
        </label>
        <ErrorBoundaryWrapper>
          <Controller
            control={control}
            name="messengers.0.platform"
            rules={{ required: '필수 선택 항목입니다.' }}
            render={({ field }) => (
              <ListBoxHeadlessInController
                value={platform ? field.value : undefined}
                buttonValue={
                  platform
                    ? messengerList?.find(
                        (item) => item.serverName === field.value,
                      )?.platformTitle
                    : undefined
                }
                onChange={(value) => platformChangeHandler(value)}
                lists={filteredMessengerList}
                valueToShow={'platformTitle'}
                valueToSave={'serverName'}
                placeholder={'Select Messenger'}
                size="w-48"
              />
            )}
          />
        </ErrorBoundaryWrapper>
      </div>
      <ErrorMessage
        errors={errors}
        name="messengers.0.platform"
        render={({ message }) => (
          <p className="standard-error-message ml-44">{message}</p>
        )}
      />
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedCautionDialog}
        setIsOpened={setIsOpenedCautionDialog}
        type="caution"
        title={'Platform Change'}
        subTitle={`메신저 플랫폼을 변경하면 해당 프로젝트에 생성되어 있는 스케줄러\n메신저 알람 설정이 모두 삭제됩니다.`}
        buttonChildren={
          <>
            <Button type="submit" variant="caution" onClick={okButtonHandler}>
              OK
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={cancelButtonHandler}
            >
              Cancel
            </Button>
          </>
        }
      />
    </ErrorBoundaryWrapper>
  );
};

export default EditMessengerPlatform;
