import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';
import { CompareBy } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ServerFormatDataType } from '@customTypes/index';
import { CreateWebTestStepData } from '@customTypes/testStep/type';
import { ErrorMessage } from '@hookform/error-message';
import { useWebTestStepByCommandQuery } from '@lib/api/ide/web/webTestStep';
import {
  dataTypeList,
  findDataType,
  findWebTestStepDescriptionFromIdx,
} from '@utils/index';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const TypeAndReferenceValue = () => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<CreateWebTestStepData>();

  const { data: webTestStepBySaveTextList } = useWebTestStepByCommandQuery();

  useEffect(() => {
    setValue('optionsDto.dataType', ServerFormatDataType.Number);
    setValue('optionsDto.compareBy', CompareBy.Id);

    return () => {
      setValue('optionsDto.dataType', undefined);
      setValue('optionsDto.compareBy', undefined);
      setValue('optionsDto.compareIdx', undefined);
    };
  }, [setValue]);

  return (
    <ErrorBoundaryWrapper>
      <div className="flex flex-col gap-1">
        <p>Type</p>
        <ErrorBoundaryWrapper>
          <Controller
            control={control}
            name={'optionsDto.dataType'}
            render={({ field }) => (
              <ListBoxHeadlessInController
                value={field.value}
                buttonValue={findDataType(field.value)}
                onChange={field.onChange}
                lists={dataTypeList}
                valueToSave={'serverName'}
                valueToShow={'name'}
              />
            )}
          />
        </ErrorBoundaryWrapper>
      </div>
      <div className="flex flex-col gap-1">
        <p>Reference Value</p>
        <ErrorBoundaryWrapper>
          <Controller
            control={control}
            name={'optionsDto.compareIdx'}
            rules={{ required: '기준 값을 선택해 주세요.' }}
            render={({ field }) => (
              <ListBoxHeadlessInController
                value={field.value}
                buttonValue={findWebTestStepDescriptionFromIdx(
                  Number(field.value),
                  webTestStepBySaveTextList,
                )}
                placeholder={
                  '기준 값을 Save Text 스텝 리스트에서 선택해 주세요.'
                }
                onChange={field.onChange}
                lists={webTestStepBySaveTextList}
                valueToSave={'idx'}
                valueToShow={'description'}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={'optionsDto.compareIdx'}
            render={({ message }) => (
              <p className="error-message pt-1">{message}</p>
            )}
          />
        </ErrorBoundaryWrapper>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default TypeAndReferenceValue;
