import { ErrorBoundaryWrapper, PagingHeadings } from '@autosquare/common';
import { useParentPath } from '@hooks/index';
import React from 'react';
import { useLocation } from 'react-router-dom';

const AdminUserDetailPagingHeading = () => {
  const location = useLocation();
  const adminUserListPage = useParentPath();

  const breadcrumbs = [
    { title: 'Administration', href: adminUserListPage },
    { title: 'Users', href: adminUserListPage },
    { title: 'Detail', href: location.pathname + location.search },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings breadcrumbs={breadcrumbs} pageTitle={'Detail User'} />
    </ErrorBoundaryWrapper>
  );
};

export default AdminUserDetailPagingHeading;
