import {
  SectionTitleContainer,
  SectionTitleHeader,
  SectionTitleHeaderContainer,
  SectionTitleHeaderDescription,
} from '@autosquare/common';
import { ReservationDeviceForm } from '@customTypes/index';
import React, { useRef } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';
import { ErrorMessage } from '@hookform/error-message';
import { CustomDatePicker } from '@components/index';
import { useReservationTime } from '@hooks/index';

const SelectReservationTime = () => {
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext<ReservationDeviceForm>();

  const { minStartTime, dateOnChange, checkIsToday, checkIsSameDay } =
    useReservationTime();

  const startDateRef = useRef<HTMLButtonElement>(null);
  const endDateRef = useRef<HTMLButtonElement>(null);

  const startTime = watch('startTime');
  const endTime = watch('endTime');

  const isToday = checkIsToday(startTime);
  const isSameDay = checkIsSameDay(startTime, endTime);

  const timeList = [
    {
      id: 'startTime',
      title: 'Start Time',
      date: (
        <Controller
          control={control}
          name="startTime"
          render={({ field }) => (
            <CustomDatePicker
              selected={field.value ? new Date(field.value) : null}
              onChange={(date) => {
                dateOnChange(date, field.onChange);
              }}
              showPopperArrow={false}
              dateFormat="yyyy-MM-dd HH:mm"
              timeIntervals={10}
              minDate={new Date()}
              maxDate={
                endTime
                  ? dayjs(endTime).toDate()
                  : dayjs().add(30, 'days').toDate()
              }
              minTime={
                isToday
                  ? minStartTime.toDate()
                  : dayjs().startOf('day').toDate()
              }
              maxTime={
                endTime
                  ? dayjs(endTime).subtract(10, 'minutes').toDate()
                  : dayjs().endOf('day').toDate()
              }
              buttonRef={startDateRef}
              width="w-full"
              showTimeSelect
              selectsStart
            />
          )}
        />
      ),
    },
    {
      id: 'endTime',
      title: 'End Time',
      date: (
        <Controller
          control={control}
          name="endTime"
          render={({ field }) => (
            <CustomDatePicker
              selected={field.value ? new Date(field.value) : null}
              onChange={(date) => {
                dateOnChange(date, field.onChange);
              }}
              showPopperArrow={false}
              dateFormat="yyyy-MM-dd HH:mm"
              timeIntervals={10}
              minDate={startTime ? dayjs(startTime).toDate() : dayjs().toDate()}
              maxDate={
                startTime
                  ? dayjs(startTime).add(30, 'days').toDate()
                  : dayjs().add(30, 'days').toDate()
              }
              minTime={
                isSameDay
                  ? dayjs(startTime).add(10, 'minutes').toDate()
                  : dayjs().startOf('day').toDate()
              }
              maxTime={dayjs().endOf('day').toDate()}
              buttonRef={endDateRef}
              width="w-full"
              showTimeSelect
              selectsEnd
            />
          )}
        />
      ),
    },
  ];

  return (
    <section>
      <SectionTitleContainer>
        <SectionTitleHeaderContainer>
          <SectionTitleHeader>Select Reservation Time</SectionTitleHeader>
          <SectionTitleHeaderDescription>
            예약 시작 및 종료 날짜와 시간을 선택해 주세요.
          </SectionTitleHeaderDescription>
        </SectionTitleHeaderContainer>
      </SectionTitleContainer>
      <div className="flex w-full gap-4">
        {timeList.map((time) => (
          <div className="w-full" key={time.id}>
            <p>{time.title}</p>
            <div className="mt-1">{time.date}</div>
            <ErrorMessage
              errors={errors}
              name={time.id}
              render={({ message }) => (
                <p className="standard-error-message">{message}</p>
              )}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default SelectReservationTime;
