import React from 'react';
import MobileSpeedTestResultsOverviewTable from '@components/Overview/MobileSpeedTestOverviewList/MobileSpeedTestResultsOverviewTable';
import WebSpeedTestResultsOverviewTable from '@components/Overview/WebSpeedTestOverviewList/WebSpeedTestResultsOverviewTable';
import { MobileSpeedTestResultData } from '@customTypes/ide/speedTest/Mobile/testResult/type';
import { WebSpeedTestResultData } from '@customTypes/ide/speedTest/Web/testResult/type';

type Props = {
  total: number;
  resultData: MobileSpeedTestResultData | WebSpeedTestResultData;
};

const SpeedTestResultTableSection = ({ total, resultData }: Props) => {
  const isResultDataAsMobileSpeedTestResultDataType = (
    type: MobileSpeedTestResultData | WebSpeedTestResultData,
  ): type is MobileSpeedTestResultData => {
    return 'content' in type;
  };

  return (
    <div className="overflow-auto scrollbar-thin">
      {!resultData || total === 0 ? (
        <div className="my-10 text-center text-sm text-gray-900">
          현재 조회되는 데이터가 없습니다.
        </div>
      ) : isResultDataAsMobileSpeedTestResultDataType(resultData) ? (
        <MobileSpeedTestResultsOverviewTable resultData={resultData} />
      ) : (
        <WebSpeedTestResultsOverviewTable resultData={resultData} />
      )}
    </div>
  );
};

export default SpeedTestResultTableSection;
