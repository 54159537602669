import {
  Button,
  ErrorBoundaryWrapper,
  SectionTitleContainer,
  SectionTitleHeader,
  SectionTitleHeaderContainer,
} from '@autosquare/common';
import { useParentPath } from '@hooks/index';
import { useReservedDeviceDetailQuery } from '@lib/api/ide/queryHooks';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ReservationInformation = () => {
  const navigate = useNavigate();
  const reservationDeviceListPath = useParentPath();

  const {
    data: reservationDeviceDetail,
    isLoading,
    isSuccess,
  } = useReservedDeviceDetailQuery();

  const reservationDeviceInformationList = [
    {
      label: 'Model',
      value: isLoading ? (
        <div className="h-5 w-20 animate-pulse rounded bg-gray-200" />
      ) : (
        <p>{reservationDeviceDetail?.deviceInfo?.deviceModel}</p>
      ),
    },
    {
      label: 'Name',
      value: isLoading ? (
        <div className="h-5 w-20 animate-pulse rounded bg-gray-200" />
      ) : (
        <p>{reservationDeviceDetail?.deviceInfo?.deviceName || '-'}</p>
      ),
    },
    {
      label: 'OS',
      value: isLoading ? (
        <div className="h-5 w-20 animate-pulse rounded bg-gray-200" />
      ) : (
        <p>{reservationDeviceDetail?.deviceInfo?.deviceOs}</p>
      ),
    },
  ];

  // 디바이스 정보가 없으면 목록으로 이동
  useEffect(() => {
    if (isSuccess && !reservationDeviceDetail?.deviceInfo.deviceIdx) {
      navigate(reservationDeviceListPath);
    }
  }, [reservationDeviceDetail, isSuccess]);

  return (
    <ErrorBoundaryWrapper>
      <section>
        <SectionTitleContainer>
          <SectionTitleHeaderContainer>
            <SectionTitleHeader>Reservation Information</SectionTitleHeader>
          </SectionTitleHeaderContainer>
          <Button
            type="button"
            variant="secondary"
            onClick={() => navigate(reservationDeviceListPath)}
          >
            Cancel
          </Button>
        </SectionTitleContainer>
        <div className="my-6 grid grid-cols-3 border-t border-gray-200 py-6">
          {reservationDeviceInformationList.map((deviceInfo) => (
            <div className="space-y-2" key={deviceInfo.label}>
              <p className="font-medium leading-normal">{deviceInfo.label}</p>
              {deviceInfo.value}
            </div>
          ))}
        </div>
      </section>
    </ErrorBoundaryWrapper>
  );
};

export default ReservationInformation;
