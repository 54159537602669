import { ErrorBoundaryWrapper } from '@autosquare/common';
import React from 'react';

const SuccessToSendEmail = () => {
  return (
    <ErrorBoundaryWrapper>
      <p className="text-sm text-gray-500">
        사용자 이메일 주소로 가입 링크가 전송되었습니다.
      </p>
      <p className="pb-2 text-sm text-gray-500">
        이메일을 사용할 수 없는 경우 아래 가입 링크를 사용자에게 직접 전송해
        주세요.
      </p>
    </ErrorBoundaryWrapper>
  );
};

export default SuccessToSendEmail;
