import { Button, ErrorBoundaryWrapper, regexLibrary } from '@autosquare/common';

import {
  useDeviceListQuery,
  useRemoteSettingTcpWirelessDeviceMutation,
  useSettingTcpWirelessDeviceMutation,
} from '@lib/api/ide/mobile/mobileDevice';

import {
  ConnectionType,
  DeviceInfoOs,
  TcpConnectionRequest,
} from '@customTypes/ide/device/device';

import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import IsSuccessConnection from './IsSuccessConnection';
import HowToUseTcpConnection from './PortConnection/HowToUseTcpConnection';
import SerialNumberDeviceListbox from './PortConnection/SerialNumberDeviceListbox';
import { Mode } from '@customTypes/ide/remote/type';
import { useRemoteDeviceListQuery } from '@lib/api/ide/remote/remote';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';
import { getRemoteState } from '@utils/static/getConfiguration';

interface Props {
  closeModal: () => void;
}

const PortConnection = ({ closeModal }: Props) => {
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<TcpConnectionRequest>();

  const { configuration } = getRemoteState();
  const settingTcpWirelessDeviceMutation =
    configuration === Mode.Standard
      ? useSettingTcpWirelessDeviceMutation()
      : useRemoteSettingTcpWirelessDeviceMutation();

  const onSubmit: SubmitHandler<TcpConnectionRequest> = (data) => {
    settingTcpWirelessDeviceMutation.mutate(data);
  };

  const resetFetchingAndFormError = () => {
    settingTcpWirelessDeviceMutation.reset();
    clearErrors();
  };

  const deviceListQuery =
    configuration === Mode.Standard
      ? useDeviceListQuery()
      : useRemoteDeviceListQuery();

  const deviceList = deviceListQuery.data;
  const aosDeviceList = deviceList?.filter(
    (device) =>
      device.os === DeviceInfoOs.Aos &&
      device.connectionType === ConnectionType.USB,
  );

  const onClickButton = () => {
    deviceListQuery.refetch();
  };

  const buttonValue = (value: string) => {
    const selectedDevice = aosDeviceList?.find(
      (device) => device.serialNumber === value,
    );
    return `${selectedDevice?.model} - ${selectedDevice?.serialNumber}`;
  };

  return (
    <ErrorBoundaryWrapper>
      <h3 className="text-base font-semibold leading-7 text-gray-900">
        ADB 무선 연결 방식
      </h3>
      {settingTcpWirelessDeviceMutation.isSuccess ? (
        <IsSuccessConnection
          isSuccess={true}
          onClick={closeModal}
          connectingType={'adb'}
        />
      ) : settingTcpWirelessDeviceMutation.isError ? (
        <IsSuccessConnection
          isSuccess={false}
          onClick={resetFetchingAndFormError}
          connectingType={'adb'}
          errorMessage={settingTcpWirelessDeviceMutation.error.message}
        />
      ) : (
        <>
          <p className="text-sm font-medium leading-7">
            PC와 디바이스를 동일한 Wi-Fi에 연결한 후, 연결하려는 디바이스의
            시리얼 번호와 IP 주소를 입력해 주세요.
          </p>
          <form
            className="border-b border-solid border-gray-200 pb-5 pt-3"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-2.5">
              <div className="w-full">
                <div className="flex w-full items-start justify-center">
                  <p className="w-40 whitespace-pre px-3 py-2.5 text-end text-sm font-normal leading-tight text-gray-900">
                    Device
                  </p>
                  <div className="flex flex-col items-start">
                    <Controller
                      control={control}
                      name={'serialNumber'}
                      rules={{ required: '연결할 디바이스를 선택해 주세요.' }}
                      render={({ field }) => (
                        <SerialNumberDeviceListbox
                          value={field.value || ''}
                          onChange={field.onChange}
                          buttonValue={
                            field.value
                              ? buttonValue(field.value)
                              : 'Select a test device'
                          }
                          onClickButton={onClickButton}
                          deviceLists={aosDeviceList}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={'serialNumber'}
                      render={({ message }) => (
                        <p className="error-message pl-3 pt-2.5">{message}</p>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full">
                <label className="flex w-full items-start justify-center">
                  <p className="w-40 whitespace-pre px-3 py-2.5 text-end text-sm font-normal leading-tight text-gray-900">
                    IP 주소
                  </p>
                  <div className="flex flex-col items-start">
                    <input
                      type="text"
                      className="input-base w-64"
                      {...register('ipAddress', {
                        required: 'IP 주소를 입력해 주세요.',
                        pattern: {
                          value: regexLibrary.ipAddress,
                          message: 'IP 주소를 다시 확인해 주세요.',
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={'ipAddress'}
                      render={({ message }) => (
                        <p className="error-message pl-3 pt-2.5">{message}</p>
                      )}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="flex-center pt-2.5">
              <Button
                type="submit"
                buttonSize="sm"
                disabled={settingTcpWirelessDeviceMutation.isLoading}
              >
                OK
              </Button>
            </div>
          </form>
          <HowToUseTcpConnection />
        </>
      )}
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default PortConnection;
