import { findExtractTextFormatTypeByServerName } from '@utils/static/mobileTestStep/extractTextFormat/extractTextFormatTypeList';
import { getDisplayTextBySaveTextFrom } from '@utils/mapping/mobileTestStep/saveTextMapping';

import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { SaveTextFrom } from '@customTypes/ide/mobileTestStep/SaveTextFrom';

import React from 'react';

import PropertyDetail from './PropertyDetail';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Props = {
  list: MobileTestStepData;
};

const SaveTextList = ({ list }: Props) => {
  const parsedSecret = list.optionsDto.secret === 'true';
  const parsedTextExtract = list.optionsDto.textExtract === 'true';
  const phoneNumber = parsedSecret ? '******' : list.value;

  return (
    <ErrorBoundaryWrapper>
      <PropertyDetail term={'Secret'} description={parsedSecret ? 'Y' : 'N'} />
      <PropertyDetail
        term={'From'}
        description={getDisplayTextBySaveTextFrom(list.optionsDto.from)}
      />
      {list.optionsDto.from === SaveTextFrom.Sms && (
        <PropertyDetail term={'Phone Number'} description={phoneNumber} />
      )}
      <PropertyDetail
        term={'Extract Text Format'}
        description={parsedTextExtract ? 'Y' : 'N'}
      />
      {parsedTextExtract && (
        <>
          <PropertyDetail
            term={'Type / Length'}
            description={`${findExtractTextFormatTypeByServerName(list.optionsDto.textExtractOptions.type)} / ${list.optionsDto.textExtractOptions.length}`}
          />
          <PropertyDetail
            term={'Index'}
            description={list.optionsDto.textExtractOptions.index}
          />
        </>
      )}
    </ErrorBoundaryWrapper>
  );
};

export default SaveTextList;
