import React from 'react';
import {
  ProjectListDetail,
  ProjectListDetailFeaturesCategory,
  ProjectListDetailFeaturesFeature,
} from '@customTypes/dashboard/project/type';
import {
  AdminUserData,
  UpdateAdminProjectDetailData,
} from '@customTypes/dashboard/admin/type';
import { FormProvider, useForm } from 'react-hook-form';
import ProjectInformation from './AdminProjectDetailForm/ProjectInformation';
import ProjectDetailsFeatures from './AdminProjectDetailForm/ProjectDetailsFeatures';
import ProjectEditButton from './AdminProjectDetailForm/ProjectEditButton';
import ProjectEditFeatures from './ProjectEditFeatures';
import ProjectEditInformation from './ProjectEditInformation';
import ProjectDetailsMessenger from './AdminProjectDetailForm/ProjectDetailsMessenger';
import ProjectEditMessenger from './AdminProjectDetailForm/ProjectEditMessenger';
import useAdminProjectEditFormHandler from '@hooks/admin/useAdminProjectEditFormHandler';
import ProjectDetailsAutoRegister from './AdminProjectDetailForm/ProjectDetailsAutoRegister';
import ProjectEditAutoRegister from './AdminProjectDetailForm/ProjectEditAutoRegister';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import { useUpdateAdminProjectDetailMutation } from '@lib/api/dashboard/queryHooks';

interface Props {
  isEdited: boolean;
  setIsEdited: React.Dispatch<React.SetStateAction<boolean>>;
  adminProjectData: ProjectListDetail;
  adminUserListData: AdminUserData[];
}

const AdminProjectDetailForm = ({
  isEdited,
  setIsEdited,
  adminProjectData,
  adminUserListData,
}: Props) => {
  const checkFeaturesActivate = (
    projectData: ProjectListDetail,
    category: ProjectListDetailFeaturesCategory,
    feature: ProjectListDetailFeaturesFeature,
  ) =>
    projectData?.features?.find(
      (value) => value?.category === category && value?.feature === feature,
    )?.activate;

  const recipientFormat = adminProjectData?.messengers[0]?.recipientList
    ?.map((item) => item.split('/'))
    ?.map((item) => {
      return { dataId: item[0], info: item[1] };
    });

  const methods = useForm<UpdateAdminProjectDetailData>({
    defaultValues: {
      name: adminProjectData.name,
      serviceName: adminProjectData.serviceName,
      description: adminProjectData.description,
      projectAdminList: adminProjectData.projectAdminList.map(
        (projectAdmin) => projectAdmin.email,
      ),
      features: adminProjectData.features.filter((feature) => feature.activate),
      messengers: adminProjectData.messengers,
      recipientFormat: recipientFormat,
      registers: adminProjectData.registers,
    },
  });

  const updateAdminProjectDetailMutation =
    useUpdateAdminProjectDetailMutation();

  const { onSubmit, isToggleOn, setIsToggleOn } =
    useAdminProjectEditFormHandler(
      methods,
      updateAdminProjectDetailMutation,
      adminProjectData,
    );

  return (
    <ErrorBoundaryWrapper>
      {isEdited ? (
        <FormProvider {...methods}>
          <form
            className="mt-8 space-y-6"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <ProjectEditInformation adminUserListData={adminUserListData} />
            <ProjectEditFeatures />
            <ProjectEditMessenger adminProjectData={adminProjectData} />
            <ProjectEditAutoRegister
              autoRegisterData={adminProjectData.registers}
              isToggleOn={isToggleOn}
              setIsToggleOn={setIsToggleOn}
            />
            <ProjectEditButton
              setIsEdited={setIsEdited}
              savedToggleStatus={adminProjectData.registers[0]?.activate}
              setIsToggleOn={setIsToggleOn}
              updateAdminProjectDetailMutation={
                updateAdminProjectDetailMutation
              }
            />
          </form>
        </FormProvider>
      ) : (
        <ErrorBoundaryWrapper>
          <ProjectInformation
            projectName={adminProjectData?.name}
            serviceName={adminProjectData?.serviceName}
            description={adminProjectData?.description}
            projectAdminEmail={adminProjectData?.projectAdminList[0]?.email}
          />
          <ProjectDetailsFeatures
            checkFeaturesActivate={checkFeaturesActivate}
          />
          <ProjectDetailsMessenger
            isActive={adminProjectData.messengers[0]?.activate}
            messengerData={adminProjectData.messengers}
          />
          <ProjectDetailsAutoRegister
            registerData={adminProjectData.registers}
          />
        </ErrorBoundaryWrapper>
      )}
    </ErrorBoundaryWrapper>
  );
};

export default AdminProjectDetailForm;
