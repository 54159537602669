import React, { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { MobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';

import ClickCoordinateOption from '@components/shared/ClickCoordinateOption';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const TestStepClickCoordinate = () => {
  const {
    startX,
    startY,
    onChangeStartX,
    onChangeStartY,
    imageWidth,
    imageHeight,
  } = useContext(MobileTestStepContext);

  const { setValue } = useFormContext<CreateTestStepData>();

  const coordinateInputOptions = [
    {
      labelTitle: 'X',
      value: startX,
      max: imageWidth,
      onChange: onChangeStartX,
    },
    {
      labelTitle: 'Y',
      value: startY,
      max: imageHeight,
      onChange: onChangeStartY,
    },
  ];

  useEffect(() => {
    setValue('value', `${startX},${startY}`);
    return () => setValue('value', undefined);
  }, [startX, startY, setValue]);

  return (
    <ErrorBoundaryWrapper>
      <div className="text-sm">
        <ClickCoordinateOption
          imageWidth={imageWidth}
          imageHeight={imageHeight}
          clickOptions={coordinateInputOptions}
        />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default TestStepClickCoordinate;
