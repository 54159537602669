import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import { z } from 'zod';

export enum TestResultType {
  Manual = 'Manual',
  Monitoring = 'Monitoring',
  Unit = 'Unit',
}

export enum TestResultStatus {
  NA = 'NA',
  Pass = 'PASS',
  Running = 'RUNNING',
  Fail = 'FAIL',
  Skip = 'SKIP',
  NotExecuted = '-',
}

export enum TestResultLabel {
  NA = 'N/A',
  Pass = 'Pass',
  Running = 'Running',
  Fail = 'Fail',
  Skip = 'Skip',
  NotExecuted = '-',
}

export enum TestResultDisplay {
  NA = TestResultLabel.NA,
  PASS = TestResultLabel.Pass,
  RUNNING = TestResultLabel.Running,
  FAIL = TestResultLabel.Fail,
  SKIP = TestResultLabel.Skip,
  NOTEXECUTED = TestResultLabel.NotExecuted,
}

export interface TestResult {
  idx: number;
  tester: string;
  scenarioName: string;
  startTime: string;
  endTime: string;
  type: TestResultType;
  result: TestResultLabel | TestResultStatus;
  device: string;
  appVersion: string;
  browserName: string;
  browserVersion: string;
  failReason: string;
  mobileOs: DeviceInfoOs;
}

export interface TestResultSummary {
  total: number;
  pass: number;
  fail: number;
  passRate: number | 'NaN';
}

export interface TestResultDetails {
  idx: number;
  seq: number;
  title: string;
  result: TestResultLabel | TestResultStatus;
  videoPath: string;
  logPath: string;
  description?: string;
  details: TestResultImageDetails[];
}

export interface TestResultImageDetails {
  idx: number;
  seq: number;
  description: string;
  imagePath: string;
}

export interface TestResultCaseDetail {
  idx: number;
  seq: number;
  command: MobileCommandOptionServerName;
  description: string;
  failReason: string;
  result: string;
  packageName: string;
  appVersion: string;
  browser: null | string;
}

export interface TestResultCommonProperties {
  idx: number;
  tester: string;
  scenarioName: string;
  startTime: string;
  endTime: string;
  result: TestResultLabel | TestResultStatus;
  type: TestResultType;
}
export interface MobileTestResultProperties extends TestResultCommonProperties {
  device: string;
  appVersion: string;
  mobileOs: DeviceInfoOs;
}
export interface MobileTestResultPagingData {
  first: boolean;
  last: boolean;
  currentPage: number;
  currentSize: number;
  totalPages: number;
  totalElements: number;
  content: MobileTestResultProperties[];
  initialCreatedAt: string;
}

export interface WebResult extends TestResultCommonProperties {
  browserName: string;
  browserVersion: string;
}

export interface WebTestResultPagingData {
  first: boolean;
  last: boolean;
  currentPage: number;
  currentSize: number;
  totalPages: number;
  totalElements: number;
  content: WebResult[];
  initialCreatedAt: string;
}

export interface TestResultInquiryByCase {
  summary: TestResultSummary;
  resultDetail: TestResultDetails[];
  information: TestResult;
}

export interface MobileTestResultDetailInquiryByCase {
  idx: number;
  seq: number;
  command: string;
  description: string;
  failReason: string;
  result: string;
  packageName: string;
  appVersion: string;
}

export interface WebTestResultDetailInquiryByCase {
  idx: number;
  seq: number;
  command: string;
  browser: string | null;
  description: string;
  result: TestResultLabel | TestResultStatus;
  failReason: string | '-';
}

export interface TestResultDetailInquiryByCase {
  idx: number;
  seq: number;
  command: MobileCommandOptionServerName;
  description: string;
  packageName: string;
  appVersion: string;
  browser: string | null;
  result: TestResultLabel | TestResultStatus;
  failReason: string | '-';
}

export const TestResultCaseResultExportSchema = z.object({
  caseResultIdx: z.number(),
  stepResultIdxList: z.array(z.number()),
});
export const TestResultExportCaseResultListSchema = z.object({
  caseResultList: z.array(TestResultCaseResultExportSchema),
  exportScreenshot: z.boolean(),
});

export type TestResultCaseResultExport = z.infer<
  typeof TestResultCaseResultExportSchema
>;
export type TestResultExportCaseResultList = z.infer<
  typeof TestResultExportCaseResultListSchema
>;
