import { Button, ErrorBoundaryWrapper } from '@autosquare/common';
import {
  DeviceApprovalRequest,
  DeviceApprovalRequestSchema,
} from '@customTypes/index';
import { zodResolver } from '@hookform/resolvers/zod';
import { useParentPath } from '@hooks/index';
import { useRegisterDeviceRequestMutation } from '@lib/api/dashboard/queryHooks';
import React, { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import ProjectDeviceInfoDialog from './ProjectDeviceInfoForm/ProjectDeviceInfoDialog';
import AddDeviceId from './ProjectDeviceInfoForm/AddDeviceId';
import AddDeviceModel from './ProjectDeviceInfoForm/AddDeviceModel';
import AddDeviceName from './ProjectDeviceInfoForm/AddDeviceName';
import AddDeviceOs from './ProjectDeviceInfoForm/AddDeviceOs';
import AddDeviceType from './ProjectDeviceInfoForm/AddDeviceType';
import AddDeviceApprovalRequest from './ProjectDeviceInfoForm/AddDeviceApprovalRequest';

const ProjectDeviceInfoForm = () => {
  const { projectIdx } = useParams();
  const deviceListPage = useParentPath();

  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const methods = useForm<DeviceApprovalRequest>({
    defaultValues: { projectIdx: Number(projectIdx) },
    resolver: zodResolver(DeviceApprovalRequestSchema),
  });

  const registerDeviceRequestMutation = useRegisterDeviceRequestMutation();

  const onSubmit: SubmitHandler<DeviceApprovalRequest> = (data) => {
    registerDeviceRequestMutation.mutate(data, {
      onSuccess: () => setIsSuccessDialogOpen(true),
      onError: () => setIsErrorDialogOpen(true),
    });
  };

  return (
    <ErrorBoundaryWrapper>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="space-y-6 border-b border-solid border-gray-200 pb-6 pt-1">
            <AddDeviceId />
            <AddDeviceModel />
            <AddDeviceName />
            <div className="grid grid-cols-2 gap-x-6">
              <AddDeviceOs />
              <AddDeviceType />
            </div>
            <AddDeviceApprovalRequest />
          </div>
          <div className="flex items-center justify-end gap-3 pt-6">
            <div className="flex items-center justify-end gap-3">
              <Link to={deviceListPage}>
                <Button type="button" variant="secondary">
                  Cancel
                </Button>
              </Link>
              <Button
                type="submit"
                disabled={registerDeviceRequestMutation.isLoading}
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
      <ProjectDeviceInfoDialog
        registerDeviceRequestMutation={registerDeviceRequestMutation}
        isSuccessDialogOpen={isSuccessDialogOpen}
        setIsSuccessDialogOpen={setIsSuccessDialogOpen}
        isErrorDialogOpen={isErrorDialogOpen}
        setIsErrorDialogOpen={setIsErrorDialogOpen}
      />
    </ErrorBoundaryWrapper>
  );
};

export default ProjectDeviceInfoForm;
