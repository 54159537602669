import { ErrorBoundaryWrapper } from '@autosquare/common';
import React from 'react';
import DetailProjectAdmin from './DetailProjectInformationInput/DetailProjectAdmin';
import DetailInputFields from './DetailProjectInformationInput/DetailInputFields';

const DetailProjectInformationInput = () => {
  return (
    <ErrorBoundaryWrapper>
      <section className="pt-4">
        <div className="space-y-2.5">
          <DetailInputFields id="name" label="Project Name" disabled={true} />
          <DetailInputFields
            id="serviceName"
            label="Service Name(옵션 - 0~100자 입력 가능)"
            isPreventEnterKey={true}
          />
          <DetailInputFields
            id="description"
            label="Description(옵션 - 0~100자 입력 가능)"
            isPreventEnterKey={true}
          />
          <div className="flex flex-col gap-2">
            <p className="font-medium">Project Admin*</p>
            <DetailProjectAdmin />
          </div>
        </div>
      </section>
    </ErrorBoundaryWrapper>
  );
};

export default DetailProjectInformationInput;
