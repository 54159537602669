import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';
import { useSpeedMobileTestCaseListQuery } from '@lib/api/ide/speedMobile/speedMobileTestCase';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { ClickId } from '../clickOptions';
import {
  DeviceKeyServerName,
  findListNameFromServerName,
} from '../deviceKeyList';
import { findTitleFromIdx } from '../findTitleFromIdx';
import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionServerName,
} from '../mobileCommandOption';

export const getCommandDisplayText = (list: MobileTestStepData) => {
  const isUiTest = isWordInLocationPathname('ui-test');
  const isSpeedTest = isWordInLocationPathname('speed-test');

  const { data: mobileTestCaseList } = useMobileTestCaseListQuery({
    enabled:
      isUiTest && list.command === MobileCommandOptionServerName.ImportTestCase,
  });

  const { data: mobileSpeedTestCaseList } = useSpeedMobileTestCaseListQuery({
    enabled:
      isSpeedTest &&
      list.command === MobileCommandOptionServerName.ImportTestCase,
  });

  const testCaseList = isUiTest
    ? mobileTestCaseList
    : isSpeedTest && mobileSpeedTestCaseList;
  const caseTitle = findTitleFromIdx(Number(list.value), testCaseList);
  const importTestStepCount = list.optionsDto?.stepIdxList?.split(',').length;

  switch (list.command) {
    case MobileCommandOptionServerName.Click:
      if (list.optionsDto.clickBy === ClickId.Image) {
        return 'Crop Image';
      } else if (list.value?.split(',').length >= 2) {
        return `[${list.value}]`;
      } else if (list.optionsDto.secret === 'true') {
        return '******';
      } else {
        return list.value;
      }

    case MobileCommandOptionServerName.CompareImage:
      return 'Crop Image';

    case MobileCommandOptionServerName.ScrollSwipe:
      return `[${list.value}]`;

    case MobileCommandOptionServerName.DeviceKey:
      return findListNameFromServerName(list.value as DeviceKeyServerName);

    case MobileCommandOptionServerName.Loop:
      return `Condition="${findMobileCommandNameFromServerName(
        list.optionsDto.conditionCommand,
      )}" Action="${findMobileCommandNameFromServerName(
        list.value as MobileCommandOptionServerName,
      )}"`;

    case MobileCommandOptionServerName.IF:
      return list.optionsDto.conditionCommand ===
        MobileCommandOptionServerName.CompareImage
        ? 'Crop Image'
        : list.optionsDto.conditionValue;

    case MobileCommandOptionServerName.ImportTestCase:
      return caseTitle;

    case MobileCommandOptionServerName.UnlockPattern:
      return 'points';

    case MobileCommandOptionServerName.ImportTestStep:
      return `${caseTitle} / ${importTestStepCount}`;

    case MobileCommandOptionServerName.SaveText:
    case MobileCommandOptionServerName.ConfirmText:
      return list.optionsDto.secret === 'true' ? '******' : list.value;

    default:
      return list.value;
  }
};
