import {
  Dashboard,
  Help,
  ProjectList,
  Settings,
  SpeedTest,
  UITest,
} from '@autosquare/common';

import {
  useProjectDetailQuery,
  useProjectListQuery,
} from '@lib/api/ide/project/project';

import { checkIsProjectFeatureIncludedAll } from '@utils/static/checkProjectFeature';

import SettingsAndHelpModal from '@components/SettingsAndHelpModal/SettingsAndHelpModal';

import { ProjectListDetailFeaturesFeature } from '@customTypes/dashboard/project/type';

import React, { ReactElement, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ComputerDesktopIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { Mode } from '@customTypes/ide/remote/type';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '@store/ide/remote/remoteSettingsModalSlice';
import { getRemoteState } from '@utils/static/getConfiguration';
import { RootState } from '@app/store';
import RemoteStartSettingDialog from '@components/Nav/shared/RemoteStartSettingDialog';
import { DevicePhoneMobileIcon } from '@heroicons/react/24/outline';
import { useReservationSettingQuery } from '@lib/api/ide/queryHooks';

type newSidebarList = {
  name: string;
  href?: string;
  icon: ReactElement;
  onClick: () => void;
  enabled: boolean;
};

const NewSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { projectIdx } = useParams();

  const [isSettingsModalOpened, setIsSettingsModalOpened] = useState(false);
  const [isHelpModalOpened, setIsHelpModalOpened] = useState(false);

  const projectListQuery = useProjectListQuery();
  const projectDetailQuery = useProjectDetailQuery({
    enabled: projectIdx !== undefined,
  });
  const { configuration } = getRemoteState();
  const projectDetail = projectDetailQuery.data;

  const { data: reservationSetting } = useReservationSettingQuery();
  const isReservationEnabled = reservationSetting?.reservation === 'true';

  const newSidebarList: newSidebarList[] = [
    {
      name: 'Project List',
      href: '/ide/projects/list',
      icon: <ProjectList />,
      onClick: () => navigate('/ide/projects/list'),
      enabled: true,
    },
    {
      name: 'Dashboard',
      href: `/ide/projects/${projectIdx}/dashboard/`,
      icon: <Dashboard />,
      onClick: () => navigate(`/ide/projects/${projectIdx}/dashboard/overview`),
      enabled: location.pathname.includes(projectIdx),
    },
    {
      name: 'UI Test',
      href: `/ide/projects/${projectIdx}/ui-test/`,
      icon: <UITest />,
      onClick: () => navigate(`/ide/projects/${projectIdx}/ui-test/overview`),
      enabled: checkIsProjectFeatureIncludedAll(
        projectDetail,
        ProjectListDetailFeaturesFeature.Functional,
      ),
    },
    {
      name: 'Speed Test',
      href: `/ide/projects/${projectIdx}/speed-test/`,
      icon: <SpeedTest />,
      onClick: () =>
        navigate(`/ide/projects/${projectIdx}/speed-test/overview`),
      enabled: checkIsProjectFeatureIncludedAll(
        projectDetail,
        ProjectListDetailFeaturesFeature.Speed,
      ),
    },
    {
      name: 'Devices',
      href: `/ide/projects/${projectIdx}/devices`,
      icon: <DevicePhoneMobileIcon className="size-full" strokeWidth={2} />,
      onClick: () => navigate(`/ide/projects/${projectIdx}/devices`),
      enabled: !!projectIdx && isReservationEnabled,
    },
    {
      name: 'Remote PC',
      icon: <ComputerDesktopIcon />,
      onClick: () => {
        dispatch(showModal());
      },
      enabled: configuration === Mode.Remote,
    },
    {
      name: 'Settings',
      icon: <Settings />,
      onClick: () => setIsSettingsModalOpened(true),
      enabled: true,
    },
    {
      name: 'Help',
      icon: <Help />,
      onClick: () => setIsHelpModalOpened(true),
      enabled: true,
    },
  ];

  const filteredNewSidebarList = newSidebarList.filter(
    (item) => item.enabled === true,
  );

  const isRemoteSettingsOpen = useSelector(
    (state: RootState) => state.remoteSettingsModal.isRemoteSettingOpen,
  );

  return (
    <>
      <aside className="z-50 inline-flex h-screen w-20 flex-col items-start justify-start bg-gray-900 md:w-24 lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-20 lg:flex-col">
        <div className="flex h-72 flex-col items-center justify-start gap-5 self-stretch py-5">
          {projectListQuery.isLoading || projectListQuery.isFetching ? (
            Array.from({ length: 4 }, (_, i) => (
              <div
                key={i}
                className={`flex size-16 animate-pulse flex-col items-center justify-center gap-1 rounded-lg bg-gray-500 px-2 py-1.5`}
              >
                <div className="relative size-6"></div>
                <div className="whitespace-wrap text-center text-[0.625rem] font-semibold leading-normal"></div>
              </div>
            ))
          ) : (
            <>
              {filteredNewSidebarList.map((item) => (
                <button
                  key={item.name}
                  onClick={item.onClick}
                  className={clsx(
                    'flex min-h-16 w-16 flex-col items-center justify-center gap-1 rounded-lg p-2 hover:bg-gray-800 hover:text-white',
                    item.name.includes('PC') && 'whitespace-nowrap',
                    location.pathname.includes(item.href)
                      ? 'bg-gray-800 text-white'
                      : 'text-gray-400',
                  )}
                >
                  <div className="relative size-6">{item.icon}</div>
                  <div className="whitespace-wrap text-center text-[0.625rem] font-semibold leading-normal">
                    {item.name}
                  </div>
                </button>
              ))}
            </>
          )}
        </div>
      </aside>
      {isSettingsModalOpened ? (
        <SettingsAndHelpModal
          type={'settings'}
          setState={setIsSettingsModalOpened}
        />
      ) : (
        isHelpModalOpened && (
          <SettingsAndHelpModal type={'help'} setState={setIsHelpModalOpened} />
        )
      )}
      {isRemoteSettingsOpen && <RemoteStartSettingDialog />}
    </>
  );
};

export default NewSidebar;
