import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import OverviewSubTitleHeader from '@components/shared/OverviewSubTitleHeader';
import {
  ResultReports,
  TestResultLabelList,
} from '@customTypes/ide/overview/type';
import OverviewNoData from '@components/shared/OverviewNoData';
import { TestResultLabel } from '@customTypes/index';

type Props = {
  data: ResultReports;
  header: string;
};

const ResultRatePie = ({ data, header }: Props) => {
  const sortOrderLabel: TestResultLabelList[] = [
    TestResultLabel.Pass,
    TestResultLabel.Fail,
    TestResultLabel.NA,
  ];

  const sortedSeriesData: number[] = sortOrderLabel.map((label) => {
    const index = data?.resultRate?.labels.indexOf(label);
    return data?.resultRate?.percent[index];
  });
  const filteredSeriesData = sortedSeriesData.filter((value) => value > 0);

  const pieData: { series: number[]; options: ApexOptions } = {
    series: filteredSeriesData,
    options: {
      chart: {
        type: 'pie',
      },
      plotOptions: {
        pie: {
          customScale: 0.8,
          dataLabels: {
            offset: -40,
          },
        },
      },
      tooltip: {
        y: {
          formatter: (v) => `${Math.ceil(Number(v))}%`,
        },
      },
      dataLabels: {
        enabled: true,
        distributed: false,
        textAnchor: 'middle',
        style: {
          fontFamily: 'inter',
          fontSize: '16px',
          fontWeight: '600',
        },
        formatter: (v) => `${Math.ceil(Number(v))}%`,
        dropShadow: {
          enabled: false,
        },
      },
      legend: {
        position: 'right',
        horizontalAlign: 'center',
        offsetX: 20,
        offsetY: 100,
        onItemHover: {
          highlightDataSeries: true,
        },
        markers: { radius: 0 },
      },
      responsive: [{ breakpoint: 1280, options: { chart: { width: 150 } } }],
      labels: sortOrderLabel,
      colors: ['#73c0de', '#ee6666', '#fac858'],
    },
  };
  const isDataNull =
    !data?.resultRate?.percent ||
    data?.resultRate?.percent.every((item) => item === 0);

  return (
    <div>
      <OverviewSubTitleHeader header={header} />
      {isDataNull ? (
        <OverviewNoData />
      ) : (
        <div id="chart">
          <ReactApexChart
            height={350}
            options={pieData.options}
            series={pieData.series}
            type="pie"
          />
        </div>
      )}
    </div>
  );
};

export default ResultRatePie;
