import { Button } from '@autosquare/common';
import React from 'react';
import { Link } from 'react-router-dom';

const AccessDenied = () => {
  return (
    <section className="flex-center h-[calc(100dvh-26rem)] w-full flex-col gap-2.5">
      <h3 className="text-lg font-bold text-error-message">권한이 없습니다.</h3>
      <Link to={'/projects'}>
        <Button type="button" variant="primary">
          프로젝트 페이지 이동
        </Button>
      </Link>
    </section>
  );
};

export default AccessDenied;
