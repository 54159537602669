import { ArrowSpinner, ErrorBoundaryWrapper } from '@autosquare/common';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useCreateMobileTestStepMutation } from '@lib/api/ide/mobile/mobileTestStep';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import { ClickId } from '@utils/static/clickOptions';
import {
  CreateTestStepData,
  MaskingOptionsType,
  MaskingOptionsValueListObject,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { AppInfoSource, DeviceInfoSource } from '@customTypes/testStep/type';
import { SaveTextFrom } from '@customTypes/ide/mobileTestStep/SaveTextFrom';
import MobileTestStepForm from './AddTestStep/MobileTestStepForm';
import { useCreateSpeedMobileTestStep } from '@lib/api/ide/speedMobile/speedMobileTestStep';
import { isValueListObject } from '@utils/index';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import ErrorDialog from '@components/Dialog/ErrorDialog';

interface Props {
  deviceInfo: DeviceInfoSource;
  appInfo: AppInfoSource;
}

const AddTestStep = ({ deviceInfo, appInfo }: Props) => {
  const { setValue, handleSubmit, reset } =
    useFormContext<CreateTestStepData>();

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const needToDefaultValues = () => {
    setValue('packageName', appInfo?.package_name);
    setValue('appVersion', appInfo?.app_version);
    setValue('optionsDto.udid', deviceInfo?.udid);
    setValue('optionsDto.model', deviceInfo?.model);
    setValue('optionsDto.screenSize', deviceInfo?.physical_size);
    setValue('optionsDto.overrideSize', deviceInfo?.override_size);
    setValue('optionsDto.dpi', deviceInfo?.physical_dpi);
    setValue('optionsDto.overrideDpi', deviceInfo?.override_dpi);
    setValue('optionsDto.viewportSize', deviceInfo?.viewport_size);
    setValue('optionsDto.waitingTime', '2');
    setValue('required', true);
    setValue('optionsDto.actionOptions', undefined);
    setValue('optionsDto.passActionOptions', undefined);
    setValue('optionsDto.failActionOptions', undefined);
    setValue('optionsDto.stepIdxList', undefined);
    setValue('optionsDto.fullXpath', undefined);
  };

  useEffect(() => {
    needToDefaultValues();
  }, [appInfo, deviceInfo, setValue]);

  const createTestStepMutation = isWordInLocationPathname('ui-test')
    ? useCreateMobileTestStepMutation()
    : useCreateSpeedMobileTestStep();

  const onSubmit: SubmitHandler<CreateTestStepData> = (data) => {
    if (
      (data.command === MobileCommandOptionServerName.Click &&
        data.optionsDto.clickBy === ClickId.Default) ||
      data.command === MobileCommandOptionServerName.FindElement ||
      (data.command === MobileCommandOptionServerName.SaveText &&
        data.optionsDto.from === SaveTextFrom.Element) ||
      data.command === MobileCommandOptionServerName.ConfirmText ||
      data.command === MobileCommandOptionServerName.InputText ||
      data.command === MobileCommandOptionServerName.VerifyNoElement ||
      data.command === MobileCommandOptionServerName.DataValidation
    ) {
      data.value = `xpath=${data.value}`;
    }

    if (
      data.command === MobileCommandOptionServerName.Loop &&
      (data.optionsDto.conditionCommand ===
        MobileCommandOptionServerName.VerifyNoElement ||
        data.optionsDto.conditionCommand ===
          MobileCommandOptionServerName.FindElement)
    ) {
      data.optionsDto.conditionValue = `xpath=${data.optionsDto.conditionValue}`;
    }

    if (
      data.command === MobileCommandOptionServerName.Loop &&
      data.value === MobileCommandOptionServerName.Click
    ) {
      data.optionsDto.actionValue = `xpath=${data.optionsDto.actionValue}`;
    }

    if (
      data.command === MobileCommandOptionServerName.IF &&
      (data.optionsDto.conditionCommand ===
        MobileCommandOptionServerName.FindElement ||
        data.optionsDto.conditionCommand ===
          MobileCommandOptionServerName.VerifyNoElement)
    ) {
      data.optionsDto.conditionValue = `xpath=${data.optionsDto.conditionValue}`;
    }

    if (
      data.command === MobileCommandOptionServerName.IF &&
      data.value.split(',')[0] === MobileCommandOptionServerName.Click
    ) {
      data.optionsDto.passActionValue = `xpath=${data.optionsDto.passActionValue}`;
    }

    if (
      data.command === MobileCommandOptionServerName.IF &&
      data.value.split(',')[1] !== undefined &&
      data.value.split(',')[1] === MobileCommandOptionServerName.Click
    ) {
      data.optionsDto.failActionValue = `xpath=${data.optionsDto.failActionValue}`;
    }

    const valueList = data.optionsDto.maskingOptions.valueList;

    if (
      data.optionsDto.maskingOptions.enabled &&
      data.optionsDto.maskingOptions.type === MaskingOptionsType.Element &&
      valueList &&
      isValueListObject(valueList[0])
    ) {
      data.optionsDto.maskingOptions.valueList = (
        valueList as MaskingOptionsValueListObject[]
      ).map((item) => `xpath=${item.value}`);
    }

    createTestStepMutation.mutate(data, {
      onSuccess: () => {
        reset();
        needToDefaultValues();
      },
      onError: () => {
        setIsErrorDialogOpen(true);
      },
    });
  };

  return (
    <ErrorBoundaryWrapper>
      <form
        className="gray-scrollbar col-span-3 flex h-full flex-col gap-4 border p-4"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <MobileTestStepForm />
      </form>
      {createTestStepMutation.isLoading && <ArrowSpinner />}
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        setIsOpen={setIsErrorDialogOpen}
        subTitle={createTestStepMutation.error?.message}
      />
    </ErrorBoundaryWrapper>
  );
};

export default AddTestStep;
