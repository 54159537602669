import { ErrorBoundaryWrapper } from '@autosquare/common';
import { SectionTitleMain } from '@autosquare/common';
import { SectionTitle } from '@autosquare/common';
import { SectionMainTitleContainer } from '@autosquare/common';
import React, { ReactNode } from 'react';

type TestCaseInfoHeaderProps = {
  children: ReactNode;
  buttonChildren?: ReactNode;
};

const TestCaseInfoHeader = ({
  children,
  buttonChildren,
}: TestCaseInfoHeaderProps) => {
  return (
    <div className="mt-8 pb-5">
      <SectionTitle>
        <SectionMainTitleContainer>
          <SectionTitleMain>Test Case Information</SectionTitleMain>
          {buttonChildren}
        </SectionMainTitleContainer>
      </SectionTitle>
      <ErrorBoundaryWrapper>{children}</ErrorBoundaryWrapper>
    </div>
  );
};

export default TestCaseInfoHeader;
