import React from 'react';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  UpdateWebTestStepData,
  WebTestStepData,
} from '@customTypes/testStep/type';
import EditConditionAndValue from './EditDataValidation/EditConditionAndValue';
import EditDataValidationInputData from './EditDataValidation/EditDataValidationInputData';
import { useFieldArray, useFormContext } from 'react-hook-form';

type Props = {
  webTestStepData: WebTestStepData;
};

const EditDataValidation = ({ webTestStepData }: Props) => {
  const { control } = useFormContext<UpdateWebTestStepData>();

  const { fields, append, remove, replace } = useFieldArray<
    UpdateWebTestStepData,
    'optionsDto.operatorList'
  >({
    control,
    name: 'optionsDto.operatorList',
  });

  return (
    <ErrorBoundaryWrapper>
      <EditConditionAndValue
        webTestStepData={webTestStepData}
        replace={replace}
      />
      <EditDataValidationInputData
        fields={fields}
        append={append}
        remove={remove}
      />
    </ErrorBoundaryWrapper>
  );
};

export default EditDataValidation;
