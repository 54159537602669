import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';

import DefinitionList from '@components/shared/DefinitionList';
import DefinitionTextAreaByReactHookForm from '@components/shared/TestStepDetail/DefinitionTextAreaByReactHookForm';
import DetailDivisionLine from '@components/shared/TestStepDetail/DetailDivisionLine';
import TestStepDetailBasicDefinition from '@components/shared/TestStepDetail/TestStepDetailBasicDefinition';
import TestStepListValue from '@components/TestStepCommon/TestStepListValue';

import {
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionName,
  mobileCommandOptions,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';

import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  mobileTestStepData: MobileTestStepData;
};

const IfCondition = ({ mobileTestStepData }: Props) => {
  const { control, watch, setValue } = useFormContext<UpdateTestStepData>();

  const conditionCommand = watch('optionsDto.conditionCommand');

  useEffect(() => {
    if (
      mobileTestStepData.optionsDto.conditionCommand ===
      MobileCommandOptionServerName.CompareImage
    ) {
      if (conditionCommand === MobileCommandOptionServerName.CompareImage) {
        setValue(
          'optionsDto.conditionValue',
          mobileTestStepData.optionsDto.conditionValue,
        );
      } else {
        setValue('optionsDto.conditionValue', undefined);
      }
    }
  }, [mobileTestStepData, conditionCommand, setValue]);

  const filteredList = mobileCommandOptions.filter(
    (command) =>
      command.name === MobileCommandOptionName.FindElement ||
      command.name === MobileCommandOptionName.VerifyNoElement ||
      command.name === MobileCommandOptionName.CompareImage,
  );

  const filteredListWithDisabled = filteredList.map((command) =>
    mobileTestStepData.optionsDto.conditionCommand !==
      MobileCommandOptionServerName.CompareImage &&
    command.name === MobileCommandOptionName.CompareImage
      ? {
          ...command,
          disabled: true,
        }
      : command,
  );

  return (
    <ErrorBoundaryWrapper>
      <DetailDivisionLine
        title={'Condition'}
        description={'조건식을 설정합니다.'}
      />
      <DefinitionList
        term={'Command'}
        description={
          <Controller
            name={'optionsDto.conditionCommand'}
            control={control}
            render={({ field }) => (
              <ListBoxHeadlessInController
                value={field.value}
                buttonValue={findMobileCommandNameFromServerName(field.value)}
                onChange={field.onChange}
                lists={filteredListWithDisabled}
                valueToSave={'serverName'}
                valueToShow={'name'}
              />
            )}
          />
        }
      />
      {conditionCommand === MobileCommandOptionServerName.CompareImage ? (
        <TestStepDetailBasicDefinition
          term={'Value'}
          description={
            mobileTestStepData?.optionsDto.conditionCommand ===
            MobileCommandOptionServerName.CompareImage ? (
              <TestStepListValue
                imageValue={mobileTestStepData.optionsDto.conditionValue}
              />
            ) : (
              mobileTestStepData.optionsDto.conditionValue
            )
          }
        />
      ) : mobileTestStepData.optionsDto.conditionCommand ===
        MobileCommandOptionServerName.CompareImage ? (
        <DefinitionTextAreaByReactHookForm
          term={'Value*'}
          name={'optionsDto.conditionValue'}
          disableAutoHeight={true}
        />
      ) : (
        <DefinitionTextAreaByReactHookForm
          term={'Value*'}
          name={'optionsDto.conditionValue'}
        />
      )}
    </ErrorBoundaryWrapper>
  );
};

export default IfCondition;
