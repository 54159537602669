import React from 'react';
import { SubHeaderTitle } from '@autosquare/common';
import InputAutoRegister from './InputAutoRegister';
import { useFormContext } from 'react-hook-form';
import { CreateTestScenarioData } from '@customTypes/testScenario/type';
import { InputBasic } from '@components/shared/InputBasic';
import { AutoRegisterInfoData } from '@customTypes/ide/scheduler/scheduler';
import { useGetAutoRegistersInfoQuery } from '@lib/api/ide/autoRegisters/autoRegisters';
import clsx from 'clsx';

type Props = {
  autoRegistersList: AutoRegisterInfoData[];
  setAutoRegistersList: React.Dispatch<
    React.SetStateAction<AutoRegisterInfoData[]>
  >;
};

const InputSpace = ({ autoRegistersList, setAutoRegistersList }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CreateTestScenarioData>();

  const { data: autoRegisterInfo } = useGetAutoRegistersInfoQuery();
  const isAutoRegistersActivate = autoRegisterInfo?.length > 0;
  return (
    <div className="mt-8 pb-4">
      <SubHeaderTitle
        subTitle={'Test Scenario Information'}
        description={'테스트 시나리오 정보를 입력해주세요.'}
      />
      <div className="mt-4 flex flex-col">
        <div
          className={clsx(
            'grid gap-x-4',
            isAutoRegistersActivate ? 'grid-cols-11' : 'grid-cols-8',
          )}
        >
          <InputBasic
            labelName={'title'}
            inputName={'Title*'}
            register={register('title', {
              required: '필수 입력 항목입니다.',
              maxLength: {
                value: 50,
                message: '50자를 초과할 수 없습니다.',
              },
            })}
            errors={errors}
          />
          <InputBasic
            labelName={'description'}
            inputName={'Description'}
            register={register('description', {
              maxLength: {
                value: 200,
                message: '200자를 초과할 수 없습니다.',
              },
            })}
            errors={errors}
          />
          {isAutoRegistersActivate && (
            <InputAutoRegister
              autoRegistersList={autoRegistersList}
              setAutoRegistersList={setAutoRegistersList}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default InputSpace;
