import {
  ErrorBoundaryWrapper,
  PageHeadingButtons,
  PagingHeadings,
} from '@autosquare/common';
import { CommonDialog } from '@components/Dialog';
import { RESET_DELAY_MS } from '@constants/timeout';
import { useParentPath } from '@hooks/index';
import { useDeleteAdminProjectMutation } from '@lib/api/dashboard/queryHooks';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
  isEdited: boolean;
  setIsEdited: React.Dispatch<React.SetStateAction<boolean>>;
};

const AdminProjectDetailPagingHeadings = ({ isEdited, setIsEdited }: Props) => {
  const { pathname, search } = useLocation();

  const projectListPage = useParentPath();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteErrorModalOpen, setIsDeleteErrorModalOpen] = useState(false);

  const deleteAdminProjectMutation = useDeleteAdminProjectMutation();

  const deleteOnClick = () => {
    deleteAdminProjectMutation.mutate(undefined, {
      onError: () => {
        setIsDeleteErrorModalOpen(true);
      },
      onSettled: () => {
        setIsDeleteModalOpen(false);
      },
    });
  };

  const errorModalOnClick = () => {
    setIsDeleteErrorModalOpen(false);
    setTimeout(() => {
      deleteAdminProjectMutation.reset();
    }, RESET_DELAY_MS);
  };

  const breadcrumbs = [
    {
      title: 'Administration',
      href: projectListPage,
    },
    {
      title: 'Projects',
      href: projectListPage,
    },
    { title: isEdited ? 'Edit' : 'Detail', href: pathname + search },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: 'Cancel',
      type: 'button',
      variant: 'secondary',
      href: projectListPage,
    },
    {
      title: 'Edit',
      onClick: () => setIsEdited(true),
      type: 'button',
      variant: 'primary',
    },
    {
      title: 'Delete',
      onClick: () => setIsDeleteModalOpen(true),
      type: 'button',
      variant: 'caution',
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={isEdited ? 'Project Edit' : 'Project Details'}
        pageHeadingButtons={isEdited ? null : pageHeadingButtons}
      />
      <CommonDialog
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        iconType={'caution'}
        title={'Delete Project'}
        subTitle={
          '해당 프로젝트를 삭제하시겠습니까?\n프로젝트를 삭제하면 더 이상 사용할 수 없습니다.\n(해당 프로젝트에 등록된 디바이스 및 정보들이 삭제됩니다.)'
        }
      >
        <CommonDialog.DialogButtonPanels>
          <CommonDialog.DialogButton
            type="button"
            variant="caution"
            onClick={deleteOnClick}
            disabled={deleteAdminProjectMutation.isLoading}
          >
            Delete
          </CommonDialog.DialogButton>
          <CommonDialog.DialogButton
            type="button"
            variant="secondary"
            onClick={() => setIsDeleteModalOpen(false)}
          >
            Cancel
          </CommonDialog.DialogButton>
        </CommonDialog.DialogButtonPanels>
      </CommonDialog>
      <CommonDialog
        isOpen={isDeleteErrorModalOpen}
        setIsOpen={setIsDeleteErrorModalOpen}
        iconType={'caution'}
        title={'Delete Error'}
        subTitle={
          deleteAdminProjectMutation.isError &&
          deleteAdminProjectMutation.error.message
        }
        onClose={errorModalOnClick}
      >
        <CommonDialog.DialogButtonPanels>
          <CommonDialog.DialogButton
            type="button"
            variant="secondary"
            onClick={errorModalOnClick}
          >
            Close
          </CommonDialog.DialogButton>
        </CommonDialog.DialogButtonPanels>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default AdminProjectDetailPagingHeadings;
