import React from 'react';

type Props = {
  title?: string;
  content: number | string | JSX.Element;
};

const DetailUserGrid = ({ title, content }: Props) => {
  return (
    <div className="grid grid-cols-3 py-4">
      <div>{title}</div>
      <div className="col-span-2 grid grid-cols-2">{content}</div>
    </div>
  );
};

export default DetailUserGrid;
