import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { axiosLocalInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';
import { browserKeys } from '@queries/ide/web/browserKeys';
import { BrowserInfo } from '@customTypes/ide/browser/browser';
import { Mode } from '@customTypes/ide/remote/type';
import { getRemoteState } from '@utils/static/getConfiguration';

// GET
const webBrowserList = async (): Promise<BrowserInfo[]> => {
  const response = await axiosLocalInstance.get<BrowserInfo[]>(
    IDEAPI.GET_BROWSER_LIST,
  );
  return response.data;
};
export const useWebBrowserListQuery = (
  options?: UseQueryOptions<BrowserInfo[], Error>,
) => {
  const { configuration } = getRemoteState();
  return useQuery<BrowserInfo[], Error>({
    queryKey: browserKeys.all,
    queryFn: () => webBrowserList(),
    enabled: configuration === Mode.Standard,
    ...options,
  });
};
