import {
  WebSpeedTestResultData,
  WebSpeedTestResultDetail,
  WebSpeedTestResultTableDetailData,
} from '@customTypes/ide/speedTest/Web/testResult/type';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { axiosRawResponseInstance, axiosInstance } from '..';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { API } from '@api/endpoints';
import { webSpeedTestResultKeys } from '@queries/dashboard/webSpeedTestResultKeys';
import { DeleteIndexList } from '@customTypes/index';

// GET
const webSpeedTestResultList = async (
  projectIdx: string,
  page: number,
): Promise<WebSpeedTestResultData> => {
  const response = await axiosInstance.get<WebSpeedTestResultData>(
    API.getWebSpeedTestResultList(projectIdx, page),
  );
  return response.data;
};
export const useWebSpeedTestResultListQuery = (
  page: number,
  options?: UseQueryOptions<WebSpeedTestResultData>,
) => {
  const { projectIdx } = useParams();
  const queryKey = `${projectIdx}-${page}`;
  return useQuery<WebSpeedTestResultData, Error>({
    queryKey: webSpeedTestResultKeys.list(queryKey),
    queryFn: () => webSpeedTestResultList(projectIdx, page),
    ...options,
  });
};

const webSpeedTestResultDetail = async (
  projectIdx: string,
  groupIdx: string,
  idx: string,
  start: string,
  end: string,
  orderBy: string,
  channel: string,
): Promise<WebSpeedTestResultDetail> => {
  const response = await axiosInstance.get<WebSpeedTestResultDetail>(
    API.getWebSpeedTestResultDetail(
      projectIdx,
      groupIdx,
      idx,
      start,
      end,
      orderBy,
      channel,
    ),
  );
  return response.data;
};

export const useWebSpeedTestResultDetailQuery = () => {
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const groupIdx = searchParams.get('group');
  const idx = searchParams.get('result');
  const start = searchParams.get('start');
  const end = searchParams.get('end');
  const orderBy = searchParams.get('order-by');
  const LowerCaseChannel = searchParams.get('channel');
  const channel =
    LowerCaseChannel.charAt(0).toUpperCase() + LowerCaseChannel.slice(1);

  return useQuery<WebSpeedTestResultDetail, Error>({
    queryKey: webSpeedTestResultKeys.detail(idx),
    queryFn: () =>
      webSpeedTestResultDetail(
        projectIdx,
        groupIdx,
        idx,
        start,
        end,
        orderBy,
        channel,
      ),
  });
};
const webSpeedTestResultTableDetail = async (
  chartIdx: string,
): Promise<WebSpeedTestResultTableDetailData> => {
  const response = await axiosInstance.get<WebSpeedTestResultTableDetailData>(
    API.getWebSpeedTestResultByIdx(chartIdx),
  );
  return response.data;
};

export const useWebSpeedTestResultTableDetailQuery = (
  chartIdx: string,
  options?: UseQueryOptions<WebSpeedTestResultTableDetailData>,
) => {
  return useQuery<WebSpeedTestResultTableDetailData, Error>({
    queryFn: () => webSpeedTestResultTableDetail(chartIdx),
    queryKey: webSpeedTestResultKeys.detailInfo(chartIdx),
    ...options,
  });
};

const searchWebSpeedTestResultList = async (
  search: string,
): Promise<WebSpeedTestResultData> => {
  const response = await axiosInstance.get<WebSpeedTestResultData>(
    `${API.WEB_SPEED_TEST_RESULT}/search${search}`,
  );
  return response.data;
};
export const useSearchWebSpeedTestResultListQuery = () => {
  const { search } = useLocation();
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page');
  const size = searchParams.get('size');
  const projectIdx = searchParams.get('projectIdx');
  const order = searchParams.get('order');

  return useQuery<WebSpeedTestResultData, Error>({
    queryFn: () => searchWebSpeedTestResultList(search),
    queryKey: webSpeedTestResultKeys.search(search),
    enabled:
      page !== null && size !== null && projectIdx !== null && order !== null,
    keepPreviousData: true,
  });
};

// POST
const webSpeedTestResultExport = async (
  result: string,
  data: FormData,
): Promise<{ blob: Blob; filename: string }> => {
  const response = await axiosRawResponseInstance.post<Blob>(
    `${API.WEB_SPEED_TEST_RESULT}/${result}/download-excel`,
    data,
    { responseType: 'blob' },
  );

  const contentDisposition = response.headers['content-disposition'];
  const filenameMatch = contentDisposition.match(/filename="(.+)"/);

  return { blob: response.data, filename: filenameMatch[1] };
};
export const useWebSpeedTestResultExportMutation = () => {
  const [searchParams] = useSearchParams();
  const result = searchParams.get('result');

  return useMutation<{ blob: Blob; filename: string }, Error, FormData>({
    mutationFn: (data) => webSpeedTestResultExport(result, data),
    onSuccess: ({ blob, filename }) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },
  });
};

// DELETE
const webSpeedTestResultDelete = async (
  data: DeleteIndexList,
): Promise<DeleteIndexList> => {
  const response = await axiosInstance.delete<DeleteIndexList>(
    `${API.WEB_SPEED_TEST_RESULT}`,
    { data },
  );
  return response.data;
};
export const useWebSpeedTestResultDeleteMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const backToListPage = localStorage.getItem('entryPoint');

  return useMutation<DeleteIndexList, Error, DeleteIndexList>({
    mutationFn: (data) => webSpeedTestResultDelete(data),
    onSuccess: () => {
      queryClient.invalidateQueries(webSpeedTestResultKeys.lists());
      queryClient.invalidateQueries(webSpeedTestResultKeys.searches());
      navigate(backToListPage);
    },
  });
};
