import React, { useState } from 'react';
import { Button } from '@autosquare/common';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import { WdaStatusResponse } from '@customTypes/ide/device/device';

type Props = {
  derivedData: WdaStatusResponse;
  setFileData: React.Dispatch<
    React.SetStateAction<{ projectIdx: number; file: File }[]>
  >;
  onClickDownloadDataHandler: (projectIdx: number) => void;
};

const RemoteWebDriverAgentBodyRow = ({
  derivedData,
  setFileData,
  onClickDownloadDataHandler,
}: Props) => {
  const [zipFileName, setZipFileName] = useState('');
  const [isUploadedFile, setIsUploadedFile] = useState(false);

  const handleFilesChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number,
  ) => {
    const file = e.target.files['0'];
    setFileData((previousFileData) => [
      ...previousFileData,
      { projectIdx: idx, file: file },
    ]);
    setZipFileName(e.target.files['0'].name);
    setIsUploadedFile(true);
  };

  return (
    <ErrorBoundaryWrapper>
      <tr key={derivedData.projectName}>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
          {derivedData.projectName}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
          {derivedData.status === true ? (
            <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
              <svg
                className="size-1.5 fill-green-500"
                viewBox="0 0 6 6"
                aria-hidden="true"
              >
                <circle cx={3} cy={3} r={3} />
              </svg>
              Latest
            </span>
          ) : derivedData.status === false ? (
            <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
              <svg
                className="size-1.5 fill-yellow-500"
                viewBox="0 0 6 6"
                aria-hidden="true"
              >
                <circle cx={3} cy={3} r={3} />
              </svg>
              Updated
            </span>
          ) : (
            <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
              <svg
                className="size-1.5 fill-red-500"
                viewBox="0 0 6 6"
                aria-hidden="true"
              >
                <circle cx={3} cy={3} r={3} />
              </svg>
              None
            </span>
          )}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
          {derivedData.filePath && !derivedData.status ? (
            <Button
              type="button"
              variant="link"
              buttonSize="none"
              onClick={() => onClickDownloadDataHandler(derivedData.projectIdx)}
            >
              Download
            </Button>
          ) : (
            '-'
          )}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
          {isUploadedFile ? (
            <div className="flex items-center gap-x-2">
              {zipFileName}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4 cursor-pointer"
                onClick={() => {
                  setIsUploadedFile(false);
                  setZipFileName('');
                  setFileData((previousData) =>
                    previousData.filter(
                      (data) => data.projectIdx !== derivedData.projectIdx,
                    ),
                  );
                }}
              >
                <path
                  fillRule="evenodd"
                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Zm2.78-4.22a.75.75 0 0 1-1.06 0L8 9.06l-1.72 1.72a.75.75 0 1 1-1.06-1.06L6.94 8 5.22 6.28a.75.75 0 0 1 1.06-1.06L8 6.94l1.72-1.72a.75.75 0 1 1 1.06 1.06L9.06 8l1.72 1.72a.75.75 0 0 1 0 1.06Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          ) : (
            <label
              htmlFor={`upload-wda-${derivedData.projectIdx}`}
              className="hover:text-indigo-9000 cursor-pointer text-indigo-600"
            >
              Upload
              <input
                className="sr-only"
                type="file"
                accept=".zip"
                id={`upload-wda-${derivedData.projectIdx}`}
                onChange={(e) => handleFilesChange(e, derivedData.projectIdx)}
                multiple
              />
            </label>
          )}
        </td>
      </tr>
    </ErrorBoundaryWrapper>
  );
};

export default RemoteWebDriverAgentBodyRow;
