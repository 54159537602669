import { ErrorBoundaryWrapper } from '@autosquare/common';
import ExportOptionsTestStepResultUI from '@components/ExportOptionsDialog/components/ExportOptionsTestStepResultUI';
import { useExportOptionsTestStepResult } from '@hooks/index';
import { useMobileTestCaseExecutionDetailQuery } from '@lib/api/ide/mobile/mobileTestResult';
import { useWebTestCaseExecutionDetailQuery } from '@lib/api/ide/web/webTestResult';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import React from 'react';

type Props = {
  caseResultIdx: number;
  isPanelOpen: boolean;
};

const ExportOptionsTestStepResult = ({ caseResultIdx, isPanelOpen }: Props) => {
  const testResultDetailInquiryByCaseQuery = isWordInLocationPathname('mobile')
    ? useMobileTestCaseExecutionDetailQuery(caseResultIdx)
    : useWebTestCaseExecutionDetailQuery(caseResultIdx);
  const testResultDetailInquiryByCase = testResultDetailInquiryByCaseQuery.data;

  const { caseResult, handleChildCheckbox } =
    useExportOptionsTestStepResult(caseResultIdx);

  return (
    <ErrorBoundaryWrapper>
      <ExportOptionsTestStepResultUI
        isPanelOpen={isPanelOpen}
        testResultDetailInquiryByCase={testResultDetailInquiryByCase}
        caseResult={caseResult}
        handleChildCheckbox={handleChildCheckbox}
      />
    </ErrorBoundaryWrapper>
  );
};

export default ExportOptionsTestStepResult;
