import {
  ErrorBoundaryWrapper,
  SectionTitle,
  SectionMainTitleContainer,
  SectionTitleMain,
  SectionTitleRight,
} from '@autosquare/common';
import {
  CreateTestDataAutomation,
  UpdateTestDataAutomation,
} from '@customTypes/testCase/type';
import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { useFormContext, UseFormRegisterReturn } from 'react-hook-form';

const RegisterInformation = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CreateTestDataAutomation | UpdateTestDataAutomation>();

  const automationInfoInput: {
    title: string;
    register: UseFormRegisterReturn;
    name: keyof CreateTestDataAutomation | keyof UpdateTestDataAutomation;
  }[] = [
    {
      title: 'Title*',
      register: {
        ...register('title'),
      },
      name: 'title',
    },
    {
      title: 'Description',
      register: {
        ...register('description'),
      },
      name: 'description',
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <SectionTitle>
        <SectionMainTitleContainer>
          <SectionTitleMain>Information</SectionTitleMain>
          <SectionTitleRight type="caution">*필수 입력 사항</SectionTitleRight>
        </SectionMainTitleContainer>
      </SectionTitle>
      <div className="grid grid-cols-2 gap-x-4">
        {automationInfoInput.map((item) => (
          <div key={item.name}>
            <h3 className="text-sm font-medium leading-normal text-gray-900">
              {item.title}
            </h3>
            <input className="input-base mt-1" {...item.register} />
            <ErrorMessage
              errors={errors}
              name={item.name}
              render={({ message }) => (
                <p className="standard-error-message">{message}</p>
              )}
            />
          </div>
        ))}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default RegisterInformation;
