import React from 'react';
import { ErrorBoundaryWrapper, Spinner } from '@autosquare/common';
import { useFormContext, useWatch } from 'react-hook-form';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { AddAppInfo } from '@customTypes/dashboard/project/application/type';
import { ErrorMessage } from '@hookform/error-message';

import { ErrorDialog } from '@components/Dialog';
import FileUploadInput from './UploadFileSection/FileUploadInput';
import { useUploadFileSection } from '@hooks/useUploadFileSection';

const UploadFileSection = () => {
  const {
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<AddAppInfo>();
  const file = watch('file');
  const uploadedInfo = useWatch({ control });

  const {
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
    isUploaded,
    setIsUploaded,
    handleAppFileDelete,
    handleAppFileUpload,
    deleteData,
    isUploadErrorModalOpen,
    setIsUploadErrorModalOpen,
    isDeleteErrorModalOpen,
    setIsDeleteErrorModalOpen,
    uploadFileAndGetInfoMutation,
    deleteTempFileMutation,
    isDragging,
  } = useUploadFileSection(uploadedInfo);

  return (
    <ErrorBoundaryWrapper>
      <div
        className="flex-center flex-col gap-y-4 rounded-lg border border-dashed border-gray-300 px-6 py-10"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        {isDragging ? (
          <p className="-z-50 py-10">Drop File</p>
        ) : (
          <div className="flex items-center gap-x-2">
            {uploadFileAndGetInfoMutation.isLoading ? (
              <Spinner size="lg" message="Uploading..." />
            ) : file?.name ? (
              <>
                <p className="py-10 text-base font-semibold leading-tight">
                  {file.name}
                </p>
                <button
                  type="button"
                  onClick={() => {
                    if (isUploaded) {
                      handleAppFileDelete(deleteData);
                    }
                    setIsUploaded(false);
                    setValue('file', null);
                    clearErrors('file');
                  }}
                >
                  <XCircleIcon className="size-5 text-red-500" />
                </button>
              </>
            ) : (
              <FileUploadInput handleAppFileUpload={handleAppFileUpload} />
            )}
          </div>
        )}
      </div>
      <ErrorMessage
        errors={errors}
        name="file"
        render={({ message }) => (
          <p className="error-message my-4">{message}</p>
        )}
      />
      <ErrorDialog
        isOpen={isUploadErrorModalOpen}
        setIsOpen={setIsUploadErrorModalOpen}
        subTitle={
          uploadFileAndGetInfoMutation.isError &&
          uploadFileAndGetInfoMutation.error.message
        }
      />
      <ErrorDialog
        isOpen={isDeleteErrorModalOpen}
        setIsOpen={setIsDeleteErrorModalOpen}
        subTitle={
          deleteTempFileMutation.error && deleteTempFileMutation.error.message
        }
      />
    </ErrorBoundaryWrapper>
  );
};

export default UploadFileSection;
