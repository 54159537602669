import {
  Button,
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';
import { DeviceInfoOsFullName } from '@customTypes/ide/device/device';
import {
  AddAdministrationDevice,
  AddAdministrationDeviceSchema,
  DeviceType,
} from '@customTypes/index';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  useAllAdminProjectListQuery,
  useCreateAdminDeviceMutation,
} from '@lib/api/dashboard/queryHooks';
import {
  CommonDialog,
  DialogButton,
  DialogButtonPanels,
} from '@components/Dialog';

type Props = { adminDeviceListPage: string };

const AdminDeviceInformationForm = ({ adminDeviceListPage }: Props) => {
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<AddAdministrationDevice>({
    defaultValues: { deviceEnabled: true },
    resolver: zodResolver(AddAdministrationDeviceSchema),
  });

  const {
    data: adminProjectList,
    isLoading,
    isError,
    error,
  } = useAllAdminProjectListQuery();

  const createAdminDeviceMutation = useCreateAdminDeviceMutation();

  const onErrorModalClose = () => {
    setIsOpenErrorModal(false);
  };

  const onSubmit: SubmitHandler<AddAdministrationDevice> = (data) => {
    createAdminDeviceMutation.mutate(data, {
      onError: () => setIsOpenErrorModal(true),
    });
  };

  const findProjectNameByIdx = (idx: number) => {
    return adminProjectList?.find((project) => project.idx === idx)?.name;
  };

  const addDeviceList: {
    title: string;
    name: keyof AddAdministrationDevice;
    component: React.JSX.Element;
  }[] = [
    {
      title:
        'Device ID* (영문 대소문자, 숫자[0-9], 대시(-), 언더바(_) 입력 가능)',
      name: 'udid',
      component: (
        <input
          className="input-base"
          placeholder="UDID(iOS) 또는 Serial Number(Android)를 입력해 주세요."
          {...register('udid')}
        />
      ),
    },
    {
      title:
        'Device Model* (1~20자, 영문 대소문자, 숫자[0-9], 대시(-), 쉼표(,), 온점(.) 입력 가능)',
      name: 'deviceModel',
      component: (
        <input
          className="input-base"
          placeholder="등록할 디바이스의 모델명을 입력해 주세요."
          {...register('deviceModel')}
        />
      ),
    },
    {
      title:
        'Device Name (1~20자, 영문 대소문자, 한글, 숫자[0-9], 대시(-), 언더바(_), 쉼표(,), 온점(.) 입력 가능)',
      name: 'deviceName',
      component: (
        <input
          className="input-base"
          placeholder="등록할 디바이스의 이름을 입력해 주세요."
          {...register('deviceName')}
        />
      ),
    },
    {
      title: 'Device OS*',
      name: 'deviceOs',
      component: (
        <Controller
          control={control}
          name="deviceOs"
          render={({ field }) => (
            <ListBoxHeadlessInController
              value={field.value || ''}
              buttonValue={field.value}
              onChange={field.onChange}
              lists={[
                { title: DeviceInfoOsFullName.Aos },
                { title: DeviceInfoOsFullName.Ios },
              ]}
              valueToSave={'title'}
              valueToShow={'title'}
              placeholder={'Select OS'}
            />
          )}
        />
      ),
    },
    {
      title: 'Device Type',
      name: 'deviceType',
      component: (
        <Controller
          control={control}
          name="deviceType"
          render={({ field }) => (
            <ListBoxHeadlessInController
              value={field.value || ''}
              buttonValue={field.value}
              onChange={field.onChange}
              lists={[
                { title: DeviceType.Mobile },
                { title: DeviceType.Tablet },
              ]}
              valueToSave={'title'}
              valueToShow={'title'}
              placeholder={'Select Type'}
            />
          )}
        />
      ),
    },
    {
      title: `Project (디바이스를 사용할 프로젝트를 지정할 수 있습니다)`,
      name: 'projectIdx',
      component: (
        <Controller
          control={control}
          name="projectIdx"
          render={({ field }) => (
            <ListBoxHeadlessInController
              value={field.value || ''}
              buttonValue={
                isError ? error.message : findProjectNameByIdx(field.value)
              }
              onChange={field.onChange}
              lists={adminProjectList}
              valueToSave={'idx'}
              valueToShow={'name'}
              placeholder={'Select Project'}
              disabled={isLoading}
            />
          )}
        />
      ),
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Device Information
        </h2>
        <p className="text-sm font-normal leading-normal text-gray-600">
          등록할 디바이스의 정보를 입력해 주세요.
        </p>
        <div className="space-y-6 border-b border-solid border-gray-200 py-6">
          {addDeviceList.map((device) => (
            <div key={device.name} className="flex flex-col gap-y-2">
              <ErrorBoundaryWrapper>
                <label className="text-sm font-medium leading-normal text-gray-900">
                  {device.title}
                </label>
                {device.component}
                <ErrorMessage
                  errors={errors}
                  name={device.name}
                  render={({ message }) => (
                    <p className="standard-error-message py-0">{message}</p>
                  )}
                />
              </ErrorBoundaryWrapper>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-between gap-3 pt-6">
          <div />
          <div className="flex items-center justify-end gap-3">
            <Button
              type="button"
              variant="secondary"
              onClick={() => navigate(adminDeviceListPage)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={createAdminDeviceMutation.isLoading}
            >
              Add
            </Button>
          </div>
        </div>
      </form>
      <CommonDialog
        isOpen={isOpenErrorModal}
        setIsOpen={setIsOpenErrorModal}
        title={'Failure'}
        subTitle={
          createAdminDeviceMutation.isError &&
          createAdminDeviceMutation.error.message
        }
        iconType={'caution'}
        onClose={onErrorModalClose}
      >
        <DialogButtonPanels>
          <DialogButton variant={'secondary'} onClick={onErrorModalClose}>
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default AdminDeviceInformationForm;
