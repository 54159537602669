import React from 'react';
import DeviceDetailFormFields from './DeviceDetailFormFields';
import { useFormContext } from 'react-hook-form';
import { UpdateProjectDeviceDetail } from '@customTypes/index';

const DeviceDetailModel = () => {
  const { register } = useFormContext<UpdateProjectDeviceDetail>();

  return (
    <DeviceDetailFormFields
      title={
        'Device Model* (1~20자, 영문 대소문자, 숫자[0-9], 대시(-), 쉼표(,), 온점(.) 입력 가능)'
      }
      name={'deviceModel'}
    >
      <input
        className="input-base disabled:bg-gray-100"
        placeholder="등록할 디바이스의 모델명을 입력해 주세요."
        {...register('deviceModel')}
        disabled
      />
    </DeviceDetailFormFields>
  );
};

export default DeviceDetailModel;
