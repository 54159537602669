import {
  ErrorBoundaryWrapper,
  ErrorMessage,
  TableSkeleton,
} from '@autosquare/common';
import { useReservedDeviceDetailQuery } from '@lib/api/ide/queryHooks';
import React from 'react';
import ReservationTimeTableHeader from './ReservationTimeTable/ReservationTimeTableHeader';
import ReservationTimeTableBody from './ReservationTimeTable/ReservationTimeTableBody';

const ReservationTimeTable = () => {
  const reservedDeviceDetailQuery = useReservedDeviceDetailQuery();
  const reservedDeviceDetail = reservedDeviceDetailQuery.data;

  if (reservedDeviceDetailQuery.isLoading) {
    return <TableSkeleton />;
  }

  if (reservedDeviceDetailQuery.isError) {
    return (
      <ErrorMessage>{reservedDeviceDetailQuery.error.message}</ErrorMessage>
    );
  }

  return (
    <ErrorBoundaryWrapper>
      <div className="mt-6">
        <div className="table min-w-full divide-y divide-gray-300" role="table">
          <ReservationTimeTableHeader />
          <div className="table-row-group divide-y divide-gray-200">
            {reservedDeviceDetail.reservationInfo.map((reservationInfo) => (
              <ReservationTimeTableBody
                reservationInfo={reservationInfo}
                key={reservationInfo.reservationIdx}
              />
            ))}
          </div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default ReservationTimeTable;
