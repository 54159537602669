import React from 'react';
import { Checkbox } from '@autosquare/common';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

type Props = {
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  listeners: SyntheticListenerMap;
  title: string;
  disabled?: boolean;
};

const LeftItemSection = ({
  isChecked,
  onChange,
  listeners,
  title,
  disabled = false,
}: Props) => {
  return (
    <div className="flex items-center">
      <Checkbox checked={isChecked} onChange={onChange} disabled={disabled} />
      <div className="ml-7" {...listeners}>
        {title}
      </div>
    </div>
  );
};

export default LeftItemSection;
