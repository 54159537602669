import { ErrorBoundaryWrapper } from '@autosquare/common';
import SecretBox from '@components/TestStep/SecretBox';
import TestStepConfirmInputRadio from '@components/TestStep/TestStepConfirmInputRadio';
import {
  CompareBy,
  CompareRange,
  CreateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import {
  AddServerName,
  FromServerName,
  SeparatorServerName,
} from '@customTypes/ide/mobileTestStep/textReplace/textReplace';
import { useGetTestCaseDataAutomation } from '@lib/api/ide/mobile/mobileTestAutomation';
import { useGetSpeedTestCaseDataAutomation } from '@lib/api/ide/speedMobile/mobileTestAutomation';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const CompareByRadio = () => {
  const { register, watch, control, resetField, setValue } =
    useFormContext<CreateTestStepData>();

  const command = watch('command');
  const compareBy = watch('optionsDto.compareBy');

  const isUiTestLocation = isWordInLocationPathname('ui-test');
  const { data: automationData } = isUiTestLocation
    ? useGetTestCaseDataAutomation()
    : useGetSpeedTestCaseDataAutomation();

  const targetData = automationData?.targetData;

  const radioDefaultOptions = [
    {
      id: 'id',
      title: '스텝 선택',
      onClick: () => {
        resetField('optionsDto.compareText');
        resetField('optionsDto.textReplaceOptions');
        setValue('optionsDto.textReplace', undefined);
        setValue('optionsDto.autoColumn', undefined);
        setValue('optionsDto.targetData', undefined);
      },
      defaultChecked: true,
    },
    {
      id: 'text',
      title: '직접 입력',
      onClick: () => {
        resetField('optionsDto.compareIdx');
        resetField('optionsDto.textReplaceOptions');
        setValue('optionsDto.textReplace', undefined);
        setValue('optionsDto.autoColumn', undefined);
        setValue('optionsDto.targetData', undefined);
        if (command === MobileCommandOptionServerName.ConfirmText) {
          setValue('optionsDto.textEncrypt', undefined);
        }
        if (command === MobileCommandOptionServerName.InputText) {
          setValue('optionsDto.textEncrypt', 'false');
          setValue('optionsDto.secret', undefined);
        }
      },
      defaultChecked: false,
    },
  ];

  const radioOptionsWithTextReplace = {
    id: 'replace',
    title: 'Text Replace',
    onClick: () => {
      resetField('optionsDto.compareIdx');
      resetField('optionsDto.compareText');
      setValue('optionsDto.textReplace', 'true');
      setValue('optionsDto.compareRange', CompareRange.Equal);
      resetField('optionsDto.repeatLimit');
      setValue('optionsDto.textReplaceOptions.from', FromServerName.Today);
      setValue('optionsDto.textReplaceOptions.addKey', AddServerName.Day);
      setValue('optionsDto.textReplaceOptions.addValue', 0);
      setValue(
        'optionsDto.textReplaceOptions.separator',
        SeparatorServerName.None,
      );
    },
    defaultChecked: false,
  };

  const radioOptionsWithAutomation = {
    id: 'automation',
    title: 'Automation',
    onClick: () => {
      resetField('optionsDto.compareIdx');
      resetField('optionsDto.compareText');
      setValue('optionsDto.textReplace', undefined);
      setValue('optionsDto.textEncrypt', undefined);
      setValue('optionsDto.compareBy', CompareBy.Automation);
      setValue('optionsDto.targetData', targetData);
    },
    defaultChecked: false,
  };

  const radioOptions =
    command === MobileCommandOptionServerName.ConfirmText && isUiTestLocation
      ? [...radioDefaultOptions, radioOptionsWithTextReplace]
      : [...radioDefaultOptions, radioOptionsWithAutomation];

  return (
    <ErrorBoundaryWrapper>
      <fieldset className="space-y-4 sm:flex sm:grid-cols-3 sm:items-center sm:justify-between sm:space-x-5 sm:space-y-0">
        <TestStepConfirmInputRadio
          register={register('optionsDto.compareBy')}
          radioOptions={radioOptions}
          name={'compareText'}
        />
      </fieldset>
      {compareBy === CompareBy.Text &&
        command === MobileCommandOptionServerName.InputText && (
          <SecretBox control={control} name="optionsDto.textEncrypt" />
        )}
    </ErrorBoundaryWrapper>
  );
};

export default CompareByRadio;
