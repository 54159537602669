import {
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
  ErrorMessage,
  TestCaseDetailSkeleton,
} from '@autosquare/common';

import { RootState } from '@app/store';

import CreateNewGroupDialog from '@components/shared/TestCaseGroup/CreateNewGroupDialog';

import { openCreateGroup } from '@store/ide/speedTestCase/CreateGroupSlice';

import {
  useCopyWebTestCaseMutation,
  useDeleteWebTestCaseMutation,
  useUpdateWebTestCaseMutation,
  useWebTestCaseDetailQuery,
} from '@lib/api/ide/web/webTestCase';

import {
  PriorityNameEnum,
  UpdateWebTestCaseData,
  WebTestCaseDetailData,
} from '@customTypes/testCase/type';

import React, { useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import WebTestCaseInfoDefault from './WebTestCaseInfo/WebTestCaseInfoDefault';
import WebTestCaseInfoForm from './WebTestCaseInfo/WebTestCaseInfoForm';

const WebTestCaseInfo = () => {
  const dispatch = useDispatch();
  const { projectIdx } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const webTestCaseDetailQuery = useWebTestCaseDetailQuery(idx);
  const webTestCaseDetailData = webTestCaseDetailQuery.data;

  const [isEdited, setIsEdited] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isCopyModalOpened, setIsCopyModalOpened] = useState(false);
  const [isCopyCompleteModalOpened, setIsCopyCompleteModalOpened] =
    useState(false);
  const [copiedTestCaseIdx, setCopiedTestCaseIdx] = useState<string | null>(
    null,
  );

  const method = useForm<UpdateWebTestCaseData>({
    defaultValues: {
      title: '',
      description: '',
      priority: PriorityNameEnum.Medium,
      caseGroupIdx: undefined,
    },
  });

  const isOpenedCreateGroup = useSelector(
    (state: RootState) => state.createGroup.isOpen,
  );

  const openModal = () => {
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const openCopyModal = () => {
    setIsCopyModalOpened(true);
  };

  const closeCopyModal = () => {
    setIsCopyModalOpened(false);
  };

  const closeEdit = () => {
    setIsEdited(false);
  };

  const updateWebTestCaseMutation = useUpdateWebTestCaseMutation(idx);

  const onSubmit: SubmitHandler<UpdateWebTestCaseData> = (data) => {
    updateWebTestCaseMutation.mutate(data, {
      onSuccess: () => {
        setIsEdited(false);
      },
    });
  };

  const deleteWebTestCaseMutation = useDeleteWebTestCaseMutation();

  const copyWebTestCaseMutaion = useCopyWebTestCaseMutation();

  const handleCopyTestCase = () => {
    if (!projectIdx) {
      console.error('Project ID is undefined');
      return;
    }

    copyWebTestCaseMutaion.mutate(
      { idx: idx, projectIdx: projectIdx },
      {
        onSuccess: (data: WebTestCaseDetailData) => {
          closeCopyModal();
          setCopiedTestCaseIdx(data.idx.toString());
          setIsCopyCompleteModalOpened(true);
        },
      },
    );
  };

  const handleGoToCopiedCase = () => {
    if (copiedTestCaseIdx) {
      navigate(`${pathname}?idx=${copiedTestCaseIdx}`);
    }
    setIsCopyCompleteModalOpened(false);
  };

  const webTestCasePage = pathname.split('/').slice(0, -1).join('/');

  return (
    <ErrorBoundaryWrapper>
      <FormProvider {...method}>
        <form onSubmit={method.handleSubmit(onSubmit)}>
          <div className="mt-8 pb-5 sm:flex sm:items-center sm:justify-between">
            <h2 className="text-base font-semibold leading-6 text-gray-900">
              Test Case Information
            </h2>
            <div className="mt-3 flex gap-3 sm:mt-0">
              {isEdited ? (
                <>
                  <Button type="button" variant="secondary" onClick={closeEdit}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="primary">
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type="button"
                    variant="primary"
                    onClick={openCopyModal}
                  >
                    Copy
                  </Button>
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsEdited(true);
                    }}
                  >
                    Edit
                  </Button>
                  <Button type="button" variant="caution" onClick={openModal}>
                    Delete
                  </Button>
                </>
              )}
            </div>
          </div>
          <ErrorMessage>
            {updateWebTestCaseMutation.isError
              ? updateWebTestCaseMutation.error.message
              : deleteWebTestCaseMutation.isError &&
                deleteWebTestCaseMutation.error.message}
          </ErrorMessage>
          {webTestCaseDetailQuery.isLoading ||
          webTestCaseDetailQuery.isFetching ? (
            <TestCaseDetailSkeleton testCategory={'ui'} platform={'web'} />
          ) : isEdited ? (
            <WebTestCaseInfoForm />
          ) : (
            <WebTestCaseInfoDefault
              webTestCaseDetailData={webTestCaseDetailData}
            />
          )}
        </form>
        <DoubleCheckDialogHeadlessUI
          isOpened={isModalOpened}
          setIsOpened={setIsModalOpened}
          type={'caution'}
          title={'Delete Test Case'}
          subTitle={'해당 테스트 케이스를 삭제하시겠습니까?'}
          buttonChildren={
            <>
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  deleteWebTestCaseMutation.mutate([Number(idx)], {
                    onSuccess: () => {
                      closeModal();
                      navigate(webTestCasePage);
                    },
                  })
                }
              >
                Delete
              </Button>
              <Button type="button" variant="secondary" onClick={closeModal}>
                Cancel
              </Button>
            </>
          }
        />
        <DoubleCheckDialogHeadlessUI
          isOpened={isCopyModalOpened}
          setIsOpened={setIsCopyModalOpened}
          type={'checked'}
          title={'Copy Test Case'}
          subTitle={'해당 테스트 케이스를 복사하시겠습니까?'}
          buttonChildren={
            <>
              <Button
                type="button"
                variant="primary"
                onClick={handleCopyTestCase}
              >
                Copy
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={closeCopyModal}
              >
                Cancel
              </Button>
            </>
          }
        />
        <DoubleCheckDialogHeadlessUI
          isOpened={isCopyCompleteModalOpened}
          setIsOpened={setIsCopyCompleteModalOpened}
          type={'checked'}
          title={'Test case copy complete'}
          subTitle={'테스트 케이스 복사가 완료되었습니다.'}
          buttonChildren={
            <>
              <Button
                type="button"
                variant="primary"
                onClick={handleGoToCopiedCase}
              >
                Go to copied case
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={() => setIsCopyCompleteModalOpened(false)}
              >
                Close
              </Button>
            </>
          }
        />
        <CreateNewGroupDialog
          isOpen={isOpenedCreateGroup}
          setIsOpen={() => dispatch(openCreateGroup())}
        />
      </FormProvider>
    </ErrorBoundaryWrapper>
  );
};

export default WebTestCaseInfo;
