import React from 'react';
import clsx from 'clsx';
import { TooltipTop } from '@autosquare/common';

type Props = {
  deactivate: number;
  activate: number;
  total: number;
  count: number;
};

const SchedulerItem = ({ deactivate, activate, total, count }: Props) => {
  const schedulerTooltipClass =
    'group-hover:visible invisible bottom-1/2 group-hover:visible -translate-x-1/2 -translate-y-1/2 left-1/2';
  return (
    <div className="flex w-full items-center">
      {deactivate > 0 && (
        <div
          className={clsx(
            'group relative h-6 bg-gray-200',
            activate === 0 ? 'rounded-lg' : 'rounded-l-lg',
          )}
          style={{
            width: `${(deactivate / total) * 100}%`,
          }}
        >
          <TooltipTop
            positionClass={schedulerTooltipClass}
          >{`Deactivate ${deactivate}`}</TooltipTop>
        </div>
      )}
      {activate > 0 && (
        <div
          className={clsx(
            'group relative h-6 bg-gray-300',
            deactivate === 0 ? 'rounded-lg' : 'rounded-r-lg',
          )}
          style={{
            width: `${(activate / total) * 100}%`,
          }}
        >
          <TooltipTop
            positionClass={schedulerTooltipClass}
          >{`Activate ${activate}`}</TooltipTop>
        </div>
      )}
      <div className="pl-3 text-xs font-normal text-black">{count}</div>
    </div>
  );
};

export default SchedulerItem;
