import { Button, ErrorBoundaryWrapper } from '@autosquare/common';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';
import {
  useEmailAlarmSettingQuery,
  useUpdateEmailAlarmMutation,
} from '@lib/api/ide/setting/settings';
import {
  UpdateSettingEmail,
  UpdateSettingEmailSchema,
} from '@customTypes/index';
import { FormProperty } from '@customTypes/setting/type';
import { getRemoteState } from '@utils/static/getConfiguration';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

type Props = {
  closeModal?: () => void;
};

const SettingsEmailForm = ({ closeModal }: Props) => {
  const accessToken = useSelector(
    (state: RootState) => state.accessTokenValue.ideAccessToken,
  );

  const isAccessToken = accessToken !== null;

  const { readOnlyMode } = getRemoteState();

  const { data: emailAlarmSetting } = useEmailAlarmSettingQuery({
    enabled: isAccessToken,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateSettingEmail>({
    resolver: zodResolver(UpdateSettingEmailSchema),
    defaultValues: {
      smtp_server: emailAlarmSetting.smtp_server,
      smtp_port: emailAlarmSetting.smtp_port,
      smtp_username: emailAlarmSetting.smtp_username,
    },
  });

  const updateEmailAlarmMutation = useUpdateEmailAlarmMutation();

  const onSubmit: SubmitHandler<UpdateSettingEmail> = (data) => {
    const defaultData = [
      { key: 'smtp_server', value: data.smtp_server },
      { key: 'smtp_port', value: data.smtp_port.toString() },
      { key: 'smtp_username', value: data.smtp_username },
    ];

    const passWordData = [
      ...defaultData,
      { key: 'smtp_password', value: data.smtp_password },
    ];

    const updateData = data.smtp_password ? passWordData : defaultData;

    updateEmailAlarmMutation.mutate(updateData, {
      onSuccess: () => {
        closeModal();
      },
    });
  };

  const defaultProperties: FormProperty[] = [
    {
      label: 'Mail(SMTP) Server (ex. smtp.gmail.com)',
      id: 'smtp_server',
      type: 'text',
      register: register('smtp_server'),
    },
    {
      label: 'SMTP Port (ex. 465)',
      id: 'smtp_port',
      type: 'number',
      register: register('smtp_port', {
        valueAsNumber: true,
      }),
    },
    {
      label: 'Username (ex. user@gmail.com)',
      id: 'smtp_username',
      type: 'email',
      register: register('smtp_username'),
    },
  ];

  const passwordProperty: FormProperty = {
    label: 'Password',
    id: 'smtp_password',
    type: 'password',
    register: register('smtp_password'),
    placeholder: emailAlarmSetting.smtp_server
      ? '저장된 비밀번호는 표시되지 않습니다.'
      : '',
  };

  const formProperties: FormProperty[] = readOnlyMode
    ? defaultProperties
    : [...defaultProperties, passwordProperty];

  return (
    <ErrorBoundaryWrapper>
      <div className="font-semibold">
        <h2 className="mt-5 text-lg">Email</h2>
        <hr />
        <p className="my-5 text-base font-semibold">SMTP 설정</p>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mt-4 flow-root"
        noValidate
      >
        {formProperties?.map((formProperty) => (
          <div key={formProperty.id}>
            <label htmlFor={formProperty.id} className="text-sm font-medium">
              {formProperty.label}
              <input
                id={formProperty.id}
                name={formProperty.id}
                type={formProperty.type}
                className="input-base hide-input-number-arrow my-4 mb-5 disabled:bg-gray-100"
                disabled={!isAccessToken || readOnlyMode}
                placeholder={formProperty.placeholder}
                {...formProperty.register}
              />
            </label>
            <ErrorMessage
              errors={errors}
              name={formProperty.id}
              render={({ message }) => (
                <p className="error-message -my-2">{message}</p>
              )}
            />
          </div>
        ))}
        <div className="mt-4 flex items-center justify-between px-2">
          <div />
          <p className="error-message">
            {!isAccessToken
              ? '로그인 후 등록 가능합니다.'
              : updateEmailAlarmMutation.isError &&
                updateEmailAlarmMutation.error.message}
          </p>
          <Button
            type={readOnlyMode ? 'button' : 'submit'}
            variant="primary"
            disabled={!isAccessToken || updateEmailAlarmMutation.isLoading}
            onClick={() => {
              if (readOnlyMode) closeModal();
            }}
          >
            Save
          </Button>
        </div>
      </form>
    </ErrorBoundaryWrapper>
  );
};

export default SettingsEmailForm;
