import React, { useEffect } from 'react';
import { UpdateAdminProjectDetailData } from '@customTypes/dashboard/admin/type';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const EditMessengerOfficeToken = () => {
  const {
    register,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<UpdateAdminProjectDetailData>();

  const officeTokenField = watch('messengers.0.officeToken');

  useEffect(() => {
    if (!officeTokenField) {
      setError('messengers.0.officeToken', {
        message: '필수 입력 항목입니다.',
      });
      return;
    }
    clearErrors('messengers.0.officeToken');
  }, [officeTokenField, setError, clearErrors]);

  return (
    <div className="mt-6 sm:space-y-0 sm:pb-0">
      <div className="flex items-center gap-x-3 py-5">
        <label className="text-sm font-medium leading-6 text-gray-900">
          Office Token*
        </label>
        <p className="text-sm font-medium leading-tight text-gray-500">
          알림 받을 메신저의 토큰을 입력해 주세요.
        </p>
      </div>
      <div className="mt-2">
        <ErrorBoundaryWrapper>
          <input
            type="text"
            name={'officeToken'}
            id={'officeToken'}
            className="input-base"
            {...register('messengers.0.officeToken', {
              required: '필수 입력 항목입니다.',
            })}
          />
          <ErrorMessage
            errors={errors}
            name="messengers.0.officeToken"
            render={({ message }) => (
              <p className="error-message py-1.5">{message}</p>
            )}
          />
        </ErrorBoundaryWrapper>
      </div>
    </div>
  );
};

export default EditMessengerOfficeToken;
