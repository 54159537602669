import React, { useEffect } from 'react';
import {
  Checkbox,
  ErrorMessage,
  ImportTestStepTableSkeleton,
} from '@autosquare/common';
import { useFormContext } from 'react-hook-form';
import { useImportTestStep } from '@hooks/testStep/useImportTestStep';
import ImportTestStepTable from '@components/TestStep/ImportTestStepTable';
import {
  MobileTestStepByCase,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { useMobileTestStepByCaseQuery } from '@lib/api/ide/mobile/mobileTestStep';
import { UseQueryResult } from '@tanstack/react-query';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { useGetSpeedMobileTestStep } from '@lib/api/ide/speedMobile/speedMobileTestStep';

type Props = {
  selectedArray: number[];
  selectAllArray: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectArray: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  query: UseQueryResult<MobileTestStepByCase, Error>;
};

const TestStepCheckBoxTable = ({
  selectedArray,
  selectAllArray,
  selectArray,
  query,
}: Props) => {
  const { setError, clearErrors, setValue, watch } =
    useFormContext<UpdateTestStepData>();

  const isUiLocation = isWordInLocationPathname('ui-test');
  const isSpeedLocation = isWordInLocationPathname('speed-test');

  const caseIdx = watch('value');
  const mobileTestStepListQuery = isUiLocation
    ? useMobileTestStepByCaseQuery(caseIdx, {
        enabled: isUiLocation && caseIdx !== undefined,
      })
    : useGetSpeedMobileTestStep(caseIdx, {
        enabled: isSpeedLocation && caseIdx !== undefined,
      });
  const mobileOs = watch('mobileOs');
  const mobileTestStepList = mobileTestStepListQuery.data?.[mobileOs];

  const { uniqueCompareIdxArray } = useImportTestStep(
    mobileTestStepList,
    selectedArray,
  );

  const testStepHeader = [
    <Checkbox
      checked={selectedArray.length > 0}
      onChange={(e) => selectAllArray(e)}
      key="selected-checkbox"
    />,
    '#',
    'Command',
    'Description',
  ];

  useEffect(() => {
    const isReferencedStepIncluded = uniqueCompareIdxArray.every((item) =>
      selectedArray.includes(item),
    );
    const noErrors = selectedArray.length > 0 && isReferencedStepIncluded;

    if (selectedArray.length === 0) {
      setError('optionsDto.stepIdxList', {
        type: 'manual',
        message: '테스트 스텝을 1개 이상 선택해 주세요.',
      });
    }
    if (uniqueCompareIdxArray.length > 0) {
      if (!isReferencedStepIncluded) {
        setError('optionsDto.stepIdxList', {
          type: 'manual',
          message: '참조되는 Save Text 스텝을 같이 선택해 주세요.',
        });
      }
    }
    if (noErrors) {
      clearErrors('optionsDto.stepIdxList');
      setValue('optionsDto.stepIdxList', selectedArray.join(','));
    }
  }, [selectedArray]);

  return (
    <>
      {query.isLoading || query.isFetching ? (
        <ImportTestStepTableSkeleton hasBorder={true} style="ml-6 mt-5" />
      ) : query.isError ? (
        <ErrorMessage>{query.error.message}</ErrorMessage>
      ) : (
        <ImportTestStepTable
          headers={testStepHeader}
          rows={mobileTestStepList}
          selectArray={selectArray}
          selectedArray={selectedArray}
          style="border-lg mt-5 w-full border border-gray-200 text-sm text-gray-900 sm:ml-6"
        />
      )}
    </>
  );
};

export default TestStepCheckBoxTable;
