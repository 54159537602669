import React from 'react';
import AddDeviceFormFields from './AddDeviceFormFields';
import { Controller, useFormContext } from 'react-hook-form';
import { ListBoxHeadlessInController } from '@autosquare/common';
import { DeviceApprovalRequest, DeviceType } from '@customTypes/index';

const AddDeviceType = () => {
  const { control } = useFormContext<DeviceApprovalRequest>();

  return (
    <AddDeviceFormFields label="Device Type" name="deviceType">
      <Controller
        control={control}
        name="deviceType"
        render={({ field }) => (
          <ListBoxHeadlessInController
            value={field.value || ''}
            buttonValue={field.value}
            onChange={field.onChange}
            lists={[{ title: DeviceType.Mobile }, { title: DeviceType.Tablet }]}
            valueToSave={'title'}
            valueToShow={'title'}
            placeholder={'Select Type'}
          />
        )}
      />
    </AddDeviceFormFields>
  );
};

export default AddDeviceType;
