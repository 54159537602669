import { ErrorBoundaryWrapper } from '@autosquare/common';
import React from 'react';
import DeviceDetailStatus from './DeviceDetailFormSection/DeviceDetailStatus';
import DeviceDetailDeviceId from './DeviceDetailFormSection/DeviceDetailDeviceId';
import DeviceDetailModel from './DeviceDetailFormSection/DeviceDetailModel';
import DeviceDetailName from './DeviceDetailFormSection/DeviceDetailName';
import DeviceDetailOs from './DeviceDetailFormSection/DeviceDetailOs';
import DeviceDetailType from './DeviceDetailFormSection/DeviceDetailType';
import InstalledApp from '../../../common/InstalledApp/InstalledApp';

const DeviceDetailFormSection = () => {
  return (
    <ErrorBoundaryWrapper>
      <div className="space-y-6 border-b border-solid border-gray-200 pb-6">
        <DeviceDetailStatus />
        <DeviceDetailDeviceId />
        <DeviceDetailModel />
        <DeviceDetailName />
        <div className="grid grid-cols-2 gap-x-6">
          <DeviceDetailOs />
          <DeviceDetailType />
        </div>
        <InstalledApp />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default DeviceDetailFormSection;
