import React from 'react';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const AutomationSettingsSkeleton = () => {
  return (
    <ErrorBoundaryWrapper>
      <div className="mt-8 space-y-2 pb-5 sm:flex-col sm:items-center sm:justify-center">
        <div className="flex items-center justify-between">
          <h2 className="text-base font-semibold leading-normal text-gray-900">
            Information
          </h2>
          <div className="h-7 w-40 animate-pulse rounded-md bg-gray-200"></div>
        </div>
        <div className="grid grid-cols-2 gap-x-4">
          {['Title*', 'Description'].map((item, i) => (
            <div key={i} className="space-y-1">
              <h3 className="text-sm font-medium leading-normal text-gray-900">
                {item}
              </h3>
              <div className="h-9 animate-pulse rounded-md bg-gray-200"></div>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-between gap-4 pt-4">
          <div className="space-y-2">
            <div className="flex items-center justify-start gap-x-3">
              <h2 className="text-base font-semibold leading-normal text-gray-900">
                Test Data
              </h2>
            </div>
            <p className="text-sm font-normal leading-tight tracking-tight text-gray-500">
              CSV 파일은 10MB 이하만 업로드 가능합니다. 파일이 표 형식이어야
              정상적으로 불러올 수 있습니다.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-6">
        <div className="h-36 w-full animate-pulse overflow-auto rounded-lg bg-gray-200" />
      </div>
      <div className="flex items-center gap-x-4 pt-6">
        <p className="text-base font-semibold leading-normal text-gray-900">
          Order By
        </p>
        <div className="h-6 w-40 animate-pulse rounded-md bg-gray-200"></div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default AutomationSettingsSkeleton;
