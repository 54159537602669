import {
  ArrowSpinner,
  ErrorBoundaryWrapper,
  ErrorMessage,
  ListSkeleton,
} from '@autosquare/common';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';
import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import SpeedTestStepListHeader from './SpeedTestStepList/SpeedTestStepListHeader';
import SpeedTestStepDragList from './SpeedTestStepList/SpeedTestStepDragList';
import {
  useGetSpeedMobileTestStep,
  useUpdateOrderSpeedMobileTestStep,
} from '@lib/api/ide/speedMobile/speedMobileTestStep';
import SpeedTestStepRepeatBox from './SpeedTestStepList/SpeedTestStepRepeatBox';
import { useMobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';
import { useGetWebSocketMessage } from '@hooks/electron/useGetWebSocketMessage';
import clsx from 'clsx';
import SpeedTestStepExecutionResultDialog from './SpeedTestStepList/SpeedTestStepExecutionResultDialog';

const SpeedTestStepList = () => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const [lists, setLists] = useState<MobileTestStepData[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasBeenRunning, setHasBeenRunning] = useState(false);
  const [isExecutionStarted, setIsExecutionStarted] = useState(false);

  const speedMobileTestStepList = useGetSpeedMobileTestStep();

  const deviceInfo = useSelector((state: RootState) => state.device.deviceInfo);

  const startStepIdx =
    deviceInfo.os === 'ios'
      ? speedMobileTestStepList.data?.iosRepeatInfo?.startIdx
      : speedMobileTestStepList.data?.aosRepeatInfo?.startIdx;
  const endStepIdx =
    deviceInfo.os === 'ios'
      ? speedMobileTestStepList.data?.iosRepeatInfo?.endIdx
      : speedMobileTestStepList.data?.aosRepeatInfo?.endIdx;
  //drag & drop
  useEffect(() => {
    setLists(
      deviceInfo.os === DeviceInfoOs.Aos
        ? speedMobileTestStepList.data?.aos
        : speedMobileTestStepList.data?.ios,
    );
  }, [speedMobileTestStepList.data]);

  const [indexArray, setIndexArray] = useState<number[]>(undefined);

  const updateTestStepListSequenceMutation = useUpdateOrderSpeedMobileTestStep({
    idx: idx,
    mobileOs: deviceInfo.os,
    step: indexArray,
    startStepIdx: startStepIdx ?? 0,
    endStepIdx: endStepIdx ?? 0,
  });

  useEffect(() => {
    if (indexArray) {
      updateTestStepListSequenceMutation.mutate();
    }
  }, [indexArray, startStepIdx, endStepIdx]);

  const handleOnDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      const items = Array.from(lists);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setLists(items);
      setIndexArray(items.map((items) => items.idx));
    },
    [lists],
  );

  // unit test
  const { testStepResult, isTestRunning, testTarget, testSessionId } =
    useGetWebSocketMessage();
  const { setIsCountThreeNeeded, isLandscapeMode } = useMobileTestStepContext();

  useEffect(() => {
    if (!isExecutionStarted) return;

    if (isTestRunning) {
      setHasBeenRunning(true);
      return;
    }

    if (hasBeenRunning) {
      if (
        testStepResult?.result === 'Pass' ||
        testStepResult?.result === 'Fail'
      ) {
        setIsCountThreeNeeded(true);
      }

      setIsModalOpen(true);
      setHasBeenRunning(false);
    }
  }, [isTestRunning, hasBeenRunning, testStepResult, isExecutionStarted]);

  return (
    <ErrorBoundaryWrapper>
      <div
        className={clsx(
          'gray-scrollbar flex size-full flex-col gap-4 border border-solid p-4',
          !isLandscapeMode && 'col-span-3',
        )}
      >
        <SpeedTestStepListHeader
          lists={lists}
          isLoading={speedMobileTestStepList.isLoading}
        />
        {speedMobileTestStepList.isLoading ? (
          <ListSkeleton />
        ) : speedMobileTestStepList.isError ? (
          <ErrorMessage>{speedMobileTestStepList.error.message}</ErrorMessage>
        ) : (
          <div className={`flex size-full flex-col gap-4`}>
            <SpeedTestStepRepeatBox />
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="lists">
                {(provided) => (
                  <ul
                    id="lists"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="flex h-max flex-col gap-1"
                  >
                    {lists?.length === 0 ? (
                      <div className="mt-16 w-full text-center text-sm font-medium leading-normal text-gray-900">
                        생성된 스텝이 없습니다. 스텝을 생성해 주세요.
                      </div>
                    ) : (
                      lists?.map((list, index) => (
                        <SpeedTestStepDragList
                          list={list}
                          index={index}
                          key={list.idx}
                          lists={lists}
                          startStepIdx={startStepIdx}
                          endStepIdx={endStepIdx}
                          setIsExecutionStarted={setIsExecutionStarted}
                        />
                      ))
                    )}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
            <SpeedTestStepExecutionResultDialog
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              setIsExecutionStarted={setIsExecutionStarted}
            />
          </div>
        )}
      </div>
      {isTestRunning &&
        testTarget === 'mobile' &&
        deviceInfo.serialNumber === testSessionId && <ArrowSpinner />}
    </ErrorBoundaryWrapper>
  );
};

export default SpeedTestStepList;
