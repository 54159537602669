import { Breadcrumbs, ErrorBoundaryWrapper } from '@autosquare/common';
import ExportOptionsDialog from '@components/ExportOptionsDialog/ExportOptionsDialog';
import { TestResultDetails } from '@customTypes/testResult/type';
import { useExportOptions } from '@hooks/index';
import { useMobileTestResultExportMutation } from '@lib/api/ide/mobile/mobileTestResult';
import { useWebTestResultExportMutation } from '@lib/api/ide/web/webTestResult';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import React from 'react';
import ExportOptionsTestResult from './ExportOptions/ExportOptionsTestResult';

type Props = {
  breadcrumbs: Breadcrumbs[];
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  resultDetails: TestResultDetails[];
};

const ExportOptions = ({
  breadcrumbs,
  isOpen,
  setIsOpen,
  resultDetails,
}: Props) => {
  const mobileTestResultExportMutation = useMobileTestResultExportMutation();
  const webTestResultExportMutation = useWebTestResultExportMutation();

  const testResultExportMutation = isWordInLocationPathname('mobile')
    ? mobileTestResultExportMutation
    : webTestResultExportMutation;

  const {
    newBreadcrumbs,
    methods,
    onSubmit,
    caseResultList,
    handleAllChange,
    closeDialog,
    inputRef,
  } = useExportOptions({
    breadcrumbs,
    resultDetails,
    setIsOpen,
    testResultExportMutation,
  });

  return (
    <ErrorBoundaryWrapper>
      <ExportOptionsDialog
        isOpen={isOpen}
        closeDialog={closeDialog}
        newBreadcrumbs={newBreadcrumbs}
        methods={methods}
        onSubmit={onSubmit}
        inputRef={inputRef}
        caseResultList={caseResultList}
        handleAllChange={handleAllChange}
        resultDetails={resultDetails}
        testResultExportMutation={testResultExportMutation}
      >
        {resultDetails?.map((resultDetail) => (
          <ExportOptionsTestResult
            resultDetail={resultDetail}
            key={resultDetail.seq}
          />
        ))}
      </ExportOptionsDialog>
    </ErrorBoundaryWrapper>
  );
};

export default ExportOptions;
