import React, { useState } from 'react';
import Application from './Settings/Application';
import Alarm from './Settings/Alarm';
import IosTest from './Settings/IosTest';
import SidebarInSettings from './Settings/Sidebar/SidebarInSettings';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type MenuTab = 'Application' | 'iOS Test' | 'Alarm';
type Props = {
  closeModal: () => void;
  menu?: MenuTab;
};

const Settings = ({ closeModal, menu }: Props) => {
  const [settingsMenu, setSettingsMenu] = useState<MenuTab>(
    menu ? menu : 'Application',
  );

  return (
    <ErrorBoundaryWrapper>
      <SidebarInSettings
        settingsMenu={settingsMenu}
        setSettingsMenu={setSettingsMenu}
      />
      {settingsMenu === 'Application' ? (
        <Application closeModal={closeModal} />
      ) : settingsMenu === 'iOS Test' ? (
        <IosTest closeModal={closeModal} />
      ) : (
        <Alarm closeModal={closeModal} />
      )}
    </ErrorBoundaryWrapper>
  );
};

export default Settings;
