import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  UpdateTestStepData,
  UpdateTestStepDataKeys,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import { preventEnterHandler } from '@utils/helper/preventEnterHandler';
interface Props {
  term: string;
  name: UpdateTestStepDataKeys;
  message?: string;
  disableAutoHeight?: boolean;
  isSavedSecretValue?: boolean;
}

const DefinitionTextAreaWithSecretOption = ({
  term,
  name,
  isSavedSecretValue,
  message = 'Value 값을 입력해 주세요.',
  disableAutoHeight = false,
}: Props) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<UpdateTestStepData>();
  const isSecret = watch('optionsDto.secret') === 'true';

  const { ref, ...rest } = register(name, {
    required: {
      value: !(isSecret && isSavedSecretValue),
      message: message,
    },
  });

  const placeholder =
    isSecret && isSavedSecretValue
      ? '암호화된 값은 표시되지 않습니다.'
      : '텍스트를 입력해 주세요.';

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (textareaRef.current && !disableAutoHeight) {
      setHeight(textareaRef.current.scrollHeight);
    }
  }, [name, disableAutoHeight]);

  useImperativeHandle(ref, () => textareaRef.current);

  return (
    <ErrorBoundaryWrapper>
      <div
        className={`items-center border-b border-gray-200 sm:flex sm:px-6 sm:py-4`}
      >
        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
          {term}
        </dt>
        <dd className="mt-1 w-full text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
          <div className="w-full">
            <label htmlFor={name} className="sr-only">
              {term}
            </label>
            <textarea
              name={name}
              id={name}
              style={{ height: disableAutoHeight ? 'auto' : height }}
              className={clsx('input-base', isSecret && 'password-input')}
              ref={textareaRef}
              placeholder={placeholder}
              {...rest}
              onKeyDown={(e) => preventEnterHandler(e, isSecret)}
            />
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <p className="standard-error-message">{message}</p>
              )}
            />
          </div>
        </dd>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default DefinitionTextAreaWithSecretOption;
