import {
  SectionTitleContainer,
  SectionTitleHeader,
  SectionTitleHeaderContainer,
  SectionTitleHeaderDescription,
  SectionTitleRight,
} from '@autosquare/common';
import React from 'react';

const SelectApplicationHeader = () => {
  return (
    <SectionTitleContainer>
      <SectionTitleHeaderContainer>
        <SectionTitleHeader>Select Application</SectionTitleHeader>
        <SectionTitleHeaderDescription>
          등록된 애플리케이션만 선택하여 설치할 수 있습니다.
        </SectionTitleHeaderDescription>
      </SectionTitleHeaderContainer>
      <SectionTitleRight type="caution">*필수 선택 사항</SectionTitleRight>
    </SectionTitleContainer>
  );
};

export default SelectApplicationHeader;
