import { Breadcrumbs } from '@autosquare/common';
import {
  TestResultDetails,
  TestResultExportCaseResultList,
} from '@customTypes/index';
import { UseMutationResult } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

type Props = {
  breadcrumbs: Breadcrumbs[];
  resultDetails: TestResultDetails[];
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  testResultExportMutation: UseMutationResult<
    {
      blob: Blob;
      filename: string;
    },
    Error,
    {
      caseResultList?: {
        caseResultIdx?: number;
        stepResultIdxList?: number[];
      }[];
    },
    unknown
  >;
};

export const useExportOptions = ({
  breadcrumbs,
  resultDetails,
  setIsOpen,
  testResultExportMutation,
}: Props) => {
  const newBreadcrumbs = [
    ...breadcrumbs.map((breadcrumb) => ({
      title: breadcrumb.title,
    })),
    { title: 'Export' },
  ];

  const methods = useForm<TestResultExportCaseResultList>({
    defaultValues: {
      caseResultList: resultDetails.map((result) => ({
        caseResultIdx: result.idx,
        stepResultIdxList: result.details.map((detail) => detail.idx),
      })),
      // imagePath가 모두 있어야 exportScreenshot true
      exportScreenshot:
        resultDetails.length > 0 &&
        resultDetails[0].details.length > 0 &&
        resultDetails[0].details.every((detail) => !!detail.imagePath),
    },
  });

  const onSubmit: SubmitHandler<TestResultExportCaseResultList> = (data) => {
    data.caseResultList = data.caseResultList.sort(
      (a, b) => a.caseResultIdx - b.caseResultIdx,
    );

    if (data.caseResultList.length === 0) {
      methods.setError('caseResultList', {
        type: 'required',
        message: '결과를 선택해 주세요.',
      });
    } else {
      testResultExportMutation.mutate(data);
    }
  };

  const caseResultList = methods.watch('caseResultList');

  // 전체 체크박스 핸들러
  const handleAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const newCaseResultList = checked
      ? resultDetails.map((result) => ({
          caseResultIdx: result.idx,
          stepResultIdxList: result.details.map((detail) => detail.idx),
        }))
      : [];
    methods.setValue('caseResultList', newCaseResultList);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      const isIndeterminate =
        caseResultList.length > 0 &&
        caseResultList.length < resultDetails.length;
      inputRef.current.indeterminate = isIndeterminate;
    }
  }, [caseResultList]);

  useEffect(() => {
    if (methods.formState.errors && caseResultList.length > 0) {
      methods.clearErrors('caseResultList');
    }
  }, [methods.formState.errors, caseResultList]);

  const closeDialog = () => {
    methods.reset();
    setIsOpen(false);
  };

  return {
    newBreadcrumbs,
    methods,
    onSubmit,
    caseResultList,
    handleAllChange,
    closeDialog,
    inputRef,
  };
};
