import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import ProjectsSidebar from './components/ProjectsSidebar';
import Administration from './components/Administration';
import clsx from 'clsx';
import RemoteSidebar from './components/RemoteSidebar';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const Sidebar = () => {
  const location = useLocation();

  const [isToggledSidebar, setIsToggledSidebar] = useState(false);

  const documentHeight = document.documentElement.scrollHeight;

  const inlineHeightStyle = () =>
    isToggledSidebar ? { height: `${documentHeight}px` } : { height: 'auto' };

  return (
    <ErrorBoundaryWrapper>
      <div
        className={clsx(
          'z-30 flex w-screen lg:w-auto',
          isToggledSidebar && `absolute backdrop-blur-sm`,
        )}
        style={inlineHeightStyle()}
      >
        <aside
          className={clsx(
            'gray-scrollbar w-full bg-white lg:fixed lg:h-[calc(100%-4rem)] lg:w-72 lg:border-r lg:border-gray-200 lg:pt-1',
            isToggledSidebar && 'w-72',
          )}
        >
          {location.pathname.includes('/admin') ? (
            <Administration
              isToggledSidebar={isToggledSidebar}
              setIsToggledSidebar={setIsToggledSidebar}
            />
          ) : location.pathname.includes('/remote') ? (
            <RemoteSidebar
              isToggledSidebar={isToggledSidebar}
              setIsToggledSidebar={setIsToggledSidebar}
            />
          ) : (
            <ProjectsSidebar
              isToggledSidebar={isToggledSidebar}
              setIsToggledSidebar={setIsToggledSidebar}
            />
          )}
        </aside>
        {isToggledSidebar && (
          <button
            type="button"
            className="w-full bg-black/20 opacity-90"
            onClick={() => setIsToggledSidebar(false)}
          />
        )}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default Sidebar;
