import React from 'react';
import MessengerRequestUrl from './MessengerFields/MessengerRequestUrl';

const Messenger02 = () => {
  return (
    <>
      <MessengerRequestUrl />
    </>
  );
};

export default Messenger02;
