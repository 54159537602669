import { ErrorBoundaryWrapper } from '@autosquare/common';
import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React from 'react';

import PropertyDetail from './PropertyDetail';

interface Props {
  list: MobileTestStepData;
}

const UnLockPatternList = ({ list }: Props) => {
  const valueStringArray = JSON.parse(list.value).map(
    (point: { x: number; y: number }) => `[${point.x}, ${point.y}]`,
  );
  const value = valueStringArray.join(', ');

  return (
    <ErrorBoundaryWrapper>
      <PropertyDetail term={'Points'} description={value} />
    </ErrorBoundaryWrapper>
  );
};

export default UnLockPatternList;
