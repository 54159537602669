import { ClipBoardButton, ErrorBoundaryWrapper } from '@autosquare/common';

import React from 'react';

import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';

import { ClickId } from '@utils/static/clickOptions';
import { MobileTestStepDataOptionsDto } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import Base64CroppedImage from '@components/TestStepCommon/Base64CroppedImage';
import { getStepPropertyValue } from '@utils/static/mobileTestStep/testStepPropertyValue';

type Props = {
  valueText: string;
  commandText: MobileCommandOptionServerName;
  setIsCopied: React.Dispatch<React.SetStateAction<boolean>>;
  optionsDto: MobileTestStepDataOptionsDto;
  saveTextIndex: number;
};

const PropertyValue = ({
  valueText,
  commandText,
  setIsCopied,
  optionsDto,
  saveTextIndex,
}: Props) => {
  const handleCopy = (valueText: string) => {
    window.navigator.clipboard.writeText(valueText);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  const showViewValue = getStepPropertyValue(
    commandText,
    valueText,
    optionsDto,
    saveTextIndex,
  );
  const parsedSecret = optionsDto.secret === 'true';

  return (
    <ErrorBoundaryWrapper>
      <div className="grid grid-cols-3 px-3 py-2 odd:bg-gray-50 even:bg-white">
        <dt className="text-xs font-medium leading-6 text-gray-900">Value</dt>
        {(commandText === MobileCommandOptionServerName.Click &&
          optionsDto.clickBy === ClickId.Image) ||
        commandText === MobileCommandOptionServerName.CompareImage ? (
          <Base64CroppedImage valueText={valueText} />
        ) : (
          <div className="col-span-2 text-xs leading-6 text-gray-700">
            <div className="whitespace-pre-wrap break-all">
              {showViewValue}
              {!parsedSecret && (
                <ClipBoardButton onClick={() => handleCopy(showViewValue)} />
              )}
            </div>
          </div>
        )}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default PropertyValue;
