import { regexLibrary } from '@autosquare/common';
import { DeviceInfoOsFullName } from '@customTypes/ide/device/device';
import { z } from 'zod';

export enum DeviceType {
  Mobile = 'Mobile',
  Tablet = 'Tablet',
}

export const DeviceAdministrationInfoSchema = z.object({
  idx: z.number(),
  requester: z.string(),
  udid: z.string().regex(regexLibrary.udid, {
    message: '영문 대소문자, 숫자[0-9], 대시(-), 언더바(_)만 입력 가능합니다.',
  }),
  deviceModel: z.string().regex(regexLibrary.deviceModelRegex, {
    message:
      '1~20자, 영문 대소문자, 숫자[0-9], 대시(-), 쉼표(,), 온점(.)만 입력 가능합니다.',
  }),
  deviceName: z.preprocess(
    (val) => (typeof val === 'string' && val === '' ? undefined : val),
    z
      .string()
      .regex(regexLibrary.deviceNameRegex, {
        message:
          '1~20자, 영문 대소문자, 한글, 숫자[0-9], 대시(-), 언더바(_), 쉼표(,), 온점(.)만 입력 가능합니다.',
      })
      .optional(),
  ),
  deviceOs: z.nativeEnum(DeviceInfoOsFullName, {
    message: '필수 선택 항목입니다.',
  }),
  deviceType: z.nativeEnum(DeviceType).optional(),
  projectName: z.string(),
  registeredAt: z.string().datetime(),
  modifiedAt: z.string().datetime(),
  deviceEnabled: z.boolean(),
});

export type DeviceAdministrationInfo = z.infer<
  typeof DeviceAdministrationInfoSchema
>;

export const AddAdministrationDeviceSchema =
  DeviceAdministrationInfoSchema.pick({
    udid: true,
    deviceModel: true,
    deviceName: true,
    deviceOs: true,
    deviceType: true,
    deviceEnabled: true,
  }).extend({
    projectIdx: z.number().optional(),
  });

export type AddAdministrationDevice = z.infer<
  typeof AddAdministrationDeviceSchema
>;

export const UpdateAdministrationDeviceSchema = AddAdministrationDeviceSchema;

export type UpdateAdministrationDevice = z.infer<
  typeof UpdateAdministrationDeviceSchema
>;

// Device ApprovalType
export enum ApprovalType {
  Add = 'Add',
  Delete = 'Delete',
}

export enum RequestStatus {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export const PendingApprovalRequestSchema = z.object({
  approvalType: z.nativeEnum(ApprovalType),
  requester: z.string(),
  description: z.string().optional(),
  dateTime: z.string().datetime(),
});

export const DeviceApprovalInfoSchema = DeviceAdministrationInfoSchema.pick({
  idx: true,
  deviceName: true,
  deviceOs: true,
  projectName: true,
}).merge(PendingApprovalRequestSchema);

export const UpdateDeviceApprovalSchema = z.object({
  idx: z.number(),
  requestStatus: z.nativeEnum(RequestStatus),
  reason: z
    .string()
    .max(100, { message: '100자 이하 입력해 주세요.' })
    .optional(),
});

export type DeviceApprovalInfo = z.infer<typeof DeviceApprovalInfoSchema>;
export type UpdateDeviceApproval = z.infer<typeof UpdateDeviceApprovalSchema>;
