import { ScenarioListDetailData } from '@autosquare/common';

import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { axiosInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';
import { mobileTestScenarioKeys } from '@queries/ide/mobile/mobileTestScenarioKeys';
import {
  CreateTestScenarioData,
  UpdateTestScenarioData,
} from 'src/types/testScenario/type';
import { schedulerKeys } from '@queries/ide/schedulerKeys';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

// GET
const mobileTestScenario = async (
  idx: string,
): Promise<ScenarioListDetailData[]> => {
  const response = await axiosInstance.get<ScenarioListDetailData[]>(
    IDEAPI.GET_ALL_MOBILE_TEST_SCENARIO + idx,
  );
  return response.data;
};
export const useMobileTestScenarioQuery = (
  idx: string,
  options?: UseQueryOptions<ScenarioListDetailData[], Error>,
) =>
  useQuery<ScenarioListDetailData[], Error>({
    queryKey: mobileTestScenarioKeys.list(idx),
    queryFn: () => mobileTestScenario(idx),
    ...options,
  });

const mobileTestScenarioDetail = async (
  idx: string,
): Promise<ScenarioListDetailData> => {
  const response = await axiosInstance.get<ScenarioListDetailData>(
    IDEAPI.GET_EACH_MOBILE_TEST_SCENARIO + idx,
  );
  return response.data;
};
export const useMobileTestScenarioDetailQuery = (idx: string) =>
  useQuery<ScenarioListDetailData, Error>({
    queryKey: mobileTestScenarioKeys.detail(idx),
    queryFn: () => mobileTestScenarioDetail(idx),
  });

const getFilteredMobileTestScenarioList = async (
  search: string,
): Promise<ScenarioListDetailData[]> => {
  const response = await axiosInstance.get<ScenarioListDetailData[]>(
    `${IDEAPI.GET_EACH_MOBILE_TEST_SCENARIO}search${search}`,
  );
  return response.data;
};

export const useFilteredMobileTestScenarioListQuery = () => {
  const { search } = useLocation();
  return useQuery<ScenarioListDetailData[], Error, ScenarioListDetailData[]>({
    queryKey: mobileTestScenarioKeys.search(search),
    queryFn: () => getFilteredMobileTestScenarioList(search),
    enabled: search !== '' && isWordInLocationPathname('mobile'),
  });
};

// POST
const createMobileTestScenario = async (
  projectIdx: string,
  data: CreateTestScenarioData,
): Promise<CreateTestScenarioData> => {
  const response = await axiosInstance.post(
    IDEAPI.CREATE_MOBILE_TEST_SCENARIO + projectIdx,
    data,
  );
  return response.data;
};
export const useCreateMobileTestScenarioMutation = () => {
  const queryClient = useQueryClient();
  const { projectIdx } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  return useMutation<CreateTestScenarioData, Error, CreateTestScenarioData>({
    mutationFn: (data) => createMobileTestScenario(projectIdx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileTestScenarioKeys.lists());
      queryClient.invalidateQueries(mobileTestScenarioKeys.searches());
      navigate(location.pathname.split('/').slice(0, -1).join('/'));
    },
  });
};

// PUT
const updateMobileTestScenario = async (
  scenarioIdx: string,
  data: UpdateTestScenarioData,
): Promise<UpdateTestScenarioData> => {
  const response = await axiosInstance.put<UpdateTestScenarioData>(
    IDEAPI.UPDATE_MOBILE_TEST_SCENARIO + scenarioIdx,
    data,
  );
  return response.data;
};
export const useUpdateMobileTestScenarioMutation = (scenarioIdx: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<UpdateTestScenarioData, Error, UpdateTestScenarioData>({
    mutationFn: (data) => updateMobileTestScenario(scenarioIdx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileTestScenarioKeys.all);
      queryClient.invalidateQueries(mobileTestScenarioKeys.searches());
      navigate(location.pathname.split('/').slice(0, -1).join('/'));
    },
  });
};

// DELETE
const deleteMobileTestScenario = async (
  idxList: number[],
): Promise<unknown> => {
  const response = await axiosInstance.delete(
    IDEAPI.DELETE_MOBILE_TEST_SCENARIO,
    { data: { idx_list: idxList } },
  );
  return response.data;
};
export const useDeleteMobileTestScenarioMutation = (idxList: number[]) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error>({
    mutationFn: () => deleteMobileTestScenario(idxList),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileTestScenarioKeys.lists());
      queryClient.invalidateQueries(mobileTestScenarioKeys.searches());
      queryClient.invalidateQueries(schedulerKeys.lists());
    },
  });
};
