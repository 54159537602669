import {
  ProjectListDetail,
  ProjectListDetailFeaturesCategory,
  ProjectListDetailFeaturesFeature,
} from '@customTypes/dashboard/project/type';
import { checkIsProjectFeatureIncluded } from '@utils/static/checkProjectFeature';
import { useParams } from 'react-router-dom';

export const useRemoteSidebar = (projectList: ProjectListDetail) => {
  const { projectIdx } = useParams();

  const basePathname = `/projects/${projectIdx}/remote/`;
  const mobilePathname = `${basePathname}ui-test/mobile/`;
  const webPathname = `${basePathname}ui-test/web/`;
  const mobileSpeedPathname = `${basePathname}speed-test/mobile/`;
  const webSpeedPathname = `${basePathname}speed-test/web/`;
  const testScenario = 'Test Scenario';
  const testCase = 'Test Case';
  const testResult = 'Test Result';

  const isMobileFeatureIncluded = checkIsProjectFeatureIncluded(
    projectList,
    ProjectListDetailFeaturesCategory.Mobile,
    ProjectListDetailFeaturesFeature.Functional,
  );

  const isWebFeatureIncluded = checkIsProjectFeatureIncluded(
    projectList,
    ProjectListDetailFeaturesCategory.Web,
    ProjectListDetailFeaturesFeature.Functional,
  );

  const isMobileSpeedIncluded = checkIsProjectFeatureIncluded(
    projectList,
    ProjectListDetailFeaturesCategory.Mobile,
    ProjectListDetailFeaturesFeature.Speed,
  );

  const isWebSpeedIncluded = checkIsProjectFeatureIncluded(
    projectList,
    ProjectListDetailFeaturesCategory.Web,
    ProjectListDetailFeaturesFeature.Speed,
  );

  const overview = {
    name: 'Overview',
    href: `${basePathname}overview`,
  };

  const sidebarUITestMobileChildren = [
    {
      name: testScenario,
      href: `${mobilePathname}test-scenario`,
    },
    {
      name: testCase,
      href: `${mobilePathname}test-case`,
    },
    {
      name: testResult,
      href: `${mobilePathname}test-result`,
    },
  ];

  const sidebarSpeedTestMobileChildren = [
    {
      name: testScenario,
      href: `${mobileSpeedPathname}test-scenario`,
    },
    {
      name: testCase,
      href: `${mobileSpeedPathname}test-case`,
    },
    {
      name: testResult,
      href: `${mobileSpeedPathname}test-result`,
    },
  ];

  const sidebarUITestWebChildren = [
    {
      name: testScenario,
      href: `${webPathname}test-scenario`,
    },
    {
      name: testCase,
      href: `${webPathname}test-case`,
    },
    {
      name: testResult,
      href: `${webPathname}test-result`,
    },
  ];

  const sidebarSpeedTestWebChildren = [
    {
      name: testScenario,
      href: `${webSpeedPathname}test-scenario`,
    },
    {
      name: testCase,
      href: `${webSpeedPathname}test-case`,
    },
    {
      name: testResult,
      href: `${webSpeedPathname}test-result`,
    },
  ];

  const uiTestSidebar = {
    name: 'UI Test',
    children: [
      isMobileFeatureIncluded &&
        isMobileFeatureIncluded && {
          id: 'ui-scheduler',
          name: 'Scheduler',
          href: `${basePathname}ui-test/scheduler`,
        },
      isMobileFeatureIncluded && {
        id: 'ui-mobile-test',
        name: 'Mobile',
        location: ProjectListDetailFeaturesCategory.Mobile,
        children: sidebarUITestMobileChildren,
      },
      isWebFeatureIncluded && {
        id: 'ui-web-test',
        name: 'Web',
        location: ProjectListDetailFeaturesCategory.Web,
        children: sidebarUITestWebChildren,
      },
    ].filter(Boolean), // false인 항목을 제거하여 실제로 필요한 것만 남김
  };

  const speedTestSidebar = {
    name: 'Speed Test',
    children: [
      isMobileSpeedIncluded &&
        isWebSpeedIncluded && {
          id: 'speed-scheduler',
          name: 'Scheduler',
          href: `${basePathname}speed-test/scheduler`,
        },
      isMobileSpeedIncluded && {
        id: 'speed-mobile-test',
        name: 'Mobile',
        location: ProjectListDetailFeaturesCategory.Mobile,
        children: sidebarSpeedTestMobileChildren,
      },
      isWebSpeedIncluded && {
        id: 'speed-web-test',
        name: 'Web',
        location: ProjectListDetailFeaturesCategory.Web,
        children: sidebarSpeedTestWebChildren,
      },
    ].filter(Boolean),
  };

  const member = {
    name: 'Members',
    href: `/projects/${projectIdx}/remote/members/list`,
  };

  const sidebarList: {
    name: string;
    href?: string;
    children?: {
      id: string;
      name: string;
      href?: string;
      location?: string;
      children?: { name: string; href: string }[];
    }[];
  }[] = [
    overview,
    isMobileFeatureIncluded || isWebFeatureIncluded ? uiTestSidebar : undefined,
    isMobileSpeedIncluded || isWebSpeedIncluded ? speedTestSidebar : undefined,
    // member,
  ].filter(Boolean);

  return { sidebarList };
};
