import React, { memo, useEffect } from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';
import Konva from 'konva';

import { useMobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';
import { ErrorBoundaryWrapper } from '@autosquare/common';

interface Props {
  screenshot: string;
  stageWidth: number;
  stageHeight: number;
  imageRef: React.MutableRefObject<Konva.Image>;
}

const MobileImage = memo(function MobileImage({
  screenshot,
  stageWidth,
  stageHeight,
  imageRef,
}: Props) {
  const { setImageWidth, setImageHeight } = useMobileTestStepContext();

  const [image] = useImage(`data:image/png;base64,${screenshot}`);

  useEffect(() => {
    if (image) {
      setImageWidth(image.width);
      setImageHeight(image.height);
    }
  }, [image, setImageWidth, setImageHeight]);

  return (
    <ErrorBoundaryWrapper>
      <Image
        image={image}
        width={stageWidth}
        height={stageHeight}
        ref={imageRef}
      />
    </ErrorBoundaryWrapper>
  );
});

export default MobileImage;
