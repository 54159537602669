import {
  ArrowSpinner,
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
  ErrorMessage,
  ListSkeleton,
} from '@autosquare/common';

import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';

import TestStepListHeader from './TestStepList/TestStepListHeader';
import TestStepDragList from './TestStepList/TestStepDragList';

import { useMobileTestStepByCaseQuery } from '@lib/api/ide/mobile/mobileTestStep';
import { useUpdateTestCaseListSequenceMutation } from '@lib/api/ide/mobile/mobileTestCase';

import { RootState } from '@app/store';

import { useGetWebSocketMessage } from '@hooks/electron/useGetWebSocketMessage';

import { MobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import clsx from 'clsx';

const TestStepList = () => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const [lists, setLists] = useState<MobileTestStepData[]>([]);
  const [isModalOpened, setIsModalOpened] = useState(true);
  const [hasBeenRunning, setHasBeenRunning] = useState(false);

  const mobileTestStepByCaseQuery = useMobileTestStepByCaseQuery(idx);

  const deviceInfo = useSelector((state: RootState) => state.device.deviceInfo);

  //drag & drop
  useEffect(() => {
    setLists(
      deviceInfo.os === DeviceInfoOs.Aos
        ? mobileTestStepByCaseQuery.data?.aos
        : mobileTestStepByCaseQuery.data?.ios,
    );
  }, [mobileTestStepByCaseQuery.data]);

  const [indexArray, setIndexArray] = useState<number[]>(null);

  const updateTestCaseListSequenceMutation =
    useUpdateTestCaseListSequenceMutation({
      idx: idx,
      mobileOs: deviceInfo.os,
      indexArray: indexArray,
    });

  useEffect(() => {
    updateTestCaseListSequenceMutation.mutate();
  }, [indexArray]);

  const handleOnDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      const items = Array.from(lists);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setLists(items);
      setIndexArray(items.map((items) => items.idx));
    },
    [lists],
  );

  // unit test
  const refreshToken = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );
  const { testStepResult, isTestRunning, testTarget, testSessionId } =
    useGetWebSocketMessage();
  const { setIsCountThreeNeeded, isLandscapeMode } = useContext(
    MobileTestStepContext,
  );

  useEffect(() => {
    if (isTestRunning) {
      setHasBeenRunning(true);
    } else if (
      (hasBeenRunning && testStepResult?.result === 'Pass') ||
      (hasBeenRunning && testStepResult?.result === 'Fail')
    ) {
      setIsModalOpened(true);
      setHasBeenRunning(false);
      setIsCountThreeNeeded(true);
    } else if (hasBeenRunning) {
      setIsModalOpened(true);
      setHasBeenRunning(false);
    }
  }, [isTestRunning, hasBeenRunning, testStepResult]);

  return (
    <ErrorBoundaryWrapper>
      <div
        className={clsx(
          'gray-scrollbar flex size-full flex-col gap-4 border border-solid p-4',
          !isLandscapeMode && 'col-span-3',
        )}
      >
        <TestStepListHeader
          lists={lists}
          isLoading={mobileTestStepByCaseQuery.isLoading}
        />
        {mobileTestStepByCaseQuery.isLoading ? (
          <ListSkeleton />
        ) : mobileTestStepByCaseQuery.isError ? (
          <ErrorMessage>{mobileTestStepByCaseQuery.error.message}</ErrorMessage>
        ) : (
          <div className={`flex size-full flex-col gap-4`}>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="lists">
                {(provided) => (
                  <ul
                    id="lists"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="flex h-max flex-col gap-1"
                  >
                    {lists?.length === 0 ? (
                      <div className="mt-16 w-full text-center text-sm font-medium leading-normal text-gray-900">
                        생성된 스텝이 없습니다. 스텝을 생성해 주세요.
                      </div>
                    ) : (
                      lists?.map((list, index) => (
                        <TestStepDragList
                          list={list}
                          index={index}
                          key={list.idx}
                          lists={lists}
                          deviceInfo={deviceInfo}
                          refreshToken={refreshToken}
                        />
                      ))
                    )}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
            {testStepResult?.result === 'Pass' ? (
              <DoubleCheckDialogHeadlessUI
                isOpened={isModalOpened}
                setIsOpened={setIsModalOpened}
                type={'checked'}
                title={'Execute Success'}
                subTitle={'테스트 결과: Pass'}
                buttonChildren={
                  <Button
                    type="button"
                    variant="primary"
                    onClick={() => setIsModalOpened(false)}
                  >
                    Close
                  </Button>
                }
              />
            ) : testStepResult?.result === 'Fail' ? (
              <DoubleCheckDialogHeadlessUI
                isOpened={isModalOpened}
                setIsOpened={setIsModalOpened}
                type={'caution'}
                title={'Execute Failure'}
                subTitle={`테스트 결과: Fail\n실패 사유: ${testStepResult?.fail_reason}`}
                buttonChildren={
                  <Button
                    type="button"
                    variant="primary"
                    onClick={() => setIsModalOpened(false)}
                  >
                    Close
                  </Button>
                }
              />
            ) : (
              testStepResult?.result === 'N/A' && (
                <DoubleCheckDialogHeadlessUI
                  isOpened={isModalOpened}
                  setIsOpened={setIsModalOpened}
                  type={'yellow caution'}
                  title={'Execute Error'}
                  subTitle={`테스트 결과: N/A\n실패 사유: ${testStepResult?.fail_reason}`}
                  buttonChildren={
                    <Button
                      type="button"
                      variant="primary"
                      onClick={() => setIsModalOpened(false)}
                    >
                      Close
                    </Button>
                  }
                />
              )
            )}
          </div>
        )}
        {isTestRunning &&
          testTarget === 'mobile' &&
          deviceInfo.serialNumber === testSessionId && <ArrowSpinner />}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default TestStepList;
