import { IDEAPI } from '@api/ideEndpoints';
import {
  CreateSpeedSchedulerData,
  DeleteSpeedSchedulerData,
  SpeedSchedulerDetailData,
  UpdateSpeedSchedulerData,
} from '@customTypes/ide/speedScheduler/speedScheduler';
import { speedSchedulerKeys } from '@queries/ide/speedSchedulerKeys';
import { axiosInstance, axiosLocalInstance } from '..';
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
  useMutation,
} from '@tanstack/react-query';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { remoteConfig } from '@utils/static/remoteConfig';

const speedSchedulerBaseUrl = IDEAPI.SPEED_TEST_SCHEDULER;
const speedSchedulerLocalBaseUrl = IDEAPI.SPEED_TEST_LOCAL_SCHEDULER;
const remoteSpeedSchedulerBaseUrl = `${IDEAPI.REMOTE}/connect/scheduler/speed`;

// GET
const getSpeedSchedulerList = async (
  projectIdx: string,
): Promise<SpeedSchedulerDetailData[]> => {
  const response = await axiosInstance.get<SpeedSchedulerDetailData[]>(
    `${speedSchedulerBaseUrl}?project=${projectIdx}`,
  );
  return response.data;
};
export const useSpeedSchedulerListQuery = (
  options?: UseQueryOptions<SpeedSchedulerDetailData[], Error>,
) => {
  const { projectIdx } = useParams();

  return useQuery<SpeedSchedulerDetailData[], Error>({
    queryKey: speedSchedulerKeys.list(projectIdx),
    queryFn: () => getSpeedSchedulerList(projectIdx),
    ...options,
  });
};

const getSpeedSchedulerDetail = async (
  idx: string,
): Promise<SpeedSchedulerDetailData> => {
  const response = await axiosInstance.get<SpeedSchedulerDetailData>(
    `${speedSchedulerBaseUrl}/${idx}`,
  );
  return response.data;
};
export const useSpeedSchedulerDetailQuery = (
  options?: UseQueryOptions<SpeedSchedulerDetailData, Error>,
) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  return useQuery<SpeedSchedulerDetailData, Error>({
    queryKey: speedSchedulerKeys.detail(idx),
    queryFn: () => getSpeedSchedulerDetail(idx),
    ...options,
  });
};

const filteredSpeedTestSchedulerList = async (
  search: string,
): Promise<SpeedSchedulerDetailData[]> => {
  const response = await axiosInstance.get<SpeedSchedulerDetailData[]>(
    `${speedSchedulerBaseUrl}/search${search}`,
  );
  return response.data;
};
export const useSearchSpeedTestSchedulerListQuery = () => {
  const { search } = useLocation();

  return useQuery<SpeedSchedulerDetailData[], Error>({
    queryKey: speedSchedulerKeys.search(search),
    queryFn: () => filteredSpeedTestSchedulerList(search),
    enabled: search !== '',
  });
};

// POST
const createSpeedScheduler = async (
  cookie: string,
  data: CreateSpeedSchedulerData,
): Promise<SpeedSchedulerDetailData> => {
  const response = await axiosLocalInstance.post<SpeedSchedulerDetailData>(
    speedSchedulerLocalBaseUrl,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useCreateSpeedSchedulerMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  return useMutation<SpeedSchedulerDetailData, Error, CreateSpeedSchedulerData>(
    {
      mutationFn: (data) => createSpeedScheduler(cookie, data),
      onSuccess: () => {
        queryClient.invalidateQueries(speedSchedulerKeys.all);
        navigate(pathname.split('/').slice(0, -1).join('/'));
      },
    },
  );
};

//리모트 속도 스케줄러 생성
//https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Schedule/saveSpeedScheduler
const remoteCreateSpeedScheduler = async (
  data: CreateSpeedSchedulerData,
): Promise<SpeedSchedulerDetailData> => {
  const response = await axiosLocalInstance.post<SpeedSchedulerDetailData>(
    remoteSpeedSchedulerBaseUrl,
    data,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteCreateSpeedSchedulerMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return useMutation<SpeedSchedulerDetailData, Error, CreateSpeedSchedulerData>(
    {
      mutationFn: (data) => remoteCreateSpeedScheduler(data),
      onSuccess: () => {
        queryClient.invalidateQueries(speedSchedulerKeys.all);
        navigate(pathname.split('/').slice(0, -1).join('/'));
      },
    },
  );
};

// PUT
const updateSpeedScheduler = async (
  cookie: string,
  data: UpdateSpeedSchedulerData,
): Promise<SpeedSchedulerDetailData> => {
  const response = await axiosLocalInstance.put<SpeedSchedulerDetailData>(
    speedSchedulerLocalBaseUrl,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useUpdateSpeedSchedulerMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  return useMutation<SpeedSchedulerDetailData, Error, UpdateSpeedSchedulerData>(
    {
      mutationFn: (data) => updateSpeedScheduler(cookie, data),
      onSuccess: () => {
        queryClient.invalidateQueries(speedSchedulerKeys.all);
        navigate(pathname.split('/').slice(0, -1).join('/'));
      },
    },
  );
};

//리모트 속도 스케줄러 수정
//https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Schedule/updateSpeedScheduler
const remoteUpdateSpeedScheduler = async (
  data: UpdateSpeedSchedulerData,
): Promise<SpeedSchedulerDetailData> => {
  const response = await axiosLocalInstance.put<SpeedSchedulerDetailData>(
    remoteSpeedSchedulerBaseUrl,
    data,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteUpdateSpeedSchedulerMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return useMutation<SpeedSchedulerDetailData, Error, UpdateSpeedSchedulerData>(
    {
      mutationFn: (data) => remoteUpdateSpeedScheduler(data),
      onSuccess: () => {
        queryClient.invalidateQueries(speedSchedulerKeys.all);
        navigate(pathname.split('/').slice(0, -1).join('/'));
      },
    },
  );
};

const updateSpeedSchedulerStatus = async (
  cookie: string,
  idx: number,
  isRunning: boolean,
): Promise<SpeedSchedulerDetailData> => {
  const response = await axiosLocalInstance.put<SpeedSchedulerDetailData>(
    `${speedSchedulerLocalBaseUrl}/${idx}/${isRunning}`,
    undefined,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useUpdateSpeedSchedulerStatusMutation = () => {
  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );
  const isRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );
  const queryClient = useQueryClient();

  const isDetailPage = isWordInLocationPathname('/detail');

  return useMutation<
    SpeedSchedulerDetailData,
    Error,
    { idx: number; isRunning: boolean }
  >({
    mutationFn: (data) =>
      updateSpeedSchedulerStatus(cookie, data.idx, data.isRunning),
    onSuccess: () => {
      queryClient.invalidateQueries(speedSchedulerKeys.lists());
      queryClient.invalidateQueries(speedSchedulerKeys.searches());
      if (!isRunning || !isDetailPage) {
        queryClient.invalidateQueries(speedSchedulerKeys.details());
      }
    },
  });
};

//리모트 속도 스케줄러 상태 변경
//https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Schedule/updateSpeedSchedulerStatus
const remoteUpdateSpeedSchedulerStatus = async (
  idx: number,
  isRunning: boolean,
): Promise<SpeedSchedulerDetailData> => {
  const response = await axiosLocalInstance.put<SpeedSchedulerDetailData>(
    `${remoteSpeedSchedulerBaseUrl}/${idx}/${isRunning}`,
    undefined,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteUpdateSpeedSchedulerStatusMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    SpeedSchedulerDetailData,
    Error,
    { idx: number; isRunning: boolean }
  >({
    mutationFn: (data) =>
      remoteUpdateSpeedSchedulerStatus(data.idx, data.isRunning),
    onSuccess: () => {
      queryClient.invalidateQueries(speedSchedulerKeys.lists());
      queryClient.invalidateQueries(speedSchedulerKeys.searches());
      queryClient.invalidateQueries(speedSchedulerKeys.details());
    },
  });
};

const deleteSpeedScheduler = async (
  data: DeleteSpeedSchedulerData,
  cookie: string,
): Promise<unknown> => {
  const response = await axiosLocalInstance.put<unknown>(
    `${speedSchedulerLocalBaseUrl}/delete`,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useDeleteSpeedSchedulerMutation = () => {
  const queryClient = useQueryClient();

  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  return useMutation<unknown, Error, DeleteSpeedSchedulerData>({
    mutationFn: (data) => deleteSpeedScheduler(data, cookie),
    onSuccess: () => {
      queryClient.invalidateQueries(speedSchedulerKeys.all);
    },
  });
};

// 리모트 속도 스케줄러 삭제
//https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Schedule/deleteSpeedScheduler
const remoteDeleteSpeedScheduler = async (
  data: DeleteSpeedSchedulerData,
): Promise<unknown> => {
  const response = await axiosLocalInstance.put<unknown>(
    `${remoteSpeedSchedulerBaseUrl}/delete`,
    data,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteDeleteSpeedSchedulerMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, DeleteSpeedSchedulerData>({
    mutationFn: (data) => remoteDeleteSpeedScheduler(data),
    onSuccess: () => {
      queryClient.invalidateQueries(speedSchedulerKeys.all);
    },
  });
};
