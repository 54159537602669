import { API } from '@api/endpoints';
import { WebTestStepData } from '@customTypes/testStep/type';
import { webTestStepKeys } from '@queries/ide/web/webTestStepKeys';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { axiosInstance } from '..';

// GET
// Web Test Step List 전체 조회
const getTestStepList = async (idx: string): Promise<WebTestStepData[]> => {
  const response = await axiosInstance.get<WebTestStepData[]>(
    `${API.WEB_TEST_STEP}s?case=${idx}`,
  );
  return response.data;
};
export const useRemoteWebTestStepListQuery = (
  idx?: number,
  options?: UseQueryOptions<WebTestStepData[], Error>,
) => {
  const [searchParams] = useSearchParams();
  const webTestCaseIdx = idx ? idx.toString() : searchParams.get('idx');

  return useQuery<WebTestStepData[], Error>({
    queryKey: webTestStepKeys.case(webTestCaseIdx),
    queryFn: () => getTestStepList(webTestCaseIdx),
    ...options,
  });
};
