import React from 'react';
import MessengerRequestUrl from './MessengerFields/MessengerRequestUrl';
import MessengerOfficeToken from './MessengerFields/MessengerOfficeToken';
import MessengerRecipientList from './MessengerFields/MessengerRecipientList';

const Messenger01 = () => {
  return (
    <>
      <MessengerRequestUrl />
      <MessengerOfficeToken />
      <MessengerRecipientList />
    </>
  );
};

export default Messenger01;
