import { ErrorBoundaryWrapper } from '@autosquare/common';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import SwitchInController from '@components/shared/HeadlessUI/Switch/SwitchInController';
import {
  SchedulerTypeServerName,
  UpdateSchedulerData,
} from '@customTypes/index';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  isRunning: boolean;
  type: SchedulerTypeServerName;
};

const SchedulerDetailDeviceReboot = ({ isRunning, type }: Props) => {
  if (type === SchedulerTypeServerName.Web) return null;

  const { control } = useFormContext<UpdateSchedulerData>();

  return (
    <ErrorBoundaryWrapper>
      <div className="py-3">
        <SchedulerGridContainer
          title={'Device Reboot'}
          tooltipWord="스케줄러 실행 종료 시, 연결된 디바이스를 자동으로 재부팅할 수 있습니다."
          tooltipClass="-right-56 bottom-6"
        >
          <Controller
            control={control}
            name="deviceReboot"
            render={({ field }) => (
              <SwitchInController
                checked={field.value || false}
                onChange={field.onChange}
                disabled={!isRunning}
              />
            )}
          />
        </SchedulerGridContainer>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default SchedulerDetailDeviceReboot;
