import React, { useState } from 'react';

import {
  useDeleteSpeedMobileTestScenarioMutation,
  useSearchMobileSpeedTestScenarioListQuery,
} from '@lib/api/ide/speedMobile/speedMobileTestScenario';
import {
  ArrowSpinner,
  Checkbox,
  Detail,
  ErrorBoundaryWrapper,
  Execution,
  TableBodyBasic,
  TableHeader,
  TableWrapper,
  Time,
  useCheckbox,
} from '@autosquare/common';
import { useNavigate } from 'react-router';
import { timePattern } from '@utils/static/timePattern';
import TestExecutionContainer from '@components/TestExecution/TestExecutionContainer';
import TableSkeleton from '@components/Skeleton/TableSkeleton';
import ErrorMessageInTableBody from '@components/SpeedTestScenario/ErrorMessageInTableBody';
import DeleteDialogInSpeedTestScenarioList from '@components/SpeedTestScenario/DeleteDialogInSpeedTestScenarioList';
import dayjs from 'dayjs';
import NoListResultMessage from '@components/Search/NoListResultMessage';
import { useRemoteMobileTestExecutionMutation } from '@lib/api/ide/remote/remote';
import RemoteTestExecutionAlarmModal from '@components/TestExecution/shared/RemoteTestExecutionAlarmModal';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';

const MobileSpeedTestScenarioTable = () => {
  const navigate = useNavigate();

  const [isTestExecutionOpened, setIsTestExecutionOpened] = useState(false);
  const [testScenarioIdx, setTestScenarioIdx] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExecutionErrorModalOpen, setIsExecutionErrorModalOpen] =
    useState(false);

  const searchMobileSpeedTestScenarioListQuery =
    useSearchMobileSpeedTestScenarioListQuery();
  const searchMobileSpeedTestScenarioList =
    searchMobileSpeedTestScenarioListQuery.data;

  const deleteSpeedMobileTestScenarioMutation =
    useDeleteSpeedMobileTestScenarioMutation();

  const remoteMobileScenarioExecutionMutation =
    useRemoteMobileTestExecutionMutation('scenario');

  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(searchMobileSpeedTestScenarioList, 'idx');

  const headerList = [
    {
      idx: 1,
      title: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={(e) => selectAllArray(e)}
          key="selected-checkbox"
        />
      ),
    },
    {
      idx: 2,
      title: selectedArray.length > 0 ? <div className="size-5" /> : 'No',
    },
    {
      idx: 3,
      title: selectedArray.length > 0 ? <div className="size-5" /> : 'Title',
    },
    { idx: 4, title: 'Test Case Count' },
    { idx: 5, title: 'Creator' },
    { idx: 6, title: 'Created at', isSortable: true },
    { idx: 7, title: '' },
    { idx: 8, title: '' },
  ];

  return (
    <ErrorBoundaryWrapper>
      <TableWrapper selectedArray={selectedArray} openDialog={openDialog}>
        <thead>
          <tr>
            {headerList.map((header) => (
              <TableHeader key={header.idx} isSortable={header.isSortable}>
                {header.title}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {searchMobileSpeedTestScenarioListQuery.isLoading ||
          searchMobileSpeedTestScenarioListQuery.isFetching ? (
            <TableSkeleton dataLength={headerList.length} />
          ) : searchMobileSpeedTestScenarioListQuery.isError ? (
            <ErrorMessageInTableBody colSpan={headerList.length}>
              {searchMobileSpeedTestScenarioListQuery.error.message}
            </ErrorMessageInTableBody>
          ) : searchMobileSpeedTestScenarioList?.length === 0 ? (
            <NoListResultMessage
              colSpan={headerList.length}
              testType="scenario"
            />
          ) : (
            searchMobileSpeedTestScenarioList?.map(
              (speedMobileTestScenario, idx) => (
                <tr key={speedMobileTestScenario.idx}>
                  <TableBodyBasic>
                    <Checkbox
                      checked={selectedArray.includes(
                        speedMobileTestScenario.idx,
                      )}
                      onChange={(e) =>
                        selectArray(speedMobileTestScenario.idx, e)
                      }
                    />
                  </TableBodyBasic>
                  <TableBodyBasic>{idx + 1}</TableBodyBasic>
                  <TableBodyBasic>
                    <div className="font-medium text-gray-900">
                      {speedMobileTestScenario.title}
                    </div>
                    <div className="mt-1 text-gray-500">
                      {speedMobileTestScenario.description}
                    </div>
                  </TableBodyBasic>
                  <TableBodyBasic>
                    {speedMobileTestScenario.caseCount}
                  </TableBodyBasic>
                  <TableBodyBasic>
                    {speedMobileTestScenario.creator}
                  </TableBodyBasic>
                  <TableBodyBasic>
                    <div>
                      {dayjs(speedMobileTestScenario.createdAt).format(
                        'YYYY-MM-DD',
                      ) ?? '-'}
                    </div>
                    <div
                      className={`mt-1 flex items-center justify-start gap-2 text-gray-500`}
                    >
                      {speedMobileTestScenario.createdAt && <Time />}
                      {speedMobileTestScenario.createdAt.match(timePattern)[0]}
                    </div>
                  </TableBodyBasic>
                  <TableBodyBasic>
                    <Execution
                      testType="scenario"
                      tooltipLocation="right"
                      disabled={speedMobileTestScenario.caseCount === 0}
                      onClick={() => {
                        if (speedMobileTestScenario.caseCount > 0) {
                          setTestScenarioIdx(speedMobileTestScenario.idx);
                          setIsTestExecutionOpened(true);
                        }
                      }}
                    />
                  </TableBodyBasic>
                  <TableBodyBasic>
                    <Detail
                      onClick={() =>
                        navigate({
                          pathname: 'detail',
                          search: `idx=${speedMobileTestScenario.idx}`,
                        })
                      }
                    />
                  </TableBodyBasic>
                </tr>
              ),
            )
          )}
        </tbody>
      </TableWrapper>
      <DeleteDialogInSpeedTestScenarioList
        isOpenedCautionDialog={isOpenedCautionDialog}
        setIsOpenedCautionDialog={setIsOpenedCautionDialog}
        deleteSpeedMobileTestScenarioMutation={
          deleteSpeedMobileTestScenarioMutation
        }
        selectedArray={selectedArray}
        openDialog={openDialog}
        closeDialog={closeDialog}
        resetSelectedArray={resetSelectedArray}
      />
      {isTestExecutionOpened && (
        <TestExecutionContainer
          setState={setIsTestExecutionOpened}
          idx={testScenarioIdx}
          testType={'scenario'}
          deviceType={'mobile'}
          testLocation={'speed-test'}
          setIsModalOpen={setIsModalOpen}
          remoteMobileExecutionMutation={remoteMobileScenarioExecutionMutation}
          setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
        />
      )}
      {remoteMobileScenarioExecutionMutation.isLoading && <ArrowSpinner />}
      <RemoteTestExecutionAlarmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isExecutionErrorModalOpen={isExecutionErrorModalOpen}
        setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
      />
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default MobileSpeedTestScenarioTable;
