import React from 'react';
import { useParams } from 'react-router-dom';

import { useMobileTestScenarioQuery } from '@lib/api/ide/mobile/mobileTestScenario';
import { useWebTestScenarioQuery } from '@lib/api/ide/web/webTestScenario';

import DraggableElement from './CreateSchedulerScenario/DraggableElement';

import {
  CreateSchedulerData,
  SchedulerTypeServerName,
} from '@customTypes/ide/scheduler/scheduler';
import { useFormContext } from 'react-hook-form';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const CreateSchedulerScenario = () => {
  const { projectIdx } = useParams();
  const { watch } = useFormContext<CreateSchedulerData>();
  const testType = watch('type');
  const testScenarioQuery =
    testType === SchedulerTypeServerName.Mobile
      ? useMobileTestScenarioQuery(projectIdx, { staleTime: 20 })
      : useWebTestScenarioQuery({ idx: projectIdx, staleTime: 20 });

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer title={'Scenario'}>
        <div className="mt-2 text-sm sm:col-span-2 sm:mt-0">
          <DraggableElement testScenarioQuery={testScenarioQuery} />
        </div>
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default CreateSchedulerScenario;
