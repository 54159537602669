import { ErrorBoundaryWrapper, Fieldset } from '@autosquare/common';
import {
  ProjectListDetail,
  ProjectListDetailFeaturesCategory,
  ProjectListDetailFeaturesFeature,
} from '@customTypes/dashboard/project/type';
import { useAdminProjectDetailQuery } from '@lib/api/dashboard/queryHooks';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

interface Props {
  checkFeaturesActivate: (
    projectData: ProjectListDetail,
    category: ProjectListDetailFeaturesCategory,
    feature: ProjectListDetailFeaturesFeature,
  ) => boolean;
}

const ProjectDetailsFeatures = ({ checkFeaturesActivate }: Props) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const adminProjectDetailQuery = useAdminProjectDetailQuery(idx);
  const adminProjectData = adminProjectDetailQuery.data;

  const fieldsetValues = [
    {
      idx: 0,
      fieldsetName: 'Mobile',
      inputField: [
        {
          id: 'mobile-functional',
          labelName: '기능 테스트',
          defaultChecked: checkFeaturesActivate(
            adminProjectData,
            ProjectListDetailFeaturesCategory.Mobile,
            ProjectListDetailFeaturesFeature.Functional,
          ),
          disabled: true,
        },
        {
          id: 'mobile-speed',
          labelName: '속도 테스트',
          defaultChecked: checkFeaturesActivate(
            adminProjectData,
            ProjectListDetailFeaturesCategory.Mobile,
            ProjectListDetailFeaturesFeature.Speed,
          ),
          disabled: true,
        },
      ],
    },
    {
      idx: 1,
      fieldsetName: 'Web',
      inputField: [
        {
          id: 'web-functional',
          labelName: '기능 테스트',
          defaultChecked: checkFeaturesActivate(
            adminProjectData,
            ProjectListDetailFeaturesCategory.Web,
            ProjectListDetailFeaturesFeature.Functional,
          ),
          disabled: true,
        },
        {
          id: 'web-speed',
          labelName: '속도 테스트',
          defaultChecked: checkFeaturesActivate(
            adminProjectData,
            ProjectListDetailFeaturesCategory.Web,
            ProjectListDetailFeaturesFeature.Speed,
          ),
          disabled: true,
        },
      ],
    },
  ];

  return (
    <div className="border-b border-gray-200 py-5">
      <div className="border-b border-gray-200 pb-5">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Features
        </h3>
      </div>
      <div>
        <div className="mt-2 space-y-6 pb-8 sm:space-y-0 sm:pb-0">
          <ErrorBoundaryWrapper>
            {fieldsetValues?.map((value) => (
              <Fieldset
                key={value.idx}
                fieldsetName={value.fieldsetName}
                inputField={value.inputField}
              />
            ))}
          </ErrorBoundaryWrapper>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsFeatures;
