import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useMobileTestResultSearchListQuery } from '@lib/api/dashboard/mobile/mobileTestResult';

import {
  DefaultSkeleton,
  ErrorBoundaryWrapper,
  Pagination,
  PagingHeadings,
  ResultsToolbar,
} from '@autosquare/common';

import SearchForm from '@components/SearchForm/SearchForm';
import MobileTestResultList from '@components/UITest/MobileTestResult/MobileTestResultList';
import getFirstGenerationDateToDateType from '@utils/static/getFirstGenerationDateToDateType';

const MobileTestResult = () => {
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));
  const size = Number(searchParams.get('size'));

  const mobileTestResultSearchListQuery = useMobileTestResultSearchListQuery();
  const mobileTestResultSearchList = mobileTestResultSearchListQuery.data;
  const mobileTestResults = mobileTestResultSearchList?.content;

  const initialCreatedAt = mobileTestResultSearchList?.initialCreatedAt;
  const formattedCreatedAt = getFirstGenerationDateToDateType(initialCreatedAt);

  const currentUrl = pathname + search;
  const breadcrumbs = [
    { title: 'Mobile', href: currentUrl },
    { title: 'UI Test Result', href: currentUrl },
    { title: 'List', href: currentUrl },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={'UI Test Result List'}
      />
      <SearchForm initialCreatedAt={formattedCreatedAt}>
        <SearchForm.ResultTestType />
        <SearchForm.MobileDeviceOs />
        <SearchForm.ExecutionResult />
      </SearchForm>
      <ResultsToolbar
        totalResults={mobileTestResultSearchList?.totalElements}
      />
      <MobileTestResultList />
      {mobileTestResultSearchListQuery.isLoading ? (
        <DefaultSkeleton number={1} />
      ) : (
        mobileTestResults?.length !== 0 && (
          <Pagination
            page={page}
            total={mobileTestResultSearchList?.totalElements}
            size={size}
          />
        )
      )}
    </ErrorBoundaryWrapper>
  );
};

export default MobileTestResult;
