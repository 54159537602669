import DefinitionList from '@components/shared/DefinitionList';
import SwitchInController from '@components/shared/HeadlessUI/Switch/SwitchInController';
import { UpdateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const TestStepEditMaskingOptions = () => {
  const { control } = useFormContext<UpdateTestStepData>();

  return (
    <DefinitionList
      term={'Masking Options'}
      description={
        <Controller
          control={control}
          name={'optionsDto.maskingOptions.enabled'}
          render={({ field }) => (
            <SwitchInController
              checked={field.value === 'true' ? true : false}
              onChange={field.onChange}
              size="sm"
              disabled={true}
            />
          )}
        />
      }
    />
  );
};

export default TestStepEditMaskingOptions;
