import { ErrorBoundaryWrapper } from '@autosquare/common';
import { RecipientData } from '@customTypes/ide/speedScheduler/speedScheduler';
import React from 'react';

type Props = {
  messengerList: RecipientData[];
  isRunning: boolean;

  deleteTagItem: (indexToDelete: number) => void;
};

const SpeedSchedulerDetailMessengerList = ({
  messengerList,
  isRunning,
  deleteTagItem,
}: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div className="mt-4">
        {messengerList?.map((value, index) => (
          <span
            key={`${value.recipientId}${value.recipientInfo}${index}`}
            className="m-1 inline-flex items-center justify-between gap-x-0.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600"
          >
            {value?.recipientInfo === undefined
              ? value?.recipientId
              : `${value?.recipientId} / ${value?.recipientInfo}`}
            {isRunning && (
              <button
                type="button"
                className="group relative -mr-1 size-3.5 rounded-sm hover:bg-gray-500/20"
                onClick={() => deleteTagItem(index)}
              >
                <span className="sr-only">Remove</span>
                <svg
                  viewBox="0 0 14 14"
                  className="size-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75"
                >
                  <path d="M4 4l6 6m0-6l-6 6" />
                </svg>
                <span className="absolute -inset-1" />
              </button>
            )}
          </span>
        ))}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default SpeedSchedulerDetailMessengerList;
