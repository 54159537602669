import {
  ArrowSpinner,
  decodeJwtToken,
  ErrorBoundaryWrapper,
} from '@autosquare/common';

import { RootState } from '@app/store';

import { useProjectListQuery } from '@lib/api/ide/project/project';
import {
  useUserInfoQuery,
  usePostUserSettingMutation,
} from '@lib/api/ide/user/user';
import { useSchedulerInitQuery } from '@lib/api/ide/scheduler/scheduler';

import NewSidebar from '@components/NewSidebar/NewSidebar';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProjectLists from './components/ProjectLists';
import NoProjectList from './components/NoProjectList';
import { getRemoteState } from '@utils/static/getConfiguration';
import { useRemoteSystemSettingQuery } from '@lib/api/ide/remote/remote';
import { Navigate } from 'react-router-dom';
import { showModal } from '@store/ide/remote/remoteSettingsModalSlice';
import RemoteStartSettingDialog from '@components/Nav/shared/RemoteStartSettingDialog';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';

const IdeProject = () => {
  const dispatch = useDispatch();
  const {
    isRemoteConnectionNeeded,
    standardConfiguration,
    remoteConfiguration,
  } = getRemoteState();
  const projectListQuery = useProjectListQuery({
    enabled: isRemoteConnectionNeeded || standardConfiguration,
  });
  const projectLists = projectListQuery.data;
  const projectListQueryLoading =
    projectListQuery.isLoading || projectListQuery.isFetching;
  const isRemoteConnecting = useSelector(
    (state: RootState) => state.remoteConnection.isConnecting,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const schedulerInitQuery = useSchedulerInitQuery();

  const accessToken = useSelector(
    (state: RootState) => state.accessTokenValue.ideAccessToken,
  );

  const applicationUrl = localStorage.getItem('applicationUrl');

  const userInfoQuery = useUserInfoQuery();
  const userInfo = userInfoQuery.data;

  const postUserSettingMutation = usePostUserSettingMutation();

  const { data: remoteSystemSetting } = useRemoteSystemSettingQuery();

  const remoteSettingsOpenOption =
    remoteConfiguration &&
    isRemoteConnectionNeeded &&
    remoteSystemSetting?.remote_enable &&
    (!projectListQuery.isLoading || !projectListQuery.isFetching);

  useEffect(() => {
    if (standardConfiguration && userInfo) {
      postUserSettingMutation.mutate(userInfo);
    }
  }, [userInfo, standardConfiguration]);

  useEffect(() => {
    if (!applicationUrl) {
      window.electron.sendNoApplicationUrl();
    }

    if (accessToken) {
      const userId = decodeJwtToken(accessToken)?.sub;
      window.electron.send('userId', userId);
    }
  }, []);

  useEffect(() => {
    if (remoteSettingsOpenOption) {
      dispatch(showModal());
    }
  }, [remoteSettingsOpenOption]);

  return (
    <>
      {accessToken ? (
        <ErrorBoundaryWrapper>
          <NewSidebar />
          <main className="flex max-w-full sm:px-0 lg:px-0">
            <div
              className={`w-full px-4 lg:ml-20 ${
                projectLists?.length === 0 ? 'pt-0' : 'pt-8'
              }`}
            >
              {isRemoteConnectionNeeded
                ? isRemoteConnecting && <ArrowSpinner />
                : (standardConfiguration || remoteConfiguration) &&
                  (projectLists?.length === 0 ? (
                    <NoProjectList />
                  ) : (
                    <ProjectLists
                      projectListQuery={projectListQuery}
                      projectLists={projectLists}
                    />
                  ))}
              <RemoteStartSettingDialog
                projectListQueryLoading={projectListQueryLoading}
              />
              <RemoteConnectionAlarmModal />
            </div>
          </main>
        </ErrorBoundaryWrapper>
      ) : (
        <Navigate to="/ide/login" state="!200" />
      )}
    </>
  );
};

export default IdeProject;
