import { API } from '@api/endpoints';
import {
  DeleteProjectDevice,
  DeviceApprovalRequest,
  DeviceListInProject,
  UpdateProjectDeviceDetail,
} from '@customTypes/index';
import { axiosInstance } from '@lib/api/dashboard';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { adminDevicesKeys, projectDeviceKeys } from '@queries/index';
import { useParentPath } from '@hooks/useParentPath';

// GET
// 프로젝트 메뉴에서 사용 가능한 디바이스 리스트를 조회 Device List In Project
// https://dev.t-square.co.kr/swagger-ui/index.html#/Project%20Device%20Management/getApprovedDeviceList
const getDeviceListInProject = async (
  projectIdx: string,
): Promise<DeviceListInProject[]> => {
  const response = await axiosInstance.get(
    `${API.PROJECT_DEVICE}?project=${projectIdx}`,
  );
  return response.data;
};

export const useDeviceListInProjectQuery = () => {
  const { projectIdx } = useParams();

  return useQuery<DeviceListInProject[], ServerResponseErrorDetails>({
    queryFn: () => getDeviceListInProject(projectIdx),
    queryKey: projectDeviceKeys.list(projectIdx),
  });
};

// 프로젝트 내 등록된 디바이스 상세 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Project%20Device%20Management/getApprovedDevice
const getProjectDeviceDetail = async (
  deviceIdx: string,
): Promise<DeviceListInProject> => {
  const response = await axiosInstance.get(
    `${API.PROJECT_DEVICE}/${deviceIdx}`,
  );
  return response.data;
};

export const useProjectDeviceDetailQuery = () => {
  const [searchParams] = useSearchParams();
  const deviceIdx = searchParams.get('idx');

  return useQuery<DeviceListInProject, ServerResponseErrorDetails>({
    queryFn: () => getProjectDeviceDetail(deviceIdx),
    queryKey: projectDeviceKeys.detail(deviceIdx),
  });
};

// POST
// 프로젝트 내 디바이스 등록 요청
// https://dev.t-square.co.kr/swagger-ui/index.html#/Project%20Device%20Management/requestAddDevice
export const registerDeviceRequest = async (
  data: DeviceApprovalRequest,
): Promise<DeviceListInProject> => {
  const response = await axiosInstance.post(
    `${API.PROJECT_DEVICE}/approval`,
    data,
  );
  return response.data;
};

export const useRegisterDeviceRequestMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    DeviceListInProject,
    ServerResponseErrorDetails,
    DeviceApprovalRequest
  >({
    mutationFn: (data) => registerDeviceRequest(data),
    onSuccess: () => {
      queryClient.invalidateQueries(projectDeviceKeys.all);
    },
  });
};

// PUT
// 프로젝트 내 디바이스의 활성화 상태 변경
// https://dev.t-square.co.kr/swagger-ui/index.html#/Project%20Device%20Management/updateDeviceStatus
export const updateDeviceActivationStatus = async (
  idx: number,
  enabled: boolean,
): Promise<DeviceListInProject> => {
  const response = await axiosInstance.put(
    `${API.PROJECT_DEVICE}/${idx}/${enabled}`,
  );
  return response.data;
};

export const useDeviceActivationMutation = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const deviceListPage = useParentPath();

  return useMutation<
    DeviceListInProject,
    ServerResponseErrorDetails,
    { idx: number; enabled: boolean }
  >({
    mutationFn: ({ idx, enabled }) =>
      updateDeviceActivationStatus(idx, enabled),
    onSuccess: () => {
      queryClient.invalidateQueries(projectDeviceKeys.all);
      queryClient.invalidateQueries(adminDevicesKeys.all);
      if (pathname.includes('/detail')) {
        navigate(deviceListPage);
      }
    },
  });
};

// 등록된 Device 정보 수정
// https://dev.t-square.co.kr/swagger-ui/index.html#/Project%20Device%20Management/updateDeviceAdministration
export const updateProjectDeviceInfo = async (
  idx: string,
  data: UpdateProjectDeviceDetail,
): Promise<DeviceListInProject> => {
  const response = await axiosInstance.put(
    `${API.PROJECT_DEVICE}/${idx}`,
    data,
  );
  return response.data;
};

export const useProjectDeviceDetailMutation = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const queryClient = useQueryClient();

  const idx = searchParams.get('idx');
  const deviceListPage = pathname.split('/').slice(0, -1).join('/');

  return useMutation<
    DeviceListInProject,
    ServerResponseErrorDetails,
    UpdateProjectDeviceDetail
  >({
    mutationFn: (data) => updateProjectDeviceInfo(idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(projectDeviceKeys.all);
      queryClient.invalidateQueries(adminDevicesKeys.all);
      navigate(deviceListPage);
    },
  });
};

// DELETE
// 프로젝트 내 디바이스 삭제 요청
// https://dev.t-square.co.kr/swagger-ui/index.html#/Project%20Device%20Management/requestDeleteDevice
export const deleteDeviceRequest = async (
  data: DeleteProjectDevice,
): Promise<unknown> => {
  const response = await axiosInstance.delete(
    `${API.PROJECT_DEVICE}/approval`,
    { data: data },
  );
  return response.data;
};

export const useDeleteDeviceRequestMutation = () => {
  return useMutation<unknown, ServerResponseErrorDetails, DeleteProjectDevice>({
    mutationFn: (data) => deleteDeviceRequest(data),
  });
};
