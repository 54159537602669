import { TestCaseDetailDataSchema } from '@customTypes/index';
import { z } from 'zod';

export const ImportTestStepFilteredListSchema = TestCaseDetailDataSchema.pick({
  idx: true,
  title: true,
  description: true,
  platformType: true,
}).extend({
  step: z.array(z.number()).optional(),
  disabled: z.boolean(),
});

export type ImportTestStepFilteredList = z.infer<
  typeof ImportTestStepFilteredListSchema
>;
