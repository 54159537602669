import { IDEAPI } from '@api/ideEndpoints';

import { useNavigate, useParams } from 'react-router-dom';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { PlatFormTypeServerName, TestTypeCategory } from '@autosquare/common';
import { remoteKeys } from '@queries/dashboard/remoteKeys';
import {
  Mode,
  RemoteControl,
  RemoteControlInfo,
  RemoteSettingSystem,
} from '@customTypes/ide/remote/type';
import { DeviceInfo } from '@customTypes/ide/device/device';
import { mobileDeviceKeys } from '@queries/ide/mobile/mobileDeviceKeys';
import { BrowserInfo } from '@customTypes/ide/browser/browser';
import { browserKeys } from '@queries/ide/web/browserKeys';
import { useDispatch } from 'react-redux';
import { ExecutionTestData } from '@customTypes/testExecution/type';
import {
  WebSpeedTestExecutionType,
  WebTestExecutionData,
} from '@customTypes/ide/browser/browserExecution';
import { deleteRemoteInfo } from '@store/ide/remote/remoteControlSlice';
import { clearRemoteAccessToken } from '@store/api/accessTokenSlice';
import { axiosInstance } from '..';
import { remoteConfig } from '@utils/static/remoteConfig';
import { getRemoteState } from '@utils/static/getConfiguration';

// GET
// 원격제어가 가능한 IDE 리스트 조회
const fetchRemoteIDEList = async (): Promise<RemoteControlInfo[]> => {
  const response = await axiosInstance.get<RemoteControlInfo[]>(
    `${IDEAPI.REMOTE}`,
  );
  return response.data;
};
export const useRemoteIDEListQuery = (
  options?: UseQueryOptions<RemoteControlInfo[], ServerResponseErrorDetails>,
) => {
  return useQuery<RemoteControlInfo[], ServerResponseErrorDetails>({
    queryKey: remoteKeys.lists(),
    queryFn: () => fetchRemoteIDEList(),
    ...options,
  });
};

// 원격제어 가능한 사용자 설정 조회
const fetchRemoteSystemSetting = async (): Promise<RemoteSettingSystem> => {
  const response = await axiosInstance.get<RemoteSettingSystem>(
    `${IDEAPI.SYSTEM_REMOTE_SETTING}`,
  );
  return response.data;
};
export const useRemoteSystemSettingQuery = (
  options?: UseQueryOptions<RemoteSettingSystem, ServerResponseErrorDetails>,
) => {
  return useQuery<RemoteSettingSystem, ServerResponseErrorDetails>({
    queryKey: remoteKeys.systemSetting(),
    queryFn: () => fetchRemoteSystemSetting(),
    ...options,
  });
};

// 원격 디바이스 목록 조회
//https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Device/getDeviceList
const deviceList = async (projectIdx: string): Promise<DeviceInfo[]> => {
  const response = await axiosInstance.get<DeviceInfo[]>(
    `${IDEAPI.REMOTE}/connect/device/mobile?projectIdx=${projectIdx}`,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteDeviceListQuery = (
  options?: UseQueryOptions<DeviceInfo[], Error>,
) => {
  const { projectIdx } = useParams();
  const { configuration } = getRemoteState();
  return useQuery<DeviceInfo[], Error>({
    queryKey: mobileDeviceKeys.lists(),
    queryFn: () => deviceList(projectIdx),
    enabled: configuration === Mode.Remote,
    ...options,
  });
};

// 원격 브라우저 목록 조회
//https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Device/getBrowserList
const webBrowserList = async (): Promise<BrowserInfo[]> => {
  const response = await axiosInstance.get<BrowserInfo[]>(
    `${IDEAPI.REMOTE}/connect/web/browser`,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteWebBrowserListQuery = (
  options?: UseQueryOptions<BrowserInfo[], Error>,
) => {
  const { configuration } = getRemoteState();
  return useQuery<BrowserInfo[], Error>({
    queryKey: browserKeys.all,
    queryFn: () => webBrowserList(),
    enabled: configuration === Mode.Remote,
    ...options,
  });
};

// POST
// 원격 제어 연결
const connectRemoteControl = async (
  data: RemoteControl,
): Promise<RemoteControlInfo> => {
  const response = await axiosInstance.post(
    `${IDEAPI.REMOTE}/enter`,
    data,
    remoteConfig,
  );
  return response.data;
};
export const useConnectRemoteControlMutation = () => {
  const navigate = useNavigate();
  return useMutation<
    RemoteControlInfo,
    ServerResponseErrorDetails,
    RemoteControl
  >({
    mutationFn: (data) => connectRemoteControl(data),
    onSuccess: () => {
      navigate(`/ide/projects/list`);
    },
  });
};

// 원격 제어 종료
const disconnectRemoteControl = async (
  data: RemoteControl,
): Promise<RemoteControlInfo> => {
  const response = await axiosInstance.post(
    `${IDEAPI.REMOTE}/exit`,
    data,
    remoteConfig,
  );
  return response.data;
};
export const useDisconnectRemoteControlMutation = () => {
  const dispatch = useDispatch();

  return useMutation<
    RemoteControlInfo,
    ServerResponseErrorDetails,
    RemoteControl
  >({
    mutationFn: (data) => disconnectRemoteControl(data),
    onSuccess: () => {
      dispatch(deleteRemoteInfo());
      dispatch(clearRemoteAccessToken());
    },
  });
};

// Remote Mobile Execution
const executionMobileTestExecution = async (
  type: string,
  data: ExecutionTestData,
): Promise<unknown> => {
  const response = await axiosInstance.post<unknown>(
    `${IDEAPI.REMOTE}/connect/execute/mobile/run?type=${type}`,
    data,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteMobileTestExecutionMutation = (
  testType: TestTypeCategory,
  platformType?: PlatFormTypeServerName,
) => {
  const isUiTest = isWordInLocationPathname('ui-test');
  const isSpeedTest = isWordInLocationPathname('speed-test');

  const testExecutionType = (): string => {
    const executionTypeMap = {
      uiStep: 'command',
      uiCase: 'unit',
      uiScenario: 'manual',
      appSpeedStep: 'command_app_speed',
      webSpeedStep: 'command_web_speed',
      appSpeedCase: 'unit_app_speed',
      webSpeedCase: 'unit_web_speed',
      speedScenario: 'scenario_speed',
    };

    if (isUiTest && testType === 'step') return executionTypeMap.uiStep;
    if (isUiTest && testType === 'case') return executionTypeMap.uiCase;
    if (isUiTest && testType === 'scenario') return executionTypeMap.uiScenario;
    if (
      isSpeedTest &&
      platformType === PlatFormTypeServerName.MobileApp &&
      testType === 'step'
    )
      return executionTypeMap.appSpeedStep;
    if (
      isSpeedTest &&
      platformType === PlatFormTypeServerName.MobileWeb &&
      testType === 'step'
    )
      return executionTypeMap.webSpeedStep;
    if (
      isSpeedTest &&
      platformType === PlatFormTypeServerName.MobileApp &&
      testType === 'case'
    )
      return executionTypeMap.appSpeedCase;
    if (
      isSpeedTest &&
      platformType === PlatFormTypeServerName.MobileWeb &&
      testType === 'case'
    )
      return executionTypeMap.webSpeedCase;

    if (isSpeedTest && testType === 'scenario')
      return executionTypeMap.speedScenario;
  };

  const type = testExecutionType();

  return useMutation<unknown, Error, ExecutionTestData>({
    mutationFn: (data) => executionMobileTestExecution(type, data),
  });
};

// Remote Web Execution
const executionWebTestExecution = async (
  type: WebSpeedTestExecutionType,
  data: WebTestExecutionData,
): Promise<unknown> => {
  const response = await axiosInstance.post<unknown>(
    `${IDEAPI.REMOTE}/connect/execute/web/run?type=${type}`,
    data,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteWebTestExecutionMutation = (
  testType: TestTypeCategory,
) => {
  const isUiTest = isWordInLocationPathname('ui-test');
  const isSpeedTest = isWordInLocationPathname('speed-test');

  const testExecutionType = (): WebSpeedTestExecutionType => {
    const executionTypeMap = {
      uiCase: WebSpeedTestExecutionType.Case,
      uiScenario: WebSpeedTestExecutionType.Scenario,
      speedCase: WebSpeedTestExecutionType.SpeedCase,
      speedScenario: WebSpeedTestExecutionType.SpeedScenario,
    };

    if (isUiTest && testType === 'case') return executionTypeMap.uiCase;
    if (isUiTest && testType === 'scenario') return executionTypeMap.uiScenario;
    if (isSpeedTest && testType === 'case') return executionTypeMap.speedCase;
    if (isSpeedTest && testType === 'scenario')
      return executionTypeMap.speedScenario;
  };

  return useMutation<unknown, Error, WebTestExecutionData>({
    mutationFn: (data) => executionWebTestExecution(testExecutionType(), data),
  });
};

// PUT
// 원격 제어 정보 수정
const editAliasRemoteControl = async (
  data: RemoteControl,
): Promise<RemoteControlInfo> => {
  const response = await axiosInstance.put(`${IDEAPI.REMOTE}`, data);
  return response.data;
};

export const useUpdateAliasRemoteControlMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    RemoteControlInfo,
    ServerResponseErrorDetails,
    RemoteControl
  >({
    mutationFn: (data) => editAliasRemoteControl(data),
    onSuccess: () => {
      queryClient.invalidateQueries(remoteKeys.lists());
    },
  });
};
