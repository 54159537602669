import React, { useEffect } from 'react';
import AddTestStepConditionHeader from '@components/TestStep/AddTestStepConditionHeader';
import { ServerFormatDataType } from '@customTypes/ide/mobileTestStep/dataValidation/dataValidation';
import {
  CompareBy,
  CreateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { useFormContext } from 'react-hook-form';
import TypeAndReferenceValue from './DataValidationComponent/TypeAndReferenceValue';
import OperatorInputValueFieldsArray from './DataValidationComponent/OperatorInputValueFieldsArray';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const DataValidationComponent = () => {
  const { setValue } = useFormContext<CreateTestStepData>();

  useEffect(() => {
    setValue('optionsDto.dataType', ServerFormatDataType.Number);
    setValue('optionsDto.operatorList', [
      { operator: undefined, inputValue: undefined },
    ]);
    setValue('optionsDto.compareBy', CompareBy.Id);
    return () => {
      setValue('optionsDto.operatorList', undefined);
      setValue('optionsDto.dataType', undefined);
      setValue('optionsDto.compareBy', undefined);
      setValue('optionsDto.compareIdx', undefined);
    };
  }, []);

  return (
    <ErrorBoundaryWrapper>
      <div className="space-y-4">
        <AddTestStepConditionHeader
          header={'Condition'}
          description={'조건식을 설정합니다.'}
        />
        <TypeAndReferenceValue />
        <OperatorInputValueFieldsArray />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default DataValidationComponent;
