import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';
import {
  AddAdministrationDevice,
  UpdateAdministrationDevice,
} from '@customTypes/index';

type Props = {
  id: keyof AddAdministrationDevice;
  label: string;
  children: React.ReactNode;
};

const AdminDeviceDetailFormFields = ({ id, label, children }: Props) => {
  const {
    formState: { errors },
  } = useFormContext<UpdateAdministrationDevice>();

  return (
    <div className="flex flex-col gap-y-2">
      <label className="text-sm font-medium leading-normal text-gray-900">
        {label}
      </label>
      {children}
      <ErrorMessage
        errors={errors}
        name={id}
        render={({ message }) => (
          <p className="standard-error-message py-0">{message}</p>
        )}
      />
    </div>
  );
};

export default AdminDeviceDetailFormFields;
