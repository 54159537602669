export const overviewKeys = {
  all: ['overview'] as const,
  mobile: (projectIdx: string) =>
    [...overviewKeys.all, 'mobile', projectIdx] as const,
  web: (projectIdx: string) =>
    [...overviewKeys.all, 'web', projectIdx] as const,
  lists: () => [...overviewKeys.all, 'list'] as const,
  list: (projectIdx: string) => [...overviewKeys.lists(), projectIdx] as const,
  report: (projectIdx: string, location: string) =>
    [...overviewKeys.all, 'report', projectIdx, location] as const,
};
