import React from 'react';

interface Props {
  errorMessage: string;
}

const TestResultDetailError = ({ errorMessage }: Props) => {
  return errorMessage === '404' ? (
    <div className="flex w-full flex-col items-center justify-center py-4">
      <div className="flex flex-col items-center justify-center">
        <div className="text-center text-base font-semibold leading-normal text-[#043e8d]">
          404
        </div>
        <div className="h-4 self-stretch" />
        <div className="text-center text-5xl font-bold leading-[48px] text-gray-900">
          Data not found
        </div>
        <div className="h-6 self-stretch" />
        <div className="text-center text-base font-bold leading-7 text-gray-600">
          sorry. The data you are looking for cannot be found.
        </div>
      </div>
    </div>
  ) : (
    <div className="flex w-full flex-col items-center justify-center py-4">
      <div className="error-message text-center text-base font-semibold leading-normal">
        {errorMessage}
      </div>
    </div>
  );
};

export default TestResultDetailError;
