import React from 'react';
import clsx from 'clsx';
import EnvironmentBar from '@components/Overview/EnvironmentBar';
import MobileUITestResults from '@components/Overview/MobileUITestResults';
import ResultRatePie from '@components/Overview/ResultRatePie';
import TestBreakdownGroup from '@components/Overview/TestBreakdownGroup';
import WebUITestResults from '@components/Overview/WebUITestResults';
import OverviewSubTitle from '@components/shared/OverviewSubTitle';
import { checkIsProjectFeatureIncluded } from '@utils/static/checkProjectFeature';
import {
  ProjectListDetailFeaturesCategory,
  ProjectListDetailFeaturesFeature,
} from '@customTypes/dashboard/project/type';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';
import { MobileTestResultProperties, WebResult } from '@customTypes/index';
import { OverviewResultRateReport } from '@customTypes/ide/overview/type';

type Props = {
  reportData: OverviewResultRateReport;
  mobileFunctionalResults: MobileTestResultProperties[];
  webFunctionalResults: WebResult[];
};

const UITestTabOverview = ({
  reportData,
  mobileFunctionalResults,
  webFunctionalResults,
}: Props) => {
  const tableHeaders = ['No', 'Title', 'DateTime', 'Result', 'Detail'];
  const projectList = useSelector(
    (state: RootState) => state.projectList.projectList,
  );
  const isMobileTestFunctionalEnabled = checkIsProjectFeatureIncluded(
    projectList,
    ProjectListDetailFeaturesCategory.Mobile,
    ProjectListDetailFeaturesFeature.Functional,
  );
  const isWebTestFunctionalEnabled = checkIsProjectFeatureIncluded(
    projectList,
    ProjectListDetailFeaturesCategory.Web,
    ProjectListDetailFeaturesFeature.Functional,
  );
  const isBothFunctionalEnabled =
    isMobileTestFunctionalEnabled && isWebTestFunctionalEnabled;
  const mobileReportData = reportData?.mobileResultReports;
  const webReportData = reportData?.webResultReports;

  return (
    <div className="flow-root">
      <OverviewSubTitle
        subTitle={'Result Rate'}
        description="프로젝트 테스트 결과 비율"
      />
      <div className="grid grid-cols-2 gap-x-7">
        {isMobileTestFunctionalEnabled && (
          <ResultRatePie data={mobileReportData} header="Mobile" />
        )}
        {isWebTestFunctionalEnabled && (
          <ResultRatePie data={webReportData} header="Web" />
        )}
        <div className={clsx(isBothFunctionalEnabled && 'col-span-2')}>
          <OverviewSubTitle
            subTitle={'Environment'}
            description="테스트 환경"
          />
          <div
            className={clsx(
              isBothFunctionalEnabled && 'grid grid-cols-2 gap-x-3',
            )}
          >
            {isMobileTestFunctionalEnabled && (
              <EnvironmentBar
                data={mobileReportData?.envRate}
                envType={'mobile'}
              />
            )}
            {isWebTestFunctionalEnabled && (
              <EnvironmentBar data={webReportData?.envRate} envType={'web'} />
            )}
          </div>
          <div className="mt-8 flow-root">
            <OverviewSubTitle
              subTitle={'Test Breakdown'}
              description="프로젝트 내 스케줄러, 시나리오, 케이스 개수"
            />
            <TestBreakdownGroup
              data={reportData}
              isMobileTestEnabled={isMobileTestFunctionalEnabled}
              isWebTestEnabled={isWebTestFunctionalEnabled}
            />
          </div>
        </div>
      </div>
      <div
        className={clsx(
          isBothFunctionalEnabled && 'grid grid-cols-1 gap-x-3 2xl:grid-cols-2',
        )}
      >
        {isMobileTestFunctionalEnabled && (
          <MobileUITestResults
            tableHeaders={tableHeaders}
            mobileResults={mobileFunctionalResults}
          />
        )}
        {isWebTestFunctionalEnabled && (
          <WebUITestResults
            tableHeaders={tableHeaders}
            webResults={webFunctionalResults}
          />
        )}
      </div>
    </div>
  );
};

export default UITestTabOverview;
