import {
  TestResult,
  TestResultDetailInquiryByCase,
  TestResultExportCaseResultList,
  TestResultInquiryByCase,
  WebTestResultPagingData,
} from '@customTypes/testResult/type';
import { DeleteIndexList, ServerResponseErrorDetails } from '@customTypes/type';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { axiosRawResponseInstance, axiosInstance } from '..';
import { API } from '@api/endpoints';
import { webTestResultKeys } from '@queries/ide/web/webTestResultKeys';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

// GET
const getWebTestResultList = async (
  projectIdx: string,
  currentPage: number,
  limit: number,
): Promise<WebTestResultPagingData> => {
  const response = await axiosInstance.get(
    API.getWebTestResultList(projectIdx, currentPage, limit),
  );
  return response.data;
};
export const useWebTestResultListQuery = (
  projectIdx: string,
  currentPage: number,
  limit: number,
  options?: UseQueryOptions<
    WebTestResultPagingData,
    ServerResponseErrorDetails
  >,
) => {
  return useQuery<WebTestResultPagingData, ServerResponseErrorDetails>({
    queryKey: webTestResultKeys.list(projectIdx),
    queryFn: () => getWebTestResultList(projectIdx, currentPage, limit),
    staleTime: 10000,
    ...options,
  });
};

const webTestResultSearchList = async (
  search: string,
): Promise<WebTestResultPagingData> => {
  const response = await axiosInstance.get<WebTestResultPagingData>(
    `${API.GET_WEB_TEST_RESULT}search${search}`,
  );
  return response.data;
};
export const useWebTestResultSearchListQuery = (
  options?: UseQueryOptions<WebTestResultPagingData, Error>,
) => {
  const { search } = useLocation();
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page');
  const size = searchParams.get('size');
  const projectIdx = searchParams.get('projectIdx');
  const order = searchParams.get('order');

  return useQuery<WebTestResultPagingData, Error>({
    queryKey: webTestResultKeys.search(search),
    queryFn: () => webTestResultSearchList(search),
    enabled:
      page !== null && size !== null && projectIdx !== null && order !== null,
    keepPreviousData: true,
    ...options,
  });
};

const getWebTestCaseExecutionDetail = async (
  idx: string,
  caseResultIdx: number,
): Promise<TestResultDetailInquiryByCase[]> => {
  const response = await axiosInstance.get(
    API.getWebTestCaseExecutionDetail(idx, caseResultIdx),
  );
  return response.data;
};
export const useWebTestCaseExecutionDetailQuery = (
  caseResultIdx: number,
  options?: UseQueryOptions<
    TestResultDetailInquiryByCase[],
    ServerResponseErrorDetails
  >,
) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  return useQuery<TestResultDetailInquiryByCase[], ServerResponseErrorDetails>({
    queryKey: webTestResultKeys.executionDetail(idx, caseResultIdx),
    queryFn: () => getWebTestCaseExecutionDetail(idx, caseResultIdx),
    ...options,
  });
};
const getWebTestResultByTestCase = async (
  idx: string,
): Promise<TestResultInquiryByCase> => {
  const response = await axiosInstance.get(`${API.getWebTestCaseResult(idx)}`);
  return response.data;
};
export const useWebTestCaseResultQuery = (idx: string) => {
  return useQuery<TestResultInquiryByCase, ServerResponseErrorDetails>({
    queryKey: webTestResultKeys.detailInfo(idx),
    queryFn: () => getWebTestResultByTestCase(idx),
  });
};

const getWebTestResultDetail = async (idx: string): Promise<TestResult> => {
  const response = await axiosInstance.get(`${API.GET_WEB_TEST_RESULT}${idx}`);
  return response.data;
};
export const useWebTestResultDetailQuery = (idx: string) => {
  return useQuery<TestResult, ServerResponseErrorDetails>({
    queryKey: webTestResultKeys.detail(idx),
    queryFn: () => getWebTestResultDetail(idx),
  });
};

const webTestResultImageData = async (path: string[]): Promise<string[]> => {
  const requests = path.map((path) =>
    axiosRawResponseInstance.get<MediaSource>(path, {
      responseType: 'blob',
    }),
  );

  const responses = await Promise.all(requests);
  const objectUrls = responses.map((response) =>
    URL.createObjectURL(response.data),
  );

  return objectUrls;
};

//image
export const useWebTestResultImageData = (
  path: string[],
  options?: UseQueryOptions<string[], Error>,
) => {
  return useQuery<string[], Error>({
    queryKey: webTestResultKeys.detailImage(path[0]),
    queryFn: () => webTestResultImageData(path),
    ...options,
  });
};

// POST
const webTestResultExport = async (
  resultIdx: string,
  data: TestResultExportCaseResultList,
): Promise<{ blob: Blob; filename: string }> => {
  const response = await axiosRawResponseInstance.post<Blob>(
    `${API.WEB_TEST_RESULT}/${resultIdx}/download-excel`,
    data,
    { responseType: 'blob' },
  );

  const contentDisposition = response.headers['content-disposition'];
  const filenameMatch = contentDisposition.match(/filename="(.+)"/);

  return { blob: response.data, filename: filenameMatch[1] };
};
export const useWebTestResultExportMutation = () => {
  const [searchParams] = useSearchParams();
  const resultIdx = searchParams.get('idx');

  return useMutation<
    { blob: Blob; filename: string },
    Error,
    TestResultExportCaseResultList
  >({
    mutationFn: (data) => webTestResultExport(resultIdx, data),
    onSuccess: ({ blob, filename }) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },
  });
};

// DELETE
const webTestResultDelete = async (
  data: DeleteIndexList,
): Promise<DeleteIndexList> => {
  const response = await axiosInstance.delete<DeleteIndexList>(
    `${API.WEB_TEST_RESULT}`,
    { data },
  );
  return response.data;
};
export const useWebTestResultDeleteMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation<DeleteIndexList, Error, DeleteIndexList>({
    mutationFn: (data) => webTestResultDelete(data),
    onSuccess: () => {
      queryClient.invalidateQueries(webTestResultKeys.lists());
      queryClient.invalidateQueries(webTestResultKeys.searches());
      navigate(-1);
    },
  });
};
