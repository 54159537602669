import { Button } from '@autosquare/common';
import { CommonDialog, DialogButtonPanels } from '@components/Dialog';
import React from 'react';

type Props = {
  isDisabledDevice: boolean;
  setIsDisabledDevice: React.Dispatch<React.SetStateAction<boolean>>;
  disabledOnClick: () => void;
};

const UpdateDeviceDisableDialog = ({
  isDisabledDevice,
  setIsDisabledDevice,
  disabledOnClick,
}: Props) => {
  return (
    <CommonDialog
      isOpen={isDisabledDevice}
      setIsOpen={setIsDisabledDevice}
      iconType={'caution'}
      title={'Device Disable'}
      subTitle={
        '해당 디바이스를 비활성화하면 IDE에서 사용할 수 없습니다.\n비활성화하시겠습니까?\n(스케줄러에 설정된 경우, 테스트 결과는 N/A로 처리됩니다.)'
      }
    >
      <DialogButtonPanels>
        <Button type="button" variant="primary" onClick={disabledOnClick}>
          OK
        </Button>
        <Button
          type="button"
          variant="secondary"
          onClick={() => setIsDisabledDevice(false)}
        >
          Cancel
        </Button>
      </DialogButtonPanels>
    </CommonDialog>
  );
};

export default UpdateDeviceDisableDialog;
