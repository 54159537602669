import React from 'react';

import { Button, ErrorBoundaryWrapper, ErrorMessage } from '@autosquare/common';

import { UpdateAdminProjectDetailData } from '@customTypes/dashboard/admin/type';
import { ServerResponseErrorDetails } from '@customTypes/type';

import { UseMutationResult } from '@tanstack/react-query';

import { useFormContext } from 'react-hook-form';

interface Props {
  setIsEdited: React.Dispatch<React.SetStateAction<boolean>>;
  savedToggleStatus: boolean;
  setIsToggleOn: React.Dispatch<React.SetStateAction<boolean>>;
  updateAdminProjectDetailMutation: UseMutationResult<
    unknown,
    ServerResponseErrorDetails,
    UpdateAdminProjectDetailData,
    unknown
  >;
}

const ProjectEditButton = ({
  setIsEdited,
  savedToggleStatus,
  setIsToggleOn,
  updateAdminProjectDetailMutation,
}: Props) => {
  const { reset } = useFormContext<UpdateAdminProjectDetailData>();

  return (
    <ErrorBoundaryWrapper>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <div />
        <ErrorMessage margin={'m-0'}>
          {updateAdminProjectDetailMutation.isError &&
            updateAdminProjectDetailMutation.error.message}
        </ErrorMessage>
        <div className="flex items-center justify-between gap-x-6">
          <Button
            type="button"
            variant="secondary"
            onClick={() => {
              reset();
              setIsToggleOn(savedToggleStatus);
              updateAdminProjectDetailMutation.reset();
              setIsEdited(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type={'submit'}
            variant="primary"
            disabled={updateAdminProjectDetailMutation.isLoading}
          >
            Save
          </Button>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default ProjectEditButton;
