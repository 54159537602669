import React from 'react';

const SmsListSkeleton = () => {
  return (
    <div className="h-full animate-pulse gap-4">
      <div className="grid w-full grid-cols-3 gap-4 border-b border-gray-200 p-2">
        {Array.from({ length: 3 }, (_, i) => (
          <div
            key={i}
            className="h-6 w-full rounded-md bg-gray-200 dark:bg-gray-700"
          />
        ))}
      </div>
      {Array.from({ length: 3 }, (_, i) => (
        <div key={i} className="grid w-full grid-cols-3 gap-4 p-2">
          {Array.from({ length: 3 }, (_, j) => (
            <div
              key={j}
              className="h-6 w-full rounded-md bg-gray-200 dark:bg-gray-700"
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default SmsListSkeleton;
