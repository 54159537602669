import React, { useState } from 'react';

import CreateSchedulerStartDate from './CreateSchedulerSchedule/CreateSchedulerStartDate';
import CreateSchedulerRunningCycle from './CreateSchedulerSchedule/CreateSchedulerRunningCycle ';
import CreateSchedulerDays from './CreateSchedulerSchedule/CreateSchedulerDays';
import CreateSchedulerPreview from './CreateSchedulerSchedule/CreateSchedulerPreview';

import { RunningCycle } from '@customTypes/ide/scheduler/scheduler';
import { hourCycle } from '@utils/static/runningCycle';
import { days } from '@utils/static/selectedDays';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';

const CreateSchedulerSchedule = () => {
  const [selectedCycle, setSelectedCycle] = useState<RunningCycle>(
    hourCycle[0],
  );
  const [selectedDays, setSelectedDays] = useState<string[]>(days);
  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer title={'Schedule'}>
        <div className="grid grid-cols-10 gap-8">
          <CreateSchedulerStartDate />
          <CreateSchedulerRunningCycle
            selectedCycle={selectedCycle}
            setSelectedCycle={setSelectedCycle}
          />
          <CreateSchedulerPreview
            selectedCycle={selectedCycle}
            selectedDays={selectedDays}
          />
          <CreateSchedulerDays
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
          />
        </div>
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default CreateSchedulerSchedule;
