import React, { useEffect, useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useLocation } from 'react-router-dom';

import SidebarChildren from './SidebarList/SidebarChildren';
import clsx from 'clsx';

interface Props {
  item: {
    name: string;
    location?: string;
    children?: {
      name: string;
      href: string;
      base: string;
    }[];
    href?: string;
    base?: string;
  };
}

const SidebarList = ({ item }: Props) => {
  const location = useLocation();
  const [isOpened, setIsOpened] = useState(item.location !== 'username');
  useEffect(() => {
    if (location.pathname.includes(item.location)) {
      setIsOpened(true);
    }
  }, []);

  return (
    <div>
      <button
        className={clsx(
          'flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700 transition-all duration-700',
          location.pathname.includes(item.base)
            ? 'bg-gray-50'
            : 'hover:bg-gray-50',
        )}
        onClick={() => setIsOpened(!isOpened)}
      >
        <ChevronRightIcon
          className={clsx(
            'size-5 shrink-0',
            isOpened ? 'rotate-90 text-gray-500' : 'text-gray-400',
          )}
          aria-hidden="true"
        />
        {item.name}
      </button>
      {isOpened && (
        <ul className="mt-1 px-2">
          {item.children.map((subItem) => (
            <SidebarChildren key={subItem.name} subItem={subItem} />
          ))}
        </ul>
      )}
    </div>
  );
};

export default SidebarList;
