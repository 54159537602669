import { useState } from 'react';
import {
  MessengerPlatformRequiredInfo,
  UpdateAdminProjectDetailData,
} from '@customTypes/dashboard/admin/type';
import { UseFormSetValue } from 'react-hook-form';

const useEditMessengerPlatformHandler = (
  filteredMessengerList: MessengerPlatformRequiredInfo[],
  defaultPlatform: string,
  changePlatformCount: number,
  setIsOpenedCautionDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setChangePlatformCount: React.Dispatch<React.SetStateAction<number>>,
  setValue: UseFormSetValue<UpdateAdminProjectDetailData>,
) => {
  const [tempPlatform, setTempPlatform] = useState(null);

  const platformChangeHandler = (value) => {
    const findFieldCode = filteredMessengerList?.find(
      (item) => item.serverName === value,
    ).platformCode;

    if (defaultPlatform === value && changePlatformCount === 0) return;
    if (
      defaultPlatform !== undefined &&
      defaultPlatform !== value &&
      changePlatformCount === 0
    ) {
      setTempPlatform(value);
      setIsOpenedCautionDialog(true);
    } else {
      setValue('messengers.0.platform', value);
      if (findFieldCode === 'MSGR01') {
        setValue('messengers.0', {
          activate: true,
          platform: value,
          requestUrl: '',
          sender: undefined,
          srvCode: undefined,
          recipientList: [],
          officeToken: '',
        });
        setValue('recipientId', '');
        setValue('recipientInfo', '');
        setValue('recipientFormat', undefined);
      }
      if (
        findFieldCode === 'MSGR10' ||
        findFieldCode === 'MSGR11' ||
        findFieldCode === 'MSGR12'
      ) {
        setValue('messengers.0', {
          activate: true,
          platform: value,
          requestUrl: '',
          srvCode: '',
          sender: '',
          officeToken: undefined,
          recipientList: [],
        });
        setValue('recipientId', '');
        setValue('recipientInfo', '');
        setValue('recipientFormat', undefined);
      }
    }
  };

  const okButtonHandler = () => {
    const findPlatformRequired = filteredMessengerList?.find(
      (item) => item.serverName === tempPlatform,
    );

    if (tempPlatform !== null) {
      setValue('messengers.0', {
        activate: true,
        platform: tempPlatform,
        requestUrl: '',
        officeToken: '',
        srvCode: '',
        sender: '',
        recipientList: [],
      });
      setValue('recipientId', '');
      setValue('recipientInfo', '');
      setValue('recipientFormat', undefined);
    }
    if (findPlatformRequired?.platformCode === 'MSGR01') {
      setValue('messengers.0.requestUrl', '');
      setValue('messengers.0.sender', undefined);
      setValue('messengers.0.srvCode', undefined);
    }
    if (
      findPlatformRequired?.platformCode === 'MSGR10' ||
      findPlatformRequired?.platformCode === 'MSGR11' ||
      findPlatformRequired?.platformCode === 'MSGR12'
    ) {
      setValue('messengers.0.requestUrl', '');
      setValue('messengers.0.srvCode', '');
      setValue('messengers.0.sender', '');
      setValue('messengers.0.officeToken', undefined);
    }
    setChangePlatformCount((prev) => prev + 1);
    setTempPlatform(null);
    setIsOpenedCautionDialog(false);
  };

  const cancelButtonHandler = () => {
    setTempPlatform(null);
    setIsOpenedCautionDialog(false);
  };

  return { platformChangeHandler, okButtonHandler, cancelButtonHandler };
};

export default useEditMessengerPlatformHandler;
