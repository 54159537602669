import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  DefaultSkeleton,
  ErrorBoundaryWrapper,
  Pagination,
  PagingHeadings,
  ResultsToolbar,
} from '@autosquare/common';

import { useWebTestResultSearchListQuery } from '@lib/api/dashboard/web/webTestResult';

import SearchForm from '@components/SearchForm/SearchForm';
import WebTestResultList from '@components/UITest/WebTestResult/WebTestResultList';
import getFirstGenerationDateToDateType from '@utils/static/getFirstGenerationDateToDateType';

const WebTestResult = () => {
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));
  const size = Number(searchParams.get('size'));

  const webTestResultSearchListQuery = useWebTestResultSearchListQuery();
  const webTestResultSearchList = webTestResultSearchListQuery.data;

  const webTestResult = webTestResultSearchList?.content;
  const initialCreatedAt = webTestResultSearchList?.initialCreatedAt;
  const formattedCreatedAt = getFirstGenerationDateToDateType(initialCreatedAt);

  const currentPageLink = pathname + search;
  const breadcrumbs = [
    { title: 'Web', href: currentPageLink },
    { title: 'UI Test Result', href: currentPageLink },
    { title: 'List', href: currentPageLink },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={'UI Test Result List'}
      />
      <SearchForm calendarSubTitle="Date" initialCreatedAt={formattedCreatedAt}>
        <SearchForm.ResultTestType />
        <SearchForm.Browser />
        <SearchForm.ExecutionResult />
      </SearchForm>
      <ResultsToolbar totalResults={webTestResultSearchList?.totalElements} />
      <WebTestResultList />
      {webTestResultSearchListQuery.isLoading ? (
        <DefaultSkeleton number={1} />
      ) : (
        webTestResult?.length !== 0 && (
          <Pagination
            page={page}
            total={webTestResultSearchList?.totalElements}
            size={size}
          />
        )
      )}
    </ErrorBoundaryWrapper>
  );
};

export default WebTestResult;
