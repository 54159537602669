import { ErrorBoundaryWrapper } from '@autosquare/common';
import { CreateAdminProjectData } from '@customTypes/dashboard/admin/type';
import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const MessengerRequestUrl = () => {
  const {
    register,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateAdminProjectData>();

  const requestUrlField = watch('messengers.0.requestUrl');

  useEffect(() => {
    if (!requestUrlField) {
      setError('messengers.0.requestUrl', {
        message: '필수 입력 항목입니다.',
      });
      return;
    }
    clearErrors('messengers.0.requestUrl');
  }, [requestUrlField, clearErrors, setError]);

  return (
    <div className="mt-6 sm:space-y-0 sm:pb-0">
      <div className="flex items-center gap-x-3 py-5">
        <label className="text-sm font-medium leading-6 text-gray-900">
          Request URL*
        </label>
        <p className="text-sm font-medium leading-tight text-gray-500">
          알림을 받을 메신저의 URL을 입력해 주세요.
        </p>
      </div>
      <div className="mt-2">
        <ErrorBoundaryWrapper>
          <input
            type="text"
            name={'requestUrl'}
            id={'requestUrl'}
            className="input-base"
            {...register('messengers.0.requestUrl', {
              required: '필수 입력 항목입니다.',
            })}
          />
          <ErrorMessage
            errors={errors}
            name="messengers.0.requestUrl"
            render={({ message }) => (
              <p className="error-message py-1.5">{message}</p>
            )}
          />
        </ErrorBoundaryWrapper>
      </div>
    </div>
  );
};

export default MessengerRequestUrl;
