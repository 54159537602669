import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';
import TestStepValueListbox from '@components/IDE/TestStepValueListbox';
import KeyboardEventListbox from '@components/IDE/WebTestStep/KeyboardEventListbox';
import WebTestStepImportTestCaseListBox from '@components/IDE/WebTestStep/WebTestStepImportTestCaseListBox';
import AddTestStepConditionHeader from '@components/TestStep/AddTestStepConditionHeader';
import { useWebTestStepContext } from '@contexts/ide/WebTestStepProvider';
import { CreateWebTestStepData } from '@customTypes/testStep/type';
import { ErrorMessage } from '@hookform/error-message';
import {
  findWebCommandNameFromServerName,
  WebCommandOptionName,
  webCommandOptions,
  WebCommandOptionServerName,
} from '@utils/static/webCommandOptions';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  passCommand: WebCommandOptionServerName;
  setPassCommand: React.Dispatch<
    React.SetStateAction<WebCommandOptionServerName>
  >;
  valueDefaultText: (command: WebCommandOptionServerName) => string;
};

const IfActionPass = ({
  passCommand,
  setPassCommand,
  valueDefaultText,
}: Props) => {
  const { isPassListboxOpened, setIsPassListboxOpened, fullXPath } =
    useWebTestStepContext();

  const {
    setValue,
    watch,
    control,
    formState: { errors },
  } = useFormContext<CreateWebTestStepData>();

  const conditionCommand = watch('optionsDto.conditionCommand');
  const conditionValue = watch('optionsDto.conditionValue');
  const passActionValue = watch('optionsDto.passActionValue');
  const maskingOptionsEnabled = watch('optionsDto.maskingOptions.enabled');

  useEffect(() => {
    return () => {
      setValue('optionsDto.passActionValue', undefined);
    };
  }, [passCommand, setValue]);

  return (
    <ErrorBoundaryWrapper>
      <AddTestStepConditionHeader
        header={'Action'}
        description={'조건식의 결과에 따라 실행될 동작을 지정합니다.'}
      />
      <div className="flex gap-4">
        <div className="mt-1 w-10 text-sm font-medium text-green-400">PASS</div>
        <div className="flex w-[calc(100%-3.5rem)] flex-col gap-4">
          <ListBoxHeadlessInController
            value={passCommand}
            buttonValue={
              passCommand
                ? findWebCommandNameFromServerName(passCommand)
                : 'Command'
            }
            onChange={setPassCommand}
            lists={webCommandOptions.filter(
              (webCommand) =>
                webCommand.name === WebCommandOptionName.Click ||
                webCommand.name === WebCommandOptionName.ImportTestCase ||
                webCommand.name === WebCommandOptionName.KeyBoardEvent,
            )}
            valueToSave={'serverName'}
            valueToShow={'name'}
            onClickOption={() =>
              setValue('optionsDto.passActionValue', undefined)
            }
            disabled={
              conditionCommand === undefined ||
              conditionValue === undefined ||
              maskingOptionsEnabled
            }
          />
          {passCommand === WebCommandOptionServerName.KeyBoardEvent ? (
            <KeyboardEventListbox value={'optionsDto.passActionValue'} />
          ) : passCommand === WebCommandOptionServerName.ImportTestCase ? (
            <WebTestStepImportTestCaseListBox
              value={'optionsDto.passActionValue'}
            />
          ) : (
            <ErrorBoundaryWrapper>
              <Controller
                control={control}
                name={'optionsDto.passActionValue'}
                rules={{ required: 'Pass Action Value를 선택해주세요.' }}
                render={({ field }) => (
                  <TestStepValueListbox
                    valueText={field.value || valueDefaultText(passCommand)}
                    setValueText={field.onChange}
                    totalValues={fullXPath}
                    isListboxOpened={isPassListboxOpened}
                    setIsListboxOpened={setIsPassListboxOpened}
                    resetOnClick={() => {
                      setPassCommand(undefined);
                      setValue('optionsDto.passActionValue', undefined);
                    }}
                    disabled={
                      conditionCommand === undefined ||
                      conditionValue === undefined ||
                      passCommand === undefined ||
                      passActionValue !== undefined ||
                      maskingOptionsEnabled
                    }
                    resetDisabled={maskingOptionsEnabled}
                  />
                )}
              />
            </ErrorBoundaryWrapper>
          )}
        </div>
      </div>
      <ErrorMessage
        name="optionsDto.passActionValue"
        errors={errors}
        render={({ message }) => (
          <p className="error-message !mt-2 ml-14">{message}</p>
        )}
      />
    </ErrorBoundaryWrapper>
  );
};

export default IfActionPass;
