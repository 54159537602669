import React from 'react';

import {
  Button,
  ErrorBoundaryWrapper,
  TableBodyBasic,
} from '@autosquare/common';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { AdminUserEmail } from '@customTypes/index';
import { TrashIcon } from '@heroicons/react/20/solid';

type Props = {
  email: AdminUserEmail;
  idx: number;
  setIsCopyToClipBoard: React.Dispatch<React.SetStateAction<boolean>>;
  isSendInvite: boolean;
  deleteCurrentUserEmail: (email: string) => void;
};

const AddSystemUsersTableBody = ({
  email,
  idx,
  setIsCopyToClipBoard,
  isSendInvite,
  deleteCurrentUserEmail,
}: Props) => {
  const handleCopy = async (signUpLink: string) => {
    try {
      await navigator.clipboard.writeText(signUpLink);
      setIsCopyToClipBoard(true);
      setTimeout(() => {
        setIsCopyToClipBoard(false);
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundaryWrapper>
      <tr className="border-t border-gray-300">
        <TableBodyBasic>{idx + 1}</TableBodyBasic>
        <TableBodyBasic>{email.inviteUserEmail}</TableBodyBasic>
        <TableBodyBasic>
          {isSendInvite ? (
            email.inviteLink ? (
              <div className="flex items-center justify-between text-sm font-normal leading-tight text-gray-900">
                <p>{email.inviteLink}</p>
                <Button
                  type="button"
                  variant="imageOriginal"
                  onClick={() => handleCopy(email.inviteLink)}
                >
                  <DocumentDuplicateIcon className="size-5" />
                </Button>
              </div>
            ) : (
              email.inviteError && (
                <p className="error-message justify-start">
                  {email.inviteError}
                </p>
              )
            )
          ) : (
            <div className="flex items-center justify-end">
              <Button
                type="button"
                variant="imageOriginal"
                onClick={() => deleteCurrentUserEmail(email.inviteUserEmail)}
              >
                <TrashIcon className="size-5" />
              </Button>
            </div>
          )}
        </TableBodyBasic>
      </tr>
    </ErrorBoundaryWrapper>
  );
};

export default AddSystemUsersTableBody;
