import { ErrorBoundaryWrapper } from '@autosquare/common';
import { UpdateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { ControllerRenderProps, Path, useFormContext } from 'react-hook-form';

type Props<TFieldValues, TName extends Path<TFieldValues>> = {
  field: ControllerRenderProps<TFieldValues, TName>;
};

const ControllerTextArea = <TFieldValues, TName extends Path<TFieldValues>>({
  field,
}: Props<TFieldValues, TName>) => {
  const { ref, ...rest } = field;

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '0px';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [field]);

  useImperativeHandle(ref, () => textareaRef.current);

  const {
    formState: { errors },
  } = useFormContext<UpdateTestStepData>();

  return (
    <ErrorBoundaryWrapper>
      <textarea
        ref={textareaRef}
        className="block min-h-9 w-full overflow-y-auto rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 scrollbar-thin placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        {...(rest as unknown as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
        value={rest.value as string}
        name={rest.name as string}
      />
      <ErrorMessage
        errors={errors}
        name={rest.name}
        render={({ message }) => (
          <p className="standard-error-message">{message}</p>
        )}
      />
    </ErrorBoundaryWrapper>
  );
};

export default ControllerTextArea;
