import { API } from '@api/endpoints';
import { useMutation } from '@tanstack/react-query';
import { axiosRawResponseInstance } from '..';
import { ServerResponseErrorDetails } from '@customTypes/type';

type OS = 'win' | 'mac';
type Arch = 'x64' | 'arm64';

type FileName =
  | 'Autosquare-win-x64.exe'
  | 'Autosquare-mac-x64.pkg'
  | 'Autosquare-mac-arm64.pkg';

// GET
export const getIdeFileDownload = async (
  os: OS,
  arch: Arch,
  fileName: FileName,
): Promise<void> => {
  const response = await axiosRawResponseInstance.get(
    API.ideFileDownload(os, arch),
    {
      responseType: 'blob',
    },
  );
  const blob = new Blob([response.data]);
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  document.body.removeChild(link);
  link.click();
  link.remove();
};

// mutation
export const useIdeFileDownloadMutation = () => {
  return useMutation<
    void,
    ServerResponseErrorDetails,
    {
      os: 'win' | 'mac';
      arch: 'x64' | 'arm64';
    }
  >({
    mutationFn: async ({
      os,
      arch,
    }: {
      os: 'win' | 'mac';
      arch: 'x64' | 'arm64';
    }) => {
      const fileName: FileName =
        os === 'win' && arch === 'x64'
          ? 'Autosquare-win-x64.exe'
          : os === 'mac' && arch === 'x64'
            ? 'Autosquare-mac-x64.pkg'
            : os === 'mac' && arch === 'arm64'
              ? 'Autosquare-mac-arm64.pkg'
              : undefined;

      await getIdeFileDownload(os, arch, fileName);
    },
  });
};
