import React from 'react';
import { AddAppInfo } from '@customTypes/dashboard/project/application/type';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext, useWatch } from 'react-hook-form';

const FileInformationSection = () => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<AddAppInfo>();

  const list: {
    title: string;
    name: keyof Omit<AddAppInfo, 'file'>;
    editable?: boolean;
  }[] = [
    { title: 'App Name', name: 'appName' },
    { title: 'Package Name', name: 'packageName' },
    { title: 'App Version', name: 'appVersion' },
    { title: 'Build Version', name: 'buildVersion' },
    { title: 'OS', name: 'osType' },
    {
      title: 'Description',
      name: 'description',
      editable: true,
    },
  ];

  const values = useWatch({ control });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { file, ...restValues } = values;
  return (
    <div className="space-y-6 border-t border-gray-200 pb-8 pt-4">
      {list.map(({ title, name, editable }) => (
        <div
          key={title}
          className="grid items-center gap-4 text-sm leading-normal text-gray-900 md:grid-cols-8"
        >
          <div className="font-medium">{title}</div>
          {editable ? (
            <>
              <div className="col-span-7 w-full">
                <input type="text" className="input-base" {...register(name)} />
              </div>
              <ErrorMessage
                name="description"
                errors={errors}
                render={({ message }) => (
                  <p className="error-message col-start-5">{message}</p>
                )}
              />
            </>
          ) : (
            <div className="col-span-7 whitespace-nowrap font-normal">
              {restValues?.[name] ? restValues[name] : '-'}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FileInformationSection;
