import React, { useEffect, useState } from 'react';
import {
  useMobileDeleteDriverMutation,
  useRemoteMobileDeleteDriverMutation,
} from '@lib/api/ide/mobile/mobileDevice';
import { useGetRefreshToken } from '@hooks/electron/useGetRefreshToken';
import MobileTestStepProvider from '@contexts/ide/MobileTestStepProvider';
import MainTestStep from './components/MainTestStep';
import { Mode } from '@customTypes/ide/remote/type';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';
import { getRemoteState } from '@utils/static/getConfiguration';
import MobileTestStepHeader from './components/MobileTestStepHeader';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const MobileTestStep = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { cookie } = useGetRefreshToken();

  // 컴포넌트 언마운트 되었을 때 Driver 삭제 요청 처리
  const [hasMounted, setHasMounted] = useState(false);

  const { configuration } = getRemoteState();
  const mobileDeleteDriverMutation =
    configuration === Mode.Standard
      ? useMobileDeleteDriverMutation()
      : useRemoteMobileDeleteDriverMutation();

  useEffect(() => {
    if (!hasMounted) {
      setHasMounted(true);
    }
    return () => {
      if (hasMounted) {
        mobileDeleteDriverMutation.mutate();
      }
    };
  }, [hasMounted]);

  return (
    <ErrorBoundaryWrapper>
      <MobileTestStepProvider>
        <div className="relative flex h-dvh max-w-full flex-col overflow-hidden">
          <MobileTestStepHeader />
          <MainTestStep />
        </div>
        <RemoteConnectionAlarmModal />
      </MobileTestStepProvider>
    </ErrorBoundaryWrapper>
  );
};

export default MobileTestStep;
