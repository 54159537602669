import React from 'react';
import DatResult from './TestCaseImportShowingResults/DatResult';
import CsvResult from './TestCaseImportShowingResults/CsvResult';
import { useTestCaseImport } from '@contexts/ide/TestCaseImportContext';

const TestCaseImportShowingResults = () => {
  const { fileData, fileInfo, isDatData, isCsvData } = useTestCaseImport();

  const fileName = fileInfo?.name;

  if (/\.dat$/.test(fileName) && isDatData(fileData)) {
    return <DatResult fileData={fileData} />;
  }

  if (/\.csv$/.test(fileName) && isCsvData(fileData)) {
    return <CsvResult fileData={fileData} />;
  }
};

export default TestCaseImportShowingResults;
