import React from 'react';
import LineChart from './LineChart';
import SpeedTestResultGraphInfoTable from '@components/shared/SpeedTestResultTable/SpeedTestResultGraphInfoTable';
import {
  MobileSpeedTestResultDetailData,
  LoadSpeedDataContents,
} from '@customTypes/ide/speedTest/Mobile/testResult/type';
import {
  AccessorFn,
  ColumnDef,
  createColumnHelper,
} from '@tanstack/react-table';
import ReactApexChart from 'react-apexcharts';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  TestResultDisplay,
  TestResultStatus,
} from '@customTypes/testResult/type';

type Props = {
  data: MobileSpeedTestResultDetailData;
  chartRef: React.MutableRefObject<ReactApexChart>;
};

const SpeedTestResultGraph = ({ data, chartRef }: Props) => {
  const columnHelper = createColumnHelper();

  const makeData = (
    chartCategories: string[],
    data: LoadSpeedDataContents[],
  ): unknown[] => {
    return [...chartCategories].reverse().map((v, i) => ({
      0: { idx: 0, data: v },
      ...data?.reduce((acc, item, dataIndex) => {
        acc[dataIndex + 1] = [...item.data]
          .map((value) => ({
            idx: value.detailIdx,
            data:
              value?.result !== TestResultStatus.Pass
                ? TestResultDisplay[value?.result]
                : value?.value.toFixed(2),
            status: value?.result,
          }))
          .reverse()[i];
        return acc;
      }, {}),
    }));
  };

  const makeColumns = (
    columnsData: LoadSpeedDataContents[],
  ): ColumnDef<unknown, unknown>[] => [
    columnHelper.accessor('0' as unknown as AccessorFn<unknown, unknown>, {
      header: 'Date',
      cell: (info) => info.getValue(),
    }),
    ...columnsData.map((v, i) =>
      columnHelper.accessor(
        (i + 1).toString() as unknown as AccessorFn<unknown, unknown>,
        {
          header: v?.name,
          cell: (info) => info.getValue(),
        },
      ),
    ),
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="flex items-center justify-between border-b border-gray-200 pb-3 pt-6">
        <h2 className="font-semibold">Test Result Graph</h2>
      </div>
      <LineChart data={data} chartRef={chartRef} />
      <SpeedTestResultGraphInfoTable
        data={makeData(data?.dateList, data?.loadSpeedData)}
        columns={makeColumns(data?.loadSpeedData)}
      />
    </ErrorBoundaryWrapper>
  );
};

export default SpeedTestResultGraph;
