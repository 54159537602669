import React from 'react';
import InstalledAppHeader from './components/InstalledAppHeader';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import InstalledAppTable from './components/InstalledAppTable';

const InstalledApp = () => {
  return (
    <ErrorBoundaryWrapper>
      <InstalledAppHeader />
      <InstalledAppTable />
    </ErrorBoundaryWrapper>
  );
};

export default InstalledApp;
