import {
  ErrorBoundaryWrapper,
  ErrorMessage,
  ListBoxHeadlessInController,
} from '@autosquare/common';

import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import {
  findPlatformTypeNameFromServerName,
  platformTypeList,
} from '@utils/static/platformTypeList';

import { useTestCaseGroupListQuery } from '@lib/api/ide/testGroup/testCaseGroup';

import FormField from '@components/shared/Input/FormField';
import PriorityListbox from '@components/shared/HeadlessUI/Listbox/PriorityListbox';
import TestGroupBox from '@components/IDE/TestCase/TestGroupBox';

import { CreateMobileTestCaseData } from '@customTypes/testCase/type';

const CreateMobileTestForm = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<CreateMobileTestCaseData>();

  const { isError, error } = useTestCaseGroupListQuery();

  return (
    <ErrorBoundaryWrapper>
      <div className="mt-6 flex flex-col pb-12">
        <div className="grid grid-cols-3 gap-4">
          <div className="flex flex-col">
            <div className="mb-2 block text-sm font-medium leading-6 text-gray-900">
              Platform Type
            </div>
            <Controller
              control={control}
              name="platformType"
              render={({ field }) => (
                <ListBoxHeadlessInController
                  value={field.value}
                  buttonValue={findPlatformTypeNameFromServerName(field.value)}
                  onChange={field.onChange}
                  lists={platformTypeList.slice(1)}
                  valueToSave={'serverName'}
                  valueToShow={'name'}
                />
              )}
            />
          </div>
          <div className="flex flex-col">
            <div className="block text-sm font-medium leading-6 text-gray-900">
              Priority
            </div>
            <div className="mt-2">
              <Controller
                control={control}
                name={'priority'}
                render={({ field }) => (
                  <PriorityListbox
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
          </div>
          <TestGroupBox isRequired={false} />
        </div>
        <div className="mt-6 flex flex-row gap-4">
          <FormField
            name={'tcId'}
            labelTitle={'TC-ID*'}
            errors={errors}
            register={register('tcId')}
          />
          <FormField
            name={'title'}
            labelTitle={'Title*'}
            errors={errors}
            register={register('title')}
          />
          <FormField
            name={'description'}
            labelTitle={'Description'}
            errors={errors}
            register={register('description')}
          />
        </div>
        {isError && <ErrorMessage>{error.message}</ErrorMessage>}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default CreateMobileTestForm;
