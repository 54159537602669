import React from 'react';
import clsx from 'clsx';

type Props = {
  count: number;
  total: number;
  isMobileTestEnabled: boolean;
  isWebTestEnabled: boolean;
};

const ScenarioItem = ({
  count,
  total,
  isMobileTestEnabled,
  isWebTestEnabled,
}: Props) => {
  return (
    <>
      <div
        className={clsx(
          'h-6 w-full rounded-lg',
          isMobileTestEnabled
            ? 'bg-gray-300'
            : isWebTestEnabled && 'bg-gray-100',
        )}
        style={{
          width: `${(count / total) * 100}%`,
        }}
      />
      <div className="text-xs font-normal text-black">{count}</div>
    </>
  );
};

export default ScenarioItem;
