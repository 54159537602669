import { AutoRegisterInfoData } from '@customTypes/ide/scheduler/scheduler';
import {
  CreateTestScenarioData,
  UpdateTestScenarioData,
} from '@customTypes/testScenario/type';
import { AutoRegisterPlatformServername } from '@utils/static/autoRegister/autoRegisterTypeList';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ConfluenceInputScenarioEditField from './AutoRegisterScenarioEditInputList/ConfluenceInputScenarioEditField';
import JiraInputScenarioEditField from './AutoRegisterScenarioEditInputList/JiraInputScenarioEditField';

type Props = {
  autoIndex: number;
  autoRegistersData: AutoRegisterInfoData[];
  selectedAutoRegisterData: AutoRegisterInfoData;
};

const AutoRegisterEditInputList = ({
  autoIndex,
  autoRegistersData,
  selectedAutoRegisterData,
}: Props) => {
  const { control } = useFormContext<
    CreateTestScenarioData | UpdateTestScenarioData
  >();
  const autoRegisters = useWatch({
    control,
    name: 'autoRegisters',
    defaultValue: autoRegistersData,
  });
  const platform = useWatch({
    control,
    name: `autoRegisters.${autoIndex}.platformName`,
  });

  return (
    <div className="flex flex-col space-y-4">
      {platform === AutoRegisterPlatformServername.Jira && (
        <JiraInputScenarioEditField
          autoIndex={autoIndex}
          autoRegisters={autoRegisters}
          selectedAutoRegisterData={selectedAutoRegisterData}
        />
      )}
      {platform === AutoRegisterPlatformServername.Confluence && (
        <ConfluenceInputScenarioEditField
          autoIndex={autoIndex}
          autoRegisters={autoRegisters}
        />
      )}
    </div>
  );
};

export default AutoRegisterEditInputList;
