import { ErrorBoundaryWrapper } from '@autosquare/common';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import TestResultSaveOptionsCheckbox from '@components/Scheduler/TestResultSaveOptionsCheckbox';
import {
  CreateSchedulerData,
  SchedulerTypeServerName,
} from '@customTypes/ide/scheduler/scheduler';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

const CreateSchedulerResultSaveOptions = () => {
  const { register, control, setValue } = useFormContext<CreateSchedulerData>();

  const testType = useWatch({ control, name: 'type' });

  useEffect(() => {
    if (testType === SchedulerTypeServerName.Mobile) {
      setValue('saveVideo', true);
      setValue('saveScreenshot', true);
      setValue('saveLog', true);
    } else {
      setValue('saveVideo', undefined);
      setValue('saveLog', undefined);
      setValue('saveScreenshot', true);
    }
  }, [testType, setValue]);

  const checkboxList =
    testType === SchedulerTypeServerName.Mobile
      ? [
          { title: 'Video', register: register('saveVideo', { value: true }) },
          {
            title: 'Screenshot',
            register: register('saveScreenshot', { value: true }),
          },
          { title: 'Log File', register: register('saveLog', { value: true }) },
        ]
      : [
          {
            title: 'Screenshot',
            register: register('saveScreenshot', { value: true }),
          },
        ];

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer title={'Test Result Save Options'}>
        <div
          className={clsx(
            'col-span-2 grid',
            testType === SchedulerTypeServerName.Mobile
              ? 'grid-cols-3'
              : 'grid-cols-1',
          )}
        >
          {checkboxList.map((checkbox) => (
            <TestResultSaveOptionsCheckbox
              key={checkbox.title}
              title={checkbox.title}
              register={checkbox.register}
            />
          ))}
        </div>
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default CreateSchedulerResultSaveOptions;
