import { ErrorBoundaryWrapper } from '@autosquare/common';
import { useProjectSettingDetailQuery } from '@lib/api/dashboard/queryHooks';
import React from 'react';

const DetailMessenger = () => {
  const { data: projectSettingDetail } = useProjectSettingDetailQuery();

  const messengerList = projectSettingDetail.messengers;

  if (projectSettingDetail.messengers.length === 0) {
    return;
  }

  const recipientList = messengerList?.[0]?.recipientList;

  return (
    <ErrorBoundaryWrapper>
      <section className="pb-2 pt-6">
        <h2 className="border-b border-solid border-gray-200 pb-5">
          Messenger
        </h2>
        <div>
          <div className="space-y-5 pt-3">
            <p className="font-medium">Recipient List</p>
            <ul className="space-y-2.5 rounded border border-solid border-gray-300 p-2.5">
              {recipientList.map((recipient, idx) => (
                <li key={recipient} className="flex py-1.5">
                  <p className="px-3">{idx + 1}</p>
                  <p className="px-3">{`${recipient.split('/')[0]}${recipient.split('/')[1] ? ` / ${recipient.split('/')[1]}` : ''}`}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </ErrorBoundaryWrapper>
  );
};

export default DetailMessenger;
