import {
  CompareBy,
  MobileTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { useGetTestCaseDataAutomation } from '@lib/api/ide/mobile/mobileTestAutomation';
import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';
import { useGetSpeedTestCaseDataAutomation } from '@lib/api/ide/speedMobile/mobileTestAutomation';
import { useSpeedMobileTestCaseListQuery } from '@lib/api/ide/speedMobile/speedMobileTestCase';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import React from 'react';
import { ClickId } from '../clickOptions';
import {
  DeviceKeyServerName,
  findListNameFromServerName,
} from '../deviceKeyList';
import { findTitleFromIdx } from '../findTitleFromIdx';
import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionServerName,
} from '../mobileCommandOption';

export const getTestStepDetailListValue = (
  mobileTestStep: MobileTestStepData,
  mobileTestStepList: MobileTestStepData[],
) => {
  const location = isWordInLocationPathname('ui-test')
    ? 'ui-test'
    : 'speed-test';

  const mobileTestCaseListQuery = useMobileTestCaseListQuery({
    enabled: location === 'ui-test',
  });

  const speedMobileCaseListQuery = useSpeedMobileTestCaseListQuery({
    enabled: location === 'speed-test',
  });

  const mobileTestCaseList =
    location === 'ui-test'
      ? mobileTestCaseListQuery.data
      : speedMobileCaseListQuery.data;

  const testCaseTitle = findTitleFromIdx(
    Number(mobileTestStep.value),
    mobileTestCaseList,
  );
  const importTestStepCount =
    mobileTestStep.optionsDto?.stepIdxList?.split(',').length;

  const isSecret = mobileTestStep.optionsDto?.secret === 'true';
  const secretValueText = isSecret ? '******' : mobileTestStep.value;

  // input text일 때 test automation 유무 확인
  const { data: testCaseDataAutomation } = useGetTestCaseDataAutomation({
    enabled:
      location === 'ui-test' &&
      mobileTestStep.command === MobileCommandOptionServerName.InputText,
  });

  const { data: speedTestCaseDataAutomation } =
    useGetSpeedTestCaseDataAutomation({
      enabled:
        location === 'speed-test' &&
        mobileTestStep.command === MobileCommandOptionServerName.InputText,
    });

  const dataAutomationInfo =
    location === 'ui-test'
      ? testCaseDataAutomation
      : speedTestCaseDataAutomation;

  switch (mobileTestStep.command) {
    case MobileCommandOptionServerName.Click:
      if (mobileTestStep.optionsDto.clickBy === ClickId.Coordinate) {
        return `[${mobileTestStep.value}]`;
      } else {
        return mobileTestStep.value;
      }

    case MobileCommandOptionServerName.ScrollSwipe:
      return `[${mobileTestStep.value}]`;

    case MobileCommandOptionServerName.ConfirmText:
    case MobileCommandOptionServerName.InputText:
      if (mobileTestStep.optionsDto.compareBy === CompareBy.Text) {
        if (mobileTestStep.optionsDto.textEncrypt === 'true' || isSecret) {
          return `${secretValueText}\ntarget text="**********"`;
        } else {
          return `${secretValueText}\ntarget text="${mobileTestStep.optionsDto.compareText}"`;
        }
      }
      if (mobileTestStep.optionsDto.compareBy === CompareBy.Id) {
        const compareIdx =
          mobileTestStepList
            ?.map((item) => item?.idx)
            .indexOf(parseInt(mobileTestStep.optionsDto.compareIdx)) + 1;
        return `${secretValueText}\ntarget text=#${compareIdx}`;
      }
      if (mobileTestStep.optionsDto.compareBy === CompareBy.Automation) {
        return `${secretValueText}/${dataAutomationInfo?.idx ? 'Automation' : 'None'}`;
      }
      return secretValueText;

    case MobileCommandOptionServerName.DeviceKey:
      return findListNameFromServerName(
        mobileTestStep.value as DeviceKeyServerName,
      );

    case MobileCommandOptionServerName.Loop:
      return (
        <>
          <p>
            Condition=&quot;
            {findMobileCommandNameFromServerName(
              mobileTestStep.optionsDto.conditionCommand,
            )}
            &quot;
          </p>
          <p>
            Action=&quot;
            {findMobileCommandNameFromServerName(
              mobileTestStep.value as MobileCommandOptionServerName,
            )}
            &quot;
          </p>
        </>
      );

    case MobileCommandOptionServerName.IF:
      return (
        <>
          <div>
            <p>Condition</p>
            <p>
              {findMobileCommandNameFromServerName(
                mobileTestStep.optionsDto.conditionCommand,
              )}
              , {mobileTestStep.optionsDto.conditionValue}
            </p>
          </div>
          <br />
          <div>
            <p>PASS</p>
            {findMobileCommandNameFromServerName(
              mobileTestStep.value.split(
                ',',
              )[0] as MobileCommandOptionServerName,
            )}
            , {mobileTestStep.optionsDto.passActionValue}
          </div>
          <br />
          <div>
            <p>FAIL</p>
            {mobileTestStep.value.split(',')[1] !== undefined &&
            mobileTestStep.optionsDto.failActionValue !== undefined
              ? `${findMobileCommandNameFromServerName(
                  mobileTestStep.value.split(
                    ',',
                  )[1] as MobileCommandOptionServerName,
                )}, ${mobileTestStep.optionsDto.failActionValue}`
              : 'None'}
          </div>
        </>
      );

    case MobileCommandOptionServerName.ImportTestCase:
      return testCaseTitle;

    case MobileCommandOptionServerName.ImportTestStep:
      return `${testCaseTitle} / ${importTestStepCount}`;
    default:
      return mobileTestStep.value;
  }
};
