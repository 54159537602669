import { UseMutationResult } from '@tanstack/react-query';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { useCallback, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

export const useExportChart = (
  speedTestResultExportMutation: UseMutationResult<
    { blob: Blob; filename: string },
    Error,
    FormData,
    unknown
  >,
) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const groupIdx = searchParams.get('group');
  const start = searchParams.get('start');
  const end = searchParams.get('end');
  const orderBy = searchParams.get('order-by');
  const channel = searchParams.get('channel');

  const mobileRequestDto = {
    projectIdx: Number(projectIdx),
    groupIdx: Number(groupIdx),
    start,
    end,
  };

  const webRequestDto = {
    projectIdx: mobileRequestDto.projectIdx,
    groupIdx: mobileRequestDto.groupIdx,
    startDate: start,
    endDate: end,
    orderBy,
    channel: channel,
  };

  const requestDto = isWordInLocationPathname('mobile')
    ? mobileRequestDto
    : webRequestDto;

  const chartRef = useRef(null);

  const exportChartToPng = useCallback(async () => {
    if (chartRef.current) {
      const chartInstance = chartRef.current.chart;

      if (chartInstance) {
        try {
          const { imgURI } = await chartInstance.dataURI(); // 차트의 데이터 URI 가져오기

          if (imgURI) {
            const base64Data = imgURI.split(',')[1];

            // Base64 데이터를 Blob으로 변환
            const byteCharacters = atob(base64Data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'image/png' });

            const formData = new FormData();
            formData.append('pngFile', blob, 'chart.png');
            formData.append('requestDto', JSON.stringify(requestDto));

            speedTestResultExportMutation.mutate(formData, {
              onError: () => {
                setIsModalOpen(true);
                setErrorMessage(
                  'Export를 하는 중 에러가 발생했습니다.\n재시도 이후에도 에러가 발생한다면 담당자에게 문의해 주세요.',
                );
              },
            });
          }
        } catch (error) {
          setIsModalOpen(true);
          setErrorMessage(
            'Export를 하는 중 에러가 발생했습니다.\n재시도 이후에도 에러가 발생한다면 담당자에게 문의해주세요.',
          );
        }
      }
    }
  }, [requestDto]);

  return {
    errorMessage,
    isModalOpen,
    setIsModalOpen,
    exportChartToPng,
    chartRef,
  };
};
