import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';
import { ErrorMessage } from '@hookform/error-message';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { useGetTestCaseDataAutomation } from '@lib/api/ide/mobile/mobileTestAutomation';
import { useGetSpeedTestCaseDataAutomation } from '@lib/api/ide/speedMobile/mobileTestAutomation';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

const AutomationOrderContents = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CreateTestStepData>();
  const isUiTestLocation = isWordInLocationPathname('ui-test');

  const { data: automationData } = isUiTestLocation
    ? useGetTestCaseDataAutomation()
    : useGetSpeedTestCaseDataAutomation();

  const rows = automationData?.table?.sheets?.[0].rows;
  const columnList = Object.keys(rows?.[0] || false)?.map((item) => {
    return { title: item || '-' };
  });

  return (
    <ErrorBoundaryWrapper>
      <Controller
        control={control}
        name="optionsDto.autoColumn"
        rules={{
          required: 'Test Data를 선택해 주세요.',
          validate: {
            valueDashError: (value) => {
              if (value === '-') {
                return 'Test Data를 선택해 주세요.';
              }
            },
          },
        }}
        render={({ field }) => (
          <ListBoxHeadlessInController
            value={field.value}
            buttonValue={field.value}
            onChange={field.onChange}
            lists={columnList}
            valueToSave={'title'}
            valueToShow={'title'}
            placeholder={'Select Test Data'}
            noListMessage={'저장된 Test Data가 없습니다.'}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name="optionsDto.autoColumn"
        render={({ message }) => (
          <p className="error-message -mt-2">{message}</p>
        )}
      />
    </ErrorBoundaryWrapper>
  );
};

export default AutomationOrderContents;
