import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React from 'react';

type Props = {
  value: number | string;
  onChange: (value: number | string) => void;
  label: 'year' | 'month';
  list: string[];
};

const CalenderYearMonthListbox = ({ value, onChange, label, list }: Props) => {
  return (
    <Listbox value={value} onChange={onChange}>
      <div className="relative">
        <ListboxButton className="w-20 rounded-lg border-none pr-8 text-sm font-bold text-gray-900 focus:ring-0">
          {value}
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="size-4 text-gray-900"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>
        <ListboxOptions
          className={clsx(
            'absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black/5 focus:outline-none',
            {
              '-left-2': label === 'year',
              'gray-scrollbar': label === 'month',
            },
          )}
        >
          {list.map((list) => (
            <ListboxOption
              key={list}
              className={clsx(
                'group relative cursor-default select-none py-2 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white',
              )}
              value={list}
            >
              <div className="flex items-center justify-center gap-x-1">
                <span
                  className={clsx(
                    'block truncate font-normal group-data-[selected]:font-semibold',
                  )}
                >
                  {list}
                </span>
                <CheckIcon
                  className="invisible size-4 group-data-[selected]:visible"
                  aria-hidden="true"
                />
              </div>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
};

export default CalenderYearMonthListbox;
