import React from 'react';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import AdminDataStorageSettingHeader from './components/AdminDataStorageSettingHeader';
import AdminDataStorageSettingDataLoader from './components/AdminDataStorageSettingDataLoader';

const AdminDataStorageSetting = () => {
  return (
    <ErrorBoundaryWrapper>
      <AdminDataStorageSettingHeader />
      <AdminDataStorageSettingDataLoader />
    </ErrorBoundaryWrapper>
  );
};

export default AdminDataStorageSetting;
