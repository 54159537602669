import { ErrorBoundaryWrapper } from '@autosquare/common';
import DescriptionData from '@components/IDE/WebTestStep/DescriptionData';
import { WebTestStepData } from '@customTypes/testStep/type';
import { useWebTestStepByCommandQuery } from '@lib/api/ide/web/webTestStep';
import {
  findDataType,
  findOperatorLabel,
  findWebTestStepDescriptionFromIdx,
} from '@utils/index';
import React from 'react';

type Props = {
  allWebTestStepList: WebTestStepData;
};

const DataValidationDetail = ({ allWebTestStepList }: Props) => {
  const { data: webTestStepBySaveTextList } = useWebTestStepByCommandQuery();

  const inputValueList = allWebTestStepList.optionsDto.operatorList.map(
    (operator) =>
      `${findOperatorLabel(operator.operator)}${operator.inputValue}`,
  );
  const joinInputValue = inputValueList.join(', ');

  return (
    <ErrorBoundaryWrapper>
      <DescriptionData
        title={'Condition'}
        data={findDataType(allWebTestStepList.optionsDto.dataType)}
      />
      <DescriptionData
        title={'Reference Value'}
        data={
          findWebTestStepDescriptionFromIdx(
            Number(allWebTestStepList.optionsDto.compareIdx),
            webTestStepBySaveTextList,
          ) || 'None'
        }
      />
      <DescriptionData title={'Input Data'} data={joinInputValue} />
    </ErrorBoundaryWrapper>
  );
};

export default DataValidationDetail;
