import { ErrorBoundaryWrapper } from '@autosquare/common';
import React, { useState } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useCreateMobileTestCaseMutation } from '@lib/api/ide/mobile/mobileTestCase';
import { PlatFormTypeServerName } from '@utils/static/platformTypeList';
import {
  CreateMobileTestCaseData,
  PriorityNameEnum,
  CreateMobileTestCaseDataSchema,
} from '@customTypes/index';

import CreateMobileTestForm from './components/CreateMobileTestForm';
import CreateNewGroupDialog from '@components/shared/TestCaseGroup/CreateNewGroupDialog';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';
import { useDispatch } from 'react-redux';
import { openCreateGroup } from '@store/ide/speedTestCase/CreateGroupSlice';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CommonDialog,
  DialogButton,
  DialogButtonPanels,
} from '@components/Dialog';
import CreateMobileTestCasePagingHeading from './components/CreateMobileTestCasePagingHeading';
import CreateMobileTestHeader from './components/CreateMobileTestHeader';

const CreateMobileTestCase = () => {
  const isOpenedCreateGroup = useSelector(
    (state: RootState) => state.createGroup.isOpen,
  );
  const dispatch = useDispatch();

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const methods = useForm<CreateMobileTestCaseData>({
    defaultValues: {
      platformType: PlatFormTypeServerName.MobileApp,
      priority: PriorityNameEnum.Medium,
    },
    resolver: zodResolver(CreateMobileTestCaseDataSchema),
  });

  const createMobileTestCaseMutation = useCreateMobileTestCaseMutation();

  const onSubmit: SubmitHandler<CreateMobileTestCaseData> = (data) => {
    createMobileTestCaseMutation.mutate(data, {
      onError: () => {
        setIsErrorModalOpen(true);
      },
    });
  };

  return (
    <ErrorBoundaryWrapper>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CreateMobileTestCasePagingHeading />
          <CreateMobileTestHeader />
          <CreateMobileTestForm />
        </form>
      </FormProvider>
      <CreateNewGroupDialog
        isOpen={isOpenedCreateGroup}
        setIsOpen={() => dispatch(openCreateGroup())}
      />
      <CommonDialog
        isOpen={isErrorModalOpen}
        setIsOpen={setIsErrorModalOpen}
        iconType={'caution'}
        title={'Error'}
        subTitle={
          createMobileTestCaseMutation.isError &&
          createMobileTestCaseMutation.error.message
        }
      >
        <DialogButtonPanels>
          <DialogButton
            variant="secondary"
            type="button"
            onClick={() => setIsErrorModalOpen(false)}
          >
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default CreateMobileTestCase;
