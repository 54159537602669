import { RootState } from '@app/store';
import {
  DeviceInfoOs,
  DistributionTestExecutionTestInfo,
} from '@customTypes/ide/device/device';

import { hasDownloadedData } from '@store/ide/settings/settingsSlice';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PagingHeaderWithTotalCount from '@components/TestExecution/shared/PagingHeaderWithTotalCount';
import DistributedExecutionSettings from './MobileDistributedExecution/DistributedExecutionSettings';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';
import TestResultSaveOptions from '../../TestResultSaveOptions/TestResultSaveOptions';
import { UseMutationResult } from '@tanstack/react-query';
import { useTestExecutionContext } from '@contexts/ide/TestExecutionProvider';
import { getRemoteState } from '@utils/static/getConfiguration';
import { useCheckWdaStatusQuery } from '@lib/api/ide/mobile/mobileDevice';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Props = {
  testType: TestTypeCategory;
  divWidth: number;
  setIsReadyToNavigate: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsWirelessDeviceModal: React.Dispatch<React.SetStateAction<boolean>>;
  setConnectionType: React.Dispatch<React.SetStateAction<'wifi' | 'port'>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  remoteExecutionMutation: UseMutationResult<
    unknown,
    Error,
    DistributionTestExecutionTestInfo,
    unknown
  >;
  setIsExecutionErrorModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeModalExecution: () => void;
};

const MobileDistributedExecution = ({
  testType,
  divWidth,
  setIsReadyToNavigate,
  setIsSettingsOpen,
  setIsWirelessDeviceModal,
  setConnectionType,
  setIsModalOpen,
  remoteExecutionMutation,
  setIsExecutionErrorModalOpen,
  closeModalExecution,
}: Props) => {
  const { projectIdx } = useParams();

  const [isSelectedDevices, setIsSelectedDevices] = useState(false);
  const [isXcodeApp, setIsXcodeApp] = useState(false);
  const [isWdaFile, setIsWdaFile] = useState(false);
  const [needIosSetting, setNeedIosSetting] = useState(false);

  const selectedExecutionList = useSelector(
    (state: RootState) => state.distributionExecution.selectedExecutionList,
  );
  const dispatch = useDispatch();
  const isDownloadedData = useSelector(
    (state: RootState) => state.settings.isDownloadedData,
  );

  const { shouldSaveVideo, shouldSaveScreenshot, shouldSaveLogFile } =
    useTestExecutionContext();
  const data = {
    testInfo: selectedExecutionList,
    saveVideo: shouldSaveVideo,
    saveScreenshot: shouldSaveScreenshot,
    saveLog: shouldSaveLogFile,
  };
  const { remoteConfiguration, standardConfiguration } = getRemoteState();

  const checkWdaStatusQuery = useCheckWdaStatusQuery();
  const remotePCWdaStatusData = checkWdaStatusQuery.data;
  const remotePCWdaStatus = remotePCWdaStatusData?.filter(
    (data) => data.projectIdx === Number(projectIdx),
  )?.[0]?.status;

  const checkCondition = (os: string) => {
    if (standardConfiguration) {
      return os === DeviceInfoOs.Ios && (!isXcodeApp || !isWdaFile);
    }
    return os === DeviceInfoOs.Ios && remotePCWdaStatus === null;
  };

  const executionTestOnClick = () => {
    if (!selectedExecutionList?.length) {
      setIsSelectedDevices(true);
      return;
    }

    if (selectedExecutionList.some(({ device }) => checkCondition(device.os))) {
      setNeedIosSetting(true);
      return;
    }

    setIsSelectedDevices(false);
    if (remoteConfiguration) {
      closeModalExecution();
      remoteExecutionMutation.mutate(data, {
        onSuccess: () => setIsModalOpen(true),
        onError: () => setIsExecutionErrorModalOpen(true),
      });
    } else {
      setIsReadyToNavigate(true);
    }
  };

  useEffect(() => {
    const getXcodeLocation = async () => {
      const xcodeLocation: string | undefined =
        await window.electron.invoke('checkXcodeLocation');
      setIsXcodeApp(xcodeLocation?.toLowerCase().includes('xcode.app'));
    };
    const checkWdaFile = async () => {
      const existWdaFile: boolean = await window.electron.invoke(
        'existWdaFile',
        projectIdx,
      );
      setIsWdaFile(existWdaFile);
    };
    if (standardConfiguration) {
      getXcodeLocation();
      checkWdaFile();

      if (isDownloadedData) {
        getXcodeLocation();
        checkWdaFile();
        dispatch(hasDownloadedData(false));
      }
    }
  }, [isDownloadedData, standardConfiguration]);

  const breadcrumbs = [
    { title: 'UI Test' },
    { title: 'Mobile' },
    {
      title:
        testType === 'case'
          ? 'Test Case'
          : testType === 'scenario' && 'Test Scenario',
    },
    { title: 'Distributed Execution' },
  ];

  const pageHeadingButtons = [
    { title: 'Execution test', onClick: executionTestOnClick },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeaderWithTotalCount
        breadcrumbs={breadcrumbs}
        pageTitle={'Distributed Execution'}
        selectedDeviceLength={selectedExecutionList?.length}
        pageHeadingButtons={pageHeadingButtons}
      />
      <TestResultSaveOptions>
        <TestResultSaveOptions.Video />
        <TestResultSaveOptions.Screenshot />
        <TestResultSaveOptions.LogFile />
      </TestResultSaveOptions>
      <DistributedExecutionSettings
        testType={testType}
        divWidth={divWidth}
        isSelectedDevices={isSelectedDevices}
        needIosSetting={needIosSetting}
        setNeedIosSetting={setNeedIosSetting}
        setIsSettingsOpen={setIsSettingsOpen}
        setIsWirelessDeviceModal={setIsWirelessDeviceModal}
        setConnectionType={setConnectionType}
      />
    </ErrorBoundaryWrapper>
  );
};

export default MobileDistributedExecution;
