import { ErrorMessage, PagingHeadingsInAutosquare } from '@autosquare/common';

import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useOverviewQuery } from '@lib/api/ide/overview/overview';
import { useProjectDetailQuery } from '@lib/api/ide/project/project';

import OverviewSkeleton from './components/OverviewSkeleton';
import {
  ProjectListDetailFeaturesCategory,
  ProjectListDetailFeaturesFeature,
} from '@customTypes/dashboard/project/type';
import MobileTestResults from '@components/Overview/MobileUITestResults';
import WebTestResults from '@components/Overview/WebUITestResults';
import { checkIsProjectFeatureIncluded } from '@utils/static/checkProjectFeature';
import MobileSpeedTestOverviewList from '@components/Overview/MobileSpeedTestOverviewList';
import WebSpeedTestOverviewList from '@components/Overview/WebSpeedTestOverviewList';
import { useGetIdeSpeedMobileTestResult } from '@lib/api/ide/speedMobile/speedMobileTestResult';
import { useIdeWebSpeedTestResultListQuery } from '@lib/api/ide/speedWeb/webSpeedTestResult';

const IdeOverview = () => {
  const { pathname, search } = useLocation();
  const { projectIdx } = useParams();

  const overviewQuery = useOverviewQuery(projectIdx);
  const overview = overviewQuery.data;

  const projectDetailQuery = useProjectDetailQuery();
  const projectDetail = projectDetailQuery.data;

  const PAGE_LIMIT = 5;
  const PAGE = 1;
  const speedMobileTestResultQuery = useGetIdeSpeedMobileTestResult(
    PAGE,
    PAGE_LIMIT,
  );
  const speedMobileTestResultData = speedMobileTestResultQuery.data;

  const speedWebTestResultQuery = useIdeWebSpeedTestResultListQuery(PAGE);
  const speedWebTestResultData = speedWebTestResultQuery.data;

  return (
    <>
      <PagingHeadingsInAutosquare
        breadcrumbs={[
          { title: 'Dashboard', href: pathname + search },
          { title: 'Overview', href: pathname + search },
        ]}
        pageTitle={'Overview'}
      />
      {overviewQuery.isLoading ||
      overviewQuery.isFetching ||
      speedMobileTestResultQuery.isLoading ||
      speedMobileTestResultQuery.isFetching ||
      speedWebTestResultQuery.isLoading ||
      speedWebTestResultQuery.isFetching ||
      projectDetailQuery.isLoading ||
      projectDetailQuery.isFetching ? (
        <OverviewSkeleton />
      ) : overviewQuery.isError ? (
        <ErrorMessage>{overviewQuery.error.message}</ErrorMessage>
      ) : speedMobileTestResultQuery.isError ? (
        <ErrorMessage>{speedMobileTestResultQuery.error.message}</ErrorMessage>
      ) : speedWebTestResultQuery.isError ? (
        <ErrorMessage>{speedWebTestResultQuery.error.message}</ErrorMessage>
      ) : projectDetailQuery.isError ? (
        <ErrorMessage>{projectDetailQuery.error.message}</ErrorMessage>
      ) : (
        <>
          {checkIsProjectFeatureIncluded(
            projectDetail,
            ProjectListDetailFeaturesCategory.Mobile,
            ProjectListDetailFeaturesFeature.Functional,
          ) && (
            <MobileTestResults
              tableHeaders={tableHeaders}
              mobileResults={overview?.mobileResults}
            />
          )}
          {checkIsProjectFeatureIncluded(
            projectDetail,
            ProjectListDetailFeaturesCategory.Web,
            ProjectListDetailFeaturesFeature.Functional,
          ) && (
            <WebTestResults
              tableHeaders={tableHeaders}
              webResults={overview?.webResults}
            />
          )}
          {checkIsProjectFeatureIncluded(
            projectDetail,
            ProjectListDetailFeaturesCategory.Mobile,
            ProjectListDetailFeaturesFeature.Speed,
          ) && (
            <MobileSpeedTestOverviewList
              resultData={speedMobileTestResultData}
            />
          )}
          {checkIsProjectFeatureIncluded(
            projectDetail,
            ProjectListDetailFeaturesCategory.Web,
            ProjectListDetailFeaturesFeature.Speed,
          ) && <WebSpeedTestOverviewList resultData={speedWebTestResultData} />}
        </>
      )}
    </>
  );
};

export default IdeOverview;

const tableHeaders = ['No', 'Title', 'DateTime', 'Result', 'Detail'];
