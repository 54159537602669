import {
  ComboboxHeadlessInController,
  ErrorBoundaryWrapper,
} from '@autosquare/common';

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';

import { RootState } from '@app/store';

import {
  useMobileAppListQuery,
  useRemoteMobileAppListQuery,
} from '@lib/api/ide/mobile/mobileDevice';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ErrorMessage } from '@hookform/error-message';
import { getRemoteState } from '@utils/static/getConfiguration';

const SearchTestStepValue = () => {
  const deviceInfo = useSelector((state: RootState) => state.device.deviceInfo);

  const { standardConfiguration } = getRemoteState();
  const mobileAppListQuery = standardConfiguration
    ? useMobileAppListQuery()
    : useRemoteMobileAppListQuery();
  const appLists = mobileAppListQuery.data;

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CreateTestStepData>();

  const value = watch('value');

  useEffect(() => {
    setValue('value', '');
    setValue('optionsDto.elementOptions', undefined);
    return () => setValue('value', undefined);
  }, [setValue]);

  const filteredAppLists = appLists?.filter((appList) =>
    appList
      .toLocaleLowerCase()
      .includes(value ? value.toLocaleLowerCase() : ''),
  );

  return (
    <ErrorBoundaryWrapper>
      <Controller
        control={control}
        name="value"
        rules={{ required: 'App을 선택해주세요.' }}
        render={({ field }) => {
          return (
            <ComboboxHeadlessInController
              value={field.value || ''}
              onChange={field.onChange}
              filteredList={filteredAppLists}
              placeholder={
                deviceInfo.os === DeviceInfoOs.Aos
                  ? 'Search Package Name'
                  : 'Search Bundle Identifier'
              }
              reset={() => {
                setValue('value', '');
                mobileAppListQuery.refetch();
              }}
              isError={mobileAppListQuery.isError}
              error={mobileAppListQuery.error}
            />
          );
        }}
      />
      <ErrorMessage
        errors={errors}
        name="value"
        render={({ message }) => (
          <p className="error-message -mt-2">{message}</p>
        )}
      />
    </ErrorBoundaryWrapper>
  );
};

export default SearchTestStepValue;
