import { OverviewFunctionalResultType } from '@autosquare/common';
import { PriorityNameEnum } from '@customTypes/testCase/type';
import {
  MobileTestResultProperties,
  TestResultLabel,
  WebResult,
} from '@customTypes/testResult/type';
export interface MobileOverview {
  functionalResults: OverviewFunctionalResultType[];
  speedResults: MobileSpeedTestResultsInOverviewType;
}

export interface WebOverview {
  functionalResults: OverviewFunctionalResultType[];
  speedResults: WebSpeedTestResultsInOverviewType;
}

export interface OverviewResults {
  mobileResults: MobileTestResultProperties[];
  webResults: WebResult[];
}

export type WebSpeedTestResultsInOverviewType = {
  date: string;
  header: string[];
  data: (string | number[])[][];
};

export type MobileSpeedTestResultsInOverviewType = {
  date: string;
  header: string[];
  data: (string | number)[][];
};

export type TestResultLabelList =
  | TestResultLabel.Pass
  | TestResultLabel.Fail
  | TestResultLabel.NA;

export type ResultRate = {
  labels: TestResultLabelList[];
  percent: number[];
};

export type EnvRate = {
  devices: string[];
  percent: number[];
};

export type SpeedEnvRate = {
  mobileApp: EnvRate;
  mobileWeb: EnvRate;
  platformPercent: number[];
  platforms: string[];
};

export type BreakdownType = 'Scheduler' | 'Test Scenario' | 'Test Case';

export enum BreakdownTypeEnum {
  Scheduler = 'Scheduler',
  Scenario = 'Test Scenario',
  Case = 'Test Case',
}

export type BreakdownPriority = {
  priority: PriorityNameEnum;
  count: number;
};
export type BreakdownGroup = {
  type: BreakdownType;
  totalCount: number;
  activateCount?: number;
  priorities?: BreakdownPriority[];
};

export type ResultReports = {
  resultRate: ResultRate;
  envRate: EnvRate & SpeedEnvRate;
  breakdownGroup: BreakdownGroup[];
};

export type OverviewResultRateReport = {
  mobileResultReports: ResultReports;
  webResultReports: ResultReports;
};

export type MergedBreakdownData = {
  type: string;
  total: number;
  count: {
    mobile: number;
    web: number;
  };
  activate?: {
    mobile: number;
    web: number;
  };
  deactivate?: {
    mobile: number;
    web: number;
  };
  priority?: {
    mobile: BreakdownPriority[];
    web: BreakdownPriority[];
  };
};
