import React from 'react';
import { TooltipTop } from '@autosquare/common';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

type Props = {
  position: string;
};

const AutoRegisterTitle = ({ position }: Props) => {
  return (
    <div className="flex items-center gap-x-3">
      <div className="text-sm font-medium leading-normal text-gray-900">
        AutoRegister
      </div>
      <div className="group relative">
        <QuestionMarkCircleIcon
          className="size-5 cursor-help text-red-500"
          strokeWidth={1.75}
        />
        <TooltipTop
          positionClass={clsx('invisible group-hover:visible', position)}
        >
          테스트 결과를 자동 등록할 플랫폼을 선택해 주세요.
        </TooltipTop>
      </div>
    </div>
  );
};

export default AutoRegisterTitle;
