import { useNumberInput } from '@autosquare/common';

import PointOrRectangleInput from '@components/shared/PointOrRectangleInput';

import { CreateWebTestStepData } from '@customTypes/testStep/type';

import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const ScrollAndSwipe = () => {
  const { setValue } = useFormContext<CreateWebTestStepData>();

  const [width, setWidth] = useState<number>(null);
  const [height, setHeight] = useState<number>(null);
  const [startX, onChangeStartX, setStartX] = useNumberInput<number>(null);
  const [startY, onChangeStartY, setStartY] = useNumberInput<number>(null);
  const [endX, onChangeEndX, setEndX] = useNumberInput<number>(null);
  const [endY, onChangeEndY, setEndY] = useNumberInput<number>(null);

  useEffect(() => {
    setValue('value', `${startX},${startY},${endX},${endY}`);

    window.electron.send('inputCircleCoordinate', {
      startX: startX,
      startY: startY,
      endX: endX,
      endY: endY,
    });
  }, [startX, startY, endX, endY, setValue]);

  useEffect(() => {
    window.electron.on<{ x: number; y: number }>(
      'webStartCircleCoordinate',
      (message) => {
        setStartX(message.x);
        setStartY(message.y);
      },
    );
    window.electron.on<{ x: number; y: number }>(
      'webEndCircleCoordinate',
      (message) => {
        setEndX(message.x);
        setEndY(message.y);
      },
    );
    window.electron.on<{ width: number; height: number }>(
      'screenSize',
      (message) => {
        setWidth(message.width);
        setHeight(message.height);
      },
    );
  }, []);

  useEffect(() => {
    return () => {
      setValue('value', '');
    };
  }, [setValue]);

  const scrollSwipeProperties = [
    {
      labelTitle: 'startX',
      maxSize: width,
      value: startX,
      onChange: onChangeStartX,
    },
    {
      labelTitle: 'startY',
      maxSize: height,
      value: startY,
      onChange: onChangeStartY,
    },
    {
      labelTitle: 'endX',
      maxSize: width,
      value: endX,
      onChange: onChangeEndX,
    },
    {
      labelTitle: 'endY',
      maxSize: height,
      value: endY,
      onChange: onChangeEndY,
    },
  ];

  return (
    <PointOrRectangleInput
      imageWidth={width}
      imageHeight={height}
      properties={scrollSwipeProperties}
      isHiddenInputArrow={true}
    />
  );
};

export default ScrollAndSwipe;
