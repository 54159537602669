import {
  TestDataAutomationInfo,
  UpdateTestDataAutomation,
  UpdateTestDataAutomationSchema,
} from '@customTypes/testCase/type';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

export const useUpdateTestDataAutomationFrom = (
  testAutomationData: TestDataAutomationInfo,
) => {
  const methods = useForm<UpdateTestDataAutomation>({
    defaultValues: {
      automationIdx: testAutomationData.idx,
      title: testAutomationData.title,
      description: testAutomationData.description,
      autoOrder: testAutomationData.autoOrder,
      projectIdx: testAutomationData.projectIdx,
      caseIdx: testAutomationData.caseIdx,
      file: new File([], testAutomationData.table.fileName, {
        type: 'text/csv',
      }),
    },
    resolver: zodResolver(UpdateTestDataAutomationSchema),
  });

  return methods;
};
