import { Button } from '@autosquare/common';
import { InstallAppRequest } from '@customTypes/index';
import { useParentPath } from '@hooks/useParentPath';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const InstallButtonSection = () => {
  const navigate = useNavigate();

  const deviceListPath = useParentPath();

  const { watch } = useFormContext<InstallAppRequest>();

  const osType = watch('osType');
  const packageName = watch('packageName');
  const appVersion = watch('appVersion');
  const buildVersion = watch('buildVersion');
  const deviceIdxList = watch('deviceIdxList');

  const isDisabled =
    !osType ||
    !packageName ||
    !appVersion ||
    !buildVersion ||
    deviceIdxList.length === 0;

  return (
    <div className="mt-6 flex justify-end gap-3 border-t border-gray-200 pt-6">
      <Button
        type="button"
        variant="secondary"
        onClick={() => navigate(deviceListPath)}
      >
        Cancel
      </Button>
      <Button type="submit" disabled={isDisabled}>
        Install
      </Button>
    </div>
  );
};

export default InstallButtonSection;
