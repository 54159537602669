import { ErrorBoundaryWrapper } from '@autosquare/common';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import LoopAction from './LoopComponent/LoopAction';
import LoopCondition from './LoopComponent/LoopCondition';

const LoopComponent = () => {
  const { setValue } = useFormContext<CreateTestStepData>();

  useEffect(() => {
    setValue('required', true);
  }, [setValue]);

  return (
    <ErrorBoundaryWrapper>
      <LoopCondition />
      <LoopAction />
    </ErrorBoundaryWrapper>
  );
};

export default LoopComponent;
