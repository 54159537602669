import { IDEAPI } from '@api/ideEndpoints';
import {
  CreateTestDataAutomation,
  TestDataAutomationInfo,
  UpdateTestDataAutomation,
} from '@customTypes/testCase/type';
import { axiosInstance, axiosLocalInstance } from '..';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { webTestCaseKeys } from '@queries/ide/web/webTestCaseKeys';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { webTestStepKeys } from '@queries/ide/web/webTestStepKeys';
import { useParentPath } from '@hooks/index';
import { enhanceAutomationDataWithIdx } from '@utils/index';

//GET
// Web Test Data Automation 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/(New)%20Web%20UI%20Test%20Data%20Automation/getWebAutomationData
const getWebTestCaseDataAutomation = async (
  caseIdx: string,
): Promise<TestDataAutomationInfo> => {
  const response = await axiosInstance.get<TestDataAutomationInfo>(
    `${IDEAPI.WEB_DATA_AUTOMATION}/case/${caseIdx}`,
  );
  return response.data;
};

export const useGetWebTestCaseDataAutomation = (
  options?: UseQueryOptions<
    TestDataAutomationInfo,
    Error,
    TestDataAutomationInfo
  >,
) => {
  const [searchParam] = useSearchParams();
  const caseIdx = searchParam.get('idx');
  return useQuery<TestDataAutomationInfo, Error, TestDataAutomationInfo>({
    queryFn: () => getWebTestCaseDataAutomation(caseIdx),
    queryKey: webTestCaseKeys.automation(caseIdx),
    ...options,
  });
};

//POST
// 인코딩하여 CSV 파일 저장
// http://localhost:8081/swagger-ui/index.html#/Mobile%20Test%20Data%20Automation/saveMobileUiData_1
const createWebTestDataAutomation = async (data: CreateTestDataAutomation) => {
  const response = await axiosLocalInstance.post<TestDataAutomationInfo>(
    `${IDEAPI.LOCAL_AUTOMATION}/web`,
    data,
  );
  return response.data;
};

export const useCreateWebTestDataAutomationMutation = () => {
  const { projectIdx } = useParams();
  const [searchParam] = useSearchParams();
  const caseIdx = searchParam.get('idx');
  const { search } = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const webTestCaseDetailPage = useParentPath();

  return useMutation<
    TestDataAutomationInfo,
    ServerResponseErrorDetails,
    CreateTestDataAutomation
  >({
    mutationFn: (data) =>
      createWebTestDataAutomation(
        enhanceAutomationDataWithIdx(data, caseIdx, projectIdx),
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(webTestCaseKeys.all);
      queryClient.invalidateQueries(webTestStepKeys.all);
      navigate({ pathname: webTestCaseDetailPage, search: search });
    },
  });
};

//PUT
// 인코딩하여 CSV 파일 업데이트
// http://localhost:8081/swagger-ui/index.html#/Mobile%20Test%20Data%20Automation/updateMobileUiData
const updateWebTestAutomation = async (data: UpdateTestDataAutomation) => {
  const { automationIdx, ...restData } = data;
  const response = await axiosLocalInstance.put(
    `${IDEAPI.LOCAL_AUTOMATION}/web/update/${automationIdx}`,
    restData,
  );
  return response.data;
};

export const useUpdateWebTestAutomationMutation = () => {
  const { search } = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const webTestCaseDetailPage = useParentPath();

  return useMutation<
    TestDataAutomationInfo,
    ServerResponseErrorDetails,
    UpdateTestDataAutomation
  >({
    mutationFn: (data) => updateWebTestAutomation(data),
    onSuccess: () => {
      queryClient.invalidateQueries(webTestCaseKeys.all);
      queryClient.invalidateQueries(webTestStepKeys.all);
      navigate({ pathname: webTestCaseDetailPage, search: search });
    },
  });
};

//DELETE
// Web Test Data Automation 삭제
// https://dev.t-square.co.kr/swagger-ui/index.html#/(New)%20Web%20UI%20Test%20Data%20Automation/deleteWebAutomationData
const deleteTestDataAutomation = async (
  dataAutomationIdx: number,
): Promise<string> => {
  const response = await axiosInstance.delete(
    `${IDEAPI.WEB_DATA_AUTOMATION}/delete/${dataAutomationIdx}`,
  );
  return response.data;
};

//idx는 caseIdx나 projectIdx가 아닌 서버에서 보내주는 Idx임
export const useDeleteWebTestDataAutomationMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { search } = useLocation();

  const webTestCaseDetailPage = useParentPath();

  return useMutation<string, ServerResponseErrorDetails, number>({
    mutationFn: (dataAutomationIdx) =>
      deleteTestDataAutomation(dataAutomationIdx),
    onSuccess: () => {
      queryClient.invalidateQueries(webTestCaseKeys.all);
      queryClient.invalidateQueries(webTestStepKeys.all);
      navigate({ pathname: webTestCaseDetailPage, search: search });
    },
  });
};
