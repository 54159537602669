import { Spinner } from '@autosquare/common';
import {
  CommonDialog,
  DialogButton,
  DialogButtonPanels,
} from '@components/Dialog';
import { useUserInfoByDecodeKeyQuery } from '@lib/api/dashboard/queryHooks';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SignUpForm from './SignUpFormContainer/SignUpForm';

const SignUpFormContainer = () => {
  const [searchParams] = useSearchParams();
  const decodeKey = searchParams.get('key');

  const navigate = useNavigate();

  const [isErrorDialog, setIsErrorDialog] = useState(false);

  // decodeKey 값으로 이메일 주소 확인
  const { isLoading, isError } = useUserInfoByDecodeKeyQuery(decodeKey);

  const onCloseDialog = () => {
    setIsErrorDialog(false);
    navigate('/login');
  };

  useEffect(() => {
    if (isError) {
      setIsErrorDialog(true);
    }
  }, [isError]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return (
      <CommonDialog
        isOpen={isErrorDialog}
        setIsOpen={setIsErrorDialog}
        iconType="caution"
        title="Failure"
        subTitle="가입이 불가능한 URL입니다. 새로운 초대 링크를 생성해 주세요."
        onClose={onCloseDialog}
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant="secondary"
            onClick={onCloseDialog}
          >
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
    );
  }

  return <SignUpForm />;
};

export default SignUpFormContainer;
