import { ErrorBoundaryWrapper } from '@autosquare/common';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { RectOrTwoPointsCoordinate } from '@customTypes/testStep/type';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Rect } from 'react-konva';

interface Props {
  coordinate: RectOrTwoPointsCoordinate;
}

const SelectedObject = ({ coordinate }: Props) => {
  const { watch } = useFormContext<CreateTestStepData>();

  const value = watch('value');
  const conditionCommand = watch('optionsDto.conditionCommand');

  const isVisible =
    conditionCommand === MobileCommandOptionServerName.CompareImage
      ? value !== 'undefined'
      : true;

  return (
    <ErrorBoundaryWrapper>
      <Rect
        x={Math.floor(coordinate?.x1)}
        y={Math.floor(coordinate?.y1)}
        width={Math.floor(Math.abs(coordinate?.x2 - coordinate?.x1))}
        height={Math.floor(Math.abs(coordinate?.y2 - coordinate?.y1))}
        fill="blue"
        opacity={0.3}
        visible={isVisible}
        listening={isVisible}
      />
    </ErrorBoundaryWrapper>
  );
};

export default SelectedObject;
