import {
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorMessage,
  GroupContentSkeleton,
} from '@autosquare/common';

import { useGroupContext } from '@contexts/ide/GroupProvider';

import {
  useDeleteCaseGroupMutation,
  useTestCaseGroupListQuery,
} from '@lib/api/ide/testGroup/testCaseGroup';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import GroupContentCard from './GroupModalContent/GroupContentCard';
import GroupCreateFolderButton from './GroupModalContent/GroupCreateFolderButton';

import EditGroup from '../EditGroup';
import {
  useDeleteTestCaseServiceGroupMutation,
  useGetTestCaseServiceGroupListQuery,
} from '@lib/api/ide/serviceGroup/serviceGroup';
import GroupControl from './GroupControl/GroupControl';
import CreateNewGroupDialog from '../CreateNewGroupDialog';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/store';

import { openCreateGroup } from '@store/ide/speedTestCase/CreateGroupSlice';

const GroupModalContent = () => {
  const isOpenedCreateGroup = useSelector(
    (state: RootState) => state.createGroup.isOpen,
  );

  const dispatch = useDispatch();

  const [openCaution, setOpenCaution] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [groupIdx, setGroupIdx] = useState(null);

  const { isEditModalOpened, setIsEditModalOpened, tabGroupType } =
    useGroupContext();
  const { projectIdx } = useParams();
  const testCaseGroupList = useTestCaseGroupListQuery({
    enabled: tabGroupType === 'Test',
  });
  const deleteTestGroupMutation = useDeleteCaseGroupMutation(projectIdx);

  const serviceGroupList = useGetTestCaseServiceGroupListQuery({
    enabled: tabGroupType === 'Service',
  });

  const deleteServiceGroupMutation =
    useDeleteTestCaseServiceGroupMutation(projectIdx);

  const groupListQuery =
    tabGroupType === 'Test' ? testCaseGroupList : serviceGroupList;

  const deleteGroupMutation =
    tabGroupType === 'Test'
      ? deleteTestGroupMutation
      : deleteServiceGroupMutation;

  const groupType = tabGroupType === 'Test' ? '테스트' : '서비스';

  // Modal 이 종료되는 시간과 reset이 충돌하는 시간이 나타나 setTimeout 설정
  useEffect(() => {
    if (!openError) {
      setTimeout(() => {
        deleteGroupMutation.reset();
      }, 500);
    }
  }, [openError]);

  return (
    <>
      {groupListQuery.isLoading ? (
        <div className="bg-white p-2">
          <div className="max-h-96 px-4">
            <ul className="relative grid grid-cols-1 gap-x-6 gap-y-8 px-4 lg:grid-cols-3 xl:gap-x-8">
              <GroupContentSkeleton />
            </ul>
          </div>
        </div>
      ) : groupListQuery.isError ? (
        <ErrorMessage>{groupListQuery.error.message}</ErrorMessage>
      ) : groupListQuery.data.length === 0 ? (
        <>
          <GroupControl
            setOpenCreateGroup={() => dispatch(openCreateGroup())}
          />
          <GroupCreateFolderButton />
        </>
      ) : (
        <div className="bg-white p-2">
          <GroupControl
            setOpenCreateGroup={() => dispatch(openCreateGroup())}
          />
          <div className="gray-scrollbar max-h-96 px-4">
            <ul className="relative grid grid-cols-1 gap-x-6 gap-y-8 px-4 lg:grid-cols-3 xl:gap-x-8">
              {[...groupListQuery.data]
                ?.sort((a, b) => b.idx - a.idx)
                .map((item) => (
                  <GroupContentCard
                    key={item.idx}
                    item={item}
                    setOpenCaution={setOpenCaution}
                    setGroupIdx={setGroupIdx}
                  />
                ))}
            </ul>
          </div>
        </div>
      )}
      <CreateNewGroupDialog
        isOpen={isOpenedCreateGroup}
        setIsOpen={() => dispatch(openCreateGroup())}
      />
      <EditGroup
        isOpen={isEditModalOpened}
        setIsOpen={setIsEditModalOpened}
        groupIdx={groupIdx}
        groupData={groupListQuery.data}
      />
      <DoubleCheckDialogHeadlessUI
        isOpened={openCaution}
        setIsOpened={setOpenCaution}
        type="caution"
        title={`Delete ${tabGroupType} Group`}
        subTitle={`해당 ${groupType} 그룹을 삭제하시겠습니까?\n그룹을 삭제해도 연결된 케이스는 삭제되지 않습니다.`}
        buttonChildren={
          <>
            <Button
              type="button"
              variant="caution"
              onClick={() => {
                tabGroupType === 'Test'
                  ? deleteTestGroupMutation.mutate(
                      { caseGroupIdx: groupIdx },
                      {
                        onSuccess: () => {
                          setOpenCaution(false);
                        },
                        onError: () => {
                          setOpenCaution(false);
                          setOpenError(true);
                        },
                      },
                    )
                  : deleteServiceGroupMutation.mutate(
                      { serviceGroupIdx: groupIdx },
                      {
                        onSuccess: () => {
                          setOpenCaution(false);
                        },
                        onError: () => {
                          setOpenCaution(false);
                          setOpenError(true);
                        },
                      },
                    );
              }}
            >
              Delete
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={() => setOpenCaution(false)}
            >
              Cancel
            </Button>
          </>
        }
      />
      <DoubleCheckDialogHeadlessUI
        isOpened={openError}
        setIsOpened={setOpenError}
        type="caution"
        title={'Error'}
        subTitle={
          deleteGroupMutation.isError && deleteGroupMutation.error.message
        }
        buttonChildren={
          <Button
            type="button"
            variant="caution"
            onClick={() => {
              setOpenError(false);
            }}
          >
            OK
          </Button>
        }
      />
    </>
  );
};

export default GroupModalContent;
