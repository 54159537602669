import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Disclosure } from '@headlessui/react';
import dayjs from 'dayjs';

import { RootState } from '@app/store';

import BasicNav from './components/BasicNav';
import MobileViewNav from './components/MobileViewNav';
import { useUserInfoQuery } from '@lib/api/dashboard/user/users';
import { useSendAccessLogMutation } from '@lib/api/dashboard/accessLog/accessLog';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const Nav = () => {
  const { pathname } = useLocation();
  const { projectIdx } = useParams();

  // scroll to top when route changes
  const noScrollPaths = [];

  useEffect(() => {
    if (!noScrollPaths.includes(pathname)) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  const userInfo = useSelector((state: RootState) => state.userInfo.userInfo);
  const accessToken = useSelector(
    (state: RootState) => state.accessTokenValue.accessTokenValue,
  );

  // check if valid access token
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data } = useUserInfoQuery();

  // save access log api
  const sendAccessLogMutation = useSendAccessLogMutation();

  useEffect(() => {
    sendAccessLogMutation.mutate({
      accessTime: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      url: window.location.href,
      details:
        projectIdx === undefined
          ? []
          : [{ key: 'projectIdx', value: projectIdx }],
    });
  }, [window.location.href]);

  return (
    <ErrorBoundaryWrapper>
      <Disclosure as="nav" className="fixed z-40 w-full bg-white shadow">
        <ErrorBoundaryWrapper>
          <BasicNav userInfo={userInfo} accessToken={accessToken} />
          <MobileViewNav userInfo={userInfo} accessToken={accessToken} />
        </ErrorBoundaryWrapper>
      </Disclosure>
    </ErrorBoundaryWrapper>
  );
};

export default Nav;
