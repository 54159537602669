import { ErrorBoundaryWrapper } from '@autosquare/common';
import { RunningCycle } from '@customTypes/ide/scheduler/scheduler';
import { CreateSpeedSchedulerData } from '@customTypes/ide/speedScheduler/speedScheduler';
import { createCronExpression } from '@utils/static/createCronExpression';
import { parseCronCycle } from '@utils/static/parseCronCycle';
import { formatSelectedDays } from '@utils/static/selectedDays';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  selectedCycle: RunningCycle;
  selectedDays: string[];
};

const CreateSpeedSchedulerPreview = ({
  selectedCycle,
  selectedDays,
}: Props) => {
  const { watch, setValue } = useFormContext<CreateSpeedSchedulerData>();

  const startTime = watch('startTime');
  const cronCycle = watch('cronCycle');

  const finalCron = createCronExpression(
    startTime,
    selectedDays,
    selectedCycle,
  );

  useEffect(() => {
    setValue('cronCycle', finalCron);
  }, [setValue, finalCron]);

  const formattedDays = formatSelectedDays(selectedDays);
  const alert = formattedDays.length;
  const runPart = parseCronCycle(finalCron);

  return (
    <ErrorBoundaryWrapper>
      <div className="mt-2 sm:col-span-5 sm:mt-0">
        <label
          htmlFor="preview"
          className="mb-2 block text-sm font-medium leading-6 text-gray-900"
        >
          Preview
        </label>
        <div className="group relative rounded-l-lg border p-3 text-sm">
          <div>
            <span className="mr-2">After:</span>
            <span className="mr-2">
              {dayjs(startTime).format('YYYY-MM-DD HH:mm')}
            </span>
          </div>
          <div>
            <span className="mr-2">On:</span>
            <span
              className={clsx(
                'mr-2',
                alert === 43 && 'font-semibold text-red-500',
              )}
            >
              {formattedDays}
            </span>
          </div>
          <div>
            <span className="mr-2">Run:</span>
            <span>{runPart.timeInterval}</span>
          </div>
          <div className="absolute bottom-20 right-5 mb-2 hidden min-w-max rounded bg-black px-2.5 py-1 text-xs text-white opacity-80 group-hover:block">
            {cronCycle}
          </div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default CreateSpeedSchedulerPreview;
