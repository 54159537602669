import { Button, DoubleCheckDialogHeadlessUI } from '@autosquare/common';
import { useGetRemoteErrorHandler } from '@hooks/remote/useGetRemoteErrorHandler';
import { closeErrorModal } from '@store/ide/remote/remoteConnectionStatusSlice';
import React from 'react';
import { useDispatch } from 'react-redux';

const RemoteConnectionAlarmModal = () => {
  const dispatch = useDispatch();

  const {
    onModalCloseHandler,
    isRemoteConnectionError,
    remoteConnectionErrorMessage,
  } = useGetRemoteErrorHandler();
  return (
    <DoubleCheckDialogHeadlessUI
      isOpened={isRemoteConnectionError}
      setIsOpened={() => dispatch(closeErrorModal())}
      type={'caution'}
      title={isRemoteConnectionError && 'Remote Disconnected'}
      subTitle={isRemoteConnectionError && remoteConnectionErrorMessage}
      buttonChildren={
        <Button type="button" variant="primary" onClick={onModalCloseHandler}>
          OK
        </Button>
      }
    />
  );
};

export default RemoteConnectionAlarmModal;
