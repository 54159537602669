import {
  CompareBy,
  CompareRange,
  copyMobileTestStepSchema,
  MaskingOptionsType,
  MaskingOptionsValueListObject,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ClickId } from '@utils/static/clickOptions';

import { WebCommandOptionServerName } from '@utils/static/webCommandOptions';
import { z } from 'zod';
import { OperatorList, ServerFormatDataType } from '@customTypes/index';

export interface WebMaskingOptionsDto {
  enabled: boolean;
  type: MaskingOptionsType;
  valueList: string[] | MaskingOptionsValueListObject[] | undefined;
}

interface BaseWebTestStepData {
  command: WebCommandOptionServerName;
  value: string;
  description: string;
  required: boolean;
  optionsDto: WebTestStepOptionsDto;
}

export interface CreateWebTestStepData extends BaseWebTestStepData {
  caseIdx: number;
}

export interface WebTestStepOptionsDto {
  optionType: string;
  waitingTime: string;
  clickBy: ClickId | undefined;
  compareBy: CompareBy;
  compareText: string | undefined;
  compareIdx: string | undefined;
  compareRange: CompareRange;
  loop: number | undefined;
  cropCoordinate?: string;
  screenSize?: string;
  targetElement?: string;
  conditionCommand: WebCommandOptionServerName;
  conditionValue: string;
  passActionValue: string;
  failActionValue: string;
  stepIdxList: string;
  dataType: ServerFormatDataType;
  operatorList: OperatorList[];
  secret: 'true' | 'false';
  textEncrypt: 'true' | 'false';
  maskingOptions: WebMaskingOptionsDto;
  autoColumn: string;
  targetData: string;
}

export interface WebTestStepData extends BaseWebTestStepData {
  idx: number;
}

export interface UpdateWebTestStepData extends BaseWebTestStepData {
  caseIdx: number;
  startX?: number;
  startY?: number;
  endX?: number;
  endY?: number;
}

export type WebTestStepDataKeys =
  | keyof WebTestStepData
  | `optionsDto.${keyof WebTestStepOptionsDto}`
  | `optionsDto.maskingOptions.${keyof WebMaskingOptionsDto}`;

export type UpdateWebTestStepDataKeys =
  | keyof UpdateWebTestStepData
  | `optionsDto.${keyof WebTestStepOptionsDto}`
  | `optionsDto.maskingOptions.${keyof WebMaskingOptionsDto}`;

export interface ActionOptions {
  repeatLimit: string;
}

export interface StepOptionsData {
  createdAt: string;
  modifiedAt: string;
  key: string;
  status: string;
  value: string;
}

export interface StepImageData {
  createdAt: string;
  modifiedAt: string;
  status: string;
  uniqueKey: string;
  value: string;
}

export interface MobileTestStepData {
  createdAt: string;
  modifiedAt: string;
  command: string;
  value: string;
  description: string;
  required: boolean;
  appVersion: string;
  packageName: string;
  creator: string;
  ownerUuid: string;
  uniqueKey: string;
  status: string;
  stepOptions: StepOptionsData[];
  stepImage: StepImageData;
}

export interface RectOrTwoPointsCoordinate {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}

export interface CoordinateData extends RectOrTwoPointsCoordinate {
  idx: number;
  x: number;
  y: number;
  width: number;
}

export interface SourceProperties {
  enabled: boolean;
  height: number;
  index: number;
  label: string | number;
  name: string;
  'relative-xpath': string;
  type: string;
  width: number;
  x: number;
  y: number;
  'resource-id': string;
  'content-desc': string;
  text: string | number;
  value: string;
  bounds: string;
  checkable: boolean;
  checked: boolean;
  class: string;
  clickable: boolean;
  displayed: boolean;
  extras: string;
  focusable: boolean;
  focused: boolean;
  hint: string;
  'long-clickable': boolean;
  package: string;
  password: boolean;
  scrollable: boolean;
  selected: boolean;
  xpath: string;
}

export interface MobileScreenshotPageSource {
  page_source: [RectOrTwoPointsCoordinate[], SourceProperties[]];
  device_info: DeviceInfoSource;
  app_info: AppInfoSource;
}

export interface DeviceInfoSource {
  udid: string;
  model: string;
  screenSize: string;
  override_size: string;
  physical_dpi: string;
  physical_size: string;
  override_dpi: string;
  viewport_size: string;
}

export interface AppInfoSource {
  app_version: string;
  secure_flag: boolean;
  package_name: string;
}

export interface ArrowPoint {
  x: number;
  y: number;
  fill: string;
  width: number;
  height: number;
  draggable: boolean;
}

export interface MobileTestStepCoordinate {
  width: number;
  height: number;
  realScreenRatio: number;
  startPoint: {
    x: number;
    y: number;
  };
  endPoint: {
    x: number;
    y: number;
  };
}

export interface PatternPoints {
  uuid: string;
  pointsCoordinate: { x: number; y: number };
}

export interface CoordinateInputOptions {
  labelTitle: 'X' | 'Y';
  max: number;
  name: keyof Pick<UpdateTestStepData, 'clickYValue' | 'clickXValue'>;
}

export interface ScrollAndSwipeInputOptions {
  labelTitle: 'StartX' | 'StartY' | 'EndX' | 'EndY';
  max: number;
  name: keyof Pick<UpdateTestStepData, 'startX' | 'startY' | 'endX' | 'endY'>;
}

export const copyWebTestStepSchema = copyMobileTestStepSchema;

export type CopyWebTestStep = z.infer<typeof copyWebTestStepSchema>;
