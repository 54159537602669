import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';

import {
  ConnectionType,
  DeviceInfo,
  TestExecutionTestType,
} from '@customTypes/ide/device/device';
import { TestCaseDetailData } from '@customTypes/testCase/type';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMobileTestScenarioQuery } from '@lib/api/ide/mobile/mobileTestScenario';
import {
  ErrorBoundaryWrapper,
  ScenarioListDetailData,
} from '@autosquare/common';
import { useDispatch } from 'react-redux';
import {
  addExecutionItem,
  removeExecutionItem,
} from '@store/ide/device/distributionExecutionSlice';
import TestCombobox from './Device/TestCombobox';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';
import { LiaUsb } from 'react-icons/lia';

type Props = {
  device: DeviceInfo;
  testType: TestTypeCategory;
};

const Device = ({ device, testType }: Props) => {
  const { projectIdx } = useParams();

  const [selectedTestCase, setSelectedTestCase] = useState<
    TestCaseDetailData | ScenarioListDetailData
  >(null);
  const [query, setQuery] = useState('');

  const dispatch = useDispatch();

  const mobileTestCaseListQuery = useMobileTestCaseListQuery({
    enabled: testType === 'case',
  });
  const mobileTestCaseList = mobileTestCaseListQuery.data;

  const mobileTestScenarioQuery = useMobileTestScenarioQuery(projectIdx, {
    enabled: testType === 'scenario',
  });
  const mobileTestScenarioList = mobileTestScenarioQuery.data;

  const filteredTestCaseList =
    query === ''
      ? mobileTestCaseList
      : mobileTestCaseList?.filter((testCase) =>
          testCase.title.toLowerCase().includes(query.toLowerCase()),
        );

  const filteredTestScenarioList =
    query === ''
      ? mobileTestScenarioList
      : mobileTestScenarioList?.filter((testScenario) =>
          testScenario.title.toLowerCase().includes(query.toLowerCase()),
        );

  useEffect(() => {
    if (selectedTestCase) {
      dispatch(removeExecutionItem(device.serialNumber));
      dispatch(
        addExecutionItem({
          testType:
            testType === 'case'
              ? TestExecutionTestType.Unit
              : testType === 'scenario' && TestExecutionTestType.Manual,
          projectIdx: Number(projectIdx),
          idx: selectedTestCase?.idx,
          device: device,
        }),
      );
    }
  }, [selectedTestCase]);

  const reset = (serialNumber: string) => {
    setQuery('');
    dispatch(removeExecutionItem(serialNumber));
    setSelectedTestCase(null);
  };

  return (
    <ErrorBoundaryWrapper>
      <div className="flex flex-col gap-4 rounded-md border border-solid border-gray-200 p-2.5">
        <div className="flex gap-2.5">
          <div className="flex-center">
            {device.connectionType === null ||
            device.connectionType === ConnectionType.USB ? (
              <LiaUsb size="1.5rem" />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.288 15.038a5.25 5.25 0 0 1 7.424 0M5.106 11.856c3.807-3.808 9.98-3.808 13.788 0M1.924 8.674c5.565-5.565 14.587-5.565 20.152 0M12.53 18.22l-.53.53-.53-.53a.75.75 0 0 1 1.06 0Z"
                />
              </svg>
            )}
          </div>
          <div className="flex flex-col gap-1 truncate">
            <p className="text-sm font-normal leading-tight text-black">
              {device.model}
            </p>
            <p className="truncate text-sm font-normal leading-tight text-gray-500">
              {device.serialNumber}
            </p>
          </div>
        </div>
        <div className="flex w-full items-center justify-between gap-2.5">
          <div className="w-full">
            <TestCombobox
              selectedTestCase={selectedTestCase}
              setSelectedTestCase={setSelectedTestCase}
              query={query}
              setQuery={setQuery}
              reset={reset}
              testType={testType}
              filteredTestCaseList={filteredTestCaseList}
              filteredTestScenarioList={filteredTestScenarioList}
              device={device}
            />
          </div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default Device;
