import {
  DownloadButton,
  ErrorBoundaryWrapper,
  ImageSkeletonLoader,
  VideoSkeletonLoader,
} from '@autosquare/common';

import {
  useMobileTestResultImageData,
  useMobileTestResultLogData,
  useMobileTestResultVideoData,
} from '@lib/api/dashboard/mobile/mobileTestResult';

import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { downloadBlob } from '@utils/static/downloadBlob';

import TestResultDetailError from '@components/shared/Error/TestResultDetailError';

import React from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TabPanel, TabPanels } from '@headlessui/react';
import clsx from 'clsx';

interface Props {
  imagePaths: string[];
  videoPath: string;
  logPath: string;
}

const MobileResultDetail = ({ imagePaths, videoPath, logPath }: Props) => {
  const videoData = useMobileTestResultVideoData(videoPath, {
    enabled: !isWordInLocationPathname('ide') && videoPath !== null,
  });
  const videoSrc = videoData?.data;

  const imageData = useMobileTestResultImageData(imagePaths, {
    enabled: !isWordInLocationPathname('ide') && imagePaths[0] !== null,
  });
  const imageSrc = imageData?.data;

  const logData = useMobileTestResultLogData(logPath, {
    enabled: !isWordInLocationPathname('ide') && logPath !== null,
  });
  const logSrc = logData?.data;

  return (
    <ErrorBoundaryWrapper>
      <TabPanels>
        <TabPanel className={clsx('py-2', videoPath === null && 'hidden')}>
          {videoData.isLoading ? (
            <VideoSkeletonLoader />
          ) : videoData.isError ? (
            <TestResultDetailError errorMessage={videoData.error.message} />
          ) : (
            <div className="flex flex-col items-center justify-center">
              {videoPath !== null && (
                <DownloadButton
                  onClick={() =>
                    downloadBlob(videoSrc, videoPath.split('/').at(-1))
                  }
                />
              )}
              <video
                autoPlay
                controls
                className="aspect-video"
                controlsList="nodownload"
              >
                <source src={videoSrc} />
                <track default kind="captions" />
              </video>
            </div>
          )}
        </TabPanel>
        <TabPanel className={clsx('py-2', imagePaths[0] === null && 'hidden')}>
          {imageData.isLoading ? (
            <ImageSkeletonLoader />
          ) : imageData.isError ? (
            <TestResultDetailError errorMessage={imageData.error.message} />
          ) : (
            <Swiper
              slidesPerView={1}
              loop={true}
              navigation={true}
              pagination={{
                clickable: true,
              }}
              modules={[Navigation, Pagination]}
              className="size-full"
            >
              {imageSrc?.map((imagePath) => (
                <SwiperSlide
                  key={imagePath}
                  className="flex justify-center bg-white text-center text-sm"
                >
                  <div className="flex items-center justify-center pb-8">
                    <img
                      src={imagePath}
                      alt="screenshot"
                      className="block aspect-auto w-2/4 object-cover"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </TabPanel>
        <TabPanel className={clsx('py-2', logPath === null && 'hidden')}>
          {logData.isError ? (
            <TestResultDetailError errorMessage={logData.error.message} />
          ) : (
            <div className="mt-4 text-center">
              <h3 className="mt-2 text-sm font-semibold text-gray-900">
                {logData.isLoading ? (
                  <div className="flex w-full animate-pulse items-center justify-center gap-x-1.5 px-2.5 py-1.5">
                    <div className="h-6 w-72 rounded-md bg-gray-200 shadow-sm dark:bg-gray-700" />
                  </div>
                ) : (
                  logPath?.split('/')?.[logPath?.split('/').length - 1]
                )}
              </h3>
              <div className="mt-2">
                {logData.isLoading ? (
                  <div className="mb-3 flex w-full animate-pulse items-center justify-center gap-x-1.5 px-2.5 py-1.5">
                    <div className="size-6 rounded-md bg-gray-200 shadow-sm dark:bg-gray-700" />
                  </div>
                ) : (
                  logPath !== null && (
                    <DownloadButton
                      onClick={() =>
                        downloadBlob(
                          logSrc,
                          logPath?.split('/')?.[logPath?.split('/').length - 1],
                        )
                      }
                    />
                  )
                )}
              </div>
            </div>
          )}
        </TabPanel>
      </TabPanels>
    </ErrorBoundaryWrapper>
  );
};

export default MobileResultDetail;
