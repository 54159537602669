import React, { useEffect, useState } from 'react';
import { getMonth, getYear } from 'date-fns';
import clsx from 'clsx';
import { calendarMonths, generateYearRange } from '@utils/static/calendar';
import CalenderYearMonthListbox from '@components/shared/HeadlessUI/Listbox/CalenderYearMonthListbox';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Props = {
  date: Date;
  changeYear: (year: number) => void;
  changeMonth: (month: number) => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  isScheduler?: boolean;
};

const CalendarCustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  isScheduler = false,
}: Props) => {
  const calendarYears = generateYearRange(
    isScheduler ? getYear(new Date()) : 2023,
    isScheduler ? getYear(new Date()) + 2 : getYear(new Date()) + 1,
    1,
  );

  const prevButtonDisabled = isScheduler
    ? false
    : date.getFullYear() === 2023 && date.getMonth() + 1 === 1;

  const nextButtonDisabled = isScheduler
    ? date.getFullYear() === getYear(new Date()) + 1 &&
      date.getMonth() + 1 === 12
    : false;

  const prevButtonEnabled = !prevButtonDisabled && !prevMonthButtonDisabled;
  const nextButtonEnabled = !nextButtonDisabled && !nextMonthButtonDisabled;
  const [selectedYear, setSelectedYear] = useState(getYear(date));
  const [selectedMonth, setSelectedMonth] = useState(
    calendarMonths[getMonth(date)],
  );

  const handleYearChange = (value: number) => {
    setSelectedYear(value);
    changeYear(value);
  };

  const handleMonthChange = (value: string) => {
    setSelectedMonth(value);
    changeMonth(calendarMonths.indexOf(value));
  };

  useEffect(() => {
    setSelectedYear(getYear(date));
  }, [date.getFullYear()]);

  useEffect(() => {
    setSelectedMonth(calendarMonths[getMonth(date)]);
  }, [date.getMonth()]);

  return (
    <ErrorBoundaryWrapper>
      <div className="flex justify-between gap-x-3">
        <button
          type="button"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled || prevButtonDisabled}
          className={clsx(
            'w-[2.188rem] pl-2',
            prevButtonEnabled &&
              'rounded-lg px-2.5 py-2 pl-2 hover:bg-gray-100/50',
          )}
        >
          <ChevronLeftIcon
            strokeWidth={'2'}
            className={clsx(
              'size-4',
              (prevMonthButtonDisabled || prevButtonDisabled) && 'invisible',
            )}
          />
        </button>
        <div className="flex items-center gap-x-2">
          <CalenderYearMonthListbox
            value={selectedYear}
            onChange={handleYearChange}
            label="year"
            list={calendarYears}
          />
          <CalenderYearMonthListbox
            value={selectedMonth}
            onChange={handleMonthChange}
            label="month"
            list={calendarMonths}
          />
        </div>
        <button
          type="button"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled || nextButtonDisabled}
          className={clsx(
            'w-[2.188rem] pl-2',
            nextButtonEnabled &&
              'rounded-lg px-2.5 py-2 pl-2 hover:bg-gray-100/50',
          )}
        >
          <ChevronRightIcon
            strokeWidth={'2'}
            className={clsx(
              'size-4',
              (nextMonthButtonDisabled || nextButtonDisabled) && 'invisible',
            )}
          />
        </button>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default CalendarCustomHeader;
