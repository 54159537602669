import {
  Checkbox,
  ErrorBoundaryWrapper,
  ScenarioListDetailData,
} from '@autosquare/common';

import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@app/store';
import { SelectedItem } from '@autosquare/common/src/hooks/checkbox/useCheckboxWithDnd';

interface Props {
  testScenario: ScenarioListDetailData;
  selectArray: (
    idx: number,
    dndIndex: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  selectedArray: SelectedItem[];
}

const DetailListItemCard = ({
  testScenario,
  selectArray,
  selectedArray,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { title, dndIndex, ...restData } = testScenario;

  const selectedDnd = selectedArray.map(({ dndIndex }) => dndIndex);
  const hasSchedulerRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );

  return (
    <ErrorBoundaryWrapper>
      <div className="border-border-line m-2 flex items-center border border-solid bg-gray-50 p-2">
        <Checkbox
          checked={selectedDnd.includes(testScenario?.dndIndex)}
          onChange={(e) => {
            selectArray(testScenario?.idx, testScenario?.dndIndex, e);
          }}
          disabled={!hasSchedulerRunning}
        />
        <div className="ml-7">{testScenario?.title}</div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default DetailListItemCard;
