import React from 'react';
import AddDeviceFormFields from './AddDeviceFormFields';
import { useFormContext } from 'react-hook-form';
import { DeviceApprovalRequest } from '@customTypes/index';

const AddDeviceModel = () => {
  const { register } = useFormContext<DeviceApprovalRequest>();

  return (
    <AddDeviceFormFields
      label="Device Model* (1~20자, 영문 대소문자, 숫자[0-9], 대시(-), 쉼표(,), 온점(.) 입력 가능)"
      name="deviceModel"
    >
      <input
        className="input-base"
        placeholder="등록할 디바이스의 모델명을 입력해 주세요."
        {...register('deviceModel')}
      />
    </AddDeviceFormFields>
  );
};

export default AddDeviceModel;
