import { ErrorBoundaryWrapper, Fieldset } from '@autosquare/common';

import { UpdateAdminProjectDetailData } from '@customTypes/dashboard/admin/type';
import {
  ProjectListDetailFeaturesCategory,
  ProjectListDetailFeaturesFeature,
} from '@customTypes/dashboard/project/type';

import React from 'react';
import {
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

const ProjectEditFeatures = () => {
  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext<UpdateAdminProjectDetailData>();

  const { fields, append, update } = useFieldArray({
    control,
    name: 'features',
  });

  const isActivateFeatures = (
    fields: FieldArrayWithId<UpdateAdminProjectDetailData, 'features', 'id'>[],
    feature: ProjectListDetailFeaturesFeature,
    category: ProjectListDetailFeaturesCategory,
  ) =>
    fields?.find(
      (field) => field.feature === feature && field.category === category,
    )?.activate;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: {
      id: string;
      labelName: string;
      feature: ProjectListDetailFeaturesFeature;
      category: ProjectListDetailFeaturesCategory;
    },
  ) => {
    const isChecked = e.target.checked;
    const index = fields.findIndex(
      (f) => f.category === field.category && f.feature === field.feature,
    );

    // 기존에 없었던 index 를 클릭했을 때 index = -1
    if (index !== -1) {
      const updatedField = { ...fields[index], activate: isChecked };
      delete updatedField.id;
      update(index, updatedField);
    } else {
      append({
        category: field.category,
        feature: field.feature,
        activate: isChecked,
      });
    }

    clearErrors('features');
  };

  const fieldsetValues = [
    {
      idx: 0,
      fieldsetName: 'Mobile',
      inputField: [
        {
          id: 'mobile-functional',
          labelName: '기능 테스트',
          defaultChecked: isActivateFeatures(
            fields,
            ProjectListDetailFeaturesFeature.Functional,
            ProjectListDetailFeaturesCategory.Mobile,
          ),
          feature: ProjectListDetailFeaturesFeature.Functional,
          category: ProjectListDetailFeaturesCategory.Mobile,
        },
        {
          id: 'mobile-speed',
          labelName: '속도 테스트',
          defaultChecked: isActivateFeatures(
            fields,
            ProjectListDetailFeaturesFeature.Speed,
            ProjectListDetailFeaturesCategory.Mobile,
          ),
          feature: ProjectListDetailFeaturesFeature.Speed,
          category: ProjectListDetailFeaturesCategory.Mobile,
        },
      ],
    },
    {
      idx: 1,
      fieldsetName: 'Web',
      inputField: [
        {
          id: 'web-functional',
          labelName: '기능 테스트',
          defaultChecked: isActivateFeatures(
            fields,
            ProjectListDetailFeaturesFeature.Functional,
            ProjectListDetailFeaturesCategory.Web,
          ),
          feature: ProjectListDetailFeaturesFeature.Functional,
          category: ProjectListDetailFeaturesCategory.Web,
        },
        {
          id: 'web-speed',
          labelName: '속도 테스트',
          defaultChecked: isActivateFeatures(
            fields,
            ProjectListDetailFeaturesFeature.Speed,
            ProjectListDetailFeaturesCategory.Web,
          ),
          feature: ProjectListDetailFeaturesFeature.Speed,
          category: ProjectListDetailFeaturesCategory.Web,
        },
      ],
    },
  ];

  return (
    <div className="border-b border-gray-900/10 pb-6">
      <h2 className="text-base font-semibold leading-7 text-gray-900">
        Features
      </h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">
        프로젝트에서 사용 할 수 있는 기능을 선택해주세요.(1개 이상 필수 선택)
      </p>
      <ErrorBoundaryWrapper>
        <div>
          <div className="mt-6 space-y-6 pb-12 sm:space-y-0 sm:pb-0">
            {fieldsetValues?.map((value) => (
              <Fieldset
                key={value.idx}
                fieldsetName={value.fieldsetName}
                inputField={value.inputField.map((field) => ({
                  ...field,
                  onChange: (e) => {
                    handleChange(e, field);
                  },
                }))}
              />
            ))}
          </div>
        </div>
        <ErrorMessage
          errors={errors}
          name="features"
          render={({ message }) => <p className="error-message">{message}</p>}
        />
      </ErrorBoundaryWrapper>
    </div>
  );
};

export default ProjectEditFeatures;
