import { RootState } from '@app/store';
import { Mode } from '@customTypes/ide/remote/type';
import { useSelector } from 'react-redux';

export const getRemoteState = () => {
  const remotePCInfo = useSelector(
    (state: RootState) => state.remoteControl.remoteInfo,
  );
  const configuration = localStorage.getItem('configuration');

  const remoteConfiguration = configuration === Mode.Remote;
  const standardConfiguration = configuration === Mode.Standard;
  const isRemoteConnectionNeeded =
    remoteConfiguration && remotePCInfo === undefined;
  const readOnlyMode =
    configuration === Mode.Remote && remotePCInfo !== undefined;

  return {
    readOnlyMode,
    isRemoteConnectionNeeded,
    configuration,
    standardConfiguration,
    remoteConfiguration,
  };
};
