import { ErrorBoundaryWrapper, PagingHeadings } from '@autosquare/common';
import React from 'react';

const AdminDataStorageSettingHeader = () => {
  const breadcrumbs = [
    {
      title: 'Administration',
      href: `#`,
    },
    {
      title: 'Settings',
      href: `#`,
    },
    { title: 'Data Storage', href: `#` },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings breadcrumbs={breadcrumbs} pageTitle={'Data Storage'} />
      <p className="text-gray-600">
        테스트 결과의 저장 기간을 설정할 수 있습니다.
      </p>
    </ErrorBoundaryWrapper>
  );
};

export default AdminDataStorageSettingHeader;
