import {
  ErrorBoundaryWrapper,
  ErrorMessage,
  TestCaseDetailSkeleton,
} from '@autosquare/common';
import React from 'react';
import { useMobileTestCaseDetailQuery } from '@lib/api/ide/queryHooks';
import TestCaseInfoHeader from './TestCaseInfo/TestCaseInfoHeader';
import TestCaseInfoEdit from './TestCaseInfo/TestCaseInfoEdit';

const TestCaseInfo = () => {
  const { isLoading, isError, error } = useMobileTestCaseDetailQuery();

  if (isLoading) {
    return (
      <TestCaseInfoHeader>
        <TestCaseDetailSkeleton testCategory={'ui'} platform={'mobile'} />
      </TestCaseInfoHeader>
    );
  }

  if (isError) {
    return (
      <TestCaseInfoHeader>
        <ErrorMessage>{error.message}</ErrorMessage>
      </TestCaseInfoHeader>
    );
  }

  return (
    <ErrorBoundaryWrapper>
      <TestCaseInfoEdit />
    </ErrorBoundaryWrapper>
  );
};

export default TestCaseInfo;
