import {
  Breadcrumbs,
  Button,
  ErrorBoundaryWrapper,
  PagingHeadingsInAutosquare,
} from '@autosquare/common';
import { useParentPath } from '@hooks/useParentPath';
import { useProjectDetailQuery } from '@lib/api/ide/project/project';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const MobileTestStepHeader = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const { data: projectName } = useProjectDetailQuery();

  const mobilePathname = useParentPath();
  const projectPathname = useParentPath(2);

  const isUiTest = isWordInLocationPathname('ui-test');

  const overviewPath = `${projectPathname}/overview`;
  const testCasePage = `${mobilePathname}/test-case`;
  const testCaseDetailPage = `${mobilePathname}/test-case/detail?idx=${idx}`;

  const breadcrumbsTitle = isUiTest ? 'UI Test' : 'Speed Test';
  const mobileTestCaseTitle = sessionStorage.getItem('testCaseTitle');

  const breadcrumbs: Breadcrumbs[] = [
    { title: projectName?.name, href: '/ide/projects/list' },
    { title: breadcrumbsTitle, href: overviewPath },
    { title: 'Mobile', href: testCasePage },
    { title: mobileTestCaseTitle, href: testCaseDetailPage },
    { title: 'Test Step', href: pathname + search },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="mx-4 flex items-center justify-between py-4">
        <PagingHeadingsInAutosquare breadcrumbs={breadcrumbs} />
        <Button
          type="button"
          variant="primary"
          onClick={() => navigate(testCaseDetailPage)}
        >
          Close
        </Button>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default MobileTestStepHeader;
