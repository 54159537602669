import { ErrorBoundaryWrapper } from '@autosquare/common';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React from 'react';
import { useFormContext } from 'react-hook-form';

const TestStepRequiredHookForm = () => {
  const { register } = useFormContext<CreateTestStepData>();

  return (
    <ErrorBoundaryWrapper>
      <div className="w-full">
        <fieldset className="flex items-center">
          <legend className="sr-only">Required</legend>
          <div className="space-y-5">
            <div className="relative flex items-start gap-3">
              <div className="text-sm leading-6">
                <label
                  htmlFor="required"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Required
                </label>
              </div>
              <div className="flex h-6 items-center">
                <input
                  id="required"
                  name="required"
                  type="checkbox"
                  defaultChecked={true}
                  className="input-checkbox-congress-blue"
                  {...register('required')}
                />
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default TestStepRequiredHookForm;
