import { ErrorBoundaryWrapper, ListBoxHeadlessUI } from '@autosquare/common';

import { useWebTestStepContext } from '@contexts/ide/WebTestStepProvider';

import { CreateWebTestStepData } from '@customTypes/testStep/type';

import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

type ValueKeys = keyof Pick<CreateWebTestStepData, 'value'>;

type Props = {
  value?: ValueKeys;
  rules?: Omit<
    RegisterOptions<CreateWebTestStepData, ValueKeys>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
};

const GetUrlListBox = ({
  value = 'value',
  rules = { required: 'URL을 선택해 주세요.' },
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CreateWebTestStepData>();

  const { currentPageUrl } = useWebTestStepContext();

  return (
    <ErrorBoundaryWrapper>
      <Controller
        control={control}
        name={'value'}
        rules={rules}
        render={({ field }) => (
          <ListBoxHeadlessUI
            state={field.value || 'Value'}
            setState={field.onChange}
            list={currentPageUrl}
            wordInButton={field.value || 'Value'}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={value}
        render={({ message }) => (
          <p className="error-message !mt-2">{message}</p>
        )}
      />
    </ErrorBoundaryWrapper>
  );
};

export default GetUrlListBox;
