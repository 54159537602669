import React, { useEffect } from 'react';
import { Button, ErrorBoundaryWrapper } from '@autosquare/common';
import AutoRegisterList from '@components/AutoRegisterList/AutoRegisterList';
import {
  AutoRegisterType,
  UpdateAdminProjectDetailData,
} from '@customTypes/dashboard/admin/type';
import { PlusIcon } from '@heroicons/react/20/solid';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';

type Props = {
  autoRegisterDataList: AutoRegisterType[];
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  isEditModalOpened: boolean;
  setIsEditModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCaution: React.Dispatch<React.SetStateAction<boolean>>;
  isAddClicked: boolean;
  setIsAddClicked: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditAutoRegisterAddPlatform = ({
  autoRegisterDataList,
  setIndex,
  isEditModalOpened,
  setIsEditModalOpened,
  setOpenCaution,
  isAddClicked,
  setIsAddClicked,
}: Props) => {
  const {
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext<UpdateAdminProjectDetailData>();

  useEffect(() => {
    if (!isAddClicked) {
      setValue('platform', undefined);
      resetField('registers');
    }
  }, [isAddClicked, setValue, resetField]);

  useEffect(() => {
    if (!isEditModalOpened && autoRegisterDataList.length > 0) {
      setValue('platform', undefined);
      resetField('registers');
    }
  }, [isEditModalOpened, autoRegisterDataList, setValue, resetField]);

  useEffect(() => {
    if (autoRegisterDataList.length > 0) {
      setValue('registers', autoRegisterDataList);
    } else {
      setValue('registers', []);
    }
  }, [autoRegisterDataList, setValue]);
  return (
    <ErrorBoundaryWrapper>
      <div>
        <div className="flex w-full items-center gap-x-4 sm:space-y-0 sm:pb-0">
          <div className="text-sm font-medium leading-normal text-gray-900">
            Platform*
          </div>
          {autoRegisterDataList.length > 0 && (
            <AutoRegisterList
              autoDataRegisterList={autoRegisterDataList}
              setIndex={setIndex}
              setIsEditModalOpened={setIsEditModalOpened}
              setOpenCaution={setOpenCaution}
            />
          )}
          <Button
            type="button"
            variant="imageOriginal"
            className="flex items-center justify-center"
            onClick={() => setIsAddClicked(true)}
          >
            <PlusIcon className="size-6" />
            <p className="text-sm font-normal text-gray-900">Add</p>
          </Button>
        </div>
        <ErrorMessage
          name="registers"
          errors={errors}
          render={({ message }) => (
            <p className="standard-error-message ml-24">{message}</p>
          )}
        />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default EditAutoRegisterAddPlatform;
