import {
  TestResult,
  TestResultDetailInquiryByCase,
  TestResultExportCaseResultList,
  TestResultInquiryByCase,
  WebTestResultPagingData,
} from '@customTypes/testResult/type';

import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { axiosInstance, axiosRawResponseInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';
import { webTestResultKeys } from '@queries/ide/web/webTestResultKeys';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { DeleteIndexList } from '@customTypes/index';

// GET
const webTestResultList = async (
  projectIdx: string,
  page: number,
  size: number,
): Promise<WebTestResultPagingData> => {
  const response = await axiosInstance.get<WebTestResultPagingData>(
    IDEAPI.getWebTestResultList(projectIdx, page, size),
  );
  return response.data;
};
export const useWebTestResultListQuery = (page: number, size: number) => {
  const { projectIdx } = useParams();
  return useQuery<WebTestResultPagingData, Error>({
    queryKey: webTestResultKeys.list(projectIdx),
    queryFn: () => webTestResultList(projectIdx, page, size),
  });
};

const webTestResultSearchList = async (
  search: string,
): Promise<WebTestResultPagingData> => {
  const response = await axiosInstance.get<WebTestResultPagingData>(
    `${IDEAPI.WEB_TEST_RESULT}/search${search}`,
  );
  return response.data;
};
export const useWebTestResultSearchListQuery = (
  options?: UseQueryOptions<WebTestResultPagingData, Error>,
) => {
  const { search } = useLocation();
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page');
  const size = searchParams.get('size');
  const projectIdx = searchParams.get('projectIdx');
  const order = searchParams.get('order');

  return useQuery<WebTestResultPagingData, Error>({
    queryKey: webTestResultKeys.search(search),
    queryFn: () => webTestResultSearchList(search),
    enabled:
      page !== null && size !== null && projectIdx !== null && order !== null,
    keepPreviousData: true,
    ...options,
  });
};

const webTestResultDetail = async (
  idx: string,
): Promise<TestResultInquiryByCase> => {
  const reponse = await axiosInstance.get<TestResultInquiryByCase>(
    IDEAPI.getWebTestResultDetailInfo(idx),
  );
  return reponse.data;
};
export const useWebTestResultDetailQuery = (idx: string) => {
  return useQuery<TestResultInquiryByCase, Error>({
    queryKey: webTestResultKeys.detail(idx),
    queryFn: () => webTestResultDetail(idx),
  });
};

const webTestResultDetailInfo = async (idx: string): Promise<TestResult> => {
  const response = await axiosInstance.get<TestResult>(
    IDEAPI.GET_WEB_TEST_RESULT_DETAIL + idx,
  );
  return response.data;
};
export const useWebTestResultDetailInfoQuery = (idx: string) => {
  return useQuery<TestResult, Error>({
    queryKey: webTestResultKeys.detailInfo(idx),
    queryFn: () => webTestResultDetailInfo(idx),
  });
};

const webTestCaseExecutionDetail = async (
  idx: string,
  caseResultIdx: number,
): Promise<TestResultDetailInquiryByCase[]> => {
  const response = await axiosInstance.get<TestResultDetailInquiryByCase[]>(
    IDEAPI.getWebTestCaseExecutionDetail(idx, caseResultIdx),
  );
  return response.data;
};
export const useWebTestCaseExecutionDetailQuery = (caseResultIdx: number) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  return useQuery<TestResultDetailInquiryByCase[], Error>({
    queryKey: webTestResultKeys.executionDetail(idx, caseResultIdx),
    queryFn: () => webTestCaseExecutionDetail(idx, caseResultIdx),
    refetchOnMount: true,
    staleTime: 1000 * 20,
  });
};

//image
const webTestResultImageData = async (path: string[]): Promise<string[]> => {
  const requests = path.map((path) =>
    axiosRawResponseInstance.get<MediaSource>(path, {
      responseType: 'blob',
    }),
  );

  const responses = await Promise.all(requests);
  const objectUrls = responses.map((response) =>
    URL.createObjectURL(response.data),
  );

  return objectUrls;
};

export const useWebTestResultImageData = (
  path: string[],
  options?: UseQueryOptions<string[], Error>,
) => {
  return useQuery<string[], Error>({
    queryKey: webTestResultKeys.detailImage(path[0]),
    queryFn: () => webTestResultImageData(path),
    ...options,
  });
};

// POST
const webTestResultExport = async (
  resultIdx: string,
  data: TestResultExportCaseResultList,
): Promise<{ blob: Blob; filename: string }> => {
  const response = await axiosRawResponseInstance.post<Blob>(
    `${IDEAPI.WEB_TEST_RESULT}/${resultIdx}/download-excel`,
    data,
    { responseType: 'blob' },
  );

  const contentDisposition = response.headers['content-disposition'];
  const filenameMatch = contentDisposition.match(/filename="(.+)"/);

  return { blob: response.data, filename: filenameMatch[1] };
};
export const useWebTestResultExportMutation = () => {
  const [searchParams] = useSearchParams();
  const resultIdx = searchParams.get('idx');

  return useMutation<
    { blob: Blob; filename: string },
    Error,
    TestResultExportCaseResultList
  >({
    mutationFn: (data) => webTestResultExport(resultIdx, data),
    onSuccess: ({ blob, filename }) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },
  });
};

// DELETE
const webTestResultDelete = async (
  data: DeleteIndexList,
): Promise<DeleteIndexList> => {
  const response = await axiosInstance.delete<DeleteIndexList>(
    `${IDEAPI.WEB_TEST_RESULT}`,
    { data },
  );
  return response.data;
};
export const useWebTestResultDeleteMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation<DeleteIndexList, Error, DeleteIndexList>({
    mutationFn: (data) => webTestResultDelete(data),
    onSuccess: () => {
      queryClient.invalidateQueries(webTestResultKeys.lists());
      queryClient.invalidateQueries(webTestResultKeys.searches());
      navigate(-1);
    },
  });
};
