import { Variant, ElectronFile } from '@autosquare/common';
import { MobileTestStepData } from '@customTypes/testStep/type';
import { PlatFormTypeServerName } from '@utils/static/platformTypeList';
import { UseFormRegisterReturn } from 'react-hook-form';
import { z } from 'zod';

export enum PriorityNameEnum {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export enum PriorityServerName {
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW',
}

export enum PriorityEnum {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export enum AutomationAutoOrder {
  ASC = 'ASC',
  DESC = 'DESC',
  Random = 'RANDOM',
}
export interface PostExportMobileTestCaseData {
  projectIdx: number;
  exportCaseIdx: number[];
}

export interface PostExportMobileTestCase {
  path: string;
  exportData: MobileTestCaseExportDataResponse;
}

export interface MobileTestCaseExportDataResponse {
  exportDate: string;
  exportProjectIdx: number;
  exportServer: string;
  requestUser: string;
  testCases: TestCaseData[];
}

export interface MobileTestCaseExportResponse {
  saved: boolean;
  savedPath: string;
}

export interface PostMobileTestCaseExportListToLocal {
  projectIdx: number;
  path: string;
}

export interface WebTestCaseDetailData {
  idx: number;
  title: string;
  creator: string;
  description?: string;
  step: number[];
  stepCount: number;
  dndIndex?: number;
  priority: PriorityNameEnum;
  updatedAt: string;
  platformType?: PlatFormTypeServerName;
  caseGroupInfo?: null | CaseGroupInfo;
  createdAt: string;
  initialCreatedAt: string;
}

export interface CaseGroupInfo {
  name: string;
  caseGroupIdx: number;
}

const DefaultTestCaseDetailSchema = z.object({
  title: z
    .string()
    .min(1, '필수 입력 사항 입니다.')
    .max(50, '50자를 초과할 수 없습니다.'),
  description: z.string().max(200, '200자를 초과할 수 없습니다.').optional(),
  tcId: z
    .string()
    .min(1, '필수 입력 사항 입니다.')
    .max(50, '50자를 초과할 수 없습니다.'),
  priority: z.nativeEnum(PriorityNameEnum),
  platformType: z.nativeEnum(PlatFormTypeServerName),
  caseGroupIdx: z.number().optional(),
});

// TestCaseDetailStepS
export const TestCaseDetailStepSchema = z.object({
  aos: z.array(z.number()).optional(),
  aosStepCount: z.number().optional(),
  aosRepeatStepInfo: z
    .object({ startIdx: z.number(), endIdx: z.number() })
    .optional()
    .nullable(),
  ios: z.array(z.number()).optional(),
  iosStepCount: z.number().optional(),
  iosRepeatStepInfo: z
    .object({ startIdx: z.number(), endIdx: z.number() })
    .optional()
    .nullable(),
  stepCount: z.number().optional(),
});

export type TestCaseDetailStep = z.infer<typeof TestCaseDetailStepSchema>;

// UI Test Mobile Test Case Detail
export const TestCaseDetailDataSchema = DefaultTestCaseDetailSchema.pick({
  title: true,
  description: true,
  tcId: true,
  priority: true,
  platformType: true,
  caseGroupIdx: true,
}).extend({
  idx: z.number(),
  dndIndex: z.number().optional(),
  priority: z.nativeEnum(PriorityNameEnum),
  creator: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  platformType: z.nativeEnum(PlatFormTypeServerName).optional(),
  caseGroupInfo: z
    .object({
      name: z.string(),
      caseGroupIdx: z.number(),
    })
    .optional()
    .nullable(),
  steps: TestCaseDetailStepSchema,
  initialCreatedAt: z.string().optional(),
});

export type TestCaseDetailData = z.infer<typeof TestCaseDetailDataSchema>;

export const CreateMobileTestCaseDataSchema = z.object({
  title: z
    .string({ required_error: '필수 항목을 선택해 주세요.' })
    .min(1, '필수 입력 사항 입니다.')
    .max(50, '50자를 초과할 수 없습니다.')
    .trim(),
  description: z
    .string()
    .max(200, '200자를 초과할 수 없습니다.')
    .trim()
    .optional(),
  tcId: z
    .string({ required_error: '필수 항목을 선택해 주세요.' })
    .min(1, '필수 입력 사항 입니다.')
    .max(50, '50자를 초과할 수 없습니다.')
    .trim(),
  priority: z.nativeEnum(PriorityNameEnum),
  platformType: z.nativeEnum(PlatFormTypeServerName),
  caseGroupIdx: z.number().optional(),
});

export type CreateMobileTestCaseData = z.infer<
  typeof CreateMobileTestCaseDataSchema
>;

export const UpdateMobileTestCaseDataSchema =
  CreateMobileTestCaseDataSchema.pick({
    title: true,
    description: true,
    priority: true,
    platformType: true,
    caseGroupIdx: true,
    tcId: true,
  });

export type UpdateMobileTestCaseData = z.infer<
  typeof UpdateMobileTestCaseDataSchema
>;

export type TestCaseDetailPickTitleAndIdx = Pick<
  TestCaseDetailData,
  'title' | 'idx' | 'dndIndex'
>;

// import test case type
export enum ImportedFileDataStatus {
  Done = 'done',
  Fail = 'fail',
  Update = 'Update',
  New = 'New',
}

export interface TestCaseData {
  createdAt: string;
  creator: string;
  description: string;
  modifiedAt: string;
  ownerUuid: string;
  platformType: string;
  priority: PriorityNameEnum;
  projectIdx: number;
  status: string;
  title: string;
  uniqueKey: string;
  aosStepCount: number;
  iosStepCount: number;
  testSteps: MobileTestStepData[];
}

export interface ImportedFileData {
  status: ImportedFileDataStatus;
  testCaseData: TestCaseData;
}

export type ImportedFileCsvData = Record<string, string[]>;

export interface ImportTestCaseResponse {
  description: string;
  failReason: string | null;
  importResult: 'done' | 'fail' | 'doing' | 'wait';
  priority: PriorityNameEnum;
  stepCount: number;
  title: string;
}

export const CreateImportCsvTestCaseSchema =
  CreateMobileTestCaseDataSchema.pick({
    priority: true,
    platformType: true,
  }).extend({
    projectIdx: z.number(),
    title: z.record(z.string(), z.array(z.string())),
    tcId: z.record(z.string(), z.array(z.string())),
    description: z.record(z.string(), z.array(z.string())).optional(),
  });

export type CreateImportCsvTestCase = z.infer<
  typeof CreateImportCsvTestCaseSchema
>;

export const ImportCsvTestCaseRequestSchema =
  CreateMobileTestCaseDataSchema.pick({
    title: true,
    description: true,
    tcId: true,
    priority: true,
    platformType: true,
  }).superRefine((data, ctx) => {
    if (data.description === data.title) {
      ctx.addIssue({
        code: 'custom',
        path: ['description'],
        message: '이미 선택된 필드입니다.',
      });
      ctx.addIssue({
        code: 'custom',
        path: ['title'],
        message: '이미 선택된 필드입니다.',
      });
    }

    if (data.description === data.tcId) {
      ctx.addIssue({
        code: 'custom',
        path: ['description'],
        message: '이미 선택된 필드입니다.',
      });
      ctx.addIssue({
        code: 'custom',
        path: ['tcId'],
        message: '이미 선택된 필드입니다.',
      });
    }

    if (data.title === data.tcId) {
      ctx.addIssue({
        code: 'custom',
        path: ['title'],
        message: '이미 선택된 필드입니다.',
      });
      ctx.addIssue({
        code: 'custom',
        path: ['tcId'],
        message: '이미 선택된 필드입니다.',
      });
    }
  });

export type ImportCsvTestCaseRequest = z.infer<
  typeof ImportCsvTestCaseRequestSchema
>;

export const CreateImportCsvTestCaseResponseSchema =
  CreateImportCsvTestCaseSchema.pick({
    title: true,
    description: true,
    priority: true,
  }).extend({
    importResult: z.enum(['done', 'fail']),
    failReason: z.string().nullish(),
  });

export type CreateImportCsvTestCaseResponse = z.infer<
  typeof CreateImportCsvTestCaseResponseSchema
>;

export interface CreateWebTestCaseData {
  priority: PriorityNameEnum;
  title: string;
  description?: string;
  caseGroupIdx?: number;
}

export interface UpdateWebTestCaseData {
  priority: PriorityNameEnum;
  title: string;
  description?: string;
  caseGroupIdx?: number;
}

export const SheetDataSchema = z.object({
  sheetName: z.string(),
  rows: z.array(z.record(z.string(), z.union([z.string(), z.number()]))),
});

export type SheetData = z.infer<typeof SheetDataSchema>;

export const FileTableDataSchema = z.object({
  fileName: z.string(),
  sheetCount: z.number(),
  lastUpdated: z.string(),
  sheets: z.array(SheetDataSchema),
});

export type FileTableData = z.infer<typeof FileTableDataSchema>;

export type AutomatonInfoInput = {
  title: string;
  value?: string;
  name: string;
  register: UseFormRegisterReturn;
};

export const BaseAutomationDataSchema = z.object({
  title: z
    .string()
    .min(1, '필수 입력 항목입니다.')
    .max(50, '50자를 초과할 수 없습니다.'),
  description: z.string().max(200, '200자를 초과할 수 없습니다.').optional(),
  autoOrder: z.nativeEnum(AutomationAutoOrder),
});

export type BaseAutomationData = z.infer<typeof BaseAutomationDataSchema>;

export const TestDataAutomationInfoSchema = BaseAutomationDataSchema.extend({
  idx: z.number(),
  projectIdx: z.number().optional(),
  caseIdx: z.number().optional(),
  table: FileTableDataSchema,
  targetData: z.string(),
});

export type TestDataAutomationInfo = z.infer<
  typeof TestDataAutomationInfoSchema
>;

const MAX_FILE_SIZE = 10 * 1024 * 1024;

export const CreateTestDataAutomationSchema = TestDataAutomationInfoSchema.pick(
  {
    title: true,
    description: true,
    autoOrder: true,
    caseIdx: true,
    projectIdx: true,
  },
).extend({
  file: z
    .custom<ElectronFile>((file) => file instanceof File, {
      message: 'Test Data를 업로드해 주세요.',
    })
    .refine((file) => file.type === 'text/csv', {
      message: '해당 파일은 지원하지 않습니다.',
    })
    .refine((file) => file.size <= MAX_FILE_SIZE, {
      message: '10MB 이하의 CSV 파일만 업로드 가능합니다.',
    }),
  filePath: z.string().optional(),
});

export type CreateTestDataAutomation = z.infer<
  typeof CreateTestDataAutomationSchema
>;

export const UpdateTestDataAutomationSchema =
  CreateTestDataAutomationSchema.extend({ automationIdx: z.number() });

export type UpdateTestDataAutomation = z.infer<
  typeof UpdateTestDataAutomationSchema
>;

export type AutomationButton = {
  name: string;
  variant: Variant;
  type: 'button' | 'submit';
  onClick?: () => void;
};
