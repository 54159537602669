import {
  ArrowSpinner,
  Button,
  CreatePlusButton,
  DistributedExecutionButton,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
  PageHeadingButtons,
  PagingHeadingsNavigate,
  useCheckbox,
} from '@autosquare/common';

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  useDeleteMobileTestScenarioMutation,
  useFilteredMobileTestScenarioListQuery,
} from '@lib/api/ide/mobile/mobileTestScenario';
import {
  useDeleteWebTestScenarioMutation,
  useFilteredWebTestScenarioListQuery,
} from '@lib/api/ide/web/webTestScenario';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import ScenarioListTable from './components/ScenarioListTable';
import TestExecutionContainer from '@components/TestExecution/TestExecutionContainer';
import SearchForm from '@components/SearchForm/SearchForm';
import getFirstGenerationDateToDateType from '@utils/static/getFirstGenerationDateToDateType';
import { useRemoteDistributionMobileTestExecutionMutation } from '@lib/api/ide/mobile/mobileExecution';
import RemoteTestExecutionAlarmModal from '@components/TestExecution/shared/RemoteTestExecutionAlarmModal';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';

const TestScenarioList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentUrl = location.pathname + location.search;

  const [isDistributedExecution, setIsDistributedExecution] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExecutionErrorModalOpen, setIsExecutionErrorModalOpen] =
    useState(false);

  const mobileTestScenarioListQuery = useFilteredMobileTestScenarioListQuery();
  const webTestScenarioListQuery = useFilteredWebTestScenarioListQuery();

  const remoteMobileDistributedExecutionMutation =
    useRemoteDistributionMobileTestExecutionMutation();

  const mobileTestScenarioList = mobileTestScenarioListQuery.data;
  const webTestScenarioList = webTestScenarioListQuery.data;

  const scenarioLists = isWordInLocationPathname('mobile')
    ? mobileTestScenarioList
    : webTestScenarioList;

  const initialCreatedAt = scenarioLists?.[0]?.createdAt;
  const formattedCreatedAt = getFirstGenerationDateToDateType(initialCreatedAt);

  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(scenarioLists, 'idx');

  const deleteTestScenarioMutation = isWordInLocationPathname('mobile')
    ? useDeleteMobileTestScenarioMutation(selectedArray)
    : useDeleteWebTestScenarioMutation(selectedArray);

  const breadcrumbs = [
    {
      title: 'UI Test',
      href: currentUrl.split('/').slice(0, -2).join('/') + '/overview',
    },
    {
      title: isWordInLocationPathname('mobile') ? 'Mobile' : 'Web',
      href: currentUrl,
    },
    { title: 'Test Scenario', href: currentUrl },
    { title: 'List', href: currentUrl },
  ];

  const pageHeadingButtonsDefault: PageHeadingButtons[] = [
    {
      icon: <CreatePlusButton />,
      variant: 'imageBasic',
      onClick: () => {
        navigate('create');
      },
      description: 'Create Scenario',
    },
  ];
  const addingPageHeadingButtons: PageHeadingButtons = {
    icon: <DistributedExecutionButton />,
    variant: 'imageBasic',
    onClick: () => {
      setIsDistributedExecution(true);
    },
    description: 'Distributed Execution',
  };

  const pageHeadingButtons: PageHeadingButtons[] = isWordInLocationPathname(
    'web',
  )
    ? pageHeadingButtonsDefault
    : [...pageHeadingButtonsDefault, addingPageHeadingButtons];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Scenario List'}
        pageHeadingButtons={pageHeadingButtons}
      />
      <SearchForm initialCreatedAt={formattedCreatedAt} />
      <ScenarioListTable
        selectAllArray={selectAllArray}
        selectArray={selectArray}
        selectedArray={selectedArray}
        openDialog={openDialog}
        mobileListQuery={mobileTestScenarioListQuery}
        webListQuery={webTestScenarioListQuery}
      />
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedCautionDialog}
        setIsOpened={setIsOpenedCautionDialog}
        type="caution"
        title={
          deleteTestScenarioMutation.isError ? 'Error' : 'Delete Test Scenario'
        }
        subTitle={
          deleteTestScenarioMutation.isError
            ? deleteTestScenarioMutation.error.message
            : '해당 테스트 시나리오를 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {deleteTestScenarioMutation.isError ? undefined : (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  deleteTestScenarioMutation.mutate(undefined, {
                    onSuccess: () => {
                      closeDialog();
                      resetSelectedArray();
                    },
                    onError: () => {
                      openDialog();
                    },
                  })
                }
              >
                Delete
              </Button>
            )}
            <Button type="button" variant="secondary" onClick={closeDialog}>
              Cancel
            </Button>
          </>
        }
      />
      {isDistributedExecution && (
        <TestExecutionContainer
          setState={setIsDistributedExecution}
          testType={'scenario'}
          deviceType={'mobile'}
          isDistributedClicked={true}
          setIsModalOpen={setIsModalOpen}
          remoteMobileDistributedExecutionMutation={
            remoteMobileDistributedExecutionMutation
          }
          setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
        />
      )}
      {remoteMobileDistributedExecutionMutation.isLoading && <ArrowSpinner />}
      <RemoteTestExecutionAlarmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isExecutionErrorModalOpen={isExecutionErrorModalOpen}
        setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
      />
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default TestScenarioList;
