import React, { useEffect } from 'react';
import {
  Checkbox,
  TableHeader,
  useTableHeaderLengthStore,
} from '@autosquare/common';
import { useIndeterminateCheckbox } from '@hooks/checkbox/useIndeterminateCheckbox';
import { useAuthUserInfo } from '@hooks/index';

type Props = {
  selectedArray: number[];
  selectAllArray: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedCount: number;
  totalCount: number;
  disabledOption: boolean;
};

const ApplicationListTableHeader = ({
  selectedArray,
  selectAllArray,
  selectedCount,
  totalCount,
  disabledOption,
}: Props) => {
  const { length, setLength } = useTableHeaderLengthStore();
  const { isAdmin } = useAuthUserInfo();
  const checkboxRef = useIndeterminateCheckbox(selectedCount, totalCount);
  const tableHeaderList = [
    isAdmin && {
      idx: 1,
      title: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={(e) => selectAllArray(e)}
          disabled={disabledOption}
          ref={checkboxRef}
        />
      ),
    },
    {
      idx: 2,
      title:
        selectedArray.length > 0 ? <div className="h-3 w-[1.188rem]" /> : 'No',
    },
    {
      idx: 3,
      title: selectedArray.length > 0 ? <div /> : 'App Name',
    },
    { idx: 4, title: 'Package Name' },
    { idx: 5, title: 'App Version' },
    { idx: 6, title: 'Build Version' },
    { idx: 7, title: 'OS' },
    { idx: 8, title: 'Registered' },
    { idx: 9, title: 'User' },
    { idx: 10, title: '' },
  ].filter(Boolean);

  useEffect(() => {
    if (length === 0) {
      setLength(tableHeaderList.length);
    }
    return () => setLength(0);
  }, []);

  return (
    <thead>
      <tr>
        {tableHeaderList.map((tableHeader) => (
          <TableHeader key={tableHeader.idx}>{tableHeader.title}</TableHeader>
        ))}
      </tr>
    </thead>
  );
};

export default ApplicationListTableHeader;
