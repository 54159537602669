import {
  ErrorBoundaryWrapper,
  SectionTitle,
  SectionTitleMain,
} from '@autosquare/common';
import { useTestCaseImport } from '@contexts/ide/TestCaseImportContext';
import React from 'react';

const CsvCompleteResult = () => {
  const { importFormCsvData } = useTestCaseImport();

  const tableHeaderList = [
    { id: 'number', label: 'No' },
    { id: 'test-case-field', label: 'Test Case Field' },
    { id: 'csv-field', label: 'CSV Field' },
  ];

  const tableBodyList = [
    {
      id: 'tcId',
      testCaseField: 'TC-ID*',
      csvField: importFormCsvData.tcId,
    },
    {
      id: 'title',
      testCaseField: 'Title*',
      csvField: importFormCsvData.title,
    },
    {
      id: 'description',
      testCaseField: 'Description',
      csvField: importFormCsvData?.description,
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="mt-4 h-full">
        <SectionTitle>
          <SectionTitleMain color="primary">Completed!</SectionTitleMain>
        </SectionTitle>
        <div
          className="grid w-full grid-cols-11 text-sm font-semibold leading-tight text-gray-900"
          role="table"
        >
          {tableHeaderList.map((header) => (
            <div
              key={header.id}
              role="column-header"
              className="col-span-5 border-b border-gray-300 py-3.5 text-start first:col-span-1"
            >
              {header.label}
            </div>
          ))}
          {tableBodyList.map((body, index) => (
            <div
              key={body.testCaseField}
              className="col-span-11 grid grid-cols-11 items-center border-b border-gray-300 font-normal text-gray-500"
              role="row"
            >
              <div className="col-span-1 py-4 text-gray-900" role="cell">
                {index + 1}
              </div>
              <div className="col-span-5 py-4" role="cell">
                {body.testCaseField}
              </div>
              <div className="col-span-5 py-4" role="cell">
                {body.csvField}
              </div>
            </div>
          ))}
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default CsvCompleteResult;
