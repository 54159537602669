import { Button } from '@autosquare/common';
import { useParentPath } from '@hooks/useParentPath';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  onDeleteButtonHandler: () => void;
  isAdmin: boolean;
  isButtonDisabled: boolean;
};

const ProjectApplicationButtonSection = ({
  onDeleteButtonHandler,
  isAdmin,
  isButtonDisabled,
}: Props) => {
  const parentPage = useParentPath();
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-end gap-x-3 pt-6">
      <Button
        type="button"
        variant="secondary"
        onClick={() => navigate(parentPage)}
        disabled={isButtonDisabled}
      >
        Cancel
      </Button>
      {isAdmin && (
        <>
          <Button
            type="button"
            variant="caution"
            onClick={onDeleteButtonHandler}
            disabled={isButtonDisabled}
          >
            Delete
          </Button>
          <Button
            type="submit"
            variant="primary"
            form="submitApplicationDetail"
            disabled={isButtonDisabled}
          >
            Save
          </Button>
        </>
      )}
    </div>
  );
};

export default ProjectApplicationButtonSection;
