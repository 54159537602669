import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Rect, Transformer } from 'react-konva';

import { useMobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';

import { canvasHandleCursor } from '@hooks/konva/canvasHandleCursor';
import { useFormContext } from 'react-hook-form';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Props = {
  isSelected: boolean;
  setIsSelected: Dispatch<SetStateAction<boolean>>;
  realScreenRatio: number;
};

const ComparingRect = ({
  isSelected,
  setIsSelected,
  realScreenRatio,
}: Props) => {
  const {
    startX,
    setStartX,
    startY,
    setStartY,
    endX,
    setEndX,
    endY,
    setEndY,
    isComparingRectHidden,
  } = useMobileTestStepContext();

  const { watch } = useFormContext<CreateTestStepData>();

  const value = watch('value');
  const conditionCommand = watch('optionsDto.conditionCommand');

  const listening =
    conditionCommand === MobileCommandOptionServerName.CompareImage
      ? value === 'undefined'
      : true;

  const rectRef = useRef(null);
  const trRef = useRef(null);

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([rectRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const onSelect = () => {
    setIsSelected(true);
  };

  const rectProps = {
    x: Math.round(startX * realScreenRatio),
    y: Math.round(startY * realScreenRatio),
    width: Math.round(
      Math.abs(endX * realScreenRatio - startX * realScreenRatio),
    ),
    height: Math.round(
      Math.abs(endY * realScreenRatio - startY * realScreenRatio),
    ),
    fill: '#FFA500',
    id: 'rect',
  };

  return (
    <ErrorBoundaryWrapper>
      <Rect
        ref={rectRef}
        {...rectProps}
        draggable
        onClick={onSelect}
        onTab={onSelect}
        opacity={0.5}
        onDragEnd={(e) => {
          setStartX(Math.ceil(e.target.x() / realScreenRatio));
          setStartY(Math.ceil(e.target.y() / realScreenRatio));
          setEndX(
            Math.ceil((e.target.x() + e.target.width()) / realScreenRatio),
          );
          setEndY(
            Math.ceil((e.target.y() + e.target.height()) / realScreenRatio),
          );
        }}
        onTransformEnd={() => {
          const node = rectRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);

          setStartX(Math.ceil(node.x() / realScreenRatio));
          setStartY(Math.ceil(node.y() / realScreenRatio));
          setEndX(
            Math.ceil((node.x() + node.width() * scaleX) / realScreenRatio),
          );
          setEndY(
            Math.ceil((node.y() + node.height() * scaleY) / realScreenRatio),
          );
        }}
        onMouseEnter={canvasHandleCursor}
        onMouseLeave={canvasHandleCursor}
        listening={listening}
        visible={isComparingRectHidden}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          keepRatio={false}
          flipEnabled={false}
          listening={listening}
          visible={isComparingRectHidden}
        />
      )}
    </ErrorBoundaryWrapper>
  );
};

export default ComparingRect;
