import { ErrorBoundaryWrapper } from '@autosquare/common';
import { DeviceListInProject } from '@customTypes/index';
import clsx from 'clsx';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthUserInfo } from '@hooks/index';
import AdminDeviceRow from './ProjectDeviceTableBody/AdminDeviceRow';
import MemberDeviceRow from './ProjectDeviceTableBody/MemberDeviceRow';
import DeviceDetailCell from './ProjectDeviceTableBody/DeviceDetailCell';

type Props = {
  device: DeviceListInProject;
  selectedArray: number[];
  selectArray: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  idx: number;
  isActivate: boolean;
};

const ProjectDeviceTableBody = ({
  device,
  selectedArray,
  selectArray,
  idx,
  isActivate,
}: Props) => {
  const navigate = useNavigate();
  const { userProjectRole } = useAuthUserInfo();
  const isAdmin = userProjectRole === 0;

  return (
    <ErrorBoundaryWrapper>
      <tr
        className={clsx(
          !device.enabled && isActivate ? 'bg-gray-100' : 'bg-white',
        )}
      >
        {isAdmin ? (
          <AdminDeviceRow
            device={device}
            selectedArray={selectedArray}
            selectArray={selectArray}
            idx={idx}
            isActivate={isActivate}
          />
        ) : (
          <MemberDeviceRow device={device} idx={idx} isActivate={isActivate} />
        )}
        <DeviceDetailCell
          deviceIdx={device.idx}
          isActivate={isActivate}
          navigate={navigate}
        />
      </tr>
    </ErrorBoundaryWrapper>
  );
};

export default ProjectDeviceTableBody;
