import { ErrorBoundaryWrapper } from '@autosquare/common';
import React from 'react';
import AdminDeviceListHeader from './components/AdminDeviceListHeader';
import DeviceAuthorization from './components/DeviceAuthorization';
import DeviceListTable from './components/DeviceListTable';

const AdminDeviceList = () => {
  return (
    <ErrorBoundaryWrapper>
      <AdminDeviceListHeader />
      <DeviceAuthorization />
      <DeviceListTable />
    </ErrorBoundaryWrapper>
  );
};

export default AdminDeviceList;
