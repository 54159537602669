import { useTestExecutionContext } from '@contexts/ide/TestExecutionProvider';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const TestResultSaveOptions = ({ children }: Props) => {
  const childCount = React.Children.count(children);
  const gridColsClass = `grid-cols-${Math.min(childCount, 3)}`;

  return (
    <section>
      <h3 className="border-b border-gray-100 pb-4 text-base font-semibold leading-7 text-gray-900">
        Test Result Save Options
      </h3>
      <div className={clsx('grid w-full', gridColsClass)}>{children}</div>
    </section>
  );
};

export const Video = () => {
  const { shouldSaveVideo, handleVideoChange } = useTestExecutionContext();

  return (
    <DefaultCheckbox
      title={'Video'}
      checked={shouldSaveVideo}
      onChange={handleVideoChange}
    />
  );
};

export const Screenshot = () => {
  const { shouldSaveScreenshot, handleScreenshotChange } =
    useTestExecutionContext();

  return (
    <DefaultCheckbox
      title={'Screenshot'}
      checked={shouldSaveScreenshot}
      onChange={handleScreenshotChange}
    />
  );
};

export const LogFile = () => {
  const { shouldSaveLogFile, handleLogFileChange } = useTestExecutionContext();

  return (
    <DefaultCheckbox
      title={'Log File'}
      checked={shouldSaveLogFile}
      onChange={handleLogFileChange}
    />
  );
};

type DefaultCheckboxProps = {
  title: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const DefaultCheckbox = ({
  title,
  checked,
  onChange,
}: DefaultCheckboxProps) => {
  return (
    <div className="flex items-center gap-x-3 py-4">
      <label htmlFor={title} className="text-sm font-medium leading-normal">
        {title}
      </label>
      <input
        type="checkbox"
        name={title}
        id={title}
        className="input-checkbox-congress-blue"
        defaultChecked={checked}
        onChange={onChange}
      />
    </div>
  );
};

TestResultSaveOptions.Video = Video;
TestResultSaveOptions.Screenshot = Screenshot;
TestResultSaveOptions.LogFile = LogFile;

export default TestResultSaveOptions;
