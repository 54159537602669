import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';

import TestStepValueListbox from '@components/IDE/TestStepValueListbox';
import DeviceKeyListBox from '@components/TestStep/DeviceKeyListBox';

import { MobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';

import { useTestStepValueList } from '@hooks/testStep/useTestStepValueList';

import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionName,
  mobileCommandOptions,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React, { useContext, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import ConfirmAndInputText from '../ConfirmAndInputText';
import ImportTestCaseCombobox from '@components/TestStep/ImportTestCaseCombobox';

type Props = {
  passCommand: MobileCommandOptionServerName;
  failCommand: MobileCommandOptionServerName;
  setFailCommand: React.Dispatch<
    React.SetStateAction<MobileCommandOptionServerName>
  >;
  valueDefaultText: (command: MobileCommandOptionServerName) => string;
};

const IfActionFail = ({
  passCommand,
  failCommand,
  setFailCommand,
  valueDefaultText,
}: Props) => {
  const { isFailListboxOpened, setIsFailListboxOpened } = useContext(
    MobileTestStepContext,
  );

  const { setValue, watch, control } = useFormContext<CreateTestStepData>();

  const passActionValue = watch('optionsDto.passActionValue');
  const failActionValue = watch('optionsDto.failActionValue');
  const maskingOptionsEnabled =
    watch('optionsDto.maskingOptions.enabled') === 'true';

  const failValueList = useTestStepValueList({
    command: failCommand,
  });

  useEffect(() => {
    return () => setValue('optionsDto.failActionValue', undefined);
  }, [failCommand, setValue]);

  useEffect(() => {
    if (failCommand === MobileCommandOptionServerName.Click) {
      setValue(
        'optionsDto.failActionOptions.elementOptions.bounds',
        failValueList.bounds,
      );
      return () => setValue('optionsDto.failActionOptions', undefined);
    }
  }, [setValue, failCommand, failActionValue]);

  return (
    <ErrorBoundaryWrapper>
      <div className="flex gap-4">
        <div className="mt-1 w-10 text-sm font-medium text-red-500">FAIL</div>
        <div className="flex w-[calc(100%-3.5rem)] flex-col gap-4">
          <ListBoxHeadlessInController
            value={failCommand}
            buttonValue={
              failCommand
                ? findMobileCommandNameFromServerName(failCommand)
                : 'Command'
            }
            onChange={setFailCommand}
            lists={mobileCommandOptions.filter(
              (mobileCommand) =>
                mobileCommand.name === MobileCommandOptionName.Click ||
                mobileCommand.name === MobileCommandOptionName.ImportTestCase ||
                mobileCommand.name === MobileCommandOptionName.DeviceKey,
              // mobileCommand.name === MobileCommandOptionName.InputText
            )}
            valueToSave={'serverName'}
            valueToShow={'name'}
            onClickOption={() =>
              setValue('optionsDto.failActionValue', undefined)
            }
            disabled={
              passCommand === undefined ||
              passActionValue === undefined ||
              passActionValue === 'Value' ||
              maskingOptionsEnabled
            }
          />
          {failCommand === MobileCommandOptionServerName.DeviceKey ? (
            <DeviceKeyListBox
              value={'optionsDto.failActionValue'}
              rules={{ required: false }}
            />
          ) : failCommand === MobileCommandOptionServerName.ImportTestCase ? (
            <ImportTestCaseCombobox
              value={'optionsDto.failActionValue'}
              rules={{ required: false }}
            />
          ) : (
            <Controller
              control={control}
              name={'optionsDto.failActionValue'}
              render={({ field }) => (
                <TestStepValueListbox
                  valueText={field.value || valueDefaultText(failCommand)}
                  setValueText={field.onChange}
                  totalValues={failValueList.totalValues}
                  isListboxOpened={isFailListboxOpened}
                  setIsListboxOpened={setIsFailListboxOpened}
                  resetOnClick={() => {
                    setFailCommand(undefined);
                    setValue('optionsDto.failActionValue', undefined);
                  }}
                  disabled={
                    passActionValue === undefined ||
                    passCommand === undefined ||
                    failCommand === undefined ||
                    failActionValue !== undefined ||
                    maskingOptionsEnabled
                  }
                  resetDisabled={maskingOptionsEnabled}
                />
              )}
            />
          )}
          {passCommand === MobileCommandOptionServerName.InputText && (
            <ConfirmAndInputText />
          )}
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default IfActionFail;
