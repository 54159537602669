import {
  ErrorBoundaryWrapper,
  ErrorMessage,
  RoleAuth,
  TableSkeleton,
} from '@autosquare/common';
import {
  useProjectMemberListQuery,
  useProjectSettingDetailQuery,
} from '@lib/api/dashboard/queryHooks';
import React from 'react';
import ProjectSettingsDetailForm from './ProjectSettingsDetailInformation/ProjectSettingsDetailForm';
import { useAuthUserInfo } from '@hooks/index';
import ProjectSettingsDetailNormal from './ProjectSettingsDetailInformation/ProjectSettingsDetailNormal';

const ProjectSettingsDetailInformation = () => {
  const projectSettingDetailQuery = useProjectSettingDetailQuery();
  const projectMemberListQuery = useProjectMemberListQuery();

  const { userProjectRole, userAuthRole } = useAuthUserInfo();

  if (projectSettingDetailQuery.isLoading || projectMemberListQuery.isLoading) {
    return <TableSkeleton />;
  }

  if (projectSettingDetailQuery.isError) {
    return (
      <ErrorMessage>{projectSettingDetailQuery.error.message}</ErrorMessage>
    );
  }

  if (projectMemberListQuery.isError) {
    return <ErrorMessage>{projectMemberListQuery.error.message}</ErrorMessage>;
  }

  if (userProjectRole === 0 || userAuthRole === RoleAuth.Admin) {
    return (
      <ErrorBoundaryWrapper>
        <ProjectSettingsDetailForm />
      </ErrorBoundaryWrapper>
    );
  }

  if (userProjectRole === 1) {
    return (
      <ErrorBoundaryWrapper>
        <ProjectSettingsDetailNormal />
      </ErrorBoundaryWrapper>
    );
  }
};

export default ProjectSettingsDetailInformation;
