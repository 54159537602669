export const projectKeys = {
  all: ['project'] as const,
  lists: () => [...projectKeys.all, 'list'] as const,
  details: () => [...projectKeys.all, 'detail'] as const,
  detail: (idx: string) => [...projectKeys.details(), idx] as const,
  projectMemberLists: () => [...projectKeys.all, 'projectMemberList'] as const,
  projectMemberList: (projectIdx: string) =>
    [...projectKeys.projectMemberLists(), projectIdx] as const,
  projectMemberDetails: () =>
    [...projectKeys.all, 'projectMemberDetail'] as const,
  projectMemberDetail: (projectIdx: string, idx: string) => [
    ...projectKeys.projectMemberDetails(),
    projectIdx,
    idx,
  ],
  groupMemberLists: () => [...projectKeys.all] as const,
  groupMemberList: (projectIdx: string) =>
    [...projectKeys.groupMemberLists(), projectIdx] as const,
  autoRegisters: (idx: string) =>
    [...projectKeys.all, 'autoRegisters', idx] as const,
  jiraPlatform: (idx: string, location: string) =>
    [...projectKeys.all, 'jiraPlatform', idx, location] as const,
  jiraIssueType: (idx: string, location: string) =>
    [...projectKeys.all, 'jiraIssueType', idx, location] as const,
  jiraPriority: (
    idx: string,
    location: string,
    projectKey: string,
    issueTypeName: string,
  ) =>
    [
      ...projectKeys.all,
      'jiraPriority',
      idx,
      location,
      projectKey,
      issueTypeName,
    ] as const,
};
