import { useState, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  useProjectMemberListQuery,
  useProjectSettingDetailQuery,
} from '@lib/api/dashboard/queryHooks';
import { UpdateProjectDetailSetting } from '@customTypes/index';
import { MailReceiver } from '@autosquare/common';

export const useDetailProjectAdmin = () => {
  const { data: projectSettingDetail } = useProjectSettingDetailQuery();
  const { data: projectMemberList } = useProjectMemberListQuery();

  const { setValue, clearErrors } =
    useFormContext<UpdateProjectDetailSetting>();

  const [projectAdminList, setProjectAdminList] = useState<MailReceiver[]>(
    projectSettingDetail.projectAdminList,
  );
  const [projectAdminInput, setProjectAdminInput] = useState('');
  const [isOpenedMemberList, setIsOpenedMemberList] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [temporarySavedEmailAddress, setTemporarySavedEmailAddress] =
    useState<MailReceiver>({ email: '', name: '' });

  const inputRef = useRef<HTMLInputElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const ulRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    setValue(
      'projectAdminList',
      projectAdminList.map((projectAdmin) => projectAdmin.email),
    );
    clearErrors('projectAdminList');
  }, [projectAdminList]);

  useEffect(() => {
    if (isOpenedMemberList && ulRef.current?.children[selectedIndex]) {
      ulRef.current.children[selectedIndex].scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      });
    }
  }, [selectedIndex, isOpenedMemberList]);

  const filteredPeople =
    projectAdminInput === ''
      ? projectMemberList?.filter(
          (adminUser) =>
            !projectAdminList.some(
              (projectAdmin) => projectAdmin.email === adminUser.userId,
            ),
        )
      : projectMemberList?.filter(
          (adminUser) =>
            !projectAdminList.some(
              (projectAdmin) => projectAdmin.email === adminUser.userId,
            ) &&
            (adminUser.userId
              .toLowerCase()
              .includes(projectAdminInput.toLowerCase()) ||
              adminUser.name
                .toLowerCase()
                .includes(projectAdminInput.toLowerCase())),
        );

  return {
    projectAdminList,
    setProjectAdminList,
    projectAdminInput,
    setProjectAdminInput,
    isOpenedMemberList,
    setIsOpenedMemberList,
    selectedIndex,
    setSelectedIndex,
    temporarySavedEmailAddress,
    setTemporarySavedEmailAddress,
    inputRef,
    wrapperRef,
    ulRef,
    filteredPeople,
  };
};
