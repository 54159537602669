import { RootState } from '@app/store';
import {
  ErrorBoundaryWrapper,
  RepeatStepComboboxHeadlessUI,
  StartAndFinishMark,
} from '@autosquare/common';
import { FilteredList } from '@autosquare/common/src/utils/type/repeatBox/type';

import { useRepeatSpeedMobileTestStep } from '@lib/api/ide/speedMobile/speedMobileTestStep';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

type Props = {
  startRepeatIdx: number;
  endRepeatIdx: number;
  filteredList: FilteredList[];
};

const FinishCombobox = ({
  startRepeatIdx,
  endRepeatIdx,
  filteredList,
}: Props) => {
  const deviceInfo = useSelector((state: RootState) => state.device.deviceInfo);

  const initialValue =
    endRepeatIdx === 0
      ? { idx: null, label: '', disabled: false }
      : filteredList?.find((item) => item.idx === endRepeatIdx);

  const [selectedValue, setSelectedValue] = useState(initialValue);

  const startIndex = filteredList?.findIndex(
    (item) => item?.idx === startRepeatIdx,
  );
  const filteredFinishList = filteredList?.map((item, index) => ({
    ...item,
    disabled: index <= startIndex || item.disabled,
  }));
  const saveRepeatTestStep = useRepeatSpeedMobileTestStep();

  const handleChange = (value: FilteredList) => {
    setSelectedValue(value);
    saveRepeatTestStep.mutate({
      startStepIdx: startRepeatIdx,
      endStepIdx: value === null ? 0 : value?.idx,
      mobileOs: deviceInfo.os,
    });
  };

  useEffect(() => {
    if (endRepeatIdx === 0) {
      setSelectedValue({ idx: null, label: '', disabled: false });
    }
  }, [endRepeatIdx]);

  return (
    <ErrorBoundaryWrapper>
      <div className="flex items-center gap-2.5">
        <StartAndFinishMark />
        <p className="w-12 text-xs font-medium  text-gray-900">Finish</p>
        <div className="w-full">
          <RepeatStepComboboxHeadlessUI
            value={selectedValue}
            filteredList={filteredFinishList}
            onChange={handleChange}
            disabledBox={startRepeatIdx === 0}
            findIndex={
              filteredFinishList?.find(
                (item) => item?.idx === selectedValue?.idx,
              )?.idx
            }
          />
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default FinishCombobox;
