import React, { useEffect } from 'react';
import {
  AppInfoEditableFields,
  AppInfoList,
  EditableFieldsSchema,
} from '@customTypes/dashboard/project/application/type';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UseMutationResult } from '@tanstack/react-query';
import { ServerResponseErrorDetails } from '@customTypes/type';

type Props = {
  detailData: AppInfoList;
  onSuccessHandler: () => void;
  list: { title: string; data: string; editable?: boolean }[];
  updateMutation: UseMutationResult<
    AppInfoList,
    ServerResponseErrorDetails,
    AppInfoList
  >;
};

const ProjectApplicationDetailForm = ({
  detailData,
  onSuccessHandler,
  list,
  updateMutation,
}: Props) => {
  const methods = useForm<AppInfoEditableFields>({
    resolver: zodResolver(EditableFieldsSchema),
  });

  const onSubmit: SubmitHandler<AppInfoEditableFields> = (data) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { description, ...rest } = detailData;

    const dataForSubmit = {
      ...rest,
      ...data,
    };

    updateMutation.mutate(dataForSubmit, {
      onSuccess: onSuccessHandler,
    });
  };

  useEffect(() => {
    if (detailData?.description) {
      methods.setValue('description', detailData?.description);
    }
  }, [detailData?.description]);
  return (
    <form
      onSubmit={methods.handleSubmit(onSubmit)}
      id="submitApplicationDetail"
    >
      <div className="space-y-6 border-t border-gray-200 pb-8 pt-4">
        {list.map((item) => (
          <div
            key={item.title}
            className="grid items-center gap-4 text-sm leading-normal text-gray-900 md:grid-cols-8"
          >
            <div className="font-medium">{item.title}</div>
            {item.editable ? (
              <>
                <div className="col-span-7 w-full">
                  <input
                    type="text"
                    className="input-base"
                    {...methods.register('description')}
                  />
                </div>
                <ErrorMessage
                  name="description"
                  errors={methods.formState.errors}
                  render={({ message }) => (
                    <p className="error-message col-start-5">{message}</p>
                  )}
                />
              </>
            ) : (
              <div className="whitespace-nowrap font-normal">{item.data}</div>
            )}
          </div>
        ))}
      </div>
    </form>
  );
};

export default ProjectApplicationDetailForm;
