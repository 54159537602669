import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  AdminUserStatus,
  UpdateAdminUserInfoSchema,
} from '@customTypes/dashboard/admin/type';
import { UpdateAdminUserInfo } from '@customTypes/index';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import AdminUserInformationFormContents from './AdminUserInformationForm/AdminUserInformationFormContents';
import AdminUserInformationFormButton from './AdminUserInformationForm/AdminUserInformationFormButton';
import {
  useAdminUserDetailQuery,
  useUpdateAdminUserDetailMutation,
} from '@lib/api/dashboard/queryHooks';
import { ErrorDialog } from '@components/Dialog';

const AdminUserInformationForm = () => {
  const { data: adminUserDetail } = useAdminUserDetailQuery();

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const methods = useForm<UpdateAdminUserInfo>({
    resolver: zodResolver(UpdateAdminUserInfoSchema),
    defaultValues: {
      role: adminUserDetail.role,
      jobTitle: adminUserDetail.jobTitle,
      organization: adminUserDetail.organization,
      department: adminUserDetail.department,
      status: adminUserDetail.status === AdminUserStatus.Activate,
    },
  });

  const updateAdminUserDetailMutation = useUpdateAdminUserDetailMutation();

  const onSubmit: SubmitHandler<UpdateAdminUserInfo> = (data) => {
    const onError = () => {
      setIsErrorDialogOpen(true);
    };

    if (data.status) {
      updateAdminUserDetailMutation.mutate(data, { onError });
    } else {
      updateAdminUserDetailMutation.mutate(
        {
          status: data.status,
          role: adminUserDetail.role,
          jobTitle: adminUserDetail.jobTitle,
          organization: adminUserDetail.organization,
          department: adminUserDetail.department,
        },
        { onError },
      );
    }
  };

  return (
    <ErrorBoundaryWrapper>
      <FormProvider {...methods}>
        <form
          className="text-sm font-medium leading-normal text-gray-900"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <AdminUserInformationFormContents />
          <ErrorDialog
            isOpen={isErrorDialogOpen}
            setIsOpen={setIsErrorDialogOpen}
            subTitle={updateAdminUserDetailMutation.error?.message}
          />
          <AdminUserInformationFormButton
            isUpdateLoading={updateAdminUserDetailMutation.isLoading}
          />
        </form>
      </FormProvider>
    </ErrorBoundaryWrapper>
  );
};

export default AdminUserInformationForm;
