import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';

import DefinitionList from '@components/shared/DefinitionList';
import DivisionWord from '@components/shared/TestStepDetail/DivisionWord';
import ControllerTextArea from '@components/shared/TextArea/ControllerTextArea';

import {
  deviceKeyList,
  deviceKeyListInIos,
  DeviceKeyServerName,
  findListNameFromServerName,
  findListNameFromServerNameInIos,
} from '@utils/static/deviceKeyList';
import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionName,
  mobileCommandOptions,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';

import {
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { DeviceInfoOs } from '@customTypes/ide/device/device';

import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ImportTestCaseCombobox from '@components/TestStep/ImportTestCaseCombobox';

type Props = {
  splitValue: (value: string, index: number) => MobileCommandOptionServerName;
  selectedFailCommand: MobileCommandOptionServerName;
  setSelectedFailCommand: React.Dispatch<
    React.SetStateAction<MobileCommandOptionServerName>
  >;
  mobileTestStepData: MobileTestStepData;
};

const IfFailAction = ({
  splitValue,
  selectedFailCommand,
  setSelectedFailCommand,
  mobileTestStepData,
}: Props) => {
  const {
    watch,
    control,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext<UpdateTestStepData>();

  const mobileOs = watch('mobileOs');

  const defaultFailCommand = splitValue(mobileTestStepData?.value, 1);
  const commandNameFromServerName =
    findMobileCommandNameFromServerName(defaultFailCommand);
  const failActionValue = mobileTestStepData?.optionsDto?.failActionValue;

  const handleClickOption = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const target = e.target as HTMLElement;
    const textContent = target.textContent;

    if (textContent === commandNameFromServerName) {
      setValue('optionsDto.failActionValue', failActionValue);
    } else {
      setValue('optionsDto.failActionValue', '');
    }
  };

  useEffect(() => {
    if (
      selectedFailCommand !== defaultFailCommand ||
      (selectedFailCommand === defaultFailCommand &&
        watch('optionsDto.failActionValue') !== '')
    ) {
      clearErrors('optionsDto.failActionValue');
    }
  }, [selectedFailCommand, clearErrors]);

  return (
    <ErrorBoundaryWrapper>
      <DivisionWord color="red">FAIL</DivisionWord>
      <DefinitionList
        term={'Command'}
        description={
          <ListBoxHeadlessInController
            value={selectedFailCommand}
            buttonValue={findMobileCommandNameFromServerName(
              selectedFailCommand,
            )}
            onChange={setSelectedFailCommand}
            lists={mobileCommandOptions.filter(
              (command) =>
                command.name === MobileCommandOptionName.Click ||
                command.name === MobileCommandOptionName.ImportTestCase ||
                command.name === MobileCommandOptionName.DeviceKey,
              // command.name === MobileCommandOptionName.InputText
            )}
            valueToSave={'serverName'}
            valueToShow={'name'}
            onClickOption={handleClickOption}
          />
        }
      />
      <DefinitionList
        term={'Value*'}
        description={
          selectedFailCommand ===
          MobileCommandOptionServerName.ImportTestCase ? (
            <ImportTestCaseCombobox value="optionsDto.failActionValue" />
          ) : (
            <Controller
              control={control}
              name="optionsDto.failActionValue"
              rules={{
                required:
                  selectedFailCommand === MobileCommandOptionServerName.Click
                    ? 'Value 값을 입력해 주세요.'
                    : selectedFailCommand ===
                        MobileCommandOptionServerName.DeviceKey &&
                      'Key Event를 선택해 주세요.',
              }}
              render={({ field }) =>
                selectedFailCommand === MobileCommandOptionServerName.Click ? (
                  <ControllerTextArea field={field} />
                ) : (
                  selectedFailCommand ===
                    MobileCommandOptionServerName.DeviceKey && (
                    <ErrorBoundaryWrapper>
                      <ListBoxHeadlessInController
                        value={field.value}
                        buttonValue={
                          mobileOs === DeviceInfoOs.Aos
                            ? findListNameFromServerName(
                                field.value as DeviceKeyServerName,
                              ) || 'Select Key Event'
                            : findListNameFromServerNameInIos(
                                field.value as DeviceKeyServerName,
                              ) || 'Select Key Event'
                        }
                        onChange={field.onChange}
                        lists={
                          mobileOs === DeviceInfoOs.Aos
                            ? deviceKeyList.slice(1)
                            : deviceKeyListInIos.slice(1)
                        }
                        valueToSave={'serverName'}
                        valueToShow={'listName'}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={'optionsDto.failActionValue'}
                        render={({ message }) => (
                          <p className="standard-error-message">{message}</p>
                        )}
                      />
                    </ErrorBoundaryWrapper>
                  )
                )
              }
            />
          )
        }
      />
    </ErrorBoundaryWrapper>
  );
};

export default IfFailAction;
