import { ErrorBoundaryWrapper, ErrorMessage } from '@autosquare/common';

import React, { useState } from 'react';

import { useDeviceListQuery } from '@lib/api/ide/mobile/mobileDevice';

import Device from './components/Device';
import DeviceListHeader from './components/DeviceListHeader';
import DeviceListSkeleton from './components/DeviceListSkeleton';
import { useRemoteDeviceListQuery } from '@lib/api/ide/remote/remote';
import { Mode } from '@customTypes/ide/remote/type';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';
import { getRemoteState } from '@utils/static/getConfiguration';

const DeviceList = () => {
  const [isElectronErrorMessage, setIsElectronErrorMessage] = useState('');
  const { configuration } = getRemoteState();
  const deviceListQuery =
    configuration === Mode.Standard
      ? useDeviceListQuery()
      : useRemoteDeviceListQuery();
  const deviceLists = deviceListQuery.data;

  return (
    <ErrorBoundaryWrapper>
      <DeviceListHeader
        deviceListQuery={deviceListQuery}
        setIsElectronErrorMessage={setIsElectronErrorMessage}
      />
      {deviceListQuery.isLoading || deviceListQuery.isFetching ? (
        <DeviceListSkeleton />
      ) : deviceListQuery.isError ? (
        <ErrorMessage>{deviceListQuery.error.message}</ErrorMessage>
      ) : isElectronErrorMessage ? (
        <ErrorMessage>{isElectronErrorMessage}</ErrorMessage>
      ) : (
        <Device deviceLists={deviceLists} />
      )}
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default DeviceList;
