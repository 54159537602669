import { API } from '@api/endpoints';
import {
  AddMemberData,
  ProjectMember,
  ProjectMemberList,
  UpdateMemberInfoData,
} from '@customTypes/dashboard/project/type';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { axiosInstance } from '@lib/api/dashboard';
import { projectKeys } from '@queries/dashboard/project/projectKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

// GET
// 프로젝트 맴버 리스트 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Project/getProjectMembers
const getProjectMemberList = async (
  projectIdx: string,
): Promise<ProjectMemberList[]> => {
  const response = await axiosInstance.get<ProjectMemberList[]>(
    API.getMembers(projectIdx),
  );
  return response.data;
};
export const useProjectMemberListQuery = () => {
  const { projectIdx } = useParams();

  return useQuery<ProjectMemberList[], ServerResponseErrorDetails>({
    queryKey: projectKeys.projectMemberList(projectIdx),
    queryFn: () => getProjectMemberList(projectIdx),
  });
};

// 프로젝트 멤버 상세조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Project/getProjectMemberDetail
const getProjectMemberDetail = async (
  projectIdx: string,
  idx: string,
): Promise<ProjectMemberList> => {
  const response = await axiosInstance.get<ProjectMemberList>(
    API.getMemberDetail(projectIdx, idx),
  );
  return response.data;
};
export const useProjectMemberDetailQuery = () => {
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  return useQuery<ProjectMemberList, ServerResponseErrorDetails>({
    queryKey: projectKeys.projectMemberDetail(projectIdx, idx),
    queryFn: () => getProjectMemberDetail(projectIdx, idx),
  });
};

// POST
// 프로젝트 멤버 추가
// https://dev.t-square.co.kr/swagger-ui/index.html#/Project/addProjectMember
const addMemberProjectMember = async (
  projectIdx: string,
  data: AddMemberData,
): Promise<ProjectMember> => {
  const response = await axiosInstance.post(
    API.postProjectMember(projectIdx),
    data,
  );
  return response.data;
};

export const useAddProjectMemberMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { projectIdx } = useParams();
  const { pathname } = useLocation();

  const memberListPage = pathname.split('/').slice(0, -1).join('/');

  return useMutation<ProjectMember, ServerResponseErrorDetails, AddMemberData>({
    mutationFn: (data) => addMemberProjectMember(projectIdx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(projectKeys.all);
      navigate(memberListPage);
    },
  });
};

// PUT
// 프로젝트 멤버 정보 수정
// https://dev.t-square.co.kr/swagger-ui/index.html#/Project/updateProjectMemberInfo
const updateMemberInfo = async (
  projectIdx: string,
  idx: string,
  data: UpdateMemberInfoData,
): Promise<UpdateMemberInfoData> => {
  const response = await axiosInstance.put(
    API.putMemberInfo(projectIdx, idx),
    data,
  );
  return response.data;
};
export const useUpdateMemberInfoMutation = () => {
  const queryClient = useQueryClient();
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  return useMutation<
    UpdateMemberInfoData,
    ServerResponseErrorDetails,
    UpdateMemberInfoData
  >({
    mutationFn: (data) => updateMemberInfo(projectIdx, idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(projectKeys.all);
    },
  });
};

// 프로젝트 멤버 삭제
// https://dev.t-square.co.kr/swagger-ui/index.html#/Project/deleteProjectMember
const deleteMemberInfo = async (
  projectIdx: string,
  idx: string,
): Promise<unknown> => {
  const response = await axiosInstance.delete(
    API.removeMember(projectIdx, idx),
  );
  return response.data;
};
export const useRemoveMemberMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const memberListPage = pathname.split('/').slice(0, -1).join('/');

  return useMutation<unknown, ServerResponseErrorDetails>({
    mutationFn: () => deleteMemberInfo(projectIdx, idx),
    onSuccess: () => {
      queryClient.invalidateQueries(projectKeys.projectMemberLists());
      navigate(memberListPage);
    },
  });
};
