import {
  Breadcrumbs,
  ErrorBoundaryWrapper,
  PageHeadingButtons,
  PagingHeadingsNavigate,
} from '@autosquare/common';
import { useReservedDeviceListQuery } from '@lib/api/ide/queryHooks';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ReservationDeviceListHeader = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { data: reservationDeviceList, isSuccess } =
    useReservedDeviceListQuery();

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'Devices', href: pathname },
    { title: 'List', href: pathname },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: 'Reserve',
      onClick: () => {
        navigate('reserve');
      },
      disabled: !isSuccess || !reservationDeviceList?.authorizationEnabled,
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Device List'}
        pageHeadingButtons={pageHeadingButtons}
      />
    </ErrorBoundaryWrapper>
  );
};

export default ReservationDeviceListHeader;
