import { API } from '@api/endpoints';
import { axiosRawResponseInstance } from '..';
import { useQuery } from '@tanstack/react-query';
import {
  AdminSystemSettingsData,
  ServerResponseErrorDetails,
} from '@customTypes/index';
import { settingKeys } from '@queries/index';

type DeviceSystemSettingsData = Pick<AdminSystemSettingsData, 'device'>;

// GET
// Device Authorization Setting 값 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Settings/getDeviceSetting
const getDeviceAuthorizationSetting =
  async (): Promise<DeviceSystemSettingsData> => {
    const response = await axiosRawResponseInstance.get(
      `${API.SETTINGS}authorization/device`,
    );
    return response.data;
  };
export const useDeviceAuthorizationSettingQuery = () => {
  return useQuery<DeviceSystemSettingsData, ServerResponseErrorDetails>({
    queryKey: settingKeys.deviceAuthorizationSetting(),
    queryFn: getDeviceAuthorizationSetting,
  });
};
