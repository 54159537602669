import { ErrorBoundaryWrapper, PagingHeadings } from '@autosquare/common';
import { useParentPath } from '@hooks/index';
import React from 'react';
import { useLocation } from 'react-router-dom';

const ProjectAddDevicePagingHeader = () => {
  const { pathname } = useLocation();
  const deviceListPage = useParentPath();

  const breadcrumbs = [
    { title: 'Project Settings', href: deviceListPage },
    { title: 'Devices', href: deviceListPage },
    { title: 'Add Device', href: pathname },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings breadcrumbs={breadcrumbs} pageTitle={'Add Device'} />
    </ErrorBoundaryWrapper>
  );
};

export default ProjectAddDevicePagingHeader;
