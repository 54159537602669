import React from 'react';
import clsx from 'clsx';
import OverviewSubTitle from '@components/shared/OverviewSubTitle';
import OverviewSubTitleHeader from '@components/shared/OverviewSubTitleHeader';
import {
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
} from '@heroicons/react/20/solid';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

const OverviewGraphSkeleton = () => {
  const biFunctionalHeader = ['Mobile', 'Web'];
  const isUiLocation = isWordInLocationPathname('ui-test');
  const isSpeedTestLocation = isWordInLocationPathname('speed-test');

  return (
    <div
      className={clsx(
        'flow-root',
        (isUiLocation || isSpeedTestLocation) && 'mt-8',
      )}
    >
      <OverviewSubTitle
        subTitle={'Result Rate'}
        description="프로젝트 테스트 결과 비율"
      />
      <div className="grid grid-cols-2 gap-x-7">
        {Array.from({ length: 2 }, (_, i) => (
          <div className="flex flex-col gap-y-3" key={i}>
            <OverviewSubTitleHeader header={biFunctionalHeader[i]} />
            <div className="mt-10 flex flex-col items-center justify-center space-y-3">
              <div className="relative flex items-center justify-center">
                <div
                  className={clsx(
                    'size-[14.375rem] animate-pulse rounded-full bg-gray-200 2xl:size-[16.473rem]',
                  )}
                />
              </div>
            </div>
          </div>
        ))}
        <div className={clsx('col-span-2 mt-16')}>
          <OverviewSubTitle
            subTitle={'Environment'}
            description="테스트 환경"
          />
          <div className={clsx('grid grid-cols-2 gap-x-3')}>
            <div className="flex h-7 animate-pulse rounded-2xl bg-gray-200" />
            <div className="flex h-7 animate-pulse rounded-2xl bg-gray-200" />
          </div>
          <div className="mt-8 flow-root">
            <OverviewSubTitle
              subTitle={'Test Breakdown'}
              description="프로젝트 내 스케줄러, 시나리오, 케이스 개수"
            />
            <div
              className={clsx(
                'grid w-full grid-cols-3 gap-x-4 space-y-2.5 2xl:gap-12',
              )}
            >
              {['Scheduler', 'Test Scenario', 'Test Case'].map((type) => (
                <div
                  key={type}
                  className={clsx(
                    'grid w-full grid-cols-1 gap-x-2.5 space-y-2.5',
                  )}
                >
                  <OverviewSubTitle
                    subTitle={type}
                    description={`0개`}
                    isSubCategory
                  />
                  <div className="flex items-center justify-start gap-x-3">
                    <DevicePhoneMobileIcon className="size-6" />
                    <div className="flex w-full items-center">
                      <div className="h-6 w-full animate-pulse rounded-2xl bg-gray-200" />
                    </div>
                  </div>
                  <div className="flex items-center justify-start gap-x-3">
                    <ComputerDesktopIcon className="size-6" />
                    <div className="flex w-full items-center">
                      <div className="h-6 w-full animate-pulse rounded-2xl bg-gray-200" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {isUiLocation ? (
        <div className={'grid grid-cols-1 gap-x-3 2xl:grid-cols-2'}>
          <div className="mt-8 pb-3">
            <div className="flex items-center space-x-2">
              <p className="text-sm font-semibold leading-6 text-gray-900">
                Mobile
              </p>
              <svg
                className="size-5 shrink-0 text-gray-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <p className="truncate text-sm text-gray-500">
                최근 테스트 결과 목록
              </p>
            </div>
            <div className="w-full animate-pulse">
              {Array.from({ length: 6 }, (_, i) => (
                <div
                  className="my-6 h-4 rounded odd:bg-gray-200 even:bg-gray-300"
                  key={i}
                />
              ))}
            </div>
          </div>
          <div className="mt-8 pb-3">
            <div className="flex items-center space-x-2">
              <p className="text-sm font-semibold leading-6 text-gray-900">
                Web
              </p>
              <svg
                className="size-5 shrink-0 text-gray-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <p className="truncate text-sm text-gray-500">
                최근 테스트 결과 목록
              </p>
            </div>
            <div className="w-full animate-pulse">
              {Array.from({ length: 6 }, (_, i) => (
                <div
                  key={i}
                  className="my-6 h-4 rounded odd:bg-gray-200 even:bg-gray-300"
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-8 flow-root">
          <OverviewSubTitle
            subTitle={'Recent Test Results'}
            description="최근 테스트 결과 목록"
          />
          <div className="relative w-full animate-pulse">
            <div className="relative flex w-[8.6rem] rounded-md bg-slate-100 px-2 py-1.5">
              <div className="absolute left-2 top-1 h-4/5 w-12 rounded-md shadow-sm transition-transform duration-300 ease-out" />
              {Array.from({ length: 1 }, (_, i) => (
                <div
                  key={i}
                  className="relative z-10 mr-2 h-8 w-14 rounded-md bg-gray-300"
                />
              ))}
            </div>
            <div className="mt-3 rounded-md border border-gray-100 p-6">
              <div className="space-y-3">
                {Array.from({ length: 3 }, (_, i) => (
                  <div key={i} className="h-4 w-full rounded-md bg-gray-300" />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OverviewGraphSkeleton;
