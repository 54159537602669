import { TableHeader, TableHeaderContainer } from '@autosquare/common';
import React, { useEffect } from 'react';

type Props = {
  setHeaderLength: React.Dispatch<React.SetStateAction<number>>;
};

const ReservationDeviceTableHeader = ({ setHeaderLength }: Props) => {
  const headerListWithoutIdx = [
    { title: 'No' },
    { title: 'Model' },
    { title: 'Name' },
    { title: 'OS' },
    { title: 'User' },
    { title: 'Status' },
    { title: '' },
  ];

  const headerList = headerListWithoutIdx.map((header, idx) => ({
    ...header,
    idx: idx + 1,
  }));

  useEffect(() => {
    setHeaderLength(headerList.length);
  }, [headerList, setHeaderLength]);

  return (
    <TableHeaderContainer>
      {headerList.map((header) => (
        <TableHeader key={header.idx}>{header.title}</TableHeader>
      ))}
    </TableHeaderContainer>
  );
};

export default ReservationDeviceTableHeader;
