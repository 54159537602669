import { ErrorBoundaryWrapper } from '@autosquare/common';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ErrorMessage } from '@hookform/error-message';

import React from 'react';
import { useFormContext } from 'react-hook-form';

const TestStepDescriptionHookForm = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CreateTestStepData>();

  return (
    <ErrorBoundaryWrapper>
      <div className="w-full">
        <label htmlFor="url" className="sr-only">
          Description
        </label>
        <input
          type="text"
          name="description"
          id="description"
          className="input-base"
          placeholder="Description"
          {...register('description', {
            required: 'Description은 1자에서 200자 이내로 입력해 주세요.',
            maxLength: {
              value: 200,
              message: 'Description은 1자에서 200자 이내로 입력해 주세요.',
            },
          })}
        />
        <ErrorMessage
          errors={errors}
          name="description"
          render={({ message }) => (
            <p className="error-message pt-2">{message}</p>
          )}
        />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default TestStepDescriptionHookForm;
