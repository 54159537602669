import React from 'react';
import SpeedSchedulerDetailDays from './SpeedTestSchedulerDetailSchedule/SpeedSchedulerDetailDays';
import SpeedSchedulerDetailPreview from './SpeedTestSchedulerDetailSchedule/SpeedSchedulerDetailPreview';

import SpeedSchedulerDetailRunningCycle from './SpeedTestSchedulerDetailSchedule/SpeedSchedulerDetailRunningCycle';
import SpeedSchedulerDetailStartDate from './SpeedTestSchedulerDetailSchedule/SpeedSchedulerDetailStartDate';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const SpeedTestSchedulerDetailSchedule = () => {
  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer title={'Schedule'}>
        <div className="sm:grid sm:grid-cols-10 sm:grid-rows-2 sm:items-start sm:gap-x-8">
          <SpeedSchedulerDetailStartDate />
          <SpeedSchedulerDetailRunningCycle />
          <SpeedSchedulerDetailPreview />
          <SpeedSchedulerDetailDays />
        </div>
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default SpeedTestSchedulerDetailSchedule;
