import {
  ErrorBoundaryWrapper,
  ErrorMessage,
  TableSkeleton,
} from '@autosquare/common';
import { useAdminSettingsQuery } from '@lib/api/dashboard/queryHooks';
import React from 'react';
import AdminDataStorageSettingDataForm from './AdminDataStorageSettingDataLoader/AdminDataStorageSettingDataForm';

const AdminDataStorageSettingDataLoader = () => {
  const { error, isError, isLoading } = useAdminSettingsQuery(
    'test_results_data_period',
  );

  if (isLoading) {
    return <TableSkeleton />;
  }

  if (isError) {
    return <ErrorMessage>{error.message}</ErrorMessage>;
  }

  return (
    <ErrorBoundaryWrapper>
      <AdminDataStorageSettingDataForm />
    </ErrorBoundaryWrapper>
  );
};

export default AdminDataStorageSettingDataLoader;
