import { createQueryKeys } from '@queries/createQueryKeys';

export const mobileSpeedTestResultKeys = {
  ...createQueryKeys('mobileSpeedTestResultKeys'),
  result: (projectIdx: string, startDateFormat: string) =>
    [...mobileSpeedTestResultKeys.all, projectIdx, startDateFormat] as const,
  detailInfos: () => [...mobileSpeedTestResultKeys.all, 'detailInfo'] as const,
  detailInfo: (idx: string) =>
    [...mobileSpeedTestResultKeys.detailInfos(), idx] as const,
  detailVideo: (path: string[], chartIndex: number) =>
    [
      [...mobileSpeedTestResultKeys.detailInfos(), path, chartIndex],
      'video',
    ] as const,
  detailLog: (path: string, chartIndex: number) =>
    [
      [...mobileSpeedTestResultKeys.detailInfos(), path, chartIndex],
      'log',
    ] as const,
};
