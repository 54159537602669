import { ComboboxUI, ErrorBoundaryWrapper } from '@autosquare/common';

import {
  CreateWebTestStepData,
  UpdateWebTestStepData,
  WebTestStepOptionsDto,
} from '@customTypes/testStep/type';

import { useWebTestCaseListQuery } from '@lib/api/ide/web/webTestCase';

import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useState } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

type ValueKeys =
  | keyof Pick<CreateWebTestStepData, 'value'>
  | keyof Pick<UpdateWebTestStepData, 'value'>
  | `optionsDto.${keyof Pick<
      WebTestStepOptionsDto,
      'passActionValue' | 'failActionValue'
    >}`;

type Props = {
  value?: ValueKeys;
  rules?:
    | Omit<
        RegisterOptions<CreateWebTestStepData, ValueKeys>,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
      >
    | Omit<
        RegisterOptions<UpdateWebTestStepData, ValueKeys>,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
      >;
};

const WebTestStepImportTestCaseListBox = ({
  value = 'value',
  rules = { required: 'Test Case를 선택해주세요.' },
}: Props) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const [query, setQuery] = useState('');

  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateWebTestStepData | UpdateWebTestStepData>();

  const webTestCaseListQuery = useWebTestCaseListQuery();

  const webTestCaseList = webTestCaseListQuery.data?.filter(
    (webTestCase) => webTestCase.idx !== Number(idx),
  );

  const findTitleFromIdxByWebTestCase = (idx: number) =>
    webTestCaseList?.find((webTestCase) => webTestCase.idx === idx)?.title;

  const filteredList = webTestCaseListQuery.isSuccess
    ? query === ''
      ? webTestCaseList
      : webTestCaseList?.filter((webTestCase) =>
          webTestCase.title.toLowerCase().includes(query.toLowerCase()),
        )
    : [];

  useEffect(() => {
    return () => {
      clearErrors(value);
    };
  }, [clearErrors]);

  return (
    <ErrorBoundaryWrapper>
      <Controller
        control={control}
        name={value}
        rules={rules}
        render={({ field }) => (
          <ComboboxUI
            query={query}
            setQuery={setQuery}
            filteredList={filteredList}
            findTitleFromIdx={findTitleFromIdxByWebTestCase}
            isLoading={webTestCaseListQuery.isLoading}
            isError={webTestCaseListQuery.isError}
            error={webTestCaseListQuery.error}
            value={field.value || ''}
            onChange={field.onChange}
            valueToSave={'idx'}
            valueToShow={'title'}
            placeholder={'Select Test Case'}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={value}
        render={({ message }) => (
          <p className="error-message !mt-2">{message}</p>
        )}
      />
    </ErrorBoundaryWrapper>
  );
};

export default WebTestStepImportTestCaseListBox;
