import {
  ArrowSpinner,
  Breadcrumbs,
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorMessage,
  PageHeadingButtons,
  PagingHeadingButtonOnClick,
  TableSkeleton,
} from '@autosquare/common';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '@app/store';
import { getTestCaseIndexArrayInDetail } from '@store/ide/numberArraySlice';
import {
  useDeleteMobileTestScenarioMutation,
  useMobileTestScenarioDetailQuery,
  useUpdateMobileTestScenarioMutation,
} from '@lib/api/ide/mobile/mobileTestScenario';
import {
  useWebTestScenarioDetailQuery,
  useUpdateWebTestScenarioMutation,
  useDeleteWebTestScenarioMutation,
} from '@lib/api/ide/web/webTestScenario';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import DetailInput from './components/DetailInput';
import DetailDnd from './components/DetailDnd';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { UpdateTestScenarioData } from '@customTypes/testScenario/type';
import { AutoRegisterInfoData } from '@customTypes/ide/scheduler/scheduler';

const TestScenarioDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const testScenarioDetailQuery = isWordInLocationPathname('mobile')
    ? useMobileTestScenarioDetailQuery(idx)
    : useWebTestScenarioDetailQuery(idx);

  const scenarioDetails = testScenarioDetailQuery.data;
  const step = scenarioDetails?.step;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTestCaseIndexArrayInDetail(step));
  }, [step]);

  const [autoRegistersList, setAutoRegistersList] = useState<
    AutoRegisterInfoData[]
  >([]);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const openModal = () => {
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const testCaseIdxs = useSelector(
    (state: RootState) => state.numberArray.testCaseIndexArray,
  );

  const methods = useForm<UpdateTestScenarioData>({
    defaultValues: {
      title: scenarioDetails?.title,
      description: scenarioDetails?.description,
      testCaseIdxs: testCaseIdxs,
    },
  });

  const updateTestScenarioMutation = isWordInLocationPathname('mobile')
    ? useUpdateMobileTestScenarioMutation(idx)
    : useUpdateWebTestScenarioMutation(idx);

  const onSubmit: SubmitHandler<UpdateTestScenarioData> = (data) => {
    const autoRegistersListWithoutId = autoRegistersList.map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ id, ...rest }) => rest,
    );
    const updatedData = {
      ...data,
      autoRegisters: autoRegistersListWithoutId,
      testCaseIdxs: testCaseIdxs,
    };

    if (testCaseIdxs.length === 0) return;
    updateTestScenarioMutation.mutate(updatedData);
  };

  const deleteTestScenarioMutation = isWordInLocationPathname('mobile')
    ? useDeleteMobileTestScenarioMutation([Number(idx)])
    : useDeleteWebTestScenarioMutation([Number(idx)]);

  const breadcrumbs: Breadcrumbs[] = [
    {
      title: 'UI Test',
      href: location.pathname.split('/').slice(0, -3).join('/') + '/overview',
    },
    {
      title: isWordInLocationPathname('mobile') ? 'Mobile' : 'Web',
      href: location.pathname.split('/').slice(0, -1).join('/'),
    },
    {
      title: 'Test Scenario',
      href: location.pathname.split('/').slice(0, -1).join('/'),
    },
    {
      title: 'Detail',
      href: location.pathname + location.search,
    },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: 'Delete',
      type: 'button',
      variant: 'caution',
      onClick: () => {
        setIsModalOpened(true);
      },
    },
    { title: 'Save', type: 'submit' },
  ];

  useEffect(() => {
    if (scenarioDetails) {
      methods.setValue('title', scenarioDetails?.title);
      methods.setValue('description', scenarioDetails?.description);
      setAutoRegistersList(scenarioDetails.autoRegisters);
    }
  }, [scenarioDetails]);

  useEffect(() => {
    if (methods.formState.isSubmitted && testCaseIdxs.length === 0) {
      methods.setError('testCaseIdxs', {
        type: 'required',
        message: '필수 선택 항목입니다.',
      });
    }
    if (methods.formState.isSubmitted && testCaseIdxs.length > 0) {
      methods.clearErrors('testCaseIdxs');
    }
  }, [methods.formState.isSubmitted, testCaseIdxs]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <PagingHeadingButtonOnClick
            breadcrumbs={breadcrumbs}
            pageTitle={'Test Scenario Detail'}
            pageHeadingButtons={pageHeadingButtons}
          />
          {updateTestScenarioMutation.isLoading ||
          deleteTestScenarioMutation.isLoading ? (
            <ArrowSpinner />
          ) : updateTestScenarioMutation.isError ? (
            <ErrorMessage>
              {updateTestScenarioMutation.error.message}
            </ErrorMessage>
          ) : (
            deleteTestScenarioMutation.isError && (
              <ErrorMessage>
                {deleteTestScenarioMutation.error.message}
              </ErrorMessage>
            )
          )}
          {testScenarioDetailQuery.isLoading ||
          testScenarioDetailQuery.isFetching ? (
            <TableSkeleton />
          ) : testScenarioDetailQuery.isError ? (
            <div>{testScenarioDetailQuery.error.message}</div>
          ) : (
            <>
              <DetailInput
                autoRegistersList={autoRegistersList}
                setAutoRegistersList={setAutoRegistersList}
              />
              <DetailDnd />
            </>
          )}
        </form>
      </FormProvider>
      {
        <DoubleCheckDialogHeadlessUI
          isOpened={isModalOpened}
          setIsOpened={setIsModalOpened}
          type={'caution'}
          title={
            deleteTestScenarioMutation.isError
              ? 'Error'
              : 'Delete Test Scenario'
          }
          subTitle={
            deleteTestScenarioMutation.isError
              ? deleteTestScenarioMutation.error.message
              : '해당 테스트 시나리오를 삭제하시겠습니까?'
          }
          buttonChildren={
            <>
              {deleteTestScenarioMutation.isError ? undefined : (
                <Button
                  type="button"
                  variant="caution"
                  onClick={() =>
                    deleteTestScenarioMutation.mutate(undefined, {
                      onSuccess: () => {
                        closeModal();
                        navigate(
                          location.pathname.split('/').slice(0, -1).join('/'),
                        );
                      },
                      onError: () => {
                        openModal();
                      },
                    })
                  }
                >
                  Delete
                </Button>
              )}
              <Button type="button" variant="secondary" onClick={closeModal}>
                Cancel
              </Button>
            </>
          }
        />
      }
    </>
  );
};

export default TestScenarioDetail;
