import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SpeedTestSchedulerDetailScenarioDraggableSection from './SpeedTestSchedulerDetailScenario/SpeedTestSchedulerDetailScenarioDraggableSection';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const SpeedTestSchedulerDetailScenario = () => {
  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer title="Scenario" isTitleCenter={true}>
        <DndProvider backend={HTML5Backend}>
          <SpeedTestSchedulerDetailScenarioDraggableSection />
        </DndProvider>
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default SpeedTestSchedulerDetailScenario;
