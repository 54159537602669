import { ElectronFile } from '@autosquare/common';
import {
  AddAppInfo,
  DeleteTempFile,
} from '@customTypes/dashboard/project/application/type';
import {
  useDeleteTempFileInfoMutation,
  useUploadFileAndGetInfoMutation,
} from '@lib/api/dashboard/project/project-settings/application/application';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

export const useUploadFileSection = (uploadedInfo: AddAppInfo) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isUploadErrorModalOpen, setIsUploadErrorModalOpen] = useState(false);
  const [isDeleteErrorModalOpen, setIsDeleteErrorModalOpen] = useState(false);
  const { pathname } = useLocation();

  const { setValue, reset } = useFormContext<AddAppInfo>();
  const excludeKeys = ['file', 'description', 'appName', 'checksum'];
  const deleteData = Object.fromEntries(
    Object.entries(uploadedInfo).filter(([key]) => !excludeKeys.includes(key)),
  );

  const uploadFileAndGetInfoMutation = useUploadFileAndGetInfoMutation();
  const deleteTempFileMutation = useDeleteTempFileInfoMutation();

  const handleAppFileUpload = (file: File) => {
    const isValid =
      file.size <= 300 * 1024 * 1024 &&
      (file.name.endsWith('.ipa') || file.name.endsWith('.apk'));

    if (!isValid) {
      setValue('file', null);
      return;
    }
    uploadFileAndGetInfoMutation.mutate(file, {
      onSuccess: (response: AddAppInfo) => {
        Object.entries(response).forEach(([key, value]) => {
          if (key !== 'file') {
            setValue(key as keyof AddAppInfo, value);
          }
        });
        setIsUploaded(true);
      },
      onError: () => setIsUploadErrorModalOpen(true),
    });
  };

  const handleAppFileDelete = (deleteData: DeleteTempFile) => {
    deleteTempFileMutation.mutate(deleteData, {
      onError: () => setIsDeleteErrorModalOpen(true),
      onSuccess: () => {
        reset();
        setIsUploaded(false);
      },
    });
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (isUploaded) {
      try {
        deleteTempFileMutation.mutateAsync(deleteData);
        reset();
        setIsUploaded(false);
      } catch (error) {
        setIsDeleteErrorModalOpen(true);
      }
    }
    const dataFiles = [...e.dataTransfer.files] as ElectronFile[];
    if (dataFiles && dataFiles.length > 0) {
      const file = dataFiles ? dataFiles[0] : undefined;
      setValue('file', file, { shouldValidate: true });
      handleAppFileUpload(file);
      setIsDragging(false);
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const isUploadedRef = useRef(isUploaded);
  const pathnameRef = useRef(pathname);
  const deleteDataRef = useRef<DeleteTempFile>(deleteData);

  useEffect(() => {
    isUploadedRef.current = isUploaded;
  }, [isUploaded]);

  useEffect(() => {
    pathnameRef.current = pathname;
  }, [pathname]);

  useEffect(() => {
    deleteDataRef.current = deleteData;
  }, [deleteData]);

  useEffect(() => {
    return () => {
      if (
        isUploadedRef.current &&
        pathnameRef.current.includes('add-application')
      ) {
        handleAppFileDelete(deleteDataRef.current);
      }
    };
  }, []);

  return {
    handleDragOver,
    handleDrop,
    handleDragEnter,
    handleDragLeave,
    isUploaded,
    setIsUploaded,
    handleAppFileDelete,
    handleAppFileUpload,
    deleteData,
    isUploadErrorModalOpen,
    setIsUploadErrorModalOpen,
    isDeleteErrorModalOpen,
    setIsDeleteErrorModalOpen,
    uploadFileAndGetInfoMutation,
    deleteTempFileMutation,
    isDragging,
  };
};
