import { ErrorBoundaryWrapper } from '@autosquare/common';
import NoTestDropList from '@components/DragAndDrop/NoTestDropList';
import SchedulerCopiedTestScenarioListContainer from '@components/Scheduler/SchedulerCopiedTestScenarioListContainer';
import SchedulerTestScenarioListCard from '@components/Scheduler/SchedulerTestScenarioListCard';
import { CreateSpeedSchedulerData } from '@customTypes/ide/speedScheduler/speedScheduler';
import { SpeedTestScenarioDataInScheduler } from '@customTypes/ide/speedTest/Mobile/testScenario/type';
import { useSpeedTestSchedulerListDrop } from '@hooks/speedTestScheduler/useSpeedTestSchedulerListDrop';
import React from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  copiedTestScenarioList: SpeedTestScenarioDataInScheduler[];
  setCopiedTestScenarioList: React.Dispatch<
    React.SetStateAction<SpeedTestScenarioDataInScheduler[]>
  >;
  changeCardList: (list: SpeedTestScenarioDataInScheduler[]) => void;
};

const SchedulerTestScenarioList = ({
  copiedTestScenarioList,
  setCopiedTestScenarioList,
  changeCardList,
}: Props) => {
  const { setValue } = useFormContext<CreateSpeedSchedulerData>();

  const { drop, onRemove, moveCard } = useSpeedTestSchedulerListDrop(
    setValue,
    copiedTestScenarioList,
    setCopiedTestScenarioList,
    changeCardList,
  );

  return (
    <ErrorBoundaryWrapper>
      <SchedulerCopiedTestScenarioListContainer drop={drop}>
        {copiedTestScenarioList?.length === 0 ? (
          <NoTestDropList />
        ) : (
          copiedTestScenarioList?.map((testScenario, index) => (
            <SchedulerTestScenarioListCard
              key={index}
              index={index}
              testScenario={testScenario}
              onRemove={onRemove}
              moveCard={moveCard}
            />
          ))
        )}
      </SchedulerCopiedTestScenarioListContainer>
    </ErrorBoundaryWrapper>
  );
};

export default SchedulerTestScenarioList;
