import {
  LocationCategory,
  TestPlatformType,
} from '@lib/api/dashboard/overview/overview';
import { useEffect, useRef, useState } from 'react';

export const useSlidingTab = (
  selectedIndex: number,
  selectedTestCategory: LocationCategory | TestPlatformType,
  tabList: {
    title: LocationCategory | TestPlatformType;
    content: {
      id: number;
      post: React.JSX.Element;
    }[];
  }[],
) => {
  const tabRef = useRef<(HTMLElement | null)[]>([]);
  const tabListRef = useRef<HTMLDivElement>(null);
  const [tabWidths, setTabWidths] = useState<number[]>([]);
  const [tabListWidth, setTabListWidth] = useState<number>(0);

  const getTranslateX = () => {
    if (tabWidths.length === 0) return 0;
    return tabWidths.slice(0, selectedIndex).reduce((acc, cur) => acc + cur, 0);
  };
  useEffect(() => {
    if (tabRef.current.length > 0) {
      const width = tabRef.current.map((el) => el?.offsetWidth || 0);
      setTabWidths(width);
      const totalWidth = tabRef.current.reduce(
        (acc, cur) => acc + (cur?.offsetWidth || 0),
        0,
      );
      setTabListWidth(totalWidth);
    }
  }, [selectedTestCategory, selectedIndex, tabList]);

  return {
    getTranslateX,
    tabListRef,
    tabListWidth,
    tabWidths,
    tabRef,
    setTabListWidth,
    setTabWidths,
  };
};
