import {
  ErrorBoundaryWrapper,
  findPlatformTypeNameFromServerName,
} from '@autosquare/common';
import { SpeedMobileTestCaseData } from '@customTypes/speedTestCase/type';
import { findFillColorFromName } from '@utils/static/priorityLists';
import React, { useEffect } from 'react';

type Props = {
  speedMobileTestCaseDetail: SpeedMobileTestCaseData;
};
const TestCaseInfoDefault = ({ speedMobileTestCaseDetail }: Props) => {
  useEffect(() => {
    sessionStorage.setItem('testCaseTitle', speedMobileTestCaseDetail?.title);
  }, []);

  const fillColor = findFillColorFromName(speedMobileTestCaseDetail?.priority);
  const descriptionList = [
    {
      term: 'Platform Type',
      description:
        speedMobileTestCaseDetail?.platformType === null
          ? '-'
          : findPlatformTypeNameFromServerName(
              speedMobileTestCaseDetail?.platformType,
            ),
    },
    {
      term: 'Priority',
      description: (
        <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
          <svg
            className={`size-1.5 ${fillColor}`}
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx={3} cy={3} r={3} />
          </svg>
          {speedMobileTestCaseDetail?.priority}
        </span>
      ),
    },
    {
      term: 'Test Group',
      description: speedMobileTestCaseDetail?.caseGroupInfo?.name || '-',
    },
    {
      term: 'Service Group',
      description: speedMobileTestCaseDetail?.serviceGroupInfo?.name || '-',
    },
    { term: 'Creator', description: speedMobileTestCaseDetail?.creator },
  ];

  const bottomDescriptionLists = [
    { term: 'Title', description: speedMobileTestCaseDetail?.title },
    {
      term: 'Description',
      description: speedMobileTestCaseDetail?.description,
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="mt-1">
        <dl className="grid grid-cols-1 border-t border-gray-100 py-6 sm:grid-cols-5">
          {descriptionList.map((description) => (
            <div key={description.term}>
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {description.term}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                {description.description}
              </dd>
            </div>
          ))}
        </dl>
        <dl className="grid grid-cols-1 border-t border-gray-100 py-6 sm:grid-cols-3">
          {bottomDescriptionLists.map((description) => (
            <div key={description.term}>
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {description.term}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                {description.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default TestCaseInfoDefault;
