import { ErrorBoundaryWrapper } from '@autosquare/common';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import SwitchInController from '@components/shared/HeadlessUI/Switch/SwitchInController';
import {
  CreateSpeedSchedulerData,
  SpeedSchedulerTypeName,
} from '@customTypes/index';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const CreateSpeedTestSchedulerDeviceReboot = () => {
  const { control, watch, setValue } =
    useFormContext<CreateSpeedSchedulerData>();

  const testType = watch('type');

  // 테스트 타입이 웹일 경우 디바이스 재부팅 옵션 false 설정
  useEffect(() => {
    if (testType === SpeedSchedulerTypeName.Web) {
      setValue('deviceReboot', false);
    }
  }, [testType]);

  if (testType === SpeedSchedulerTypeName.Web) return null;

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer
        title={'Device Reboot'}
        tooltipWord="스케줄러 실행 종료 시, 연결된 디바이스를 자동으로 재부팅할 수 있습니다."
        tooltipClass="-right-56 bottom-6"
      >
        <Controller
          control={control}
          name="deviceReboot"
          render={({ field }) => (
            <SwitchInController
              checked={field.value || false}
              onChange={field.onChange}
            />
          )}
        />
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default CreateSpeedTestSchedulerDeviceReboot;
