import { IDEAPI } from '@api/ideEndpoints';
import { axiosInstance } from '..';
import {
  CancelReservationDevice,
  ReservationDevice,
  ReservationDeviceAvailableList,
  ReservationDeviceDetail,
  ReservationDeviceInfo,
  ReservationDeviceList,
  ServerResponseErrorDetails,
  UpdateReservationDevice,
} from '@customTypes/index';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { reservationDeviceKeys } from '@queries/index';

// GET
// 예약된 디바이스 목록 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Device%20Reservation%20Controller/getDeviceAvailableList
const getReservedDeviceList = async (
  projectIdx: string,
): Promise<ReservationDeviceList> => {
  const response = await axiosInstance.get(
    `${IDEAPI.DEVICES_RESERVATION}/book/list?projectIdx=${projectIdx}`,
  );
  return response.data;
};

export const useReservedDeviceListQuery = () => {
  const { projectIdx } = useParams();

  return useQuery<ReservationDeviceList, ServerResponseErrorDetails>({
    queryKey: reservationDeviceKeys.list(projectIdx),
    queryFn: () => getReservedDeviceList(projectIdx),
    enabled: !!projectIdx,
  });
};

// 예약 내역 상세
// https://dev.t-square.co.kr/swagger-ui/index.html#/Device%20Reservation%20Controller/getDeviceBookDetailList
const getReservedDeviceDetail = async (
  projectIdx: string,
  deviceIdx: string,
): Promise<ReservationDeviceDetail> => {
  const response = await axiosInstance.get(
    `${IDEAPI.DEVICES_RESERVATION}/${deviceIdx}/detail/list?projectIdx=${projectIdx}`,
  );
  return response.data;
};

export const useReservedDeviceDetailQuery = () => {
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const deviceIdx = searchParams.get('deviceIdx');

  return useQuery<ReservationDeviceDetail, ServerResponseErrorDetails>({
    queryKey: reservationDeviceKeys.detail(projectIdx, deviceIdx),
    queryFn: () => getReservedDeviceDetail(projectIdx, deviceIdx),
    enabled: !!projectIdx && !!deviceIdx,
  });
};

// POST
// 예약 가능한 디바이스 목록
// https://dev.t-square.co.kr/swagger-ui/index.html#/Device%20Reservation%20Controller/getDeviceBookList
const getAvailableDeviceList = async (
  data: ReservationDeviceAvailableList,
): Promise<ReservationDeviceInfo[]> => {
  const response = await axiosInstance.post(
    `${IDEAPI.DEVICES_RESERVATION}/available/list`,
    data,
  );
  return response.data;
};

export const useGetAvailableDeviceListMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ReservationDeviceInfo[],
    ServerResponseErrorDetails,
    ReservationDeviceAvailableList
  >({
    mutationFn: (data) => getAvailableDeviceList(data),
    onSuccess: () => {
      queryClient.invalidateQueries(reservationDeviceKeys.all);
    },
  });
};

// 디바이스 예약
// https://dev.t-square.co.kr/swagger-ui/index.html#/Device%20Reservation%20Controller/saveDeviceReservation
const saveDeviceReservation = async (
  data: ReservationDevice,
): Promise<ReservationDeviceDetail> => {
  const response = await axiosInstance.post(
    `${IDEAPI.DEVICES_RESERVATION}`,
    data,
  );
  return response.data;
};

export const useSaveDeviceReservationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ReservationDeviceDetail,
    ServerResponseErrorDetails,
    ReservationDevice
  >({
    mutationFn: (data) => saveDeviceReservation(data),
    onSuccess: () => {
      queryClient.invalidateQueries(reservationDeviceKeys.all);
    },
  });
};

// PUT
// 디바이스 예약 변경
// https://dev.t-square.co.kr/swagger-ui/index.html#/Device%20Reservation%20Controller/updateDeviceReservation
const updateDeviceReservation = async (
  data: UpdateReservationDevice,
): Promise<ReservationDeviceDetail> => {
  const response = await axiosInstance.put(
    `${IDEAPI.DEVICES_RESERVATION}`,
    data,
  );
  return response.data;
};

export const useUpdateDeviceReservationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ReservationDeviceDetail,
    ServerResponseErrorDetails,
    UpdateReservationDevice
  >({
    mutationFn: (data) => updateDeviceReservation(data),
    onSuccess: () => {
      queryClient.invalidateQueries(reservationDeviceKeys.all);
    },
  });
};

// DELETE
// 디바이스 예약 취소
// https://dev.t-square.co.kr/swagger-ui/index.html#/Device%20Reservation%20Controller/deleteDeviceReservation
const deleteDeviceReservation = async (
  data: CancelReservationDevice,
): Promise<boolean> => {
  const response = await axiosInstance.delete(`${IDEAPI.DEVICES_RESERVATION}`, {
    data: data,
  });
  return response.data;
};

export const useDeleteDeviceReservationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    boolean,
    ServerResponseErrorDetails,
    CancelReservationDevice
  >({
    mutationFn: (data) => deleteDeviceReservation(data),
    onSuccess: () => {
      queryClient.invalidateQueries(reservationDeviceKeys.all);
    },
  });
};
