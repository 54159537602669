import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  CreateTestStepData,
  MaskingOptionsType,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const MaskingOptionsRadio = () => {
  const { register, setValue } = useFormContext<CreateTestStepData>();

  useEffect(() => {
    setValue('optionsDto.maskingOptions.type', MaskingOptionsType.Element);
  }, [setValue]);

  const radioId = 'maskingOptionsType';

  const radioDefaultOptions = [
    {
      id: MaskingOptionsType.Element,
      title: '직접 선택',
      defaultChecked: true,
    },
    {
      id: MaskingOptionsType.Coordinate,
      title: '영역 선택',
      defaultChecked: false,
    },
    {
      id: MaskingOptionsType.Full,
      title: '전체 화면',
      defaultChecked: false,
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <fieldset>
        <div className="grid grid-cols-3 items-center">
          {radioDefaultOptions.map((radioOption) => (
            <div key={radioOption.id} className="flex items-center">
              <input
                id={radioId + radioOption.id}
                name={'maskingOptions.type'}
                type="radio"
                defaultChecked={radioOption.defaultChecked}
                value={radioOption.id}
                className="input-radio-congress-blue"
                {...register('optionsDto.maskingOptions.type')}
              />
              <label
                htmlFor={radioId + radioOption.id}
                className="ml-3 block cursor-pointer text-sm font-medium leading-6 text-gray-900"
              >
                {radioOption.title}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </ErrorBoundaryWrapper>
  );
};

export default MaskingOptionsRadio;
