import {
  ArrowSpinner,
  BackToListIcon,
  Breadcrumbs,
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
  PageHeadingButtons,
  PagingHeadingsNavigate,
  TestResultDetailMenu,
} from '@autosquare/common';
import { useWebSpeedTestResultDeleteMutation } from '@lib/api/dashboard/web/webSpeedResult';
import React, { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

type Props = {
  exportChartToPng: () => Promise<void>;
};

const WebSpeedTestResultHeader = ({ exportChartToPng }: Props) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const webSpeedTestResultDeleteMutation =
    useWebSpeedTestResultDeleteMutation();

  const resultIdx = searchParams.get('result');
  const resultStatus = searchParams.get('status');
  const isResultNA = resultStatus === 'NA';
  const testResultListPage = `${pathname.split('/').slice(0, -1).join('/')}`;

  const backToListPage = localStorage.getItem('entryPoint');

  const breadcrumbs: Breadcrumbs[] = [
    {
      title: 'Web',
      href: testResultListPage,
    },
    {
      title: 'Speed Test Result',
      href: testResultListPage,
    },
    { title: 'Detail', href: pathname + search },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: (
        <div className="flex items-center justify-between gap-1.5 text-sm font-semibold leading-5 text-gray-900">
          <BackToListIcon />
          Back to list
        </div>
      ),
      onClick: () => navigate(backToListPage ?? testResultListPage),
      type: 'button',
      variant: 'secondary',
    },
  ];

  const testResultDetailMenu: TestResultDetailMenu[] = [
    {
      title: 'Export',
      onClick: () => {
        exportChartToPng();
      },
      disabled: isResultNA,
    },
    {
      title: 'Delete',
      onClick: () => {
        if (webSpeedTestResultDeleteMutation.isError) {
          webSpeedTestResultDeleteMutation.reset();
        }
        setIsModalOpen(true);
      },
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Speed Test Result Detail'}
        pageHeadingButtons={pageHeadingButtons}
        testResultDetailMenu={testResultDetailMenu}
      />
      <DoubleCheckDialogHeadlessUI
        isOpened={isModalOpen}
        setIsOpened={setIsModalOpen}
        type={'caution'}
        title={
          webSpeedTestResultDeleteMutation.isError
            ? 'Error'
            : 'Delete Test Result'
        }
        subTitle={
          webSpeedTestResultDeleteMutation.isError
            ? webSpeedTestResultDeleteMutation.error.message
            : '해당 테스트 결과를 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {!webSpeedTestResultDeleteMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  webSpeedTestResultDeleteMutation.mutate(
                    { idxList: [Number(resultIdx)] },
                    {
                      onError: () => {
                        setIsModalOpen(true);
                      },
                    },
                  )
                }
                disabled={webSpeedTestResultDeleteMutation.isLoading}
              >
                Delete
              </Button>
            )}
            <Button
              type="button"
              variant="secondary"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>
          </>
        }
      />
      {webSpeedTestResultDeleteMutation.isLoading && <ArrowSpinner />}
    </ErrorBoundaryWrapper>
  );
};

export default WebSpeedTestResultHeader;
