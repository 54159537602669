import React from 'react';
import { ListBoxHeadlessInController } from '@autosquare/common';
import {
  findJiraIssueTypeFromId,
  isRowDisabled,
} from '@utils/static/autoRegister/autoRegisterUtils';
import { ControllerRenderProps, UseFieldArrayUpdate } from 'react-hook-form';
import { JiraProjectIssueTypeInfo } from '@lib/api/ide/autoRegisters/autoRegisters';
import {
  CreateTestScenarioData,
  UpdateTestScenarioData,
} from '@customTypes/testScenario/type';

type Props = {
  field: ControllerRenderProps<
    CreateTestScenarioData | UpdateTestScenarioData,
    `autoRegisters.${number}.jira.mapping.${number}.issueType`
  >;
  update: UseFieldArrayUpdate<CreateTestScenarioData | UpdateTestScenarioData>;
  jiraIssueType: JiraProjectIssueTypeInfo[];
  jiraProjectKey: string;
  index: number;
  fieldsIndex: number;
  selectedIssueType: string;
  selectedTestResult: string;
};

const IdeScenarioJiraInputEditIssueType = ({
  field,
  update,
  jiraIssueType,
  jiraProjectKey,
  index,
  fieldsIndex,
  selectedIssueType,
  selectedTestResult,
}: Props) => {
  return (
    <ListBoxHeadlessInController
      value={field.value}
      buttonValue={
        field.value === ''
          ? null
          : findJiraIssueTypeFromId(field.value, jiraIssueType)
      }
      lists={jiraIssueType}
      valueToSave="id"
      valueToShow="name"
      onChange={(value) => {
        field.onChange(value);
        if (!!selectedIssueType && selectedIssueType !== value) {
          update(fieldsIndex, {
            testResult: selectedTestResult,
            issueType: value,
            priority: '',
          });
        }
      }}
      placeholder="Select"
      location="jira"
      disabled={
        jiraIssueType?.length === 0 ||
        !jiraProjectKey ||
        !isRowDisabled(index, fieldsIndex)
      }
    />
  );
};

export default IdeScenarioJiraInputEditIssueType;
