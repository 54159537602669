import { z } from 'zod';

const BaseRemoteSchema = z.object({
  idx: z.number(),
  userId: z.string().email(),
  alias: z.string(),
});

export const RemoteStatusEnum = z.enum([
  'READY',
  'WAITING',
  'CONNECTED',
  'DISCONNECTED',
]);
type RemoteStatusEnum = z.infer<typeof RemoteStatusEnum>;

export enum Mode {
  Standard = 'standard',
  Remote = 'remote',
}

export const ModeEnum = z.nativeEnum(Mode);
export type ModeType = z.infer<typeof ModeEnum>;

export const RemoteStatus = RemoteStatusEnum.Enum;

export const RemoteControlInfoSchema = BaseRemoteSchema.extend({
  status: RemoteStatusEnum,
});
export const RemoteControlSchema = BaseRemoteSchema;

export type RemoteControlInfo = z.infer<typeof RemoteControlInfoSchema>;
export type RemoteControl = z.infer<typeof RemoteControlSchema>;

const RemoteSettingSystemSchema = z.object({
  remote_enable: z.boolean(),
});
export type RemoteSettingSystem = z.infer<typeof RemoteSettingSystemSchema>;
