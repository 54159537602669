import { IDEAPI } from '@api/ideEndpoints';
import { axiosLocalInstance } from '..';
import {
  SpeedMobileCaseExecution,
  SpeedMobileExecution,
  SpeedMobileScenarioExecution,
} from '@customTypes/speedTestExecution/speedTestExecution';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';
import { TestStepData } from '@customTypes/testExecution/type';
import { useParams } from 'react-router-dom';

const mobileSpeedTestExecutionUrl = IDEAPI.RUN_SPEED_APP_TEST_STEP;

//POST
// http://localhost:8081/swagger-ui/index.html#/Test%20Executor/testExecute
// Mobile Speed App - Mobile Test Step 단위 테스트 실행
const speedExecutionMobileTestStep = async (
  cookie: string,
  data: TestStepData,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    `${mobileSpeedTestExecutionUrl}command_app_speed_step`,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useMobileSpeedTestStepExecutionMutation = () => {
  const { projectIdx } = useParams();

  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );
  const deviceInfo = useSelector((state: RootState) => state.device.deviceInfo);

  const speedTestData = (stepIdx: number): TestStepData => {
    return {
      stepIdx: stepIdx,
      infos: [deviceInfo],
      projectIdx: Number(projectIdx),
    };
  };

  return useMutation<unknown, Error, number>({
    mutationFn: (stepIdx) =>
      speedExecutionMobileTestStep(cookie, speedTestData(stepIdx)),
  });
};

// Mobile Speed App - Mobile Test Step List내 Repeat Step 실행
const speedExecutionMobileTestRepeatStep = async (
  cookie: string,
  data: SpeedMobileExecution,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    `${mobileSpeedTestExecutionUrl}command_app_speed`,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useMobileSpeedTestRepeatStepExecutionMutation = (
  cookie: string,
) => {
  return useMutation<unknown, Error, SpeedMobileExecution>({
    mutationFn: (data) => speedExecutionMobileTestRepeatStep(cookie, data),
  });
};

// Mobile SpeedApp Test Case 실행
const speedExecutionMobileTestCase = async (
  cookie: string,
  data: SpeedMobileCaseExecution,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    `${mobileSpeedTestExecutionUrl}unit_app_speed`,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};

export const useSpeedExecutionMobileTestCaseMutation = (
  cookie: string,
  data: SpeedMobileCaseExecution,
) => {
  return useMutation<unknown, Error>({
    mutationFn: () => speedExecutionMobileTestCase(cookie, data),
  });
};

// Mobile Speed Web Test Case 실행
const speedExecutionMobileWebTestCase = async (
  cookie: string,
  data: SpeedMobileCaseExecution,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    `${mobileSpeedTestExecutionUrl}unit_web_speed`,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};

export const useSpeedExecutionMobileWebTestCaseMutation = (
  cookie: string,
  data: SpeedMobileCaseExecution,
) => {
  return useMutation<unknown, Error>({
    mutationFn: () => speedExecutionMobileWebTestCase(cookie, data),
  });
};

// Mobile Speed Test Scenario 실행
const speedExecutionMobileTestScenario = async (
  cookie: string,
  data: SpeedMobileScenarioExecution,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    `${mobileSpeedTestExecutionUrl}scenario_speed`,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};

export const useSpeedExecutionMobileTestScenarioMutation = (cookie: string) => {
  return useMutation<unknown, Error, SpeedMobileScenarioExecution>({
    mutationFn: (data) => speedExecutionMobileTestScenario(cookie, data),
  });
};
