import React from 'react';
import { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';

import SchedulerDetailStartDate from './SchedulerDetailSchedule/SchedulerDetailStartDate';
import SchedulerDetailRunningCycle from './SchedulerDetailSchedule/SchedulerDetailRunningCycle';

import 'react-datepicker/dist/react-datepicker.css';
import SchedulerDetailPreview from './SchedulerDetailSchedule/SchedulerDetailPreview';
import SchedulerDetailDays from './SchedulerDetailSchedule/SchedulerDetailDays';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';

type Props = {
  isRunning: boolean;
};

const SchedulerDetailSchedule = ({ isRunning }: Props) => {
  registerLocale('ko', ko);

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer title={'Schedule'}>
        <div className="sm:grid sm:grid-cols-10 sm:grid-rows-2 sm:items-start sm:gap-x-8 sm:py-4">
          <SchedulerDetailStartDate isRunning={isRunning} />
          <SchedulerDetailRunningCycle isRunning={isRunning} />
          <SchedulerDetailPreview />
          <SchedulerDetailDays isRunning={isRunning} />
        </div>
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default SchedulerDetailSchedule;
