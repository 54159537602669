import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import AdminProjectDetailForm from './components/AdminProjectDetailForm';
import {
  ErrorBoundaryWrapper,
  ErrorMessage,
  ListSkeleton,
} from '@autosquare/common';
import AdminProjectDetailPagingHeadings from './components/AdminProjectDetailPagingHeadings';
import {
  useAdminProjectDetailQuery,
  useAdminUserListQuery,
} from '@lib/api/dashboard/queryHooks';

const AdminProjectDetail = () => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const adminUserListQuery = useAdminUserListQuery();
  const adminProjectDetailQuery = useAdminProjectDetailQuery(idx);

  const adminProjectData = adminProjectDetailQuery.data;

  const adminUserListData = adminUserListQuery.data?.filter(
    (adminUser) => adminUser.status === 'Activate',
  );

  const [isEdited, setIsEdited] = useState(false);

  return (
    <ErrorBoundaryWrapper>
      <AdminProjectDetailPagingHeadings
        isEdited={isEdited}
        setIsEdited={setIsEdited}
      />
      {adminProjectDetailQuery.isLoading || adminUserListQuery.isLoading ? (
        <ListSkeleton margin="my-8" />
      ) : adminProjectDetailQuery.isError || adminUserListQuery.isError ? (
        <ErrorMessage>
          {adminProjectDetailQuery.error.message ||
            adminUserListQuery.error.message}
        </ErrorMessage>
      ) : (
        <AdminProjectDetailForm
          isEdited={isEdited}
          setIsEdited={setIsEdited}
          adminProjectData={adminProjectData}
          adminUserListData={adminUserListData}
        />
      )}
    </ErrorBoundaryWrapper>
  );
};

export default AdminProjectDetail;
