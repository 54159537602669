import React from 'react';
import EditMessengerRequestUrl from './EditMessengerFields/EditMessengerRequestUrl';
import EditMessengerServerCode from './EditMessengerFields/EditMessengerServerCode';
import EditMessengerSenderId from './EditMessengerFields/EditMessengerSenderId';
import EditMessengerRecipientList from './EditMessengerFields/EditMessengerRecipientList';
import { ProjectListDetail } from '@customTypes/dashboard/project/type';

type Props = {
  adminProjectData: ProjectListDetail;
  changePlatformCount: number;
};

const EditMessenger10to12 = ({
  adminProjectData,
  changePlatformCount,
}: Props) => {
  return (
    <>
      <EditMessengerRequestUrl />
      <EditMessengerServerCode />
      <EditMessengerSenderId />
      <EditMessengerRecipientList
        adminProjectData={adminProjectData}
        changePlatformCount={changePlatformCount}
      />
    </>
  );
};

export default EditMessenger10to12;
