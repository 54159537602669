import React, { ReactElement } from 'react';

import { useDeleteTestStepMutation } from '@lib/api/ide/mobile/mobileTestStep';

import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import DeleteDialog from './TestStepDetailBasic/DeleteDialog';

import { useDeleteSpeedMobileTestStep } from '@lib/api/ide/speedMobile/speedMobileTestStep';

import ActionButtonGroup from './TestStepDetailBasic/ActionButtonGroup';
import { useDeleteDialog } from '@hooks/index';
import TestStepDetailContent from './TestStepDetailBasic/TestStepDetailContent';
import { ErrorBoundaryWrapper } from '@autosquare/common';

interface Props {
  mobileTestStepData: MobileTestStepData;
  setIsEdited: React.Dispatch<React.SetStateAction<boolean>>;
  closeDialog: () => void;
  testStepDetailListValue: string | ReactElement;
}

const TestStepDetailBasic = ({
  mobileTestStepData,
  setIsEdited,
  closeDialog,
  testStepDetailListValue,
}: Props) => {
  const { isModalOpened, openModal, closeModal, setIsModalOpened } =
    useDeleteDialog();

  const uiTestDeleteStepMutation = useDeleteTestStepMutation();
  const speedTestDeleteStepMutation = useDeleteSpeedMobileTestStep();

  return (
    <ErrorBoundaryWrapper>
      <ActionButtonGroup setIsEdited={setIsEdited} openModal={openModal} />
      <div className="divide-y divide-gray-200">
        <div className="px-4 py-5 sm:p-0">
          <dl className="space-y-8 sm:space-y-0">
            <TestStepDetailContent
              mobileTestStepData={mobileTestStepData}
              testStepDetailListValue={testStepDetailListValue}
            />
          </dl>
        </div>
      </div>
      <DeleteDialog
        isModalOpened={isModalOpened}
        setIsModalOpened={setIsModalOpened}
        closeModal={closeModal}
        closeDialog={closeDialog}
        openModal={openModal}
        uiTestDeleteStepMutation={uiTestDeleteStepMutation}
        speedTestDeleteStepMutation={speedTestDeleteStepMutation}
        mobileStepIdx={mobileTestStepData?.idx}
      />
    </ErrorBoundaryWrapper>
  );
};

export default TestStepDetailBasic;
