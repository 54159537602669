import { ErrorBoundaryWrapper } from '@autosquare/common';
import { MaskingOptionsType } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { CreateWebTestStepData } from '@customTypes/testStep/type';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import WebTestStepMaskingOptionsDirectChoice from './WebTestStepMaskingOptions/WebTestStepMaskingOptionsDirectChoice';
import WebTestStepMaskingOptionsFullScreen from './WebTestStepMaskingOptions/WebTestStepMaskingOptionsFullScreen';
import WebTestStepMaskingOptionsHeader from './WebTestStepMaskingOptions/WebTestStepMaskingOptionsHeader';
import WebTestStepMaskingOptionsRadio from './WebTestStepMaskingOptions/WebTestStepMaskingOptionsRadio';

const WebTestStepMaskingOptions = () => {
  const { watch } = useFormContext<CreateWebTestStepData>();

  const maskingOptionsEnabled = watch('optionsDto.maskingOptions.enabled');

  const maskingOptionsType = watch('optionsDto.maskingOptions.type');

  return (
    <ErrorBoundaryWrapper>
      <WebTestStepMaskingOptionsHeader />
      {maskingOptionsEnabled && (
        <ErrorBoundaryWrapper>
          <WebTestStepMaskingOptionsRadio />
          {maskingOptionsType === MaskingOptionsType.Element ? (
            <WebTestStepMaskingOptionsDirectChoice />
          ) : (
            <WebTestStepMaskingOptionsFullScreen />
          )}
        </ErrorBoundaryWrapper>
      )}
    </ErrorBoundaryWrapper>
  );
};

export default WebTestStepMaskingOptions;
