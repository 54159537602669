import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

export type AccessToken = {
  accessTokenValue: string | null;
  ideAccessToken: string | null;
  remoteAccessToken: string | undefined;
  autosquareMode: string | undefined;
};

const initialState: AccessToken = {
  accessTokenValue: null,
  ideAccessToken: null,
  remoteAccessToken: undefined,
  autosquareMode: undefined,
};

export const accessTokenSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    getAccessToken: (state, action: PayloadAction<string | null>) => {
      state.accessTokenValue = action.payload;
    },
    clearAccessToken: (state) => {
      state.accessTokenValue = undefined;
    },
    getIdeAccessToken: (state, action: PayloadAction<string | null>) => {
      state.ideAccessToken = action.payload;
    },
    clearIdeAccessToken: (state) => {
      state.ideAccessToken = undefined;
    },
    getRemoteAccessToken: (state, action: PayloadAction<string | null>) => {
      state.remoteAccessToken = action.payload;
    },
    clearRemoteAccessToken: (state) => {
      state.remoteAccessToken = undefined;
    },
    getAutosquareMode: (state, action: PayloadAction<string | null>) => {
      state.autosquareMode = action.payload;
    },
    clearAutoSquareMode: (state) => {
      state.autosquareMode = undefined;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const {
  getAccessToken,
  clearAccessToken,
  getIdeAccessToken,
  clearIdeAccessToken,
  getRemoteAccessToken,
  clearRemoteAccessToken,
  getAutosquareMode,
  clearAutoSquareMode,
} = accessTokenSlice.actions;

export default accessTokenSlice.reducer;
