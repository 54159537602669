import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import CalendarCustomInput from './CalendarCustomInput/CalendarCustomInput';
import {
  NavigateOptions,
  SetURLSearchParams,
  useLocation,
  useParams,
} from 'react-router-dom';
import dayjs from 'dayjs';
import CalendarCustomHeader from '@components/Calendar/CalendarCustomHeader';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Props = {
  endDate: Date;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
  startDate: Date;
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
  resultIdx: string;
  groupIdx: string;
  isWeb?: boolean;
};

const RangePickEndCalendar = ({
  endDate,
  setEndDate,
  startDate,
  searchParams,
  setSearchParams,
  resultIdx,
  groupIdx,
  isWeb = false,
}: Props) => {
  const inputRef = useRef(null);
  const { state } = useLocation();
  const { projectIdx } = useParams();
  const [initialState] = useState<NavigateOptions>(state);
  const [initialProjectIdx] = useState<string>(projectIdx);

  const handleDateChange = (newEndDate: Date) => {
    setEndDate(newEndDate);
    const updatedParams = {
      ...Object.fromEntries(searchParams.entries()),
      result: resultIdx,
      project: initialProjectIdx,
      group: groupIdx,
      start: isWeb
        ? dayjs(startDate).format('YYYY-MM-DD')
        : dayjs(startDate).format('YYYYMMDD'),
      end: isWeb
        ? dayjs(newEndDate).format('YYYY-MM-DD')
        : dayjs(newEndDate).format('YYYYMMDD'),
    };
    setSearchParams(updatedParams, { state: initialState });
  };
  return (
    <ErrorBoundaryWrapper>
      <div className="z-20">
        <DatePicker
          selected={endDate}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          showPopperArrow={false}
          onChange={handleDateChange}
          dateFormat={'yyyy-MM-dd'}
          maxDate={new Date()}
          className="input-base h-11 w-36 rounded-md border border-gray-300 text-sm font-normal leading-tight"
          customInput={<CalendarCustomInput ref={inputRef} />}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <CalendarCustomHeader
              date={date}
              changeYear={changeYear}
              changeMonth={changeMonth}
              decreaseMonth={decreaseMonth}
              increaseMonth={increaseMonth}
              prevMonthButtonDisabled={prevMonthButtonDisabled}
              nextMonthButtonDisabled={nextMonthButtonDisabled}
            />
          )}
          selectsEnd
          showYearDropdown
          showMonthDropdown
          shouldCloseOnSelect
          toggleCalendarOnIconClick
        />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default RangePickEndCalendar;
