import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { SidebarList } from '@customTypes/type';
import clsx from 'clsx';

type Props = {
  sidebarList: SidebarList;
  setIsToggledSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

const NoChildrenSidebarList = ({ sidebarList, setIsToggledSidebar }: Props) => {
  const location = useLocation();

  return (
    <Link to={sidebarList.href} onClick={() => setIsToggledSidebar(false)}>
      <div
        className={clsx(
          'block cursor-pointer rounded-md py-2 pl-10 pr-2 text-sm font-semibold leading-6 text-gray-700',
          location.pathname.includes(sidebarList.hrefBasic)
            ? 'bg-gray-50'
            : 'hover:bg-gray-50',
        )}
      >
        {sidebarList.name}
      </div>
    </Link>
  );
};

export default NoChildrenSidebarList;
