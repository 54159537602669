import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  UpdateTestStepData,
  UpdateTestStepDataKeys,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

interface Props {
  name: UpdateTestStepDataKeys;
  radioOptions: {
    title: string;
    id: string;
    defaultChecked: boolean;
    onClick?: () => void;
  }[];
}

const InputRadio = ({ name, radioOptions }: Props) => {
  const { register } = useFormContext<UpdateTestStepData>();
  return (
    <fieldset>
      <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
        {radioOptions.map((radioOption) => (
          <div key={radioOption.id} className="flex items-center gap-x-3">
            <input
              id={radioOption.id}
              name={radioOption.id}
              type="radio"
              defaultChecked={radioOption.defaultChecked}
              onClick={radioOption.onClick}
              value={radioOption.id}
              className="input-radio-congress-blue"
              {...register(name)}
            />
            <label
              htmlFor={radioOption.id}
              className="block cursor-pointer text-sm font-medium leading-6 text-gray-900"
            >
              {radioOption.title}
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

export default InputRadio;
