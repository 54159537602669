import {
  EmailList,
  ErrorBoundaryWrapper,
  MailReceiver,
  useInput,
  useRefOutsideClick,
} from '@autosquare/common';
import { handleEmailListOnKeyDown } from '@utils/static/dashboard/handleEmailListOnKeyDown';
import {
  AdminUserData,
  UpdateAdminProjectDetailData,
} from '@customTypes/dashboard/admin/type';
import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useAdminProjectDetailQuery } from '@lib/api/dashboard/queryHooks';

interface Props {
  adminUserListData: AdminUserData[];
}

const ProjectEditAdmin = ({ adminUserListData }: Props) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const adminProjectDetailQuery = useAdminProjectDetailQuery(idx);
  const adminProjectData = adminProjectDetailQuery.data;

  const {
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<UpdateAdminProjectDetailData>();

  const [projectAdminList, setProjectAdminList] = useState<MailReceiver[]>(
    adminProjectData?.projectAdminList,
  );
  const [projectAdminInput, onChangeProjectAdminInput, setProjectAdminInput] =
    useInput('');
  const [isOpenedMemberList, setIsOpenedMemberList] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [temporarySavedEmailAddress, setTemporarySavedEmailAddress] =
    useState<MailReceiver>({ email: '', name: '' });

  const inputRef: React.MutableRefObject<HTMLInputElement | null> =
    useRef(null);
  const wrapperRef: React.MutableRefObject<HTMLDivElement | null> =
    useRef(null);
  const ulRef: React.MutableRefObject<HTMLUListElement | null> = useRef(null);

  useRefOutsideClick(wrapperRef, () => setIsOpenedMemberList(false));

  useEffect(() => {
    setValue(
      'projectAdminList',
      projectAdminList?.map((projectAdmin) => projectAdmin.email),
    );
    clearErrors('projectAdminList');
  }, [projectAdminList]);

  useEffect(() => {
    if (isOpenedMemberList && ulRef?.current?.children[selectedIndex]) {
      ulRef.current.children[selectedIndex].scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      });
    }
  }, [selectedIndex, isOpenedMemberList]);

  const filteredPeople =
    projectAdminInput === ''
      ? adminUserListData?.filter(
          (adminUser) =>
            !projectAdminList?.some(
              (projectAdmin) => projectAdmin?.email === adminUser?.email,
            ),
        )
      : adminUserListData?.filter(
          (adminUser) =>
            !projectAdminList?.some(
              (projectAdmin) => projectAdmin?.email === adminUser?.email,
            ) &&
            (adminUser?.email
              .toLowerCase()
              .includes(projectAdminInput?.toLowerCase()) ||
              adminUser?.name
                .toLowerCase()
                .includes(projectAdminInput?.toLowerCase())),
        );

  const onSelectMember = (member: AdminUserData) => {
    if (
      projectAdminList?.find((x) => x?.email === projectAdminInput) ===
      undefined
    ) {
      setProjectAdminList([
        ...projectAdminList,
        { email: member?.email, name: member?.name },
      ]);
      setProjectAdminInput('');
      inputRef.current.value = '';
      setTemporarySavedEmailAddress({ email: '', name: '' });
      setIsOpenedMemberList(false);
    }
  };

  const emailListState = {
    temporarySavedList: temporarySavedEmailAddress,
    projectAdminList,
    selectedIndex,
    filteredPeople,
  };

  const emailListActions = {
    setIsOpenedMemberList,
    setProjectAdminList,
    setProjectAdminInput,
    setSelectedIndex,
    setTemporarySavedEmailAddress,
  };

  const emailListRefs = { inputRef };

  return (
    <ErrorBoundaryWrapper>
      <div className="col-span-full">
        <label
          htmlFor="project-admin"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Project Admin(필수)
        </label>
        <div className="mt-2">
          <ErrorBoundaryWrapper>
            <div ref={wrapperRef}>
              <div className="relative">
                <input
                  className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={onChangeProjectAdminInput}
                  placeholder="Select user"
                  ref={inputRef}
                  onKeyDown={(e) =>
                    handleEmailListOnKeyDown(
                      e,
                      emailListState,
                      emailListActions,
                      emailListRefs,
                    )
                  }
                  onClick={() => setIsOpenedMemberList(true)}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                  onClick={() => setIsOpenedMemberList(!isOpenedMemberList)}
                  onKeyDown={(e) =>
                    handleEmailListOnKeyDown(
                      e,
                      emailListState,
                      emailListActions,
                      emailListRefs,
                    )
                  }
                >
                  <ChevronUpDownIcon
                    className="size-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
                {filteredPeople?.length > 0 && isOpenedMemberList && (
                  <ul
                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm"
                    ref={ulRef}
                  >
                    {filteredPeople?.map((person, index) => (
                      <li key={person.userIdx}>
                        <button
                          type="button"
                          className={clsx(
                            'group relative w-full cursor-default select-none py-2 pl-3 pr-9 hover:bg-indigo-600',
                            index === selectedIndex
                              ? 'bg-indigo-600 text-white'
                              : 'text-gray-900',
                          )}
                          onClick={() => onSelectMember(person)}
                        >
                          <div className="flex">
                            <span
                              className={`truncate group-hover:text-gray-50`}
                            >
                              {person.name}
                            </span>
                            <span
                              className={clsx(
                                'ml-2 truncate text-gray-500 group-hover:text-indigo-200',
                                index === selectedIndex
                                  ? 'text-indigo-200'
                                  : 'text-gray-500',
                              )}
                            >
                              {person.email}
                            </span>
                          </div>
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </ErrorBoundaryWrapper>
          <EmailList state={projectAdminList} setState={setProjectAdminList} />
        </div>
        <ErrorMessage
          errors={errors}
          name="projectAdminList"
          render={({ message }) => <p className="error-message">{message}</p>}
        />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default ProjectEditAdmin;
