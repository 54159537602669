import React from 'react';
import {
  ErrorMessage,
  ListSkeleton,
  TableHeader,
  Time,
} from '@autosquare/common';
import { ProjectListDetail } from '@customTypes/dashboard/project/type';
import { timePatternWithSeconds } from '@utils/static/timePattern';
import { useNavigate } from 'react-router-dom';
import { UseQueryResult } from '@tanstack/react-query';

type Props = {
  projectListQuery: UseQueryResult<ProjectListDetail[], Error>;
  projectLists: ProjectListDetail[];
};

const ProjectLists = ({ projectListQuery, projectLists }: Props) => {
  const navigate = useNavigate();

  const tableHeaders = [
    'Project Name',
    'Service Name',
    'Description',
    'Updated at',
  ];

  return (
    <>
      <div className="px-4 pb-5 md:flex md:items-center md:justify-between">
        <div className="flex min-w-0 items-center gap-x-4">
          <h2 className="text-2xl font-bold leading-7 text-gray-900">
            Project List
          </h2>
        </div>
      </div>
      {projectListQuery.isLoading || projectListQuery.isFetching ? (
        <ListSkeleton />
      ) : projectListQuery.isError ? (
        <ErrorMessage>{projectListQuery.error.message}</ErrorMessage>
      ) : (
        <>
          <div className="px-4">
            <div className="flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        {tableHeaders.map((tableHeader, idx) => (
                          <TableHeader key={idx}>{tableHeader}</TableHeader>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {projectLists?.map((projectList) => (
                        <tr key={projectList.idx} className="even:bg-gray-50">
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-blue-600 sm:pl-3">
                            <button
                              type="button"
                              className="hover:underline hover:decoration-blue-600"
                              onClick={() =>
                                navigate(
                                  `/ide/projects/${projectList.idx}/dashboard/overview`,
                                )
                              }
                            >
                              {projectList.name}
                            </button>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {projectList.serviceName}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {projectList.description}
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div>
                              {projectList.updatedAt.split(' ')[0] ?? '-'}
                            </div>
                            <div
                              className={`mt-1 flex items-center justify-start gap-2 text-gray-500`}
                            >
                              {projectList.updatedAt !== '-' && <Time />}
                              {projectList.updatedAt !== '-' &&
                                projectList.updatedAt.match(
                                  timePatternWithSeconds,
                                )[0]}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProjectLists;
