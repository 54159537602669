import React from 'react';
import SelectApplicationListbox from './SelectApplicationListbox';
import { InstallAppRequest } from '@customTypes/index';
import { useFormContext } from 'react-hook-form';
import { useGetProjectAppListQuery } from '@lib/api/dashboard/queryHooks';

const SelectApplicationBuildVersion = () => {
  const { watch } = useFormContext<InstallAppRequest>();

  const osType = watch('osType');
  const packageName = watch('packageName');
  const appVersion = watch('appVersion');

  const { data: projectAppList } = useGetProjectAppListQuery();

  // packageName 과 osType 과 appVersion 을 기준으로 필터링
  const filteredAppList = projectAppList?.filter(
    (app) =>
      app.osType === osType &&
      app.packageName === packageName &&
      app.appVersion === appVersion,
  );

  return (
    <SelectApplicationListbox
      label={'Build Version*'}
      name={'buildVersion'}
      lists={filteredAppList}
      valueToSave={'buildVersion'}
      valueToShow={'buildVersion'}
      disabled={!appVersion}
    />
  );
};

export default SelectApplicationBuildVersion;
