import {
  ArrowSpinner,
  BackToListIcon,
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
  PageHeadingButtons,
  PagingHeadingsNavigate,
  TestResultDetailMenu,
} from '@autosquare/common';
import { TestResultStatus } from '@customTypes/index';
import { useMobileSpeedTestResultDeleteMutation } from '@lib/api/ide/speedMobile/speedMobileTestResult';

import React, { useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

type Props = {
  exportChartToPng: () => Promise<void>;
};

const MobileSpeedTestResultHeader = ({ exportChartToPng }: Props) => {
  const { pathname, search } = useLocation();
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const mobileSpeedTestResultDeleteMutation =
    useMobileSpeedTestResultDeleteMutation();

  const resultIdx = searchParams.get('result');
  const resultStatus = searchParams.get('status');
  const isResultNA = resultStatus === TestResultStatus.NA;
  const copyUrl = `${localStorage.getItem('applicationUrl')}/projects/${projectIdx}/mobile/speed/test-result/detail${search}`;
  const backToListPage = localStorage.getItem('entryPoint');

  const breadcrumbs = [
    {
      title: 'Speed Test',
      href: `${pathname.split('/').slice(0, -3).join('/')}/overview`,
    },
    {
      title: 'Mobile',
      href: `/ide/projects/${projectIdx}/speed-test/mobile/test-result`,
    },
    {
      title: 'Test Result',
      href: `/ide/projects/${projectIdx}/speed-test/mobile/test-result`,
    },
    { title: 'Detail', href: pathname + search },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: (
        <div className="flex items-center justify-between gap-1.5 text-sm font-semibold leading-5 text-gray-900">
          <BackToListIcon />
          Back to list
        </div>
      ),
      onClick: () => navigate(backToListPage),
      type: 'button',
      variant: 'secondary',
    },
  ];

  const testResultDetailMenu: TestResultDetailMenu[] = [
    {
      title: 'Export',
      onClick: () => {
        exportChartToPng();
      },
      disabled: isResultNA,
    },
    {
      title: 'Delete',
      onClick: () => {
        if (mobileSpeedTestResultDeleteMutation.isError) {
          mobileSpeedTestResultDeleteMutation.reset();
        }
        setIsModalOpen(true);
      },
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Result Detail'}
        pageHeadingButtons={pageHeadingButtons}
        copy={true}
        url={copyUrl}
        testResultDetailMenu={testResultDetailMenu}
      />
      <DoubleCheckDialogHeadlessUI
        isOpened={isModalOpen}
        setIsOpened={setIsModalOpen}
        type={'caution'}
        title={
          mobileSpeedTestResultDeleteMutation.isError
            ? 'Error'
            : 'Delete Test Result'
        }
        subTitle={
          mobileSpeedTestResultDeleteMutation.isError
            ? mobileSpeedTestResultDeleteMutation.error.message
            : '해당 테스트 결과를 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {!mobileSpeedTestResultDeleteMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  mobileSpeedTestResultDeleteMutation.mutate(
                    { idxList: [Number(resultIdx)] },
                    {
                      onError: () => {
                        setIsModalOpen(true);
                      },
                    },
                  )
                }
                disabled={mobileSpeedTestResultDeleteMutation.isLoading}
              >
                Delete
              </Button>
            )}
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              Cancel
            </Button>
          </>
        }
      />
      {mobileSpeedTestResultDeleteMutation.isLoading && <ArrowSpinner />}
    </ErrorBoundaryWrapper>
  );
};

export default MobileSpeedTestResultHeader;
