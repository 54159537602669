import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';

import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionName,
  mobileCommandOptions,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';

import AddTestStepConditionHeader from '@components/TestStep/AddTestStepConditionHeader';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import CompareImageCoordinate from './IfCondition/CompareImageCoordinate';
import SelectedElement from './IfCondition/SelectedElement';
import { ErrorMessage } from '@hookform/error-message';

const IfCondition = () => {
  const {
    watch,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<CreateTestStepData>();

  const value = watch('value');
  const conditionCommand = watch('optionsDto.conditionCommand');
  const maskingOptionsEnabled =
    watch('optionsDto.maskingOptions.enabled') === 'true';

  useEffect(() => {
    return () => {
      setValue('optionsDto.conditionCommand', undefined);
    };
  }, [setValue]);

  return (
    <ErrorBoundaryWrapper>
      <AddTestStepConditionHeader
        header={'Condition'}
        description={'조건식을 설정합니다.'}
      />
      <Controller
        control={control}
        name={'optionsDto.conditionCommand'}
        rules={{ required: 'Condition Command를 선택해주세요.' }}
        defaultValue={undefined}
        render={({ field }) => (
          <ListBoxHeadlessInController
            value={field.value}
            buttonValue={
              findMobileCommandNameFromServerName(field.value) || 'Command'
            }
            onChange={(e) => {
              field.onChange(e);
              setValue('optionsDto.conditionValue', undefined);
            }}
            lists={mobileCommandOptions.filter(
              (mobileCommand) =>
                mobileCommand.name === MobileCommandOptionName.FindElement ||
                mobileCommand.name ===
                  MobileCommandOptionName.VerifyNoElement ||
                mobileCommand.name === MobileCommandOptionName.CompareImage,
            )}
            valueToSave={'serverName'}
            valueToShow={'name'}
            disabled={value !== 'undefined' || maskingOptionsEnabled}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={'optionsDto.conditionCommand'}
        render={({ message }) => (
          <p className="error-message -mt-2">{message}</p>
        )}
      />
      {conditionCommand === MobileCommandOptionServerName.CompareImage ? (
        <CompareImageCoordinate />
      ) : (
        <SelectedElement />
      )}
    </ErrorBoundaryWrapper>
  );
};

export default IfCondition;
