import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  UpdateWebTestStepData,
  UpdateWebTestStepDataKeys,
} from '@customTypes/testStep/type';

import { ErrorMessage } from '@hookform/error-message';
import { preventEnterHandler } from '@utils/helper/preventEnterHandler';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  name: UpdateWebTestStepDataKeys;
  isSavedSecretValue?: boolean;
};

const WebTestStepDetailConfirmInputTextarea = ({
  name,
  isSavedSecretValue,
}: Props) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<UpdateWebTestStepData>();

  const isSecret = watch('optionsDto.secret') === 'true';

  const { ref, ...rest } = register(name, {
    required: {
      value: !(isSecret && isSavedSecretValue),
      message: 'Value값을 입력해 주세요.',
    },
  });

  const placeholder =
    isSecret && isSavedSecretValue
      ? '암호화된 값은 표시되지 않습니다.'
      : '텍스트를 입력해 주세요.';

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (textareaRef.current) {
      setHeight(textareaRef.current.scrollHeight);
    }
  }, []);

  useImperativeHandle(ref, () => textareaRef.current);

  return (
    <ErrorBoundaryWrapper>
      <div className="w-full">
        <label htmlFor={name} className="sr-only">
          {name}
        </label>
        <textarea
          name={name}
          id={name}
          style={{ height }}
          className="block w-full overflow-y-auto rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 scrollbar-thin placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          ref={textareaRef}
          placeholder={placeholder}
          onKeyDown={(e) => preventEnterHandler(e, isSecret)}
          {...rest}
        />
        <ErrorMessage
          name={name}
          errors={errors}
          render={({ message }) => (
            <p className="standard-error-message">{message}</p>
          )}
        />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default WebTestStepDetailConfirmInputTextarea;
