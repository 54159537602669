import React from 'react';
import DetailPaste from './DetailPaste';
import {
  ErrorBoundaryWrapper,
  ScenarioListDetailData,
  useCheckboxWithDnd,
} from '@autosquare/common';
import SelectionHeaderWithCheckbox from '@components/shared/SelectionHeaderWithCheckbox';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';

type Props = {
  copyTestScenarios: ScenarioListDetailData[];
  setCopyTestScenarios: React.Dispatch<
    React.SetStateAction<ScenarioListDetailData[]>
  >;
};

const SchedulerDetailScenarioListBox = ({
  copyTestScenarios,
  setCopyTestScenarios,
}: Props) => {
  const {
    selectAllArray,
    selectArray,
    selectedArray,
    setSelectedArray,
    resetSelectedArray,
  } = useCheckboxWithDnd(copyTestScenarios, 'idx', 'dndIndex');

  const hasSchedulerRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );
  return (
    <ErrorBoundaryWrapper>
      {copyTestScenarios.length > 0 && (
        <SelectionHeaderWithCheckbox
          selectAllArray={selectAllArray}
          selectedArray={selectedArray}
          total={copyTestScenarios.length}
          hasDeleteOption
          setCopyTestCases={setCopyTestScenarios}
          resetSelectedArray={resetSelectedArray}
          disabled={!hasSchedulerRunning}
        />
      )}
      <DetailPaste
        copyTestScenarios={copyTestScenarios}
        setCopyTestScenarios={setCopyTestScenarios}
        selectArray={selectArray}
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
      />
    </ErrorBoundaryWrapper>
  );
};

export default SchedulerDetailScenarioListBox;
