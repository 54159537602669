import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';

import DefinitionList from '@components/shared/DefinitionList';
import DivisionWord from '@components/shared/TestStepDetail/DivisionWord';

import {
  deviceKeyList,
  deviceKeyListInIos,
  DeviceKeyServerName,
  findListNameFromServerName,
  findListNameFromServerNameInIos,
} from '@utils/static/deviceKeyList';
import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionName,
  mobileCommandOptions,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';

import ControllerTextArea from '@components/shared/TextArea/ControllerTextArea';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import {
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ImportTestCaseCombobox from '@components/TestStep/ImportTestCaseCombobox';

type Props = {
  selectedPassCommand: MobileCommandOptionServerName;
  setSelectedPassCommand: React.Dispatch<
    React.SetStateAction<MobileCommandOptionServerName>
  >;
  splitValue: (value: string, index: number) => MobileCommandOptionServerName;
  mobileTestStepData: MobileTestStepData;
};

const IfPassAction = ({
  selectedPassCommand,
  setSelectedPassCommand,
  splitValue,
  mobileTestStepData,
}: Props) => {
  const {
    watch,
    control,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext<UpdateTestStepData>();

  const mobileOs = watch('mobileOs');

  const defaultPassCommand = splitValue(mobileTestStepData?.value, 0);
  const commandNameFromServerName =
    findMobileCommandNameFromServerName(defaultPassCommand);
  const passActionValue = mobileTestStepData?.optionsDto?.passActionValue;

  const handleClickOption = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const target = e.target as HTMLElement;
    const textContent = target.textContent;

    if (textContent === commandNameFromServerName) {
      setValue('optionsDto.passActionValue', passActionValue);
    } else {
      setValue('optionsDto.passActionValue', '');
    }
  };

  useEffect(() => {
    if (
      selectedPassCommand !== defaultPassCommand ||
      (selectedPassCommand === defaultPassCommand &&
        watch('optionsDto.passActionValue') !== '')
    ) {
      clearErrors('optionsDto.passActionValue');
    }
  }, [selectedPassCommand, clearErrors]);

  return (
    <ErrorBoundaryWrapper>
      <DivisionWord color="green">PASS</DivisionWord>
      <DefinitionList
        term={'Command'}
        description={
          <ListBoxHeadlessInController
            value={selectedPassCommand}
            buttonValue={findMobileCommandNameFromServerName(
              selectedPassCommand,
            )}
            onChange={setSelectedPassCommand}
            lists={mobileCommandOptions.filter(
              (command) =>
                command.name === MobileCommandOptionName.Click ||
                command.name === MobileCommandOptionName.ImportTestCase ||
                command.name === MobileCommandOptionName.DeviceKey,
              // command.name === MobileCommandOptionName.InputText
            )}
            valueToSave={'serverName'}
            valueToShow={'name'}
            onClickOption={handleClickOption}
          />
        }
      />
      <DefinitionList
        term={'Value*'}
        description={
          selectedPassCommand ===
          MobileCommandOptionServerName.ImportTestCase ? (
            <ImportTestCaseCombobox value="optionsDto.passActionValue" />
          ) : (
            <Controller
              control={control}
              name={'optionsDto.passActionValue'}
              rules={{
                required:
                  selectedPassCommand === MobileCommandOptionServerName.Click
                    ? 'Value 값을 입력해 주세요.'
                    : selectedPassCommand ===
                        MobileCommandOptionServerName.DeviceKey &&
                      'Key Event를 선택해 주세요.',
              }}
              render={({ field }) =>
                selectedPassCommand === MobileCommandOptionServerName.Click ? (
                  <ControllerTextArea field={field} />
                ) : (
                  selectedPassCommand ===
                    MobileCommandOptionServerName.DeviceKey && (
                    <ErrorBoundaryWrapper>
                      <ListBoxHeadlessInController
                        value={field.value}
                        buttonValue={
                          mobileOs === DeviceInfoOs.Aos
                            ? findListNameFromServerName(
                                field.value as DeviceKeyServerName,
                              ) || 'Select Key Event'
                            : findListNameFromServerNameInIos(
                                field.value as DeviceKeyServerName,
                              ) || 'Select Key Event'
                        }
                        onChange={field.onChange}
                        lists={
                          mobileOs === DeviceInfoOs.Aos
                            ? deviceKeyList.slice(1)
                            : deviceKeyListInIos.slice(1)
                        }
                        valueToSave={'serverName'}
                        valueToShow={'listName'}
                      />
                      <ErrorMessage
                        name="optionsDto.passActionValue"
                        errors={errors}
                        render={({ message }) => (
                          <p className="standard-error-message">{message}</p>
                        )}
                      />
                    </ErrorBoundaryWrapper>
                  )
                )
              }
            />
          )
        }
      />
    </ErrorBoundaryWrapper>
  );
};

export default IfPassAction;
