import { useEffect, useRef } from 'react';

export const useIndeterminateCheckbox = (
  selectedCount: number,
  totalCount: number,
) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkboxRef.current) {
      const isIndeterminate = selectedCount > 0 && selectedCount < totalCount;
      checkboxRef.current.indeterminate = isIndeterminate;
    }
  }, [selectedCount, totalCount]);

  return checkboxRef;
};
