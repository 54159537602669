import React, { useEffect } from 'react';
import SelectApplicationListbox from './SelectApplicationListbox';
import {
  DeviceInfoOs,
  DeviceInfoOsFullName,
  InstallAppRequest,
} from '@customTypes/index';
import { useFormContext } from 'react-hook-form';

const SelectApplicationOsListbox = () => {
  const { setValue, watch } = useFormContext<InstallAppRequest>();

  const osType = watch('osType');

  useEffect(() => {
    setValue('packageName', undefined);
    setValue('appVersion', undefined);
    setValue('buildVersion', undefined);
  }, [osType]);

  const osTypeList = [
    {
      id: DeviceInfoOs.Aos,
      value: DeviceInfoOsFullName.Aos,
    },
    {
      id: DeviceInfoOs.Ios,
      value: DeviceInfoOsFullName.Ios,
    },
  ];

  return (
    <SelectApplicationListbox
      label={'OS*'}
      name={'osType'}
      lists={osTypeList}
      valueToSave={'value'}
      valueToShow={'value'}
    />
  );
};

export default SelectApplicationOsListbox;
