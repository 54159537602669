import { ErrorBoundaryWrapper, ImageSkeletonLoader } from '@autosquare/common';

import TestResultDetailError from '@components/shared/Error/TestResultDetailError';

import { useWebTestResultImageData } from '@lib/api/dashboard/web/webTestResult';

import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import React from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TabPanel, TabPanels } from '@headlessui/react';
import clsx from 'clsx';

type Props = {
  imagePaths: string[];
};

const WebResultDetail = ({ imagePaths }: Props) => {
  const webImageData = useWebTestResultImageData(imagePaths, {
    enabled: isWordInLocationPathname('web') && imagePaths[0] !== null,
  });
  const webImageSrc = webImageData?.data;

  return (
    <ErrorBoundaryWrapper>
      <TabPanels>
        <TabPanel className={clsx('py-2', imagePaths[0] === null && 'hidden')}>
          {webImageData.isLoading ? (
            <ImageSkeletonLoader />
          ) : webImageData.isError ? (
            <TestResultDetailError errorMessage={webImageData.error.message} />
          ) : (
            <Swiper
              slidesPerView={1}
              loop={true}
              navigation={true}
              pagination={{
                clickable: true,
              }}
              modules={[Navigation, Pagination]}
              className="size-full"
            >
              {webImageSrc?.map((imagePath) => (
                <SwiperSlide
                  key={imagePath}
                  className="flex justify-center bg-white text-center text-sm"
                >
                  <div className="flex items-center justify-center pb-8">
                    <img
                      src={imagePath}
                      alt="screenshot"
                      className="block aspect-auto w-2/4 object-cover"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </TabPanel>
      </TabPanels>
    </ErrorBoundaryWrapper>
  );
};

export default WebResultDetail;
