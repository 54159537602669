import { ErrorBoundaryWrapper } from '@autosquare/common';
import clsx from 'clsx';
import React from 'react';

type Props = {
  messengerReceiver: { recipientId: string; recipientInfo?: string }[];
  deleteTagItem: (indexToDelete: number) => void;
};

const SelectedRecipientCardList = ({
  messengerReceiver,
  deleteTagItem,
}: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div className="mt-2">
        {messengerReceiver?.map((data, index) => (
          <span
            key={`${data.recipientId}/${data.recipientInfo}${index}`}
            className="m-1 inline-flex items-center justify-between gap-x-0.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600"
          >
            {data.recipientInfo === undefined
              ? data.recipientId
              : `${data.recipientId} / ${data.recipientInfo}`}
            <button
              type="button"
              className={clsx('group relative -mr-1 size-3.5 rounded-sm')}
              onClick={() => deleteTagItem(index)}
            >
              <span className="sr-only">Remove</span>
              <svg
                viewBox="0 0 14 14"
                className="size-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75"
              >
                <path d="M4 4l6 6m0-6l-6 6" />
              </svg>
              <span className="absolute -inset-1" />
            </button>
          </span>
        ))}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default SelectedRecipientCardList;
