import {
  ArrowSpinner,
  ErrorBoundaryWrapper,
  UseCheckboxAttribute,
} from '@autosquare/common';

import React, { useState } from 'react';

import TestExecutionContainer from '@components/TestExecution/TestExecutionContainer';
import { useFilteredMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';
import MobileTestCaseListUI from '@components/UITest/MobileTestCase/MobileTestCaseListUI';
import { useRemoteMobileTestExecutionMutation } from '@lib/api/ide/remote/remote';
import RemoteTestExecutionAlarmModal from '@components/TestExecution/shared/RemoteTestExecutionAlarmModal';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';

interface Props extends UseCheckboxAttribute {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileTestCaseList = ({
  selectAllArray,
  selectArray,
  selectedArray,
  openDialog,
  isModalOpen,
  setIsModalOpen,
}: Props) => {
  const [isTestExecutionOpened, setIsTestExecutionOpened] = useState(false);
  const [testCaseIdx, setTestCaseIdx] = useState<number>(0);
  const [isExecutionErrorModalOpen, setIsExecutionErrorModalOpen] =
    useState(false);

  const remoteMobileExecution = useRemoteMobileTestExecutionMutation('case');
  const filteredMobileTestCaseListQuery = useFilteredMobileTestCaseListQuery();

  return (
    <ErrorBoundaryWrapper>
      <MobileTestCaseListUI
        filteredMobileTestCaseListQuery={filteredMobileTestCaseListQuery}
        setTestCaseIdx={setTestCaseIdx}
        setIsTestExecutionOpened={setIsTestExecutionOpened}
        selectAllArray={selectAllArray}
        selectArray={selectArray}
        selectedArray={selectedArray}
        openDialog={openDialog}
      />
      {isTestExecutionOpened && (
        <TestExecutionContainer
          setState={setIsTestExecutionOpened}
          idx={testCaseIdx}
          testType={'case'}
          deviceType={'mobile'}
          setIsModalOpen={setIsModalOpen}
          remoteMobileExecutionMutation={remoteMobileExecution}
          setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
        />
      )}
      {remoteMobileExecution.isLoading && <ArrowSpinner />}
      <RemoteTestExecutionAlarmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isExecutionErrorModalOpen={isExecutionErrorModalOpen}
        setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
      />
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default MobileTestCaseList;
