import {
  Breadcrumbs,
  PagingHeadingsInAutosquare,
  ErrorBoundaryWrapper,
} from '@autosquare/common';
import AddWirelessDeviceButton from '@components/AddWirelessDeviceButton/AddWirelessDeviceButton';

import React from 'react';
import { useLocation } from 'react-router-dom';
import CreateSpeedTestScheduleForm from './components/CreateSpeedTestScheduleForm';

const CreateSpeedTestScheduler = () => {
  const location = useLocation();

  const breadcrumbs: Breadcrumbs[] = [
    {
      title: 'Speed Test',
      href: `${location.pathname.split('/').slice(0, -2).join('/')}/overview`,
    },
    {
      title: 'Scheduler',
      href: `${location.pathname.split('/').slice(0, -1).join('/')}`,
    },
    {
      title: 'Create',
      href: location.pathname + location.search,
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="flex items-end justify-between">
        <PagingHeadingsInAutosquare
          breadcrumbs={breadcrumbs}
          pageTitle={'Create Scheduler'}
        />
        <AddWirelessDeviceButton />
      </div>
      <CreateSpeedTestScheduleForm />
    </ErrorBoundaryWrapper>
  );
};

export default CreateSpeedTestScheduler;
