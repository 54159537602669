import { ErrorBoundaryWrapper } from '@autosquare/common';
import React from 'react';
import ProjectDeviceDetailPagingHeading from './components/ProjectDeviceDetailPagingHeading';
import ProjectDeviceDetailInformation from './components/ProjectDeviceDetailInformation';
import ProjectDeviceDetailMain from './components/ProjectDeviceDetailMain';

const ProjectDeviceDetail = () => {
  return (
    <ErrorBoundaryWrapper>
      <ProjectDeviceDetailPagingHeading />
      <ProjectDeviceDetailInformation />
      <ProjectDeviceDetailMain />
    </ErrorBoundaryWrapper>
  );
};

export default ProjectDeviceDetail;
