import {
  ListBoxHeadlessInController,
  ErrorBoundaryWrapper,
} from '@autosquare/common';

import TestStepValueListbox from '@components/IDE/TestStepValueListbox';
import AddTestStepConditionHeader from '@components/TestStep/AddTestStepConditionHeader';
import DeviceKeyListBox from '@components/TestStep/DeviceKeyListBox';

import { useTestStepValueList } from '@hooks/testStep/useTestStepValueList';

import { MobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionName,
  mobileCommandOptions,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';

import React, { useContext, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import ConfirmAndInputText from '../ConfirmAndInputText';
import ImportTestCaseCombobox from '@components/TestStep/ImportTestCaseCombobox';

type Props = {
  passCommand: MobileCommandOptionServerName;
  setPassCommand: React.Dispatch<
    React.SetStateAction<MobileCommandOptionServerName>
  >;
  valueDefaultText: (command: MobileCommandOptionServerName) => string;
};

const IfActionPass = ({
  passCommand,
  setPassCommand,
  valueDefaultText,
}: Props) => {
  const { isPassListboxOpened, setIsPassListboxOpened } = useContext(
    MobileTestStepContext,
  );

  const {
    setValue,
    watch,
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateTestStepData>();

  const conditionCommand = watch('optionsDto.conditionCommand');
  const conditionValue = watch('optionsDto.conditionValue');
  const passActionValue = watch('optionsDto.passActionValue');
  const maskingOptionsEnabled =
    watch('optionsDto.maskingOptions.enabled') === 'true';

  const passValueList = useTestStepValueList({
    command: passCommand,
  });

  useEffect(() => {
    return () => {
      setValue('optionsDto.passActionValue', undefined);
      clearErrors('optionsDto.passActionValue');
    };
  }, [passCommand, setValue]);

  useEffect(() => {
    if (passCommand === MobileCommandOptionServerName.Click) {
      setValue(
        'optionsDto.passActionOptions.elementOptions.bounds',
        passValueList.bounds,
      );
      return () => setValue('optionsDto.passActionOptions', undefined);
    }
  }, [setValue, passCommand, passActionValue]);

  return (
    <ErrorBoundaryWrapper>
      <AddTestStepConditionHeader
        header={'Action'}
        description={'조건식의 결과에 따라 실행될 동작을 지정합니다.'}
      />
      <div className="flex gap-4">
        <div className="mt-1 w-10 text-sm font-medium text-green-400">PASS</div>
        <div className="flex w-[calc(100%-3.5rem)] flex-col gap-4">
          <ListBoxHeadlessInController
            value={passCommand}
            buttonValue={
              passCommand
                ? findMobileCommandNameFromServerName(passCommand)
                : 'Command'
            }
            onChange={setPassCommand}
            lists={mobileCommandOptions.filter(
              (mobileCommand) =>
                mobileCommand.name === MobileCommandOptionName.Click ||
                mobileCommand.name === MobileCommandOptionName.ImportTestCase ||
                mobileCommand.name === MobileCommandOptionName.DeviceKey,
              // mobileCommand.name === MobileCommandOptionName.InputText
            )}
            valueToSave={'serverName'}
            valueToShow={'name'}
            onClickOption={() =>
              setValue('optionsDto.passActionValue', undefined)
            }
            disabled={
              conditionCommand === undefined ||
              conditionValue === undefined ||
              maskingOptionsEnabled
            }
          />
          {passCommand === MobileCommandOptionServerName.DeviceKey ? (
            <DeviceKeyListBox value={'optionsDto.passActionValue'} />
          ) : passCommand === MobileCommandOptionServerName.ImportTestCase ? (
            <ImportTestCaseCombobox value={'optionsDto.passActionValue'} />
          ) : (
            <ErrorBoundaryWrapper>
              <Controller
                control={control}
                name={'optionsDto.passActionValue'}
                rules={{ required: 'Pass Action Value를 선택해주세요.' }}
                render={({ field }) => (
                  <TestStepValueListbox
                    valueText={field.value || valueDefaultText(passCommand)}
                    setValueText={field.onChange}
                    totalValues={passValueList.totalValues}
                    isListboxOpened={isPassListboxOpened}
                    setIsListboxOpened={setIsPassListboxOpened}
                    resetOnClick={() => {
                      setPassCommand(undefined);
                      setValue('optionsDto.passActionValue', undefined);
                    }}
                    disabled={
                      conditionCommand === undefined ||
                      conditionValue === undefined ||
                      passCommand === undefined ||
                      passActionValue !== undefined ||
                      maskingOptionsEnabled
                    }
                    resetDisabled={maskingOptionsEnabled}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name={'optionsDto.passActionValue'}
                render={({ message }) => (
                  <p className="error-message -mt-2">{message}</p>
                )}
              />
            </ErrorBoundaryWrapper>
          )}
          {passCommand === MobileCommandOptionServerName.InputText && (
            <ConfirmAndInputText />
          )}
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default IfActionPass;
