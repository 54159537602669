import React, { useEffect } from 'react';
import clsx from 'clsx';
import SwitchInController from '@components/shared/HeadlessUI/Switch/SwitchInController';
import { CreateAdminProjectData } from '@customTypes/dashboard/admin/type';
import { Controller, useFormContext } from 'react-hook-form';
import MessengerPlatform from './MessengerPlatform/MessengerFields/MessengerPlatform';
import Messenger10to12 from './MessengerPlatform/Messenger10to12';
import Messenger01 from './MessengerPlatform/Messenger01';
import Messenger02 from './MessengerPlatform/Messenger02';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import { useAdminAvailableMessengerListQuery } from '@lib/api/dashboard/queryHooks';

const Messenger = () => {
  const { control, watch, setValue, clearErrors } =
    useFormContext<CreateAdminProjectData>();

  const isToggleOn = watch('messengers.0.activate');
  const platform = watch('messengers.0.platform');

  const messengerListQuery = useAdminAvailableMessengerListQuery();
  const messengerList = messengerListQuery.data;

  const platformCode = messengerList?.find(
    (messenger) => messenger.serverName === platform,
  )?.platformCode;

  useEffect(() => {
    if (!isToggleOn) {
      clearErrors('messengers');
      clearErrors('recipientFormat');
      setValue('messengers', []);
    }
  }, [isToggleOn, setValue, clearErrors]);

  return (
    <ErrorBoundaryWrapper>
      <div className="flex items-center justify-between gap-x-3 pb-6">
        <div className="flex gap-x-3">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Messenger
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            프로젝트에서 테스트 결과를 발송할 메신저 알림을 설정해 주세요.
          </p>
        </div>
        <div className="flex justify-end">
          <ErrorBoundaryWrapper>
            <Controller
              control={control}
              name={`messengers.0.activate`}
              defaultValue={false}
              render={({ field }) => (
                <SwitchInController
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </ErrorBoundaryWrapper>
        </div>
      </div>
      <ErrorBoundaryWrapper>
        {isToggleOn && (
          <div
            className={clsx(
              'transition duration-700 ease-in-out',
              isToggleOn ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0',
            )}
          >
            <div className="w-full pb-12 sm:space-y-0 sm:pb-0">
              <MessengerPlatform messengerList={messengerList} />
              {platformCode === 'MSGR10' ||
              platformCode === 'MSGR11' ||
              platformCode === 'MSGR12' ? (
                <Messenger10to12 />
              ) : platformCode === 'MSGR01' ? (
                <Messenger01 />
              ) : (
                platformCode === 'MSGR02' && <Messenger02 />
              )}
            </div>
          </div>
        )}
      </ErrorBoundaryWrapper>
    </ErrorBoundaryWrapper>
  );
};

export default Messenger;
