import { RootState } from '@app/store';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import { Switch } from '@headlessui/react';
import { hasSchedulerRunning } from '@store/ide/scheduler/schedulerSlice';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const SpeedTestSchedulerDetailStatus = () => {
  const dispatch = useDispatch();
  const isRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer title="Status">
        <Switch
          checked={isRunning}
          onChange={(value) => dispatch(hasSchedulerRunning(value))}
          className={clsx(
            'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
            isRunning ? 'bg-congress-blue' : 'bg-gray-200',
          )}
        >
          <span className="sr-only">Scheduler Status</span>
          <span
            aria-hidden="true"
            className={clsx(
              'pointer-events-none inline-block size-5 rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              isRunning ? 'translate-x-5' : 'translate-x-0',
            )}
          />
        </Switch>
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default SpeedTestSchedulerDetailStatus;
