import { useWebSpeedTestCaseDetailQuery } from '@lib/api/ide/speedWeb/webSpeedTestCase';

import PriorityListbox from '@components/shared/HeadlessUI/Listbox/PriorityListbox';

import { UpdateWebSpeedTestCaseInfo } from '@customTypes/ide/speedTest/Web/testCase/type';

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import TestGroupBox from '@components/IDE/TestCase/TestGroupBox';
import ServiceGroupBox from '@components/IDE/TestCase/ServiceGroupBox';

const WebSpeedTestCaseInfoDetailTop = () => {
  const { control } = useFormContext<UpdateWebSpeedTestCaseInfo>();

  const webSpeedTestCaseDetailQuery = useWebSpeedTestCaseDetailQuery();
  const webSpeedTestCaseDetail = webSpeedTestCaseDetailQuery.data;

  return (
    <div className="grid grid-cols-1 gap-4 border-t border-gray-100 sm:grid-cols-4">
      <div className="flex flex-col gap-2 py-6 sm:col-span-1">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          Priority
        </dt>
        <Controller
          name="priority"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <PriorityListbox value={field.value} onChange={field.onChange} />
          )}
        />
      </div>
      <div className="flex flex-col gap-2 py-6 sm:col-span-1">
        <TestGroupBox />
      </div>
      <div className="flex flex-col gap-2 py-6 sm:col-span-1">
        <ServiceGroupBox />
      </div>
      <div className="py-6">
        <dt className="text-sm font-medium leading-6 text-gray-900">Creator</dt>
        <div className="relative mt-3 w-full text-sm leading-6 text-gray-700">
          {webSpeedTestCaseDetail?.creator}
        </div>
      </div>
    </div>
  );
};

export default WebSpeedTestCaseInfoDetailTop;
