import React from 'react';

type Props = {
  dataLength: number;
};

const TableSkeleton = ({ dataLength }: Props) => {
  return (
    <>
      {Array.from({ length: 10 }, (_, i) => (
        <tr key={i} data-testid="table-skeleton">
          {Array.from({ length: dataLength }, (_, j) => (
            <td
              className="whitespace-nowrap px-3 py-4 text-center text-sm"
              key={j}
            >
              <div className="h-8 w-16 animate-pulse rounded bg-gray-200" />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export default TableSkeleton;
