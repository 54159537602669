import {
  SectionMainTitleContainer,
  SectionTitleContainer,
  SectionTitleHeader,
  SectionTitleHeaderDescription,
  TooltipTop,
} from '@autosquare/common';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { useDeviceAuthorizationSettingQuery } from '@lib/api/dashboard/queryHooks';
import clsx from 'clsx';
import React from 'react';

const DeviceAuthorizationInfo = () => {
  const { data: deviceAuthorizationSetting, isSuccess } =
    useDeviceAuthorizationSettingQuery();

  const isActivate = deviceAuthorizationSetting?.device === 'true';

  return (
    <SectionTitleContainer>
      <SectionMainTitleContainer>
        <SectionTitleHeader>Device Authorization</SectionTitleHeader>
        <SectionTitleHeaderDescription>
          IDE에서 등록된 디바이스만 사용하도록 제한할 수 있습니다.
        </SectionTitleHeaderDescription>
      </SectionMainTitleContainer>
      {isSuccess && (
        <div
          className={clsx(
            'flex items-center gap-2 rounded-md px-3 py-2 text-sm font-semibold leading-tight',
            isActivate
              ? 'bg-blue-200 text-blue-900'
              : 'bg-red-100 text-red-500',
          )}
        >
          {isActivate ? 'Activate' : 'Deactivate'}
          <div className="group relative">
            <QuestionMarkCircleIcon
              strokeWidth={2}
              className={clsx(
                'size-5 cursor-help',
                isActivate ? 'text-blue-900' : 'text-red-500',
              )}
            />
            <TooltipTop positionClass="invisible group-hover:visible -bottom-12 -right-4">
              {isActivate
                ? '활성화 상태이며, IDE에서 해당 프로젝트에 등록된 디바이스만 사용할 수 있습니다.'
                : '비활성화 상태이며, IDE에 연결된 모든 디바이스를 사용할 수 있습니다.'}
            </TooltipTop>
          </div>
        </div>
      )}
    </SectionTitleContainer>
  );
};

export default DeviceAuthorizationInfo;
