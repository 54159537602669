export const applicationKeys = {
  all: ['application'] as const,
  appLists: () => [...applicationKeys.all, 'list'] as const,
  appList: (projectIdx: string) =>
    [...applicationKeys.all, 'list', projectIdx] as const,
  applicationDetails: () => [...applicationKeys.all, 'detail'] as const,
  applicationDetail: (idx: string) =>
    [...applicationKeys.applicationDetails(), idx] as const,
  addApplications: () => [...applicationKeys.all, 'add application'] as const,
  addApplication: (projectIdx: string) =>
    [...applicationKeys.addApplications(), projectIdx] as const,
};
