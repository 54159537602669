import {
  ArrowSpinner,
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
} from '@autosquare/common';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';
import RemoteTestExecutionAlarmModal from '@components/TestExecution/shared/RemoteTestExecutionAlarmModal';
import TestExecutionContainer from '@components/TestExecution/TestExecutionContainer';
import { useRemoteMobileTestExecutionMutation } from '@lib/api/ide/remote/remote';
import { useDeleteSpeedMobileTestCaseMutation } from '@lib/api/ide/speedMobile/speedMobileTestCase';
import { PlatFormTypeServerName } from '@utils/static/platformTypeList';
import React, { useState } from 'react';

type Props = {
  platformType: PlatFormTypeServerName;
  isTestExecutionOpened: boolean;
  setIsTestExecutionOpened: React.Dispatch<React.SetStateAction<boolean>>;
  testCaseIdx: number;
  isOpenedCautionDialog: boolean;
  setIsOpenedCautionDialog: React.Dispatch<React.SetStateAction<boolean>>;
  closeDialog: () => void;
  selectedArray: number[];
  resetSelectedArray: () => void;
};

const ExecutionContainerAndErrorDialog = ({
  platformType,
  isTestExecutionOpened,
  setIsTestExecutionOpened,
  testCaseIdx,
  isOpenedCautionDialog,
  setIsOpenedCautionDialog,
  closeDialog,
  selectedArray,
  resetSelectedArray,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExecutionErrorModalOpen, setIsExecutionErrorModalOpen] =
    useState(false);
  const speedMobileDeleteTestCaseMutation =
    useDeleteSpeedMobileTestCaseMutation();

  const remoteMobileCaseExecutionMutation =
    useRemoteMobileTestExecutionMutation('case', platformType);
  return (
    <ErrorBoundaryWrapper>
      {isTestExecutionOpened && (
        <TestExecutionContainer
          setState={setIsTestExecutionOpened}
          idx={testCaseIdx}
          testType={'case'}
          deviceType={'mobile'}
          testLocation="speed-test"
          platformType={platformType}
          setIsModalOpen={setIsModalOpen}
          remoteMobileExecutionMutation={remoteMobileCaseExecutionMutation}
          setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
        />
      )}
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedCautionDialog}
        setIsOpened={setIsOpenedCautionDialog}
        type="caution"
        title={
          speedMobileDeleteTestCaseMutation.isError
            ? 'Error'
            : 'Delete Test Case'
        }
        subTitle={
          speedMobileDeleteTestCaseMutation.isError
            ? speedMobileDeleteTestCaseMutation.error.message
            : '해당 테스트 케이스를 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {!speedMobileDeleteTestCaseMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  speedMobileDeleteTestCaseMutation.mutate(
                    { idxList: selectedArray },
                    {
                      onSuccess: () => {
                        closeDialog();
                        resetSelectedArray();
                      },
                    },
                  )
                }
              >
                Delete
              </Button>
            )}
            <Button type="button" variant="secondary" onClick={closeDialog}>
              Cancel
            </Button>
          </>
        }
      />
      {remoteMobileCaseExecutionMutation.isLoading && <ArrowSpinner />}
      <RemoteTestExecutionAlarmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isExecutionErrorModalOpen={isExecutionErrorModalOpen}
        setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
      />
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default ExecutionContainerAndErrorDialog;
