import {
  AddAdministrationDeviceSchema,
  DeviceAdministrationInfoSchema,
  DeviceInfoOsFullName,
  UpdateAdministrationDeviceSchema,
} from '@customTypes/index';
import { z } from 'zod';

export const DeviceListInProjectSchema = DeviceAdministrationInfoSchema.omit({
  deviceEnabled: true,
}).extend({ enabled: z.boolean() });

export type DeviceListInProject = z.infer<typeof DeviceListInProjectSchema>;

export const DeviceApprovalRequestSchema = AddAdministrationDeviceSchema.pick({
  projectIdx: true,
  udid: true,
  deviceModel: true,
  deviceName: true,
  deviceOs: true,
  deviceType: true,
}).extend({
  description: z
    .string()
    .max(100, { message: '100자 이하 입력해 주세요.' })
    .optional(),
});

export type DeviceApprovalRequest = z.infer<typeof DeviceApprovalRequestSchema>;

export const UpdateProjectDeviceDetailSchema =
  UpdateAdministrationDeviceSchema.omit({
    projectIdx: true,
    deviceEnabled: true,
  }).extend({ enabled: z.boolean() });

export type UpdateProjectDeviceDetail = z.infer<
  typeof UpdateProjectDeviceDetailSchema
>;

export const DeleteProjectDeviceSchema = z.object({
  idxList: z.number().array(),
  reason: z
    .string()
    .max(100, { message: '100자 이하 입력해 주세요.' })
    .optional(),
});

export type DeleteProjectDevice = z.infer<typeof DeleteProjectDeviceSchema>;

export const EnableInstallDeviceSchema = DeviceAdministrationInfoSchema.pick({
  idx: true,
  deviceModel: true,
  deviceName: true,
  deviceOs: true,
  deviceType: true,
}).extend({
  remoteAlias: z.string(),
  packageName: z.string(),
  appVersion: z.string(),
  buildVersion: z.string(),
  enable: z.boolean(),
  failReason: z.string().nullish(),
});

export type EnableInstallDevice = z.infer<typeof EnableInstallDeviceSchema>;

export const InstalledAppListSchema = z.object({
  appName: z.string(),
  packageName: z.string(),
  appVersion: z.string(),
  buildVersion: z.string(),
  osType: z.nativeEnum(DeviceInfoOsFullName),
  description: z.string(),
});

export type InstalledAppList = z.infer<typeof InstalledAppListSchema>;

export const InstalledAppVersionSchema = z.object({
  appList: z.array(InstalledAppListSchema),
  connected: z.boolean(),
});

export type InstalledAppVersion = z.infer<typeof InstalledAppVersionSchema>;

export const InstallAppRequestSchema = InstalledAppListSchema.pick({
  osType: true,
  packageName: true,
  appVersion: true,
  buildVersion: true,
}).extend({
  projectIdx: z.string(),
  deviceIdxList: z.number().array().min(1),
});

export type InstallAppRequest = z.infer<typeof InstallAppRequestSchema>;

export const InstallAppResponseSchema = EnableInstallDeviceSchema.pick({
  idx: true,
  deviceModel: true,
  deviceName: true,
  deviceOs: true,
  deviceType: true,
  remoteAlias: true,
  failReason: true,
}).extend({
  result: z.enum(['Success', 'Fail']),
  registeredAt: z.string().datetime(),
});

export type InstallAppResponse = z.infer<typeof InstallAppResponseSchema>;
