import React from 'react';
import SelectDeviceHeader from './SelectDevice/SelectDeviceHeader';
import SelectDeviceTable from './SelectDevice/SelectDeviceTable';

const SelectDevice = () => {
  return (
    <>
      <SelectDeviceHeader />
      <SelectDeviceTable />
    </>
  );
};

export default SelectDevice;
