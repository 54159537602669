import {
  Button,
  ErrorBoundaryWrapper,
  TableBodyBasic,
  TableBodyContainer,
  TableHeader,
  TableHeaderContainer,
} from '@autosquare/common';
import { InstallAppResponse } from '@customTypes/index';
import { useParentPath } from '@hooks/useParentPath';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  installAppResponse: InstallAppResponse[];
};

const InstallResultTable = ({ installAppResponse }: Props) => {
  const navigate = useNavigate();

  const deviceListPath = useParentPath();

  const tableHeaderWithoutIdx = [
    'No',
    'Model',
    'OS',
    'PC Name',
    'Result',
    'Fail Reason',
  ];

  const tableHeader = tableHeaderWithoutIdx.map((header, idx) => ({
    idx,
    header,
  }));

  return (
    <ErrorBoundaryWrapper>
      <table className="w-full divide-y divide-gray-200">
        <TableHeaderContainer>
          {tableHeader.map((header) => (
            <TableHeader key={header.idx}>{header.header}</TableHeader>
          ))}
        </TableHeaderContainer>
        <TableBodyContainer>
          {installAppResponse.map((response, idx) => (
            <tr key={response.idx}>
              <TableBodyBasic>{idx + 1}</TableBodyBasic>
              <TableBodyBasic isPaddingZero={!!response.deviceModel}>
                <div className="flex flex-col">
                  <p>{response.deviceModel}</p>
                  {response.deviceName && (
                    <p className="text-xs leading-normal text-gray-500">
                      {response.deviceName}
                    </p>
                  )}
                </div>
              </TableBodyBasic>
              <TableBodyBasic isPaddingZero={!!response.deviceType}>
                <div className="flex flex-col">
                  <p>{response.deviceOs}</p>
                  {response.deviceType && (
                    <p className="text-xs leading-normal text-gray-500">
                      {response.deviceType}
                    </p>
                  )}
                </div>
              </TableBodyBasic>
              <TableBodyBasic>{response.remoteAlias}</TableBodyBasic>
              <TableBodyBasic
                textColor={response.result === 'Success' ? 'blue' : 'red'}
                isBold
              >
                {response.result === 'Success' ? 'PASS' : 'FAIL'}
              </TableBodyBasic>
              <TableBodyBasic
                textColor={response.result === 'Fail' ? 'red' : 'black'}
              >
                {response.failReason || '-'}
              </TableBodyBasic>
            </tr>
          ))}
        </TableBodyContainer>
      </table>
      <div className="mt-6 flex justify-end gap-3 border-t border-gray-200 pt-6">
        <Button
          type="button"
          variant="secondary"
          onClick={() => navigate(deviceListPath)}
        >
          Cancel
        </Button>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default InstallResultTable;
