import { RemoteStatus } from '@customTypes/ide/remote/type';
import { useRemoteIDEListQuery } from '@lib/api/ide/remote/remote';

export const useGetRemoteListAndIndex = (isOpen: boolean, idx: number) => {
  const remoteIDEListQuery = useRemoteIDEListQuery({ enabled: isOpen });
  const remoteIDEList = remoteIDEListQuery.data || [];

  const priority = {
    CONNECTED: 1,
    WAITING: 2,
    DISCONNECTED: 3,
  };

  const sortedRemoteIDEList = [...remoteIDEList]?.sort((a, b) => {
    if (a.idx === idx) return -1;
    if (b.idx === idx) return 1;
    const aPriority =
      a.status === RemoteStatus.CONNECTED && a.idx !== idx
        ? priority.DISCONNECTED
        : priority[a.status];
    const bPriority =
      b.status === RemoteStatus.CONNECTED && b.idx !== idx
        ? priority.DISCONNECTED
        : priority[b.status];
    return aPriority - bPriority;
  });

  const validIndex = sortedRemoteIDEList?.findIndex(
    (remoteIDE) =>
      remoteIDE.status === RemoteStatus.WAITING ||
      (remoteIDE.status === RemoteStatus.CONNECTED && remoteIDE.idx === idx),
  );

  return {
    remoteIDEListQuery,
    remoteIDEList,
    sortedRemoteIDEList,
    validIndex,
  };
};
