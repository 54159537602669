import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, ListBoxHeadlessInController } from '@autosquare/common';
import {
  AutoRegisterInfoData,
  CreateSchedulerData,
  UpdateSchedulerData,
} from '@customTypes/ide/scheduler/scheduler';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import { ErrorMessage } from '@hookform/error-message';
import {
  useGetJiraIssueTypeQuery,
  useGetJiraProjectListQuery,
} from '@lib/api/ide/autoRegisters/autoRegisters';
import { AutoRegisterPlatformServername } from '@utils/static/autoRegister/autoRegisterTypeList';
import { hasDuplicateTestResults } from '@utils/static/autoRegister/hasDuplicateJiraMapping';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import IdeJiraInputTestResult from '@components/shared/IdeJiraInputContents/IdeJiraInputTestResult';
import IdeJiraInputPriority from '@components/shared/IdeJiraInputContents/IdeJiraInputPriority';
import IdeJiraInputEditIssueType from '@components/shared/IdeJiraInputEditField/IdeJiraInputEditIssueType';
import { findJiraIssueTypeFromId } from '@utils/static/autoRegister/autoRegisterUtils';

type Props = {
  autoIndex: number;
  autoRegisters: AutoRegisterInfoData[];
  selectedAutoRegisterData: AutoRegisterInfoData;
};

const JiraInputEditField = ({
  autoIndex,
  autoRegisters,
  selectedAutoRegisterData,
}: Props) => {
  const {
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateSchedulerData | UpdateSchedulerData>();

  const [priorityErrorObj, setPriorityErrorObj] = useState<{
    isError: boolean;
    message: string;
  }>({ isError: false, message: '' });

  const { fields, append, remove, update, replace } = useFieldArray<
    CreateSchedulerData | UpdateSchedulerData
  >({
    control,
    name: `autoRegisters.${autoIndex}.jira.mapping`,
  });

  const fieldsLength = fields.length;
  const fieldsIndex = fieldsLength - 1;

  const {
    data: jiraProjectList,
    isLoading,
    isFetching,
    isError: isJiraProjectListError,
    error: jiraProjectError,
  } = useGetJiraProjectListQuery();
  const jiraProjectListLength = jiraProjectList?.length;

  const findJiraProjectNameFromKey = (key: string) =>
    jiraProjectList?.find((data) => data.key === key)?.name;

  const jiraProjectKey = useWatch({
    control,
    name: `autoRegisters.${autoIndex}.jira.projectKey`,
  });

  const {
    data: jiraIssueType,
    refetch: jiraIssueTypeRefetch,
    isError: isJiraIssueTypeError,
    error: jiraIssueTypeError,
  } = useGetJiraIssueTypeQuery(jiraProjectKey);

  const jiraMapping = autoRegisters?.[autoIndex]?.jira?.mapping || [];

  const isJiraPriorityError = priorityErrorObj.isError;

  const selectedFieldValue = (name: string) =>
    jiraMapping?.[fieldsIndex]?.[name];

  const selectedIssueType = findJiraIssueTypeFromId(
    selectedFieldValue('issueType'),
    jiraIssueType,
  );

  const selectedTestResult = selectedFieldValue('testResult');

  const plusButtonDisabledOption =
    fieldsLength === 3 || !selectedTestResult || !selectedIssueType;

  useEffect(() => {
    jiraIssueTypeRefetch();
  }, [jiraProjectKey]);

  useEffect(() => {
    if (
      selectedAutoRegisterData.platformName ===
      AutoRegisterPlatformServername.Jira
    ) {
      setValue(
        `autoRegisters.${autoIndex}.platformName`,
        selectedAutoRegisterData?.platformName,
      );
      setValue(
        `autoRegisters.${autoIndex}.jira.projectKey`,
        selectedAutoRegisterData?.jira?.projectKey,
      );
      replace(selectedAutoRegisterData?.jira?.mapping);
    }
  }, [selectedAutoRegisterData, setValue, replace]);

  useEffect(() => {
    const result = hasDuplicateTestResults(jiraMapping);
    const mapping = jiraMapping?.every(
      (item) => item?.testResult && item?.issueType,
    );

    if (
      mapping &&
      errors.autoRegisters?.[autoIndex]?.jira?.mapping?.type === 'required'
    ) {
      clearErrors(`autoRegisters.${autoIndex}.jira.mapping`);
    }

    if (
      !result &&
      errors.autoRegisters?.[autoIndex]?.jira?.mapping?.type === 'duplication'
    ) {
      clearErrors(`autoRegisters.${autoIndex}.jira.mapping`);
    }
  }, [jiraMapping, errors, autoIndex, clearErrors]);

  return (
    <>
      <Controller
        name={`autoRegisters.${autoIndex}.jira.projectKey`}
        render={({ field }) => (
          <ListBoxHeadlessInController
            value={field.value}
            buttonValue={findJiraProjectNameFromKey(field.value)}
            lists={jiraProjectList || []}
            valueToSave="key"
            valueToShow="name"
            onChange={(value) => {
              replace({ testResult: '', issueType: '', priority: '' });
              field.onChange(value);
            }}
            placeholder="이슈를 등록할 프로젝트를 선택해 주세요."
            noListMessage="선택할 항목이 없습니다."
            location="jira"
            disabled={isLoading || isFetching}
          />
        )}
      />
      {isJiraProjectListError ? (
        <p className="error-message">{jiraProjectError.message}</p>
      ) : (
        <ErrorMessage
          name={`autoRegisters.${autoIndex}.jira.projectKey`}
          errors={errors}
          render={({ message }) => <p className="error-message">{message}</p>}
        />
      )}
      <div className="flex flex-col space-y-2">
        {fields.map((field, index) => (
          <div
            key={field.id}
            className="flex items-center justify-between gap-x-3"
          >
            <div className="flex w-full flex-col items-start gap-y-2 text-sm font-normal leading-normal">
              <div className="flex w-full flex-col gap-y-2">
                {index === 0 && <div>Test Result*</div>}
                <Controller
                  control={control}
                  name={`autoRegisters.${autoIndex}.jira.mapping.${index}.testResult`}
                  render={({ field }) => (
                    <IdeJiraInputTestResult
                      field={field}
                      jiraProjectKey={jiraProjectKey}
                      jiraProjectListLength={jiraProjectListLength}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex w-full flex-col items-start gap-y-2 text-sm font-normal leading-normal">
              <div className="flex w-full flex-col gap-y-2">
                {index === 0 && <div>Issue Type*</div>}
                <Controller
                  control={control}
                  name={`autoRegisters.${autoIndex}.jira.mapping.${index}.issueType`}
                  render={({ field }) => (
                    <IdeJiraInputEditIssueType
                      field={field}
                      update={update}
                      jiraIssueType={jiraIssueType}
                      jiraProjectKey={jiraProjectKey}
                      index={index}
                      fieldsIndex={fieldsIndex}
                      selectedIssueType={selectedIssueType}
                      selectedTestResult={selectedTestResult}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex w-full flex-col items-start gap-y-2 text-sm font-normal leading-normal">
              <div className="flex w-full flex-col gap-y-2">
                {index === 0 && <div>Priority</div>}
                <Controller
                  control={control}
                  name={`autoRegisters.${autoIndex}.jira.mapping.${index}.priority`}
                  render={({ field }) => (
                    <IdeJiraInputPriority
                      field={field}
                      jiraMapping={jiraMapping}
                      index={index}
                      fieldsIndex={fieldsIndex}
                      jiraProjectKey={jiraProjectKey}
                      jiraIssueType={jiraIssueType}
                      setPriorityErrorObj={setPriorityErrorObj}
                    />
                  )}
                />
              </div>
            </div>
            <div className={clsx(index === 0 && 'pt-7')}>
              <Button
                type="button"
                variant="secondary"
                className={clsx(
                  'disabled:bg-gray-100',
                  fieldsLength === 1 && 'bg-gray-50',
                )}
                isSquare
                onClick={() => remove(index)}
                disabled={fieldsLength === 1}
              >
                <MinusIcon className="size-6" />
              </Button>
            </div>
          </div>
        ))}
        <Button
          type="button"
          variant="imageOriginal"
          className="flex-center h-9 w-full rounded border border-dashed border-gray-300 px-2 py-1 disabled:bg-gray-50"
          onClick={() =>
            append({ testResult: null, issueType: null, priority: null })
          }
          disabled={plusButtonDisabledOption}
        >
          <PlusIcon
            className={clsx(
              'size-6',
              fieldsLength === 3 ? 'text-gray-400' : 'text-gray-900',
            )}
          />
        </Button>
        {isJiraIssueTypeError ? (
          <p className="error-message">{jiraIssueTypeError.message}</p>
        ) : isJiraPriorityError ? (
          <p className="error-message">{priorityErrorObj.message}</p>
        ) : (
          <ErrorMessage
            name={`autoRegisters.${autoIndex}.jira.mapping`}
            errors={errors}
            render={({ message }) => <p className="error-message">{message}</p>}
          />
        )}
      </div>
    </>
  );
};

export default JiraInputEditField;
