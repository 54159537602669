import { RootState } from '@app/store';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import TestResultSaveOptionsCheckbox from '@components/Scheduler/TestResultSaveOptionsCheckbox';
import { UpdateSpeedSchedulerData } from '@customTypes/ide/speedScheduler/speedScheduler';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

const SpeedTestSchedulerDetailTestResultSaveOptions = () => {
  const { register } = useFormContext<UpdateSpeedSchedulerData>();

  const isRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );

  const checkboxList = [
    { title: 'Video', register: register('saveVideo') },
    { title: 'Log File', register: register('saveLog') },
  ];

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer
        title="Test Result Save Options"
        isTitleCenter={true}
      >
        <div className="mt-2 grid grid-cols-2">
          {checkboxList.map((checkbox) => (
            <TestResultSaveOptionsCheckbox
              key={checkbox.title}
              title={checkbox.title}
              register={checkbox.register}
              disabled={!isRunning}
            />
          ))}
        </div>
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default SpeedTestSchedulerDetailTestResultSaveOptions;
