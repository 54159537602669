import { RootState } from '@app/store';
import {
  Breadcrumbs,
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
  PagingHeadings,
} from '@autosquare/common';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import WebDriverAgent from '@components/SettingsAndHelpModal/components/Settings/IosTest/WebDriverAgent';
import XCodeLocation from '@components/SettingsAndHelpModal/components/Settings/IosTest/XCodeLocation';
import { getRemoteState } from '@utils/static/getConfiguration';
import RemoteWebDriverAgent from './IosTest/RemoteWebDriverAgent';
type Props = {
  closeModal: () => void;
};

const IosTest = ({ closeModal }: Props) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const { standardConfiguration, remoteConfiguration } = getRemoteState();

  const accessToken = useSelector(
    (state: RootState) => state.accessTokenValue.ideAccessToken,
  );

  useEffect(() => {
    if (!accessToken) {
      setIsModalOpened(true);
    }
  }, []);

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'Settings' },
    { title: 'iOS Test' },
  ];

  return (
    <ErrorBoundaryWrapper>
      <main className="gray-scrollbar w-full pr-10 pt-5">
        <PagingHeadings
          breadcrumbs={breadcrumbs}
          pageTitle={'iOS Test Settings'}
        />
        {standardConfiguration ? (
          <>
            <XCodeLocation />
            <WebDriverAgent accessToken={accessToken} closeModal={closeModal} />
          </>
        ) : (
          remoteConfiguration && (
            <RemoteWebDriverAgent closeModal={closeModal} />
          )
        )}
      </main>
      <DoubleCheckDialogHeadlessUI
        isOpened={isModalOpened}
        setIsOpened={setIsModalOpened}
        type={'caution'}
        title={'Error'}
        subTitle={'This page is available after login'}
        buttonChildren={
          <Button
            type="button"
            variant="primary"
            onClick={() => {
              closeModal();
              setIsModalOpened(false);
            }}
          >
            Close
          </Button>
        }
      />
    </ErrorBoundaryWrapper>
  );
};

export default IosTest;
