import React from 'react';
import DeviceDetailFormFields from './DeviceDetailFormFields';
import { useFormContext } from 'react-hook-form';
import { UpdateProjectDeviceDetail } from '@customTypes/index';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';

const DeviceDetailOs = () => {
  const { register } = useFormContext<UpdateProjectDeviceDetail>();

  return (
    <DeviceDetailFormFields title={'Device OS*'} name={'deviceOs'}>
      <div className="relative">
        <input
          className="input-base disabled:bg-gray-100"
          {...register('deviceOs')}
          disabled
        />
        <ChevronUpDownIcon
          className="absolute right-2 top-2 size-5 text-gray-400"
          aria-hidden="true"
        />
      </div>
    </DeviceDetailFormFields>
  );
};

export default DeviceDetailOs;
