import React from 'react';
import {
  Breadcrumbs,
  CreatePlusButton,
  ErrorBoundaryWrapper,
  PageHeadingButtons,
  PagingHeadingsInAutosquare,
} from '@autosquare/common';
import SearchForm from '@components/SearchForm/SearchForm';

import { useLocation, useNavigate } from 'react-router';
import MobileSpeedTestScenarioTable from './components/MobileSpeedTestScenarioTable';
import { useSearchMobileSpeedTestScenarioListQuery } from '@lib/api/ide/speedMobile/speedMobileTestScenario';
import getFirstGenerationDateToDateType from '@utils/static/getFirstGenerationDateToDateType';

const MobileSpeedTestScenario = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const overviewPath = `${pathname.split('/').slice(0, -2).join('/')}/overview`;

  const { data: searchMobileSpeedTestScenarioList } =
    useSearchMobileSpeedTestScenarioListQuery();
  const initialCreatedAt =
    searchMobileSpeedTestScenarioList?.[0]?.initialCreatedAt;
  const formattedCreatedAt = getFirstGenerationDateToDateType(initialCreatedAt);
  const breadcrumbs: Breadcrumbs[] = [
    { title: 'Speed Test', href: overviewPath },
    { title: 'Mobile', href: pathname },
    { title: 'Test Scenario', href: pathname },
    { title: 'List', href: pathname },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      onClick: () => {
        navigate('create');
      },
      description: 'Create Scenario',
      icon: <CreatePlusButton />,
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Scenario List'}
        pageHeadingButtons={pageHeadingButtons}
      />
      <SearchForm initialCreatedAt={formattedCreatedAt} />
      <MobileSpeedTestScenarioTable />
    </ErrorBoundaryWrapper>
  );
};

export default MobileSpeedTestScenario;
