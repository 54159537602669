import { API } from '@api/endpoints';
import {
  DeviceInfoOsFullName,
  EnableInstallDevice,
  InstallAppRequest,
  InstallAppResponse,
  InstalledAppVersion,
} from '@customTypes/index';
import { axiosInstance } from '@lib/api/dashboard';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { projectDeviceKeys } from '@queries/index';

// GET
// 원격지 디바이스 정보 리스트
// https://dev.t-square.co.kr/swagger-ui/index.html#/Project%20Device%20App%20Management/getInstalledAppVersionInLocalDevice
const getEnabledInstallDeviceList = async (
  projectIdx: string,
  packageName: string,
  type: DeviceInfoOsFullName,
): Promise<EnableInstallDevice[]> => {
  const response = await axiosInstance.get(
    `${API.PROJECT_DEVICE_APP_MANAGEMENT}?project=${projectIdx}&package=${packageName}&type=${type}`,
  );
  return response.data;
};

export const useEnabledInstallDeviceListQuery = (
  packageName: string,
  type: DeviceInfoOsFullName,
  options?: UseQueryOptions<EnableInstallDevice[], ServerResponseErrorDetails>,
) => {
  const { projectIdx } = useParams();

  return useQuery<EnableInstallDevice[], ServerResponseErrorDetails>({
    queryFn: () => getEnabledInstallDeviceList(projectIdx, packageName, type),
    queryKey: projectDeviceKeys.enabledInstallDeviceList(
      projectIdx,
      packageName,
      type,
    ),
    ...options,
  });
};

// 원격지 디바이스 내 설치된 앱 버전 정보 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Project%20Device%20App%20Management/getInstalledAppVersionInLocalDevice_1
const getInstalledAppVersionList = async (
  projectIdx: string,
  deviceIdx: string,
): Promise<InstalledAppVersion> => {
  const response = await axiosInstance.get(
    `${API.PROJECT_DEVICE_APP_MANAGEMENT}/version/${deviceIdx}?project=${projectIdx}`,
  );
  return response.data;
};

export const useInstalledAppVersionListQuery = () => {
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const deviceIdx = searchParams.get('idx');

  return useQuery<InstalledAppVersion, ServerResponseErrorDetails>({
    queryFn: () => getInstalledAppVersionList(projectIdx, deviceIdx),
    queryKey: projectDeviceKeys.installedAppVersionList(projectIdx, deviceIdx),
  });
};

// POST
// 원격지 디바이스내 앱 설치
// https://dev.t-square.co.kr/swagger-ui/index.html#/Project%20Device%20App%20Management/installApp
export const installAppInDevice = async (
  data: InstallAppRequest,
): Promise<InstallAppResponse[]> => {
  const response = await axiosInstance.post(
    `${API.PROJECT_DEVICE_APP_MANAGEMENT}/install`,
    data,
  );
  return response.data;
};

export const useInstallAppInDeviceMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    InstallAppResponse[],
    ServerResponseErrorDetails,
    InstallAppRequest
  >({
    mutationFn: (data) => installAppInDevice(data),
    onSuccess: () => {
      queryClient.invalidateQueries(
        projectDeviceKeys.installedAppVersionLists(),
      );
    },
  });
};
