export const BASE_URL = '/api';

export const PROJECT = `${BASE_URL}/project`;
export const PROJECT_DEVICE = `${PROJECT}/devices`;
export const PROJECT_APPLICATION = `${PROJECT}/app`;
export const ADMIN = `${BASE_URL}/admin`;
export const ADMIN_PROJECT = `${ADMIN}/project`;
export const ADMIN_SETTINGS = `${ADMIN}/settings`;
export const ADMIN_WEBDRIVER = `${ADMIN}/webdriver`;
export const OVERVIEW = `${BASE_URL}/overview`;
export const SETTINGS = `${BASE_URL}/settings`;
export const DOWNLOAD = `${BASE_URL}/ide/install`;
export const AUTH_CODE = `${BASE_URL}/user/auth-code/`;
export const RESET_PASSWORD = `${BASE_URL}/user/`;

// Mobile
const MOBILE_TEST_SCENARIO = `${BASE_URL}/scenario`;
const MOBILE_TEST_CASE = `${BASE_URL}/testcase`;
const MOBILE_TEST_STEP = `${BASE_URL}/teststep`;
const TEST_RESULT = `${BASE_URL}/result`;
const MOBILE_TEST_AUTOMATION = `${MOBILE_TEST_CASE}/automation`;

// Web
const WEB_TEST_SCENARIO = `${BASE_URL}/web/scenario`;
const WEB_TEST_CASE = `${BASE_URL}/web/testcase`;
const WEB_TEST_STEP = `${BASE_URL}/web/teststep`;
const WEB_TEST_RESULT = `${BASE_URL}/web/result/functional`;

// Mobile Speed
const MOBILE_SPEED_TEST_SCENARIO = `${BASE_URL}/scenario/speed`;
const MOBILE_SPEED_TEST_CASE = `${BASE_URL}/testcase/speed`;
const MOBILE_APP_SPEED_TEST_STEP = `${BASE_URL}/teststep/speed/app`;
const MOBILE_WEB_SPEED_TEST_STEP = `${BASE_URL}/teststep/speed/web`;
const MOBILE_SPEED_TEST_RESULT = `${BASE_URL}/result/speed/mobile`;
const MOBILE_SPEED_TEST_AUTOMATION = `${MOBILE_SPEED_TEST_CASE}/automation`;

// Web Speed
const WEB_SPEED_TEST_SCENARIO = `${BASE_URL}/web/speed/scenario`;
const WEB_SPEED_TEST_CASE = `${BASE_URL}/testcase/speed/web`;
const WEB_SPEED_TEST_STEP = `${BASE_URL}/web/teststep/speed`;
const WEB_SPEED_TEST_RESULT = `${BASE_URL}/result/speed`;
const WEB_SPEED_TEST_RESULT_DETAIL = `${WEB_SPEED_TEST_RESULT}/list/detail`;

// Scheduler
const SCHEDULER = `${BASE_URL}/scheduler`;

// Speed Scheduler
const SPEED_SCHEDULER = `${SCHEDULER}/speed`;

export const API = {
  // User
  LOGIN: `${BASE_URL}/user/login`,
  SIGN_UP: `${BASE_URL}/user/signup`,
  LOGOUT: `${BASE_URL}/logout`,
  USER_INFO: `${BASE_URL}/userinfo`,
  CREATE_INVITE_SIGNUP: `${BASE_URL}/admin/invite`,
  CHECK_USER_INFO_DECODE_KEY: `${BASE_URL}/user/decode-key?key=`,

  // Settings
  SETTINGS: `${SETTINGS}/`,
  EMAIL_ALARM_SETTING: `${SETTINGS}/alarm`,

  // Overview
  OVERVIEW: `${OVERVIEW}`,
  UI_TEST_REPORT_OVERVIEW: `${OVERVIEW}/resultReport?projectIdx=`,
  SPEED_TEST_REPORT_OVERVIEW: `${OVERVIEW}/speed/resultReport?projectIdx=`,

  // Admin User List
  ADMIN: `${ADMIN}`,
  GET_ADMIN_USER_LIST: `${ADMIN}/userlist`,
  GET_ADMIN_DETAIL_USER_INFO: `${ADMIN}/userlist/`,
  UPDATE_ADMIN_DETAIL_USER_INFO: `${ADMIN}/userlist/`,

  // Admin Project
  ADMIN_PROJECT: `${ADMIN_PROJECT}`,
  GET_ADMIN_ALL_PROJECT_LIST: `${ADMIN_PROJECT}`,
  GET_ADMIN_PROJECT: `${ADMIN_PROJECT}/`,
  CREATE_ADMIN_PROJECT: `${ADMIN_PROJECT}`,
  UPDATE_ADMIN_PROJECT: `${ADMIN_PROJECT}/`,
  ADMIN_MESSENGER: `${ADMIN_PROJECT}/messenger`,

  // Admin System Settings
  GET_ADMIN_SYSTEM_SETTINGS: `${ADMIN_SETTINGS}`,
  UPDATE_ADMIN_SYSTEM_SETTINGS: `${ADMIN_SETTINGS}`,

  // Admin WebDriver
  GET_ADMIN_WEB_DRIVER_LIST: `${ADMIN_WEBDRIVER}`,
  UPLOAD_ADMIN_WEB_DRIVER: `${ADMIN_WEBDRIVER}`,

  // Project
  PROJECT: `${BASE_URL}/project`,
  getMembers: (projectIdx: string) => {
    return `${PROJECT}/${projectIdx}/list`;
  },
  postProjectMember: (projectIdx: string) => {
    return `${PROJECT}/${projectIdx}/member`;
  },
  getMemberDetail: (projectIdx: string, idx: string) => {
    return `${PROJECT}/${projectIdx}/list/${idx}`;
  },
  putMemberInfo: (projectIdx: string, idx: string) => {
    return `${PROJECT}/${projectIdx}/member/${idx}`;
  },
  removeMember: (projectIdx: string, idx: string) => {
    return `${PROJECT}/${projectIdx}/member/${idx}`;
  },

  // Project Device
  PROJECT_DEVICE: `${PROJECT_DEVICE}`,

  //Project Application
  PROJECT_APPLICATION: `${PROJECT_APPLICATION}`,

  // Project Device App Management
  PROJECT_DEVICE_APP_MANAGEMENT: `${PROJECT_DEVICE}/app`,

  // Test Result
  TEST_RESULT: `${BASE_URL}/result`,
  TEST_RESULT_OVERVIEW: `${TEST_RESULT}/overview?projectIdx=`,
  TEST_RESULT_ALL_INQUIRY: `${TEST_RESULT}?projectIdx=`,
  testResultListPagination: (
    projectIdx: string,
    currentPage: number,
    limit: number,
  ) => {
    return `${TEST_RESULT}?projectIdx=${projectIdx}&page=${currentPage}&size=${limit}`;
  },
  GET_TEST_RESULT: `${BASE_URL}/result/`,
  getTestResultList: (projectIdx: number, page: number, size: number) => {
    return `${TEST_RESULT}?projectIdx=${projectIdx}&page=${page}&size=${size}`;
  },
  getMobileTestCaseExecutionDetail: (
    testResultIdx: string,
    testCaseidx: number,
  ) => {
    return `${TEST_RESULT}/${testResultIdx}/detail/${testCaseidx}`;
  },
  getTestCaseResult: (idx: string) => {
    return `${TEST_RESULT}/${idx}/detail?ideRequest=false`;
  },

  // Web Test Result
  WEB_TEST_RESULT: WEB_TEST_RESULT,
  GET_WEB_TEST_RESULT: `${WEB_TEST_RESULT}/`,
  getWebTestResultList: (projectIdx: string, page: number, size: number) => {
    return `${WEB_TEST_RESULT}?projectIdx=${projectIdx}&page=${page}&size=${size}`;
  },
  getWebTestCaseExecutionDetail: (
    testResultIdx: string,
    testCaseidx: number,
  ) => {
    return `${WEB_TEST_RESULT}/${testResultIdx}/detail/${testCaseidx}`;
  },
  getWebTestCaseResult: (idx: string) => {
    return `${WEB_TEST_RESULT}/${idx}/detail?ideRequest=false`;
  },

  // Speed Test Result > Mobile
  MOBILE_SPEED_TEST_RESULT: MOBILE_SPEED_TEST_RESULT,
  getMobileSpeedTestResultList: (
    projectIdx: string,
    page: number,
    size: number,
  ) =>
    `${MOBILE_SPEED_TEST_RESULT}/list?projectIdx=${projectIdx}&page=${page}&size=${size}`,

  getMobileSpeedTestResultDetail: (
    resultIdx: string,
    projectIdx: string,
    groupIdx: string,
    start: string,
    end: string,
  ) =>
    `${MOBILE_SPEED_TEST_RESULT}/${resultIdx}?project=${projectIdx}&group=${groupIdx}&start=${start}&end=${end}`,

  DETAIL_SPEED_MOBILE_TEST_RESULT_BY_IDX: `${MOBILE_SPEED_TEST_RESULT}/detail/`,

  // Speed Test Result > Web
  WEB_SPEED_TEST_RESULT: WEB_SPEED_TEST_RESULT,
  getWebSpeedTestResultList: (projectIdx: string, page: number) =>
    `${WEB_SPEED_TEST_RESULT}/list?projectIdx=${projectIdx}&page=${page}`,
  getWebSpeedTestResultDetail: (
    projectIdx: string,
    groupIdx: string,
    idx: string,
    start: string,
    end: string,
    orderBy: string,
    channel: string,
  ) =>
    `${WEB_SPEED_TEST_RESULT_DETAIL}?projectIdx=${projectIdx}&groupIdx=${groupIdx}&idx=${idx}&startDate=${start}&endDate=${end}&orderBy=${orderBy}&channel=${channel}`,
  getWebSpeedTestResultByIdx: (chartIdx: string) =>
    `${WEB_SPEED_TEST_RESULT_DETAIL}/repeat?resultIdx=${chartIdx}`,

  // Access Log Controller
  SAVE_ACCESS_LOG: `${BASE_URL}/log`,

  INVITE: `${BASE_URL}/invite`,
  DECODE_KEY: `${BASE_URL}/user/decode-key`,

  // IDE File Download
  ideFileDownload: (os: 'win' | 'mac', arch: 'x64' | 'arm64') =>
    `${DOWNLOAD}?os=${os}&arch=${arch}`,

  // Send Auth Code
  SEND_AUTH_CODE: `${AUTH_CODE}send`,
  CONFIRM_AUTH_CODE: `${AUTH_CODE}confirm`,

  //Reset Password
  RESET_PASSWORD: `${RESET_PASSWORD}password-reset`,

  // Mobile
  MOBILE_TEST_SCENARIO: `${MOBILE_TEST_SCENARIO}`,
  MOBILE_TEST_CASE: `${MOBILE_TEST_CASE}`,
  MOBILE_TEST_STEP: `${MOBILE_TEST_STEP}`,
  MOBILE_TEST_AUTOMATION: MOBILE_TEST_AUTOMATION,

  // Web
  WEB_TEST_SCENARIO: `${WEB_TEST_SCENARIO}`,
  WEB_TEST_CASE: `${WEB_TEST_CASE}`,
  WEB_TEST_STEP: `${WEB_TEST_STEP}`,

  // MOBILE SPEED
  MOBILE_SPEED_TEST_SCENARIO: MOBILE_SPEED_TEST_SCENARIO,
  MOBILE_SPEED_TEST_CASE: MOBILE_SPEED_TEST_CASE,
  MOBILE_APP_SPEED_TEST_STEP: MOBILE_APP_SPEED_TEST_STEP,
  MOBILE_WEB_SPEED_TEST_STEP: MOBILE_WEB_SPEED_TEST_STEP,
  MOBILE_SPEED_TEST_AUTOMATION: MOBILE_SPEED_TEST_AUTOMATION,

  // WEB SPEED
  WEB_SPEED_TEST_SCENARIO: WEB_SPEED_TEST_SCENARIO,
  WEB_SPEED_TEST_CASE: WEB_SPEED_TEST_CASE,
  WEB_SPEED_TEST_STEP: WEB_SPEED_TEST_STEP,

  // SCHEDULER
  SCHEDULER: SCHEDULER,

  // SPEED SCHEDULER
  SPEED_SCHEDULER: SPEED_SCHEDULER,

  // Admin Device
  ADMIN_DEVICES: `${ADMIN}/devices`,
  ADMIN_DEVICES_APPROVAL: `${ADMIN}/devices/approval`,
};
