import React from 'react';
import InstallResultHeader from './InstallResultHeader';
import { ErrorBoundaryWrapper, Spinner } from '@autosquare/common';

const InstallLoading = () => {
  return (
    <ErrorBoundaryWrapper>
      <InstallResultHeader />
      <div className="py-12">
        <Spinner size="lg" message="Installing..." />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default InstallLoading;
