import React, { useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import {
  useWebTestCaseResultQuery,
  useWebTestResultDeleteMutation,
  useWebTestResultDetailQuery,
} from '@lib/api/dashboard/web/webTestResult';

import Summary from '@components/shared/TestResultDetail/Summary';
import Information from '@components/shared/TestResultDetail/Information';
import ExecutionDetails from '@components/shared/TestResultDetail/ExecutionDetails';
import TestCaseResultListSidebar from '@components/shared/TestResultDetail/TestCaseResultListSidebar';

import {
  ArrowSpinner,
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
  ErrorMessage,
  PagingHeadings,
  TableSkeleton,
  TestResultDetailMenu,
  useDetectScrollLocation,
} from '@autosquare/common';
import WebExportOptions from '@components/UITest/WebExportOptions/WebExportOptions';

const WebTestResultDetail = () => {
  const { projectIdx } = useParams();
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExportOptionOpen, setIsExportOptionOpen] = useState(false);

  const webTestCaseResultQuery = useWebTestCaseResultQuery(idx);
  const webTestResultDetailQuery = useWebTestResultDetailQuery(idx);

  const testResultSummary = webTestCaseResultQuery.data?.summary;
  const resultDetails = webTestCaseResultQuery.data?.resultDetail;

  const webTestResultDeleteMutation = useWebTestResultDeleteMutation();

  const { elementRefs, activeSection, handleClick } = useDetectScrollLocation({
    offsetY: -100,
    dependencyArray: resultDetails,
  });

  const breadcrumbs = [
    {
      title: 'Web',
      href: `/projects/${projectIdx}/web/test-result`,
    },
    {
      title: 'UI Test Result',
      href: `/projects/${projectIdx}/web/test-result`,
    },
    {
      title: 'Detail',
      href: pathname + search,
    },
  ];

  const testResultDetailMenu: TestResultDetailMenu[] = [
    {
      title: 'Export',
      onClick: () => {
        setIsExportOptionOpen(true);
      },
      disabled: resultDetails?.length === 0,
    },
    {
      title: 'Delete',
      onClick: () => {
        if (webTestResultDeleteMutation.isError) {
          webTestResultDeleteMutation.reset();
        }
        setIsModalOpen(true);
      },
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="xl:mr-96">
        <PagingHeadings
          breadcrumbs={breadcrumbs}
          pageTitle={'UI Test Result Detail'}
          testResultDetailMenu={testResultDetailMenu}
        />
      </div>
      {webTestCaseResultQuery.isLoading ||
      webTestCaseResultQuery.isFetching ||
      webTestResultDetailQuery.isLoading ||
      webTestResultDetailQuery.isFetching ? (
        <TableSkeleton />
      ) : webTestCaseResultQuery.isError || webTestResultDetailQuery.isError ? (
        <ErrorMessage>
          {webTestCaseResultQuery.error.message ||
            webTestResultDetailQuery.error.message}{' '}
        </ErrorMessage>
      ) : (
        <>
          <div className="xl:mr-96">
            <Summary
              testResultSummary={testResultSummary}
              result={webTestResultDetailQuery.data?.result}
              reason={webTestResultDetailQuery.data?.failReason}
            />
            <Information testResult={webTestResultDetailQuery.data} />
            <ExecutionDetails
              resultDetails={resultDetails}
              elementRefs={elementRefs}
            />
          </div>
          <TestCaseResultListSidebar
            resultDetails={resultDetails}
            handleClick={handleClick}
            activeSection={activeSection}
          />
          <WebExportOptions
            breadcrumbs={breadcrumbs}
            isOpen={isExportOptionOpen}
            setIsOpen={setIsExportOptionOpen}
            resultDetails={resultDetails}
          />
        </>
      )}
      <DoubleCheckDialogHeadlessUI
        isOpened={isModalOpen}
        setIsOpened={setIsModalOpen}
        type={'caution'}
        title={
          webTestResultDeleteMutation.isError ? 'Error' : 'Delete test result'
        }
        subTitle={
          webTestResultDeleteMutation.isError
            ? webTestResultDeleteMutation.error.message
            : '해당 테스트 결과를 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {!webTestResultDeleteMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  webTestResultDeleteMutation.mutate(
                    { idxList: [Number(idx)] },
                    {
                      onError: () => {
                        setIsModalOpen(true);
                      },
                    },
                  )
                }
                disabled={webTestResultDeleteMutation.isLoading}
              >
                Delete
              </Button>
            )}
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              Cancel
            </Button>
          </>
        }
      />
      {webTestResultDeleteMutation.isLoading && <ArrowSpinner />}
    </ErrorBoundaryWrapper>
  );
};

export default WebTestResultDetail;
