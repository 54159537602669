import { ErrorBoundaryWrapper } from '@autosquare/common';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { RootState } from '@app/store';
import { useMobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';
import {
  useMobileScreenshotQuery,
  useMobileSourceQuery,
  useRemoteMobileScreenshotQuery,
  useRemoteMobileSourceQuery,
} from '@lib/api/ide/mobile/mobileDevice';
import useSetupCoordinates from '@hooks/testStep/useSetupCoordinates';
import { mobileDeviceKeys } from '@queries/ide/mobile/mobileDeviceKeys';
import { getMobileScreenshotSource } from '@store/ide/mobileTestStep/mobileScreenshotSourceSlice';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import AddTestStep from './MainTestStep/AddTestStep';
import ToolButton from './MainTestStep/ToolButton';
import clsx from 'clsx';
import { Mode } from '@customTypes/ide/remote/type';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';
import { getRemoteState } from '@utils/static/getConfiguration';
import ScreenshotContainer from './MainTestStep/ScreenshotContainer';
import TestStepListContainer from './MainTestStep/TestStepListContainer';

const MainTestStep = () => {
  const {
    isCountThreeNeeded,
    setIsCountThreeNeeded,
    countDown,
    setCountDown,
    setIsListboxOpened,
    isLandscapeMode,
  } = useMobileTestStepContext();

  const connectedDeviceInfo = useSelector(
    (state: RootState) => state.device.deviceInfo,
  );
  const methods = useForm<CreateTestStepData>({
    defaultValues: {
      mobileOs: connectedDeviceInfo.os,
    },
  });

  const command = methods.watch('command');
  const value = methods.watch('value');
  const clickBy = methods.watch('optionsDto.clickBy');
  const conditionCommand = methods.watch('optionsDto.conditionCommand');

  const { configuration } = getRemoteState();
  const mobileScreenshotQuery =
    configuration === Mode.Standard
      ? useMobileScreenshotQuery()
      : useRemoteMobileScreenshotQuery();

  const mobileSourceQuery =
    configuration === Mode.Standard
      ? useMobileSourceQuery()
      : useRemoteMobileSourceQuery();
  const mobileSource = mobileSourceQuery.data;

  //screenshot 저장
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMobileScreenshotSource(mobileScreenshotQuery.data));
  }, [mobileScreenshotQuery.data]);

  const appInfo = mobileSource?.app_info;

  useSetupCoordinates(command, clickBy, value, conditionCommand);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isCountThreeNeeded) {
      const timer = setInterval(() => {
        setCountDown((prev) => prev - 1);
      }, 1000);

      if (countDown === 0) {
        queryClient.refetchQueries({ queryKey: mobileDeviceKeys.screenshot() });
        queryClient.refetchQueries({ queryKey: mobileDeviceKeys.source() });
        setIsCountThreeNeeded(false);
        setIsListboxOpened(true);
        setCountDown(3);
      }

      return () => {
        clearInterval(timer);
      };
    }
  }, [countDown, isCountThreeNeeded]);

  return (
    <ErrorBoundaryWrapper>
      <div className="flex">
        <ToolButton
          refetch={() => {
            mobileScreenshotQuery.refetch();
            mobileSourceQuery.refetch();
            setIsListboxOpened(true);
          }}
          appInfo={appInfo}
        />
        <div
          className={clsx(
            'mb-2 grid h-[calc(100lvh-6rem)] w-full grid-cols-10 gap-4',
            isLandscapeMode ? 'ml-4 mr-11' : 'mx-4',
          )}
        >
          <FormProvider {...methods}>
            <ScreenshotContainer />
            <AddTestStep
              deviceInfo={mobileSourceQuery.data?.device_info}
              appInfo={appInfo}
            />
          </FormProvider>
          <TestStepListContainer />
        </div>
      </div>
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default MainTestStep;
