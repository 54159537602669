import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  UpdateWebTestStepData,
  UpdateWebTestStepDataKeys,
} from '@customTypes/testStep/type';

import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import React from 'react';
import { useFormContext, UseFormRegisterReturn } from 'react-hook-form';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  term: string;
  name: UpdateWebTestStepDataKeys;
  register: UseFormRegisterReturn;
  type: React.HTMLInputTypeAttribute;
  isBorderNeeded?: boolean;
}

const WebTestStepInputByReactHookForm = ({
  term,
  name,
  register,
  type = 'text',
  isBorderNeeded = true,
  ...rest
}: Props) => {
  const {
    formState: { errors },
  } = useFormContext<UpdateWebTestStepData>();

  return (
    <ErrorBoundaryWrapper>
      <div
        className={clsx(
          'items-center sm:flex sm:px-6 sm:py-4',
          isBorderNeeded ? 'border-b border-gray-200' : 'border-none',
        )}
      >
        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
          {term}
        </dt>
        <dd className="mt-1 w-full text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
          <div className="w-full">
            <label htmlFor={name} className="sr-only">
              {term}
            </label>
            {type === 'number' ? (
              <input
                type={'number'}
                name={name}
                id={name}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                {...register}
                {...rest}
              />
            ) : (
              <input
                type={type}
                name={name}
                id={name}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                {...register}
                {...rest}
              />
            )}
          </div>
          <div>
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <p className="standard-error-message">{message}</p>
              )}
            />
          </div>
        </dd>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default WebTestStepInputByReactHookForm;
