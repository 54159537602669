import { Checkbox, Detail, TableBodyBasic } from '@autosquare/common';
import { DeviceAdministrationInfo } from '@customTypes/index';
import { ClockIcon } from '@heroicons/react/24/outline';
import { timePattern } from '@utils/static/timePattern';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import DeviceStatus from './AdminDeviceTableBody/DeviceStatus';

type Props = {
  adminDevice: DeviceAdministrationInfo;
  isDeviceEnabled: boolean;
  selectedArray: number[];
  selectArray: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  idx: number;
};

const AdminDeviceTableBody = ({
  adminDevice,
  isDeviceEnabled,
  selectedArray,
  selectArray,
  idx,
}: Props) => {
  const navigate = useNavigate();

  return (
    <tr
      className={clsx({
        'bg-gray-100': !adminDevice.deviceEnabled && isDeviceEnabled,
      })}
    >
      <TableBodyBasic>
        <Checkbox
          checked={selectedArray.includes(adminDevice.idx)}
          onChange={(e) => selectArray(adminDevice.idx, e)}
        />
      </TableBodyBasic>
      <TableBodyBasic disabled={!isDeviceEnabled}>{idx + 1}</TableBodyBasic>
      <TableBodyBasic disabled={!isDeviceEnabled}>
        <div>
          <p
            className={clsx(
              isDeviceEnabled ? 'text-gray-900' : 'text-gray-300',
            )}
          >
            {adminDevice.deviceModel}
          </p>
          {adminDevice.deviceName && (
            <p
              className={clsx(
                'mt-1 w-full break-words',
                isDeviceEnabled ? 'text-gray-500' : 'text-gray-300',
              )}
            >
              {adminDevice.deviceName}
            </p>
          )}
        </div>
      </TableBodyBasic>
      <TableBodyBasic disabled={!isDeviceEnabled}>
        <div>
          <p
            className={clsx(
              isDeviceEnabled ? 'text-gray-900' : 'text-gray-300',
            )}
          >
            {adminDevice.deviceOs}
          </p>
          {adminDevice.deviceType && (
            <p
              className={clsx(
                'mt-1 w-full break-words',
                isDeviceEnabled ? 'text-gray-500' : 'text-gray-300',
              )}
            >
              {adminDevice.deviceType}
            </p>
          )}
        </div>
      </TableBodyBasic>
      <TableBodyBasic disabled={!isDeviceEnabled}>
        {adminDevice.projectName}
      </TableBodyBasic>
      <TableBodyBasic disabled={!isDeviceEnabled}>
        <div>{dayjs(adminDevice.registeredAt).format('YYYY-MM-DD')}</div>
        <div
          className={clsx(
            `mt-1 flex items-center justify-start gap-2`,
            isDeviceEnabled ? 'text-gray-500' : 'text-gray-300',
          )}
        >
          {adminDevice.registeredAt && (
            <ClockIcon strokeWidth={1.5} className={clsx('size-3.5')} />
          )}
          {RegExp(timePattern).exec(adminDevice.registeredAt)[0]}
        </div>
      </TableBodyBasic>
      <TableBodyBasic disabled={!isDeviceEnabled}>
        <DeviceStatus
          adminDevice={adminDevice}
          isDeviceEnabled={isDeviceEnabled}
        />
      </TableBodyBasic>
      <TableBodyBasic disabled={!isDeviceEnabled}>
        <Detail
          onClick={() =>
            navigate({
              pathname: 'detail',
              search: `idx=${adminDevice.idx}`,
            })
          }
          disabled={!isDeviceEnabled}
        />
      </TableBodyBasic>
    </tr>
  );
};

export default AdminDeviceTableBody;
