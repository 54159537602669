import React, { useState } from 'react';
import clsx from 'clsx';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { useSlidingTab } from '@hooks/overview/useSlidingTab';
import { TestPlatformType } from '@lib/api/dashboard/overview/overview';

type Props = {
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  tabList: {
    title: TestPlatformType;
    content: {
      id: number;
      post: React.JSX.Element;
    }[];
  }[];
};

const SlidingTab = ({ selectedIndex, setSelectedIndex, tabList }: Props) => {
  const [selectedTab, setSelectedTab] = useState<TestPlatformType>(
    tabList?.[0].title,
  );
  const { tabListRef, tabListWidth, getTranslateX, tabWidths, tabRef } =
    useSlidingTab(selectedIndex, selectedTab, tabList);

  return (
    <TabGroup selectedIndex={selectedIndex} onChange={setSelectedIndex}>
      <div className="relative w-full">
        <TabList
          className="relative flex whitespace-nowrap break-words rounded-md bg-slate-100 px-2 py-1.5"
          style={{ width: `${(tabListWidth + 16) / 16}rem` }}
          ref={tabListRef}
        >
          <div
            className={clsx(
              `absolute top-1 h-4/5 rounded-md bg-white shadow-sm transition-transform duration-300 ease-out`,
              tabWidths[selectedIndex],
            )}
            style={{
              transform: `translateX(${getTranslateX()}px)`,
              width: `${tabWidths?.[selectedIndex] / 16}rem`,
            }}
          />
          {tabList.map((tab, index) => (
            <Tab
              key={tab.title}
              className="relative z-10 rounded-md px-3 py-1.5 text-sm font-medium text-slate-600 data-[selected]:text-gray-900 focus:outline-none"
              ref={(el) => (tabRef.current[index] = el)}
              onClick={() => setSelectedTab(tab.title)}
            >
              {tab.title}
            </Tab>
          ))}
        </TabList>
        <TabPanels className="mt-3 rounded-md border border-gray-100 p-6">
          {tabList.map(({ title, content }) => (
            <TabPanel key={title}>
              <ul>
                {content.map((item) => (
                  <li key={item.id}>{item.post}</li>
                ))}
              </ul>
            </TabPanel>
          ))}
        </TabPanels>
      </div>
    </TabGroup>
  );
};

export default SlidingTab;
