import { RootState } from '@app/store';

import {
  clearPoints,
  deletePoint,
} from '@store/ide/mobileTestStep/unlockPatternSlice';
import { addUuid, clearUuid } from '@store/ide/mobileTestStep/uuidSlice';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const UnlockPattern = () => {
  const dispatch = useDispatch();

  const unlockPatternPoints = useSelector(
    (state: RootState) => state.unlockPattern.points,
  );
  const realScreenRatio = useSelector(
    (state: RootState) => state.screen.realScreenRatio,
  );
  const selectedUuid = useSelector((state: RootState) => state.uuid.uuid);

  const handleDeletePoint = (uuid: string) => {
    dispatch(deletePoint(uuid));
  };

  const handleClearPoints = () => {
    dispatch(clearPoints());
  };

  const onClickUuid = (uuid: string) =>
    selectedUuid === uuid ? dispatch(clearUuid()) : dispatch(addUuid(uuid));

  const {
    setValue,
    resetField,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext<CreateTestStepData>();

  const value = watch('value');
  const isCoordinateCountUnderTwo = value?.split(',')?.length < 4;

  useEffect(() => {
    setValue(
      'value',
      JSON.stringify(
        unlockPatternPoints?.map((point) => ({
          x: Math.round(point.pointsCoordinate.x / realScreenRatio),
          y: Math.round(point.pointsCoordinate.y / realScreenRatio),
        })),
      ),
    );
    return () => {
      resetField('value');
      clearErrors('value');
    };
  }, [setValue, unlockPatternPoints]);

  useEffect(() => {
    return () => {
      dispatch(clearPoints());
      dispatch(clearUuid());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isCoordinateCountUnderTwo) {
      setError('value', {
        type: 'minLength',
        message: '2개 이상의 좌표를 선택해주세요.',
      });
      return () => {
        clearErrors('value');
      };
    }
  }, [value, setError, clearErrors]);

  return (
    <ErrorBoundaryWrapper>
      <div className="flex flex-col items-start justify-start border-b border-gray-200">
        <div className="flex items-center justify-start gap-2 py-2">
          <div className="text-sm font-medium leading-tight text-gray-900">
            Points
          </div>
          <div className="text-xs font-normal leading-none text-gray-500">
            패턴이 그려질 좌표 위치를 설정합니다.(최소 2개)
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <ErrorBoundaryWrapper>
          <ul
            className={clsx(
              'w-full text-sm leading-6',
              unlockPatternPoints?.length > 0 &&
                'border border-solid border-gray-200',
            )}
          >
            {unlockPatternPoints?.length === 0 ? (
              <li className="w-full p-2.5">
                <p className="text-center text-sm font-normal leading-tight text-red-500">
                  스크린샷 화면을 클릭하여 패턴이 그려질 좌표를 설정해주세요.
                </p>
              </li>
            ) : (
              unlockPatternPoints?.map((point, index) => (
                <li
                  className={clsx(
                    'px-3 py-2',
                    selectedUuid === point.uuid
                      ? 'bg-blue-500 text-white'
                      : 'odd:bg-gray-50 even:bg-white',
                  )}
                  key={point.uuid}
                >
                  <div className="flex w-full items-center justify-between">
                    <button
                      type="button"
                      onClick={() => {
                        onClickUuid(point.uuid);
                      }}
                      className="flex w-full items-center justify-between"
                    >
                      <div className="flex items-center justify-between gap-4">
                        <span className="min-w-5">{index + 1}</span>
                        <span>{`[${Math.round(point.pointsCoordinate.x / realScreenRatio)}, ${Math.round(point.pointsCoordinate.y / realScreenRatio)}]`}</span>
                      </div>
                    </button>
                    <button
                      type="button"
                      onClick={() => handleDeletePoint(point.uuid)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className={clsx(
                          'size-6',
                          selectedUuid === point.uuid
                            ? 'text-white'
                            : 'text-red-500',
                        )}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    </button>
                  </div>
                </li>
              ))
            )}
          </ul>
        </ErrorBoundaryWrapper>
        {unlockPatternPoints?.length > 0 && (
          <ErrorBoundaryWrapper>
            <button
              type="button"
              className="w-full rounded-md border border-gray-200 bg-white px-3 py-2 text-sm font-normal leading-tight text-red-500"
              onClick={handleClearPoints}
            >
              모든 Point 삭제하기
            </button>
            <ErrorMessage
              errors={errors}
              name={'value'}
              render={({ message }) => (
                <p className="error-message">{message}</p>
              )}
            />
          </ErrorBoundaryWrapper>
        )}
      </div>
      <div className="flex flex-col items-start justify-start border-b border-gray-200">
        <div className="flex items-center justify-start gap-2 py-2">
          <div className="text-sm font-medium leading-tight text-gray-900">
            Options
          </div>
          <div className="text-xs font-normal leading-none text-gray-500">
            기타 옵션 값을 설정합니다.
          </div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default UnlockPattern;
