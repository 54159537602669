import { axiosRawResponseInstance, axiosInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';
import { useQuery } from '@tanstack/react-query';
import { projectKeys } from '@queries/ide/projectKeys';
import { useLocation, useParams } from 'react-router-dom';
import { AutoRegisterType } from '@customTypes/dashboard/admin/type';

type JiraProjectInfo = {
  id: string;
  key: string;
  name: string;
};

export type JiraProjectIssueTypeInfo = {
  id: string;
  name: string;
  untranslatedName: string;
  subtask: boolean;
};
type Fields = {
  priority: {
    allowedValues: AllowedValues[];
  };
};

type IssueTypes = { name: string; fields: Fields };

export type AllowedValues = { id: string; name: string; iconUrl: string };

export type PriorityProjectsType = {
  key: string;
  issuetypes: IssueTypes[];
};

type JiraProjectPriorityInfo = {
  projects: PriorityProjectsType[];
};

//GET
// 어드민에서 설정한 autoRegister 정보
const getAutoRegistersInfo = async (
  projectIdx: string,
): Promise<AutoRegisterType[]> => {
  const response = await axiosInstance.get<AutoRegisterType[]>(
    `${IDEAPI.PROJECT_DETAIL}${projectIdx}/auto-registers`,
  );
  return response.data;
};

export const useGetAutoRegistersInfoQuery = () => {
  const { projectIdx } = useParams();
  return useQuery<AutoRegisterType[], Error>({
    queryKey: projectKeys.autoRegisters(projectIdx),
    queryFn: () => getAutoRegistersInfo(projectIdx),
  });
};

// 1. Jira에 등록된 프로젝트 리스트
const getJiraProjectList = async (
  projectIdx: string,
): Promise<JiraProjectInfo[]> => {
  const response = await axiosRawResponseInstance.get<JiraProjectInfo[]>(
    `${IDEAPI.GET_JIRA_DATA}projects?projectIdx=${projectIdx}`,
  );

  return response.data;
};

export const useGetJiraProjectListQuery = () => {
  const { projectIdx } = useParams();
  const { pathname } = useLocation();
  return useQuery<JiraProjectInfo[], Error>({
    queryKey: projectKeys.jiraPlatform(projectIdx, pathname),
    queryFn: () => getJiraProjectList(projectIdx),
  });
};

// 2. 해당 프로젝트에 대한 Issue Type 조회
const getJiraIssueType = async (
  projectIdx: string,
  projectKey: string,
): Promise<JiraProjectIssueTypeInfo[]> => {
  const response = await axiosRawResponseInstance.get<
    JiraProjectIssueTypeInfo[]
  >(
    `${IDEAPI.GET_JIRA_DATA}issuetypes?projectIdx=${projectIdx}&projectKey=${projectKey}`,
  );
  return response.data;
};

export const useGetJiraIssueTypeQuery = (key: string) => {
  const { projectIdx } = useParams();
  const { pathname } = useLocation();
  return useQuery<JiraProjectIssueTypeInfo[], Error>({
    queryKey: projectKeys.jiraIssueType(projectIdx, pathname),
    queryFn: () => getJiraIssueType(projectIdx, key),
    enabled: key !== undefined,
  });
};

// 3. 해당 Issue Type 에 대한 Priority
const getJiraPriority = async (
  projectIdx: string,
  projectKey: string,
  issueTypeName: string,
): Promise<JiraProjectPriorityInfo[]> => {
  const response = await axiosRawResponseInstance.get<
    JiraProjectPriorityInfo[]
  >(
    `${IDEAPI.GET_JIRA_DATA}priorities?projectIdx=${projectIdx}&projectKey=${projectKey}&issueTypeName=${issueTypeName}`,
  );
  return response.data;
};

export const useGetJiraPriorityQuery = (
  projectKey: string,
  issueTypeName: string,
) => {
  const { projectIdx } = useParams();
  const { pathname } = useLocation();
  return useQuery<JiraProjectPriorityInfo[], Error>({
    queryKey: projectKeys.jiraPriority(
      projectIdx,
      pathname,
      projectKey,
      issueTypeName,
    ),
    queryFn: () => getJiraPriority(projectIdx, projectKey, issueTypeName),
    enabled: !!projectKey && !!issueTypeName,
  });
};
