import { TableBodyBasic } from '@autosquare/common';
import clsx from 'clsx';
import React from 'react';

interface DeviceInfoCellProps {
  model: string;
  name?: string;
  isActivate: boolean;
}

const DeviceInfoCell = ({ model, name, isActivate }: DeviceInfoCellProps) => {
  return (
    <TableBodyBasic>
      <div>
        <p className={clsx({ 'text-gray-300': !isActivate })}>{model}</p>
        {name && (
          <p
            className={clsx(
              'mt-1 w-full break-words',
              isActivate ? 'text-gray-500' : 'text-gray-300',
            )}
          >
            {name}
          </p>
        )}
      </div>
    </TableBodyBasic>
  );
};

export default DeviceInfoCell;
