import React from 'react';

import AdminUserInformation from './components/AdminUserInformation';

import { ErrorBoundaryWrapper } from '@autosquare/common';

import AdminUserDetailPagingHeading from './components/AdminUserDetailPagingHeading';
import AdminUserInformationHeader from './components/AdminUserInformationHeader';

const AdminUserDetail = () => {
  return (
    <ErrorBoundaryWrapper>
      <AdminUserDetailPagingHeading />
      <AdminUserInformationHeader />
      <AdminUserInformation />
    </ErrorBoundaryWrapper>
  );
};

export default AdminUserDetail;
