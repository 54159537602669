import { ErrorBoundaryWrapper } from '@autosquare/common';
import BrowserListBox from '@components/Scheduler/BrowserListBox';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';
import { BrowserInfo } from '@customTypes/ide/browser/browser';
import { Mode } from '@customTypes/ide/remote/type';
import {
  CreateSchedulerData,
  SchedulerTypeServerName,
} from '@customTypes/ide/scheduler/scheduler';
import { ErrorMessage } from '@hookform/error-message';
import { useRemoteWebBrowserListQuery } from '@lib/api/ide/remote/remote';
import { useWebBrowserListQuery } from '@lib/api/ide/web/webBrowser';
import { getRemoteState } from '@utils/static/getConfiguration';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const CreateSchedulerBrowser = () => {
  const {
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateSchedulerData>();
  const [browserInfoDto, setBrowserInfoDto] = useState<BrowserInfo>(undefined);

  const testType = watch('type');
  const { configuration } = getRemoteState();
  const webBrowserListQuery =
    configuration === Mode.Standard
      ? useWebBrowserListQuery({
          enabled: testType === SchedulerTypeServerName.Web,
        })
      : useRemoteWebBrowserListQuery({
          enabled: testType === SchedulerTypeServerName.Web,
        });

  useEffect(() => {
    if (testType === SchedulerTypeServerName.Mobile) {
      setValue('browserInfoDto', undefined);
      setBrowserInfoDto(undefined);
    }

    return () => {
      setValue('browserInfoDto', undefined);
    };
  }, [testType]);

  // 브라우저 정보 저장
  const handleChange = (selectedBrowser: BrowserInfo) => {
    setBrowserInfoDto(selectedBrowser);
    setValue('browserInfoDto', selectedBrowser);
  };

  // browser error 초기화
  useEffect(() => {
    if (browserInfoDto) {
      clearErrors('browserInfoDto');
    }
  }, [clearErrors, browserInfoDto]);
  return (
    <ErrorBoundaryWrapper>
      {testType === SchedulerTypeServerName.Web && (
        <SchedulerGridContainer
          title="Browser"
          isTitleCenter={testType === SchedulerTypeServerName.Web}
        >
          <BrowserListBox
            browserInfoDto={browserInfoDto}
            handleChange={handleChange}
            webBrowserListQuery={webBrowserListQuery}
          />
          <ErrorMessage
            errors={errors}
            name={'browserInfoDto'}
            render={({ message }) => (
              <p className="standard-error-message">{message}</p>
            )}
          />
        </SchedulerGridContainer>
      )}
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default CreateSchedulerBrowser;
