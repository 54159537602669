import { RootState } from '@app/store';
import {
  decodeJwtToken,
  ErrorBoundaryWrapper,
  TableBodyBasic,
  TableBodyContainer,
  TableHeader,
} from '@autosquare/common';
import { DetailUserGrid } from '@components/index';
import SwitchInController from '@components/shared/HeadlessUI/Switch/SwitchInController';
import { UpdateAdminUserInfo } from '@customTypes/index';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { ErrorMessage } from '@hookform/error-message';
import { useAdminUserDetailQuery } from '@lib/api/dashboard/queryHooks';
import clsx from 'clsx';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

const AdminUserInformationFormContents = () => {
  const { data: adminUserDetail } = useAdminUserDetailQuery();

  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext<UpdateAdminUserInfo>();

  const isUserActivate = watch('status');

  const accessToken = useSelector(
    (state: RootState) => state.accessTokenValue.accessTokenValue,
  );
  const parsingToken = decodeJwtToken(accessToken);

  const projectTableHeaderList = [
    { idx: 1, title: 'No' },
    { idx: 2, title: 'Project Title' },
    { idx: 3, title: 'Role' },
  ];

  return (
    <ErrorBoundaryWrapper>
      <section className="border-b border-solid border-gray-200 pb-4">
        <DetailUserGrid
          title={'Status'}
          content={
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <SwitchInController
                  checked={field.value}
                  onChange={field.onChange}
                  disabled={parsingToken?.sub === adminUserDetail.email}
                />
              )}
            />
          }
        />
        <DetailUserGrid
          title={'Name'}
          content={
            parsingToken?.sub === adminUserDetail.email ? (
              <div className="flex items-center justify-start gap-6">
                <div>{adminUserDetail.name}</div>
                <div className="role-label">System Admin</div>
              </div>
            ) : (
              adminUserDetail.name
            )
          }
        />
        <DetailUserGrid title={'Email'} content={adminUserDetail.email} />
        <DetailUserGrid
          title={'Job Title*'}
          content={
            <ErrorBoundaryWrapper>
              <input
                className="input-base col-span-1 disabled:bg-gray-100"
                {...register('jobTitle')}
                disabled={!isUserActivate}
              />
              <ErrorMessage
                errors={errors}
                name="jobTitle"
                render={({ message }) => (
                  <p className="standard-error-message col-span-2 -mb-3">
                    {message}
                  </p>
                )}
              />
            </ErrorBoundaryWrapper>
          }
        />
        <DetailUserGrid
          title={'Organization'}
          content={
            <ErrorBoundaryWrapper>
              <input
                className="input-base col-span-1 disabled:bg-gray-100"
                {...register('organization')}
                disabled={!isUserActivate}
              />
              <ErrorMessage
                errors={errors}
                name="organization"
                render={({ message }) => (
                  <p className="standard-error-message col-span-2 -mb-3">
                    {message}
                  </p>
                )}
              />
            </ErrorBoundaryWrapper>
          }
        />
        <DetailUserGrid
          title={'Department'}
          content={
            <ErrorBoundaryWrapper>
              <input
                className="input-base col-span-1 disabled:bg-gray-100"
                {...register('department')}
                disabled={!isUserActivate}
              />
              <ErrorMessage
                errors={errors}
                name="department"
                render={({ message }) => (
                  <p className="standard-error-message col-span-2 -mb-3">
                    {message}
                  </p>
                )}
              />
            </ErrorBoundaryWrapper>
          }
        />
        <DetailUserGrid
          title={'Project'}
          content={
            <Disclosure>
              <DisclosureButton
                className="group col-span-2 flex items-center justify-between"
                disabled={adminUserDetail.projectCount === 0}
              >
                <span>{adminUserDetail.projectCount}</span>
                <ChevronDownIcon
                  className={clsx(
                    'size-6 group-data-[open]:rotate-180',
                    adminUserDetail.projectCount === 0 && 'hidden',
                  )}
                />
              </DisclosureButton>
              <DisclosurePanel
                className="gray-scrollbar col-span-2 mt-4 max-h-80 w-full bg-gray-50 px-2.5"
                as="div"
              >
                <table className="w-full">
                  <thead>
                    <tr>
                      {projectTableHeaderList.map((header) => (
                        <TableHeader key={header.idx}>
                          {header.title}
                        </TableHeader>
                      ))}
                    </tr>
                  </thead>
                  <TableBodyContainer>
                    {adminUserDetail.projectList.map((project, idx) => (
                      <tr
                        key={project.projectIdx}
                        className="border-t border-solid border-gray-200"
                      >
                        <TableBodyBasic>{idx + 1}</TableBodyBasic>
                        <TableBodyBasic>{project.projectTitle}</TableBodyBasic>
                        <TableBodyBasic>{project.userRole}</TableBodyBasic>
                      </tr>
                    ))}
                  </TableBodyContainer>
                </table>
              </DisclosurePanel>
            </Disclosure>
          }
        />
        <DetailUserGrid
          title={'Registered'}
          content={adminUserDetail.registered}
        />
        <DetailUserGrid
          title={'Last Seen'}
          content={adminUserDetail.lastSeen}
        />
      </section>
    </ErrorBoundaryWrapper>
  );
};

export default AdminUserInformationFormContents;
