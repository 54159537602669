import {
  Checkbox,
  ErrorBoundaryWrapper,
  ErrorMessage,
  TableHeader,
  TableNoList,
  TableSkeleton,
  TableWrapper,
  useCheckbox,
} from '@autosquare/common';

import {
  useAdminDeviceListQuery,
  useAdminSettingsQuery,
} from '@lib/api/dashboard/queryHooks';

import React, { useEffect } from 'react';

import AdminDeviceTableBody from './DeviceListTable/AdminDeviceTableBody';
import DeleteDeviceDialog from './DeviceListTable/DeleteDeviceDialog';
import { useIndeterminateCheckbox } from '@hooks/index';
const DeviceListTable = () => {
  const adminDeviceListQuery = useAdminDeviceListQuery();
  const adminDeviceList = adminDeviceListQuery.data;

  const { data: deviceSettingsStatus } = useAdminSettingsQuery('device');
  const isDeviceEnabled = deviceSettingsStatus?.device === 'true';

  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(adminDeviceList, 'idx');

  const checkboxRef = useIndeterminateCheckbox(
    selectedArray.length,
    adminDeviceList?.length,
  );

  const tableHeaderList = [
    {
      idx: 1,
      title: (
        <Checkbox
          ref={checkboxRef}
          checked={selectedArray.length > 0}
          onChange={(e) => selectAllArray(e)}
          disabled={
            adminDeviceListQuery.isLoading ||
            adminDeviceListQuery.isFetching ||
            adminDeviceListQuery.isError
          }
        />
      ),
    },
    {
      idx: 2,
      title: selectedArray.length > 0 ? <div className="w-4" /> : 'No',
    },
    {
      idx: 3,
      title: selectedArray.length > 0 ? <div className="w-14" /> : 'Model',
    },
    { idx: 4, title: 'OS' },
    { idx: 5, title: 'Project' },
    { idx: 6, title: 'Registered' },
    { idx: 7, title: '' },
    { idx: 8, title: '' },
  ];

  useEffect(() => {
    if (!isDeviceEnabled) {
      resetSelectedArray();
    }
  }, [isDeviceEnabled]);

  return (
    <ErrorBoundaryWrapper>
      <TableWrapper openDialog={openDialog} selectedArray={selectedArray}>
        <thead>
          <tr>
            {tableHeaderList.map((tableHeader) => (
              <TableHeader key={tableHeader.idx}>
                {tableHeader.title}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {adminDeviceListQuery.isLoading ? (
            <TableNoList colSpan={tableHeaderList.length}>
              <TableSkeleton />
            </TableNoList>
          ) : adminDeviceListQuery.isError ? (
            <TableNoList colSpan={tableHeaderList.length}>
              <ErrorMessage margin="my-0">
                {adminDeviceListQuery.error.message}
              </ErrorMessage>
            </TableNoList>
          ) : adminDeviceList.length === 0 ? (
            <TableNoList colSpan={tableHeaderList.length}>
              등록된 디바이스가 존재하지 않습니다.
            </TableNoList>
          ) : (
            adminDeviceList
              .toSorted((a, b) => b.idx - a.idx)
              .map((adminDevice, idx) => (
                <AdminDeviceTableBody
                  key={adminDevice.idx}
                  adminDevice={adminDevice}
                  isDeviceEnabled={isDeviceEnabled}
                  selectedArray={selectedArray}
                  selectArray={selectArray}
                  idx={idx}
                />
              ))
          )}
        </tbody>
      </TableWrapper>
      <DeleteDeviceDialog
        isOpen={isOpenedCautionDialog}
        setIsOpen={setIsOpenedCautionDialog}
        selectedArray={selectedArray}
        closeDialog={closeDialog}
        resetSelectedArray={resetSelectedArray}
      />
    </ErrorBoundaryWrapper>
  );
};

export default DeviceListTable;
