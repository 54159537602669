import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
  regexLibrary,
} from '@autosquare/common';

import DefinitionList from '@components/shared/DefinitionList';
import DetailDivisionLine from '@components/shared/TestStepDetail/DetailDivisionLine';
import ControllerTextArea from '@components/shared/TextArea/ControllerTextArea';
import DefinitionInputByReactHookForm from '@components/shared/TestStepDetail/DefinitionInputByReactHookForm';

import {
  deviceKeyList,
  deviceKeyListInIos,
  DeviceKeyServerName,
  findListNameFromServerName,
  findListNameFromServerNameInIos,
} from '@utils/static/deviceKeyList';
import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionName,
  mobileCommandOptions,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';

import {
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import TestStepDetailScrollAndSwipe from '@components/shared/TestStepDetail/TestStepDetailScrollAndSwipe';
import { ErrorMessage } from '@hookform/error-message';

type Props = {
  mobileTestStep: MobileTestStepData;
};

const LoopAction = ({ mobileTestStep }: Props) => {
  const {
    watch,
    control,
    setValue,
    register,
    clearErrors,
    unregister,
    formState: { errors },
  } = useFormContext<UpdateTestStepData>();

  const value = watch('value');
  const actionRepeatLimit = watch('optionsDto.actionOptions.repeatLimit');

  const mobileTestStepValue = mobileTestStep.value;
  const actionValue = mobileTestStep.optionsDto.actionValue;

  useEffect(() => {
    return () => {
      unregister('optionsDto.actionValue');
    };
  }, [value, unregister]);

  useEffect(() => {
    if (value === mobileTestStepValue) {
      setValue('optionsDto.actionValue', actionValue);
    } else {
      setValue('optionsDto.actionValue', '');
    }

    clearErrors('optionsDto.actionValue');
  }, [value, setValue]);

  return (
    <ErrorBoundaryWrapper>
      <DetailDivisionLine
        title={'Action'}
        description={'반복 실행 될 동작을 설정합니다.'}
      />
      <DefinitionList
        term={'Command'}
        description={
          <Controller
            name={'value'}
            control={control}
            rules={{ required: 'Action Command를 선택해주세요.' }}
            render={({ field }) => (
              <ListBoxHeadlessInController
                value={field.value}
                buttonValue={findMobileCommandNameFromServerName(
                  field.value as MobileCommandOptionServerName,
                )}
                onChange={field.onChange}
                lists={mobileCommandOptions.filter(
                  (command) =>
                    command.name === MobileCommandOptionName.DeviceKey ||
                    command.name === MobileCommandOptionName.ScrollSwipe ||
                    command.name === MobileCommandOptionName.Click ||
                    command.name === MobileCommandOptionName.Wait,
                )}
                valueToSave={'serverName'}
                valueToShow={'name'}
              />
            )}
          />
        }
      />
      {value === MobileCommandOptionServerName.ScrollSwipe ? (
        <ErrorBoundaryWrapper>
          <TestStepDetailScrollAndSwipe
            mobileTestStep={mobileTestStep}
            keyName={'optionsDto.actionValue'}
          />
          <DefinitionInputByReactHookForm
            term={'Repeat'}
            name={'optionsDto.actionOptions.repeatLimit'}
            type={'number'}
            defaultValue={actionRepeatLimit || 1}
            register={register('optionsDto.actionOptions.repeatLimit', {
              required: '1이상 99이하 숫자를 입력해주세요.',
              min: { value: 1, message: '1이상 99이하 숫자를 입력해주세요.' },
              max: { value: 99, message: '1이상 99이하 숫자를 입력해주세요.' },
            })}
          />
        </ErrorBoundaryWrapper>
      ) : value === MobileCommandOptionServerName.DeviceKey ? (
        <DefinitionList
          term={'Value'}
          description={
            <Controller
              control={control}
              name={'optionsDto.actionValue'}
              rules={{ required: 'Value를 확인해주세요.' }}
              render={({ field }) => (
                <ErrorBoundaryWrapper>
                  <ListBoxHeadlessInController
                    value={field.value}
                    buttonValue={
                      mobileTestStep.mobileOs === 'aos'
                        ? findListNameFromServerName(
                            field.value as DeviceKeyServerName,
                          ) || 'Select Key Event'
                        : findListNameFromServerNameInIos(
                            field.value as DeviceKeyServerName,
                          ) || 'Select Key Event'
                    }
                    onChange={field.onChange}
                    lists={
                      mobileTestStep.mobileOs === 'aos'
                        ? deviceKeyList.slice(1)
                        : deviceKeyListInIos.slice(1)
                    }
                    valueToSave={'serverName'}
                    valueToShow={'listName'}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="optionsDto.actionValue"
                    render={({ message }) => (
                      <p className="standard-error-message">{message}</p>
                    )}
                  />
                </ErrorBoundaryWrapper>
              )}
            />
          }
        />
      ) : value === MobileCommandOptionServerName.Click ? (
        <DefinitionList
          term={'Value'}
          description={
            <Controller
              control={control}
              name={'optionsDto.actionValue'}
              rules={{ required: 'Value를 확인해주세요.' }}
              render={({ field }) => <ControllerTextArea field={field} />}
            />
          }
        />
      ) : (
        value === MobileCommandOptionServerName.Wait && (
          <ErrorBoundaryWrapper>
            <div className="items-center border-b border-gray-200 sm:flex sm:px-6 sm:py-4">
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
                Value(sec)*
              </dt>
              <dd className="mt-1 w-full text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                <div className="w-full">
                  <label htmlFor={'optionsDto.actionValue'} className="sr-only">
                    Value(sec)*
                  </label>
                  <input
                    type={'text'}
                    name={'optionsDto.actionValue'}
                    id={'optionsDto.actionValue'}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    {...register('optionsDto.actionValue', {
                      required: `Value 값을 최소 3초 이상 180초 이하 입력해 주세요.`,
                      min: {
                        value: 3,
                        message: `Value 값을 최소 3초 이상 180초 이하 입력해 주세요.`,
                      },
                      max: {
                        value: 180,
                        message: `Value 값을 최소 3초 이상 180초 이하 입력해 주세요.`,
                      },
                      pattern: {
                        value: regexLibrary.maxThreeDigitsRegex,
                        message: `Value 값을 최소 3초 이상 180초 이하 입력해 주세요.`,
                      },
                    })}
                  />
                </div>
                <div>
                  <ErrorMessage
                    errors={errors}
                    name={'optionsDto.actionValue'}
                    render={({ message }) => (
                      <p className="standard-error-message">{message}</p>
                    )}
                  />
                </div>
              </dd>
            </div>
          </ErrorBoundaryWrapper>
        )
      )}
    </ErrorBoundaryWrapper>
  );
};

export default LoopAction;
