import React, { useEffect } from 'react';
import { ListBoxHeadlessInController } from '@autosquare/common';
import {
  findJiraIssueTypeFromId,
  isRowDisabled,
} from '@utils/static/autoRegister/autoRegisterUtils';
import { JiraMappingType } from '@customTypes/ide/scheduler/scheduler';
import {
  AllowedValues,
  JiraProjectIssueTypeInfo,
  PriorityProjectsType,
  useGetJiraPriorityQuery,
} from '@lib/api/ide/autoRegisters/autoRegisters';
import { ControllerRenderProps } from 'react-hook-form';
import {
  CreateTestScenarioData,
  UpdateTestScenarioData,
} from '@customTypes/testScenario/type';

type Props = {
  jiraMapping: JiraMappingType[];
  field: ControllerRenderProps<
    CreateTestScenarioData | UpdateTestScenarioData,
    `autoRegisters.${number}.jira.mapping.${number}.priority`
  >;
  index: number;
  fieldsIndex: number;
  jiraProjectKey: string;
  jiraIssueType: JiraProjectIssueTypeInfo[];
  setPriorityErrorObj: React.Dispatch<
    React.SetStateAction<{ isError: boolean; message: string }>
  >;
};

const IdeScenarioJiraInputPriority = ({
  jiraMapping,
  field,
  index,
  fieldsIndex,
  jiraProjectKey,
  jiraIssueType,
  setPriorityErrorObj,
}: Props) => {
  const selectedIssueType = jiraMapping?.[index]?.issueType;
  const {
    data: jiraPriority,
    refetch: jiraPriorityRefetch,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetJiraPriorityQuery(
    jiraProjectKey,
    findJiraIssueTypeFromId(selectedIssueType, jiraIssueType),
  );
  const jiraPriorityData = jiraPriority?.['projects'] as PriorityProjectsType[];
  const findPriorityFromId = (id: string) =>
    jiraPriorityList?.find((priority) => priority.id === id)?.name;

  const jiraPriorityList = jiraPriorityData?.flatMap((data) =>
    data.issuetypes?.flatMap(
      (type) => type.fields?.priority?.allowedValues || [],
    ),
  ) as AllowedValues[];

  useEffect(() => {
    if (isError) {
      setPriorityErrorObj({
        isError: isError,
        message: error?.message,
      });
    }
  }, [isError, error, setPriorityErrorObj]);

  return (
    <ListBoxHeadlessInController
      value={field.value}
      buttonValue={field.value === '' ? null : findPriorityFromId(field.value)}
      lists={jiraPriorityList}
      valueToSave="id"
      valueToShow="name"
      onChange={(value) => {
        if (value !== selectedIssueType) {
          jiraPriorityRefetch();
        }
        field.onChange(value);
      }}
      placeholder="Select"
      noListMessage="선택 항목 없음"
      location="jira"
      disabled={
        jiraIssueType?.length === 0 ||
        !jiraProjectKey ||
        !selectedIssueType ||
        !isRowDisabled(index, fieldsIndex) ||
        isLoading ||
        isFetching
      }
    />
  );
};

export default IdeScenarioJiraInputPriority;
