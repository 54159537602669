import {
  ErrorBoundaryWrapper,
  ErrorMessage,
  ImportTestStepTableSkeleton,
} from '@autosquare/common';
import ImportTestStepTable from '@components/TestStep/ImportTestStepTable';
import TestStepDetailBasicDefinition from '@components/shared/TestStepDetail/TestStepDetailBasicDefinition';
import TestStepListValue from '@components/TestStepCommon/TestStepListValue';

import {
  CompareBy,
  MaskingOptionsType,
  MobileTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { SaveTextFrom } from '@customTypes/ide/mobileTestStep/SaveTextFrom';
import { useMobileTestStepByCaseQuery } from '@lib/api/ide/mobile/mobileTestStep';
import { useGetSpeedMobileTestStep } from '@lib/api/ide/speedMobile/speedMobileTestStep';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { ClickId } from '@utils/static/clickOptions';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import React from 'react';
import BasicDataValidation from './TestStepDetailContent/BasicDataValidation';
import BasicIfCommand from './TestStepDetailContent/BasicIfCommand';
import BasicLoop from './TestStepDetailContent/BasicLoop';
import BasicTextReplace from './TestStepDetailContent/BasicTextReplace';
import DefaultDefinition from './TestStepDetailContent/DefaultDefinition';
import SaveTextList from './TestStepDetailContent/SaveTextList';

type Props = {
  mobileTestStepData: MobileTestStepData;
  testStepDetailListValue: string | React.ReactElement;
};

const TestStepDetailContent = ({
  mobileTestStepData,
  testStepDetailListValue,
}: Props) => {
  const selectedCaseIdx = mobileTestStepData?.value;

  const isUiLocation = isWordInLocationPathname('ui-test');
  const isSpeedLocation = isWordInLocationPathname('speed-test');

  const stepListQuery = isUiLocation
    ? useMobileTestStepByCaseQuery(selectedCaseIdx, {
        enabled:
          isUiLocation &&
          mobileTestStepData?.command ===
            MobileCommandOptionServerName.ImportTestStep,
      })
    : useGetSpeedMobileTestStep(selectedCaseIdx, {
        enabled:
          isSpeedLocation &&
          mobileTestStepData?.command ===
            MobileCommandOptionServerName.ImportTestStep,
      });

  const stepList = stepListQuery.data;

  const selectedImportTestStep =
    mobileTestStepData.optionsDto.stepIdxList?.split(',');

  const selectedImportTestStepList = selectedImportTestStep?.flatMap(
    (selected) =>
      stepList?.[mobileTestStepData?.mobileOs]?.filter(
        (step) => step.idx === Number(selected),
      ),
  );

  const isSecretValue = mobileTestStepData.optionsDto.secret === 'true';

  const testStepHeader = ['#', 'Command', 'Description'];

  return (
    <ErrorBoundaryWrapper>
      <DefaultDefinition mobileTestStepData={mobileTestStepData} />
      {mobileTestStepData?.optionsDto.clickBy === ClickId.Image ||
      mobileTestStepData?.command ===
        MobileCommandOptionServerName.CompareImage ? (
        <TestStepDetailBasicDefinition
          term={'Value'}
          description={
            <TestStepListValue imageValue={mobileTestStepData?.value} />
          }
        />
      ) : mobileTestStepData?.command === MobileCommandOptionServerName.Wait ? (
        <TestStepDetailBasicDefinition
          term={'Value(sec)'}
          description={testStepDetailListValue}
        />
      ) : (
        mobileTestStepData?.command !== MobileCommandOptionServerName.Loop &&
        mobileTestStepData?.command !== MobileCommandOptionServerName.IF &&
        mobileTestStepData?.command !==
          MobileCommandOptionServerName.UnlockPattern &&
        !(
          mobileTestStepData?.command ===
            MobileCommandOptionServerName.SaveText &&
          mobileTestStepData.optionsDto?.from === SaveTextFrom.Sms
        ) && (
          <TestStepDetailBasicDefinition
            term={'Value'}
            description={
              mobileTestStepData?.command ===
              MobileCommandOptionServerName.ImportTestStep ? (
                <ErrorBoundaryWrapper>
                  {stepListQuery.isLoading || stepListQuery.isFetching ? (
                    <ErrorBoundaryWrapper>
                      <div className="mb-6 h-6 w-64 animate-pulse rounded-md bg-gray-300"></div>
                      <ImportTestStepTableSkeleton
                        hasCheckBox={false}
                        hasBorder={true}
                      />
                    </ErrorBoundaryWrapper>
                  ) : stepListQuery.isError ? (
                    <ErrorMessage>{stepListQuery.error.message}</ErrorMessage>
                  ) : (
                    <ErrorBoundaryWrapper>
                      <div className="mb-6">{testStepDetailListValue}</div>
                      <ImportTestStepTable
                        headers={testStepHeader}
                        rows={selectedImportTestStepList}
                        style="border border-gray-200 rounded-lg"
                        columnWidths={['w-14', 'w-3/6', 'w-3/6']}
                        hasCheckBox={false}
                      />
                    </ErrorBoundaryWrapper>
                  )}
                </ErrorBoundaryWrapper>
              ) : (
                testStepDetailListValue || (isSecretValue && '******')
              )
            }
          />
        )
      )}
      {mobileTestStepData?.command ===
      MobileCommandOptionServerName.ConfirmText ? (
        <TestStepDetailBasicDefinition
          term={'Secret'}
          description={
            mobileTestStepData?.optionsDto.secret === 'true' ? 'Y' : 'N'
          }
        />
      ) : (
        mobileTestStepData?.command ===
          MobileCommandOptionServerName.InputText &&
        mobileTestStepData.optionsDto.compareBy !== CompareBy.Automation && (
          <TestStepDetailBasicDefinition
            term={'Secret'}
            description={
              mobileTestStepData?.optionsDto.textEncrypt === 'true' ? 'Y' : 'N'
            }
          />
        )
      )}
      {mobileTestStepData?.command ===
      MobileCommandOptionServerName.ScrollSwipe ? (
        <TestStepDetailBasicDefinition
          term={'Repeat'}
          description={mobileTestStepData?.optionsDto.repeatLimit}
        />
      ) : mobileTestStepData?.command ===
        MobileCommandOptionServerName.UnlockPattern ? (
        <TestStepDetailBasicDefinition
          term={'Points'}
          description={JSON.parse(mobileTestStepData?.value)
            .map(
              (value: { x: number; y: number }) => `[${value.x}, ${value.y}]`,
            )
            .join(', ')}
        />
      ) : mobileTestStepData?.command ===
        MobileCommandOptionServerName.DataValidation ? (
        <BasicDataValidation mobileTestStepData={mobileTestStepData} />
      ) : mobileTestStepData?.command === MobileCommandOptionServerName.Click &&
        mobileTestStepData.optionsDto.clickBy === ClickId.Default ? (
        <>
          <TestStepDetailBasicDefinition
            term={'Secret'}
            description={
              mobileTestStepData?.optionsDto.secret === 'true' ? 'Y' : 'N'
            }
          />
          <BasicTextReplace mobileTestStepData={mobileTestStepData} />
        </>
      ) : mobileTestStepData?.command ===
          MobileCommandOptionServerName.ConfirmText &&
        mobileTestStepData.optionsDto.textReplace === 'true' ? (
        <BasicTextReplace mobileTestStepData={mobileTestStepData} />
      ) : (
        mobileTestStepData?.command ===
          MobileCommandOptionServerName.SaveText && (
          <SaveTextList mobileTestStepData={mobileTestStepData} />
        )
      )}
      {mobileTestStepData?.command !== MobileCommandOptionServerName.Loop &&
        mobileTestStepData?.command !== MobileCommandOptionServerName.IF &&
        mobileTestStepData?.command !== MobileCommandOptionServerName.Wait && (
          <>
            <TestStepDetailBasicDefinition
              term={'Masking Options'}
              description={
                mobileTestStepData.optionsDto.maskingOptions?.enabled === 'true'
                  ? `Y / ${mobileTestStepData.optionsDto.maskingOptions?.type === MaskingOptionsType.Element ? '직접 선택' : mobileTestStepData.optionsDto.maskingOptions?.type === MaskingOptionsType.Coordinate ? '영역 선택' : '전체 화면'}`
                  : 'N'
              }
            />
            <TestStepDetailBasicDefinition
              term={'Required'}
              description={mobileTestStepData?.required ? 'Y' : 'N'}
            />
          </>
        )}
      {mobileTestStepData?.command === MobileCommandOptionServerName.Loop ? (
        <BasicLoop mobileTestStepData={mobileTestStepData} />
      ) : mobileTestStepData?.command === MobileCommandOptionServerName.IF ? (
        <BasicIfCommand mobileTestStepData={mobileTestStepData} />
      ) : (
        mobileTestStepData?.command === MobileCommandOptionServerName.Wait && (
          <TestStepDetailBasicDefinition
            term={'Masking Options'}
            description={
              mobileTestStepData.optionsDto.maskingOptions?.enabled === 'true'
                ? `Y / ${mobileTestStepData.optionsDto.maskingOptions?.type === MaskingOptionsType.Element ? '직접 선택' : mobileTestStepData.optionsDto.maskingOptions?.type === MaskingOptionsType.Coordinate ? '영역 선택' : '전체 화면'}`
                : 'N'
            }
          />
        )
      )}
      {(mobileTestStepData?.command !== MobileCommandOptionServerName.Loop ||
        mobileTestStepData?.value !== MobileCommandOptionServerName.Wait) &&
        mobileTestStepData?.command !== MobileCommandOptionServerName.Wait && (
          <TestStepDetailBasicDefinition
            term={'WaitTime'}
            description={mobileTestStepData?.optionsDto.waitingTime}
          />
        )}
    </ErrorBoundaryWrapper>
  );
};

export default TestStepDetailContent;
