import React, { useEffect } from 'react';

import { useWebTestStepByCommandQuery } from '@lib/api/ide/web/webTestStep';

import { WebCommandOptionServerName } from '@utils/static/webCommandOptions';

import NoSaveTestStep from '@components/TestStep/NoSaveTestStep';

import {
  CompareBy,
  CompareRange,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { useFormContext } from 'react-hook-form';
import { CreateWebTestStepData } from '@customTypes/testStep/type';
import WebSecretBox from '@components/TestStep/WebSecretBox';
import SaveTestStep from './ConfirmAndInputText/SaveTestStep';
import CompareTextInput from './ConfirmAndInputText/CompareTextInput';
import WebTestStepConfirmInputRadio from '@components/IDE/WebTestStepDetail/WebTestStepConfirmInputRadio';
import WebAutomationOrderContents from './ConfirmAndInputText/WebAutomationOrderContents';
import { useGetWebTestCaseDataAutomation } from '@lib/api/ide/web/webTestAutomation';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const ConfirmAndInputText = () => {
  const { setValue, resetField, watch, control } =
    useFormContext<CreateWebTestStepData>();

  const webTestStepByCommandQuery = useWebTestStepByCommandQuery();
  const saveTextLists = webTestStepByCommandQuery.data;
  const { data: automationData } = useGetWebTestCaseDataAutomation();
  const targetData = automationData?.targetData;

  const command = watch('command');
  const compareBy = watch('optionsDto.compareBy');

  useEffect(() => {
    if (
      command === WebCommandOptionServerName.ConfirmText ||
      (command === WebCommandOptionServerName.InputText &&
        compareBy !== CompareBy.Automation)
    ) {
      setValue('optionsDto.compareBy', CompareBy.Id);
    }
  }, [command]);

  useEffect(() => {
    if (command === WebCommandOptionServerName.ConfirmText) {
      setValue('optionsDto.compareRange', CompareRange.Equal);
      setValue('optionsDto.secret', 'false');
    }

    if (command === WebCommandOptionServerName.InputText) {
      resetField('optionsDto.compareRange');
    }
  }, [resetField, command, setValue]);

  useEffect(() => {
    return () => {
      resetField('optionsDto.compareBy');
      resetField('optionsDto.compareText');
      resetField('optionsDto.compareIdx');
      resetField('optionsDto.compareRange');
      resetField('optionsDto.autoColumn');
      setValue('optionsDto.secret', undefined);
      setValue('optionsDto.textEncrypt', undefined);
      setValue('optionsDto.targetData', undefined);
    };
  }, [resetField, setValue]);

  useEffect(() => {
    setValue('optionsDto.compareText', undefined);
    if (
      command === WebCommandOptionServerName.ConfirmText &&
      compareBy === CompareBy.Text
    ) {
      setValue('optionsDto.textEncrypt', undefined);
    }
    if (
      command === WebCommandOptionServerName.InputText &&
      compareBy === CompareBy.Text
    ) {
      setValue('optionsDto.textEncrypt', 'false');
      setValue('optionsDto.secret', undefined);
    }
  }, [command, setValue, resetField, compareBy]);

  const radioOptions = [
    {
      id: CompareBy.Id,
      title: '스텝 선택',
      onClick: () => {
        resetField('optionsDto.compareText');
        setValue('optionsDto.autoColumn', undefined);
        setValue('optionsDto.targetData', undefined);
      },
      defaultChecked: true,
    },
    {
      id: CompareBy.Text,
      title: '직접 입력',
      onClick: () => {
        resetField('optionsDto.compareIdx');
        setValue('optionsDto.autoColumn', undefined);
        setValue('optionsDto.targetData', undefined);
      },
      defaultChecked: false,
    },
  ];

  const radioWithAutomation = {
    id: CompareBy.Automation,
    title: 'Automation',
    onClick: () => {
      resetField('optionsDto.compareText');
      resetField('optionsDto.compareIdx');
      setValue('optionsDto.compareBy', CompareBy.Automation);
      setValue('optionsDto.textEncrypt', undefined);
      setValue('optionsDto.secret', undefined);
      setValue('optionsDto.targetData', targetData);
    },
    defaultChecked: false,
  };

  const filteredRadioOptions =
    command === WebCommandOptionServerName.InputText
      ? [...radioOptions, radioWithAutomation]
      : radioOptions;

  const equalAndContainRadioOptions = [
    {
      title: '동일',
      id: CompareRange.Equal,
      defaultChecked: true,
    },
    {
      title: '포함',
      id: CompareRange.Contain,
      defaultChecked: false,
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      {command === WebCommandOptionServerName.ConfirmText && (
        <WebSecretBox control={control} />
      )}
      <WebTestStepConfirmInputRadio
        name={'optionsDto.compareBy'}
        radioOptions={filteredRadioOptions}
      />
      {command === WebCommandOptionServerName.InputText &&
        compareBy === CompareBy.Text && (
          <WebSecretBox control={control} name={'optionsDto.textEncrypt'} />
        )}
      {compareBy === CompareBy.Id && saveTextLists?.length === 0 ? (
        <NoSaveTestStep />
      ) : compareBy === CompareBy.Id && saveTextLists?.length !== 0 ? (
        <SaveTestStep />
      ) : (
        compareBy === CompareBy.Text && <CompareTextInput />
      )}
      {command === WebCommandOptionServerName.ConfirmText && (
        <WebTestStepConfirmInputRadio
          name={'optionsDto.compareRange'}
          radioOptions={equalAndContainRadioOptions}
        />
      )}
      {compareBy === CompareBy.Automation && <WebAutomationOrderContents />}
    </ErrorBoundaryWrapper>
  );
};

export default ConfirmAndInputText;
