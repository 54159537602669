import { ErrorBoundaryWrapper } from '@autosquare/common';
import React, { Fragment, ReactElement } from 'react';

interface Props {
  term: string;
  description: string | ReactElement;
}

const TestStepDetailBasicDefinition = ({ term, description }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div className="border-b border-gray-200 last:border-b-0 sm:flex sm:px-6 sm:py-5">
        <dt className="flex items-start text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
          {term}
        </dt>
        <dd className="mt-1 break-all text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0 ">
          {typeof description === 'string'
            ? description?.split('\n').map((line, index, array) =>
                index === array.length - 1 ? (
                  line
                ) : (
                  <Fragment key={index}>
                    {line}
                    <br />
                  </Fragment>
                ),
              )
            : description}
        </dd>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default TestStepDetailBasicDefinition;
