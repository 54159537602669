import { Button, DoubleCheckDialogHeadlessUI } from '@autosquare/common';
import React, { ReactNode } from 'react';
import OptionsDtoTableHeader from './OptionsDtoTableHeader';

type Props = {
  removeOptionsDto: () => void;
  isOpenedCautionDialog: boolean;
  setIsOpenedCautionDialog: React.Dispatch<React.SetStateAction<boolean>>;
  openDialog: () => void;
  closeDialog: () => void;
  selectedArray: number[];
  selectAllArray: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children: ReactNode;
};

const VariableList = ({
  removeOptionsDto,
  isOpenedCautionDialog,
  setIsOpenedCautionDialog,
  openDialog,
  closeDialog,
  selectedArray,
  selectAllArray,
  children,
}: Props) => {
  const tableHeaders = OptionsDtoTableHeader({
    selectedArray,
    selectAllArray,
    openDialog,
  });

  return (
    <div className="border-t border-solid border-gray-200 text-sm font-normal leading-normal text-gray-900">
      <div
        className={
          '-mx-4 inline-block min-w-full overflow-x-auto align-middle scrollbar-thin sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'
        }
      >
        <table className="h-full min-w-full">
          <thead>
            <tr>
              {tableHeaders.map((tableHeader) => (
                <td
                  key={tableHeader.idx}
                  className="h-10 whitespace-nowrap px-3 text-left text-sm font-medium"
                >
                  {tableHeader.header}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
      </div>
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedCautionDialog}
        setIsOpened={setIsOpenedCautionDialog}
        type={'caution'}
        title={'Delete Test Step Option'}
        subTitle={'해당 테스트 스텝의 옵션을 삭제하시겠습니까?'}
        buttonChildren={
          <>
            <Button type="button" variant="caution" onClick={removeOptionsDto}>
              Delete
            </Button>
            <Button type="button" variant="secondary" onClick={closeDialog}>
              Cancel
            </Button>
          </>
        }
      />
    </div>
  );
};

export default VariableList;
