import {
  ArrowSpinner,
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
  ErrorMessage,
  NoResults,
  SpeedTestResultDetailSkeleton,
} from '@autosquare/common';
import RangePickEndCalendar from '@components/MobileSpeedTestResultDetail/RangePickCalendar/RangePickEndCalendar';
import RangePickStartCalendar from '@components/MobileSpeedTestResultDetail/RangePickCalendar/RangePickStartCalendar';
import WebSpeedTestResultHeader from '@components/SpeedTest/WebSpeedTestResultDetail/WebSpeedTestResultHeader';
import InformationTable from '@components/WebSpeedTestResultDetail/InformationTable/InformationTable';
import SpeedTestResultGraph from '@components/WebSpeedTestResultDetail/SpeedTestResultGraph/SpeedTestResultGraph';
import { useExportChart } from '@hooks/index';
import {
  useWebSpeedTestResultDetailQuery,
  useWebSpeedTestResultExportMutation,
} from '@lib/api/dashboard/web/webSpeedResult';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const WebSpeedTestResultDetail = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const webSpeedTestResultDetailQuery = useWebSpeedTestResultDetailQuery();
  const webSpeedTestResultDetailData = webSpeedTestResultDetailQuery.data;

  const webSpeedTestResultExportMutation =
    useWebSpeedTestResultExportMutation();

  const start = searchParams.get('start');
  const defaultStartDate = dayjs(start).toDate();

  const end = searchParams.get('end');
  const defaultEndDate = dayjs(end).toDate();

  const resultIdx = searchParams.get('result');
  const groupIdx = searchParams.get('group');

  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const resultStatus = searchParams.get('status');
  const isResultNA = resultStatus === 'NA';

  const NoData = webSpeedTestResultDetailData?.date.length === 0;

  useEffect(() => {
    setStartDate(new Date(dayjs(start).format('YYYY-MM-DD')));
    webSpeedTestResultDetailQuery.refetch();
  }, [start]);

  useEffect(() => {
    setEndDate(new Date(dayjs(end).format('YYYY-MM-DD')));
    webSpeedTestResultDetailQuery.refetch();
  }, [end]);

  useEffect(() => {
    return () => localStorage.removeItem('entryPoint');
  }, []);

  const {
    isModalOpen,
    setIsModalOpen,
    chartRef,
    exportChartToPng,
    errorMessage,
  } = useExportChart(webSpeedTestResultExportMutation);

  return (
    <ErrorBoundaryWrapper>
      <WebSpeedTestResultHeader exportChartToPng={exportChartToPng} />
      {webSpeedTestResultDetailQuery.isLoading ? (
        <SpeedTestResultDetailSkeleton />
      ) : webSpeedTestResultDetailQuery.isError ? (
        <ErrorMessage>
          {webSpeedTestResultDetailQuery.error.message}
        </ErrorMessage>
      ) : (
        <div className="mt-14 px-8">
          <div className="flex items-center justify-between">
            <h2 className="font-semibold">
              {webSpeedTestResultDetailData?.menu} Loading Time 비교측정 결과
            </h2>
            {!isResultNA && (
              <div className="flex items-center justify-center gap-x-3">
                <RangePickStartCalendar
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  resultIdx={resultIdx}
                  groupIdx={groupIdx}
                  minDate={webSpeedTestResultDetailData.date[0]}
                  isWeb={true}
                />
                <span>&#45;</span>
                <RangePickEndCalendar
                  endDate={endDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  resultIdx={resultIdx}
                  groupIdx={groupIdx}
                  isWeb={true}
                />
              </div>
            )}
          </div>
          {NoData ? (
            <NoResults />
          ) : (
            <ErrorBoundaryWrapper>
              <InformationTable data={webSpeedTestResultDetailData} />
              {webSpeedTestResultDetailQuery.isRefetching ? (
                <SpeedTestResultDetailSkeleton
                  isRefetching={webSpeedTestResultDetailQuery.isRefetching}
                />
              ) : (
                <SpeedTestResultGraph
                  data={webSpeedTestResultDetailData}
                  chartRef={chartRef}
                />
              )}
            </ErrorBoundaryWrapper>
          )}
        </div>
      )}
      <DoubleCheckDialogHeadlessUI
        isOpened={isModalOpen}
        setIsOpened={setIsModalOpen}
        type={'caution'}
        title={'Export Error'}
        subTitle={errorMessage}
        buttonChildren={
          <Button type="button" onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
        }
      />
      {webSpeedTestResultExportMutation.isLoading && <ArrowSpinner />}
    </ErrorBoundaryWrapper>
  );
};

export default WebSpeedTestResultDetail;
