import {
  OverviewResults,
  MobileOverview,
  WebOverview,
  OverviewResultRateReport,
} from '@customTypes/ide/overview/type';

import { axiosInstance } from '../index';
import { IDEAPI } from '@api/ideEndpoints';
import { useQuery } from '@tanstack/react-query';
import { overviewKeys } from '@queries/dashboard/overviewKeys';
import { useLocation, useParams } from 'react-router-dom';

// GET
const getOverview = async (projectIdx: string): Promise<OverviewResults> => {
  const response = await axiosInstance.get(`${IDEAPI.OVERVIEW}${projectIdx}`);
  return response.data;
};
export const useOverviewQuery = (projectIdx: string) => {
  return useQuery<OverviewResults, Error, OverviewResults>({
    queryKey: ['overview', projectIdx],
    queryFn: () => getOverview(projectIdx),
  });
};

const getMobileOverview = async (
  projectIdx: string,
): Promise<MobileOverview> => {
  const response = await axiosInstance.get(
    `${IDEAPI.MOBILE_OVERVIEW}${projectIdx}`,
  );
  return response.data;
};

export const useMobileOverviewQuery = (projectIdx: string) =>
  useQuery<MobileOverview, Error, MobileOverview>({
    queryKey: overviewKeys.mobile(projectIdx),
    queryFn: () => getMobileOverview(projectIdx),
  });

const getWebOverview = async (projectIdx: string): Promise<WebOverview> => {
  const response = await axiosInstance.get(
    `${IDEAPI.WEB_OVERVIEW}${projectIdx}`,
  );
  return response.data;
};

export const useWebOverviewQuery = (projectIdx: string) =>
  useQuery<WebOverview, Error, WebOverview>({
    queryKey: overviewKeys.web(projectIdx),
    queryFn: () => getWebOverview(projectIdx),
  });

const getResultRate = async (
  projectIdx: string,
  pathname: string,
): Promise<OverviewResultRateReport> => {
  const response = await axiosInstance.get(
    `${IDEAPI.getResultReportOverview(pathname)}${projectIdx}`,
  );
  return response.data;
};

export const useGetResultRateQuery = () => {
  const { projectIdx } = useParams();
  const { pathname } = useLocation();
  const location = pathname.includes('ui-test') ? 'ui-test' : 'speed-test';
  return useQuery<OverviewResultRateReport, Error, OverviewResultRateReport>({
    queryKey: overviewKeys.report(projectIdx, location),
    queryFn: () => getResultRate(projectIdx, pathname),
  });
};
