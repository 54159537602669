import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
  SectionTitle,
  SectionTitleMain,
} from '@autosquare/common';
import { ImportCsvTestCaseRequest } from '@customTypes/index';
import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type TableBodyList = {
  id: keyof ImportCsvTestCaseRequest;
  testCaseField: string;
  csvField: React.ReactNode;
};

type Props = {
  csvHeaderListWithNo: { idx: number; header: string }[];
};

const DataMapping = ({ csvHeaderListWithNo }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<ImportCsvTestCaseRequest>();

  const tableHeaderList = [
    { id: 'number', label: 'No' },
    { id: 'test-case-field', label: 'Test Case Field' },
    { id: 'csv-field', label: 'CSV Field' },
  ];

  const tableBodyList: TableBodyList[] = [
    {
      id: 'tcId',
      testCaseField: 'TC-ID*',
      csvField: (
        <Controller
          control={control}
          name="tcId"
          render={({ field }) => (
            <ListBoxHeadlessInController
              value={field.value}
              buttonValue={field.value}
              onChange={field.onChange}
              lists={csvHeaderListWithNo}
              valueToSave={'header'}
              valueToShow={'header'}
              placeholder="Select field"
              isGrayBackground
            />
          )}
        />
      ),
    },
    {
      id: 'title',
      testCaseField: 'Title*',
      csvField: (
        <Controller
          control={control}
          name="title"
          render={({ field }) => (
            <ListBoxHeadlessInController
              lists={csvHeaderListWithNo}
              value={field.value}
              buttonValue={field.value}
              onChange={field.onChange}
              valueToSave={'header'}
              valueToShow={'header'}
              placeholder="Select field"
              isGrayBackground
            />
          )}
        />
      ),
    },
    {
      id: 'description',
      testCaseField: 'Description',
      csvField: (
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <ListBoxHeadlessInController
              lists={csvHeaderListWithNo}
              value={field.value}
              buttonValue={field.value}
              onChange={field.onChange}
              valueToSave={'header'}
              valueToShow={'header'}
              placeholder="Select field"
              isGrayBackground
            />
          )}
        />
      ),
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="mt-4 h-full">
        <SectionTitle>
          <SectionTitleMain>Data Mapping</SectionTitleMain>
        </SectionTitle>
        <div
          className="mt-4 grid w-full grid-cols-11 text-sm font-semibold leading-tight text-gray-900"
          role="table"
        >
          {tableHeaderList.map((header) => (
            <div
              key={header.id}
              role="column-header"
              className="col-span-5 border-b border-gray-300 py-3.5 text-start first:col-span-1"
            >
              {header.label}
            </div>
          ))}
          {tableBodyList.map((body, index) => (
            <div
              key={body.testCaseField}
              className="col-span-11 grid grid-cols-11 items-center border-b border-gray-300 font-medium"
              role="row"
            >
              <div className="col-span-1 py-4" role="cell">
                {index + 1}
              </div>
              <div className="col-span-5 py-4" role="cell">
                {body.testCaseField}
              </div>
              <div className="col-span-5 py-4" role="cell">
                {body.csvField}
                <ErrorMessage
                  errors={errors}
                  name={body.id}
                  render={({ message }) => (
                    <p className="standard-error-message">{message}</p>
                  )}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default DataMapping;
