import {
  DefaultSkeleton,
  ErrorBoundaryWrapper,
  Pagination,
  PagingHeadingsInAutosquare,
  ResultsToolbar,
} from '@autosquare/common';

import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useMobileTestResultSearchListQuery } from '@lib/api/ide/mobile/mobileTestResult';

import TestResultTable from './components/TestResultTable';
import SearchForm from '@components/SearchForm/SearchForm';
import getFirstGenerationDateToDateType from '@utils/static/getFirstGenerationDateToDateType';

const IdeMobileTestResult = () => {
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));
  const size = Number(searchParams.get('size'));

  const mobileTestResultSearchListQuery = useMobileTestResultSearchListQuery();
  const mobileTestResultSearchList = mobileTestResultSearchListQuery.data;

  const initialCreatedAt = mobileTestResultSearchList?.initialCreatedAt;
  const formattedCreatedAt = getFirstGenerationDateToDateType(initialCreatedAt);

  const breadcrumbs = [
    {
      title: 'UI Test',
      href: `${pathname.split('/').slice(0, -2).join('/')}/overview`,
    },
    { title: 'Mobile', href: pathname + search },
    { title: 'Test Result', href: pathname + search },
    { title: 'List', href: pathname + search },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Result List'}
      />
      <SearchForm initialCreatedAt={formattedCreatedAt}>
        <SearchForm.ResultTestType />
        <SearchForm.MobileDeviceOs />
        <SearchForm.ExecutionResult />
      </SearchForm>
      <ResultsToolbar
        totalResults={mobileTestResultSearchList?.totalElements}
      />
      <TestResultTable />
      {mobileTestResultSearchListQuery.isLoading ? (
        <DefaultSkeleton number={1} height={'h-4'} outMargin={'my-4'} />
      ) : (
        mobileTestResultSearchList?.content.length !== 0 && (
          <Pagination
            page={page}
            total={mobileTestResultSearchList?.totalElements}
            size={size}
            isDashboard={false}
          />
        )
      )}
    </ErrorBoundaryWrapper>
  );
};

export default IdeMobileTestResult;
