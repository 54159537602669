import {
  ArrowSpinner,
  Checkbox,
  Detail,
  ErrorBoundaryWrapper,
  Execution,
  TableBodyBasic,
  TableHeader,
  TableWrapper,
  Time,
  useCheckbox,
} from '@autosquare/common';
import NoListResultMessage from '@components/Search/NoListResultMessage';
import TestExecutionContainer from '@components/TestExecution/TestExecutionContainer';
import DeleteDialogInSpeedTestScenarioList from '@components/SpeedTestScenario/DeleteDialogInSpeedTestScenarioList';
import ErrorMessageInTableBody from '@components/SpeedTestScenario/ErrorMessageInTableBody';
import TableSkeleton from '@components/Skeleton/TableSkeleton';
import {
  useDeleteSpeedWebTestScenarioMutation,
  useSearchWebSpeedTestScenarioListQuery,
} from '@lib/api/ide/speedWeb/webSpeedTestScenario';
import { timePattern } from '@utils/static/timePattern';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRemoteWebTestExecutionMutation } from '@lib/api/ide/remote/remote';
import RemoteTestExecutionAlarmModal from '@components/TestExecution/shared/RemoteTestExecutionAlarmModal';

const WebSpeedTestScenarioTable = () => {
  const navigate = useNavigate();

  const [isTestExecutionOpened, setIsTestExecutionOpened] = useState(false);
  const [testScenarioIdx, setTestScenarioIdx] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExecutionErrorModalOpen, setIsExecutionErrorModalOpen] =
    useState(false);

  const searchWebSpeedTestScenarioListQuery =
    useSearchWebSpeedTestScenarioListQuery();
  const searchWebSpeedTestScenarioList =
    searchWebSpeedTestScenarioListQuery.data;

  const deleteSpeedWebTestScenarioMutation =
    useDeleteSpeedWebTestScenarioMutation();

  const remoteWebTestScenarioExecutionMutation =
    useRemoteWebTestExecutionMutation('scenario');
  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(searchWebSpeedTestScenarioList, 'idx');

  const headerList = [
    {
      idx: 1,
      header: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={(e) => selectAllArray(e)}
          key="selected-checkbox"
        />
      ),
    },
    {
      idx: 2,
      header: selectedArray.length > 0 ? <div className="size-5" /> : 'No',
    },
    {
      idx: 3,
      header: selectedArray.length > 0 ? <div className="size-5" /> : 'Title',
    },
    { idx: 4, header: 'Test Case Count' },
    { idx: 5, header: 'Creator' },
    { idx: 6, header: 'Created at', isSortable: true },
    { idx: 7, header: '' },
    { idx: 8, header: '' },
  ];

  return (
    <ErrorBoundaryWrapper>
      <TableWrapper selectedArray={selectedArray} openDialog={openDialog}>
        <thead>
          <tr>
            {headerList.map((header) => (
              <TableHeader key={header.idx} isSortable={header.isSortable}>
                {header.header}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {searchWebSpeedTestScenarioListQuery.isLoading ||
          searchWebSpeedTestScenarioListQuery.isFetching ? (
            <TableSkeleton dataLength={headerList.length} />
          ) : searchWebSpeedTestScenarioListQuery.isError ? (
            <ErrorMessageInTableBody colSpan={headerList.length}>
              {searchWebSpeedTestScenarioListQuery.error.message}
            </ErrorMessageInTableBody>
          ) : searchWebSpeedTestScenarioList?.length === 0 ? (
            <NoListResultMessage
              colSpan={headerList.length}
              testType="scenario"
            />
          ) : (
            searchWebSpeedTestScenarioList?.map((webSpeedTestScenario, idx) => (
              <tr key={webSpeedTestScenario.idx}>
                <TableBodyBasic>
                  <Checkbox
                    checked={selectedArray.includes(webSpeedTestScenario.idx)}
                    onChange={(e) => selectArray(webSpeedTestScenario.idx, e)}
                  />
                </TableBodyBasic>
                <TableBodyBasic>{idx + 1}</TableBodyBasic>
                <TableBodyBasic>
                  <div className="font-medium text-gray-900">
                    {webSpeedTestScenario.title}
                  </div>
                  <div className="mt-1 text-gray-500">
                    {webSpeedTestScenario.description}
                  </div>
                </TableBodyBasic>
                <TableBodyBasic>
                  {webSpeedTestScenario.caseCount}
                </TableBodyBasic>
                <TableBodyBasic>{webSpeedTestScenario.creator}</TableBodyBasic>
                <TableBodyBasic>
                  <div>
                    {dayjs(webSpeedTestScenario.createdAt).format(
                      'YYYY-MM-DD',
                    ) ?? '-'}
                  </div>
                  <div
                    className={`mt-1 flex items-center justify-start gap-2 text-gray-500`}
                  >
                    {webSpeedTestScenario.createdAt && <Time />}
                    {webSpeedTestScenario.createdAt.match(timePattern)[0]}
                  </div>
                </TableBodyBasic>
                <TableBodyBasic>
                  <Execution
                    testType="scenario"
                    tooltipLocation="right"
                    disabled={webSpeedTestScenario.caseCount === 0}
                    onClick={() => {
                      if (webSpeedTestScenario.caseCount > 0) {
                        setTestScenarioIdx(webSpeedTestScenario.idx);
                        setIsTestExecutionOpened(true);
                      }
                    }}
                  />
                </TableBodyBasic>
                <TableBodyBasic>
                  <Detail
                    onClick={() =>
                      navigate({
                        pathname: 'detail',
                        search: `idx=${webSpeedTestScenario.idx}`,
                      })
                    }
                  />
                </TableBodyBasic>
              </tr>
            ))
          )}
        </tbody>
      </TableWrapper>
      <DeleteDialogInSpeedTestScenarioList
        isOpenedCautionDialog={isOpenedCautionDialog}
        setIsOpenedCautionDialog={setIsOpenedCautionDialog}
        deleteSpeedMobileTestScenarioMutation={
          deleteSpeedWebTestScenarioMutation
        }
        selectedArray={selectedArray}
        openDialog={openDialog}
        closeDialog={closeDialog}
        resetSelectedArray={resetSelectedArray}
      />
      {isTestExecutionOpened && (
        <TestExecutionContainer
          setState={setIsTestExecutionOpened}
          idx={testScenarioIdx}
          testType={'scenario'}
          deviceType={'web'}
          testLocation={'speed-test'}
          remoteWebExecutionMutation={remoteWebTestScenarioExecutionMutation}
          setIsModalOpen={setIsModalOpen}
          setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
        />
      )}
      {remoteWebTestScenarioExecutionMutation.isLoading && <ArrowSpinner />}
      <RemoteTestExecutionAlarmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isExecutionErrorModalOpen={isExecutionErrorModalOpen}
        setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
      />
    </ErrorBoundaryWrapper>
  );
};

export default WebSpeedTestScenarioTable;
