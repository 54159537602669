import {
  DescriptionListSkeleton,
  ErrorBoundaryWrapper,
  ErrorMessage,
  PagingHeadingsInAutosquare,
} from '@autosquare/common';

import React from 'react';
import { useLocation } from 'react-router-dom';

import { useSchedulerDetailQuery } from '@lib/api/ide/scheduler/scheduler';

import SchedulerDetailForm from './components/SchedulerDetailForm';
import AddWirelessDeviceButton from '@components/AddWirelessDeviceButton/AddWirelessDeviceButton';

const SchedulerDetail = () => {
  const location = useLocation();

  const schedulerDetailQuery = useSchedulerDetailQuery();
  const schedulerDetailData = schedulerDetailQuery.data;

  const breadcrumbs = [
    {
      title: 'UI Test',
      href: location.pathname.split('/').slice(0, -2).join('/') + '/overview',
    },
    {
      title: 'Scheduler',
      href: location.pathname.split('/').slice(0, -1).join('/'),
    },
    { title: 'Detail', href: location.pathname + location.search },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="flex items-center justify-between gap-x-4">
        <PagingHeadingsInAutosquare
          breadcrumbs={breadcrumbs}
          pageTitle={'Scheduler Detail'}
        />
        <div className="mt-8">
          <AddWirelessDeviceButton />
        </div>
      </div>
      {schedulerDetailQuery.isLoading || schedulerDetailQuery.isFetching ? (
        <DescriptionListSkeleton number={10} isLine={false} />
      ) : schedulerDetailQuery.isError ? (
        <ErrorMessage margin="my-8">
          {schedulerDetailQuery.error.message}
        </ErrorMessage>
      ) : (
        <SchedulerDetailForm schedulerDetailData={schedulerDetailData} />
      )}
    </ErrorBoundaryWrapper>
  );
};

export default SchedulerDetail;
