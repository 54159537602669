import { RootState } from '@app/store';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import { SpeedTestScenarioDataInScheduler } from '@customTypes/ide/speedTest/Mobile/testScenario/type';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import clsx from 'clsx';
import React from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { useSelector } from 'react-redux';

type Props = {
  copiedTestScenarioList: SpeedTestScenarioDataInScheduler[];
  speedTestScenario: SpeedTestScenarioDataInScheduler;
  changeCardList: (list: SpeedTestScenarioDataInScheduler[]) => void;
};

const TestScenarioListItemCard = ({
  copiedTestScenarioList,
  speedTestScenario,
  changeCardList,
}: Props) => {
  const isRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );

  let id = 1;

  const box = {
    ...speedTestScenario,
  };

  const [, drag] = useDrag({
    type: 'card',
    canDrag: isWordInLocationPathname('detail') ? isRunning : true,
    item() {
      const useless = copiedTestScenarioList.find(
        (copyTestScenario) => copyTestScenario.idx === -1,
      );
      if (!useless) {
        changeCardList([
          ...copiedTestScenarioList,
          {
            title: speedTestScenario.title,
            idx: speedTestScenario.idx,
            dndIndex: -1,
          },
        ]);
      }
      return box;
    },
    end(_: unknown, monitor: DragSourceMonitor) {
      const uselessIndex = copiedTestScenarioList.findIndex(
        (copyTestCase) => copyTestCase.dndIndex === -1,
      );

      if (monitor.didDrop()) {
        copiedTestScenarioList.splice(uselessIndex, 1, {
          ...monitor.getItem(),
          dndIndex: id++,
        });
      } else {
        copiedTestScenarioList.splice(uselessIndex, 1);
      }
      changeCardList(copiedTestScenarioList);
    },
  });

  return (
    <ErrorBoundaryWrapper>
      <div
        ref={drag}
        className={clsx(
          'm-2 flex items-center border border-solid bg-gray-50 px-3 py-2',
          isWordInLocationPathname('create') || isRunning
            ? 'cursor-move'
            : 'cursor-default',
        )}
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 9h16.5m-16.5 6.75h16.5"
            />
          </svg>
        </div>
        <div className="ml-7 text-sm">{speedTestScenario.title}</div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default TestScenarioListItemCard;
