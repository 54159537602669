import { ListBoxHeadlessInController } from '@autosquare/common';

import {
  findTextReplaceFromNameByServerName,
  textReplaceFromList,
} from '@utils/static/mobileTestStep/textReplace/textReplaceFromList';

import DefinitionList from '@components/shared/DefinitionList';

import { UpdateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { FromServerName } from '@customTypes/ide/mobileTestStep/textReplace/textReplace';

import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  from?: FromServerName;
};

const From = ({ from = FromServerName.Today }: Props) => {
  const { control, setValue, resetField } =
    useFormContext<UpdateTestStepData>();

  useEffect(() => {
    if (from) {
      setValue('optionsDto.textReplaceOptions.from', from);
    } else {
      setValue('optionsDto.textReplaceOptions.from', FromServerName.Today);
    }
  }, [from, setValue, resetField]);

  return (
    <DefinitionList
      term={'From'}
      description={
        <Controller
          control={control}
          name="optionsDto.textReplaceOptions.from"
          render={({ field }) => (
            <ListBoxHeadlessInController
              value={field.value}
              buttonValue={findTextReplaceFromNameByServerName(field.value)}
              onChange={field.onChange}
              lists={textReplaceFromList}
              valueToSave={'serverName'}
              valueToShow={'name'}
            />
          )}
        />
      }
    />
  );
};

export default From;
