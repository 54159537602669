import { ScenarioListDetailData } from '@autosquare/common';
import { SelectedItem } from '@autosquare/common/src/hooks/checkbox/useCheckboxWithDnd';
import { TestCaseDetailPickTitleAndIdx } from '@customTypes/testCase/type';

export const useScenarioDndCard = (
  copyItem: TestCaseDetailPickTitleAndIdx | ScenarioListDetailData,
  selectedArray: SelectedItem[],
  selectArray: (idx: number, dndKey: number, e) => void,
  onRemove: (id: number) => void,
  setSelectedArray: React.Dispatch<React.SetStateAction<SelectedItem[]>>,
  id: number,
) => {
  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    selectArray(copyItem.idx, copyItem.dndIndex, e);
  };
  const isChecked = selectedArray
    .map(({ dndIndex }) => dndIndex)
    .includes(copyItem.dndIndex);

  const onTrashIconHandler = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedArray((prev) => prev.filter(({ dndIndex }) => dndIndex !== id));
    onRemove(id);
  };

  return { onCheckboxChange, isChecked, onTrashIconHandler };
};
