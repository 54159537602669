import {
  ErrorBoundaryWrapper,
  SectionTitle,
  SectionTitleMain,
} from '@autosquare/common';
import { ImportCsvTestCaseRequest, PriorityNameEnum } from '@customTypes/index';
import { PlatFormTypeServerName } from '@utils/static/platformTypeList';
import React from 'react';
import { useFormContext } from 'react-hook-form';

type RadioObject = {
  id: string;
  value: string;
  label?: string;
};

type SetDefaultValue = {
  id: keyof ImportCsvTestCaseRequest;
  legend: string;
  radioList: RadioObject[];
};

const SetDefaultValues = () => {
  const { register } = useFormContext<ImportCsvTestCaseRequest>();

  const platformTypeRadioList: RadioObject[] = [
    {
      id: 'platformType-mobile-app',
      value: PlatFormTypeServerName.MobileApp,
      label: 'Mobile App',
    },
    {
      id: 'platformType-mobile-web',
      value: PlatFormTypeServerName.MobileWeb,
      label: 'Mobile Web',
    },
  ];

  const priorityRadioList: RadioObject[] = [
    {
      id: 'priority-high',
      value: PriorityNameEnum.High,
    },

    {
      id: 'priority-medium',
      value: PriorityNameEnum.Medium,
    },
    {
      id: 'priority-low',
      value: PriorityNameEnum.Low,
    },
  ];

  const setDefaultValueList: SetDefaultValue[] = [
    {
      id: 'platformType',
      legend: 'Platform Type',
      radioList: platformTypeRadioList,
    },
    {
      id: 'priority',
      legend: 'Priority',
      radioList: priorityRadioList,
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="mt-4">
        <SectionTitle>
          <SectionTitleMain>Set Default Values</SectionTitleMain>
        </SectionTitle>
        <div className="divide-y divide-gray-300 text-sm font-medium  leading-tight text-gray-900">
          {setDefaultValueList.map((value) => (
            <div className="flex gap-x-6 px-3 py-4" key={value.legend}>
              <legend className="w-24">{value.legend}</legend>
              {value.radioList.map((radio) => (
                <label
                  key={radio.id}
                  className="flex items-center gap-x-2 font-normal leading-4"
                >
                  <input
                    id={radio.id}
                    type="radio"
                    value={radio.value}
                    className="input-radio-congress-blue"
                    {...register(value.id)}
                  />
                  <span>{radio.label || radio.value}</span>
                </label>
              ))}
            </div>
          ))}
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default SetDefaultValues;
