import dayjs from 'dayjs';

// 10분 단위로 반올림하는 유틸리티 함수
export const getRoundedTime = (date: dayjs.Dayjs) => {
  const minutes = date.minute();
  const roundedMinutes = Math.ceil(minutes / 10) * 10;
  const hoursToAdd = roundedMinutes === 60 ? 1 : 0;

  return date
    .minute(roundedMinutes === 60 ? 0 : roundedMinutes)
    .add(hoursToAdd, 'hour')
    .second(0);
};

// 날짜 포맷팅 유틸리티 함수
export const formatDateTime = (date: Date) => {
  return dayjs(date).format('YYYY-MM-DD HH:mm');
};

// 같은 날짜인지 확인하는 유틸리티 함수
export const isSameDate = (date1: string | Date, date2: string | Date) => {
  return (
    dayjs(date1).startOf('day').diff(dayjs(date2).startOf('day'), 'day') === 0
  );
};
