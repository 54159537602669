import { MessengerAlarmType } from '@customTypes/dashboard/admin/type';
import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import Messenger10to12Detail from './Messenger/Messenger10to12Detail';
import Messenger01Detail from './Messenger/Messenger01Detail';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import { useAdminAvailableMessengerListQuery } from '@lib/api/dashboard/queryHooks';

type Props = {
  isActive: boolean;
  messengerData: MessengerAlarmType[];
};

const ProjectDetailsMessenger = ({ isActive, messengerData }: Props) => {
  const messengerAvailableListQuery = useAdminAvailableMessengerListQuery();
  const messengerList = messengerAvailableListQuery.data;
  const platformServerName = messengerData[0]?.platform;
  const selectedMessengerInfo = messengerList?.find(
    (messenger) => messenger.serverName === platformServerName,
  );

  const platformCode = selectedMessengerInfo?.platformCode;
  const platformTitle = selectedMessengerInfo?.platformTitle;

  return (
    <ErrorBoundaryWrapper>
      <div className="flex items-center justify-between py-6">
        <div className="flex gap-x-3">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Messenger
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            프로젝트에서 사용 할 수 있는 메신저 알람을 설정해 주세요.
          </p>
        </div>
        <div className="flex justify-end">
          <Switch as={Fragment} checked={isActive} disabled>
            <button
              className={clsx(
                'data-[checked]:bg-congress-blue group inline-flex size-4 h-6 w-11 items-center rounded-full bg-gray-400 data-[disabled]:cursor-not-allowed',
              )}
            >
              <span className="sr-only">Switch Enabled</span>
              <span
                className={clsx(
                  'size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6',
                )}
              />
            </button>
          </Switch>
        </div>
      </div>
      {platformCode === 'MSGR01' && isActive ? (
        <Messenger01Detail
          platformTitle={platformTitle}
          messengerData={messengerData[0]}
        />
      ) : (
        (platformCode === 'MSGR10' ||
          platformCode === 'MSGR11' ||
          platformCode === 'MSGR12') &&
        isActive && (
          <Messenger10to12Detail
            platformTitle={platformTitle}
            messengerData={messengerData[0]}
          />
        )
      )}
    </ErrorBoundaryWrapper>
  );
};

export default ProjectDetailsMessenger;
