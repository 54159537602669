import { ErrorBoundaryWrapper, ElectronFile } from '@autosquare/common';
import {
  CreateTestDataAutomation,
  UpdateTestDataAutomation,
} from '@customTypes/testCase/type';
import { PaperClipIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { ErrorMessage } from '@hookform/error-message';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

const RegisterTestData = () => {
  const [isDragging, setIsDragging] = useState(false);

  const {
    register,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateTestDataAutomation | UpdateTestDataAutomation>();

  const files = watch('file');

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const dataFiles = [...e.dataTransfer.files] as ElectronFile[];

    if (dataFiles && dataFiles.length > 0) {
      const file = dataFiles ? dataFiles[0] : undefined;

      setValue('file', file, { shouldValidate: true });
      setIsDragging(false);
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  return (
    <ErrorBoundaryWrapper>
      <div className="space-y-2">
        <div className="flex items-center justify-start gap-x-3">
          <h2 className="text-base font-semibold leading-normal text-gray-900">
            Test Data*
          </h2>
        </div>
        <p className="text-sm font-normal leading-tight tracking-tight text-gray-500">
          CSV 파일은 10MB 이하만 업로드 가능합니다. 파일이 표 형식이어야
          정상적으로 불러올 수 있습니다.
        </p>
      </div>
      <div
        className="flex-center flex-col gap-y-4 rounded-lg border border-dashed border-gray-300 px-8 py-10"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        {isDragging ? (
          <p className="-z-50 py-[1.125rem]">Drop File</p>
        ) : files?.name ? (
          <div className="flex items-center gap-x-2">
            <p className="text-base font-semibold leading-tight">
              {files.name}
            </p>
            <button
              type="button"
              onClick={() => {
                setValue('file', null);
                clearErrors('file');
              }}
            >
              <XCircleIcon className="size-5 text-red-500" />
            </button>
          </div>
        ) : (
          <>
            <PaperClipIcon className="size-6" />
            <label htmlFor="file-upload">
              <p className="cursor-pointer text-sm font-semibold leading-tight text-indigo-600">
                Upload a File
              </p>
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
                accept=".csv"
                {...register('file', {
                  onChange: (e) => {
                    const file = e.target.files?.[0] || null;
                    setValue('file', file, { shouldValidate: true });
                  },
                })}
              />
            </label>
          </>
        )}
      </div>
      <ErrorMessage
        errors={errors}
        name="file"
        render={({ message }) => (
          <p className="error-message -my-4">{message}</p>
        )}
      />
    </ErrorBoundaryWrapper>
  );
};

export default RegisterTestData;
