import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProjectMemberDetailState {
  isEdited: boolean;
}

const initialState: ProjectMemberDetailState = {
  isEdited: false,
};

const projectMemberDetailSlice = createSlice({
  name: 'projectMemberDetail',
  initialState,
  reducers: {
    setIsEdited: (state, action: PayloadAction<boolean>) => {
      state.isEdited = action.payload;
    },
  },
});

export const { setIsEdited } = projectMemberDetailSlice.actions;
export default projectMemberDetailSlice.reducer;
