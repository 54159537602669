import { createQueryKeys } from '@queries/createQueryKeys';

export const mobileTestResultKeys = {
  ...createQueryKeys('mobileTestResult'),
  detailInfos: () => [...mobileTestResultKeys.all, 'detailInfo'] as const,
  detailInfo: (idx: string) =>
    [...mobileTestResultKeys.detailInfos(), idx] as const,
  executionDetails: () =>
    [...mobileTestResultKeys.all, 'executionDetail'] as const,
  executionDetail: (testresultidx: string, caseResultIdx: number) =>
    [
      ...mobileTestResultKeys.executionDetails(),
      testresultidx,
      caseResultIdx,
    ] as const,
  detailImage: (path: string) =>
    [[...mobileTestResultKeys.detailInfos(), path], 'image'] as const,
  detailVideo: (path: string) =>
    [[...mobileTestResultKeys.detailInfos(), path], 'video'] as const,
  detailLog: (path: string) =>
    [[...mobileTestResultKeys.detailInfos(), path], 'log'] as const,
};
