import React from 'react';
import { useTestCaseImport } from '@contexts/ide/TestCaseImportContext';
import CsvCompleteResult from './TestCaseImportCompleteResults/CsvCompleteResult';
import DatCompleteResult from './TestCaseImportCompleteResults/DatCompleteResult';

const TestCaseImportCompleteResults = () => {
  const { fileData, isDatData, isCsvData } = useTestCaseImport();

  if (isDatData(fileData)) {
    return <DatCompleteResult fileData={fileData} />;
  }

  if (isCsvData(fileData)) {
    return <CsvCompleteResult />;
  }
};

export default TestCaseImportCompleteResults;
