import { Breadcrumbs } from '@autosquare/common';
import ExportOptionsDialog from '@components/ExportOptionsDialog/ExportOptionsDialog';
import { TestResultDetails } from '@customTypes/index';
import { useExportOptions } from '@hooks/index';
import { useWebTestResultExportMutation } from '@lib/api/dashboard/web/webTestResult';
import React from 'react';
import ExportOptionsTestResult from './components/ExportOptionsTestResult';

type Props = {
  breadcrumbs: Breadcrumbs[];
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  resultDetails: TestResultDetails[];
};

const WebExportOptions = ({
  breadcrumbs,
  isOpen,
  setIsOpen,
  resultDetails,
}: Props) => {
  const webTestResultExportMutation = useWebTestResultExportMutation();

  const {
    newBreadcrumbs,
    methods,
    onSubmit,
    caseResultList,
    handleAllChange,
    closeDialog,
    inputRef,
  } = useExportOptions({
    breadcrumbs,
    resultDetails,
    setIsOpen,
    testResultExportMutation: webTestResultExportMutation,
  });

  return (
    <ExportOptionsDialog
      isOpen={isOpen}
      closeDialog={closeDialog}
      newBreadcrumbs={newBreadcrumbs}
      methods={methods}
      onSubmit={onSubmit}
      inputRef={inputRef}
      caseResultList={caseResultList}
      handleAllChange={handleAllChange}
      resultDetails={resultDetails}
      testResultExportMutation={webTestResultExportMutation}
    >
      {resultDetails?.map((resultDetail) => (
        <ExportOptionsTestResult
          resultDetail={resultDetail}
          key={resultDetail.seq}
        />
      ))}
    </ExportOptionsDialog>
  );
};

export default WebExportOptions;
