import React from 'react';
import {
  CreateTestScenarioData,
  UpdateTestScenarioData,
} from '@customTypes/testScenario/type';
import { FieldErrors, UseFormRegisterReturn } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

type Props = {
  labelName: string;
  inputName: string;
  placeholder?: string | null;
  register: UseFormRegisterReturn;
  errors: FieldErrors<CreateTestScenarioData | UpdateTestScenarioData>;
};

export const InputBasic = ({
  labelName,
  inputName,
  placeholder,
  register,
  errors,
}: Props) => {
  return (
    <div className="col-span-4 w-full">
      <div className="flex gap-x-4">
        <label
          htmlFor={labelName}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {inputName}
        </label>
        <ErrorMessage
          name={labelName}
          errors={errors}
          render={({ message }) => <p className="error-message">{message}</p>}
        />
      </div>
      <div className="mt-2 flex flex-col">
        <input
          type="text"
          name={labelName}
          id={labelName}
          placeholder={placeholder ?? null}
          className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          {...register}
        />
      </div>
    </div>
  );
};
