import {
  ErrorBoundaryWrapper,
  PagingHeadingButtonOnClick,
} from '@autosquare/common';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootState } from '@app/store';

import { getSelectedDevices } from '@store/ide/device/selectedDevicesSlice';
import { hasDownloadedData } from '@store/ide/settings/settingsSlice';

import { DeviceInfo, DeviceInfoOs } from '@customTypes/ide/device/device';

import ExecutionInfo from './MobileTestExecution/ExecutionInfo';
import SelectedDevices from './MobileTestExecution/SelectedDevices';
import { TestTypeCategory } from '@autosquare/common';
import TestResultSaveOptions from '../../TestResultSaveOptions/TestResultSaveOptions';
import { Mode } from '@customTypes/ide/remote/type';
import { useTestExecutionContext } from '@contexts/ide/TestExecutionProvider';
import { UseMutationResult } from '@tanstack/react-query';
import { ExecutionTestData } from '@customTypes/testExecution/type';
import { getRemoteState } from '@utils/static/getConfiguration';
import { useCheckWdaStatusQuery } from '@lib/api/ide/mobile/mobileDevice';

interface Props {
  idx: number;
  setIsReadyToNavigate: React.Dispatch<React.SetStateAction<boolean>>;
  testType: TestTypeCategory;
  divWidth: number;
  mobileOS?: DeviceInfoOs;
  setIsSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  testLocation: 'ui-test' | 'speed-test';
  setIsWirelessDeviceModal: React.Dispatch<React.SetStateAction<boolean>>;
  setConnectionType: React.Dispatch<React.SetStateAction<'wifi' | 'port'>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeModalExecution: () => void;
  remoteMobileExecutionMutation: UseMutationResult<
    unknown,
    Error,
    ExecutionTestData,
    unknown
  >;
  setIsExecutionErrorModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileTestExecution = ({
  idx,
  setIsReadyToNavigate,
  testType,
  divWidth,
  mobileOS,
  setIsSettingsOpen,
  testLocation = 'ui-test',
  setIsWirelessDeviceModal,
  setConnectionType,
  setIsModalOpen,
  closeModalExecution,
  remoteMobileExecutionMutation,
  setIsExecutionErrorModalOpen,
}: Props) => {
  const { projectIdx } = useParams();

  const [selectedDevices, setSelectedDevices] = useState<DeviceInfo[]>([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isSelectedDevices, setIsSelectedDevices] = useState(false);
  const [isXcodeApp, setIsXcodeApp] = useState(false);
  const [isWdaFile, setIsWdaFile] = useState(false);
  const [needIosSetting, setNeedIosSetting] = useState(false);

  const { shouldSaveVideo, shouldSaveScreenshot, shouldSaveLogFile } =
    useTestExecutionContext();

  const defaultTestData = {
    infos: selectedDevices,
    projectIdx: Number(projectIdx),
  };

  const testStepData = { ...defaultTestData, stepIdx: idx };

  const testCaseData = {
    ...defaultTestData,
    caseIdx: idx,
    saveVideo: shouldSaveVideo,
    saveScreenshot: shouldSaveScreenshot,
    saveLog: shouldSaveLogFile,
  };

  const testScenarioData = {
    ...defaultTestData,
    scenarioIdx: idx,
    saveVideo: shouldSaveVideo,
    saveScreenshot: shouldSaveScreenshot,
    saveLog: shouldSaveLogFile,
  };

  const remoteData =
    testType === 'step'
      ? testStepData
      : testType === 'case'
        ? testCaseData
        : testScenarioData;

  const checkedDeviceHandler = (value: DeviceInfo, isChecked: boolean) => {
    if (isChecked) {
      setSelectedDevices([...selectedDevices, value]);
    }
    if (!isChecked && selectedDevices.includes(value)) {
      setSelectedDevices(
        selectedDevices.filter((selectedDevice) => selectedDevice !== value),
      );
    }
  };

  const checkHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: DeviceInfo,
  ) => {
    setIsChecked(!isChecked);
    checkedDeviceHandler(value, e.target.checked);
    setIsSelectedDevices(false);
  };

  const dispatch = useDispatch();
  const isDownloadedData = useSelector(
    (state: RootState) => state.settings.isDownloadedData,
  );

  useEffect(() => {
    dispatch(getSelectedDevices(selectedDevices));
  }, [selectedDevices]);

  const { configuration, standardConfiguration } = getRemoteState();

  const checkWdaStatusQuery = useCheckWdaStatusQuery();
  const remotePCWdaStatusData = checkWdaStatusQuery.data;
  const remotePCWdaStatus = remotePCWdaStatusData?.filter(
    (data) => data.projectIdx === Number(projectIdx),
  )?.[0]?.status;

  const checkCondition = (os: string) => {
    if (standardConfiguration) {
      return os === DeviceInfoOs.Ios && (!isXcodeApp || !isWdaFile);
    }
    return os === DeviceInfoOs.Ios && remotePCWdaStatus === null;
  };

  const executionTestOnClick = () => {
    if (!selectedDevices?.length) {
      setIsSelectedDevices(true);
      return;
    }
    if (selectedDevices.some(({ os }) => checkCondition(os))) {
      setNeedIosSetting(true);
      return;
    }
    setIsSelectedDevices(false);
    if (configuration === Mode.Remote) {
      closeModalExecution();
      remoteMobileExecutionMutation.mutate(remoteData, {
        onSuccess: () => setIsModalOpen(true),
        onError: () => setIsExecutionErrorModalOpen(true),
      });
    } else {
      setIsReadyToNavigate(true);
    }
  };

  useEffect(() => {
    const getXcodeLocation = async () => {
      const xcodeLocation: string | undefined =
        await window.electron.invoke('checkXcodeLocation');
      setIsXcodeApp(xcodeLocation?.toLowerCase().includes('xcode.app'));
    };
    const checkWdaFile = async () => {
      const existWdaFile: boolean = await window.electron.invoke(
        'existWdaFile',
        projectIdx,
      );
      setIsWdaFile(existWdaFile);
    };
    if (standardConfiguration) {
      getXcodeLocation();
      checkWdaFile();

      if (isDownloadedData) {
        getXcodeLocation();
        checkWdaFile();
        dispatch(hasDownloadedData(false));
      }
    }
  }, [isDownloadedData, standardConfiguration]);

  const breadcrumbs = [
    { title: testLocation === 'ui-test' ? 'UI Test' : 'Speed Test' },
    { title: 'Mobile' },
    {
      title:
        testType === 'step'
          ? 'Test Step'
          : testType === 'case'
            ? 'Test Case'
            : 'Test Scenario',
    },
    { title: 'Execution' },
  ];

  const pageHeadingButtons = [
    { title: 'Execution test', onClick: executionTestOnClick },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingButtonOnClick
        breadcrumbs={breadcrumbs}
        pageTitle={
          testType === 'step'
            ? 'Test Step Execution'
            : testType === 'case'
              ? 'Test Case Execution'
              : 'Test Scenario Execution'
        }
        pageHeadingButtons={pageHeadingButtons}
      />
      <ExecutionInfo idx={idx} testType={testType} />
      {testType !== 'step' && (
        <TestResultSaveOptions>
          <TestResultSaveOptions.Video />
          <TestResultSaveOptions.Screenshot />
          <TestResultSaveOptions.LogFile />
        </TestResultSaveOptions>
      )}
      <SelectedDevices
        selectedDevices={selectedDevices}
        setSelectedDevices={setSelectedDevices}
        checkHandler={checkHandler}
        isSelectedDevices={isSelectedDevices}
        setIsSelectedDevices={setIsSelectedDevices}
        divWidth={divWidth}
        testType={testType}
        mobileOS={mobileOS}
        idx={idx}
        needIosSetting={needIosSetting}
        setNeedIosSetting={setNeedIosSetting}
        setIsSettingsOpen={setIsSettingsOpen}
        setIsWirelessDeviceModal={setIsWirelessDeviceModal}
        setConnectionType={setConnectionType}
      />
    </ErrorBoundaryWrapper>
  );
};

export default MobileTestExecution;
