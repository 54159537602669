import { ErrorBoundaryWrapper, PagingHeadings } from '@autosquare/common';
import { useParentPath } from '@hooks/useParentPath';
import React from 'react';
import { useLocation } from 'react-router-dom';

const AddUserPagingHeader = () => {
  const { pathname } = useLocation();

  const userListPage = useParentPath();

  const breadcrumbs = [
    { title: 'Administration', href: userListPage },
    { title: 'Users', href: userListPage },
    { title: 'Add', href: pathname },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings breadcrumbs={breadcrumbs} pageTitle={'Add User'} />
    </ErrorBoundaryWrapper>
  );
};

export default AddUserPagingHeader;
