import { RootState } from '@app/store';
import {
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
} from '@autosquare/common';
import { useGetRemoteErrorHandler } from '@hooks/remote/useGetRemoteErrorHandler';
import { useDisconnectRemoteControlMutation } from '@lib/api/ide/remote/remote';
import { deleteRemoteInfo } from '@store/ide/remote/remoteControlSlice';
import { getRemoteState } from '@utils/static/getConfiguration';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

interface Props {
  subItem: {
    name: string;
    href: string;
    base: string;
  };
}

const SidebarChildren = ({ subItem }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const remotePCInfo = useSelector(
    (state: RootState) => state.remoteControl.remoteInfo,
  );

  const disconnectRemoteControlMutation = useDisconnectRemoteControlMutation();
  const { setIsErrorModalOpen, isErrorModalOpen, errorOnClose } =
    useGetRemoteErrorHandler(disconnectRemoteControlMutation);

  const { remoteConfiguration } = getRemoteState();
  const handleNavigate = () => {
    if (subItem.name === 'Logout') {
      if (remoteConfiguration && remotePCInfo) {
        disconnectRemoteControlMutation.mutate(remotePCInfo, {
          onError: () => setIsErrorModalOpen(true),
          onSuccess: () => {
            dispatch(deleteRemoteInfo());
            window.electron.send('logout');
          },
        });
        return;
      }
      window.electron.send('logout');
    }

    if (subItem.base !== location.pathname) {
      navigate(subItem.href);
    }
  };

  return (
    <ErrorBoundaryWrapper>
      <li>
        <button
          type="button"
          onClick={handleNavigate}
          className={clsx(
            'flex w-full cursor-pointer rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700',
            location.pathname.includes(subItem.base)
              ? 'bg-gray-50'
              : 'hover:bg-gray-50',
          )}
        >
          {subItem.name}
        </button>
      </li>
      <DoubleCheckDialogHeadlessUI
        isOpened={isErrorModalOpen}
        setIsOpened={setIsErrorModalOpen}
        type={'caution'}
        title={disconnectRemoteControlMutation.isError && 'Error'}
        subTitle={
          disconnectRemoteControlMutation.isError &&
          disconnectRemoteControlMutation.error.message
        }
        buttonChildren={
          <Button type="button" variant="primary" onClick={errorOnClose}>
            OK
          </Button>
        }
        onClose={errorOnClose}
      />
    </ErrorBoundaryWrapper>
  );
};

export default SidebarChildren;
