import React from 'react';

import {
  DeviceKeyServerName,
  findListNameFromServerName,
} from '@utils/static/deviceKeyList';
import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';

import {
  MaskingOptionsType,
  MobileTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

import PropertyDetail from './PropertyDetail';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Props = {
  list: MobileTestStepData;
  setIsCopied: React.Dispatch<React.SetStateAction<boolean>>;
};

const LoopTestStepList = ({ list, setIsCopied }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <PropertyDetail
        term={'Condition'}
        description={findMobileCommandNameFromServerName(
          list.optionsDto.conditionCommand,
        )}
      />
      <PropertyDetail
        term={'Value'}
        description={list.optionsDto.conditionValue}
        isRequiredCopy={true}
        setIsCopied={setIsCopied}
      />
      <PropertyDetail
        term={'Repeat'}
        description={list.optionsDto.repeatLimit}
        setIsCopied={setIsCopied}
      />
      <PropertyDetail
        term={'Action'}
        description={findMobileCommandNameFromServerName(
          list.value as MobileCommandOptionServerName,
        )}
      />
      <PropertyDetail
        term={'Value'}
        description={
          list.value === MobileCommandOptionServerName.DeviceKey
            ? findListNameFromServerName(
                list.optionsDto.actionValue as DeviceKeyServerName,
              )
            : list.value === MobileCommandOptionServerName.ScrollSwipe
              ? `[${list.optionsDto.actionValue}]`
              : list.optionsDto.actionValue
        }
        isRequiredCopy={true}
        setIsCopied={setIsCopied}
      />
      {list.value === MobileCommandOptionServerName.ScrollSwipe && (
        <PropertyDetail
          term={'Repeat'}
          description={list.optionsDto.actionOptions.repeatLimit}
          setIsCopied={setIsCopied}
        />
      )}
      <PropertyDetail
        term={'Masking Options'}
        description={
          list.optionsDto.maskingOptions?.enabled === 'true'
            ? `Y / ${list.optionsDto.maskingOptions?.type === MaskingOptionsType.Element ? '직접 선택' : list.optionsDto.maskingOptions?.type === MaskingOptionsType.Coordinate ? '영역 선택' : '전체 화면'}`
            : 'N'
        }
      />
    </ErrorBoundaryWrapper>
  );
};

export default LoopTestStepList;
