import {
  ErrorBoundaryWrapper,
  PagingHeadings,
  PageHeadingButtons,
} from '@autosquare/common';
import { useAuthUserInfo } from '@hooks/index';
import { useDeviceAuthorizationSettingQuery } from '@lib/api/dashboard/queryHooks';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ProjectDevicesListHeader = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { data: deviceAuthorizationSetting } =
    useDeviceAuthorizationSettingQuery();

  const isActivate = deviceAuthorizationSetting?.device === 'true';

  const { isAdmin } = useAuthUserInfo();

  const breadcrumbs = [
    { title: 'Project Settings', href: pathname },
    { title: 'Devices', href: pathname },
    { title: 'List', href: pathname },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = isAdmin
    ? [
        {
          title: 'Install',
          variant: 'outlinedBlue',
          type: 'button',
          onClick: () => {
            navigate('app-install');
          },
          disabled: !isActivate,
        },
        {
          title: 'Add',
          variant: 'primary',
          type: 'button',
          onClick: () => {
            navigate('add-device');
          },
        },
      ]
    : null;

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={'Device List'}
        pageHeadingButtons={pageHeadingButtons}
      />
    </ErrorBoundaryWrapper>
  );
};

export default ProjectDevicesListHeader;
