import React from 'react';
import {
  DescriptionListSkeleton,
  ErrorBoundaryWrapper,
  ErrorMessage,
} from '@autosquare/common';
import AdminUserInformationForm from './AdminUserInformation/AdminUserInformationForm';
import { useAdminUserDetailQuery } from '@lib/api/dashboard/queryHooks';

const AdminUserInformation = () => {
  const { isLoading, isFetching, isError, error } = useAdminUserDetailQuery();

  return (
    <ErrorBoundaryWrapper>
      {isLoading || isFetching ? (
        <DescriptionListSkeleton
          number={9}
          isLine={false}
          outPadding={'px-0 py-4'}
        />
      ) : isError ? (
        <ErrorMessage>{error.message}</ErrorMessage>
      ) : (
        <AdminUserInformationForm />
      )}
    </ErrorBoundaryWrapper>
  );
};

export default AdminUserInformation;
