import {
  ErrorBoundaryWrapper,
  PagingHeadingsInAutosquare,
} from '@autosquare/common';
import React from 'react';
import { useLocation } from 'react-router-dom';
import MobileTestStepDetailProvider from '@contexts/ide/MobileTestStepDetailProvider';
import MobileTestCaseDetailProvider from '@contexts/ide/MobileTestCaseDetailProvider';
import TestCaseInfo from './components/TestCaseInfo';
import TestStepList from './components/TestStepList';
import { useParentPath } from '@hooks/index';

const MobileTestCaseDetail = () => {
  const { pathname, search } = useLocation();

  const projectPath = useParentPath(3);
  const overviewPath = `${projectPath}/overview`;
  const testCasePage = useParentPath();

  const breadcrumbs = [
    { title: 'UI Test', href: overviewPath },
    { title: 'Mobile', href: testCasePage },
    { title: 'Test Case', href: testCasePage },
    { title: 'Detail', href: pathname + search },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Case Detail'}
      />
      <MobileTestCaseDetailProvider>
        <TestCaseInfo />
        <MobileTestStepDetailProvider>
          <TestStepList />
        </MobileTestStepDetailProvider>
      </MobileTestCaseDetailProvider>
    </ErrorBoundaryWrapper>
  );
};

export default MobileTestCaseDetail;
