import { decodeJwtToken, ErrorBoundaryWrapper } from '@autosquare/common';

import React, { Fragment } from 'react';
import { useLocation, Link } from 'react-router-dom';

import {
  DisclosureButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

import { useDashboardLogoutMutation } from '@lib/api/dashboard/user/users';

import { UserInfoType } from '@customTypes/type';
import clsx from 'clsx';
import TsquareLogo from '../shared/TsquareLogo';
import UserInfoLogo from '../shared/UserInfoLogo';

type Props = {
  userInfo: UserInfoType;
  accessToken: string;
};

const BasicNav = ({ userInfo, accessToken }: Props) => {
  const location = useLocation();

  const dashboardLogoutMutation = useDashboardLogoutMutation();

  const navbarTitles = [
    { title: 'Projects', type: 'internal', link: '/projects' },
    { title: 'Download', type: 'internal', link: '/download' },
    {
      title: 'Documents',
      type: 'external',
      link:
        window.location.origin === 'http://localhost:3002'
          ? 'http://localhost:3000/docs/'
          : `${window.location.origin}/docs/`,
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="max-w-full px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 justify-between">
          <div className="flex">
            <TsquareLogo />
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
              {navbarTitles?.map((navBarTitle) =>
                navBarTitle.type === 'internal' ? (
                  <Link
                    to={navBarTitle.link}
                    key={navBarTitle.title}
                    className={clsx(
                      'border-congress-blue inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 hover:text-gray-700',
                      location.pathname.includes(
                        navBarTitle.title?.toLowerCase(),
                      ) && 'border-b-2',
                    )}
                  >
                    {navBarTitle.title}
                  </Link>
                ) : (
                  // eslint-disable-next-line react/jsx-no-target-blank
                  <a
                    href={navBarTitle.link}
                    key={navBarTitle.title}
                    className="border-congress-blue inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 hover:text-gray-700"
                    target="_blank"
                  >
                    {navBarTitle.title}
                  </a>
                ),
              )}
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center sm:gap-2.5">
            {/* Profile dropdown */}
            <Menu as="div" className="relative ml-3">
              <MenuButton className="flex rounded-full bg-white text-sm focus:outline-none">
                <UserInfoLogo userName={userInfo.name} />
              </MenuButton>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
                  {decodeJwtToken(accessToken)?.auth === 'ROLE_ADMIN' && (
                    <MenuItem>
                      <Link
                        to={'/admin/users'}
                        className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                      >
                        Administration
                      </Link>
                    </MenuItem>
                  )}
                  <MenuItem>
                    <button
                      type="button"
                      className="block w-full px-4 py-2 text-start text-sm text-gray-700 data-[focus]:bg-gray-100"
                      onClick={() => dashboardLogoutMutation.mutate()}
                    >
                      Sign out
                    </button>
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
          </div>
          <div className="-mr-2 flex items-center sm:hidden">
            {/* Mobile menu button */}
            <DisclosureButton className="group inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open main menu</span>
              <XMarkIcon
                className="hidden size-6 group-data-[open]:block"
                aria-hidden="true"
              />
              <Bars3Icon
                className="block size-6 group-data-[open]:hidden"
                aria-hidden="true"
              />
            </DisclosureButton>
          </div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default BasicNav;
