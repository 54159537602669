import { ListBoxHeadlessInController } from '@autosquare/common';

import PriorityListbox from '@components/shared/HeadlessUI/Listbox/PriorityListbox';
import TestGroupBox from '@components/IDE/TestCase/TestGroupBox';

import { useMobileTestCaseDetailQuery } from '@lib/api/ide/mobile/mobileTestCase';

import {
  findPlatformTypeNameFromServerName,
  platformTypeList,
} from '@utils/static/platformTypeList';

import { UpdateMobileTestCaseData } from '@customTypes/testCase/type';

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const TestCaseInfoTop = () => {
  const { control } = useFormContext<UpdateMobileTestCaseData>();

  const { data: mobileTestCaseDetail } = useMobileTestCaseDetailQuery();

  return (
    <div className="grid grid-cols-1 gap-4 border-t border-gray-100 sm:grid-cols-4">
      <div className="flex flex-col gap-2 py-6 sm:col-span-1">
        <div className="text-sm font-medium leading-6">Platform Type</div>
        <Controller
          name="platformType"
          control={control}
          render={({ field }) => (
            <ListBoxHeadlessInController
              value={field.value}
              buttonValue={findPlatformTypeNameFromServerName(field.value)}
              onChange={field.onChange}
              lists={platformTypeList.slice(1)}
              valueToSave={'serverName'}
              valueToShow={'name'}
            />
          )}
        />
      </div>
      <div className="flex flex-col gap-2 py-6 sm:col-span-1">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          Priority
        </dt>
        <Controller
          name="priority"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <PriorityListbox value={field.value} onChange={field.onChange} />
          )}
        />
      </div>
      <div className="py-6">
        <TestGroupBox isRequired={false} />
      </div>
      <div className="py-6">
        <dt className="text-sm font-medium leading-6 text-gray-900">Creator</dt>
        <div className="relative mt-3 w-full text-sm leading-6 text-gray-700">
          {mobileTestCaseDetail?.creator}
        </div>
      </div>
    </div>
  );
};

export default TestCaseInfoTop;
