import {
  useMutation,
  useQuery,
  useQueryClient,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { IDEAPI } from '@api/ideEndpoints';
import { axiosInstance, axiosLocalInstance } from '..';
import { mobileTestCaseKeys } from '@queries/ide/mobile/mobileTestCaseKeys';
import { mobileTestStepKeys } from '@queries/ide/mobile/mobileTestStepKeys';
import {
  ImportTestCaseResponse,
  ImportedFileData,
  MobileTestCaseExportDataResponse,
  MobileTestCaseExportResponse,
  PostExportMobileTestCase,
  PostExportMobileTestCaseData,
  PostMobileTestCaseExportListToLocal,
  TestCaseData,
  TestCaseDetailData,
  CreateMobileTestCaseData,
  UpdateMobileTestCaseData,
  ImportedFileCsvData,
  CreateImportCsvTestCase,
  CreateImportCsvTestCaseResponse,
  ImportCsvTestCaseRequest,
} from '@customTypes/index';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { groupKeys } from '@queries/ide/groupKeys';

interface UseUpdateTestCaseListSequenceMutation {
  idx: string;
  mobileOs: DeviceInfoOs;
  indexArray: number[];
}

interface CopyMobileTestCaseMutation {
  idx: string;
  projectIdx: string;
}

// GET
// Test Case List 전체 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Test%20Case/getAllTestCases
const getMobileTestCaseList = async (
  projectIdx: string,
): Promise<TestCaseDetailData[]> => {
  const response = await axiosInstance.get<TestCaseDetailData[]>(
    IDEAPI.GET_MOBILE_TEST_CASE + projectIdx,
  );
  return response.data;
};
export const useMobileTestCaseListQuery = (
  options?: UseQueryOptions<TestCaseDetailData[], Error>,
) => {
  const { projectIdx } = useParams();

  return useQuery<TestCaseDetailData[], Error, TestCaseDetailData[]>({
    queryKey: mobileTestCaseKeys.list(projectIdx),
    queryFn: () => getMobileTestCaseList(projectIdx),
    ...options,
  });
};

// Test Case 상세 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Test%20Case/getTestCase
const getMobileTestCaseDetail = async (
  idx: string,
): Promise<TestCaseDetailData> => {
  const response = await axiosInstance.get<TestCaseDetailData>(
    IDEAPI.GET_DETAIL_MOBILE_TEST_CASE + idx,
  );
  return response.data;
};
export const useMobileTestCaseDetailQuery = (testCaseIdx?: string) => {
  const [searchParams] = useSearchParams();
  const searchParamsTestCaseIdx = searchParams.get('idx');

  const idx = testCaseIdx ?? searchParamsTestCaseIdx;

  return useQuery<TestCaseDetailData, Error, TestCaseDetailData>({
    queryKey: mobileTestCaseKeys.detail(idx),
    queryFn: () => getMobileTestCaseDetail(idx),
  });
};

// Test Case 조건 조회 (검색 조회)
// https://dev.t-square.co.kr/swagger-ui/index.html#/Test%20Case/getFilteredTestCase
const getFilteredMobileTestCaseList = async (
  search: string,
): Promise<TestCaseDetailData[]> => {
  const response = await axiosInstance.get<TestCaseDetailData[]>(
    `${IDEAPI.MOBILE_TEST_CASE}/search${search}`,
  );
  return response.data;
};
export const useFilteredMobileTestCaseListQuery = () => {
  const { search } = useLocation();

  return useQuery<TestCaseDetailData[], Error, TestCaseDetailData[]>({
    queryKey: mobileTestCaseKeys.search(search),
    queryFn: () => getFilteredMobileTestCaseList(search),
    enabled: search !== '',
  });
};

// POST
// Test Case 생성
// https://dev.t-square.co.kr/swagger-ui/index.html#/Test%20Case/saveTestCase
const createMobileTestCase = async (
  projectIdx: string,
  data: CreateMobileTestCaseData,
): Promise<TestCaseDetailData> => {
  const response = await axiosInstance.post<TestCaseDetailData>(
    IDEAPI.CREATE_MOBILE_TEST_CASE + projectIdx,
    data,
  );
  return response.data;
};
export const useCreateMobileTestCaseMutation = (
  options?: UseMutationOptions<
    TestCaseDetailData,
    ServerResponseErrorDetails,
    CreateMobileTestCaseData
  >,
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { projectIdx } = useParams();

  return useMutation<
    TestCaseDetailData,
    ServerResponseErrorDetails,
    CreateMobileTestCaseData
  >({
    mutationFn: (data) => createMobileTestCase(projectIdx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileTestCaseKeys.lists());
      queryClient.invalidateQueries(mobileTestCaseKeys.searches());
      queryClient.invalidateQueries(groupKeys.all);
      navigate(`/ide/projects/${projectIdx}/ui-test/mobile/test-case`);
    },
    ...options,
  });
};

// Test Case Export
// http://localhost:8081/swagger-ui/index.html#/Test%20Case/exportTestCase
const exportTestCase = async (
  data: PostExportMobileTestCase,
): Promise<MobileTestCaseExportResponse> => {
  const response = await axiosLocalInstance.post<MobileTestCaseExportResponse>(
    IDEAPI.EXPORT_MOBILE_TEST_CASE,
    data,
  );
  return response.data;
};
export const useExportTestCaseMutation = () => {
  return useMutation<
    MobileTestCaseExportResponse,
    Error,
    PostExportMobileTestCase
  >({
    mutationFn: (data) => exportTestCase(data),
  });
};

// Test Case Export Data 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Test%20Case/getExportTestCaseData
const exportMobileTestCaseData = async (
  data: PostExportMobileTestCaseData,
): Promise<MobileTestCaseExportDataResponse> => {
  const response = await axiosInstance.post<MobileTestCaseExportDataResponse>(
    IDEAPI.EXPORT_MOBILE_TEST_CASE_DATA,
    data,
  );
  return response.data;
};
export const useMobileTestCaseDataExportMutation = () => {
  return useMutation<
    MobileTestCaseExportDataResponse,
    Error,
    PostExportMobileTestCaseData
  >({
    mutationFn: (data) => exportMobileTestCaseData(data),
  });
};

// Import .dat File Load
// http://localhost:8081/swagger-ui/index.html#/Test%20Case/getTestCaseListToImport
const importTestCase = async (
  cookie: string,
  data: PostMobileTestCaseExportListToLocal,
): Promise<ImportedFileData[]> => {
  const response = await axiosLocalInstance.post<ImportedFileData[]>(
    IDEAPI.IMPORT_MOBILE_TEST_CASE_LIST,
    data,
    {
      headers: { 'Token-Value': cookie },
    },
  );
  return response.data;
};
export const useImportTestCaseMutation = (cookie: string) => {
  return useMutation<
    ImportedFileData[],
    Error,
    PostMobileTestCaseExportListToLocal
  >({
    mutationFn: (data) => importTestCase(cookie, data),
  });
};

// Test Case Import Dat File
// https://dev.t-square.co.kr/swagger-ui/index.html#/Test%20Case/importTestCase
const postImportList = async (
  projectIdx: string,
  data: TestCaseData,
): Promise<ImportTestCaseResponse> => {
  const response = await axiosInstance.post<ImportTestCaseResponse>(
    IDEAPI.IMPORT_MOBILE_TEST_CASE,
    { projectIdx: Number(projectIdx), testCaseData: data },
  );
  return response.data;
};
export const usePostImportListMutation = (projectIdx: string) => {
  const queryClient = useQueryClient();

  return useMutation<ImportTestCaseResponse, Error, TestCaseData>({
    mutationFn: (data) => postImportList(projectIdx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileTestCaseKeys.all);
    },
  });
};

// Import .csv File Load
// http://localhost:8081/swagger-ui/index.html#/Test%20Case/getTestCaseCSVImport
const importCsvTestCaseFileLoad = async (
  cookie: string,
  data: PostMobileTestCaseExportListToLocal,
): Promise<ImportedFileCsvData[]> => {
  const response = await axiosLocalInstance.post<ImportedFileCsvData[]>(
    `${IDEAPI.IMPORT_MOBILE_TEST_CASE_LIST}/csv`,
    data,
    {
      headers: { 'Token-Value': cookie },
    },
  );
  return response.data;
};
export const useImportCsvTestCaseMutation = (cookie: string) => {
  return useMutation<
    ImportedFileCsvData[],
    Error,
    PostMobileTestCaseExportListToLocal
  >({
    mutationFn: (data) => importCsvTestCaseFileLoad(cookie, data),
  });
};

// CSV Test Case Import
// https://dev.t-square.co.kr/swagger-ui/index.html#/Test%20Case/importTestCase_1
const importCsvTestCase = async (
  data: CreateImportCsvTestCase,
): Promise<CreateImportCsvTestCaseResponse> => {
  const response = await axiosInstance.post<CreateImportCsvTestCaseResponse>(
    `${IDEAPI.IMPORT_MOBILE_TEST_CASE}/csv`,
    data,
  );
  return response.data;
};
export const useImportCsvTestCaseCsvMutation = () => {
  const { projectIdx } = useParams();
  const queryClient = useQueryClient();

  const updatedData = (
    data: CreateImportCsvTestCase,
  ): CreateImportCsvTestCase => {
    return {
      ...data,
      projectIdx: Number(projectIdx),
    };
  };

  return useMutation<
    CreateImportCsvTestCaseResponse,
    Error,
    CreateImportCsvTestCase
  >({
    mutationFn: (data) => importCsvTestCase(updatedData(data)),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileTestCaseKeys.all);
    },
  });
};

// Test Case 복사
// https://dev.t-square.co.kr/swagger-ui/index.html#/Test%20Case/copyTestCase_2
const copyTestCase = async ({
  idx,
  projectIdx,
}: CopyMobileTestCaseMutation): Promise<TestCaseDetailData> => {
  const response = await axiosInstance.post<TestCaseDetailData>(
    `${IDEAPI.COPY_MOBILE_TEST_CASE}${idx}`,
    { projectIdx: Number(projectIdx) },
  );
  return response.data;
};

export const useCopyTestCaseMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<TestCaseDetailData, Error, CopyMobileTestCaseMutation>({
    mutationFn: copyTestCase,
    onSuccess: () => {
      queryClient.invalidateQueries(mobileTestCaseKeys.lists());
      queryClient.invalidateQueries(groupKeys.all);
    },
  });
};

// PUT
// Test Case 수정
// https://dev.t-square.co.kr/swagger-ui/index.html#/Test%20Case/updateTestCase
const updateMobileTestCase = async (
  idx: string,
  data: UpdateMobileTestCaseData,
): Promise<TestCaseDetailData> => {
  const response = await axiosInstance.put<TestCaseDetailData>(
    `${IDEAPI.UPDATE_MOBILE_TEST_CASE}${idx}`,
    data,
  );
  return response.data;
};
export const useUpdateMobileTestCaseMutation = () => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  return useMutation<TestCaseDetailData, Error, UpdateMobileTestCaseData>({
    mutationFn: (data) => updateMobileTestCase(idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileTestCaseKeys.all);
      queryClient.invalidateQueries(groupKeys.all);
    },
  });
};

// Test Case 순서 수정
// https://dev.t-square.co.kr/swagger-ui/index.html#/Test%20Case/updateTestCaseStep_2
const updateTestCaseListSequence = async (
  idx: string,
  mobileOs: DeviceInfoOs,
  indexArray: number[],
): Promise<unknown> => {
  const response = await axiosInstance.put<unknown>(
    IDEAPI.UPDATE_TEST_CASE_LIST + idx,
    {
      mobileOs: mobileOs,
      step: indexArray,
    },
  );
  return response.data;
};
export const useUpdateTestCaseListSequenceMutation = ({
  idx,
  mobileOs,
  indexArray,
}: UseUpdateTestCaseListSequenceMutation) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error>({
    mutationFn: () => updateTestCaseListSequence(idx, mobileOs, indexArray),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileTestStepKeys.all);
    },
  });
};

// Delete
// Test Case 삭제
// https://dev.t-square.co.kr/swagger-ui/index.html#/Test%20Case/deleteTestCase_1
const deleteTestCase = async (data: {
  idx_list: number[];
}): Promise<{ idx_list: number[] }> => {
  const response = await axiosInstance.delete<{ idx_list: number[] }>(
    IDEAPI.DELETE_MOBILE_TEST_CASE,
    { data: data },
  );
  return response.data;
};
export const useDeleteTestCaseMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<{ idx_list: number[] }, Error, { idx_list: number[] }>({
    mutationFn: (data) => deleteTestCase(data),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileTestCaseKeys.lists());
      queryClient.invalidateQueries(mobileTestCaseKeys.searches());
      queryClient.invalidateQueries(groupKeys.all);
    },
  });
};
