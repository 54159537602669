import SwitchInController from '@components/shared/HeadlessUI/Switch/SwitchInController';
import { UpdateAdministrationDevice } from '@customTypes/index';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const StatusField = () => {
  const { control } = useFormContext<UpdateAdministrationDevice>();

  return (
    <div className="flex items-center justify-start gap-8">
      <label className="text-sm font-medium leading-normal text-gray-900">
        Status
      </label>
      <Controller
        control={control}
        name="deviceEnabled"
        render={({ field }) => (
          <SwitchInController checked={field.value} onChange={field.onChange} />
        )}
      />
    </div>
  );
};

export default StatusField;
