import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';

import DefinitionList from '@components/shared/DefinitionList';
import DivisionWord from '@components/shared/TestStepDetail/DivisionWord';
import ControllerTextArea from '@components/shared/TextArea/ControllerTextArea';

import {
  UpdateWebTestStepData,
  WebTestStepData,
} from '@customTypes/testStep/type';

import {
  findKeyboardEventNameFromServerName,
  keyboardEventList,
  KeyboardEventServerName,
} from '@utils/static/keyboardEventList';
import {
  findWebCommandNameFromServerName,
  WebCommandOptionName,
  webCommandOptions,
  WebCommandOptionServerName,
} from '@utils/static/webCommandOptions';

import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import WebTestStepImportTestCaseListBox from '@components/IDE/WebTestStep/WebTestStepImportTestCaseListBox';

type Props = {
  selectedPassCommand: WebCommandOptionServerName;
  setSelectedPassCommand: React.Dispatch<
    React.SetStateAction<WebCommandOptionServerName>
  >;
  splitValue: (value: string, index: number) => WebCommandOptionServerName;
  webTestStepData: WebTestStepData;
};

const IfPassAction = ({
  selectedPassCommand,
  setSelectedPassCommand,
  splitValue,
  webTestStepData,
}: Props) => {
  const {
    watch,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<UpdateWebTestStepData>();

  const defaultPassCommand = splitValue(webTestStepData.value, 0);
  const commandNameFromServerName =
    findWebCommandNameFromServerName(defaultPassCommand);
  const passActionValue = webTestStepData.optionsDto.passActionValue;

  const handleClickOption = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const target = e.target as HTMLElement;
    const textContent = target.textContent;

    if (textContent === commandNameFromServerName) {
      setValue('optionsDto.passActionValue', passActionValue);
    } else {
      setValue('optionsDto.passActionValue', '');
    }
  };

  useEffect(() => {
    if (
      selectedPassCommand !== defaultPassCommand ||
      (selectedPassCommand === defaultPassCommand &&
        watch('optionsDto.passActionValue') !== '')
    ) {
      clearErrors('optionsDto.passActionValue');
    }
  }, [selectedPassCommand, clearErrors]);

  return (
    <ErrorBoundaryWrapper>
      <DivisionWord color="green">PASS</DivisionWord>
      <DefinitionList
        term={'Command'}
        description={
          <ListBoxHeadlessInController
            value={selectedPassCommand}
            buttonValue={findWebCommandNameFromServerName(selectedPassCommand)}
            onChange={setSelectedPassCommand}
            lists={webCommandOptions.filter(
              (command) =>
                command.name === WebCommandOptionName.Click ||
                command.name === WebCommandOptionName.ImportTestCase ||
                command.name === WebCommandOptionName.KeyBoardEvent,
            )}
            valueToSave={'serverName'}
            valueToShow={'name'}
            onClickOption={handleClickOption}
          />
        }
      />
      <DefinitionList
        term={'Value*'}
        description={
          selectedPassCommand === WebCommandOptionServerName.ImportTestCase ? (
            <WebTestStepImportTestCaseListBox value="optionsDto.passActionValue" />
          ) : (
            <Controller
              control={control}
              name={'optionsDto.passActionValue'}
              rules={{
                required:
                  selectedPassCommand === WebCommandOptionServerName.Click
                    ? 'Value 값을 입력해 주세요.'
                    : selectedPassCommand ===
                        WebCommandOptionServerName.KeyBoardEvent &&
                      'Key Event를 선택해 주세요.',
              }}
              render={({ field }) =>
                selectedPassCommand === WebCommandOptionServerName.Click ? (
                  <ControllerTextArea field={field} />
                ) : (
                  selectedPassCommand ===
                    WebCommandOptionServerName.KeyBoardEvent && (
                    <ErrorBoundaryWrapper>
                      <ListBoxHeadlessInController
                        value={field.value}
                        buttonValue={
                          findKeyboardEventNameFromServerName(
                            field.value as KeyboardEventServerName,
                          ) || 'Select Keyboard Event'
                        }
                        onChange={field.onChange}
                        lists={keyboardEventList}
                        valueToSave={'serverName'}
                        valueToShow={'name'}
                      />
                      <ErrorMessage
                        name="optionsDto.passActionValue"
                        errors={errors}
                        render={({ message }) => (
                          <p className="standard-error-message">{message}</p>
                        )}
                      />
                    </ErrorBoundaryWrapper>
                  )
                )
              }
            />
          )
        }
      />
    </ErrorBoundaryWrapper>
  );
};

export default IfPassAction;
