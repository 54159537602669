import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/store';

import {
  Breadcrumbs,
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorMessage,
  IdeMode,
  IdeModeHeader,
  PagingHeadingsNavigate,
} from '@autosquare/common';

import {
  checkValidateUrlMutation,
  sendAppUrlMutation,
} from '@lib/api/ide/setting/settings';

import { clearIdeAccessToken } from '@store/api/accessTokenSlice';

import ApplicationAlert from './ApplicationAlert';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ApplicationUrl } from '@customTypes/setting/type';
import { getRemoteState } from '@utils/static/getConfiguration';
import { useDisconnectRemoteControlMutation } from '@lib/api/ide/remote/remote';
import { deleteRemoteInfo } from '@store/ide/remote/remoteControlSlice';

type Props = {
  closeModal: () => void;
};

const Application = ({ closeModal }: Props) => {
  const applicationUrl = localStorage.getItem('applicationUrl');
  //IDE원격 기능 완료되어서 default 모드로 선택한 사람 없어지면 삭제!
  const getConfiguration = (value: 'standard' | 'remote' | 'default') => {
    if (value === 'standard' || value === 'remote') return value;
    else return 'standard';
  };
  const configuration = getConfiguration(
    localStorage.getItem('configuration') as 'standard' | 'remote',
  );

  const { register, watch, handleSubmit } = useForm<ApplicationUrl>({
    defaultValues: {
      applicationUrl: applicationUrl,
      configuration: configuration,
    },
  });
  const url = watch('applicationUrl');
  const config = watch('configuration');

  const [isValidateUrlError, setIsValidateUrlError] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const dispatch = useDispatch();
  const accessToken = useSelector(
    (state: RootState) => state.accessTokenValue.ideAccessToken,
  );

  const { readOnlyMode } = getRemoteState();

  const sendAppUrl = sendAppUrlMutation({
    url: url,
    onSuccess: async () => {
      if (accessToken) {
        dispatch(clearIdeAccessToken());
      }
    },
  });
  const checkValidateUrl = checkValidateUrlMutation();
  const remotePCInfo = useSelector(
    (state: RootState) => state.remoteControl.remoteInfo,
  );
  const disconnectRemoteControlMutation = useDisconnectRemoteControlMutation();

  const onSubmit: SubmitHandler<ApplicationUrl> = (data) => {
    if (data.applicationUrl.endsWith('/')) {
      data.applicationUrl = data.applicationUrl.slice(0, -1);
    }
    if (applicationUrl === url && configuration === config) {
      closeModal();
    } else {
      checkValidateUrl.mutate(data.applicationUrl, {
        onSuccess: () => {
          if (configuration !== 'remote') {
            sendAppUrl.mutate();
          }
          if (remotePCInfo !== undefined) {
            disconnectRemoteControlMutation.mutate(remotePCInfo);
            dispatch(deleteRemoteInfo());
          }
          window.electron.send('editServerUrl', data);
          localStorage.setItem('applicationUrl', data.applicationUrl);
          localStorage.setItem('configuration', data.configuration);
          setIsValidateUrlError(false);
          closeModal();
        },
        onError: () => {
          setIsValidateUrlError(true);
        },
      });
    }
  };

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'Settings', disabled: true },
    { title: 'Application', disabled: true },
  ];

  return (
    <div className="relative w-full">
      {isValidateUrlError && (
        <ApplicationAlert
          status="error"
          message="유효하지 않은 대시보드 서버 URL 입니다."
          onClick={() => setIsValidateUrlError(false)}
        />
      )}
      <main className="w-full pr-10 pt-5">
        <div className="flex grow flex-col">
          <PagingHeadingsNavigate
            breadcrumbs={breadcrumbs}
            pageTitle={'Application Settings'}
            titleDescription={
              'IDE 기본 설정을 진행해 주세요. 이후 설정 화면에서 IDE 설정 변경 시 IDE가 자동으로 재실행됩니다.'
            }
          />
          <form className="mt-10 space-y-3" onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Dashboard Server URL
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                로그인, 테스트 데이터 동기화 등 Dashboard 서버와 연동을 위한
                Server URL을 입력해주세요.
              </p>
              <div className="mt-5">
                <label htmlFor="dashboard_url" className="sr-only">
                  Dashboard URL
                </label>
                <input
                  name={'dashboard_url'}
                  id={'dashboard_url'}
                  placeholder={'http://localhost:8080'}
                  className="input-base disabled:bg-gray-100"
                  defaultValue={applicationUrl}
                  pattern="https?://.+"
                  title="Please enter a valid URL"
                  required
                  {...register('applicationUrl')}
                  disabled={readOnlyMode}
                />
              </div>
            </fieldset>
            <fieldset>
              <IdeModeHeader />
              <div className="mt-5 flex items-center justify-start gap-x-3.5">
                <IdeMode
                  configuration={config}
                  register={register('configuration')}
                />
              </div>
            </fieldset>
            <div className="sm:flex-center mt-6 flex flex-col items-end justify-between gap-y-3 sm:flex-row sm:gap-x-6">
              <div className="hidden sm:visible" />
              <ErrorMessage margin={'m-0'}>
                {sendAppUrl?.isError && sendAppUrl.error?.message}
              </ErrorMessage>
              <Button
                type={config === configuration ? 'submit' : 'button'}
                variant="primary"
                onClick={() => {
                  if (config !== configuration) {
                    setIsModalOpened(true);
                  }
                }}
              >
                Save
              </Button>
            </div>
            <DoubleCheckDialogHeadlessUI
              type="caution"
              isOpened={isModalOpened}
              setIsOpened={setIsModalOpened}
              title={'Change Application Settings'}
              subTitle={`IDE 설정 변경 후 프로그램이 종료되고 재실행됩니다.\n설정을 변경하시겠습니까?`}
              buttonChildren={
                <>
                  <Button
                    type="submit"
                    variant="primary"
                    onClick={() => {
                      setIsModalOpened(false);
                      handleSubmit(onSubmit)();
                    }}
                  >
                    OK
                  </Button>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => setIsModalOpened(false)}
                  >
                    Cancel
                  </Button>
                </>
              }
            />
          </form>
        </div>
      </main>
    </div>
  );
};

export default Application;
