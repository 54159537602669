import {
  ErrorBoundaryWrapper,
  SectionMainTitleContainer,
  SectionTitleContainer,
  SectionTitleHeaderDescription,
  SectionTitleMain,
} from '@autosquare/common';
import { useProjectDeviceDetailQuery } from '@lib/api/dashboard/queryHooks';
import React from 'react';

const ProjectDeviceDetailInformation = () => {
  const { data, isSuccess } = useProjectDeviceDetailQuery();

  return (
    <ErrorBoundaryWrapper>
      <SectionTitleContainer>
        <SectionMainTitleContainer>
          <SectionTitleMain>Device Information</SectionTitleMain>
          <SectionTitleHeaderDescription>
            등록할 디바이스의 정보를 입력해 주세요.
          </SectionTitleHeaderDescription>
        </SectionMainTitleContainer>
        {isSuccess && (
          <p className="text-sm font-medium leading-tight text-gray-500">
            Updated at {data.modifiedAt}
          </p>
        )}
      </SectionTitleContainer>
    </ErrorBoundaryWrapper>
  );
};

export default ProjectDeviceDetailInformation;
