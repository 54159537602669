import { findTextReplaceFromNameByServerName } from '@utils/static/mobileTestStep/textReplace/textReplaceFromList';
import { findTextReplaceAddNameByServerName } from '@utils/static/mobileTestStep/textReplace/textReplaceAddList';

import TestStepDetailBasicDefinition from '@components/shared/TestStepDetail/TestStepDetailBasicDefinition';

import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React from 'react';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';

type Props = {
  mobileTestStepData: MobileTestStepData;
};

const BasicTextReplace = ({ mobileTestStepData }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <TestStepDetailBasicDefinition
        term={'Text Replace'}
        description={
          mobileTestStepData.value?.includes('@text') ||
          mobileTestStepData.value?.includes('@label') ||
          mobileTestStepData.optionsDto.secret === 'true' ||
          (mobileTestStepData.command ===
            MobileCommandOptionServerName.ConfirmText &&
            mobileTestStepData.optionsDto.textReplace)
            ? mobileTestStepData.optionsDto.textReplace === 'true'
              ? 'Y'
              : 'N'
            : '-'
        }
      />
      {mobileTestStepData.optionsDto.textReplace === 'true' && (
        <ErrorBoundaryWrapper>
          <TestStepDetailBasicDefinition
            term={'From'}
            description={findTextReplaceFromNameByServerName(
              mobileTestStepData.optionsDto.textReplaceOptions.from,
            )}
          />
          <TestStepDetailBasicDefinition
            term={'Add'}
            description={`${findTextReplaceAddNameByServerName(mobileTestStepData.optionsDto.textReplaceOptions.addKey)} / ${mobileTestStepData.optionsDto.textReplaceOptions.addValue}`}
          />
          <TestStepDetailBasicDefinition
            term={'Date Format'}
            description={
              mobileTestStepData.optionsDto.textReplaceOptions.dateFormat
            }
          />
        </ErrorBoundaryWrapper>
      )}
    </ErrorBoundaryWrapper>
  );
};

export default BasicTextReplace;
