import React from 'react';
import AdminDeviceDetailFormFields from './AdminDeviceDetailFormFields';
import { DeviceType, UpdateAdministrationDevice } from '@customTypes/index';
import { Controller, useFormContext } from 'react-hook-form';
import { ListBoxHeadlessInController } from '@autosquare/common';

const DeviceTypeFields = () => {
  const { control, watch } = useFormContext<UpdateAdministrationDevice>();

  const isEnabled = watch('deviceEnabled');

  return (
    <AdminDeviceDetailFormFields id={'deviceType'} label={'Device Type'}>
      <Controller
        control={control}
        name="deviceType"
        render={({ field }) => (
          <ListBoxHeadlessInController
            value={field.value || ''}
            buttonValue={field.value}
            onChange={field.onChange}
            lists={[{ title: DeviceType.Mobile }, { title: DeviceType.Tablet }]}
            valueToSave={'title'}
            valueToShow={'title'}
            placeholder={'Select Type'}
            disabled={!isEnabled}
            isGrayBackground={!isEnabled}
          />
        )}
      />
    </AdminDeviceDetailFormFields>
  );
};

export default DeviceTypeFields;
