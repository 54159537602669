import { Button } from '@autosquare/common';
import React from 'react';

type Props = {
  setIsEdited: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: () => void;
};

const ActionButtonGroup = ({ setIsEdited, openModal }: Props) => {
  return (
    <div className="shrink-0 border-b border-gray-200 px-4 py-5 sm:px-6">
      <div className="flex justify-end space-x-3">
        <Button
          type="button"
          variant="secondary"
          onClick={() => setIsEdited(true)}
        >
          Edit
        </Button>
        <Button type="button" variant="caution" onClick={openModal}>
          Delete
        </Button>
      </div>
    </div>
  );
};

export default ActionButtonGroup;
