import React from 'react';
import AdminDeviceDetailFormFields from './AdminDeviceDetailFormFields';
import { useFormContext } from 'react-hook-form';
import { UpdateAdministrationDevice } from '@customTypes/index';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';

const DeviceOsFields = () => {
  const { register } = useFormContext<UpdateAdministrationDevice>();

  return (
    <AdminDeviceDetailFormFields id={'deviceOs'} label={'Device OS*'}>
      <div className="relative">
        <input
          className="input-base disabled:bg-gray-100"
          {...register('deviceOs')}
          disabled
        />
        <ChevronUpDownIcon
          className="absolute right-2 top-2 size-5 text-gray-400"
          aria-hidden="true"
        />
      </div>
    </AdminDeviceDetailFormFields>
  );
};

export default DeviceOsFields;
