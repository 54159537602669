import { ErrorBoundaryWrapper } from '@autosquare/common';
import DetailDivisionLine from '@components/shared/TestStepDetail/DetailDivisionLine';
import DivisionWord from '@components/shared/TestStepDetail/DivisionWord';
import TestStepDetailBasicDefinition from '@components/shared/TestStepDetail/TestStepDetailBasicDefinition';

import { WebTestStepData } from '@customTypes/testStep/type';

import { useWebTestCaseListQuery } from '@lib/api/ide/web/webTestCase';
import {
  findKeyboardEventNameFromServerName,
  KeyboardEventServerName,
} from '@utils/static/keyboardEventList';
import {
  findWebCommandNameFromServerName,
  WebCommandOptionServerName,
} from '@utils/static/webCommandOptions';

import React from 'react';

type Props = {
  webTestStepData: WebTestStepData;
};

const IfDetailBasic = ({ webTestStepData }: Props) => {
  const passCommand = webTestStepData?.value.split(',')[0];
  const failCommand = webTestStepData?.value.split(',')[1];

  const webTestCaseQuery = useWebTestCaseListQuery();
  const webTestCaseList = webTestCaseQuery.data;

  const findTitleFromIdx = (idx: number) =>
    webTestCaseList?.find((testCase) => testCase.idx === idx)?.title;

  return (
    <ErrorBoundaryWrapper>
      <DetailDivisionLine
        title={'Condition'}
        description={'조건식을 설정합니다.'}
      />
      <TestStepDetailBasicDefinition
        term={'Command'}
        description={findWebCommandNameFromServerName(
          webTestStepData.optionsDto.conditionCommand,
        )}
      />
      <TestStepDetailBasicDefinition
        term={'Value*'}
        description={webTestStepData.optionsDto.conditionValue}
      />
      <DetailDivisionLine
        title={'Action'}
        description={'조건식의 결과에 따라 실행될 동작을 지정합니다.'}
      />
      <DivisionWord color="green">PASS</DivisionWord>
      <TestStepDetailBasicDefinition
        term={'Command'}
        description={findWebCommandNameFromServerName(
          passCommand as WebCommandOptionServerName,
        )}
      />
      <TestStepDetailBasicDefinition
        term={'Value*'}
        description={
          passCommand === WebCommandOptionServerName.KeyBoardEvent
            ? findKeyboardEventNameFromServerName(
                webTestStepData.optionsDto
                  .passActionValue as KeyboardEventServerName,
              )
            : passCommand === WebCommandOptionServerName.ImportTestCase
              ? findTitleFromIdx(
                  Number(webTestStepData.optionsDto.passActionValue),
                )
              : webTestStepData.optionsDto.passActionValue
        }
      />
      {failCommand !== undefined &&
        webTestStepData.optionsDto?.failActionValue !== undefined && (
          <ErrorBoundaryWrapper>
            <DivisionWord color="red">FAIL</DivisionWord>
            <TestStepDetailBasicDefinition
              term={'Command'}
              description={findWebCommandNameFromServerName(
                failCommand as WebCommandOptionServerName,
              )}
            />
            <TestStepDetailBasicDefinition
              term={'Value*'}
              description={
                failCommand === WebCommandOptionServerName.KeyBoardEvent
                  ? findKeyboardEventNameFromServerName(
                      webTestStepData.optionsDto
                        .failActionValue as KeyboardEventServerName,
                    )
                  : failCommand === WebCommandOptionServerName.ImportTestCase
                    ? findTitleFromIdx(
                        Number(webTestStepData.optionsDto.failActionValue),
                      )
                    : webTestStepData.optionsDto.failActionValue
              }
            />
          </ErrorBoundaryWrapper>
        )}
    </ErrorBoundaryWrapper>
  );
};

export default IfDetailBasic;
