import { Button, ErrorBoundaryWrapper } from '@autosquare/common';
import {
  DeviceAdministrationInfo,
  UpdateAdministrationDevice,
} from '@customTypes/index';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { UseMutationResult } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteDialog from './AdminDeviceDetailFormButtons/DeleteDialog';

type Props = {
  adminDeviceListPage: string;
  updateAdminDeviceStatusMutation: UseMutationResult<
    DeviceAdministrationInfo,
    ServerResponseErrorDetails,
    { idx: number; enabled: boolean }
  >;
  updateAdminDeviceMutation: UseMutationResult<
    DeviceAdministrationInfo,
    ServerResponseErrorDetails,
    UpdateAdministrationDevice
  >;
};

const AdminDeviceDetailFormButtons = ({
  adminDeviceListPage,
  updateAdminDeviceStatusMutation,
  updateAdminDeviceMutation,
}: Props) => {
  const navigate = useNavigate();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <ErrorBoundaryWrapper>
      <div className="flex items-center justify-end gap-3 pt-6">
        <div className="flex items-center justify-end gap-3">
          <Button
            type="button"
            variant="secondary"
            onClick={() => navigate(adminDeviceListPage)}
          >
            Cancel
          </Button>
          <Button
            type="button"
            variant="caution"
            onClick={() => setIsDeleteModalOpen(true)}
          >
            Delete
          </Button>
          <Button
            type="submit"
            disabled={
              updateAdminDeviceStatusMutation.isLoading ||
              updateAdminDeviceMutation.isLoading
            }
          >
            Save
          </Button>
        </div>
        <DeleteDialog
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
        />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default AdminDeviceDetailFormButtons;
