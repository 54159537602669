import { SignupRequest } from '@customTypes/index';
import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { useFormContext } from 'react-hook-form';

type FormFieldProps = {
  title: string;
  name: keyof SignupRequest;
  type: React.InputHTMLAttributes<HTMLInputElement>['type'];
  onChange?: () => void;
  disabled?: boolean;
};

const SignUpFormFields = ({
  title,
  name,
  type,
  onChange,
  disabled = false,
}: FormFieldProps) => {
  const {
    register,
    formState: { errors },
    trigger,
  } = useFormContext<SignupRequest>();

  return (
    <div>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {title}
      </label>
      <div className="mt-2">
        <input
          className="input-base disabled:bg-gray-50"
          type={type}
          {...register(name, {
            onChange: () => {
              onChange && onChange();
              trigger(name);
            },
          })}
          disabled={disabled}
        />
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <p className="error-message pt-2">{message}</p>
        )}
      />
    </div>
  );
};

export default SignUpFormFields;
