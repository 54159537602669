import React, { useContext, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useTestStepValueList } from '@hooks/testStep/useTestStepValueList';

import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';

import { MobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';

import TestStepValueListbox from '@components/IDE/TestStepValueListbox';
import DeviceKeyListBox from '@components/TestStep/DeviceKeyListBox';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { SaveTextFrom } from '@customTypes/ide/mobileTestStep/SaveTextFrom';
import SecretBox from '@components/TestStep/SecretBox';
import { ClickId } from '@utils/static/clickOptions';
import { ErrorMessage } from '@hookform/error-message';
import ImportTestCaseCombobox from '@components/TestStep/ImportTestCaseCombobox';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const TestStepValue = () => {
  const { isListboxOpened, setIsListboxOpened } = useContext(
    MobileTestStepContext,
  );

  const {
    setValue,
    watch,
    control,
    formState: { errors },
  } = useFormContext<CreateTestStepData>();

  const command = watch('command');
  const saveTextFrom = watch('optionsDto.from');
  const clickBy = watch('optionsDto.clickBy');
  const maskingOptionsEnabled =
    watch('optionsDto.maskingOptions.enabled') === 'true';
  const value = watch('value');
  const resetOnClick = () => {
    if (!maskingOptionsEnabled) {
      setValue('value', '');
      setIsListboxOpened(true);
    }
  };

  const { totalValues, bounds, objWithFullXpathMap } = useTestStepValueList({
    command: command,
    valueName: 'value',
  });

  useEffect(() => {
    return () => {
      setValue('value', undefined);
    };
  }, [setValue]);

  useEffect(() => {
    if (
      command === MobileCommandOptionServerName.ImportTestCase ||
      command === MobileCommandOptionServerName.DeviceKey
    ) {
      setValue('optionsDto.elementOptions', undefined);
    } else {
      setValue('optionsDto.elementOptions.bounds', bounds);
    }
    return () => setValue('optionsDto.elementOptions', undefined);
  }, [setValue, bounds, command]);

  const selectElementCondition =
    (command === MobileCommandOptionServerName.SaveText &&
      saveTextFrom === SaveTextFrom.Element) ||
    command === MobileCommandOptionServerName.ConfirmText ||
    command === MobileCommandOptionServerName.InputText ||
    command === MobileCommandOptionServerName.VerifyNoElement;

  const valueTextField = selectElementCondition ? 'Select Element' : 'Value';

  const rulesRequiredMessage = selectElementCondition
    ? 'Element를 선택해주세요.'
    : 'Value를 선택해주세요.';

  useEffect(() => {
    setValue('optionsDto.fullXpath', undefined);
    if (value !== undefined) {
      setValue('optionsDto.fullXpath', objWithFullXpathMap.get(value));
    }
    return () => setValue('optionsDto.fullXpath', undefined);
  }, [value, command]);

  return (
    <ErrorBoundaryWrapper>
      {command === MobileCommandOptionServerName.ImportTestCase ? (
        <ImportTestCaseCombobox />
      ) : command === MobileCommandOptionServerName.DeviceKey ? (
        <DeviceKeyListBox />
      ) : (
        <ErrorBoundaryWrapper>
          <Controller
            control={control}
            name={'value'}
            rules={{ required: rulesRequiredMessage }}
            render={({ field }) => (
              <TestStepValueListbox
                valueText={field.value || valueTextField}
                setValueText={field.onChange}
                totalValues={totalValues}
                isListboxOpened={isListboxOpened}
                setIsListboxOpened={setIsListboxOpened}
                disabled={command === undefined || maskingOptionsEnabled}
                resetOnClick={resetOnClick}
                resetDisabled={maskingOptionsEnabled}
                isReset={true}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={'value'}
            render={({ message }) => (
              <p className="error-message -my-2">{message}</p>
            )}
          />
          {clickBy === ClickId.Default && <SecretBox control={control} />}
        </ErrorBoundaryWrapper>
      )}
    </ErrorBoundaryWrapper>
  );
};

export default TestStepValue;
