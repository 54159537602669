import { DeviceInfoOs } from '@customTypes/ide/device/device';
import React, { useState } from 'react';
import TestStepListTable from './TestStepList/TestStepListTable';
import {
  ArrowSpinner,
  Button,
  ErrorBoundaryWrapper,
  PlatFormTypeServerName,
  TableSkeleton,
} from '@autosquare/common';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import TestExecutionContainer from '@components/TestExecution/TestExecutionContainer';
import { useGetSpeedMobileTestStep } from '@lib/api/ide/speedMobile/speedMobileTestStep';
import TestDataAutomationButton from '@components/TestAutomationSettings/mobile/TestDataAutomationButton';
import { useRemoteMobileTestExecutionMutation } from '@lib/api/ide/remote/remote';
import RemoteTestExecutionAlarmModal from '@components/TestExecution/shared/RemoteTestExecutionAlarmModal';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';

type Props = { platformType: PlatFormTypeServerName };

const TestStepList = ({ platformType }: Props) => {
  const [isTestExecutionOpened, setIsTestExecutionOpened] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExecutionErrorModalOpen, setIsExecutionErrorModalOpen] =
    useState(false);

  const testStepList = [
    { idx: 1, title: DeviceInfoOs.Aos },
    { idx: 2, title: DeviceInfoOs.Ios },
  ];

  const navigate = useNavigate();
  const { search } = useLocation();
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const speedMobileTestStepQuery = useGetSpeedMobileTestStep();
  const speedMobileTestStepList = speedMobileTestStepQuery?.data;

  const remoteMobileStepExecutionMutation =
    useRemoteMobileTestExecutionMutation('case', platformType);

  const isExecuteButtonActive =
    (speedMobileTestStepList?.aosRepeatInfo?.startIdx > 0 &&
      speedMobileTestStepList?.aosRepeatInfo?.endIdx > 0) ||
    (speedMobileTestStepList?.iosRepeatInfo?.startIdx > 0 &&
      speedMobileTestStepList?.iosRepeatInfo?.endIdx > 0);

  return (
    <ErrorBoundaryWrapper>
      <TestDataAutomationButton />
      <section className="px-4 pt-6 sm:px-0">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h2 className="text-base font-semibold leading-6 text-gray-900">
              Test Step List
            </h2>
          </div>
          <div className="relative flex gap-4">
            <Button
              type="button"
              variant="secondary"
              onClick={() => setIsTestExecutionOpened(true)}
              disabled={!isExecuteButtonActive}
            >
              Execute
            </Button>
            <Button
              type="button"
              onClick={() =>
                navigate({
                  pathname: `/ide/projects/${projectIdx}/speed-test/mobile/test-case/device-list`,
                  search: search,
                })
              }
            >
              Add test step
            </Button>
          </div>
        </div>
        <div className="grid grid-flow-col grid-cols-2 gap-4">
          {speedMobileTestStepQuery.isLoading ? (
            <>
              <TableSkeleton />
              <TableSkeleton />
            </>
          ) : (
            testStepList?.map((stepList) => (
              <TestStepListTable key={stepList.idx} title={stepList.title} />
            ))
          )}
        </div>
      </section>
      {isTestExecutionOpened && (
        <TestExecutionContainer
          setState={setIsTestExecutionOpened}
          idx={Number(idx)}
          testType="case"
          deviceType="mobile"
          testLocation="speed-test"
          platformType={platformType}
          setIsModalOpen={setIsModalOpen}
          remoteMobileExecutionMutation={remoteMobileStepExecutionMutation}
          setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
        />
      )}
      {remoteMobileStepExecutionMutation.isLoading && <ArrowSpinner />}
      <RemoteTestExecutionAlarmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isExecutionErrorModalOpen={isExecutionErrorModalOpen}
        setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
      />
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default TestStepList;
