import React, { useState } from 'react';
import {
  ErrorMessage,
  TableNoList,
  TableSkeleton,
  TableWrapper,
  useCheckbox,
  useTableHeaderLengthStore,
} from '@autosquare/common';
import {
  useDeleteProjectAppListMutation,
  useGetProjectAppListQuery,
} from '@lib/api/dashboard/project/project-settings/application/application';
import dayjs from 'dayjs';
import ApplicationTableBody from './ApplicationTableBody';
import ApplicationListTableHeader from './ApplicationListTable/ApplicationListTableHeader';
import {
  CommonDialog,
  DialogButton,
  DialogButtonPanels,
} from '@components/Dialog';
import DeleteButtonCommonDialog from '@components/shared/Dialog/DeleteButtonCommonDialog';

const ApplicationListTable = () => {
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const projectAppListQuery = useGetProjectAppListQuery();
  const applicationList = projectAppListQuery?.data;
  const { length } = useTableHeaderLengthStore();

  const {
    openDialog,
    selectAllArray,
    selectedArray,
    selectArray,
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    resetSelectedArray,
  } = useCheckbox(applicationList, 'idx');

  const selectedCount = selectedArray?.length;
  const totalCount = applicationList?.length;
  const disabledOption =
    projectAppListQuery.isLoading ||
    projectAppListQuery.isFetching ||
    projectAppListQuery.isError;

  const deleteProjectAppListMutation = useDeleteProjectAppListMutation();

  const onClose = () => {
    resetSelectedArray();
    setIsErrorDialogOpen(false);
    projectAppListQuery.refetch();
  };

  const onDialogDeleteButtonHandler = () => {
    deleteProjectAppListMutation.mutate(selectedArray, {
      onSuccess: () => {
        projectAppListQuery.refetch();
      },
      onSettled: () => {
        setIsOpenedCautionDialog(false);
        resetSelectedArray();
      },
    });
  };

  return (
    <>
      <TableWrapper openDialog={openDialog} selectedArray={selectedArray}>
        <ApplicationListTableHeader
          selectedArray={selectedArray}
          selectAllArray={selectAllArray}
          selectedCount={selectedCount}
          totalCount={totalCount}
          disabledOption={disabledOption}
        />
        <tbody className="divide-y divide-gray-200">
          {projectAppListQuery.isLoading ? (
            <TableNoList colSpan={length}>
              <TableSkeleton />
            </TableNoList>
          ) : projectAppListQuery.isError ? (
            <ErrorMessage>{projectAppListQuery.error.message}</ErrorMessage>
          ) : applicationList.length === 0 ? (
            <TableNoList colSpan={length}>
              등록된 애플리케이션이 존재하지 않습니다.
            </TableNoList>
          ) : (
            applicationList
              .sort(
                (a, b) =>
                  dayjs(b.updatedAt).valueOf() - dayjs(a.updatedAt).valueOf(),
              )
              .map((info, index) => (
                <ApplicationTableBody
                  key={info.idx}
                  selectArray={selectArray}
                  selectedArray={selectedArray}
                  info={info}
                  index={index}
                />
              ))
          )}
        </tbody>
      </TableWrapper>
      <DeleteButtonCommonDialog
        title="Delete Application"
        subTitle="해당 애플리케이션을 삭제하시겠습니까?"
        isOpen={isOpenedCautionDialog}
        setIsOpen={setIsOpenedCautionDialog}
        onDeleteHandler={onDialogDeleteButtonHandler}
      />
      <CommonDialog
        isOpen={isErrorDialogOpen}
        setIsOpen={setIsErrorDialogOpen}
        iconType={'caution'}
        title={'Failure'}
        subTitle={
          deleteProjectAppListMutation.isError &&
          deleteProjectAppListMutation.error.message
        }
        onClose={onClose}
      >
        <DialogButtonPanels>
          <DialogButton variant="primary" type="button" onClick={onClose}>
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
    </>
  );
};

export default ApplicationListTable;
