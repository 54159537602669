import { CommonDialog } from '@components/Dialog';
import { RESET_DELAY_MS } from '@constants/index';
import { useDeleteAdminDeviceMutation } from '@lib/api/dashboard/queryHooks';
import React from 'react';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedArray: number[];
  closeDialog: () => void;
  resetSelectedArray: () => void;
};

const DeleteDeviceDialog = ({
  isOpen,
  setIsOpen,
  selectedArray,
  closeDialog,
  resetSelectedArray,
}: Props) => {
  const deleteAdminDeviceMutation = useDeleteAdminDeviceMutation();

  const deleteDevice = () => {
    deleteAdminDeviceMutation.mutate(selectedArray, {
      onSuccess: () => {
        resetSelectedArray();
        closeDialog();
      },
    });
  };

  const onClose = () => {
    if (deleteAdminDeviceMutation.isError) {
      setTimeout(() => {
        deleteAdminDeviceMutation.reset();
      }, RESET_DELAY_MS);
    }
    closeDialog();
  };

  return (
    <CommonDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      iconType={'caution'}
      title={deleteAdminDeviceMutation.isError ? 'Error' : 'Delete Device'}
      subTitle={
        deleteAdminDeviceMutation.isError
          ? deleteAdminDeviceMutation.error.message
          : '해당 디바이스를 삭제하시겠습니까?'
      }
      onClose={onClose}
    >
      <CommonDialog.DialogButtonPanels>
        {!deleteAdminDeviceMutation.isError && (
          <CommonDialog.DialogButton
            variant="caution"
            type="button"
            onClick={deleteDevice}
            disabled={deleteAdminDeviceMutation.isLoading}
          >
            Delete
          </CommonDialog.DialogButton>
        )}
        <CommonDialog.DialogButton
          variant="secondary"
          type="button"
          onClick={onClose}
        >
          Cancel
        </CommonDialog.DialogButton>
      </CommonDialog.DialogButtonPanels>
    </CommonDialog>
  );
};

export default DeleteDeviceDialog;
