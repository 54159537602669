import React, { useState } from 'react';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import {
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
} from '@autosquare/common';
import { PlusIcon } from '@heroicons/react/20/solid';
import IdeAutoRegisterModal from '@components/IdeAutoRegisterCreateEditModal/IdeAutoRegisterModal';
import {
  AutoRegisterInfoData,
  CreateSchedulerData,
} from '@customTypes/ide/scheduler/scheduler';
import IdeAutoRegisterList from '@components/IdeAutoRegisterList/IdeAutoRegisterList';
import { useFieldArray, useFormContext } from 'react-hook-form';
import IdeAutoRegisterEditModal from '@components/IdeAutoRegisterCreateEditModal/IdeAutoRegisterEditModal';

type Props = {
  autoRegistersList: AutoRegisterInfoData[];
  setAutoRegistersList: React.Dispatch<
    React.SetStateAction<AutoRegisterInfoData[]>
  >;
};

const CreateAutoRegister = ({
  autoRegistersList,
  setAutoRegistersList,
}: Props) => {
  const { control, resetField } = useFormContext<CreateSchedulerData>();
  const { fields, append, remove, update } = useFieldArray<
    CreateSchedulerData,
    'autoRegisters'
  >({
    control,
    name: 'autoRegisters',
  });

  const [isAddClicked, setIsAddClicked] = useState(false);
  const [index, setIndex] = useState<number>(null);

  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [openCaution, setOpenCaution] = useState(false);

  const deleteButtonHandler = () => {
    const removeList = autoRegistersList.filter((_, idx) => idx !== index);
    setAutoRegistersList(removeList);
    remove(index);
    setOpenCaution(false);
  };

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer
        title="AutoRegister"
        tooltipClass="bottom-6 -right-32"
        tooltipWord="테스트 결과를 자동 등록할 플랫폼을 선택해 주세요."
      >
        <div className="flex items-center gap-x-5">
          {autoRegistersList.length > 0 && (
            <IdeAutoRegisterList
              autoDataRegisterList={autoRegistersList}
              setIndex={setIndex}
              setIsEditModalOpened={setIsEditModalOpened}
              setOpenCaution={setOpenCaution}
            />
          )}
          <Button
            type="button"
            variant="imageOriginal"
            buttonSize="none"
            className="flex items-center justify-center"
            onClick={() => {
              resetField('autoRegisters');
              append({ platformName: '' });
              setIsAddClicked(true);
            }}
          >
            <PlusIcon className="size-6" />
            <p className="text-sm font-normal text-gray-900">Add</p>
          </Button>
          {isAddClicked && (
            <IdeAutoRegisterModal
              isAddClicked={isAddClicked}
              setIsAddClicked={setIsAddClicked}
              autoRegistersList={autoRegistersList}
              setAutoRegistersList={setAutoRegistersList}
              fields={fields}
              update={update}
              remove={remove}
            />
          )}
        </div>
        <DoubleCheckDialogHeadlessUI
          isOpened={openCaution}
          setIsOpened={setOpenCaution}
          type={'caution'}
          title={'Delete Platform'}
          subTitle={
            '삭제하시면 해당 플랫폼에 테스트 결과 자동 등록이 불가능 합니다.'
          }
          buttonChildren={
            <>
              <Button
                type="button"
                variant={'caution'}
                onClick={deleteButtonHandler}
              >
                Delete
              </Button>
              <Button
                type="button"
                variant={'secondary'}
                onClick={() => setOpenCaution(false)}
              >
                Cancel
              </Button>
            </>
          }
        />
        {isEditModalOpened && (
          <IdeAutoRegisterEditModal
            index={index}
            update={update}
            selectedData={autoRegistersList?.[index]}
            autoRegistersList={autoRegistersList}
            setAutoRegisterList={setAutoRegistersList}
            isEditModalOpened={isEditModalOpened}
            setIsEditModalOpened={setIsEditModalOpened}
          />
        )}
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default CreateAutoRegister;
