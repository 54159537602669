import { useAdminDeviceDetailQuery } from '@lib/api/dashboard/queryHooks';
import React from 'react';

const AdminDeviceInformation = () => {
  const { data: adminDeviceDetail } = useAdminDeviceDetailQuery();

  return (
    <div className="flex flex-col gap-1 py-4">
      <div className="flex items-center justify-between">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Device Information
        </h2>
        <div className="text-sm font-medium leading-tight text-gray-500">
          {adminDeviceDetail?.modifiedAt &&
            `Updated at ${adminDeviceDetail?.modifiedAt}`}
        </div>
      </div>
      <p className="text-sm font-normal leading-normal text-gray-600">
        등록할 디바이스의 정보를 입력해 주세요.
      </p>
    </div>
  );
};

export default AdminDeviceInformation;
