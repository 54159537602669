import React from 'react';

type Props = {
  stepNumber: 1 | 2 | 3 | 4;
};

const TestCaseImportStep = ({ stepNumber }: Props) => {
  const steps = [
    {
      id: 'Step 1',
      name: 'Upload File',
      status: stepNumber === 1 ? 'current' : 'complete',
    },
    {
      id: 'Step 2',
      name: 'Preview',
      status:
        stepNumber === 1
          ? 'upcoming'
          : stepNumber === 2
            ? 'current'
            : 'complete',
    },
    {
      id: 'Step 3',
      name: 'Import Test Case',
      status:
        stepNumber === 4
          ? 'complete'
          : stepNumber === 3
            ? 'current'
            : 'upcoming',
    },
  ];

  return (
    <nav aria-label="Progress">
      <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {steps.map((step) => (
          <li key={step.name} className="md:flex-1">
            <div
              className={`group flex flex-col border-l-4 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 ${
                step.status === 'complete'
                  ? 'border-indigo-800'
                  : step.status === 'current'
                    ? 'border-indigo-600'
                    : 'border-gray-300'
              }`}
            >
              <span
                className={`text-sm font-medium ${
                  step.status === 'complete'
                    ? 'text-indigo-800'
                    : step.status === 'current'
                      ? 'text-indigo-600'
                      : 'text-gray-700'
                }`}
              >
                {step.id}
              </span>
              <span className="text-sm font-medium">{step.name}</span>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default TestCaseImportStep;
