import {
  AnimateLayoutChanges,
  defaultAnimateLayoutChanges,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export const useSortableCard = (id: number, disabled?: boolean) => {
  const animateLayoutChanges: AnimateLayoutChanges = (args) =>
    defaultAnimateLayoutChanges({ ...args, wasDragging: true });

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ animateLayoutChanges, id: id, disabled: disabled });

  const sortableStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    marginBottom: 5,
    marginTop: 8,
    opacity: isDragging ? 0.5 : 1,
  };
  return {
    attributes,
    listeners,
    setNodeRef,
    sortableStyle,
    isDragging,
  };
};
