export const adminKeys = {
  all: ['admin'] as const,
  userLists: () => [...adminKeys.all, 'list'] as const,
  userDetails: () => [...adminKeys.all, 'detail'] as const,
  userDetail: (idx: string) => [...adminKeys.userDetails(), idx] as const,
  projectLists: () => [...adminKeys.all, 'projectList'] as const,
  projectDetails: () => [...adminKeys.all, 'projectDetail'] as const,
  projectDetail: (idx: string) => [...adminKeys.projectDetails(), idx] as const,
  systemSettings: () => [...adminKeys.all, 'systemSettings'] as const,
  settings: (key: string) => [...adminKeys.systemSettings(), key] as const,
  webdriverLists: () => [...adminKeys.all, 'list'] as const,
  messengers: () => [...adminKeys.all, 'messenger'] as const,
  device: () => [...adminKeys.all, 'devices'] as const,
};
