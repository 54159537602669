import { ErrorBoundaryWrapper } from '@autosquare/common';
import React from 'react';
import ReserveDeviceHeader from './components/ReserveDeviceHeader';
import ReserveDeviceForm from './components/ReserveDeviceForm';

const ReserveDevice = () => {
  return (
    <ErrorBoundaryWrapper>
      <ReserveDeviceHeader />
      <ReserveDeviceForm />
    </ErrorBoundaryWrapper>
  );
};

export default ReserveDevice;
