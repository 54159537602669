import { API } from '@api/endpoints';
import {
  AddAdministrationDevice,
  DeviceAdministrationInfo,
  DeviceApprovalInfo,
  UpdateAdministrationDevice,
  UpdateDeviceApproval,
} from '@customTypes/index';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { adminDevicesKeys, projectDeviceKeys } from '@queries/index';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { axiosInstance } from '../..';

// GET
// admin device list 전체 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Device%20Administration/getDeviceALlList
const getAdminDeviceList = async (): Promise<DeviceAdministrationInfo[]> => {
  const response = await axiosInstance.get(API.ADMIN_DEVICES);
  return response.data;
};
export const useAdminDeviceListQuery = () => {
  return useQuery<DeviceAdministrationInfo[], ServerResponseErrorDetails>({
    queryKey: adminDevicesKeys.lists(),
    queryFn: () => getAdminDeviceList(),
  });
};

// admin device detail 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Device%20Administration/getDeviceDetail
const getAdminDeviceDetail = async (
  idx: string,
): Promise<DeviceAdministrationInfo> => {
  const response = await axiosInstance.get(`${API.ADMIN_DEVICES}/${idx}`);
  return response.data;
};
export const useAdminDeviceDetailQuery = () => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  return useQuery<DeviceAdministrationInfo, ServerResponseErrorDetails>({
    queryKey: adminDevicesKeys.detail(idx),
    queryFn: () => getAdminDeviceDetail(idx),
  });
};

// Admin Device Approval 목록
// https://dev.t-square.co.kr/swagger-ui/index.html#/Device%20Approval%20Management/getApprovalPendingList
const getAdminDeviceApprovalList = async (): Promise<DeviceApprovalInfo[]> => {
  const response = await axiosInstance.get(`${API.ADMIN_DEVICES}/approval`);
  return response.data;
};
export const useAdminDeviceApprovalListQuery = () => {
  return useQuery<DeviceApprovalInfo[], ServerResponseErrorDetails>({
    queryKey: adminDevicesKeys.approval(),
    queryFn: () => getAdminDeviceApprovalList(),
  });
};

// POST
// https://dev.t-square.co.kr/swagger-ui/index.html#/Device%20Administration/saveDeviceAdministration
export const createAdminDevice = async (
  data: AddAdministrationDevice,
): Promise<DeviceAdministrationInfo> => {
  const response = await axiosInstance.post(API.ADMIN_DEVICES, data);
  return response.data;
};
export const useCreateAdminDeviceMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation<
    DeviceAdministrationInfo,
    ServerResponseErrorDetails,
    AddAdministrationDevice
  >({
    mutationFn: (data) => createAdminDevice(data),
    onSuccess: () => {
      queryClient.invalidateQueries(adminDevicesKeys.all);
      navigate(`/admin/devices`);
    },
  });
};

// PUT
// update admin device detail
// https://dev.t-square.co.kr/swagger-ui/index.html#/Device%20Administration/updateDeviceAdministration
const updateAdminDevicesDetail = async (
  idx: string,
  data: UpdateAdministrationDevice,
): Promise<DeviceAdministrationInfo> => {
  const response = await axiosInstance.put(`${API.ADMIN_DEVICES}/${idx}`, data);
  return response.data;
};
export const useUpdateAdminDeviceMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  return useMutation<
    DeviceAdministrationInfo,
    ServerResponseErrorDetails,
    UpdateAdministrationDevice
  >({
    mutationFn: (data) => updateAdminDevicesDetail(idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(adminDevicesKeys.all);
      navigate('/admin/devices');
    },
  });
};

// 디바이스의 활성화 여부를 변경
// https://dev.t-square.co.kr/swagger-ui/index.html#/Device%20Administration/updateDeviceAdministration_1
const updateAdminDeviceStatus = async (data: {
  idx: number;
  enabled: boolean;
}): Promise<DeviceAdministrationInfo> => {
  const response = await axiosInstance.put(
    `${API.ADMIN_DEVICES}/${data.idx}/${data.enabled}`,
  );
  return response.data;
};
export const useUpdateAdminDeviceStatusMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    DeviceAdministrationInfo,
    ServerResponseErrorDetails,
    { idx: number; enabled: boolean }
  >({
    mutationFn: (data) => updateAdminDeviceStatus(data),
    onSuccess: (response) => {
      queryClient.invalidateQueries(adminDevicesKeys.lists());
      if (!isWordInLocationPathname('/detail')) {
        queryClient.invalidateQueries(
          adminDevicesKeys.detail(response.idx.toString()),
        );
        queryClient.invalidateQueries(projectDeviceKeys.lists());
        queryClient.invalidateQueries(
          projectDeviceKeys.detail(response.idx.toString()),
        );
      }
    },
  });
};

// Admin Device 승인 검토 결과 저장
// https://dev.t-square.co.kr/swagger-ui/index.html#/Device%20Approval%20Management/updateDeviceAdministration_2
const updateAdminDeviceApproval = async (
  data: UpdateDeviceApproval,
): Promise<unknown> => {
  const response = await axiosInstance.put(
    `${API.ADMIN_DEVICES_APPROVAL}`,
    data,
  );
  return response.data;
};
export const useUpdateAdminDeviceApprovalMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, ServerResponseErrorDetails, UpdateDeviceApproval>(
    {
      mutationFn: (data) => updateAdminDeviceApproval(data),
      onSuccess: () => {
        queryClient.invalidateQueries(adminDevicesKeys.all);
      },
    },
  );
};

// DELETE
// https://dev.t-square.co.kr/swagger-ui/index.html#/Device%20Administration/deleteDeviceAdministration
const deleteAdminDevices = async (
  data: number[],
): Promise<DeviceAdministrationInfo> => {
  const response = await axiosInstance.delete(`${API.ADMIN_DEVICES}`, {
    data: { idxList: data },
  });
  return response.data;
};
export const useDeleteAdminDeviceMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { pathname } = useLocation();

  return useMutation<
    DeviceAdministrationInfo,
    ServerResponseErrorDetails,
    number[]
  >({
    mutationFn: (data) => deleteAdminDevices(data),
    onSuccess: () => {
      if (pathname.includes('/detail')) {
        queryClient.invalidateQueries(adminDevicesKeys.lists());
        navigate('/admin/devices');
      } else {
        queryClient.invalidateQueries(adminDevicesKeys.all);
      }
    },
  });
};
