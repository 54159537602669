import { ErrorMessage } from '@autosquare/common';
import { DescriptionListSkeleton } from '@autosquare/common';
import { useProjectMemberDetailQuery } from '@lib/api/dashboard/queryHooks';
import React from 'react';
import MemberInformationDescriptionForm from './MemberInformationDescription/MemberInformationDescriptionForm';

const MemberInformationDescription = () => {
  const projectMemberDetailQuery = useProjectMemberDetailQuery();

  if (
    projectMemberDetailQuery.isLoading ||
    projectMemberDetailQuery.isFetching
  ) {
    return (
      <DescriptionListSkeleton
        number={7}
        isLine={false}
        outPadding={'py-2.5'}
      />
    );
  }

  if (projectMemberDetailQuery.isError) {
    return (
      <ErrorMessage>{projectMemberDetailQuery.error.message}</ErrorMessage>
    );
  }

  return <MemberInformationDescriptionForm />;
};

export default MemberInformationDescription;
