import React, { useState } from 'react';
import {
  ArrowSpinner,
  Button,
  ErrorBoundaryWrapper,
  ErrorMessage,
} from '@autosquare/common';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import { ArrowPathIcon, WifiIcon } from '@heroicons/react/24/outline';
import RemoteIDE from './RemoteStartSettingDialog/RemoteIDE';
import { RemoteControl } from '@customTypes/index';
import { useDispatch, useSelector } from 'react-redux';
import {
  useConnectRemoteControlMutation,
  useDisconnectRemoteControlMutation,
} from '@lib/api/ide/remote/remote';
import { RootState } from '@app/store';
import {
  deleteRemoteInfo,
  getRemoteInfo,
} from '@store/ide/remote/remoteControlSlice';
import { useGetRemoteListAndIndex } from '@utils/remoteControlListOrder';
import { useNavigate } from 'react-router-dom';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';
import {
  completedConnecting,
  openErrorModal,
  startConnecting,
} from '@store/ide/remote/remoteConnectionStatusSlice';
import { closeModal } from '@store/ide/remote/remoteSettingsModalSlice';

type Props = {
  projectListQueryLoading?: boolean;
};

const RemoteStartSettingDialog = ({ projectListQueryLoading }: Props) => {
  const [remoteControlInfo, setRemoteControlInfo] = useState<
    RemoteControl | undefined
  >(undefined);

  const remotePCInfo = useSelector(
    (state: RootState) => state.remoteControl.remoteInfo,
  );
  const isOpen = useSelector(
    (state: RootState) => state.remoteSettingsModal.isRemoteSettingOpen,
  );

  const connectRemoteControlMutation = useConnectRemoteControlMutation();
  const disconnectRemoteControlMutation = useDisconnectRemoteControlMutation();
  const isRemoteConnectionNeeded = remotePCInfo === undefined;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { remoteIDEListQuery, remoteIDEList, sortedRemoteIDEList, validIndex } =
    useGetRemoteListAndIndex(isOpen, remotePCInfo?.idx);

  return (
    <ErrorBoundaryWrapper>
      <Dialog
        open={isOpen}
        onClose={() => {
          if (!isRemoteConnectionNeeded) {
            connectRemoteControlMutation.reset();
            remoteIDEListQuery.refetch();
            dispatch(closeModal());
          }
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4 backdrop-brightness-75">
          <DialogPanel className="w-[32rem] space-y-4 rounded-lg border border-gray-300 bg-white p-6">
            <DialogTitle className="flex items-center gap-4">
              <div className="flex size-10 items-center justify-center rounded-full bg-[#c7ddff]">
                <WifiIcon className="size-6" strokeWidth={1.75} />
              </div>
              <p className="text-center text-base font-semibold leading-normal tracking-tight text-gray-900">
                Remote Start Settings
              </p>
            </DialogTitle>
            <div className="flex w-full items-center justify-between">
              <h3 className="text-sm font-medium leading-normal text-gray-900">
                Remote Control PC List
              </h3>
              <Button
                type="button"
                variant="black"
                className="flex items-center gap-1.5"
                onClick={() => remoteIDEListQuery.refetch()}
              >
                <ArrowPathIcon className="size-5" strokeWidth={2} />
                <span>Refresh</span>
              </Button>
            </div>
            <ul className="gray-scrollbar max-h-[23.5rem] w-full space-y-4 rounded-md border border-gray-300 p-3">
              {remoteIDEListQuery.isLoading || remoteIDEListQuery.isFetching ? (
                Array.from({ length: 5 }, () => (
                  <div
                    key={crypto.randomUUID()}
                    className="flex h-5 w-full items-center gap-3 bg-white"
                  >
                    <input
                      type="radio"
                      className="input-radio-congress-blue"
                      disabled
                    />
                    <div className="h-5 w-60 animate-pulse rounded bg-gray-200 dark:bg-gray-700" />
                    <PencilSquareIcon className="size-5" />
                  </div>
                ))
              ) : remoteIDEListQuery.isError ? (
                <ErrorMessage>{remoteIDEListQuery.error.message}</ErrorMessage>
              ) : remoteIDEList.length === 0 ? (
                <li className="text-center text-sm font-medium leading-loose tracking-tight text-black">
                  검색된 결과가 없습니다.
                </li>
              ) : (
                sortedRemoteIDEList.map((remoteIDE, index) => (
                  <RemoteIDE
                    key={remoteIDE.idx}
                    remoteIDE={remoteIDE}
                    index={index}
                    setRemoteControlInfo={setRemoteControlInfo}
                    validIndex={validIndex}
                  />
                ))
              )}
            </ul>
            {connectRemoteControlMutation.isError && (
              <ErrorMessage>
                {connectRemoteControlMutation.error.message}
              </ErrorMessage>
            )}
            <div className="flex items-center justify-end gap-4">
              {!isRemoteConnectionNeeded && (
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    connectRemoteControlMutation.reset();
                    dispatch(closeModal());
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button
                type="button"
                variant="primary"
                onClick={() => {
                  if (
                    !isRemoteConnectionNeeded &&
                    remoteControlInfo?.idx !== remotePCInfo?.idx
                  ) {
                    dispatch(startConnecting());
                    disconnectRemoteControlMutation.mutate(remotePCInfo, {
                      onSuccess: () => {
                        connectRemoteControlMutation.mutate(remoteControlInfo, {
                          onSuccess: () => {
                            dispatch(closeModal());
                            dispatch(deleteRemoteInfo());
                            dispatch(getRemoteInfo(remoteControlInfo));
                            remoteIDEListQuery.refetch();
                            dispatch(completedConnecting());
                            navigate(`/ide/projects/list`);
                          },
                        });
                      },
                      onError: () => {
                        dispatch(openErrorModal());
                      },
                    });
                  }
                  if (isRemoteConnectionNeeded) {
                    connectRemoteControlMutation.mutate(remoteControlInfo, {
                      onSuccess: () => {
                        dispatch(closeModal());
                        dispatch(getRemoteInfo(remoteControlInfo));
                        remoteIDEListQuery.refetch();
                      },
                      onError: () => {
                        dispatch(openErrorModal());
                      },
                    });
                  }
                  if (remoteControlInfo?.idx === remotePCInfo?.idx) {
                    dispatch(closeModal());
                    return;
                  }
                }}
                disabled={
                  connectRemoteControlMutation.isLoading ||
                  disconnectRemoteControlMutation.isLoading ||
                  projectListQueryLoading ||
                  remoteIDEListQuery.isLoading ||
                  remoteIDEListQuery.isFetching ||
                  remoteIDEList?.length === 0
                }
              >
                Connect
              </Button>
            </div>
          </DialogPanel>
        </div>
        {(connectRemoteControlMutation.isLoading ||
          disconnectRemoteControlMutation.isLoading ||
          projectListQueryLoading) && <ArrowSpinner />}
      </Dialog>
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default RemoteStartSettingDialog;
