import { ClipBoardButton, ErrorBoundaryWrapper } from '@autosquare/common';

import DescriptionData from '@components/IDE/WebTestStep/DescriptionData';

import {
  findWebCommandNameFromServerName,
  WebCommandOptionServerName,
} from '@utils/static/webCommandOptions';

import { WebTestStepData } from '@customTypes/testStep/type';

import { useWebTestCaseListQuery } from '@lib/api/ide/web/webTestCase';

import React from 'react';
import { findTitleFromIdxByTestCase } from '@utils/arrayUtils';
import {
  findKeyboardEventNameFromServerName,
  KeyboardEventServerName,
} from '@utils/static/keyboardEventList';
import { MaskingOptionsType } from '@customTypes/ide/mobileTestStep/mobileTestStep';

type Props = {
  allWebTestStepList: WebTestStepData;
  setIsCopied: React.Dispatch<React.SetStateAction<boolean>>;
};

const IfDetail = ({ allWebTestStepList, setIsCopied }: Props) => {
  const webTestCaseListQuery = useWebTestCaseListQuery();
  const webTestCaseList = webTestCaseListQuery.data;

  const value = allWebTestStepList.value;
  const splitValue = value.split(',');

  const actionCommand = splitValue[0] as WebCommandOptionServerName;
  const failCommand =
    splitValue.length === 2 && (splitValue[1] as WebCommandOptionServerName);

  const passActionValue = allWebTestStepList.optionsDto.passActionValue;
  const failActionValue = allWebTestStepList.optionsDto?.failActionValue;

  const filteredValue = (
    command: WebCommandOptionServerName,
    value: string,
  ) => {
    return command === WebCommandOptionServerName.ImportTestCase
      ? findTitleFromIdxByTestCase(Number(value), webTestCaseList)
      : command === WebCommandOptionServerName.KeyBoardEvent
        ? findKeyboardEventNameFromServerName(
            value as unknown as KeyboardEventServerName,
          )
        : value;
  };

  const handleCopy = (values: string) => {
    window.navigator.clipboard.writeText(values);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <ErrorBoundaryWrapper>
      <DescriptionData title={'Condition'} />
      <DescriptionData
        title={'Command'}
        data={findWebCommandNameFromServerName(
          allWebTestStepList.optionsDto.conditionCommand,
        )}
      />
      <DescriptionData
        title={'Value'}
        data={
          <>
            {allWebTestStepList.optionsDto.conditionValue}
            <ClipBoardButton
              onClick={() =>
                handleCopy(allWebTestStepList.optionsDto.conditionValue)
              }
            />
          </>
        }
      />
      <div className="grid grid-cols-3 px-3 py-2 odd:bg-gray-50 even:bg-white">
        <div className="text-xs font-medium leading-6 text-green-500">
          Action
        </div>
      </div>
      <DescriptionData
        title={'Command'}
        data={findWebCommandNameFromServerName(actionCommand)}
      />
      <DescriptionData
        title={'Value'}
        data={
          <>
            {filteredValue(actionCommand, passActionValue)}
            {
              <ClipBoardButton
                onClick={() =>
                  handleCopy(filteredValue(actionCommand, passActionValue))
                }
              />
            }
          </>
        }
      />
      {failCommand && (
        <ErrorBoundaryWrapper>
          <div className="grid grid-cols-3 px-3 py-2 odd:bg-gray-50 even:bg-white">
            <div className="text-xs font-medium leading-6 text-red-500">
              Fail
            </div>
          </div>
          <DescriptionData
            title={'Command'}
            data={findWebCommandNameFromServerName(failCommand)}
          />
          <DescriptionData
            title={'Value'}
            data={
              <>
                {filteredValue(failCommand, failActionValue)}
                {
                  <ClipBoardButton
                    onClick={() =>
                      handleCopy(filteredValue(failCommand, failActionValue))
                    }
                  />
                }
              </>
            }
          />
        </ErrorBoundaryWrapper>
      )}
      <DescriptionData
        title={'Masking Options'}
        data={
          allWebTestStepList.optionsDto.maskingOptions.enabled
            ? `Y / ${allWebTestStepList.optionsDto.maskingOptions?.type === MaskingOptionsType.Element ? '직접 선택' : '전체 화면'}`
            : 'N'
        }
      />
    </ErrorBoundaryWrapper>
  );
};

export default IfDetail;
