import {
  Button,
  ErrorBoundaryWrapper,
  ErrorMessage,
  MailReceiver,
} from '@autosquare/common';

import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import SchedulerDetailStatus from './SchedulerDetailForm/SchedulerDetailStatus';
import SchedulerDetailTestType from './SchedulerDetailForm/SchedulerDetailTestType';
import SchedulerDetailScenario from './SchedulerDetailForm/SchedulerDetailScenario';
import SchedulerDetailDevice from './SchedulerDetailForm/SchedulerDetailDevice';
import SchedulerDetailSchedule from './SchedulerDetailForm/SchedulerDetailSchedule';
import SchedulerDetailEmailAlarm from './SchedulerDetailForm/SchedulerDetailEmailAlarm';
import SchedulerDetailTitle from './SchedulerDetailForm/SchedulerDetailTitle';

import { RootState } from '@app/store';

import {
  useRemoteUpdateSchedulerMutation,
  useRemoteUpdateSchedulerStatusMutation,
  useUpdateSchedulerMutation,
  useUpdateSchedulerStatusMutation,
} from '@lib/api/ide/scheduler/scheduler';

import { startTime } from '@utils/static/startTime';

import {
  AutoRegisterInfoData,
  SchedulerDetailData,
  SchedulerTypeServerName,
  UpdateSchedulerData,
  UpdateSchedulerSchema,
} from '@customTypes/ide/scheduler/scheduler';
import { ConnectionType, DeviceInfoOs } from '@customTypes/ide/device/device';
import { SupportedBrowserList } from '@customTypes/ide/browser/browser';
import { zodResolver } from '@hookform/resolvers/zod';
import SchedulerDetailMessengerAlarm from './SchedulerDetailForm/SchedulerDetailMessengerAlarm';
import { useSchedulerMessengerAlarm } from '@hooks/scheduler/useSchedulerMessengerAlarm';
import SchedulerDetailTestResultSaveOptions from './SchedulerDetailForm/SchedulerDetailTestResultSaveOptions';
import { useGetAutoRegistersInfoQuery } from '@lib/api/ide/autoRegisters/autoRegisters';
import SchedulerDetailAutoRegister from './SchedulerDetailForm/SchedulerDetailAutoRegister';
import { Mode } from '@customTypes/ide/remote/type';
import { getRemoteState } from '@utils/static/getConfiguration';
import SchedulerDetailDeviceReboot from './SchedulerDetailForm/SchedulerDetailDeviceReboot';

interface Props {
  schedulerDetailData: SchedulerDetailData;
}

const SchedulerDetailForm = ({ schedulerDetailData }: Props) => {
  const navigate = useNavigate();
  const { projectIdx } = useParams();

  const [isRunning, setIsRunning] = useState<boolean>(
    schedulerDetailData.running,
  );

  const [emailAddressLists, setEmailAddressLists] = useState<MailReceiver[]>(
    schedulerDetailData.mailReceivers,
  );

  const [autoRegistersList, setAutoRegistersList] = useState<
    AutoRegisterInfoData[]
  >(schedulerDetailData.autoRegisters);

  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  const environmentSplitValue = (index: number) =>
    schedulerDetailData.environment.split('/')[index];
  const methods = useForm<UpdateSchedulerData>({
    defaultValues: {
      scheduleIdx: schedulerDetailData.idx,
      title: schedulerDetailData.title,
      scenarioIdxList: schedulerDetailData.scenarioIdxList,
      infos:
        schedulerDetailData.type === SchedulerTypeServerName.Mobile
          ? {
              udid: environmentSplitValue(0),
              model: environmentSplitValue(1),
              os: environmentSplitValue(2) as DeviceInfoOs,
              connectionType: environmentSplitValue(3) as ConnectionType,
              serialNumber: environmentSplitValue(0),
            }
          : {
              browserName: environmentSplitValue(0) as SupportedBrowserList,
              version: environmentSplitValue(1),
            },
      startTime: startTime(new Date(schedulerDetailData.startTime)),
      cronCycle: schedulerDetailData.cycle,
      autoRegister: schedulerDetailData.autoRegister,
      mailReceivers: schedulerDetailData.mailReceivers,
      messengerReceivers: schedulerDetailData.messengerReceivers ?? [],
      saveVideo:
        schedulerDetailData.type === SchedulerTypeServerName.Mobile
          ? schedulerDetailData.saveVideo
          : undefined,
      saveScreenshot: schedulerDetailData.saveScreenshot,
      saveLog:
        schedulerDetailData.type === SchedulerTypeServerName.Mobile
          ? schedulerDetailData.saveLog
          : undefined,
      deviceReboot: schedulerDetailData.deviceReboot,
    },
    resolver: zodResolver(UpdateSchedulerSchema),
  });

  const schedulerListUrl = `/ide/projects/${projectIdx}/ui-test/scheduler/`;
  const { configuration } = getRemoteState();
  const updateSchedulerStatusMutation =
    configuration === Mode.Standard
      ? useUpdateSchedulerStatusMutation({
          idx: schedulerDetailData.idx,
          isRunning: isRunning,
          cookie: cookie,
        })
      : useRemoteUpdateSchedulerStatusMutation(
          isRunning,
          schedulerDetailData.idx,
        );

  const updateSchedulerMutation =
    configuration === Mode.Standard
      ? useUpdateSchedulerMutation({
          cookie: cookie,
          url: schedulerListUrl,
        })
      : useRemoteUpdateSchedulerMutation({
          cookie: cookie,
          url: schedulerListUrl,
        });

  const { isMessengerActivate } = useSchedulerMessengerAlarm();
  const { data: autoRegisterInfo } = useGetAutoRegistersInfoQuery();
  const isAutoRegistersActivate = autoRegisterInfo?.length > 0;

  const onSubmit: SubmitHandler<UpdateSchedulerData> = (data) => {
    if (isRunning && data.scenarioIdxList.length === 0) {
      methods.setError('scenarioIdxList', {
        type: 'required',
        message: '시나리오를 선택해 주세요.',
      });
      return;
    }
    const autoRegistersListWithoutId = autoRegistersList.map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ id, ...rest }) => rest,
    );
    const updatedData = {
      ...data,
      autoRegisters: autoRegistersListWithoutId,
    };

    updateSchedulerStatusMutation.mutate(undefined, {
      onSuccess: () => {
        if (isRunning) {
          updateSchedulerMutation.mutate(updatedData, {
            onSuccess: () => {
              navigate(schedulerListUrl);
            },
          });
        } else {
          navigate(schedulerListUrl);
        }
      },
    });
  };

  return (
    <ErrorBoundaryWrapper>
      <FormProvider {...methods}>
        <form
          noValidate
          onSubmit={methods.handleSubmit(onSubmit)}
          className="mt-4 flow-root"
        >
          <div className="mt-4 space-y-4 border-b border-gray-900/10 pb-12">
            <SchedulerDetailStatus
              isRunning={isRunning}
              setIsRunning={setIsRunning}
            />
            <SchedulerDetailTestType type={schedulerDetailData.type} />
            <SchedulerDetailTitle isRunning={isRunning} />
            <SchedulerDetailScenario
              testType={schedulerDetailData.type}
              scenarioList={schedulerDetailData.scenarioIdxList}
            />
            <SchedulerDetailDevice
              type={schedulerDetailData.type}
              isRunning={isRunning}
            />
            <SchedulerDetailSchedule isRunning={isRunning} />
            <SchedulerDetailTestResultSaveOptions
              type={schedulerDetailData.type}
              isRunning={isRunning}
            />
            <SchedulerDetailDeviceReboot
              type={schedulerDetailData.type}
              isRunning={isRunning}
            />
            <SchedulerDetailEmailAlarm
              emailAddressLists={emailAddressLists}
              setEmailAddressLists={setEmailAddressLists}
              isRunning={isRunning}
            />
            {isMessengerActivate && (
              <SchedulerDetailMessengerAlarm isRunning={isRunning} />
            )}
            {isAutoRegistersActivate && (
              <SchedulerDetailAutoRegister
                isRunning={isRunning}
                autoRegistersList={autoRegistersList}
                setAutoRegistersList={setAutoRegistersList}
              />
            )}
          </div>
          <div className="mt-6 flex items-center justify-between gap-x-6">
            <div />
            {updateSchedulerMutation.isError ? (
              <ErrorMessage>
                {updateSchedulerMutation.error.message}
              </ErrorMessage>
            ) : (
              updateSchedulerStatusMutation.isError && (
                <ErrorMessage>
                  {updateSchedulerStatusMutation.error.message}
                </ErrorMessage>
              )
            )}
            <div className="flex items-center justify-between gap-x-6">
              <Button
                type="button"
                variant="secondary"
                onClick={() => navigate(schedulerListUrl)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={
                  updateSchedulerMutation.isLoading ||
                  updateSchedulerStatusMutation.isLoading
                }
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </ErrorBoundaryWrapper>
  );
};

export default SchedulerDetailForm;
