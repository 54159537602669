import { Button, ErrorBoundaryWrapper } from '@autosquare/common';
import { UpdateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { UpdateWebTestStepData } from '@customTypes/testStep/type';
import { UseMutationResult } from '@tanstack/react-query';
import React from 'react';

interface Props {
  setIsEdited: (value: React.SetStateAction<boolean>) => void;
  updateTestStepMutation:
    | UseMutationResult<unknown, Error, UpdateTestStepData, unknown>
    | UseMutationResult<unknown, Error, UpdateWebTestStepData, unknown>;
}

const TestStepDetailEditButton = ({
  setIsEdited,
  updateTestStepMutation,
}: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div className="shrink-0 border-b border-gray-200 px-4 py-5 sm:px-6">
        <div className="flex w-full justify-end">
          {updateTestStepMutation.isError && (
            <p className="error-message w-full">
              {updateTestStepMutation.error.message}
            </p>
          )}
          <div className="flex-center gap-3">
            <Button
              type="button"
              variant="secondary"
              onClick={() => setIsEdited(false)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={updateTestStepMutation.isLoading}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default TestStepDetailEditButton;
