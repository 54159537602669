import React from 'react';
import { ErrorBoundaryWrapper, TooltipTop } from '@autosquare/common';
import { CompareBy } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { UseFormRegisterReturn } from 'react-hook-form';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

type Props = {
  register: UseFormRegisterReturn;
  radioOptions: {
    title: string;
    id: string;
    defaultChecked: boolean;
    onClick?: () => void;
  }[];
  name: string;
};

const TestStepConfirmInputRadio = ({ register, radioOptions, name }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      {radioOptions?.map((radioOption) => (
        <div key={radioOption.id} className="grid grid-flow-col items-center">
          <input
            id={radioOption.id}
            name={name}
            type="radio"
            defaultChecked={radioOption.defaultChecked}
            onClick={radioOption.onClick}
            value={radioOption.id}
            className="input-radio-congress-blue"
            {...register}
          />
          <label
            htmlFor={radioOption.id}
            className="ml-3 block cursor-pointer text-sm font-medium leading-6 text-gray-900"
          >
            {radioOption.title}
          </label>
          {radioOption.id === CompareBy.Replace && (
            <div className="group relative flex items-center">
              <span className="ml-1.5 block">
                <QuestionMarkCircleIcon
                  className="size-5 cursor-help text-red-500"
                  strokeWidth={1.75}
                />
              </span>
              <TooltipTop positionClass="absolute -left-52 bottom-7 group-hover:block hidden">
                텍스트를 추출하여 값을 치환하는 기능입니다.
              </TooltipTop>
            </div>
          )}
        </div>
      ))}
    </ErrorBoundaryWrapper>
  );
};

export default TestStepConfirmInputRadio;
