import React from 'react';
import AddDeviceFormFields from './AddDeviceFormFields';
import { useFormContext } from 'react-hook-form';
import { DeviceApprovalRequest } from '@customTypes/index';

const AddDeviceId = () => {
  const { register } = useFormContext<DeviceApprovalRequest>();

  return (
    <AddDeviceFormFields
      label="Device ID* (영문 대소문자, 숫자[0-9], 대시(-), 언더바(_) 입력 가능)"
      name="udid"
    >
      <input
        className="input-base"
        placeholder="UDID(iOS) 또는 Serial Number(Android)를 입력해 주세요."
        {...register('udid')}
      />
    </AddDeviceFormFields>
  );
};

export default AddDeviceId;
