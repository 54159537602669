import React from 'react';
import {
  Breadcrumbs,
  ErrorBoundaryWrapper,
  PageHeadingButtons,
  PagingHeadings,
} from '@autosquare/common';
import { useAuthUserInfo } from '@hooks/index';
import { useLocation, useNavigate } from 'react-router-dom';
import ApplicationListTable from './components/ApplicationListTable';

const ProjectApplicationList = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'Project Settings', href: pathname },
    { title: 'Application', href: pathname },
    { title: 'List', href: pathname },
  ];

  const { isAdmin } = useAuthUserInfo();
  const pageHeadingButtons: PageHeadingButtons[] = isAdmin
    ? [
        {
          title: 'Add',
          variant: 'primary',
          type: 'button',
          onClick: () => {
            navigate('add-application');
          },
        },
      ]
    : null;

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={'Application List'}
        pageHeadingButtons={pageHeadingButtons}
      />
      <ApplicationListTable />
    </ErrorBoundaryWrapper>
  );
};

export default ProjectApplicationList;
