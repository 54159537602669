import { ErrorBoundaryWrapper } from '@autosquare/common';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import TestResultSaveOptionsCheckbox from '@components/Scheduler/TestResultSaveOptionsCheckbox';
import {
  SchedulerTypeServerName,
  UpdateSchedulerData,
} from '@customTypes/ide/scheduler/scheduler';
import clsx from 'clsx';
import React from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  type: SchedulerTypeServerName;
  isRunning: boolean;
};

const SchedulerDetailTestResultSaveOptions = ({ type, isRunning }: Props) => {
  const { register } = useFormContext<UpdateSchedulerData>();

  const checkboxList =
    type === SchedulerTypeServerName.Mobile
      ? [
          { title: 'Video', register: register('saveVideo') },
          { title: 'Screenshot', register: register('saveScreenshot') },
          { title: 'Log File', register: register('saveLog') },
        ]
      : [{ title: 'Screenshot', register: register('saveScreenshot') }];

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer title={'Test Result Save Options'}>
        <div
          className={clsx(
            'col-span-2 grid',
            type === SchedulerTypeServerName.Mobile
              ? 'grid-cols-3'
              : 'grid-cols-1',
          )}
        >
          {checkboxList.map((checkbox) => (
            <TestResultSaveOptionsCheckbox
              key={checkbox.title}
              title={checkbox.title}
              register={checkbox.register}
              disabled={!isRunning}
            />
          ))}
        </div>
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default SchedulerDetailTestResultSaveOptions;
