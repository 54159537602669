import { ErrorBoundaryWrapper } from '@autosquare/common';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

type Props = {
  control: Control<CreateTestStepData>;
  name?: 'optionsDto.textEncrypt' | 'optionsDto.secret';
};

const SecretBox = ({ control, name = 'optionsDto.secret' }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div className="flex justify-start">
        <label
          htmlFor="secret"
          className="flex items-center justify-between gap-2 text-sm font-medium leading-6 text-gray-900"
        >
          <legend>Secret</legend>
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <input
                id="secret"
                name="secret"
                type="checkbox"
                className="size-4 rounded border border-solid border-gray-300 text-congress-blue"
                defaultChecked={false}
                {...field}
              />
            )}
          />
        </label>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default SecretBox;
