import { MailReceiver, useInput, useRefOutsideClick } from '@autosquare/common';
import { ProjectMember } from '@customTypes/dashboard/project/type';
import {
  CreateSchedulerData,
  UpdateSchedulerData,
} from '@customTypes/ide/scheduler/scheduler';

import { useProjectMemberListQuery } from '@lib/api/ide/project/project';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';

export const useSchedulerEmailAlarm = (
  mailReceivers: MailReceiver[],
  setValue: UseFormSetValue<CreateSchedulerData | UpdateSchedulerData>,
) => {
  const { data: projectMembers } = useProjectMemberListQuery();

  const [emailAddress, onChangeEmailAddress, setEmailAddress] = useInput('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [isOpenedMemberList, setIsOpenedMemberList] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [temporarySavedEmailAddress, setTemporarySavedEmailAddress] =
    useState<MailReceiver>({ email: '', name: '' });

  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const ulRef: React.MutableRefObject<HTMLUListElement | null> = useRef(null);

  useRefOutsideClick(wrapperRef, () => setIsOpenedMemberList(false));

  useEffect(() => {
    if (
      isOpenedMemberList &&
      ulRef?.current &&
      ulRef?.current?.children[selectedIndex]
    ) {
      ulRef?.current?.children[selectedIndex]?.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      });
    }
  }, [selectedIndex, isOpenedMemberList]);

  const filteredPeople =
    emailAddress === ''
      ? projectMembers?.filter(
          (projectMember) =>
            !mailReceivers?.some(
              (emailAddress) => emailAddress?.email === projectMember?.userId,
            ),
        )
      : projectMembers?.filter(
          (projectMember) =>
            !mailReceivers?.some(
              (emailAddress) => emailAddress?.email === projectMember?.userId,
            ) &&
            (projectMember?.userId
              .toLowerCase()
              .includes(emailAddress?.toLowerCase()) ||
              projectMember?.name
                .toLowerCase()
                .includes(emailAddress?.toLowerCase())),
        );

  const onSelectMember = (member: ProjectMember) => {
    if (mailReceivers?.find((x) => x?.email === emailAddress) === undefined) {
      setValue('mailReceivers', [
        ...mailReceivers,
        { email: member?.userId, name: member?.name },
      ]);
      setEmailAddress('');
      inputRef.current.value = '';
      setIsOpenedMemberList(false);
    }
  };

  const inputFocus = () => {
    setIsOpenedMemberList(true);
    setTemporarySavedEmailAddress({
      email: filteredPeople[selectedIndex]?.userId,
      name: filteredPeople[selectedIndex]?.name,
    });
  };

  const deleteTagItem = (indexToDelete: number) => {
    setValue(
      'mailReceivers',
      mailReceivers.filter((_, index) => index !== indexToDelete),
    );
  };

  return {
    wrapperRef,
    onChangeEmailAddress,
    setIsOpenedMemberList,
    inputRef,
    emailAddress,
    temporarySavedEmailAddress,
    setEmailAddress,
    setSelectedIndex,
    setEmailErrorMessage,
    filteredPeople,
    setTemporarySavedEmailAddress,
    selectedIndex,
    inputFocus,
    isOpenedMemberList,
    ulRef,
    onSelectMember,
    emailErrorMessage,
    deleteTagItem,
  };
};
