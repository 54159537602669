import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import DetailDraggable from './SchedulerDetailScenario/DetailDraggable';

import { useMobileTestScenarioQuery } from '@lib/api/ide/mobile/mobileTestScenario';
import { useWebTestScenarioQuery } from '@lib/api/ide/web/webTestScenario';

import {
  SchedulerTypeServerName,
  UpdateSchedulerData,
} from '@customTypes/ide/scheduler/scheduler';
import { useFormContext } from 'react-hook-form';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';

interface Props {
  testType: SchedulerTypeServerName;
  scenarioList: number[];
}

const SchedulerDetailScenario = ({ testType, scenarioList }: Props) => {
  const { projectIdx } = useParams();
  const { watch, clearErrors } = useFormContext<UpdateSchedulerData>();
  const testScenarioQuery =
    testType === SchedulerTypeServerName.Mobile
      ? useMobileTestScenarioQuery(projectIdx, { staleTime: 20 })
      : useWebTestScenarioQuery({ idx: projectIdx, staleTime: 20 });
  const addScenarioList = watch('scenarioIdxList');
  useEffect(() => {
    if (scenarioList.length > 0) {
      clearErrors('scenarioIdxList');
    }
  }, [clearErrors, addScenarioList]);
  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer title={'Scenario'}>
        <div className="mt-2 text-sm sm:col-span-2 sm:mt-0">
          <DetailDraggable
            testScenarioQuery={testScenarioQuery}
            scenarioList={scenarioList}
          />
        </div>
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default SchedulerDetailScenario;
