import { ComboboxUI, ErrorBoundaryWrapper } from '@autosquare/common';
import {
  CreateTestStepData,
  CreateTestStepDataOptionsDto,
  UpdateTestStepData,
  UpdateTestStepDataOptionsDto,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ErrorMessage } from '@hookform/error-message';
import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';
import { useSpeedMobileTestCaseListQuery } from '@lib/api/ide/speedMobile/speedMobileTestCase';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

type ValueKeys =
  | keyof Pick<CreateTestStepData, 'value'>
  | `optionsDto.${keyof Pick<
      CreateTestStepDataOptionsDto,
      'actionValue' | 'passActionValue' | 'failActionValue'
    >}`
  | keyof Pick<UpdateTestStepData, 'value'>
  | `optionsDto.${keyof Pick<
      UpdateTestStepDataOptionsDto,
      'actionValue' | 'passActionValue' | 'failActionValue'
    >}`;

type Props = {
  value?: ValueKeys;
  rules?:
    | Omit<
        RegisterOptions<CreateTestStepData, ValueKeys>,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
      >
    | Omit<
        RegisterOptions<UpdateTestStepData, ValueKeys>,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
      >;
};

const ImportTestCaseCombobox = ({
  value = 'value',
  rules = { required: 'Test Case를 선택해주세요.' },
}: Props) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const [query, setQuery] = useState('');

  const isUITestLocation = isWordInLocationPathname('ui-test');
  const isMobileTestStepLocation = isWordInLocationPathname('test-step');

  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateTestStepData | UpdateTestStepData>();

  const uiMobileTestCaseListQuery = useMobileTestCaseListQuery({
    enabled: isUITestLocation,
  });
  const SpeedMobileTestCaseListQuery = useSpeedMobileTestCaseListQuery({
    enabled: !isUITestLocation,
  });

  const mobileTestCaseListQuery = isUITestLocation
    ? uiMobileTestCaseListQuery
    : SpeedMobileTestCaseListQuery;

  const mobileTestCaseList = mobileTestCaseListQuery.data?.filter(
    (mobileTestCase) => mobileTestCase.idx !== Number(idx),
  );

  const findTitleFromIdx = (idx: number) =>
    mobileTestCaseList?.find((mobileTestCase) => mobileTestCase.idx === idx)
      ?.title;

  const filteredList =
    query === '' && mobileTestCaseListQuery.isSuccess
      ? mobileTestCaseList
      : mobileTestCaseList?.filter((mobileTestCase) =>
          mobileTestCase.title.toLowerCase().includes(query.toLowerCase()),
        ) || [];

  useEffect(() => {
    return () => {
      clearErrors(value);
    };
  }, [clearErrors]);

  return (
    <ErrorBoundaryWrapper>
      <Controller
        control={control}
        name={value}
        rules={rules}
        render={({ field }) => (
          <ComboboxUI
            query={query}
            setQuery={setQuery}
            filteredList={filteredList}
            findTitleFromIdx={findTitleFromIdx}
            isLoading={mobileTestCaseListQuery.isLoading}
            isError={mobileTestCaseListQuery.isError}
            error={mobileTestCaseListQuery.error}
            value={field.value || ''}
            onChange={field.onChange}
            valueToSave={'idx'}
            valueToShow={'title'}
            placeholder={'Select Test Case'}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={value}
        render={({ message }) => (
          <p
            className={clsx(
              isMobileTestStepLocation
                ? 'error-message'
                : 'standard-error-message',
            )}
          >
            {message}
          </p>
        )}
      />
    </ErrorBoundaryWrapper>
  );
};

export default ImportTestCaseCombobox;
