import { DateInTable, TableBodyBasic } from '@autosquare/common';
import { DeviceApprovalInfo } from '@customTypes/index';
import React from 'react';
import ApprovalActionButtons from './AdminRequestTableBody/ApprovalActionButtons';
import ApprovalDescription from './AdminRequestTableBody/ApprovalDescription';

type Props = {
  deviceApproval: DeviceApprovalInfo;
  idx: number;
};

const AdminRequestTableBody = ({ deviceApproval, idx }: Props) => {
  return (
    <tr>
      <TableBodyBasic>{idx + 1}</TableBodyBasic>
      <TableBodyBasic>{deviceApproval.approvalType}</TableBodyBasic>
      <TableBodyBasic>{deviceApproval.requester}</TableBodyBasic>
      <TableBodyBasic>{deviceApproval.deviceName || '-'}</TableBodyBasic>
      <TableBodyBasic>{deviceApproval.deviceOs}</TableBodyBasic>
      <TableBodyBasic>{deviceApproval.projectName}</TableBodyBasic>
      <TableBodyBasic>
        <DateInTable dateTime={deviceApproval.dateTime} />
      </TableBodyBasic>
      <TableBodyBasic>
        <ApprovalDescription description={deviceApproval.description} />
      </TableBodyBasic>
      <TableBodyBasic>
        <ApprovalActionButtons deviceApproval={deviceApproval} />
      </TableBodyBasic>
    </tr>
  );
};

export default AdminRequestTableBody;
