import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, ListBoxHeadlessInController } from '@autosquare/common';
import {
  useGetJiraIssueTypeQuery,
  useGetJiraProjectListQuery,
} from '@lib/api/ide/autoRegisters/autoRegisters';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import {
  AutoRegisterInfoData,
  CreateSchedulerData,
} from '@customTypes/ide/scheduler/scheduler';
import IdeJiraInputTestResult from '@components/shared/IdeJiraInputContents/IdeJiraInputTestResult';
import IdeJiraInputIssueType from '@components/shared/IdeJiraInputContents/IdeJiraInputIssueType';
import IdeJiraInputPriority from '@components/shared/IdeJiraInputContents/IdeJiraInputPriority';

type Props = {
  autoIndex: number;
  autoRegisters: AutoRegisterInfoData;
};

const IdeJiraInputContents = ({ autoIndex, autoRegisters }: Props) => {
  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateSchedulerData>();

  const [priorityErrorObj, setPriorityErrorObj] = useState<{
    isError: boolean;
    message: string;
  }>({ isError: false, message: '' });

  const { fields, append, remove, update } = useFieldArray<CreateSchedulerData>(
    {
      control,
      name: `autoRegisters.${autoIndex}.jira.mapping`,
    },
  );

  const fieldsLength = fields.length;
  const fieldsIndex = fieldsLength - 1;

  const {
    data: jiraProjectList,
    isLoading,
    isFetching,
    isError: isJiraProjectListError,
    error: jiraProjectError,
  } = useGetJiraProjectListQuery();

  const jiraProjectListLength = jiraProjectList?.length;

  const jiraProjectKey = useWatch({
    control,
    name: `autoRegisters.${autoIndex}.jira.projectKey`,
  });

  const {
    data: jiraIssueType,
    refetch: jiraIssueTypeRefetch,
    isError: isJiraIssueTypeError,
    error: jiraIssueTypeError,
  } = useGetJiraIssueTypeQuery(jiraProjectKey);

  const jiraMapping = autoRegisters?.jira?.mapping;

  const isJiraPriorityError = priorityErrorObj.isError;

  const selectedFieldValue = (name: string) =>
    jiraMapping?.[fieldsIndex]?.[name];

  const findJiraProjectNameFromKey = (key: string) =>
    jiraProjectList?.find((data) => data.key === key)?.name;

  const plusButtonDisabledOption =
    fieldsLength === 3 ||
    !selectedFieldValue('testResult') ||
    !selectedFieldValue('issueType');

  const mapping = jiraMapping?.every(
    (item) => item.testResult && item.issueType,
  );

  useEffect(() => {
    jiraIssueTypeRefetch();
    fields.forEach((_, index) => {
      update(index, { testResult: '', issueType: '', priority: '' });
      if (index !== 0) {
        remove(index);
      }
    });
    clearErrors(`autoRegisters.${autoIndex}.jira.projectKey`);
  }, [jiraProjectKey]);

  useEffect(() => {
    if (
      mapping &&
      jiraProjectKey &&
      errors.autoRegisters?.[autoIndex].jira.mapping.type === 'required'
    ) {
      clearErrors(`autoRegisters.${autoIndex}.jira.mapping`);
    }
    if (
      mapping &&
      jiraProjectKey &&
      errors.autoRegisters?.[autoIndex].jira.mapping.type === 'duplication'
    ) {
      clearErrors(`autoRegisters.${autoIndex}.jira.mapping`);
    }
  }, [jiraMapping]);

  useEffect(() => {
    if (fieldsLength === 0) {
      append({
        testResult: '',
        issueType: '',
        priority: '',
      });
    }
  }, [fields, append]);

  return (
    <>
      <Controller
        name={`autoRegisters.${autoIndex}.jira.projectKey`}
        render={({ field }) => (
          <ListBoxHeadlessInController
            value={field.value}
            buttonValue={findJiraProjectNameFromKey(field.value)}
            lists={jiraProjectList || []}
            valueToSave="key"
            valueToShow="name"
            onChange={field.onChange}
            placeholder="이슈를 등록할 프로젝트를 선택해 주세요."
            noListMessage="선택할 항목이 없습니다."
            location="jira"
            disabled={isLoading || isFetching}
          />
        )}
      />
      {isJiraProjectListError ? (
        <p className="error-message">{jiraProjectError.message}</p>
      ) : (
        <ErrorMessage
          name={`autoRegisters.${autoIndex}.jira.projectKey`}
          errors={errors}
          render={({ message }) => <p className="error-message">{message}</p>}
        />
      )}
      <div className="flex flex-col space-y-2">
        {fields.map((inputField, index) => (
          <div
            key={inputField.id}
            className="flex items-center justify-between gap-x-3"
          >
            <div className="flex w-full flex-col items-start gap-y-2 text-sm font-normal leading-normal">
              <div className="flex w-full flex-col gap-y-2">
                {index === 0 && <div>Test Result*</div>}
                <Controller
                  control={control}
                  name={`autoRegisters.${autoIndex}.jira.mapping.${index}.testResult`}
                  render={({ field }) => (
                    <IdeJiraInputTestResult
                      field={field}
                      jiraProjectKey={jiraProjectKey}
                      jiraProjectListLength={jiraProjectListLength}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex w-full flex-col items-start gap-y-2 text-sm font-normal leading-normal">
              <div className="flex w-full flex-col gap-y-2">
                {index === 0 && <div>Issue Type*</div>}
                <Controller
                  control={control}
                  name={`autoRegisters.${autoIndex}.jira.mapping.${index}.issueType`}
                  render={({ field }) => (
                    <IdeJiraInputIssueType
                      field={field}
                      jiraIssueType={jiraIssueType}
                      jiraProjectKey={jiraProjectKey}
                      index={index}
                      fieldsIndex={fieldsIndex}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex w-full flex-col items-start gap-y-2 text-sm font-normal leading-normal">
              <div className="flex w-full flex-col gap-y-2">
                {index === 0 && <div>Priority</div>}
                <Controller
                  control={control}
                  name={`autoRegisters.${autoIndex}.jira.mapping.${index}.priority`}
                  render={({ field }) => (
                    <IdeJiraInputPriority
                      field={field}
                      fieldsIndex={fieldsIndex}
                      index={index}
                      jiraIssueType={jiraIssueType}
                      jiraMapping={jiraMapping}
                      jiraProjectKey={jiraProjectKey}
                      setPriorityErrorObj={setPriorityErrorObj}
                    />
                  )}
                />
              </div>
            </div>
            <div className={clsx(index === 0 && 'pt-7')}>
              <Button
                type="button"
                variant="secondary"
                className={clsx(
                  'disabled:bg-gray-100',
                  fieldsLength === 1 && 'bg-gray-50',
                )}
                isSquare
                onClick={() => remove(index)}
                disabled={fieldsLength === 1}
              >
                <MinusIcon className="size-6" />
              </Button>
            </div>
          </div>
        ))}
        <Button
          type="button"
          variant="imageOriginal"
          className="flex-center h-9 w-full rounded border border-dashed border-gray-300 px-2 py-1 disabled:bg-gray-50"
          onClick={() =>
            append({ testResult: null, issueType: null, priority: null })
          }
          disabled={plusButtonDisabledOption}
        >
          <PlusIcon
            className={clsx(
              'size-6',
              fieldsLength === 3 ? 'text-gray-400' : 'text-gray-900',
            )}
          />
        </Button>
        {isJiraIssueTypeError ? (
          <p className="error-message">{jiraIssueTypeError.message}</p>
        ) : isJiraPriorityError ? (
          <p className="error-message">{priorityErrorObj.message}</p>
        ) : (
          <ErrorMessage
            name={`autoRegisters.${autoIndex}.jira.mapping`}
            errors={errors}
            render={({ message }) => <p className="error-message">{message}</p>}
          />
        )}
      </div>
    </>
  );
};

export default IdeJiraInputContents;
