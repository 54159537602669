import { ErrorBoundaryWrapper } from '@autosquare/common';
import { ListBoxHeadlessInController } from '@autosquare/common/src/components/shared/HeadlessUI/ListBoxHeadlessInController';
import {
  dataTypeList,
  findDataTypeFromServerName,
} from '@customTypes/ide/mobileTestStep/dataValidation/dataValidation';
import {
  CreateTestStepData,
  MobileTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ErrorMessage } from '@hookform/error-message';
import { useMobileTestStepBySaveTextQuery } from '@lib/api/ide/mobile/mobileTestStep';
import { useSpeedMobileTestStepBySaveTextQuery } from '@lib/api/ide/speedMobile/speedMobileTestStep';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { findDescriptionFromIdx } from '@utils/static/findDescriptionFromIdx';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const TypeAndReferenceValue = () => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<CreateTestStepData>();

  const mobileOs = watch('mobileOs');

  const uiLocation = isWordInLocationPathname('ui-test');
  const mobileTestStepBySaveTextQuery = uiLocation
    ? useMobileTestStepBySaveTextQuery()
    : useSpeedMobileTestStepBySaveTextQuery();

  const mobileTestStepBySaveTextData =
    mobileTestStepBySaveTextQuery.data as MobileTestStepData[];

  // mobile os에 따른 리스트 구분 필요
  const referenceValueList = mobileTestStepBySaveTextData?.filter(
    (testStep) => testStep.mobileOs === mobileOs,
  );

  return (
    <ErrorBoundaryWrapper>
      <div className="flex flex-col gap-y-1">
        <div className="text-sm font-normal leading-normal text-gray-900">
          Type
        </div>
        <Controller
          control={control}
          name="optionsDto.dataType"
          render={({ field }) => (
            <ListBoxHeadlessInController
              value={field.value}
              onChange={field.onChange}
              lists={dataTypeList}
              valueToSave="serverName"
              valueToShow="name"
              buttonValue={findDataTypeFromServerName(field.value)}
            />
          )}
        />
      </div>
      <div className="flex flex-col gap-y-1">
        <div className="text-sm font-normal leading-normal text-gray-900">
          Reference Value
        </div>
        <Controller
          control={control}
          name="optionsDto.compareIdx"
          rules={{ required: '기준 값을 선택해 주세요.' }}
          render={({ field }) => (
            <ListBoxHeadlessInController
              value={field.value}
              onChange={field.onChange}
              lists={referenceValueList}
              valueToSave="idx"
              valueToShow="description"
              buttonValue={findDescriptionFromIdx(
                Number(field.value),
                referenceValueList,
              )}
              placeholder="기준 값을 Save Text 스텝 리스트에서 선택해 주세요."
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={'optionsDto.compareIdx'}
          render={({ message }) => (
            <p className="error-message pt-1">{message}</p>
          )}
        />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default TypeAndReferenceValue;
