import { ArrowSpinner, Button, ErrorBoundaryWrapper } from '@autosquare/common';
import {
  CommonDialog,
  DialogButton,
  DialogButtonPanels,
} from '@components/Dialog';

import { useIdeFileDownloadMutation } from '@lib/api/dashboard/download/download';

import React, { useState } from 'react';

const Download = () => {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const ideFileDownloadMutation = useIdeFileDownloadMutation();

  const onError = () => {
    setIsDialogOpened(true);
  };

  const downloadList = [
    {
      idx: 1,
      title: 'Windows x64',
      onClick: () => {
        ideFileDownloadMutation.mutate(
          { os: 'win', arch: 'x64' },
          { onError: onError },
        );
      },
    },
    {
      idx: 2,
      title: 'macOS x64',
      onClick: () => {
        ideFileDownloadMutation.mutate(
          { os: 'mac', arch: 'x64' },
          { onError: onError },
        );
      },
    },
    {
      idx: 3,
      title: 'macOS arm64',
      onClick: () => {
        ideFileDownloadMutation.mutate(
          { os: 'mac', arch: 'arm64' },
          { onError: onError },
        );
      },
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="px-4 pb-5 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="border-b border-solid border-gray-200 pb-2.5 text-2xl font-bold leading-7 text-gray-900">
            Download
          </h1>
        </div>
      </div>
      <div className="inline-flex flex-col px-2.5">
        <div className="flex h-11 w-full items-start justify-start gap-2.5 p-2.5">
          <div className="text-sm font-normal leading-normal text-black">
            IDE 설치를 원하는 운영체제를 선택해 주세요.
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-5 p-2.5 sm:flex-row">
          {downloadList.map((download) => (
            <Button
              key={download.idx}
              onClick={download.onClick}
              className="inline-flex h-10 w-40 items-center justify-between gap-1.5"
            >
              <div className="text-sm font-semibold leading-normal text-white">
                {download.title}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>
            </Button>
          ))}
        </div>
      </div>
      {ideFileDownloadMutation.isLoading && <ArrowSpinner />}
      <CommonDialog
        isOpen={isDialogOpened}
        setIsOpen={setIsDialogOpened}
        iconType={'caution'}
        title={'Download Failed'}
        subTitle={
          ideFileDownloadMutation.isError &&
          ideFileDownloadMutation.error.message
        }
      >
        <DialogButtonPanels>
          <DialogButton type="button" onClick={() => setIsDialogOpened(false)}>
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default Download;
