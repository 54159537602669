import { RootState } from '@app/store';
import { ErrorBoundaryWrapper, ListBoxHeadlessUI } from '@autosquare/common';
import { RunningCycle } from '@customTypes/ide/scheduler/scheduler';
import { UpdateSpeedSchedulerData } from '@customTypes/ide/speedScheduler/speedScheduler';

import { parseCronCycle } from '@utils/static/parseCronCycle';
import { hourCycle, minuteCycle, time } from '@utils/static/runningCycle';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

const SpeedSchedulerDetailRunningCycle = () => {
  const { setValue, watch } = useFormContext<UpdateSpeedSchedulerData>();

  const cycleValue = watch('cronCycle');
  const startTimeValue = watch('startTime');

  const isRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );

  const initialTimeUnit = cycleValue.split(' ')[1].includes('/')
    ? 'Minute'
    : 'Hour';

  const [timeUnit, setTimeUnit] = useState(initialTimeUnit);

  const selectedTime =
    parseCronCycle(cycleValue).timeInterval === 'Once a day'
      ? 'Every 24 hour'
      : parseCronCycle(cycleValue).timeInterval;

  const formattedSelectedTime = selectedTime.replace(/(\(s\))/, '');

  const findCycle =
    initialTimeUnit === 'Hour'
      ? hourCycle.find((cycle) => cycle.title === formattedSelectedTime)
      : initialTimeUnit === 'Minute' &&
        minuteCycle.find((cycle) => cycle.title === formattedSelectedTime);

  const selectedDays = parseCronCycle(cycleValue).daysOfWeek;

  const [hours, minutes] = startTimeValue.split(' ')[1].split(':');

  const reCreateCronExpression = (runningCycle: RunningCycle) => {
    let cronExpression: string;

    const formattedDays =
      selectedDays.split(',').length === 7 ? '?' : selectedDays;
    const formattedTimeCycle = runningCycle.title.match(/\d+/)?.[0];

    const startHours = Number(hours) < 10 ? hours.split('')[1] : hours;
    const startMinutes = Number(minutes) < 10 ? minutes.split('')[1] : minutes;

    if (timeUnit === 'Hour') {
      if (formattedTimeCycle === '24') {
        if (formattedDays === '?') {
          cronExpression = `0 ${startMinutes} ${startHours} * * ?`;
        } else {
          cronExpression = `0 ${startMinutes} ${startHours} ? * ${formattedDays}`;
        }
      } else {
        if (formattedDays === '?') {
          cronExpression = `0 ${startMinutes} 0/${formattedTimeCycle} * * ?`;
        } else {
          cronExpression = `0 ${startMinutes} 0/${formattedTimeCycle} ? * ${formattedDays}`;
        }
      }
    } else {
      if (formattedDays === '?') {
        cronExpression = `0 0/${formattedTimeCycle} * * * ?`;
      } else {
        cronExpression = `0 0/${formattedTimeCycle} * ? * ${formattedDays}`;
      }
    }
    return cronExpression;
  };

  const [selectedCycle, setSelectedCycle] = useState<RunningCycle>(findCycle);

  const handleTimeUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const unit = e.target.value;
    setTimeUnit(unit);
    if (unit === 'Minute') {
      setSelectedCycle(minuteCycle[0]);
    } else {
      setSelectedCycle(hourCycle[0]);
    }
  };

  useEffect(() => {
    const newCronCycle = reCreateCronExpression(selectedCycle);
    setValue('cronCycle', newCronCycle);
  }, [timeUnit, selectedCycle, startTimeValue]);

  return (
    <ErrorBoundaryWrapper>
      <div className="mt-2 sm:col-span-5 sm:mt-0">
        <label
          htmlFor="cycle"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Cycle
        </label>
        <div className="mt-2 flex items-center justify-center space-x-4">
          <div className="flex gap-x-3">
            {time.map((item) => (
              <label
                htmlFor={`${item.id}`}
                className="mr-2 flex items-center text-sm font-medium"
                key={item.id}
              >
                <input
                  id={`${item.id}`}
                  name="cycle unit"
                  type="radio"
                  value={item.val}
                  className={clsx(
                    'input-radio-congress-blue mr-3',
                    !isRunning && 'bg-gray-100 text-gray-100',
                  )}
                  checked={timeUnit === item.val}
                  onChange={handleTimeUnitChange}
                  disabled={!isRunning}
                />
                {item.val}
              </label>
            ))}
          </div>
          <div className="w-full">
            <ListBoxHeadlessUI
              state={selectedCycle}
              setState={setSelectedCycle}
              list={timeUnit === 'Minute' ? minuteCycle : hourCycle}
              wordInButton="title"
              wordInOptions="title"
              disabled={!isRunning}
              isNeededButtonGray
            />
          </div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default SpeedSchedulerDetailRunningCycle;
