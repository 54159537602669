import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { RootState } from '@app/store';

import { useMobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';

import PointOrRectangleInput from '@components/shared/PointOrRectangleInput';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { getCroppedImage } from '@utils/imageUtils';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const ComparingImageInClick = () => {
  const {
    startX,
    startY,
    endX,
    endY,
    onChangeStartX,
    onChangeStartY,
    onChangeEndX,
    onChangeEndY,
    imageWidth,
    imageHeight,
  } = useMobileTestStepContext();

  const {
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateTestStepData>();

  const screenshot = useSelector(
    (state: RootState) => state.mobileScreenshotSource.screenshotSource,
  );

  useEffect(() => {
    return () => {
      setValue('value', undefined);
      setValue('optionsDto.cropCoordinate', undefined);
    };
  }, [setValue]);

  useEffect(() => {
    setValue(
      'optionsDto.cropCoordinate',
      `${startX},${startY},${endX},${endY}`,
    );
    setValue(
      'value',
      getCroppedImage(
        startX,
        startY,
        Math.abs(endX - startX),
        Math.abs(endY - startY),
        screenshot,
      ).split(',')[1],
    );
  }, [startX, startY, endX, endY]);

  useEffect(() => {
    if (
      startX > imageWidth ||
      startY > imageHeight ||
      endX > imageWidth ||
      endY > imageHeight
    ) {
      setError('optionsDto.cropCoordinate', {
        type: 'max',
        message: `X는 0이상 ${imageWidth}이하, Y는 0이상 ${imageHeight} 이하로 작성해 주세요.`,
      });
    } else if (startX < 0 || startY < 0 || endX < 0 || endY < 0) {
      setError('optionsDto.cropCoordinate', {
        type: 'min',
        message: `X는 0이상 ${imageWidth}이하, Y는 0이상 ${imageHeight} 이하로 작성해 주세요.`,
      });
    } else if (!startX || !startY || !endX || !endY) {
      setError('optionsDto.cropCoordinate', {
        type: 'required',
        message: `X는 0이상 ${imageWidth}이하, Y는 0이상 ${imageHeight} 이하로 작성해 주세요.`,
      });
    } else {
      clearErrors('optionsDto.cropCoordinate');
    }
  }, [
    startX,
    startY,
    endX,
    endY,
    imageWidth,
    imageHeight,
    setError,
    clearErrors,
  ]);

  const rectProperties = [
    {
      labelTitle: 'startX',
      maxSize: imageWidth,
      value: startX,
      onChange: onChangeStartX,
    },
    {
      labelTitle: 'startY',
      maxSize: imageHeight,
      value: startY,
      onChange: onChangeStartY,
    },
    {
      labelTitle: 'endX',
      maxSize: imageWidth,
      value: endX,
      onChange: onChangeEndX,
    },
    {
      labelTitle: 'endY',
      maxSize: imageHeight,
      value: endY,
      onChange: onChangeEndY,
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PointOrRectangleInput
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        properties={rectProperties}
        isHiddenInputArrow={true}
      />
      <ErrorMessage
        errors={errors}
        name={'optionsDto.cropCoordinate'}
        render={({ message }) => (
          <p className="error-message -mt-2">{message}</p>
        )}
      />
    </ErrorBoundaryWrapper>
  );
};

export default ComparingImageInClick;
