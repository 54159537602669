import React, { useEffect, useState } from 'react';

import tsquareLogo from '@assets/img/tsquare_logo.png';
import dayjs from 'dayjs';

type BuildDateAndAppVersion = {
  buildDate: string;
  version: string;
};

const HelpInformation = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [helpInformation, setHelpInformation] =
    useState<BuildDateAndAppVersion>({
      buildDate: '2023-01-01',
      version: '0.0.1',
    });

  const dashboardBuildDate = dayjs(VITE_BUILD_DATE).format('YYYY-MM-DD');
  const dashboardBuildVersion = VITE_APP_VERSION;

  useEffect(() => {
    const getBuildDateAndVersion = async () => {
      const buildDateAndAppVersion: BuildDateAndAppVersion =
        await window.electron.invoke('buildDateAndAppVersion');

      setHelpInformation(buildDateAndAppVersion);
    };
    getBuildDateAndVersion();
  }, []);

  return (
    <main className="flex w-full flex-col items-center justify-center">
      <div className="flex items-center justify-center">
        <img src={tsquareLogo} alt="tsquare_logo" width={240} height={48} />
      </div>
      <div className="my-3 text-center sm:mt-5">
        <p className="text-sm text-gray-900">
          <span>Version: </span>
          <span onClick={() => setIsClicked(!isClicked)}>
            v
            {isClicked
              ? `${helpInformation.version}-ide`
              : dashboardBuildVersion}
          </span>
        </p>
        <div className="mt-2">
          <p className="text-sm text-gray-900">
            <span>Release Date: </span>
            <span>
              {isClicked
                ? `${helpInformation.buildDate}`
                : `${dashboardBuildDate}`}
            </span>
          </p>
        </div>
        <div className="max-w-7xl px-6 lg:px-8">
          <p className="mt-3 text-center text-xs leading-5 text-gray-900">
            Copyright &copy; 2023 TSQUARE Co., Ltd. All rights reserved.
          </p>
        </div>
      </div>
    </main>
  );
};

export default HelpInformation;
