import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import CalendarCustomHeader from '@components/Calendar/CalendarCustomHeader';
import CalendarIconButton from '@components/SearchForm/components/CalendarIconButton';

import 'react-datepicker/dist/react-datepicker.css';

interface CustomDatePickerProps
  extends Omit<ReactDatePickerProps, 'customInput'> {
  buttonRef?: React.RefObject<HTMLButtonElement>;
  width?: 'w-full' | 'min-w-40';
}

const CustomDatePicker = ({
  buttonRef,
  width = 'min-w-40',
  ...props
}: CustomDatePickerProps) => {
  return (
    <DatePicker
      {...props}
      showPopperArrow={false}
      customInput={
        <CalendarIconButton
          ref={buttonRef}
          placeholderText="Select"
          width={width}
        />
      }
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <CalendarCustomHeader
          date={date}
          changeYear={changeYear}
          changeMonth={changeMonth}
          decreaseMonth={decreaseMonth}
          increaseMonth={increaseMonth}
          prevMonthButtonDisabled={prevMonthButtonDisabled}
          nextMonthButtonDisabled={nextMonthButtonDisabled}
        />
      )}
    />
  );
};

export default CustomDatePicker;
