import { ErrorBoundaryWrapper } from '@autosquare/common';
import React from 'react';
import DeviceUnavailableIcon from '@assets/icons/DeviceUnavailableIcon.svg';

const ReservationDeviceUnAuthorization = () => {
  return (
    <ErrorBoundaryWrapper>
      <div className="flex-center flex-col gap-2 py-8">
        <img
          src={DeviceUnavailableIcon}
          alt="Device UnAuthorization"
          width={48}
          height={48}
        />
        <div className="flex-center flex-col gap-1">
          <p className="text-sm font-semibold leading-tight text-gray-900">
            Device Authorization이 설정되어 있지 않습니다.
          </p>
          <p className="text-sm font-normal leading-tight text-gray-500">
            등록된 테스트 디바이스만 사용할 경우 시스템 어드민에게 문의해
            주세요.
          </p>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default ReservationDeviceUnAuthorization;
