import {
  ReservationInfo,
  UpdateReservationDevice,
  UpdateReservationDeviceForm,
  UpdateReservationDeviceFormSchema,
} from '@customTypes/index';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useReservedDeviceDetailQuery,
  useUpdateDeviceReservationMutation,
} from '@lib/api/ide/queryHooks';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CustomDatePicker } from '@components/index';
import { useReservationTime } from '@hooks/index';
import {
  CommonDialog,
  DialogButton,
  DialogButtonPanels,
} from '@components/Dialog';

type Props = {
  reservationInfo: ReservationInfo;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

const ReservationTimeTableBodyForm = ({
  reservationInfo,
  setIsEdit,
}: Props) => {
  const { projectIdx } = useParams();
  const { data: reservedDeviceDetail } = useReservedDeviceDetailQuery();
  const { minStartTime, dateOnChange, checkIsToday, checkIsSameDay } =
    useReservationTime();

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const startDateRef = useRef<HTMLButtonElement>(null);
  const endDateRef = useRef<HTMLButtonElement>(null);

  const updateDeviceReservationMutation = useUpdateDeviceReservationMutation();

  const { control, handleSubmit, watch, reset } =
    useForm<UpdateReservationDeviceForm>({
      values: {
        deviceIdx: reservedDeviceDetail.deviceInfo.deviceIdx,
        projectIdx: Number(projectIdx),
        reservationIdx: reservationInfo.reservationIdx,
        startTime: `${reservationInfo.reservationStartDate} ${reservationInfo.reservationStartTime}`,
        endTime: `${reservationInfo.reservationEndDate} ${reservationInfo.reservationEndTime}`,
      },
      resolver: zodResolver(UpdateReservationDeviceFormSchema),
    });

  const onSubmit: SubmitHandler<UpdateReservationDeviceForm> = (data) => {
    const updatedData: UpdateReservationDevice = {
      ...data,
      startDate: dayjs(data.startTime).format('YYYY-MM-DD'),
      endDate: dayjs(data.endTime).format('YYYY-MM-DD'),
      startTime: dayjs(data.startTime).format('HH:mm'),
      endTime: dayjs(data.endTime).format('HH:mm'),
    };

    updateDeviceReservationMutation.mutate(updatedData, {
      onSuccess: () => {
        setIsEdit(false);
      },
      onError: () => {
        setIsErrorModalOpen(true);
      },
    });
  };

  const startTime = watch('startTime');
  const endTime = watch('endTime');

  const isToday = checkIsToday(startTime);
  const isSameDay = checkIsSameDay(startTime, endTime);

  return (
    <>
      <form
        key={reservationInfo.reservationIdx}
        className="table-row"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="table-cell whitespace-pre-wrap border-t border-gray-200 px-3 py-4 text-sm text-gray-900">
          <Controller
            control={control}
            name="startTime"
            render={({ field }) => (
              <CustomDatePicker
                selected={field.value ? new Date(field.value) : null}
                onChange={(date) => {
                  dateOnChange(date, field.onChange);
                }}
                showPopperArrow={false}
                dateFormat="yyyy-MM-dd HH:mm"
                timeIntervals={10}
                minDate={new Date()}
                maxDate={
                  endTime
                    ? dayjs(endTime).toDate()
                    : dayjs().add(30, 'days').toDate()
                }
                minTime={
                  isToday
                    ? minStartTime.toDate()
                    : dayjs().startOf('day').toDate()
                }
                maxTime={
                  endTime
                    ? dayjs(endTime).subtract(10, 'minutes').toDate()
                    : dayjs().endOf('day').toDate()
                }
                buttonRef={startDateRef}
                width="w-full"
                showTimeSelect
                selectsStart
              />
            )}
          />
        </div>
        <div className="table-cell whitespace-pre-wrap border-t border-gray-200 px-3 py-4 text-sm text-gray-900">
          <Controller
            control={control}
            name="endTime"
            render={({ field }) => (
              <CustomDatePicker
                selected={field.value ? new Date(field.value) : null}
                onChange={(date) => {
                  dateOnChange(date, field.onChange);
                }}
                showPopperArrow={false}
                dateFormat="yyyy-MM-dd HH:mm"
                timeIntervals={10}
                minDate={
                  startTime ? dayjs(startTime).toDate() : dayjs().toDate()
                }
                maxDate={
                  startTime
                    ? dayjs(startTime).add(30, 'days').toDate()
                    : dayjs().add(30, 'days').toDate()
                }
                minTime={
                  isSameDay
                    ? dayjs(startTime).add(10, 'minutes').toDate()
                    : dayjs().startOf('day').toDate()
                }
                maxTime={dayjs().endOf('day').toDate()}
                buttonRef={endDateRef}
                width="w-full"
                showTimeSelect
                selectsEnd
              />
            )}
          />
        </div>
        <div className="table-cell whitespace-pre-wrap border-t border-gray-200 px-3 py-4 text-sm text-gray-900">
          {reservationInfo.userName}
        </div>
        <div className="table-cell whitespace-pre-wrap border-t border-gray-200 px-3 py-4 text-sm text-gray-900">
          <div className="flex justify-end gap-4 text-sm font-semibold leading-tight">
            <button
              type="button"
              className="text-gray-900 enabled:hover:text-opacity-80"
              onClick={() => {
                setIsEdit(false);
                reset();
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="text-congress-blue enabled:hover:text-opacity-80 disabled:opacity-50"
              disabled={updateDeviceReservationMutation.isLoading}
            >
              {updateDeviceReservationMutation.isLoading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </div>
      </form>
      <CommonDialog
        isOpen={isErrorModalOpen}
        setIsOpen={setIsErrorModalOpen}
        iconType="caution"
        title="Failure"
        subTitle={updateDeviceReservationMutation.error?.message}
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            onClick={() => setIsErrorModalOpen(false)}
          >
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
    </>
  );
};

export default ReservationTimeTableBodyForm;
