import { RootState } from '@app/store';
import {
  ErrorBoundaryWrapper,
  RepeatStepComboboxHeadlessUI,
  StartAndFinishMark,
} from '@autosquare/common';
import { FilteredList } from '@autosquare/common/src/utils/type/repeatBox/type';
import { useRepeatSpeedMobileTestStep } from '@lib/api/ide/speedMobile/speedMobileTestStep';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

type Props = {
  startRepeatIdx: number;
  endRepeatIdx: number;
  filteredList: FilteredList[];
};

const StartCombobox = ({
  startRepeatIdx,
  endRepeatIdx,
  filteredList,
}: Props) => {
  const deviceInfo = useSelector((state: RootState) => state.device.deviceInfo);

  const initialValue =
    startRepeatIdx === 0
      ? { idx: null, label: '', disabled: false }
      : filteredList?.find((item) => item.idx === startRepeatIdx);
  const [selectedValue, setSelectedValue] = useState(initialValue);

  const endIndex = filteredList?.findIndex((item) => item.idx === endRepeatIdx);
  const filteredStartList =
    startRepeatIdx === 0 || endRepeatIdx === 0
      ? filteredList
      : filteredList?.map((item, index) => ({
          ...item,
          disabled: index === endIndex || item.disabled,
        }));

  const saveRepeatTestStep = useRepeatSpeedMobileTestStep();

  const handleChange = (value: FilteredList) => {
    setSelectedValue(value);
    if (value === null) {
      saveRepeatTestStep.mutate({
        startStepIdx: 0,
        endStepIdx: 0,
        mobileOs: deviceInfo.os,
      });
    }
    if (value !== null) {
      saveRepeatTestStep.mutate({
        startStepIdx: value.idx,
        endStepIdx: endRepeatIdx,
        mobileOs: deviceInfo.os,
      });
    }
  };

  useEffect(() => {
    if (startRepeatIdx === 0) {
      setSelectedValue(initialValue);
    }
  }, [startRepeatIdx]);

  return (
    <ErrorBoundaryWrapper>
      <div className="flex items-center gap-2.5">
        <StartAndFinishMark />
        <p className="w-12 text-xs font-medium text-gray-900">Start</p>
        <div className="w-full">
          <RepeatStepComboboxHeadlessUI
            value={selectedValue}
            filteredList={filteredStartList}
            onChange={handleChange}
            findIndex={
              filteredStartList?.find(
                (item) => item?.idx === selectedValue?.idx,
              )?.idx
            }
          />
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default StartCombobox;
