import {
  CreateSchedulerData,
  UpdateSchedulerData,
} from '@customTypes/ide/scheduler/scheduler';
import { MessengerReceiver } from '@customTypes/ide/speedScheduler/speedScheduler';

import { useGetSchedulerMessengerInfoQuery } from '@lib/api/ide/scheduler/scheduler';
import { useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';

export const useSchedulerMessengerAlarm = (
  messengerReceiver?: MessengerReceiver[],
  setValue?: UseFormSetValue<CreateSchedulerData | UpdateSchedulerData>,
) => {
  const [query, setQuery] = useState<string>('');

  const filteredMessengerReceivers = messengerReceiver
    ?.map((item) => item.recipientList)
    ?.filter(Boolean)
    ?.flatMap((item) => {
      const newRecipient = item.split(',').map((item) => {
        const [recipientId, recipientInfo] = item.split('/');
        return recipientInfo ? { recipientId, recipientInfo } : { recipientId };
      });

      return newRecipient;
    });

  const { data: messengerInfo } = useGetSchedulerMessengerInfoQuery();

  const isMessengerActivate = messengerInfo?.[0]?.activate;
  const platformName = messengerInfo?.[0]?.platform;

  const messengerRecipientList = messengerInfo?.[0]?.recipientList
    ?.filter(Boolean)
    ?.map((item) => {
      const [recipientId, recipientInfo] = item.split('/');
      return recipientInfo ? { recipientId, recipientInfo } : { recipientId };
    });

  const filteredList =
    query === ''
      ? messengerRecipientList?.filter(
          (receiver) =>
            !filteredMessengerReceivers?.some(
              (recipient) => recipient?.recipientId === receiver?.recipientId,
            ),
        )
      : messengerRecipientList?.filter(
          (receiver) =>
            !filteredMessengerReceivers?.some(
              (recipient) => recipient?.recipientId === receiver?.recipientId,
            ) &&
            (receiver?.recipientId
              .toLowerCase()
              .includes(query?.toLowerCase()) ||
              receiver?.recipientInfo
                ?.toLowerCase()
                .includes(query?.toLowerCase())),
        );

  const deleteTagItem = (indexToDelete: number) => {
    const deletedMessengerList = filteredMessengerReceivers
      .filter((_, index) => index !== indexToDelete)
      .map(
        (item) =>
          `${item.recipientId}${item.recipientInfo ? '/' : ''}${item.recipientInfo ?? ''}`,
      )
      .join(',');

    if (deletedMessengerList) {
      setValue('messengerReceivers', [
        {
          platformName: platformName,
          recipientList: deletedMessengerList,
        },
      ]);
    } else {
      setValue('messengerReceivers', []);
    }
  };

  return {
    deleteTagItem,
    filteredList,
    platformName,
    query,
    setQuery,
    filteredMessengerReceivers,
    messengerRecipientList,
    isMessengerActivate,
  };
};
