import React from 'react';
import {
  Breadcrumbs,
  CreatePlusButton,
  ErrorBoundaryWrapper,
  PageHeadingButtons,
  PagingHeadingsInAutosquare,
} from '@autosquare/common';
import SearchForm from '@components/SearchForm/SearchForm';
import { useLocation, useNavigate } from 'react-router-dom';
import WebSpeedTestScenarioTable from './components/WebSpeedTestScenarioTable';
import { useSearchWebSpeedTestScenarioListQuery } from '@lib/api/ide/speedWeb/webSpeedTestScenario';
import getFirstGenerationDateToDateType from '@utils/static/getFirstGenerationDateToDateType';

const WebSpeedTestScenario = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const overviewPath = `${pathname.split('/').slice(0, -2).join('/')}/overview`;

  const { data: searchWebSpeedTestScenarioList } =
    useSearchWebSpeedTestScenarioListQuery();

  const initialCreatedAt =
    searchWebSpeedTestScenarioList?.[0]?.initialCreatedAt;

  const formattedCreatedAt = getFirstGenerationDateToDateType(initialCreatedAt);

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'Speed Test', href: overviewPath },
    { title: 'Web', href: pathname },
    { title: 'Test Scenario', href: pathname },
    { title: 'List', href: pathname },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      onClick: () => {
        navigate('create');
      },
      description: 'Create Scenario',
      icon: <CreatePlusButton />,
    },
  ];
  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Scenario List'}
        pageHeadingButtons={pageHeadingButtons}
      />
      <SearchForm initialCreatedAt={formattedCreatedAt} />
      <WebSpeedTestScenarioTable />
    </ErrorBoundaryWrapper>
  );
};

export default WebSpeedTestScenario;
