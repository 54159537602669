import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CreatePlusButton,
  GroupManagement,
  PageHeadingButtons,
  PagingHeadingsInAutosquare,
} from '@autosquare/common';

import WebTestCaseList from './components/WebTestCaseList';
import GroupProvider from '@contexts/ide/GroupProvider';
import GroupContainer from '@components/shared/TestCaseGroup/GroupContainer';
import SearchForm from '@components/SearchForm/SearchForm';
import { useGetFilteredWebTestCaseListQuery } from '@lib/api/ide/web/webTestCase';
import getFirstGenerationDateToDateType from '@utils/static/getFirstGenerationDateToDateType';

const WebTestCase = () => {
  const { pathname, search } = useLocation();
  const currentUrl = pathname + search;
  const webTestCaseListQuery = useGetFilteredWebTestCaseListQuery();
  const webTestCases = webTestCaseListQuery.data;

  const initialCreatedAt = webTestCases?.[0]?.initialCreatedAt;
  const formattedCreatedAt = getFirstGenerationDateToDateType(initialCreatedAt);

  const breadcrumbs = [
    {
      title: 'UI Test',
      href: `${pathname.split('/').slice(0, -2).join('/')}/overview`,
    },
    { title: 'Web', href: currentUrl },
    { title: 'Test Case', href: currentUrl },
    { title: 'List', href: currentUrl },
  ];
  const [isGroupOpened, setIsGroupOpened] = useState(false);
  const navigate = useNavigate();
  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      icon: <CreatePlusButton />,
      variant: 'imageBasic',
      description: 'Create Case',
      onClick: () => navigate('create'),
    },
    {
      icon: <GroupManagement />,
      variant: 'imageBasic',
      description: 'Group Management',
      onClick: () => setIsGroupOpened(true),
    },
  ];

  return (
    <>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Case List'}
        pageHeadingButtons={pageHeadingButtons}
      />
      <SearchForm initialCreatedAt={formattedCreatedAt}>
        <SearchForm.Priority />
      </SearchForm>
      <WebTestCaseList />
      <GroupProvider>
        {isGroupOpened && <GroupContainer closeModal={setIsGroupOpened} />}
      </GroupProvider>
    </>
  );
};

export default WebTestCase;
