import { ErrorBoundaryWrapper } from '@autosquare/common';
import { useProjectSettingDetailQuery } from '@lib/api/dashboard/queryHooks';
import React from 'react';

const DetailInfo = () => {
  const { data: projectSettingDetail } = useProjectSettingDetailQuery();

  const infoList = [
    { title: 'Project Name', data: projectSettingDetail.name },
    {
      title: 'Service Name',
      data: projectSettingDetail.serviceName || '-',
    },
    {
      title: 'Description',
      data: projectSettingDetail.description || '-',
    },
  ];

  const projectAdminList = projectSettingDetail.projectAdminList;

  return (
    <ErrorBoundaryWrapper>
      <dl className="grid grid-cols-3 border-b border-solid border-gray-200 py-4 text-sm font-normal leading-normal text-gray-900">
        {infoList.map((info) => (
          <div key={info.title} className="space-y-2">
            <dt className="font-medium">{info.title}</dt>
            <dd>{info.data}</dd>
          </div>
        ))}
        <div className="col-span-3 w-full space-y-2 pt-6">
          <dt className="font-medium">Project Admin</dt>
          <dd className="flex flex-wrap gap-x-4 gap-y-2">
            {projectAdminList.map((admin) => (
              <div
                key={admin.userIdx}
                className="rounded-md bg-gray-100 px-2 py-1 text-xs font-medium leading-none text-gray-600"
              >{`${admin.email}(${admin.name})`}</div>
            ))}
          </dd>
        </div>
      </dl>
    </ErrorBoundaryWrapper>
  );
};

export default DetailInfo;
