import React from 'react';
import { ListBoxHeadlessInController } from '@autosquare/common';
import { ControllerRenderProps } from 'react-hook-form';
import {
  CreateTestScenarioData,
  UpdateTestScenarioData,
} from '@customTypes/testScenario/type';
import { JiraTestResultList } from '@utils/static/autoRegister/autoRegisterTypeList';

type Props = {
  field: ControllerRenderProps<
    CreateTestScenarioData | UpdateTestScenarioData,
    `autoRegisters.${number}.jira.mapping.${number}.testResult`
  >;
  jiraProjectListLength: number;
  jiraProjectKey: string;
};

const IdeScenarioJiraInputTestResult = ({
  field,
  jiraProjectListLength,
  jiraProjectKey,
}: Props) => {
  return (
    <ListBoxHeadlessInController
      value={field.value}
      buttonValue={field.value === '' ? null : field.value}
      lists={JiraTestResultList}
      valueToSave="serverName"
      valueToShow="serverName"
      onChange={field.onChange}
      location="jira"
      placeholder="Select"
      disabled={jiraProjectListLength === 0 || jiraProjectKey === ''}
    />
  );
};

export default IdeScenarioJiraInputTestResult;
