import { RootState } from '@app/store';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { closeErrorModal } from '@store/ide/remote/remoteConnectionStatusSlice';
import { deleteRemoteInfo } from '@store/ide/remote/remoteControlSlice';
import { UseMutationResult } from '@tanstack/react-query';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const useGetRemoteErrorHandler = <TVariables, TData = unknown>(
  mutation?: UseMutationResult<
    TData,
    Error | ServerResponseErrorDetails,
    TVariables,
    unknown
  >,
) => {
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const navigate = useNavigate();
  const errorOnClose = () => {
    if (
      mutation.isError &&
      mutation.error.message.includes('원격 접속이 이미 종료')
    ) {
      navigate(`/ide/projects/list`);
    }
    setIsErrorModalOpen(false);
  };

  const dispatch = useDispatch();
  const isRemoteConnectionError = useSelector(
    (state: RootState) => state.remoteConnection.isErrorModalOpen,
  );
  const remoteConnectionErrorMessage = useSelector(
    (state: RootState) => state.remoteConnection.errorMessage,
  );

  const onModalCloseHandler = () => {
    dispatch(closeErrorModal());
    dispatch(deleteRemoteInfo());
    navigate(`/ide/projects/list`);
  };

  return {
    isErrorModalOpen,
    setIsErrorModalOpen,
    errorOnClose,
    isRemoteConnectionError,
    remoteConnectionErrorMessage,
    onModalCloseHandler,
  };
};
