import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AutomationAutoOrder,
  CreateTestDataAutomation,
  CreateTestDataAutomationSchema,
} from '@customTypes/testCase/type';

export const useCreateTestDataAutomationForm = () => {
  const methods = useForm<CreateTestDataAutomation>({
    defaultValues: {
      file: null,
      autoOrder: AutomationAutoOrder.Random,
    },
    resolver: zodResolver(CreateTestDataAutomationSchema),
  });

  return methods;
};
