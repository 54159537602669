import { RootState } from '@app/store';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import { MessengerAlarmHeadlessListbox } from '@components/Scheduler/MessengerAlarmHeadlessListbox';
import { UpdateSchedulerData } from '@customTypes/ide/scheduler/scheduler';
import { RecipientData } from '@customTypes/ide/speedScheduler/speedScheduler';
import { useSpeedSchedulerMessengerAlarm } from '@hooks/speedScheduler/useSpeedSchedulerMessengerAlarm';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import SpeedSchedulerDetailMessengerList from './SpeedTestSchedulerDetailMessengerAlarm/SpeedSchedulerDetailMessengerList';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';

const SpeedTestSchedulerDetailMessengerAlarm = () => {
  const isRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );
  const [inputValue, setInputValue] = useState<RecipientData>({
    recipientId: '',
    recipientInfo: '',
  });
  const { setValue, watch } = useFormContext<UpdateSchedulerData>();
  const messengerReceiver = watch('messengerReceivers');

  const {
    filteredMessengerReceivers,
    filteredList,
    platformName,
    deleteTagItem,
  } = useSpeedSchedulerMessengerAlarm(messengerReceiver, setValue);

  useEffect(() => {
    const savedRecipient = messengerReceiver?.[0]?.recipientList ?? '';
    const addedRecipient = `${inputValue?.recipientId}${inputValue?.recipientInfo ? '/' : ''}${inputValue?.recipientInfo ?? ''}`;
    const updatedRecipient = savedRecipient
      ? `${savedRecipient},${addedRecipient}`
      : addedRecipient;
    if (addedRecipient) {
      setValue('messengerReceivers', [
        {
          platformName: platformName,
          recipientList: updatedRecipient,
        },
      ]);
    }
  }, [inputValue]);

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer title={'Messenger Alarm'}>
        <div className="mt-2 sm:col-span-2 sm:mt-0">
          <MessengerAlarmHeadlessListbox
            state={inputValue}
            setState={setInputValue}
            list={filteredList}
            disabled={!isRunning}
          />
          <SpeedSchedulerDetailMessengerList
            isRunning={isRunning}
            messengerList={filteredMessengerReceivers}
            deleteTagItem={deleteTagItem}
          />
        </div>
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default SpeedTestSchedulerDetailMessengerAlarm;
