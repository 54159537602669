import React, { useState } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { Button, ErrorBoundaryWrapper } from '@autosquare/common';
import {
  TestDataAutomationInfo,
  UpdateTestDataAutomation,
} from '@customTypes/testCase/type';
import {
  useDeleteSpeedTestDataAutomationMutation,
  useUpdateSpeedTestDataAutomationMutation,
} from '@lib/api/ide/speedMobile/mobileTestAutomation';
import { useUpdateTestDataAutomationFrom } from '@hooks/index';
import { useNavigate } from 'react-router-dom';
import RegisterTestData from '@components/TestAutomationSettings/common/RegisterTestData';
import RegisterInformation from '@components/TestAutomationSettings/common/RegisterInformation';
import AutomationOrderBy from '@components/TestAutomationSettings/common/AutomationOrderBy';
import UpdateDataAutomationErrorDialog from '@components/TestAutomationSettings/common/UpdateDataAutomationErrorDialog';
import { DeleteDataAutomationConfirmDialog } from '@components/TestAutomationSettings/common/DeleteDataAutomationConfirmDialog';
import DeleteDataAutomationErrorDialog from '@components/TestAutomationSettings/common/DeleteDataAutomationErrorDialog';
import AutomationSettingsUpdatedTime from '@components/TestAutomationSettings/common/AutomationSettingsUpdatedTime';

type Props = {
  testAutomationData: TestDataAutomationInfo;
  testCaseDetailPage: string;
};

const AutomationDisplayDataComponent = ({
  testAutomationData,
  testCaseDetailPage,
}: Props) => {
  const navigate = useNavigate();

  const [isUpdateErrorModalOpen, setIsUpdateErrorModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteErrorModalOpen, setIsDeleteErrorModalOpen] = useState(false);

  const methods = useUpdateTestDataAutomationFrom(testAutomationData);

  const updateAutomationMutation = useUpdateSpeedTestDataAutomationMutation();

  const onSubmit: SubmitHandler<UpdateTestDataAutomation> = (data) => {
    const updatedData = { ...data, filePath: data.file.path || undefined };
    delete updatedData.file;

    updateAutomationMutation.mutate(updatedData, {
      onSuccess: () => navigate(testCaseDetailPage),
      onError: () => {
        setIsUpdateErrorModalOpen(true);
      },
    });
  };

  const deleteAutomationMutation = useDeleteSpeedTestDataAutomationMutation();

  const onDelete = () => {
    deleteAutomationMutation.mutate(testAutomationData.idx, {
      onError: () => {
        setIsDeleteErrorModalOpen(true);
      },
    });
  };

  return (
    <ErrorBoundaryWrapper>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="mt-8 space-y-2 pb-5 sm:flex-col sm:items-center sm:justify-center"
        >
          <RegisterInformation />
          <div className="flex flex-col gap-y-6 pt-4">
            <RegisterTestData />
            <AutomationOrderBy autoOrder={testAutomationData.autoOrder} />
            <AutomationSettingsUpdatedTime
              lastUpdated={testAutomationData.table.lastUpdated}
            />
            <div className="flex items-center justify-end gap-x-2">
              <Button
                type="button"
                variant="secondary"
                onClick={() => navigate(testCaseDetailPage)}
              >
                Cancel
              </Button>
              <Button
                type="button"
                variant="caution"
                onClick={() => setIsDeleteModalOpen(true)}
              >
                Delete
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={updateAutomationMutation.isLoading}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
      <UpdateDataAutomationErrorDialog
        isUpdateErrorModalOpen={isUpdateErrorModalOpen}
        setIsUpdateErrorModalOpen={setIsUpdateErrorModalOpen}
        updateAutomationMutation={updateAutomationMutation}
      />
      <DeleteDataAutomationConfirmDialog
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        isLoading={deleteAutomationMutation.isLoading}
        onDelete={onDelete}
      />
      <DeleteDataAutomationErrorDialog
        isDeleteErrorModalOpen={isDeleteErrorModalOpen}
        setIsDeleteErrorModalOpen={setIsDeleteErrorModalOpen}
        deleteAutomationMutation={deleteAutomationMutation}
      />
    </ErrorBoundaryWrapper>
  );
};

export default AutomationDisplayDataComponent;
