import {
  ErrorBoundaryWrapper,
  ErrorMessage,
  TableBodyBasic,
  TableHeader,
  TableNoList,
  TableRow,
  TableSkeleton,
} from '@autosquare/common';
import NoListResultMessage from '@components/Search/NoListResultMessage';
import { useWebTestResultSearchListQuery } from '@lib/api/dashboard/web/webTestResult';
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';

const WebTestResultList = () => {
  const [searchParams] = useSearchParams();
  const size = Number(searchParams.get('size'));

  const webTestResultSearchListQuery = useWebTestResultSearchListQuery();
  const webTestResultSearchList = webTestResultSearchListQuery.data;

  const headerList = [
    { idx: 1, title: 'No' },
    { idx: 2, title: 'Type' },
    { idx: 3, title: 'Title' },
    { idx: 4, title: 'Tester' },
    { idx: 5, title: 'DateTime', isSortable: true },
    { idx: 6, title: 'Browser' },
    { idx: 7, title: 'Result' },
    { idx: 8, title: 'Detail' },
  ];
  const headerLength = headerList.length;

  return (
    <ErrorBoundaryWrapper>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {headerList.map((header) => (
                    <TableHeader
                      key={header.idx}
                      isSortable={header.isSortable}
                    >
                      {header.title}
                    </TableHeader>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white">
                <ErrorBoundaryWrapper>
                  {webTestResultSearchListQuery.isLoading ? (
                    <TableNoList colSpan={headerLength}>
                      <TableSkeleton />
                    </TableNoList>
                  ) : webTestResultSearchListQuery.isError ? (
                    <TableNoList colSpan={headerLength}>
                      <ErrorMessage>
                        {webTestResultSearchListQuery.error.message}
                      </ErrorMessage>
                    </TableNoList>
                  ) : webTestResultSearchList?.content.length === 0 ? (
                    <NoListResultMessage
                      colSpan={headerLength}
                      testType="result"
                    />
                  ) : (
                    webTestResultSearchList?.content?.map((webTest, index) => (
                      <TableRow key={webTest.idx}>
                        <TableBodyBasic>
                          {(webTestResultSearchList.currentPage - 1) * size +
                            index +
                            1}
                        </TableBodyBasic>
                        <TableBodyBasic>{webTest.type}</TableBodyBasic>
                        <TableBodyBasic>{webTest.scenarioName}</TableBodyBasic>
                        <TableBodyBasic>{webTest.tester}</TableBodyBasic>
                        <TableBodyBasic>{webTest.startTime}</TableBodyBasic>
                        <TableBodyBasic>
                          {webTest.browserName[0].toUpperCase() +
                            webTest.browserName.slice(1)}
                        </TableBodyBasic>
                        <TableBodyBasic>{webTest.result}</TableBodyBasic>
                        <TableBodyBasic>
                          {webTest.result !== 'Running' ? (
                            <Link
                              to={`detail?idx=${webTest.idx}`}
                              className="rounded-md shadow-sm hover:text-congress-blue"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                                />
                              </svg>
                            </Link>
                          ) : (
                            <div className="size-6" />
                          )}
                        </TableBodyBasic>
                      </TableRow>
                    ))
                  )}
                </ErrorBoundaryWrapper>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default WebTestResultList;
