import React from 'react';
import tsquareLogo from '@assets/img/tsquare_logo.png';

const SignUpHeader = () => {
  return (
    <div className="flex w-full items-center justify-center">
      <img src={tsquareLogo} alt="t-square logo" width={212} height={40} />
    </div>
  );
};

export default SignUpHeader;
