import { Button, ErrorBoundaryWrapper } from '@autosquare/common';
import { useParentPath } from '@hooks/index';
import React from 'react';
import { Link } from 'react-router-dom';

type DeviceDetailFormButtonsProps = {
  setIsDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
};

const DeviceDetailFormButtons = ({
  setIsDeleteModalOpen,
  isLoading,
}: DeviceDetailFormButtonsProps) => {
  const deviceListPage = useParentPath();

  return (
    <ErrorBoundaryWrapper>
      <div className="flex items-center justify-end gap-3 pt-6">
        <div className="flex items-center justify-end gap-3">
          <Link to={deviceListPage}>
            <Button type="button" variant="secondary">
              Cancel
            </Button>
          </Link>
          <Button
            type="button"
            variant="caution"
            onClick={() => setIsDeleteModalOpen(true)}
          >
            Delete
          </Button>
          <Button type="submit" disabled={isLoading}>
            Save
          </Button>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default DeviceDetailFormButtons;
