/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import React, { useState } from 'react';
import clsx from 'clsx';
import SpeedTestResultTableDetail from './SpeedTestResultTableDetail/SpeedTestResultTableDetail';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import WebSpeedTestResultTableDetail from '../WebSpeedTestResultTableDetail/WebSpeedTestResultTableDetail';
import { ErrorBoundaryWrapper, TooltipTop } from '@autosquare/common';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { TestResultStatus } from '@customTypes/index';

type Props = {
  data: unknown[];
  columns: ColumnDef<unknown, unknown>[];
};

const SpeedTestResultGraphInfoTable = ({ data, columns }: Props) => {
  const [isDetailOpened, setIsDetailOpened] = useState(false);
  const [sorting, setSorting] = useState<SortingState>();
  const [chartIndex, setChartIndex] = useState<number>(null);
  const isDashboard = !isWordInLocationPathname('speed-test');
  const location = isWordInLocationPathname('web') ? 'web' : 'mobile';

  const table = useReactTable({
    data,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <ErrorBoundaryWrapper>
      <div className="space-y-1">
        <div className="flex items-center gap-x-2.5">
          <div className="text-sm font-medium leading-normal text-black">
            Test Result Table
          </div>
          <div className="group relative">
            <QuestionMarkCircleIcon className="size-5 cursor-help text-red-500" />
            <TooltipTop
              positionClass={'invisible group-hover:visible -left-36 bottom-6'}
            >
              테이블 헤더를 클릭하면 정렬 방식이 오름차순 / 내림차순으로
              변경됩니다.
            </TooltipTop>
          </div>
        </div>
        <div className="max-h-72 overflow-auto rounded-lg border border-gray-200 scrollbar-thin">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="sticky top-0 bg-white">
              {table?.getHeaderGroups()?.map((headerGroup) => (
                <tr key={headerGroup?.id}>
                  {headerGroup?.headers?.map((header) => (
                    <th
                      key={header?.id}
                      scope="col"
                      className={`px-3 py-2 text-left text-sm font-semibold text-gray-900 ${
                        header?.column?.getCanSort() &&
                        'cursor-pointer select-none'
                      }`}
                      onClick={header?.column?.getToggleSortingHandler()}
                    >
                      <span className="flex items-center gap-9">
                        {header?.isPlaceholder
                          ? null
                          : flexRender(
                              header?.column?.columnDef?.header,
                              header?.getContext(),
                            )}
                        {{
                          asc: <FiChevronUp size={16} />,
                          desc: <FiChevronDown size={16} />,
                        }[header?.column?.getIsSorted() as string] ?? null}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white">
              {table?.getRowModel()?.rows?.map((row) => (
                <tr key={row?.id} className="even:bg-gray-50">
                  {row?.getVisibleCells()?.map((cell) => {
                    const cellData = (cell?.getValue() as any)?.data;
                    const cellIdx = (cell?.getValue() as any)?.idx;
                    const status = (cell?.getValue() as any)?.status;
                    const notExecuted =
                      status === TestResultStatus.NotExecuted &&
                      cellData === TestResultStatus.NotExecuted;
                    const cursorDefaultOption =
                      cellIdx === 0 ||
                      cellIdx === -1 ||
                      (cellIdx === '-' && status === TestResultStatus.NA) ||
                      (Number(cellData) === 0 &&
                        status === TestResultStatus.NA) ||
                      notExecuted;
                    const isDetailViewable =
                      cellIdx !== 0 && cellIdx !== -1 && !notExecuted;
                    return (
                      <td
                        key={cell?.id}
                        className={clsx(
                          'whitespace-nowrap p-2 text-sm text-gray-500',
                          Number(cellData) > 3 && 'text-red-500',
                        )}
                      >
                        <button
                          type="button"
                          onClick={() => {
                            if (isDetailViewable) {
                              setIsDetailOpened(true);
                              setChartIndex(cellIdx);
                            }
                          }}
                          className={clsx(
                            cursorDefaultOption
                              ? 'cursor-default'
                              : 'cursor-pointer:hover rounded-2xl hover:bg-sky-100',
                            Number(cellData) === 0 ? 'px-4 py-2' : 'p-2',
                          )}
                        >
                          {Number(cellData) === 0 ? '-' : cellData}
                        </button>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {isDetailOpened &&
          (location === 'mobile' ? (
            <SpeedTestResultTableDetail
              chartIndex={chartIndex}
              isDashboard={isDashboard}
            />
          ) : (
            location === 'web' && (
              <WebSpeedTestResultTableDetail
                chartIndex={chartIndex}
                isDashboard={isDashboard}
              />
            )
          ))}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default SpeedTestResultGraphInfoTable;
