import { Button, ErrorBoundaryWrapper, ErrorMessage } from '@autosquare/common';

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useCreateSchedulerMutation,
  useRemoteCreatedSchedulerMutation,
} from '@lib/api/ide/scheduler/scheduler';
import {
  AutoRegisterInfoData,
  CreateSchedulerData,
  CreateSchedulerSchema,
  SchedulerTypeServerName,
} from '@customTypes/ide/scheduler/scheduler';
import CreateSchedulerTestType from './CreateSchedulerForm/CreateSchedulerTestType';
import CreateSchedulerScenario from './CreateSchedulerForm/CreateSchedulerScenario';
import CreateSchedulerSchedule from './CreateSchedulerForm/CreateSchedulerSchedule';
import CreateSchedulerEmailAlarm from './CreateSchedulerForm/CreateSchedulerEmailAlarm';
import CreateSchedulerTitle from './CreateSchedulerForm/CreateSchedulerTitle';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import CreateSchedulerBrowser from './CreateSchedulerBrowser';
import CreateSchedulerDevice from './CreateSchedulerForm/CreateSchedulerDevice';
import CreateSchedulerMessenger from './CreateSchedulerForm/CreateSchedulerMessenger';
import { useSchedulerMessengerAlarm } from '@hooks/scheduler/useSchedulerMessengerAlarm';
import CreateSchedulerResultSaveOptions from './CreateSchedulerForm/CreateSchedulerResultSaveOptions';
import CreateAutoRegister from './CreateSchedulerForm/CreateAutoRegister';
import { useGetAutoRegistersInfoQuery } from '@lib/api/ide/autoRegisters/autoRegisters';
import { Mode } from '@customTypes/ide/remote/type';
import { getRemoteState } from '@utils/static/getConfiguration';
import CreateSchedulerDeviceReboot from './CreateSchedulerForm/CreateSchedulerDeviceReboot';

const CreateSchedulerForm = () => {
  const { projectIdx } = useParams();
  const navigate = useNavigate();
  const [autoRegistersList, setAutoRegistersList] = useState<
    AutoRegisterInfoData[]
  >([]);
  const methods = useForm<CreateSchedulerData>({
    defaultValues: {
      projectIdx: Number(projectIdx),
      type: SchedulerTypeServerName.Mobile,
      mailReceivers: [],
      autoRegister: true,
      messengerReceivers: [],
      autoRegisters: [],
      deviceReboot: false,
    },
    resolver: zodResolver(CreateSchedulerSchema),
  });
  const { configuration } = getRemoteState();
  const createSchedulerMutation =
    configuration === Mode.Standard
      ? useCreateSchedulerMutation()
      : useRemoteCreatedSchedulerMutation();

  const onSubmit: SubmitHandler<CreateSchedulerData> = (data) => {
    const updatedData = {
      ...data,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      autoRegisters: autoRegistersList.map(({ id, ...rest }) => rest),
    };

    if (
      updatedData.type === SchedulerTypeServerName.Mobile &&
      updatedData?.deviceInfoDto === undefined
    ) {
      methods.setError('deviceInfoDto', {
        type: 'required',
        message: 'Device를 선택해 주세요.',
      });
    } else if (
      updatedData.type === SchedulerTypeServerName.Web &&
      !updatedData.browserInfoDto
    ) {
      methods.setError('browserInfoDto', {
        type: 'required',
        message: 'Browser를 선택해 주세요.',
      });
    } else if (updatedData.scenarioIdxList.length === 0) {
      methods.setError('scenarioIdxList', {
        type: 'required',
        message: '시나리오를 선택해 주세요.',
      });
    } else {
      createSchedulerMutation.mutate(updatedData);
    }
  };
  const { isMessengerActivate } = useSchedulerMessengerAlarm();

  const { data: autoRegisterInfo } = useGetAutoRegistersInfoQuery();
  const isAutoRegistersActivate = autoRegisterInfo?.length > 0;

  return (
    <ErrorBoundaryWrapper>
      <FormProvider {...methods}>
        <form
          noValidate
          onSubmit={methods.handleSubmit(onSubmit)}
          className="mt-4 flow-root"
        >
          <div className="space-y-8 border-b border-gray-900/10 pb-12">
            <CreateSchedulerTestType />
            <CreateSchedulerTitle />
            <CreateSchedulerScenario />
            <CreateSchedulerDevice />
            <CreateSchedulerBrowser />
            <CreateSchedulerSchedule />
            <CreateSchedulerResultSaveOptions />
            <CreateSchedulerDeviceReboot />
            <CreateSchedulerEmailAlarm />
            {isMessengerActivate && <CreateSchedulerMessenger />}
            {isAutoRegistersActivate && (
              <CreateAutoRegister
                autoRegistersList={autoRegistersList}
                setAutoRegistersList={setAutoRegistersList}
              />
            )}
          </div>
          <div className="flex items-center justify-end gap-6 border-t border-solid border-gray-200 py-4">
            {createSchedulerMutation.isError && (
              <ErrorMessage>
                {createSchedulerMutation.error.message}
              </ErrorMessage>
            )}
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <div className="flex items-center justify-between gap-x-6">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={createSchedulerMutation.isLoading}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </ErrorBoundaryWrapper>
  );
};

export default CreateSchedulerForm;
