import React from 'react';
import { Link } from 'react-router-dom';

const SignUpFooter = () => {
  return (
    <div className="mt-6 border-t border-solid border-gray-200 pt-6 text-center">
      <Link
        to="/login"
        className="hover:text-congress-blue text-sm leading-5 text-gray-500"
      >
        로그인 화면으로 이동하기
      </Link>
    </div>
  );
};

export default SignUpFooter;
