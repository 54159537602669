import { ErrorBoundaryWrapper } from '@autosquare/common';
import WebTestStepProvider from '@contexts/ide/WebTestStepProvider';
import React, { useEffect } from 'react';

import WebAddTestStep from './components/WebAddTestStep';
import WebTestStepList from './components/WebTestStepList';

const WebAddTestStepAndList = () => {
  // body 태그의 overflow 로 인해 dataValidation의 useFieldArray가 추가 될 때 의도치 않은 스크롤이 발생하여 body태그 class 수정
  useEffect(() => {
    const originalClass = document.body.className;
    document.body.className = 'overflow-hidden';
    return () => {
      document.body.className = originalClass;
    };
  }, []);

  return (
    <ErrorBoundaryWrapper>
      <div className="flex h-screen gap-2 overflow-hidden">
        <WebTestStepProvider>
          <WebAddTestStep />
          <WebTestStepList />
        </WebTestStepProvider>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default WebAddTestStepAndList;
