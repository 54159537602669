import { useEffect } from 'react';
import { ProjectListDetailFeaturesCategory } from '@customTypes/dashboard/project/type';
import { checkIsProjectFeatureIncluded } from '@utils/static/checkProjectFeature';
import { checkIsProjectFeatureIncludedAll } from '@utils/static/checkProjectFeature';
import { useSelector } from 'react-redux';
import {
  useFunctionalTestOverviewQuery,
  useGetResultRateQuery,
  useGetSpeedTestResultRateQuery,
} from '@lib/api/dashboard/overview/overview';
import { useState } from 'react';
import { RootState } from '@app/store';
import { ProjectListDetailFeaturesFeature } from '@customTypes/dashboard/project/type';
import { LocationCategory } from '@lib/api/dashboard/overview/overview';
import { useGetMobileSpeedTestResultList } from '@lib/api/dashboard/mobile/mobileSpeedResult';
import { useWebSpeedTestResultListQuery } from '@lib/api/dashboard/web/webSpeedResult';

export const useCommonDashboardOverview = () => {
  const PAGE = 1;
  const SIZE = 5;

  const projectList = useSelector(
    (state: RootState) => state.projectList.projectList,
  );
  const isProjectListReady = Boolean(projectList);

  const isUITestEnabled = checkIsProjectFeatureIncludedAll(
    projectList,
    ProjectListDetailFeaturesFeature.Functional,
  );
  const isSpeedTestEnabled = checkIsProjectFeatureIncludedAll(
    projectList,
    ProjectListDetailFeaturesFeature.Speed,
  );
  const isBothTestEnabled = isUITestEnabled && isSpeedTestEnabled;

  const isMobileTestSpeedEnabled = checkIsProjectFeatureIncluded(
    projectList,
    ProjectListDetailFeaturesCategory.Mobile,
    ProjectListDetailFeaturesFeature.Speed,
  );
  const isWebTestSpeedEnabled = checkIsProjectFeatureIncluded(
    projectList,
    ProjectListDetailFeaturesCategory.Web,
    ProjectListDetailFeaturesFeature.Speed,
  );
  const initialSelectedCategory =
    isProjectListReady && isUITestEnabled
      ? LocationCategory.UI
      : LocationCategory.SPEED;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [categorySelectedIndex, setCategorySelectedIndex] = useState(0);
  const [selectedTestCategory, setSelectedTestCategory] =
    useState<LocationCategory>(initialSelectedCategory);

  const functionalTestOverviewQuery = useFunctionalTestOverviewQuery({
    enabled: selectedTestCategory === LocationCategory.UI,
  });
  const mobileFunctionalResults =
    functionalTestOverviewQuery.data?.mobileResults;
  const webFunctionalResults = functionalTestOverviewQuery.data?.webResults;
  const mobileSpeedResultQuery = useGetMobileSpeedTestResultList(PAGE, SIZE, {
    enabled:
      selectedTestCategory === LocationCategory.SPEED &&
      isMobileTestSpeedEnabled,
  });

  const mobileSpeedResult = mobileSpeedResultQuery?.data;
  const webSpeedResultQuery = useWebSpeedTestResultListQuery(PAGE, {
    enabled:
      selectedTestCategory === LocationCategory.SPEED && isWebTestSpeedEnabled,
  });
  const webSpeedResult = webSpeedResultQuery?.data;

  const resultRateQuery =
    selectedTestCategory === LocationCategory.UI
      ? useGetResultRateQuery(selectedTestCategory)
      : useGetSpeedTestResultRateQuery(selectedTestCategory);
  const reportData = resultRateQuery.data;

  const isInitialLoading =
    (selectedTestCategory === LocationCategory.UI &&
      functionalTestOverviewQuery.isLoading) ||
    (selectedTestCategory === LocationCategory.SPEED &&
      (mobileSpeedResultQuery.isLoading || webSpeedResultQuery.isLoading)) ||
    resultRateQuery.isLoading;

  const isDataFetching =
    (selectedTestCategory === LocationCategory.UI &&
      functionalTestOverviewQuery.isFetching) ||
    (selectedTestCategory === LocationCategory.SPEED &&
      (mobileSpeedResultQuery.isFetching || webSpeedResultQuery.isFetching)) ||
    resultRateQuery.isFetching;

  useEffect(() => {
    setSelectedTestCategory(initialSelectedCategory);
  }, [projectList]);

  return {
    mobileFunctionalResults,
    webFunctionalResults,
    reportData,
    selectedIndex,
    setSelectedIndex,
    categorySelectedIndex,
    setCategorySelectedIndex,
    selectedTestCategory,
    setSelectedTestCategory,
    mobileSpeedResultQuery,
    webSpeedResultQuery,
    resultRateQuery,
    functionalTestOverviewQuery,
    mobileSpeedResult,
    webSpeedResult,
    isDataFetching,
    isBothTestEnabled,
    isUITestEnabled,
    isInitialLoading,
  };
};
