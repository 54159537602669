import React from 'react';

import companyLogo from '@assets/img/logo_icon.png';

const TsquareLogo = () => {
  return (
    <div className="flex shrink-0 items-center">
      <img
        className="block h-6 w-auto lg:hidden"
        src={companyLogo}
        alt="T-SQUARE"
      />
      <img
        className="hidden h-6 w-auto lg:block"
        src={companyLogo}
        alt="T-SQUARE"
      />
      <span className="ml-4 font-semibold text-gray-800">TSQUARE</span>
    </div>
  );
};

export default TsquareLogo;
