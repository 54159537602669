import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  UpdateAdministrationDevice,
  UpdateAdministrationDeviceSchema,
} from '@customTypes/index';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useAdminDeviceDetailQuery,
  useAllAdminProjectListQuery,
  useUpdateAdminDeviceMutation,
  useUpdateAdminDeviceStatusMutation,
} from '@lib/api/dashboard/queryHooks';
import { adminDevicesKeys } from '@queries/index';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import AdminDeviceDetailFormButtons from './AdminDeviceDetailForm/AdminDeviceDetailFormButtons';
import DeviceIdFields from './AdminDeviceDetailForm/DeviceIdFields';
import DeviceModelFields from './AdminDeviceDetailForm/DeviceModelFields';
import DeviceNameFields from './AdminDeviceDetailForm/DeviceNameFields';
import DeviceOsFields from './AdminDeviceDetailForm/DeviceOsFields';
import DeviceTypeFields from './AdminDeviceDetailForm/DeviceTypeFields';
import ProjectFields from './AdminDeviceDetailForm/ProjectFields';
import StatusField from './AdminDeviceDetailForm/StatusField';
import { ErrorDialog } from '@components/Dialog';
import UpdateDeviceDisableDialog from './AdminDeviceDetailForm/UpdateDeviceDisableDialog';

type Props = {
  adminDeviceListPage: string;
};

const AdminDeviceDetailForm = ({ adminDeviceListPage }: Props) => {
  const navigate = useNavigate();

  const [isUpdateStatusErrorDialogOpen, setIsUpdateStatusErrorDialogOpen] =
    useState(false);
  const [isUpdateDeviceErrorDialogOpen, setIsUpdateDeviceErrorDialogOpen] =
    useState(false);
  const [isDisabledDevice, setIsDisabledDevice] = useState(false);

  const { data: adminDeviceDetail } = useAdminDeviceDetailQuery();
  const { data: adminProjectList } = useAllAdminProjectListQuery();

  const findIdxFromTitle = (title: string) =>
    adminProjectList.find((project) => project.name === title)?.idx;

  const methods = useForm<UpdateAdministrationDevice>({
    defaultValues: {
      udid: adminDeviceDetail.udid,
      deviceModel: adminDeviceDetail.deviceModel,
      deviceName: adminDeviceDetail.deviceName || undefined,
      deviceOs: adminDeviceDetail.deviceOs,
      deviceType: adminDeviceDetail.deviceType || undefined,
      projectIdx: findIdxFromTitle(adminDeviceDetail.projectName),
      deviceEnabled: adminDeviceDetail.deviceEnabled,
    },
    resolver: zodResolver(UpdateAdministrationDeviceSchema),
  });

  const updateAdminDeviceStatusMutation = useUpdateAdminDeviceStatusMutation();
  const updateAdminDeviceMutation = useUpdateAdminDeviceMutation();

  const queryClient = useQueryClient();

  const onSubmit: SubmitHandler<UpdateAdministrationDevice> = (data) => {
    if (data.deviceEnabled) {
      updateAdminDeviceStatusMutation.mutate(
        { idx: adminDeviceDetail.idx, enabled: true },
        {
          onSuccess: () => {
            updateAdminDeviceMutation.mutate(data, {
              onError: () => {
                setIsUpdateDeviceErrorDialogOpen(true);
              },
            });
          },
          onError: () => {
            setIsUpdateStatusErrorDialogOpen(true);
          },
        },
      );
    } else {
      setIsDisabledDevice(true);
    }
  };

  const disabledOnClick = () => {
    updateAdminDeviceStatusMutation.mutate(
      { idx: adminDeviceDetail.idx, enabled: false },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(adminDevicesKeys.all);
          navigate(adminDeviceListPage);
        },
        onError: () => {
          setIsUpdateStatusErrorDialogOpen(true);
        },
      },
    );
  };

  return (
    <ErrorBoundaryWrapper>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="space-y-6 border-b border-solid border-gray-200 py-6">
            <StatusField />
            <DeviceIdFields />
            <DeviceModelFields />
            <DeviceNameFields />
            <DeviceOsFields />
            <DeviceTypeFields />
            <ProjectFields />
          </div>
          <AdminDeviceDetailFormButtons
            adminDeviceListPage={adminDeviceListPage}
            updateAdminDeviceStatusMutation={updateAdminDeviceStatusMutation}
            updateAdminDeviceMutation={updateAdminDeviceMutation}
          />
        </form>
      </FormProvider>
      {/* 상태 변경 실패 Dialog */}
      <ErrorDialog
        isOpen={isUpdateStatusErrorDialogOpen}
        setIsOpen={setIsUpdateStatusErrorDialogOpen}
        subTitle={updateAdminDeviceStatusMutation.error?.message}
      />
      {/* 디바이스 수정 실패 Dialog */}
      <ErrorDialog
        isOpen={isUpdateDeviceErrorDialogOpen}
        setIsOpen={setIsUpdateDeviceErrorDialogOpen}
        subTitle={updateAdminDeviceMutation.error?.message}
      />
      {/* 디바이스 비활성화 토글 선택 시 모달 */}
      <UpdateDeviceDisableDialog
        isDisabledDevice={isDisabledDevice}
        setIsDisabledDevice={setIsDisabledDevice}
        disabledOnClick={disabledOnClick}
      />
    </ErrorBoundaryWrapper>
  );
};

export default AdminDeviceDetailForm;
