import {
  CardListSkeleton,
  ErrorBoundaryWrapper,
  ScenarioListDetailData,
  useCheckboxWithDnd,
  useInput,
} from '@autosquare/common';

import React, { useEffect, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

import DetailListItemCard from './DetailDraggable/DetailListItemCard';
import clsx from 'clsx';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';
import { UpdateSchedulerData } from '@customTypes/ide/scheduler/scheduler';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';
import TransferItemButton from '@components/shared/TransferItemButton';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import SelectionHeaderWithCheckbox from '@components/shared/SelectionHeaderWithCheckbox';
import SchedulerDetailScenarioListBox from './DetailDraggable/SchedulerDetailScenarioListBox';

interface Props {
  testScenarioQuery: UseQueryResult<ScenarioListDetailData[], Error>;
  scenarioList: number[];
}

const DetailDraggable = ({ testScenarioQuery, scenarioList }: Props) => {
  const {
    formState: { errors },
  } = useFormContext<UpdateSchedulerData>();

  const [copyTestScenarios, setCopyTestScenarios] = useState<
    ScenarioListDetailData[]
  >([]);
  const [testScenarios, setTestScenarios] = useState<
    ScenarioListDetailData[] | null
  >([]);
  const [searchWord, onChangeSearchWord, setSearchWord] = useInput('');

  const searchResultArray = testScenarios?.filter((testScenario) =>
    testScenario.title.toLowerCase().includes(searchWord.toLowerCase()),
  );
  const { selectAllArray, selectArray, selectedArray, resetSelectedArray } =
    useCheckboxWithDnd(testScenarios, 'idx', 'dndIndex');

  const hasSchedulerRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );

  const allTestScenarios = testScenarioQuery.data;

  const stepArrLists = scenarioList?.map((scenario) =>
    allTestScenarios?.find(
      (allTestScenario) => allTestScenario?.idx === scenario,
    ),
  );

  useEffect(() => {
    setCopyTestScenarios(
      stepArrLists?.map((stepArrList, index) => ({
        ...stepArrList,
        dndIndex: index + 1,
      })),
    );
    setTestScenarios(
      allTestScenarios?.map((testScenario, index) => ({
        ...testScenario,
        dndIndex: index + 1,
      })),
    );
  }, [allTestScenarios, scenarioList]);

  const copyAndDropList = [
    {
      title: 'All Test Scenario List',
      list:
        testScenarios?.length === 0 ? (
          <div className="flex h-2/3 items-center justify-center">
            추가 가능한 테스트 시나리오가 존재하지 않습니다.
          </div>
        ) : (
          <>
            <SelectionHeaderWithCheckbox
              selectAllArray={selectAllArray}
              selectedArray={selectedArray}
              total={testScenarios?.length}
              disabled={!hasSchedulerRunning}
            />
            <div className="gray-scrollbar max-h-[13.375rem]">
              {searchResultArray?.map((testScenario) => (
                <DetailListItemCard
                  key={testScenario.idx}
                  testScenario={testScenario}
                  selectArray={selectArray}
                  selectedArray={selectedArray}
                />
              ))}
            </div>
          </>
        ),
    },
    {
      title: 'add button',
      list: (
        <TransferItemButton
          items={testScenarios}
          copyItems={copyTestScenarios}
          setCopyItems={setCopyTestScenarios}
          selectedArray={selectedArray}
          resetSelectedArray={resetSelectedArray}
          disabled={!hasSchedulerRunning}
        />
      ),
    },
    {
      title: 'Scheduler Scenario List',
      list: (
        <SchedulerDetailScenarioListBox
          copyTestScenarios={copyTestScenarios}
          setCopyTestScenarios={setCopyTestScenarios}
        />
      ),
    },
  ];

  if (testScenarioQuery.isLoading || testScenarioQuery.isFetching) {
    return <CardListSkeleton />;
  }
  if (testScenarioQuery.isError) {
    return (
      <div className="flex items-center justify-center">
        {testScenarioQuery.error.message}
      </div>
    );
  }
  return (
    <ErrorBoundaryWrapper>
      <div className="flex w-full items-center gap-4">
        {copyAndDropList?.map((item, index) => (
          <div className={clsx(index !== 1 && 'w-full')} key={item.title}>
            <div className="mb-2 flex justify-between">
              {index !== 1 && (
                <p className="font-medium leading-6">{item.title}</p>
              )}
            </div>
            <div
              className={clsx(
                index !== 1 && 'h-80 rounded-md border border-solid',
              )}
            >
              {index === 0 && (
                <div className="relative w-full px-2 pb-1.5 pt-2">
                  <MagnifyingGlassIcon className="absolute left-4 top-4 size-4" />
                  <input
                    className="input-sm px-8 disabled:bg-gray-100"
                    value={searchWord}
                    onChange={onChangeSearchWord}
                    disabled={!hasSchedulerRunning}
                  />
                  <XMarkIcon
                    className={clsx(
                      'absolute right-4 top-4 size-4',
                      hasSchedulerRunning && 'cursor-pointer',
                    )}
                    onClick={() => setSearchWord('')}
                  />
                </div>
              )}
              {item.list}
            </div>
          </div>
        ))}
      </div>
      <ErrorMessage
        name="scenarioIdxList"
        errors={errors}
        render={({ message }) => (
          <p className="standard-error-message">{message}</p>
        )}
      />
    </ErrorBoundaryWrapper>
  );
};

export default DetailDraggable;
