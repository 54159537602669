import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  CommonDialog,
  DialogButton,
  DialogButtonPanels,
  DialogPanels,
} from '@components/Dialog';
import { RESET_DELAY_MS } from '@constants/index';
import {
  DeleteProjectDevice,
  DeleteProjectDeviceSchema,
} from '@customTypes/index';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDeleteDeviceRequestMutation } from '@lib/api/dashboard/queryHooks';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeDialog: () => void;
  selectedArray?: number[];
  resetSelectedArray?: () => void;
};

const DeviceDeleteRequestDialog = ({
  isOpen,
  setIsOpen,
  closeDialog,
  resetSelectedArray,
  selectedArray,
}: Props) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const idxLst = selectedArray ?? [Number(idx)];

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<DeleteProjectDevice>({
    values: { idxList: idxLst },
    resolver: zodResolver(DeleteProjectDeviceSchema),
  });

  const deleteDeviceRequestMutation = useDeleteDeviceRequestMutation();

  const onSubmit: SubmitHandler<DeleteProjectDevice> = (data) => {
    deleteDeviceRequestMutation.mutate(data, {
      onSuccess: () => {
        if (resetSelectedArray) resetSelectedArray();
        setIsSuccessDialogOpen(true);
      },
      onError: () => {
        setIsErrorDialogOpen(true);
      },
    });
  };

  const onClose = () => {
    if (deleteDeviceRequestMutation.isError) {
      setTimeout(() => {
        deleteDeviceRequestMutation.reset();
      }, RESET_DELAY_MS);
    }
    reset();
    if (isOpen) closeDialog();
    if (isSuccessDialogOpen) setIsSuccessDialogOpen(false);
    if (isErrorDialogOpen) setIsErrorDialogOpen(false);
  };

  return (
    <ErrorBoundaryWrapper>
      <CommonDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        iconType={'checked'}
        title={'Approval Request'}
        subTitle={'디바이스 삭제 요청 사유를 입력하실 수 있습니다.'}
        onClose={onClose}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogPanels>
            <input className="input-base" {...register('reason')} />
            <ErrorMessage
              errors={errors}
              name="reason"
              render={({ message }) => (
                <p className="error-message pt-2">{message}</p>
              )}
            />
          </DialogPanels>
          <DialogButtonPanels>
            <DialogButton
              variant="primary"
              type="submit"
              disabled={deleteDeviceRequestMutation.isLoading}
            >
              Submit
            </DialogButton>
            <DialogButton variant="secondary" type="button" onClick={onClose}>
              Cancel
            </DialogButton>
          </DialogButtonPanels>
        </form>
      </CommonDialog>
      <CommonDialog
        isOpen={isErrorDialogOpen}
        setIsOpen={setIsErrorDialogOpen}
        iconType={'caution'}
        title={
          deleteDeviceRequestMutation.isError &&
          deleteDeviceRequestMutation.error.message.includes(
            '승인 대기중인 디바이스',
          )
            ? 'Approval Already Pending'
            : 'Approval Failure'
        }
        subTitle={
          deleteDeviceRequestMutation.isError &&
          deleteDeviceRequestMutation.error.message
        }
        onClose={onClose}
      >
        <DialogButtonPanels>
          <DialogButton variant="primary" type="button" onClick={onClose}>
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
      <CommonDialog
        isOpen={isSuccessDialogOpen}
        setIsOpen={setIsSuccessDialogOpen}
        iconType={'checked'}
        title={'Approval Success'}
        subTitle={'디바이스 승인 요청이 완료되었습니다.'}
        onClose={onClose}
      >
        <DialogButtonPanels>
          <DialogButton variant="primary" type="button" onClick={onClose}>
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default DeviceDeleteRequestDialog;
