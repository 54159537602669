import { ErrorBoundaryWrapper } from '@autosquare/common';
import React from 'react';
import ReservationDeviceListHeader from './components/ReservationDeviceListHeader';
import ReservationDeviceMain from './components/ReservationDeviceMain';

const ReservationDeviceList = () => {
  return (
    <ErrorBoundaryWrapper>
      <ReservationDeviceListHeader />
      <ReservationDeviceMain />
    </ErrorBoundaryWrapper>
  );
};

export default ReservationDeviceList;
