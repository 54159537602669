import { Button, ErrorBoundaryWrapper } from '@autosquare/common';
import { UpdateAdminProjectDetailData } from '@customTypes/dashboard/admin/type';
import { ProjectListDetail } from '@customTypes/dashboard/project/type';
import { TrashIcon } from '@heroicons/react/20/solid';
import { ErrorMessage } from '@hookform/error-message';
import {
  useAdminAvailableMessengerListQuery,
  useSendTestMessengerAlarmMutation,
} from '@lib/api/dashboard/queryHooks';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

type Props = {
  adminProjectData: ProjectListDetail;
  changePlatformCount: number;
};

const EditMessengerRecipientList = ({
  adminProjectData,
  changePlatformCount,
}: Props) => {
  const {
    register,
    control,
    setValue,
    setError,
    resetField,
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext<UpdateAdminProjectDetailData>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'recipientFormat',
  });

  const recipientId = useWatch({ control, name: 'recipientId' });
  const recipientInfo = useWatch({ control, name: 'recipientInfo' });

  const requestUrl = watch('messengers.0.requestUrl');
  const senderId = watch('messengers.0.sender');
  const officeToken = watch('messengers.0.officeToken');

  const sendTestMessengerAlarm = useSendTestMessengerAlarmMutation();
  const messengerData = watch('messengers');

  const messengerListQuery = useAdminAvailableMessengerListQuery();
  const messengerList = messengerListQuery.data;

  const defaultPlatform = adminProjectData?.messengers[0]?.platform;
  const defaultRecipientList = adminProjectData?.messengers[0]?.recipientList;
  const platform = watch('messengers.0.platform');
  const platformCode = messengerList?.find(
    (messenger) => messenger.serverName === platform,
  )?.platformCode;

  const [recipientList, setRecipientList] = useState<string[]>(
    changePlatformCount === 0 ? (defaultRecipientList ?? []) : [],
  );

  const errorsName = errors.recipientId
    ? 'recipientId'
    : errors.recipientInfo
      ? 'recipientInfo'
      : errors.recipientFormat && 'recipientFormat';
  const disabledOptions =
    platformCode === 'MSGR01'
      ? !requestUrl || !fields.length || !officeToken
      : platformCode === 'MSGR02'
        ? !requestUrl
        : (platformCode === 'MSGR10' ||
            platformCode === 'MSGR11' ||
            platformCode === 'MSGR12') &&
          (!requestUrl || !fields.length || !senderId);

  const addRecipient = () => {
    if (!recipientId?.trim()) {
      setError('recipientId', {
        type: 'required',
        message: 'Recipient ID는 필수 입력 항목입니다.',
      });
      return;
    }
    if (recipientInfo && !recipientInfo?.match(/^[^,/]{1,50}$/)) {
      setError('recipientInfo', {
        type: 'regex',
        message:
          'Recipient Info는 1~50자, 영문 대소문자, 한글, 숫자, -_. 만 입력 가능합니다.',
      });
      return;
    }
    append({
      dataId: recipientId,
      info: recipientInfo,
    });
    const newRecipient = [recipientId, recipientInfo].filter(Boolean).join('/');
    const updatedList = [...(recipientList || []), newRecipient];
    resetField('recipientId');
    resetField('recipientInfo');
    setRecipientList(updatedList);
    setValue('messengers.0.recipientList', updatedList);
  };

  const removeRecipient = (index: number) => {
    setRecipientList((prevList) => prevList?.filter((_, idx) => idx !== index));
    setValue(
      'messengers.0.recipientList',
      recipientList?.filter((_, idx) => idx !== index),
    );
    remove(index);
  };

  const recipientInputList = [
    {
      label: 'Recipient ID*',
      id: 'recipientId',
      placeholder: '수신자 데이터를 입력해주세요. (ex. 사번, 아이디 등)',
    },
    {
      label:
        'Recipient Info (1~50자, 영문 대소문자, 한글, 숫자, -_. 입력 가능)',
      id: 'recipientInfo',
      placeholder: '표시할 수신자 정보를 입력해 주세요. (ex. 이름, 부서명 등)',
    },
  ];

  useEffect(() => {
    if (recipientId !== '') {
      clearErrors('recipientId');
    }
  }, [clearErrors, recipientId]);

  useEffect(() => {
    if (recipientInfo?.match(/^[^,/]{1,50}$/)) {
      clearErrors('recipientInfo');
    }
  }, [clearErrors, recipientInfo]);

  useEffect(() => {
    if (platform !== defaultPlatform) {
      setRecipientList([]);
      resetField('recipientFormat');
      remove();
    }
  }, [platform, remove, setRecipientList, resetField]);

  useEffect(() => {
    if (fields.length === 0) {
      setError('recipientFormat', {
        message: 'Recipient ID는 필수 입력 항목입니다.',
      });
      return;
    }
    clearErrors('recipientFormat');
  }, [fields, setError, clearErrors]);

  return (
    <ErrorBoundaryWrapper>
      <div className="flex items-center gap-x-3 py-5">
        <h2 className="text-sm font-medium leading-tight text-gray-900">
          Recipient List
        </h2>
        <p className="text-sm text-gray-500">
          알림 수신자를 등록하고 관리할 수 있습니다.
        </p>
      </div>
      <div
        className={clsx(
          'flex items-end justify-start gap-x-4',
          errors?.recipientId ||
            errors?.recipientInfo ||
            errors?.recipientFormat
            ? 'pb-2'
            : 'pb-7',
        )}
      >
        {recipientInputList?.map((item, index) => (
          <React.Fragment key={item.id}>
            <div className="flex w-full flex-col gap-y-1">
              <label className="mb-1 text-sm font-medium leading-tight text-gray-900">
                {item.label}
              </label>
              <input
                type="text"
                id={item.id}
                placeholder={item.placeholder}
                className="input-base w-full"
                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                {...register(item.id as keyof UpdateAdminProjectDetailData, {
                  pattern: {
                    value: /^[^,/]{1,50}$/,
                    message: '콤마(,)와 슬래시(/)는 입력할 수 없습니다.',
                  },
                })}
              />
            </div>
            {index === 0 && (
              <div className="text-sm font-medium text-gray-900">/</div>
            )}
          </React.Fragment>
        ))}
        <Button type="button" variant="secondary" onClick={addRecipient}>
          Add
        </Button>
      </div>
      <ErrorMessage
        errors={errors}
        name={errorsName}
        render={({ message }) => (
          <p className="error-message py-2">{message}</p>
        )}
      />
      {fields.length > 0 && (
        <ul className="mb-6 space-y-3 rounded-md p-2.5 shadow-sm ring-1 ring-inset ring-gray-300">
          {fields?.map((recipient, index) => (
            <li
              key={recipient.id}
              className="flex items-center justify-between gap-x-3 px-3 text-sm font-medium leading-tight text-gray-900"
            >
              <div className="flex gap-x-10">
                <span>{index + 1}</span>
                <span>
                  {recipient.dataId}
                  {recipient.info && ` / ${recipient.info}`}
                </span>
              </div>
              <Button
                type="button"
                variant="imageBasic"
                onClick={() => removeRecipient(index)}
              >
                <TrashIcon aria-hidden="true" className="size-5" />
              </Button>
            </li>
          ))}
        </ul>
      )}
      <div className="flex justify-center pt-7">
        <Button
          type="button"
          variant="gray"
          disabled={disabledOptions}
          onClick={() => sendTestMessengerAlarm.mutate(messengerData[0])}
        >
          Send Test Alarm
        </Button>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default EditMessengerRecipientList;
