import {
  ArrowSpinner,
  Button,
  DoubleCheckDialogHeadlessUI,
  PlatFormTypeServerName,
} from '@autosquare/common';

import DescriptionList from '@components/shared/MobileWebTestStep/DescriptionList';
import TestStepDetailTableDataCell from '@components/shared/MobileWebTestStep/TestStepDetailTableDataCell';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';
import RemoteTestExecutionAlarmModal from '@components/TestExecution/shared/RemoteTestExecutionAlarmModal';
import TestExecutionContainer from '@components/TestExecution/TestExecutionContainer';
import { MobileWebSpeedTestStepData } from '@customTypes/ide/speedTest/Mobile/webTestStep/type';
import { useRemoteMobileTestExecutionMutation } from '@lib/api/ide/remote/remote';

import { useDeleteMobileWebSpeedTestStepMutation } from '@lib/api/ide/speedMobile/mobileWebTestStep';

import { findTextReplaceAddNameByServerName } from '@utils/static/mobileTestStep/textReplace/textReplaceAddList';
import { findTextReplaceFromNameByServerName } from '@utils/static/mobileTestStep/textReplace/textReplaceFromList';

import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface Props {
  startCreatingTestStep: () => void;
  startUpdateTestStep: () => void;
  mobileWebSpeedTestStep: MobileWebSpeedTestStepData;
}

const MobileWebTestStepDetail = ({
  startCreatingTestStep,
  startUpdateTestStep,
  mobileWebSpeedTestStep,
}: Props) => {
  const [isTestExecutionOpened, setIsTestExecutionOpened] = useState(false);
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExecutionErrorModalOpen, setIsExecutionErrorModalOpen] =
    useState(false);

  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const deleteMobileWebSpeedTestStepMutation =
    useDeleteMobileWebSpeedTestStepMutation();

  const remoteMobileCaseExecutionMutation =
    useRemoteMobileTestExecutionMutation(
      'case',
      PlatFormTypeServerName.MobileWeb,
    );

  const openDialog = () => {
    setIsDialogOpened(true);
  };

  const closeDialog = () => {
    setIsDialogOpened(false);
  };

  const tableHeader = ['Key Name', 'From', 'Value', 'Date Format'];

  return (
    <section className="py-6">
      <div className="flex items-center justify-between border-b border-solid border-gray-200 pb-3.5">
        <h3 className="text-base font-semibold leading-normal text-gray-900">
          Test Step
        </h3>
        <div className="flex gap-4">
          {mobileWebSpeedTestStep ? (
            <>
              <Button
                type="button"
                variant="primary"
                onClick={() => setIsTestExecutionOpened(true)}
              >
                Execute
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={startUpdateTestStep}
              >
                Edit
              </Button>
              <Button type="button" variant="caution" onClick={openDialog}>
                Delete
              </Button>
            </>
          ) : (
            <>
              <Button type="button" variant="secondary" disabled>
                Execute
              </Button>
              <Button type="button" onClick={startCreatingTestStep}>
                Create
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="space-y-2.5 py-2.5 text-sm font-normal leading-9 text-black">
        {mobileWebSpeedTestStep ? (
          <>
            <DescriptionList
              title={'URL'}
              description={mobileWebSpeedTestStep.url}
            />
            <DescriptionList
              title={'Description'}
              description={mobileWebSpeedTestStep.description}
            />
            {mobileWebSpeedTestStep.optionsDto.length > 0 && (
              <div className="py-4">
                <table className="w-full text-sm font-normal leading-normal text-gray-900">
                  <thead>
                    <tr className="border-b border-solid border-gray-200">
                      {tableHeader.map((header) => (
                        <th key={header} className="text-start font-medium">
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {mobileWebSpeedTestStep.optionsDto?.map((option) => (
                      <tr key={option.keyName}>
                        <TestStepDetailTableDataCell>
                          {option.keyName}
                        </TestStepDetailTableDataCell>
                        <TestStepDetailTableDataCell>
                          {findTextReplaceFromNameByServerName(option.from)}
                        </TestStepDetailTableDataCell>
                        <TestStepDetailTableDataCell>{`${findTextReplaceAddNameByServerName(option.addKey)}+${option.addValue}`}</TestStepDetailTableDataCell>
                        <TestStepDetailTableDataCell>
                          {option.dateFormat}
                        </TestStepDetailTableDataCell>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </>
        ) : (
          <p className="text-center">생성된 테스트 스텝이 없습니다.</p>
        )}
      </div>
      <DoubleCheckDialogHeadlessUI
        isOpened={isDialogOpened}
        setIsOpened={setIsDialogOpened}
        type={'caution'}
        title={'Delete Test Step'}
        subTitle={'해당 테스트 스텝을 삭제하시겠습니까?'}
        buttonChildren={
          <>
            <Button
              type="button"
              variant="caution"
              onClick={() =>
                deleteMobileWebSpeedTestStepMutation.mutate(
                  mobileWebSpeedTestStep.idx,
                )
              }
            >
              Delete
            </Button>
            <Button type="button" variant="secondary" onClick={closeDialog}>
              Cancel
            </Button>
          </>
        }
      />
      {isTestExecutionOpened && (
        <TestExecutionContainer
          setState={setIsTestExecutionOpened}
          idx={Number(idx)}
          testType="case"
          deviceType="mobile"
          testLocation="speed-test"
          platformType={PlatFormTypeServerName.MobileWeb}
          setIsModalOpen={setIsModalOpen}
          remoteMobileExecutionMutation={remoteMobileCaseExecutionMutation}
          setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
        />
      )}
      {remoteMobileCaseExecutionMutation.isLoading && <ArrowSpinner />}
      <RemoteTestExecutionAlarmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isExecutionErrorModalOpen={isExecutionErrorModalOpen}
        setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
      />
      <RemoteConnectionAlarmModal />
    </section>
  );
};

export default MobileWebTestStepDetail;
