import { DefaultSkeleton, ErrorMessage } from '@autosquare/common';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useRemoteSidebar } from '@hooks/sidebar/useRemoteSidebar';
import { useProjectDetailQuery } from '@lib/api/dashboard/project/project';
import clsx from 'clsx';

import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  isToggledSidebar: boolean;
  setIsToggledSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

const RemoteSidebar = ({ isToggledSidebar, setIsToggledSidebar }: Props) => {
  const projectData = useProjectDetailQuery();
  const projectList = projectData.data;

  const { sidebarList } = useRemoteSidebar(projectList);

  return (
    <div
      className={clsx(
        'flex h-full grow flex-col bg-white lg:px-6',
        isToggledSidebar ? 'px-6' : 'px-2',
      )}
    >
      {projectData.isLoading ? (
        <div className="my-6">
          <DefaultSkeleton
            number={5}
            height={'h-3.5'}
            outMargin={'m-0'}
            margin={'my-6'}
          />
        </div>
      ) : projectData.isError ? (
        <ErrorMessage>{projectData.error.message}</ErrorMessage>
      ) : (
        <>
          <div
            className={clsx(
              'items-center border-b px-6 py-4 lg:hidden',
              isToggledSidebar ? 'hidden' : 'flex',
            )}
          >
            <button
              type="button"
              className="mr-6"
              onClick={() => setIsToggledSidebar(!isToggledSidebar)}
            >
              <Bars3Icon className="block size-6" aria-hidden="true" />
            </button>
            <div>Menu</div>
          </div>
          <div
            className={clsx('lg:block', isToggledSidebar ? 'block' : 'hidden')}
          >
            <div className="flex h-16 shrink-0 items-center justify-between">
              <span className="text-sm font-semibold">{projectList?.name}</span>
              <XMarkIcon
                className="block size-6 cursor-pointer lg:hidden"
                aria-hidden="true"
                onClick={() => setIsToggledSidebar(false)}
              />
            </div>
            <div className="flex flex-1 flex-col">
              <ul role="list" className="-mx-2 space-y-1">
                {sidebarList.map((sidebar) => (
                  <li className="space-y-1" key={sidebar.name}>
                    {sidebar.href ? (
                      <Link
                        to={sidebar.href}
                        onClick={() => setIsToggledSidebar(false)}
                        className={clsx(
                          'block cursor-pointer rounded-md py-2 pl-10 pr-2 text-sm font-semibold leading-6 text-gray-700',
                          location.pathname.includes(sidebar.href)
                            ? 'bg-gray-50'
                            : 'hover:bg-gray-50',
                        )}
                      >
                        {sidebar.name}
                      </Link>
                    ) : (
                      <>
                        <p className="inline-flex w-full items-center justify-start border-b border-gray-300 py-2 pr-2 text-sm font-semibold leading-normal text-gray-700">
                          {sidebar.name}
                        </p>
                        {sidebar.children.map((child) =>
                          child.children ? (
                            <Disclosure key={child.id} defaultOpen={true}>
                              <DisclosureButton className="group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50">
                                <ChevronRightIcon
                                  className="size-5 shrink-0 text-gray-400 group-data-[open]:rotate-90 group-data-[open]:text-gray-500"
                                  aria-hidden="true"
                                />
                                {child.name}
                              </DisclosureButton>
                              {child.children.map((secondChild) => (
                                <DisclosurePanel key={secondChild.name}>
                                  <Link
                                    to={secondChild.href}
                                    onClick={(e) => {
                                      if (
                                        secondChild.href === location.pathname
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    className={clsx(
                                      'block cursor-pointer rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700',
                                      location.pathname.includes(
                                        secondChild.href,
                                      )
                                        ? 'bg-gray-50'
                                        : 'hover:bg-gray-50',
                                    )}
                                  >
                                    {secondChild.name}
                                  </Link>
                                </DisclosurePanel>
                              ))}
                            </Disclosure>
                          ) : (
                            <Link
                              to={child.href}
                              className={clsx(
                                'block cursor-pointer rounded-md py-2 pl-9 pr-2 text-sm font-semibold leading-6 text-gray-700',
                                location.pathname.includes(child.href)
                                  ? 'bg-gray-50'
                                  : 'hover:bg-gray-50',
                              )}
                              key={child.id}
                            >
                              {child.name}
                            </Link>
                          ),
                        )}
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RemoteSidebar;
