import { Button, ErrorBoundaryWrapper } from '@autosquare/common';
import React, { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import TestCaseInfoHeader from './TestCaseInfoHeader';
import TestCaseInfoForm from './TestCaseInfoEdit/TestCaseInfoForm';
import TestCaseInfoDefault from '@components/UITest/TestCaseDetail/TestCaseInfoDefault';
import {
  UpdateMobileTestCaseData,
  UpdateMobileTestCaseDataSchema,
} from '@customTypes/index';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  useMobileTestCaseDetailQuery,
  useUpdateMobileTestCaseMutation,
} from '@lib/api/ide/queryHooks';
import { zodResolver } from '@hookform/resolvers/zod';
import { RootState } from '@app/store';
import CreateNewGroupDialog from '@components/shared/TestCaseGroup/CreateNewGroupDialog';
import { openCreateGroup } from '@store/ide/speedTestCase/CreateGroupSlice';
import TestCaseInfoEditDialogs from './TestCaseInfoEdit/TestCaseInfoEditDialogs';

const TestCaseInfoEdit = () => {
  const dispatch = useDispatch();

  const [isEdited, setIsEdited] = useState(false);
  const [isUpdateErrorModalOpened, setIsUpdateErrorModalOpened] =
    useState(false);
  const [isDeleteTestCaseModalOpened, setIsDeleteTestCaseModalOpened] =
    useState(false);
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);

  const openEditTestCase = () => {
    setIsEdited(true);
  };

  const closeEditTestCase = () => {
    method.reset();
    setIsEdited(false);
  };

  const isOpenedCreateGroup = useSelector(
    (state: RootState) => state.createGroup.isOpen,
  );

  const { data: mobileTestCaseDetail } = useMobileTestCaseDetailQuery();

  const method = useForm<UpdateMobileTestCaseData>({
    values: {
      title: mobileTestCaseDetail.title,
      description: mobileTestCaseDetail.description,
      priority: mobileTestCaseDetail.priority,
      platformType: mobileTestCaseDetail.platformType,
      caseGroupIdx: mobileTestCaseDetail.caseGroupInfo?.caseGroupIdx,
      tcId: mobileTestCaseDetail.tcId,
    },
    resolver: zodResolver(UpdateMobileTestCaseDataSchema),
  });

  const updateMobileTestCaseMutation = useUpdateMobileTestCaseMutation();

  const onSubmit: SubmitHandler<UpdateMobileTestCaseData> = (data) => {
    updateMobileTestCaseMutation.mutate(data, {
      onSuccess: () => {
        setIsEdited(false);
      },
      onError: () => {
        setIsUpdateErrorModalOpened(true);
      },
    });
  };

  return (
    <ErrorBoundaryWrapper>
      {isEdited ? (
        <FormProvider {...method}>
          <form onSubmit={method.handleSubmit(onSubmit)}>
            <TestCaseInfoHeader
              buttonChildren={
                <div className="flex gap-3">
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={closeEditTestCase}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={updateMobileTestCaseMutation.isLoading}
                  >
                    Save
                  </Button>
                </div>
              }
            >
              <TestCaseInfoForm />
            </TestCaseInfoHeader>
          </form>
        </FormProvider>
      ) : (
        <div>
          <TestCaseInfoHeader
            buttonChildren={
              <div className="flex gap-3">
                <Button
                  type="button"
                  variant="primary"
                  onClick={() => setIsCopyModalOpen(true)}
                >
                  Copy
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={openEditTestCase}
                >
                  Edit
                </Button>
                <Button
                  type="button"
                  variant="caution"
                  onClick={() => setIsDeleteTestCaseModalOpened(true)}
                >
                  Delete
                </Button>
              </div>
            }
          >
            <TestCaseInfoDefault mobileTestCaseDetail={mobileTestCaseDetail} />
          </TestCaseInfoHeader>
        </div>
      )}
      <TestCaseInfoEditDialogs
        isUpdateErrorModalOpened={isUpdateErrorModalOpened}
        setIsUpdateErrorModalOpened={setIsUpdateErrorModalOpened}
        isDeleteTestCaseModalOpened={isDeleteTestCaseModalOpened}
        setIsDeleteTestCaseModalOpened={setIsDeleteTestCaseModalOpened}
        updateMobileTestCaseMutation={updateMobileTestCaseMutation}
        isCopyModalOpen={isCopyModalOpen}
        setIsCopyModalOpen={setIsCopyModalOpen}
      />
      <CreateNewGroupDialog
        isOpen={isOpenedCreateGroup}
        setIsOpen={() => dispatch(openCreateGroup())}
      />
    </ErrorBoundaryWrapper>
  );
};

export default TestCaseInfoEdit;
