import {
  AdminUserData,
  AdminUserEmail,
  DeleteAdminUserData,
  DeleteAdminUserErrorData,
  InviteUserResponse,
  UpdateAdminUserInfo,
} from '@customTypes/index';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { adminKeys } from '@queries/ide/adminKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosInstance } from '../..';
import { API } from '@api/endpoints';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useParentPath } from '@hooks/index';
import { projectKeys } from '@queries/dashboard/project/projectKeys';

// GET
// 어드민 유저 리스트 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Admin/getUserList
const getAdminUserList = async (): Promise<AdminUserData[]> => {
  const response = await axiosInstance.get(API.GET_ADMIN_USER_LIST);
  return response.data;
};
export const useAdminUserListQuery = () =>
  useQuery<AdminUserData[], ServerResponseErrorDetails>({
    queryKey: adminKeys.userLists(),
    queryFn: () => getAdminUserList(),
  });

// 어드민 유저 정보 상세 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Admin/getUserDetailInfo
const getAdminUserDetail = async (idx: string): Promise<AdminUserData> => {
  const response = await axiosInstance.get(
    API.GET_ADMIN_DETAIL_USER_INFO + idx,
  );
  return response.data;
};
export const useAdminUserDetailQuery = () => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  return useQuery<AdminUserData, ServerResponseErrorDetails>({
    queryKey: adminKeys.userDetail(idx),
    queryFn: () => getAdminUserDetail(idx),
  });
};

// POST
// 회원가입 경로 생성
// https://dev.t-square.co.kr/swagger-ui/index.html#/Admin/inviteUser
const postInviteUser = async (
  data: Pick<AdminUserEmail, 'inviteUserEmail'>,
): Promise<InviteUserResponse> => {
  const response = await axiosInstance.post(
    `${API.CREATE_INVITE_SIGNUP}`,
    data,
  );
  return response.data;
};
export const useInviteUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    InviteUserResponse,
    ServerResponseErrorDetails,
    Pick<AdminUserEmail, 'inviteUserEmail'>
  >({
    mutationFn: (data) => postInviteUser(data),
    onSuccess: () => {
      queryClient.invalidateQueries(adminKeys.all);
    },
  });
};

// PUT
// Update Admin User Information 유저 정보 수정
// https://dev.t-square.co.kr/swagger-ui/index.html#/Admin/updateUserInfo
const updateAdminUserDetail = async (
  idx: string,
  data: UpdateAdminUserInfo,
): Promise<AdminUserData> => {
  const response = await axiosInstance.put(
    API.UPDATE_ADMIN_DETAIL_USER_INFO + idx,
    data,
  );
  return response.data;
};
export const useUpdateAdminUserDetailMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const adminUserListPage = useParentPath();

  return useMutation<
    AdminUserData,
    ServerResponseErrorDetails,
    UpdateAdminUserInfo
  >({
    mutationFn: (data) => updateAdminUserDetail(idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(adminKeys.all);
      navigate(adminUserListPage);
    },
  });
};

// DELETE
// Delete Admin User 유저정보 삭제
// https://dev.t-square.co.kr/swagger-ui/index.html#/Admin/deleteUser
const deleteAdminUser = async (
  data: DeleteAdminUserData,
): Promise<AdminUserData> => {
  const response = await axiosInstance.delete(API.ADMIN, { data: data });
  return response.data;
};
export const useDeleteAdminUserMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const adminUserListPage = useParentPath();

  return useMutation<
    AdminUserData,
    ServerResponseErrorDetails<DeleteAdminUserErrorData[]>,
    DeleteAdminUserData
  >({
    mutationFn: (data) => deleteAdminUser(data),
    onSuccess: () => {
      queryClient.invalidateQueries(adminKeys.userLists());
      navigate(adminUserListPage);
    },
  });
};
