import {
  ErrorBoundaryWrapper,
  SectionTitle,
  SectionMainTitleContainer,
  SectionTitleMain,
  SectionTitleRight,
} from '@autosquare/common';
import React from 'react';

const AdminUserInformationHeader = () => {
  return (
    <ErrorBoundaryWrapper>
      <div className="pt-6">
        <SectionTitle withBorder>
          <SectionMainTitleContainer>
            <SectionTitleMain>User Information</SectionTitleMain>
            <SectionTitleRight type="caution">
              *필수 입력 사항
            </SectionTitleRight>
          </SectionMainTitleContainer>
        </SectionTitle>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default AdminUserInformationHeader;
