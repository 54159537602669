import {
  Breadcrumbs,
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorMessage,
  PageHeadingButtons,
  PagingHeadingButtonOnClick,
} from '@autosquare/common';
import SpeedTestScenaioDetailSkeleton from '@components/SpeedTestScenario/SpeedTestScenaioDetailSkeleton';
import {
  CreateMobileSpeedTestScenarioDataSchema,
  UpdateMobileSpeedTestScenarioData,
} from '@customTypes/ide/speedTest/Mobile/testScenario/type';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useDeleteSpeedMobileTestScenarioMutation,
  useSpeedMobileTestScenarioDetailQuery,
  useUpdateSpeedMobileTestScenarioMutation,
} from '@lib/api/ide/speedMobile/speedMobileTestScenario';

import React, { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import MobileSpeedTestScenarioDetailDndSpace from './components/MobileSpeedTestScenarioDetailDndSpace';
import MobileSpeedTestScenarioDetailInformation from './components/MobileSpeedTestScenarioDetailInformation';

const MobileSpeedTestScenarioDetail = () => {
  const navigate = useNavigate();
  const { projectIdx } = useParams();
  const location = useLocation();

  const [isModalOpened, setIsModalOpened] = useState(false);

  const speedMobileTestScenarioDetailQuery =
    useSpeedMobileTestScenarioDetailQuery();
  const speedMobileTestScenarioDetail = speedMobileTestScenarioDetailQuery.data;

  const updateSpeedMobileTestScenarioMutation =
    useUpdateSpeedMobileTestScenarioMutation();

  const deleteSpeedMobileTestScenarioMutation =
    useDeleteSpeedMobileTestScenarioMutation();

  const methods = useForm<UpdateMobileSpeedTestScenarioData>({
    defaultValues: { projectIdx: Number(projectIdx) },
    resolver: zodResolver(CreateMobileSpeedTestScenarioDataSchema),
  });

  useEffect(() => {
    if (speedMobileTestScenarioDetailQuery.isSuccess) {
      methods.setValue('title', speedMobileTestScenarioDetail.title);
      methods.setValue(
        'description',
        speedMobileTestScenarioDetail.description,
      );
      methods.setValue('steps', speedMobileTestScenarioDetail.steps);
    }
  }, [speedMobileTestScenarioDetailQuery.isSuccess]);

  const testScenarioListPage = location.pathname
    .split('/')
    .slice(0, -1)
    .join('/');

  const onSubmit: SubmitHandler<UpdateMobileSpeedTestScenarioData> = (data) => {
    updateSpeedMobileTestScenarioMutation.mutate(data, {
      onSuccess: () => {
        navigate(testScenarioListPage);
      },
    });
  };

  const deleteOnClick = () => {
    deleteSpeedMobileTestScenarioMutation.mutate({
      idxList: [speedMobileTestScenarioDetail?.idx],
    });
  };

  const breadcrumbs: Breadcrumbs[] = [
    {
      title: 'Speed Test',
      href: location.pathname.split('/').slice(0, -3).join('/') + '/overview',
    },
    {
      title: 'Mobile',
      href: testScenarioListPage,
    },
    {
      title: 'Test Scenario',
      href: testScenarioListPage,
    },
    {
      title: 'Detail',
      href: location.pathname + location.search,
    },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: 'Delete',
      type: 'button',
      variant: 'caution',
      onClick: () => {
        setIsModalOpened(true);
      },
    },
    { title: 'Save', type: 'submit' },
  ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PagingHeadingButtonOnClick
          breadcrumbs={breadcrumbs}
          pageTitle={'Test Scenario Detail'}
          pageHeadingButtons={pageHeadingButtons}
        />
        {speedMobileTestScenarioDetailQuery.isError ? (
          <ErrorMessage>
            {speedMobileTestScenarioDetailQuery.error.message}
          </ErrorMessage>
        ) : (
          updateSpeedMobileTestScenarioMutation.isError && (
            <ErrorMessage>
              {updateSpeedMobileTestScenarioMutation.error.message}
            </ErrorMessage>
          )
        )}
        {speedMobileTestScenarioDetailQuery.isLoading ? (
          <SpeedTestScenaioDetailSkeleton />
        ) : (
          <MobileSpeedTestScenarioDetailInformation />
        )}
        <MobileSpeedTestScenarioDetailDndSpace />
      </form>
      <DoubleCheckDialogHeadlessUI
        isOpened={isModalOpened}
        setIsOpened={setIsModalOpened}
        type={'caution'}
        title={
          deleteSpeedMobileTestScenarioMutation.isError
            ? 'Error'
            : 'Delete Test Scenario'
        }
        subTitle={
          deleteSpeedMobileTestScenarioMutation.isError
            ? deleteSpeedMobileTestScenarioMutation.error.message
            : '해당 테스트 시나리오를 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {deleteSpeedMobileTestScenarioMutation.isError ? undefined : (
              <Button type="button" variant="caution" onClick={deleteOnClick}>
                Delete
              </Button>
            )}
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                setIsModalOpened(false);
              }}
            >
              Cancel
            </Button>
          </>
        }
      />
    </FormProvider>
  );
};

export default MobileSpeedTestScenarioDetail;
