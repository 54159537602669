import React, { useEffect } from 'react';
import SelectApplicationListbox from './SelectApplicationListbox';
import { useFormContext } from 'react-hook-form';
import { InstallAppRequest } from '@customTypes/index';
import { useGetProjectAppListQuery } from '@lib/api/dashboard/queryHooks';

const SelectApplicationPackageName = () => {
  const { watch, setValue } = useFormContext<InstallAppRequest>();

  const osType = watch('osType');
  const packageName = watch('packageName');

  const { data: projectAppList, isLoading } = useGetProjectAppListQuery({
    enabled: !!osType,
  });

  // osType 을 기준으로 필터링
  const osFilteredAppList = projectAppList?.filter(
    (app) => app.osType === osType,
  );
  // 중복된 packageName 을 삭제하여 필터링
  const packageNameFilteredAppList = osFilteredAppList?.filter(
    (app, idx, self) =>
      idx === self.findIndex((t) => t.packageName === app.packageName),
  );

  // packageName 이 변경되면 appVersion 과 buildVersion 을 초기화
  useEffect(() => {
    setValue('appVersion', undefined);
    setValue('buildVersion', undefined);
  }, [packageName]);

  return (
    <SelectApplicationListbox
      label={'Package Name*'}
      name={'packageName'}
      lists={packageNameFilteredAppList}
      valueToSave={'packageName'}
      valueToShow={'packageName'}
      disabled={!osType || isLoading}
    />
  );
};

export default SelectApplicationPackageName;
