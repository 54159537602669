import {
  AdminSystemSettings,
  AdminSystemSettingsData,
  AdminWebDriverListData,
  UpdateAdminSetting,
  UploadAdminWebDriverData,
  UploadAdminWebDriverResponse,
} from '@customTypes/index';
import { axiosInstance, axiosRawResponseInstance } from '../..';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { adminKeys } from '@queries/ide/adminKeys';
import { API } from '@api/endpoints';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { settingKeys } from '@queries/index';

// GET
// 어드민 시스템 전체 세팅 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Admin/getAllSystemSettings
const getAdminSystemSettings = async (): Promise<AdminSystemSettings> => {
  const response = await axiosInstance.get(API.GET_ADMIN_SYSTEM_SETTINGS);
  return response.data;
};
export const useAdminSystemSettingsQuery = () => {
  return useQuery<AdminSystemSettings, ServerResponseErrorDetails>({
    queryKey: adminKeys.systemSettings(),
    queryFn: getAdminSystemSettings,
  });
};

// 어드민 웹 드라이버 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Admin/getAllWebDrivers
const getAdminWebDriverList = async (): Promise<AdminWebDriverListData[]> => {
  const response = await axiosInstance.get(API.GET_ADMIN_WEB_DRIVER_LIST);
  return response.data;
};
export const useAdminWebDriverListQuery = () =>
  useQuery<AdminWebDriverListData[], ServerResponseErrorDetails>({
    queryKey: adminKeys.webdriverLists(),
    queryFn: () => getAdminWebDriverList(),
    onError: (error) => {
      if (error instanceof Error) {
        console.error('Query error:', error.message);
      }
    },
  });

// 어드민 세팅 key 값에 따른 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Admin/getSettingByKey
// response에 data 키 값이 없어서 axiosRawResponseInstance 사용
const getAdminSettings = async <K extends keyof AdminSystemSettingsData>(
  key: K,
): Promise<{ [P in K]: AdminSystemSettingsData[P] }> => {
  const response = await axiosRawResponseInstance.get(
    `${API.GET_ADMIN_SYSTEM_SETTINGS}/${key}`,
  );
  return response.data;
};
export const useAdminSettingsQuery = <K extends keyof AdminSystemSettingsData>(
  key: K,
) => {
  return useQuery<
    { [P in K]: AdminSystemSettingsData[P] },
    ServerResponseErrorDetails
  >({
    queryKey: adminKeys.settings(key),
    queryFn: () => getAdminSettings(key),
  });
};

// POST
// 어드민 업로드 웹 드라이버
// https://dev.t-square.co.kr/swagger-ui/index.html#/Admin/uploadWebDriver
const uploadAdminWebDriver = async (
  data: UploadAdminWebDriverData,
): Promise<UploadAdminWebDriverResponse> => {
  const formData = new FormData();
  formData.append('uploadFile', data.uploadFile);
  formData.append('os', data.os);
  formData.append('browser', data.browser);
  formData.append('version', data.version);
  formData.append('cpuArch', data.cpuArch);

  const response = await axiosInstance.post(
    API.UPLOAD_ADMIN_WEB_DRIVER,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
  return response.data;
};

export const useUploadAdminWebDriverMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    UploadAdminWebDriverResponse,
    Error,
    UploadAdminWebDriverData
  >({
    mutationFn: (data) => uploadAdminWebDriver(data),
    onSuccess: (newWebDriver) => {
      queryClient.setQueryData<AdminWebDriverListData[]>(
        adminKeys.webdriverLists(),
        (oldData) => {
          if (oldData) {
            return [...oldData, newWebDriver];
          }
          return [newWebDriver];
        },
      );
    },
  });
};

// PUT
// 어드민 시스템 설정 변경
// https://dev.t-square.co.kr/swagger-ui/index.html#/Admin/updateSettings
const updateAdminSetting = async (
  data: Record<string, string>,
): Promise<unknown> => {
  const response = await axiosInstance.put(API.UPDATE_ADMIN_SYSTEM_SETTINGS, {
    settings: data,
  });
  return response.data;
};
export const useUpdateAdminSettingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    ServerResponseErrorDetails,
    Record<string, string>
  >({
    mutationFn: (data) => updateAdminSetting(data),
    onSuccess: () => {
      queryClient.invalidateQueries(adminKeys.all);
      queryClient.invalidateQueries(settingKeys.all);
    },
  });
};

// key값에 따른 어드민 시스템 설정 변경
// https://dev.t-square.co.kr/swagger-ui/index.html#/Admin/updateSetting
const updateAdminKeySetting = async <K extends keyof AdminSystemSettingsData>(
  key: K,
  data: UpdateAdminSetting,
): Promise<{ [P in K]: AdminSystemSettingsData[P] }> => {
  const response = await axiosInstance.put(
    `${API.UPDATE_ADMIN_SYSTEM_SETTINGS}/${key}`,
    data,
  );
  return response.data;
};
export const useUpdateAdminKeySettingMutation = <
  K extends keyof AdminSystemSettingsData,
>(
  key: K,
) => {
  const queryClient = useQueryClient();

  return useMutation<
    { [P in K]: AdminSystemSettingsData[P] },
    ServerResponseErrorDetails,
    UpdateAdminSetting
  >({
    mutationFn: (data) => updateAdminKeySetting(key, data),
    onSuccess: () => {
      queryClient.invalidateQueries(adminKeys.systemSettings());
    },
  });
};
