import { Checkbox, TableHeader } from '@autosquare/common';
import {
  DeviceListInProject,
  ServerResponseErrorDetails,
} from '@customTypes/index';
import { useAuthUserInfo, useIndeterminateCheckbox } from '@hooks/index';
import { UseQueryResult } from '@tanstack/react-query';
import React, { useEffect } from 'react';

type Props = {
  deviceListQuery: UseQueryResult<
    DeviceListInProject[],
    ServerResponseErrorDetails
  >;
  selectedArray: number[];
  selectAllArray: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setTableHeaderLength: React.Dispatch<React.SetStateAction<number>>;
  isActivate: boolean;
};

const ProjectDeviceTableHeader = ({
  deviceListQuery,
  selectedArray,
  selectAllArray,
  setTableHeaderLength,
  isActivate,
}: Props) => {
  const { isAdmin } = useAuthUserInfo();
  const deviceList = deviceListQuery.data;
  const totalCount = deviceList?.length;

  const selectedCount = selectedArray?.length;

  const checkboxRef = useIndeterminateCheckbox(selectedCount, totalCount);

  const adminTableHeaderList = [
    {
      idx: 1,
      title: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={(e) => selectAllArray(e)}
          disabled={
            deviceListQuery.isLoading ||
            deviceListQuery.isFetching ||
            deviceListQuery.isError ||
            !isActivate
          }
          ref={checkboxRef}
        />
      ),
    },
    {
      idx: 2,
      title: selectedArray.length > 0 ? <div className="w-4" /> : 'No',
    },
    {
      idx: 3,
      title: selectedArray.length > 0 ? <div className="w-14" /> : 'Model',
    },
    { idx: 4, title: 'OS' },
    { idx: 5, title: 'Registered' },
    { idx: 6, title: '' },
    { idx: 7, title: '' },
  ];

  const userTableHeaderList = [
    {
      idx: 1,
      title: 'No',
    },
    {
      idx: 2,
      title: 'Model',
    },
    { idx: 3, title: 'OS' },
    { idx: 4, title: 'Registered' },
    { idx: 5, title: 'Status' },
    { idx: 6, title: '' },
  ];

  const tableHeaderList = isAdmin ? adminTableHeaderList : userTableHeaderList;

  useEffect(() => {
    setTableHeaderLength(tableHeaderList.length);
  }, [tableHeaderList]);

  return (
    <thead>
      <tr>
        {tableHeaderList.map((tableHeader) => (
          <TableHeader key={tableHeader.idx}>{tableHeader.title}</TableHeader>
        ))}
      </tr>
    </thead>
  );
};

export default ProjectDeviceTableHeader;
