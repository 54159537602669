import React from 'react';
import { Disclosure, DisclosureButton } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

import TestStepResult from './TestStepResult';

import {
  ErrorBoundaryWrapper,
  HeadingLevelTwoWithUnderline,
} from '@autosquare/common';

import { TestResultDetails } from '@customTypes/testResult/type';
import clsx from 'clsx';

type Props = {
  resultDetails: TestResultDetails[];
  elementRefs: React.MutableRefObject<HTMLHeadingElement[]>;
};

const ExecutionDetails = ({ resultDetails, elementRefs }: Props) => {
  const resultDetailsLength = resultDetails.length;

  return (
    <ErrorBoundaryWrapper>
      <section>
        <HeadingLevelTwoWithUnderline>
          Execution Details ({resultDetailsLength})
        </HeadingLevelTwoWithUnderline>
        <div className="-mx-4">
          {resultDetails?.map((resultDetail, index) => (
            <Disclosure key={resultDetail.seq}>
              <div
                className={clsx(
                  'border-b border-l-0 border-b-gray-200 py-4 sm:mx-4',
                  resultDetail.result === 'Pass'
                    ? 'border-l-green-500'
                    : resultDetail.result === 'Fail'
                      ? 'border-l-red-500'
                      : 'border-l-gray-500',
                )}
              >
                <div className="bg-white">
                  <DisclosureButton className="w-full">
                    <div className="flex items-center justify-between space-x-3 px-4">
                      <div className="flex min-w-0 flex-col gap-2">
                        <div className="flex items-start gap-x-3">
                          <p
                            className={clsx(
                              'mt-0.5 whitespace-nowrap px-1.5 py-0.5 text-xs font-medium uppercase ring-1 ring-inset',
                              resultDetail.result === 'Pass'
                                ? 'bg-green-50 text-green-700 ring-green-600/20'
                                : resultDetail.result === 'Fail'
                                  ? 'bg-red-50 text-red-700 ring-red-600/10'
                                  : 'bg-gray-50 text-gray-700 ring-gray-600/10',
                            )}
                          >
                            {resultDetail.result}
                          </p>
                          <p className="text-sm font-semibold leading-6 text-gray-900">
                            #{resultDetail.seq}
                          </p>
                          <h4
                            className="text-sm font-semibold leading-6 text-gray-900"
                            ref={(el) => (elementRefs.current[index] = el!)}
                            id={resultDetail.title}
                          >
                            {resultDetail.title}
                          </h4>
                        </div>
                        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                          <p className="truncate">{resultDetail.description}</p>
                        </div>
                      </div>
                      <div className="justify-items-end">
                        <div className="grid grid-cols-1">
                          <ChevronRightIcon
                            className="size-5 shrink-0 text-gray-400 group-data-[open]:rotate-90 group-data-[open]:text-gray-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                  </DisclosureButton>
                  <TestStepResult
                    caseResultIdx={resultDetail.idx}
                    videoPath={resultDetail.videoPath}
                    logPath={resultDetail.logPath}
                    imagePaths={resultDetail.details.map(
                      (item) => item.imagePath,
                    )}
                    result={resultDetail.result}
                  />
                </div>
              </div>
            </Disclosure>
          ))}
        </div>
      </section>
    </ErrorBoundaryWrapper>
  );
};

export default ExecutionDetails;
