import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  CreateSchedulerData,
  SchedulerTypeName,
  SchedulerTypeServerName,
} from '@customTypes/ide/scheduler/scheduler';
import {
  ProjectListDetailFeaturesCategory,
  ProjectListDetailFeaturesFeature,
} from '@customTypes/index';
import { useProjectDetailQuery } from '@lib/api/ide/project/project';
import { checkIsProjectFeatureIncluded } from '@utils/static/checkProjectFeature';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

const CreateSchedulerTestType = () => {
  const { data: projectDetail } = useProjectDetailQuery();

  const { register, control, setValue } = useFormContext<CreateSchedulerData>();

  const testType = useWatch({ control, name: 'type' });

  useEffect(() => {
    setValue('scenarioIdxList', []);
  }, [testType, setValue]);

  // mobile 기능 활성화 여부
  const isMobileSettings = checkIsProjectFeatureIncluded(
    projectDetail,
    ProjectListDetailFeaturesCategory.Mobile,
    ProjectListDetailFeaturesFeature.Functional,
  );

  // web 기능 활성화 여부
  const isWebSettings = checkIsProjectFeatureIncluded(
    projectDetail,
    ProjectListDetailFeaturesCategory.Web,
    ProjectListDetailFeaturesFeature.Functional,
  );

  // 기능에 따른 테스트 타입 초기 설정 설정
  useEffect(() => {
    if (isMobileSettings) {
      setValue('type', SchedulerTypeServerName.Mobile);
    }
    if (!isMobileSettings && isWebSettings) {
      setValue('type', SchedulerTypeServerName.Web);
    }
  }, [isMobileSettings, isWebSettings, setValue]);

  const testTypeList = [
    {
      name: SchedulerTypeName.Mobile,
      value: SchedulerTypeServerName.Mobile,
      disabled: !isMobileSettings,
    },
    {
      name: SchedulerTypeName.Web,
      value: SchedulerTypeServerName.Web,
      disabled: !isWebSettings,
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <fieldset>
        <legend className="sr-only">Test Type</legend>
        <div className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 sm:pt-4">
          <div
            className="text-sm font-medium leading-6 text-gray-900"
            aria-hidden="true"
          >
            Test Type
          </div>
          <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
            {testTypeList.map((testType) => (
              <div key={testType.name} className="flex items-center">
                <input
                  id={testType.name}
                  name="test type"
                  type="radio"
                  value={testType.value}
                  {...register('type')}
                  className="input-radio-congress-blue mr-2 disabled:bg-gray-100"
                  disabled={testType.disabled}
                />
                <label
                  htmlFor={testType.name}
                  className={clsx(
                    'flex items-center gap-x-2 text-sm font-medium leading-6',
                    testType.disabled
                      ? 'cursor-default text-gray-300'
                      : 'cursor-pointer text-gray-900',
                  )}
                >
                  {testType.name}
                </label>
              </div>
            ))}
          </div>
        </div>
      </fieldset>
    </ErrorBoundaryWrapper>
  );
};

export default CreateSchedulerTestType;
