import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';
import TestStepValueListbox from '@components/IDE/TestStepValueListbox';
import AddTestStepConditionHeader from '@components/TestStep/AddTestStepConditionHeader';
import { useWebTestStepContext } from '@contexts/ide/WebTestStepProvider';
import { CreateWebTestStepData } from '@customTypes/testStep/type';
import { ErrorMessage } from '@hookform/error-message';
import {
  findWebCommandNameFromServerName,
  WebCommandOptionName,
  webCommandOptions,
} from '@utils/static/webCommandOptions';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const IfCondition = () => {
  const {
    watch,
    control,
    resetField,
    setValue,
    formState: { errors },
  } = useFormContext<CreateWebTestStepData>();

  const { fullXPath, isListboxOpened, setIsListboxOpened } =
    useWebTestStepContext();

  const value = watch('value');
  const conditionCommand = watch('optionsDto.conditionCommand');
  const conditionValue = watch('optionsDto.conditionValue');
  const maskingOptionsEnabled = watch('optionsDto.maskingOptions.enabled');

  // To do: optionsDto.conditionCommand와 optionsDto.conditionValue를 사용하는 다른 command 생성 시 command onChange에 resetField 추가
  useEffect(() => {
    return () => {
      resetField('optionsDto.conditionCommand');
      resetField('optionsDto.conditionValue');
    };
  }, [resetField]);

  return (
    <ErrorBoundaryWrapper>
      <AddTestStepConditionHeader
        header={'Condition'}
        description={'조건식을 설정합니다.'}
      />
      <ErrorBoundaryWrapper>
        <Controller
          control={control}
          name={'optionsDto.conditionCommand'}
          rules={{ required: 'Condition Command를 선택해주세요.' }}
          defaultValue={undefined}
          render={({ field }) => (
            <ListBoxHeadlessInController
              value={field.value}
              buttonValue={
                findWebCommandNameFromServerName(field.value) || 'Command'
              }
              onChange={field.onChange}
              lists={webCommandOptions.filter(
                (webCommand) =>
                  webCommand.name === WebCommandOptionName.FindElement,
              )}
              valueToSave={'serverName'}
              valueToShow={'name'}
              disabled={value !== 'undefined' || maskingOptionsEnabled}
            />
          )}
        />
        <ErrorMessage
          name="optionsDto.conditionCommand"
          errors={errors}
          render={({ message }) => (
            <p className="error-message !mt-2">{message}</p>
          )}
        />
      </ErrorBoundaryWrapper>
      <ErrorBoundaryWrapper>
        <Controller
          control={control}
          name={'optionsDto.conditionValue'}
          rules={{ required: 'Condition Value를 선택해주세요.' }}
          render={({ field }) => (
            <TestStepValueListbox
              valueText={field.value || 'Value'}
              setValueText={field.onChange}
              totalValues={fullXPath}
              isListboxOpened={isListboxOpened}
              setIsListboxOpened={setIsListboxOpened}
              resetOnClick={() => {
                resetField('optionsDto.conditionCommand');
                setValue('optionsDto.conditionValue', undefined);
              }}
              disabled={
                conditionCommand === undefined ||
                conditionValue !== undefined ||
                maskingOptionsEnabled
              }
              resetDisabled={maskingOptionsEnabled}
            />
          )}
        />
        <ErrorMessage
          name="optionsDto.conditionValue"
          errors={errors}
          render={({ message }) => (
            <p className="error-message !mt-2">{message}</p>
          )}
        />
      </ErrorBoundaryWrapper>
    </ErrorBoundaryWrapper>
  );
};

export default IfCondition;
