import { ErrorBoundaryWrapper } from '@autosquare/common';
import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

type Props = {
  title: string;
  register: UseFormRegisterReturn;
  disabled?: boolean;
};

const TestResultSaveOptionsCheckbox = ({
  title,
  register,
  disabled = false,
}: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div className="flex items-center gap-3" key={title}>
        <input
          name={title}
          id={title}
          type="checkbox"
          className="input-checkbox-congress-blue disabled:bg-gray-100 disabled:hover:bg-gray-100"
          disabled={disabled}
          {...register}
        />
        <label htmlFor={title} className="text-sm font-medium leading-normal">
          {title}
        </label>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default TestResultSaveOptionsCheckbox;
