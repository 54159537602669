import React, { Fragment } from 'react';
import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Variant = 'primary' | 'black';
type Size = 'sm' | 'base';

export type SwitchInControllerProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  size?: Size;
  variant?: Variant;
};

const SwitchInController = ({
  checked,
  onChange,
  disabled,
  size = 'base',
  variant = 'primary',
}: SwitchInControllerProps) => {
  const switchSize =
    size === 'base'
      ? {
          backgroundSize: 'h-6 w-11',
          buttonSize: 'size-4',
        }
      : size === 'sm' && {
          backgroundSize: 'h-5 w-10',
          buttonSize: 'size-3',
        };

  const switchColor: Record<Variant, string> = {
    primary: 'data-[checked]:bg-congress-blue',
    black: 'data-[checked]:bg-black',
  };

  return (
    <ErrorBoundaryWrapper>
      <Switch
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        as={Fragment}
      >
        <button
          className={clsx(
            'group inline-flex items-center rounded-full bg-gray-400 data-[disabled]:cursor-not-allowed data-[disabled]:bg-gray-200',
            switchSize.backgroundSize,
            switchColor[variant],
          )}
        >
          <span className="sr-only">Switch Enabled</span>
          <span
            aria-hidden="true"
            className={clsx(
              'translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6',
              switchSize.buttonSize,
            )}
          />
        </button>
      </Switch>
    </ErrorBoundaryWrapper>
  );
};

export default SwitchInController;
