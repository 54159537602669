import { ErrorBoundaryWrapper } from '@autosquare/common';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import { UpdateSchedulerData } from '@customTypes/ide/scheduler/scheduler';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';

import React from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  isRunning: boolean;
}

const SchedulerDetailTitle = ({ isRunning }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<UpdateSchedulerData>();

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer title={'Title'}>
        <div className="mt-2 sm:col-span-2 sm:mt-0">
          <input
            id="title"
            className={clsx('input-base', !isRunning && 'bg-gray-100')}
            placeholder="Enter scheduler title"
            disabled={!isRunning}
            {...register('title')}
          />
          <ErrorMessage
            name="title"
            errors={errors}
            render={({ message }) => (
              <p className="standard-error-message">{message}</p>
            )}
          />
        </div>
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default SchedulerDetailTitle;
