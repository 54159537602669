import { Button, ErrorBoundaryWrapper } from '@autosquare/common';
import {
  CommonDialog,
  DialogButtonPanels,
  ErrorDialog,
} from '@components/Dialog';
import { DeviceListInProject } from '@customTypes/index';
import { useDeviceActivationMutation } from '@lib/api/dashboard/queryHooks';
import React, { useState } from 'react';

type Props = {
  device: DeviceListInProject;
  disabled: boolean;
};

const DeviceStatusInProject = ({ device, disabled }: Props) => {
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [isDisabledDialogOpen, setIsDisabledDialogOpen] = useState(false);

  const deviceActivationMutation = useDeviceActivationMutation();

  const updateDeviceStatus = (enabled: boolean) => {
    deviceActivationMutation.mutate(
      { idx: device.idx, enabled: enabled },
      {
        onError: () => {
          setIsErrorDialogOpen(true);
        },
        onSettled: () => {
          setIsDisabledDialogOpen(false);
        },
      },
    );
  };

  return (
    <ErrorBoundaryWrapper>
      {device.enabled ? (
        <Button
          type="button"
          onClick={() => setIsDisabledDialogOpen(true)}
          buttonSize="none"
          variant="toggleOn"
          disabled={disabled}
        >
          ON
        </Button>
      ) : (
        <Button
          type="button"
          onClick={() => updateDeviceStatus(true)}
          buttonSize="none"
          variant="toggleOff"
          disabled={disabled}
        >
          OFF
        </Button>
      )}

      {/* 디바이스 비활성화 시 Dialog */}
      <CommonDialog
        isOpen={isDisabledDialogOpen}
        setIsOpen={setIsDisabledDialogOpen}
        iconType={'caution'}
        title={'Device Disable'}
        subTitle={
          '해당 디바이스를 비활성화하면 IDE에서 사용할 수 없습니다.\n비활성화하시겠습니까?\n(스케줄러에 설정된 경우, 테스트 결과는 N/A로 처리됩니다.)'
        }
      >
        <DialogButtonPanels>
          <Button
            type="button"
            variant="primary"
            onClick={() => {
              updateDeviceStatus(false);
            }}
            disabled={deviceActivationMutation.isLoading}
          >
            OK
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={() => setIsDisabledDialogOpen(false)}
          >
            Cancel
          </Button>
        </DialogButtonPanels>
      </CommonDialog>

      {/* 디바이스 비활성화 시 에러 메시지 Dialog */}
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        setIsOpen={setIsErrorDialogOpen}
        subTitle={
          deviceActivationMutation.isError &&
          deviceActivationMutation.error.message
        }
      />
    </ErrorBoundaryWrapper>
  );
};

export default DeviceStatusInProject;
