import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { axiosRawResponseInstance, axiosInstance } from '..';
import { API } from '@api/endpoints';
import { mobileTestResultKeys } from '@queries/ide/mobile/mobileTestResultKeys';

import {
  MobileTestResultPagingData,
  TestResult,
  TestResultDetailInquiryByCase,
  TestResultExportCaseResultList,
  TestResultInquiryByCase,
} from '@customTypes/testResult/type';

import { DeleteIndexList, ServerResponseErrorDetails } from '@customTypes/type';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

// GET
const getMobileTestResultList = async (
  projectIdx: string,
  currentPage: number,
  limit: number,
): Promise<MobileTestResultPagingData> => {
  const response = await axiosInstance.get(
    API.testResultListPagination(projectIdx, currentPage, limit),
  );
  return response.data;
};
export const useMobileTestResultListQuery = (
  projectIdx: string,
  currentPage: number,
  limit: number,
) =>
  useQuery<MobileTestResultPagingData, ServerResponseErrorDetails>({
    queryKey: mobileTestResultKeys.list(projectIdx),
    queryFn: () => getMobileTestResultList(projectIdx, currentPage, limit),
    keepPreviousData: true,
  });

const getMobileTestResultByTestCase = async (
  idx: string,
): Promise<TestResultInquiryByCase> => {
  const response = await axiosInstance.get<TestResultInquiryByCase>(
    `${API.getTestCaseResult(idx)}`,
  );
  return response.data;
};
export const useMobileTestCaseResultQuery = (idx: string) => {
  return useQuery<TestResultInquiryByCase, ServerResponseErrorDetails>({
    queryKey: mobileTestResultKeys.detailInfo(idx),
    queryFn: () => getMobileTestResultByTestCase(idx),
  });
};

const getMobileTestResultDetail = async (idx: string): Promise<TestResult> => {
  const response = await axiosInstance.get(`${API.GET_TEST_RESULT}${idx}`);
  return response.data;
};
export const useMobileTestResultDetailQuery = (idx: string) => {
  return useQuery<TestResult, ServerResponseErrorDetails>({
    queryKey: mobileTestResultKeys.detail(idx),
    queryFn: () => getMobileTestResultDetail(idx),
  });
};

const getMobileTestCaseExecutionDetail = async (
  idx: string,
  caseResultIdx: number,
): Promise<TestResultDetailInquiryByCase[]> => {
  const response = await axiosInstance.get(
    API.getMobileTestCaseExecutionDetail(idx, caseResultIdx),
  );
  return response.data;
};
export const useMobileTestCaseExecutionDetailQuery = (
  caseResultIdx: number,
  options?: UseQueryOptions<
    TestResultDetailInquiryByCase[],
    ServerResponseErrorDetails
  >,
) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  return useQuery<TestResultDetailInquiryByCase[], ServerResponseErrorDetails>({
    queryKey: mobileTestResultKeys.executionDetail(idx, caseResultIdx),
    queryFn: () => getMobileTestCaseExecutionDetail(idx, caseResultIdx),
    ...options,
  });
};

const mobileTestResultSearchList = async (
  search: string,
): Promise<MobileTestResultPagingData> => {
  const response = await axiosInstance.get<MobileTestResultPagingData>(
    `${API.GET_TEST_RESULT}search${search}`,
  );
  return response.data;
};
export const useMobileTestResultSearchListQuery = (
  options?: UseQueryOptions<MobileTestResultPagingData, Error>,
) => {
  const { search } = useLocation();
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page');
  const size = searchParams.get('size');
  const projectIdx = searchParams.get('projectIdx');
  const order = searchParams.get('order');

  return useQuery<MobileTestResultPagingData, Error>({
    queryKey: mobileTestResultKeys.search(search),
    queryFn: () => mobileTestResultSearchList(search),
    enabled:
      page !== null && size !== null && projectIdx !== null && order !== null,
    keepPreviousData: true,
    ...options,
  });
};

//image
const mobileTestResultImageData = async (path: string[]): Promise<string[]> => {
  const requests = path.map((path) =>
    axiosRawResponseInstance.get<MediaSource>(path, {
      responseType: 'blob',
    }),
  );

  const responses = await Promise.all(requests);
  const objectUrls = responses.map((response) =>
    URL.createObjectURL(response.data),
  );

  return objectUrls;
};
export const useMobileTestResultImageData = (
  path: string[],
  options?: UseQueryOptions<string[], Error>,
) => {
  return useQuery<string[], Error>({
    queryKey: mobileTestResultKeys.detailImage(path[0]),
    queryFn: () => mobileTestResultImageData(path),
    ...options,
  });
};

//video
const mobileTestResultVideoAndLogData = async (
  path: string,
): Promise<string> => {
  const response = await axiosRawResponseInstance.get<MediaSource | Blob>(
    path,
    { responseType: 'blob' },
  );

  const objectUrl = URL.createObjectURL(response.data);
  return objectUrl;
};

export const useMobileTestResultVideoData = (
  path: string,
  options?: UseQueryOptions<string, Error>,
) => {
  return useQuery<string, Error>({
    queryKey: mobileTestResultKeys.detailVideo(path),
    queryFn: () => mobileTestResultVideoAndLogData(path),
    ...options,
  });
};

//log
export const useMobileTestResultLogData = (
  path: string,
  options?: UseQueryOptions<string, Error>,
) => {
  return useQuery<string, Error>({
    queryKey: mobileTestResultKeys.detailLog(path),
    queryFn: () => mobileTestResultVideoAndLogData(path),
    ...options,
  });
};

// POST
const mobileTestResultExport = async (
  resultIdx: string,
  data: TestResultExportCaseResultList,
): Promise<{ blob: Blob; filename: string }> => {
  const response = await axiosRawResponseInstance.post<Blob>(
    `${API.TEST_RESULT}/${resultIdx}/download-excel`,
    data,
    { responseType: 'blob' },
  );

  const contentDisposition = response.headers['content-disposition'];
  const filenameMatch = contentDisposition.match(/filename="(.+)"/);

  return { blob: response.data, filename: filenameMatch[1] };
};
export const useMobileTestResultExportMutation = () => {
  const [searchParams] = useSearchParams();
  const resultIdx = searchParams.get('idx');

  return useMutation<
    { blob: Blob; filename: string },
    Error,
    TestResultExportCaseResultList
  >({
    mutationFn: (data) => mobileTestResultExport(resultIdx, data),
    onSuccess: ({ blob, filename }) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },
  });
};

// DELETE
const mobileTestResultDelete = async (
  data: DeleteIndexList,
): Promise<DeleteIndexList> => {
  const response = await axiosInstance.delete<DeleteIndexList>(
    `${API.TEST_RESULT}`,
    { data },
  );
  return response.data;
};
export const useMobileTestResultDeleteMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation<DeleteIndexList, Error, DeleteIndexList>({
    mutationFn: (data) => mobileTestResultDelete(data),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileTestResultKeys.lists());
      queryClient.invalidateQueries(mobileTestResultKeys.searches());
      navigate(-1);
    },
  });
};
