import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  DefaultSkeleton,
  ErrorBoundaryWrapper,
  ErrorMessage,
  Pagination,
  PagingHeadings,
  SpeedTestResultTableSkeleton,
} from '@autosquare/common';

import { useSearchWebSpeedTestResultListQuery } from '@lib/api/dashboard/web/webSpeedResult';
import WebSpeedTestResultList from '@components/WebSpeedTestResultList/WebSpeedTestResultList';
import SearchForm from '@components/SearchForm/SearchForm';
import NoSpeedTestResultMessage from '@components/Search/NoSpeedTestResultMessage';
import getFirstGenerationDateToDateType from '@utils/static/getFirstGenerationDateToDateType';

const WebSpeedTestResult = () => {
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));
  const size = Number(searchParams.get('size'));

  const searchWebSpeedTestResultListQuery =
    useSearchWebSpeedTestResultListQuery();
  const searchWebSpeedTestResultList = searchWebSpeedTestResultListQuery.data;
  const currentUrl = location.pathname + location.search;

  const initialCreatedAt = searchWebSpeedTestResultList?.initialCreatedAt;
  const formattedCreatedAt = getFirstGenerationDateToDateType(initialCreatedAt);

  const breadcrumbs = [
    { title: 'Web', href: currentUrl },
    { title: 'Speed Test Result', href: currentUrl },
    { title: 'List', href: currentUrl },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={'Speed Test Result List'}
      />
      <SearchForm
        calendarSubTitle="Date"
        isFunctionalTest={false}
        initialCreatedAt={formattedCreatedAt}
      >
        <SearchForm.ResultTestType isFunctionalTest={false} />
      </SearchForm>
      {searchWebSpeedTestResultListQuery.isLoading ? (
        <SpeedTestResultTableSkeleton />
      ) : searchWebSpeedTestResultListQuery.isError ? (
        <ErrorMessage>
          {searchWebSpeedTestResultListQuery.error.message}
        </ErrorMessage>
      ) : searchWebSpeedTestResultList?.totalCount === 0 ? (
        <NoSpeedTestResultMessage />
      ) : (
        <WebSpeedTestResultList
          webSpeedTestResultList={searchWebSpeedTestResultList}
        />
      )}
      {searchWebSpeedTestResultListQuery.isLoading ? (
        <DefaultSkeleton number={1} height={'h-4'} outMargin={'my-4'} />
      ) : (
        searchWebSpeedTestResultList?.totalCount > 5 && (
          <Pagination
            page={page}
            total={searchWebSpeedTestResultList?.totalCount}
            size={size}
            isDashboard={false}
          />
        )
      )}
    </ErrorBoundaryWrapper>
  );
};

export default WebSpeedTestResult;
