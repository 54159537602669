import { TestResultExportCaseResultList } from '@customTypes/index';
import { useFormContext, useWatch } from 'react-hook-form';

export const useExportOptionsTestStepResult = (caseResultIdx: number) => {
  const { setValue, control } =
    useFormContext<TestResultExportCaseResultList>();

  const caseResultList = useWatch({ control, name: 'caseResultList' });
  const caseResult = caseResultList.find(
    (item) => item.caseResultIdx === caseResultIdx,
  );

  const handleChildCheckbox = (stepIdx: number, checked: boolean) => {
    const caseResultIndex = caseResultList.findIndex(
      (item) => item.caseResultIdx === caseResultIdx,
    );

    if (caseResultIndex === -1 && checked) {
      // 자식 체크박스를 선택했지만 부모가 없는 경우, 새로 추가
      setValue('caseResultList', [
        ...caseResultList,
        {
          caseResultIdx: caseResultIdx,
          stepResultIdxList: [stepIdx],
        },
      ]);
    } else if (caseResultIndex !== -1) {
      // 부모가 이미 존재하는 경우 업데이트
      const currentStepList = caseResult?.stepResultIdxList || [];

      const newStepList = checked
        ? [...currentStepList, stepIdx] // 자식 체크박스 선택 시 추가
        : currentStepList.filter((id) => id !== stepIdx); // 자식 체크박스 해제 시 제거

      if (newStepList.length === 0) {
        // 자식이 모두 해제되었을 경우 부모를 삭제
        setValue(
          'caseResultList',
          caseResultList.filter((item) => item.caseResultIdx !== caseResultIdx),
        );
      } else {
        // 그렇지 않으면 부모의 자식 목록 업데이트
        setValue(
          `caseResultList.${caseResultIndex}.stepResultIdxList`,
          newStepList,
        );
      }
    }
  };

  return { caseResult, handleChildCheckbox };
};
