import { Button, ErrorBoundaryWrapper } from '@autosquare/common';
import TestStepValueListbox from '@components/IDE/TestStepValueListbox';
import { useWebTestStepContext } from '@contexts/ide/WebTestStepProvider';
import { CreateWebTestStepData } from '@customTypes/testStep/type';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

const WebTestStepMaskingOptionsDirectChoice = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CreateWebTestStepData>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'optionsDto.maskingOptions.valueList',
  });
  const fieldsLength = fields.length;

  const command = watch('command');
  const maskingLastValue = watch(
    `optionsDto.maskingOptions.valueList.${fieldsLength - 1}.value`,
  );

  const { fullXPath } = useWebTestStepContext();

  const [listboxStates, setListboxStates] = useState(
    fields.map((_, index) => index === fields.length - 1),
  );

  const toggleListboxState = (index: number) => {
    setListboxStates((prev) =>
      prev.map((state, i) => (i === index ? !state : state)),
    );
  };

  useEffect(() => {
    setListboxStates(fields.map((_, index) => index === fields.length - 1));
  }, [fields]);

  useEffect(() => {
    setValue('optionsDto.maskingOptions.valueList', [
      { value: '', isListboxOpened: true },
    ]);
  }, [setValue]);

  return (
    <ErrorBoundaryWrapper>
      <div className="flex w-full flex-col items-center justify-between space-y-4">
        {fields.map((field, index) => (
          <div
            key={field.id}
            className="flex w-full items-center justify-between gap-4"
          >
            <Controller
              control={control}
              name={`optionsDto.maskingOptions.valueList.${index}.value`}
              rules={{ required: 'Value를 선택해 주세요.' }}
              render={({ field }) => (
                <div className="w-[calc(100%-3.5rem)]">
                  <TestStepValueListbox
                    valueText={field.value || 'Value'}
                    setValueText={field.onChange}
                    totalValues={fullXPath}
                    isListboxOpened={listboxStates[index]}
                    setIsListboxOpened={() => toggleListboxState(index)}
                    disabled={command === undefined}
                    isReset={false}
                  />
                </div>
              )}
            />
            <Button
              type="button"
              variant="secondary"
              buttonSize="xs"
              onClick={() => remove(index)}
              disabled={fieldsLength === 1}
              isSquare
            >
              <MinusIcon className="size-6 text-gray-900" />
            </Button>
          </div>
        ))}
      </div>
      <button
        type="button"
        className="flex w-full items-center justify-center rounded border border-dashed border-gray-300 px-2 py-1"
        onClick={() => {
          if (maskingLastValue) {
            append({ value: '', isListboxOpened: true });
          }
        }}
        disabled={!maskingLastValue}
      >
        <PlusIcon className="size-6" />
      </button>
      <ErrorMessage
        errors={errors}
        name={`optionsDto.maskingOptions.valueList.${fieldsLength - 1}.value`}
        render={({ message }) => <p className="error-message">{message}</p>}
      />
    </ErrorBoundaryWrapper>
  );
};

export default WebTestStepMaskingOptionsDirectChoice;
