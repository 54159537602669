import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  PagingHeadings,
  ErrorBoundaryWrapper,
  ListSkeleton,
  RoleAuth,
} from '@autosquare/common';
import AddProjectMemberForm from './components/AddProjectMemberForm';
import { useAuthUserInfo } from '@hooks/index';
import { AccessDenied } from '@components/index';

const AddProjectMember = () => {
  const { pathname } = useLocation();

  const { accessToken, userProjectRole, userAuthRole } = useAuthUserInfo();

  const memberListPage = pathname.split('/').slice(0, -1).join('/');

  const breadcrumbs = [
    { title: 'Project Settings', href: memberListPage },
    { title: 'Members', href: memberListPage },
    { title: 'Add', href: pathname },
  ];

  if (!accessToken) {
    return <ListSkeleton />;
  }

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings breadcrumbs={breadcrumbs} pageTitle={'Add Member'} />
      {userProjectRole === 0 || userAuthRole === RoleAuth.Admin ? (
        <AddProjectMemberForm memberListPage={memberListPage} />
      ) : (
        <AccessDenied />
      )}
    </ErrorBoundaryWrapper>
  );
};

export default AddProjectMember;
