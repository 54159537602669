import {
  Breadcrumbs,
  DescriptionListSkeleton,
  ErrorBoundaryWrapper,
  ErrorMessage,
  PagingHeadingsInAutosquare,
} from '@autosquare/common';
import AddWirelessDeviceButton from '@components/AddWirelessDeviceButton/AddWirelessDeviceButton';
import { useSpeedSchedulerDetailQuery } from '@lib/api/ide/speedScheduler/speedScheduler';
import React from 'react';
import { useLocation } from 'react-router-dom';
import SpeedTestSchedulerDetailForm from './components/SpeedTestSchedulerDetailForm';

const SpeedTestSchedulerDetail = () => {
  const location = useLocation();

  const speedSchedulerDetailQuery = useSpeedSchedulerDetailQuery();

  const breadcrumbs: Breadcrumbs[] = [
    {
      title: 'Speed Test',
      href: `${location.pathname.split('/').slice(0, -2).join('/')}/overview`,
    },
    {
      title: 'Scheduler',
      href: `${location.pathname.split('/').slice(0, -1).join('/')}`,
    },
    {
      title: 'Detail',
      href: location.pathname + location.search,
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="flex w-full items-end justify-between">
        <PagingHeadingsInAutosquare
          breadcrumbs={breadcrumbs}
          pageTitle={'Scheduler Detail'}
        />
        <AddWirelessDeviceButton />
      </div>
      {speedSchedulerDetailQuery.isLoading ||
      speedSchedulerDetailQuery.isFetching ? (
        <DescriptionListSkeleton number={10} isLine={false} />
      ) : speedSchedulerDetailQuery.isError ? (
        <ErrorMessage margin="my-8">
          {speedSchedulerDetailQuery.error.message}
        </ErrorMessage>
      ) : (
        <SpeedTestSchedulerDetailForm />
      )}
    </ErrorBoundaryWrapper>
  );
};

export default SpeedTestSchedulerDetail;
