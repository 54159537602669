import React, { useState } from 'react';
import clsx from 'clsx';
import {
  ErrorBoundaryWrapper,
  ErrorMessage,
  PagingHeadingsInAutosquare,
} from '@autosquare/common';
import { useLocation } from 'react-router-dom';
import {
  ProjectListDetailFeaturesCategory,
  ProjectListDetailFeaturesFeature,
} from '@customTypes/dashboard/project/type';
import { useProjectDetailQuery } from '@lib/api/ide/project/project';
import { checkIsProjectFeatureIncluded } from '@utils/static/checkProjectFeature';
import { useGetIdeSpeedMobileTestResult } from '@lib/api/ide/speedMobile/speedMobileTestResult';
import { useIdeWebSpeedTestResultListQuery } from '@lib/api/ide/speedWeb/webSpeedTestResult';
import { useGetResultRateQuery } from '@lib/api/ide/overview/overview';
import OverviewGraphSkeleton from '@components/Skeleton/OverviewGraphSkeleton';
import ResultRatePie from '@components/Overview/ResultRatePie';
import OverviewSubTitle from '@components/shared/OverviewSubTitle';
import TestBreakdownGroup from '@components/Overview/TestBreakdownGroup';
import SpeedEnvironmentBar from '@components/Overview/SpeedEnvironmentBar';
import SpeedTestResultTableSection from './components/SpeedTestResultTableSection';
import SlidingTabWithTestResult from './components/SlidingTabWithTestResult';
import { TestPlatformType } from '@lib/api/dashboard/overview/overview';

const SpeedTestOverview = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { pathname, search } = useLocation();

  const PAGE_LIMIT = 5;
  const PAGE = 1;
  const speedMobileTestResultQuery = useGetIdeSpeedMobileTestResult(
    PAGE,
    PAGE_LIMIT,
  );
  const speedMobileTestResultData = speedMobileTestResultQuery.data;

  const speedWebTestResultQuery = useIdeWebSpeedTestResultListQuery(PAGE);
  const speedWebTestResultData = speedWebTestResultQuery.data;

  const projectDetailQuery = useProjectDetailQuery();
  const projectDetail = projectDetailQuery.data;

  const isMobileTestEnabled = checkIsProjectFeatureIncluded(
    projectDetail,
    ProjectListDetailFeaturesCategory.Mobile,
    ProjectListDetailFeaturesFeature.Speed,
  );
  const isWebTestEnabled = checkIsProjectFeatureIncluded(
    projectDetail,
    ProjectListDetailFeaturesCategory.Web,
    ProjectListDetailFeaturesFeature.Speed,
  );
  const isBothEnabled = isMobileTestEnabled && isWebTestEnabled;

  const reportQuery = useGetResultRateQuery();
  const reportData = reportQuery.data;
  const mobileReportData = reportData?.mobileResultReports;
  const webReportData = reportData?.webResultReports;

  const mobileTotal = speedMobileTestResultData?.totalElements;
  const webTotal = speedWebTestResultData?.totalCount;
  const mobileTabContents = {
    title: TestPlatformType.MOBILE,
    content: [
      {
        id: 1,
        post: (
          <SpeedTestResultTableSection
            total={mobileTotal}
            resultData={speedMobileTestResultData}
          />
        ),
      },
    ],
  };
  const webTabContents = {
    title: TestPlatformType.WEB,
    content: [
      {
        id: 1,
        post: (
          <SpeedTestResultTableSection
            total={webTotal}
            resultData={speedWebTestResultData}
          />
        ),
      },
    ],
  };
  const tabList = isBothEnabled
    ? [mobileTabContents, webTabContents]
    : isMobileTestEnabled
      ? [mobileTabContents]
      : [webTabContents];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsInAutosquare
        breadcrumbs={[
          { title: 'Speed Test', href: pathname + search },
          { title: 'Overview', href: pathname + search },
        ]}
        pageTitle={'Overview'}
      />
      {speedMobileTestResultQuery.isLoading ||
      speedMobileTestResultQuery.isFetching ||
      speedWebTestResultQuery.isLoading ||
      speedWebTestResultQuery.isFetching ||
      projectDetailQuery.isLoading ||
      projectDetailQuery.isFetching ||
      reportQuery.isLoading ||
      reportQuery.isFetching ? (
        <OverviewGraphSkeleton />
      ) : speedMobileTestResultQuery.isError ? (
        <ErrorMessage>{speedMobileTestResultQuery.error.message}</ErrorMessage>
      ) : speedWebTestResultQuery.isError ? (
        <ErrorMessage> {speedWebTestResultQuery.error.message}</ErrorMessage>
      ) : projectDetailQuery.isError ? (
        <ErrorMessage>{projectDetailQuery.error.message}</ErrorMessage>
      ) : reportQuery.isError ? (
        <ErrorMessage>{reportQuery.error.message}</ErrorMessage>
      ) : (
        <div className="mt-8 flow-root">
          <OverviewSubTitle
            subTitle={'Result Rate'}
            description="프로젝트 테스트 결과 비율"
          />
          <div className="grid grid-cols-2 gap-x-7">
            {isMobileTestEnabled && (
              <ResultRatePie data={mobileReportData} header="Mobile" />
            )}
            {isWebTestEnabled && (
              <ResultRatePie data={webReportData} header="Web" />
            )}
            <div className={clsx(isBothEnabled && 'col-span-2')}>
              <OverviewSubTitle
                subTitle={'Environment'}
                description="테스트 환경"
              />
              <div
                className={clsx(isBothEnabled && 'grid grid-cols-2 gap-x-3')}
              >
                {isMobileTestEnabled && (
                  <SpeedEnvironmentBar
                    data={mobileReportData?.envRate}
                    envType={'mobile'}
                  />
                )}
                {isWebTestEnabled && (
                  <SpeedEnvironmentBar
                    data={webReportData?.envRate}
                    envType={'web'}
                  />
                )}
              </div>
              <div className="mt-8 flow-root">
                <OverviewSubTitle
                  subTitle={'Test Breakdown'}
                  description="프로젝트 내 스케줄러, 시나리오, 케이스 개수"
                />
                <TestBreakdownGroup
                  data={reportData}
                  isMobileTestEnabled={isMobileTestEnabled}
                  isWebTestEnabled={isWebTestEnabled}
                />
              </div>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <OverviewSubTitle
              subTitle={'Recent Test Results'}
              description="최근 테스트 결과 목록"
            />
          </div>
          <SlidingTabWithTestResult
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            tabList={tabList}
          />
        </div>
      )}
    </ErrorBoundaryWrapper>
  );
};

export default SpeedTestOverview;
