import React from 'react';
import AddDeviceFormFields from './AddDeviceFormFields';
import { useFormContext } from 'react-hook-form';
import { DeviceApprovalRequest } from '@customTypes/index';

const AddDeviceName = () => {
  const { register } = useFormContext<DeviceApprovalRequest>();

  return (
    <AddDeviceFormFields
      label="Device Name (1~20자, 영문 대소문자, 한글, 숫자[0-9], 대시(-), 언더바(_), 쉼표(,), 온점(.) 입력 가능)"
      name="deviceName"
    >
      <input
        className="input-base"
        placeholder="등록할 디바이스의 이름을 입력해 주세요."
        {...register('deviceName')}
      />
    </AddDeviceFormFields>
  );
};

export default AddDeviceName;
