import { createQueryKeys } from '@queries/createQueryKeys';

export const webTestResultKeys = {
  ...createQueryKeys('webTestResult'),
  detailInfos: () => [...webTestResultKeys.all, 'detailInfo'] as const,
  detailInfo: (idx: string) =>
    [...webTestResultKeys.detailInfos(), idx] as const,
  executionDetails: () =>
    [...webTestResultKeys.all, 'executionDetail'] as const,
  executionDetail: (testresultidx: string, caseResultIdx: number) =>
    [
      ...webTestResultKeys.executionDetails(),
      testresultidx,
      caseResultIdx,
    ] as const,
  detailImage: (path: string) =>
    [[...webTestResultKeys.detailInfos(), path], 'image'] as const,
};
