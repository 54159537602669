import React, { useState } from 'react';
import { ErrorBoundaryWrapper, ToggledSwitch } from '@autosquare/common';
import { AutoRegisterType } from '@customTypes/dashboard/admin/type';
import AutoRegisterList from '@components/AutoRegisterList/AutoRegisterList';

type Props = {
  registerData: AutoRegisterType[];
};

const ProjectDetailsAutoRegister = ({ registerData }: Props) => {
  const isActivate =
    registerData.length === 0
      ? false
      : registerData.every((data) => data?.activate);

  const [isToggleOn, setIsToggleOn] = useState(isActivate);

  return (
    <ErrorBoundaryWrapper>
      <div className="flex items-center justify-between gap-x-3 py-6">
        <div className="flex gap-x-3">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            AutoRegister
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            프로젝트에서 테스트 결과를 자동 등록할 플랫폼을 설정해 주세요.
          </p>
        </div>
        <div className="flex justify-end">
          <ToggledSwitch
            isEnabled={isToggleOn}
            setIsEnabled={setIsToggleOn}
            disabled
          />
        </div>
      </div>
      {isToggleOn && (
        <div className="flex items-center gap-x-4 space-x-10">
          <div className="text-sm font-medium leading-normal text-gray-900">
            Platform
          </div>
          <div className={'flex items-center gap-x-4'}>
            <AutoRegisterList
              autoDataRegisterList={registerData}
              isEditable={false}
            />
          </div>
        </div>
      )}
    </ErrorBoundaryWrapper>
  );
};

export default ProjectDetailsAutoRegister;
