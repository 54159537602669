import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useDeviceListQuery } from '@lib/api/ide/mobile/mobileDevice';
import { useWebBrowserListQuery } from '@lib/api/ide/web/webBrowser';

import {
  SchedulerTypeServerName,
  UpdateSchedulerData,
} from '@customTypes/ide/scheduler/scheduler';
import { DeviceInfo } from '@customTypes/ide/device/device';
import { BrowserInfo } from '@customTypes/ide/browser/browser';
import { ErrorMessage } from '@hookform/error-message';
import DeviceListEditBox from '@components/Scheduler/DeviceListEditBox';
import BrowserListEditBox from '@components/Scheduler/BrowserListEditBox';
import { ErrorBoundaryWrapper } from '@autosquare/common';

import { Mode } from '@customTypes/ide/remote/type';
import {
  useRemoteDeviceListQuery,
  useRemoteWebBrowserListQuery,
} from '@lib/api/ide/remote/remote';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';
import { getRemoteState } from '@utils/static/getConfiguration';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';

interface Props {
  type: string;
  isRunning: boolean;
}

const SchedulerDetailDevice = ({ type, isRunning }: Props) => {
  const { configuration } = getRemoteState();
  const deviceListQuery =
    configuration === Mode.Standard
      ? useDeviceListQuery({
          enabled: type === SchedulerTypeServerName.Mobile,
        })
      : useRemoteDeviceListQuery({
          enabled: type === SchedulerTypeServerName.Mobile,
        });

  const browserListQuery =
    configuration === Mode.Standard
      ? useWebBrowserListQuery({
          enabled: type === SchedulerTypeServerName.Web,
        })
      : useRemoteWebBrowserListQuery({
          enabled: type === SchedulerTypeServerName.Web,
        });

  const {
    control,
    formState: { errors },
  } = useFormContext<UpdateSchedulerData>();
  const filteredDevice =
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deviceListQuery.data?.map(({ enabled, ...rest }) => rest) ?? [];

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer
        title={type === SchedulerTypeServerName.Mobile ? 'Device' : 'Browser'}
      >
        <div className="mt-2 flex items-center justify-start gap-x-4 sm:col-span-2 sm:mt-0">
          {type === SchedulerTypeServerName.Mobile ? (
            <Controller
              name="infos"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DeviceListEditBox
                  selectedDevice={field.value as DeviceInfo}
                  setSelectedDevice={field.onChange}
                  deviceListQuery={deviceListQuery}
                  deviceLists={filteredDevice}
                  isRunning={isRunning}
                />
              )}
            />
          ) : (
            <Controller
              name="infos"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <BrowserListEditBox
                  selectedBrowser={field.value as BrowserInfo}
                  setSelectedBrowser={field.onChange}
                  browserListQuery={browserListQuery}
                  browserList={browserListQuery.data}
                  isRunning={isRunning}
                />
              )}
            />
          )}
        </div>
        <ErrorMessage
          name="infos"
          errors={errors}
          render={({ message }) => (
            <p className="standard-error-message">{message}</p>
          )}
        />
      </SchedulerGridContainer>
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default SchedulerDetailDevice;
