import React, { useContext } from 'react';
import { Stage, Layer } from 'react-konva';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import { MobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';

import useScreenshotLogic from '@hooks/testStep/useScreenshotLogic';

import { ClickId } from '@utils/static/clickOptions';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';

import {
  CreateTestStepData,
  MaskingOptionsType,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import {
  CoordinateData,
  RectOrTwoPointsCoordinate,
  SourceProperties,
} from '@customTypes/testStep/type';

import SelectedObject from './Screenshot/SelectedObject';
import MovingArrow from './Screenshot/MovingArrow';
import ClickCircle from './Screenshot/ClickCircle';
import ComparingRect from './Screenshot/ComparingRect';
import MobileImage from './Screenshot/MobileImage';
import PatternDrawer from './Screenshot/PatternDrawer';
import MaskingRect from './Screenshot/MaskingRect';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Props = {
  screenshot: string;
  getCoordinate: RectOrTwoPointsCoordinate[];
  coordinateInfo: SourceProperties[];
};

const ScreenShot = ({ screenshot, getCoordinate, coordinateInfo }: Props) => {
  const { isLandscapeMode } = useContext(MobileTestStepContext);

  const { watch } = useFormContext<CreateTestStepData>();

  const command = watch('command');
  const value = watch('value');
  const clickBy = watch('optionsDto.clickBy');
  const conditionCommand = watch('optionsDto.conditionCommand');
  const maskingOptionsEnabled =
    watch('optionsDto.maskingOptions.enabled') === 'true';
  const makingOptionsType = watch('optionsDto.maskingOptions.type');

  const {
    isSelected,
    setIsSelected,
    startNode,
    setStartNode,
    endNode,
    setEndNode,
    minLengthWidthArray,
    divRef,
    stageWidth,
    stageHeight,
    imageRef,
    checkDeselect,
    handleMouseOver,
    realScreenRatio,
    stageOnClick,
  } = useScreenshotLogic(getCoordinate, coordinateInfo);

  return (
    <ErrorBoundaryWrapper>
      <div
        className={clsx('flex', isLandscapeMode ? 'col-span-7' : 'col-span-4')}
        ref={divRef}
      >
        <div className="gray-scrollbar flex w-full items-center justify-center overflow-x-auto border text-sm font-semibold">
          <Stage
            width={stageWidth}
            height={stageHeight}
            onClick={stageOnClick}
            onMouseMove={handleMouseOver}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
          >
            <Layer>
              <MobileImage
                screenshot={screenshot}
                stageWidth={stageWidth}
                stageHeight={stageHeight}
                imageRef={imageRef}
              />
              {!maskingOptionsEnabled ? (
                command === MobileCommandOptionServerName.ScrollSwipe ? (
                  <MovingArrow
                    startNode={startNode}
                    endNode={endNode}
                    setStartNode={setStartNode}
                    setEndNode={setEndNode}
                    realScreenRatio={realScreenRatio}
                  />
                ) : command === MobileCommandOptionServerName.Click &&
                  clickBy === ClickId.Coordinate ? (
                  <ClickCircle realScreenRatio={realScreenRatio} />
                ) : (command === MobileCommandOptionServerName.Click &&
                    clickBy === ClickId.Image) ||
                  command === MobileCommandOptionServerName.CompareImage ? (
                  <ComparingRect
                    isSelected={isSelected}
                    setIsSelected={setIsSelected}
                    realScreenRatio={realScreenRatio}
                  />
                ) : command === MobileCommandOptionServerName.Loop &&
                  value === MobileCommandOptionServerName.ScrollSwipe ? (
                  <>
                    {minLengthWidthArray?.map((coordinate: CoordinateData) => (
                      <SelectedObject
                        key={coordinate.idx}
                        coordinate={coordinate}
                      />
                    ))}
                    <MovingArrow
                      startNode={startNode}
                      endNode={endNode}
                      setStartNode={setStartNode}
                      setEndNode={setEndNode}
                      realScreenRatio={realScreenRatio}
                    />
                  </>
                ) : command === MobileCommandOptionServerName.IF &&
                  conditionCommand ===
                    MobileCommandOptionServerName.CompareImage ? (
                  <>
                    {minLengthWidthArray?.map((coordinate: CoordinateData) => (
                      <SelectedObject
                        key={coordinate.idx}
                        coordinate={coordinate}
                      />
                    ))}
                    <ComparingRect
                      isSelected={isSelected}
                      setIsSelected={setIsSelected}
                      realScreenRatio={realScreenRatio}
                    />
                  </>
                ) : command === MobileCommandOptionServerName.UnlockPattern ? (
                  <PatternDrawer
                    stageWidth={stageWidth}
                    stageHeight={stageHeight}
                  />
                ) : (
                  minLengthWidthArray?.map((coordinate: CoordinateData) => (
                    <SelectedObject
                      key={coordinate.idx}
                      coordinate={coordinate}
                    />
                  ))
                )
              ) : makingOptionsType === MaskingOptionsType.Element ? (
                minLengthWidthArray?.map((coordinate: CoordinateData) => (
                  <SelectedObject
                    key={coordinate.idx}
                    coordinate={coordinate}
                  />
                ))
              ) : (
                makingOptionsType === MaskingOptionsType.Coordinate && (
                  <MaskingRect
                    isSelected={isSelected}
                    setIsSelected={setIsSelected}
                    realScreenRatio={realScreenRatio}
                  />
                )
              )}
            </Layer>
          </Stage>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default ScreenShot;
