import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { RemoteControl } from '@customTypes/index';

export type RemoteControlState = {
  remoteInfo: RemoteControl | undefined;
};

const initialState: RemoteControlState = {
  remoteInfo: undefined,
};

export const remoteControlSlice = createSlice({
  name: 'remote control',
  initialState,
  reducers: {
    getRemoteInfo: (state, action: PayloadAction<RemoteControl | null>) => {
      state.remoteInfo = action.payload;
    },
    deleteRemoteInfo: (state) => {
      state.remoteInfo = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const { getRemoteInfo, deleteRemoteInfo } = remoteControlSlice.actions;

export default remoteControlSlice.reducer;
