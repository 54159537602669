import React from 'react';
import clsx from 'clsx';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ErrorBoundaryWrapper,
  SpeedTestResultTableDataCell,
  SpeedTestResultTableHeader,
  Time,
} from '@autosquare/common';
import { MobileSpeedTestResultData } from '@customTypes/ide/speedTest/Mobile/testResult/type';
import { timePattern } from '@utils/static/timePattern';
import dayjs from 'dayjs';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { TestResultStatus } from '@customTypes/testResult/type';

type Props = {
  resultData: MobileSpeedTestResultData;
};

const MobileSpeedTestResultsOverviewTable = ({ resultData }: Props) => {
  const { projectIdx } = useParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const isDashboard =
    !isWordInLocationPathname('speed-test') &&
    !isWordInLocationPathname('dashboard');

  const navigateUrl = (
    resultIdx: number,
    groupIdx: number,
    dateTime: string,
  ) => {
    return isDashboard
      ? `/projects/${projectIdx}/mobile/speed/test-result/detail?result=${resultIdx}&group=${groupIdx}&start=${dayjs(dateTime).subtract(14, 'days').format('YYYYMMDD')}&end=${dayjs(dateTime).format('YYYYMMDD')}`
      : `/ide/projects/${projectIdx}/speed-test/mobile/test-result/detail?result=${resultIdx}&group=${groupIdx}&start=${dayjs(dateTime).subtract(14, 'days').format('YYYYMMDD')}&end=${dayjs(dateTime).format('YYYYMMDD')}`;
  };

  const resultWithUUID = resultData?.content?.map((item) => ({
    ...item,
    index: crypto.randomUUID(),
  }));

  return (
    <ErrorBoundaryWrapper>
      <div className="flex flex-col gap-4">
        {resultWithUUID?.map((item) => (
          <div key={item.index} className="flex w-full flex-col gap-3.5">
            <div className="flex w-full items-center justify-between leading-tight">
              <h2 className="text-sm font-semibold leading-tight text-black">
                {item.group} Loading Time 비교측정 결과
              </h2>
              <button
                type="button"
                className={clsx(
                  'flex items-center justify-center text-xs font-semibold',
                  item.resultEnum === 'RUNNING'
                    ? 'text-gray-400'
                    : 'text-gray-900',
                )}
                disabled={item.resultEnum === 'RUNNING'}
                onClick={() => {
                  localStorage.setItem('entryPoint', pathname + search);
                  navigate(
                    navigateUrl(item.resultIdx, item.groupIdx, item.dateTime),
                  );
                }}
              >
                <div className="text-center text-xs font-semibold leading-tight">
                  Detail
                </div>
                <ChevronRightIcon className="size-5" />
              </button>
            </div>
            <div className="gray-xy-scrollbar">
              <table className="min-w-full bg-white">
                <thead className="bg-gray-50">
                  <tr className="border-b border-gray-200">
                    <SpeedTestResultTableHeader width="w-40">
                      Type
                    </SpeedTestResultTableHeader>
                    <SpeedTestResultTableHeader width="w-40">
                      DateTime
                    </SpeedTestResultTableHeader>
                    {item.loadSpeedData.map((data, index) => (
                      <SpeedTestResultTableHeader key={index}>
                        {data.name}
                      </SpeedTestResultTableHeader>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b border-gray-400">
                    <SpeedTestResultTableDataCell>
                      {item.testType}
                    </SpeedTestResultTableDataCell>
                    <SpeedTestResultTableDataCell>
                      <p>{item?.dateTime?.split(' ')[0] ?? '-'}</p>
                      <div className="flex items-center gap-1 text-sm font-normal leading-tight text-gray-500">
                        {item?.dateTime && <Time />}
                        {RegExp(timePattern).exec(item.dateTime)[0]}
                      </div>
                    </SpeedTestResultTableDataCell>
                    {item.loadSpeedData.map((data) => (
                      <SpeedTestResultTableDataCell
                        key={data.name}
                        isOverThreeSeconds={data.value > 3}
                      >
                        {item.resultEnum === TestResultStatus.Pass
                          ? data.value
                          : item.resultEnum === TestResultStatus.NA
                            ? 'N/A'
                            : item.resultEnum === TestResultStatus.Fail
                              ? 'Fail'
                              : 'Running'}
                      </SpeedTestResultTableDataCell>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default MobileSpeedTestResultsOverviewTable;
