import {
  CreateWebSpeedTestCaseInfo,
  DeleteWebSpeedTestCaseInfo,
  UpdateWebSpeedTestCaseInfo,
  WebSpeedTestCaseInfo,
} from '@customTypes/ide/speedTest/Web/testCase/type';
import { axiosInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';
import { webSpeedTestCaseKeys } from '@queries/ide/speedWeb/webSpeedTestCaseKeys';

import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { groupKeys, serviceGroupKeys } from '@queries/ide/groupKeys';

// GET
const getWebSpeedTestCaseList = async (
  projectIdx: string,
): Promise<WebSpeedTestCaseInfo[]> => {
  const response = await axiosInstance.get<WebSpeedTestCaseInfo[]>(
    `${IDEAPI.WEB_SPEED_TEST_CASE}?project=${projectIdx}`,
  );
  return response.data;
};
export const useWebSpeedTestCaseListQuery = (
  options?: UseQueryOptions<WebSpeedTestCaseInfo[], Error>,
) => {
  const { projectIdx } = useParams();

  return useQuery<WebSpeedTestCaseInfo[], Error>({
    queryKey: webSpeedTestCaseKeys.list(projectIdx),
    queryFn: () => getWebSpeedTestCaseList(projectIdx),
    ...options,
  });
};

const getWebSpeedTestCaseDetail = async (
  idx: string,
): Promise<WebSpeedTestCaseInfo> => {
  const response = await axiosInstance.get<WebSpeedTestCaseInfo>(
    `${IDEAPI.WEB_SPEED_TEST_CASE}/${idx}`,
  );
  return response.data;
};
export const useWebSpeedTestCaseDetailQuery = (
  testCaseIndex?: string,
  options?: UseQueryOptions<WebSpeedTestCaseInfo, Error>,
) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const selectedIndex = testCaseIndex ? testCaseIndex : idx;

  return useQuery<WebSpeedTestCaseInfo, Error>({
    queryKey: webSpeedTestCaseKeys.detail(selectedIndex),
    queryFn: () => getWebSpeedTestCaseDetail(selectedIndex),
    ...options,
  });
};

const fetchWebSpeedTestCasesBySearch = async (
  search: string,
): Promise<WebSpeedTestCaseInfo[]> => {
  const response = await axiosInstance.get<WebSpeedTestCaseInfo[]>(
    `${IDEAPI.WEB_SPEED_TEST_CASE}/search${search}`,
  );
  return response.data;
};
export const useSearchWebSpeedTestCaseListQuery = () => {
  const { search } = useLocation();
  return useQuery<WebSpeedTestCaseInfo[], Error>({
    queryKey: webSpeedTestCaseKeys.search(search),
    queryFn: () => fetchWebSpeedTestCasesBySearch(search),
    enabled: search !== '',
  });
};

// POST
const createWebSpeedTestCase = async (
  data: CreateWebSpeedTestCaseInfo,
): Promise<WebSpeedTestCaseInfo> => {
  const response = await axiosInstance.post<WebSpeedTestCaseInfo>(
    IDEAPI.WEB_SPEED_TEST_CASE,
    data,
  );
  return response.data;
};

export const useCreateWebSpeedTestCaseMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return useMutation<
    WebSpeedTestCaseInfo,
    ServerResponseErrorDetails,
    CreateWebSpeedTestCaseInfo
  >({
    mutationFn: (data) => createWebSpeedTestCase(data),
    onSuccess: () => {
      queryClient.invalidateQueries(webSpeedTestCaseKeys.all);
      navigate(pathname.split('/').slice(0, -1).join('/'));
    },
  });
};

// PUT
const updateWebSpeedTestCase = async (
  idx: string,
  data: UpdateWebSpeedTestCaseInfo,
): Promise<WebSpeedTestCaseInfo> => {
  const response = await axiosInstance.put<WebSpeedTestCaseInfo>(
    `${IDEAPI.WEB_SPEED_TEST_CASE}/${idx}`,
    data,
  );
  return response.data;
};
export const useUpdateWebSpeedTestCaseMutation = () => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');
  const queryClient = useQueryClient();

  return useMutation<
    WebSpeedTestCaseInfo,
    ServerResponseErrorDetails,
    UpdateWebSpeedTestCaseInfo
  >({
    mutationFn: (data) => updateWebSpeedTestCase(idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(webSpeedTestCaseKeys.all);
      queryClient.invalidateQueries(serviceGroupKeys.lists());
      queryClient.invalidateQueries(groupKeys.lists());
    },
  });
};

// DELETE
const deleteWebSpeedTestCaseMutation = async (
  data: DeleteWebSpeedTestCaseInfo,
): Promise<unknown> => {
  const response = await axiosInstance.delete<unknown>(
    IDEAPI.WEB_SPEED_TEST_CASE,
    { data: data },
  );
  return response.data;
};
export const useDeleteWebSpeedTestCaseMutation = () => {
  const navigate = useNavigate();
  const { projectIdx } = useParams();
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, DeleteWebSpeedTestCaseInfo>({
    mutationFn: (data) => deleteWebSpeedTestCaseMutation(data),
    onSuccess: () => {
      navigate(`/ide/projects/${projectIdx}/speed-test/web/test-case`);
      queryClient.invalidateQueries(webSpeedTestCaseKeys.lists());
      queryClient.invalidateQueries(webSpeedTestCaseKeys.searches());
      queryClient.invalidateQueries(serviceGroupKeys.lists());
      queryClient.invalidateQueries(groupKeys.lists());
    },
  });
};
