import { ErrorBoundaryWrapper } from '@autosquare/common';
import React from 'react';
import InstallResultHeader from './InstallResultHeader';
import { InstallAppResponse } from '@customTypes/index';
import InstallResultTable from './InstallResult/InstallResultTable';

type Props = {
  installAppResponse: InstallAppResponse[];
};

const InstallResult = ({ installAppResponse }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <InstallResultHeader />
      <InstallResultTable installAppResponse={installAppResponse} />
    </ErrorBoundaryWrapper>
  );
};

export default InstallResult;
