import {
  CreateTestStepData,
  MobileSpeedTestStepList,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { axiosInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { speedMobileTestStepKeys } from '@queries/ide/speedMobile/speedMobileTestStepKeys';
import { MobileTestStepData } from '@customTypes/testStep/type';
import { SpeedTestStepRepeat } from '@customTypes/speedTestStep/type';
import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { useSearchParams } from 'react-router-dom';
import { MobileCommandOptionApiName } from '@utils/static/mobileCommandOption';

interface UpdateTestStepListSequenceMutation {
  idx: string;
  mobileOs: DeviceInfoOs;
  step: number[];
  startStepIdx: number;
  endStepIdx: number;
}
export interface DeleteSpeedTestStepMutation {
  stepIdxList: number[];
  mobileOs: DeviceInfoOs;
}

//GET
const getSpeedMobileTestStepList = async (
  idx: string,
): Promise<MobileSpeedTestStepList> => {
  const response = await axiosInstance.get<MobileSpeedTestStepList>(
    IDEAPI.GET_AND_CREATE_DELETE_MOBILE_SPEED_TEST_STEP + idx,
  );
  return response.data;
};

export const useGetSpeedMobileTestStep = (
  caseIdx?: string,
  options?: UseQueryOptions<MobileSpeedTestStepList>,
) => {
  const [searchParams] = useSearchParams();
  const idx = caseIdx ? caseIdx : searchParams.get('idx');
  return useQuery<MobileSpeedTestStepList, Error>({
    queryFn: () => getSpeedMobileTestStepList(idx),
    queryKey: speedMobileTestStepKeys.list(idx),
    ...options,
  });
};

const getSpeedMobileTestStepDetail = async (
  idx: number,
): Promise<MobileTestStepData> => {
  const response = await axiosInstance.get<MobileTestStepData>(
    IDEAPI.DETAIL_AND_UPDATE_MOBILE_SPEED_TEST_STEP + idx,
  );
  return response.data;
};

export const useGetSpeedMobileTestStepDetail = (
  idx: number,
  options?: UseQueryOptions<MobileTestStepData, Error>,
) =>
  useQuery<MobileTestStepData, Error>({
    queryFn: () => getSpeedMobileTestStepDetail(idx),
    queryKey: speedMobileTestStepKeys.detail(idx),
    ...options,
  });

const getSpeedMobileTestStepBySaveText = async (
  idx: number,
): Promise<MobileTestStepData[]> => {
  const response = await axiosInstance.get<MobileTestStepData[]>(
    `${IDEAPI.getSpeedMobileTestStepsByCommand(
      MobileCommandOptionApiName.SaveText,
      idx,
    )}`,
  );
  return response.data;
};
export const useSpeedMobileTestStepBySaveTextQuery = () => {
  const [searchParams] = useSearchParams();
  const idx = Number(searchParams.get('idx'));
  return useQuery<MobileTestStepData[], Error>({
    queryKey: speedMobileTestStepKeys.command(
      MobileCommandOptionApiName.SaveText,
      idx,
    ),
    queryFn: () => getSpeedMobileTestStepBySaveText(idx),
  });
};

//POST
const createSpeedMobileTestStep = async (
  idx: string,
  data: CreateTestStepData,
): Promise<unknown> => {
  const response = await axiosInstance.post<unknown>(
    IDEAPI.GET_AND_CREATE_DELETE_MOBILE_SPEED_TEST_STEP + idx,
    data,
  );
  return response.data;
};

export const useCreateSpeedMobileTestStep = () => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  return useMutation<unknown, Error, CreateTestStepData>({
    mutationFn: (data) => createSpeedMobileTestStep(idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(speedMobileTestStepKeys.list(idx));
    },
  });
};

//PUT
const updateSpeedMobileTestStep = async (
  idx: number,
  data: UpdateTestStepData,
): Promise<unknown> => {
  const response = await axiosInstance.put<unknown>(
    IDEAPI.DETAIL_AND_UPDATE_MOBILE_SPEED_TEST_STEP + idx,
    data,
  );
  return response.data;
};

export const useUpdateSpeedMobileTestStep = (idx: number) => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, UpdateTestStepData>({
    mutationFn: (data) => updateSpeedMobileTestStep(idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(speedMobileTestStepKeys.all);
    },
  });
};

const updateOrderSpeedMobileTestStep = async (
  idx: string,
  mobileOs: string,
  step: number[],
  startStepIdx: number,
  endStepIdx: number,
) => {
  const response = await axiosInstance.put<unknown>(
    IDEAPI.UPDATE_ORDER_MOBILE_SPEED_TEST_STEP + idx,
    {
      mobileOs: mobileOs,
      step: step,
      startStepIdx: startStepIdx,
      endStepIdx: endStepIdx,
    },
  );
  return response.data;
};

export const useUpdateOrderSpeedMobileTestStep = ({
  mobileOs,
  step,
  startStepIdx,
  endStepIdx,
}: UpdateTestStepListSequenceMutation) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');
  const queryClient = useQueryClient();
  return useMutation<unknown, Error>({
    mutationFn: () =>
      updateOrderSpeedMobileTestStep(
        idx,
        mobileOs,
        step,
        startStepIdx,
        endStepIdx,
      ),
    onSuccess: () =>
      queryClient.invalidateQueries(speedMobileTestStepKeys.list(idx)),
  });
};

const repeatSpeedMobileTestStep = async (data: SpeedTestStepRepeat) => {
  const response = await axiosInstance.put<unknown>(
    IDEAPI.REPEAT_MOBILE_SPEED_TEST_STEP,
    data,
  );
  return response.data;
};

export const useRepeatSpeedMobileTestStep = () => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const speedCaseIdx = searchParams.get('idx');
  return useMutation<unknown, Error, Omit<SpeedTestStepRepeat, 'speedCaseIdx'>>(
    {
      mutationFn: (data) =>
        repeatSpeedMobileTestStep({ ...data, speedCaseIdx: speedCaseIdx }),
      onSuccess: () =>
        queryClient.invalidateQueries(
          speedMobileTestStepKeys.list(speedCaseIdx),
        ),
    },
  );
};

//DELETE
const deleteSpeedMobileTestStep = async (
  idx: string,
  data: { stepIdxList: number[]; mobileOs: string },
): Promise<DeleteSpeedTestStepMutation> => {
  const response = await axiosInstance.delete<DeleteSpeedTestStepMutation>(
    IDEAPI.GET_AND_CREATE_DELETE_MOBILE_SPEED_TEST_STEP + idx,
    { data: data },
  );
  return response.data;
};

export const useDeleteSpeedMobileTestStep = () => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');
  return useMutation<
    DeleteSpeedTestStepMutation,
    Error,
    DeleteSpeedTestStepMutation
  >({
    mutationFn: (data) => deleteSpeedMobileTestStep(idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(speedMobileTestStepKeys.list(idx));
    },
  });
};
