import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  UpdateTestStepData,
  UpdateTestStepDataKeys,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { preventEnterHandler } from '@utils/helper/preventEnterHandler';

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: UpdateTestStepDataKeys;
  isSavedValue?: boolean;
}

const Textarea = ({ name, isSavedValue, ...restProps }: Props) => {
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext<UpdateTestStepData>();

  const isSecret = watch('optionsDto.secret') === 'true';

  const { ref, ...rest } = register(name, {
    required: {
      value: !(isSecret && isSavedValue),
      message: 'Value값을 입력해 주세요.',
    },
  });

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [height, setHeight] = useState(0);
  useEffect(() => {
    if (textareaRef.current) {
      setHeight(textareaRef.current.scrollHeight);
    }
  }, []);

  useImperativeHandle(ref, () => textareaRef.current);

  return (
    <div className="w-full">
      <label htmlFor={name} className="sr-only">
        value
      </label>
      <textarea
        name={name}
        id={name}
        style={{ height }}
        className={clsx(
          'block w-full overflow-y-auto rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 scrollbar-thin placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
          isSecret && 'password-input',
        )}
        ref={textareaRef}
        onKeyDown={(e) => preventEnterHandler(e, isSecret)}
        {...rest}
        {...restProps}
      />
      <ErrorMessage
        name={'value'}
        errors={errors}
        render={({ message }) => (
          <p className="standard-error-message">{message}</p>
        )}
      />
    </div>
  );
};

export default Textarea;
