import {
  ErrorBoundaryWrapper,
  ErrorMessage,
  ListSkeleton,
} from '@autosquare/common';

import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { useWebTestStepListQuery } from '@lib/api/ide/web/webTestStep';
import { useUpdateWebTestCaseStepMutation } from '@lib/api/ide/web/webTestCase';

import { WebTestStepData } from '@customTypes/testStep/type';

import WebNoTestStepList from './WebTestStepList/WebNoTestStepList';
import WebTestStepListCard from './WebTestStepList/WebTestStepListCard';

const WebTestStepList = () => {
  const { data, isError, error, isLoading } = useWebTestStepListQuery();

  const [allWebTestStepLists, setAllWebTestStepLists] = useState<
    WebTestStepData[]
  >([]);
  const [indexArr, setIndexArr] = useState<number[]>(null);

  useEffect(() => {
    setAllWebTestStepLists(data);
  }, [data]);

  const handleOnDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      const items = Array.from(allWebTestStepLists);
      const [reorderedItem] = items.splice(result?.source?.index, 1);
      items?.splice(result?.destination?.index, 0, reorderedItem);
      setAllWebTestStepLists(items);

      setIndexArr(items.map((item) => item?.idx));
    },
    [allWebTestStepLists],
  );

  const updateWebTestCaseStepMutation = useUpdateWebTestCaseStepMutation();

  useEffect(() => {
    updateWebTestCaseStepMutation.mutate({ step: indexArr });
  }, [indexArr]);

  return (
    <ErrorBoundaryWrapper>
      <div className="h-full w-1/2">
        <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
          <div className="h-0 flex-1 overflow-auto scrollbar-thin scrollbar-thumb-scrollbar-bg scrollbar-track-rounded-full scrollbar-thumb-rounded-full">
            <div className="bg-congress-blue p-4 sm:px-6">
              <div className="flex items-center justify-between">
                <div className="text-base font-semibold leading-6 text-white">
                  {`Test Step List (${data?.length ?? 0})`}
                </div>
              </div>
            </div>
            {isLoading ? (
              <ListSkeleton />
            ) : isError ? (
              <ErrorMessage>{error.message}</ErrorMessage>
            ) : allWebTestStepLists?.length === 0 ? (
              <WebNoTestStepList />
            ) : (
              <div className="flex flex-1 flex-col justify-between">
                <div className="divide-y divide-gray-200 px-4 sm:px-6">
                  <div className="space-y-6 pb-5 pt-6">
                    <div className="w-full">
                      <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="webTestStepList">
                          {(provided) => (
                            <div
                              id="webTestStepList"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {allWebTestStepLists?.map(
                                (allWebTestStepList, index) => (
                                  <WebTestStepListCard
                                    key={allWebTestStepList?.idx}
                                    index={index}
                                    allWebTestStepList={allWebTestStepList}
                                  />
                                ),
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default WebTestStepList;
