import {
  BreakdownTypeEnum,
  OverviewResultRateReport,
} from '@customTypes/ide/overview/type';
import { PriorityNameEnum } from '@customTypes/testCase/type';

export const priorityColors = {
  High: 'bg-red-400',
  Medium: 'bg-orange-400',
  Low: 'bg-blue-400',
};

export const breakdownGroupData = (
  data: OverviewResultRateReport,
  isMobileFunctional: boolean,
  isWebFunctional: boolean,
) => {
  const isBothFunctional = isMobileFunctional && isWebFunctional;
  const mergedTotalBreakdown = ['Scheduler', 'Test Scenario', 'Test Case'].map(
    (type) => {
      const mobileTotalCount = data?.mobileResultReports.breakdownGroup?.find(
        (item) => item.type === type,
      )?.totalCount;
      const webTotalCount = data?.webResultReports.breakdownGroup?.find(
        (item) => item.type === type,
      )?.totalCount;

      const mobileActivate = data?.mobileResultReports.breakdownGroup?.find(
        (item) => item.type === type,
      )?.activateCount;
      const webActivate = data?.webResultReports.breakdownGroup?.find(
        (item) => item.type === type,
      )?.activateCount;
      const sortOrderLabel: PriorityNameEnum[] = [
        PriorityNameEnum.High,
        PriorityNameEnum.Medium,
        PriorityNameEnum.Low,
      ];

      const mobilePriority = data?.mobileResultReports.breakdownGroup
        ?.find((item) => item.type === type)
        ?.priorities?.sort(
          (a, b) =>
            sortOrderLabel.indexOf(a.priority) -
            sortOrderLabel.indexOf(b.priority),
        );
      const webPriority = data?.webResultReports.breakdownGroup
        ?.find((item) => item.type === type)
        ?.priorities?.sort(
          (a, b) =>
            sortOrderLabel.indexOf(a.priority) -
            sortOrderLabel.indexOf(b.priority),
        );

      const totalCount = isBothFunctional
        ? mobileTotalCount + webTotalCount
        : isMobileFunctional
          ? mobileTotalCount
          : webTotalCount;

      if (type === BreakdownTypeEnum.Scheduler)
        return {
          type,
          total: totalCount,
          count: {
            mobile: mobileTotalCount,
            web: webTotalCount,
          },
          activate: {
            mobile: mobileActivate,
            web: webActivate,
          },
          deactivate: {
            mobile: mobileTotalCount - mobileActivate,
            web: webTotalCount - webActivate,
          },
        };
      if (type === BreakdownTypeEnum.Scenario)
        return {
          type,
          total: totalCount,
          count: {
            mobile: mobileTotalCount,
            web: webTotalCount,
          },
        };
      return {
        type,
        total: totalCount,
        count: {
          mobile: mobileTotalCount,
          web: webTotalCount,
        },
        priority: {
          mobile: mobilePriority,
          web: webPriority,
        },
      };
    },
  );

  return { mergedTotalBreakdown };
};
