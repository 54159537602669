import { Checkbox, ScenarioListDetailData } from '@autosquare/common';
import { SelectedItem } from '@autosquare/common/src/hooks/checkbox/useCheckboxWithDnd';
import { CreateSchedulerData } from '@customTypes/ide/scheduler/scheduler';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  copyTestScenarios: ScenarioListDetailData[];
  testScenario: ScenarioListDetailData;
  selectArray: (
    idx: number,
    dndIndex: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  selectedArray: SelectedItem[];
}

const ListItemCard = ({
  copyTestScenarios,
  testScenario,
  selectArray,
  selectedArray,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { title, dndIndex, ...restData } = testScenario;
  const selectedDnd = selectedArray.map(({ dndIndex }) => dndIndex);
  const { setValue } = useFormContext<CreateSchedulerData>();
  useEffect(() => {
    const scenarioIdxList = copyTestScenarios?.map(
      (testScenario) => testScenario.idx,
    );
    setValue('scenarioIdxList', scenarioIdxList);
  }, [copyTestScenarios, setValue]);

  return (
    <div className="m-2 flex items-center border border-solid bg-gray-50 p-2">
      <Checkbox
        checked={selectedDnd.includes(testScenario?.dndIndex)}
        onChange={(e) => {
          selectArray(testScenario?.idx, testScenario?.dndIndex, e);
        }}
      />
      <div className="ml-7">{testScenario?.title}</div>
    </div>
  );
};

export default ListItemCard;
