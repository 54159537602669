import {
  ErrorBoundaryWrapper,
  ResultsToolbar,
  SpeedTestResultTableDataCell,
  SpeedTestResultTableHeader,
  Time,
} from '@autosquare/common';
import { WebSpeedTestResultData } from '@customTypes/ide/speedTest/Web/testResult/type';
import { TestResultDisplay, TestResultStatus } from '@customTypes/index';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

import dayjs from 'dayjs';
import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

type Props = {
  webSpeedTestResultList: WebSpeedTestResultData;
};

const WebSpeedTestResultList = ({ webSpeedTestResultList }: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const resultWithIndex = webSpeedTestResultList?.list?.map((item, index) => ({
    ...item,
    index: index + 1,
  }));
  const currentPage = searchParams.get('page');
  const { pathname, search } = useLocation();

  const entryPoint = pathname + search;

  return (
    <ErrorBoundaryWrapper>
      <ResultsToolbar totalResults={webSpeedTestResultList?.totalCount} />
      <div className="flex flex-col gap-4 pt-8">
        {resultWithIndex.map((webSpeedTestResult) => (
          <div
            key={webSpeedTestResult.index}
            className="flex w-full flex-col gap-3.5"
          >
            <div className="flex w-full items-center justify-between">
              <h2 className="text-sm font-semibold leading-tight text-black">
                {webSpeedTestResult.menu}
              </h2>
              <button
                type="button"
                className={clsx(
                  'flex items-center justify-center gap-1.5',
                  webSpeedTestResult.services[0].result_enum === 'RUNNING'
                    ? 'text-gray-400'
                    : 'text-gray-900',
                )}
                aria-label="navigate detail"
                disabled={
                  webSpeedTestResult.services[0].result_enum === 'RUNNING'
                }
                onClick={() => {
                  localStorage.setItem('entryPoint', entryPoint);
                  navigate(
                    `detail?result=${webSpeedTestResult.services[0].idx}&group=${webSpeedTestResult.group_idx}&order-by=desc&channel=${webSpeedTestResult.services[0].channel}&start=${dayjs(webSpeedTestResult.date_time).subtract(14, 'day').format('YYYY-MM-DD')}&end=${dayjs(webSpeedTestResult.date_time).format('YYYY-MM-DD')}&status=${webSpeedTestResult.services[0].result_enum}&page=${currentPage}`,
                  );
                }}
              >
                <span className="text-center text-xs font-semibold leading-tight">
                  Detail
                </span>
                <ChevronRightIcon className="size-5" />
              </button>
            </div>
            <div className="w-full overflow-x-auto">
              <table className="min-w-full table-auto">
                <thead className="text-sm font-semibold leading-tight text-gray-900">
                  <tr className="border-b border-solid border-gray-200 bg-gray-50">
                    <SpeedTestResultTableHeader width="w-40">
                      Type
                    </SpeedTestResultTableHeader>
                    <SpeedTestResultTableHeader width="w-40">
                      Environment
                    </SpeedTestResultTableHeader>
                    <SpeedTestResultTableHeader width="w-40">
                      Tester
                    </SpeedTestResultTableHeader>
                    <SpeedTestResultTableHeader width="w-40">
                      DateTime
                    </SpeedTestResultTableHeader>
                    {webSpeedTestResult.services.map((service) => (
                      <SpeedTestResultTableHeader
                        key={service.service_group_idx}
                      >
                        {service.service}
                      </SpeedTestResultTableHeader>
                    ))}
                  </tr>
                </thead>
                <tbody className="text-sm font-normal leading-tight text-gray-900">
                  <tr className="border-b border-solid border-gray-400">
                    <SpeedTestResultTableDataCell>
                      {webSpeedTestResult.services[0].channel}
                    </SpeedTestResultTableDataCell>
                    <SpeedTestResultTableDataCell>
                      {webSpeedTestResult.browser}
                    </SpeedTestResultTableDataCell>
                    <SpeedTestResultTableDataCell>
                      {webSpeedTestResult.tester}
                    </SpeedTestResultTableDataCell>
                    <SpeedTestResultTableDataCell>
                      <p>{webSpeedTestResult.date_time.split(' ')[0]}</p>
                      <div className="flex items-center gap-1 text-sm font-normal leading-tight text-gray-500">
                        <Time />
                        {webSpeedTestResult.date_time.split(' ')[1]}
                      </div>
                    </SpeedTestResultTableDataCell>
                    {webSpeedTestResult.services.map((service) => (
                      <SpeedTestResultTableDataCell
                        key={service.service_group_idx}
                        isOverThreeSeconds={
                          service.result_enum === TestResultStatus.Pass &&
                          service.load_speed_average > 3
                        }
                      >
                        {service.result_enum === TestResultStatus.Pass
                          ? service.load_speed_average
                          : TestResultDisplay[service.result_enum]}
                      </SpeedTestResultTableDataCell>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default WebSpeedTestResultList;
