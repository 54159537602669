import React from 'react';

import TestStepDetailBasicDefinition from '@components/shared/TestStepDetail/TestStepDetailBasicDefinition';

import DetailDivisionLine from '@components/shared/TestStepDetail/DetailDivisionLine';

import {
  DeviceKeyServerName,
  findListNameFromServerName,
} from '@utils/static/deviceKeyList';
import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';

import {
  MaskingOptionsType,
  MobileTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ErrorBoundaryWrapper } from '@autosquare/common';

interface Props {
  mobileTestStepData: MobileTestStepData;
}

const BasicLoop = ({ mobileTestStepData }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <DetailDivisionLine
        title={'Condition'}
        description={'지정된 조건이 만족하는 동안 반복 실행됩니다.'}
      />
      <TestStepDetailBasicDefinition
        term={'Command'}
        description={findMobileCommandNameFromServerName(
          mobileTestStepData?.optionsDto.conditionCommand,
        )}
      />
      <TestStepDetailBasicDefinition
        term={'Value'}
        description={mobileTestStepData?.optionsDto.conditionValue}
      />
      <TestStepDetailBasicDefinition
        term={'Repeat'}
        description={mobileTestStepData?.optionsDto.repeatLimit}
      />
      <DetailDivisionLine
        title={'Action'}
        description={'반복 실행 될 동작을 설정합니다.'}
      />
      <TestStepDetailBasicDefinition
        term={'Command'}
        description={findMobileCommandNameFromServerName(
          mobileTestStepData?.value as MobileCommandOptionServerName,
        )}
      />
      <TestStepDetailBasicDefinition
        term={'Value'}
        description={
          mobileTestStepData?.value === MobileCommandOptionServerName.DeviceKey
            ? findListNameFromServerName(
                mobileTestStepData?.optionsDto
                  .actionValue as DeviceKeyServerName,
              )
            : mobileTestStepData?.value ===
                MobileCommandOptionServerName.ScrollSwipe
              ? `[${mobileTestStepData?.optionsDto.actionValue}]`
              : mobileTestStepData?.optionsDto.actionValue
        }
      />
      {mobileTestStepData?.value ===
        MobileCommandOptionServerName.ScrollSwipe && (
        <TestStepDetailBasicDefinition
          term={'Repeat'}
          description={
            mobileTestStepData?.optionsDto?.actionOptions?.repeatLimit
          }
        />
      )}
      <TestStepDetailBasicDefinition
        term={'Masking Options'}
        description={
          mobileTestStepData.optionsDto.maskingOptions?.enabled === 'true'
            ? `Y / ${mobileTestStepData.optionsDto.maskingOptions?.type === MaskingOptionsType.Element ? '직접 선택' : mobileTestStepData.optionsDto.maskingOptions?.type === MaskingOptionsType.Coordinate ? '영역 선택' : '전체 화면'}`
            : 'N'
        }
      />
    </ErrorBoundaryWrapper>
  );
};

export default BasicLoop;
