export const createQueryKeys = (prefix: string) => {
  return {
    all: [prefix] as const,
    lists: () => [prefix, 'list'] as const,
    list: (idx: string) => [prefix, 'list', idx] as const,
    details: () => [prefix, 'detail'] as const,
    detail: (idx: string) => [prefix, 'detail', idx] as const,
    searches: () => [prefix, 'search'] as const,
    search: (search: string) => [prefix, 'search', search] as const,
    automation: (idx: string) => [prefix, 'automation', idx] as const,
  };
};
