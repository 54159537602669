export type WebCommandOptionType = {
  id: number;
  name: string;
  apiName: string;
  serverName: string;
};

export enum WebCommandOptionName {
  Command = 'Command',
  Click = 'Click',
  GetUrl = 'Get URL',
  FindElement = 'Find Element',
  SaveText = 'Save Text',
  ConfirmText = 'Confirm Text',
  ImportTestCase = 'Import Test Case',
  InputText = 'Input Text',
  BrowserKey = 'Browser Key',
  ScrollAndSwipe = 'Scroll/Swipe',
  KeyBoardEvent = 'Keyboard Event',
  IF = 'IF',
  ImportTestStep = 'Import Test Step',
  DataValidation = 'Data Validation',
}

export enum WebCommandOptionApiName {
  Command = 'command',
  Click = 'click',
  GetUrl = 'getUrl',
  FindElement = 'findElement',
  SaveText = 'saveText',
  ConfirmText = 'confirmText',
  ImportTestCase = 'importTestCase',
  InputText = 'inputText',
  BrowserKey = 'browserKey',
  ScrollAndSwipe = 'scrollAndSwipe',
  KeyBoardEvent = 'KeyboardEvent',
  IF = 'if',
  ImportTestStep = 'importTestStep',
  DataValidation = 'dataValidation',
}

export enum WebCommandOptionServerName {
  Command = 'command',
  Click = 'click',
  GetUrl = 'get_url',
  FindElement = 'find_element',
  SaveText = 'save_text',
  ConfirmText = 'confirm_text',
  ImportTestCase = 'import_test_case',
  InputText = 'input_text',
  BrowserKey = 'browser_key',
  ScrollAndSwipe = 'scroll_and_swipe',
  KeyBoardEvent = 'keyboard_event',
  IF = 'if',
  ImportTestStep = 'import_test_step',
  DataValidation = 'data_validation',
}

export const webCommandOptions: WebCommandOptionType[] = [
  {
    id: 0,
    name: WebCommandOptionName.Command,
    apiName: WebCommandOptionApiName.Command,
    serverName: WebCommandOptionServerName.Command,
  },
  {
    id: 1,
    name: WebCommandOptionName.Click,
    apiName: WebCommandOptionApiName.Click,
    serverName: WebCommandOptionServerName.Click,
  },
  {
    id: 2,
    name: WebCommandOptionName.GetUrl,
    apiName: WebCommandOptionApiName.GetUrl,
    serverName: WebCommandOptionServerName.GetUrl,
  },
  {
    id: 3,
    name: WebCommandOptionName.FindElement,
    apiName: WebCommandOptionApiName.FindElement,
    serverName: WebCommandOptionServerName.FindElement,
  },
  {
    id: 4,
    name: WebCommandOptionName.SaveText,
    apiName: WebCommandOptionApiName.SaveText,
    serverName: WebCommandOptionServerName.SaveText,
  },
  {
    id: 5,
    name: WebCommandOptionName.ConfirmText,
    apiName: WebCommandOptionApiName.ConfirmText,
    serverName: WebCommandOptionServerName.ConfirmText,
  },
  {
    id: 6,
    name: WebCommandOptionName.InputText,
    apiName: WebCommandOptionApiName.InputText,
    serverName: WebCommandOptionServerName.InputText,
  },
  {
    id: 7,
    name: WebCommandOptionName.ImportTestCase,
    apiName: WebCommandOptionApiName.ImportTestCase,
    serverName: WebCommandOptionServerName.ImportTestCase,
  },
  {
    id: 8,
    name: WebCommandOptionName.BrowserKey,
    apiName: WebCommandOptionApiName.BrowserKey,
    serverName: WebCommandOptionServerName.BrowserKey,
  },
  {
    id: 9,
    name: WebCommandOptionName.ScrollAndSwipe,
    apiName: WebCommandOptionApiName.ScrollAndSwipe,
    serverName: WebCommandOptionServerName.ScrollAndSwipe,
  },
  {
    id: 10,
    name: WebCommandOptionName.KeyBoardEvent,
    apiName: WebCommandOptionApiName.KeyBoardEvent,
    serverName: WebCommandOptionServerName.KeyBoardEvent,
  },
  {
    id: 11,
    name: WebCommandOptionName.IF,
    apiName: WebCommandOptionApiName.IF,
    serverName: WebCommandOptionServerName.IF,
  },
  {
    id: 12,
    name: WebCommandOptionName.ImportTestStep,
    apiName: WebCommandOptionApiName.ImportTestStep,
    serverName: WebCommandOptionServerName.ImportTestStep,
  },
  {
    id: 13,
    name: WebCommandOptionName.DataValidation,
    apiName: WebCommandOptionApiName.DataValidation,
    serverName: WebCommandOptionServerName.DataValidation,
  },
];

export const findWebCommandNameFromServerName = (serverName: string) =>
  webCommandOptions.find((webCommand) => webCommand.serverName === serverName)
    ?.name;

export const findWebCommandName = (
  optionName: 'name' | 'apiName' | 'serverName',
  webCommandOption:
    | WebCommandOptionName
    | WebCommandOptionApiName
    | WebCommandOptionServerName,
) => {
  webCommandOptions.find(
    (webCommand) => webCommand?.[optionName] === webCommandOption,
  )?.[optionName];
};
