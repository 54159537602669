import {
  Button,
  Checkbox,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
  ErrorMessage,
  TableHeader,
  TableNoList,
  TableWrapper,
  useCheckbox,
} from '@autosquare/common';
import NoListResultMessage from '@components/Search/NoListResultMessage';
import TableSkeleton from '@components/Skeleton/TableSkeleton';

import {
  useDeleteSpeedSchedulerMutation,
  useRemoteDeleteSpeedSchedulerMutation,
  useRemoteUpdateSpeedSchedulerStatusMutation,
  useSearchSpeedTestSchedulerListQuery,
  useUpdateSpeedSchedulerStatusMutation,
} from '@lib/api/ide/speedScheduler/speedScheduler';
import React, { useState } from 'react';

import SpeedTestSchedulerTableBody from './SpeedTestSchedulerTable/SpeedTestSchedulerTableBody';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';
import { getRemoteState } from '@utils/static/getConfiguration';

const SpeedTestSchedulerTable = () => {
  const [isErrorModalOpened, setIsErrorModalOpened] = useState(false);

  const searchSpeedTestSchedulerListQuery =
    useSearchSpeedTestSchedulerListQuery();
  const searchSpeedTestSchedulerList = searchSpeedTestSchedulerListQuery.data;

  const { standardConfiguration } = getRemoteState();
  const updateSpeedSchedulerStatusMutation = standardConfiguration
    ? useUpdateSpeedSchedulerStatusMutation()
    : useRemoteUpdateSpeedSchedulerStatusMutation();
  const deleteSpeedSchedulerMutation = standardConfiguration
    ? useDeleteSpeedSchedulerMutation()
    : useRemoteDeleteSpeedSchedulerMutation();

  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(searchSpeedTestSchedulerList, 'idx');

  const headerList = [
    {
      idx: 1,
      title: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={selectAllArray}
          key="selected-checkbox"
        />
      ),
    },
    {
      idx: 2,
      title: selectedArray.length > 0 ? <div className="size-5" /> : 'No',
    },
    {
      idx: 3,
      title:
        selectedArray.length > 0 ? (
          <div className="h-5 w-[4.125rem]" />
        ) : (
          'Test Type'
        ),
    },
    { idx: 4, title: 'Environment' },
    { idx: 5, title: 'Title' },
    { idx: 6, title: 'Scenario Count' },
    { idx: 7, title: 'Schedule', isSortable: true },
    { idx: 8, title: 'Status' },
    { idx: 9, title: 'Detail' },
  ];
  return (
    <ErrorBoundaryWrapper>
      <TableWrapper selectedArray={selectedArray} openDialog={openDialog}>
        <thead>
          <tr>
            {headerList?.map((header) => (
              <TableHeader key={header.idx} isSortable={header.isSortable}>
                {header.title}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white">
          {searchSpeedTestSchedulerListQuery.isLoading ? (
            <TableSkeleton dataLength={headerList.length} />
          ) : searchSpeedTestSchedulerListQuery.isError ? (
            <TableNoList colSpan={headerList.length}>
              <ErrorMessage>
                {searchSpeedTestSchedulerListQuery.error.message}
              </ErrorMessage>
            </TableNoList>
          ) : searchSpeedTestSchedulerList.length === 0 ? (
            <NoListResultMessage
              colSpan={headerList.length}
              testType="scheduler"
            />
          ) : (
            searchSpeedTestSchedulerList.map((speedScheduler, idx) => (
              <SpeedTestSchedulerTableBody
                key={speedScheduler.idx}
                idx={idx}
                speedScheduler={speedScheduler}
                selectedArray={selectedArray}
                selectArray={selectArray}
                updateSpeedSchedulerStatusMutation={
                  updateSpeedSchedulerStatusMutation
                }
                setIsErrorModalOpened={setIsErrorModalOpened}
              />
            ))
          )}
        </tbody>
      </TableWrapper>
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedCautionDialog}
        setIsOpened={setIsOpenedCautionDialog}
        type="caution"
        title={
          deleteSpeedSchedulerMutation.isError ? 'Error' : 'Delete Scheduler'
        }
        subTitle={
          deleteSpeedSchedulerMutation.isError
            ? deleteSpeedSchedulerMutation.error.message
            : '해당 스케쥴러를 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {!deleteSpeedSchedulerMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  deleteSpeedSchedulerMutation.mutate(
                    { idxList: selectedArray },
                    {
                      onSuccess: () => {
                        closeDialog();
                        resetSelectedArray();
                      },
                      onError: () => {
                        openDialog();
                      },
                    },
                  )
                }
              >
                Delete
              </Button>
            )}
            <Button type="button" variant="secondary" onClick={closeDialog}>
              Cancel
            </Button>
          </>
        }
      />
      <DoubleCheckDialogHeadlessUI
        isOpened={isErrorModalOpened}
        setIsOpened={setIsErrorModalOpened}
        type="caution"
        title={'Error'}
        subTitle={
          updateSpeedSchedulerStatusMutation.isError &&
          updateSpeedSchedulerStatusMutation.error.message
        }
        buttonChildren={
          <Button
            type="button"
            variant="secondary"
            onClick={() => setIsErrorModalOpened(false)}
          >
            Cancel
          </Button>
        }
      />
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default SpeedTestSchedulerTable;
