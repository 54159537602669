import React from 'react';

const OverviewNoData = () => {
  return (
    <div className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-900">
      현재 조회되는 데이터가 없습니다.
    </div>
  );
};

export default OverviewNoData;
