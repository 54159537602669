import {
  ArrowSpinner,
  Breadcrumbs,
  Button,
  Checkbox,
  ErrorBoundaryWrapper,
  PagingHeadingsNavigate,
} from '@autosquare/common';
import {
  TestResultCaseResultExport,
  TestResultDetails,
  TestResultExportCaseResultList,
} from '@customTypes/index';
import { Dialog, DialogPanel } from '@headlessui/react';
import { ErrorMessage } from '@hookform/error-message';
import { UseMutationResult } from '@tanstack/react-query';
import React, { ReactNode } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';

type Props = {
  isOpen: boolean;
  closeDialog: () => void;
  newBreadcrumbs: Breadcrumbs[];
  resultDetails: TestResultDetails[];
  methods: UseFormReturn<TestResultExportCaseResultList>;
  onSubmit: SubmitHandler<TestResultExportCaseResultList>;
  inputRef: React.MutableRefObject<HTMLInputElement>;
  caseResultList: TestResultCaseResultExport[];
  handleAllChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  testResultExportMutation: UseMutationResult<
    { blob: Blob; filename: string },
    Error,
    TestResultExportCaseResultList
  >;
  children: ReactNode;
};

const ExportOptionsDialog = ({
  isOpen,
  closeDialog,
  newBreadcrumbs,
  methods,
  onSubmit,
  inputRef,
  caseResultList,
  handleAllChange,
  resultDetails,
  testResultExportMutation,
  children,
}: Props) => {
  const exportDataSelectOptionList = [
    {
      title: 'Summary',
      checked: true,
      disabled: true,
    },
    {
      title: 'Information',
      checked: true,
      disabled: true,
    },
    {
      title: 'Screenshot',
      // imagePath가 하나도 없으면 exportScreenshot 비활성화
      disabled:
        resultDetails.length > 0 &&
        resultDetails[0].details.length > 0 &&
        !resultDetails[0].details.every((detail) => !!detail.imagePath),
      register: methods.register('exportScreenshot'),
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <Dialog open={isOpen} onClose={closeDialog} className="relative z-50">
        <div className="fixed inset-0 flex w-screen items-center justify-center bg-gray-300/50 p-4">
          <DialogPanel className="gray-scrollbar max-h-[90%] w-4/5 border-l border-solid border-gray-200 bg-white px-6 py-8">
            <PagingHeadingsNavigate
              breadcrumbs={newBreadcrumbs}
              pageTitle={'Export Options'}
            />
            <FormProvider {...methods}>
              <h3 className="pt-10 text-base font-semibold leading-7 text-gray-900">
                Export Data Select
              </h3>
              <p className="mt-1 text-sm font-normal leading-normal text-gray-600">
                내보낼 파일에 포함할 정보를 선택할 수 있습니다.
              </p>
              <div className="grid grid-cols-3 border-b border-solid border-gray-200 pt-5 text-sm font-semibold leading-normal text-gray-900">
                {exportDataSelectOptionList.map((option) => (
                  <div
                    key={option.title}
                    className="flex items-center gap-3 py-3"
                  >
                    <input
                      type="checkbox"
                      className="input-checkbox-congress-blue disabled:bg-gray-100 hover:disabled:bg-gray-100"
                      checked={option.checked}
                      disabled={option.disabled}
                      id={option.title}
                      {...option.register}
                    />
                    <label htmlFor={option.title}>{option.title}</label>
                  </div>
                ))}
              </div>
              <form
                className="pt-6 text-sm font-semibold leading-normal text-gray-900"
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <div className="flex items-center gap-3 border-b border-solid border-gray-200 py-3">
                  <Checkbox
                    checked={caseResultList?.length > 0}
                    onChange={handleAllChange}
                    ref={inputRef}
                  />
                  <p>ALL ({resultDetails.length})</p>
                </div>
                <div className="border-b border-solid border-gray-200">
                  {children}
                </div>
                <div className="flex items-center justify-between gap-4 pt-5">
                  <div className="flex grow justify-center">
                    <ErrorMessage
                      errors={methods.formState.errors}
                      name={'caseResultList'}
                      render={({ message }) => (
                        <div className="error-message">{message}</div>
                      )}
                    />
                  </div>
                  <div className="flex items-center gap-4">
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={closeDialog}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={testResultExportMutation.isLoading}
                    >
                      Export to Xlsx
                    </Button>
                  </div>
                </div>
              </form>
            </FormProvider>
          </DialogPanel>
        </div>
      </Dialog>
      {testResultExportMutation.isLoading && <ArrowSpinner />}
    </ErrorBoundaryWrapper>
  );
};

export default ExportOptionsDialog;
