import { Button, CardListSkeleton } from '@autosquare/common';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import React from 'react';

const ScenarioCardListSkeleton = () => {
  return (
    <div className="flex size-full items-center justify-between gap-x-4">
      <CardListSkeleton />
      <Button disabled>
        <ChevronRightIcon className="size-5" strokeWidth="3" />
      </Button>
      <CardListSkeleton />
    </div>
  );
};

export default ScenarioCardListSkeleton;
