import React from 'react';
import {
  BreakdownPriority,
  BreakdownTypeEnum,
} from '@customTypes/ide/overview/type';
import SchedulerItem from './BreakdownItem/SchedulerItem';
import ScenarioItem from './BreakdownItem/ScenarioItem';
import CaseItem from './BreakdownItem/CaseItem';

type Props = {
  icon: React.ReactNode;
  type: string;
  count: number;
  deactivate: number;
  activate: number;
  priority: BreakdownPriority[];
  total: number;
  isMobileTestEnabled?: boolean;
  isWebTestEnabled?: boolean;
};

const BreakdownItem = ({
  icon,
  type,
  count,
  activate,
  deactivate,
  priority,
  total,
  isMobileTestEnabled,
  isWebTestEnabled,
}: Props) => {
  return (
    <div className="flex items-center justify-start gap-x-3">
      {icon}
      {type === BreakdownTypeEnum.Scheduler ? (
        <SchedulerItem
          deactivate={deactivate}
          activate={activate}
          count={count}
          total={total}
        />
      ) : type === BreakdownTypeEnum.Scenario ? (
        <ScenarioItem
          count={count}
          total={total}
          isMobileTestEnabled={isMobileTestEnabled}
          isWebTestEnabled={isWebTestEnabled}
        />
      ) : (
        <CaseItem count={count} total={total} priority={priority} />
      )}
    </div>
  );
};

export default BreakdownItem;
