import { ArrowSpinner, ErrorBoundaryWrapper } from '@autosquare/common';
import { DeviceInfo } from '@customTypes/ide/device/device';
import { useMobileSpeedTestRepeatStepExecutionMutation } from '@lib/api/ide/speedMobile/speedMobileExecution';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CommonDialog, DialogInput, DialogPanels } from '@components/Dialog';
import { DialogButtonPanels } from '@components/Dialog';
import { DialogButton } from '@components/Dialog';
import { RESET_DELAY_MS } from '@constants/timeout';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';

type Props = {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  deviceInfo: DeviceInfo;
  startRepeatIdx: number;
  endRepeatIdx: number;
  setIsExecutionStarted: React.Dispatch<React.SetStateAction<boolean>>;
};

const RepeatStepExecutionModal = ({
  openModal,
  setOpenModal,
  deviceInfo,
  startRepeatIdx,
  endRepeatIdx,
  setIsExecutionStarted,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [inputRepeat, setInputRepeat] = useState<number>(3);
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );
  const executeMobileTestStepMutation =
    useMobileSpeedTestRepeatStepExecutionMutation(cookie);

  const closeModal = () => {
    setOpenModal(false);
    setTimeout(() => {
      setErrorMessage('');
      setInputRepeat(3);
    }, RESET_DELAY_MS);
  };

  const executeMobileTestStep = () => {
    if (inputRepeat >= 3 && inputRepeat <= 12) {
      setErrorMessage('');
      executeMobileTestStepMutation.mutate(
        {
          infos: [deviceInfo],
          caseIdx: Number(idx),
          startStepIdx: startRepeatIdx,
          endStepIdx: endRepeatIdx,
          repeatCount: inputRepeat,
        },
        { onSuccess: () => setIsExecutionStarted(true) },
      );
      closeModal();
    }
    if (inputRepeat < 3 || inputRepeat > 12) {
      setErrorMessage(
        '테스트 횟수를 최소 3회에서 최대 12회까지 입력해 주세요.',
      );
    }
    if (!inputRepeat) {
      setErrorMessage('테스트 반복 횟수를 입력해 주세요.');
    }
  };

  const inputValueChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    const { valueAsNumber } = e.target;
    setInputRepeat(valueAsNumber);
  };

  return (
    <ErrorBoundaryWrapper>
      <CommonDialog
        isOpen={openModal}
        setIsOpen={setOpenModal}
        iconType={'caution'}
        title={'Execute Speed Test'}
        subTitle={
          '설정한 구간에 대한 속도 테스트를 진행하시겠습니까?\n(테스트 결과는 저장되지 않으며, \n테스트 실행 완료 후 현재 스크린샷과 객체 정보가 자동 갱신됩니다.)'
        }
        onClose={closeModal}
      >
        <DialogPanels>
          <div className="flex-center w-full">
            <label className="w-[86px] text-sm" htmlFor="repeat-input">
              Repeat
            </label>
            <DialogInput
              type="number"
              value={inputRepeat}
              onChange={inputValueChangeHandler}
            />
          </div>
          {errorMessage && <p className="error-message pt-2">{errorMessage}</p>}
        </DialogPanels>
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant="primary"
            onClick={executeMobileTestStep}
          >
            Execute
          </DialogButton>
          <DialogButton type="button" variant="secondary" onClick={closeModal}>
            Cancel
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
      {executeMobileTestStepMutation.isLoading && <ArrowSpinner />}
    </ErrorBoundaryWrapper>
  );
};

export default RepeatStepExecutionModal;
