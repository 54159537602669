import {
  ErrorBoundaryWrapper,
  ErrorMessage,
  TableNoList,
  TableSkeleton,
  TableWrapper,
  useCheckbox,
} from '@autosquare/common';
import {
  useDeviceAuthorizationSettingQuery,
  useDeviceListInProjectQuery,
} from '@lib/api/dashboard/queryHooks';
import React, { useState } from 'react';
import ProjectDeviceTableBody from './ProjectDevicesListSection/ProjectDeviceTableBody';
import { DeviceDeleteRequestDialog } from '@components/Dashboard/index';
import ProjectDeviceTableHeader from './ProjectDevicesListSection/ProjectDeviceTableHeader';

const ProjectDevicesListSection = () => {
  const deviceListQuery = useDeviceListInProjectQuery();
  const deviceList = deviceListQuery.data;

  const [tableHeaderLength, setTableHeaderLength] = useState(0);

  const { data: deviceAuthorizationSetting } =
    useDeviceAuthorizationSettingQuery();

  const isActivate = deviceAuthorizationSetting?.device === 'true';

  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(deviceList, 'idx');

  return (
    <ErrorBoundaryWrapper>
      <TableWrapper
        openDialog={openDialog}
        selectedArray={selectedArray}
        isMarginTop={false}
      >
        <ProjectDeviceTableHeader
          deviceListQuery={deviceListQuery}
          selectedArray={selectedArray}
          selectAllArray={selectAllArray}
          setTableHeaderLength={setTableHeaderLength}
          isActivate={isActivate}
        />
        <tbody className="divide-y divide-gray-200">
          {deviceListQuery.isLoading ? (
            <TableNoList colSpan={tableHeaderLength}>
              <TableSkeleton />
            </TableNoList>
          ) : deviceListQuery.isError ? (
            <TableNoList colSpan={tableHeaderLength}>
              <ErrorMessage margin="my-0">
                {deviceListQuery.error.message}
              </ErrorMessage>
            </TableNoList>
          ) : deviceList.length === 0 ? (
            <TableNoList colSpan={tableHeaderLength}>
              디바이스가 존재하지 않습니다.
            </TableNoList>
          ) : (
            deviceList
              .sort((a, b) => b.idx - a.idx)
              .map((device, idx) => (
                <ProjectDeviceTableBody
                  key={device.idx}
                  device={device}
                  selectedArray={selectedArray}
                  selectArray={selectArray}
                  idx={idx}
                  isActivate={isActivate}
                />
              ))
          )}
        </tbody>
      </TableWrapper>
      <DeviceDeleteRequestDialog
        isOpen={isOpenedCautionDialog}
        setIsOpen={setIsOpenedCautionDialog}
        selectedArray={selectedArray}
        closeDialog={closeDialog}
        resetSelectedArray={resetSelectedArray}
      />
    </ErrorBoundaryWrapper>
  );
};

export default ProjectDevicesListSection;
