import { ErrorBoundaryWrapper } from '@autosquare/common';
import { UpdateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

type Props = {
  control: Control<UpdateTestStepData>;
  name?: 'optionsDto.textEncrypt' | 'optionsDto.secret';
};
const SecretEditBox = ({ control, name = 'optionsDto.secret' }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div className="flex justify-start">
        <label
          htmlFor="secret"
          className="flex items-center justify-between gap-2 text-sm font-medium leading-6 text-gray-900"
        >
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <input
                id="secret"
                name="secret"
                type="checkbox"
                className="input-checkbox-blue"
                {...field}
                checked={field.value === 'true'}
                onChange={(e) =>
                  field.onChange(e.target.checked ? 'true' : 'false')
                }
              />
            )}
          />
        </label>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default SecretEditBox;
