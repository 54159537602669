import { ErrorBoundaryWrapper } from '@autosquare/common';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import React from 'react';

const AddButton = () => {
  return (
    <ErrorBoundaryWrapper>
      <div>
        <div className="flex items-center justify-between">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Add Test Step
          </h3>
          <div className="mt-3 sm:ml-4 sm:mt-0">
            <button
              type="submit"
              className="hover:text-congress-blue flex items-center gap-2 px-3 py-2 text-sm font-semibold text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <PlusCircleIcon className="size-5" />
              <p className="font-semibold">Add</p>
            </button>
          </div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default AddButton;
