import React from 'react';
import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';
import { CreateWebTestStepData } from '@customTypes/testStep/type';
import { ErrorMessage } from '@hookform/error-message';
import { useWebTestStepByCommandQuery } from '@lib/api/ide/web/webTestStep';
import { Controller, useFormContext } from 'react-hook-form';

const SaveTestStep = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CreateWebTestStepData>();

  const webTestStepByCommandQuery = useWebTestStepByCommandQuery();
  const saveTextLists = webTestStepByCommandQuery.data;

  const findDescriptionFromIdx = (idx: number) =>
    saveTextLists?.find((saveText) => saveText.idx === idx)?.description;
  return (
    <ErrorBoundaryWrapper>
      <Controller
        control={control}
        name="optionsDto.compareIdx"
        rules={{ required: 'Test Step을 선택해주세요.' }}
        render={({ field }) => (
          <ListBoxHeadlessInController
            value={field.value}
            buttonValue={
              findDescriptionFromIdx(Number(field.value)) || 'Select Test Step'
            }
            onChange={field.onChange}
            lists={saveTextLists}
            valueToSave={'idx'}
            valueToShow={'description'}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name="optionsDto.compareIdx"
        render={({ message }) => (
          <p className="error-message !mt-2">{message}</p>
        )}
      />
    </ErrorBoundaryWrapper>
  );
};

export default SaveTestStep;
