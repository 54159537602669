import React, { useEffect, useState } from 'react';
import {
  Button,
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
  regexLibrary,
} from '@autosquare/common';
import DefinitionList from '@components/shared/DefinitionList';
import {
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { findOperatorLabel, operatorList } from '@utils/index';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import EditTypeAndReferenceValue from './EditDataValidation/EditTypeAndReferenceValue';

type Props = {
  mobileTestStepData: MobileTestStepData;
};

const EditDataValidation = ({ mobileTestStepData }: Props) => {
  const [isAppendable, setIsAppendable] = useState(true);

  const {
    control,
    register,
    watch,
    formState: { errors, isSubmitted },
  } = useFormContext<UpdateTestStepData>();

  const { fields, append, remove, replace } = useFieldArray<UpdateTestStepData>(
    {
      control,
      name: 'optionsDto.operatorList',
    },
  );

  const optionsDtoOperatorList = watch('optionsDto.operatorList');

  const isOperatorTruthy = optionsDtoOperatorList?.every(
    (operator) => !!operator.operator,
  );
  const isInputValueTruthy = optionsDtoOperatorList?.every(
    (operator) =>
      !!operator.inputValue &&
      regexLibrary.numericWithOptionalDecimals12Digits.test(
        operator.inputValue,
      ),
  );

  const plusButtonOnClick = () => {
    if (isOperatorTruthy && isInputValueTruthy) {
      append({ operator: undefined, inputValue: undefined });
    } else {
      setIsAppendable(false);
    }
  };

  useEffect(() => {
    if (isOperatorTruthy && isInputValueTruthy) {
      setIsAppendable(true);
    }
  }, [isInputValueTruthy, isOperatorTruthy]);

  const isOperatorError =
    errors &&
    errors?.optionsDto?.operatorList?.some((operator) => operator?.operator);

  const isInputValueError =
    errors &&
    errors?.optionsDto?.operatorList?.some((operator) => operator?.inputValue);

  return (
    <ErrorBoundaryWrapper>
      <EditTypeAndReferenceValue
        replace={replace}
        mobileTestStepData={mobileTestStepData}
      />
      <DefinitionList
        term={'Input Data*'}
        description={
          <>
            <div className="mb-4 flex flex-col gap-y-1">
              <div className="grid grid-cols-7">
                <div className="col-span-3">
                  <div className="text-sm font-normal">Operators</div>
                </div>
                <div className="col-span-4">
                  <div className="text-sm font-normal">Input Value</div>
                </div>
              </div>
              <div className="grid grid-cols-7 gap-4">
                {fields.map((field, index) => (
                  <React.Fragment key={field.id}>
                    <div className="col-span-3">
                      <Controller
                        control={control}
                        name={`optionsDto.operatorList.${index}.operator`}
                        rules={{ required: '연산 기호를 선택해 주세요.' }}
                        render={({ field }) => (
                          <ListBoxHeadlessInController
                            value={field.value}
                            onChange={field.onChange}
                            lists={operatorList}
                            valueToSave="serverName"
                            valueToShow="label"
                            buttonValue={findOperatorLabel(field.value)}
                            placeholder="Select"
                          />
                        )}
                      />
                    </div>
                    <div className="col-span-4 flex gap-4">
                      <input
                        type="number"
                        step={'any'}
                        className="input-base hide-input-number-arrow"
                        {...register(
                          `optionsDto.operatorList.${index}.inputValue`,
                          {
                            required:
                              '최대 12자리의 숫자와 소수점 이하 12자리까지 입력 가능합니다.\n숫자(0-9)와 온점(.)만 허용됩니다.',
                            pattern: {
                              value:
                                regexLibrary.numericWithOptionalDecimals12Digits,
                              message:
                                '최대 12자리의 숫자와 소수점 이하 12자리까지 입력 가능합니다.\n숫자(0-9)와 온점(.)만 허용됩니다.',
                            },
                          },
                        )}
                        placeholder="변경 값을 입력하세요."
                      />
                      <Button
                        type="button"
                        variant="secondary"
                        isSquare
                        onClick={() => remove(index)}
                        disabled={fields.length === 1}
                      >
                        <MinusIcon className="size-6" />
                      </Button>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
            <button
              type="button"
              className="flex-center h-9 w-full rounded border border-dashed border-gray-300 px-2 py-1"
              onClick={plusButtonOnClick}
            >
              <PlusIcon className="size-6 text-gray-900" />
            </button>
            {(!isAppendable || isSubmitted) &&
            (!isOperatorTruthy || isOperatorError) ? (
              <p className="error-message pt-2">연산 기호를 선택해 주세요.</p>
            ) : (
              (!isAppendable || isSubmitted) &&
              (!isInputValueTruthy || isInputValueError) && (
                <p className="error-message py-2">
                  최대 12자리의 숫자와 소수점 이하 12자리까지 입력 가능합니다.
                  <br />
                  숫자(0-9)와 온점(.)만 허용됩니다.
                </p>
              )
            )}
          </>
        }
      />
    </ErrorBoundaryWrapper>
  );
};

export default EditDataValidation;
