import { ErrorBoundaryWrapper } from '@autosquare/common';
import React from 'react';

import WebSpeedTestCaseDetailInfoBottom from './WebSpeedTestCaseInfoEdit/WebSpeedTestCaseDetailInfoBottom';
import WebSpeedTestCaseInfoDetailTop from './WebSpeedTestCaseInfoEdit/WebSpeedTestCaseInfoDetailTop';

const WebSpeedTestCaseInfoEdit = () => {
  return (
    <ErrorBoundaryWrapper>
      <div className="mt-1">
        <WebSpeedTestCaseInfoDetailTop />
        <WebSpeedTestCaseDetailInfoBottom />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default WebSpeedTestCaseInfoEdit;
