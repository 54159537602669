import React from 'react';

interface TooltipProps {
  children: React.ReactNode;
  positionClass: string;
  customStyle?: React.CSSProperties;
}

const Tooltip = ({ children, positionClass, customStyle }: TooltipProps) => {
  return (
    <div className={`tooltip-top ${positionClass}`} style={customStyle}>
      {children}
    </div>
  );
};

export default Tooltip;
