import React from 'react';
import { MobileSpeedTestResultTableDetailData } from '@customTypes/ide/speedTest/Mobile/testResult/type';
import SpeedRepeatResultTable from './SpeedRepeatResult/SpeedRepeatResultTable';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Props = {
  speedMobileTestResultTableData: MobileSpeedTestResultTableDetailData;
  platformTypeName: string;
  chartIndex: number;
};

const SpeedRepeatResult = ({
  speedMobileTestResultTableData,
  platformTypeName,
  chartIndex,
}: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div className="mt-3">
        <p className="border-b border-gray-200 py-3 text-sm font-semibold text-gray-900">
          Repeat Result &#40;
          {speedMobileTestResultTableData?.repeatResults.length}&#41;
        </p>
        {speedMobileTestResultTableData?.repeatResults.length === 0 ? (
          <div className="flex items-center justify-center px-6 py-5">
            <p className="text-sm font-normal text-gray-900">
              진행된 테스트 결과가 없습니다.
            </p>
          </div>
        ) : (
          <SpeedRepeatResultTable
            platformTypeName={platformTypeName}
            repeatResults={speedMobileTestResultTableData?.repeatResults}
            logPath={speedMobileTestResultTableData?.logPath}
            chartIndex={chartIndex}
          />
        )}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default SpeedRepeatResult;
