import { TableNoList } from '@autosquare/common';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

type TestType = 'case' | 'scenario' | 'scheduler' | 'result';

type Props = {
  colSpan: number;
  testType: TestType;
};

const NoListResultMessage = ({ colSpan, testType }: Props) => {
  const [searchParams] = useSearchParams();

  const requiredParams = ['page', 'size', 'projectIdx', 'order'];

  const hasExtraParams = Array.from(searchParams.keys()).some(
    (key) => !requiredParams.includes(key),
  );

  const noResultMessage: Record<TestType, string> = {
    case: '해당 프로젝트에 생성된 테스트 케이스가 존재하지 않습니다.',
    scenario: '해당 프로젝트에 생성된 테스트 시나리오가 존재하지 않습니다.',
    scheduler: '추가된 스케줄러가 없습니다.',
    result: '진행된 테스트 결과가 없습니다.',
  };

  return (
    <TableNoList colSpan={colSpan}>
      {hasExtraParams ? '검색된 결과가 없습니다.' : noResultMessage[testType]}
    </TableNoList>
  );
};

export default NoListResultMessage;
