import { SupportedBrowserList } from '@customTypes/ide/browser/browser';
import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { PriorityEnum } from '@customTypes/testCase/type';
import { TestResultStatus, TestResultType } from '@customTypes/testResult/type';
import { PlatFormTypeServerName } from '@utils/static/platformTypeList';
import { z } from 'zod';

export enum StatusEnum {
  On = 'on',
  Off = 'off',
}

export enum TestTypeEnum {
  Mobile = 'mobile',
  Web = 'web',
}

export enum SortOrder {
  Desc = 'desc',
  Asc = 'asc',
}

export enum SpeedTestResultType {
  Monitoring = 'Monitoring',
  Scenario = 'Scenario',
  Case = 'Case',
}

export const SearchbarParamsSchema = z.object({
  projectIdx: z.string(),
  value: z.string().max(50, { message: '50자 이하 입력해 주세요.' }).optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  page: z.number().optional(),
  size: z.string().optional(),
  order: z.nativeEnum(SortOrder).optional(),
  priority: z.union([
    z.string().optional(),
    z.array(z.nativeEnum(PriorityEnum)).optional(),
  ]),
  platform: z.union([
    z.string().optional(),
    z.array(z.nativeEnum(PlatFormTypeServerName)).optional(),
  ]),
  status: z.union([
    z.string().optional(),
    z.array(z.nativeEnum(StatusEnum)).optional(),
  ]),
  testType: z.union([
    z.string().optional(),
    z.array(z.nativeEnum(TestTypeEnum)).optional(),
  ]),
  type: z.union([
    z.string().optional(),
    z.array(z.nativeEnum(TestResultType)).optional(),
    z.array(z.nativeEnum(SpeedTestResultType)).optional(),
  ]),
  browser: z.union([
    z.string().optional(),
    z.array(z.nativeEnum(SupportedBrowserList).optional()),
  ]),
  result: z.union([
    z.string().optional(),
    z.array(z.nativeEnum(TestResultStatus).optional()),
  ]),
  os: z.union([
    z.string().optional(),
    z.array(z.nativeEnum(DeviceInfoOs).optional()),
  ]),
});

export type SearchbarParams = z.infer<typeof SearchbarParamsSchema>;

export type SearchbarFieldNames = keyof SearchbarParams;
