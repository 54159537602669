import { regexLibrary } from '@autosquare/common';
import {
  AddServerName,
  FromServerName,
  SeparatorServerName,
} from '@customTypes/ide/mobileTestStep/textReplace/textReplace';
import { z } from 'zod';

export const WebSpeedTestStepDataOptionsDtoSchema = z.object({
  keyName: z
    .string()
    .trim()
    .min(1, { message: 'Key Name을 입력해 주세요.' })
    .max(50, { message: '50자 이하 입력해 주세요.' })
    .regex(/^[a-zA-Z0-9-_]+$/, {
      message: 'dash(-), underbar(_), 영 대소문자, 숫자만 입력해 주세요.',
    }),
  from: z.nativeEnum(FromServerName),
  addKey: z.nativeEnum(AddServerName),
  addValue: z
    .number({
      required_error: 'Value를 입력해 주세요.',
      invalid_type_error: 'Value를 입력해 주세요.',
    })
    .nonnegative({ message: 'Value 값을 최소 0부터 입력해 주세요.' })
    .lte(50, { message: 'Value 값은 최대 50까지 입력가능합니다.' }),
  dateFormat: z
    .string()
    .min(1, {
      message: 'Date Format의 년, 월, 일 중 1가지 이상 선택해 주세요.',
    })
    .regex(regexLibrary.dateFormatRegex, {
      message: '올바르지 않은 날짜 형식입니다.',
    }),
  separator: z.nativeEnum(SeparatorServerName),
  idx: z.number(),
  uuid: z.string(),
  start: z.number(),
  end: z.number(),
});

export const BaseWebSpeedTestStepDataSchema = z.object({
  url: z.string().trim().min(1, { message: 'URL을 입력해 주세요.' }),
  description: z
    .string()
    .min(1, { message: 'Description은 1자에서 200자 이내로 입력해 주세요.' })
    .max(200, { message: 'Description은 1자에서 200자 이내로 입력해 주세요.' }),
  optionsDto: z.array(WebSpeedTestStepDataOptionsDtoSchema),
});

export const WebSpeedTestStepDataSchema = BaseWebSpeedTestStepDataSchema.extend(
  { idx: z.number().int() },
);

export const CreateWebSpeedTestStepDataSchema =
  BaseWebSpeedTestStepDataSchema.extend({ speedTestCaseIdx: z.number().int() });

export const UpdateWebSpeedTestStepDataSchema = BaseWebSpeedTestStepDataSchema;

export type WebSpeedTestStepDataOptionsDto = z.infer<
  typeof WebSpeedTestStepDataOptionsDtoSchema
>;

export type WebSpeedTestStepData = z.infer<typeof WebSpeedTestStepDataSchema>;

export type CreateWebSpeedTestStepData = z.infer<
  typeof CreateWebSpeedTestStepDataSchema
>;

export type UpdateWebSpeedTestStepData = z.infer<
  typeof UpdateWebSpeedTestStepDataSchema
>;
