import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const MaskingOptionsFullScreen = () => {
  const { setValue } = useFormContext<CreateTestStepData>();

  useEffect(() => {
    setValue('optionsDto.maskingOptions.valueList', undefined);
  }, []);

  return (
    <div className="inline-flex h-9 items-center justify-start rounded-md py-1.5 pl-3 pr-2">
      <div className="text-sm font-normal leading-normal text-gray-900">
        스크린샷에 보이는 화면 전체의 텍스트 값이 암호화 처리 됩니다.
      </div>
    </div>
  );
};

export default MaskingOptionsFullScreen;
