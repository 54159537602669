import React from 'react';

import TestStepConfirmOrInputText from './TestStepConfirmOrInputText/TestStepConfirmOrInputText';
import TestStepScrollAndSwipe from './TestStepScrollAndSwipe/TestStepScrollAndSwipe';
import TestStepSelectBox from './TestStepSelectBox/TestStepSelectBox';

import { WebCommandOptionServerName } from '@utils/static/webCommandOptions';

import { WebTestStepData } from '@customTypes/testStep/type';
import WebTestStepTextareaByReactHookForm from '@components/IDE/WebTestStepDetail/WebTestStepTextareaByReactHookForm';
import { ErrorBoundaryWrapper } from '@autosquare/common';

interface Props {
  webTestStep: WebTestStepData;
}

const TestStepValue = ({ webTestStep }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      {webTestStep.command === WebCommandOptionServerName.ImportTestCase ||
      webTestStep.command === WebCommandOptionServerName.BrowserKey ||
      webTestStep.command === WebCommandOptionServerName.KeyBoardEvent ||
      webTestStep.command === WebCommandOptionServerName.ImportTestStep ? (
        <TestStepSelectBox command={webTestStep.command} />
      ) : webTestStep.command === WebCommandOptionServerName.ScrollAndSwipe ? (
        <TestStepScrollAndSwipe webTestStep={webTestStep} />
      ) : webTestStep.command === WebCommandOptionServerName.ConfirmText ||
        webTestStep.command === WebCommandOptionServerName.InputText ? (
        <TestStepConfirmOrInputText webTestStep={webTestStep} />
      ) : (
        <WebTestStepTextareaByReactHookForm
          term={'Value*'}
          name={'value'}
          isSavedSecretValue={webTestStep.optionsDto.secret === 'true'}
          secretOption={
            webTestStep.command === WebCommandOptionServerName.SaveText ||
            webTestStep.command === WebCommandOptionServerName.Click
          }
        />
      )}
    </ErrorBoundaryWrapper>
  );
};

export default TestStepValue;
