import React, { useEffect } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  AutoRegisterInfoData,
  CreateSchedulerData,
  UpdateSchedulerData,
} from '@customTypes/ide/scheduler/scheduler';
import { ConfluenceTestResultList } from '@utils/static/autoRegister/autoRegisterTypeList';
import useTextareaResize from '@hooks/autoRegister/useTextareaResize';

type Props = {
  autoIndex: number;
  autoRegisters: AutoRegisterInfoData;
};

const IdeConfluenceInputContents = ({ autoIndex, autoRegisters }: Props) => {
  const {
    register,
    watch,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateSchedulerData | UpdateSchedulerData>();

  const { handleInput, userHeight } = useTextareaResize();

  const handleCheckboxChange = (isChecked: boolean, value: string) => {
    const currentTestResults =
      watch(`autoRegisters.${autoIndex}.confluence.testResults`) || [];

    if (isChecked) {
      setValue(`autoRegisters.${autoIndex}.confluence.testResults`, [
        ...currentTestResults,
        value,
      ]);
    } else {
      setValue(
        `autoRegisters.${autoIndex}.confluence.testResults`,
        currentTestResults.filter((item: string) => item !== value),
      );
    }
  };

  const isChecked = (label: string) =>
    watch(`autoRegisters.${autoIndex}.confluence.testResults`)?.includes(label);

  const requestUrl = useWatch({
    control,
    name: `autoRegisters.${autoIndex}.confluence.requestUrl`,
  });
  const testResult = useWatch({
    control,
    name: `autoRegisters.${autoIndex}.confluence.testResults`,
  });
  useEffect(() => {
    setValue(
      `autoRegisters.${autoIndex}.confluence.testResults`,
      ConfluenceTestResultList.map((item) => item.label),
    );
  }, [setValue, autoIndex]);

  useEffect(() => {
    if (requestUrl) {
      clearErrors(`autoRegisters.${autoIndex}.confluence.requestUrl`);
    }
    if (testResult?.length > 0) {
      clearErrors(`autoRegisters.${autoIndex}.confluence.testResults`);
    }
  }, [autoRegisters]);

  return (
    <>
      <textarea
        name={`autoRegisters.${autoIndex}.confluence.requestUrl`}
        id={`autoRegisters.${autoIndex}.confluence.requestUrl`}
        className="input-base gray-scrollbar max-h-[8.25rem]"
        rows={1}
        placeholder="결과를 자동 등록할 위치의 URL을 입력해 주세요."
        onInput={handleInput}
        style={{ height: userHeight || 'auto' }}
        {...register(`autoRegisters.${autoIndex}.confluence.requestUrl`)}
      />
      <ErrorMessage
        name={`autoRegisters.${autoIndex}.confluence.requestUrl`}
        errors={errors}
        render={({ message }) => <p className="error-message">{message}</p>}
      />
      <div className="flex gap-x-8 text-sm">
        <div className="font-normal text-gray-900">Test Result</div>
        <div className="flex items-center justify-between gap-x-16 font-medium text-[#0e1827]">
          {ConfluenceTestResultList.map((item, index) => (
            <label key={item.id} className="flex items-center gap-2">
              <input
                type="checkbox"
                id={item.id + index}
                className="input-checkbox-congress-blue"
                checked={isChecked(item.label)}
                onChange={(e) =>
                  handleCheckboxChange(e.target.checked, item.label)
                }
              />
              {item.label}
            </label>
          ))}
        </div>
      </div>
      <ErrorMessage
        name={`autoRegisters.${autoIndex}.confluence.testResults`}
        errors={errors}
        render={({ message }) => <p className="error-message">{message}</p>}
      />
    </>
  );
};

export default IdeConfluenceInputContents;
