export const projectKeys = {
  all: ['project'] as const,
  lists: () => [...projectKeys.all, 'list'] as const,
  list: (projectIdx: string) => [...projectKeys.lists(), projectIdx] as const,
  details: () => [...projectKeys.all, 'detail'] as const,
  detail: (idx: string) => [...projectKeys.details(), idx] as const,
  settingDetails: () => [...projectKeys.all, 'settingDetail'] as const,
  settingDetail: (idx: string) =>
    [...projectKeys.settingDetails(), idx] as const,
  projectMemberLists: () => [...projectKeys.all, 'projectMemberList'] as const,
  projectMemberList: (projectIdx: string) =>
    [...projectKeys.projectMemberLists(), projectIdx] as const,
  projectMemberDetails: () =>
    [...projectKeys.all, 'projectMemberDetail'] as const,
  projectMemberDetail: (projectIdx: string, idx: string) => [
    ...projectKeys.projectMemberDetails(),
    projectIdx,
    idx,
  ],
  groupMemberLists: () => [...projectKeys.all, 'groupMemberList'] as const,
  groupMemberList: (projectIdx: string) =>
    [...projectKeys.groupMemberLists(), projectIdx] as const,
  alarms: () => [...projectKeys.all, 'alarm'] as const,
  alarm: (idx: string) => [...projectKeys.alarms(), idx] as const,
  autoRegisters: () => [...projectKeys.all, 'autoRegisters'] as const,
  autoRegister: (projectIdx: string) =>
    [...projectKeys.autoRegisters(), projectIdx] as const,
};
