import { ErrorBoundaryWrapper } from '@autosquare/common';
import clsx from 'clsx';
import React from 'react';

type Props = {
  headers: string[];
  isWeb?: boolean;
};

const SpeedRepeatResultTableHeader = ({ headers, isWeb = false }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div className="flex border-b border-gray-200">
        {headers.map((header, index) => (
          <div
            key={index}
            className={clsx(
              'px-6 py-5 text-left text-sm font-medium text-gray-900',
              {
                'flex-1': !isWeb || (isWeb && index !== 0),
                'w-48': isWeb && index === 0,
              },
            )}
          >
            {header}
          </div>
        ))}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default SpeedRepeatResultTableHeader;
