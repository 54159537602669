import { createQueryKeys } from '@queries/createQueryKeys';

export const speedMobileTestResultKeys = {
  ...createQueryKeys('speedMobileTestResultKeys'),
  detailInfos: () => [...speedMobileTestResultKeys.all, 'detailInfo'] as const,
  detailInfo: (idx: string) =>
    [...speedMobileTestResultKeys.detailInfos(), idx] as const,
  detailVideo: (path: string[], chartIndex: number) =>
    [
      [...speedMobileTestResultKeys.detailInfos(), path, chartIndex],
      'video',
    ] as const,
  detailLog: (path: string, chartIndex: number) =>
    [
      [...speedMobileTestResultKeys.detailInfos(), path, chartIndex],
      'log',
    ] as const,
};
