import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { axiosInstance } from '..';

import { API } from '@api/endpoints';

import { ServerResponseErrorDetails } from '@customTypes/type';
import { projectKeys } from '@queries/dashboard/project/projectKeys';
import {
  ProjectListDetail,
  UpdateProjectDetailSetting,
} from '@customTypes/index';
import { ActivateMessengerData } from '@customTypes/ide/scheduler/scheduler';
import { AutoRegisterType } from '@customTypes/dashboard/admin/type';

// GET
// 프로젝트 목록 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Project/getProjectList
const getProjectListQuery = async (): Promise<ProjectListDetail[]> => {
  const response = await axiosInstance.get<ProjectListDetail[]>(API.PROJECT);
  return response.data;
};
export const projectListQuery = () =>
  useQuery<ProjectListDetail[], ServerResponseErrorDetails>({
    queryKey: projectKeys.lists(),
    queryFn: () => getProjectListQuery(),
  });

// 프로젝트 개별 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Project/getProjectName
const getProjectDetail = async (
  projectIdx: string,
): Promise<ProjectListDetail> => {
  const response = await axiosInstance.get<ProjectListDetail>(
    `${API.PROJECT}/${projectIdx}`,
  );
  return response.data;
};
export const useProjectDetailQuery = () => {
  const { projectIdx } = useParams();

  return useQuery<ProjectListDetail, ServerResponseErrorDetails>({
    queryKey: projectKeys.detail(projectIdx),
    queryFn: () => getProjectDetail(projectIdx),
  });
};

// project settings 프로젝트 세팅 상세 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Project/getProjectDetail
const getProjectSettingDetail = async (
  projectIdx: string,
): Promise<ProjectListDetail> => {
  const response = await axiosInstance.get<ProjectListDetail>(
    `${API.PROJECT}/details/${projectIdx}`,
  );
  return response.data;
};
export const useProjectSettingDetailQuery = () => {
  const { projectIdx } = useParams();

  return useQuery<ProjectListDetail, ServerResponseErrorDetails>({
    queryKey: projectKeys.settingDetail(projectIdx),
    queryFn: () => getProjectSettingDetail(projectIdx),
  });
};

// 프로젝트 내 알림 플랫폼 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Project/getProjectAlarmList
const getSchedulerMessengerInfo = async (
  idx: string,
): Promise<ActivateMessengerData[]> => {
  const response = await axiosInstance.get(`${API.PROJECT}/${idx}/alarm`);
  return response.data;
};

export const useRemoteAlarmPlatformQuery = () => {
  const { projectIdx } = useParams();

  return useQuery<ActivateMessengerData[], Error>({
    queryKey: projectKeys.alarm(projectIdx),
    queryFn: () => getSchedulerMessengerInfo(projectIdx),
  });
};

// 어드민에서 설정한 autoRegister 정보
// https://dev.t-square.co.kr/swagger-ui/index.html#/Project/getActiveProjectAutoRegisters
const getAutoRegistersInfo = async (
  projectIdx: string,
): Promise<AutoRegisterType[]> => {
  const response = await axiosInstance.get<AutoRegisterType[]>(
    `${API.PROJECT}/${projectIdx}/auto-registers`,
  );
  return response.data;
};

export const useAutoRegistersInfoQuery = () => {
  const { projectIdx } = useParams();

  return useQuery<AutoRegisterType[], Error>({
    queryKey: projectKeys.autoRegister(projectIdx),
    queryFn: () => getAutoRegistersInfo(projectIdx),
  });
};

// PUT
// project settings 프로젝트 세팅 수정
// https://dev.t-square.co.kr/swagger-ui/index.html#/Project/updateProject
const updateProjectSetting = async (
  projectIdx: string,
  data: UpdateProjectDetailSetting,
): Promise<ProjectListDetail> => {
  const response = await axiosInstance.put(
    `${API.PROJECT}/details/${projectIdx}`,
    data,
  );
  return response.data;
};
export const useUpdateProjectSettingMutation = () => {
  const queryClient = useQueryClient();
  const { projectIdx } = useParams();

  return useMutation<
    ProjectListDetail,
    ServerResponseErrorDetails,
    UpdateProjectDetailSetting
  >({
    mutationFn: (data) => updateProjectSetting(projectIdx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(projectKeys.all);
    },
  });
};
