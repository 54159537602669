import React, { useEffect, useState } from 'react';
import { Controller, Path, useFormContext } from 'react-hook-form';
import { CommonDialog } from '@components/Dialog';
import {
  AutoRegisterType,
  CreateAdminProjectData,
} from '@customTypes/dashboard/admin/type';
import {
  autoRegisterPlatformList,
  AutoRegisterPlatformServername,
  confluenceInput,
  findAutoRegisterPlatformName,
  jiraInput,
} from '@utils/static/autoRegister/autoRegisterTypeList';
import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';
import AutoRegisterInputList from './AutoRegisterModal/AutoRegisterInputList';
import { dynamicDefaultInputList } from '@utils/static/autoRegister/autoRegisterInputList';

type Props = {
  isEditModalOpened: boolean;
  setIsEditModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  autoRegisterData: AutoRegisterType;
  autoRegisterDataList: AutoRegisterType[];
  setAutoRegisterDataList: React.Dispatch<
    React.SetStateAction<AutoRegisterType[]>
  >;
  targetIndex: number;
};

const AutoRegisterEditModal = ({
  isEditModalOpened,
  setIsEditModalOpened,
  autoRegisterData,
  autoRegisterDataList,
  setAutoRegisterDataList,
  targetIndex,
}: Props) => {
  const { control, watch, setValue, trigger, resetField, clearErrors } =
    useFormContext<CreateAdminProjectData>();

  const [isSavedButtonClicked, setIsSavedButtonClicked] = useState(false);
  const platform = watch('platform');

  const currentPlatform = autoRegisterData.platform;
  const filteredPlatformList = autoRegisterPlatformList.filter(
    (platform) =>
      currentPlatform === platform.serverName ||
      !autoRegisterDataList.some(
        (registered) => registered.platform === platform.serverName,
      ),
  );

  const { autoRegisterInputList } = dynamicDefaultInputList(platform);

  const validateHandling = (result: boolean) => {
    if (result) {
      const newData = {
        ...watch('platformInput'),
        activate: true,
        platform: platform,
      };
      const newDataList = autoRegisterDataList.map((data, idx) =>
        idx === targetIndex ? newData : data,
      );
      setAutoRegisterDataList(newDataList);
      setValue('platform', platform);
      setIsEditModalOpened(false);
      setIsSavedButtonClicked(false);
    }
  };

  const saveButtonHandler = async () => {
    setIsSavedButtonClicked(true);
    if (platform === AutoRegisterPlatformServername.Jira) {
      const result = await trigger(jiraInput, { shouldFocus: true });
      validateHandling(result);
      return;
    }
    if (platform === AutoRegisterPlatformServername.Confluence) {
      const result = await trigger(confluenceInput, {
        shouldFocus: true,
      });
      validateHandling(result);
    }
  };

  const cancelButtonHandler = () => {
    clearErrors('platformInput');
    setIsEditModalOpened(false);
    setValue('platform', undefined);
    Object.keys(autoRegisterData).forEach((key) =>
      setValue(
        `platformInput.${key}` as Path<CreateAdminProjectData>,
        undefined,
      ),
    );
  };

  useEffect(() => {
    setValue('platform', autoRegisterData.platform);
    Object.keys(autoRegisterData).forEach((key) =>
      setValue(
        `platformInput.${key}` as Path<CreateAdminProjectData>,
        autoRegisterData[key],
      ),
    );
  }, [setValue, autoRegisterData]);

  useEffect(() => {
    clearErrors('platformInput');
  }, [platform]);

  return (
    <ErrorBoundaryWrapper>
      <CommonDialog
        isOpen={isEditModalOpened}
        setIsOpen={setIsEditModalOpened}
        iconType={'edit'}
        title={'Edit Platform'}
        subTitle={''}
        onClose={() => clearErrors('platformInput')}
      >
        <div className="mt-5 space-y-4">
          <Controller
            control={control}
            name="platform"
            render={({ field }) => (
              <ListBoxHeadlessInController
                value={field.value}
                onChange={(value) => {
                  if (autoRegisterData.platform !== value) {
                    resetField('platformInput.requestUrl');
                    resetField('platformInput.email');
                    resetField('platformInput.apiToken');
                  } else {
                    Object.keys(autoRegisterData).forEach((key) =>
                      setValue(
                        `platformInput.${key}` as Path<CreateAdminProjectData>,
                        autoRegisterData[key],
                      ),
                    );
                  }
                  field.onChange(value);
                }}
                lists={filteredPlatformList}
                valueToSave={'serverName'}
                valueToShow={'name'}
                buttonValue={findAutoRegisterPlatformName(field.value)}
                placeholder="Select a Platform"
              />
            )}
          />
          <div className="flex flex-col space-y-4">
            <AutoRegisterInputList
              autoRegisterInputList={autoRegisterInputList}
              isSavedButtonClicked={isSavedButtonClicked}
            />
          </div>
          <CommonDialog.DialogButtonPanels>
            <CommonDialog.DialogButton
              variant="primary"
              type="button"
              onClick={saveButtonHandler}
            >
              Save
            </CommonDialog.DialogButton>
            <CommonDialog.DialogButton
              variant="secondary"
              type="button"
              onClick={cancelButtonHandler}
            >
              Cancel
            </CommonDialog.DialogButton>
          </CommonDialog.DialogButtonPanels>
        </div>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default AutoRegisterEditModal;
