import { ErrorMessage, TableSkeleton } from '@autosquare/common';
import React from 'react';
import ReservationDeviceUnAuthorization from './ReservationDeviceMain/ReservationDeviceUnAuthorization';
import { useReservedDeviceListQuery } from '@lib/api/ide/queryHooks';
import ReservationDeviceAuthorization from './ReservationDeviceMain/ReservationDeviceAuthorization';

const ReservationDeviceMain = () => {
  const {
    data: reservationDeviceList,
    isLoading,
    isError,
    error,
  } = useReservedDeviceListQuery();

  if (isLoading) {
    return <TableSkeleton />;
  }

  if (isError) {
    return <ErrorMessage>{error.message}</ErrorMessage>;
  }

  if (!reservationDeviceList?.authorizationEnabled) {
    return <ReservationDeviceUnAuthorization />;
  }

  return <ReservationDeviceAuthorization />;
};

export default ReservationDeviceMain;
