import React from 'react';
import SignUpFormFields from './SignUpFormFields';

const PasswordFields = () => {
  return (
    <>
      <SignUpFormFields
        title={'Password*'}
        name={'password'}
        type={'password'}
      />
      <SignUpFormFields
        title={'Confirm Password*'}
        name={'confirmPassword'}
        type={'password'}
      />
    </>
  );
};

export default PasswordFields;
