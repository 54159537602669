import React, { useState, useRef } from 'react';
import JSZip from 'jszip';
import { useUploadAdminWebDriverMutation } from '@lib/api/dashboard/queryHooks';

interface WebDriverUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUploadSuccess: () => void;
}

const WebDriverUploadModal: React.FC<WebDriverUploadModalProps> = ({
  isOpen,
  onClose,
  onUploadSuccess,
}) => {
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [os, setOs] = useState('');
  const [browser, setBrowser] = useState('');
  const [version, setVersion] = useState('');
  const [cpuArch, setCpuArch] = useState('');
  const [error, setError] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const uploadMutation = useUploadAdminWebDriverMutation();

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setUploadFile(selectedFile);
      setError('');

      // Check if the file contains chromedriver.exe or msedgedriver.exe
      const zip = new JSZip();
      try {
        const contents = await zip.loadAsync(selectedFile);
        const hasValidDriver = Object.keys(contents.files).some(
          (fileName) =>
            fileName.includes('chromedriver.exe') ||
            fileName.includes('msedgedriver.exe'),
        );
        if (!hasValidDriver) {
          setError(
            'The zip file must contain chromedriver.exe or msedgedriver.exe',
          );
          setUploadFile(null);
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        }
      } catch (error) {
        console.error('Error reading zip file:', error);
        setError('Error reading zip file');
        setUploadFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!uploadFile || !os || !browser || !version || !cpuArch) {
      setError('All fields are required');
      return;
    }

    try {
      await uploadMutation.mutateAsync({
        uploadFile,
        os,
        browser,
        version,
        cpuArch,
      });
      onUploadSuccess();
      resetForm();
    } catch (error) {
      console.error('Error uploading web driver:', error);
      setError('Failed to upload web driver');
    }
  };

  const resetForm = () => {
    setUploadFile(null);
    setOs('');
    setBrowser('');
    setVersion('');
    setCpuArch('');
    setError('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 size-full overflow-y-auto bg-gray-600/50">
      <div className="relative top-20 mx-auto w-96 rounded-md border bg-white p-5 shadow-lg">
        <h3 className="mb-4 text-lg font-medium leading-6 text-gray-900">
          Upload WebDriver
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-sm font-medium leading-6 text-gray-900"
              htmlFor="uploadFile"
            >
              Select a file
            </label>
            <input
              type="file"
              id="uploadFile"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept=".zip"
              className="block w-full rounded-lg border border-gray-200 text-sm shadow-sm file:me-4 file:border-0 file:bg-gray-50 file:px-4 file:py-3 focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-900 dark:text-neutral-400 dark:file:bg-neutral-700 dark:file:text-neutral-400"
            />
          </div>
          {uploadFile && (
            <>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium leading-6 text-gray-900"
                  htmlFor="os"
                >
                  OS
                </label>
                <select
                  id="os"
                  value={os}
                  onChange={(e) => setOs(e.target.value)}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value="">Select OS</option>
                  <option value="Windows">Windows</option>
                  <option value="Mac">Mac</option>
                </select>
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium leading-6 text-gray-900"
                  htmlFor="browser"
                >
                  Browser
                </label>
                <select
                  id="browser"
                  value={browser}
                  onChange={(e) => setBrowser(e.target.value)}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value="">Select Browser</option>
                  <option value="Chrome">Chrome</option>
                  <option value="Edge">Edge</option>
                </select>
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium leading-6 text-gray-900"
                  htmlFor="version"
                >
                  Version
                </label>
                <input
                  type="text"
                  id="version"
                  value={version}
                  onChange={(e) => setVersion(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium leading-6 text-gray-900"
                  htmlFor="cpuArch"
                >
                  CPU Architecture
                </label>
                <select
                  id="cpuArch"
                  value={cpuArch}
                  onChange={(e) => setCpuArch(e.target.value)}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value="">Select CPU Architecture</option>
                  <option value="x64">x64</option>
                  <option value="x86">x86</option>
                  <option value="arm64">arm64</option>
                </select>
              </div>
            </>
          )}
          {error && <p className="mb-4 text-xs italic text-red-500">{error}</p>}
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={onClose}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WebDriverUploadModal;
