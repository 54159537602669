import { useWebTestCaseListQuery } from '@lib/api/ide/web/webTestCase';
import { WebCommandOptionServerName } from '../webCommandOptions';
import { findTitleFromIdxByTestCase } from '@utils/arrayUtils';
import { WebTestStepData } from '@customTypes/testStep/type';
import { findTitleFromIdx } from '../findTitleFromIdx';
import { findBrowserKeyNameFromServerName } from '../browserKeyList';
import {
  findKeyboardEventNameFromServerName,
  KeyboardEventServerName,
} from '../keyboardEventList';
import { useWebTestStepListQuery } from '@lib/api/ide/web/webTestStep';
import { CompareBy } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { useGetWebTestCaseDataAutomation } from '@lib/api/ide/web/webTestAutomation';

export const getWebAddTestStepListValue = (
  allWebTestStepList: WebTestStepData,
) => {
  const { data: webTestStepList } = useWebTestStepListQuery();
  const { data: webTestCaseList } = useWebTestCaseListQuery({
    enabled:
      allWebTestStepList.command ===
        WebCommandOptionServerName.ImportTestCase ||
      allWebTestStepList.command === WebCommandOptionServerName.ImportTestStep,
  });

  const importTestStepTitle = findTitleFromIdxByTestCase(
    Number(allWebTestStepList.value),
    webTestCaseList,
  );
  const importTestStepCount =
    allWebTestStepList.optionsDto.stepIdxList?.split(',').length;

  const targetTextNum =
    webTestStepList
      ?.map((item) => item.idx)
      .indexOf(Number(allWebTestStepList.optionsDto.compareIdx)) + 1;

  const isSecret = allWebTestStepList.optionsDto.secret === 'true';
  const isTextEncrypt = allWebTestStepList.optionsDto.textEncrypt === 'true';

  // input text 에서 Data Automation 유무 확인 로직
  const { data: webTestCaseDataAutomation } = useGetWebTestCaseDataAutomation({
    enabled:
      allWebTestStepList.command === WebCommandOptionServerName.InputText,
  });

  switch (allWebTestStepList.command) {
    case WebCommandOptionServerName.DataValidation:
    case WebCommandOptionServerName.GetUrl:
      return allWebTestStepList.value;
    case WebCommandOptionServerName.ImportTestCase:
      return findTitleFromIdx(
        Number(allWebTestStepList.value),
        webTestCaseList,
      );
    case WebCommandOptionServerName.ImportTestStep:
      return `${importTestStepTitle} / ${importTestStepCount}`;

    case WebCommandOptionServerName.BrowserKey:
      return findBrowserKeyNameFromServerName(allWebTestStepList.value);
    case WebCommandOptionServerName.KeyBoardEvent:
      return findKeyboardEventNameFromServerName(
        allWebTestStepList.value as KeyboardEventServerName,
      );
    case WebCommandOptionServerName.ScrollAndSwipe:
      return `[${allWebTestStepList.value}]`;

    case WebCommandOptionServerName.Click:
    case WebCommandOptionServerName.SaveText:
      if (isSecret) {
        return `******`;
      } else {
        return allWebTestStepList.value;
      }
    case WebCommandOptionServerName.InputText:
      if (allWebTestStepList.optionsDto.compareBy === CompareBy.Automation) {
        return `${allWebTestStepList.value}/${webTestCaseDataAutomation?.idx ? 'Automation' : 'None'}`;
      }
      return allWebTestStepList.value;
    default:
      if (allWebTestStepList.optionsDto.compareBy === 'id') {
        if (isSecret) {
          return `******\ntarget text=#${targetTextNum}`;
        } else {
          return `${allWebTestStepList.value}\ntarget text=#${targetTextNum}`;
        }
      } else if (allWebTestStepList.optionsDto.compareBy === 'text') {
        if (isSecret) {
          return `******\ntarget text="**********"`;
        }
        if (isTextEncrypt) {
          return `${allWebTestStepList.value}\ntarget text="**********"`;
        }
        return `${allWebTestStepList.value}\ntarget text="${allWebTestStepList.optionsDto.compareText}"`;
      } else {
        return allWebTestStepList.value;
      }
  }
};
