import ExportOptionsTestStepResultUI from '@components/ExportOptionsDialog/components/ExportOptionsTestStepResultUI';
import { useExportOptionsTestStepResult } from '@hooks/index';
import { useWebTestCaseExecutionDetailQuery } from '@lib/api/dashboard/web/webTestResult';
import React from 'react';

type Props = {
  caseResultIdx: number;
  isPanelOpen: boolean;
};

const ExportOptionsTestStepResult = ({ caseResultIdx, isPanelOpen }: Props) => {
  const webTestResultDetailInquiryByCaseQuery =
    useWebTestCaseExecutionDetailQuery(caseResultIdx);
  const testResultDetailInquiryByCase =
    webTestResultDetailInquiryByCaseQuery.data;

  const { caseResult, handleChildCheckbox } =
    useExportOptionsTestStepResult(caseResultIdx);

  return (
    <ExportOptionsTestStepResultUI
      isPanelOpen={isPanelOpen}
      testResultDetailInquiryByCase={testResultDetailInquiryByCase}
      caseResult={caseResult}
      handleChildCheckbox={handleChildCheckbox}
    />
  );
};

export default ExportOptionsTestStepResult;
