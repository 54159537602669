import React, { useEffect, useState } from 'react';
import {
  ErrorMessage,
  SpeedTestResultDetailSkeleton,
  NoResults,
  DoubleCheckDialogHeadlessUI,
  Button,
  ArrowSpinner,
  ErrorBoundaryWrapper,
} from '@autosquare/common';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import InformationTable from '@components/MobileSpeedTestResultDetail/InformationTable/InformationTable';
import SpeedTestResultGraph from '@components/MobileSpeedTestResultDetail/SpeedTestResultGraph/SpeedTestResultGraph';
import RangePickEndCalendar from '@components/MobileSpeedTestResultDetail/RangePickCalendar/RangePickEndCalendar';
import RangePickStartCalendar from '@components/MobileSpeedTestResultDetail/RangePickCalendar/RangePickStartCalendar';
import {
  useMobileSpeedResultDetailQuery,
  useMobileSpeedTestResultExportMutation,
} from '@lib/api/dashboard/mobile/mobileSpeedResult';
import { useExportChart } from '@hooks/index';
import MobileSpeedTestResultHeader from '@components/SpeedTest/MobileSpeedTestResultDetail/MobileSpeedTestResultHeader';

const MobileSpeedTestResultDetail = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const speedMobileTestResultDetailQuery = useMobileSpeedResultDetailQuery();
  const speedMobileTestResultDetailData = speedMobileTestResultDetailQuery.data;

  const start = searchParams.get('start');
  const defaultStartDate = dayjs(start).toDate();

  const end = searchParams.get('end');
  const defaultEndDate = dayjs(end).toDate();

  const resultIdx = searchParams.get('result');
  const groupIdx = searchParams.get('group');

  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const NoData = speedMobileTestResultDetailData?.dateList.length === 0;

  useEffect(() => {
    setStartDate(new Date(dayjs(start).format()));
    speedMobileTestResultDetailQuery.refetch();
  }, [start]);

  useEffect(() => {
    setEndDate(new Date(dayjs(end).format()));
    speedMobileTestResultDetailQuery.refetch();
  }, [end]);

  useEffect(() => {
    return () => localStorage.removeItem('entryPoint');
  }, []);

  const mobileSpeedTestResultExportMutation =
    useMobileSpeedTestResultExportMutation();

  const {
    isModalOpen,
    setIsModalOpen,
    chartRef,
    exportChartToPng,
    errorMessage,
  } = useExportChart(mobileSpeedTestResultExportMutation);

  return (
    <ErrorBoundaryWrapper>
      <MobileSpeedTestResultHeader exportChartToPng={exportChartToPng} />
      {speedMobileTestResultDetailQuery.isLoading ? (
        <SpeedTestResultDetailSkeleton />
      ) : speedMobileTestResultDetailQuery.isError ? (
        <ErrorMessage>
          {speedMobileTestResultDetailQuery.error.message}
        </ErrorMessage>
      ) : (
        <div className="mt-14 px-8">
          <div className="flex items-center justify-between">
            <h2 className="font-semibold">
              {speedMobileTestResultDetailData?.group} Loading Time 비교측정
              결과
            </h2>
            <div className="flex items-center justify-center gap-x-3">
              <RangePickStartCalendar
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                resultIdx={resultIdx}
                groupIdx={groupIdx}
                minDate={speedMobileTestResultDetailData.dateList[0]}
              />
              <span>&#45;</span>
              <RangePickEndCalendar
                endDate={endDate}
                setEndDate={setEndDate}
                startDate={startDate}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                resultIdx={resultIdx}
                groupIdx={groupIdx}
              />
            </div>
          </div>
          {NoData ? (
            <NoResults />
          ) : (
            <ErrorBoundaryWrapper>
              <InformationTable data={speedMobileTestResultDetailData} />
              {speedMobileTestResultDetailQuery.isRefetching ? (
                <SpeedTestResultDetailSkeleton
                  isRefetching={speedMobileTestResultDetailQuery.isRefetching}
                />
              ) : (
                <SpeedTestResultGraph
                  data={speedMobileTestResultDetailData}
                  chartRef={chartRef}
                />
              )}
            </ErrorBoundaryWrapper>
          )}
        </div>
      )}
      <DoubleCheckDialogHeadlessUI
        isOpened={isModalOpen}
        setIsOpened={setIsModalOpen}
        type={'caution'}
        title={'Export Error'}
        subTitle={errorMessage}
        buttonChildren={
          <Button
            type="button"
            variant="secondary"
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </Button>
        }
      />
      {mobileSpeedTestResultExportMutation.isLoading && <ArrowSpinner />}
    </ErrorBoundaryWrapper>
  );
};

export default MobileSpeedTestResultDetail;
