import {
  CardListSkeleton,
  ErrorBoundaryWrapper,
  ScenarioListDetailData,
  useCheckboxWithDnd,
  useInput,
} from '@autosquare/common';

import React, { useEffect, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

import ListItemCard from './DraggableElement/ListItemCard';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { CreateSchedulerData } from '@customTypes/ide/scheduler/scheduler';
import { ErrorMessage } from '@hookform/error-message';
import SelectionHeaderWithCheckbox from '@components/shared/SelectionHeaderWithCheckbox';
import TransferItemButton from '@components/shared/TransferItemButton';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import SchedulerScenarioTestCaseListBox from './DraggableElement/SchedulerScenarioTestCaseListBox';

interface Props {
  testScenarioQuery: UseQueryResult<ScenarioListDetailData[], Error>;
}

const DraggableElement = ({ testScenarioQuery }: Props) => {
  const {
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateSchedulerData>();
  const testType = watch('type');
  const scenarioIdxList = watch('scenarioIdxList');

  const [copyTestScenarios, setCopyTestScenarios] = useState<
    ScenarioListDetailData[]
  >([]);
  const [testScenarios, setTestScenarios] = useState<
    ScenarioListDetailData[] | null
  >([]);
  const [searchWord, onChangeSearchWord, setSearchWord] = useInput('');

  const searchResultArray = testScenarios?.filter((testScenario) =>
    testScenario.title.toLowerCase().includes(searchWord.toLowerCase()),
  );

  const { selectAllArray, selectArray, selectedArray, resetSelectedArray } =
    useCheckboxWithDnd(testScenarios, 'idx', 'dndIndex');

  const allTestScenarios = testScenarioQuery.data;

  useEffect(() => {
    setTestScenarios(
      allTestScenarios?.map((testScenario, index) => ({
        ...testScenario,
        dndIndex: index + 1,
      })),
    );
  }, [allTestScenarios]);

  useEffect(() => {
    setCopyTestScenarios([]);
  }, [testType]);

  useEffect(() => {
    if (scenarioIdxList?.length > 0) {
      clearErrors('scenarioIdxList');
    }
  }, [clearErrors, scenarioIdxList]);

  const copyAndDropList = [
    {
      title: 'All Test Scenario List',
      list:
        testScenarios?.length === 0 ? (
          <div className="flex h-2/3 items-center justify-center">
            추가 가능한 테스트 시나리오가 존재하지 않습니다.
          </div>
        ) : (
          <>
            <SelectionHeaderWithCheckbox
              selectAllArray={selectAllArray}
              selectedArray={selectedArray}
              total={testScenarios?.length}
            />
            <div className="gray-scrollbar max-h-[13.375rem]">
              {searchResultArray?.map((testScenario) => (
                <ListItemCard
                  key={testScenario.idx}
                  testScenario={testScenario}
                  copyTestScenarios={copyTestScenarios}
                  selectArray={selectArray}
                  selectedArray={selectedArray}
                />
              ))}
            </div>
          </>
        ),
    },
    {
      title: 'add button',
      list: (
        <TransferItemButton
          items={testScenarios}
          copyItems={copyTestScenarios}
          setCopyItems={setCopyTestScenarios}
          selectedArray={selectedArray}
          resetSelectedArray={resetSelectedArray}
        />
      ),
    },
    {
      title: 'Scheduler Scenario List',
      list: (
        <SchedulerScenarioTestCaseListBox
          copyTestScenarios={copyTestScenarios}
          setCopyTestScenarios={setCopyTestScenarios}
        />
      ),
    },
  ];

  if (testScenarioQuery.isLoading || testScenarioQuery.isFetching) {
    return <CardListSkeleton />;
  }
  if (testScenarioQuery.isError) {
    return (
      <div className="flex items-center justify-center">
        {testScenarioQuery.error.message}
      </div>
    );
  }

  return (
    <ErrorBoundaryWrapper>
      <div className="flex w-full items-center gap-4">
        {copyAndDropList?.map((item, index) => (
          <div className={clsx(index !== 1 && 'w-full')} key={item.title}>
            <div className="mb-2 flex justify-between">
              {index !== 1 && (
                <p className="font-medium leading-6">{item.title}</p>
              )}
            </div>
            <div
              className={clsx(
                index !== 1 &&
                  'border-border-line h-80 rounded-md border border-solid',
              )}
            >
              {index === 0 && (
                <div className="relative w-full px-2 pb-1.5 pt-2">
                  <MagnifyingGlassIcon className="absolute left-4 top-4 size-4" />
                  <input
                    className="input-sm px-8"
                    value={searchWord}
                    onChange={onChangeSearchWord}
                  />
                  <XMarkIcon
                    className="absolute right-4 top-4 size-4 cursor-pointer"
                    onClick={() => setSearchWord('')}
                  />
                </div>
              )}
              {item.list}
            </div>
          </div>
        ))}
      </div>
      <ErrorMessage
        name="scenarioIdxList"
        errors={errors}
        render={({ message }) => (
          <p className="standard-error-message">{message}</p>
        )}
      />
    </ErrorBoundaryWrapper>
  );
};

export default DraggableElement;
