import React from 'react';
import AdminDeviceDetailFormFields from './AdminDeviceDetailFormFields';
import { useFormContext } from 'react-hook-form';
import { UpdateAdministrationDevice } from '@customTypes/index';

const DeviceNameFields = () => {
  const { register, watch } = useFormContext<UpdateAdministrationDevice>();

  const isEnabled = watch('deviceEnabled');

  return (
    <AdminDeviceDetailFormFields
      id={'deviceName'}
      label={
        'Device Name (1~20자, 영문 대소문자, 한글, 숫자[0-9], 대시(-), 언더바(_), 쉼표(,), 온점(.) 입력 가능)'
      }
    >
      <input
        className="input-base disabled:bg-gray-100"
        placeholder="등록할 디바이스의 이름을 입력해 주세요."
        {...register('deviceName')}
        disabled={!isEnabled}
      />
    </AdminDeviceDetailFormFields>
  );
};

export default DeviceNameFields;
