import { ErrorBoundaryWrapper } from '@autosquare/common';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { SaveTextFrom } from '@customTypes/ide/mobileTestStep/SaveTextFrom';
import clsx from 'clsx';

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const From = () => {
  const { watch, resetField, setValue, register, unregister, clearErrors } =
    useFormContext<CreateTestStepData>();

  const mobileOs = watch('mobileOs');
  const saveTextFrom = watch('optionsDto.from');

  useEffect(() => {
    return () => resetField('optionsDto.from');
  }, [resetField]);

  useEffect(() => {
    if (saveTextFrom === SaveTextFrom.Element) {
      setValue('optionsDto.textExtract', 'false');
      unregister('value');
    }

    if (saveTextFrom === SaveTextFrom.Sms) {
      setValue('optionsDto.textExtract', 'true');
      setValue('value', '');
    }

    clearErrors('value');

    return () => {
      resetField('optionsDto.textExtract');
    };
  }, [saveTextFrom, setValue, resetField, unregister]);

  const radioList = [
    {
      title: 'Element',
      id: SaveTextFrom.Element,
      defaultChecked: true,
      onClick: () => {
        if (saveTextFrom === SaveTextFrom.Sms) {
          setValue('value', '');
        }
      },
    },
    {
      title: 'SMS',
      id: SaveTextFrom.Sms,
      disabled: mobileOs === DeviceInfoOs.Ios,
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="grid w-full grid-cols-3 items-center text-sm font-medium leading-normal text-gray-900">
        <p>From</p>
        {radioList.map((radio) => (
          <div key={radio.id} className="flex items-center">
            <input
              id={radio.id}
              name={'from'}
              type="radio"
              defaultChecked={radio.defaultChecked}
              value={radio.id}
              className="input-radio-congress-blue"
              onClick={radio.onClick}
              disabled={radio.disabled}
              aria-disabled={radio.disabled}
              {...register('optionsDto.from')}
            />
            <label
              htmlFor={radio.id}
              className={clsx(
                'ml-3 block text-sm font-medium leading-6 text-gray-900',
                radio.disabled
                  ? 'cursor-default text-opacity-50'
                  : 'cursor-pointer',
              )}
              aria-disabled={radio.disabled}
            >
              {radio.title}
            </label>
          </div>
        ))}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default From;
