import {
  Detail,
  TableBodyBasic,
  TableBodyContainer,
  TableNoList,
} from '@autosquare/common';
import { DeviceReservationStatus } from '@customTypes/index';
import { useReservedDeviceListQuery } from '@lib/api/ide/queryHooks';
import clsx from 'clsx';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  headerLength: number;
};

const ReservationDeviceAuthorizationTableBody = ({ headerLength }: Props) => {
  const navigate = useNavigate();

  const { data: reservationDeviceList } = useReservedDeviceListQuery();

  return (
    <TableBodyContainer>
      {reservationDeviceList.reservationInfo?.length === 0 ? (
        <TableNoList colSpan={headerLength}>
          <p>해당 프로젝트에 등록된 디바이스가 존재하지 않습니다.</p>
        </TableNoList>
      ) : (
        reservationDeviceList.reservationInfo.map((reservation, idx) => (
          <tr
            key={reservation.deviceIdx}
            className={clsx(
              reservation.reservationStatus ===
                DeviceReservationStatus.Unavailable && 'bg-gray-50',
            )}
          >
            <TableBodyBasic>{idx + 1}</TableBodyBasic>
            <TableBodyBasic>{reservation.deviceModel}</TableBodyBasic>
            <TableBodyBasic>{reservation.deviceName || '-'}</TableBodyBasic>
            <TableBodyBasic>{reservation.deviceOs}</TableBodyBasic>
            <TableBodyBasic>{reservation.userName || '-'}</TableBodyBasic>
            <TableBodyBasic>{reservation.reservationStatus}</TableBodyBasic>
            <TableBodyBasic>
              <Detail
                type="button"
                onClick={() =>
                  navigate({
                    pathname: 'detail',
                    search: `?deviceIdx=${reservation.deviceIdx}`,
                  })
                }
                disabled={
                  reservation.reservationStatus ===
                    DeviceReservationStatus.Available ||
                  reservation.reservationStatus ===
                    DeviceReservationStatus.Unavailable
                }
              />
            </TableBodyBasic>
          </tr>
        ))
      )}
    </TableBodyContainer>
  );
};

export default ReservationDeviceAuthorizationTableBody;
