import { ErrorMessage, Spinner } from '@autosquare/common';

import { RootState } from '@app/store';

import {
  useCheckWdaStatusQuery,
  useCreateSessionIdMutation,
  useRemoteCreateSessionIdMutation,
} from '@lib/api/ide/mobile/mobileDevice';

import { getDeviceInfo } from '@store/ide/device/selectedDevicesSlice';
import { hasDownloadedData } from '@store/ide/settings/settingsSlice';

import { DeviceInfo } from '@customTypes/ide/device/device';
import { ServerErrorCode } from '@customTypes/type';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import XcodeError from './Device/XcodeError';
import MobileDeviceList from './Device/MobileDeviceList';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { Mode } from '@customTypes/ide/remote/type';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';
import { getRemoteState } from '@utils/static/getConfiguration';

interface Props {
  deviceLists: DeviceInfo[];
}

const Device = ({ deviceLists }: Props) => {
  const { projectIdx } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const location = isWordInLocationPathname('ui-test')
    ? 'ui-test'
    : 'speed-test';

  const dispatch = useDispatch();

  const [deviceValue, setDeviceValue] = useState<DeviceInfo>();
  const [isXcodeApp, setIsXcodeApp] = useState(false);
  const [isWdaFile, setIsWdaFile] = useState(false);
  const [needIosSetting, setNeedIosSetting] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const isDownloadedData = useSelector(
    (state: RootState) => state.settings.isDownloadedData,
  );
  const { configuration, standardConfiguration, remoteConfiguration } =
    getRemoteState();
  const createSessionIdMutation =
    configuration === Mode.Standard
      ? useCreateSessionIdMutation()
      : useRemoteCreateSessionIdMutation();

  const checkWdaStatusQuery = useCheckWdaStatusQuery();
  const remotePCWdaStatusData = checkWdaStatusQuery.data;
  const remotePCWdaStatus = remotePCWdaStatusData?.filter(
    (data) => data.projectIdx === Number(projectIdx),
  )?.[0]?.status;

  const remoteCreateSessionCondition = remotePCWdaStatus !== null;
  useEffect(() => {
    if (
      (standardConfiguration && needIosSetting && isXcodeApp && isWdaFile) ||
      (remoteConfiguration && needIosSetting && remoteCreateSessionCondition)
    ) {
      createSessionIdMutation.mutate(deviceValue, {
        onSuccess: () => {
          navigate({
            pathname: `/ide/projects/${projectIdx}/${location}/mobile/test-step`,
            search: `${search}&udid=${deviceValue.udid}`,
          });
          dispatch(getDeviceInfo(deviceValue));
        },
        onError: () => {
          setNeedIosSetting(false);
        },
      });
    }
  }, [isXcodeApp, isWdaFile, needIosSetting, configuration]);

  useEffect(() => {
    const getXcodeLocation = async () => {
      const xcodeLocation: string | undefined =
        await window.electron.invoke('checkXcodeLocation');
      setIsXcodeApp(xcodeLocation?.toLowerCase().includes('xcode.app'));
    };
    const checkWdaFile = async () => {
      const existWdaFile: boolean = await window.electron.invoke(
        'existWdaFile',
        projectIdx,
      );
      setIsWdaFile(existWdaFile);
    };
    if (standardConfiguration) {
      getXcodeLocation();
      checkWdaFile();

      if (isDownloadedData) {
        getXcodeLocation();
        checkWdaFile();
        setNeedIosSetting(false);
        dispatch(hasDownloadedData(false));
      }
    }
  }, [isDownloadedData, standardConfiguration]);

  const needIosSettingButtonOption =
    (standardConfiguration && needIosSetting && (!isXcodeApp || !isWdaFile)) ||
    (!standardConfiguration && needIosSetting);

  return (
    <div>
      {deviceLists?.length === 0 ? (
        <div className="mt-3 flex w-full items-start justify-center rounded-md border border-solid border-gray-200 bg-gray-50 p-2 leading-5">
          연결된 디바이스가 없습니다.
        </div>
      ) : createSessionIdMutation.isLoading ? (
        <Spinner size="full" text="lg" message="Loading..." isFullScreen />
      ) : (createSessionIdMutation.isError &&
          createSessionIdMutation.error?.response?.data?.errorDetails
            ?.errorCode === ServerErrorCode.NOT_READY_TEST_ENVIRONMENT) ||
        needIosSettingButtonOption ? (
        <XcodeError
          errorMessage={
            createSessionIdMutation.error?.response?.data?.errorDetails?.message
          }
          isSettingsOpen={isSettingsOpen}
          setIsSettingsOpen={setIsSettingsOpen}
        />
      ) : createSessionIdMutation.isError ? (
        <ErrorMessage>
          {createSessionIdMutation.error?.response?.data?.errorDetails?.message}
        </ErrorMessage>
      ) : isAuthenticated ? (
        <ErrorMessage>
          디바이스가 unauthorized 상태입니다. 이 컴퓨터를 신뢰하도록 모두 허용해
          주세요.
        </ErrorMessage>
      ) : (
        <ul className="mt-3 grid grid-cols-4 gap-6">
          {deviceLists?.map((deviceList) => (
            <MobileDeviceList
              deviceList={deviceList}
              mutation={createSessionIdMutation}
              setNeedIosSetting={setNeedIosSetting}
              setDeviceValue={setDeviceValue}
              setIsAuthenticated={setIsAuthenticated}
              key={deviceList.udid}
              remotePCWdaStatus={remotePCWdaStatus}
            />
          ))}
        </ul>
      )}
      <RemoteConnectionAlarmModal />
    </div>
  );
};

export default Device;
