import SubTitleHeaderSection from '@components/shared/SubTitleHeader/SubTitleHeaderSection';
import React from 'react';

const InstallResultHeader = () => {
  return (
    <SubTitleHeaderSection
      header="Install Result"
      description="애플리케이션 설치 결과를 확인할 수 있습니다. (설치 완료까지 최대
        30초가 소요될 수 있습니다.)"
    />
  );
};

export default InstallResultHeader;
