import { DeviceInfoSchema } from '@customTypes/ide/device/device';
import { BrowserInfoSchema } from '@customTypes/ide/browser/browser';
import { z } from 'zod';
import { MessengerReceiverSchema } from '../speedScheduler/speedScheduler';
import { MailReceiverSchema } from '@autosquare/common';

export enum SchedulerTypeName {
  Mobile = 'Mobile',
  Web = 'Web',
}

export enum SchedulerTypeServerName {
  Mobile = 'mobile app',
  Web = 'web',
}

export const JiraMappingSchema = z.object({
  testResult: z.string().optional().nullable(),
  issueType: z.string().optional().nullable(),
  priority: z.string().optional().nullable(),
});
export const JiraAutoRegisterSchema = z.object({
  projectKey: z.string(),
  mapping: z.array(JiraMappingSchema),
});

export const ConfluenceRegisterSchema = z.object({
  requestUrl: z.string(),
  testResults: z.array(z.string()),
});
export const AutoRegisterInfoSchema = z.object({
  platformName: z.string(),
  jira: JiraAutoRegisterSchema.optional(),
  confluence: ConfluenceRegisterSchema.optional(),
  id: z.string().optional().nullable(),
});

export const BaseSchedulerSchema = z.object({
  title: z.string(),
  type: z.nativeEnum(SchedulerTypeServerName),
  startTime: z.string(),
  autoRegister: z.boolean(),
  mailReceivers: z.array(MailReceiverSchema),
  messengerReceivers: z.array(MessengerReceiverSchema),
  createdAt: z.string(),
  saveVideo: z.boolean().optional(),
  saveScreenshot: z.boolean().optional(),
  saveLog: z.boolean().optional(),
  autoRegisters: z.array(AutoRegisterInfoSchema).optional(),
  deviceReboot: z.boolean(),
});

export const SchedulerDetailSchema = BaseSchedulerSchema.extend({
  idx: z.number(),
  cycle: z.string(),
  running: z.boolean(),
  environment: z.string(),
  projectIdx: z.number(),
  scenarioIdxList: z.array(z.number()),
  creator: z.string(),
  ownerUuid: z.string(),
  initialCreatedAt: z.string().optional(),
});

export const BaseLocalSchedulerSchema = z.object({
  title: z
    .string()
    .min(1, { message: 'Title을 입력해 주세요.' })
    .max(50, { message: '50자 이하 입력해 주세요.' }),
  scenarioIdxList: z.array(z.number()),
  startTime: z.string(),
  autoRegister: z.boolean(),
  mailReceivers: z.array(MailReceiverSchema).optional(),
  messengerReceivers: z.array(MessengerReceiverSchema).optional(),
  saveVideo: z.boolean().optional(),
  saveScreenshot: z.boolean().optional(),
  saveLog: z.boolean().optional(),
  autoRegisters: z.array(AutoRegisterInfoSchema).optional(),
  initialCreatedAt: z.string().optional(),
  deviceReboot: z.boolean(),
});

export const ActivateMessengerInfoSchema = z.object({
  platform: z.string(),
  activate: z.boolean(),
  requestUrl: z.string(),
  srvCode: z.string(),
  sender: z.string(),
  recipientList: z.array(z.string()),
});

export const CreateSchedulerSchema = BaseLocalSchedulerSchema.extend({
  type: z.nativeEnum(SchedulerTypeServerName),
  projectIdx: z.number(),
  deviceInfoDto: DeviceInfoSchema.optional(),
  browserInfoDto: BrowserInfoSchema.optional(),
  cronCycle: z.string(),
});

const InfoSchema = DeviceInfoSchema.merge(BrowserInfoSchema).optional();

export const UpdateSchedulerSchema = BaseLocalSchedulerSchema.extend({
  scheduleIdx: z.number(),
  infos: InfoSchema,
  cronCycle: z.string(),
});
export const DeleteIndexListSchema = z.object({
  idxList: z.array(z.number()),
});
export type SchedulerDetailData = z.infer<typeof SchedulerDetailSchema>;
export type CreateSchedulerData = z.infer<typeof CreateSchedulerSchema>;
export type UpdateSchedulerData = z.infer<typeof UpdateSchedulerSchema>;
export type DeleteSchedulerData = z.infer<typeof DeleteIndexListSchema>;
export type ActivateMessengerData = z.infer<typeof ActivateMessengerInfoSchema>;
export type JiraMappingType = z.infer<typeof JiraMappingSchema>;
export type AutoRegisterInfoData = z.infer<typeof AutoRegisterInfoSchema>;

export interface RunningCycle {
  idx: number;
  title: string;
}
