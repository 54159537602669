import {
  DescriptionListSkeleton,
  ErrorBoundaryWrapper,
  ErrorMessage,
} from '@autosquare/common';

import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { useGetWebTestStepQuery } from '@lib/api/ide/web/webTestStep';
import { RootState } from '@app/store';

import TestStepDetailBasic from './TestStepDetail/TestStepDetailBasic';
import TestStepDetailEdit from './TestStepDetail/TestStepDetailEdit';
import clsx from 'clsx';
import { RESET_DELAY_MS } from '@constants/index';

type Props = {
  isDialogOpened: boolean;
  setIsDialogOpened: React.Dispatch<React.SetStateAction<boolean>>;
  testStepDetailListValue: string | ReactElement;
};

const TestStepDetail = ({
  isDialogOpened,
  setIsDialogOpened,
  testStepDetailListValue,
}: Props) => {
  const [isEdited, setIsEdited] = useState(false);

  const testStepIdx = useSelector(
    (state: RootState) => state.webTestStepDetail.testStepIdx,
  );
  const webTestStepQuery = useGetWebTestStepQuery(testStepIdx, {
    enabled: isDialogOpened,
  });

  const webTestStepData = webTestStepQuery.data;

  const closeDialog = () => {
    setIsDialogOpened(false);
    const timer = setTimeout(() => {
      setIsEdited(false);
    }, RESET_DELAY_MS);
    return () => clearTimeout(timer);
  };

  return (
    <ErrorBoundaryWrapper>
      <Dialog
        open={isDialogOpened}
        as="div"
        className="relative z-50"
        onClose={closeDialog}
      >
        <DialogBackdrop
          className="fixed inset-0 bg-black/30"
          aria-hidden="true"
        />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <DialogPanel
                transition
                className={clsx([
                  'pointer-events-auto w-screen max-w-[43.75rem]',
                  'transition duration-500 sm:duration-700',
                  'data-[enter]:translate-x-full ',
                  'data-[leave]:translate-x-full ',
                ])}
              >
                <div className="flex h-full flex-col overflow-y-auto bg-white shadow-xl scrollbar-thin">
                  <div className="bg-congress-blue px-4 py-6 sm:px-6">
                    <div className="flex items-center justify-between">
                      <DialogTitle className="text-base font-semibold leading-6 text-white">
                        Test Step Detail
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-congress-blue text-indigo-200 hover:text-white"
                          onClick={closeDialog}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="size-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {webTestStepQuery.isLoading || webTestStepQuery.isFetching ? (
                    <DescriptionListSkeleton
                      number={5}
                      isButtonSkeleton={true}
                    />
                  ) : webTestStepQuery.isError ? (
                    <ErrorMessage>
                      {webTestStepQuery.error.message}
                    </ErrorMessage>
                  ) : isEdited ? (
                    <TestStepDetailEdit
                      setIsEdited={setIsEdited}
                      webTestStepData={webTestStepData}
                    />
                  ) : (
                    <TestStepDetailBasic
                      webTestStepData={webTestStepData}
                      setIsEdited={setIsEdited}
                      closeDialog={closeDialog}
                      testStepDetailListValue={testStepDetailListValue}
                    />
                  )}
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    </ErrorBoundaryWrapper>
  );
};

export default TestStepDetail;
