import { ErrorBoundaryWrapper } from '@autosquare/common';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import {
  SpeedSchedulerTypeServerName,
  UpdateSpeedSchedulerData,
} from '@customTypes/index';
import { useSpeedSchedulerDetailQuery } from '@lib/api/ide/queryHooks';
import React from 'react';
import SwitchInController from '@components/shared/HeadlessUI/Switch/SwitchInController';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';

const SpeedTestSchedulerDetailDeviceReboot = () => {
  const { data: speedSchedulerDetail } = useSpeedSchedulerDetailQuery();

  if (speedSchedulerDetail.type === SpeedSchedulerTypeServerName.Web)
    return null;

  const { control } = useFormContext<UpdateSpeedSchedulerData>();

  const isRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer
        title={'Device Reboot'}
        tooltipWord="스케줄러 실행 종료 시, 연결된 디바이스를 자동으로 재부팅할 수 있습니다."
        tooltipClass="-right-56 bottom-6"
      >
        <Controller
          control={control}
          name="deviceReboot"
          render={({ field }) => (
            <SwitchInController
              checked={field.value || false}
              onChange={field.onChange}
              disabled={!isRunning}
            />
          )}
        />
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default SpeedTestSchedulerDetailDeviceReboot;
