import { TableHeader, TableHeaderContainer } from '@autosquare/common';
import React, { useEffect } from 'react';

type Props = {
  setHeaderLength: React.Dispatch<React.SetStateAction<number>>;
};

const InstalledAppTableHeader = ({ setHeaderLength }: Props) => {
  const tableHeaderWithoutIdx = [
    'No',
    'App Name',
    'Package Name',
    'App Version',
    'Build Version',
    'OS',
  ];

  const tableHeader = tableHeaderWithoutIdx.map((header, idx) => ({
    idx,
    header,
  }));

  useEffect(() => {
    setHeaderLength(tableHeader.length);
  }, [tableHeader, setHeaderLength]);

  return (
    <TableHeaderContainer>
      {tableHeader.map((header) => (
        <TableHeader key={header.idx}>{header.header}</TableHeader>
      ))}
    </TableHeaderContainer>
  );
};

export default InstalledAppTableHeader;
