import { z } from 'zod';
import { AutoRegisterType, MessengerAlarmType } from '../admin/type';
import { AutoRegisterPlatformServername } from '@utils/static/autoRegister/autoRegisterTypeList';

export enum ProjectListDetailFeaturesCategory {
  Mobile = 'mobile_app',
  Web = 'web',
}

export enum ProjectListDetailFeaturesFeature {
  Functional = 'functional_test',
  Speed = 'speed_test',
}

export const ProjectListDetailFeaturesSchema = z.object({
  category: z.nativeEnum(ProjectListDetailFeaturesCategory),
  feature: z.nativeEnum(ProjectListDetailFeaturesFeature),
  activate: z.boolean(),
});

export type ProjectListDetailFeatures = z.infer<
  typeof ProjectListDetailFeaturesSchema
>;

export interface ProjectListDetail {
  idx: number;
  name: string;
  description?: string;
  updatedAt: string;
  serviceName?: null | string;
  projectAdminList: {
    userIdx: number;
    name: string;
    email: string;
    systemRole: UserRole | null;
  }[];
  features: ProjectListDetailFeatures[];
  messengers: MessengerAlarmType[];
  registers: AutoRegisterType[];
}

const messengersSchema = z.object({
  platform: z.string().optional(),
  activate: z.boolean().optional(),
  requestUrl: z.string().optional(),
  srvCode: z.string().optional(),
  sender: z.string().optional(),
  recipientList: z.string().array().optional(),
  officeToken: z.string(),
});

const registerSchema = z.object({
  activate: z.boolean().optional(),
  platform: z.nativeEnum(AutoRegisterPlatformServername).optional(),
  email: z.string().optional(),
  apiToken: z.string().optional(),
  requestUrl: z.string().optional(),
});

export const UpdateProjectDetailSettingSchema = z.object({
  name: z.string(),
  serviceName: z
    .string()
    .max(100, { message: '100자 이하 입력 가능합니다.' })
    .optional(),
  description: z
    .string()
    .max(100, { message: '100자 이하 입력 가능합니다.' })
    .optional(),
  projectAdminList: z
    .string()
    .array()
    .min(1, { message: '최소 한명 이상의 프로젝트 어드민을 설정해 주세요.' }),
  features: z.array(ProjectListDetailFeaturesSchema),
  messengers: z.array(messengersSchema).optional(),
  registers: z.array(registerSchema).optional(),
});

export type UpdateProjectDetailSetting = z.infer<
  typeof UpdateProjectDetailSettingSchema
>;

export interface ProjectMember {
  idx: number;
  name: string;
  userId: string;
  groupName: string;
}

export enum UsageStatus {
  Active = 'ACTIVE',
  Deactivate = 'DEACTIVATE',
  Pending = 'PENDING',
}

export type ProjectMemberList = {
  idx: number;
  name: string;
  userId: string;
  projectRole: UserRole;
  lastSeen: string;
  usageStatus: UsageStatus;
  jobTitle: string;
  organization: string;
  department: string;
  systemRole: 0 | 1 | 2;
  modifiedAt: string;
};

export enum UserRole {
  Admin = 'Admin',
  Member = 'Member',
}

export const AddMemberDataSchema = z.object({
  addUserId: z.string().email({ message: 'Invalid email address' }),
  addUserRole: z.nativeEnum(UserRole),
});

export type AddMemberData = z.infer<typeof AddMemberDataSchema>;

export const UpdateMemberInfoDataSchema = z.object({
  projectRole: z.nativeEnum(UserRole),
});

export type UpdateMemberInfoData = z.infer<typeof UpdateMemberInfoDataSchema>;
