import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';

import {
  CreateWebTestStepData,
  WebTestStepOptionsDto,
} from '@customTypes/testStep/type';

import {
  findKeyboardEventNameFromServerName,
  keyboardEventList,
  KeyboardEventServerName,
} from '@utils/static/keyboardEventList';

import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

type ValueKeys =
  | keyof Pick<CreateWebTestStepData, 'value'>
  | `optionsDto.${keyof Pick<
      WebTestStepOptionsDto,
      'passActionValue' | 'failActionValue'
    >}`;

type Props = {
  value?: ValueKeys;
  rules?: Omit<
    RegisterOptions<CreateWebTestStepData, ValueKeys>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
};

const KeyboardEventListbox = ({
  value = 'value',
  rules = { required: 'Keyboard Event를 선택해주세요.' },
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CreateWebTestStepData>();

  return (
    <ErrorBoundaryWrapper>
      <Controller
        control={control}
        name={value}
        rules={rules}
        render={({ field }) => (
          <ListBoxHeadlessInController
            value={field.value}
            buttonValue={
              findKeyboardEventNameFromServerName(
                field.value as KeyboardEventServerName,
              ) || 'Select Keyboard Event'
            }
            onChange={field.onChange}
            lists={keyboardEventList}
            valueToSave={'serverName'}
            valueToShow={'name'}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={value}
        render={({ message }) => (
          <p className="error-message !mt-2">{message}</p>
        )}
      />
    </ErrorBoundaryWrapper>
  );
};

export default KeyboardEventListbox;
