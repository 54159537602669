import React from 'react';
import {
  ErrorBoundaryWrapper,
  ScenarioListDetailData,
  useCheckboxWithDnd,
} from '@autosquare/common';
import SelectionHeaderWithCheckbox from '@components/shared/SelectionHeaderWithCheckbox';
import Paste from './Paste';

type Props = {
  copyTestScenarios: ScenarioListDetailData[];
  setCopyTestScenarios: React.Dispatch<
    React.SetStateAction<ScenarioListDetailData[]>
  >;
};

const SchedulerScenarioTestCaseListBox = ({
  copyTestScenarios,
  setCopyTestScenarios,
}: Props) => {
  const {
    selectAllArray,
    selectArray,
    selectedArray,
    setSelectedArray,
    resetSelectedArray,
  } = useCheckboxWithDnd(copyTestScenarios, 'idx', 'dndIndex');
  return (
    <ErrorBoundaryWrapper>
      {copyTestScenarios.length > 0 && (
        <SelectionHeaderWithCheckbox
          selectAllArray={selectAllArray}
          selectedArray={selectedArray}
          total={copyTestScenarios.length}
          hasDeleteOption
          setCopyTestCases={setCopyTestScenarios}
          resetSelectedArray={resetSelectedArray}
        />
      )}
      <Paste
        copyTestScenarios={copyTestScenarios}
        setCopyTestScenarios={setCopyTestScenarios}
        selectArray={selectArray}
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
      />
    </ErrorBoundaryWrapper>
  );
};

export default SchedulerScenarioTestCaseListBox;
