import React from 'react';
import { RepeatResultsData } from '@customTypes/ide/speedTest/Mobile/testResult/type';

import SpeedRepeatResultTableRow from './SpeedRepeatResultTable/SpeedRepeatResultTableRow';
import SpeedRepeatResultTableHeader from './SpeedRepeatResultTable/SpeedRepeatResultTableHeader';
import VideoAndLogTabPanel from './SpeedRepeatResultTable/VideoAndLogTabPanel';
import clsx from 'clsx';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Props = {
  platformTypeName: string;
  repeatResults: RepeatResultsData[];
  logPath: string;
  chartIndex: number;
};

const SpeedRepeatResultTable = ({
  platformTypeName,
  repeatResults,
  logPath,
  chartIndex,
}: Props) => {
  const headers = [
    'Repeat',
    'DateTime',
    ...(platformTypeName === 'Web' ? ['DomTime'] : []),
    'LoadTime(s)',
  ];

  const videoPath = repeatResults.map((item) => item.videoPath);

  return (
    <ErrorBoundaryWrapper>
      <div
        className={clsx(
          'grid gap-x-8',
          videoPath[0] === null && logPath === null
            ? 'grid-cols-1'
            : 'grid-cols-2',
        )}
      >
        <div
          className={clsx(
            videoPath[0] === null && logPath === null
              ? 'min-w-full'
              : 'min-w-1/2',
          )}
        >
          <SpeedRepeatResultTableHeader headers={headers} />
          {repeatResults?.map((result) => (
            <SpeedRepeatResultTableRow
              key={result.repeat}
              result={result}
              isWeb={platformTypeName === 'Web'}
            />
          ))}
        </div>
        <div
          className={clsx(
            'min-w-1/2',
            videoPath[0] === null && logPath === null && 'hidden',
          )}
        >
          <VideoAndLogTabPanel
            repeatResults={repeatResults}
            chartIndex={chartIndex}
            logPath={logPath}
          />
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default SpeedRepeatResultTable;
