import {
  SectionTitleContainer,
  SectionTitleHeader,
  SectionTitleHeaderContainer,
  SectionTitleHeaderDescription,
  TooltipTop,
} from '@autosquare/common';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';

const SelectDeviceHeader = () => {
  return (
    <SectionTitleContainer>
      <SectionTitleHeaderContainer>
        <div className="flex items-center gap-2">
          <SectionTitleHeader>Select Device</SectionTitleHeader>
          <div className="group relative">
            <QuestionMarkCircleIcon
              strokeWidth={2}
              className={'size-5 cursor-help text-red-500'}
            />
            <TooltipTop positionClass="invisible group-hover:visible bottom-8 -left-28">
              선택한 패키지가 디바이스에 설치된 경우에만 해당 앱의 버전 정보가
              표시됩니다.
            </TooltipTop>
          </div>
        </div>
        <SectionTitleHeaderDescription>
          설치가 필요한 디바이스를 선택해 주세요.
        </SectionTitleHeaderDescription>
      </SectionTitleHeaderContainer>
    </SectionTitleContainer>
  );
};

export default SelectDeviceHeader;
