import { ErrorMessage, ListSkeleton } from '@autosquare/common';

import React from 'react';

import { useMobileTestCaseExecutionDetailQuery } from '@lib/api/ide/mobile/mobileTestResult';
import { useWebTestCaseExecutionDetailQuery } from '@lib/api/ide/web/webTestResult';

import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import MediaAndLogHandler from './TestStepResult/MediaAndLogHandler';
import TestStepResultDetail from './TestStepResult/TestStepResultDetail';
import {
  TestResultLabel,
  TestResultStatus,
} from '@customTypes/testResult/type';

type Props = {
  caseResultIdx: number;
  videoPath: string;
  logPath: string;
  imagePaths: string[];
  result: TestResultStatus | TestResultLabel;
};

const TestStepResult = ({
  caseResultIdx,
  imagePaths,
  videoPath,
  logPath,
  result,
}: Props) => {
  const testResultDetailInquiryByCaseQuery = isWordInLocationPathname('mobile')
    ? useMobileTestCaseExecutionDetailQuery(caseResultIdx)
    : useWebTestCaseExecutionDetailQuery(caseResultIdx);
  const testResultDetailInquiryByCase = testResultDetailInquiryByCaseQuery.data;

  return (
    <>
      <div className="grid grid-cols-2 pb-10">
        {testResultDetailInquiryByCaseQuery.isLoading ||
        testResultDetailInquiryByCaseQuery.isFetching ? (
          <ListSkeleton />
        ) : testResultDetailInquiryByCaseQuery.isError ? (
          <ErrorMessage>
            {testResultDetailInquiryByCaseQuery.error.message}
          </ErrorMessage>
        ) : (
          <>
            <TestStepResultDetail
              testResultDetailInquiryByCase={testResultDetailInquiryByCase}
            />
            {result !== TestResultLabel.NA && (
              <MediaAndLogHandler
                imagePaths={imagePaths}
                videoPath={videoPath}
                logPath={logPath}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TestStepResult;
