import { ErrorBoundaryWrapper } from '@autosquare/common';
import ExportOptionsTestResultUI from '@components/ExportOptionsDialog/components/ExportOptionsTestResultUI';
import { TestResultDetails } from '@customTypes/testResult/type';
import { useExportOptionsTestResult } from '@hooks/index';
import React from 'react';
import ExportOptionsTestStepResult from './ExportOptionsTestResult/ExportOptionsTestStepResult';

type Props = {
  resultDetail: TestResultDetails;
};

const ExportOptionsTestResult = ({ resultDetail }: Props) => {
  const { isPanelOpen, setIsPanelOpen, handleParentChange, currentItem } =
    useExportOptionsTestResult(resultDetail);

  return (
    <ErrorBoundaryWrapper>
      <ExportOptionsTestResultUI
        isPanelOpen={isPanelOpen}
        setIsPanelOpen={setIsPanelOpen}
        resultDetail={resultDetail}
        currentItem={currentItem}
        handleParentChange={handleParentChange}
      >
        <ExportOptionsTestStepResult
          caseResultIdx={resultDetail.idx}
          isPanelOpen={isPanelOpen}
        />
      </ExportOptionsTestResultUI>
    </ErrorBoundaryWrapper>
  );
};

export default ExportOptionsTestResult;
