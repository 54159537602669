import { ErrorBoundaryWrapper } from '@autosquare/common';
import React from 'react';
import { useProjectMemberDetailQuery } from '@lib/api/dashboard/queryHooks';
import MemberInformationDescription from './MemberInformation/MemberInformationDescription';

const MemberInformation = () => {
  const { data: projectMemberDetail } = useProjectMemberDetailQuery();

  return (
    <ErrorBoundaryWrapper>
      <div className="mt-8">
        <div className="flex items-center justify-between">
          <h2 className="text-base font-semibold">Member Information</h2>
          {projectMemberDetail?.modifiedAt && (
            <p className="text-sm font-medium leading-tight text-gray-500">
              Updated at {projectMemberDetail.modifiedAt}
            </p>
          )}
        </div>
        <dl className="mt-8 border-y border-solid border-gray-300 pb-6 pt-2 text-sm font-medium">
          <MemberInformationDescription />
        </dl>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default MemberInformation;
