import { ArrowPath, Button, ErrorMessage } from '@autosquare/common';
import BrowserCard from '@components/TestExecution/shared/BrowserCard';
import BrowserCardSkeleton from '@components/TestExecution/shared/BrowserCardSkeleton';
import NoBrowserList from '@components/TestExecution/shared/NoBrowserList';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';
import { BrowserInfo } from '@customTypes/ide/browser/browser';
import { Mode } from '@customTypes/ide/remote/type';
import { useRemoteWebBrowserListQuery } from '@lib/api/ide/remote/remote';
import { useWebBrowserListQuery } from '@lib/api/ide/web/webBrowser';
import { onChangeWebExecutionInfosData } from '@store/ide/browser/selectedWebExecutionDataSlice';
import { getRemoteState } from '@utils/static/getConfiguration';

import clsx from 'clsx';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

type Props = {
  selectedBrowser: BrowserInfo[];
  setSelectedBrowser: React.Dispatch<React.SetStateAction<BrowserInfo[]>>;
  checkHandler: (
    e: React.ChangeEvent<HTMLInputElement>,
    value: BrowserInfo,
  ) => void;
  isSelectedBrowser: boolean;
  setIsSelectedBrowser: React.Dispatch<React.SetStateAction<boolean>>;
  divWidth: number;
};

const SelectBrowser = ({
  selectedBrowser,
  setSelectedBrowser,
  checkHandler,
  isSelectedBrowser,
  setIsSelectedBrowser,
  divWidth,
}: Props) => {
  const dispatch = useDispatch();

  const { configuration } = getRemoteState();
  const webBrowserListQuery =
    configuration === Mode.Standard
      ? useWebBrowserListQuery()
      : useRemoteWebBrowserListQuery();
  const webBrowserList = webBrowserListQuery.data;

  const [gridCols, setGridCols] = useState('grid-cols-3');

  useEffect(() => {
    if (divWidth > 1024) {
      setGridCols('grid-cols-4');
    } else {
      setGridCols('grid-cols-3');
    }
  }, [divWidth]);

  useEffect(() => {
    dispatch(onChangeWebExecutionInfosData({ infos: selectedBrowser[0] }));
  }, [selectedBrowser]);

  const resetList = () => {
    setSelectedBrowser([]);
    setIsSelectedBrowser(false);
    webBrowserListQuery.refetch();
  };

  return (
    <section>
      <div className="flex items-center justify-between">
        <div className="flex-center gap-4">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            Select Browser
          </h3>
          {isSelectedBrowser && selectedBrowser?.length === 0 && (
            <ErrorMessage width="max-w-full" margin="m-0">
              브라우저를 선택해주세요.
            </ErrorMessage>
          )}
        </div>
        <Button
          type="button"
          variant="black"
          buttonSize="sm"
          onClick={resetList}
        >
          <ArrowPath size={5} /> Refresh
        </Button>
      </div>
      {webBrowserListQuery.isLoading || webBrowserListQuery.isFetching ? (
        <div className={clsx('mt-3 grid', gridCols)}>
          <BrowserCardSkeleton />
        </div>
      ) : webBrowserListQuery.isError ? (
        <ErrorMessage>{webBrowserListQuery.error.message}</ErrorMessage>
      ) : webBrowserList?.length === 0 ? (
        <NoBrowserList />
      ) : (
        <ul role="list" className={clsx('mt-3 grid gap-6', gridCols)}>
          {webBrowserList?.map((webBrowser) => (
            <BrowserCard
              key={webBrowser.browserName}
              browser={webBrowser}
              selectedBrowser={selectedBrowser}
              checkHandler={checkHandler}
            />
          ))}
        </ul>
      )}
      <RemoteConnectionAlarmModal />
    </section>
  );
};

export default SelectBrowser;
