import {
  DescriptionListSkeleton,
  ErrorBoundaryWrapper,
  ErrorMessage,
} from '@autosquare/common';

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { RootState } from '@app/store';

import { useMobileTestStepQuery } from '@lib/api/ide/mobile/mobileTestStep';

import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import { useGetSpeedMobileTestStepDetail } from '@lib/api/ide/speedMobile/speedMobileTestStep';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { getTestStepDetailListValue } from '@utils/index';
import TestStepDetailEdit from './TestStepDetail/TestStepDetailEdit';
import TestStepDetailBasic from './TestStepDetail/TestStepDetailBasic';
import clsx from 'clsx';
import { RESET_DELAY_MS } from '@constants/index';

interface Props {
  isDialogOpened: boolean;
  setIsDialogOpened: React.Dispatch<React.SetStateAction<boolean>>;
  mobileTestStep: MobileTestStepData;
  mobileTestStepList: MobileTestStepData[];
}

const TestStepDetail = ({
  isDialogOpened,
  setIsDialogOpened,
  mobileTestStep,
  mobileTestStepList,
}: Props) => {
  const location = isWordInLocationPathname('ui-test')
    ? 'ui-test'
    : 'speed-test';

  const [isEdited, setIsEdited] = useState(false);
  const testStepIdx = useSelector(
    (state: RootState) => state.mobileTestStepDetail.testStepIdx,
  );

  const mobileTestStepQuery =
    location === 'ui-test'
      ? useMobileTestStepQuery(testStepIdx, {
          enabled: location === 'ui-test' && isDialogOpened,
        })
      : useGetSpeedMobileTestStepDetail(testStepIdx, {
          enabled: location === 'speed-test' && isDialogOpened,
        });

  const mobileTestStepData = mobileTestStepQuery.data as MobileTestStepData;

  const closeDialog = () => {
    setIsDialogOpened(false);
    const timer = setTimeout(() => {
      setIsEdited(false);
    }, RESET_DELAY_MS);
    return () => clearTimeout(timer);
  };

  const testStepDetailListValue = getTestStepDetailListValue(
    mobileTestStep,
    mobileTestStepList,
  );

  return (
    <ErrorBoundaryWrapper>
      <Dialog
        as="div"
        open={isDialogOpened}
        className="relative z-50"
        onClose={closeDialog}
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <DialogPanel
                transition
                className={clsx([
                  'pointer-events-auto w-screen max-w-[43.75rem]',
                  'transition duration-500 sm:duration-700',
                  'data-[enter]:translate-x-full',
                  'data-[leave]:translate-x-full',
                ])}
              >
                <div className="flex h-full flex-col overflow-y-auto bg-white shadow-xl scrollbar-thin">
                  <div className="bg-congress-blue px-4 py-6 sm:px-6">
                    <div className="flex items-center justify-between">
                      <DialogTitle className="text-base font-semibold leading-6 text-white">
                        Test Step Detail
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="bg-congress-blue rounded-md text-indigo-200 hover:text-white"
                          onClick={closeDialog}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="size-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {mobileTestStepQuery.isLoading ||
                  mobileTestStepQuery.isFetching ? (
                    <DescriptionListSkeleton
                      number={5}
                      isButtonSkeleton={true}
                    />
                  ) : mobileTestStepQuery.isError ? (
                    <ErrorMessage>
                      {mobileTestStepQuery.error.message}
                    </ErrorMessage>
                  ) : isEdited ? (
                    <TestStepDetailEdit
                      setIsEdited={setIsEdited}
                      mobileTestStepData={mobileTestStepData}
                      isEdited={isEdited}
                    />
                  ) : (
                    <TestStepDetailBasic
                      mobileTestStepData={mobileTestStepData}
                      setIsEdited={setIsEdited}
                      closeDialog={closeDialog}
                      testStepDetailListValue={testStepDetailListValue}
                    />
                  )}
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    </ErrorBoundaryWrapper>
  );
};

export default TestStepDetail;
