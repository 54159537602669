import {
  ErrorBoundaryWrapper,
  TableBodyContainer,
  useCheckbox,
} from '@autosquare/common';
import React, { useEffect, useState } from 'react';
import SelectDeviceTableHeader from './SelectDeviceTable/SelectDeviceTableHeader';
import { useEnabledInstallDeviceListQuery } from '@lib/api/dashboard/queryHooks';
import { useFormContext } from 'react-hook-form';
import { InstallAppRequest } from '@customTypes/index';
import SelectDeviceTableBody from './SelectDeviceTable/SelectDeviceTableBody';

const SelectDeviceTable = () => {
  const [headerLength, setHeaderLength] = useState(0);

  const { watch, setValue } = useFormContext<InstallAppRequest>();

  const packageName = watch('packageName');
  const osType = watch('osType');

  const enabledInstallDeviceListQuery = useEnabledInstallDeviceListQuery(
    packageName,
    osType,
    { enabled: !!packageName && !!osType },
  );

  const enabledInstallDeviceList = enabledInstallDeviceListQuery.data;

  // 디바이스 선택 가능한 디바이스만 필터링
  const filteredEnabledInstallDeviceList = enabledInstallDeviceList?.filter(
    (device) => device.enable,
  );

  const { selectAllArray, selectArray, selectedArray, resetSelectedArray } =
    useCheckbox(filteredEnabledInstallDeviceList, 'idx');

  useEffect(() => {
    setValue('deviceIdxList', selectedArray);
  }, [selectedArray, setValue]);

  // osType 또는 packageName 변경 시 선택된 디바이스 초기화
  useEffect(() => {
    if (enabledInstallDeviceListQuery.isFetching) {
      resetSelectedArray();
    }
  }, [enabledInstallDeviceListQuery.isFetching]);

  return (
    <ErrorBoundaryWrapper>
      <div className="overflow-x-auto">
        <table className="w-full divide-y divide-gray-200">
          <SelectDeviceTableHeader
            setHeaderLength={setHeaderLength}
            selectAllArray={selectAllArray}
            selectedArray={selectedArray}
            deviceListLength={filteredEnabledInstallDeviceList?.length}
          />
          <TableBodyContainer>
            <SelectDeviceTableBody
              headerLength={headerLength}
              enabledInstallDeviceListQuery={enabledInstallDeviceListQuery}
              selectArray={selectArray}
              selectedArray={selectedArray}
            />
          </TableBodyContainer>
        </table>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default SelectDeviceTable;
