import React from 'react';
import { DisclosurePanel } from '@headlessui/react';

import TestStepResultDetail from './TestStepResultDetail';
import MediaAndLogHandler from './MediaAndLogHandler';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import {
  ErrorMessage,
  ListSkeleton,
  ErrorBoundaryWrapper,
} from '@autosquare/common';
import { useWebTestCaseExecutionDetailQuery } from '@lib/api/dashboard/web/webTestResult';
import { useMobileTestCaseExecutionDetailQuery } from '@lib/api/dashboard/mobile/mobileTestResult';
import {
  TestResultLabel,
  TestResultStatus,
} from '@customTypes/testResult/type';

type Props = {
  caseResultIdx: number;
  videoPath: string;
  logPath: string;
  imagePaths: string[];
  result: TestResultStatus | TestResultLabel;
};

const TestStepResult = ({
  caseResultIdx,
  imagePaths,
  videoPath,
  logPath,
  result,
}: Props) => {
  const testStepResultQuery = isWordInLocationPathname('mobile')
    ? useMobileTestCaseExecutionDetailQuery(caseResultIdx)
    : useWebTestCaseExecutionDetailQuery(caseResultIdx);

  return (
    <ErrorBoundaryWrapper>
      <DisclosurePanel>
        <div className="grid grid-cols-2 pb-10">
          {testStepResultQuery.isLoading || testStepResultQuery.isFetching ? (
            <ListSkeleton />
          ) : testStepResultQuery.isError ? (
            <ErrorMessage>{testStepResultQuery.error.message}</ErrorMessage>
          ) : (
            <ErrorBoundaryWrapper>
              <TestStepResultDetail testStepResult={testStepResultQuery.data} />
              {result !== TestResultLabel.NA && (
                <MediaAndLogHandler
                  imagePaths={imagePaths}
                  videoPath={videoPath}
                  logPath={logPath}
                />
              )}
            </ErrorBoundaryWrapper>
          )}
        </div>
      </DisclosurePanel>
    </ErrorBoundaryWrapper>
  );
};

export default TestStepResult;
