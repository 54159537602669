import { ErrorBoundaryWrapper } from '@autosquare/common';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ErrorMessage } from '@hookform/error-message';

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  keyName: 'value' | 'optionsDto.actionValue';
}

const WaitCommandHookForm = ({ keyName }: Props) => {
  const MIN = 3;
  const MAX = 180;

  const {
    register,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateTestStepData>();

  useEffect(() => {
    setValue(keyName, '60');
    setValue('optionsDto.waitingTime', '0');
    setValue('required', true);
    return () => {
      setValue(keyName, undefined);
      setValue('optionsDto.waitingTime', '3');
      clearErrors(keyName);
    };
  }, [setValue]);

  return (
    <ErrorBoundaryWrapper>
      <div className="grid grid-cols-4 items-start gap-4">
        <label
          htmlFor="waitValue"
          className="block pt-1 text-sm font-medium leading-6 text-gray-900"
        >
          Value(sec)
        </label>
        <div className="col-span-3">
          <input
            type="number"
            name="waitValue"
            id="waitValue"
            className="input-base"
            defaultValue={60}
            {...register(keyName, {
              required: 'Value 값을 최소 3초 이상 입력해 주세요.',
              min: {
                value: MIN,
                message: `${MIN} 이상 숫자만 입력 가능합니다.`,
              },
              max: {
                value: MAX,
                message: `${MAX} 이하 숫자만 입력 가능합니다.`,
              },
            })}
          />
        </div>
        <ErrorMessage
          errors={errors}
          name={keyName}
          render={({ message }) => (
            <p className="error-message col-span-4 -mt-2">{message}</p>
          )}
        />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default WaitCommandHookForm;
