import React, { useEffect, useState } from 'react';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import { CreateSchedulerData } from '@customTypes/ide/scheduler/scheduler';
import { useSchedulerMessengerAlarm } from '@hooks/scheduler/useSchedulerMessengerAlarm';
import { useFormContext } from 'react-hook-form';
import SelectedRecipientCardList from '@components/Scheduler/SelectedRecipientCardList';
import { MessengerAlarmHeadlessListbox } from '@components/Scheduler/MessengerAlarmHeadlessListbox';
import { RecipientData } from '@customTypes/ide/speedScheduler/speedScheduler';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const CreateSchedulerMessenger = () => {
  const { setValue, watch } = useFormContext<CreateSchedulerData>();
  const messengerReceiver = watch('messengerReceivers');
  const [inputValue, setInputValue] = useState<RecipientData>({
    recipientId: '',
    recipientInfo: '',
  });
  const {
    deleteTagItem,
    filteredList,
    platformName,
    filteredMessengerReceivers,
  } = useSchedulerMessengerAlarm(messengerReceiver, setValue);

  useEffect(() => {
    const savedRecipient = messengerReceiver[0]?.recipientList ?? '';
    const addedRecipient = `${inputValue?.recipientId}${inputValue?.recipientInfo ? '/' : ''}${inputValue?.recipientInfo ?? ''}`;
    const updatedRecipient = savedRecipient
      ? `${savedRecipient},${addedRecipient}`
      : addedRecipient;

    setValue('messengerReceivers', [
      {
        platformName: platformName,
        recipientList: updatedRecipient,
      },
    ]);
  }, [inputValue, setValue]);

  // 최초 렌더링 시 messengerReceivers 빈 배열로 설정
  useEffect(() => {
    setValue('messengerReceivers', []);
  }, [setValue]);

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer title="Messenger Alarm" isTitleCenter={true}>
        <MessengerAlarmHeadlessListbox
          list={filteredList}
          setState={setInputValue}
          state={inputValue}
        />
        <SelectedRecipientCardList
          messengerReceiver={filteredMessengerReceivers}
          deleteTagItem={deleteTagItem}
        />
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default CreateSchedulerMessenger;
