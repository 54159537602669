import { useCheckboxWithDnd, useInput } from '@autosquare/common';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@app/store';
import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';
import { useWebTestCaseListQuery } from '@lib/api/ide/web/webTestCase';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import DetailListItemCard from './DetailDraggable/DetailListItemCard';
import {
  TestCaseDetailData,
  TestCaseDetailPickTitleAndIdx,
} from '@customTypes/testCase/type';
import { UpdateTestScenarioData } from '@customTypes/testScenario/type';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import SelectionHeaderWithCheckbox from '@components/shared/SelectionHeaderWithCheckbox';
import TransferItemButton from '@components/shared/TransferItemButton';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import DetailScenarioTestCaseListBox from './DetailDraggable/DetailScenarioTestCaseListBox';
import ScenarioCardListSkeleton from '@components/Skeleton/ScenarioCardListSkeleton';

const DetailDraggable = () => {
  const {
    formState: { errors },
  } = useFormContext<UpdateTestScenarioData>();

  const [copyTestCases, setCopyTestCases] = useState<
    TestCaseDetailPickTitleAndIdx[] | null
  >([]);
  const [testCases, setTestCases] = useState<
    TestCaseDetailPickTitleAndIdx[] | null
  >([]);

  const {
    data: allTestCases,
    isError,
    error,
    isLoading,
    isFetching,
  } = isWordInLocationPathname('mobile')
    ? useMobileTestCaseListQuery()
    : useWebTestCaseListQuery();

  const testCaseIndexArrayInDetail = useSelector(
    (state: RootState) => state.numberArray.testCaseIndexArrayInDetail,
  );

  const stepArrayLists = testCaseIndexArrayInDetail?.map((step) =>
    (allTestCases as Partial<TestCaseDetailData[]>)?.find(
      (allTestCase) => allTestCase.idx === step,
    ),
  );

  useEffect(() => {
    setCopyTestCases(
      stepArrayLists?.map((stepArrayList, index) => ({
        ...stepArrayList,
        dndIndex: index + 1,
      })),
    );
    setTestCases(
      allTestCases?.map((allTestCase, index) => ({
        ...allTestCase,
        dndIndex: stepArrayLists?.length + index + 1,
      })),
    );
  }, [allTestCases, testCaseIndexArrayInDetail]);

  const [searchWord, onChangeSearchWord, setSearchWord] = useInput('');

  const searchResultArray = testCases?.filter((testCase) =>
    testCase.title.toLowerCase().includes(searchWord.toLowerCase()),
  );

  const { selectAllArray, selectedArray, selectArray, resetSelectedArray } =
    useCheckboxWithDnd(testCases, 'idx', 'dndIndex');
  const copyAndDropList = [
    {
      title: 'All Test Case List',
      list:
        testCases?.length === 0 ? (
          <div className="flex items-center justify-center p-3">
            추가 가능한 테스트 케이스가 존재하지 않습니다.
          </div>
        ) : (
          <div className="h-[calc(100vh-21rem)]">
            <SelectionHeaderWithCheckbox
              selectAllArray={selectAllArray}
              selectedArray={selectedArray}
              total={testCases?.length}
            />
            <div className="gray-scrollbar h-[calc(100vh-28rem)] overflow-y-scroll">
              {searchResultArray?.map((testCase) => (
                <DetailListItemCard
                  key={testCase.idx}
                  testCase={testCase}
                  selectArray={selectArray}
                  selectedArray={selectedArray}
                />
              ))}
            </div>
          </div>
        ),
    },
    {
      title: 'add button',
      list: (
        <TransferItemButton
          items={testCases}
          copyItems={copyTestCases}
          setCopyItems={setCopyTestCases}
          selectedArray={selectedArray}
          resetSelectedArray={resetSelectedArray}
        />
      ),
    },
    {
      title: 'Scenario Test Case List',
      list: (
        <DetailScenarioTestCaseListBox
          total={copyTestCases?.length}
          copyTestCases={copyTestCases}
          setCopyTestCases={setCopyTestCases}
        />
      ),
    },
  ];

  if (isLoading || isFetching) {
    return <ScenarioCardListSkeleton />;
  }

  if (isError) {
    return (
      <div className="text-error-message my-4 w-full whitespace-pre-wrap text-center text-sm">
        {error.message}
      </div>
    );
  }

  return (
    <div className="flex w-full items-center gap-4">
      {copyAndDropList?.map((item, index) => (
        <div className={clsx(index !== 1 && 'w-full')} key={item.title}>
          <div className="mb-2 flex justify-between">
            <div className="flex gap-x-4">
              {index !== 1 && (
                <p className="font-medium leading-6">{item.title}</p>
              )}
              {index === 0 && (
                <ErrorMessage
                  name="testCaseIdxs"
                  errors={errors}
                  render={({ message }) => (
                    <p className="error-message mb-3">{message}</p>
                  )}
                />
              )}
            </div>
          </div>
          <div
            className={clsx(
              index !== 1 &&
                'h-[calc(100vh-21rem)] rounded-md border border-solid',
            )}
          >
            {index === 0 && (
              <div className="relative w-full px-2 pb-1.5 pt-2">
                <MagnifyingGlassIcon className="absolute left-4 top-4 size-4" />
                <input
                  className="input-sm px-8"
                  value={searchWord}
                  onChange={onChangeSearchWord}
                />
                <XMarkIcon
                  className="absolute right-4 top-4 size-4 cursor-pointer"
                  onClick={() => setSearchWord('')}
                />
              </div>
            )}
            {item.list}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DetailDraggable;
