import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';
import DefinitionList from '@components/shared/DefinitionList';
import {
  UpdateWebTestStepData,
  WebTestStepData,
} from '@customTypes/testStep/type';
import { ErrorMessage } from '@hookform/error-message';
import { useWebTestStepByCommandQuery } from '@lib/api/ide/web/webTestStep';
import {
  dataTypeList,
  findDataType,
  findWebTestStepDescriptionFromIdx,
} from '@utils/index';
import React, { useEffect } from 'react';
import {
  Controller,
  UseFieldArrayReplace,
  useFormContext,
} from 'react-hook-form';

type Props = {
  webTestStepData: WebTestStepData;
  replace: UseFieldArrayReplace<UpdateWebTestStepData>;
};

const EditConditionAndValue = ({ webTestStepData, replace }: Props) => {
  const {
    control,
    setValue,
    unregister,
    resetField,
    formState: { errors },
  } = useFormContext<UpdateWebTestStepData>();

  const { data: webSaveTextStepList } = useWebTestStepByCommandQuery();

  const referenceValue = webTestStepData.optionsDto.compareIdx;
  useEffect(() => {
    setValue('optionsDto.compareIdx', referenceValue);
  }, [setValue]);

  return (
    <ErrorBoundaryWrapper>
      <DefinitionList
        term={'Condition*'}
        description={
          <Controller
            control={control}
            name={'optionsDto.dataType'}
            render={({ field }) => (
              <ListBoxHeadlessInController
                value={field.value}
                buttonValue={findDataType(field.value)}
                onChange={field.onChange}
                lists={dataTypeList}
                valueToShow={'name'}
                valueToSave={'serverName'}
              />
            )}
          />
        }
      />
      <DefinitionList
        term={'Reference Value*'}
        description={
          <ErrorBoundaryWrapper>
            <Controller
              control={control}
              name={'optionsDto.compareIdx'}
              rules={{
                validate: (value) =>
                  findWebTestStepDescriptionFromIdx(
                    Number(value),
                    webSaveTextStepList,
                  ) === undefined
                    ? '기준 값을 선택해 주세요.'
                    : true,
              }}
              render={({ field }) => (
                <ListBoxHeadlessInController
                  value={field.value}
                  buttonValue={
                    findWebTestStepDescriptionFromIdx(
                      Number(field.value),
                      webSaveTextStepList,
                    ) || 'None'
                  }
                  placeholder={
                    '기준 값을 Save Text 스텝 리스트에서 선택해 주세요.'
                  }
                  onChange={(value) => {
                    field.onChange(value);
                    if (
                      Number(value) !== Number(referenceValue) ||
                      referenceValue === undefined
                    ) {
                      unregister('optionsDto.operatorList');
                      replace({ inputValue: undefined, operator: undefined });
                    } else {
                      resetField('optionsDto.operatorList');
                      setValue(
                        'optionsDto.operatorList',
                        webTestStepData.optionsDto.operatorList,
                      );
                    }
                  }}
                  lists={webSaveTextStepList}
                  valueToSave={'idx'}
                  valueToShow={'description'}
                  savedType={'string'}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name={'optionsDto.compareIdx'}
              render={({ message }) => (
                <p className="standard-error-message">{message}</p>
              )}
            />
          </ErrorBoundaryWrapper>
        }
      />
    </ErrorBoundaryWrapper>
  );
};

export default EditConditionAndValue;
