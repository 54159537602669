import {
  ErrorBoundaryWrapper,
  ErrorMessage,
  PagingHeadings,
  TableSkeleton,
} from '@autosquare/common';
import {
  useAdminDeviceDetailQuery,
  useAllAdminProjectListQuery,
} from '@lib/api/dashboard/queryHooks';
import React from 'react';
import { useLocation } from 'react-router-dom';
import AdminDeviceDetailForm from './components/AdminDeviceDetailForm';
import AdminDeviceInformation from './components/AdminDeviceInformation';
import { useParentPath } from '@hooks/index';

const AdminDeviceDetail = () => {
  const { pathname, search } = useLocation();

  const adminDeviceDetailQuery = useAdminDeviceDetailQuery();
  const allAdminProjectListQuery = useAllAdminProjectListQuery();

  const adminDeviceListPage = useParentPath();
  const breadcrumbs = [
    { title: 'Administration', href: adminDeviceListPage },
    { title: 'Devices', href: adminDeviceListPage },
    { title: 'Detail', href: pathname + search },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings breadcrumbs={breadcrumbs} pageTitle={'Device Detail'} />
      <AdminDeviceInformation />
      {adminDeviceDetailQuery.isLoading ||
      adminDeviceDetailQuery.isFetching ||
      allAdminProjectListQuery.isLoading ? (
        <TableSkeleton />
      ) : adminDeviceDetailQuery.isError ? (
        <ErrorMessage>{adminDeviceDetailQuery.error.message}</ErrorMessage>
      ) : allAdminProjectListQuery.isError ? (
        <ErrorMessage>{allAdminProjectListQuery.error.message}</ErrorMessage>
      ) : (
        <AdminDeviceDetailForm adminDeviceListPage={adminDeviceListPage} />
      )}
    </ErrorBoundaryWrapper>
  );
};

export default AdminDeviceDetail;
