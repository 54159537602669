import { DialogButton } from '@components/Dialog';
import { DialogButtonPanels } from '@components/Dialog/DialogComponents';
import { CommonDialog } from '@components/Dialog';
import React from 'react';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { UseMutationResult } from '@tanstack/react-query';

type DeleteDataAutomationErrorDialogProps = {
  isDeleteErrorModalOpen: boolean;
  setIsDeleteErrorModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deleteAutomationMutation: UseMutationResult<
    string,
    ServerResponseErrorDetails,
    number,
    unknown
  >;
};

const DeleteDataAutomationErrorDialog = ({
  isDeleteErrorModalOpen,
  setIsDeleteErrorModalOpen,
  deleteAutomationMutation,
}: DeleteDataAutomationErrorDialogProps) => {
  return (
    <CommonDialog
      isOpen={isDeleteErrorModalOpen}
      setIsOpen={setIsDeleteErrorModalOpen}
      iconType="caution"
      title="Error"
      subTitle={
        deleteAutomationMutation.isError &&
        deleteAutomationMutation.error.message
      }
    >
      <DialogButtonPanels>
        <DialogButton
          type="button"
          variant="secondary"
          onClick={() => setIsDeleteErrorModalOpen(false)}
        >
          Cancel
        </DialogButton>
      </DialogButtonPanels>
    </CommonDialog>
  );
};

export default DeleteDataAutomationErrorDialog;
