import {
  Button,
  ErrorBoundaryWrapper,
  TableHeader,
  TableNoList,
} from '@autosquare/common';
import { AdminUserEmail } from '@customTypes/index';
import React, { useState } from 'react';
import ClipBoardAlert from '../ClipBoardAlert';
import AddSystemUsersTableBody from './AddSystemUsersTable/AddSystemUsersTableBody';
import { useInviteUserMutation } from '@lib/api/dashboard/queryHooks';

type Props = {
  userEmailList: AdminUserEmail[];
  setUserEmailList: React.Dispatch<React.SetStateAction<AdminUserEmail[]>>;
  isSendInvite: boolean;
  setIsSendInvite: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddSystemUsersTable = ({
  userEmailList,
  setUserEmailList,
  isSendInvite,
  setIsSendInvite,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCopyToClipBoard, setIsCopyToClipBoard] = useState(false);

  const deleteCurrentUserEmail = (email: string) => {
    setUserEmailList((prev) =>
      prev.filter((user) => user.inviteUserEmail !== email),
    );
  };

  const inviteUserMutation = useInviteUserMutation();

  const handleSendInvites = async () => {
    setIsLoading(true);

    const promises = userEmailList.map((user) =>
      inviteUserMutation.mutateAsync({
        inviteUserEmail: user.inviteUserEmail,
      }),
    );

    const results = await Promise.allSettled(promises);

    results.forEach((result, index) => {
      const userEmail = userEmailList[index].inviteUserEmail;
      if (result.status === 'fulfilled') {
        setUserEmailList((prev) =>
          prev.map((user) =>
            user.inviteUserEmail === userEmail
              ? { ...user, inviteLink: result.value.signupPath }
              : user,
          ),
        );
      } else if (result.status === 'rejected') {
        setUserEmailList((prev) =>
          prev.map((user) =>
            user.inviteUserEmail === userEmail
              ? { ...user, inviteError: result.reason.message }
              : user,
          ),
        );
      }
    });

    setIsLoading(false);
    setIsSendInvite(true);
  };

  const refreshButton = () => {
    setUserEmailList([]);
    setIsSendInvite(false);
  };

  const tableHeaderList = [
    { idx: 1, title: 'No' },
    { idx: 2, title: 'Email' },
    { idx: 3, title: isSendInvite ? 'URL' : '' },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div>
        <div className="gray-scrollbar max-h-[30rem]">
          <table className="w-full">
            <thead>
              <tr>
                {tableHeaderList.map((header) => (
                  <TableHeader key={header.idx}>{header.title}</TableHeader>
                ))}
              </tr>
            </thead>
            <tbody>
              {userEmailList.length === 0 ? (
                <TableNoList colSpan={tableHeaderList.length}>
                  초대하고자 하는 사용자의 이메일 주소를 입력 후 Send Invite
                  버튼을 클릭하세요.
                </TableNoList>
              ) : (
                userEmailList.map((email, idx) => (
                  <AddSystemUsersTableBody
                    key={email.inviteUserEmail}
                    email={email}
                    idx={idx}
                    setIsCopyToClipBoard={setIsCopyToClipBoard}
                    isSendInvite={isSendInvite}
                    deleteCurrentUserEmail={deleteCurrentUserEmail}
                  />
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex-center py-6">
          {isSendInvite ? (
            <Button type="button" onClick={refreshButton}>
              Refresh
            </Button>
          ) : (
            <Button
              type="button"
              onClick={handleSendInvites}
              disabled={isLoading || userEmailList.length === 0}
            >
              Send invite
            </Button>
          )}
        </div>
      </div>
      {isCopyToClipBoard && <ClipBoardAlert />}
    </ErrorBoundaryWrapper>
  );
};

export default AddSystemUsersTable;
