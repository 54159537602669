import { ErrorMessage, TableNoList, TableSkeleton } from '@autosquare/common';
import { useInstalledAppVersionListQuery } from '@lib/api/dashboard/queryHooks';
import React from 'react';
import InstalledAppTableBodyRow from './InstalledAppTableBodyRow';

type Props = {
  headerLength: number;
};

const InstalledAppTableBody = ({ headerLength }: Props) => {
  const installedAppVersionListQuery = useInstalledAppVersionListQuery();
  const installedAppVersionList = installedAppVersionListQuery.data;

  if (installedAppVersionListQuery.isLoading) {
    return (
      <TableNoList colSpan={headerLength}>
        <TableSkeleton />
      </TableNoList>
    );
  }

  if (installedAppVersionListQuery.isError) {
    return (
      <TableNoList colSpan={headerLength}>
        <ErrorMessage>
          {installedAppVersionListQuery.error.message}
        </ErrorMessage>
      </TableNoList>
    );
  }

  if (installedAppVersionList.appList.length === 0) {
    return (
      <TableNoList colSpan={headerLength}>
        등록된 애플리케이션이 존재하지 않습니다.
      </TableNoList>
    );
  }

  return installedAppVersionList.appList.map((installedAppVersion, idx) => (
    <InstalledAppTableBodyRow
      key={installedAppVersion.packageName}
      installedAppVersion={installedAppVersion}
      idx={idx}
    />
  ));
};

export default InstalledAppTableBody;
