import React from 'react';
import TestStepDetailBasicDefinition from '@components/shared/TestStepDetail/TestStepDetailBasicDefinition';
import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { findDataTypeFromServerName } from '@customTypes/ide/mobileTestStep/dataValidation/dataValidation';
import { findDescriptionFromIdx } from '@utils/static/findDescriptionFromIdx';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { useMobileTestStepBySaveTextQuery } from '@lib/api/ide/mobile/mobileTestStep';
import { useSpeedMobileTestStepBySaveTextQuery } from '@lib/api/ide/speedMobile/speedMobileTestStep';
import { findOperatorLabel } from '@utils/index';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Props = {
  mobileTestStepData: MobileTestStepData;
};

const BasicDataValidation = ({ mobileTestStepData }: Props) => {
  const uiLocation = isWordInLocationPathname('ui-test');
  const mobileTestStepBySaveTextQuery = uiLocation
    ? useMobileTestStepBySaveTextQuery()
    : useSpeedMobileTestStepBySaveTextQuery();

  const referenceValueList =
    mobileTestStepBySaveTextQuery.data as MobileTestStepData[];

  const inputDataDescription = mobileTestStepData?.optionsDto.operatorList
    .map((item) => `${findOperatorLabel(item.operator)}${item.inputValue}`)
    .join(`\n`);
  return (
    <ErrorBoundaryWrapper>
      <TestStepDetailBasicDefinition
        term={'Condition'}
        description={findDataTypeFromServerName(
          mobileTestStepData?.optionsDto.dataType,
        )}
      />
      <TestStepDetailBasicDefinition
        term={'Reference Value'}
        description={
          findDescriptionFromIdx(
            Number(mobileTestStepData?.optionsDto.compareIdx),
            referenceValueList,
          ) || 'None'
        }
      />
      <TestStepDetailBasicDefinition
        term={'Input Data'}
        description={inputDataDescription}
      />
    </ErrorBoundaryWrapper>
  );
};

export default BasicDataValidation;
