import React from 'react';
import { Link, createSearchParams } from 'react-router-dom';

import {
  ErrorBoundaryWrapper,
  TableBodyBasic,
  TableHeader,
  TableRow,
} from '@autosquare/common';
import { ProjectMemberList } from '@customTypes/dashboard/project/type';

type Props = {
  members: ProjectMemberList[];
};

const ProjectMembersTable = ({ members }: Props) => {
  const sortedMembers = members?.toSorted(
    (a, b) => a.systemRole - b.systemRole,
  );

  const titles = ['No', 'Name', 'Email', 'Role', 'Last Seen', 'Detail'];

  return (
    <ErrorBoundaryWrapper>
      <div className="px-0">
        <div className="mt-8 flow-root">
          <div className="-my-2x -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    {titles?.map((title) => (
                      <TableHeader key={title}>{title}</TableHeader>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {sortedMembers?.map((member, idx) => (
                    <TableRow key={member.idx}>
                      <TableBodyBasic>{idx + 1}</TableBodyBasic>
                      <TableBodyBasic>
                        <div className="flex items-center gap-4">
                          {member.name}
                          {member.systemRole === 0 && (
                            <div className="role-label admin">System Admin</div>
                          )}
                        </div>
                      </TableBodyBasic>
                      <TableBodyBasic>{member.userId}</TableBodyBasic>
                      <TableBodyBasic>{member?.projectRole}</TableBodyBasic>
                      <TableBodyBasic>{member?.lastSeen}</TableBodyBasic>
                      <TableBodyBasic>
                        <button
                          type="button"
                          className="rounded-md shadow-sm hover:text-congress-blue"
                        >
                          <Link
                            to={{
                              pathname: `detail`,
                              search: createSearchParams({
                                idx: member.idx.toString(),
                              }).toString(),
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                              />
                            </svg>
                          </Link>
                        </button>
                      </TableBodyBasic>
                    </TableRow>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default ProjectMembersTable;
