import { useInput } from '@autosquare/common';
import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useMemo,
} from 'react';

interface TestExecutionProviderContext {
  isModalOpened: boolean;
  setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: () => void;
  closeModal: () => void;
  shouldSaveVideo: boolean;
  handleVideoChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setShouldSaveVideo: React.Dispatch<React.SetStateAction<boolean>>;
  shouldSaveScreenshot: boolean;
  handleScreenshotChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setShouldSaveScreenshot: React.Dispatch<React.SetStateAction<boolean>>;
  shouldSaveLogFile: boolean;
  handleLogFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setShouldSaveLogFile: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TestExecutionContext = createContext<TestExecutionProviderContext>(
  {
    isModalOpened: false,
    setIsModalOpened: () => {},
    openModal: () => {},
    closeModal: () => {},
    shouldSaveVideo: true,
    handleVideoChange: () => {},
    setShouldSaveVideo: () => {},
    shouldSaveScreenshot: true,
    handleScreenshotChange: () => {},
    setShouldSaveScreenshot: () => {},
    shouldSaveLogFile: true,
    handleLogFileChange: () => {},
    setShouldSaveLogFile: () => {},
  },
);

export const useTestExecutionContext = () => {
  return useContext(TestExecutionContext);
};

interface Props {
  children: ReactNode;
}

const TestExecutionProvider = ({ children }: Props) => {
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [shouldSaveVideo, handleVideoChange, setShouldSaveVideo] =
    useInput<boolean>(true);
  const [
    shouldSaveScreenshot,
    handleScreenshotChange,
    setShouldSaveScreenshot,
  ] = useInput<boolean>(true);
  const [shouldSaveLogFile, handleLogFileChange, setShouldSaveLogFile] =
    useInput<boolean>(true);

  const openModal = () => {
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const contextValue = useMemo(
    () => ({
      isModalOpened,
      setIsModalOpened,
      openModal,
      closeModal,
      shouldSaveVideo,
      handleVideoChange,
      setShouldSaveVideo,
      shouldSaveScreenshot,
      handleScreenshotChange,
      setShouldSaveScreenshot,
      shouldSaveLogFile,
      handleLogFileChange,
      setShouldSaveLogFile,
    }),
    [isModalOpened, shouldSaveVideo, shouldSaveScreenshot, shouldSaveLogFile],
  );

  return (
    <TestExecutionContext.Provider value={contextValue}>
      {children}
    </TestExecutionContext.Provider>
  );
};

export default TestExecutionProvider;
