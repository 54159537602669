import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Bars3Icon } from '@heroicons/react/24/outline';

import { RootState } from '@app/store';

import AdminSidebarUnderMonitorSize from './AdminSidebarUnderMonitorSize';
import AdminSidebarMonitorSize from './AdminSidebarMonitorSize';

import { decodeJwtToken, ErrorBoundaryWrapper } from '@autosquare/common';

import { SidebarList } from '@customTypes/type';

type Props = {
  isToggledSidebar: boolean;
  setIsToggledSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

const Administration = ({ isToggledSidebar, setIsToggledSidebar }: Props) => {
  const navigate = useNavigate();

  const accessToken = useSelector(
    (state: RootState) => state.accessTokenValue.accessTokenValue,
  );
  const checkAdminRole = decodeJwtToken(accessToken)?.auth;

  useEffect(() => {
    !!checkAdminRole &&
      checkAdminRole !== 'ROLE_ADMIN' &&
      navigate('/not-admin');
  }, [checkAdminRole]);

  const sidebarLists: SidebarList[] = [
    {
      name: 'Users',
      location: 'users',
      children: [
        {
          name: 'User List',
          href: '/admin/users',
          hrefBasic: '/admin/users',
        },
      ],
    },
    {
      name: 'Devices',
      location: 'devices',
      children: [
        {
          name: 'Device List',
          href: '/admin/devices',
          hrefBasic: '/admin/devices',
        },
      ],
    },
    {
      name: 'Projects',
      location: 'project',
      children: [
        {
          name: 'Project List',
          href: '/admin/projects',
          hrefBasic: '/admin/projects',
        },
      ],
    },
    {
      name: 'Settings',
      location: 'settings',
      children: [
        {
          name: 'Data Storage',
          href: '/admin/settings/data-storage',
          hrefBasic: '/admin/settings/data-storage',
        },
        {
          name: 'WebDriver',
          href: '/admin/settings/webdriver',
          hrefBasic: '/admin/settings/webdriver',
        },
      ],
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      {!isToggledSidebar && (
        <div className="flex items-center border-b px-6 py-4 lg:hidden">
          <button
            type="button"
            className="mr-6 "
            onClick={() => setIsToggledSidebar(!isToggledSidebar)}
          >
            <Bars3Icon className="block size-6" aria-hidden="true" />
          </button>
          <div>Menu</div>
        </div>
      )}
      <AdminSidebarMonitorSize
        sidebarLists={sidebarLists}
        setIsToggledSidebar={setIsToggledSidebar}
      />
      {isToggledSidebar && (
        <AdminSidebarUnderMonitorSize
          sidebarLists={sidebarLists}
          setIsToggledSidebar={setIsToggledSidebar}
        />
      )}
    </ErrorBoundaryWrapper>
  );
};

export default Administration;
