import { IdeUserInfo } from '@autosquare/common';

import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { axiosInstance, axiosLocalInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';

import { Login } from '@customTypes/login/type';
import { useNavigate } from 'react-router-dom';

// GET
export const getUseInfo = async (): Promise<IdeUserInfo> => {
  const response = await axiosInstance.get<IdeUserInfo>(IDEAPI.GET_USER_INFO);
  return response.data;
};
export const useUserInfoQuery = (
  options?: UseQueryOptions<IdeUserInfo, Error>,
) =>
  useQuery<IdeUserInfo, Error>({
    queryKey: ['user', 'userInfo'],
    queryFn: getUseInfo,
    ...options,
  });

// POST
const postLogin = async (data: Login): Promise<Login> => {
  const response = await axiosInstance.post(IDEAPI.LOGIN, data);
  return response.data;
};
export const usePostLoginMutation = () => {
  const navigate = useNavigate();

  return useMutation<Login, Error, Login>({
    mutationFn: (data) => postLogin(data),
    onSuccess: () => {
      window.electron.send('startRemoteServer');
      navigate('/ide/projects/list');
    },
  });
};

const postUserSetting = async (data: IdeUserInfo): Promise<IdeUserInfo> => {
  const response = await axiosLocalInstance.post<IdeUserInfo>(
    IDEAPI.USER_SETTING,
    data,
  );
  return response.data;
};
export const usePostUserSettingMutation = () => {
  return useMutation<IdeUserInfo, Error, IdeUserInfo>({
    mutationFn: (data) => postUserSetting(data),
  });
};
