import React from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';

import NoChildrenSidebarList from './NoChildrenSidebarList';
import ProjectSidebarSubList from './ProjectSidebarSubList';

import { SidebarList } from '@customTypes/type';

type Props = {
  sidebarLists: SidebarList[];
  setIsToggledSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

const AdminSidebarUnderMonitorSize = ({
  sidebarLists,
  setIsToggledSidebar,
}: Props) => {
  return (
    <div className="inset-0 flex h-full w-72 grow flex-col overflow-y-auto bg-white px-6">
      <div className="flex h-16 shrink-0 items-center justify-between">
        <span className="text-sm font-semibold">Administration</span>
        <XMarkIcon
          className="block size-6 cursor-pointer"
          aria-hidden="true"
          onClick={() => setIsToggledSidebar(false)}
        />
      </div>
      <div className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {sidebarLists?.map((sidebarList) => (
                <li key={sidebarList.name}>
                  {!sidebarList.children ? (
                    <NoChildrenSidebarList
                      sidebarList={sidebarList}
                      setIsToggledSidebar={setIsToggledSidebar}
                    />
                  ) : (
                    <ProjectSidebarSubList sidebarList={sidebarList} />
                  )}
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdminSidebarUnderMonitorSize;
