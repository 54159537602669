import {
  ComboboxHeadlessInController,
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
  TooltipTop,
  useDeviceSize,
} from '@autosquare/common';

import { RootState } from '@app/store';

import {
  extractTextFormatTypeList,
  findExtractTextFormatTypeByServerName,
} from '@utils/static/mobileTestStep/extractTextFormat/extractTextFormatTypeList';

import { ExtractTextFormatTypeServerName } from '@customTypes/ide/mobileTestStep/extractTextFormat/extractTextFormat';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { SaveTextFrom } from '@customTypes/ide/mobileTestStep/SaveTextFrom';

import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

const ExtractTextFormatBody = () => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const showTooltip = () => setTooltipVisible(true);
  const hideTooltip = () => setTooltipVisible(false);

  const { width: deviceWidth } = useDeviceSize();

  const {
    control,
    watch,
    setValue,
    resetField,
    register,
    formState: { errors },
  } = useFormContext<CreateTestStepData>();

  const textExtract = watch('optionsDto.textExtract');
  const fromSaveText = watch('optionsDto.from');
  const textExtractOptionsLength = watch(
    'optionsDto.textExtractOptions.length',
  );

  const numbersArray = useSelector(
    (state: RootState) => state.pageSources.selectedNumberArray,
  );
  const filteredNumberArray = numbersArray?.filter(
    (number) => number.length === Number(textExtractOptionsLength),
  );

  const smsContents = useSelector(
    (state: RootState) => state.pageSources.smsContents,
  );
  const smsContentsArray = smsContents ? smsContents.split(', ') : [];
  const filteredSmsContentsArray = smsContentsArray?.filter(
    (smsNumber) => smsNumber.length === Number(textExtractOptionsLength),
  );

  useEffect(() => {
    setValue(
      'optionsDto.textExtractOptions.type',
      ExtractTextFormatTypeServerName.Number,
    );
    setValue('optionsDto.textExtractOptions.length', 6);
    setValue('optionsDto.textExtractOptions.index', '1');

    return () => {
      resetField('optionsDto.textExtractOptions');
    };
  }, [setValue, resetField]);

  return (
    <ErrorBoundaryWrapper>
      <div
        className={clsx(
          'grid grid-cols-2 items-center justify-between gap-4 text-sm font-medium leading-normal text-gray-900',
          textExtract === 'true' && 'border-t border-gray-200 pt-4',
        )}
      >
        <div className="col-span-1 flex items-center justify-between gap-4">
          <p>Type</p>
          <div className="w-full">
            <Controller
              control={control}
              name={'optionsDto.textExtractOptions.type'}
              render={({ field }) => (
                <ListBoxHeadlessInController
                  value={field.value}
                  buttonValue={findExtractTextFormatTypeByServerName(
                    field.value,
                  )}
                  onChange={field.onChange}
                  lists={extractTextFormatTypeList}
                  valueToSave={'serverName'}
                  valueToShow={'name'}
                />
              )}
            />
          </div>
        </div>
        <div className="col-span-1 flex items-center justify-center gap-4">
          <label>Length</label>
          <input
            type="number"
            className="input-base"
            defaultValue={6}
            {...register('optionsDto.textExtractOptions.length', {
              required: 'Extract Text Format: Length를 확인해주세요.',
              min: { value: 1, message: '1이상 입력 가능합니다.' },
            })}
          />
        </div>
        <ErrorMessage
          errors={errors}
          name="optionsDto.textExtractOptions.length"
          render={({ message }) => (
            <p className="error-message col-span-2">{message}</p>
          )}
        />
      </div>
      <div className="relative flex items-center justify-between gap-3 text-sm font-medium leading-normal text-gray-900">
        <p>Index</p>
        <div className="flex items-center">
          <QuestionMarkCircleIcon
            className="size-5 cursor-help text-red-500"
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
          />
        </div>
        <Controller
          control={control}
          name="optionsDto.textExtractOptions.index"
          rules={{ required: 'Extract Text Format: Index를 확인해주세요.' }}
          render={({ field }) => (
            <div className="w-full">
              <ComboboxHeadlessInController
                value={field.value || ''}
                onChange={field.onChange}
                filteredList={
                  fromSaveText === SaveTextFrom.Element
                    ? filteredNumberArray
                    : filteredSmsContentsArray
                }
                savedIndex={true}
              />
            </div>
          )}
        />
        {tooltipVisible && deviceWidth > 1700 ? (
          <TooltipTop positionClass={`left-0 bottom-9`}>
            객체에서 찾은 값이 여러개일 경우 몇 번째 값을 사용할지 입력해주세요.
          </TooltipTop>
        ) : (
          tooltipVisible &&
          deviceWidth <= 1700 && (
            <TooltipTop positionClass={`left-0 bottom-9`}>
              <p>객체에서 찾은 값이 여러개일 경우</p>
              <p>몇 번째 값을 사용할지 입력해 주세요.</p>
            </TooltipTop>
          )
        )}
      </div>
      <ErrorMessage
        errors={errors}
        name="optionsDto.textExtractOptions.index"
        render={({ message }) => (
          <p className="error-message -my-2">{message}</p>
        )}
      />
    </ErrorBoundaryWrapper>
  );
};

export default ExtractTextFormatBody;
