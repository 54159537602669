import {
  Checkbox,
  ErrorBoundaryWrapper,
  TableHeader,
  TableHeaderContainer,
} from '@autosquare/common';
import { ReservationDeviceInfo } from '@customTypes/index';
import { useIndeterminateCheckbox } from '@hooks/index';
import React, { useEffect } from 'react';

type Props = {
  availableDeviceList: ReservationDeviceInfo[];
  setHeaderLength: React.Dispatch<React.SetStateAction<number>>;
  selectedArray: number[];
  selectAllArray: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const AvailableDevicesTableHeader = ({
  availableDeviceList,
  setHeaderLength,
  selectedArray,
  selectAllArray,
}: Props) => {
  const inputRef = useIndeterminateCheckbox(
    selectedArray.length,
    availableDeviceList.length,
  );

  const tableHeaderListWithoutIdx = [
    { title: 'No' },
    { title: 'Model' },
    { title: 'Name' },
    { title: 'OS' },
    {
      title: (
        <div className="flex items-center justify-end">
          <Checkbox
            checked={selectedArray.length > 0}
            onChange={selectAllArray}
            ref={inputRef}
            disabled={availableDeviceList.length === 0}
          />
        </div>
      ),
    },
  ];

  const tableHeaderList = tableHeaderListWithoutIdx.map((header, idx) => ({
    ...header,
    idx: idx + 1,
  }));

  useEffect(() => {
    setHeaderLength(tableHeaderList.length);
  }, [tableHeaderList, setHeaderLength]);

  return (
    <ErrorBoundaryWrapper>
      <TableHeaderContainer>
        {tableHeaderList.map((header) => (
          <TableHeader key={header.idx}>{header.title}</TableHeader>
        ))}
      </TableHeaderContainer>
    </ErrorBoundaryWrapper>
  );
};

export default AvailableDevicesTableHeader;
