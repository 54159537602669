import { RootState } from '@app/store';
import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';
import {
  CommonDialog,
  DialogButton,
  DialogButtonPanels,
} from '@components/Dialog';
import {
  UpdateMemberInfoData,
  UpdateMemberInfoDataSchema,
  UserRole,
} from '@customTypes/index';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useProjectMemberDetailQuery,
  useUpdateMemberInfoMutation,
} from '@lib/api/dashboard/queryHooks';
import { setIsEdited } from '@store/index';
import { findMemberName, memberRole } from '@utils/static/memberRole';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

const MemberInformationDescriptionForm = () => {
  const { data: projectMemberDetail } = useProjectMemberDetailQuery();

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const dispatch = useDispatch();
  const isEdited = useSelector(
    (state: RootState) => state.projectMemberDetail.isEdited,
  );

  const { control, handleSubmit, resetField } = useForm<UpdateMemberInfoData>({
    resolver: zodResolver(UpdateMemberInfoDataSchema),
    defaultValues: {
      projectRole:
        projectMemberDetail?.projectRole === memberRole[1].name
          ? UserRole.Admin
          : projectMemberDetail?.projectRole,
    },
  });

  const updateMemberInfoMutation = useUpdateMemberInfoMutation();

  const onSubmit: SubmitHandler<UpdateMemberInfoData> = (data) => {
    updateMemberInfoMutation.mutate(data, {
      onSuccess: () => {
        dispatch(setIsEdited(false));
      },
      onError: () => {
        setIsErrorDialogOpen(true);
      },
    });
  };

  useEffect(() => {
    if (!isEdited) {
      resetField('projectRole');
    }
  }, [isEdited, resetField]);

  const descriptionList = [
    {
      term: 'Name',
      description:
        projectMemberDetail?.systemRole === 0 ? (
          <div className="flex items-center gap-6">
            <div>{projectMemberDetail?.name}</div>
            <div className="role-label admin">System Admin</div>
          </div>
        ) : (
          projectMemberDetail?.name
        ),
    },
    { term: 'Email', description: projectMemberDetail?.userId },
    { term: 'Job Title', description: projectMemberDetail?.jobTitle || '-' },
    {
      term: 'Organization',
      description: projectMemberDetail?.organization || '-',
    },
    { term: 'Department', description: projectMemberDetail?.department || '-' },
    {
      term: 'Role',
      description: isEdited ? (
        <Controller
          control={control}
          name="projectRole"
          render={({ field }) => (
            <ListBoxHeadlessInController
              value={field.value}
              buttonValue={findMemberName(field.value)}
              onChange={field.onChange}
              lists={memberRole}
              valueToSave={'serverName'}
              valueToShow={'name'}
            />
          )}
          disabled={!isEdited}
        />
      ) : (
        projectMemberDetail?.projectRole
      ),
    },
    { term: 'Last Seen', description: projectMemberDetail?.lastSeen || '-' },
  ];

  return (
    <ErrorBoundaryWrapper>
      <form onSubmit={handleSubmit(onSubmit)} id="edit-member-form" noValidate>
        {descriptionList.map((description) => (
          <div
            className="grid grid-cols-3 items-center gap-4 py-4"
            key={description.term}
          >
            <dt>{description.term}</dt>
            <dd className="col-span-2 sm:col-span-1">
              {description.description}
            </dd>
          </div>
        ))}
      </form>
      {/* 수정 에러 Dialog*/}
      <CommonDialog
        isOpen={isErrorDialogOpen}
        setIsOpen={setIsErrorDialogOpen}
        iconType={'caution'}
        title={'Failure'}
        subTitle={updateMemberInfoMutation.error?.message}
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant="secondary"
            onClick={() => setIsErrorDialogOpen(false)}
          >
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default MemberInformationDescriptionForm;
