import { Button, ErrorBoundaryWrapper } from '@autosquare/common';

import React, { useEffect, useState } from 'react';

import { WebCommandOptionName } from '@utils/static/webCommandOptions';
import { LuRefreshCcw } from 'react-icons/lu';
import clsx from 'clsx';

type Props = {
  handleSubmit: (e: React.FormEvent<HTMLElement>) => void;
  websiteUrl: string;
  onChangeWebsiteUrl: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isInspectorMode: boolean;
  setIsInspectorMode: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadedWebsite: boolean;
  changeInspectorMode: () => void;
};

const WebTestStepForm = ({
  handleSubmit,
  websiteUrl,
  onChangeWebsiteUrl,
  isInspectorMode,
  setIsInspectorMode,
  isLoadedWebsite,
  changeInspectorMode,
}: Props) => {
  const [command, setCommand] = useState<WebCommandOptionName>(
    WebCommandOptionName.Command,
  );
  const [isMaskingOptionsEnabled, setIsMaskingOptionsEnabled] = useState(false);

  const isScrollCommand = command === WebCommandOptionName.ScrollAndSwipe;

  useEffect(() => {
    window.electron.on<{
      command: WebCommandOptionName;
      isMaskingEnabled: boolean;
    }>('webCommandOption', (data) => {
      setCommand(data.command);
      setIsMaskingOptionsEnabled(data.isMaskingEnabled);
    });
  }, []);

  useEffect(() => {
    if (isScrollCommand && !isMaskingOptionsEnabled) {
      setIsInspectorMode(false);
    }
  }, [command, isMaskingOptionsEnabled]);

  const handleReload = () => {
    window.electron.send('reload-page');
    setIsInspectorMode(false);
  };

  const handleRightClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!isLoadedWebsite) return;
    window.electron.send('popup-menu');
  };

  const handleOpenStepList = () => {
    window.electron.send('openWebTestStepListWindow');
    setIsInspectorMode(false);
  };

  return (
    <ErrorBoundaryWrapper>
      <form className="relative flex flex-row gap-2" onSubmit={handleSubmit}>
        <div className="flex rounded-md ring-1 ring-inset ring-gray-300">
          <button
            type="button"
            className={clsx(
              'button-refresh',
              !isLoadedWebsite
                ? 'cursor-not-allowed text-gray-500'
                : 'cursor-pointer hover:rounded-md hover:bg-gray-50 hover:ring-1 hover:ring-inset hover:ring-gray-300 focus:z-10 focus:rounded-md focus:ring-2 focus:ring-inset focus:ring-indigo-600',
            )}
            onClick={handleReload}
            onContextMenu={handleRightClick}
            disabled={!isLoadedWebsite}
          >
            <LuRefreshCcw />
          </button>
        </div>
        <div className="w-full">
          <label htmlFor="url" className="sr-only">
            URL
          </label>
          <input
            type="text"
            name="url"
            id="url"
            className="block w-full rounded-md border-0 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
            placeholder="URL"
            onChange={onChangeWebsiteUrl}
            value={websiteUrl}
          />
        </div>
        <div>
          <Button type="submit" variant="primary">
            Send
          </Button>
        </div>
        <div>
          <button
            type="button"
            className={clsx(
              'whitespace-nowrap rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300',
              !isLoadedWebsite || (!isMaskingOptionsEnabled && isScrollCommand)
                ? 'cursor-not-allowed'
                : 'cursor-pointer',
              isInspectorMode
                ? 'bg-red-500 text-white'
                : 'bg-gray-200 text-gray-900',
            )}
            onClick={changeInspectorMode}
            disabled={!isMaskingOptionsEnabled && isScrollCommand}
          >
            <div className="flex space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="size-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10 1a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5A.75.75 0 0110 1zM5.05 3.05a.75.75 0 011.06 0l1.062 1.06A.75.75 0 116.11 5.173L5.05 4.11a.75.75 0 010-1.06zm9.9 0a.75.75 0 010 1.06l-1.06 1.062a.75.75 0 01-1.062-1.061l1.061-1.06a.75.75 0 011.06 0zM3 8a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5A.75.75 0 013 8zm11 0a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5A.75.75 0 0114 8zm-6.828 2.828a.75.75 0 010 1.061L6.11 12.95a.75.75 0 01-1.06-1.06l1.06-1.06a.75.75 0 011.06 0zm3.594-3.317a.75.75 0 00-1.37.364l-.492 6.861a.75.75 0 001.204.65l1.043-.799.985 3.678a.75.75 0 001.45-.388l-.978-3.646 1.292.204a.75.75 0 00.74-1.16l-3.874-5.764z"
                  clipRule="evenodd"
                />
              </svg>
              <p className="font-semibold">Inspector</p>
            </div>
          </button>
        </div>
        <div>
          <span
            className="isolate inline-flex rounded-md shadow-sm"
            onClick={handleOpenStepList}
          >
            <button
              type="button"
              className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
            >
              <div className="flex space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="size-5"
                >
                  <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                </svg>
              </div>
            </button>
            <button
              type="button"
              className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
            >
              <div className="flex space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="size-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M6 4.75A.75.75 0 016.75 4h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 4.75zM6 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 10zm0 5.25a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75a.75.75 0 01-.75-.75zM1.99 4.75a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1v-.01zM1.99 15.25a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1v-.01zM1.99 10a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1V10z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </button>
          </span>
        </div>
      </form>
    </ErrorBoundaryWrapper>
  );
};

export default WebTestStepForm;
