import {
  Checkbox,
  TableHeader,
  TableHeaderContainer,
} from '@autosquare/common';
import { useIndeterminateCheckbox } from '@hooks/index';
import React, { useEffect } from 'react';

type Props = {
  setHeaderLength: React.Dispatch<React.SetStateAction<number>>;
  selectAllArray: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedArray: number[];
  deviceListLength: number;
};

const SelectDeviceTableHeader = ({
  setHeaderLength,
  selectAllArray,
  selectedArray,
  deviceListLength,
}: Props) => {
  const checkboxRef = useIndeterminateCheckbox(
    selectedArray.length,
    deviceListLength,
  );

  const tableHeaderWithoutIdx = [
    <Checkbox
      key="checkbox"
      checked={selectedArray.length > 0}
      onChange={selectAllArray}
      ref={checkboxRef}
      disabled={deviceListLength === 0}
    />,
    'No',
    'Model',
    'OS',
    'PC Name',
    'App Version',
    'Build Version',
    'Ready to Install',
  ];

  const tableHeader = tableHeaderWithoutIdx.map((header, idx) => ({
    idx,
    header,
  }));

  useEffect(() => {
    setHeaderLength(tableHeader.length);
  }, []);

  return (
    <TableHeaderContainer>
      {tableHeader.map((header) => (
        <TableHeader key={header.idx}>{header.header}</TableHeader>
      ))}
    </TableHeaderContainer>
  );
};

export default SelectDeviceTableHeader;
