import TestStepDetailBasicDefinition from '@components/shared/TestStepDetail/TestStepDetailBasicDefinition';

import { findExtractTextFormatTypeByServerName } from '@utils/static/mobileTestStep/extractTextFormat/extractTextFormatTypeList';
import { getDisplayTextBySaveTextFrom } from '@utils/mapping/mobileTestStep/saveTextMapping';

import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { SaveTextFrom } from '@customTypes/ide/mobileTestStep/SaveTextFrom';

import React from 'react';
import { ErrorBoundaryWrapper } from '@autosquare/common';

interface Props {
  mobileTestStepData: MobileTestStepData;
}

const SaveTextList = ({ mobileTestStepData }: Props) => {
  const secret = mobileTestStepData.optionsDto.secret === 'true';

  const phoneNumber = secret ? '******' : mobileTestStepData?.value;
  return (
    <ErrorBoundaryWrapper>
      <TestStepDetailBasicDefinition
        term={'Secret'}
        description={secret ? 'Y' : 'N'}
      />
      <TestStepDetailBasicDefinition
        term={'From'}
        description={getDisplayTextBySaveTextFrom(
          mobileTestStepData?.optionsDto?.from,
        )}
      />
      {mobileTestStepData?.optionsDto?.from === SaveTextFrom.Sms && (
        <TestStepDetailBasicDefinition
          term={'Phone Number'}
          description={phoneNumber}
        />
      )}
      <TestStepDetailBasicDefinition
        term={'Extract Text Format'}
        description={
          mobileTestStepData?.optionsDto.textExtract === 'true' ? 'Y' : 'N'
        }
      />
      {mobileTestStepData?.optionsDto.textExtract === 'true' && (
        <>
          <TestStepDetailBasicDefinition
            term={'Type / Length'}
            description={`${findExtractTextFormatTypeByServerName(mobileTestStepData?.optionsDto.textExtractOptions.type)} / ${mobileTestStepData?.optionsDto.textExtractOptions.length} `}
          />
          <TestStepDetailBasicDefinition
            term={'Index'}
            description={
              mobileTestStepData?.optionsDto.textExtractOptions.index as string
            }
          />
        </>
      )}
    </ErrorBoundaryWrapper>
  );
};

export default SaveTextList;
