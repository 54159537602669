import { RootState } from '@app/store';
import { decodeJwtToken, PrType } from '@autosquare/common';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// 현재 접속한 유저의 인증과 관련된 부분 확인 가능
export const useAuthUserInfo = () => {
  const { projectIdx } = useParams();

  const accessToken = useSelector(
    (state: RootState) => state.accessTokenValue.accessTokenValue,
  );

  const userId = decodeJwtToken(accessToken)?.sub;

  const userAuthRole = decodeJwtToken(accessToken)?.auth;

  const userProjectRole = decodeJwtToken(accessToken)?.pr?.find(
    (pr: PrType) => pr.idx === parseInt(projectIdx),
  )?.role;

  const isAdmin = userProjectRole === 0;
  const isMember = userProjectRole === 1;

  return {
    accessToken,
    userId,
    userAuthRole,
    userProjectRole,
    isAdmin,
    isMember,
  };
};
