import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';

import DefinitionList from '@components/shared/DefinitionList';
import DefinitionTextAreaByReactHookForm from '@components/shared/TestStepDetail/DefinitionTextAreaByReactHookForm';
import DetailDivisionLine from '@components/shared/TestStepDetail/DetailDivisionLine';

import { UpdateWebTestStepData } from '@customTypes/testStep/type';

import {
  findWebCommandNameFromServerName,
  WebCommandOptionName,
  webCommandOptions,
} from '@utils/static/webCommandOptions';

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const IfCondition = () => {
  const { control } = useFormContext<UpdateWebTestStepData>();

  return (
    <ErrorBoundaryWrapper>
      <DetailDivisionLine
        title={'Condition'}
        description={'조건식을 설정합니다.'}
      />
      <DefinitionList
        term={'Command'}
        description={
          <Controller
            name={'optionsDto.conditionCommand'}
            control={control}
            render={({ field }) => (
              <ListBoxHeadlessInController
                value={field.value}
                buttonValue={findWebCommandNameFromServerName(field.value)}
                onChange={field.onChange}
                lists={webCommandOptions.filter(
                  (command) =>
                    command.name === WebCommandOptionName.FindElement,
                )}
                valueToSave={'serverName'}
                valueToShow={'name'}
              />
            )}
          />
        }
      />
      <DefinitionTextAreaByReactHookForm
        term={'Value*'}
        name={'optionsDto.conditionValue'}
      />
    </ErrorBoundaryWrapper>
  );
};

export default IfCondition;
