import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import {
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import dayjs from 'dayjs';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';

import 'dayjs/locale/ko';

dayjs.extend(isLeapYear);
dayjs.locale('ko');

import { persistor, store } from './app/store';

import './index.css';

import NavRoot from './components/NavRoot/NavRoot';
import SidebarRoot from './components/SidebarRoot/SidebarRoot';
import MiddleAlignRoot from './components/shared/MiddleAlignRoot/MiddleAlignRoot';
import Main from './pages/Dashboard/Main/Main';
import Login from './pages/Dashboard/Login/Login';
import SignUp from './pages/Dashboard/SignUp/SignUp';
import SignUpError from './pages/Dashboard/SignUpError/SignUpError';
import Projects from './pages/Dashboard/Projects/Projects';
import Overview from './pages/Dashboard/Overview/Overview';
import MobileTestResult from './pages/Dashboard/MobileTestResult/MobileTestResult';
import MobileTestResultDetail from './pages/Dashboard/MobileTestResultDetail/MobileTestResultDetail';
import Admin from './pages/Dashboard/Admin/Admin';
import Details from './pages/Dashboard/Admin/Details/Details';
import NotFound from './NotFound';
import InvalidToken from './pages/Dashboard/InvalidToken/InvalidToken';
import ProjectMembersList from './pages/Dashboard/ProjectSettings/ProjectMembersList/ProjectMembersList';
import AddProjectMember from './pages/Dashboard/ProjectSettings/AddProjectMember/AddProjectMember';
import CheckUpdateDate from './pages/Dashboard/CheckUpdateDate/CheckUpdateDate';
import AddUsers from './pages/Dashboard/Admin/Users/AddUsers/AddUsers';
import AdminUsersList from './pages/Dashboard/Admin/Users/AdminUsersList/AdminUsersList';
import AdminProjectList from './pages/Dashboard/Admin/Projects/AdminProjectList/AdminProjectList';
import AdminCreateProject from './pages/Dashboard/Admin/Projects/AdminCreateProject/AdminCreateProject';
import AdminProjectDetail from './pages/Dashboard/Admin/Projects/AdminProjectDetail/AdminProjectDetail';
import AdminGeneralSettings from './pages/Dashboard/Admin/Settings/AdminDataStorageSetting/AdminDataStorageSetting';
import AdminWebDriver from './pages/Dashboard/Admin/Settings/AdminWebDriver/AdminWebDriver';
import InternalServerError from './pages/Dashboard/InternalServerError/InternalServerError';
import NotAdmin from './pages/Dashboard/NotAdmin/NotAdmin';
import WebSpeedTestResult from './pages/Dashboard/WebSpeedTestResult/WebSpeedTestResult';
import ProjectMemberDetail from './pages/Dashboard/ProjectSettings/ProjectMemberDetail/ProjectMemberDetail';
import AdminUserDetail from './pages/Dashboard/Admin/Users/AdminUserDetail/AdminUserDetail';
import MobileSpeedTestResult from './pages/Dashboard/MobileSpeedTestResult/MobileSpeedTestResult';
import WebTestResult from './pages/Dashboard/WebTestResult/WebTestResult';
import WebTestResultDetail from './pages/Dashboard/WebTestResultDetail/WebTestResultDetail';
import AutosquareRoot from './components/shared/AutosquareLayout/AutosquareRoot';

// IDE
import ServerError from './pages/IDE/ServerError/ServerError';
import IdeProject from './pages/IDE/Project/IdeProject';
import IdeLogin from './pages/IDE/Login/IdeLogin';
import IdeMain from './pages/IDE/Main/Main';
import IdeRoot from './components/IdeRoot/IdeRoot';
import ProjectWithSidebar from './components/ProjectWithSidebar/ProjectWithSidebar';
import IdeOverview from './pages/IDE/Dashboard/Overview/IdeOverview';
import MobileTestCase from './pages/IDE/UITest/MobileTestCase/MobileTestCase';
import IdeMobileTestResult from './pages/IDE/UITest/MobileTestResult/IdeMobileTestResult';
import IdeWebTestResult from './pages/IDE/UITest/WebTestResult/IdeWebTestResult';
import TestResultDetail from './pages/IDE/UITest/TestResultDetail/TestResultDetail';
import CreateMobileTestCase from './pages/IDE/UITest/CreateMobileTestCase/CreateMobileTestCase';
import MobileTestCaseDetail from './pages/IDE/UITest/MobileTestCaseDetail/MobileTestCaseDetail';
import MobileTestCaseExport from './pages/IDE/UITest/MobileTestCaseExport/MobileTestCaseExport';
import MobileTestCaseImport from './pages/IDE/UITest/MobileTestCaseImport/MobileTestCaseImport';
import CreateTestScenario from './pages/IDE/UITest/CreateTestScenario/CreateTestScenario';
import TestScenarioDetail from './pages/IDE/UITest/TestScenarioDetail/TestScenarioDetail';
import WebTestCase from './pages/IDE/UITest/WebTestCase/WebTestCase';
import CreateWebTestCase from './pages/IDE/UITest/CreateWebTestCase/CreateWebTestCase';
import WebTestCaseDetail from './pages/IDE/UITest/WebTestCaseDetail/WebTestCaseDetail';
import SchedulerList from './pages/IDE/UITest/SchedulerList/SchedulerList';
import CreateScheduler from './pages/IDE/UITest/CreateScheduler/CreateScheduler';
import SchedulerDetail from './pages/IDE/UITest/SchedulerDetail/SchedulerDetail';
import WebTestStep from './pages/IDE/UITest/WebTestStep/WebTestStep';
import WebAddTestStepAndList from './pages/IDE/UITest/WebAddTestStepAndList/WebAddTestStepAndList';
import IdeInvalidToken from './pages/IDE/IdeInvalidToken/IdeInvalidToken';
import IdeNotFound from './pages/IDE/IdeNotFound/IdeNotFound';
import IdeErrorPage from './pages/IDE/IdeErrorPage/IdeErrorPage';
import DeviceSettings from './pages/IDE/DeviceSettings/DeviceSettings';
import TestExecutionSpinner from './pages/IDE/UITest/TestExecutionSpinner/TestExecutionSpinner';
import UITestOverview from './pages/IDE/UITest/UITestOverview/UITestOverview';
import SpeedTestOverview from './pages/IDE/SpeedTest/SpeedTestOverview/SpeedTestOverview';
import SpeedTestScheduler from './pages/IDE/SpeedTest/SpeedTestScheduler/SpeedTestScheduler';
import MobileSpeedTestScenario from './pages/IDE/SpeedTest/Mobile/MobileSpeedTestScenario/MobileSpeedTestScenario';
import MobileSpeedTestCase from './pages/IDE/SpeedTest/Mobile/MobileSpeedTestCase/MobileSpeedTestCase';
import WebSpeedTestCase from './pages/IDE/SpeedTest/Web/WebSpeedTestCase/WebSpeedTestCase';
import WebSpeedTestScenario from './pages/IDE/SpeedTest/Web/WebSpeedTestScenario/WebSpeedTestScenario';
import IdeWebSpeedTestResult from './pages/IDE/SpeedTest/Web/IdeWebSpeedTestResult/IdeWebSpeedTestResult';
import IdeMobileSpeedTestResult from './pages/IDE/SpeedTest/Mobile/IdeMobileSpeedTestResult/IdeMobileSpeedTestResult';
import CreateSpeedMobileTestCase from './pages/IDE/SpeedTest/Mobile/CreateMobileSpeedTestCase/CreateSpeedMobileTestCase';
import MobileSpeedTestCaseDetail from './pages/IDE/SpeedTest/Mobile/MobileSpeedTestCaseDetail/MobileSpeedTestCaseDetail';
import StreamingCaution from './pages/IDE/UITest/StreamingCaution/StreamingCaution';
import Download from './pages/Dashboard/Download/Download';
import SpeedMobileDeviceList from './pages/IDE/SpeedTest/Mobile/SpeedMobileDeviceList/SpeedMobileDeviceList';
import MobileDeviceList from './pages/IDE/UITest/MobileDeviceList/MobileDeviceList';
import CreateWebSpeedTestCase from './pages/IDE/SpeedTest/Web/CreateWebSpeedTestCase/CreateWebSpeedTestCase';
import WebSpeedTestCaseDetail from './pages/IDE/SpeedTest/Web/WebSpeedTestCaseDetail/WebSpeedTestCaseDetail';
import ForgotPassword from './pages/Dashboard/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/Dashboard/ResetPassword/ResetPassword';
import UiMobileTestStep from './pages/IDE/UITest/MobileTestStep/UiMobileTestStep';
import SpeedMobileTestStep from './pages/IDE/SpeedTest/Mobile/MobileSpeedTestStep/SpeedMobileTestStep';
import IdeMobileSpeedTestResultDetail from './pages/IDE/SpeedTest/Mobile/IdeMobileSpeedTestResultDetail/IdeMobileSpeedTestResultDetail';
import CreateMobileSpeedTestScenario from './pages/IDE/SpeedTest/Mobile/CreateMobileSpeedTestScenario/CreateMobileSpeedTestScenario';
import MobileSpeedTestScenarioDetail from './pages/IDE/SpeedTest/Mobile/MobileSpeedTestScenarioDetail/MobileSpeedTestScenarioDetail';
import WebSpeedTestScenarioDetail from './pages/IDE/SpeedTest/Web/WebSpeedTestScenarioDetail/WebSpeedTestScenarioDetail';
import CreateWebSpeedTestScenario from './pages/IDE/SpeedTest/Web/CreateWebSpeedTestScenario/CreateWebSpeedTestScenario';
import MobileSpeedTestResultDetail from './pages/Dashboard/MobileSpeedTestResultDetail/MobileSpeedTestResultDetail';
import WebSpeedTestResultDetail from './pages/Dashboard/WebSpeedTestResultDetail/WebSpeedTestResultDetail';
import IdeWebSpeedTestResultDetail from './pages/IDE/SpeedTest/Web/IdeWebSpeedTestResultDetail/IdeWebSpeedTestResultDetail';
import SpeedTestSchedulerDetail from './pages/IDE/SpeedTest/SpeedTestSchedulerDetail/SpeedTestSchedulerDetail';
import CreateSpeedTestScheduler from './pages/IDE/SpeedTest/CreateSpeedTestScheduler/CreateSpeedTestScheduler';
import MobileTestScenario from './pages/IDE/UITest/MobileTestScenario/MobileTestScenario';
import WebTestScenario from './pages/IDE/UITest/WebTestScenario/WebTestScenario';
import WebTestAutomationSettings from './pages/IDE/UITest/WebTestAutomationSettings/WebTestAutomationSettings';
import MobileTestAutomationSettings from './pages/IDE/UITest/MobileTestAutomationSettings/MobileTestAutomationSettings';
import MobileSpeedTestAutomationSettings from './pages/IDE/SpeedTest/Mobile/MobileSpeedTestAutomationSettings/MobileSpeedTestAutomationSettings';
import AdminDeviceList from './pages/Dashboard/Admin/Devices/AdminDeviceList/AdminDeviceList';
import AdminAddDevice from './pages/Dashboard/Admin/Devices/AdminAddDevice/AdminAddDevice';
import AdminDeviceDetail from './pages/Dashboard/Admin/Devices/AdminDeviceDetail/AdminDeviceDetail';
import AdminDeviceApproval from './pages/Dashboard/Admin/Devices/AdminDeviceApproval/AdminDeviceApproval';
import ProjectDevicesList from './pages/Dashboard/ProjectSettings/ProjectDevicesList/ProjectDevicesList';
import ProjectAddDevice from './pages/Dashboard/ProjectSettings/ProjectAddDevice/ProjectAddDevice';
import ProjectDeviceDetail from './pages/Dashboard/ProjectSettings/ProjectDeviceDetail/ProjectDeviceDetail';
import ProjectSettingsDetail from './pages/Dashboard/ProjectSettings/ProjectSettingsDetail/ProjectSettingsDetail';
import ReservationDeviceList from './pages/IDE/Devices/ReservedDeviceList/ReservationDeviceList';
import ReserveDevice from './pages/IDE/Devices/ReserveDevice/ReserveDevice';
import ReservedDeviceDetail from './pages/IDE/Devices/ReservedDeviceDetail/ReservedDeviceDetail';
import ProjectApplicationList from './pages/Dashboard/ProjectSettings/ProjectApplicationList/ProjectApplicationList';
import AddProjectApplication from './pages/Dashboard/ProjectSettings/AddProjectApplication/AddProjectApplication';
import ProjectApplicationDetail from './pages/Dashboard/ProjectSettings/ProjectApplicationDetail/ProjectApplicationDetail';
import DeviceAppInstall from './pages/Dashboard/ProjectSettings/DeviceAppInstall/DeviceAppInstall';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 20,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  },
});

if (VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: VITE_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({ maskAllText: true, blockAllMedia: true }),
    ],
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/[a-z]+\.t-square\.co\.kr\/api/,
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const sentryCreateBrowserRouter = VITE_SENTRY_DSN
  ? Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  : createBrowserRouter;

const router = sentryCreateBrowserRouter([
  // Dashboard Router
  { path: '/', element: <Main /> },
  // { path: "/home", element: <Home /> },
  {
    path: '/',
    element: <MiddleAlignRoot />,
    children: [
      { path: '/login', element: <Login /> },
      { path: 'signup', element: <SignUp /> },
      { path: 'signup-error', element: <SignUpError /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: 'check-update-date', element: <CheckUpdateDate /> },
    ],
  },
  {
    path: '/projects/',
    element: <NavRoot />,
    children: [{ path: '', element: <Projects /> }],
  },
  {
    path: '/',
    element: <NavRoot />,
    children: [{ path: 'download/', element: <Download /> }],
  },
  {
    path: '/',
    element: <NavRoot />,
    children: [{ path: 'download/', element: <Download /> }],
  },
  {
    path: '/projects/:projectIdx/',
    element: <SidebarRoot />,
    children: [
      { path: 'overview', element: <Overview /> },
      { path: 'mobile/test-result', element: <MobileTestResult /> },
      { path: 'mobile/speed/test-result/', element: <MobileSpeedTestResult /> },
      {
        path: 'mobile/speed/test-result/detail',
        element: <MobileSpeedTestResultDetail />,
      },
      {
        path: 'mobile/test-result/detail',
        element: <MobileTestResultDetail />,
      },
      { path: 'web/test-result', element: <WebTestResult /> },
      { path: 'web/test-result/detail', element: <WebTestResultDetail /> },
      { path: 'web/speed/test-result', element: <WebSpeedTestResult /> },
      {
        path: 'web/speed/test-result/detail',
        element: <WebSpeedTestResultDetail />,
      },
      {
        path: 'project-settings',
        children: [
          { path: 'detail', element: <ProjectSettingsDetail /> },
          {
            path: 'members/',
            children: [
              { path: '', element: <ProjectMembersList /> },
              { path: 'add-member', element: <AddProjectMember /> },
              { path: 'detail', element: <ProjectMemberDetail /> },
            ],
          },
          {
            path: 'devices/',
            children: [
              { path: '', element: <ProjectDevicesList /> },
              { path: 'add-device', element: <ProjectAddDevice /> },
              { path: 'detail', element: <ProjectDeviceDetail /> },
              { path: 'app-install', element: <DeviceAppInstall /> },
            ],
          },
          {
            path: 'application/',
            children: [
              { path: '', element: <ProjectApplicationList /> },
              { path: 'add-application', element: <AddProjectApplication /> },
              { path: 'detail', element: <ProjectApplicationDetail /> },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/',
    element: <SidebarRoot />,
    children: [
      {
        path: 'admin/',
        element: <Admin />,
        children: [{ path: 'details', element: <Details /> }],
      },
      {
        path: 'admin/users/',
        children: [
          { path: '', element: <AdminUsersList /> },
          { path: 'add', element: <AddUsers /> },
          { path: 'detail', element: <AdminUserDetail /> },
        ],
      },
      {
        path: 'admin/devices/',
        children: [
          { path: '', element: <AdminDeviceList /> },
          { path: 'add', element: <AdminAddDevice /> },
          { path: 'detail', element: <AdminDeviceDetail /> },
          { path: 'approval', element: <AdminDeviceApproval /> },
        ],
      },
      {
        path: 'admin/projects/',
        children: [
          { path: '', element: <AdminProjectList /> },
          { path: 'create', element: <AdminCreateProject /> },
          { path: 'details', element: <AdminProjectDetail /> },
        ],
      },
      {
        path: 'admin/settings/',
        children: [
          { path: 'data-storage', element: <AdminGeneralSettings /> },
          { path: 'webdriver', element: <AdminWebDriver /> },
        ],
      },
    ],
  },

  { path: '/invalid-token', element: <InvalidToken /> },
  { path: '/internal-server-error', element: <InternalServerError /> },
  { path: '/not-admin', element: <NotAdmin /> },
  { path: '*', element: <NotFound /> },

  // IDE Router
  {
    path: '/ide/',
    element: <IdeRoot />,
    children: [
      { path: '', element: <IdeMain /> },
      { path: 'login', element: <IdeLogin /> },
      { path: 'projects/list', element: <IdeProject /> },
      {
        path: 'projects/:projectIdx/',
        element: <ProjectWithSidebar />,
        children: [
          {
            path: 'dashboard',
            children: [{ path: 'overview', element: <IdeOverview /> }],
          },
          {
            path: 'ui-test',
            children: [
              { path: 'overview', element: <UITestOverview /> },
              {
                path: 'scheduler',
                children: [
                  { path: '', element: <SchedulerList /> },
                  { path: 'create', element: <CreateScheduler /> },
                  { path: 'detail', element: <SchedulerDetail /> },
                ],
              },
              {
                path: 'mobile',
                children: [
                  { path: 'test-scenario', element: <MobileTestScenario /> },
                  {
                    path: 'test-scenario/create',
                    element: <CreateTestScenario />,
                  },
                  {
                    path: 'test-scenario/detail',
                    element: <TestScenarioDetail />,
                  },
                  { path: 'test-case', element: <MobileTestCase /> },
                  {
                    path: 'test-case/create',
                    element: <CreateMobileTestCase />,
                  },
                  {
                    path: 'test-case/detail',
                    children: [
                      { path: '', element: <MobileTestCaseDetail /> },
                      {
                        path: 'automation',
                        element: <MobileTestAutomationSettings />,
                      },
                    ],
                  },
                  {
                    path: 'test-case/export',
                    element: <MobileTestCaseExport />,
                  },
                  {
                    path: 'test-case/import',
                    element: <MobileTestCaseImport />,
                  },
                  {
                    path: 'test-case/device-list',
                    element: <MobileDeviceList />,
                  },
                  {
                    path: 'test-case/device-settings',
                    element: <DeviceSettings />,
                  },
                  { path: 'test-result', element: <IdeMobileTestResult /> },
                  { path: 'test-result/detail', element: <TestResultDetail /> },
                ],
              },
              {
                path: 'web',
                children: [
                  { path: 'test-scenario', element: <WebTestScenario /> },
                  {
                    path: 'test-scenario/create',
                    element: <CreateTestScenario />,
                  },
                  {
                    path: 'test-scenario/detail',
                    element: <TestScenarioDetail />,
                  },
                  { path: 'test-case', element: <WebTestCase /> },
                  { path: 'test-case/create', element: <CreateWebTestCase /> },
                  {
                    path: 'test-case/detail',
                    children: [
                      { path: '', element: <WebTestCaseDetail /> },
                      {
                        path: 'automation',
                        element: <WebTestAutomationSettings />,
                      },
                    ],
                  },
                  { path: 'test-result', element: <IdeWebTestResult /> },
                  { path: 'test-result/detail', element: <TestResultDetail /> },
                ],
              },
            ],
          },
          {
            path: 'speed-test',
            children: [
              { path: 'overview', element: <SpeedTestOverview /> },
              {
                path: 'scheduler',
                children: [
                  { path: '', element: <SpeedTestScheduler /> },
                  { path: 'create', element: <CreateSpeedTestScheduler /> },
                  { path: 'detail', element: <SpeedTestSchedulerDetail /> },
                ],
              },
              {
                path: 'mobile',
                children: [
                  {
                    path: 'test-scenario',
                    element: <MobileSpeedTestScenario />,
                  },
                  {
                    path: 'test-scenario/create',
                    element: <CreateMobileSpeedTestScenario />,
                  },
                  {
                    path: 'test-scenario/detail',
                    element: <MobileSpeedTestScenarioDetail />,
                  },
                  { path: 'test-case', element: <MobileSpeedTestCase /> },
                  {
                    path: 'test-case/create',
                    element: <CreateSpeedMobileTestCase />,
                  },
                  {
                    path: 'test-case/detail',
                    children: [
                      { path: '', element: <MobileSpeedTestCaseDetail /> },
                      {
                        path: 'automation',
                        element: <MobileSpeedTestAutomationSettings />,
                      },
                    ],
                  },
                  {
                    path: 'test-case/device-list',
                    element: <SpeedMobileDeviceList />,
                  },
                  {
                    path: 'test-result',
                    element: <IdeMobileSpeedTestResult />,
                  },
                  {
                    path: 'test-result/detail',
                    element: <IdeMobileSpeedTestResultDetail />,
                  },
                ],
              },
              {
                path: 'web',
                children: [
                  { path: 'test-scenario', element: <WebSpeedTestScenario /> },
                  {
                    path: 'test-scenario/create',
                    element: <CreateWebSpeedTestScenario />,
                  },
                  {
                    path: 'test-scenario/detail',
                    element: <WebSpeedTestScenarioDetail />,
                  },
                  { path: 'test-case', element: <WebSpeedTestCase /> },
                  {
                    path: 'test-case/create',
                    element: <CreateWebSpeedTestCase />,
                  },
                  {
                    path: 'test-case/detail',
                    element: <WebSpeedTestCaseDetail />,
                  },
                  { path: 'test-result', element: <IdeWebSpeedTestResult /> },
                  {
                    path: 'test-result/detail',
                    element: <IdeWebSpeedTestResultDetail />,
                  },
                ],
              },
            ],
          },
          {
            path: 'devices',
            children: [
              { path: '', element: <ReservationDeviceList /> },
              { path: 'reserve', element: <ReserveDevice /> },
              { path: 'detail', element: <ReservedDeviceDetail /> },
            ],
          },
        ],
      },
      { path: 'invalid-token', element: <IdeInvalidToken /> },
      {
        path: '',
        element: <AutosquareRoot />,
        children: [
          { path: 'internal-server-error', element: <ServerError /> },
          { path: 'error', element: <IdeErrorPage /> },
        ],
      },
    ],
  },
  {
    path: '/ide/projects/:projectIdx/ui-test/mobile/test-step',
    element: <UiMobileTestStep />,
  },
  {
    path: '/ide/projects/:projectIdx/ui-test/web/test-step',
    element: <WebTestStep />,
  },
  {
    path: '/ide/projects/:projectIdx/ui-test/web/test-step/add-and-list',
    element: <WebAddTestStepAndList />,
  },
  {
    path: '/ide/projects/:projectIdx/speed-test/mobile/test-step',
    element: <SpeedMobileTestStep />,
  },
  { path: '/test-execution-spinner', element: <TestExecutionSpinner /> },
  { path: '/ide/streaming-caution', element: <StreamingCaution /> },
  { path: '/ide/not-found', element: <IdeNotFound /> },
  { path: '/ide/*', element: <IdeNotFound /> },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </StrictMode>,
);
