import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getTestCaseIndexArray } from '@store/ide/numberArraySlice';

import DetailCard from './DetailPaste/DetailCard';
import { TestCaseDetailPickTitleAndIdx } from '@customTypes/testCase/type';
import { SelectedItem } from '@autosquare/common/src/hooks/checkbox/useCheckboxWithDnd';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  MeasuringStrategy,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useScenarioDndHandler } from '@hooks/useScenarioDndHandler';

interface Props {
  copyTestCases: TestCaseDetailPickTitleAndIdx[];
  setCopyTestCases: React.Dispatch<
    React.SetStateAction<TestCaseDetailPickTitleAndIdx[]>
  >;
  selectedArray: SelectedItem[];
  selectArray: (
    idx: number,
    dndIndex: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  setSelectedArray: React.Dispatch<React.SetStateAction<SelectedItem[]>>;
}

const DetailPaste = ({
  copyTestCases,
  setCopyTestCases,
  selectedArray,
  selectArray,
  setSelectedArray,
}: Props) => {
  const dispatch = useDispatch();

  const {
    testCaseIdxArray,
    sensors,
    onDragEnd,
    onDragStart,
    items,
    onRemove,
    activeId,
    overlayCopyTestCase,
  } = useScenarioDndHandler(copyTestCases, setCopyTestCases);

  useEffect(() => {
    dispatch(getTestCaseIndexArray(testCaseIdxArray));
  }, [copyTestCases]);

  return (
    <div className="gray-scrollbar h-[calc(100vh-26rem)]">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        modifiers={[restrictToVerticalAxis]}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {copyTestCases?.map((copyTestCase) => (
            <DetailCard
              key={copyTestCase.dndIndex}
              copyTestCase={copyTestCase}
              selectArray={selectArray}
              selectedArray={selectedArray}
              onRemove={onRemove}
              setSelectedArray={setSelectedArray}
            />
          ))}
        </SortableContext>
        <DragOverlay
          zIndex={100}
          wrapperElement="ul"
          style={{
            boxShadow: activeId && '0 10px 10px -5px',
          }}
        >
          {activeId ? (
            <div style={{ transform: 'scale(1.04)' }}>
              <DetailCard
                copyTestCase={overlayCopyTestCase}
                selectArray={selectArray}
                selectedArray={selectedArray}
                onRemove={onRemove}
                setSelectedArray={setSelectedArray}
              />
            </div>
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
};

export default DetailPaste;
