import { useSlidingTab } from '@hooks/overview/useSlidingTab';
import { LocationCategory } from '@lib/api/dashboard/overview/overview';
import clsx from 'clsx';
import React from 'react';

type Props = {
  categorySelectedIndex: number;
  setCategorySelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  selectedTestCategory: LocationCategory;
  setSelectedTestCategory: React.Dispatch<
    React.SetStateAction<LocationCategory>
  >;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  testLocationTabList: {
    title: LocationCategory;
    content: { id: number; post: React.JSX.Element }[];
  }[];
};

const TestCategoryTab = ({
  categorySelectedIndex,
  setCategorySelectedIndex,
  selectedTestCategory,
  setSelectedTestCategory,
  setSelectedIndex,
  testLocationTabList,
}: Props) => {
  const { tabListRef, tabListWidth, getTranslateX, tabWidths, tabRef } =
    useSlidingTab(
      categorySelectedIndex,
      selectedTestCategory,
      testLocationTabList,
    );

  return (
    <div
      className="relative flex whitespace-nowrap break-words rounded-md bg-slate-100 px-2 py-1.5"
      role="tablist"
      style={{ width: `${(tabListWidth + 16) / 16}rem` }}
      ref={tabListRef}
    >
      <div
        className={clsx(
          `absolute top-1 h-4/5 rounded-md bg-white shadow-sm transition-transform duration-300 ease-out`,
          tabWidths[categorySelectedIndex],
        )}
        style={{
          transform: `translateX(${getTranslateX()}px)`,
          width: `${tabWidths[categorySelectedIndex] || 0}px`,
        }}
      />
      {testLocationTabList.map((tab, index) => (
        <button
          key={tab.title}
          role="tab"
          id={`tab-${index}`}
          className={clsx(
            'relative z-10 rounded-md px-3 py-1.5 text-sm font-medium',
            selectedTestCategory === tab.title
              ? 'text-gray-900'
              : 'text-slate-600',
          )}
          onClick={() => {
            setSelectedTestCategory(tab.title);
            setCategorySelectedIndex(index);
            setSelectedIndex(0);
          }}
          ref={(el) => (tabRef.current[index] = el)}
        >
          {tab.title}
        </button>
      ))}
    </div>
  );
};

export default TestCategoryTab;
