import React from 'react';
import { ErrorBoundaryWrapper, useCheckboxWithDnd } from '@autosquare/common';
import SelectionHeaderWithCheckbox from '@components/shared/SelectionHeaderWithCheckbox';
import DetailPaste from './DetailPaste';
import { TestCaseDetailPickTitleAndIdx } from '@customTypes/testCase/type';

type Props = {
  total: number;
  copyTestCases: TestCaseDetailPickTitleAndIdx[];
  setCopyTestCases: React.Dispatch<
    React.SetStateAction<TestCaseDetailPickTitleAndIdx[]>
  >;
};

const DetailScenarioTestCaseListBox = ({
  total,
  copyTestCases,
  setCopyTestCases,
}: Props) => {
  const {
    selectAllArray,
    selectArray,
    selectedArray,
    setSelectedArray,
    resetSelectedArray,
  } = useCheckboxWithDnd(copyTestCases, 'idx', 'dndIndex');

  return (
    <ErrorBoundaryWrapper>
      {copyTestCases.length > 0 && (
        <SelectionHeaderWithCheckbox
          selectAllArray={selectAllArray}
          selectedArray={selectedArray}
          total={total}
          hasDeleteOption
          setCopyTestCases={setCopyTestCases}
          resetSelectedArray={resetSelectedArray}
        />
      )}
      <DetailPaste
        copyTestCases={copyTestCases}
        setCopyTestCases={setCopyTestCases}
        selectArray={selectArray}
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
      />
    </ErrorBoundaryWrapper>
  );
};

export default DetailScenarioTestCaseListBox;
