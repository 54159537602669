import { TestCaseDetailData } from '@customTypes/testCase/type';
import { findPlatformTypeNameFromServerName } from '@utils/static/platformTypeList';
import { findFillColorFromName } from '@utils/static/priorityLists';
import React, { useEffect } from 'react';

interface Props {
  mobileTestCaseDetail: TestCaseDetailData;
}

const TestCaseInfoDefault = ({ mobileTestCaseDetail }: Props) => {
  const fillColor = findFillColorFromName(mobileTestCaseDetail?.priority);

  useEffect(() => {
    sessionStorage.setItem('testCaseTitle', mobileTestCaseDetail.title);
  }, []);

  const descriptionList = [
    {
      term: 'Platform Type',
      description:
        mobileTestCaseDetail.platformType === null
          ? '-'
          : findPlatformTypeNameFromServerName(
              mobileTestCaseDetail.platformType,
            ),
    },
    {
      term: 'Priority',
      description: (
        <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
          <svg
            className={`size-1.5 ${fillColor}`}
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx={3} cy={3} r={3} />
          </svg>
          {mobileTestCaseDetail.priority}
        </span>
      ),
    },
    {
      term: 'Test Group',
      description: mobileTestCaseDetail.caseGroupInfo?.name || '-',
    },
    { term: 'Creator', description: mobileTestCaseDetail.creator },
  ];

  const bottomDescriptionLists = [
    { term: 'TC-ID', description: mobileTestCaseDetail.tcId },
    { term: 'Title', description: mobileTestCaseDetail.title },
    { term: 'Description', description: mobileTestCaseDetail.description },
  ];

  return (
    <div className="mt-1">
      <dl className="grid grid-cols-1 border-t border-gray-100 py-6 sm:grid-cols-4">
        {descriptionList.map((description) => (
          <div key={description.term}>
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {description.term}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
              {description.description}
            </dd>
          </div>
        ))}
      </dl>
      <dl className="grid grid-cols-1 border-t border-gray-100 py-6 sm:grid-cols-3">
        {bottomDescriptionLists.map((description) => (
          <div key={description.term}>
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {description.term}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
              {description.description}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default TestCaseInfoDefault;
