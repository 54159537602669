import { ErrorBoundaryWrapper } from '@autosquare/common';
import AllTestScenarioListInDraggableElement from '@components/Scheduler/AllTestScenarioListInDraggableElement';
import ScenarioDraggableElementContainer from '@components/Scheduler/ScenarioDraggableElementContainer';
import SchedulerTestScenarioListContainer from '@components/Scheduler/SchedulerTestScenarioListContainer';
import {
  SpeedSchedulerTypeName,
  SpeedSchedulerTypeServerName,
  UpdateSpeedSchedulerData,
} from '@customTypes/ide/speedScheduler/speedScheduler';
import { ErrorMessage } from '@hookform/error-message';
import { useSpeedTestSchedulerDraggableSpace } from '@hooks/speedTestScheduler/useSpeedTestSchedulerDraggableSpace';
import { useSpeedSchedulerDetailQuery } from '@lib/api/ide/speedScheduler/speedScheduler';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import SpeedSchedulerDetailTestScenarioList from './SpeedTestSchedulerDetailScenarioDraggableSection/SpeedSchedulerDetailTestScenarioList';

const SpeedTestSchedulerDetailScenarioDraggableSection = () => {
  const {
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext<UpdateSpeedSchedulerData>();

  const { data: speedSchedulerDetail } = useSpeedSchedulerDetailQuery();

  const testType = speedSchedulerDetail?.type;
  const scenarioIdxList = watch('scenarioIdxList');

  const {
    copiedTestScenarioList,
    setCopiedTestScenarioList,
    searchWord,
    onChangeSearchWord,
    setSearchWord,
    changeCardList,
    searchResultTestScenarioList,
    speedMobileTestScenarioList,
    speedWebTestScenarioList,
    isLoading,
  } = useSpeedTestSchedulerDraggableSpace(
    'detail',
    testType === SpeedSchedulerTypeServerName.Mobile
      ? SpeedSchedulerTypeName.Mobile
      : SpeedSchedulerTypeName.Web,
  );

  useEffect(() => {
    if (scenarioIdxList?.length > 0) {
      clearErrors('scenarioIdxList');
    }
  }, [clearErrors, scenarioIdxList]);

  return (
    <ErrorBoundaryWrapper>
      <ScenarioDraggableElementContainer>
        <AllTestScenarioListInDraggableElement
          isLoading={isLoading}
          speedTestScenarioList={
            testType === SpeedSchedulerTypeServerName.Mobile
              ? speedMobileTestScenarioList
              : speedWebTestScenarioList
          }
          searchResultTestScenarioList={searchResultTestScenarioList}
          copiedTestScenarioList={copiedTestScenarioList}
          changeCardList={changeCardList}
          searchWord={searchWord}
          onChangeSearchWord={onChangeSearchWord}
          setSearchWord={setSearchWord}
        />
        <SchedulerTestScenarioListContainer>
          <SpeedSchedulerDetailTestScenarioList
            isLoading={isLoading}
            copiedTestScenarioList={copiedTestScenarioList}
            setCopiedTestScenarioList={setCopiedTestScenarioList}
            changeCardList={changeCardList}
          />
        </SchedulerTestScenarioListContainer>
        <ErrorMessage
          errors={errors}
          name="scenarioIdxList"
          render={({ message }) => (
            <p className="standard-error-message">{message}</p>
          )}
        />
      </ScenarioDraggableElementContainer>
    </ErrorBoundaryWrapper>
  );
};

export default SpeedTestSchedulerDetailScenarioDraggableSection;
