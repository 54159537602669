import React from 'react';

const ReservationTimeTableHeader = () => {
  const tableHeaderWithoutIdx = [
    { title: 'Start Time' },
    { title: 'End Time' },
    { title: 'User' },
    { title: '' },
  ];

  const tableHeaderList = tableHeaderWithoutIdx.map((header, idx) => ({
    ...header,
    idx: idx + 1,
  }));

  return (
    <div className="table-header-group">
      <div className="table-row">
        {tableHeaderList.map((header) => (
          <div
            key={header.idx}
            className="table-cell whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold"
          >
            {header.title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReservationTimeTableHeader;
