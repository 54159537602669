import { Checkbox, ErrorBoundaryWrapper } from '@autosquare/common';
import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { WebTestStepData } from '@customTypes/testStep/type';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';
import { findWebCommandNameFromServerName } from '@utils/static/webCommandOptions';
import clsx from 'clsx';
import React from 'react';

type Props = {
  headers: string[] | (string | React.JSX.Element)[];
  rows: WebTestStepData[] | MobileTestStepData[];
  style?: string;
  hasCheckBox?: boolean;
  selectedArray?: number[];
  selectArray?: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  columnWidths?: string[];
};

const ImportTestStepTable = ({
  headers,
  rows,
  style,
  selectedArray,
  selectArray,
  hasCheckBox = true,
  columnWidths = ['w-16', 'w-16', 'w-2/6'],
}: Props) => {
  const isMobilePage = isWordInLocationPathname('mobile');
  const disabledStep = (step) =>
    step?.command.includes('import') ||
    (step?.command === MobileCommandOptionServerName.IF &&
      step?.value.includes('import'));

  return (
    <ErrorBoundaryWrapper>
      <div className={clsx('gray-scrollbar max-h-72', style)}>
        <table className="w-full table-fixed border-separate border-spacing-0">
          <thead className="sticky top-0 border-b border-gray-300 bg-white">
            <tr className="h-12 border-b border-gray-300 px-3 py-3.5">
              {headers.map((header, index) => (
                <th
                  key={index}
                  className={clsx(
                    'border-b border-gray-300 p-3 text-left text-sm font-medium',
                    {
                      [columnWidths[index] || '']: true,
                    },
                  )}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="border-b border-gray-300 text-sm">
            {rows?.map((step, index) => (
              <tr
                key={step?.idx}
                className={clsx(
                  'h-12  px-3 py-3.5',
                  disabledStep(step) && 'text-gray-300',
                )}
              >
                {hasCheckBox && (
                  <td className="border-b border-gray-300 p-3">
                    <Checkbox
                      checked={selectedArray.includes(step?.idx)}
                      onChange={(e) => selectArray(step?.idx, e)}
                      disabled={disabledStep(step)}
                      customStyle={disabledStep(step) && 'bg-gray-100'}
                    />
                  </td>
                )}
                <td className="border-b border-gray-300 p-3">{index + 1}</td>
                <td className="border-b border-gray-300 p-3">
                  {isMobilePage
                    ? findMobileCommandNameFromServerName(step?.command)
                    : findWebCommandNameFromServerName(step?.command)}
                </td>
                <td className="truncate border-b border-gray-300 p-3">
                  {step?.description}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default ImportTestStepTable;
