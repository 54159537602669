import { RootState } from '@app/store';
import {
  ArrowSpinner,
  ErrorBoundaryWrapper,
  QuestionsMarkCircle,
  RepeatExecution,
  TooltipTop,
} from '@autosquare/common';
import { MobileSpeedTestStepList } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { useGetSpeedMobileTestStep } from '@lib/api/ide/speedMobile/speedMobileTestStep';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import StartCombobox from './SpeedTestStepRepeatBox/StartCombobox';
import FinishCombobox from './SpeedTestStepRepeatBox/FinishCombobox';
import { generateRepeatStepList } from '@utils/static/mobileTestStep/findTitleFromIdxBySpeedMobileStep';
import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { useGetWebSocketMessage } from '@hooks/electron/useGetWebSocketMessage';
import RepeatStepExecutionModal from './SpeedTestStepRepeatBox/RepeatStepExecutionModal';
import { MobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';
import RepeatTestStepResultModal from './SpeedTestStepRepeatBox/RepeatTestStepResultModal/RepeatTestStepResultModal';

const SpeedTestStepRepeatBox = () => {
  const [hasBeenRunning, setHasBeenRunning] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isResultModalOpened, setIsResultModalOpened] = useState(false);
  const [isExecutionStarted, setIsExecutionStarted] = useState(false);

  const deviceInfo = useSelector((state: RootState) => state.device.deviceInfo);

  const { testStepResult, isTestRunning, testTarget } =
    useGetWebSocketMessage();

  const { setIsCountThreeNeeded } = useContext(MobileTestStepContext);

  const speedMobileTestStepList = useGetSpeedMobileTestStep();

  const startName = DeviceInfoOs.Ios
    ? 'iosRepeatInfo.startIdx'
    : 'aosRepeatInfo.startIdx';

  const endName =
    deviceInfo.os === DeviceInfoOs.Ios
      ? 'iosRepeatInfo.endIdx'
      : 'aosRepeatInfo.endIdx';

  const comboBoxList =
    deviceInfo.os === DeviceInfoOs.Ios
      ? speedMobileTestStepList.data.ios
      : speedMobileTestStepList.data.aos;

  const startFilteredList = generateRepeatStepList(comboBoxList, true);
  const endFilteredList = generateRepeatStepList(comboBoxList, false);

  const startRepeatIdx =
    deviceInfo.os === DeviceInfoOs.Ios
      ? speedMobileTestStepList.data?.iosRepeatInfo?.startIdx
      : speedMobileTestStepList.data?.aosRepeatInfo?.startIdx;

  const endRepeatIdx =
    deviceInfo.os === DeviceInfoOs.Ios
      ? speedMobileTestStepList.data?.iosRepeatInfo?.endIdx
      : speedMobileTestStepList.data?.aosRepeatInfo?.endIdx;

  const { resetField, setValue } = useForm<MobileSpeedTestStepList>();

  const executionDisabled =
    deviceInfo.os === DeviceInfoOs.Ios
      ? speedMobileTestStepList.data.iosRepeatInfo?.startIdx === 0 ||
        speedMobileTestStepList.data.iosRepeatInfo?.endIdx === 0
      : speedMobileTestStepList.data.aosRepeatInfo?.startIdx === 0 ||
        speedMobileTestStepList.data.aosRepeatInfo?.endIdx === 0;

  useEffect(() => {
    setValue(startName, startRepeatIdx);
    if (startRepeatIdx === 0) {
      resetField(startName);
    }
  }, [startRepeatIdx]);

  useEffect(() => {
    setValue(endName, endRepeatIdx);
    if (endRepeatIdx === 0) {
      resetField(endName);
    }
  }, [endRepeatIdx]);

  useEffect(() => {
    if (!isExecutionStarted) return;

    if (isTestRunning) {
      setHasBeenRunning(true);
      return;
    }

    if (hasBeenRunning) {
      if (
        testStepResult?.result === 'Pass' ||
        testStepResult?.result === 'Fail'
      ) {
        setIsCountThreeNeeded(true);
      }

      setIsResultModalOpened(true);
      setHasBeenRunning(false);
    }
  }, [isTestRunning, hasBeenRunning, testStepResult, isExecutionStarted]);

  return (
    <ErrorBoundaryWrapper>
      <div className="grid grid-rows-3 gap-y-2.5 rounded-md border border-gray-200 p-4">
        <div className="flex items-center justify-between border-b border-gray-200 pb-1.5">
          <div className="flex items-center justify-start gap-x-2">
            <h2 className="text-sm font-medium text-gray-900">Repeat Step</h2>
            <div className="group relative">
              <QuestionsMarkCircle />
              <TooltipTop positionClass="icon-description bottom-7 left-[-5.5rem] w-48 whitespace-pre-wrap opacity-0 after:left-1/2 group-hover:opacity-100 2xl:left-[-3.75rem] 2xl:after:left-[20%] 2xl:w-max">
                속도 측정을 반복할 구간의 시작과 종료 지점을 설정할 수 있습니다.
              </TooltipTop>
            </div>
          </div>
          <RepeatExecution
            testType="step"
            disabled={executionDisabled}
            onClick={() => setOpenModal(true)}
          />
        </div>
        <StartCombobox
          startRepeatIdx={startRepeatIdx}
          endRepeatIdx={endRepeatIdx}
          filteredList={startFilteredList}
        />
        <FinishCombobox
          startRepeatIdx={startRepeatIdx}
          endRepeatIdx={endRepeatIdx}
          filteredList={endFilteredList}
        />
        <RepeatStepExecutionModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          deviceInfo={deviceInfo}
          startRepeatIdx={startRepeatIdx}
          endRepeatIdx={endRepeatIdx}
          setIsExecutionStarted={setIsExecutionStarted}
        />
        <RepeatTestStepResultModal
          testStepResult={testStepResult}
          isResultModalOpened={isResultModalOpened}
          setIsResultModalOpened={setIsResultModalOpened}
          setIsExecutionStarted={setIsExecutionStarted}
        />
        {isTestRunning && testTarget === 'mobile' && <ArrowSpinner />}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default SpeedTestStepRepeatBox;
