import { ErrorBoundaryWrapper, TooltipTop } from '@autosquare/common';
import SwitchInController from '@components/shared/HeadlessUI/Switch/SwitchInController';
import { CreateWebTestStepData } from '@customTypes/testStep/type';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { WebCommandOptionServerName } from '@utils/static/webCommandOptions';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const WebTestStepMaskingOptionsHeader = () => {
  const { control, watch, resetField, setValue } =
    useFormContext<CreateWebTestStepData>();

  const maskingOptionsEnabled = watch('optionsDto.maskingOptions.enabled');

  const command = watch('command');
  const value = watch('value');
  const conditionValue = watch('optionsDto.conditionValue');
  const passActionValue = watch('optionsDto.passActionValue');

  useEffect(() => {
    if (!maskingOptionsEnabled) {
      resetField('optionsDto.maskingOptions.type');
      resetField('optionsDto.maskingOptions.valueList');
    }
  }, [maskingOptionsEnabled, resetField]);

  useEffect(() => {
    setValue('optionsDto.maskingOptions.enabled', false);
  }, []);

  const maskingOptionsDiabled = () => {
    if (command === WebCommandOptionServerName.IF) {
      if (!conditionValue || !passActionValue) {
        return true;
      }
    }

    return !command || !value;
  };

  return (
    <ErrorBoundaryWrapper>
      <div className="flex items-center justify-between pr-4">
        <div className="flex items-center gap-1.5">
          <span className="text-sm font-medium leading-normal text-gray-900">
            Masking Options
          </span>
          <div className="group relative">
            <QuestionMarkCircleIcon
              className="size-5 cursor-help text-red-500"
              strokeWidth={1.75}
            />
            <TooltipTop
              positionClass={'invisible group-hover:visible -left-28 bottom-7'}
            >
              스크린샷 저장 시 선택한 항목이 마스킹 처리됩니다
            </TooltipTop>
          </div>
        </div>
        <Controller
          control={control}
          name={'optionsDto.maskingOptions.enabled'}
          render={({ field }) => (
            <SwitchInController
              checked={field.value === true}
              onChange={field.onChange}
              size="sm"
              disabled={maskingOptionsDiabled()}
            />
          )}
        />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default WebTestStepMaskingOptionsHeader;
