import { useSpeedMobileTestCaseDetailQuery } from '@lib/api/ide/speedMobile/speedMobileTestCase';

import PriorityListbox from '@components/shared/HeadlessUI/Listbox/PriorityListbox';
import TestGroupBox from '@components/IDE/TestCase/TestGroupBox';
import ServiceGroupBox from '@components/IDE/TestCase/ServiceGroupBox';

import { findPlatformTypeNameFromServerName } from '@utils/static/platformTypeList';

import { UpdateSpeedMobileTestCaseData } from '@customTypes/speedTestCase/type';

import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

const TestCaseInfoTop = () => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const { control, setValue } = useFormContext<UpdateSpeedMobileTestCaseData>();

  const speedMobileTestCaseDetailQuery = useSpeedMobileTestCaseDetailQuery(idx);
  const speedMobileTestCaseDetail = speedMobileTestCaseDetailQuery.data;

  useEffect(() => {
    if (speedMobileTestCaseDetail) {
      setValue('platformType', speedMobileTestCaseDetail.platformType);
      setValue('priority', speedMobileTestCaseDetail.priority);
      setValue(
        'caseGroupIdx',
        speedMobileTestCaseDetail.caseGroupInfo?.caseGroupIdx,
      );
      setValue(
        'serviceGroupIdx',
        speedMobileTestCaseDetail.serviceGroupInfo?.serviceGroupIdx,
      );
    }
  }, [setValue, speedMobileTestCaseDetail]);

  return (
    <div className="grid gap-4 border-t border-gray-100 sm:grid-cols-5">
      <div className="flex flex-col gap-2 py-6 sm:col-span-1">
        <dt className="text-sm font-medium leading-6">Platform Type</dt>
        <div className="ml-5 text-sm font-medium leading-6 text-gray-900">
          {findPlatformTypeNameFromServerName(
            speedMobileTestCaseDetail?.platformType,
          )}
        </div>
      </div>
      <div className="flex flex-col gap-2 py-6 sm:col-span-1">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          Priority
        </dt>
        <Controller
          name="priority"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <PriorityListbox
              value={field.value || null}
              onChange={field.onChange}
            />
          )}
        />
      </div>
      <div className="flex flex-col gap-2 py-6 sm:col-span-1">
        <TestGroupBox />
      </div>
      <div className="flex flex-col gap-2 py-6 sm:col-span-1">
        <ServiceGroupBox />
      </div>
      <div className="py-6">
        <dt className="text-sm font-medium leading-6 text-gray-900">Creator</dt>
        <div className="relative mt-3 w-full text-sm leading-6 text-gray-700">
          {speedMobileTestCaseDetail?.creator}
        </div>
      </div>
    </div>
  );
};

export default TestCaseInfoTop;
