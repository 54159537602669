import React from 'react';
import dayjs from 'dayjs';

const CheckUpdateDate = () => {
  const buildDate = dayjs(VITE_BUILD_DATE).format('YYYY-MM-DD');
  const buildTime = dayjs(VITE_BUILD_DATE).format('HH:mm:ss');

  return (
    <>
      <p className="text-3xl">v{VITE_APP_VERSION}-Dashboard</p>
      <p className="text-3xl">
        Release Date: {buildDate} {buildTime}
      </p>
    </>
  );
};

export default CheckUpdateDate;
