import { RootState } from '@app/store';
import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';
import NoSaveTestStep from '@components/TestStep/NoSaveTestStep';
import {
  CompareBy,
  CreateTestStepData,
  MobileTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ErrorMessage } from '@hookform/error-message';
import { useMobileTestStepBySaveTextQuery } from '@lib/api/ide/mobile/mobileTestStep';
import { useSpeedMobileTestStepBySaveTextQuery } from '@lib/api/ide/speedMobile/speedMobileTestStep';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { findDescriptionFromIdx } from '@utils/static/findDescriptionFromIdx';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import clsx from 'clsx';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import TextReplaceDateFormat from '../TextReplace/TextReplaceDateFormat';
import TextReplaceFromAndAdd from '../TextReplace/TextReplaceFromAndAdd';
import AutomationOrderContents from './AutomationOrderContents/AutomationOrderContents';
import { preventEnterHandler } from '@utils/helper/preventEnterHandler';

const CompareByContents = () => {
  const {
    register,
    watch,
    control,
    formState: { errors },
  } = useFormContext<CreateTestStepData>();

  const command = watch('command');
  const compareBy = watch('optionsDto.compareBy');
  const isSecret =
    watch('optionsDto.secret') && JSON.parse(watch('optionsDto.secret'));
  const isTextEncrypt =
    watch('optionsDto.textEncrypt') &&
    JSON.parse(watch('optionsDto.textEncrypt'));

  const deviceInfo = useSelector((state: RootState) => state.device.deviceInfo);

  const isUiTestLocation = isWordInLocationPathname('ui-test');

  const getTestStepsByCommandQuery = isUiTestLocation
    ? useMobileTestStepBySaveTextQuery()
    : useSpeedMobileTestStepBySaveTextQuery();

  const saveTextLists = (
    getTestStepsByCommandQuery.data as MobileTestStepData[]
  )?.filter(
    (saveText: MobileTestStepData) => saveText.mobileOs === deviceInfo.os,
  ) as MobileTestStepData[];

  return (
    <ErrorBoundaryWrapper>
      {compareBy === CompareBy.Id && saveTextLists?.length === 0 ? (
        <NoSaveTestStep />
      ) : compareBy === CompareBy.Id && saveTextLists?.length !== 0 ? (
        <ErrorBoundaryWrapper>
          <Controller
            control={control}
            name="optionsDto.compareIdx"
            rules={{ required: 'Test Step을 선택해주세요.' }}
            render={({ field }) => (
              <ListBoxHeadlessInController
                value={field.value}
                buttonValue={
                  findDescriptionFromIdx(Number(field.value), saveTextLists) ||
                  'Select Test Step'
                }
                onChange={field.onChange}
                lists={saveTextLists}
                valueToSave={'idx'}
                valueToShow={'description'}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="optionsDto.compareIdx"
            render={({ message }) => (
              <p className="error-message -mt-2">{message}</p>
            )}
          />
        </ErrorBoundaryWrapper>
      ) : compareBy === CompareBy.Text ? (
        <ErrorBoundaryWrapper>
          <input
            className={clsx('input-base', {
              'password-input': isTextEncrypt || isSecret,
            })}
            type="text"
            placeholder={
              command === MobileCommandOptionServerName.ConfirmText
                ? '비교할 텍스트를 직접 입력해 주세요.'
                : '텍스트를 입력해 주세요.'
            }
            {...register('optionsDto.compareText', {
              required: '텍스트를 입력해 주세요.',
            })}
            onKeyDown={(e) => preventEnterHandler(e, isTextEncrypt || isSecret)}
          />
          <ErrorMessage
            errors={errors}
            name="optionsDto.compareText"
            render={({ message }) => (
              <p className="error-message -mt-2">{message}</p>
            )}
          />
        </ErrorBoundaryWrapper>
      ) : command === MobileCommandOptionServerName.ConfirmText &&
        compareBy === CompareBy.Replace ? (
        <div className="flex flex-col gap-4 text-sm font-medium leading-normal text-gray-900">
          <TextReplaceFromAndAdd />
          <TextReplaceDateFormat />
        </div>
      ) : (
        command === MobileCommandOptionServerName.InputText &&
        compareBy === CompareBy.Automation && <AutomationOrderContents />
      )}
    </ErrorBoundaryWrapper>
  );
};

export default CompareByContents;
