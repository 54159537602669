import {
  ErrorBoundaryWrapper,
  SpeedTestResultTableHeader,
} from '@autosquare/common';
import {
  DeviceInfoOs,
  DeviceInfoOsFullName,
} from '@customTypes/ide/device/device';
import { MobileSpeedTestResultDetailData } from '@customTypes/ide/speedTest/Mobile/testResult/type';
import dayjs from 'dayjs';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

type Props = {
  data: MobileSpeedTestResultDetailData;
};
const InformationTable = ({ data }: Props) => {
  const [searchParams] = useSearchParams();
  const start = searchParams.get('start');
  const end = searchParams.get('end');
  const startDate = dayjs(start).format('YYYY-MM-DD');
  const endDate = dayjs(end).format('YYYY-MM-DD');
  const osInfo =
    data.os === DeviceInfoOs.Aos
      ? DeviceInfoOsFullName.Aos
      : DeviceInfoOsFullName.Ios;
  return (
    <div className="pb-4">
      <div className="pb-3 pt-6 text-sm font-semibold text-gray-900">
        Information
      </div>
      <ErrorBoundaryWrapper>
        <table className="min-w-full bg-white">
          <thead className="bg-gray-50">
            <tr className="border-b border-gray-200">
              <SpeedTestResultTableHeader width="text-left h-[3.625rem]">
                Tester
              </SpeedTestResultTableHeader>
              <SpeedTestResultTableHeader width="text-left ">
                Date
              </SpeedTestResultTableHeader>
              <SpeedTestResultTableHeader width="text-left">
                OS
              </SpeedTestResultTableHeader>
              <SpeedTestResultTableHeader width="text-left">
                Device
              </SpeedTestResultTableHeader>
              <SpeedTestResultTableHeader width="text-left">
                Title
              </SpeedTestResultTableHeader>
              <SpeedTestResultTableHeader width="text-left">
                Type
              </SpeedTestResultTableHeader>
            </tr>
          </thead>
          <tbody>
            <tr className="h-[3.625rem]">
              <td className="px-3 py-2 text-sm text-gray-900">
                {data?.tester}
              </td>
              <td className="px-3 py-2 text-sm text-gray-900">
                <div>
                  {startDate} &#126; &#32;
                  {endDate}
                </div>
              </td>
              <td className="px-3 py-2 text-left text-sm text-gray-900">
                {osInfo}
              </td>
              <td className="px-3 py-2 text-left text-sm text-gray-900">
                {data?.device}
              </td>
              <td className="truncate px-3 py-2 text-left text-sm text-gray-900">
                {data?.title}
              </td>
              <td className="px-3 py-2 text-left text-sm text-gray-900">
                {data?.testType}
              </td>
            </tr>
          </tbody>
        </table>
      </ErrorBoundaryWrapper>
    </div>
  );
};

export default InformationTable;
