import {
  ErrorBoundaryWrapper,
  PageHeadingButtons,
  PagingHeadings,
  RoleAuth,
  useGlobalLoadingStore,
} from '@autosquare/common';
import { useAuthUserInfo } from '@hooks/index';
import { useProjectSettingDetailQuery } from '@lib/api/dashboard/queryHooks';
import React from 'react';
import { useLocation } from 'react-router-dom';

const ProjectSettingsDetailPagingHeadings = () => {
  const { pathname } = useLocation();

  const { isLoading, isSuccess } = useProjectSettingDetailQuery();
  const { userProjectRole, userAuthRole } = useAuthUserInfo();

  const isUpdateLoading = useGlobalLoadingStore((state) => state.isLoading);

  const breadcrumbs = [
    { title: 'Project Settings', href: pathname },
    { title: 'Detail', href: pathname },
  ];

  const getActionButtons = (): PageHeadingButtons[] => {
    if (isLoading) {
      return null;
    }

    if (
      isSuccess &&
      (userProjectRole === 0 || userAuthRole === RoleAuth.Admin)
    ) {
      return [
        {
          title: 'Save',
          type: 'submit',
          variant: 'primary',
          form: 'project-settings-detail-form',
          disabled: isUpdateLoading,
        },
      ];
    }

    return null;
  };

  const pageHeadingButtons = getActionButtons();

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={'Detail'}
        pageHeadingButtons={pageHeadingButtons}
      />
    </ErrorBoundaryWrapper>
  );
};

export default ProjectSettingsDetailPagingHeadings;
