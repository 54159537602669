import React from 'react';
import { SpeedTestResultDetailsSkeleton } from '@autosquare/common';
import { useIdeWebSpeedTestResultTableDetailQuery } from '@lib/api/ide/speedWeb/webSpeedTestResult';
import SpeedInformationTable from './SpeedInformationTable/SpeedInformationTable';
import SpeedRepeatResult from './SpeedRepeatResult/SpeedRepeatResult';
import { useWebSpeedTestResultTableDetailQuery } from '@lib/api/dashboard/web/webSpeedResult';

type Props = {
  chartIndex: number;
  isDashboard: boolean;
};

const WebSpeedTestResultTableDetail = ({ chartIndex, isDashboard }: Props) => {
  const speedWebTestResultTableQuery = isDashboard
    ? useWebSpeedTestResultTableDetailQuery(String(chartIndex), {
        enabled: chartIndex !== null && isDashboard,
      })
    : useIdeWebSpeedTestResultTableDetailQuery(String(chartIndex), {
        enabled: chartIndex !== null && !isDashboard,
      });

  const speedWebTestResultTableData = speedWebTestResultTableQuery.data;
  const isPass = speedWebTestResultTableData?.info.loadSpeedAverage > 0;
  return (
    <>
      <div className="pb-3 pt-6 font-semibold text-gray-900">
        {speedWebTestResultTableData?.info.service} Result Details
      </div>
      {speedWebTestResultTableQuery.isLoading ||
      speedWebTestResultTableQuery.isFetching ? (
        <SpeedTestResultDetailsSkeleton />
      ) : speedWebTestResultTableQuery.isError ? (
        <p className="standard-error-message">
          {speedWebTestResultTableQuery.error.message}
        </p>
      ) : (
        <>
          <SpeedInformationTable
            speedWebTestResultTableData={speedWebTestResultTableData}
          />
          {isPass && (
            <SpeedRepeatResult
              speedWebTestResultTableData={speedWebTestResultTableData}
            />
          )}
        </>
      )}
    </>
  );
};

export default WebSpeedTestResultTableDetail;
