import {
  ErrorBoundaryWrapper,
  TableBodyBasic,
  TableBodyGray,
} from '@autosquare/common';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { usePostImportListMutation } from '@lib/api/ide/mobile/mobileTestCase';
import {
  ImportTestCaseResponse,
  TestCaseData,
} from '@customTypes/testCase/type';

type Props = {
  index: number;
  testCase: TestCaseData;
  setSuccessfulImportNumber: React.Dispatch<React.SetStateAction<number>>;
};

const TestCaseImportStepThreeTable = ({
  index,
  testCase,
  setSuccessfulImportNumber,
}: Props) => {
  const { projectIdx } = useParams();
  const [data, setData] = useState<ImportTestCaseResponse>(undefined);

  const importTestCaseMutation = usePostImportListMutation(projectIdx);

  useEffect(() => {
    importTestCaseMutation.mutate(testCase, {
      onSuccess: (response) => {
        setData(response);
        if (response?.importResult === 'done') {
          setSuccessfulImportNumber((prev) => prev + 1);
        }
      },
    });
  }, []);

  return (
    <ErrorBoundaryWrapper>
      <tr key={testCase.uniqueKey}>
        <TableBodyBasic isFirstPaddingZero={true}>{index + 1}</TableBodyBasic>
        <TableBodyGray>{testCase.title}</TableBodyGray>
        <td className="table-data-cell-gray max-w-xs break-words">
          {testCase.description}
        </td>
        <TableBodyGray>
          AOS: {testCase.aosStepCount} / iOS: {testCase.iosStepCount}
        </TableBodyGray>
        <TableBodyGray>
          {testCase.priority[0] + testCase.priority.slice(1).toLowerCase()}
        </TableBodyGray>
        <TableBodyBasic>
          {data?.importResult === 'done' ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-green-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          ) : data?.importResult === 'fail' ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-red-600"
            >
              <title>{data?.failReason}</title>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : data?.importResult === 'doing' ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 animate-spin text-orange-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          )}
        </TableBodyBasic>
      </tr>
    </ErrorBoundaryWrapper>
  );
};

export default TestCaseImportStepThreeTable;
