import React from 'react';
import DeviceDetailFormFields from './DeviceDetailFormFields';
import { useFormContext } from 'react-hook-form';
import { UpdateProjectDeviceDetail } from '@customTypes/index';

const DeviceDetailDeviceId = () => {
  const { register } = useFormContext<UpdateProjectDeviceDetail>();

  return (
    <DeviceDetailFormFields
      title={
        'Device ID* (영문 대소문자, 숫자[0-9], 대시(-), 언더바(_) 입력 가능)'
      }
      name={'udid'}
    >
      <input
        className="input-base disabled:bg-gray-100"
        placeholder="UDID(iOS) 또는 Serial Number(Android)를 입력해 주세요."
        {...register('udid')}
        disabled
      />
    </DeviceDetailFormFields>
  );
};

export default DeviceDetailDeviceId;
