import {
  Button,
  ErrorBoundaryWrapper,
  PagingHeadings,
} from '@autosquare/common';
import { useAdminDeviceApprovalListQuery } from '@lib/api/dashboard/queryHooks';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const AdminDeviceListHeader = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { data: adminDeviceApprovalList, isSuccess } =
    useAdminDeviceApprovalListQuery();

  const breadcrumbs = [
    { title: 'Administration', href: pathname },
    { title: 'Devices', href: pathname },
    { title: 'List', href: pathname },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="flex items-end justify-between">
        <PagingHeadings breadcrumbs={breadcrumbs} pageTitle={'Device List'} />
        <div className="flex items-center gap-2">
          <div className="relative">
            <button
              className="flex justify-center rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 disabled:bg-gray-200"
              type="button"
              onClick={() => navigate('approval')}
            >
              Approval
            </button>
            {isSuccess && adminDeviceApprovalList?.length > 0 && (
              <div className="flex-center absolute -left-3 -top-3 size-6 rounded-full bg-red-500 text-sm font-semibold leading-tight text-white">
                {adminDeviceApprovalList?.length}
              </div>
            )}
          </div>
          <Button type="button" onClick={() => navigate('add')}>
            Add
          </Button>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default AdminDeviceListHeader;
