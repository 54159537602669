import React from 'react';
import AdminDeviceDetailFormFields from './AdminDeviceDetailFormFields';
import { useFormContext } from 'react-hook-form';
import { UpdateAdministrationDevice } from '@customTypes/index';

const DeviceIdFields = () => {
  const { register } = useFormContext<UpdateAdministrationDevice>();

  return (
    <AdminDeviceDetailFormFields
      id={'udid'}
      label={
        'Device ID* (영문 대소문자, 숫자[0-9], 대시(-), 언더바(_) 입력 가능)'
      }
    >
      <input
        className="input-base disabled:bg-gray-100"
        placeholder="UDID(iOS) 또는 Serial Number(Android)를 입력해 주세요."
        {...register('udid')}
        disabled
      />
    </AdminDeviceDetailFormFields>
  );
};

export default DeviceIdFields;
