import { useState } from 'react';
import { UpdateAdminProjectDetailData } from '@customTypes/dashboard/admin/type';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { UseMutationResult } from '@tanstack/react-query';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { ProjectListDetail } from '@customTypes/dashboard/project/type';
import { useAdminAvailableMessengerListQuery } from '@lib/api/dashboard/queryHooks';

const useAdminProjectEditFormHandler = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  methods: UseFormReturn<UpdateAdminProjectDetailData, any, undefined>,
  updateAdminProjectDetailMutation: UseMutationResult<
    unknown,
    ServerResponseErrorDetails,
    UpdateAdminProjectDetailData,
    unknown
  >,
  adminProjectData: ProjectListDetail,
) => {
  const messengerPlatform = methods.watch('messengers.0.platform');
  const [isOpenedCautionDialog, setIsOpenedCautionDialog] = useState(false);

  const isAutoRegisterToggleOn =
    methods.watch('registers').length > 0 &&
    methods.watch('registers.0.activate');

  const [isToggleOn, setIsToggleOn] = useState(isAutoRegisterToggleOn);

  const { data: messengerList } = useAdminAvailableMessengerListQuery();

  const onSubmit: SubmitHandler<UpdateAdminProjectDetailData> = (data) => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      recipientFormat,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      recipientId,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      recipientInfo,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      platform,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      platformInput,
      ...rest
    } = data;

    const updatedMessengers = rest.messengers.map((messenger) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { activate, ...restDefaultData } = adminProjectData
        .messengers?.[0] || { activate: false };

      if (messenger.activate) {
        return messenger;
      }
      if (!messenger.activate && messengerPlatform != undefined) {
        return {
          activate: false,
          ...restDefaultData,
        };
      }
      if (!messenger.activate && messengerPlatform === undefined) {
        return [];
      }
    });

    const updatedRegisters = rest.registers.map((register) => ({
      ...register,
      activate: isToggleOn,
    }));

    const cleanedData = {
      ...rest,
      messengers: updatedMessengers,
      registers: updatedRegisters,
    };

    const platformCode = messengerList?.find(
      (messenger) => messenger.serverName === rest?.messengers[0]?.platform,
    )?.platformCode;

    if (cleanedData.projectAdminList.length === 0) {
      methods.setError('projectAdminList', {
        type: 'required',
        message: 'Project Admin을 1명 이상 선택해주세요.',
      });
    } else if (cleanedData.features.every((feature) => !feature.activate)) {
      methods.setError('features', {
        type: 'required',
        message: '프로젝트 기능을 1개 이상 선택해주세요.',
      });
    } else if (cleanedData.registers.length === 0 && isToggleOn) {
      methods.setError('registers', {
        type: 'required',
        message: '필수 선택 항목입니다.',
      });
    } else if (
      updatedMessengers?.[0]?.activate &&
      platformCode !== 'MSGR02' &&
      !rest.messengers[0]?.recipientList
    ) {
      methods.setError('recipientId', {
        type: 'required',
        message: 'Recipient ID는 필수 입력 항목입니다.',
      });
    } else {
      updateAdminProjectDetailMutation.mutate(cleanedData);
    }
  };

  return {
    onSubmit,
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    isToggleOn,
    setIsToggleOn,
  };
};

export default useAdminProjectEditFormHandler;
