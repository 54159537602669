import { RootState } from '@app/store';
import {
  ArrowSpinner,
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
} from '@autosquare/common';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';
import RemoteTestExecutionAlarmModal from '@components/TestExecution/shared/RemoteTestExecutionAlarmModal';
import TestExecutionContainer from '@components/TestExecution/TestExecutionContainer';
import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { useRemoteMobileTestExecutionMutation } from '@lib/api/ide/remote/remote';
import { useDeleteSpeedMobileTestStep } from '@lib/api/ide/speedMobile/speedMobileTestStep';

import React, { useState } from 'react';
import { useSelector } from 'react-redux';

interface Props {
  isTestStepExecutionOpened: boolean;
  setIsTestStepExecutionOpened: React.Dispatch<React.SetStateAction<boolean>>;
  title: DeviceInfoOs;
  isOpenedCautionDialog: boolean;
  setIsOpenedCautionDialog: React.Dispatch<React.SetStateAction<boolean>>;
  selectedArray: number[];
  openDialog: () => void;
  closeDialog: () => void;
  resetSelectedArray: () => void;
}

const SpeedExecutionAndDialog = ({
  isTestStepExecutionOpened,
  setIsTestStepExecutionOpened,
  title,
  isOpenedCautionDialog,
  setIsOpenedCautionDialog,
  selectedArray,
  openDialog,
  closeDialog,
  resetSelectedArray,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExecutionErrorModalOpen, setIsExecutionErrorModalOpen] =
    useState(false);
  const testStepIdx = useSelector(
    (state: RootState) => state.mobileTestStepDetail.testStepIdx,
  );

  const deleteTestStepMutation = useDeleteSpeedMobileTestStep();

  const remoteMobileStepExecutionMutation =
    useRemoteMobileTestExecutionMutation('step');

  return (
    <ErrorBoundaryWrapper>
      {isTestStepExecutionOpened && (
        <TestExecutionContainer
          setState={setIsTestStepExecutionOpened}
          idx={testStepIdx}
          testType={'step'}
          deviceType={'mobile'}
          mobileOS={title}
          setIsModalOpen={setIsModalOpen}
          remoteMobileExecutionMutation={remoteMobileStepExecutionMutation}
          setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
        />
      )}
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedCautionDialog}
        setIsOpened={setIsOpenedCautionDialog}
        type="caution"
        title={deleteTestStepMutation.isError ? 'Error' : 'Delete Test Step'}
        subTitle={
          deleteTestStepMutation.isError
            ? deleteTestStepMutation.error.message
            : `해당 테스트 스텝을 삭제하시겠습니까?\n설정한 Repeat Step 옵션도 같이 삭제 처리됩니다.`
        }
        buttonChildren={
          <>
            {!deleteTestStepMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  deleteTestStepMutation.mutate(
                    { stepIdxList: selectedArray, mobileOs: title },
                    {
                      onSuccess: () => {
                        closeDialog();
                        resetSelectedArray();
                      },
                      onError: () => {
                        openDialog();
                      },
                    },
                  )
                }
              >
                Delete
              </Button>
            )}
            <Button type="button" variant="secondary" onClick={closeDialog}>
              Cancel
            </Button>
          </>
        }
      />
      {remoteMobileStepExecutionMutation.isLoading && <ArrowSpinner />}
      <RemoteTestExecutionAlarmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isExecutionErrorModalOpen={isExecutionErrorModalOpen}
        setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
      />
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default SpeedExecutionAndDialog;
