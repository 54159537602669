import React from 'react';
import { CreateWebTestStepData } from '@customTypes/testStep/type';
import { Control, Controller } from 'react-hook-form';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Props = {
  control: Control<CreateWebTestStepData>;
  name?: 'optionsDto.secret' | 'optionsDto.textEncrypt';
};

const WebSecretBox = ({ control, name = 'optionsDto.secret' }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div className="flex justify-start">
        <label
          htmlFor="secret"
          className="flex items-center justify-between gap-2 text-sm font-medium leading-6 text-gray-900"
        >
          <legend>Secret</legend>
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <input
                id="secret"
                name="secret"
                type="checkbox"
                className="size-4 rounded border border-solid border-gray-300 text-congress-blue"
                defaultChecked={false}
                {...field}
              />
            )}
          />
        </label>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default WebSecretBox;
