import {
  ErrorMessage,
  PagingHeadingsInAutosquare,
  PlatFormTypeServerName,
  useScrollEvent,
} from '@autosquare/common';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '@app/store';
import { useGetWebSocketMessage } from '@hooks/electron/useGetWebSocketMessage';

import DetailStopAndLoading from './MobileTestExecutionDetail/DetailStopAndLoading';
import {
  useSpeedExecutionMobileTestCaseMutation,
  useSpeedExecutionMobileTestScenarioMutation,
  useSpeedExecutionMobileWebTestCaseMutation,
} from '@lib/api/ide/speedMobile/speedMobileExecution';
import { useFormContext } from 'react-hook-form';
import {
  SpeedMobileCaseExecution,
  SpeedMobileScenarioExecution,
} from '@customTypes/speedTestExecution/speedTestExecution';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';
import { useTestExecutionContext } from '@contexts/ide/TestExecutionProvider';

interface Props {
  idx: number;
  closeModalExecution: () => void;
  platformType: PlatFormTypeServerName;
  testType: TestTypeCategory;
}

const SpeedMobileTestExecutionDetail = ({
  idx,
  closeModalExecution,
  platformType,
  testType,
}: Props) => {
  const { projectIdx } = useParams();

  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );
  const selectedDevices = useSelector(
    (state: RootState) => state.device.selectedDevicesArray,
  );
  const [isTestExecutionStopped, setIsTestExecutionStopped] = useState(false);
  const [totalTestsToComplete, setTotalTestsToComplete] = useState<number>(0);
  const [currentCompletedTests, setCurrentCompletedTests] = useState<number>(0);

  const { webSocketMessages, isTestRunning, setIsTestRunning } =
    useGetWebSocketMessage();
  const { scrollRef } = useScrollEvent(webSocketMessages);
  const { watch } = useFormContext<SpeedMobileCaseExecution>();
  const { shouldSaveVideo, shouldSaveLogFile } = useTestExecutionContext();

  const repeatCount = Number(watch('repeatCount'));

  const defaultTestData = {
    infos: selectedDevices,
    projectIdx: Number(projectIdx),
    repeatCount: repeatCount,
  };

  const testCaseData: SpeedMobileCaseExecution = {
    ...defaultTestData,
    caseIdx: idx,
    saveVideo: shouldSaveVideo,
    saveLog: shouldSaveLogFile,
  };

  const testScenarioData: SpeedMobileScenarioExecution = {
    ...defaultTestData,
    scenarioIdx: idx,
    saveVideo: shouldSaveVideo,
    saveLog: shouldSaveLogFile,
  };

  const executeMobileTest =
    platformType === PlatFormTypeServerName.MobileApp
      ? useSpeedExecutionMobileTestCaseMutation(cookie, testCaseData)
      : platformType === PlatFormTypeServerName.MobileWeb &&
        useSpeedExecutionMobileWebTestCaseMutation(cookie, testCaseData);

  const speedExecutionMobileTestScenarioMutation =
    useSpeedExecutionMobileTestScenarioMutation(cookie);

  useEffect(() => {
    if (cookie !== null) {
      if (testType === 'case') {
        executeMobileTest.mutate();
      }
      if (testType === 'scenario') {
        speedExecutionMobileTestScenarioMutation.mutate(testScenarioData);
      }
    }
  }, [cookie]);

  useEffect(() => {
    if (
      webSocketMessages[webSocketMessages.length - 1]?.includes(
        '테스트 진행을 위해 디바이스 세션 연결을 진행합니다',
      )
    ) {
      setTotalTestsToComplete((prev) => prev + 1);
    }
  }, [webSocketMessages]);

  useEffect(() => {
    if (
      isTestExecutionStopped &&
      webSocketMessages[webSocketMessages.length - 1]?.includes(
        '테스트가 완전히 종료되었습니다',
      )
    ) {
      setCurrentCompletedTests((prev) => prev + 1);
    }
  }, [webSocketMessages, isTestExecutionStopped]);

  useEffect(() => {
    if (
      isTestExecutionStopped &&
      currentCompletedTests === totalTestsToComplete
    ) {
      closeModalExecution();
      setIsTestExecutionStopped(false);
    }
  }, [currentCompletedTests, totalTestsToComplete, isTestExecutionStopped]);

  const breadcrumbs = [
    { title: 'Speed Test' },
    { title: 'Mobile' },
    {
      title:
        testType === 'scenario'
          ? 'Test Scenario'
          : testType === 'case' && 'Test Case',
    },
    { title: 'Execution' },
    { title: 'Detail' },
  ];

  return (
    <>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Execution Detail'}
      />
      <div className="h-[calc(100%-7rem)]">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Execution Log
        </h2>
        <div
          className={`flex h-[calc(100%-3rem)] flex-col gap-2 overflow-auto overflow-y-scroll rounded-md border border-gray-200 bg-gray-50 px-4 py-2 scrollbar-thin scrollbar-track-rounded-full scrollbar-thumb-rounded-full`}
          ref={scrollRef}
        >
          {executeMobileTest.isError ? (
            <ErrorMessage margin="my-0">
              {executeMobileTest.error.message}
            </ErrorMessage>
          ) : (
            webSocketMessages?.map((webSocketMessage, idx) => (
              <p key={idx} className="text-sm leading-6">
                {webSocketMessage}
              </p>
            ))
          )}
        </div>
        <DetailStopAndLoading
          testType="case"
          isTestExecutionStopped={isTestExecutionStopped}
          isTestRunning={isTestRunning}
          setIsTestRunning={setIsTestRunning}
          setIsTestExecutionStopped={setIsTestExecutionStopped}
          closeModalExecution={closeModalExecution}
        />
      </div>
    </>
  );
};

export default SpeedMobileTestExecutionDetail;
