import { JiraMappingType } from '@customTypes/ide/scheduler/scheduler';

export const hasDuplicateTestResults = (
  array: JiraMappingType[],
  key: string = 'testResult',
) =>
  array.filter(
    (item, index, arr) =>
      arr.findIndex((element) => element[key] === item[key]) !== index,
  ).length > 0;
