import React, { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import SwitchInController from '@components/shared/HeadlessUI/Switch/SwitchInController';
import { UpdateAdminProjectDetailData } from '@customTypes/dashboard/admin/type';
import { Controller, useFormContext } from 'react-hook-form';
import EditMessengerPlatform from './EditMessenger/EditMessengerPlatform';
import EditMessenger10to12 from './EditMessenger/EditMessenger10to12';
import EditMessenger01 from './EditMessenger/EditMessenger01';
import EditMessenger02 from './EditMessenger/EditMessenger02';
import { ProjectListDetail } from '@customTypes/dashboard/project/type';
import {
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
} from '@autosquare/common';
import { useAdminAvailableMessengerListQuery } from '@lib/api/dashboard/queryHooks';

type Props = {
  adminProjectData: ProjectListDetail;
};

const ProjectEditMessenger = ({ adminProjectData }: Props) => {
  const { control, watch, clearErrors, setValue } =
    useFormContext<UpdateAdminProjectDetailData>();
  const [isOpenedCautionDialog, setIsOpenedCautionDialog] = useState(false);
  const [changePlatformCount, setChangePlatformCount] = useState(0);
  const messengerListQuery = useAdminAvailableMessengerListQuery();
  const messengerList = messengerListQuery.data;

  const isToggleOn = watch('messengers.0.activate') ?? false;
  const platform = watch('messengers.0.platform');

  const platformCode = messengerList?.find(
    (messenger) => messenger.serverName === platform,
  )?.platformCode;
  const defaultPlatform = adminProjectData.messengers[0]?.platform;

  const okButtonHandler = () => {
    clearErrors('messengers');
    clearErrors('recipientFormat');
    if (changePlatformCount === 1) {
      setValue('messengers.0.platform', undefined);
    }
    setIsOpenedCautionDialog(false);
  };

  const cancelButtonHandler = () => {
    setIsOpenedCautionDialog(false);
    setValue('messengers.0.activate', true);
  };

  useEffect(() => {
    if (!isToggleOn) {
      clearErrors('recipientFormat');
      clearErrors('messengers');
      if (adminProjectData.messengers.length === 0) {
        setValue('messengers', []);
      }
    }
  }, [isToggleOn, setValue, clearErrors]);

  return (
    <ErrorBoundaryWrapper>
      <div className="flex items-center justify-between gap-x-3 pb-6">
        <ErrorBoundaryWrapper>
          <div className="flex gap-x-3">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Messenger
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              프로젝트에서 테스트 결과를 발송할 메신저 알림을 설정해 주세요.
            </p>
          </div>
          <div className="flex justify-end">
            <Controller
              control={control}
              name={`messengers.0.activate`}
              defaultValue={isToggleOn}
              render={({ field }) => (
                <SwitchInController
                  checked={field.value}
                  onChange={(value) => {
                    if (defaultPlatform && !value) {
                      setIsOpenedCautionDialog(true);
                    }
                    field.onChange(value);
                  }}
                />
              )}
            />
          </div>
        </ErrorBoundaryWrapper>
      </div>
      {isToggleOn && (
        <div
          className={clsx(
            'transition duration-700 ease-in-out',
            isToggleOn ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0',
          )}
        >
          <div className="w-full pb-12 sm:space-y-0 sm:pb-0">
            <EditMessengerPlatform
              messengerList={messengerList}
              defaultPlatform={defaultPlatform}
              changePlatformCount={changePlatformCount}
              setChangePlatformCount={setChangePlatformCount}
            />
            {platformCode === 'MSGR10' ||
            platformCode === 'MSGR11' ||
            platformCode === 'MSGR12' ? (
              <EditMessenger10to12
                adminProjectData={adminProjectData}
                changePlatformCount={changePlatformCount}
              />
            ) : platformCode === 'MSGR01' ? (
              <EditMessenger01
                adminProjectData={adminProjectData}
                changePlatformCount={changePlatformCount}
              />
            ) : (
              platformCode === 'MSGR02' && <EditMessenger02 />
            )}
          </div>
        </div>
      )}
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedCautionDialog}
        setIsOpened={setIsOpenedCautionDialog}
        type="caution"
        title={'Disable Messenger'}
        subTitle={`메신저 알람을 비활성화 상태로 전환하면 해당 프로젝트에 생성되어 \n있는 스케줄러 메신저 알람 설정이 모두 삭제됩니다. \n \n(메신저 설정을 위해 입력한 정보는 유지됩니다.)`}
        buttonChildren={
          <>
            <Button type="submit" variant="caution" onClick={okButtonHandler}>
              OK
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={cancelButtonHandler}
            >
              Cancel
            </Button>
          </>
        }
      />
    </ErrorBoundaryWrapper>
  );
};

export default ProjectEditMessenger;
