import { ErrorBoundaryWrapper } from '@autosquare/common';
import { useMobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';
import { canvasHandleCursor } from '@hooks/konva/canvasHandleCursor';
import Konva from 'konva';
import React, { useEffect, useRef } from 'react';
import { Rect, Transformer } from 'react-konva';

type Props = {
  isSelected: boolean;
  setIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
  realScreenRatio: number;
};

const MaskingRect = ({ isSelected, setIsSelected, realScreenRatio }: Props) => {
  const {
    maskingStartX,
    maskingStartY,
    maskingEndX,
    maskingEndY,
    setMaskingStartX,
    setMaskingStartY,
    setMaskingEndX,
    setMaskingEndY,
  } = useMobileTestStepContext();

  const rectRef = useRef<Konva.Rect>(null);
  const trRef = useRef<Konva.Transformer>(null);

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([rectRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const onSelect = () => {
    setIsSelected(true);
  };

  const rectProps = {
    x: Math.round(maskingStartX * realScreenRatio),
    y: Math.round(maskingStartY * realScreenRatio),
    width: Math.round(
      Math.abs(maskingEndX * realScreenRatio - maskingStartX * realScreenRatio),
    ),
    height: Math.round(
      Math.abs(maskingEndY * realScreenRatio - maskingStartY * realScreenRatio),
    ),
    fill: '#FFA500',
    id: 'maskingRect',
  };

  return (
    <ErrorBoundaryWrapper>
      <Rect
        ref={rectRef}
        {...rectProps}
        draggable
        onClick={onSelect}
        onTab={onSelect}
        opacity={0.5}
        onDragEnd={(e) => {
          setMaskingStartX(Math.ceil(e.target.x() / realScreenRatio));
          setMaskingStartY(Math.ceil(e.target.y() / realScreenRatio));
          setMaskingEndX(
            Math.ceil((e.target.x() + e.target.width()) / realScreenRatio),
          );
          setMaskingEndY(
            Math.ceil((e.target.y() + e.target.height()) / realScreenRatio),
          );
        }}
        onTransformEnd={() => {
          const node = rectRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);

          setMaskingStartX(Math.ceil(node.x() / realScreenRatio));
          setMaskingStartY(Math.ceil(node.y() / realScreenRatio));
          setMaskingEndX(
            Math.ceil((node.x() + node.width() * scaleX) / realScreenRatio),
          );
          setMaskingEndY(
            Math.ceil((node.y() + node.height() * scaleY) / realScreenRatio),
          );
        }}
        onMouseEnter={canvasHandleCursor}
        onMouseLeave={canvasHandleCursor}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          keepRatio={false}
          flipEnabled={false}
        />
      )}
    </ErrorBoundaryWrapper>
  );
};

export default MaskingRect;
