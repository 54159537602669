import { useMobileTestStepBySaveTextQuery } from '@lib/api/ide/mobile/mobileTestStep';

import InputRadio from '@components/shared/TestStepDetail/InputRadio';
import Textarea from '@components/shared/TestStepDetail/Textarea';
import SecretEditBox from '@components/TestStep/SecretEditBox';

import {
  CompareBy,
  CompareRange,
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import { useSpeedMobileTestStepBySaveTextQuery } from '@lib/api/ide/speedMobile/speedMobileTestStep';

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import TestStepSelectSaveText from './TestStepConfirmOrInputText/TestStepSelectSaveText';
import From from '../ClickTextReplace/From';
import Add from '../ClickTextReplace/Add';
import DateFormat from '../ClickTextReplace/DateFormat';
import DefinitionList from '@components/shared/DefinitionList';
import EditAutomationContents from './TestStepConfirmOrInputText/EditAutomationContents.tsx/EditAutomationContents';
import EditInputTextSecretAndTextEncrypt from './TestStepConfirmOrInputText/EditInputTextSecretAndTextEncrypt/EditInputTextSecretAndTextEncrypt';
import { useGetTestCaseDataAutomation } from '@lib/api/ide/mobile/mobileTestAutomation';
import { useGetSpeedTestCaseDataAutomation } from '@lib/api/ide/speedMobile/mobileTestAutomation';
import { ErrorBoundaryWrapper } from '@autosquare/common';

interface Props {
  mobileTestStep: MobileTestStepData;
}

const TestStepConfirmOrInputText = ({ mobileTestStep }: Props) => {
  const uiLocation = isWordInLocationPathname('ui-test');
  const mobileTestStepBySaveTextQuery = uiLocation
    ? useMobileTestStepBySaveTextQuery()
    : useSpeedMobileTestStepBySaveTextQuery();

  const saveTextLists = (
    mobileTestStepBySaveTextQuery.data as MobileTestStepData[]
  )?.filter(
    (saveText: MobileTestStepData) =>
      saveText.mobileOs === mobileTestStep.mobileOs,
  ) as MobileTestStepData[];

  const { data: automationData } = uiLocation
    ? useGetTestCaseDataAutomation()
    : useGetSpeedTestCaseDataAutomation();

  const { watch, setValue, control, clearErrors } =
    useFormContext<UpdateTestStepData>();
  const compareBy = watch('optionsDto.compareBy');

  const radioDefaultOptions = [
    {
      id: 'id',
      title: '스텝 선택',
      defaultChecked: mobileTestStep.optionsDto.compareBy === CompareBy.Id,
      onClick: () => {
        setValue(
          'optionsDto.compareIdx',
          mobileTestStep.optionsDto.compareBy === CompareBy.Id
            ? mobileTestStep.optionsDto.compareIdx
            : undefined,
        );
        setValue('optionsDto.textReplace', undefined);
        setValue('optionsDto.textReplaceOptions', undefined);
        setValue('optionsDto.autoColumn', undefined);
        setValue('optionsDto.targetData', undefined);
      },
    },
    {
      id: 'text',
      title: '직접 입력',
      defaultChecked: mobileTestStep.optionsDto.compareBy === CompareBy.Text,
      onClick: () => {
        setValue(
          'optionsDto.compareIdx',
          mobileTestStep.optionsDto.compareBy === CompareBy.Id
            ? mobileTestStep.optionsDto.compareIdx
            : undefined,
        );
        setValue(
          'optionsDto.compareText',
          mobileTestStep.optionsDto.compareBy === CompareBy.Text
            ? mobileTestStep.optionsDto.compareText
            : undefined,
        );
        setValue('optionsDto.textReplace', undefined);
        setValue('optionsDto.textReplaceOptions', undefined);
        setValue('optionsDto.autoColumn', undefined);
        setValue('optionsDto.targetData', undefined);
      },
    },
  ];
  const radioWithTextReplace = {
    id: 'replace',
    title: 'Text Replace',
    defaultChecked: mobileTestStep.optionsDto.textReplace === 'true',
    onClick: () => {
      setValue('optionsDto.textReplace', 'true');
      setValue('optionsDto.compareText', undefined);
      setValue('optionsDto.compareRange', CompareRange.Equal);
      setValue('optionsDto.compareIdx', undefined);
      setValue('optionsDto.targetData', undefined);
    },
  };

  const radioOptionsWithAutomation = {
    id: 'automation',
    title: 'Automation',
    defaultChecked:
      mobileTestStep.optionsDto.compareBy === CompareBy.Automation,
    onClick: () => {
      setValue('optionsDto.compareIdx', undefined);
      setValue('optionsDto.compareText', undefined);
      setValue('optionsDto.textReplace', undefined);
      setValue('optionsDto.compareBy', CompareBy.Automation);
      setValue('optionsDto.textEncrypt', undefined);
      setValue('optionsDto.targetData', automationData?.targetData);
    },
  };
  const radioOptions =
    mobileTestStep.command === MobileCommandOptionServerName.ConfirmText &&
    uiLocation
      ? [...radioDefaultOptions, radioWithTextReplace]
      : [...radioDefaultOptions, radioOptionsWithAutomation];

  const equalAndContainRadioOptions = [
    {
      title: '동일',
      id: 'equal',
      defaultChecked:
        mobileTestStep.optionsDto.compareRange === CompareRange.Equal,
    },
    {
      title: '포함',
      id: 'contain',
      defaultChecked:
        mobileTestStep.optionsDto.compareRange === CompareRange.Contain,
    },
  ];

  const isSecret = watch('optionsDto.secret') === 'true';

  const isSavedSecretInput = mobileTestStep.optionsDto?.secret === 'true';

  useEffect(() => {
    if (
      mobileTestStep.optionsDto.compareBy === CompareBy.Text &&
      mobileTestStep.optionsDto.textEncrypt === 'true'
    ) {
      setValue('optionsDto.compareText', null);
    }
  }, []);

  useEffect(() => {
    if (compareBy === CompareBy.Text) {
      clearErrors('optionsDto.compareIdx');
    }
  }, [compareBy, clearErrors]);

  return (
    <ErrorBoundaryWrapper>
      <div className="items-center border-b border-gray-200 sm:flex sm:px-6 sm:py-4">
        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48 ">
          Value*
        </dt>
        <dd className="mt-1 grid w-full grid-rows-1 gap-2 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
          <Textarea
            name={'value'}
            placeholder={
              isSecret && isSavedSecretInput
                ? '암호화된 입력 값은 표시되지 않습니다.'
                : '텍스트를 입력해 주세요.'
            }
            isSavedValue={isSavedSecretInput}
          />
          <div className="space-y-4 sm:flex sm:items-center sm:justify-between sm:space-x-10 sm:space-y-0">
            <InputRadio
              name={'optionsDto.compareBy'}
              radioOptions={radioOptions}
            />
          </div>
          {compareBy === CompareBy.Id && saveTextLists?.length === 0 ? (
            <div className="flex flex-col items-center justify-center border border-solid border-border-line p-3 text-sm">
              <p className="mb-2">선택 가능한 테스트 스텝이 없습니다.</p>
              <p>Save Text Step을 먼저 추가해주세요.</p>
            </div>
          ) : compareBy === CompareBy.Id && saveTextLists?.length !== 0 ? (
            <TestStepSelectSaveText saveTextLists={saveTextLists} />
          ) : (
            compareBy === CompareBy.Text && (
              <EditInputTextSecretAndTextEncrypt
                mobileTestStep={mobileTestStep}
              />
            )
          )}
          {mobileTestStep.command ===
            MobileCommandOptionServerName.ConfirmText &&
            compareBy !== CompareBy.Replace && (
              <InputRadio
                name={'optionsDto.compareRange'}
                radioOptions={equalAndContainRadioOptions}
              />
            )}
          {compareBy === CompareBy.Automation && <EditAutomationContents />}
        </dd>
      </div>
      {((mobileTestStep.command === MobileCommandOptionServerName.InputText &&
        compareBy === CompareBy.Text) ||
        mobileTestStep.command ===
          MobileCommandOptionServerName.ConfirmText) && (
        <DefinitionList
          term={'Secret'}
          description={
            <SecretEditBox
              control={control}
              name={
                mobileTestStep.command ===
                MobileCommandOptionServerName.InputText
                  ? 'optionsDto.textEncrypt'
                  : 'optionsDto.secret'
              }
            />
          }
        />
      )}
      {compareBy === CompareBy.Replace && (
        <ErrorBoundaryWrapper>
          <From from={mobileTestStep?.optionsDto?.textReplaceOptions?.from} />
          <Add
            addKey={mobileTestStep?.optionsDto?.textReplaceOptions?.addKey}
            addValue={mobileTestStep?.optionsDto?.textReplaceOptions?.addValue}
          />
          <DateFormat
            textReplaceOptions={mobileTestStep?.optionsDto?.textReplaceOptions}
          />
        </ErrorBoundaryWrapper>
      )}
    </ErrorBoundaryWrapper>
  );
};

export default TestStepConfirmOrInputText;
