import { ErrorBoundaryWrapper } from '@autosquare/common';
import React from 'react';
import AddSystemUsersForm from './components/AddSystemUsersForm';
import AddUserPagingHeader from './components/AddUserPagingHeader';
import AddSystemUsers from './components/AddSystemUsers';

const AddUsers = () => {
  return (
    <ErrorBoundaryWrapper>
      <AddUserPagingHeader />
      <AddSystemUsers />
      <AddSystemUsersForm />
    </ErrorBoundaryWrapper>
  );
};

export default AddUsers;
