import {
  Checkbox,
  Detail,
  ErrorMessage,
  Execution,
  PlatFormTypeServerName,
  TableBodyBasic,
  TableHeader,
  TableNoList,
  TableWrapper,
  Time,
  findPlatformTypeNameFromServerName,
  useCheckbox,
} from '@autosquare/common';

import { timePattern } from '@utils/static/timePattern';
import { useSearchMobileSpeedTestCaseListQuery } from '@lib/api/ide/speedMobile/speedMobileTestCase';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import NoListResultMessage from '@components/Search/NoListResultMessage';
import ExecutionContainerAndErrorDialog from './MobileSpeedTestCaseList/ExecutionContainerAndErrorDialog';
import TableSkeleton from '@components/Skeleton/TableSkeleton';

const MobileSpeedTestCaseList = () => {
  const navigate = useNavigate();

  const [testCaseIdx, setTestCaseIdx] = useState<number>(0);
  const [platformType, setPlatformType] =
    useState<PlatFormTypeServerName>(null);
  const [isTestExecutionOpened, setIsTestExecutionOpened] = useState(false);

  const searchMobileSpeedTestCaseListQuery =
    useSearchMobileSpeedTestCaseListQuery();
  const searchMobileSpeedTestCaseList = searchMobileSpeedTestCaseListQuery.data;

  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(searchMobileSpeedTestCaseList, 'idx');

  const headerList = [
    {
      idx: 1,
      title: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={(e) => selectAllArray(e)}
        />
      ),
    },
    {
      idx: 2,
      title: selectedArray.length > 0 ? <div className="w-[1.125rem]" /> : 'No',
    },
    {
      idx: 3,
      title:
        selectedArray.length > 0 ? <div className="w-[3.75rem]" /> : 'Platform',
    },
    { idx: 4, title: 'Test Group' },
    { idx: 5, title: 'Service Group' },
    { idx: 6, title: 'Title' },
    { idx: 7, title: 'Step Count' },
    { idx: 8, title: 'Priority' },
    { idx: 9, title: 'Creator' },
    { idx: 10, title: 'Created at', isSortable: true },
    { idx: 11, title: '' },
    { idx: 12, title: '' },
  ];

  return (
    <>
      <TableWrapper selectedArray={selectedArray} openDialog={openDialog}>
        <thead>
          <tr>
            {headerList.map((header) => (
              <TableHeader key={header.idx} isSortable={header.isSortable}>
                {header.title}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {searchMobileSpeedTestCaseListQuery.isLoading ||
          searchMobileSpeedTestCaseListQuery.isFetching ? (
            <TableSkeleton dataLength={headerList.length} />
          ) : searchMobileSpeedTestCaseListQuery.isError ? (
            <TableNoList colSpan={headerList.length}>
              <ErrorMessage>
                {searchMobileSpeedTestCaseListQuery.error.message}
              </ErrorMessage>
            </TableNoList>
          ) : searchMobileSpeedTestCaseList?.length === 0 ? (
            <NoListResultMessage colSpan={headerList.length} testType="case" />
          ) : (
            searchMobileSpeedTestCaseList?.map((mobileTestCase, idx) => (
              <tr key={mobileTestCase.idx}>
                <TableBodyBasic>
                  <Checkbox
                    checked={selectedArray.includes(Number(mobileTestCase.idx))}
                    onChange={(e) => selectArray(Number(mobileTestCase.idx), e)}
                  />
                </TableBodyBasic>
                <TableBodyBasic>{idx + 1}</TableBodyBasic>
                <TableBodyBasic>
                  {mobileTestCase.platformType === null
                    ? '-'
                    : findPlatformTypeNameFromServerName(
                        mobileTestCase.platformType,
                      )
                        .replace(/mobile/i, '')
                        .trim()}
                </TableBodyBasic>
                <TableBodyBasic>
                  {mobileTestCase.caseGroupInfo?.name ?? '-'}
                </TableBodyBasic>
                <TableBodyBasic>
                  {mobileTestCase.serviceGroupInfo?.name ?? '-'}
                </TableBodyBasic>
                <td className="table-data-basic max-w-[14.75rem]">
                  <div className="font-medium text-gray-900">
                    {mobileTestCase.title}
                  </div>
                  <div className="mt-1 w-full break-words text-gray-500">
                    {mobileTestCase.description}
                  </div>
                </td>
                <TableBodyBasic>
                  {mobileTestCase.platformType ===
                  PlatFormTypeServerName.MobileApp ? (
                    <>
                      <p>AOS:{mobileTestCase.steps?.aosStepCount ?? 0}</p>
                      <p>iOS:{mobileTestCase.steps?.iosStepCount ?? 0}</p>
                    </>
                  ) : (
                    mobileTestCase.steps.stepCount
                  )}
                </TableBodyBasic>
                <TableBodyBasic>{mobileTestCase.priority}</TableBodyBasic>
                <TableBodyBasic>{mobileTestCase.creator}</TableBodyBasic>
                <TableBodyBasic>
                  <div>
                    {dayjs(mobileTestCase.createdAt).format('YYYY-MM-DD') ??
                      '-'}
                  </div>
                  <div className="mt-1 flex items-center justify-start gap-2 text-gray-500">
                    {mobileTestCase.createdAt && <Time />}
                    {RegExp(timePattern).exec(mobileTestCase.createdAt)[0]}
                  </div>
                </TableBodyBasic>
                <TableBodyBasic>
                  <Execution
                    tooltipLocation={'right'}
                    testType="case"
                    disabled={
                      mobileTestCase.steps?.aosStepCount === 0 &&
                      mobileTestCase.steps?.iosStepCount === 0
                    }
                    onClick={() => {
                      if (
                        mobileTestCase.steps?.aosStepCount > 0 ||
                        mobileTestCase.steps?.iosStepCount > 0 ||
                        mobileTestCase.steps?.stepCount > 0
                      ) {
                        setTestCaseIdx(Number(mobileTestCase.idx));
                        setIsTestExecutionOpened(true);
                        setPlatformType(mobileTestCase.platformType);
                      }
                    }}
                  />
                </TableBodyBasic>
                <TableBodyBasic>
                  <Detail
                    onClick={() =>
                      navigate({
                        pathname: 'detail',
                        search: `idx=${mobileTestCase.idx}`,
                      })
                    }
                  />
                </TableBodyBasic>
              </tr>
            ))
          )}
        </tbody>
      </TableWrapper>
      <ExecutionContainerAndErrorDialog
        platformType={platformType}
        isTestExecutionOpened={isTestExecutionOpened}
        setIsTestExecutionOpened={setIsTestExecutionOpened}
        testCaseIdx={testCaseIdx}
        isOpenedCautionDialog={isOpenedCautionDialog}
        setIsOpenedCautionDialog={setIsOpenedCautionDialog}
        closeDialog={closeDialog}
        selectedArray={selectedArray}
        resetSelectedArray={resetSelectedArray}
      />
    </>
  );
};

export default MobileSpeedTestCaseList;
