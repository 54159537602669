import React from 'react';
import AdminDeviceDetailFormFields from './AdminDeviceDetailFormFields';
import { Controller, useFormContext } from 'react-hook-form';
import { UpdateAdministrationDevice } from '@customTypes/index';
import { ListBoxHeadlessInController } from '@autosquare/common';
import { useAllAdminProjectListQuery } from '@lib/api/dashboard/queryHooks';

const ProjectFields = () => {
  const { data: adminProjectList } = useAllAdminProjectListQuery();

  const { control, watch } = useFormContext<UpdateAdministrationDevice>();

  const isEnabled = watch('deviceEnabled');

  const findProjectNameByIdx = (idx: number) => {
    return adminProjectList.find((project) => project.idx === idx)?.name;
  };

  return (
    <AdminDeviceDetailFormFields
      id={'projectIdx'}
      label={'Project (디바이스를 사용할 프로젝트를 지정할 수 있습니다)'}
    >
      <Controller
        control={control}
        name="projectIdx"
        render={({ field }) => (
          <ListBoxHeadlessInController
            value={field.value || ''}
            buttonValue={findProjectNameByIdx(field.value)}
            onChange={field.onChange}
            lists={adminProjectList}
            valueToSave={'idx'}
            valueToShow={'name'}
            placeholder={'Select Project'}
            disabled={!isEnabled}
            isGrayBackground={!isEnabled}
          />
        )}
      />
    </AdminDeviceDetailFormFields>
  );
};

export default ProjectFields;
