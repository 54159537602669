import { ErrorBoundaryWrapper } from '@autosquare/common';
import { AutoOrder } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import {
  AutomationAutoOrder,
  CreateTestDataAutomation,
  UpdateTestDataAutomation,
} from '@customTypes/testCase/type';
import React from 'react';
import { useFormContext } from 'react-hook-form';

type Props = { isDisabled?: boolean; autoOrder?: AutomationAutoOrder };

const AutomationOrderBy = ({ isDisabled = false }: Props) => {
  const { register, watch } = useFormContext<
    CreateTestDataAutomation | UpdateTestDataAutomation
  >();

  const autoOrder = watch('autoOrder');

  const orderByList = [
    {
      title: AutoOrder.Random,
      value: AutomationAutoOrder.Random,
      defaultChecked: autoOrder
        ? autoOrder === AutomationAutoOrder.Random
        : true,
    },
    {
      title: AutomationAutoOrder.ASC,
      value: AutomationAutoOrder.ASC,
      defaultChecked: autoOrder === AutomationAutoOrder.ASC,
    },
    {
      title: AutomationAutoOrder.DESC,
      value: AutomationAutoOrder.DESC,
      defaultChecked: autoOrder === AutomationAutoOrder.DESC,
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <fieldset>
        <div className="flex gap-6">
          <legend className="text-base font-semibold leading-normal text-gray-900">
            Order By
          </legend>
          <div className="flex gap-2.5">
            {orderByList.map((orderBy) => (
              <div key={orderBy.value} className="flex items-center gap-x-3">
                <input
                  type="radio"
                  className="input-radio-congress-blue"
                  value={orderBy.value}
                  id={orderBy.value}
                  name={'autoOrder'}
                  defaultChecked={orderBy.defaultChecked}
                  disabled={isDisabled}
                  {...register('autoOrder')}
                />
                <label
                  htmlFor={orderBy.value}
                  className="cursor-pointer text-sm font-medium leading-normal text-gray-900"
                >
                  {orderBy.title}
                </label>
              </div>
            ))}
          </div>
        </div>
      </fieldset>
    </ErrorBoundaryWrapper>
  );
};

export default AutomationOrderBy;
