import { API } from '@api/endpoints';
import { WebTestCaseDetailData } from '@customTypes/testCase/type';
import { groupKeys } from '@queries/ide/groupKeys';
import { webTestCaseKeys } from '@queries/ide/web/webTestCaseKeys';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { axiosInstance } from '..';

// GET
// 웹 테스트 케이스 목록 전체 조회
const webTestCaseList = async (
  projectIdx: string,
): Promise<WebTestCaseDetailData[]> => {
  const response = await axiosInstance.get<WebTestCaseDetailData[]>(
    `${API.WEB_TEST_CASE}?projectIdx=${projectIdx}`,
  );
  return response.data;
};
export const useRemoteWebTestCaseListQuery = (
  options?: UseQueryOptions<WebTestCaseDetailData[], Error>,
) => {
  const { projectIdx } = useParams();
  return useQuery<WebTestCaseDetailData[], Error>({
    queryKey: webTestCaseKeys.list(projectIdx),
    queryFn: () => webTestCaseList(projectIdx),
    ...options,
  });
};

// 웹 테스트 케이스 상세 조회
const webTestCaseDetail = async (
  idx: string,
): Promise<WebTestCaseDetailData> => {
  const response = await axiosInstance.get<WebTestCaseDetailData>(
    `${API.WEB_TEST_CASE}/${idx}`,
  );
  return response.data;
};
export const useRemoteWebTestCaseDetailQuery = (
  testCaseIdx?: number,
  options?: UseQueryOptions<WebTestCaseDetailData, Error>,
) => {
  const [searchParams] = useSearchParams();
  const webTestCaseIdx = searchParams.get('idx');

  const idx = testCaseIdx ? testCaseIdx.toString() : webTestCaseIdx;

  return useQuery<WebTestCaseDetailData, Error>({
    queryKey: webTestCaseKeys.detail(idx),
    queryFn: () => webTestCaseDetail(idx),
    ...options,
  });
};

// 웹 테스트 케이스 검색 기능 조회
const getFilteredWebTestCaseList = async (
  search: string,
): Promise<WebTestCaseDetailData[]> => {
  const response = await axiosInstance.get<WebTestCaseDetailData[]>(
    `${API.WEB_TEST_CASE}/search${search}`,
  );
  return response.data;
};
export const useFilteredRemoteWebTestCaseListQuery = () => {
  const { search } = useLocation();
  return useQuery<WebTestCaseDetailData[], Error, WebTestCaseDetailData[]>({
    queryKey: webTestCaseKeys.search(search),
    queryFn: () => getFilteredWebTestCaseList(search),
    enabled: search !== '',
  });
};

// DELETE
// 웹 테스트 케이스 삭제
const deleteWebTestCase = async (idxList: number[]): Promise<unknown> => {
  const response = await axiosInstance.delete<unknown>(`${API.WEB_TEST_CASE}`, {
    data: { idx_list: idxList },
  });
  return response.data;
};
export const useDeleteRemoteWebTestCaseMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, number[]>({
    mutationFn: (data) => deleteWebTestCase(data),
    onSuccess: () => {
      queryClient.invalidateQueries(webTestCaseKeys.lists());
      queryClient.invalidateQueries(webTestCaseKeys.searches());
      queryClient.invalidateQueries(groupKeys.all);
    },
  });
};
