import { RunningCycle } from '@customTypes/ide/scheduler/scheduler';
import { hourCycle } from '@utils/static/runningCycle';
import { days } from '@utils/static/selectedDays';
import React, { useState } from 'react';
import CreateSpeedSchedulerDays from './CreateSpeedTestSchedulerSchedule/CreateSpeedSchedulerDays';
import CreateSpeedSchedulerPreview from './CreateSpeedTestSchedulerSchedule/CreateSpeedSchedulerPreview';
import CreateSpeedSchedulerRunningCycle from './CreateSpeedTestSchedulerSchedule/CreateSpeedSchedulerRunningCycle';
import CreateSpeedSchedulerStartDate from './CreateSpeedTestSchedulerSchedule/CreateSpeedSchedulerStartDate';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const CreateSpeedTestSchedulerSchedule = () => {
  const [selectedCycle, setSelectedCycle] = useState<RunningCycle>(
    hourCycle[0],
  );
  const [selectedDays, setSelectedDays] = useState<string[]>(days);

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer title="Schedule">
        <div className="grid grid-cols-10 items-start gap-8">
          <CreateSpeedSchedulerStartDate />
          <CreateSpeedSchedulerRunningCycle
            selectedCycle={selectedCycle}
            setSelectedCycle={setSelectedCycle}
          />
          <CreateSpeedSchedulerPreview
            selectedCycle={selectedCycle}
            selectedDays={selectedDays}
          />
          <CreateSpeedSchedulerDays
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
          />
        </div>
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default CreateSpeedTestSchedulerSchedule;
