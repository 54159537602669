import {
  ArrowSpinner,
  Button,
  Checkbox,
  Detail,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
  ErrorMessage,
  Execution,
  TableBodyBasic,
  TableBodyContainer,
  TableHeader,
  TableHeaderContainer,
  TableNoList,
  TableSkeleton,
  TableWrapper,
  Time,
  useCheckbox,
} from '@autosquare/common';

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  useDeleteWebTestCaseMutation,
  useGetFilteredWebTestCaseListQuery,
} from '@lib/api/ide/web/webTestCase';

import TestExecutionContainer from '@components/TestExecution/TestExecutionContainer';
import { timePattern } from '@utils/static/timePattern';
import NoListResultMessage from '@components/Search/NoListResultMessage';
import { RESET_DELAY_MS } from '@constants/index';
import { useRemoteWebTestExecutionMutation } from '@lib/api/ide/remote/remote';
import RemoteTestExecutionAlarmModal from '@components/TestExecution/shared/RemoteTestExecutionAlarmModal';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';

const WebTestCaseList = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isTestExecutionOpened, setIsTestExecutionOpened] = useState(false);
  const [testCaseIdx, setTestCaseIdx] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExecutionErrorModalOpen, setIsExecutionErrorModalOpen] =
    useState(false);

  const webTestCaseListQuery = useGetFilteredWebTestCaseListQuery();
  const webTestCases = webTestCaseListQuery.data;

  const remoteWebTestExecutionMutation =
    useRemoteWebTestExecutionMutation('case');

  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(webTestCases, 'idx');

  const deleteWebTestCaseMutation = useDeleteWebTestCaseMutation();

  const closeDialogWithReset = () => {
    closeDialog();
    setTimeout(() => {
      deleteWebTestCaseMutation.reset();
    }, RESET_DELAY_MS);
  };

  const headerList = [
    {
      idx: 1,
      title: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={selectAllArray}
          key="selected-checkbox"
        />
      ),
    },
    {
      idx: 2,
      title: selectedArray.length > 0 ? <div className="w-5" /> : 'No',
    },
    {
      idx: 3,
      title:
        selectedArray.length > 0 ? (
          <div className="w-[4.625rem]" />
        ) : (
          'Test Group'
        ),
    },
    { idx: 4, title: 'Title' },
    { idx: 5, title: 'Step Count' },
    { idx: 6, title: 'Priority' },
    { idx: 7, title: 'Creator' },
    { idx: 8, title: 'Created at', isSortable: true },
    { idx: 9, title: '' },
    { idx: 10, title: '' },
  ];

  return (
    <ErrorBoundaryWrapper>
      <TableWrapper selectedArray={selectedArray} openDialog={openDialog}>
        <TableHeaderContainer>
          {headerList.map((header) => (
            <TableHeader key={header.idx} isSortable={header.isSortable}>
              {header.title}
            </TableHeader>
          ))}
        </TableHeaderContainer>
        <TableBodyContainer>
          {webTestCaseListQuery.isLoading || webTestCaseListQuery.isFetching ? (
            <TableNoList colSpan={headerList.length}>
              <TableSkeleton />
            </TableNoList>
          ) : webTestCaseListQuery.isError ? (
            <TableNoList colSpan={headerList.length}>
              <ErrorMessage>{webTestCaseListQuery.error.message}</ErrorMessage>
            </TableNoList>
          ) : webTestCases?.length === 0 ? (
            <NoListResultMessage colSpan={headerList.length} testType="case" />
          ) : (
            webTestCases?.map((webTestCase, index) => (
              <tr key={webTestCase.idx}>
                <TableBodyBasic>
                  <Checkbox
                    checked={selectedArray.includes(webTestCase.idx)}
                    onChange={(e) => selectArray(webTestCase.idx, e)}
                  />
                </TableBodyBasic>
                <TableBodyBasic>{index + 1}</TableBodyBasic>
                <TableBodyBasic>
                  {webTestCase.caseGroupInfo?.name ?? '-'}
                </TableBodyBasic>
                <td className="table-data-basic max-w-xs">
                  <div className="font-medium text-gray-900">
                    {webTestCase.title}
                  </div>
                  <div className="mt-1 w-full break-words text-gray-500">
                    {webTestCase.description}
                  </div>
                </td>
                <TableBodyBasic>{webTestCase.stepCount}</TableBodyBasic>
                <TableBodyBasic>{webTestCase.priority}</TableBodyBasic>
                <TableBodyBasic>{webTestCase.creator}</TableBodyBasic>
                <TableBodyBasic>
                  <div>{webTestCase.createdAt?.split(' ')[0] ?? '-'}</div>
                  <div
                    className={`mt-1 flex items-center justify-start gap-2 text-gray-500`}
                  >
                    {webTestCase.createdAt && <Time />}
                    {RegExp(timePattern).exec(webTestCase.createdAt)[0]}
                  </div>
                </TableBodyBasic>
                <TableBodyBasic>
                  <Execution
                    testType="case"
                    disabled={webTestCase.stepCount === 0}
                    tooltipLocation={'right'}
                    onClick={() => {
                      if (webTestCase.stepCount > 0) {
                        setTestCaseIdx(webTestCase.idx);
                        setIsTestExecutionOpened(true);
                      }
                    }}
                  />
                </TableBodyBasic>
                <TableBodyBasic>
                  <Detail
                    onClick={() =>
                      navigate({
                        pathname: `${pathname}/detail`,
                        search: `idx=${webTestCase.idx}`,
                      })
                    }
                  />
                </TableBodyBasic>
              </tr>
            ))
          )}
        </TableBodyContainer>
      </TableWrapper>
      {isTestExecutionOpened && (
        <TestExecutionContainer
          setState={setIsTestExecutionOpened}
          idx={testCaseIdx}
          testType={'case'}
          deviceType={'web'}
          setIsModalOpen={setIsModalOpen}
          remoteWebExecutionMutation={remoteWebTestExecutionMutation}
          setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
        />
      )}
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedCautionDialog}
        setIsOpened={setIsOpenedCautionDialog}
        onClose={closeDialogWithReset}
        type="caution"
        title={deleteWebTestCaseMutation.isError ? 'Error' : 'Delete Test Case'}
        subTitle={
          deleteWebTestCaseMutation.isError
            ? deleteWebTestCaseMutation.error.message
            : '해당 테스트 케이스를 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {!deleteWebTestCaseMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  deleteWebTestCaseMutation.mutate(selectedArray, {
                    onSuccess: () => {
                      closeDialog();
                      resetSelectedArray();
                    },
                  })
                }
              >
                Delete
              </Button>
            )}
            <Button
              type="button"
              variant="secondary"
              onClick={closeDialogWithReset}
            >
              Cancel
            </Button>
          </>
        }
      />
      {remoteWebTestExecutionMutation.isLoading && <ArrowSpinner />}
      <RemoteTestExecutionAlarmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isExecutionErrorModalOpen={isExecutionErrorModalOpen}
        setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
      />
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default WebTestCaseList;
