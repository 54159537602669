import { MiddleAlign } from '@autosquare/common';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { getIdeAccessToken } from '@store/api/accessTokenSlice';
import { usePostSettingUrlMutation } from '@lib/api/ide/setting/settings';

import LoginForm from './components/LoginForm';

import tsquare_logo from '@assets/img/tsquare_logo.png';

const IdeLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [applicationUrl, setApplicationUrl] = useState<string>(undefined);
  const [configuration, setConfiguration] = useState<string>(undefined);

  const isPersistentLogin = localStorage.getItem('isPersistentLogin');
  const localStorageApplicationUrl = localStorage.getItem('applicationUrl');

  const postSettingUrlMutation = usePostSettingUrlMutation();

  useEffect(() => {
    const getUrl = async () => {
      const url: string = await window.electron.invoke('getUrlFromStore');

      localStorage.setItem('applicationUrl', url);
      setApplicationUrl(url);
    };
    getUrl();
  }, []);

  useEffect(() => {
    const getConfig = async () => {
      const config = await window.electron.invoke('getConfigFromStore');

      localStorage.setItem('configuration', config);
      setConfiguration(config);
    };
    getConfig();
  }, []);

  useEffect(() => {
    window.electron.send('preloadDownloaded');

    if (
      localStorageApplicationUrl === undefined ||
      (applicationUrl && localStorageApplicationUrl !== applicationUrl)
    ) {
      window.location.reload();
    } else if (
      applicationUrl !== undefined &&
      configuration !== undefined &&
      configuration === 'standard'
    ) {
      postSettingUrlMutation.mutate([
        {
          key: 'dashboard_server_url',
          value: applicationUrl,
        },
      ]);
    }
  }, [applicationUrl, localStorageApplicationUrl]);

  useEffect(() => {
    if (
      isPersistentLogin === 'false' ||
      (isPersistentLogin === 'true' && location.state === '!200')
    ) {
      dispatch(getIdeAccessToken(null));
    }

    if (isPersistentLogin === 'true' && location.state !== '!200') {
      window.electron.send('startRemoteServer');
      navigate('/ide/projects/list');
    }
  }, [isPersistentLogin]);

  return (
    <MiddleAlign>
      <div className="justify-center1 flex h-auto min-h-full w-[23.75rem] flex-col pb-10">
        <div className="mb-3 flex justify-center">
          <img
            src={tsquare_logo}
            alt="tsquare_logo"
            className="w-60"
            width={240}
            height={42}
          />
        </div>
        <LoginForm applicationUrl={applicationUrl} />
        {/* <hr className="w-full mt-[0.75rem] mb-[0.5rem] border-t-[1px] border-solid border-border-lightGray" />
        <div className="text-center">
          <button type="button" onClick={() => navigate("/findpassword")}>
            비밀번호 찾기
          </button>
        </div> */}
      </div>
    </MiddleAlign>
  );
};

export default IdeLogin;
