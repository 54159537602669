import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { axiosInstance, axiosLocalInstance } from '../';
import { IDEAPI } from '@api/ideEndpoints';
import { webDriverAgentControllerKeys } from '@queries/ide/webDriverAgentControllerKeys';
import {
  DerivedData,
  UploadDerivedDataVariables,
} from '@customTypes/setting/type';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';

// GET
const getDerivedData = async (): Promise<DerivedData[]> => {
  const response = await axiosInstance.get<DerivedData[]>(IDEAPI.DOWNLOAD_WDA);
  return response.data;
};
export const useDownloadDerivedDataQuery = (
  options?: UseQueryOptions<DerivedData[], Error>,
) => {
  return useQuery<DerivedData[], Error>({
    queryKey: webDriverAgentControllerKeys.downloads(),
    queryFn: () => getDerivedData(),
    ...options,
  });
};

// POST
const uploadDerivedData = async (
  data: UploadDerivedDataVariables,
  cookie: string,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    IDEAPI.UPLOAD_WDA,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};

export const useUploadDerivedDataMutation = () => {
  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  return useMutation<unknown, Error, UploadDerivedDataVariables>({
    mutationFn: (data) => uploadDerivedData(data, cookie),
  });
};
