import AddTestStepConditionHeader from '@components/TestStep/AddTestStepConditionHeader';

import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import IfCondition from './IFComponent/IfCondition';
import IfActionPass from './IFComponent/IfActionPass';
import IfActionFail from './IFComponent/IfActionFail';
import { ErrorBoundaryWrapper } from '@autosquare/common';

const IFComponent = () => {
  const { setValue, watch } = useFormContext<CreateTestStepData>();

  const failActionValue = watch('optionsDto.failActionValue');

  const [passCommand, setPassCommand] =
    useState<MobileCommandOptionServerName>(undefined);
  const [failCommand, setFailCommand] =
    useState<MobileCommandOptionServerName>(undefined);

  useEffect(() => {
    if (
      failCommand !== MobileCommandOptionServerName.Command &&
      failActionValue !== undefined
    ) {
      setValue('value', `${passCommand},${failCommand}`);
    } else {
      setValue('value', `${passCommand}`);
    }

    return () => {
      setValue('value', undefined);
    };
  }, [passCommand, failCommand, failActionValue, setValue]);

  useEffect(() => {
    setValue('required', true);
  }, [setValue]);

  const valueDefaultText = (command: MobileCommandOptionServerName): string => {
    if (command === MobileCommandOptionServerName.Click)
      return 'Select Element';
    if (command === MobileCommandOptionServerName.DeviceKey)
      return 'Select Key Event';
    if (command === MobileCommandOptionServerName.ImportTestCase)
      return 'Select Test Case';

    return 'Value';
  };

  return (
    <ErrorBoundaryWrapper>
      <IfCondition />
      <IfActionPass
        passCommand={passCommand}
        setPassCommand={setPassCommand}
        valueDefaultText={valueDefaultText}
      />
      <IfActionFail
        passCommand={passCommand}
        failCommand={failCommand}
        setFailCommand={setFailCommand}
        valueDefaultText={valueDefaultText}
      />
      <AddTestStepConditionHeader
        header={'Options'}
        description={'기타 옵션 값을 설정합니다.'}
      />
    </ErrorBoundaryWrapper>
  );
};

export default IFComponent;
