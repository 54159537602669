import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';

import DefinitionList from '@components/shared/DefinitionList';
import DefinitionInputOrTextAreaByReactHookForm from '@components/shared/TestStepDetail/DefinitionInputOrTextAreaByReactHookForm';
import DetailDivisionLine from '@components/shared/TestStepDetail/DetailDivisionLine';
import DefinitionInputByReactHookForm from '@components/shared/TestStepDetail/DefinitionInputByReactHookForm';

import {
  findMobileCommandOptionFromServerName,
  MobileCommandOptionName,
  mobileCommandOptions,
} from '@utils/static/mobileCommandOption';

import { UpdateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const LoopCondition = () => {
  const { control, register } = useFormContext<UpdateTestStepData>();

  return (
    <ErrorBoundaryWrapper>
      <DetailDivisionLine
        title={'Condition'}
        description={'지정된 조건이 만족하는 동안 반복 실행됩니다.'}
      />
      <DefinitionList
        term={'Command'}
        description={
          <Controller
            name={'optionsDto.conditionCommand'}
            control={control}
            render={({ field }) => (
              <ListBoxHeadlessInController
                value={field.value}
                buttonValue={
                  findMobileCommandOptionFromServerName(field.value)?.name
                }
                onChange={field.onChange}
                lists={mobileCommandOptions.filter(
                  (command) =>
                    command.name === MobileCommandOptionName.VerifyNoElement ||
                    command.name === MobileCommandOptionName.FindElement,
                )}
                valueToSave={'serverName'}
                valueToShow={'name'}
              />
            )}
          />
        }
      />
      <DefinitionInputOrTextAreaByReactHookForm
        term={'Value'}
        name={'optionsDto.conditionValue'}
        tag={'textarea'}
      />
      <DefinitionInputByReactHookForm
        term={'Repeat*'}
        name={'optionsDto.repeatLimit'}
        type={'number'}
        min={1}
        max={15}
        register={register('optionsDto.repeatLimit', {
          required: 'Repeat 값을 최소 1부터 입력해 주세요.',
        })}
      />
    </ErrorBoundaryWrapper>
  );
};

export default LoopCondition;
