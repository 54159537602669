import { ErrorBoundaryWrapper, Button } from '@autosquare/common';
import { CommonDialog, DialogButtonPanels } from '@components/Dialog';
import {
  useAdminSettingsQuery,
  useUpdateAdminSettingMutation,
} from '@lib/api/dashboard/queryHooks';
import React, { useCallback, useMemo, useState } from 'react';

const DeviceAuthorization = () => {
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [isDisabledDialogOpen, setIsDisabledDialogOpen] = useState(false);
  const {
    data: deviceSettingsStatus,
    isSuccess,
    isLoading,
  } = useAdminSettingsQuery('device');

  const isDeviceSettingsStatus = useMemo(() => {
    return isSuccess && deviceSettingsStatus.device === 'true';
  }, [deviceSettingsStatus, isSuccess]);

  const updateAdminSettingMutation = useUpdateAdminSettingMutation();

  const updateDeviceAuthorizationStatus = useCallback(
    (deviceStatus: 'true' | 'false') => {
      updateAdminSettingMutation.mutate(
        { device: deviceStatus },
        {
          onError: () => {
            setIsErrorDialogOpen(true);
          },
          onSettled: () => {
            setIsDisabledDialogOpen(false);
          },
        },
      );
    },
    [updateAdminSettingMutation],
  );

  const onClose = () => {
    setIsErrorDialogOpen(false);
  };

  return (
    <ErrorBoundaryWrapper>
      <div className="-mb-4 mt-7 flex items-center justify-between">
        <div className="flex items-center gap-2.5">
          <h2 className="text-base font-semibold leading-9 text-gray-900">
            Device Authorization
          </h2>
          <p className="text-sm font-normal leading-normal text-gray-600">
            디바이스 등록 옵션을 활성화하면 테스트 실행 시 등록된 디바이스만
            사용 가능합니다.
          </p>
        </div>
        {isDeviceSettingsStatus ? (
          <Button
            variant="toggleActivate"
            onClick={() => {
              setIsDisabledDialogOpen(true);
            }}
          >
            Activate
          </Button>
        ) : (
          <Button
            variant="toggleDeactivate"
            onClick={() => {
              updateDeviceAuthorizationStatus('true');
            }}
            disabled={isLoading}
          >
            Deactivate
          </Button>
        )}
      </div>

      {/* 디바이스 비활성화 시 Dialog */}
      <CommonDialog
        isOpen={isDisabledDialogOpen}
        setIsOpen={setIsDisabledDialogOpen}
        iconType={'caution'}
        title={'Device Authorization Deactivate'}
        subTitle={
          '디바이스 등록 옵션이 비활성화되면, PC에 연결된 모든 디바이스에서\n테스트를 실행할 수 있습니다.'
        }
      >
        <DialogButtonPanels>
          <Button
            type="button"
            variant="primary"
            onClick={() => {
              updateDeviceAuthorizationStatus('false');
            }}
            disabled={isLoading}
          >
            OK
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={() => setIsDisabledDialogOpen(false)}
          >
            Cancel
          </Button>
        </DialogButtonPanels>
      </CommonDialog>

      {/* 업데이트 시 Error Dialog */}
      <CommonDialog
        isOpen={isErrorDialogOpen}
        setIsOpen={setIsErrorDialogOpen}
        iconType={'caution'}
        title={'Error'}
        subTitle={updateAdminSettingMutation.error?.message}
      >
        <DialogButtonPanels>
          <Button type="button" variant="secondary" onClick={onClose}>
            Close
          </Button>
        </DialogButtonPanels>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default DeviceAuthorization;
