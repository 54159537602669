import React, { useEffect, useState } from 'react';
import {
  Button,
  DoubleCheckDialogHeadlessUI,
  ToggledSwitch,
} from '@autosquare/common';
import { useFormContext } from 'react-hook-form';
import {
  AutoRegisterType,
  CreateAdminProjectData,
} from '@customTypes/dashboard/admin/type';
import AutoRegisterAddPlatform from './AutoRegister/AutoRegisterAddPlatform';
import AutoRegisterModal from '@components/AutoRegisterCreateEditModal/AutoRegisterModal';
import AutoRegisterEditModal from '@components/AutoRegisterCreateEditModal/AutoRegisterEditModal';

type Props = {
  isToggleOn: boolean;
  setIsToggleOn: React.Dispatch<React.SetStateAction<boolean>>;
};

const AutoRegister = ({ isToggleOn, setIsToggleOn }: Props) => {
  const { resetField, setValue } = useFormContext<CreateAdminProjectData>();

  const [isAddClicked, setIsAddClicked] = useState(false);
  const [autoRegisterDataList, setAutoRegisterDataList] = useState<
    AutoRegisterType[]
  >([]);
  const [openCaution, setOpenCaution] = useState(false);
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [index, setIndex] = useState<number>(null);

  const selectedAutoRegisterData = autoRegisterDataList.find(
    (_, idx) => idx === index,
  );

  const deleteButtonHandler = () => {
    const removedList = autoRegisterDataList.filter((_, idx) => idx !== index);
    setValue('registers', removedList);
    setAutoRegisterDataList(removedList);
    setOpenCaution(false);
  };
  useEffect(() => {
    if (!isAddClicked) {
      setValue('platform', undefined);
      resetField('registers');
    }
  }, [isAddClicked, setValue, resetField]);

  useEffect(() => {
    if (!isEditModalOpened && autoRegisterDataList.length > 0) {
      setValue('platform', undefined);
      resetField('registers');
    }
  }, [isEditModalOpened, autoRegisterDataList, setValue, resetField]);

  useEffect(() => {
    if (autoRegisterDataList.length > 0) {
      setValue('registers', autoRegisterDataList);
    } else {
      setValue('registers', []);
    }
  }, [autoRegisterDataList, setValue]);

  useEffect(() => {
    if (!isToggleOn) {
      setValue('registers', []);
      setAutoRegisterDataList([]);
    }
  }, [isToggleOn, setValue]);

  return (
    <>
      <div className="flex items-center justify-between gap-x-3">
        <div className="flex gap-x-3">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            AutoRegister
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            프로젝트에서 테스트 결과를 자동 등록할 플랫폼을 설정해 주세요.
          </p>
        </div>
        <div className="flex justify-end">
          <ToggledSwitch isEnabled={isToggleOn} setIsEnabled={setIsToggleOn} />
        </div>
      </div>
      {isToggleOn && (
        <AutoRegisterAddPlatform
          autoRegisterDataList={autoRegisterDataList}
          isToggleOn={isToggleOn}
          setIsAddClicked={setIsAddClicked}
          setIsEditModalOpened={setIsEditModalOpened}
          setOpenCaution={setOpenCaution}
          setIndex={setIndex}
        />
      )}
      {isAddClicked && (
        <AutoRegisterModal
          isAddClicked={isAddClicked}
          setIsAddClicked={setIsAddClicked}
          autoRegisterDataList={autoRegisterDataList}
          setAutoRegisterDataList={setAutoRegisterDataList}
        />
      )}
      <DoubleCheckDialogHeadlessUI
        isOpened={openCaution}
        setIsOpened={setOpenCaution}
        type={'caution'}
        title={'Delete Platform'}
        subTitle={
          '삭제하시면 해당 플랫폼에 테스트 결과 자동 등록이 불가능 합니다.'
        }
        buttonChildren={
          <>
            <Button
              type="button"
              variant={'caution'}
              onClick={deleteButtonHandler}
            >
              Delete
            </Button>
            <Button
              type="button"
              variant={'secondary'}
              onClick={() => setOpenCaution(false)}
            >
              Cancel
            </Button>
          </>
        }
      />
      {isEditModalOpened && (
        <AutoRegisterEditModal
          isEditModalOpened={isEditModalOpened}
          setIsEditModalOpened={setIsEditModalOpened}
          autoRegisterData={selectedAutoRegisterData}
          autoRegisterDataList={autoRegisterDataList}
          setAutoRegisterDataList={setAutoRegisterDataList}
          targetIndex={index}
        />
      )}
    </>
  );
};

export default AutoRegister;
