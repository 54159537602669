import { axiosInstance, axiosLocalInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { ServerResponseErrorDetails } from '@customTypes/type';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  CreateTestDataAutomation,
  TestDataAutomationInfo,
  UpdateTestDataAutomation,
} from '@customTypes/testCase/type';
import { mobileTestCaseKeys } from '@queries/ide/mobile/mobileTestCaseKeys';
import { mobileTestStepKeys } from '@queries/ide/mobile/mobileTestStepKeys';
import { useParentPath } from '@hooks/useParentPath';
import { enhanceAutomationDataWithIdx } from '@utils/index';

//GET
// Mobile Test Data Automation 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/(New)%20Mobile%20UI%20Test%20Data%20Automation/getAutomationDataByModuleType
const getTestCaseDataAutomation = async (
  caseIdx: string,
): Promise<TestDataAutomationInfo> => {
  const response = await axiosInstance.get<TestDataAutomationInfo>(
    `${IDEAPI.DATA_AUTOMATION}/case/${caseIdx}`,
  );
  return response.data;
};

export const useGetTestCaseDataAutomation = (
  options?: UseQueryOptions<
    TestDataAutomationInfo,
    Error,
    TestDataAutomationInfo
  >,
) => {
  const [searchParam] = useSearchParams();
  const caseIdx = searchParam.get('idx');
  return useQuery<TestDataAutomationInfo, Error, TestDataAutomationInfo>({
    queryFn: () => getTestCaseDataAutomation(caseIdx),
    queryKey: mobileTestCaseKeys.automation(caseIdx),
    ...options,
  });
};

//POST
// 인코딩하여 CSV 파일 저장
// http://localhost:8081/swagger-ui/index.html#/Mobile%20Test%20Data%20Automation/saveMobileUiData
const createTestDataAutomation = async (
  data: CreateTestDataAutomation,
): Promise<TestDataAutomationInfo> => {
  const response = await axiosLocalInstance.post(IDEAPI.LOCAL_AUTOMATION, data);
  return response.data;
};

export const useCreateTestDataAutomationMutation = () => {
  const { projectIdx } = useParams();
  const { search } = useLocation();
  const [searchParam] = useSearchParams();
  const caseIdx = searchParam.get('idx');
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mobileTestCaseDetailPage = useParentPath();

  return useMutation<
    TestDataAutomationInfo,
    ServerResponseErrorDetails,
    CreateTestDataAutomation
  >({
    mutationFn: (data) =>
      createTestDataAutomation(
        enhanceAutomationDataWithIdx(data, caseIdx, projectIdx),
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileTestCaseKeys.all);
      queryClient.invalidateQueries(mobileTestStepKeys.all);
      navigate({ pathname: mobileTestCaseDetailPage, search: search });
    },
  });
};

//PUT
// 인코딩하여 CSV 파일 업데이트
// http://localhost:8081/swagger-ui/index.html#/Mobile%20Test%20Data%20Automation/updateMobileUiData_1
const updateTestDataAutomation = async (
  data: UpdateTestDataAutomation,
): Promise<TestDataAutomationInfo> => {
  const { automationIdx, ...restData } = data;
  const response = await axiosLocalInstance.put(
    `${IDEAPI.LOCAL_AUTOMATION}/update/${automationIdx}`,
    restData,
  );
  return response.data;
};

export const useUpdateTestDataAutomationMutation = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mobileTestCaseDetailPage = useParentPath();

  return useMutation<
    TestDataAutomationInfo,
    ServerResponseErrorDetails,
    UpdateTestDataAutomation
  >({
    mutationFn: (data) => updateTestDataAutomation(data),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileTestCaseKeys.all);
      queryClient.invalidateQueries(mobileTestStepKeys.all);
      navigate({ pathname: mobileTestCaseDetailPage, search: search });
    },
  });
};

//DELETE
// Mobile Test Data Automation 삭제
// https://dev.t-square.co.kr/swagger-ui/index.html#/(New)%20Mobile%20UI%20Test%20Data%20Automation/deleteAutomationData_2
const deleteTestDataAutomation = async (idx: number): Promise<string> => {
  const response = await axiosInstance.delete<string>(
    `${IDEAPI.DATA_AUTOMATION}/delete/${idx}`,
  );
  return response.data;
};

//idx는 caseIdx나 projectIdx가 아닌 서버에서 보내주는 Idx임
export const useDeleteTestDataAutomationMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { search } = useLocation();

  const mobileTestCaseDetailPage = useParentPath();

  return useMutation<string, ServerResponseErrorDetails, number>({
    mutationFn: (idx) => deleteTestDataAutomation(idx),
    onSuccess: () => {
      queryClient.invalidateQueries(mobileTestCaseKeys.all);
      queryClient.invalidateQueries(mobileTestStepKeys.all);
      navigate({ pathname: mobileTestCaseDetailPage, search: search });
    },
  });
};
