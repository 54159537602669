import { DialogButton } from '@components/Dialog';
import { DialogButtonPanels } from '@components/Dialog/DialogComponents';
import { CommonDialog } from '@components/Dialog';
import React from 'react';

type DialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isLoading: boolean;
  onDelete: () => void;
};

export const DeleteDataAutomationConfirmDialog = ({
  isOpen,
  setIsOpen,
  isLoading,
  onDelete,
}: DialogProps) => {
  return (
    <CommonDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      iconType="caution"
      title="Delete Test Data Automation"
      subTitle={`저장된 테스트 데이터를 삭제하시겠습니까?\n이 데이터가 사용된 스텝은 테스트 실행 시 실패할 수 있습니다.`}
    >
      <DialogButtonPanels>
        <DialogButton
          type="button"
          variant="caution"
          onClick={onDelete}
          disabled={isLoading}
        >
          Delete
        </DialogButton>
        <DialogButton
          type="button"
          variant="secondary"
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </DialogButton>
      </DialogButtonPanels>
    </CommonDialog>
  );
};
