import React from 'react';

type Props = {
  header: string;
};

const OverviewSubTitleHeader = ({ header }: Props) => {
  return (
    <div className="flex items-center justify-between border-b border-gray-300 pb-2">
      <div className="px-2 text-sm font-semibold leading-normal text-gray-900">
        {header}
      </div>
    </div>
  );
};

export default OverviewSubTitleHeader;
