import React from 'react';
import CountDownAnimation from './ScreenshotContainer/CountDownAnimation';
import { useMobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';
import clsx from 'clsx';
import { ErrorMessage, PhotoSkeleton } from '@autosquare/common';
import { getRemoteState } from '@utils/static/getConfiguration';
import { Mode } from '@customTypes/ide/remote/type';
import {
  useMobileScreenshotQuery,
  useMobileSourceQuery,
  useRemoteMobileScreenshotQuery,
  useRemoteMobileSourceQuery,
} from '@lib/api/ide/mobile/mobileDevice';
import ScreenShot from './ScreenshotContainer/ScreenShot';

const ScreenshotContainer = () => {
  const { isCountThreeNeeded, countDown, isLandscapeMode } =
    useMobileTestStepContext();

  const { configuration } = getRemoteState();
  const mobileScreenshotQuery =
    configuration === Mode.Standard
      ? useMobileScreenshotQuery()
      : useRemoteMobileScreenshotQuery();

  const mobileSourceQuery =
    configuration === Mode.Standard
      ? useMobileSourceQuery()
      : useRemoteMobileSourceQuery();

  if (isCountThreeNeeded) {
    return <CountDownAnimation countDown={countDown} />;
  }

  if (
    mobileScreenshotQuery.isLoading ||
    mobileSourceQuery.isLoading ||
    mobileScreenshotQuery.isFetching ||
    mobileSourceQuery.isFetching
  ) {
    return (
      <div
        className={clsx('flex', isLandscapeMode ? 'col-span-7' : 'col-span-4')}
      >
        <PhotoSkeleton />
      </div>
    );
  }

  if (mobileScreenshotQuery.isError) {
    return (
      <div
        className={clsx(
          'flex w-full items-center justify-center border',
          isLandscapeMode ? 'col-span-7' : 'col-span-4',
        )}
      >
        <ErrorMessage margin={'m-auto'}>
          {mobileScreenshotQuery.error.message}
        </ErrorMessage>
      </div>
    );
  }

  if (mobileSourceQuery.isError) {
    return (
      <div
        className={clsx(
          'flex w-full items-center justify-center border',
          isLandscapeMode ? 'col-span-7' : 'col-span-4',
        )}
      >
        <ErrorMessage margin={'m-auto'}>
          {mobileSourceQuery.error.message}
        </ErrorMessage>
      </div>
    );
  }

  const mobileSource = mobileSourceQuery.data;

  // coordinate
  const getCoordinate = mobileSource.page_source[0];
  const coordinateInfo = mobileSource.page_source[1];

  return (
    <ScreenShot
      screenshot={mobileScreenshotQuery.data}
      getCoordinate={getCoordinate}
      coordinateInfo={coordinateInfo}
    />
  );
};

export default ScreenshotContainer;
