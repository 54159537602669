import { Checkbox, ErrorBoundaryWrapper } from '@autosquare/common';
import {
  TestResultCaseResultExport,
  TestResultDetails,
  TestResultLabel,
} from '@customTypes/index';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

type Props = {
  isPanelOpen: boolean;
  setIsPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  resultDetail: TestResultDetails;
  currentItem: TestResultCaseResultExport;
  handleParentChange: (
    parentIdx: number,
    checked: boolean,
    stepIdxList: number[],
  ) => void;
  children: ReactNode;
};

const ExportOptionsTestResultUI = ({
  isPanelOpen,
  setIsPanelOpen,
  resultDetail,
  currentItem,
  handleParentChange,
  children,
}: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div className="py-4">
        <div className="bg-white">
          <button
            type="button"
            className="group w-full"
            onClick={() => {
              setIsPanelOpen(!isPanelOpen);
            }}
          >
            <div className="flex items-center justify-between space-x-3 px-4">
              <div className="flex min-w-0 flex-col gap-2">
                <div className="flex items-center gap-x-3">
                  <Checkbox
                    id={`${resultDetail.idx}-checkbox`}
                    checked={currentItem?.stepResultIdxList.length > 0}
                    onChange={(e) =>
                      handleParentChange(
                        resultDetail.idx,
                        e.target.checked,
                        resultDetail.details.map((d) => d.idx),
                      )
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                  <p
                    className={clsx(
                      'mt-0.5 whitespace-nowrap px-1.5 py-0.5 text-xs font-medium uppercase ring-1 ring-inset',
                      resultDetail.result === TestResultLabel.Pass
                        ? 'bg-green-50 text-green-700 ring-green-600/20'
                        : resultDetail.result === TestResultLabel.Fail
                          ? 'bg-red-50 text-red-700 ring-red-600/10'
                          : 'bg-gray-50 text-gray-700 ring-gray-600/10',
                    )}
                  >
                    {resultDetail.result}
                  </p>
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    #{resultDetail.seq}
                  </p>
                  <h4
                    className="text-sm font-semibold leading-6 text-gray-900"
                    id={resultDetail.title}
                  >
                    {resultDetail.title}{' '}
                    <span>({resultDetail.details.length})</span>
                  </h4>
                </div>
              </div>
              <div className="justify-items-end">
                <div className="grid grid-cols-1">
                  <ChevronRightIcon
                    className={clsx(
                      'size-5 shrink-0 text-gray-400 group-data-[open]:rotate-90 group-data-[open]:text-gray-500',
                      isPanelOpen && 'rotate-90 text-gray-500',
                    )}
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </button>
          {children}
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default ExportOptionsTestResultUI;
