import React from 'react';
import { Outlet } from 'react-router-dom';

import { CopyRight } from '@autosquare/common';
import Nav from '@components/Nav/Nav';
import Sidebar from '@components/Sidebar/Sidebar';

const SidebarRoot = () => {
  return (
    <div className="mx-auto flex min-h-screen max-w-full flex-col sm:px-0 lg:px-0">
      <Nav />
      <div className="mt-16 min-h-full">
        <Sidebar />
        <div className="flex min-h-[calc(100vh-4rem)] flex-col justify-between lg:pl-72">
          <main className="mb-auto flex min-h-full max-w-full flex-col px-4 py-6 sm:px-6 lg:px-8">
            <Outlet />
          </main>
          <CopyRight />
        </div>
      </div>
    </div>
  );
};

export default SidebarRoot;
