import React from 'react';
import { useFormContext } from 'react-hook-form';

import { UpdateSchedulerData } from '@customTypes/ide/scheduler/scheduler';
import { ErrorBoundaryWrapper, MailReceiver } from '@autosquare/common';

interface Props {
  emailAddressLists: MailReceiver[];
  setEmailAddressLists: React.Dispatch<React.SetStateAction<MailReceiver[]>>;
  isRunning: boolean;
}

const SchedulerDetailEmailList = ({
  emailAddressLists,
  setEmailAddressLists,
  isRunning,
}: Props) => {
  const { setValue } = useFormContext<UpdateSchedulerData>();

  const deleteTagItem = (indexToDelete: number) => {
    setEmailAddressLists(
      emailAddressLists.filter((_, index) => index !== indexToDelete),
    );
    setValue(
      'mailReceivers',
      emailAddressLists.filter((_, index) => index !== indexToDelete),
    );
  };

  return (
    <ErrorBoundaryWrapper>
      <div className="mt-4">
        {emailAddressLists?.map((emailAddress, index) => (
          <span
            key={emailAddress?.email}
            className="m-1 inline-flex items-center justify-between gap-x-0.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600"
          >
            {emailAddress?.name === null
              ? emailAddress?.email
              : `${emailAddress?.email}(${emailAddress?.name})`}
            {isRunning && (
              <button
                type="button"
                className="group relative -mr-1 size-3.5 rounded-sm hover:bg-gray-500/20"
                onClick={() => deleteTagItem(index)}
              >
                <span className="sr-only">Remove</span>
                <svg
                  viewBox="0 0 14 14"
                  className="size-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75"
                >
                  <path d="M4 4l6 6m0-6l-6 6" />
                </svg>
                <span className="absolute -inset-1" />
              </button>
            )}
          </span>
        ))}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default SchedulerDetailEmailList;
