import { RootState } from '@app/store';
import {
  Button,
  decodeJwtToken,
  ErrorBoundaryWrapper,
  TableBodyBasic,
  TableHeader,
} from '@autosquare/common';
import { CommonDialog } from '@components/Dialog';
import { RESET_DELAY_MS } from '@constants/timeout';
import { useParentPath } from '@hooks/useParentPath';
import {
  useAdminUserDetailQuery,
  useDeleteAdminUserMutation,
} from '@lib/api/dashboard/queryHooks';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type Props = {
  isUpdateLoading: boolean;
};

const AdminUserInformationFormButton = ({ isUpdateLoading }: Props) => {
  const { data: adminUserDetail } = useAdminUserDetailQuery();

  const navigate = useNavigate();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const adminUserListPage = useParentPath();

  const deleteAdminUserMutation = useDeleteAdminUserMutation();

  const deleteOnClick = () => {
    deleteAdminUserMutation.mutate(
      {
        userIdx: adminUserDetail.userIdx,
        userEmail: adminUserDetail.email,
      },
      {
        onError: () => {
          setIsErrorDialogOpen(true);
        },
        onSettled: () => {
          setIsDeleteModalOpen(false);
        },
      },
    );
  };

  const accessToken = useSelector(
    (state: RootState) => state.accessTokenValue.accessTokenValue,
  );
  const parsingToken = decodeJwtToken(accessToken);

  const errorDialogClose = () => {
    setTimeout(() => {
      deleteAdminUserMutation.reset();
    }, RESET_DELAY_MS);
    setIsErrorDialogOpen(false);
  };

  return (
    <ErrorBoundaryWrapper>
      <div className="py-2 text-end text-sm font-medium leading-tight text-gray-500">
        Updated at {adminUserDetail.updatedAt}
      </div>
      <div className="flex items-center justify-end gap-2.5 pt-4">
        <Button
          variant="secondary"
          type="button"
          onClick={() => {
            navigate(adminUserListPage);
          }}
        >
          Cancel
        </Button>
        {adminUserDetail.email !== parsingToken?.sub && (
          <Button
            type="button"
            variant="caution"
            onClick={() => {
              setIsDeleteModalOpen(true);
            }}
          >
            Delete
          </Button>
        )}
        <Button type="submit" variant="primary" disabled={isUpdateLoading}>
          Save
        </Button>
      </div>
      <CommonDialog
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        iconType={'caution'}
        title={'Delete User'}
        subTitle={
          "해당 유저를 삭제하시겠습니까?\n삭제된 유저는 'Guest'로 표시되며, 기존 활동 기록은 유지됩니다."
        }
      >
        <CommonDialog.DialogButtonPanels>
          <CommonDialog.DialogButton
            type="button"
            variant="caution"
            onClick={deleteOnClick}
          >
            Delete
          </CommonDialog.DialogButton>
          <CommonDialog.DialogButton
            type="button"
            onClick={() => {
              setIsDeleteModalOpen(false);
            }}
            variant="secondary"
          >
            Cancel
          </CommonDialog.DialogButton>
        </CommonDialog.DialogButtonPanels>
      </CommonDialog>
      <CommonDialog
        isOpen={isErrorDialogOpen}
        setIsOpen={setIsErrorDialogOpen}
        iconType={'caution'}
        title={'Delete Error'}
        subTitle={
          deleteAdminUserMutation.isError &&
          (deleteAdminUserMutation.error.message.includes('유일한')
            ? '해당 유저는 유일한 프로젝트 어드민입니다.\n삭제 전, 프로젝트에서 권한을 변경해 주세요.'
            : deleteAdminUserMutation.error.message)
        }
        onClose={errorDialogClose}
      >
        {deleteAdminUserMutation.isError &&
          deleteAdminUserMutation.error.message.includes('유일한') && (
            <CommonDialog.DialogPanels>
              <div className="gray-scrollbar max-h-80">
                <table className="w-full">
                  <thead>
                    <tr>
                      <TableHeader>No</TableHeader>
                      <TableHeader>Project Title</TableHeader>
                    </tr>
                  </thead>
                  <tbody>
                    {deleteAdminUserMutation.error.data?.map((project, idx) => (
                      <tr
                        key={project.projectId}
                        className="border-t border-solid border-gray-200"
                      >
                        <TableBodyBasic>{idx + 1}</TableBodyBasic>
                        <TableBodyBasic>{project.projectName}</TableBodyBasic>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CommonDialog.DialogPanels>
          )}
        <CommonDialog.DialogButtonPanels>
          <CommonDialog.DialogButton
            type="button"
            onClick={errorDialogClose}
            variant="primary"
          >
            Close
          </CommonDialog.DialogButton>
        </CommonDialog.DialogButtonPanels>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default AdminUserInformationFormButton;
