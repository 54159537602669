import { Button, ErrorBoundaryWrapper } from '@autosquare/common';
import { useParentPath } from '@hooks/index';
import { useProjectDeviceDetailQuery } from '@lib/api/dashboard/queryHooks';
import React from 'react';
import { Link } from 'react-router-dom';
import InstalledApp from '../../common/InstalledApp/InstalledApp';

const NormalUserDeviceDetail = () => {
  const deviceListPage = useParentPath();
  const { data: deviceDetail } = useProjectDeviceDetailQuery();

  const deviceDetailInfo = [
    { title: 'Status', data: deviceDetail.enabled ? 'Activate' : 'Deactivate' },
    { title: 'Device ID', data: deviceDetail.udid },
    { title: 'Device Model', data: deviceDetail.deviceModel || '-' },
    { title: 'Device Name', data: deviceDetail.deviceName || '-' },
    { title: 'Device OS', data: deviceDetail.deviceOs },
    { title: 'Device Type', data: deviceDetail.deviceType || '-' },
  ];

  return (
    <ErrorBoundaryWrapper>
      <dl className="grid grid-cols-3 gap-y-12 border-t border-solid border-gray-200 pb-8 pt-4 text-sm font-normal leading-normal text-gray-900">
        {deviceDetailInfo.map((device) => (
          <div key={device.title} className="col-span-1 space-y-4">
            <dt className="font-medium">{device.title}</dt>
            <dd className="leading-tight">{device.data}</dd>
          </div>
        ))}
      </dl>
      <InstalledApp />
      <div className="flex items-center justify-end border-t border-solid border-gray-200 py-6">
        <Link to={deviceListPage}>
          <Button type="button" variant="secondary">
            Cancel
          </Button>
        </Link>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default NormalUserDeviceDetail;
