import { ErrorBoundaryWrapper } from '@autosquare/common';
import TestStepConfirmInputRadio from '@components/TestStep/TestStepConfirmInputRadio';
import {
  CompareBy,
  CompareRange,
  CreateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const EqualAndContainRadio = () => {
  const { register, watch } = useFormContext<CreateTestStepData>();

  const command = watch('command');
  const compareBy = watch('optionsDto.compareBy');

  const equalAndContainRadioOptions = [
    {
      title: '동일',
      id: CompareRange.Equal,
      defaultChecked: true,
    },
    {
      title: '포함',
      id: CompareRange.Contain,
      defaultChecked: false,
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      {command === MobileCommandOptionServerName.ConfirmText &&
        compareBy !== CompareBy.Replace && (
          <fieldset>
            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              <legend className="text-sm">비교 범위 선택 : </legend>
              <TestStepConfirmInputRadio
                register={register('optionsDto.compareRange')}
                radioOptions={equalAndContainRadioOptions}
                name={'compareRange'}
              />
            </div>
          </fieldset>
        )}
    </ErrorBoundaryWrapper>
  );
};

export default EqualAndContainRadio;
