import { ErrorBoundaryWrapper } from '@autosquare/common';
import PointOrRectangleInput from '@components/shared/PointOrRectangleInput';
import { useMobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const MaskingOptionsSelectArea = () => {
  const {
    maskingStartX,
    maskingStartY,
    maskingEndX,
    maskingEndY,
    onChangeMaskingStartX,
    onChangeMaskingStartY,
    onChangeMaskingEndX,
    onChangeMaskingEndY,
    setMaskingStartX,
    setMaskingStartY,
    setMaskingEndX,
    setMaskingEndY,
    imageWidth,
    imageHeight,
  } = useMobileTestStepContext();

  const {
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<CreateTestStepData>();

  useEffect(() => {
    setMaskingStartX(Math.floor(imageWidth / 2 - 150));
    setMaskingStartY(Math.floor(imageHeight / 2 - 150));
    setMaskingEndX(Math.floor(imageWidth / 2 + 150));
    setMaskingEndY(Math.floor(imageHeight / 2 + 150));
  }, [
    setMaskingStartX,
    setMaskingStartY,
    setMaskingEndX,
    setMaskingEndY,
    imageWidth,
    imageHeight,
  ]);

  useEffect(() => {
    setValue('optionsDto.maskingOptions.valueList', [
      `${maskingStartX},${maskingStartY},${maskingEndX},${maskingEndY}`,
    ]);
  }, [setValue, maskingStartX, maskingStartY, maskingEndX, maskingEndY]);

  useEffect(() => {
    if (
      maskingStartX > imageWidth ||
      maskingEndX > imageWidth ||
      maskingStartY > imageHeight ||
      maskingEndY > imageHeight
    ) {
      setError('optionsDto.maskingOptions.valueList', {
        type: 'max',
        message: `X는 0이상 ${imageWidth} 이하, Y는 0이상 ${imageHeight} 이하로 입력해 주세요.`,
      });
    } else if (
      maskingStartX < 0 ||
      maskingEndX < 0 ||
      maskingStartY < 0 ||
      maskingEndY < 0
    ) {
      setError('optionsDto.maskingOptions.valueList', {
        type: 'min',
        message: `X는 0이상 ${imageWidth} 이하, Y는 0이상 ${imageHeight} 이하로 입력해 주세요.`,
      });
    } else if (
      !maskingStartX ||
      !maskingEndX ||
      !maskingStartY ||
      !maskingEndY
    ) {
      setError('optionsDto.maskingOptions.valueList', {
        type: 'required',
        message: `X는 0이상 ${imageWidth} 이하, Y는 0이상 ${imageHeight} 이하로 입력해 주세요.`,
      });
    } else {
      clearErrors('optionsDto.maskingOptions.valueList');
    }
  }, [
    setError,
    maskingStartX,
    maskingStartY,
    maskingEndX,
    maskingEndY,
    imageWidth,
    imageHeight,
  ]);

  const rectProperties = [
    {
      labelTitle: 'startX',
      value: maskingStartX,
      onChange: onChangeMaskingStartX,
    },
    {
      labelTitle: 'startY',
      value: maskingStartY,
      onChange: onChangeMaskingStartY,
    },
    {
      labelTitle: 'endX',
      value: maskingEndX,
      onChange: onChangeMaskingEndX,
    },
    {
      labelTitle: 'endY',
      value: maskingEndY,
      onChange: onChangeMaskingEndY,
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PointOrRectangleInput
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        properties={rectProperties}
        isHiddenInputArrow={true}
      />
      <ErrorMessage
        errors={errors}
        name="optionsDto.maskingOptions.valueList"
        render={({ message }) => <p className="error-message">{message}</p>}
      />
    </ErrorBoundaryWrapper>
  );
};

export default MaskingOptionsSelectArea;
