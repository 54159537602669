import { ErrorBoundaryWrapper } from '@autosquare/common';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ErrorMessage } from '@hookform/error-message';

import React from 'react';
import { useFormContext } from 'react-hook-form';

const TestStepWaitTimeHookForm = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CreateTestStepData>();

  return (
    <ErrorBoundaryWrapper>
      <div>
        <div className="flex items-start gap-4">
          <label
            htmlFor="waitTime"
            className="block pt-1 text-sm font-medium leading-6 text-gray-900"
          >
            WaitTime(sec)
          </label>
          <div className="w-full">
            <input
              type="number"
              name="waitTime"
              id="waitTime"
              className="input-base"
              defaultValue={2}
              {...register('optionsDto.waitingTime', {
                required: 'WaitTime을 입력해주세요.',
                min: { value: 0, message: '0 이상 숫자만 입력 가능합니다.' },
                max: { value: 60, message: '60 이하 숫자만 입력 가능합니다.' },
              })}
            />
          </div>
        </div>
        <ErrorMessage
          errors={errors}
          name="optionsDto.waitingTime"
          render={({ message }) => (
            <p className="error-message pt-2">{message}</p>
          )}
        />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default TestStepWaitTimeHookForm;
