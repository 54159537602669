import { Detail, TableBodyBasic } from '@autosquare/common';
import React from 'react';
import { NavigateFunction } from 'react-router-dom';

interface DeviceDetailCellProps {
  deviceIdx: number;
  isActivate: boolean;
  navigate: NavigateFunction;
}

const DeviceDetailCell = ({
  deviceIdx,
  isActivate,
  navigate,
}: DeviceDetailCellProps) => {
  return (
    <TableBodyBasic>
      <Detail
        onClick={() =>
          navigate({
            pathname: 'detail',
            search: `idx=${deviceIdx}`,
          })
        }
        disabled={!isActivate}
      />
    </TableBodyBasic>
  );
};

export default DeviceDetailCell;
