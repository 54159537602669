import {
  DoubleCheckDialogHeadlessUI,
  Button,
  ArrowSpinner,
  ErrorMessage,
} from '@autosquare/common';
import {
  TableBodyContainer,
  TableHeader,
  TableSkeleton,
} from '@autosquare/common';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  useCheckWdaStatusQuery,
  useRemoteDownloadWdaDataMutation,
  useRemoteUploadWdaDataMutation,
} from '@lib/api/ide/mobile/mobileDevice';
import React, { useState } from 'react';
import RemoteWebDriverAgentBodyRow from './RemoteWebDriverAgent/RemoteWebDriverAgentBodyRow';
import { useDispatch } from 'react-redux';
import { hasDownloadedData } from '@store/ide/settings/settingsSlice';
import { useDownloadDerivedDataQuery } from '@lib/api/ide/setting/webDriverAgentController';

type Props = {
  closeModal: () => void;
};

const RemoteWebDriverAgent = ({ closeModal }: Props) => {
  const [isDownloadDerivedData, setIsDownloadDerivedData] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState('');
  const [fileData, setFileData] = useState<
    { projectIdx: number; file: File }[]
  >([]);
  const dispatch = useDispatch();

  const checkWdaStatusQuery = useCheckWdaStatusQuery();
  const remotePCWdaStatusData = checkWdaStatusQuery.data;

  const downloadDerivedDataQuery = useDownloadDerivedDataQuery();
  const downloadDerivedData = downloadDerivedDataQuery.data;

  const refinedRemoteWdaData = remotePCWdaStatusData?.map((data) => ({
    ...data,
    filePath: downloadDerivedData?.find(
      (derivedData) => derivedData.projectIdx === data.projectIdx,
    )?.filePath,
  }));
  const uploadDerivedDataMutation = useRemoteUploadWdaDataMutation();
  const downloadDeriveDataMutation = useRemoteDownloadWdaDataMutation();
  const tableHeaders = ['Project Name', 'Status', 'Download', 'Upload'];

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const uploadPromises = fileData.map((data) =>
        uploadDerivedDataMutation.mutateAsync(data),
      );

      await Promise.all(uploadPromises);
      dispatch(hasDownloadedData(true));
    } catch (error) {
      setUploadErrorMessage(error);
      setIsModalOpened(true);
    } finally {
      closeModal();
    }
  };
  const onClickDownloadDataHandler = (projectIdx: number) => {
    setIsDownloadDerivedData(true);
    downloadDeriveDataMutation.mutate(projectIdx, {
      onSettled: () => {
        setIsDownloadDerivedData(false);
        downloadDerivedDataQuery.refetch();
        checkWdaStatusQuery.refetch();
      },
    });
  };

  return (
    <ErrorBoundaryWrapper>
      <div className="mt-10 pb-5">
        <div className="flex items-center gap-x-2 px-4 sm:px-0">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            WebDriverAgent
          </h3>
          {downloadDeriveDataMutation.isError && (
            <ErrorMessage>
              {downloadDeriveDataMutation.error.message}
            </ErrorMessage>
          )}
        </div>
        {checkWdaStatusQuery.isLoading ? (
          <div className="py-4">
            <TableSkeleton />
          </div>
        ) : checkWdaStatusQuery.isError ? (
          <ErrorMessage>{checkWdaStatusQuery.error.message}</ErrorMessage>
        ) : (
          <ErrorBoundaryWrapper>
            <form className="mt-4 flow-root" onSubmit={handleSubmit}>
              <div className="inline-block min-w-full py-2 align-middle">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      {tableHeaders.map((tableHeader) => (
                        <TableHeader key={tableHeader}>
                          {tableHeader}
                        </TableHeader>
                      ))}
                    </tr>
                  </thead>
                  <TableBodyContainer>
                    {refinedRemoteWdaData?.map((derivedData) => (
                      <RemoteWebDriverAgentBodyRow
                        key={derivedData.projectIdx}
                        derivedData={derivedData}
                        setFileData={setFileData}
                        onClickDownloadDataHandler={onClickDownloadDataHandler}
                      />
                    ))}
                  </TableBodyContainer>
                </table>
              </div>
              <div className="flex items-center justify-end">
                <Button type="submit">Save</Button>
              </div>
            </form>
          </ErrorBoundaryWrapper>
        )}
      </div>
      {(isDownloadDerivedData || uploadDerivedDataMutation.isLoading) && (
        <ArrowSpinner />
      )}
      <DoubleCheckDialogHeadlessUI
        isOpened={isModalOpened}
        setIsOpened={setIsModalOpened}
        type={'caution'}
        title={'Error'}
        subTitle={uploadErrorMessage}
        buttonChildren={
          <Button
            type="button"
            variant={'primary'}
            onClick={() => {
              setIsModalOpened(false);
              setUploadErrorMessage('');
            }}
          >
            Close
          </Button>
        }
      />
    </ErrorBoundaryWrapper>
  );
};

export default RemoteWebDriverAgent;
