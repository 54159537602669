import React from 'react';
import { PaperClipIcon } from '@heroicons/react/24/outline';
import { AddAppInfo } from '@customTypes/dashboard/project/application/type';
import { useFormContext } from 'react-hook-form';

type Props = {
  handleAppFileUpload: (file: File) => void;
};

const FileUploadInput = ({ handleAppFileUpload }: Props) => {
  const { register, setValue } = useFormContext<AddAppInfo>();
  return (
    <div className="flex flex-col items-center justify-center gap-y-3 py-5">
      <PaperClipIcon className="size-6" />
      <label htmlFor="file-upload">
        <p className="cursor-pointer text-sm font-semibold leading-tight text-indigo-600">
          Upload a File
        </p>
        <input
          id="file-upload"
          name="file-upload"
          type="file"
          className="sr-only"
          accept=".ipa, .apk"
          {...register('file', {
            onChange: (e) => {
              const file = e.target.files?.[0] || null;
              setValue('file', file, { shouldValidate: true });
              handleAppFileUpload(file);
            },
          })}
        />
      </label>
    </div>
  );
};

export default FileUploadInput;
