import {
  Button,
  Checkbox,
  ErrorMessage,
  useCheckbox,
} from '@autosquare/common';

import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import ExecutionAndDialog from './TestStepListTable/ExecutionAndDialog';
import TestStepListTableBody from './TestStepListTable/TestStepListTableBody';

import { useMobileTestStepByCaseQuery } from '@lib/api/ide/mobile/mobileTestStep';
import { useUpdateTestCaseListSequenceMutation } from '@lib/api/ide/mobile/mobileTestCase';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { DropResult } from 'react-beautiful-dnd';

import MobileTestStepCopyDialog from './TestStepListTable/MobileTestStepCopyDialog';
import { useTestStepCopyValidation } from '@hooks/testStep/useTestStepCopyValidation';
import TestStepListTableHeader from '@components/UITest/TestCaseDetail/TestStepListTableHeader';

interface Props {
  title: DeviceInfoOs;
}

const TestStepListTable = ({ title }: Props) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const [isTestStepExecutionOpened, setIsTestStepExecutionOpened] =
    useState(false);
  const [lists, setLists] = useState<MobileTestStepData[]>([]);
  const [indexArray, setIndexArray] = useState<number[]>(null);

  const mobileTestStepInTestCaseQuery = useMobileTestStepByCaseQuery(idx);
  const mobileTestStepList = mobileTestStepInTestCaseQuery.data;

  const selectedMobileTestStepList =
    title === DeviceInfoOs.Aos
      ? mobileTestStepList?.aos
      : mobileTestStepList?.ios;

  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(selectedMobileTestStepList, 'idx');

  useEffect(() => {
    setLists(selectedMobileTestStepList);
  }, [mobileTestStepList]);

  const updateTestCaseListSequenceMutation =
    useUpdateTestCaseListSequenceMutation({
      idx: idx,
      mobileOs: title,
      indexArray: indexArray,
    });

  useEffect(() => {
    updateTestCaseListSequenceMutation.mutate();
  }, [indexArray]);

  const handleOnDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      const items = Array.from(lists);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setLists(items);
      setIndexArray(items.map((items) => items.idx));
    },
    [lists],
  );

  const {
    isCopyDialogOpen,
    setIsCopyDialogOpen,
    copyErrorMessage,
    copyAllButtonOnClick,
    uniqueImportTestIdxList,
  } = useTestStepCopyValidation(selectedMobileTestStepList, selectedArray);

  const tableTitles = [
    {
      idx: 1,
      element: (
        <Checkbox
          checked={selectedArray.length > 0}
          onChange={(e) => selectAllArray(e)}
        />
      ),
    },
    {
      idx: 2,
      element: selectedArray.length > 0 ? <div className="h-5 w-3" /> : '#',
    },
    {
      idx: 3,
      element:
        selectedArray.length > 0 ? <div className="h-5 w-20" /> : 'Command',
    },
    {
      idx: 4,
      element:
        selectedArray.length > 0 ? <div className="h-5 w-24" /> : 'Description',
    },
  ];

  return (
    <>
      {mobileTestStepInTestCaseQuery.isError ? (
        <ErrorMessage>
          {mobileTestStepInTestCaseQuery.error.message}
        </ErrorMessage>
      ) : (
        <div className="mt-6 grid-cols-1">
          <div className="flex gap-2.5">
            <h3 className="text-base font-semibold">
              {title === DeviceInfoOs.Aos
                ? 'Android'
                : DeviceInfoOs.Ios && 'iOS'}
            </h3>
            <p className="error-message">{copyErrorMessage}</p>
          </div>
          <div className="flow-root">
            <div className="-mx-4 -my-2 overflow-x-hidden scrollbar-thin sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {selectedArray.length > 0 && (
                  <div className="relative">
                    <div className="div-delete-all left-16 2xl:left-20">
                      <Button
                        type="button"
                        variant="primary"
                        buttonSize="xs"
                        onClick={copyAllButtonOnClick}
                      >
                        Copy all
                      </Button>
                      <Button
                        type="button"
                        variant="secondary"
                        buttonSize="xs"
                        onClick={openDialog}
                      >
                        Delete all
                      </Button>
                    </div>
                  </div>
                )}
                <table className="h-full min-w-full divide-y divide-gray-300">
                  <TestStepListTableHeader tableTitles={tableTitles} />
                  <TestStepListTableBody
                    title={title}
                    mobileTestStepList={mobileTestStepList}
                    tableHeaderLength={tableTitles.length}
                    handleOnDragEnd={handleOnDragEnd}
                    selectArray={selectArray}
                    selectedArray={selectedArray}
                    setIsTestStepExecutionOpened={setIsTestStepExecutionOpened}
                    lists={lists}
                  />
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      <MobileTestStepCopyDialog
        isOpen={isCopyDialogOpen}
        setIsOpen={setIsCopyDialogOpen}
        selectedArray={selectedArray}
        uniqueImportTestIdxList={uniqueImportTestIdxList}
      />
      <ExecutionAndDialog
        isTestStepExecutionOpened={isTestStepExecutionOpened}
        setIsTestStepExecutionOpened={setIsTestStepExecutionOpened}
        title={title}
        isOpenedCautionDialog={isOpenedCautionDialog}
        setIsOpenedCautionDialog={setIsOpenedCautionDialog}
        selectedArray={selectedArray}
        openDialog={openDialog}
        closeDialog={closeDialog}
        resetSelectedArray={resetSelectedArray}
      />
    </>
  );
};

export default TestStepListTable;
