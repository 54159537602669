import React from 'react';
import clsx from 'clsx';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  ErrorBoundaryWrapper,
  ResultsToolbar,
  SpeedTestResultTableHeader,
  Time,
} from '@autosquare/common';
import {
  MobileSpeedTestResultData,
  SpeedMobileTestResultDisplayType,
} from '@customTypes/ide/speedTest/Mobile/testResult/type';
import { timePattern } from '@utils/static/timePattern';
import dayjs from 'dayjs';
import NoSpeedTestResultMessage from '@components/Search/NoSpeedTestResultMessage';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { TestResultDisplay, TestResultStatus } from '@customTypes/index';

type Props = {
  resultData: MobileSpeedTestResultData;
};

const IdeMobileSpeedTestResultTable = ({ resultData }: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentPage = searchParams.get('page');
  const { pathname, search } = useLocation();

  const resultWithIndex = resultData?.content?.map((item, index) => ({
    ...item,
    index: index + 1,
  }));

  return (
    <ErrorBoundaryWrapper>
      {resultData?.content?.length === 0 ? (
        <NoSpeedTestResultMessage />
      ) : (
        <>
          <ResultsToolbar totalResults={resultData?.totalElements} />
          <div className="mt-6">
            {resultWithIndex?.map((item) => (
              <div key={item.index}>
                <div className="flex items-center justify-between leading-tight">
                  <div className="my-3 text-sm font-semibold">{item.group}</div>
                  <div
                    className={clsx(
                      'font-semibold',
                      item.resultEnum === 'RUNNING'
                        ? 'text-gray-400'
                        : 'text-gray-900',
                    )}
                  >
                    <button
                      type="button"
                      className="flex items-center justify-center gap-x-1.5 text-xs"
                      aria-label="navigate detail"
                      disabled={item.resultEnum === 'RUNNING'}
                      onClick={() => {
                        localStorage.setItem('entryPoint', pathname + search);
                        navigate(
                          `detail?result=${item.resultIdx}&group=${item.groupIdx}&start=${dayjs(item.dateTime).subtract(14, 'days').format('YYYYMMDD')}&end=${dayjs(item.dateTime).format('YYYYMMDD')}&status=${item.resultEnum}&page=${currentPage}`,
                        );
                      }}
                    >
                      Detail
                      <ChevronRightIcon className="size-5" />
                    </button>
                  </div>
                </div>
                <div className="gray-xy-scrollbar">
                  <table className="min-w-full bg-white">
                    <thead className="bg-gray-50">
                      <tr className="border-b border-gray-200">
                        <SpeedTestResultTableHeader width="w-40">
                          Type
                        </SpeedTestResultTableHeader>
                        <SpeedTestResultTableHeader width="w-40">
                          Environment
                        </SpeedTestResultTableHeader>
                        <SpeedTestResultTableHeader width="w-40">
                          Tester
                        </SpeedTestResultTableHeader>
                        <SpeedTestResultTableHeader width="w-40">
                          DateTime
                        </SpeedTestResultTableHeader>
                        {item.loadSpeedData.map((data, index) => (
                          <SpeedTestResultTableHeader key={index}>
                            {data.name}
                          </SpeedTestResultTableHeader>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-b border-gray-400">
                        <td className="px-4 py-2 text-sm text-gray-900">
                          {SpeedMobileTestResultDisplayType[item.testType]}
                        </td>
                        <td className="px-4 py-2 text-sm">
                          <div className="text-gray-900">
                            {item.environment.deviceName}
                          </div>
                          <div className="text-gray-500">
                            {item.environment.os}
                          </div>
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-900">
                          {item.tester}
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-900">
                          <div>{item?.dateTime?.split(' ')[0] ?? '-'}</div>
                          <div
                            className={`mt-1 flex items-center justify-start gap-2 text-gray-500`}
                          >
                            {item?.dateTime && <Time />}
                            {RegExp(timePattern).exec(item.dateTime)[0]}
                          </div>
                        </td>
                        {item.loadSpeedData.map((data, index) => (
                          <td
                            key={index}
                            className={clsx(
                              'px-4 py-2 text-sm',
                              data.value > 3 ? 'text-red-500' : 'text-gray-900',
                            )}
                          >
                            {data.result === TestResultStatus.Pass
                              ? data.value
                              : TestResultDisplay[data.result]}
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </ErrorBoundaryWrapper>
  );
};

export default IdeMobileSpeedTestResultTable;
