import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  CommonDialog,
  DialogButton,
  DialogButtonPanels,
} from '@components/Dialog';
import React from 'react';

type Props = {
  testStepResult: {
    result: 'Pass' | 'Fail' | 'N/A';
    fail_reason?: string;
    load_speed?: string;
  };
  isResultModalOpened: boolean;
  setIsResultModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setIsExecutionStarted: React.Dispatch<React.SetStateAction<boolean>>;
};

const RepeatTestStepResultModal = ({
  testStepResult,
  isResultModalOpened,
  setIsResultModalOpened,
  setIsExecutionStarted,
}: Props) => {
  const loadSpeed = testStepResult?.load_speed?.replace('s', '(sec)');

  const onClose = () => {
    setIsResultModalOpened(false);
    setIsExecutionStarted(false);
  };

  if (testStepResult?.load_speed && testStepResult?.result === 'Pass') {
    return (
      <ErrorBoundaryWrapper>
        <CommonDialog
          isOpen={isResultModalOpened}
          setIsOpen={setIsResultModalOpened}
          iconType={'checked'}
          title={'Execute Success'}
          subTitle={`속도 측정 결과: ${loadSpeed}`}
          onClose={onClose}
        >
          <DialogButtonPanels>
            <DialogButton type="button" variant="primary" onClick={onClose}>
              Close
            </DialogButton>
          </DialogButtonPanels>
        </CommonDialog>
      </ErrorBoundaryWrapper>
    );
  }

  if (testStepResult?.fail_reason && testStepResult?.result === 'Fail') {
    return (
      <ErrorBoundaryWrapper>
        <CommonDialog
          isOpen={isResultModalOpened}
          setIsOpen={setIsResultModalOpened}
          iconType={'caution'}
          title={'Execute Failure'}
          subTitle={`테스트 결과: Fail\n실패 사유: ${testStepResult?.fail_reason}`}
          onClose={onClose}
        >
          <DialogButtonPanels>
            <DialogButton type="button" variant="primary" onClick={onClose}>
              Close
            </DialogButton>
          </DialogButtonPanels>
        </CommonDialog>
      </ErrorBoundaryWrapper>
    );
  }

  if (testStepResult?.fail_reason && testStepResult?.result === 'N/A') {
    return (
      <ErrorBoundaryWrapper>
        <CommonDialog
          isOpen={isResultModalOpened}
          setIsOpen={setIsResultModalOpened}
          iconType={'yellow caution'}
          title={'Execute Error'}
          subTitle={`테스트 결과: N/A\n실패 사유: ${testStepResult?.fail_reason}`}
          onClose={onClose}
        >
          <DialogButtonPanels>
            <DialogButton type="button" variant="primary" onClick={onClose}>
              Close
            </DialogButton>
          </DialogButtonPanels>
        </CommonDialog>
      </ErrorBoundaryWrapper>
    );
  }

  return;
};

export default RepeatTestStepResultModal;
