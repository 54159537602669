import React, { useState } from 'react';
import {
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
} from '@autosquare/common';
import { PlusIcon } from '@heroicons/react/24/outline';
import {
  AutoRegisterInfoData,
  UpdateSchedulerData,
} from '@customTypes/ide/scheduler/scheduler';
import IdeAutoRegisterList from '@components/IdeAutoRegisterList/IdeAutoRegisterList';
import IdeAutoRegisterModal from '@components/IdeAutoRegisterCreateEditModal/IdeAutoRegisterModal';
import IdeAutoRegisterEditModal from '@components/IdeAutoRegisterCreateEditModal/IdeAutoRegisterEditModal';
import { useFieldArray, useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import SchedulerGridContainer from '@components/Scheduler/SchedulerGridContainer';

type Props = {
  isRunning: boolean;
  autoRegistersList: AutoRegisterInfoData[];
  setAutoRegistersList: React.Dispatch<
    React.SetStateAction<AutoRegisterInfoData[]>
  >;
};

const SchedulerDetailAutoRegister = ({
  isRunning,
  autoRegistersList,
  setAutoRegistersList,
}: Props) => {
  const { control, resetField } = useFormContext<UpdateSchedulerData>();
  const { fields, append, remove, update } = useFieldArray<
    UpdateSchedulerData,
    'autoRegisters'
  >({
    control,
    name: 'autoRegisters',
  });

  const [isAddClicked, setIsAddClicked] = useState(false);
  const [index, setIndex] = useState<number>(null);

  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [openCaution, setOpenCaution] = useState(false);

  const deleteButtonHandler = () => {
    const removeList = autoRegistersList.filter((_, idx) => idx !== index);
    setAutoRegistersList(removeList);
    remove(index);
    setOpenCaution(false);
  };

  return (
    <ErrorBoundaryWrapper>
      <SchedulerGridContainer
        title={'AutoRegister'}
        tooltipWord={'테스트 결과를 자동 등록할 플랫폼을 선택해 주세요.'}
        tooltipClass={'-right-32 bottom-6'}
      >
        <div className="mt-2 sm:col-span-2 sm:mt-0">
          <div className="flex items-center gap-x-5">
            {autoRegistersList.length > 0 && (
              <IdeAutoRegisterList
                autoDataRegisterList={autoRegistersList}
                setIndex={setIndex}
                setIsEditModalOpened={setIsEditModalOpened}
                setOpenCaution={setOpenCaution}
                isEditable={isRunning}
              />
            )}
            {isRunning && (
              <Button
                type="button"
                variant="imageOriginal"
                buttonSize="none"
                className={clsx(
                  'flex items-center justify-center',
                  !isRunning && 'text-gray-400',
                )}
                onClick={() => {
                  resetField('autoRegisters');
                  append({ platformName: '' });
                  setIsAddClicked(true);
                }}
              >
                <PlusIcon className="size-6" />
                <p
                  className={clsx(
                    'text-sm font-normal',
                    isRunning ? 'text-gray-900' : 'text-gray-500',
                  )}
                >
                  Add
                </p>
              </Button>
            )}
            {isAddClicked && (
              <IdeAutoRegisterModal
                isAddClicked={isAddClicked}
                setIsAddClicked={setIsAddClicked}
                autoRegistersList={autoRegistersList}
                setAutoRegistersList={setAutoRegistersList}
                fields={fields}
                update={update}
                remove={remove}
              />
            )}
          </div>
          <DoubleCheckDialogHeadlessUI
            isOpened={openCaution}
            setIsOpened={setOpenCaution}
            type={'caution'}
            title={'Delete Platform'}
            subTitle={
              '삭제하시면 해당 플랫폼에 테스트 결과 자동 등록이 불가능 합니다.'
            }
            buttonChildren={
              <>
                <Button
                  type="button"
                  variant={'caution'}
                  onClick={deleteButtonHandler}
                >
                  Delete
                </Button>
                <Button
                  type="button"
                  variant={'secondary'}
                  onClick={() => setOpenCaution(false)}
                >
                  Cancel
                </Button>
              </>
            }
          />
          {isEditModalOpened && (
            <IdeAutoRegisterEditModal
              index={index}
              update={update}
              selectedData={autoRegistersList?.[index]}
              autoRegistersList={autoRegistersList}
              setAutoRegisterList={setAutoRegistersList}
              isEditModalOpened={isEditModalOpened}
              setIsEditModalOpened={setIsEditModalOpened}
            />
          )}
        </div>
      </SchedulerGridContainer>
    </ErrorBoundaryWrapper>
  );
};

export default SchedulerDetailAutoRegister;
