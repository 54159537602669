import { Button, ErrorMessage, NoDevice } from '@autosquare/common';

import React, { useEffect, useState } from 'react';

import { useDeviceListQuery } from '@lib/api/ide/mobile/mobileDevice';

import { DeviceInfo, DeviceInfoOs } from '@customTypes/ide/device/device';

import { useSpeedMobileTestCaseDetailQuery } from '@lib/api/ide/speedMobile/speedMobileTestCase';
import SpeedDevice from './SelectedDevices/SpeedDevice';
import SpeedSelectedDevicesHeader from './SelectedDevices/SpeedSelectedDevicesHeader';
import SpeedDeviceSkeleton from './SelectedDevices/SpeedDeviceSkeleton';
import clsx from 'clsx';
import { TestTypeCategory } from '@autosquare/common/src/utils/type';
import { Mode } from '@customTypes/ide/remote/type';
import { useRemoteDeviceListQuery } from '@lib/api/ide/remote/remote';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';
import { getRemoteState } from '@utils/static/getConfiguration';

interface Props {
  selectedDevices: DeviceInfo[];
  setSelectedDevices: React.Dispatch<React.SetStateAction<DeviceInfo[]>>;
  checkHandler: (
    e: React.ChangeEvent<HTMLInputElement>,
    value: DeviceInfo,
  ) => void;
  isSelectedDevices: boolean;
  setIsSelectedDevices: React.Dispatch<React.SetStateAction<boolean>>;
  divWidth: number;
  testType: TestTypeCategory;
  mobileOS: DeviceInfoOs;
  idx: number;
  needIosSetting: boolean;
  setNeedIosSetting: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedBrowser: React.Dispatch<React.SetStateAction<string>>;
  setIsWirelessDeviceModal: React.Dispatch<React.SetStateAction<boolean>>;
  setConnectionType: React.Dispatch<React.SetStateAction<'wifi' | 'port'>>;
}

const SpeedSelectedDevices = ({
  selectedDevices,
  setSelectedDevices,
  checkHandler,
  isSelectedDevices,
  setIsSelectedDevices,
  divWidth,
  testType,
  mobileOS,
  idx,
  needIosSetting,
  setNeedIosSetting,
  setIsSettingsOpen,
  setSelectedBrowser,
  setIsWirelessDeviceModal,
  setConnectionType,
}: Props) => {
  const [gridCols, setGridCols] = useState('grid-cols-3');

  useEffect(() => {
    if (divWidth > 1024) {
      setGridCols('grid-cols-4');
    } else {
      setGridCols('grid-cols-3');
    }
  }, [divWidth]);

  const { data: mobileTestCase } =
    testType === 'case' && useSpeedMobileTestCaseDetailQuery(idx.toString());

  const aosCount = mobileTestCase?.steps?.aosStepCount;
  const iosCount = mobileTestCase?.steps?.iosStepCount;
  const { configuration } = getRemoteState();
  const {
    data: deviceLists,
    isFetching,
    isLoading,
    error,
    isError,
    refetch,
  } = configuration === Mode.Standard
    ? useDeviceListQuery()
    : useRemoteDeviceListQuery();

  const aosDeviceList = deviceLists
    ?.filter((deviceList) => deviceList.os === DeviceInfoOs.Aos)
    .map((device) => {
      if (selectedDevices.length > 0) {
        return {
          ...device,
          enabled: selectedDevices.some(
            (selectedDevice) => selectedDevice.udid === device.udid,
          ),
        };
      } else {
        return device;
      }
    });

  const iosDeviceList = deviceLists
    ?.filter((deviceList) => deviceList.os === DeviceInfoOs.Ios)
    .map((device) => {
      if (selectedDevices.length > 0) {
        return {
          ...device,
          enabled: selectedDevices.some(
            (selectedDevice) => selectedDevice.udid === device.udid,
          ),
        };
      } else {
        return device;
      }
    });

  const selectedIos = selectedDevices?.some(
    (device) => device.os === DeviceInfoOs.Ios && device.enabled,
  );
  const selectedAos = selectedDevices?.some(
    (device) => device.os === DeviceInfoOs.Aos && device.enabled,
  );

  return (
    <div>
      <SpeedSelectedDevicesHeader
        isSelectedDevices={isSelectedDevices}
        setIsSelectedDevices={setIsSelectedDevices}
        setSelectedDevices={setSelectedDevices}
        refetch={refetch}
        setNeedIosSetting={setNeedIosSetting}
        setIsWirelessDeviceModal={setIsWirelessDeviceModal}
        setConnectionType={setConnectionType}
      />
      {isLoading || isFetching ? (
        <SpeedDeviceSkeleton gridCols={gridCols} />
      ) : isError ? (
        <ErrorMessage>{error.message}</ErrorMessage>
      ) : (
        <ul role="list" className={`my-3 grid grid-rows-2 gap-4`}>
          <div className="flex flex-col gap-y-3 text-sm">
            <div className="flex gap-x-3">
              <p className="text-gray-900">Android</p>
              <p className="h-5 w-[1.875rem] rounded-lg bg-gray-100 text-center">
                {selectedAos ? 1 : 0}
              </p>
            </div>
            <hr />
            {aosDeviceList.length === 0 ? (
              <NoDevice />
            ) : (
              <div className={clsx('grid gap-x-4', gridCols)}>
                {aosDeviceList?.map((deviceList) => (
                  <SpeedDevice
                    deviceList={deviceList}
                    testType={testType}
                    mobileOS={mobileOS}
                    aosCount={aosCount}
                    iosCount={iosCount}
                    selectedDevices={selectedDevices}
                    checkHandler={checkHandler}
                    key={deviceList.udid}
                    setSelectedBrowser={setSelectedBrowser}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-y-3 text-sm">
            <div className="flex gap-x-3">
              <p className="text-gray-900">iOS</p>
              <p className="h-5 w-[1.875rem] rounded-lg bg-gray-100 text-center">
                {selectedIos ? 1 : 0}
              </p>
            </div>
            <hr />
            {iosDeviceList.length === 0 ? (
              <NoDevice />
            ) : needIosSetting ? (
              <div className="mt-3 flex w-full flex-col items-center justify-center gap-4 rounded-md border border-solid border-gray-200 bg-gray-50 p-2 text-base leading-5">
                <p className="text-red-500">Requires setup for iOS testing.</p>
                <Button
                  type="button"
                  variant="primary"
                  onClick={() => setIsSettingsOpen(true)}
                >
                  Open iOS Test Settings
                </Button>
              </div>
            ) : (
              <div className={clsx('grid gap-x-1', gridCols)}>
                {iosDeviceList?.map((deviceList) => (
                  <SpeedDevice
                    deviceList={deviceList}
                    testType={testType}
                    mobileOS={mobileOS}
                    aosCount={aosCount}
                    iosCount={iosCount}
                    selectedDevices={selectedDevices}
                    checkHandler={checkHandler}
                    key={deviceList.udid}
                    setSelectedBrowser={setSelectedBrowser}
                  />
                ))}
              </div>
            )}
          </div>
        </ul>
      )}
      <RemoteConnectionAlarmModal />
    </div>
  );
};

export default SpeedSelectedDevices;
