import React, { useState } from 'react';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  useCopyTestCaseMutation,
  useDeleteTestCaseMutation,
} from '@lib/api/ide/queryHooks';
import {
  TestCaseDetailData,
  UpdateMobileTestCaseData,
} from '@customTypes/index';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { DialogButton, DialogButtonPanels } from '@components/Dialog';
import { CommonDialog } from '@components/Dialog';
import { UseMutationResult } from '@tanstack/react-query';
import { useParentPath } from '@hooks/index';

type TestCaseInfoEditDialogsProps = {
  isUpdateErrorModalOpened: boolean;
  setIsUpdateErrorModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isDeleteTestCaseModalOpened: boolean;
  setIsDeleteTestCaseModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isCopyModalOpen: boolean;
  setIsCopyModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateMobileTestCaseMutation: UseMutationResult<
    TestCaseDetailData,
    Error,
    UpdateMobileTestCaseData
  >;
};

const TestCaseInfoEditDialogs = ({
  isUpdateErrorModalOpened,
  setIsUpdateErrorModalOpened,
  isDeleteTestCaseModalOpened,
  setIsDeleteTestCaseModalOpened,
  isCopyModalOpen,
  setIsCopyModalOpen,
  updateMobileTestCaseMutation,
}: TestCaseInfoEditDialogsProps) => {
  const { projectIdx } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const [isCopyCompleteModalOpen, setIsCopyCompleteModalOpen] = useState(false);
  const [copiedTestCaseIdx, setCopiedTestCaseIdx] = useState<string | null>(
    null,
  );
  const [isCopyErrorModalOpen, setIsCopyErrorModalOpen] = useState(false);
  const [isDeleteTestCaseErrorModalOpen, setIsDeleteTestCaseErrorModalOpen] =
    useState(false);

  const closeCopyModal = () => {
    setIsCopyModalOpen(false);
  };

  // Delete Test Case
  const deleteTestCaseMutation = useDeleteTestCaseMutation();

  const closeDeleteTestCaseModal = () => {
    setIsDeleteTestCaseModalOpened(false);
  };

  const testCasePath = useParentPath();

  const deleteTestCase = () => {
    deleteTestCaseMutation.mutate(
      { idx_list: [Number(idx)] },
      {
        onSuccess: () => {
          navigate(testCasePath);
        },
        onError: () => {
          setIsDeleteTestCaseErrorModalOpen(true);
        },
        onSettled: () => {
          closeDeleteTestCaseModal();
        },
      },
    );
  };

  // Copy Test Case
  const copyTestCaseMutation = useCopyTestCaseMutation();

  const handleCopyTestCase = () => {
    if (!projectIdx) {
      console.error('Project ID is undefined');
      return;
    }

    copyTestCaseMutation.mutate(
      { idx: idx, projectIdx: projectIdx },
      {
        onSuccess: (response: TestCaseDetailData) => {
          setCopiedTestCaseIdx(response.idx.toString());
          setIsCopyCompleteModalOpen(true);
        },
        onError: () => {
          setIsCopyErrorModalOpen(true);
        },
        onSettled: () => {
          closeCopyModal();
        },
      },
    );
  };

  const handleGoToCopiedCase = () => {
    if (copiedTestCaseIdx) {
      navigate(`${pathname}?idx=${copiedTestCaseIdx}`);
    }
    setIsCopyCompleteModalOpen(false);
  };

  return (
    <ErrorBoundaryWrapper>
      {/* Update 에러 모달 */}
      <CommonDialog
        isOpen={isUpdateErrorModalOpened}
        setIsOpen={setIsUpdateErrorModalOpened}
        iconType="caution"
        title="Error"
        subTitle={
          updateMobileTestCaseMutation.isError &&
          updateMobileTestCaseMutation.error.message
        }
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant="secondary"
            onClick={() => setIsUpdateErrorModalOpened(false)}
          >
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>

      {/* Delete 모달 */}
      <CommonDialog
        isOpen={isDeleteTestCaseModalOpened}
        setIsOpen={setIsDeleteTestCaseModalOpened}
        iconType="caution"
        title="Delete Test Case"
        subTitle={'해당 테스트 케이스를 삭제하시겠습니까?'}
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant="caution"
            onClick={deleteTestCase}
            disabled={deleteTestCaseMutation.isLoading}
          >
            Delete
          </DialogButton>
          <DialogButton
            type="button"
            variant="secondary"
            onClick={closeDeleteTestCaseModal}
          >
            Cancel
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>

      {/* Delete Test Case Error 모달 */}
      <CommonDialog
        isOpen={isDeleteTestCaseErrorModalOpen}
        setIsOpen={setIsDeleteTestCaseErrorModalOpen}
        iconType="caution"
        title="Error"
        subTitle={
          deleteTestCaseMutation.isError && deleteTestCaseMutation.error.message
        }
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant="secondary"
            onClick={() => setIsDeleteTestCaseErrorModalOpen(false)}
          >
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>

      {/* Copy Test Case 모달 */}
      <CommonDialog
        isOpen={isCopyModalOpen}
        setIsOpen={setIsCopyModalOpen}
        iconType="checked"
        title="Copy Test Case"
        subTitle={'해당 테스트 케이스를 복사하시겠습니까?'}
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant="primary"
            onClick={handleCopyTestCase}
            disabled={copyTestCaseMutation.isLoading}
          >
            Copy
          </DialogButton>
          <DialogButton
            type="button"
            variant="secondary"
            onClick={() => setIsCopyModalOpen(false)}
          >
            Cancel
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>

      {/* Copy Test Case 완료 모달 */}
      <CommonDialog
        isOpen={isCopyCompleteModalOpen}
        setIsOpen={setIsCopyCompleteModalOpen}
        iconType="checked"
        title="Test Case Copy Complete"
        subTitle={'테스트 케이스 복사가 완료되었습니다.'}
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant="secondary"
            onClick={() => setIsCopyCompleteModalOpen(false)}
          >
            Close
          </DialogButton>
          <DialogButton
            type="button"
            variant="primary"
            onClick={handleGoToCopiedCase}
          >
            Go to copied case
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>

      {/* Copy Test Case Error 모달 */}
      <CommonDialog
        isOpen={isCopyErrorModalOpen}
        setIsOpen={setIsCopyErrorModalOpen}
        iconType="caution"
        title="Error"
        subTitle={
          copyTestCaseMutation.isError && copyTestCaseMutation.error.message
        }
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant="secondary"
            onClick={() => setIsCopyErrorModalOpen(false)}
          >
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default TestCaseInfoEditDialogs;
