import { AutoRegisterPlatformServername } from '@utils/static/autoRegister/autoRegisterTypeList';
import { ProjectListDetailFeatures } from '../project/type';
import { z } from 'zod';
import { SignupRequestBaseSchema } from '../user/type';

export enum AdminUserStatus {
  Activate = 'Activate',
  Deactivate = 'Deactivate',
  Pending = 'Pending',
}

export enum AdminUserRole {
  Admin = 'Admin',
  Member = 'Member',
}

export type AdminUserData = {
  email: string;
  lastSeen: string;
  name: string;
  role: AdminUserRole;
  status: AdminUserStatus;
  userIdx: number;
  jobTitle: string;
  organization: string;
  department: string;
  projectCount: number;
  registered: string;
  updatedAt: string;
  projectList: { projectIdx: number; projectTitle: string; userRole: string }[];
};

export type AdminProjectListDetail = {
  description: string;
  idx: number;
  name: string;
  projectAdmin: string;
  updatedAt: string;
  userCount: number;
};

export const AdminSystemSettingsDataSchema = z.object({
  test_results_data_period: z.string(),
  device: z.enum(['true', 'false']),
  reservation: z.enum(['true', 'false']),
});

export type AdminSystemSettingsData = z.infer<
  typeof AdminSystemSettingsDataSchema
>;

export const AdminSystemSettingsSchema = z.object({
  data: AdminSystemSettingsDataSchema,
});

export type AdminSystemSettings = z.infer<typeof AdminSystemSettingsSchema>;

export const AdminDataStorageSettingSchema = AdminSystemSettingsDataSchema.pick(
  { test_results_data_period: true },
);

export type AdminDataStorageSetting = z.infer<
  typeof AdminDataStorageSettingSchema
>;

export const UpdateAdminSettingSchema = z.object({
  value: z.union([
    AdminSystemSettingsDataSchema.shape.test_results_data_period,
    AdminSystemSettingsDataSchema.shape.device,
    AdminSystemSettingsDataSchema.shape.reservation,
  ]),
});

export type UpdateAdminSetting = z.infer<typeof UpdateAdminSettingSchema>;

export type AdminWebDriverListData = {
  idx: number;
  os: string;
  browser: string;
  version: string;
  cpuArch: string;
};

export interface InviteUserResponse {
  signupPath: string;
}

export const UpdateAdminUserInfoSchema = SignupRequestBaseSchema.pick({
  jobTitle: true,
  organization: true,
  department: true,
}).extend({ role: z.nativeEnum(AdminUserRole), status: z.boolean() });

export type UpdateAdminUserInfo = z.infer<typeof UpdateAdminUserInfoSchema>;

export type MessengerPlatformCode =
  | 'MSGR01'
  | 'MSGR02'
  | 'MSGR03'
  | 'MSGR10'
  | 'MSGR11'
  | 'MSGR12'
  | 'MSGR99';

export interface MessengerPlatformRequiredInfo {
  platformCode: MessengerPlatformCode;
  serverName: string;
  platformTitle: string;
  required: string[];
}
export interface BaseMessengerSettingsDto {
  platform?: string;
  activate?: boolean;
  requestUrl?: string;
}

export interface MSG10To12AlarmSettings extends BaseMessengerSettingsDto {
  srvCode?: string;
  sender?: string;
  recipientList?: string[];
}

export interface HiworksAlarmSettings extends BaseMessengerSettingsDto {
  officeToken?: string;
  recipientList?: string[];
}

export interface TeamsAlarmSettings extends BaseMessengerSettingsDto {}

export type MessengerAlarmType = MSG10To12AlarmSettings &
  HiworksAlarmSettings &
  TeamsAlarmSettings;

export interface BaseAutoRegisterSettingsDto {
  activate: boolean;
  platform: AutoRegisterPlatformServername;
  email: string;
  apiToken: string;
}

export interface JiraPlatform extends BaseAutoRegisterSettingsDto {
  requestUrl: string;
}
export interface ConfluencePlatform extends BaseAutoRegisterSettingsDto {}

export type AutoRegisterType = JiraPlatform & ConfluencePlatform;

export interface CreateAdminProjectData {
  name: string;
  serviceName: string;
  description: string;
  projectAdminList: string[];
  features: ProjectListDetailFeatures[];
  messengers: MessengerAlarmType[];
  recipientId?: string;
  recipientInfo?: string;
  recipientFormat?: Array<{ dataId: string; info: string }>;
  registers: AutoRegisterType[];
  platformInput?: AutoRegisterType;
  platform?: AutoRegisterPlatformServername;
}

export interface UpdateAdminProjectDetailData {
  name: string;
  serviceName: string;
  description: string;
  projectAdminList: string[];
  features: ProjectListDetailFeatures[];
  messengers: MessengerAlarmType[] | [];
  recipientId?: string;
  recipientInfo?: string;
  recipientFormat?: Array<{ dataId: string; info: string }>;
  registers: AutoRegisterType[];
  platformInput?: AutoRegisterType;
  platform?: AutoRegisterPlatformServername;
}

export interface UploadAdminWebDriverData {
  uploadFile: File;
  os: string;
  browser: string;
  version: string;
  cpuArch: string;
}

export interface UploadAdminWebDriverResponse {
  idx: number;
  os: string;
  browser: string;
  version: string;
  cpuArch: string;
  status: string;
}

export const AdminUserEmailSchema = z.object({
  inviteUserEmail: z.string().email({ message: 'Invalid email address' }),
  inviteLink: z.string().optional(),
  inviteError: z.string().optional(),
});

export type AdminUserEmail = z.infer<typeof AdminUserEmailSchema>;

export type DeleteAdminUserData = {
  userIdx: number;
  userEmail: string;
};

export type DeleteAdminUserErrorData = {
  projectId: number;
  projectName: string;
};
