import {
  ErrorMessage,
  PageHeadingButtons,
  PagingHeadingButtonOnClick,
} from '@autosquare/common';

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '@app/store';
import { useCreateMobileTestScenarioMutation } from '@lib/api/ide/mobile/mobileTestScenario';
import { useCreateWebTestScenarioMutation } from '@lib/api/ide/web/webTestScenario';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import InputSpace from './components/InputSpace';
import DndSpace from './components/DndSpace';

import { CreateTestScenarioData } from '@customTypes/testScenario/type';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { AutoRegisterInfoData } from '@customTypes/ide/scheduler/scheduler';

const CreateTestScenario = () => {
  const [autoRegistersList, setAutoRegistersList] = useState<
    AutoRegisterInfoData[]
  >([]);
  const location = useLocation();

  const autoRegistersListWithoutId = autoRegistersList.map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ id, ...rest }) => rest,
  );
  const methods = useForm<CreateTestScenarioData>();

  const testCaseIdxs = useSelector(
    (state: RootState) => state.numberArray.testCaseIndexArray,
  );

  const createTestScenarioMutation = isWordInLocationPathname('mobile')
    ? useCreateMobileTestScenarioMutation()
    : useCreateWebTestScenarioMutation();

  const onSubmit: SubmitHandler<CreateTestScenarioData> = (data) => {
    const updatedData = {
      ...data,
      testCaseIdxs: testCaseIdxs,
      autoRegisters: autoRegistersListWithoutId,
    };

    if (testCaseIdxs.length === 0) return;

    createTestScenarioMutation.mutate(updatedData);
  };
  const breadcrumbs = [
    {
      title: 'UI Test',
      href: location.pathname.split('/').slice(0, -3).join('/') + '/overview',
    },
    {
      title: isWordInLocationPathname('mobile') ? 'Mobile' : 'Web',
      href: location.pathname.split('/').slice(0, -1).join('/'),
    },
    {
      title: 'Test Scenario',
      href: location.pathname.split('/').slice(0, -1).join('/'),
    },
    {
      title: 'Create',
      href: location.pathname,
    },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    { title: 'Save', type: 'submit' },
  ];

  useEffect(() => {
    if (methods.formState.isSubmitted && testCaseIdxs.length === 0) {
      methods.setError('testCaseIdxs', {
        type: 'required',
        message: '필수 선택 항목입니다.',
      });
    }
    if (methods.formState.isSubmitted && testCaseIdxs.length > 0) {
      methods.clearErrors('testCaseIdxs');
    }
  }, [methods.formState.isSubmitted, testCaseIdxs]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PagingHeadingButtonOnClick
          breadcrumbs={breadcrumbs}
          pageTitle={'Test Scenario Create'}
          pageHeadingButtons={pageHeadingButtons}
        />
        {createTestScenarioMutation.isError && (
          <ErrorMessage>
            {createTestScenarioMutation.error.message}
          </ErrorMessage>
        )}
        <InputSpace
          autoRegistersList={autoRegistersList}
          setAutoRegistersList={setAutoRegistersList}
        />
        <DndSpace />
      </form>
    </FormProvider>
  );
};

export default CreateTestScenario;
