import React, { useEffect, useState } from 'react';
import {
  Button,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
  ToggledSwitch,
} from '@autosquare/common';
import {
  AutoRegisterType,
  UpdateAdminProjectDetailData,
} from '@customTypes/dashboard/admin/type';
import { useFormContext } from 'react-hook-form';
import AutoRegisterModal from '@components/AutoRegisterCreateEditModal/AutoRegisterModal';
import AutoRegisterEditModal from '@components/AutoRegisterCreateEditModal/AutoRegisterEditModal';
import EditAutoRegisterAddPlatform from './ProjectEditAutoRegister/EditAutoRegisterAddPlatform';

type Props = {
  autoRegisterData: AutoRegisterType[];
  isToggleOn: boolean;
  setIsToggleOn: React.Dispatch<React.SetStateAction<boolean>>;
};
const ProjectEditAutoRegister = ({
  autoRegisterData,
  isToggleOn,
  setIsToggleOn,
}: Props) => {
  const {
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<UpdateAdminProjectDetailData>();
  const savedToggleStatus = autoRegisterData?.[0]?.activate;

  const [isAddClicked, setIsAddClicked] = useState(false);
  const [autoRegisterDataList, setAutoRegisterDataList] =
    useState<AutoRegisterType[]>(autoRegisterData);
  const [openCaution, setOpenCaution] = useState(false);
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [index, setIndex] = useState<number>(null);
  const [showOffToggleCaution, setShowOffToggleCaution] = useState(false);

  const selectedAutoRegisterData = autoRegisterDataList.find(
    (_, idx) => idx === index,
  );
  const showOffToggleCautionOption =
    !isToggleOn &&
    savedToggleStatus &&
    autoRegisterData.length > 0 &&
    autoRegisterDataList.length > 0;

  const deleteButtonHandler = () => {
    const removedList = autoRegisterDataList.filter((_, idx) => idx !== index);
    setValue('registers', removedList);
    setAutoRegisterDataList(removedList);
    setOpenCaution(false);
  };

  const cancelButtonHandler = () => {
    setShowOffToggleCaution(false);
    setIsToggleOn(true);
  };

  const okButtonHandler = () => {
    const newData = autoRegisterDataList.map((item) => {
      return { ...item, activate: false };
    });
    setValue('registers', newData);
    setShowOffToggleCaution(false);
  };

  useEffect(() => {
    if (showOffToggleCautionOption) {
      setShowOffToggleCaution(true);
    }
    if (
      !isToggleOn &&
      autoRegisterData.length === 0 &&
      autoRegisterDataList.length > 0
    ) {
      setValue('registers', []);
      setAutoRegisterDataList([]);
    }
    if (!isToggleOn) {
      clearErrors('registers');
    }
  }, [isToggleOn, setValue, setShowOffToggleCaution, clearErrors]);

  useEffect(() => {
    if (errors.registers && autoRegisterDataList.length > 0) {
      clearErrors('registers');
    }
  }, [clearErrors, autoRegisterDataList, errors.registers]);

  return (
    <ErrorBoundaryWrapper>
      <div className="flex items-center justify-between gap-x-3">
        <div className="flex gap-x-3">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            AutoRegister
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            프로젝트에서 테스트 결과를 자동 등록할 플랫폼을 설정해 주세요.
          </p>
        </div>
        <div className="flex justify-end">
          <ToggledSwitch isEnabled={isToggleOn} setIsEnabled={setIsToggleOn} />
        </div>
      </div>
      {isToggleOn && (
        <EditAutoRegisterAddPlatform
          autoRegisterDataList={autoRegisterDataList}
          setIndex={setIndex}
          isEditModalOpened={isEditModalOpened}
          setIsEditModalOpened={setIsEditModalOpened}
          setOpenCaution={setOpenCaution}
          isAddClicked={isAddClicked}
          setIsAddClicked={setIsAddClicked}
        />
      )}
      {isAddClicked && (
        <AutoRegisterModal
          isAddClicked={isAddClicked}
          setIsAddClicked={setIsAddClicked}
          autoRegisterDataList={autoRegisterDataList}
          setAutoRegisterDataList={setAutoRegisterDataList}
        />
      )}
      <DoubleCheckDialogHeadlessUI
        isOpened={openCaution}
        setIsOpened={setOpenCaution}
        type={'caution'}
        title={'Delete Platform'}
        subTitle={
          '삭제하시면 해당 플랫폼에 테스트 결과 자동 등록이 불가능 합니다.'
        }
        buttonChildren={
          <>
            <Button
              type="button"
              variant={'caution'}
              onClick={deleteButtonHandler}
            >
              Delete
            </Button>
            <Button
              type="button"
              variant={'secondary'}
              onClick={() => setOpenCaution(false)}
            >
              Cancel
            </Button>
          </>
        }
      />
      {isEditModalOpened && (
        <AutoRegisterEditModal
          isEditModalOpened={isEditModalOpened}
          setIsEditModalOpened={setIsEditModalOpened}
          autoRegisterData={selectedAutoRegisterData}
          autoRegisterDataList={autoRegisterDataList}
          setAutoRegisterDataList={setAutoRegisterDataList}
          targetIndex={index}
        />
      )}
      <DoubleCheckDialogHeadlessUI
        isOpened={showOffToggleCaution}
        setIsOpened={setShowOffToggleCaution}
        type={'caution'}
        title={'Disable AutoRegister'}
        subTitle={`자동 등록을 비활성화 상태로 전환하면 해당 프로젝트에 생성되어 있는 \n테스트 결과 자동 등록 기능 옵션이 모두 삭제됩니다.\n
(옵션 설정을 위해 입력한 정보는 유지됩니다.)`}
        buttonChildren={
          <>
            <Button type="button" variant={'caution'} onClick={okButtonHandler}>
              Ok
            </Button>
            <Button
              type="button"
              variant={'secondary'}
              onClick={cancelButtonHandler}
            >
              Cancel
            </Button>
          </>
        }
        onClose={() => setShowOffToggleCaution(true)}
      />
    </ErrorBoundaryWrapper>
  );
};

export default ProjectEditAutoRegister;
