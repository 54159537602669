import {
  ErrorBoundaryWrapper,
  PageHeadingButtons,
  PagingHeadings,
} from '@autosquare/common';
import React from 'react';
import { useLocation } from 'react-router-dom';

const AdminUserListPageHeading = () => {
  const { pathname } = useLocation();

  const breadcrumbs = [
    { title: 'Administration', href: pathname },
    { title: 'Users', href: pathname },
    { title: 'List', href: pathname },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      title: 'Add',
      href: `add`,
      type: 'button',
      variant: 'primary',
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={'User List'}
        pageHeadingButtons={pageHeadingButtons}
      />
    </ErrorBoundaryWrapper>
  );
};

export default AdminUserListPageHeading;
