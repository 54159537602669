import { useMutation } from '@tanstack/react-query';

import { axiosInstance, axiosLocalInstance } from '..';

import { IDEAPI } from '@api/ideEndpoints';

import {
  DeviceInfo,
  DistributionTestExecutionTestInfo,
} from '@customTypes/ide/device/device';
import { remoteConfig } from '@utils/static/remoteConfig';

interface DefaultTestData {
  infos: DeviceInfo[];
  projectIdx: number;
  saveVideo?: boolean;
  saveScreenshot?: boolean;
  saveLog?: boolean;
}

interface TestStepData extends DefaultTestData {
  stepIdx: number;
}

interface TestCaseData extends DefaultTestData {
  caseIdx: number;
}

interface TestScenarioData extends DefaultTestData {
  scenarioIdx: number;
}

// POST
// http://localhost:8081/swagger-ui/index.html#/Test%20Executor/testExecute
// Mobile Test Step 실행
const executionMobileTestStep = async (
  cookie: string,
  data: TestStepData,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    IDEAPI.RUN_TEST_STEP,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useExecutionMobileTestStepMutation = (
  cookie: string,
  data: TestStepData,
) => {
  return useMutation<unknown, Error>({
    mutationFn: () => executionMobileTestStep(cookie, data),
  });
};

// Mobile Test Case 실행
const executeMobileTestCase = async (
  cookie: string,
  data: TestCaseData,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post(IDEAPI.RUN_TEST_CASE, data, {
    headers: { 'Token-Value': cookie },
  });
  return response.data;
};
export const useExecuteMobileTestCaseMutation = (
  cookie: string,
  data: TestCaseData,
) => {
  return useMutation<unknown, Error>({
    mutationFn: () => executeMobileTestCase(cookie, data),
  });
};

// Mobile Test Scenario 실행
const executeMobileTestScenario = async (
  cookie: string,
  data: TestScenarioData,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    IDEAPI.RUN_TEST_SCENARIO,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useExecuteMobileTestScenarioMutation = (
  cookie: string,
  data: TestScenarioData,
) => {
  return useMutation<unknown, Error>({
    mutationFn: () => executeMobileTestScenario(cookie, data),
  });
};

// Mobile Test 실행 중단
// http://localhost:8081/swagger-ui/index.html#/Test%20Executor/stopTest_1
const stopMobileTestExecution = async (cookie: string): Promise<unknown> => {
  const response = await axiosLocalInstance.post(
    IDEAPI.STOP_MOBILE_TEST_EXECUTION,
    undefined,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useStopMobileTestExecutionMutation = (cookie: string) => {
  return useMutation<unknown, Error>({
    mutationFn: () => stopMobileTestExecution(cookie),
  });
};

//원격 모바일 테스트 중단
//https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Execute/stopTest
const stopRemoteMobileTestExecution = async (): Promise<unknown> => {
  const response = await axiosInstance.post(
    `${IDEAPI.REMOTE}/connect/execute/stop`,
    undefined,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteStopMobileTestExecutionMutation = () =>
  useMutation<unknown, Error>({
    mutationFn: () => stopRemoteMobileTestExecution(),
  });

const distributionMobileTestExecution = async (
  cookie: string,
  data: DistributionTestExecutionTestInfo,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post(
    IDEAPI.DISTRIBUTION_TEST_EXECUTION,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useDistributionMobileTestExecutionMutation = (cookie: string) => {
  return useMutation<unknown, Error, DistributionTestExecutionTestInfo>({
    mutationFn: (data) => distributionMobileTestExecution(cookie, data),
  });
};

//원격 분산 테스트 실행
//https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Execute/testExecute_1
const remoteDistributionMobileTestExecution = async (
  data: DistributionTestExecutionTestInfo,
): Promise<unknown> => {
  const response = await axiosInstance.post(
    `${IDEAPI.REMOTE}/connect/execute/distribution`,
    data,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteDistributionMobileTestExecutionMutation = () => {
  return useMutation<unknown, Error, DistributionTestExecutionTestInfo>({
    mutationFn: (data) => remoteDistributionMobileTestExecution(data),
  });
};
