import React from 'react';
import AutoRegisterContextMenu from '@components/AutoRegisterCreateEditModal/AutoRegisterContextMenu';
import { AutoRegisterType } from '@customTypes/dashboard/admin/type';
import { Menu, MenuButton } from '@headlessui/react';
import { findAutoRegisterPlatformName } from '@utils/static/autoRegister/autoRegisterTypeList';
import { IoEllipsisVertical } from 'react-icons/io5';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Props = {
  autoDataRegisterList: AutoRegisterType[];
  setIndex?: React.Dispatch<React.SetStateAction<number>>;
  setIsEditModalOpened?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCaution?: React.Dispatch<React.SetStateAction<boolean>>;
  isEditable?: boolean;
};

const AutoRegisterList = ({
  autoDataRegisterList,
  setIndex,
  setIsEditModalOpened,
  setOpenCaution,
  isEditable = true,
}: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div className={'ml-10 flex items-center gap-x-4'}>
        {autoDataRegisterList.map((data, index) => (
          <div
            key={data.platform + index}
            className="flex items-center gap-x-3 rounded-lg bg-gray-100 px-3 py-2.5"
          >
            <div className="text-sm font-normal leading-normal text-gray-900">
              {findAutoRegisterPlatformName(data.platform)}
            </div>
            {isEditable && (
              <Menu as="div" className="relative">
                <MenuButton
                  className="-m-2.5 block p-2.5 text-gray-900 hover:text-gray-400"
                  onClick={() => setIndex(index)}
                >
                  <span className="sr-only">Open options</span>
                  <IoEllipsisVertical size={17} color="#1f2937" />
                </MenuButton>
                <AutoRegisterContextMenu
                  setIsEditModalOpened={setIsEditModalOpened}
                  setOpenCaution={setOpenCaution}
                />
              </Menu>
            )}
          </div>
        ))}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default AutoRegisterList;
