import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  CreateTestStepData,
  MaskingOptionsType,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React from 'react';
import { useFormContext } from 'react-hook-form';
import MaskingOptionsDirectChoice from './MaskingOptions/MaskingOptionsDirectChoice';
import MaskingOptionsFullScreen from './MaskingOptions/MaskingOptionsFullScreen';
import MaskingOptionsHeader from './MaskingOptions/MaskingOptionsHeader';
import MaskingOptionsRadio from './MaskingOptions/MaskingOptionsRadio';
import MaskingOptionsSelectArea from './MaskingOptions/MaskingOptionsSelectArea';

const MaskingOptions = () => {
  const { watch } = useFormContext<CreateTestStepData>();

  const maskingOptionsEnabled =
    watch('optionsDto.maskingOptions.enabled') === 'true';

  const maskingOptionsType = watch('optionsDto.maskingOptions.type');

  return (
    <ErrorBoundaryWrapper>
      <MaskingOptionsHeader />
      {maskingOptionsEnabled && (
        <ErrorBoundaryWrapper>
          <MaskingOptionsRadio />
          {maskingOptionsType === MaskingOptionsType.Element ? (
            <MaskingOptionsDirectChoice />
          ) : maskingOptionsType === MaskingOptionsType.Coordinate ? (
            <MaskingOptionsSelectArea />
          ) : (
            <MaskingOptionsFullScreen />
          )}
        </ErrorBoundaryWrapper>
      )}
    </ErrorBoundaryWrapper>
  );
};

export default MaskingOptions;
