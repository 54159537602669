import React from 'react';

import DraggableElement from './DraggableElement';

const DndSpace = () => {
  return (
    <div className="flex w-full text-sm">
      <DraggableElement />
    </div>
  );
};

export default DndSpace;
