import { Button, ErrorBoundaryWrapper } from '@autosquare/common';
import {
  CommonDialog,
  DialogButton,
  DialogButtonPanels,
} from '@components/Dialog';
import {
  UpdateAdminSetting,
  UpdateAdminSettingSchema,
} from '@customTypes/index';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useAdminSettingsQuery,
  useUpdateAdminKeySettingMutation,
} from '@lib/api/dashboard/queryHooks';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

const AdminDataStorageSettingDataForm = () => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const { data: dataStorage } = useAdminSettingsQuery(
    'test_results_data_period',
  );

  const { handleSubmit, register } = useForm<UpdateAdminSetting>({
    defaultValues: {
      value: dataStorage.test_results_data_period,
    },
    resolver: zodResolver(UpdateAdminSettingSchema),
  });

  const updateAdminKeySettingMutation = useUpdateAdminKeySettingMutation(
    'test_results_data_period',
  );

  const onSubmit: SubmitHandler<UpdateAdminSetting> = (data) => {
    updateAdminKeySettingMutation.mutate(data, {
      onSuccess: () => {
        setIsSuccessModalOpen(true);
      },
      onError: () => {
        setIsErrorModalOpen(true);
      },
    });
  };

  const closeCommonDialog = () => {
    if (updateAdminKeySettingMutation.isError) {
      setIsErrorModalOpen(false);
    } else {
      setIsSuccessModalOpen(false);
    }
  };

  const checkboxList = [
    { value: '0', title: '전체 기간' },
    { value: '30', title: '30일' },
    { value: '90', title: '90일' },
    { value: '180', title: '180일' },
    { value: '365', title: '365일' },
  ];

  return (
    <ErrorBoundaryWrapper>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2 pt-4">
        <p className="block text-sm font-medium leading-6 text-gray-900">
          테스트 결과 데이터 보관 기간 설정 (일)
        </p>
        <div className="space-x-6">
          {checkboxList.map((checkbox) => (
            <label
              key={checkbox.title}
              className="cursor-pointer text-sm font-medium leading-normal text-gray-900"
            >
              <input
                type="radio"
                className="input-radio-congress-blue mr-3"
                value={checkbox.value}
                {...register('value')}
              />
              {checkbox.title}
            </label>
          ))}
        </div>
        <div className="flex items-center gap-4 border-b border-solid border-gray-200 pb-6 pt-3">
          <ExclamationTriangleIcon className="size-6 text-red-500" />
          <p>
            오늘을 기준으로 설정한 기간 이전의 테스트 결과 데이터가 삭제됩니다.
          </p>
        </div>
        <div className="flex items-center justify-end gap-x-6 py-4">
          <Button
            type="submit"
            variant="primary"
            disabled={updateAdminKeySettingMutation.isLoading}
          >
            Save
          </Button>
        </div>
      </form>

      <CommonDialog
        isOpen={isSuccessModalOpen}
        setIsOpen={setIsSuccessModalOpen}
        iconType={'checked'}
        title={'Success'}
        subTitle={'테스트 결과 저장 기간 설정에 성공했습니다.'}
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant={'primary'}
            onClick={closeCommonDialog}
          >
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>

      <CommonDialog
        isOpen={isErrorModalOpen}
        setIsOpen={setIsErrorModalOpen}
        iconType={'caution'}
        title={'Error'}
        subTitle={
          updateAdminKeySettingMutation.isError &&
          updateAdminKeySettingMutation.error.message
        }
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant={'secondary'}
            onClick={closeCommonDialog}
          >
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default AdminDataStorageSettingDataForm;
