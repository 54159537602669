import {
  CreateAdminProjectData,
  UpdateAdminProjectDetailData,
} from '@customTypes/dashboard/admin/type';
import { Path } from 'react-hook-form';
import { AutoRegisterPlatformServername } from './autoRegisterTypeList';
import { regexLibrary } from '@autosquare/common';

export const dynamicDefaultInputList = (
  platform: AutoRegisterPlatformServername,
) => {
  const inputDefaultList = [
    {
      labelId: `${platform}-email`,
      label: 'Email*',
      id: `${platform}-email-description`,
      explanation: '연결할 계정을 입력해 주세요.',
      name: 'platformInput.email' as Path<
        CreateAdminProjectData | UpdateAdminProjectDetailData
      >,
      pattern: {
        value: regexLibrary.email,
        message: '유효하지 않은 이메일 주소입니다.',
      },
    },
    {
      labelId: `${platform}-token`,
      label: 'API Token*',
      id: `${platform}-token-description`,
      explanation: 'Atlassian에서 발급된 인증 토큰을 입력해 주세요.',
      name: 'platformInput.apiToken' as Path<
        CreateAdminProjectData | UpdateAdminProjectDetailData
      >,
    },
  ];

  const urlInput = {
    labelId: 'jira-url',
    label: 'Jira URL*',
    id: 'jira-url-description',
    explanation: '(ex. https://example.atlassian.net)',
    name: 'platformInput.requestUrl' as Path<
      CreateAdminProjectData | UpdateAdminProjectDetailData
    >,
    pattern: {
      value: regexLibrary.jiraUrl,
      message: 'URL 형식이 올바르지 않습니다.',
    },
  };

  const autoRegisterInputList =
    platform === AutoRegisterPlatformServername.Jira
      ? [urlInput, ...inputDefaultList]
      : inputDefaultList;

  return { autoRegisterInputList };
};
