import { API } from '@api/endpoints';
import {
  AddAppInfo,
  AppInfoIdxList,
  AppInfoList,
  DeleteTempFile,
  UploadAppFileInfo,
} from '@customTypes/dashboard/project/application/type';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { axiosInstance } from '@lib/api/dashboard';
import { applicationKeys } from '@queries/dashboard/project/projectApplication/projectApplicationKeys';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';

//GET
//프로젝트에 등록된 버전 별 앱 리스트 조회
//https://dev.t-square.co.kr/swagger-ui/index.html#/v1.2.0/getAppInfoList
const getProjectAppList = async (
  projectIdx: string,
): Promise<AppInfoList[]> => {
  const response = await axiosInstance.get(
    `${API.PROJECT_APPLICATION}?project=${projectIdx}`,
  );
  return response.data;
};

export const useGetProjectAppListQuery = (
  options?: UseQueryOptions<AppInfoList[], ServerResponseErrorDetails>,
) => {
  const { projectIdx } = useParams();
  return useQuery<AppInfoList[], ServerResponseErrorDetails>({
    queryKey: applicationKeys.appList(projectIdx),
    queryFn: () => getProjectAppList(projectIdx),
    ...options,
  });
};

//프로젝트에 등록된 버전 별 앱 상세조회
//https://dev.t-square.co.kr/swagger-ui/index.html#/v1.2.0/getAppInfoList_1
const getProjectAppDetail = async (
  idx: string,
  projectIdx: string,
): Promise<AppInfoList> => {
  const response = await axiosInstance.get(
    `${API.PROJECT_APPLICATION}/${idx}?project=${projectIdx}`,
  );
  return response.data;
};

export const useGetProjectAppDetail = () => {
  const { projectIdx } = useParams();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');
  return useQuery<AppInfoList, ServerResponseErrorDetails>({
    queryKey: applicationKeys.all,
    queryFn: () => getProjectAppDetail(idx, projectIdx),
  });
};

//DELETE
//프로젝트에 등록된 앱 정보 삭제
//https://dev.t-square.co.kr/swagger-ui/index.html#/v1.2.0/deleteAppInfo
const deleteProjectAppList = async (data: AppInfoIdxList): Promise<boolean> => {
  const response = await axiosInstance.delete(API.PROJECT_APPLICATION, {
    data,
  });
  return response.data;
};

export const useDeleteProjectAppListMutation = () => {
  const queryClient = useQueryClient();
  const { projectIdx } = useParams();
  return useMutation<boolean, ServerResponseErrorDetails, number[]>({
    mutationFn: (idxList) =>
      deleteProjectAppList({ projectIdx: projectIdx, idxList: idxList }),
    onSuccess: () => {
      queryClient.invalidateQueries(applicationKeys.all);
      queryClient.invalidateQueries(applicationKeys.appList(projectIdx));
    },
  });
};

//저장된 임시 앱 파일 삭제
//https://dev.t-square.co.kr/swagger-ui/index.html#/v1.2.0/deleteAppPackage
const deleteTempFileInfo = async (data: DeleteTempFile): Promise<boolean> => {
  const response = await axiosInstance.delete(
    `${API.PROJECT_APPLICATION}/file/temp/delete`,
    { data },
  );
  return response.data;
};

export const useDeleteTempFileInfoMutation = () => {
  const queryClient = useQueryClient();
  const { projectIdx } = useParams();
  return useMutation<boolean, ServerResponseErrorDetails, DeleteTempFile>({
    mutationFn: (data) => deleteTempFileInfo(data),
    onSuccess: () =>
      queryClient.invalidateQueries(applicationKeys.addApplication(projectIdx)),
  });
};

//PUT
//프로젝트에 등록된 앱 정보 수정
//https://dev.t-square.co.kr/swagger-ui/index.html#/v1.2.0/updateAppInfo
const updateProjectAppListDetail = async (
  data: AppInfoList,
): Promise<AppInfoList> => {
  const response = await axiosInstance.put(API.PROJECT_APPLICATION, data);
  return response.data;
};

export const useUpdateProjectAppListDetailMutation = () => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');
  return useMutation<AppInfoList, ServerResponseErrorDetails, AppInfoList>({
    mutationFn: (data) => updateProjectAppListDetail(data),
    onSuccess: () => {
      queryClient.invalidateQueries(applicationKeys.applicationDetail(idx));
      queryClient.invalidateQueries(applicationKeys.appLists());
    },
  });
};

//POST
//앱 설치 파일 업로드
//https://dev.t-square.co.kr/swagger-ui/index.html#/v1.2.0/uploadAppPackage
const uploadFileAndGetInformation = async (
  projectIdx: string,
  file: File,
): Promise<UploadAppFileInfo> => {
  const response = await axiosInstance.post(
    `${API.PROJECT_APPLICATION}/file/upload/${projectIdx}`,
    { file: file },
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );

  return response.data;
};

export const useUploadFileAndGetInfoMutation = () => {
  const { projectIdx } = useParams();
  const queryClient = useQueryClient();
  return useMutation<UploadAppFileInfo, ServerResponseErrorDetails, File>({
    mutationFn: (file) => uploadFileAndGetInformation(projectIdx, file),
    onSuccess: () =>
      queryClient.invalidateQueries(applicationKeys.addApplication(projectIdx)),
  });
};

//프로젝트 내 원격 설치용 앱 등록 정보 저장
//https://dev.t-square.co.kr/swagger-ui/index.html#/v1.2.0/saveInstallAppInfo
const addAppInfo = async (data: AddAppInfo): Promise<AddAppInfo> => {
  const response = await axiosInstance.post(API.PROJECT_APPLICATION, data);
  return response.data;
};

export const useAddAppInfoMutation = () => {
  const { projectIdx } = useParams();
  const queryClient = useQueryClient();
  return useMutation<AddAppInfo, ServerResponseErrorDetails, AddAppInfo>({
    mutationFn: (data) => addAppInfo(data),
    onSuccess: () =>
      queryClient.invalidateQueries(applicationKeys.appList(projectIdx)),
  });
};
