import {
  ErrorBoundaryWrapper,
  useCheckboxWithDnd,
  useInput,
} from '@autosquare/common';

import React, { useEffect, useState } from 'react';

import { useWebTestCaseListQuery } from '@lib/api/ide/web/webTestCase';
import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import ListItemCard from './ListItemCard';
import { TestCaseDetailPickTitleAndIdx } from '@customTypes/testCase/type';
import { useFormContext } from 'react-hook-form';
import { CreateTestScenarioData } from '@customTypes/testScenario/type';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import SelectionHeaderWithCheckbox from '@components/shared/SelectionHeaderWithCheckbox';
import ScenarioTestCaseListBox from './ScenarioTestCaseListBox';
import TransferItemButton from '@components/shared/TransferItemButton';
import ScenarioCardListSkeleton from '@components/Skeleton/ScenarioCardListSkeleton';

const DraggableElement = () => {
  const {
    formState: { errors },
  } = useFormContext<CreateTestScenarioData>();

  const [copyTestCases, setCopyTestCases] = useState<
    TestCaseDetailPickTitleAndIdx[]
  >([]);

  const {
    data: allTestCases,
    isError,
    error,
    isLoading,
    isFetching,
  } = isWordInLocationPathname('mobile')
    ? useMobileTestCaseListQuery()
    : useWebTestCaseListQuery();

  const [testCases, setTestCases] = useState<
    TestCaseDetailPickTitleAndIdx[] | null
  >([]);

  useEffect(() => {
    setTestCases(
      allTestCases?.map((testCase, index) => ({
        ...testCase,
        dndIndex: index + 1,
      })),
    );
  }, [allTestCases]);

  const [searchWord, onChangeSearchWord, setSearchWord] = useInput('');

  const searchResultArray = testCases?.filter((testCase) =>
    testCase.title.toLowerCase().includes(searchWord.toLowerCase()),
  );
  const { selectAllArray, selectArray, selectedArray, resetSelectedArray } =
    useCheckboxWithDnd(testCases, 'idx', 'dndIndex');

  const copyAndDropList = [
    {
      title: 'All Test Case List',
      list:
        testCases?.length === 0 ? (
          <div className="flex items-center justify-center p-3">
            추가 가능한 테스트 케이스가 존재하지 않습니다.
          </div>
        ) : (
          <div className="h-[calc(100vh-21rem)]">
            <SelectionHeaderWithCheckbox
              selectAllArray={selectAllArray}
              selectedArray={selectedArray}
              total={testCases?.length}
            />
            <div className="gray-scrollbar h-[calc(100vh-28rem)] overflow-y-scroll">
              {searchResultArray?.map((testCase) => (
                <ListItemCard
                  key={testCase.idx}
                  testCase={testCase}
                  selectArray={selectArray}
                  selectedArray={selectedArray}
                />
              ))}
            </div>
          </div>
        ),
    },
    {
      title: 'add button',
      list: (
        <TransferItemButton
          items={testCases}
          copyItems={copyTestCases}
          setCopyItems={setCopyTestCases}
          selectedArray={selectedArray}
          resetSelectedArray={resetSelectedArray}
        />
      ),
    },
    {
      title: 'Scenario Test Case List',
      list: (
        <ScenarioTestCaseListBox
          total={copyTestCases?.length}
          copyTestCases={copyTestCases}
          setCopyTestCases={setCopyTestCases}
        />
      ),
    },
  ];

  if (isLoading || isFetching) {
    return <ScenarioCardListSkeleton />;
  }
  if (isError) {
    return (
      <div className="text-error-message my-4 w-full whitespace-pre-wrap text-center text-sm">
        {error.message}
      </div>
    );
  }
  return (
    <ErrorBoundaryWrapper>
      <div className="flex w-full items-center gap-4">
        {copyAndDropList?.map((item, index) => (
          <div className={clsx(index !== 1 && 'w-full')} key={item.title}>
            <div className="mb-2 flex justify-between">
              <div className="flex gap-x-4">
                {index !== 1 && (
                  <p className="font-medium leading-6">{item.title}</p>
                )}
                {index === 0 && (
                  <ErrorMessage
                    name="testCaseIdxs"
                    errors={errors}
                    render={({ message }) => (
                      <p className="error-message">{message}</p>
                    )}
                  />
                )}
              </div>
            </div>
            <div
              className={clsx(
                index !== 1 &&
                  'h-[calc(100vh-21rem)] rounded-md border border-solid',
              )}
            >
              {index === 0 && (
                <div className="relative w-full px-2 pb-1.5 pt-2">
                  <MagnifyingGlassIcon className="absolute left-4 top-4 size-4" />
                  <input
                    className="input-sm px-8"
                    value={searchWord}
                    onChange={onChangeSearchWord}
                  />
                  <XMarkIcon
                    className="absolute right-4 top-4 size-4 cursor-pointer"
                    onClick={() => setSearchWord('')}
                  />
                </div>
              )}
              {item.list}
            </div>
          </div>
        ))}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default DraggableElement;
