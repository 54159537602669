import { ErrorBoundaryWrapper, PagingHeadings } from '@autosquare/common';
import React from 'react';
import { useLocation } from 'react-router-dom';
import AdminDeviceInformationForm from './components/AdminDeviceInformationForm';

const AdminAddDevice = () => {
  const { pathname } = useLocation();

  const adminDeviceListPage = pathname.split('/').slice(0, -1).join('/');

  const breadcrumbs = [
    { title: 'Administration', href: adminDeviceListPage },
    { title: 'Devices', href: adminDeviceListPage },
    { title: 'Add', href: pathname },
  ];
  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings breadcrumbs={breadcrumbs} pageTitle={'Add Device'} />
      <AdminDeviceInformationForm adminDeviceListPage={adminDeviceListPage} />
    </ErrorBoundaryWrapper>
  );
};

export default AdminAddDevice;
