import {
  MobileSpeedTestResultDetailData,
  MobileSpeedTestResultData,
  MobileSpeedTestResultTableDetailData,
} from '@customTypes/ide/speedTest/Mobile/testResult/type';
import { axiosInstance, axiosRawResponseInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { speedMobileTestResultKeys } from '@queries/ide/speedMobile/speedMobileTestResultKeys';
import { DeleteIndexList } from '@customTypes/index';

//GET
const speedMobileTestResult = async (
  projectIdx: string,
  page: number,
  size: number,
): Promise<MobileSpeedTestResultData> => {
  const response = await axiosInstance.get<MobileSpeedTestResultData>(
    IDEAPI.getIdeSpeedMobileTestResult(projectIdx, page, size),
  );
  return response.data;
};

export const useGetIdeSpeedMobileTestResult = (page: number, size: number) => {
  const { projectIdx } = useParams();
  return useQuery<MobileSpeedTestResultData, Error>({
    queryKey: speedMobileTestResultKeys.list(projectIdx + page + size),
    queryFn: () => speedMobileTestResult(projectIdx, page, size),
  });
};

const speedMobileTestResultDetail = async (
  resultIdx: string,
  projectIdx: string,
  groupIdx: string,
  start: string,
  end: string,
): Promise<MobileSpeedTestResultDetailData> => {
  const response = await axiosInstance.get<MobileSpeedTestResultDetailData>(
    `${IDEAPI.DETAIL_SPEED_MOBILE_TEST_RESULT}/${resultIdx}?project=${projectIdx}&group=${groupIdx}&start=${start}&end=${end}`,
  );
  return response.data;
};

export const useSpeedMobileTestResultDetailQuery = () => {
  const [searchParams] = useSearchParams();
  const { projectIdx } = useParams();
  const resultIdx = searchParams.get('result');
  const groupIdx = searchParams.get('group');
  const start = searchParams.get('start');
  const end = searchParams.get('end');
  return useQuery<MobileSpeedTestResultDetailData, Error>({
    queryKey: speedMobileTestResultKeys.detail(resultIdx),
    queryFn: () =>
      speedMobileTestResultDetail(resultIdx, projectIdx, groupIdx, start, end),
  });
};

const speedMobileTestResultTableDetail = async (detailIdx: string) => {
  const response =
    await axiosInstance.get<MobileSpeedTestResultTableDetailData>(
      `${IDEAPI.DETAIL_SPEED_MOBILE_TEST_RESULT_BY_IDX}${detailIdx}`,
    );
  return response.data;
};

export const useIdeSpeedMobileTestResultTableDetailQuery = (
  detailIdx: string,
  options?: UseQueryOptions<MobileSpeedTestResultTableDetailData>,
) => {
  return useQuery<MobileSpeedTestResultTableDetailData, Error>({
    queryFn: () => speedMobileTestResultTableDetail(detailIdx),
    queryKey: speedMobileTestResultKeys.detailInfo(detailIdx),
    ...options,
  });
};

const searchSpeedTestMobileResult = async (
  search: string,
): Promise<MobileSpeedTestResultData> => {
  const response = await axiosInstance.get<MobileSpeedTestResultData>(
    `${IDEAPI.MOBILE_SPEED_TEST_RESULT}/search${search}`,
  );
  return response.data;
};

export const useSearchSpeedTestMobileResultQuery = () => {
  const { search } = useLocation();
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page');
  const size = searchParams.get('size');
  const projectIdx = searchParams.get('projectIdx');
  const order = searchParams.get('order');

  return useQuery<MobileSpeedTestResultData, Error>({
    queryKey: speedMobileTestResultKeys.search(search),
    queryFn: () => searchSpeedTestMobileResult(search),
    enabled:
      page !== null && size !== null && projectIdx !== null && order !== null,
    keepPreviousData: true,
  });
};

//video
const speedMobileTestResultVideoData = async (
  path: string[],
): Promise<string[]> => {
  const requests = path.map((path) =>
    axiosRawResponseInstance.get<MediaSource>(path, {
      responseType: 'blob',
    }),
  );

  const responses = await Promise.all(requests);
  const objectUrls = responses.map((response) =>
    URL.createObjectURL(response.data),
  );

  return objectUrls;
};

export const useIdeSpeedMobileTestResultVideoData = (
  path: string[],
  chartIndex: number,
  options?: UseQueryOptions<string[], Error>,
) => {
  return useQuery<string[], Error>({
    queryKey: speedMobileTestResultKeys.detailVideo(path, chartIndex),
    queryFn: () => speedMobileTestResultVideoData(path),
    ...options,
  });
};

//log
const speedMobileTestResultLogData = async (path: string): Promise<string> => {
  const response = await axiosRawResponseInstance.get<MediaSource | Blob>(
    path,
    {
      responseType: 'blob',
    },
  );

  const objectUrl = URL.createObjectURL(response.data);
  return objectUrl;
};

export const useIdeSpeedMobileTestResultLogData = (
  path: string,
  chartIndex: number,
  options?: UseQueryOptions<string, Error>,
) => {
  return useQuery<string, Error>({
    queryKey: speedMobileTestResultKeys.detailLog(path, chartIndex),
    queryFn: () => speedMobileTestResultLogData(path),
    ...options,
  });
};

// POST
const mobileSpeedTestResultExport = async (
  result: string,
  data: FormData,
): Promise<{ blob: Blob; filename: string }> => {
  const response = await axiosRawResponseInstance.post<Blob>(
    `${IDEAPI.MOBILE_SPEED_TEST_RESULT}/${result}/download-excel`,
    data,
    { responseType: 'blob' },
  );

  const contentDisposition = response.headers['content-disposition'];
  const filenameMatch = contentDisposition.match(/filename="(.+)"/);

  return { blob: response.data, filename: filenameMatch[1] };
};
export const useMobileSpeedTestResultExportMutation = () => {
  const [searchParams] = useSearchParams();
  const result = searchParams.get('result');

  return useMutation<{ blob: Blob; filename: string }, Error, FormData>({
    mutationFn: (data) => mobileSpeedTestResultExport(result, data),
    onSuccess: ({ blob, filename }) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },
  });
};

// DELETE
const mobileSpeedTestResultDelete = async (
  data: DeleteIndexList,
): Promise<DeleteIndexList> => {
  const response = await axiosInstance.delete<DeleteIndexList>(
    `${IDEAPI.MOBILE_SPEED_TEST_RESULT}`,
    { data },
  );
  return response.data;
};
export const useMobileSpeedTestResultDeleteMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const backToListPage = localStorage.getItem('entryPoint');

  return useMutation<DeleteIndexList, Error, DeleteIndexList>({
    mutationFn: (data) => mobileSpeedTestResultDelete(data),
    onSuccess: () => {
      queryClient.invalidateQueries(speedMobileTestResultKeys.lists());
      queryClient.invalidateQueries(speedMobileTestResultKeys.searches());
      navigate(backToListPage);
    },
  });
};
