import React, { useState } from 'react';

import { ErrorBoundaryWrapper } from '@autosquare/common';
import AddSystemUsersInput from './AddSystemUsersForm/AddSystemUsersInput';
import { AdminUserEmail } from '@customTypes/index';
import AddSystemUsersTable from './AddSystemUsersForm/AddSystemUsersTable';

const AddSystemUsersForm = () => {
  const [userEmailList, setUserEmailList] = useState<AdminUserEmail[]>([]);
  const [isSendInvite, setIsSendInvite] = useState<boolean>(false);

  return (
    <ErrorBoundaryWrapper>
      <AddSystemUsersInput
        userEmailList={userEmailList}
        setUserEmailList={setUserEmailList}
        isSendInvite={isSendInvite}
      />
      <AddSystemUsersTable
        userEmailList={userEmailList}
        setUserEmailList={setUserEmailList}
        isSendInvite={isSendInvite}
        setIsSendInvite={setIsSendInvite}
      />
    </ErrorBoundaryWrapper>
  );
};

export default AddSystemUsersForm;
