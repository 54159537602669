import {
  ErrorBoundaryWrapper,
  useGlobalLoadingStore,
} from '@autosquare/common';
import React, { useEffect, useState } from 'react';
import DetailFeatures from '../../shared/DetailFeatures';
import DetailMessenger from '../../shared/DetailMessenger';
import DetailAutoRegister from '../../shared/DetailAutoRegister';
import DetailProjectInformationInput from './ProjectSettingsDetailForm/DetailProjectInformationInput';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  UpdateProjectDetailSetting,
  UpdateProjectDetailSettingSchema,
} from '@customTypes/index';
import {
  useProjectSettingDetailQuery,
  useUpdateProjectSettingMutation,
} from '@lib/api/dashboard/queryHooks';
import { zodResolver } from '@hookform/resolvers/zod';
import ProjectSettingsDetailModal from './ProjectSettingsDetailForm/ProjectSettingsDetailModal';

const ProjectSettingsDetailForm = () => {
  const { data: projectSettingDetail } = useProjectSettingDetailQuery();

  const setIsLoading = useGlobalLoadingStore((state) => state.setIsLoading);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const methods = useForm<UpdateProjectDetailSetting>({
    resolver: zodResolver(UpdateProjectDetailSettingSchema),
    defaultValues: {
      name: projectSettingDetail.name,
      serviceName: projectSettingDetail.serviceName,
      description: projectSettingDetail.description,
      features: projectSettingDetail.features,
      messengers: projectSettingDetail.messengers,
      registers: projectSettingDetail.registers,
    },
  });

  const updateProjectSettingMutation = useUpdateProjectSettingMutation();

  const onSubmit: SubmitHandler<UpdateProjectDetailSetting> = (data) => {
    updateProjectSettingMutation.mutate(data, {
      onSuccess: () => {
        setIsSuccessModalOpen(true);
      },
      onError: () => {
        setIsErrorModalOpen(true);
      },
    });
  };

  useEffect(() => {
    if (updateProjectSettingMutation.isLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [updateProjectSettingMutation.isLoading]);

  return (
    <ErrorBoundaryWrapper>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          id="project-settings-detail-form"
        >
          <DetailProjectInformationInput />
          <DetailFeatures />
          <DetailMessenger />
          <DetailAutoRegister />
        </form>
      </FormProvider>
      <ProjectSettingsDetailModal
        isSuccessModalOpen={isSuccessModalOpen}
        setIsSuccessModalOpen={setIsSuccessModalOpen}
        isErrorModalOpen={isErrorModalOpen}
        setIsErrorModalOpen={setIsErrorModalOpen}
      />
    </ErrorBoundaryWrapper>
  );
};

export default ProjectSettingsDetailForm;
