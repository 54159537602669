import dayjs from 'dayjs';
import React from 'react';

type Props = {
  lastUpdated: string;
};

const AutomationSettingsUpdatedTime = ({ lastUpdated }: Props) => {
  const updatedAt = dayjs(lastUpdated).format('YYYY-MM-DD HH:mm:ss');

  return (
    <div className="flex items-center justify-end border-t border-gray-200 pt-4">
      <p className="text-gray-500">Updated at {updatedAt}</p>
    </div>
  );
};

export default AutomationSettingsUpdatedTime;
