import { ErrorMessage, TableNoList, TableSkeleton } from '@autosquare/common';
import {
  EnableInstallDevice,
  ServerResponseErrorDetails,
} from '@customTypes/index';
import { UseQueryResult } from '@tanstack/react-query';
import React from 'react';
import SelectDeviceTableBodyRow from './SelectDeviceTableBody/SelectDeviceTableBodyRow';

type Props = {
  headerLength: number;
  enabledInstallDeviceListQuery: UseQueryResult<
    EnableInstallDevice[],
    ServerResponseErrorDetails
  >;
  selectArray: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedArray: number[];
};

const SelectDeviceTableBody = ({
  headerLength,
  enabledInstallDeviceListQuery,
  selectArray,
  selectedArray,
}: Props) => {
  const enabledInstallDeviceList = enabledInstallDeviceListQuery.data;

  if (
    enabledInstallDeviceListQuery.fetchStatus === 'fetching' &&
    enabledInstallDeviceListQuery.isLoading
  ) {
    return (
      <TableNoList colSpan={headerLength}>
        <TableSkeleton />
      </TableNoList>
    );
  }

  if (enabledInstallDeviceListQuery.isError) {
    return (
      <TableNoList colSpan={headerLength}>
        <ErrorMessage>
          {enabledInstallDeviceListQuery.error.message}
        </ErrorMessage>
      </TableNoList>
    );
  }

  if (!enabledInstallDeviceList || enabledInstallDeviceList.length === 0) {
    return (
      <TableNoList colSpan={headerLength}>
        디바이스가 존재하지 않습니다.
      </TableNoList>
    );
  }

  if (!!enabledInstallDeviceList && enabledInstallDeviceList.length > 0) {
    return enabledInstallDeviceList.map((device, idx) => (
      <SelectDeviceTableBodyRow
        key={device.idx}
        device={device}
        idx={idx}
        selectArray={selectArray}
        selectedArray={selectedArray}
      />
    ));
  }

  return null;
};

export default SelectDeviceTableBody;
