import React, { useState } from 'react';
import {
  Breadcrumbs,
  ErrorBoundaryWrapper,
  PagingHeadings,
} from '@autosquare/common';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useParentPath } from '@hooks/useParentPath';
import {
  useDeleteProjectAppListMutation,
  useGetProjectAppDetail,
  useUpdateProjectAppListDetailMutation,
} from '@lib/api/dashboard/project/project-settings/application/application';
import ProjectApplicationSkeleton from './components/ProjectApplicationSkeleton';
import AutomationSettingsUpdatedTime from '@components/TestAutomationSettings/common/AutomationSettingsUpdatedTime';

import ProjectApplicationDetailView from './components/ProjectApplicationDetailView';
import ProjectApplicationDetailForm from './components/ProjectApplicationDetailForm';
import ProjectApplicationButtonSection from './components/ProjectApplicationButtonSection';
import DeleteButtonCommonDialog from '@components/shared/Dialog/DeleteButtonCommonDialog';
import SubTitleHeaderSection from '@components/shared/SubTitleHeader/SubTitleHeaderSection';
import { useAuthUserInfo } from '@hooks/index';

const ProjectApplicationDetail = () => {
  const [isOpenedCautionDialog, setIsOpenedCautionDialog] = useState(false);
  const { pathname } = useLocation();
  const parentPage = useParentPath();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const idx = Number(searchParams.get('idx'));

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'Project Settings', href: pathname },
    { title: 'Application', href: parentPage },
    { title: 'Detail', href: pathname },
  ];

  const {
    data: projectAppDetailData,
    isLoading,
    isFetching,
    refetch,
  } = useGetProjectAppDetail();

  const onSuccessHandler = () => {
    refetch();
    navigate(parentPage);
  };

  const onDeleteButtonHandler = () => {
    setIsOpenedCautionDialog(true);
  };

  const onDialogDeleteButtonHandler = () => {
    deleteProjectAppListMutation.mutate([idx]);
    navigate(parentPage);
  };

  const detailDataList = [
    { title: 'App Name', data: projectAppDetailData?.appName },
    { title: 'Package Name', data: projectAppDetailData?.packageName },
    { title: 'App Version', data: projectAppDetailData?.appVersion },
    { title: 'Build Version', data: projectAppDetailData?.buildVersion },
    { title: 'OS', data: projectAppDetailData?.osType },
    {
      title: 'Description',
      data: !projectAppDetailData?.description
        ? '-'
        : projectAppDetailData?.description,
      editable: true,
    },
  ];

  const { isAdmin } = useAuthUserInfo();
  const updateProjectAppListDetailMutation =
    useUpdateProjectAppListDetailMutation();
  const deleteProjectAppListMutation = useDeleteProjectAppListMutation();
  const isButtonDisabled =
    updateProjectAppListDetailMutation.isLoading || isLoading || isFetching;

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadings
        breadcrumbs={breadcrumbs}
        pageTitle={'Application Detail'}
      />
      <SubTitleHeaderSection
        header="Information"
        description="등록한 애플리케이션의 정보를 수정할 수 있습니다."
      />
      {isLoading || isFetching ? (
        <ProjectApplicationSkeleton />
      ) : isAdmin ? (
        <ProjectApplicationDetailForm
          detailData={projectAppDetailData}
          onSuccessHandler={onSuccessHandler}
          list={detailDataList}
          updateMutation={updateProjectAppListDetailMutation}
        />
      ) : (
        <ProjectApplicationDetailView list={detailDataList} />
      )}
      <AutomationSettingsUpdatedTime
        lastUpdated={projectAppDetailData?.updatedAt}
      />
      <ProjectApplicationButtonSection
        onDeleteButtonHandler={onDeleteButtonHandler}
        isAdmin={isAdmin}
        isButtonDisabled={isButtonDisabled}
      />
      <DeleteButtonCommonDialog
        title="Delete Application"
        subTitle="해당 애플리케이션을 삭제하시겠습니까?"
        isOpen={isOpenedCautionDialog}
        setIsOpen={setIsOpenedCautionDialog}
        onDeleteHandler={onDialogDeleteButtonHandler}
      />
    </ErrorBoundaryWrapper>
  );
};

export default ProjectApplicationDetail;
