import {
  ArrowSpinner,
  Button,
  DoubleCheckDialogHeadlessUI,
} from '@autosquare/common';
import DescriptionList from '@components/shared/MobileWebTestStep/DescriptionList';
import TestStepDetailTableDataCell from '@components/shared/MobileWebTestStep/TestStepDetailTableDataCell';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';
import RemoteTestExecutionAlarmModal from '@components/TestExecution/shared/RemoteTestExecutionAlarmModal';
import TestExecutionContainer from '@components/TestExecution/TestExecutionContainer';
import { WebSpeedTestStepData } from '@customTypes/ide/speedTest/Web/testStep/type';
import { useRemoteMobileTestExecutionMutation } from '@lib/api/ide/remote/remote';
import { useDeleteSpeedWebSepMutation } from '@lib/api/ide/speedWeb/speedWebTestStep';
import { findTextReplaceAddNameByServerName } from '@utils/static/mobileTestStep/textReplace/textReplaceAddList';
import { findTextReplaceFromNameByServerName } from '@utils/static/mobileTestStep/textReplace/textReplaceFromList';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

type Props = {
  startCreatingTestStep: () => void;
  startUpdateTestStep: () => void;
  speedWebStep: WebSpeedTestStepData;
};

const WebSpeedTestStepListTable = ({
  startCreatingTestStep,
  startUpdateTestStep,
  speedWebStep,
}: Props) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [isTestExecutionOpened, setIsTestExecutionOpened] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExecutionErrorModalOpen, setIsExecutionErrorModalOpen] =
    useState(false);

  const remoteMobileStepExecutionMutation =
    useRemoteMobileTestExecutionMutation('step');
  const deleteSpeedWebStepMutation = useDeleteSpeedWebSepMutation();
  const openDialog = () => {
    setIsDialogOpened(true);
  };

  const closeDialog = () => {
    setIsDialogOpened(false);
  };
  const tableHeader = ['Key Name', 'From', 'Value', 'Date Format'];

  return (
    <>
      <section className="py-6">
        <div className="flex items-center justify-between border-b border-solid border-gray-200 pb-3.5">
          <h3 className="text-base font-semibold leading-normal text-gray-900">
            Test Step
          </h3>
          <div className="flex gap-4">
            {speedWebStep ? (
              <>
                <Button
                  type="button"
                  variant="primary"
                  onClick={() => setIsTestExecutionOpened(true)}
                >
                  Execute
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={startUpdateTestStep}
                >
                  Edit
                </Button>
                <Button type="button" variant="caution" onClick={openDialog}>
                  Delete
                </Button>
              </>
            ) : (
              <>
                <Button type="button" variant="secondary" disabled>
                  Execute
                </Button>
                <Button type="button" onClick={startCreatingTestStep}>
                  Create
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="space-y-2.5 py-2.5 text-sm font-normal leading-9 text-black">
          {speedWebStep ? (
            <>
              <DescriptionList title={'URL'} description={speedWebStep.url} />
              <DescriptionList
                title={'Description'}
                description={speedWebStep.description}
              />
              {speedWebStep.optionsDto.length > 0 && (
                <div className="py-4">
                  <table className="w-full text-sm font-normal leading-normal text-gray-900">
                    <thead>
                      <tr className="border-b border-solid border-gray-200">
                        {tableHeader.map((header) => (
                          <th key={header} className="text-start font-medium">
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {speedWebStep.optionsDto?.map((option) => (
                        <tr key={option.keyName}>
                          <TestStepDetailTableDataCell>
                            {option.keyName}
                          </TestStepDetailTableDataCell>
                          <TestStepDetailTableDataCell>
                            {findTextReplaceFromNameByServerName(option.from)}
                          </TestStepDetailTableDataCell>
                          <TestStepDetailTableDataCell>{`${findTextReplaceAddNameByServerName(option.addKey)}+${option.addValue}`}</TestStepDetailTableDataCell>
                          <TestStepDetailTableDataCell>
                            {option.dateFormat}
                          </TestStepDetailTableDataCell>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          ) : (
            <p className="text-center">생성된 테스트 스텝이 없습니다.</p>
          )}
        </div>
        <DoubleCheckDialogHeadlessUI
          isOpened={isDialogOpened}
          setIsOpened={setIsDialogOpened}
          type={'caution'}
          title={'Delete Test Step'}
          subTitle={'해당 테스트 스텝을 삭제하시겠습니까?'}
          buttonChildren={
            <>
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  deleteSpeedWebStepMutation.mutate(speedWebStep.idx)
                }
              >
                Delete
              </Button>
              <Button type="button" variant="secondary" onClick={closeDialog}>
                Cancel
              </Button>
            </>
          }
        />
        {isTestExecutionOpened && (
          <TestExecutionContainer
            setState={setIsTestExecutionOpened}
            idx={Number(idx)}
            testType={'case'}
            deviceType={'web'}
            testLocation={'speed-test'}
            setIsModalOpen={setIsModalOpen}
            remoteMobileExecutionMutation={remoteMobileStepExecutionMutation}
            setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
          />
        )}
        {remoteMobileStepExecutionMutation.isLoading && <ArrowSpinner />}
        <RemoteTestExecutionAlarmModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isExecutionErrorModalOpen={isExecutionErrorModalOpen}
          setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
        />
        <RemoteConnectionAlarmModal />
      </section>
    </>
  );
};

export default WebSpeedTestStepListTable;
