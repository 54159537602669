import {
  InstallAppRequest,
  InstallAppRequestSchema,
  InstallAppResponse,
} from '@customTypes/index';
import { zodResolver } from '@hookform/resolvers/zod';
import { useInstallAppInDeviceMutation } from '@lib/api/dashboard/queryHooks';
import React, { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import SelectApplication from './DeviceAppInstallForm/SelectApplication';
import SelectDevice from './DeviceAppInstallForm/SelectDevice';
import InstallButtonSection from './DeviceAppInstallForm/InstallButtonSection';
import InstallLoading from './DeviceAppInstallForm/InstallLoading';
import InstallResult from './DeviceAppInstallForm/InstallResult';
import { ErrorDialog } from '@components/Dialog';

const DeviceAppInstallForm = () => {
  const { projectIdx } = useParams();

  const [installAppResponse, setInstallAppResponse] = useState<
    InstallAppResponse[]
  >([]);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const methods = useForm<InstallAppRequest>({
    defaultValues: {
      projectIdx: projectIdx,
      deviceIdxList: [],
    },
    resolver: zodResolver(InstallAppRequestSchema),
  });

  const installAppInDeviceMutation = useInstallAppInDeviceMutation();

  const onSubmit: SubmitHandler<InstallAppRequest> = (data) => {
    installAppInDeviceMutation.mutate(data, {
      onSuccess: (response) => {
        setInstallAppResponse(response);
      },
      onError: () => {
        setIsErrorDialogOpen(true);
        methods.reset();
      },
    });
  };

  if (installAppInDeviceMutation.isLoading) {
    return <InstallLoading />;
  }

  if (installAppInDeviceMutation.isSuccess) {
    return <InstallResult installAppResponse={installAppResponse} />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <SelectApplication />
        <SelectDevice />
        <InstallButtonSection />
      </form>
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        setIsOpen={setIsErrorDialogOpen}
        subTitle={installAppInDeviceMutation.error?.message}
      />
    </FormProvider>
  );
};

export default DeviceAppInstallForm;
