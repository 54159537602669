import React from 'react';
import AddDeviceFormFields from './AddDeviceFormFields';
import { useFormContext } from 'react-hook-form';
import { DeviceApprovalRequest } from '@customTypes/index';

const AddDeviceApprovalRequest = () => {
  const { register } = useFormContext<DeviceApprovalRequest>();

  return (
    <AddDeviceFormFields
      label="Approval Request (디바이스 추가 요청 사유를 입력하실 수 있습니다.)"
      name="description"
    >
      <input className="input-base" {...register('description')} />
    </AddDeviceFormFields>
  );
};

export default AddDeviceApprovalRequest;
