import { ErrorBoundaryWrapper } from '@autosquare/common';
import React, { Fragment } from 'react';

type Props = {
  imageWidth: number;
  imageHeight: number;
  clickOptions: {
    labelTitle: string;
    value: number;
    max: number;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  }[];
};

const ClickCoordinateOption = ({
  imageWidth,
  imageHeight,
  clickOptions,
}: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div className="mb-2 grid w-full grid-cols-4 gap-4 text-center text-sm">
        <p>Width</p>
        <p>{imageWidth}</p>
        <p>Height</p>
        <p>{imageHeight}</p>
      </div>
      <div className="grid w-full grid-cols-4 items-center gap-4 text-center text-sm">
        {clickOptions?.map((inputOption, index) => (
          <Fragment key={index}>
            <label>{inputOption?.labelTitle}</label>
            <input
              type="number"
              min={0}
              max={inputOption?.max}
              className="w-full rounded-md border border-solid border-gray-300 px-3 py-1 text-center text-sm [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
              value={inputOption?.value || ''}
              onChange={inputOption?.onChange}
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            />
          </Fragment>
        ))}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default ClickCoordinateOption;
