import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  ErrorBoundaryWrapper,
  useCheckbox,
} from '@autosquare/common';
import { ErrorMessage } from '@hookform/error-message';
import { useImportTestStep } from '@hooks/testStep/useImportTestStep';
import { Controller, useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { findTitleFromIdx } from '@utils/static/findTitleFromIdx';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';
import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { useMobileTestStepByCaseQuery } from '@lib/api/ide/mobile/mobileTestStep';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import MobileImportTestStepTable from './MobileImportTestStepCommandList/MobileImportTestStepTable';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import {
  filterSelectedIdx,
  removeMobileUnusedFields,
} from '@utils/static/removeUnusedFields';
import { useSpeedMobileTestCaseListQuery } from '@lib/api/ide/speedMobile/speedMobileTestCase';
import { useGetSpeedMobileTestStep } from '@lib/api/ide/speedMobile/speedMobileTestStep';
import { ImportTestStepFilteredList } from '@customTypes/index';
import { ImportTestStepComboboxHeadlessInController } from '@components/index';

const MobileImportTestStepCommandList = () => {
  const [query, setQuery] = useState('');

  const {
    watch,
    control,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext<CreateTestStepData>();

  const selectedCaseIdx = watch('value');
  const stepIdxList = watch('optionsDto.stepIdxList');

  const isUiTestLocation = isWordInLocationPathname('ui-test');
  const isSpeedTestLocation = isWordInLocationPathname('speed-test');
  const mobileTestCaseListQuery = isUiTestLocation
    ? useMobileTestCaseListQuery({
        enabled: isUiTestLocation,
      })
    : useSpeedMobileTestCaseListQuery({
        enabled: isSpeedTestLocation,
      });
  const mobileTestCaseList = mobileTestCaseListQuery.data;

  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');
  const deviceInfo = watch('mobileOs');

  const selectedOsCount = isUiTestLocation
    ? deviceInfo === DeviceInfoOs.Aos
      ? 'aosStepCount'
      : 'iosStepCount'
    : deviceInfo === DeviceInfoOs.Aos
      ? 'aos'
      : 'ios';

  const filteredMobileTestCaseList = mobileTestCaseList
    ?.map((mobileCase) =>
      removeMobileUnusedFields(mobileCase, selectedOsCount, isUiTestLocation),
    )
    .filter((mobileCase) => filterSelectedIdx(mobileCase, idx));

  const filteredList =
    query === ''
      ? filteredMobileTestCaseList
      : filteredMobileTestCaseList?.filter((list) =>
          list.title.toLowerCase().includes(query.toLowerCase()),
        );

  const mobileTestStepListQuery = isUiTestLocation
    ? useMobileTestStepByCaseQuery(selectedCaseIdx, {
        enabled: isUiTestLocation && selectedCaseIdx !== undefined,
      })
    : useGetSpeedMobileTestStep(selectedCaseIdx, {
        enabled: isSpeedTestLocation && selectedCaseIdx !== undefined,
      });

  const mobileTestStep = mobileTestStepListQuery.data;
  const mobileTestStepByDevice = mobileTestStep?.[deviceInfo];
  const mobileTestStepListWithoutDisabled = mobileTestStepByDevice?.filter(
    (item) =>
      !(
        item.command.includes('import') ||
        (item.command === MobileCommandOptionServerName.IF &&
          item.value.includes('import'))
      ),
  );
  const { selectAllArray, selectArray, selectedArray, resetSelectedArray } =
    useCheckbox(mobileTestStepListWithoutDisabled, 'idx');
  const testStepHeader = [
    <Checkbox
      checked={selectedArray.length > 0}
      onChange={(e) => selectAllArray(e)}
      key="selected-checkbox"
    />,
    '#',
    'Command',
    'Description',
  ];

  const { uniqueCompareIdxArray } = useImportTestStep(
    mobileTestStepByDevice,
    selectedArray,
  );

  useEffect(() => {
    if (selectedCaseIdx && selectedArray.length === 0) {
      setError('optionsDto.stepIdxList', {
        type: 'manual',
        message: '테스트 스텝을 1개 이상 선택해 주세요.',
      });
    }
    if (query === '' || (selectedCaseIdx && selectedArray.length > 0)) {
      clearErrors('optionsDto.stepIdxList');
    }

    if (uniqueCompareIdxArray.length > 0) {
      const isReferencedStepIncluded = uniqueCompareIdxArray.every((item) =>
        selectedArray.includes(item),
      );
      if (!isReferencedStepIncluded) {
        setError('optionsDto.stepIdxList', {
          type: 'manual',
          message: '참조되는 Save Text 스텝을 같이 선택해 주세요.',
        });
      }
    }
  }, [selectedCaseIdx, selectedArray]);

  useEffect(() => {
    setValue('optionsDto.stepIdxList', selectedArray.join(','));
  }, [selectedArray]);

  useEffect(() => {
    return () => {
      clearErrors('optionsDto.stepIdxList');
      setValue('value', undefined);
    };
  }, [clearErrors, setValue]);

  useEffect(() => {
    if (selectedCaseIdx && stepIdxList === '') {
      setError('optionsDto.stepIdxList', {
        type: 'minLength',
        message: '테스트 스텝을 1개 이상 선택해 주세요.',
      });
    } else {
      clearErrors('optionsDto.stepIdxList');
    }
  }, [selectedCaseIdx, stepIdxList, clearErrors, setError]);

  return (
    <ErrorBoundaryWrapper>
      <Controller
        control={control}
        name="value"
        rules={{ required: '테스트 케이스를 선택해 주세요.' }}
        render={({ field }) => (
          <ImportTestStepComboboxHeadlessInController
            placeholder="가져올 스텝의 테스트 케이스를 선택해 주세요."
            value={field.value || ''}
            onChange={(value) => {
              field.onChange(value);
              resetSelectedArray();
            }}
            filteredList={filteredList}
            displayValue={(value: number) =>
              findTitleFromIdx(value, mobileTestCaseList) || query
            }
            isOptionDisabled={(value: ImportTestStepFilteredList) =>
              value.disabled
            }
            valueToSave={'idx'}
            valueToShow={'title'}
            setQuery={setQuery}
          />
        )}
      />
      <ErrorMessage
        name="value"
        errors={errors}
        render={({ message }) => (
          <p className="error-message -mt-2">{message}</p>
        )}
      />
      {selectedCaseIdx && (
        <section>
          <div className="border-b border-gray-300 px-1 py-1.5 text-sm font-medium text-gray-900">
            Select Test Step
          </div>
          <MobileImportTestStepTable
            query={mobileTestStepListQuery}
            headers={testStepHeader}
            rows={mobileTestStepByDevice}
            selectArray={selectArray}
            selectedArray={selectedArray}
          />
        </section>
      )}
      <ErrorMessage
        name="optionsDto.stepIdxList"
        errors={errors}
        render={({ message }) => (
          <p className="error-message -mt-2">{message}</p>
        )}
      />
    </ErrorBoundaryWrapper>
  );
};

export default MobileImportTestStepCommandList;
