import { ErrorBoundaryWrapper } from '@autosquare/common';
import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React from 'react';

interface Props {
  lists: MobileTestStepData[];
  isLoading: boolean;
}

const TestStepListHeader = ({ lists, isLoading }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div className="flex items-center justify-between">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Test Step List
        </h3>
        <div className="ml-4 mt-0">
          <div className="flex items-center justify-center gap-1 text-sm font-semibold">
            Total:{' '}
            {isLoading ? (
              <div className="h-4 w-6 animate-pulse rounded-md bg-gray-200 dark:bg-gray-700" />
            ) : (
              lists?.length
            )}
          </div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default TestStepListHeader;
