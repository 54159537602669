import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { ErrorBoundaryWrapper, TooltipTop } from '@autosquare/common';
import { EnvRate } from '@customTypes/ide/overview/type';
import OverviewNoData from '@components/shared/OverviewNoData';

type Props = {
  data: EnvRate;
  envType: 'mobile' | 'web';
};

const EnvironmentBar = ({ data, envType }: Props) => {
  const colorBar =
    envType === 'mobile'
      ? {
          AOS: 'bg-gray-100',
          iOS: 'bg-gray-300',
        }
      : {
          Safari: 'bg-gray-300',
          Chrome: 'bg-gray-400',
          Edge: 'bg-gray-100',
        };

  const testEnvironmentData = data?.devices?.map((item, index) => {
    return {
      device: item,
      percent: data?.percent[index],
      color: colorBar[item],
    };
  });

  const isDataAllNull = data?.percent?.every((item) => item === 0);

  const barRef = useRef<HTMLDivElement | []>([]);
  const textRef = useRef<HTMLDivElement | []>([]);
  const [isHiddenTextList, setIsHiddenTextList] = useState<boolean[]>([]);

  useEffect(() => {
    if (barRef.current && textRef.current) {
      const updatedHiddenText = testEnvironmentData?.map((_, idx) => {
        const barWidth = barRef.current[idx]?.offsetWidth;
        const textWidth = textRef.current[idx]?.offsetWidth;
        return textWidth > barWidth;
      });
      setIsHiddenTextList(updatedHiddenText);
    }
  }, [data]);
  return (
    <ErrorBoundaryWrapper>
      {isDataAllNull ? (
        <OverviewNoData />
      ) : (
        <div className="flex h-6">
          {testEnvironmentData
            ?.filter((data) => data.percent > 0)
            .map((environment, index, arr) => (
              <div
                key={environment.device}
                className={clsx(
                  'group relative flex cursor-default items-center justify-center',
                  arr.length === 1
                    ? 'rounded-lg'
                    : index === 0
                      ? 'rounded-l-lg'
                      : index === arr.length - 1 && 'rounded-r-lg',
                  environment.color,
                )}
                style={{ width: `${environment.percent}%` }}
                ref={(el) => (barRef.current[index] = el)}
              >
                <div
                  className={clsx(
                    'text-xs font-medium text-black',
                    isHiddenTextList?.[index] && 'hidden',
                  )}
                  ref={(el) => (textRef.current[index] = el)}
                >
                  {environment.device}
                </div>
                <TooltipTop
                  positionClass={'invisible -top-10 group-hover:visible'}
                >
                  {`${environment.device} ${environment.percent}%`}
                </TooltipTop>
              </div>
            ))}
        </div>
      )}
    </ErrorBoundaryWrapper>
  );
};

export default EnvironmentBar;
