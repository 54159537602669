import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ArrowSpinner,
  Button,
  CaseExport,
  CaseImport,
  CreatePlusButton,
  DistributedExecutionButton,
  DoubleCheckDialogHeadlessUI,
  ErrorBoundaryWrapper,
  GroupManagement,
  PageHeadingButtons,
  PagingHeadingsNavigate,
  useCheckbox,
} from '@autosquare/common';

import {
  useDeleteTestCaseMutation,
  useFilteredMobileTestCaseListQuery,
} from '@lib/api/ide/mobile/mobileTestCase';

import MobileTestCaseList from './components/MobileTestCaseList';
import GroupContainer from '@components/shared/TestCaseGroup/GroupContainer';
import GroupProvider from '@contexts/ide/GroupProvider';
import TestExecutionContainer from '@components/TestExecution/TestExecutionContainer';
import SearchForm from '@components/SearchForm/SearchForm';
import getFirstGenerationDateToDateType from '@utils/static/getFirstGenerationDateToDateType';
import { useRemoteDistributionMobileTestExecutionMutation } from '@lib/api/ide/mobile/mobileExecution';
import RemoteTestExecutionAlarmModal from '@components/TestExecution/shared/RemoteTestExecutionAlarmModal';
import RemoteConnectionAlarmModal from '@components/TestExecution/shared/RemoteConnectionAlarmModal';

const MobileTestCase = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const { data: filteredMobileTestCaseList } =
    useFilteredMobileTestCaseListQuery();

  const initialCreatedAt = filteredMobileTestCaseList?.[0]?.initialCreatedAt;
  const formattedCreatedAt = getFirstGenerationDateToDateType(initialCreatedAt);
  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(filteredMobileTestCaseList, 'idx');

  const deleteTestCaseMutation = useDeleteTestCaseMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGroupOpened, setIsGroupOpened] = useState(false);
  const [isDistributedExecution, setIsDistributedExecution] = useState(false);
  const [isExecutionErrorModalOpen, setIsExecutionErrorModalOpen] =
    useState(false);

  const distributedMutation =
    useRemoteDistributionMobileTestExecutionMutation();

  const breadcrumbs = [
    {
      title: 'UI Test',
      href: pathname.split('/').slice(0, -2).join('/') + '/overview',
    },
    { title: 'Mobile', href: pathname + search },
    { title: 'Test Case', href: pathname + search },
    { title: 'List', href: pathname + search },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      icon: <CreatePlusButton />,
      variant: 'imageBasic',
      onClick: () => {
        navigate('create');
      },
      description: 'Create Case',
    },
    {
      icon: <DistributedExecutionButton />,
      variant: 'imageBasic',
      onClick: () => {
        setIsDistributedExecution(true);
      },
      description: 'Distributed Execution',
    },
    {
      icon: <GroupManagement />,
      variant: 'imageBasic',
      onClick: () => {
        setIsGroupOpened(true);
      },
      description: 'Group Management',
    },
    {
      icon: <CaseExport />,
      variant: 'imageBasic',
      onClick: () => {
        navigate('export');
      },
      description: 'Case Export',
    },
    {
      icon: <CaseImport />,
      variant: 'imageBasic',
      onClick: () => {
        navigate('import');
      },
      description: 'Case Import',
    },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Case List'}
        pageHeadingButtons={pageHeadingButtons}
      />
      <SearchForm initialCreatedAt={formattedCreatedAt}>
        <SearchForm.Platform />
        <SearchForm.Priority />
      </SearchForm>
      <GroupProvider>
        <MobileTestCaseList
          selectAllArray={selectAllArray}
          selectArray={selectArray}
          selectedArray={selectedArray}
          openDialog={openDialog}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </GroupProvider>
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedCautionDialog}
        setIsOpened={setIsOpenedCautionDialog}
        type="caution"
        title={deleteTestCaseMutation.isError ? 'Error' : 'Delete Test Case'}
        subTitle={
          deleteTestCaseMutation.isError
            ? deleteTestCaseMutation.error.message
            : '해당 테스트 케이스를 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {!deleteTestCaseMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  deleteTestCaseMutation.mutate(
                    { idx_list: selectedArray },
                    {
                      onSuccess: () => {
                        closeDialog();
                        resetSelectedArray();
                      },
                    },
                  )
                }
                disabled={deleteTestCaseMutation.isLoading}
              >
                Delete
              </Button>
            )}
            <Button type="button" variant="secondary" onClick={closeDialog}>
              Cancel
            </Button>
          </>
        }
      />
      <GroupProvider>
        {isGroupOpened && <GroupContainer closeModal={setIsGroupOpened} />}
      </GroupProvider>
      {isDistributedExecution && (
        <TestExecutionContainer
          setState={setIsDistributedExecution}
          testType={'case'}
          deviceType={'mobile'}
          isDistributedClicked={true}
          setIsModalOpen={setIsModalOpen}
          remoteMobileDistributedExecutionMutation={distributedMutation}
          setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
        />
      )}
      {distributedMutation.isLoading && <ArrowSpinner />}
      <RemoteTestExecutionAlarmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isExecutionErrorModalOpen={isExecutionErrorModalOpen}
        setIsExecutionErrorModalOpen={setIsExecutionErrorModalOpen}
      />
      <RemoteConnectionAlarmModal />
    </ErrorBoundaryWrapper>
  );
};

export default MobileTestCase;
