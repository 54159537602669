import {
  ListBoxHeadlessInController,
  ErrorBoundaryWrapper,
} from '@autosquare/common';

import { getDisplayTextBySaveTextFrom } from '@utils/mapping/mobileTestStep/saveTextMapping';
import {
  extractTextFormatTypeList,
  findExtractTextFormatTypeByServerName,
} from '@utils/static/mobileTestStep/extractTextFormat/extractTextFormatTypeList';

import DefinitionList from '@components/shared/DefinitionList';
import SwitchInController from '@components/shared/HeadlessUI/Switch/SwitchInController';
import DefinitionInputByReactHookForm from '@components/shared/TestStepDetail/DefinitionInputByReactHookForm';

import {
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { SaveTextFrom } from '@customTypes/ide/mobileTestStep/SaveTextFrom';
import { ExtractTextFormatTypeServerName } from '@customTypes/ide/mobileTestStep/extractTextFormat/extractTextFormat';

import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import SecretEditBox from '@components/TestStep/SecretEditBox';

interface Props {
  mobileTestStepData: MobileTestStepData;
}

const EditSaveText = ({ mobileTestStepData }: Props) => {
  const {
    control,
    watch,
    setValue,
    resetField,
    register,
    formState: { errors },
  } = useFormContext<UpdateTestStepData>();

  const textExtract = watch('optionsDto.textExtract');
  const isSecret = watch('optionsDto.secret') === 'true';
  const isSavedSecretValue = mobileTestStepData.optionsDto.secret === 'true';

  const placeholder =
    isSecret && isSavedSecretValue
      ? '암호화된 값은 표시되지 않습니다.'
      : '텍스트를 입력해 주세요.';

  useEffect(() => {
    if (
      mobileTestStepData.optionsDto.textExtract === 'false' &&
      textExtract === 'true'
    ) {
      setValue(
        'optionsDto.textExtractOptions.type',
        ExtractTextFormatTypeServerName.Number,
      );
      setValue('optionsDto.textExtractOptions.length', 6);
      setValue('optionsDto.textExtractOptions.index', 1);
    }

    if (
      mobileTestStepData.optionsDto.textExtract === 'false' &&
      textExtract === 'false'
    ) {
      resetField('optionsDto.textExtractOptions.type');
      resetField('optionsDto.textExtractOptions.length');
      resetField('optionsDto.textExtractOptions.index');
    }
  }, [mobileTestStepData, textExtract, setValue, resetField]);

  return (
    <ErrorBoundaryWrapper>
      <DefinitionList
        term={'Secret'}
        description={<SecretEditBox control={control} />}
      />
      <DefinitionList
        term={'From'}
        description={getDisplayTextBySaveTextFrom(
          mobileTestStepData.optionsDto.from,
        )}
      />
      {mobileTestStepData.optionsDto.from === SaveTextFrom.Sms && (
        <DefinitionInputByReactHookForm
          term={'Phone Number*'}
          name={'value'}
          type={'number'}
          min={1}
          register={register('value', {
            required: {
              value: !(isSecret && isSavedSecretValue),
              message: 'Phone Number를 확인해주세요.',
            },
          })}
          placeholder={placeholder}
        />
      )}
      <ErrorBoundaryWrapper>
        <div
          className={`items-center border-b border-gray-200 sm:flex sm:px-6 sm:py-4`}
        >
          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
            Extract Text Format
          </dt>
          <dd className="mt-1 w-full text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
            <div className="w-full">
              <Controller
                control={control}
                name={'optionsDto.textExtract'}
                render={({ field }) => (
                  <SwitchInController
                    checked={field.value === 'true'}
                    onChange={(checked: boolean) =>
                      field.onChange(checked ? 'true' : 'false')
                    }
                    size="sm"
                    disabled={
                      mobileTestStepData.optionsDto.from === SaveTextFrom.Sms
                    }
                  />
                )}
              />
            </div>
          </dd>
        </div>
      </ErrorBoundaryWrapper>
      {textExtract === 'true' && (
        <ErrorBoundaryWrapper>
          <div
            className={`items-center border-b border-gray-200 sm:flex sm:px-6 sm:py-4`}
          >
            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
              Type/ Length*
            </dt>
            <dd className="mt-1 w-full text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
              <div className="grid w-full grid-cols-2 items-center justify-between gap-2.5">
                <div className="col-span-1">
                  <Controller
                    control={control}
                    name={'optionsDto.textExtractOptions.type'}
                    render={({ field }) => (
                      <ListBoxHeadlessInController
                        value={field.value}
                        buttonValue={findExtractTextFormatTypeByServerName(
                          field.value,
                        )}
                        onChange={field.onChange}
                        lists={extractTextFormatTypeList}
                        valueToSave={'serverName'}
                        valueToShow={'name'}
                      />
                    )}
                  />
                </div>
                <div className="col-span-1">
                  <input
                    type="number"
                    className="input-base"
                    min={1}
                    {...register('optionsDto.textExtractOptions.length', {
                      required: 'Length의 값을 입력해주세요.',
                      min: { value: 1, message: '1 이상의 값을 입력해주세요.' },
                    })}
                  />
                </div>
                <ErrorMessage
                  name="optionsDto.textExtractOptions.length"
                  errors={errors}
                  render={({ message }) => (
                    <p className="standard-error-message">{message}</p>
                  )}
                />
              </div>
            </dd>
          </div>
          <DefinitionInputByReactHookForm
            term={'Index*'}
            name={'optionsDto.textExtractOptions.index'}
            type={'number'}
            min={1}
            register={register('optionsDto.textExtractOptions.index', {
              required: 'Index의 값을 입력해 주세요.',
              min: { value: 1, message: '1 이상의 값을 입력해주세요.' },
            })}
          />
        </ErrorBoundaryWrapper>
      )}
    </ErrorBoundaryWrapper>
  );
};

export default EditSaveText;
