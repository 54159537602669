import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  UpdateProjectDeviceDetail,
  UpdateProjectDeviceDetailSchema,
} from '@customTypes/index';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useDeviceActivationMutation,
  useProjectDeviceDetailMutation,
  useProjectDeviceDetailQuery,
} from '@lib/api/dashboard/queryHooks';
import React, { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import DeviceDetailFormSection from './ProjectDeviceDetailForm/DeviceDetailFormSection';
import DeviceDetailFormButtons from './ProjectDeviceDetailForm/DeviceDetailFormButtons';
import { DeviceDeleteRequestDialog } from '@components/Dashboard';
import { ErrorDialog } from '@components/Dialog';
import DeviceDetailStatusFalseDialog from './ProjectDeviceDetailForm/DeviceDetailStatusFalseDialog';

const ProjectDeviceDetailForm = () => {
  const { data: deviceDetail } = useProjectDeviceDetailQuery();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isStatusFalseModalOpen, setIsStatusFalseModalOpen] = useState(false);
  const [
    isDeviceActivationErrorModalOpen,
    setIsDeviceActivationErrorModalOpen,
  ] = useState(false);
  const [
    isUpdateDeviceDetailErrorModalOpen,
    setIsUpdateDeviceDetailErrorModalOpen,
  ] = useState(false);

  const methods = useForm<UpdateProjectDeviceDetail>({
    defaultValues: {
      udid: deviceDetail.udid,
      deviceModel: deviceDetail.deviceModel,
      deviceName: deviceDetail.deviceName || undefined,
      deviceOs: deviceDetail.deviceOs,
      deviceType: deviceDetail.deviceType || undefined,
      enabled: deviceDetail.enabled,
    },
    resolver: zodResolver(UpdateProjectDeviceDetailSchema),
  });

  const deviceActivationMutation = useDeviceActivationMutation();
  const projectDeviceDetailMutation = useProjectDeviceDetailMutation();

  const onSubmit: SubmitHandler<UpdateProjectDeviceDetail> = (data) => {
    if (data.enabled) {
      projectDeviceDetailMutation.mutate(data, {
        onError: () => {
          setIsUpdateDeviceDetailErrorModalOpen(true);
        },
      });
    } else {
      setIsStatusFalseModalOpen(true);
    }
  };

  const updateDeviceFalseStatus = () => {
    deviceActivationMutation.mutate(
      { idx: deviceDetail.idx, enabled: false },
      {
        onError: () => {
          setIsDeviceActivationErrorModalOpen(true);
        },
      },
    );
  };

  return (
    <ErrorBoundaryWrapper>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DeviceDetailFormSection />
          <DeviceDetailFormButtons
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            isLoading={
              deviceActivationMutation.isLoading ||
              projectDeviceDetailMutation.isLoading
            }
          />
        </form>
      </FormProvider>
      {/* 디바이스 비활성화 시 Dialog */}
      <DeviceDetailStatusFalseDialog
        isOpen={isStatusFalseModalOpen}
        setIsOpen={setIsStatusFalseModalOpen}
        updateDeviceFalseStatus={updateDeviceFalseStatus}
        isLoading={deviceActivationMutation.isLoading}
      />
      {/* 디바이스 비활성화 에러 Dialog */}
      <ErrorDialog
        isOpen={isDeviceActivationErrorModalOpen}
        setIsOpen={setIsDeviceActivationErrorModalOpen}
        subTitle={deviceActivationMutation.error?.message}
      />
      {/* 디바이스 상세 업데이트 에러 Dialog */}
      <ErrorDialog
        isOpen={isUpdateDeviceDetailErrorModalOpen}
        setIsOpen={setIsUpdateDeviceDetailErrorModalOpen}
        subTitle={projectDeviceDetailMutation.error?.message}
      />
      {/* 디바이스 상세 삭제 관련 Dialog */}
      <DeviceDeleteRequestDialog
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        closeDialog={() => setIsDeleteModalOpen(false)}
      />
    </ErrorBoundaryWrapper>
  );
};

export default ProjectDeviceDetailForm;
