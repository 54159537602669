/* eslint-disable @typescript-eslint/no-unused-vars */
import { SpeedMobileTestCaseData } from '@customTypes/speedTestCase/type';
import {
  TestCaseDetailData,
  WebTestCaseDetailData,
} from '@customTypes/testCase/type';
import { PlatFormTypeServerName } from './platformTypeList';
import { ImportTestStepFilteredList } from '@customTypes/index';

export const removeMobileUnusedFields = (
  {
    priority,
    dndIndex,
    updatedAt,
    caseGroupInfo,
    creator,
    steps,
    ...rest
  }: TestCaseDetailData | SpeedMobileTestCaseData,
  selectedOsCount: string,
  isUiTestLocation: boolean,
) => ({
  ...rest,
  disabled: isUiTestLocation
    ? steps?.[selectedOsCount] === 0
    : steps?.[selectedOsCount]?.length === 0 ||
      rest.platformType === PlatFormTypeServerName.MobileWeb,
});

export const removeWebUnusedFields = ({
  priority,
  stepCount,
  updatedAt,
  creator,
  caseGroupInfo,
  ...rest
}: WebTestCaseDetailData) => ({
  ...rest,
  disabled: stepCount === 0,
});

export const filterSelectedIdx = (
  caseData: ImportTestStepFilteredList,
  idx: string,
) => {
  return caseData.idx !== Number(idx);
};
