import { RootState } from '@app/store';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

type Props = {
  searchWord: string;
  onChangeSearchWord: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  setSearchWord: (value: React.SetStateAction<string>) => void;
};

const DraggableSearchbar = ({
  searchWord,
  onChangeSearchWord,
  setSearchWord,
}: Props) => {
  const { pathname } = useLocation();

  const isCreatePathname = pathname.includes('create');

  const isRunning = useSelector(
    (state: RootState) => state.scheduler.isRunning,
  );

  return (
    <ErrorBoundaryWrapper>
      <div className="relative w-full px-2 pb-1.5 pt-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="absolute left-4 top-4 size-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>
        <input
          className="input-sm px-8 disabled:bg-gray-100"
          value={searchWord}
          onChange={onChangeSearchWord}
          disabled={!isCreatePathname && !isRunning}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className={clsx(
            'absolute right-4 top-4 size-4',
            isCreatePathname || isRunning ? 'cursor-pointer' : 'cursor-default',
          )}
          onClick={() => setSearchWord('')}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default DraggableSearchbar;
