import { ErrorBoundaryWrapper } from '@autosquare/common';
import { SmsData } from '@customTypes/ide/device/sms';
import { flexRender, Row, Table } from '@tanstack/react-table';
import clsx from 'clsx';
import React from 'react';

interface ProcessedSmsData extends SmsData {
  numbersBody: string;
}
type Props = {
  table: Table<ProcessedSmsData>;
  handleRowClick: (row: Row<ProcessedSmsData>) => void;
  selectedRowId: string;
};

const SmsTable = ({ table, handleRowClick, selectedRowId }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <table className="size-full">
        <thead className="sticky top-0 border-b border-gray-200 bg-white">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              <th className="border-gray-200 bg-white py-2 pl-1" />
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className="whitespace-nowrap p-2 text-sm font-medium leading-normal text-gray-900"
                >
                  {header.isPlaceholder ? null : (
                    <div
                      className={clsx(
                        'flex items-center justify-start',
                        header.column.getCanSort() &&
                          'cursor-pointer select-none',
                      )}
                      onClick={header.column.getToggleSortingHandler()}
                      title={
                        header.column.getCanSort()
                          ? header.column.getNextSortingOrder() === 'asc'
                            ? 'Sort ascending'
                            : header.column.getNextSortingOrder() === 'desc'
                              ? 'Sort descending'
                              : 'Clear sort'
                          : undefined
                      }
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {{
                        asc: (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="ml-1.5 size-4 text-gray-400"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m4.5 15.75 7.5-7.5 7.5 7.5"
                            />
                          </svg>
                        ),
                        desc: (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="ml-1.5 size-4 text-gray-400"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m19.5 8.25-7.5 7.5-7.5-7.5"
                            />
                          </svg>
                        ),
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white text-sm font-normal leading-normal text-gray-900">
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.original.id}
              className={clsx(
                'hover:cursor-pointer hover:bg-gray-100',
                row.original.id === selectedRowId && 'bg-gray-100',
              )}
              onClick={() => {
                handleRowClick(row);
              }}
            >
              <td className="flex h-full items-center justify-center py-0.5 pl-1">
                {row.original.id === selectedRowId ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2.5}
                    stroke="currentColor"
                    className="size-4 font-medium text-gray-900"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                ) : (
                  <div className="w-4" />
                )}
              </td>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="px-2">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </ErrorBoundaryWrapper>
  );
};

export default SmsTable;
