import React from 'react';

const AddSystemUsers = () => {
  return (
    <div className="pt-8">
      <h2 className="text-base font-semibold leading-6 text-gray-900">
        Add System User
      </h2>
      <p className="pt-1 text-sm text-gray-500">
        이메일 주소로 시스템 사용자를 초대할 수 있습니다.
      </p>
    </div>
  );
};

export default AddSystemUsers;
