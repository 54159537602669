import React from 'react';
import { Link } from 'react-router-dom';

import {
  ErrorBoundaryWrapper,
  ErrorMessage,
  TableBodyBasic,
  TableHeader,
  TableNoList,
  TableSkeleton,
} from '@autosquare/common';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';
import { AdminUserStatus } from '@customTypes/index';
import dayjs from 'dayjs';
import { AdminUserRole } from '@customTypes/dashboard/admin/type';
import { useAdminUserListQuery } from '@lib/api/dashboard/queryHooks';

const AdminUserListTable = () => {
  const {
    data: adminUserList,
    isError,
    error,
    isLoading,
    isFetching,
  } = useAdminUserListQuery();

  const userInfo = useSelector((state: RootState) => state.userInfo.userInfo);

  const lastSeenSortedAdminList = adminUserList?.sort((a, b) => {
    if (a.lastSeen === '-' && b.lastSeen === '-') return 0; // 둘 다 '-'인 경우 순서 유지
    if (a.lastSeen === '-') return 1; // a가 '-'이면 뒤로 이동
    if (b.lastSeen === '-') return -1; // b가 '-'이면 뒤로 이동

    const dateA = dayjs(a.lastSeen).valueOf();
    const dateB = dayjs(b.lastSeen).valueOf();

    return dateB - dateA;
  });

  const systemAdminFirstUserList = lastSeenSortedAdminList?.sort((a, b) => {
    if (a.email === userInfo.userId) return -1;
    if (b.email === userInfo.userId) return 1;
    return 0;
  });

  const tableHeaderList = [
    { idx: 1, title: 'No' },
    { idx: 2, title: 'Name' },
    { idx: 3, title: 'Email' },
    { idx: 4, title: 'Project' },
    { idx: 5, title: 'Status' },
    { idx: 6, title: 'Last Seen' },
    { idx: 7, title: 'Detail' },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {tableHeaderList?.map((tableHeader) => (
                    <TableHeader key={tableHeader.idx}>
                      {tableHeader.title}
                    </TableHeader>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white">
                {isLoading || isFetching ? (
                  <TableNoList colSpan={tableHeaderList.length}>
                    <TableSkeleton />
                  </TableNoList>
                ) : isError ? (
                  <TableNoList colSpan={tableHeaderList.length}>
                    <ErrorMessage>{error.message}</ErrorMessage>
                  </TableNoList>
                ) : adminUserList.length === 0 ? (
                  <TableNoList colSpan={tableHeaderList.length}>
                    <p>등록된 유저가 존재하지 않습니다.</p>
                  </TableNoList>
                ) : (
                  systemAdminFirstUserList.map((adminUser, idx) => (
                    <tr
                      key={adminUser.userIdx}
                      className={clsx(
                        'border-y-solid border-y border-y-gray-200 last:border-y-0',
                        adminUser.status === AdminUserStatus.Deactivate &&
                          'bg-gray-100',
                      )}
                    >
                      <TableBodyBasic>{idx + 1}</TableBodyBasic>
                      <TableBodyBasic>
                        <div className="flex items-center gap-4">
                          <div>{adminUser.name}</div>
                          {adminUser.role === AdminUserRole.Admin && (
                            <div className="role-label admin">System Admin</div>
                          )}
                        </div>
                      </TableBodyBasic>
                      <TableBodyBasic>{adminUser.email}</TableBodyBasic>
                      <TableBodyBasic>{adminUser.projectCount}</TableBodyBasic>
                      <TableBodyBasic>{adminUser.status}</TableBodyBasic>
                      <TableBodyBasic>{adminUser.lastSeen}</TableBodyBasic>
                      <TableBodyBasic>
                        {adminUser.name !== 'GUEST' && (
                          <Link to={`detail?idx=${adminUser.userIdx}`}>
                            <button
                              type="button"
                              className="text-gray-900 hover:text-congress-blue"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                                />
                              </svg>
                            </button>
                          </Link>
                        )}
                      </TableBodyBasic>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default AdminUserListTable;
