import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { axiosInstance, axiosLocalInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';
import { schedulerKeys } from '@queries/ide/schedulerKeys';

import {
  CreateSchedulerData,
  DeleteSchedulerData,
  SchedulerDetailData,
  UpdateSchedulerData,
  ActivateMessengerData,
} from '@customTypes/ide/scheduler/scheduler';
import { useSelector } from 'react-redux';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { RootState } from '@app/store';
import { Mode } from '@customTypes/ide/remote/type';
import { remoteConfig } from '@utils/static/remoteConfig';
import { getRemoteState } from '@utils/static/getConfiguration';

interface UseUpdateSchedulerMutation
  extends UseMutationOptions<UpdateSchedulerData> {
  cookie: string;
  url: string;
}

interface UseUpdateSchedulerStatus {
  idx: number;
  isRunning: boolean;
  cookie: string;
}

const remoteSchedulerBaseUrl = `${IDEAPI.REMOTE}/connect/scheduler`;
// GET
// 스케쥴러 전체 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Scheduler/getSchedulerList
const schedulerList = async (idx: string): Promise<SchedulerDetailData[]> => {
  const response = await axiosInstance.get<SchedulerDetailData[]>(
    IDEAPI.GET_ALL_SCHEDULER_LIST + idx,
  );
  return response.data;
};
export const useSchedulerListQuery = () => {
  const { projectIdx } = useParams();
  return useQuery<SchedulerDetailData[], Error>({
    queryKey: schedulerKeys.list(projectIdx),
    queryFn: () => schedulerList(projectIdx),
  });
};

// 스케쥴러 상세 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Scheduler/getScheduler
const schedulerDetail = async (idx: string): Promise<SchedulerDetailData> => {
  const response = await axiosInstance.get<SchedulerDetailData>(
    IDEAPI.GET_SCHEDULER + idx,
  );
  return response.data;
};
export const useSchedulerDetailQuery = () => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');
  return useQuery<SchedulerDetailData, Error>({
    queryKey: schedulerKeys.detail(idx),
    queryFn: () => schedulerDetail(idx),
    staleTime: 0,
  });
};

const schedulerInit = async (): Promise<unknown> => {
  const response = await axiosLocalInstance.get(IDEAPI.SCHEDULER_INIT);
  return response.data;
};
export const useSchedulerInitQuery = () => {
  const { configuration } = getRemoteState();
  return useQuery<unknown, Error>({
    queryKey: schedulerKeys.init(),
    queryFn: () => schedulerInit(),
    enabled: configuration === Mode.Standard,
  });
};

// 스케쥴러 필터링 검색 조회
// https://dev.t-square.co.kr/swagger-ui/index.html#/Scheduler/searchWebTestScheduler
const filteredSchedulerList = async (
  search: string,
): Promise<SchedulerDetailData[]> => {
  const response = await axiosInstance.get<SchedulerDetailData[]>(
    `${IDEAPI.GET_SCHEDULER}search${search}`,
  );
  return response.data;
};
export const useFilteredSchedulerListQuery = () => {
  const { search } = useLocation();

  return useQuery<SchedulerDetailData[], Error>({
    queryKey: schedulerKeys.search(search),
    queryFn: () => filteredSchedulerList(search),
    enabled: search !== '',
  });
};

const getSchedulerMessengerInfo = async (
  idx: string,
): Promise<ActivateMessengerData[]> => {
  const response = await axiosInstance.get(
    `${IDEAPI.PROJECT_DETAIL}${idx}/alarm`,
  );
  return response.data;
};

export const useGetSchedulerMessengerInfoQuery = () => {
  const { projectIdx } = useParams();
  return useQuery<ActivateMessengerData[], Error>({
    queryKey: schedulerKeys.messenger(projectIdx),
    queryFn: () => getSchedulerMessengerInfo(projectIdx),
  });
};

// POST
const createScheduler = async (
  cookie: string,
  data: CreateSchedulerData,
): Promise<unknown> => {
  const response = await axiosLocalInstance.post<unknown>(
    IDEAPI.CREATE_SCHEDULER,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useCreateSchedulerMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const cookie = useSelector(
    (state: RootState) => state.refreshToken.refreshToken,
  );

  return useMutation<unknown, Error, CreateSchedulerData>({
    mutationFn: (data) => createScheduler(cookie, data),
    onSuccess: () => {
      queryClient.invalidateQueries(schedulerKeys.all);
      navigate(pathname.split('/').slice(0, -1).join('/'));
    },
  });
};

// 리모트 스케쥴러 생성
// https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Schedule/saveScheduler
const remoteCreateScheduler = async (data: CreateSchedulerData) => {
  const response = await axiosInstance.post<unknown>(
    remoteSchedulerBaseUrl,
    data,
    remoteConfig,
  );
  return response.data;
};

export const useRemoteCreatedSchedulerMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return useMutation<unknown, Error, CreateSchedulerData>({
    mutationFn: (data) => remoteCreateScheduler(data),
    onSuccess: () => {
      queryClient.invalidateQueries(schedulerKeys.all);
      navigate(pathname.split('/').slice(0, -1).join('/'));
    },
  });
};

// PUT
const updateScheduler = async (
  cookie: string,
  data: UpdateSchedulerData,
): Promise<UpdateSchedulerData> => {
  const response = await axiosLocalInstance.put<UpdateSchedulerData>(
    IDEAPI.UPDATE_SCHEDULER,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useUpdateSchedulerMutation = ({
  cookie,
  url,
}: UseUpdateSchedulerMutation) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<UpdateSchedulerData, Error, UpdateSchedulerData>({
    mutationFn: (data) => updateScheduler(cookie, data),
    onSuccess: () => {
      queryClient.invalidateQueries(schedulerKeys.all);
      navigate(url);
    },
  });
};

// 리모트 스케쥴러 수정
// https://dev.t-square.co.kr/swagger-ui/index.html#/v1.0.3/updateScheduler
const remoteUpdateScheduler = async (data: UpdateSchedulerData) => {
  const response = await axiosInstance.put<UpdateSchedulerData>(
    remoteSchedulerBaseUrl,
    data,
    remoteConfig,
  );
  return response.data;
};

export const useRemoteUpdateSchedulerMutation = ({
  url,
}: UseUpdateSchedulerMutation) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation<UpdateSchedulerData, Error, UpdateSchedulerData>({
    mutationFn: (data) => remoteUpdateScheduler(data),
    onSuccess: () => {
      queryClient.invalidateQueries(schedulerKeys.all);
      navigate(url);
    },
  });
};

const updateSchedulerStatus = async ({
  idx,
  isRunning,
  cookie,
}: UseUpdateSchedulerStatus): Promise<void> => {
  const response = await axiosLocalInstance.put<void>(
    `${IDEAPI.UPDATE_SCHEDULER_STATUS}${idx}/${isRunning}`,
    undefined,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useUpdateSchedulerStatusMutation = ({
  idx,
  isRunning,
  cookie,
}: UseUpdateSchedulerStatus) => {
  const queryClient = useQueryClient();

  const isDetailPage = isWordInLocationPathname('/detail');

  return useMutation<void, Error>({
    mutationFn: () =>
      updateSchedulerStatus({ idx: idx, isRunning: isRunning, cookie: cookie }),
    onSuccess: () => {
      queryClient.invalidateQueries(schedulerKeys.lists());
      queryClient.invalidateQueries(schedulerKeys.searches());
      if (!isRunning || !isDetailPage) {
        queryClient.invalidateQueries(schedulerKeys.details());
      }
    },
  });
};

// 리모트 스케쥴러 상태 변경
// https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Schedule/updateSchedulerStatus
const remoteUpdateSchedulerStatus = async (
  idx: string,
  isRunning: boolean,
): Promise<SchedulerDetailData> => {
  const response = await axiosInstance.put<SchedulerDetailData>(
    `${remoteSchedulerBaseUrl}/${idx}/${isRunning}`,
    undefined,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteUpdateSchedulerStatusMutation = (
  isRunning: boolean,
  schedulerIdx?: number,
) => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const schedulerDetailIdx = searchParams.get('idx');
  const idx = schedulerIdx ? schedulerIdx.toString() : schedulerDetailIdx;

  return useMutation<SchedulerDetailData, Error>({
    mutationFn: () => remoteUpdateSchedulerStatus(idx, isRunning),
    onSuccess: () => {
      queryClient.invalidateQueries(schedulerKeys.lists());
      queryClient.invalidateQueries(schedulerKeys.searches());
      queryClient.invalidateQueries(schedulerKeys.details());
    },
  });
};

const deleteScheduler = async (
  data: DeleteSchedulerData,
  cookie: string,
): Promise<DeleteSchedulerData> => {
  const response = await axiosLocalInstance.put<DeleteSchedulerData>(
    IDEAPI.DELETE_SCHEDULER,
    data,
    { headers: { 'Token-Value': cookie } },
  );
  return response.data;
};
export const useDeleteSchedulerMutation = (cookie: string) => {
  const queryClient = useQueryClient();

  return useMutation<DeleteSchedulerData, Error, DeleteSchedulerData>({
    mutationFn: (data) => deleteScheduler(data, cookie),
    onSuccess: () => {
      queryClient.invalidateQueries(schedulerKeys.all);
    },
  });
};

//리모트 스케줄러 삭제
//https://dev.t-square.co.kr/swagger-ui/index.html#/Remote%20%3E%20Schedule/deleteScheduler
const remoteDeleteScheduler = async (
  data: DeleteSchedulerData,
): Promise<DeleteSchedulerData> => {
  const response = await axiosInstance.put<DeleteSchedulerData>(
    `${remoteSchedulerBaseUrl}/delete`,
    data,
    remoteConfig,
  );
  return response.data;
};
export const useRemoteDeleteSchedulerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<DeleteSchedulerData, Error, DeleteSchedulerData>({
    mutationFn: (data) => remoteDeleteScheduler(data),
    onSuccess: () => {
      queryClient.invalidateQueries(schedulerKeys.all);
    },
  });
};
