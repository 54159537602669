import React from 'react';

type Props = {
  list: { title: string; data: string; editable?: boolean }[];
};

const ProjectApplicationDetailView = ({ list }: Props) => {
  return (
    <div className="space-y-5 border-t border-gray-200 pb-8 pt-4">
      {list.map(({ title, data }) => (
        <div
          key={title}
          className="grid items-center gap-4 text-sm leading-normal text-gray-900 md:grid-cols-8"
        >
          <div className="font-medium">{title}</div>
          <div className="whitespace-nowrap font-normal">{data}</div>
        </div>
      ))}
    </div>
  );
};

export default ProjectApplicationDetailView;
