import React from 'react';
import DeviceAppInstallHeader from './components/DeviceAppInstallHeader';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import DeviceAppInstallForm from './components/DeviceAppInstallForm';

const DeviceAppInstall = () => {
  return (
    <ErrorBoundaryWrapper>
      <DeviceAppInstallHeader />
      <DeviceAppInstallForm />
    </ErrorBoundaryWrapper>
  );
};

export default DeviceAppInstall;
