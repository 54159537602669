import { Breadcrumbs, PagingHeadings } from '@autosquare/common';
import { useParentPath } from '@hooks/index';
import React from 'react';

const DeviceAppInstallHeader = () => {
  const deviceListPath = useParentPath();

  const breadcrumbs: Breadcrumbs[] = [
    { title: 'Project Settings', href: deviceListPath },
    { title: 'Devices', href: deviceListPath },
    { title: 'Install', href: '#' },
  ];

  return (
    <PagingHeadings
      breadcrumbs={breadcrumbs}
      pageTitle={'Application Install'}
    />
  );
};

export default DeviceAppInstallHeader;
