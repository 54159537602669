import React from 'react';
import clsx from 'clsx';
import { ErrorMessage, PagingHeadingsInAutosquare } from '@autosquare/common';
import {
  useGetResultRateQuery,
  useOverviewQuery,
} from '@lib/api/ide/overview/overview';
import { useProjectDetailQuery } from '@lib/api/ide/project/project';
import { useLocation, useParams } from 'react-router-dom';
import {
  ProjectListDetailFeaturesCategory,
  ProjectListDetailFeaturesFeature,
} from '@customTypes/dashboard/project/type';
import { checkIsProjectFeatureIncluded } from '@utils/static/checkProjectFeature';
import ResultRatePie from '@components/Overview/ResultRatePie';
import OverviewSubTitle from '@components/shared/OverviewSubTitle';
import EnvironmentBar from '@components/Overview/EnvironmentBar';
import TestBreakdownGroup from '@components/Overview/TestBreakdownGroup';
import OverviewGraphSkeleton from '@components/Skeleton/OverviewGraphSkeleton';
import WebUITestResults from '@components/Overview/WebUITestResults';
import MobileUITestResults from '@components/Overview/MobileUITestResults';

const UITestOverview = () => {
  const { pathname, search } = useLocation();
  const { projectIdx } = useParams();

  const overviewQuery = useOverviewQuery(projectIdx);
  const overview = overviewQuery.data;

  const projectDetailQuery = useProjectDetailQuery();
  const projectDetail = projectDetailQuery.data;

  const isMobileTestEnabled = checkIsProjectFeatureIncluded(
    projectDetail,
    ProjectListDetailFeaturesCategory.Mobile,
    ProjectListDetailFeaturesFeature.Functional,
  );
  const isWebTestEnabled = checkIsProjectFeatureIncluded(
    projectDetail,
    ProjectListDetailFeaturesCategory.Web,
    ProjectListDetailFeaturesFeature.Functional,
  );

  const isBothEnabled = isMobileTestEnabled && isWebTestEnabled;

  const reportQuery = useGetResultRateQuery();
  const reportData = reportQuery.data;
  const mobileReportData = reportData?.mobileResultReports;
  const webReportData = reportData?.webResultReports;

  return (
    <>
      <PagingHeadingsInAutosquare
        breadcrumbs={[
          { title: 'UI Test', href: pathname + search },
          { title: 'Overview', href: pathname + search },
        ]}
        pageTitle={'Overview'}
      />
      {overviewQuery.isLoading ||
      overviewQuery.isFetching ||
      projectDetailQuery.isLoading ||
      projectDetailQuery.isFetching ||
      reportQuery.isLoading ||
      reportQuery.isFetching ? (
        <OverviewGraphSkeleton />
      ) : overviewQuery.isError ? (
        <ErrorMessage>{overviewQuery.error.message}</ErrorMessage>
      ) : projectDetailQuery.isError ? (
        <ErrorMessage>{projectDetailQuery.error.message}</ErrorMessage>
      ) : reportQuery.isError ? (
        <ErrorMessage>{reportQuery.error.message}</ErrorMessage>
      ) : (
        <div className="mt-8 flow-root">
          <OverviewSubTitle
            subTitle={'Result Rate'}
            description="프로젝트 테스트 결과 비율"
          />
          <div className="grid grid-cols-2 gap-x-7">
            {isMobileTestEnabled && (
              <ResultRatePie data={mobileReportData} header="Mobile" />
            )}
            {isWebTestEnabled && (
              <ResultRatePie data={webReportData} header="Web" />
            )}
            <div className={clsx(isBothEnabled && 'col-span-2')}>
              <OverviewSubTitle
                subTitle={'Environment'}
                description="테스트 환경"
              />
              <div
                className={clsx(isBothEnabled && 'grid grid-cols-2 gap-x-3')}
              >
                {isMobileTestEnabled && (
                  <EnvironmentBar
                    data={mobileReportData?.envRate}
                    envType={'mobile'}
                  />
                )}
                {isWebTestEnabled && (
                  <EnvironmentBar
                    data={webReportData?.envRate}
                    envType={'web'}
                  />
                )}
              </div>
              <div className="mt-8 flow-root">
                <OverviewSubTitle
                  subTitle={'Test Breakdown'}
                  description="프로젝트 내 스케줄러, 시나리오, 케이스 개수"
                />
                <TestBreakdownGroup
                  data={reportData}
                  isMobileTestEnabled={isMobileTestEnabled}
                  isWebTestEnabled={isWebTestEnabled}
                />
              </div>
            </div>
          </div>
          <div
            className={clsx(
              isBothEnabled && 'grid grid-cols-1 gap-x-3 2xl:grid-cols-2',
            )}
          >
            {isMobileTestEnabled && (
              <MobileUITestResults
                tableHeaders={tableHeaders}
                mobileResults={overview?.mobileResults}
              />
            )}
            {isWebTestEnabled && (
              <WebUITestResults
                tableHeaders={tableHeaders}
                webResults={overview?.webResults}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default UITestOverview;

const tableHeaders = ['No', 'Title', 'DateTime', 'Result', 'Detail'];
