import { ErrorBoundaryWrapper } from '@autosquare/common';
import AddTestStepConditionHeader from '@components/TestStep/AddTestStepConditionHeader';

import React from 'react';
import OperatorsFieldArray from '../DataValidation/OperatorsFieldArray';
import TypeAndReferenceValue from '../DataValidation/TypeAndReferenceValue';

const DataValidation = () => {
  return (
    <ErrorBoundaryWrapper>
      <div className="flex flex-col gap-4">
        <AddTestStepConditionHeader
          header={'Condition'}
          description={'조건식을 설정합니다.'}
        />
        <TypeAndReferenceValue />
        <OperatorsFieldArray />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default DataValidation;
