import {
  ErrorBoundaryWrapper,
  ScenarioListDetailData,
} from '@autosquare/common';
import { UpdateSchedulerData } from '@customTypes/ide/scheduler/scheduler';

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { SelectedItem } from '@autosquare/common/src/hooks/checkbox/useCheckboxWithDnd';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  MeasuringStrategy,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useScenarioDndHandler } from '@hooks/useScenarioDndHandler';
import DetailCard from './DetailPaste/DetailCard';

interface Props {
  copyTestScenarios: ScenarioListDetailData[];
  setCopyTestScenarios: React.Dispatch<
    React.SetStateAction<ScenarioListDetailData[]>
  >;
  selectedArray: SelectedItem[];
  selectArray: (
    idx: number,
    dndIndex: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  setSelectedArray: React.Dispatch<React.SetStateAction<SelectedItem[]>>;
}
const DetailPaste = ({
  copyTestScenarios,
  setCopyTestScenarios,
  selectedArray,
  selectArray,
  setSelectedArray,
}: Props) => {
  const { setValue } = useFormContext<UpdateSchedulerData>();

  const {
    sensors,
    onDragEnd,
    onDragStart,
    items,
    onRemove,
    activeId,
    overlayCopyTestCase,
  } = useScenarioDndHandler(copyTestScenarios, setCopyTestScenarios);

  useEffect(() => {
    setValue(
      'scenarioIdxList',
      [...copyTestScenarios].map((list) => list?.idx),
    );
  }, [copyTestScenarios]);

  return (
    <ErrorBoundaryWrapper>
      <div className="gray-scrollbar max-h-[calc(16.375rem)]">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          modifiers={[restrictToVerticalAxis]}
          measuring={{
            droppable: {
              strategy: MeasuringStrategy.Always,
            },
          }}
        >
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            <div className="gray-scrollbar h-[calc(100%-0.5rem)] w-full">
              {copyTestScenarios?.map((copyTestScenario) => (
                <DetailCard
                  key={copyTestScenario.dndIndex}
                  copyItem={copyTestScenario}
                  selectArray={selectArray}
                  selectedArray={selectedArray}
                  onRemove={onRemove}
                  setSelectedArray={setSelectedArray}
                />
              ))}
            </div>
          </SortableContext>
          <DragOverlay
            wrapperElement="ul"
            style={{
              boxShadow: activeId && '0 10px 10px -5px',
            }}
          >
            {activeId ? (
              <div style={{ transform: 'scale(1.04)' }}>
                <DetailCard
                  copyItem={overlayCopyTestCase}
                  selectArray={selectArray}
                  selectedArray={selectedArray}
                  onRemove={onRemove}
                  setSelectedArray={setSelectedArray}
                />
              </div>
            ) : null}
          </DragOverlay>
        </DndContext>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default DetailPaste;
