import React from 'react';
import {
  CommonDialog,
  DialogButton,
  DialogButtonPanels,
} from '@components/Dialog';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onDeleteHandler: () => void;
  title: string;
  subTitle: string;
};

const DeleteButtonCommonDialog = ({
  isOpen,
  setIsOpen,
  onDeleteHandler,
  title,
  subTitle,
}: Props) => {
  return (
    <CommonDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      iconType="caution"
      title={title}
      subTitle={subTitle}
    >
      <DialogButtonPanels>
        <DialogButton type="button" onClick={onDeleteHandler} variant="caution">
          Delete
        </DialogButton>
        <DialogButton
          type="button"
          onClick={() => setIsOpen(false)}
          variant="secondary"
        >
          Cancel
        </DialogButton>
      </DialogButtonPanels>
    </CommonDialog>
  );
};

export default DeleteButtonCommonDialog;
