import {
  Breadcrumbs,
  ErrorBoundaryWrapper,
  PagingHeadingsNavigate,
} from '@autosquare/common';

import TitleBar from '@components/SettingsAndHelpModal/components/TitleBar';

import { mobileDeviceKeys } from '@queries/ide/mobile/mobileDeviceKeys';

import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import PortConnection from './components/PortConnection';
import WifiConnection from './components/WifiConnection';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

interface Props {
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  connectionType: 'wifi' | 'port';
}

const AddDevice = ({ setState, connectionType }: Props) => {
  const queryClient = useQueryClient();

  const closeModal = () => {
    queryClient.invalidateQueries(mobileDeviceKeys.lists());
    setState(false);
  };

  const breadcrumbs: Breadcrumbs[] = [
    {
      title: isWordInLocationPathname('ui-test')
        ? 'UI Test'
        : isWordInLocationPathname('speed-test') && 'Speed Test',
    },
    { title: 'Mobile' },
    { title: 'Test Case' },
    { title: 'Test Step' },
    { title: 'Device List' },
    { title: 'Add Device' },
  ];

  return (
    <ErrorBoundaryWrapper>
      <div className="relative z-50">
        <div className="fixed inset-0 flex items-center justify-center bg-black/30 backdrop-blur-sm">
          <div className={`h-4/5 w-2/3 rounded-md bg-white`}>
            <TitleBar closeModal={closeModal} width="w-2/3" />
            <div className="gray-scrollbar mt-8 flex h-full flex-col px-8 pt-8">
              <div className="pb-8">
                <PagingHeadingsNavigate
                  breadcrumbs={
                    !isWordInLocationPathname('scheduler') && breadcrumbs
                  }
                  pageTitle={'Add Device'}
                />
              </div>
              {connectionType === 'wifi' ? (
                <WifiConnection closeModal={closeModal} />
              ) : (
                <PortConnection closeModal={closeModal} />
              )}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default AddDevice;
