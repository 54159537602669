import React from 'react';
import {
  Breadcrumbs,
  ErrorBoundaryWrapper,
  PageHeadingButtons,
  PagingHeadingsInAutosquare,
} from '@autosquare/common';
import SearchForm from '@components/SearchForm/SearchForm';
import { useLocation } from 'react-router-dom';
import SpeedTestSchedulerTable from './components/SpeedTestSchedulerTable';
import { useSearchSpeedTestSchedulerListQuery } from '@lib/api/ide/speedScheduler/speedScheduler';
import getFirstGenerationDateToDateType from '@utils/static/getFirstGenerationDateToDateType';

const SpeedTestScheduler = () => {
  const { pathname, search } = useLocation();

  const { data: searchSpeedTestSchedulerList } =
    useSearchSpeedTestSchedulerListQuery();

  const initialCreatedAt = searchSpeedTestSchedulerList?.[0]?.initialCreatedAt;
  const formattedCreatedAt = getFirstGenerationDateToDateType(initialCreatedAt);

  const breadcrumbs: Breadcrumbs[] = [
    {
      title: 'Speed Test',
      href: `${pathname.split('/').slice(0, -1).join('/')}/overview`,
    },
    { title: 'Scheduler', href: pathname + search },
    { title: 'List', href: pathname + search },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    { title: 'Create Scheduler', href: 'create' },
  ];

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Scheduler List'}
        pageHeadingButtons={pageHeadingButtons}
      />
      <SearchForm initialCreatedAt={formattedCreatedAt}>
        <SearchForm.SchedulerTestType />
        <SearchForm.SchedulerStatus />
      </SearchForm>
      <SpeedTestSchedulerTable />
    </ErrorBoundaryWrapper>
  );
};

export default SpeedTestScheduler;
