import React from 'react';
import CommonDialog from './CommonDialog';
import { Button } from '@autosquare/common';
import { DialogButtonPanels } from './DialogComponents';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  subTitle: string;
  title?: string;
};

const ErrorDialog = ({
  isOpen,
  setIsOpen,
  subTitle,
  title = 'Failure',
}: Props) => {
  return (
    <CommonDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      iconType="caution"
      title={title}
      subTitle={subTitle}
    >
      <DialogButtonPanels>
        <Button
          variant="secondary"
          type="button"
          onClick={() => setIsOpen(false)}
        >
          Close
        </Button>
      </DialogButtonPanels>
    </CommonDialog>
  );
};

export default ErrorDialog;
