import React from 'react';
import { ErrorBoundaryWrapper } from '@autosquare/common';
import {
  CommonDialog,
  DialogButton,
  DialogButtonPanels,
} from '@components/Dialog';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isExecutionErrorModalOpen: boolean;
  setIsExecutionErrorModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const RemoteTestExecutionAlarmModal = ({
  isModalOpen,
  setIsModalOpen,
  isExecutionErrorModalOpen,
  setIsExecutionErrorModalOpen,
}: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <CommonDialog
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        iconType={'checked'}
        title={'Execution Success'}
        subTitle={'테스트 실행 요청이 완료되었습니다.'}
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant="secondary"
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
      <CommonDialog
        isOpen={isExecutionErrorModalOpen}
        setIsOpen={setIsExecutionErrorModalOpen}
        iconType={'caution'}
        title={'Execution Failure'}
        subTitle={'테스트 실행 요청이 실패하였습니다.'}
      >
        <DialogButtonPanels>
          <DialogButton
            type="button"
            variant="secondary"
            onClick={() => setIsExecutionErrorModalOpen(false)}
          >
            Close
          </DialogButton>
        </DialogButtonPanels>
      </CommonDialog>
    </ErrorBoundaryWrapper>
  );
};

export default RemoteTestExecutionAlarmModal;
