import React from 'react';
import clsx from 'clsx';
import { WebSpeedTestResultTableDetailData } from '@customTypes/ide/speedTest/Web/testResult/type';

type Props = {
  speedWebTestResultTableData: WebSpeedTestResultTableDetailData;
};

const SpeedInformationTable = ({ speedWebTestResultTableData }: Props) => {
  const isFailOrNA = speedWebTestResultTableData?.info.loadSpeedAverage < 0;
  const informationTable = [
    { title: 'URL', content: speedWebTestResultTableData?.info.url },
    {
      title: isFailOrNA ? 'Fail Reason' : 'Avg.Time',
      content: isFailOrNA
        ? `${speedWebTestResultTableData?.info.failReason}`
        : `${speedWebTestResultTableData?.info.loadSpeedAverage}s`,
    },
  ];
  return (
    <div className="mt-3">
      <p className="border-y border-gray-200 py-3 text-sm font-semibold text-gray-900">
        Information
      </p>
      <div className="min-w-full">
        <div className="flex flex-col">
          {informationTable.map((info, index) => (
            <div
              key={info.title}
              className={clsx(
                'flex items-center justify-start',
                index === 0 && 'border-b border-gray-200',
              )}
            >
              <div className="w-48 shrink-0 px-6 py-5 text-sm font-medium text-gray-500">
                {info.title}
              </div>
              <div className="break-all px-6 py-5 text-sm font-normal text-gray-900">
                {info.content}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SpeedInformationTable;
