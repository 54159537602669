import {
  ErrorBoundaryWrapper,
  ListBoxHeadlessInController,
} from '@autosquare/common';
import TestStepValueListbox from '@components/IDE/TestStepValueListbox';
import KeyboardEventListbox from '@components/IDE/WebTestStep/KeyboardEventListbox';
import WebTestStepImportTestCaseListBox from '@components/IDE/WebTestStep/WebTestStepImportTestCaseListBox';
import { useWebTestStepContext } from '@contexts/ide/WebTestStepProvider';
import { CreateWebTestStepData } from '@customTypes/testStep/type';
import {
  findWebCommandNameFromServerName,
  WebCommandOptionName,
  webCommandOptions,
  WebCommandOptionServerName,
} from '@utils/static/webCommandOptions';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  passCommand: WebCommandOptionServerName;
  failCommand: WebCommandOptionServerName;
  setFailCommand: React.Dispatch<
    React.SetStateAction<WebCommandOptionServerName>
  >;
  valueDefaultText: (command: WebCommandOptionServerName) => string;
};

const IfActionFail = ({
  passCommand,
  failCommand,
  setFailCommand,
  valueDefaultText,
}: Props) => {
  const { isFailListboxOpened, setIsFailListboxOpened, fullXPath } =
    useWebTestStepContext();

  const { setValue, watch, control } = useFormContext<CreateWebTestStepData>();

  const passActionValue = watch('optionsDto.passActionValue');
  const failActionValue = watch('optionsDto.failActionValue');
  const maskingOptionsEnabled = watch('optionsDto.maskingOptions.enabled');

  useEffect(() => {
    return () => setValue('optionsDto.failActionValue', undefined);
  }, [failCommand, setValue]);

  return (
    <ErrorBoundaryWrapper>
      <div className="flex gap-4">
        <div className="mt-1 w-10 text-sm font-medium text-red-500">FAIL</div>
        <div className="flex w-[calc(100%-3.5rem)] flex-col gap-4">
          <ListBoxHeadlessInController
            value={failCommand}
            buttonValue={
              failCommand
                ? findWebCommandNameFromServerName(failCommand)
                : 'Command'
            }
            onChange={setFailCommand}
            lists={webCommandOptions.filter(
              (webCommandOption) =>
                webCommandOption.name === WebCommandOptionName.Click ||
                webCommandOption.name === WebCommandOptionName.ImportTestCase ||
                webCommandOption.name === WebCommandOptionName.KeyBoardEvent,
            )}
            valueToSave={'serverName'}
            valueToShow={'name'}
            onClickOption={() =>
              setValue('optionsDto.failActionValue', undefined)
            }
            disabled={
              passCommand === undefined ||
              passActionValue === undefined ||
              passActionValue === 'Value' ||
              maskingOptionsEnabled
            }
          />
          {failCommand === WebCommandOptionServerName.KeyBoardEvent ? (
            <KeyboardEventListbox value={'optionsDto.failActionValue'} />
          ) : failCommand === WebCommandOptionServerName.ImportTestCase ? (
            <WebTestStepImportTestCaseListBox
              value={'optionsDto.failActionValue'}
            />
          ) : (
            <Controller
              control={control}
              name={'optionsDto.failActionValue'}
              render={({ field }) => (
                <TestStepValueListbox
                  valueText={field.value || valueDefaultText(failCommand)}
                  setValueText={field.onChange}
                  totalValues={fullXPath}
                  isListboxOpened={isFailListboxOpened}
                  setIsListboxOpened={setIsFailListboxOpened}
                  resetOnClick={() => {
                    setFailCommand(undefined);
                    setValue('optionsDto.failActionValue', undefined);
                  }}
                  disabled={
                    passActionValue === undefined ||
                    passCommand === undefined ||
                    failCommand === undefined ||
                    failActionValue !== undefined ||
                    maskingOptionsEnabled
                  }
                  resetDisabled={maskingOptionsEnabled}
                />
              )}
            />
          )}
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default IfActionFail;
