import React from 'react';
import {
  ErrorBoundaryWrapper,
  ErrorMessage,
  PagingHeadingsInAutosquare,
} from '@autosquare/common';
import { useLocation } from 'react-router-dom';
import AutomationRegisterComponent from './components/AutomationRegisterComponent';
import { useGetTestCaseDataAutomation } from '@lib/api/ide/mobile/mobileTestAutomation';
import AutomationDisplayDataComponent from './components/AutomationDisplayDataComponent';
import AutomationSettingsSkeleton from '@components/shared/Skeleton/AutomationSettingsSkeleton';
import { useParentPath } from '@hooks/index';

const MobileTestAutomationSettings = () => {
  const { pathname, search } = useLocation();

  const overviewPage = `${useParentPath(4)}/overview`;
  const testCasePage = useParentPath(2);
  const testCaseDetailPage = `${testCasePage}/detail${search}`;
  const testCaseAutomationPage = pathname + search;

  const breadcrumbs = [
    { title: 'UI Test', href: overviewPage },
    { title: 'Mobile', href: testCasePage },
    { title: 'Test Case', href: testCasePage },
    { title: 'Detail', href: testCaseDetailPage },
    { title: 'Automation Settings', href: testCaseAutomationPage },
  ];
  const useGetTestCaseDataAutomationQuery = useGetTestCaseDataAutomation();
  const testAutomationData = useGetTestCaseDataAutomationQuery.data;
  const isTestAutomationData = testAutomationData !== null;

  return (
    <ErrorBoundaryWrapper>
      <PagingHeadingsInAutosquare
        breadcrumbs={breadcrumbs}
        pageTitle={'Automation Settings'}
      />
      {useGetTestCaseDataAutomationQuery.isLoading ||
      useGetTestCaseDataAutomationQuery.isFetching ? (
        <AutomationSettingsSkeleton />
      ) : useGetTestCaseDataAutomationQuery.isError ? (
        <ErrorMessage>
          {useGetTestCaseDataAutomationQuery.error.message}
        </ErrorMessage>
      ) : isTestAutomationData ? (
        <AutomationDisplayDataComponent
          testAutomationData={testAutomationData}
          testCaseDetailPage={testCaseDetailPage}
        />
      ) : (
        <AutomationRegisterComponent testCaseDetailPage={testCaseDetailPage} />
      )}
    </ErrorBoundaryWrapper>
  );
};

export default MobileTestAutomationSettings;
