import { MailReceiver } from '@autosquare/common';
import { ProjectMemberList } from '@customTypes/dashboard/project/type';

export const handleSelectMember = (
  member: ProjectMemberList,
  projectAdminList: MailReceiver[],
  setProjectAdminList: React.Dispatch<React.SetStateAction<MailReceiver[]>>,
  setProjectAdminInput: React.Dispatch<React.SetStateAction<string>>,
  inputRef: React.MutableRefObject<HTMLInputElement | null>,
  setTemporarySavedEmailAddress: React.Dispatch<
    React.SetStateAction<MailReceiver>
  >,
  setIsOpenedMemberList: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  if (projectAdminList.find((x) => x.email === member.userId) === undefined) {
    setProjectAdminList([
      ...projectAdminList,
      { email: member.userId, name: member.name },
    ]);
    setProjectAdminInput('');
    if (inputRef.current) inputRef.current.value = '';
    setTemporarySavedEmailAddress({ email: '', name: '' });
    setIsOpenedMemberList(false);
  }
};
