import React, { Fragment } from 'react';
import clsx from 'clsx';
import { MenuItem, MenuItems, Transition } from '@headlessui/react';

type Props = {
  setIsEditModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCaution: React.Dispatch<React.SetStateAction<boolean>>;
};

const AutoRegisterContextMenu = ({
  setIsEditModalOpened,
  setOpenCaution,
}: Props) => {
  return (
    <>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          <MenuItem>
            <button
              type="button"
              onClick={() => {
                setIsEditModalOpened(true);
              }}
              className={clsx(
                'block w-full px-3 py-1 text-start text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50',
              )}
            >
              Edit
            </button>
          </MenuItem>
          <MenuItem>
            <button
              type="button"
              className={clsx(
                'block w-full px-3 py-1 text-start text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50',
              )}
              onClick={() => setOpenCaution(true)}
            >
              Delete
            </button>
          </MenuItem>
        </MenuItems>
      </Transition>
    </>
  );
};

export default AutoRegisterContextMenu;
