import { ErrorBoundaryWrapper } from '@autosquare/common';
import { ReservationInfo } from '@customTypes/index';
import React, { useState } from 'react';
import ReservationTimeTableBodyForm from './ReservationTimeTableBody/ReservationTimeTableBodyForm';
import ReservationTimeTableBodyRow from './ReservationTimeTableBody/ReservationTimeTableBodyRow';

type Props = {
  reservationInfo: ReservationInfo;
};

const ReservationTimeTableBody = ({ reservationInfo }: Props) => {
  const [isEdit, setIsEdit] = useState(false);

  return (
    <ErrorBoundaryWrapper>
      {isEdit ? (
        <ReservationTimeTableBodyForm
          reservationInfo={reservationInfo}
          setIsEdit={setIsEdit}
        />
      ) : (
        <ReservationTimeTableBodyRow
          reservationInfo={reservationInfo}
          setIsEdit={setIsEdit}
        />
      )}
    </ErrorBoundaryWrapper>
  );
};

export default ReservationTimeTableBody;
