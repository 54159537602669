import {
  MailReceiver,
  useShowErrorMessage,
  validateEmail,
} from '@autosquare/common';
import { ProjectMember } from '@customTypes/dashboard/project/type';

import {
  CreateSpeedSchedulerData,
  UpdateSpeedSchedulerData,
} from '@customTypes/index';

import { UseFormSetValue } from 'react-hook-form';

interface Props {
  e:
    | React.KeyboardEvent<HTMLInputElement>
    | React.KeyboardEvent<HTMLButtonElement>;
  setIsOpenedMemberList: (value: React.SetStateAction<boolean>) => void;
  inputRef: React.MutableRefObject<HTMLInputElement>;
  emailAddress: string;
  temporarySavedEmailAddress: MailReceiver;
  setEmailAddress: (value: React.SetStateAction<string>) => void;
  setSelectedIndex: (value: React.SetStateAction<number>) => void;
  setEmailErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  filteredPeople: ProjectMember[];
  setTemporarySavedEmailAddress: (
    value: React.SetStateAction<MailReceiver>,
  ) => void;
  selectedIndex: number;
  mailReceivers: MailReceiver[];
  setValue: UseFormSetValue<
    CreateSpeedSchedulerData | UpdateSpeedSchedulerData
  >;
}

export const useSpeedSchedulerEmailAlarmOnKeyDown = ({
  e,
  setIsOpenedMemberList,
  inputRef,
  emailAddress,
  temporarySavedEmailAddress,
  setEmailAddress,
  setSelectedIndex,
  setEmailErrorMessage,
  filteredPeople,
  setTemporarySavedEmailAddress,
  selectedIndex,
  mailReceivers,
  setValue,
}: Props) => {
  let newSelectedIndex: number;

  if (/^[a-z]$/g.test(e.key.toLowerCase())) {
    setIsOpenedMemberList(true);
  }

  if (e.key === 'Enter') {
    e.preventDefault();
    if (
      validateEmail(inputRef?.current?.value) ||
      validateEmail(emailAddress)
    ) {
      if (
        mailReceivers?.find(
          (x) =>
            x?.email === inputRef?.current?.value || x?.email === emailAddress,
        ) === undefined
      ) {
        setValue('mailReceivers', [
          ...mailReceivers,
          {
            email: validateEmail(emailAddress)
              ? emailAddress
              : temporarySavedEmailAddress?.email,
            name: validateEmail(emailAddress)
              ? null
              : temporarySavedEmailAddress?.name,
          },
        ]);
        setEmailAddress('');
        inputRef.current.value = '';
        setSelectedIndex(-1);
        setIsOpenedMemberList(false);
      } else {
        useShowErrorMessage(
          '등록된 이메일 주소입니다.',
          setEmailErrorMessage,
          4000,
        );
      }
    } else if (
      (!validateEmail(inputRef?.current?.value) &&
        inputRef?.current?.value !== '') ||
      (!validateEmail(emailAddress) && emailAddress !== '')
    ) {
      useShowErrorMessage(
        '이메일 형식이 아닙니다.',
        setEmailErrorMessage,
        4000,
      );
    }
  }

  if (e.key === 'Escape') {
    setIsOpenedMemberList(false);
    setEmailAddress('');
    inputRef.current.value = '';
    setSelectedIndex(-1);
  }

  const arrowKeyBasic = () => {
    e.preventDefault();
    setIsOpenedMemberList(true);
    setSelectedIndex(newSelectedIndex);
    inputRef.current.value =
      filteredPeople[newSelectedIndex]?.userId === undefined
        ? ''
        : filteredPeople[newSelectedIndex]?.userId;
    setTemporarySavedEmailAddress({
      email: filteredPeople[newSelectedIndex]?.userId,
      name: filteredPeople[newSelectedIndex]?.name,
    });
  };

  if (e.key === 'ArrowUp') {
    newSelectedIndex =
      selectedIndex > 0 ? selectedIndex - 1 : filteredPeople?.length - 1;
    arrowKeyBasic();
  }

  if (e.key === 'ArrowDown') {
    newSelectedIndex =
      selectedIndex < filteredPeople?.length - 1 ? selectedIndex + 1 : 0;
    arrowKeyBasic();
  }
};
